import React, { useState } from 'react';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { RiDeleteBinFill, RiPencilLine } from 'react-icons/ri';
import { ModalDialog } from 'uikit';
import { useDeleteUboMutation } from 'services/gatewayApi/ubosApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { UboDialog } from '../UboDialog';

export const DetailsTitle = ({ ubo, onClose }) => {
  const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery(undefined);

  const [deleteUbo] = useDeleteUboMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [uboDialogOpen, setUboDialogOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setUboDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteUbo({ id: ubo.id });
    setOpenConfirmDelete(false);
    onClose();
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pt: 5, pb: 2 }}
      >
        <Typography variant="h3" sx={{ mb: 0 }}>
          {ubo.firstName} {ubo.lastName}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button
            color="base"
            onClick={handleEdit}
            variant="contained"
            startIcon={<RiPencilLine size="14px" />}
          >
            Edit
          </Button>
          <Button
            color="error"
            onClick={handleConfirmDelete}
            variant="contained"
            startIcon={<RiDeleteBinFill size="14px" />}
            disabled={ubo.accounts?.length > 0}
          >
            Delete
          </Button>
        </Stack>
      </Stack>

      {ubo.status === 'ACTIVE' && <Chip size="small" color="secondary" label="Active" />}
      {ubo.status === 'INACTIVE' && <Chip size="small" color="error" label="Inactive" />}

      {uboDialogOpen && !isLoadingCountries && (
        <UboDialog
          open={uboDialogOpen}
          onClose={() => setUboDialogOpen(false)}
          ubo={ubo}
          countries={countries}
        />
      )}

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete Associated Individual"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Associated Individual '}
            <Typography variant="subtitle1" component="span">
              {ubo.firstName} {ubo.lastName}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </Box>
  );
};
