import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ValueLabel } from 'types';
import { Input } from 'uikit/Input';
import { NUM_LETTERS_REGEX } from 'constants/constants';
import { Client } from '../../types';
import { Account } from './Account';
import { AccountDialog } from './AccountDialog';
import { initialAccount } from '../../constants';

const IbansContainer = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    height: '530px',

    '::-webkit-scrollbar': {
      width: '16px',
    },
    '::-webkit-scrollbar-thumb': {
      height: '56px',
      borderRadius: '8px',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
      bgcolor: 'transparent',
    },
    ':hover::-webkit-scrollbar-thumb': {
      bgcolor: 'grey.300',
    },
  }),
);

interface IbansTabProps {
  client: Client;
  categories: ValueLabel[];
  setEdited: any;
}

export const IbansTab: React.FC<IbansTabProps> = ({ client, categories, setEdited }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [editAccount, setEditAccount] = useState<any>(undefined);

  const isAccounts: boolean = client.accounts.length > 0;
  const isAddButtonVisible: boolean = client.accounts.length < 3;

  const searchHandlerIbans = (e) => {
    const inputValue = e;
    if (inputValue.match(NUM_LETTERS_REGEX)) {
      setHasError(false);
      return setSearchValue(inputValue);
    }
    return setHasError(true);
  };

  return (
    <>
      <Stack spacing={4}>
        {isAccounts && (
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              searchHandlerIbans(e.target.value)
            }
            value={searchValue}
            placeholder="Search by IBAN"
            handleClear={() => searchHandlerIbans('')}
            hasError={hasError}
            // @ts-ignore
            style={{ width: '100%' }}
          />
        )}
        {isAddButtonVisible && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setEditAccount(initialAccount)}
          >
            Add account
          </Button>
        )}
        {isAccounts && (
          <IbansContainer>
            {client.accounts.map((account) => (
              <Account
                key={account.id}
                account={account}
                categories={categories}
                setEdited={setEdited}
                client={client}
                setEditAccount={setEditAccount}
                filterCase={searchValue}
              />
            ))}
          </IbansContainer>
        )}
      </Stack>

      {editAccount && (
        <AccountDialog
          open={!!editAccount}
          onClose={() => {
            setEditAccount(undefined);
          }}
          account={editAccount}
          clientAccounts={client.accounts}
          categories={categories}
          client={client}
          setEdited={setEdited}
        />
      )}
    </>
  );
};
