import {
  LOAD_RULE_STATS,
  LOAD_RULE_STATS_SUCCESS,
  LOAD_RULE_STATS_FAIL,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingListError } from '../utils/toast';

const entityTitle = 'Rule Stat';

export const fetchRuleStats =
  ({ id, startDate, endDate }) =>
  (dispatch) => {
    const postData = {
      startDate,
      endDate,
    };
    dispatch({
      type: LOAD_RULE_STATS,
      id,
    });
    ApiService.post(`/internal/rules/rule-stats/${id}`, postData)
      .then((response) => dispatch(fetchRuleStatsSuccess({ id, stats: response.data.stats })))
      .catch((error) => dispatch(fetchRuleStatsError({ id, error })));
  };

const fetchRuleStatsSuccess = ({ id, stats }) => ({
  type: LOAD_RULE_STATS_SUCCESS,
  id,
  stats,
});

const fetchRuleStatsError =
  ({ id, error }) =>
  (dispatch) => {
    dispatch({
      type: LOAD_RULE_STATS_FAIL,
      id,
      error,
    });
    toastLoadingListError(entityTitle, error);
  };
