import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PageContainer, EmptyPage, EndListButton, StickyHeader } from 'uikit';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';
import { useGetSearchSessionsQuery } from 'services/gatewayApi/pepAndSanctionsApi';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { transformResult } from './transformResult';
import { PepAndSanctionsHeader } from './PepAndSanctionsHeader';
import { SearchSessionsList } from './SearchSessions/SearchSessionsList';
import { Counter, SortParams } from './types';
import { updateLinks } from '../../uikit/BreadCrumbs/breadcrumbsSlice';

export interface SortParam {
  property: string;
  direction: 'asc' | 'desc';
}
export type TabProps = [
  counter: Counter,
  setCounter: React.Dispatch<React.SetStateAction<Counter>>,
  sortBy: SortParams,
  setSortBy: React.Dispatch<React.SetStateAction<SortParams>>,
  pageSize: number,
  setPageSize: React.Dispatch<React.SetStateAction<number>>,
];

export const PepAndSanctions: React.FC = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const location = useLocation();
  const navigate = useNavigate();
  const tabName = location.pathname.split('/').pop();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!applicationId && !['applications', 'transactions'].includes(tabName)) {
      navigate('/pep-and-sanctions/applications');
    }
    dispatch(updateLinks([]));
  }, [location]);

  const { data, isLoading, isFetching } = useGetSearchSessionsQuery({
    applicationId,
    params: { page: 0, size: pageSize },
    // sort: ['performedOn', 'ASC'], //TODO when BE will be ready
  });

  const {
    searchSessionsList,
    isLoading: isLoadingSearchSessions,
    isFetching: isFetchingSearchSessions,
    totalElements,
    numberOfElements,
  } = transformResult(data, isLoading, isFetching);

  const subTitle = 'Politically exposed persons and sanctions for the application.';

  const renderApplicationContent = () => (
    <>
      <PepAndSanctionsHeader counter={{ numberOfElements, totalElements }} subTitle={subTitle} />
      {searchSessionsList?.length > 0 ? (
        <SearchSessionsList searchSessions={searchSessionsList} />
      ) : (
        <EmptyPage
          isLoading={isLoadingSearchSessions}
          title="You haven't done a search session yet."
          subtitle="The search data will appear when the search is applied."
        />
      )}
      {(!!searchSessionsList || searchSessionsList?.length < 1) && (
        <EndListButton
          isVisible={searchSessionsList?.length > 0}
          nextPage={() =>
            setPageSize((prev) => (prev + 100 > totalElements ? totalElements : prev + 100))
          }
          isFetching={isFetchingSearchSessions}
          isEndReached={totalElements === numberOfElements}
        />
      )}
    </>
  );

  // TODO: check list Tabs by user permissions
  // const getTabs = (): Tab[] => [
  //   { value: 0, label: 'Applications', link: 'applications' },
  //   { value: 1, label: 'Transactions', link: 'transactions' },
  // ];
  // const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  // const [tabs, setTabs] = useState<Tabs>({ tabs: getTabs(), activeTab: 0 });

  const [sortBy, setSortBy] = useState<SortParams>({ property: '', sortDirection: 'ASC' }); // TODO default property
  const [counter, setCounter] = useState<Counter>({ numberOfElements: 0, totalElements: 0 });

  const tabProps: TabProps = [counter, setCounter, sortBy, setSortBy, pageSize, setPageSize];

  return (
    <>
      {applicationId ? (
        renderApplicationContent()
      ) : (
        <PageContainer>
          <Helmet title="PEP and Sanctions" />
          <StickyHeader>
            <PepAndSanctionsHeader
              counter={counter}
              // tabs={tabs} TODO make tabs by permissions
              // sortBy={sortBy} TODO make sort
              // setSortBy={setSortBy} TODO make sort
              // searchValue={searchValue} TODO make search
              // setSearchValue={setSearchValue} TODO make search
              isTransactions={tabName === 'transactions'}
              subTitle={`Politically exposed persons and sanctions. All ${tabName} searches.`}
            />
          </StickyHeader>
          <Outlet context={tabProps} />
        </PageContainer>
      )}
    </>
  );
};
