import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Typography } from '@mui/material';
import { makeSelectedProductIdData } from '../../selectors/user';
import { Button, PermissionAware } from '../../components';
import { fetchRules } from '../../actions/rules';
import { makeRulesData, makeSelectLoadingData } from '../../selectors/rules';
import RuleSetList from './RuleSetList';
import FilterWidget from './filterWidget';

export class Rules extends Component {
  // eslint-disable-line react/prefer-stateless-function

  componentWillMount() {
    this.props.fetchRules();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedProductId &&
      this.props.selectedProductId !== nextProps.selectedProductId
    ) {
      this.props.fetchRules();
    }
  }

  render() {
    const { rules, loading } = this.props;

    return (
      <div className="page-content rules-page">
        <Helmet title="Rulesets" />
        <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Typography variant="h1" py={4}>
                    Rulesets
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <FilterWidget />
              </div>
              <div className="col-md-12">
                <div className="grid simple">
                  <div className="grid-title">
                    <h4>
                      <span>Rulesets</span>
                    </h4>
                    <div className="new-button-container">
                      <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
                        <Link to="/rules/new">
                          <Button
                            buttonText="New Ruleset"
                            buttonClass="btn btn-primary btn-small"
                            iconClass="fa fa-plus"
                          />
                        </Link>
                      </PermissionAware>
                    </div>
                  </div>
                  <div className="grid-body p-0">
                    <RuleSetList rules={rules} loading={loading} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Rules.propTypes = {
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  fetchRules: PropTypes.func,
  selectedProductId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  fetchRules: () => dispatch(fetchRules()),
});

const mapStateToProps = createStructuredSelector({
  rules: makeRulesData(),
  loading: makeSelectLoadingData(),
  selectedProductId: makeSelectedProductIdData(),
});

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(Rules);
