import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { HeadLink } from 'muikit';
import { Spinner } from 'uikit';
import { RiAddCircleLine } from 'react-icons/ri';

import { useGetCustomerUsersQuery } from 'services/gatewayApi/usersApi';

import { usersTableHeaders } from '../constants';
import { UserRow } from '../UserRow';
import { UserDialog } from '../UserDialog';
import { UserDialogProps } from '../types';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';

const StyledTableCell = styled(TableCell)(({ theme }) =>
  theme.unstable_sx({
    color: 'grey.600',
    backgroundColor: 'transparent',
    px: '0 !important',
  }),
);

export const CustomerUsers = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { onboardingApplicationId, isLoadingApplication } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const [userDialogProps, setUserDialogProps] = useState<UserDialogProps>({
    open: false,
    customerId: '',
    applicationId,
  });

  useEffect(() => {
    applicationId && setUserDialogProps((prev) => ({ ...prev, applicationId }));
  }, [applicationId]);

  const { data: users = [], isLoading } = useGetCustomerUsersQuery(
    { applicationId },
    { skip: !applicationId },
  );

  return (
    <Box className="CustomerUsersTab">
      <HeadLink
        title="Customer Portal Users"
        actions={[
          <Button
            key="Btn_AddLink"
            color="primary"
            variant="contained"
            startIcon={<RiAddCircleLine size="14px" />}
            onClick={(e) => {
              e.stopPropagation();
              setUserDialogProps({
                open: true,
                customerId: 'new',
                applicationId,
              });
            }}
          >
            Add User
          </Button>,
        ]}
      />

      <Box sx={{ mt: 4 }}>
        {isLoading || isLoadingApplication || users.length < 1 ? (
          <>
            {isLoading ? (
              <div className="app-details-preloader app-details-preloader--small">
                <Spinner />
              </div>
            ) : (
              <Box mt={8}>
                <Typography variant="h1" color="grey.400">
                  Users not found.
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Table stickyHeader sx={{ my: 2 }}>
            <TableHead>
              <TableRow>
                {usersTableHeaders.map((el, idx) => (
                  <StyledTableCell key={el}>{el}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <UserRow key={user.id} user={user} setUserDialogProps={setUserDialogProps} />
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      {userDialogProps.open && (
        <UserDialog userDialogProps={userDialogProps} setUserDialogProps={setUserDialogProps} />
      )}
    </Box>
  );
};
