/* eslint-disable no-param-reassign */
import { DATETIME_FORMAT } from '../constants/constants';

export function removeEmptyProperties(obj) {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] == null ||
        obj[k] === undefined ||
        obj[k] === '' ||
        (obj[k].constructor === Array && obj[k].length === 0)) &&
      delete obj[k],
  );
}

/**
 * Formats obj's date using supplied formatDate callback param and pattern param
 * Combines into a single string and removes:
 * [modifiedDate, modifiedByName] => modified,
 * [createdDate, createdByName] => created.
 * Removes ModifiedBy, CreatedBy
 *
 * @param obj
 * @param formatDate
 * @param pattern
 * @returns {Pick<*&{created: string, modified: string}, Exclude<keyof *&{created: string, modified: string}, "createdByName"|"modifiedByName"|"createdDate"|"createdBy"|"modifiedDate"|"modifiedBy">>}
 */
export const combineDateTime = (obj, formatDate, pattern = DATETIME_FORMAT) => {
  const combine = (date, name) => {
    const resultDate = date ? `${formatDate(date, pattern)}` : '';
    const resultName = name || '';
    return `${resultDate} ${resultName}`;
  };

  const created = combine(obj.createdDate, obj.createdByName);
  const modified = obj.modifiedDate ? combine(obj.modifiedDate, obj.modifiedByName) : created;

  const {
    modifiedBy,
    modifiedByName,
    modifiedDate,
    createdBy,
    createdDate,
    createdByName,
    ...result
  } = {
    ...obj,
    modified,
    created,
  };
  return result;
};

/**
 * Formats obj's date using supplied formatDate callback param and pattern param
 * Combines into a single string:
 * [revisionInstant, modifiedByName] => modified,
 * @param obj
 * @param formatDate
 * @param pattern
 * @returns {{modified: string, revision: RevisionType}}
 */

export const transformDelete = (obj, formatDate, pattern = DATETIME_FORMAT) => ({
  modified: `${formatDate(obj.revision.revisionInstant, pattern)} ${obj.revision.modifiedByName}`,
  revision: obj.revision,
});
