import { Node } from './Node';

export class ValueNode extends Node {
  constructor(value) {
    super();
    this.value = value;
  }

  compute() {
    if (typeof this.value === 'undefined' || this.value === null) {
      console.log(
        'Referenced value is undefined ' +
          'and will be replaced with empty string ' +
          'during the evaluation of the condition value',
      );
      return '';
    }
    return this.value;
  }

  toString() {
    return JSON.stringify(this.value);
  }

  toDDFConditionNode(operator) {
    return { [operator]: this.value };
  }
}
