import { MixinsOptions } from '@mui/material/styles/createMixins';

export const mixins: MixinsOptions = {
  toolbar: {
    maxWidth: 1536, // xl
    '@media all': {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
};
