import { styled } from '@mui/system';

export const CustomTabs = styled('div', {
  name: 'CustomTabs',
  slot: 'root',
})(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection: 'column',
  '& .CustomTab .leftLine': {
    content: '""',
    position: 'absolute',
    left: '6px',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  '& .CustomTab:first-of-type .leftLine': {
    height: '50%',
    bottom: 0,
  },
  '& .CustomTab:last-of-type .leftLine': {
    height: '50%',
    top: 0,
  },
  '& .CustomTab:not(:first-of-type):not(:last-of-type) .leftLine': {
    top: 0,
  },
}));
