import { styled } from '@mui/material/styles';
import { TableRow } from '@mui/material';
import { grey } from 'theme/palette-blocks';

interface BodyRowProps {
  disablehover?: boolean | 'true' | 'false';
}

export const BodyRow = styled(TableRow)<BodyRowProps>(({ theme, disablehover = false }) =>
  theme.unstable_sx({
    '&.MuiTableRow-root': {
      '&:hover': {
        backgroundColor: disablehover ? 'transparent' : 'grey.50',
      },
    },
  }),
);
