import React, { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { InputBaseProps } from '@mui/material/InputBase';
import { Box, InputBase } from '@mui/material';
import { DATE_FORMAT } from 'constants/constants';
import { formatFormDate, formatIsoDate } from 'utils/formatter';
import { FormFieldWrapper } from '../FormFieldWrapper';
import { AdornmentWrapper } from '../AdornmentWrapper';
import { Information } from '../Information';

interface FormDatePickerProps extends InputBaseProps {
  label?: string;
  disabled?: boolean;
  info?: string;
  prefixForm?: React.FC | JSX.Element;
  suffix?: React.FC | JSX.Element;
  error?: boolean;
  value?: Date | string | null;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  label = undefined,
  disabled: disabledProp = false,
  info = undefined,
  prefixForm = undefined,
  suffix = undefined,
  error: errorProp = false,
  value: date = undefined,
  onChange: onChangeHandler,
  sx,
  onBlur: onBlurHandler,
  ...otherProps
}) => {
  const [isError, setIsError] = useState(false);

  const CheckDate = (newDate) => {
    if (newDate === null || newDate.valueOf()) {
      onChangeHandler(newDate);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  return (
    <FormFieldWrapper label={label} disabled={disabledProp} error={errorProp || isError} sx={sx}>
      <AdornmentWrapper disabled={disabledProp}>{prefixForm}</AdornmentWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat={DATE_FORMAT}
          onChange={CheckDate}
          value={formatFormDate(date)}
          disabled={disabledProp}
          {...otherProps}
          renderInput={({
            ref,
            inputRef,
            inputProps,
            InputProps,
            error,
            disabled,
            onChange,
            value,
          }) => (
            <Box ref={ref} width={1}>
              <InputBase
                placeholder={DATE_FORMAT}
                disabled={disabled}
                onChange={onChange}
                value={formatIsoDate(value, DATE_FORMAT)}
                fullWidth
                inputRef={inputRef}
                endAdornment={
                  <Box pr={1} m={0}>
                    {InputProps.endAdornment}
                  </Box>
                }
                error={error || isError}
                onBlur={onBlurHandler}
                {...inputProps}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
      <Information info={info} disabled={disabledProp} />
      <AdornmentWrapper disabled={disabledProp}>{suffix}</AdornmentWrapper>
    </FormFieldWrapper>
  );
};
