import React from 'react';
import './Panel.scss';

interface PanelProps {
  children: React.ReactNode;
}

const Panel: React.FC<PanelProps> = ({ children }) => <div className="basePanel">{children}</div>;

export default Panel;
