import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiChevronDown } from 'react-icons/fi';
import { IconButton, Typography, Menu, InputAdornment, MenuItem, Divider } from '@mui/material';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCloseCircleFill,
  RiSearch2Line,
} from 'react-icons/ri';
import { StateType } from 'features/Rules/types';
import { InputGreyStyled } from 'muikit/Input/InputGreyStyled';
import { grey } from 'theme/palette-blocks';
import './BreadCrumbs.scss';

interface BreadCrumbsComponentProps {
  handleSetPage?: (direction: 'prev' | 'next' | 'initial') => void;
  searchHandler?: (string) => void;
  searchQuery?: string;
}

export const BreadCrumbs: React.FC<BreadCrumbsComponentProps> = ({
  handleSetPage = () => { },
  searchHandler = () => { },
  searchQuery,
}) => {
  const navigate = useNavigate();

  const { clientId, applicationId } = useParams<{
    applicationId: string;
    clientId: string;
  }>();

  const links =
    useSelector((state: StateType) => state.breadcrumbsState?.links)?.filter((el) => !!el?.title) ||
    [];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openLink, setOpenLink] = useState<number>(undefined);

  const paginationData = links[openLink]?.pagination;
  const hasPagination = paginationData?.totalPages > 1;
  const hasSearch = links[openLink]?.hasSearch;

  const handleOpenMenu = ({
    event,
    linkIdxOpen,
  }: {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    linkIdxOpen?: number;
  }) => {
    event.stopPropagation();
    setOpenLink(linkIdxOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleGoToApplication = (link) => {
    handleCloseMenu();
    navigate(link);
  };

  const handleCloseMenu = () => {
    searchHandler('');
    handleSetPage('initial');
    setAnchorEl(null);
  };

  const applicationUrl = `/application-management/${clientId}/applications/${applicationId}`;
  const currentPathDepth = location.pathname.split('/').length;

  return (
    <>
      <div className="BreadCrumbs">
        {links && links?.length > 0 ? (
          links?.map((link, i) => {
            const isCurrentPage =
              link.to === location.pathname //links?.length === i + 1;
              || (link.to == applicationUrl && currentPathDepth === 6); // don't link when on an application details main page

            if (!link?.title) {
              return null;
            }

            return (
              <div className="bread-crumbs-element" key={i}>
                <div
                  className={`bread-crumbs-button ${isCurrentPage ? 'no-hover' : ''}`}
                  key={i}
                  role={isCurrentPage ? '' : 'button'}
                  tabIndex={i}
                  onClick={() => {
                    !(isCurrentPage && !!link.to) && navigate(link.to, link.state || {});
                  }}
                  onKeyDown={() => { }}
                >
                  {link?.icon && link.icon}
                  {link?.title && (
                    <Typography variant="body" color="inherit">
                      {link?.title}
                    </Typography>
                  )}
                  {false && link?.siblings && (
                    <IconButton
                      id={`${i}-openMenu`}
                      size="small"
                      sx={{ padding: '0 0.1em', marginRight: '0.3em' }}
                      onClick={(e) => handleOpenMenu({ event: e, linkIdxOpen: i })}
                    >
                      <FiChevronDown style={{ fontSize: '14px' }} />
                    </IconButton>
                  )}
                </div>

                <RiArrowRightSLine className="separator" size="16px" />
              </div>
            );
          })
        ) : (
          <span className="preloader">Loading...</span>
        )}
      </div>

      <Menu
        anchorEl={anchorEl}
        id="breadCrumbs-menu"
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{ maxHeight: '26rem' }}
        autoFocus={false}
        disableAutoFocusItem
      >
        {hasSearch && (
          <InputGreyStyled
            sx={{ mb: 1 }}
            className="searchInputBreadcrumbs"
            fullWidth
            color="secondary"
            placeholder="Find"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault();
              e.stopPropagation();
              searchHandler(e.target.value);
              handleSetPage('initial');
            }}
            value={searchQuery}
            startAdornment={
              <InputAdornment position="start" sx={{ pl: 2 }}>
                <RiSearch2Line color={grey[700]} />
              </InputAdornment>
            }
            endAdornment={
              searchQuery?.length > 0 && (
                <InputAdornment position="end" sx={{ pl: 2 }}>
                  <IconButton
                    size="small"
                    onClick={() => searchHandler('')}
                    sx={{ '&:hover': { bgcolor: 'transparent' } }}
                  >
                    <RiCloseCircleFill color={grey[700]} fontSize="14" />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        )}
        {links[openLink] && links[openLink]?.siblings?.length > 0 ? (
          links[openLink].siblings?.map((el, idx) => (
            <MenuItem
              key={idx}
              onClick={() => (el.to ? handleGoToApplication(el.to) : {})}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  el?.to && handleGoToApplication(el.to);
                }
              }}
              disabled={!el.to}
            >
              <div>
                {el?.icon && el?.icon}
                {el?.title}
              </div>
            </MenuItem>
          ))
        ) : (
          <div className="menu-item-row disabled" style={{ justifyContent: 'center' }}>
            No results
          </div>
        )}
        {hasPagination && <Divider />}
        {hasPagination && (
          <div className="paginationRow">
            <IconButton onClick={() => handleSetPage('prev')} disabled={paginationData.page === 0}>
              <RiArrowLeftSLine size="16px" />
            </IconButton>
            <Typography sx={{ color: 'grey.600' }}>
              {paginationData?.page + 1} page of {paginationData.totalPages}
            </Typography>
            <IconButton
              onClick={() => handleSetPage('next')}
              disabled={paginationData.page + 1 === paginationData?.totalPages}
            >
              <RiArrowRightSLine size="16px" />
            </IconButton>
          </div>
        )}
      </Menu>
    </>
  );
};
