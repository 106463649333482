import {
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAIL,
  ASSOCIATE_PRODUCT,
  ASSOCIATE_PRODUCT_SUCCESS,
  ASSOCIATE_PRODUCT_FAIL,
  REMOVE_ASSOCIATED_PRODUCT,
  REMOVE_ASSOCIATED_PRODUCT_SUCCESS,
  REMOVE_ASSOCIATED_PRODUCT_FAIL,
} from '../constants/constants';
import { fetchUsers } from './users';
import ApiService from '../services/apiService';
import { toastSuccess, toastError, toastLoadingListError } from '../utils/toast';

const entityTitle = 'Product';

export const fetchProducts = () => (dispatch) => {
  dispatch({
    type: LOAD_PRODUCTS,
  });
  ApiService.get('/api/gateway/tenants')
    .then((response) => {
      dispatch(fetchProductsSuccess(response));
    })
    .catch((error) => dispatch(fetchProductsFail(error)));
};

const fetchProductsSuccess = (products) => ({
  type: LOAD_PRODUCTS_SUCCESS,
  products,
});

const fetchProductsFail = (error) => (dispatch) => {
  dispatch({
    type: LOAD_PRODUCTS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

export const associateProduct = (ids) => {
  const { userId } = ids;
  const { productId } = ids;
  return (dispatch) => {
    dispatch({ type: ASSOCIATE_PRODUCT });
    ApiService.post(`/internal/products/${productId}/users/${userId}`)
      .then(() => dispatch(associateProductSuccess()))
      .catch((error) => dispatch(associateProductFail(error)));
  };
};

const associateProductSuccess = () => (dispatch) => {
  dispatch({
    type: ASSOCIATE_PRODUCT_SUCCESS,
  });
  toastSuccess(`${entityTitle} associated successfully.`);
  dispatch(fetchUsers());
};

const associateProductFail = (error) => (dispatch) => {
  dispatch({
    type: ASSOCIATE_PRODUCT_FAIL,
    error,
  });
  toastError(`${entityTitle} association failed. ${error}`);
};

export const removeAssociatedProduct = (ids) => {
  const { userId } = ids;
  const { productId } = ids;
  return (dispatch) => {
    dispatch({ type: REMOVE_ASSOCIATED_PRODUCT });
    ApiService.delete(`/internal/products/${productId}/users/${userId}`)
      .then(() => dispatch(removeAssociatedProductSuccess()))
      .catch((error) => dispatch(removeAssociatedProductFail(error)));
  };
};

const removeAssociatedProductSuccess = () => (dispatch) => {
  dispatch({
    type: REMOVE_ASSOCIATED_PRODUCT_SUCCESS,
  });
  toastSuccess(`${entityTitle} association removed successfully.`);
  dispatch(fetchUsers());
};

const removeAssociatedProductFail = (error) => (dispatch) => {
  dispatch({
    type: REMOVE_ASSOCIATED_PRODUCT_FAIL,
    error,
  });
  toastError(`${entityTitle} association removal failed. ${error}`);
};
