import { styled } from '@mui/material/styles';

export const PageTitle = styled('h1')({
  fontSize: '1.5rem',
  lineHeight: '1.2',
  fontWeight: '400',
  margin: 0,
  padding: 0,
  letterSpacing: '-0.5px',
});

export const PageControl = styled('div')(({ theme }) =>
  theme.unstable_sx({
    paddingTop: '1.75rem',
    paddingBottom: '0.75rem',
    borderBottom: `solid thin ${theme.palette.grey[300]}`,
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'grey.50',
  }),
);
