export enum IndividualKycStatus {
  NotPerformed = 'NOT_PERFORMED',
  InProgress = 'IN_PROGRESS',
  Clear = 'CLEAR',
  Consider = 'CONSIDER',
  Error = 'ERROR',
  DocumentsVirusChecking = 'DOCUMENTS_VIRUS_CHECKING',
  ManualReviewRequired = 'MANUAL_REVIEW_REQUIRED',
}

export type IndividualKycStatusType = `${IndividualKycStatus}`;

type KycStatus = {
  value: IndividualKycStatusType;
  label: string;
  color: 'default' | 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning';
};

export const kycStatuses: KycStatus[] = [
  {
    value: IndividualKycStatus.NotPerformed,
    label: 'Not Performed',
    color: 'default',
  },
  {
    value: IndividualKycStatus.InProgress,
    label: 'In Progress',
    color: 'default',
  },
  {
    value: IndividualKycStatus.Clear,
    label: 'Clear',
    color: 'success',
  },
  {
    value: IndividualKycStatus.Consider,
    label: 'Consider',
    color: 'error',
  },
  {
    value: IndividualKycStatus.Error,
    label: 'Error',
    color: 'error',
  },
  {
    value: IndividualKycStatus.DocumentsVirusChecking,
    label: 'Documents Virus Checking',
    color: 'default',
  },
  {
    value: IndividualKycStatus.ManualReviewRequired,
    label: 'Manual Review Required',
    color: 'default',
  },
];
