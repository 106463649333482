import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack, Table, TableBody } from '@mui/material';
import { BodyRow, BodyTableCell, Spinner } from 'uikit';
import { useGetApplicationHistoryQuery } from 'services/gatewayApi/applicationApi';
import { useGetAppFormSectionsHistoryQuery } from 'services/gatewayApi/applicationFormSectionsApi';
import { HistoryRowOfApplication } from './HistoryRowOfApplication';
import { HistoryRowOfForm } from './HistoryRowOfForm';

const tabs = [
  { url: 'all', title: 'All' },
  { url: 'app-status', title: 'Application' },
  { url: 'form-status', title: 'Forms' },
];

export const HistoryTab = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data: applicationHistory = [], isLoading: isLoadingApplicationHistory } =
    useGetApplicationHistoryQuery(applicationId);

  const applicationHistoryWithPrev = applicationHistory?.map((el, idx) => ({
    ...el,
    prevStatus:
      idx === applicationHistory.length - 1 ? null : applicationHistory[idx + 1].applicationStatus,
  }));

  const { formSectionsHistory = [], isLoadingFormSectionsHistory } =
    useGetAppFormSectionsHistoryQuery(
      { applicationId },
      {
        selectFromResult: ({ data, isLoading }) => ({
          formSectionsHistory: data
            ?.map((el) =>
              el.history.map(({ revision, status, partition }) => ({
                type: 'form-status',
                date: revision.revisionInstant,
                modifiedByName: revision.modifiedByName,
                revisionNumber: revision.revisionNumber,

                sectionId: el.sectionId,
                formTitle: el.title,
                formStatus: status,
                partition,
              })),
            )
            .flat()
            .sort((a, b) => (b.revisionNumber > a.revisionNumber ? 1 : -1)),
          isLoadingFormSectionsHistory: isLoading,
        }),
      },
    );
  const formSectionsHistoryWithPrev = formSectionsHistory?.map((el) => ({
    ...el,
    prevStatus: formSectionsHistory.filter(
      (item) => item.sectionId == el.sectionId && item.revisionNumber < el.revisionNumber,
    )[0]?.formStatus,
  }));

  const historyList = [
    ...(activeTabIndex != 2 ? applicationHistoryWithPrev : []),
    ...(activeTabIndex != 1 ? formSectionsHistoryWithPrev : []),
  ].sort((a, b) => (b.date > a.date ? 1 : -1));

  return (
    <div className="historyTabWrapper" style={{ minHeight: '74vh' }}>
      <Stack
        className="historyTabHeader"
        direction="row"
        spacing={2}
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          padding: '1rem 0',
          mb: 4,
        })}
      >
        {tabs.map((tab, idx) => (
          <Button
            className="TabButton"
            key={`TabButton_${tab.url}`}
            onClick={() => setActiveTabIndex(idx)}
            color="base"
            variant={activeTabIndex === idx ? 'contained' : 'text'}
          >
            {tab.title}
          </Button>
        ))}
      </Stack>

      <Table>
        <TableBody>
          {isLoadingApplicationHistory || isLoadingFormSectionsHistory ? (
            <BodyRow disablehover>
              <BodyTableCell>
                <Box className="app-details-preloader app-details-preloader--small">
                  <Spinner />
                </Box>
              </BodyTableCell>
            </BodyRow>
          ) : (
            <>
              {historyList?.map((el) => {
                if (el.type === 'app-status') {
                  return (
                    <HistoryRowOfApplication
                      key={`${el.type}-${el.revisionNumber}`}
                      date={el.date}
                      name={el.modifiedByName}
                      status={el.applicationStatus}
                      prevStatus={el.prevStatus}
                      decision={el.decision}
                      reason={el.reason}
                    />
                  );
                }
                if (el.type === 'form-status') {
                  return (
                    <HistoryRowOfForm
                      key={`${el.type}-${el.sectionId}-${el.revisionNumber}`}
                      date={el.date}
                      name={el.modifiedByName}
                      title={el.formTitle}
                      status={el.formStatus}
                      prevStatus={el.prevStatus}
                    />
                  );
                }
                return null;
              })}
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
