import { secondary } from '../../palette-blocks';

export const SecondaryChip = {
  props: {
    color: 'secondary',
  },
  style: {
    color: secondary.contrastText,
    background: secondary.light,
    '.MuiChip-deleteIcon': {
      color: secondary.contrastText,
      '&:hover, &:active': {
        color: secondary.contrastText,
      },
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: secondary.hover,
      },
      '&:active': {
        background: secondary.active,
      },
    },
    '.MuiChip-avatar': {
      background: secondary.main,
      color: 'white',
    },
  },
};

export const SecondaryChipOutlined = {
  props: {
    color: 'secondary',
    variant: 'outlined',
  },
  style: {
    borderColor: secondary.main,
    background: 'white',
    '&.MuiChip-clickable': {
      '&:hover': {
        background: secondary.hover,
        borderColor: secondary.hover,
      },
      '&:active': {
        background: secondary.active,
        borderColor: secondary.active,
      },
    },
  },
};
