import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  useDeleteGroupMultiTenantMutation,
  useDeleteGroupMutation,
} from 'services/gatewayApi/usersApi';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { toastDeleteSuccess } from 'utils/toast';
import { Button as Btn } from 'uikit';

const RoleListRow = ({ role, productsList, setIsRoleDialogOpen }) => {
  const { name, id, modifiedDate } = role;

  const { selectedProductId, permissions } = useSelector(makeUserData());
  const isSuperAdmin = permissions.some((el) => el.name === 'SUPER_ADMIN');

  const [deleteGroup] = useDeleteGroupMutation();
  const [deleteGroupMultiTenants] = useDeleteGroupMultiTenantMutation();

  const { name: productName } = productsList.filter((el) => el.id === role.tenantId)[0];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditRole = () => {
    handleClose();
    setIsRoleDialogOpen({
      role,
      open: true,
    });
  };
  const handleCopyRole = () => {
    handleClose();
    setIsRoleDialogOpen({
      role: { ...role, id: 'new', name: `${role.name}(copy)` },
      open: true,
    });
  };
  const handleDeleteRole = async () => {
    const mutationPromise = (await (isSuperAdmin && role.tenantId !== selectedProductId))
      ? deleteGroupMultiTenants({ tenantId: role.tenantId, id: role.id })
      : deleteGroup(role.id);
    // @ts-ignore
    if (!mutationPromise.error) {
      toastDeleteSuccess('Role');
    }
    handleClose();
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{ width: '100%', height: '100%' }}
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item md={5}>
          <Typography variant="body1" align="left" component="div" sx={{ fontSize: '14px' }}>
            {name}
          </Typography>
        </Grid>
        <Grid item md={7} container alignItems="center">
          <Grid item md={5}>
            <Typography variant="body2" align="left" component="div">
              {productName}
            </Typography>
          </Grid>
          <Grid item md={5}>
            <Typography variant="body2" align="left" component="div">
              {formatIsoDate(modifiedDate, DATE_FORMAT_MESSAGES)}
            </Typography>
          </Grid>
          <Grid item md={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Btn
              iconButton
              shape="rounded"
              prefix={<MoreVertIcon sx={{ fontSize: '16px' }} />}
              variant="text"
              theme="base"
              size="xs"
              onClick={handleClick}
            />
          </Grid>
        </Grid>

        <Menu
          id={`${id}-menu`}
          MenuListProps={{ 'aria-labelledby': `${id}-button` }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditRole}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleCopyRole}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleDeleteRole}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
};

export { RoleListRow };
