import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useUpdateClientFlowFundMutation } from 'services/gatewayApi/clientsApi';
import { ValueLabel } from 'types/types';
import EditIcon from '@mui/icons-material/Edit';
import { Client } from '../../types';
import { infoFieldsFinActivity } from '../../constants';

interface FinActivFormProps {
  client: Client;
  countries: ValueLabel[];
  isLoading: boolean;
  setEdited: any;
  setFinActivity: any;
}

export const FinActivForm: React.FC<FinActivFormProps> = ({
  client,
  countries = [],
  isLoading,
  setEdited,
  setFinActivity,
}) => {
  const [updateClientFlowFunds] = useUpdateClientFlowFundMutation();

  const initialFlowFundsReceiptAllowed = client.flowFunds
    ?.filter((el) => el.fundType === 'RECEIPT' && el.accessType === 'ALLOWED')
    .map((el) => el.countryCode);
  const initialFlowFundsPaymentAllowed = client.flowFunds
    ?.filter((el) => el.fundType === 'PAYMENT' && el.accessType === 'ALLOWED')
    .map((el) => el.countryCode);

  const initialFlowFundsReceiptRestricted = client.flowFunds
    ?.filter((el) => el.fundType === 'RECEIPT' && el.accessType === 'RESTRICTED')
    .map((el) => el.countryCode);
  const initialFlowFundsPaymentRestricted = client.flowFunds
    ?.filter((el) => el.fundType === 'PAYMENT' && el.accessType === 'RESTRICTED')
    .map((el) => el.countryCode);

  const [flowFundsReceiptAllowed, setFlowFundsReceiptAllowed] = useState(
    initialFlowFundsReceiptAllowed,
  );
  const [flowFundsPaymentAllowed, setFlowFundsPaymentAllowed] = useState(
    initialFlowFundsPaymentAllowed,
  );

  const [flowFundsReceiptRestricted, setFlowFundsReceiptRestricted] = useState(
    initialFlowFundsReceiptRestricted,
  );
  const [flowFundsPaymentRestricted, setFlowFundsPaymentRestricted] = useState(
    initialFlowFundsPaymentRestricted,
  );

  useEffect(() => {
    setFlowFundsReceiptAllowed(initialFlowFundsReceiptAllowed);
    setFlowFundsPaymentAllowed(initialFlowFundsPaymentAllowed);

    setFlowFundsReceiptRestricted(initialFlowFundsReceiptRestricted);
    setFlowFundsPaymentRestricted(initialFlowFundsPaymentRestricted);
  }, [client]);

  const handleChangeFlowFunds = (type, accessType, values) => {
    const countryCode = values.map((el) => el.value);

    switch (accessType) {
      case 'ALLOWED':
        switch (type) {
          case 'RECEIPT':
            return setFlowFundsReceiptAllowed(countryCode);
          case 'PAYMENT':
            return setFlowFundsPaymentAllowed(countryCode);
        }
        break;

      case 'RESTRICTED':
        switch (type) {
          case 'RECEIPT':
            return setFlowFundsReceiptRestricted(countryCode);
          case 'PAYMENT':
            return setFlowFundsPaymentRestricted(countryCode);
        }
        break;
    }
  };

  const handleSaveFlowFunds = async (type, accessType) => {
    let countryCode;

    if (
      type === 'PAYMENT' &&
      accessType === 'ALLOWED' &&
      !_.isEqual(initialFlowFundsPaymentAllowed, flowFundsPaymentAllowed)
    ) {
      countryCode = flowFundsPaymentAllowed;
    }

    if (
      type === 'RECEIPT' &&
      accessType === 'ALLOWED' &&
      !_.isEqual(initialFlowFundsReceiptAllowed, flowFundsReceiptAllowed)
    ) {
      countryCode = flowFundsReceiptAllowed;
    }

    if (
      type === 'PAYMENT' &&
      accessType === 'RESTRICTED' &&
      !_.isEqual(initialFlowFundsPaymentRestricted, flowFundsPaymentRestricted)
    ) {
      countryCode = flowFundsPaymentRestricted;
    }

    if (
      type === 'RECEIPT' &&
      accessType === 'RESTRICTED' &&
      !_.isEqual(initialFlowFundsReceiptRestricted, flowFundsReceiptRestricted)
    ) {
      countryCode = flowFundsReceiptRestricted;
    }

    if (countryCode) {
      await updateClientFlowFunds({
        id: client.id,
        type,
        body: { countryCode, accessType },
      });
      setEdited(true);
    }
  };

  const finActivity = {
    monthlyTurnover: client.monthlyTurnover,
    monthlyTransactions: client.monthlyTransactions,
    averageInbound: client.averageInbound,
    averageOutbound: client.averageOutbound,
    maxInbound: client.maxInbound,
    maxOutbound: client.maxOutbound,

    flowFundsReceiptAllowed: client.flowFunds
      .filter((el) => el.fundType === 'RECEIPT' && el.accessType === 'ALLOWED')
      .map((el) => el.countryCode),
    flowFundsPaymentAllowed: client.flowFunds
      .filter((el) => el.fundType === 'PAYMENT' && el.accessType === 'ALLOWED')
      .map((el) => el.countryCode),

    flowFundsReceiptRestricted: client.flowFunds
      .filter((el) => el.fundType === 'RECEIPT' && el.accessType === 'RESTRICTED')
      .map((el) => el.countryCode),
    flowFundsPaymentRestricted: client.flowFunds
      .filter((el) => el.fundType === 'PAYMENT' && el.accessType === 'RESTRICTED')
      .map((el) => el.countryCode),
  };

  return (
    <>
      {infoFieldsFinActivity.map((field) => (
        <Box
          key={field.value}
          sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette[200]}` })}
        >
          <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
            {field.label}
          </Typography>
          <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
            <Typography>{client[field.value]}</Typography>
          </Box>
        </Box>
      ))}

      <Stack spacing={6} sx={{ pb: 8 }}>
        <Typography variant="subtitle1" sx={{ pt: 6 }}>
          Breakdown of Flow of Funds per Country to be routed through OpenPayd
        </Typography>
        <Autocomplete
          multiple
          disabled={isLoading}
          id="flowFundsReceiptAllowed"
          options={countries}
          getOptionLabel={(option) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={countries?.filter((option) =>
            flowFundsReceiptAllowed.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            handleChangeFlowFunds('RECEIPT', 'ALLOWED', newValue);
          }}
          onBlur={() => handleSaveFlowFunds('RECEIPT', 'ALLOWED')}
          ChipProps={{ color: 'default', size: 'small', onDelete: undefined }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Receipt countries"
              placeholder="Select receipt countries"
            />
          )}
        />
        <Autocomplete
          multiple
          disabled={isLoading}
          id="flowFundsPaymentAllowed"
          options={countries}
          getOptionLabel={(option) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={countries?.filter((option) =>
            flowFundsPaymentAllowed.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            handleChangeFlowFunds('PAYMENT', 'ALLOWED', newValue);
          }}
          onBlur={() => handleSaveFlowFunds('PAYMENT', 'ALLOWED')}
          ChipProps={{ color: 'default', size: 'small', onDelete: undefined }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Payment countries"
              placeholder="Select payment countries"
            />
          )}
        />
      </Stack>

      <Stack spacing={6} sx={{ pb: 8 }}>
        <Typography variant="subtitle1" sx={{ pt: 6 }}>
          Breakdown of Flow of Funds per Country
          <Typography variant="subtitle1" component="span" color="error" sx={{ fontWeight: 600 }}>
            {' '}
            NOT{' '}
          </Typography>
          to be routed through OpenPayd
        </Typography>
        <Autocomplete
          multiple
          disabled={isLoading}
          id="flowFundsReceiptRestricted"
          options={countries}
          getOptionLabel={(option) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={countries?.filter((option) =>
            flowFundsReceiptRestricted.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            handleChangeFlowFunds('RECEIPT', 'RESTRICTED', newValue);
          }}
          onBlur={() => handleSaveFlowFunds('RECEIPT', 'RESTRICTED')}
          ChipProps={{ color: 'default', size: 'small', onDelete: undefined }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Receipt countries"
              placeholder="Select receipt countries"
            />
          )}
        />
        <Autocomplete
          multiple
          disabled={isLoading}
          id="flowFundsPaymentRestricted"
          options={countries}
          getOptionLabel={(option) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={countries?.filter((option) =>
            flowFundsPaymentRestricted.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            handleChangeFlowFunds('PAYMENT', 'RESTRICTED', newValue);
          }}
          onBlur={() => handleSaveFlowFunds('PAYMENT', 'RESTRICTED')}
          ChipProps={{ color: 'default', size: 'small', onDelete: undefined }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Payment countries"
              placeholder="Select payment countries"
            />
          )}
        />
      </Stack>

      <Button
        color="secondary"
        onClick={() => setFinActivity(finActivity)}
        variant="contained"
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
    </>
  );
};
