import { error } from '../../palette-blocks';
import { palette } from '../../palette';
import { buttonFocus } from '../mixins';

export const Error = {
  props: {
    color: 'error' as any,
  },
  style: {
    color: error.main,
    background: error.light,
    '&:hover': {
      background: error.hover,
    },
    '&:active': {
      background: error.active,
    },
    '&:disabled': {
      color: error.main,
      background: error.light,
      opacity: palette.action?.disabledOpacity,

      '.MuiLoadingButton-loadingIndicator': {
        color: error.main,
      },
    },
    '&:focus&:not(:active)': buttonFocus(error.main),
  },
};

export const ErrorText = {
  props: {
    color: 'error' as any,
    variant: 'text' as any,
  },
  style: {
    background: 'transparent',
    '&:hover': {
      background: error.light,
    },
    '&:active': {
      background: error.hover,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};

export const ErrorOutlined = {
  props: {
    color: 'error' as any,
    variant: 'outlined' as any,
  },
  style: {
    background: 'transparent',
    borderColor: error.main,
    '&:hover': {
      background: error.light,
      borderColor: error.main,
    },
    '&:active': {
      background: error.hover,
      borderColor: error.main,
    },
    '&:disabled': {
      borderColor: error.main,
      background: 'transparent',
    },
  },
};
