import { createSelector } from 'reselect';
import { initialState } from '../reducers/sanctionDetail';

const sanctionDetailState = (state) => state.sanctionDetail || initialState;
const makeSanctionDetailData = () =>
  createSelector(sanctionDetailState, (state) => state.sanctionDetail);

const makeLoadingData = () =>
  createSelector(sanctionDetailState, (state) => state.loading || false);

export { makeSanctionDetailData, makeLoadingData };
