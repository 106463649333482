import filledInputClasses from '@mui/material/FilledInput/filledInputClasses';
import { grey } from '../palette-blocks';

export const Autocomplete = {
  defaultProps: {},
  styleOverrides: {
    root: {
      [`& .${filledInputClasses.root}`]: {
        padding: 0,
        [`& .${filledInputClasses.input}`]: {
          padding: '14px 12px',
        },
      },
      '&.Mui-focused': {
        '.MuiAutocomplete-tag': {
          backgroundColor: grey[50],
          '&:hover': {
            backgroundColor: grey[200],
          },
        },
      },
    },
    tag: {
      '&:hover': { backgroundColor: `${grey[300]} !important` },
    },
  },
};
