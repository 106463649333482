import { Box, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import { LinearProgress } from '@material-ui/core';
import { useGetCaseCommentsQuery } from '../../services/gatewayApi/casesApi';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { MUIDatatableSkeleton } from '../../components';
import CaseCommentFormDialog from './CaseCommentForm';
import MUIDataTable from '../../components/MUIDataTable';
import { useGetAllUsersQuery } from '../../services/gatewayApi/usersApi';

const CaseComments = ({ caseId }) => {
  const { data: users } = useGetAllUsersQuery();
  const { data: comments, isLoading, isFetching } = useGetCaseCommentsQuery(caseId);

  // TODO: Move out and make reusable
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({ caseId });

  const handleEdit = (idx) => {
    setFormData(comments[idx]);
    setFormOpen(true);
  };

  const AddButton = () => (
    <Tooltip title="Add" disableFocusListener>
      <IconButton
        onClick={() => {
          setFormData({ caseId });
          setFormOpen(true);
        }}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
  const EditButton = ({ dataIndex }) => (
    <Tooltip title="Edit" disableFocusListener>
      <IconButton onClick={() => handleEdit(dataIndex)} size="large">
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
  EditButton.propTypes = {
    dataIndex: PropTypes.number,
  };

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: AddButton,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    textLabels: {
      body: {
        noMatch: (
          <Box padding={8}>
            <Typography variant="caption1" align="center" sx={{ color: 'grey.600' }}>
              No Comments
            </Typography>
          </Box>
        ),
      },
    },
  };

  const columns = [
    {
      name: 'comment',
      label: 'Comment',
      options: {
        setCellProps: () => ({ style: { width: '66%' } }),
      },
    },
    {
      name: 'commenterId',
      label: 'User',
      options: {
        customBodyRender: (commenterId) =>
          users && users.entities[commenterId]
            ? users.entities[commenterId].name ?? commenterId
            : 'User not found',
      },
    },
    {
      name: 'createdDate',
      label: 'Created On',
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <EditButton dataIndex={dataIndex} />
          </>
        ),
      },
    },
  ];
  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <MUIDatatableSkeleton size={2} headerHeight={64} />
        ) : (
          <>
            <Box visibility={isFetching ? 'visible' : 'hidden'}>
              <LinearProgress />
            </Box>
            <MUIDataTable
              title={
                <Typography variant="h4" component="div" gutterBottom>
                  Case Comments
                </Typography>
              }
              data={comments}
              columns={columns}
              options={options}
            />
          </>
        )}

        {formOpen && (
          <CaseCommentFormDialog
            open={formOpen}
            onClose={() => setFormOpen(false)}
            isNew={!formData?.commentId || false}
            data={formData}
          />
        )}
      </CardContent>
    </Card>
  );
};

CaseComments.propTypes = {
  caseId: PropTypes.string,
};

export default CaseComments;
