import * as axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getKCHeaders } from './gatewayApi';

class ApiService {
  handleSuccess = (response) => response;

  // eslint-disable-next-line consistent-return
  handleError = (error) => {
    if (error.response) {
      const { data, status } = error.response;

      if (data && data.location) {
        window.location = data.location;
      } else {
        if (status === 500) Sentry.captureException(error);
        let errorMessage = data; // in case response is string

        if (typeof data === 'object') {
          errorMessage = data.message || `${data.error} (${data.status}) - No description...`;
        }
        return Promise.reject(errorMessage);
      }
    }
  };

  constructor() {
    const service = axios.create({
      paramsSerializer(params) {
        const searchParams = new URLSearchParams();
        Object.keys(params).forEach((key) => {
          const param = params[key];
          if (Array.isArray(param)) {
            param.forEach((p) => {
              searchParams.append(key, p);
            });
          } else {
            searchParams.append(key, param);
          }
        });
        return searchParams.toString();
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  getHeaders(path) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    return { ...getKCHeaders(), ...defaultHeaders };
  }

  async get(path, params, headers = this.getHeaders(path)) {
    const options = {
      method: 'GET',
      data: {},
      url: path,
      headers,
      params,
    };
    return this.service(options).then((response) => response.data);
  }

  getCSV(path, params, headers = this.getHeaders(path)) {
    const updatedHeaders = { ...headers, Accept: 'text/csv' };
    const options = {
      method: 'GET',
      data: {},
      url: path,
      headers: updatedHeaders,
      params,
    };
    return this.service(options).then((response) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      a.download = `Download_${new Date().toLocaleString()}.csv`;
      a.click();
      a.remove();
    });
  }

  post(path, data, params, headers = this.getHeaders(path)) {
    const options = {
      method: 'POST',
      data,
      url: path,
      headers,
      params,
    };
    return this.service(options).then((response) => response.data);
  }

  postXLSX(path, data, params, headers = this.getHeaders(path)) {
    const contentTypeXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const updatedHeaders = {
      ...headers,
      Accept: contentTypeXlsx,
      responseType: 'arraybuffer',
    };
    const options = {
      method: 'POST',
      data,
      url: path,
      headers: updatedHeaders,
      params,
    };

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    };

    return this.service(options).then((response) => {
      const [, filename] = response.headers['content-disposition'].split('filename=');
      const a = document.createElement('a');
      const blob = b64toBlob(response.data, contentTypeXlsx);
      a.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeXlsx }));
      a.download = filename;
      a.click();
      a.remove();
    });
  }

  delete(path, headers = this.getHeaders(path)) {
    // Empty data array is a workaround to set content-type header required by backend
    const options = {
      method: 'DELETE',
      url: path,
      headers,
      data: [],
    };
    return this.service(options).then((response) => response.data);
  }

  update(path, data, headers = this.getHeaders(path)) {
    const options = {
      method: 'PUT',
      data,
      url: path,
      headers,
    };
    return this.service(options).then((response) => response.data);
  }
}

export default new ApiService();
