import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import {
  Button,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { RiCheckLine, RiUserLine } from 'react-icons/ri';
import { useGetCaseAssignmentsQuery, useUpdateCaseMutation } from 'services/gatewayApi/casesApi';
import { toastError, toastUpdateSuccess } from 'utils/toast';
import { User } from 'features/UsersManagement/types';
import { Case } from '../types';

interface AssigneeChipProps {
  caseData: Case;
  users: User[];
}

export const AssigneeChip: React.FC<AssigneeChipProps> = ({ caseData, users }) => {
  const { caseId, assigneeId, caseStatus, caseCategory } = caseData;
  const assigneeUser = users.find((u) => u.id === assigneeId);

  const [menuAnhcorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnhcorEl);
  const handleMenuClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleChipClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const [updateCase] = useUpdateCaseMutation();
  const [isSaving, setIsSaving] = useState(false);
  const handleChange = (e, id) => {
    e.stopPropagation();
    handleMenuClose(e);

    // Do not update if value is same as current
    if (id === assigneeId) {
      return;
    }

    setIsSaving(true);

    updateCase({
      caseId,
      data: {
        assigneeId: id,
        caseStatus,
      },
    })
      .unwrap()
      .then(() => {
        toastUpdateSuccess('Assignee');
      })
      .catch((rejected) => console.error(rejected))
      .finally(() => {
        setIsSaving(false);
      });
  };

  // #region Users permission check
  const { permissions } = useSelector(makeUserData());
  const isSupervisor = permissions?.some((p) => ['CASE_EDIT', 'TENANT_ADMIN'].includes(p.name));
  // #endregion

  // #region Users list
  const { data: caseAssignments = [], isLoading } = useGetCaseAssignmentsQuery(undefined, {
    skip: !isMenuOpen,
  });
  const enabledUsers = users?.filter((u) => u.enabled);
  const [limitedUsers, setLimitedUsers] = useState([]);

  useEffect(() => {
    if (
      !isLoading &&
      caseAssignments.length > 0 &&
      enabledUsers.length > 0 &&
      limitedUsers.length === 0
    ) {
      const categoryUsers = caseAssignments.filter(
        (ca) => ca.caseCategory === caseCategory && enabledUsers.some((u) => u.id === ca.userId),
      );

      setLimitedUsers(enabledUsers.filter((u) => categoryUsers.some((cu) => cu.userId === u.id)));
    }
  }, [caseAssignments, isLoading, enabledUsers]);

  const usersKeyValue = limitedUsers.map((u) => ({
    value: u.id,
    label: u.name,
  }));
  // #endregion

  return (
    <>
      <Button
        size="medium"
        sx={{ whiteSpace: 'nowrap', minWidth: 'auto', maxWidth: '100%' }}
        variant="contained"
        color="base"
        onClick={(e) => {
          e.stopPropagation();
          if (!isSaving) {
            if (isSupervisor) {
              handleChipClick(e);
            } else {
              toastError(`You don't have permission to update Case Assignee`);
            }
          }
        }}
        startIcon={
          isSaving ? <CircularProgress color="inherit" size={14} /> : <RiUserLine size="14px" />
        }
      >
        <Typography
          variant="body"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {assigneeId && assigneeUser ? assigneeUser.name : 'no asignee'}
        </Typography>
      </Button>

      <Menu id="AssigneeMenu" anchorEl={menuAnhcorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {isLoading && <MenuItem>Loading options...</MenuItem>}

        {!isLoading &&
          usersKeyValue &&
          usersKeyValue.map((item, idx) => (
            <MenuItem key={idx} onClick={(e) => handleChange(e, item.value)}>
              <ListItemIcon>
                {item.value === assigneeId && <RiCheckLine size="16px" />}
              </ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
