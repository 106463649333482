import React from 'react';
import { Skeleton } from '@mui/material';

export const NoteSkeleton = (): JSX.Element => (
  <Skeleton
    variant="rectangular"
    sx={{
      height: '115px',
      width: '100%',
      mt: 4,
      borderRadius: '18px',
    }}
  />
);
