import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/oauth/',
    prepareHeaders: (headers) => {
      headers.set('authorization', 'Basic aGF5c3RhY2stdWk6biFzYW4xMDFIQUpL');
      headers.set('content-type', 'application/x-www-form-urlencoded');
      return headers;
    },
  }),
  endpoints: (build) => ({
    getToken: build.query({
      query: () => ({
        url: 'token?grant_type=client_credentials',
        method: 'POST',
      }),
    }),
  }),
});
