/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddMerchantMutation,
  useUpdateMerchantMutation,
} from '../../services/gatewayApi/merchantsApi';
import { makeSelectedProductIdData } from '../../selectors/user';

const MerchantFormDialog = ({ open, onClose, isNew, data }) => {
  const validationSchema = yup.object({
    merchantName: yup
      .string()
      .required('Required')
      .matches(
        /^[^- ][A-Za-z0-9- ]*[^- ]$/,
        'Only letters, dashes and spaces in the middle are allowed',
      )
      .min(3, 'Minimum length is ${min}')
      .max(128, 'Maximum length is ${max}'),
    mid: yup
      .string()
      .required('Required')
      .matches(/^[0-9]*$/, 'Only numbers are allowed'),
  });

  const [createMerchant] = useAddMerchantMutation();
  const [updateMerchant] = useUpdateMerchantMutation();

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const model = {
        ...values,
        productId: selectedProductId,
      };
      const mutationPromise = isNew ? await createMerchant(model) : await updateMerchant(model);
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        onClose();
      }
    },
  });

  const handleExit = () => {
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle id="form-dialog-title">
        {isNew ? 'Add' : 'Edit'} Merchant
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box width={500} my={2}>
          <form id="riskWordForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  id="merchantName"
                  name="merchantName"
                  label="Merchant Name"
                  fullWidth
                  size="small"
                  autoFocus
                  value={formik.values.merchantName}
                  onChange={formik.handleChange}
                  error={formik.touched.merchantName && Boolean(formik.errors.merchantName)}
                  helperText={formik.touched.merchantName && formik.errors.merchantName}
                  variant="outlined"
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mid"
                  name="mid"
                  label="MID"
                  fullWidth
                  size="small"
                  value={formik.values.mid}
                  onChange={formik.handleChange}
                  error={formik.touched.mid && Boolean(formik.errors.mid)}
                  helperText={formik.touched.mid && formik.errors.mid}
                  variant="outlined"
                  inputProps={{ maxLength: 8 }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="riskWordForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

MerchantFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isNew: PropTypes.bool,
  data: PropTypes.shape({
    merchantName: PropTypes.string,
    mid: PropTypes.string,
  }),
};

export default MerchantFormDialog;
