import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { BreadCrumbs } from './BreadCrumbs';
import { BreadCrumbsLink, BreadCrumbsPagination } from './BreadCrumbs.props';
import { pathTitles } from './constants';
import { siblingsMainMenu } from '../AppHeader/AppMenuContent/appMenuData';
import { updateApplicationsSiblings, updateLinks, updateLinkSiblings } from './breadcrumbsSlice';
import { useGetHaystackClientQuery } from 'services/gatewayApi/haystackClientApi';
import { useGetApplicationQuery, useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';

import { applicationKinds } from 'features/ApplicationManagement/ClientDetails/Tabs/ApplicationsTab/constants';
import { StateType } from 'features/Rules/types';
import { capitalizeExcept } from 'utils/capitalize';

export const BreadCrumbsAdapter: React.FC = () => {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const { clientId, applicationId } = useParams();
  const location = useLocation();

  const breadcrumbsState = useSelector((state: StateType) => state.breadcrumbsState);

  const [pathnames, setPathnames] = useState([]);

  useEffect(() => {
    const actualPath = location.pathname.split('/').filter((el) => el !== '');
    setPathnames(actualPath);
  }, [location.pathname]);

  const { data: client } = useGetHaystackClientQuery(clientId, { skip: !clientId });

  const { data: applicationDetails } = useGetApplicationQuery(applicationId, { skip: !applicationId });

  const clientName = client ? client.companyName : undefined;
  const [applicationTitle, setApplicationTitle] = useState<string | undefined>(applicationId);
  useEffect(() => {
    if (applicationDetails) {
      const applicationKind = applicationDetails ? applicationDetails.kind : undefined;
      const applicationTitle = `${applicationKinds[applicationKind].replace(' Application', '')} (${applicationDetails?.categoryId === 5 ? 'Malta' : 'UK'})`;
      setApplicationTitle(applicationTitle);
    }
  }, [applicationDetails]);

  const initialPage = { page: 0, size: 8 };
  const [paginationRequest, setPaginationRequest] = useState(initialPage);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching

  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const searchHandler = (e: string) => {
    setSkipRequest(true);
    setSearchQuery(e);
    debounceQuery();
  };

  const { data: applications, isLoading: isLoadingApplications } = useGetApplicationsQuery(
    {
      page: paginationRequest.page,
      size: paginationRequest.size,
      kind: 'ONBOARDING',
      ...searchObj(),
    },
    {
      skip: skipRequest,
    },
  );

  const handleSetPage = (direction: 'prev' | 'next' | 'initial') => {
    if (direction === 'initial') {
      setPaginationRequest(initialPage);
    } else if (direction === 'prev') {
      setPaginationRequest((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setPaginationRequest((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  useEffect(() => {
    if (
      !isLoadingApplications &&
      applications?.content &&
      pathnames[0] === 'application-management'
    ) {
      const list = applications?.content || [];
      const appSiblings = list.map((el) => ({
        title: el.companyName,
        to: `/application-management/${el.haystackClientId}`,
      }));
      const pagination: BreadCrumbsPagination = {
        page: applications?.number,
        totalPages: applications?.totalPages,
      };
      dispatch(updateApplicationsSiblings({ appSiblings, pagination }));
    } else if (pathnames[0] === 'pep-and-sanctions') {
      dispatch(
        updateLinkSiblings({
          siblings: [
            { title: 'Applications', to: '/pep-and-sanctions/applications' },
            { title: 'Transactions', to: '/pep-and-sanctions/transactions' },
          ],
          linkIndex: 1,
        }),
      );
    } else {
      dispatch(
        updateApplicationsSiblings({
          appSiblings: !applicationId ? undefined : [{ title: 'no search result..' }],
          pagination: undefined,
        }),
      );
    }
  }, [applications, pathnames[0]]);

  const countSlashes = (str) =>
    (str.match(/\//g) || []).length;

  const getTitle = (fullPathName, pathNameOrValue) => {
    let modifiedPathName = fullPathName;
    let urlParamName = '';

    // Replace id values with url param names (using same naming as in routes.tsx)
    ['id', 'clientId', 'sectionId', 'sectionUrl', 'searchSessionId', 'partition', 'applicationId'].forEach((paramName) => {
      if (urlParams[paramName] === pathNameOrValue) {
        urlParamName = paramName;
      }
      if (urlParams[paramName] != 'new') {
        const regex = new RegExp(`/${urlParams[paramName]}(?=\\/|$)`);
        modifiedPathName = modifiedPathName.replace(regex, `/:${paramName}`);
      }
    });

    let title = pathTitles.find((el) =>
      el.value === modifiedPathName
      && countSlashes(el.value) === countSlashes(modifiedPathName)
    )?.label || capitalizeExcept(pathNameOrValue.replace(/-/g, ' '));

    switch (urlParamName) {
      case 'clientId':
        return clientName;

      case 'applicationId':
        return applicationTitle;

      default:
        return title;
    }
  };

  const getLinks = (): BreadCrumbsLink[] => {
    const result = [];

    pathnames.forEach((pathName, index) => {
      const slicedPathnames = pathnames.slice(0, index + 1);

      let link = `/${slicedPathnames.join('/')}`;

      const title = getTitle(link.substring(1), pathName);

      if (title !== 'hide') {
        const linkObject: BreadCrumbsLink = {
          to: link,
          title,
        };

        if (index === 0 && siblingsMainMenu) {
          linkObject.siblings = siblingsMainMenu;
        }
        if (breadcrumbsState.links[index]?.siblings?.length > 0) {
          linkObject.siblings = breadcrumbsState.links[index]?.siblings;
        }

        result.push(linkObject);
      }
    });

    return result;
  };

  useEffect(() => {
    const generatedLinks = getLinks();
    if (generatedLinks.length > 0) {
      dispatch(updateLinks(generatedLinks));
    }
  }, [pathnames, clientName, applicationTitle]);

  return (
    <BreadCrumbs
      handleSetPage={handleSetPage}
      searchHandler={searchHandler}
      searchQuery={searchQuery}
    />
  );
};
