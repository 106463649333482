import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import { setFieldValue } from '../rulesSlice';
import { RuleSortable } from '../types';
import { RuleRow } from './RuleRow';
import './node-rerender.scss';

interface NodeRendererProps {
  node: any;
  title?: React.ReactNode | ((node: any) => React.ReactNode);
  subtitle?: React.ReactNode | ((node: any) => React.ReactNode);
  path: (string | number)[];
  treeIndex: number;
  treeId: string;
  isSearchMatch?: boolean;
  isSearchFocus?: boolean;
  canDrag?: boolean;
  scaffoldBlockPxWidth: number;
  toggleChildrenVisibility?: ({
    node,
    path,
    treeIndex,
  }: {
    node: any;
    path: (string | number)[];
    treeIndex: number;
  }) => void;
  buttons?: React.ReactNode[];
  className?: string;
  style?: React.CSSProperties;
  connectDragPreview: any;
  connectDragSource: any;
  parentNode?: any; // Needed for dndManager
  isDragging: boolean;
  didDrop: boolean;
  draggedNode?: any;
  isOver: boolean;
  canDrop?: boolean;
  rowDirection?: 'ltr' | 'rtl';
  isLocked?: boolean;
  allowAnimate?: boolean;
  setIsShowInactive?: React.Dispatch<React.SetStateAction<boolean>>;
  currentStateRule?: RuleSortable;
  savedRule?: RuleSortable;
}

export const NodeRenderer: React.FC<NodeRendererProps> = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  title,
  subtitle,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style,
  didDrop,
  treeId,
  isOver, // Not needed, but preserved for other renderers
  parentNode, // Needed for dndManager
  rowDirection = 'ltr',
  isLocked,
  allowAnimate,
  setIsShowInactive,
  currentStateRule,
  savedRule,
}) => {
  const dispatch = useDispatch();

  const getUnfilteredNode = (ruleSet: RuleSortable[], restPath: string[]): RuleSortable => {
    const result = ruleSet.find((el) => el.id === restPath[0]);
    return restPath.length === 1 ? result : getUnfilteredNode(result.children, restPath.slice(1));
  };

  const handleToggleExpand = () => {
    const unfilteredChildren = currentStateRule?.children || [];
    dispatch(
      setFieldValue({
        ruleId: node.id,
        setFields: {
          expanded: !node.expanded,
          children: unfilteredChildren,
        },
      }),
    );
  };

  return (
    <div
      className="topRowWrapper"
      style={{
        height: '100%',
        ...style,
      }}
    >
      {toggleChildrenVisibility &&
        node.children &&
        (node.children.length > 0 || typeof node.children === 'function') && (
          <div>
            <IconButton
              type="button"
              sx={{ p: 0 }}
              aria-label={node.expanded ? 'expand' : 'collapse'}
              onClick={handleToggleExpand}
              className={node.expanded ? 'mui__expandButton' : 'mui__collapseButton'}
              style={{ left: -0.5 * scaffoldBlockPxWidth }}
            >
              {node.expanded ? (
                <RemoveCircleOutlineIcon style={{ fontSize: '20px' }} />
              ) : (
                <AddCircleOutlineIcon style={{ fontSize: '20px' }} />
              )}
            </IconButton>
            {node.expanded && !isDragging && <div className="rst__lineChildren" />}
          </div>
        )}

      {connectDragPreview(
        <div>
          <RuleRow
            rule={node}
            savedRule={savedRule}
            isLocked={isLocked}
            isDragging={isDragging}
            didDrop={didDrop}
            allowAnimate={allowAnimate}
            connectDragSource={connectDragSource}
            canDrag={canDrag}
            nestedLevel={path?.length}
            setIsShowInactive={setIsShowInactive}
            isSearchMatch={isSearchMatch}
            isSearchFocus={isSearchFocus}
          />
        </div>,
      )}
    </div>
  );
};
