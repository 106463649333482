import { ISelectOption } from 'uikit';

export const documentTypes: ISelectOption[] = [
  {
    value: 'DRIVING_LICENSE',
    label: "Driver's License",
  },
  {
    value: 'PASSPORT',
    label: 'Passport',
  },
  {
    value: 'ID_CARD',
    label: 'ID Card',
  },
];
