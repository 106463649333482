import { createSelector } from 'reselect';
import { initialState } from '../reducers/ruleSetStats';

const ruleSetStatsSelector = (state) => state.ruleSetStats || initialState;

const makeRuleSetStatsData = () =>
  createSelector(ruleSetStatsSelector, (ruleSetStatsState) => ruleSetStatsState);

const makeRuleSetStatsDateRangeData = () =>
  createSelector(ruleSetStatsSelector, (ruleSetStatsState) => ruleSetStatsState.dateRange);

export { makeRuleSetStatsData, makeRuleSetStatsDateRangeData };
