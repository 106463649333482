import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatIsoDate } from 'utils/formatter';
import { BodyRow, BodyTableCell } from 'uikit';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/constants';
import { ApplicationStatus } from 'features/ApplicationManagement/types';
import { getApplicationStatusOptions, getDecisionIcon } from '../../../../../constants';
import { DecisionHystory } from './types';

interface HistoryRowProps {
  date: string; // "2022-07-04T10:51:09.920Z"
  name: string;
  status: ApplicationStatus;
  prevStatus?: ApplicationStatus;
  decision?: DecisionHystory;
  reason?: string;
}

export const HistoryRowOfApplication: React.FC<HistoryRowProps> = ({
  date,
  name,
  status,
  prevStatus,
  decision,
  reason,
}) => {
  const applicationStatusOptions = getApplicationStatusOptions();

  const getLabel = (value) => applicationStatusOptions.find((el) => el.value === value)?.label;

  return (
    <BodyRow disablehover>
      <BodyTableCell colSpan={2}>
        <Typography color="grey.600" variant="body2" component="span">
          {formatIsoDate(date, DATE_FORMAT)}
        </Typography>
        <Typography color="grey.500" variant="body2" component="span" sx={{ pl: 4 }}>
          {formatIsoDate(date, TIME_FORMAT)}
        </Typography>
        <Typography variant="body1" color="grey.600" sx={{ pt: 2 }}>{`by ${name}`}</Typography>
      </BodyTableCell>
      <BodyTableCell>
        <Stack direction="row" spacing={4} alignItems="center" sx={{ height: '32px' }}>
          {prevStatus && (
            <Typography variant="body1" color="grey.600" component="span">
              {getLabel(prevStatus)}
            </Typography>
          )}
          {prevStatus && <ArrowForwardIcon sx={{ color: 'grey.600', fontSize: '14px' }} />}
          <Typography variant="body1" color="grey.900" component="span">
            {getLabel(status)}
          </Typography>
        </Stack>
        {(!!decision || !!reason) && (
          <Box sx={{ width: '100%', borderRadius: '12px', backgroundColor: 'grey.50', p: 2 }}>
            <Stack direction="row" spacing={4} alignItems="center">
              {getDecisionIcon(decision)}
              <Typography variant="body2" color="grey.900" component="span" sx={{ m: 2 }}>
                {decision === 'APPROVE' ? 'Approved' : reason}
              </Typography>
            </Stack>
          </Box>
        )}
      </BodyTableCell>
    </BodyRow>
  );
};
