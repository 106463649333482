import PropTypes from 'prop-types';
import React from 'react';
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { DataTableRow } from '../../../components';
import { deleteCaseAssignment } from '../../../actions/caseAssignments';

export class CaseAssignmentsListItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteDialog: false,
    };
  }

  confirmDelete() {
    const { item } = this.props;
    this.props.deleteAssignment(item.caseAssignmentId);
    this.setState({
      showDeleteDialog: false,
    });
  }

  handleDelete(e) {
    e.stopPropagation();
    this.setState({
      showDeleteDialog: true,
    });
  }

  render() {
    const { item, products, users } = this.props;
    const tenant = _.last(_.filter(products, { id: item.tenantId }));
    const user = _.last(_.filter(users, { id: item.userId }));

    return (
      <tbody>
        <DataTableRow>
          <td>{tenant ? tenant.name : ''}</td>
          <td>{user ? user.name : ''}</td>
          <td>{item.caseCategory}</td>
          <td>
            <a
              className="text-danger"
              onClick={(e) => {
                this.handleDelete(e);
              }}
              title="Delete"
            >
              <i className="fa fa-trash-alt" />
            </a>
            <SweetAlert
              show={this.state.showDeleteDialog}
              title="Deleting Case Assignment"
              text="Are you sure you want to permanently delete the selected Case Assignment?"
              type="warning"
              showCancelButton
              confirmButtonColor="#DD6B55"
              confirmButtonText="Yes"
              cancelButtonText="No"
              onConfirm={() => this.confirmDelete()}
              onCancel={() => this.setState({ showDeleteDialog: false })}
            />
          </td>
        </DataTableRow>
      </tbody>
    );
  }
}

CaseAssignmentsListItem.propTypes = {
  item: PropTypes.object,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  deleteAssignment: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  deleteAssignment: (id) => dispatch(deleteCaseAssignment(id)),
});

export default connect(null, mapDispatchToProps)(CaseAssignmentsListItem);
