import { gatewayApi } from '../gatewayApi';
import { TemplateField } from '../../features/ApplicationManagement/types/forms';

const formTemplateFieldsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getFormTemplateFields: build.query<TemplateField[], undefined>({
      query: () => ({
        url: `form-template-fields`,
        method: 'GET',
      }),
      providesTags: [{ type: 'FormTemplateFields', id: 'LIST' }],
    }),
  }),
});

export const { useGetFormTemplateFieldsQuery } = formTemplateFieldsApi;
