import { ValueLabel } from 'types';

// this file only defines 
export const pathTitles: ValueLabel[] = [
  { value: 'application-management', label: 'Application Management' },
  { value: 'application-management/new', label: 'Create Application' },
  { value: 'application-management/:clientId/overview', label: 'hide' },
  { value: 'application-management/:clientId/summary', label: 'hide' },
  { value: 'application-management/:clientId/customer-users', label: 'hide' },
  { value: 'application-management/:clientId/openpayd-users', label: 'hide' },
  { value: 'application-management/:clientId/associated-individuals', label: 'hide' },
  { value: 'application-management/:clientId/shareholders', label: 'hide' },

  { value: 'application-management/:clientId/applications', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/id-verify', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/risk-scoring', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/website-checklist', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/forms', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/forms/:sectionId', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/forms/:sectionId/:partition', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/app/forms/:sectionId/:partition/:sectionUrl', label: 'hide' },

  { value: 'application-management/:clientId/applications/:applicationId/docs', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/docs/documents', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/docs/internal-documents', label: 'hide' },

  { value: 'application-management/:clientId/applications/:applicationId/history', label: 'hide' },
  { value: 'application-management/:clientId/applications/:applicationId/mail', label: 'hide' },

  { value: 'pep-and-sanctions', label: 'PEP and Sanctions' },
  { value: 'pep-and-sanctions/applications', label: 'Applications' },
  { value: 'pep-and-sanctions/applications/:searchSessionId', label: 'hide' },
  { value: 'pep-and-sanctions/transactions', label: 'Transactions' },
  { value: 'pep-and-sanctions/transactions/:searchSessionId', label: 'hide' },

  { value: 'user-management/new', label: 'Create User' },
  { value: 'user-management/:id', label: 'Edit User' },

  { value: 'applicants-kyc', label: 'KYC Applicants' },

  { value: 'providers-kyc', label: 'KYC Providers' },

  { value: 'ubo-management', label: 'Associated Individuals' },

  { value: 'rules', label: 'Rules' },
  { value: 'rules/new', label: 'Create Rule' },
  { value: 'rules/:id', label: 'Edit Rule' },

  { value: 'rulesNew', label: 'Rules' },

  { value: 'rule-functions', label: 'Rule Functions' },

  { value: 'rule-vetos-monitor', label: 'Rule Vetos Monitor' },

  { value: 'sc', label: 'Showcase' },
];
