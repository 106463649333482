import React from 'react';
import { Typography } from '@mui/material';
import { SectionTitleProps } from './SectionTitle.props';

import './SectionTitle.scss';

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title = 'No title',
  isActive = false,
  disabled = false,
  onClick,
}) => {
  const handleOnClick = () => {
    if (!disabled && !isActive && onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`
        SectionTitle
        SectionTitle--${isActive ? 'active' : 'inactive'}
        ${disabled ? 'SectionTitle--disabled' : ''}
      `}
      onClick={handleOnClick}
    >
      <Typography variant="body1">{title}</Typography>
    </div>
  );
};
