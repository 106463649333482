import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import MUIDataTable from '../../components/MUIDataTable';
import { initialFormData } from './constants';
import { makeSelectedProductIdData } from '../../selectors/user';
import {
  useDeleteMerchantCategoryMutation,
  useGetMerchantCategoriesQuery,
} from '../../services/gatewayApi/merchantCategoriesApi';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { ConfirmationDialog } from '../../components';
import MerchantCategoryFormDialog from './MerchantCategoryFormDialog';

const MerchantCategoriesList = () => {
  const [page, setPage] = useState(0);
  const [merchantCategories, setMerchantCategories] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const [sortOrder, setSortOrder] = useState({
    name: 'categoryName',
    direction: 'asc',
  });

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const { data, isLoading, isFetching } = useGetMerchantCategoriesQuery(
    {
      productId: selectedProductId,
      page,
      size: pageSize,
      sort: `${sortOrder.name},${sortOrder.direction}`,
    },
    {
      skip: !selectedProductId,
    },
  );

  useEffect(() => {
    if (data) {
      setMerchantCategories(data.results);
      setCount(data.count);

      if (Math.floor((data.count - 1) / pageSize) < page) {
        resetGridPage(); // in case page is not available (after delete)
      }
    }
  }, [data, page, pageSize]);

  const resetGridPage = (skipSetPage = false) => {
    if (!skipSetPage) {
      setPage(0); // move to first page
    }
  };

  const [deleteMerchantCategory] = useDeleteMerchantCategoryMutation();
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [idToDelete, setIdToDelete] = useState('');

  const AddButton = () => (
    <Tooltip title="Add Merchant Category" disableFocusListener>
      <IconButton
        onClick={() => {
          setFormData(initialFormData);
          setFormOpen(true);
        }}
        size="large"
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const handleEdit = (id) => {
    setFormData(merchantCategories.find((item) => item.id === id));
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    setIdToDelete(id);
  };

  const confirmDelete = () => {
    deleteMerchantCategory(idToDelete);
    setIdToDelete('');
  };

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    onTableChange: (action, state) => {
      if (action === 'sort') {
        resetGridPage();
        setSortOrder(state.sortOrder);
      }

      if (action === 'changePage') {
        resetGridPage(true);
        setPage(state.page);
      }

      if (action === 'changeRowsPerPage') {
        resetGridPage();
        setPageSize(state.rowsPerPage);
      }
    },
    sortOrder,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isLoading || isFetching ? <LinearProgress /> : 'No matching records found...',
      },
    },
    serverSide: true,
    count,
    page,
    customToolbar: AddButton,
  };

  const columns = [
    {
      name: 'categoryName',
      label: 'Category Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => (
          <>
            <Tooltip title="Edit" disableFocusListener>
              <IconButton aria-label="edit" onClick={() => handleEdit(value)} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" disableFocusListener>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDelete(value);
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        <div style={{ position: 'relative' }}>
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MUIDataTable data={merchantCategories} columns={columns} options={options} />
        </div>

        <ConfirmationDialog
          open={!!idToDelete}
          onClose={() => setIdToDelete('')}
          onCancel={() => setIdToDelete('')}
          onConfirm={confirmDelete}
          title="Deleting Merchant Category"
          text="Are you sure you want to permanently delete selected Merchant Category?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          confirmButtonColor="danger"
        />

        <MerchantCategoryFormDialog
          open={formOpen}
          onClose={() => setFormOpen(false)}
          isNew={!formData?.id || false}
          data={formData}
        />
      </div>
    </div>
  );
};

export default MerchantCategoriesList;
