/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  GET_CARD_DATA,
  GET_CARD_DATA_SUCCESS,
  GET_CARD_DATA_FAIL,
} from '../../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  cardData: [],
  paginationInfo: null,
};

const cardDataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_CARD_DATA:
        draft.loading = true;
        draft.error = false;
        break;
      case GET_CARD_DATA_SUCCESS:
        draft.cardData = action.cardData.payload;
        draft.paginationInfo = action.cardData.paginationInfo;
        draft.loading = false;
        break;
      case GET_CARD_DATA_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default cardDataReducer;
