import { baseTheme } from '../baseTheme';

export const InputLabel = {
  defaultProps: {},
  styleOverrides: {},

  variants: [
    {
      props: { variant: 'filled' },
      style: {
        transform: 'none',
        position: 'relative',
        whiteSpace: 'normal',
        paddingBottom: 8,
        color: baseTheme.palette.grey[900],
        fontSize: '14px',
        'p > span': {
          fontSize: '14px',
        },
        lineHeight: '20px',
        '&.Mui-focused': {
          color: baseTheme.palette.grey[700],
        },
        '&.Mui-error': {
          color: baseTheme.palette.grey[700],
        },
      },
    },
  ],
};
