import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import toastr from 'toastr';
import { DataTable } from '../../components';
import { makeRuleSetStatsData } from '../../selectors/ruleSetStats';
import Ruleset from './RuleSet';

class RuleSetList extends React.Component {
  renderChildren() {
    const { loading, rules, ruleSetStats } = this.props;

    if (loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="7">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }
    if (!rules || rules.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="7">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    if (ruleSetStats.error) {
      toastr.error(ruleSetStats.error);
    }

    return rules.map((item, index) => {
      const statsElement = ruleSetStats.data && ruleSetStats.data.find((i) => i.id === item.id);
      let stats = [];

      if (statsElement) {
        stats = statsElement.data;
      }

      return (
        <Ruleset
          key={index}
          item={item}
          stats={stats}
          statsLoading={this.props.ruleSetStats.loading}
        />
      );
    });
  }

  render() {
    return (
      <DataTable>
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Description</th>
            <th>Active</th>
            <th>Status</th>
            <th>Hit Stats</th>
            <th className="td-small" />
            {/* <th> */}
            {/*  edit <br /> (new builder) */}
            {/* </th> */}
            <th className="td-small" />
          </tr>
        </thead>
        {this.renderChildren()}
      </DataTable>
    );
  }
}

RuleSetList.propTypes = {
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  ruleSetStats: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  ruleSetStats: makeRuleSetStatsData(),
});

export default connect(mapStateToProps)(RuleSetList);
