import React, { useCallback } from 'react';
import { FileRejection, useDropzone as useReactDropzone } from 'react-dropzone';
import { toastError } from '../../utils';

const MAXIMUM_UPLOAD_FILE_SIZE = 700; // MB

interface DropzoneProps {
  acceptedFiles?: Array<File>;
  setShowDropzone?: React.Dispatch<React.SetStateAction<boolean>>;
  upload: (data: FormData) => any;
}

export const useDropzone = ({ setShowDropzone, upload }: DropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      if (setShowDropzone) {
        setShowDropzone(false);
      }

      if (acceptedFiles.length > 0) {
        acceptedFiles.forEach((f) => {
          const formData = new FormData();
          formData.append('file', f);
          upload(formData);
        });
      }
    },
    [setShowDropzone, upload],
  );
  const onDropRejected = useCallback((fileRejections: Array<FileRejection>) => {
    if (setShowDropzone) {
      setShowDropzone(false);
    }

    fileRejections.forEach((r) => {
      r.errors.forEach(({ message }) => {
        console.error(message);
      });
      let errorMessage = r.errors[0]?.message;
      if (errorMessage.includes('File is larger than')) {
        errorMessage = `File is larger than ${MAXIMUM_UPLOAD_FILE_SIZE} Mb`;
      }
      const errorFileName = r.file?.name;
      toastError(`File "${errorFileName}" download error - ${errorMessage}`);
    });
  }, []);
  const onFileDialogCancel = useCallback(() => {
    if (setShowDropzone) {
      setShowDropzone(false);
    }
  }, []);
  const onDragLeave = (event: React.DragEvent<HTMLElement>) => {
    if (setShowDropzone) {
      setShowDropzone(false);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useReactDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: false,
    minSize: 1, // at least non-empty
    maxSize: MAXIMUM_UPLOAD_FILE_SIZE * 1000 * 1000, // 700MB
    onDrop,
    onDropRejected,
    onDragLeave,
    onFileDialogCancel,
  });

  return { acceptedFiles, getRootProps, getInputProps, open };
};
