import { RuleVetoType, StatusVeto } from '../Rules/types';
import { ProductName } from '../exceptionRules/constants';
import { BadgeType } from '../../uikit/Badge/Badge';

type BadgeTheme = {
  [key in StatusVeto]: BadgeType;
};

export const badgeTheme: BadgeTheme = {
  active: 'success',
  expired: 'danger',
  expects: 'warning',
};

interface VetoTypeLabel {
  apiName: ProductName;
  value: RuleVetoType;
  label: string;
}

export const vetoTypes: VetoTypeLabel[] = [
  {
    apiName: 'GATEWAY',
    value: 'CUSTOMER_EMAIL',
    label: 'Customer email',
  },
  {
    apiName: 'GATEWAY',
    value: 'CUSTOMER_ID',
    label: 'Customer ID',
  },
  {
    apiName: 'GATEWAY',
    value: 'DEVICE_ID',
    label: 'Device ID',
  },
  {
    apiName: 'GATEWAY',
    value: 'IP_ADDRESS',
    label: 'IP address',
  },
  {
    apiName: 'GATEWAY',
    value: 'MERCHANT_ID',
    label: 'Merchant ID',
  },
  {
    apiName: 'GATEWAY',
    value: 'PHONE_NUMBER',
    label: 'Phone number',
  },
  {
    apiName: 'BANK',
    value: 'SENDER_IBAN',
    label: 'Sender IBAN',
  },
  {
    apiName: 'BANK',
    value: 'BENEFICIARY_IBAN',
    label: 'Beneficiary IBAN',
  },
  {
    apiName: 'BANK',
    value: 'SENDER_BENEFICIARY_IBAN',
    label: 'Sender & Beneficiary IBAN',
  },
  {
    apiName: 'BANK',
    value: 'SENDER_BIC',
    label: 'Sender BIC',
  },
  {
    apiName: 'BANK',
    value: 'BENEFICIARY_BIC',
    label: 'Beneficiary BIC',
  },
  {
    apiName: 'BANK',
    value: 'SENDER_BENEFICIARY_BIC',
    label: 'Sender & Beneficiary BIC',
  },
  {
    apiName: 'BANK',
    value: 'CLIENT_ID',
    label: 'Client ID',
  },
];
