// This list must be received from the server.

import { PEPSANCTIONS_MANAGEMENT, UBO_MANAGEMENT } from 'constants/constants';

export interface AvailableService {
  link: string;
  title: string;
  order: number;
  forProducts?: string[];
  permissions?: string[];
  detailedButton?: boolean;
}

export const formTypes = {
  FORMS: {
    title: 'Forms',
    isInternal: false,
    showAddOptionalForms: true,
  },
  INTERNAL_FORMS: {
    title: 'Internal Forms',
    isInternal: true,
    showAddOptionalForms: false,
  },
};

export const verificationAndChecks: AvailableService[] = [
  {
    link: 'id-verify',
    title: 'ID Verification',
    order: 1,
  },
  {
    link: 'pep-and-sanctions',
    title: 'PEP and Sanctions',
    order: 2,
    permissions: PEPSANCTIONS_MANAGEMENT,
  },
];

export const documents: AvailableService[] = [
  {
    link: 'documents',
    title: 'Documents',
    order: 1,
    detailedButton: true,
  },
  {
    link: 'internal-documents',
    title: 'Internal Documents',
    order: 2,
    detailedButton: true,
  },
];

export const forms: AvailableService[] = [
  // {
  //  link: '/creditsafe-search',
  //  title: 'Creditsafe search',
  //  order: 5,
  // },
];

export const internalForms: AvailableService[] = [
  {
    link: 'risk-scoring',
    title: 'Risk Scoring',
    order: 1,
    detailedButton: true,
    forProducts: ['EMBANK', 'OPENPAYD'],
  },
  {
    link: 'website-checklist',
    title: 'Website Checklist',
    order: 2,
    detailedButton: true,
    forProducts: ['OPENPAYD'],
  },
];

export const availableSections = [
  {
    title: 'Documents',
    data: documents,
  },
  {
    title: formTypes.FORMS.title,
    data: forms,
  },
  {
    title: formTypes.INTERNAL_FORMS.title,
    data: internalForms,
  },
];
