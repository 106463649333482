import { gatewayApi } from '../gatewayApi';

const kycProvidersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getKycChecksByApplicationId: build.query({
      query: ({ applicationId, hasExtUrl }) => ({
        url: `kyc/checks?applicationId=${applicationId}&hasExtUrl=${hasExtUrl}`,
      }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'KycChecksForApplication', id: applicationId },
      ],
    }),

    getKycChecksByApplicantId: build.query({
      query: (applicantId) => ({
        url: `kyc/checks?applicantId=${applicantId}`,
      }),
      providesTags: (result, error, applicantId) => [
        { type: 'KycChecksForApplication', id: applicantId },
      ],
    }),

    addKycCheck: build.mutation({
      query: (body) => ({
        url: `kyc/checks`,
        method: 'POST',
        body,
      }),
    }),

    addKycExternalChecks: build.mutation({
      query: ({ applicantIds: body }) => ({
        url: `kyc/checks/external-check/bulk`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'KycChecksForApplication', id: applicationId },
      ],
    }),

    deleteKycCheck: build.mutation({
      query: ({ id }) => ({
        url: `kyc/checks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'KycChecksForApplication', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetKycChecksByApplicationIdQuery,
  useGetKycChecksByApplicantIdQuery,
  useAddKycCheckMutation,
  useAddKycExternalChecksMutation,
  useDeleteKycCheckMutation,
} = kycProvidersApi;
