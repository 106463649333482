import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';

const realm = 'realm_placeholder';
const url = '/_login';

const authority = `${url}/realms/${realm}`;
const clientId = 'public-client';

const userConfig: UserManagerSettings = {
  authority,
  client_id: clientId,
  // shouldn't contain query string
  redirect_uri: `${window.location.origin + window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
  accessTokenExpiringNotificationTimeInSeconds: 300,
  automaticSilentRenew: process.env.NODE_ENV === 'development',
  silentRequestTimeoutInSeconds: 30,
};

export const userManager = new UserManager(userConfig);
export const oidcConfig = {
  userManager,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
export const getUser = () => {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${authority}:${clientId}`);
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage) || null;
};
