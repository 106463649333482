import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import messages from './messages';

export default function NotFound() {
  return (
    <div className="page-content rules-page">
      <Helmet title="Not Found" />
      <div className="content container">
        <div className="page-title">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h3>
                  <FormattedMessage {...messages.header} />
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
