import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiDeleteBinFill, RiPencilLine } from 'react-icons/ri';
import { ModalDialog } from 'uikit';
import { useDeleteAcquirerMutation } from 'services/gatewayApi/acquirersApi';
import { AcquirerDialog } from '../AcquirerDialog';

export const DetailsTitle = ({ acquirer, onClose }) => {
  const [deleteAcquirer] = useDeleteAcquirerMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteAcquirer({ id: acquirer.id });
    setOpenConfirmDelete(false);
    onClose();
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pt: 5, pb: 2 }}
      >
        <Typography variant="h3" sx={{ mb: 0 }}>
          {acquirer.acquirerName}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button
            color="base"
            onClick={handleEdit}
            variant="contained"
            startIcon={<RiPencilLine size="14px" />}
          >
            Edit
          </Button>
          <Button
            color="error"
            onClick={handleConfirmDelete}
            variant="contained"
            startIcon={<RiDeleteBinFill size="14px" />}
          >
            Delete
          </Button>
        </Stack>
      </Stack>

      {dialogOpen && (
        <AcquirerDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          acquirer={acquirer}
        />
      )}

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete Acquirer"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Acquirer '}
            <Typography variant="subtitle1" component="span">
              {acquirer.acquirerName}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </Box>
  );
};
