import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { RiCloseCircleFill } from 'react-icons/ri';
import { IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StateType } from '../../features/Rules/types';
import { setUploadProgress } from '../../services/indicatorsSlice';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) =>
  theme.unstable_sx({
    height: '14px',
    borderRadius: '7px',
    width: '100%',
    margin: '0 12px 0 0',
    backgroundColor: 'grey.100',
    [`& .${linearProgressClasses.bar}`]: {
      height: '6px',
      margin: 'auto 0',
      bgcolor: 'primary.main',
      borderRadius: '3px',
    },
  }),
);

export const CancellableLinearProgress = ({
  docId,
  cancelUpload,
  minHeight = '32px',
}: {
  docId: string;
  cancelUpload: (cancelMessage: string) => void;
  minHeight?: string;
}) => {
  const progressBars = useSelector((state: StateType) => state.indicators.progressBars);
  const progressValue = progressBars[docId];
  const dispatch = useDispatch();
  useEffect(() => {
    if (progressValue === 100) {
      setTimeout(() => {
        dispatch(setUploadProgress({ id: docId, progressValue: undefined }));
      }, 400);
    }
  }, [progressBars]);

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ width: '100%', minHeight }}>
        {progressValue && <StyledLinearProgress variant="determinate" value={progressValue} />}
        {!progressValue && <StyledLinearProgress />}
      </Stack>
      {progressValue && (
        <>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: 'grey.600',
              whiteSpace: 'nowrap',
              px: 2,
              minHeight,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            {`${progressValue} %`}
          </Typography>

          <IconButton
            color="error"
            size="small"
            onClick={() => cancelUpload(`Canceled by user at ${progressValue}%`)}
          >
            <RiCloseCircleFill size="16px" />
          </IconButton>
        </>
      )}
    </>
  );
};
