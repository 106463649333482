/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAIL,
  ASSOCIATE_PRODUCT,
  ASSOCIATE_PRODUCT_SUCCESS,
  ASSOCIATE_PRODUCT_FAIL,
  REMOVE_ASSOCIATED_PRODUCT,
  REMOVE_ASSOCIATED_PRODUCT_SUCCESS,
  REMOVE_ASSOCIATED_PRODUCT_FAIL,
} from '../constants/constants';

export const initialState = {
  products: [],
  loadingProducts: false,
  associatingProduct: false,
  removingAssociatedProduct: false,
  error: false,
};

const productsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_PRODUCTS:
        draft.loadingProducts = true;
        break;
      case LOAD_PRODUCTS_SUCCESS:
        draft.loadingProducts = false;
        draft.products = action.products;
        break;
      case LOAD_PRODUCTS_FAIL:
        draft.loadingProducts = false;
        draft.error = action.error;
        break;
      case ASSOCIATE_PRODUCT:
        draft.associatingProduct = true;
        break;
      case ASSOCIATE_PRODUCT_SUCCESS:
        draft.associatingProduct = false;
        break;
      case ASSOCIATE_PRODUCT_FAIL:
        draft.associatingProduct = false;
        draft.error = action.error;
        break;
      case REMOVE_ASSOCIATED_PRODUCT:
        draft.removingAssociatedProduct = true;
        break;
      case REMOVE_ASSOCIATED_PRODUCT_SUCCESS:
        draft.removingAssociatedProduct = false;
        break;
      case REMOVE_ASSOCIATED_PRODUCT_FAIL:
        draft.removingAssociatedProduct = false;
        draft.error = action.error;
        break;
    }
  });

export default productsReducer;
