import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '../IconButton';
import './Modal.scss';
import { useModalDialogEffect } from 'hooks';
import { ScrollContainer } from '../ScrollContainer';

interface ModalProps {
  showModal: boolean;
  setModal: (val: boolean) => void;
  modalHeader?: any;
  modalFooter?: any;
  children?: any;
  doNotCloseOnBackdrop?: boolean;
  onBackdropClick?: any;
  closable?: boolean;
  isScroll?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  doNotCloseOnBackdrop,
  children,
  showModal,
  setModal,
  modalFooter,
  modalHeader,
  onBackdropClick,
  closable,
  isScroll = false,
}) => {
  const modalContentEl = useRef<HTMLDivElement>(null);

  useModalDialogEffect({
    hideModal: () => setModal(false),
    isShowed: showModal,
  });

  const closeModal = () => {
    setModal && setModal(false);
  };

  const backdropClick = () => {
    if (!doNotCloseOnBackdrop && !closable) {
      closeModal();
    }
    if (onBackdropClick) {
      onBackdropClick();
    }
  };

  return ReactDOM.createPortal(
    showModal && (
      <div className="Modal">
        <div className="ModalBackdrop" /> {/* unused element */}
        <div
          className="ModalContent"
          ref={modalContentEl}
          onMouseDown={(e) => e.target === modalContentEl.current && backdropClick()}
        >
          <div className="ModalContentBox">
            {closable && (
              <div className="closeButtonWrapper">
                <IconButton theme="text" onClick={() => setModal(false)}>
                  <ClearIcon style={{ fontSize: '20px' }} />
                </IconButton>
              </div>
            )}

            <div className="modalHeader">{modalHeader && modalHeader}</div>
            {isScroll ? (
              <ScrollContainer containerHeight="60vh">
                <div className="modalBody">{children}</div>
              </ScrollContainer>
            ) : (
              <div className="modalBody">{children}</div>
            )}
            <div className="modalFooter">{modalFooter && modalFooter}</div>
          </div>
        </div>
      </div>
    ),
    document.getElementById('modal-root') as HTMLDivElement,
  );
};

export default Modal;
