// eslint-disable-next-line import/no-extraneous-dependencies
import { FormState } from 'final-form';
import { getBinaryTree } from '../treeBuilder';
// eslint-disable-next-line import/no-cycle
import { getTypedValue } from '../../../api/onboarding/utils';

enum LogicalOperator {
  And = 'AND',
  Or = 'OR',
}
enum CompareOperator {
  Equal = 'equal',
  Not = 'not',
  Greater = 'gt',
  Less = 'ls',
  GreaterOrEqual = 'gte',
  LessOrEqual = 'lse',
  Contains = 'contains', // not implemented
}
interface Condition {
  order: number;
  logicalOperator: LogicalOperator;
  dependsOn: string;
  dependsOnForm: string;
  compareOperator: CompareOperator;
  value: string;
}
interface Action {
  visible?: boolean;
  enabled?: boolean;
  value?: string;
}
export interface ConditionsActions {
  actions: Array<Action>;
  conditions: Array<Condition>;
}

const internalOperatorMap = {
  equal: '=',
  not: '!=',
  gt: '>',
  ls: '<',
  gte: '>=',
  lse: '<=',
  contains: 'contains is not implemented',
  // etc...
};
const internalToDDFOperatorMap = {
  '=': 'is',
  // etc...
};

const getLiteral = (value: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const literal = getTypedValue(value);
  // if literal is a string, wrap it with "" to handle as literal, not property
  return typeof literal === 'number' || typeof literal === 'boolean'
    ? `${literal}`
    : `"${literal}"`;
};

const getBooleanExpression = (
  conditions: Array<Condition>,
  operatorMap: Record<CompareOperator, string>,
) => {
  // assume that first logical operator is always AND
  let booleanStr = '';
  for (let i = 0; i < conditions.length; i += 1) {
    const condition = conditions[i];
    booleanStr = booleanStr.concat(
      ' ',
      i !== 0 ? condition.logicalOperator : '',
      ' ',
      `$${condition.dependsOn}.$value`,
      ' ',
      operatorMap[condition.compareOperator],
      ' ',
      getLiteral(condition.value),
    );
  }
  return booleanStr;
};

export const getBooleanExpressionString = (conditionsActions: ConditionsActions): string => {
  // console.log('ConditionsActions: ', conditionsActions);
  const { conditions } = conditionsActions;
  const conditionsSorted = [...conditions].sort((c1, c2) => c1.order - c2.order);
  // console.log(
  //   'Expression to be parsed: ',
  //   getBooleanExpression(conditionsSorted, internalOperatorMap),
  // );
  return getBooleanExpression(conditionsSorted, internalOperatorMap);
};

export const computeConditions = (conditionsActions: ConditionsActions, records: any): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const computationResult = getBinaryTree(
    getBooleanExpressionString(conditionsActions),
    '$',
  ).compute(records);
  // console.log('Computed result: ', computationResult);
  return computationResult;
};

export const getDDFCondition = (conditionsActions: ConditionsActions, fieldId: string) => {
  // console.log('____DEPENDENT FIELD___:', fieldId);
  const getDDFConditionsActions = (ddfCondition: any, actions: Array<Action>) => {
    const ddfConditionsActions = { ...ddfCondition };
    ddfConditionsActions.then = {};
    ddfConditionsActions.else = {};
    for (const action of actions) {
      // Order is important
      if (action.hasOwnProperty('value')) {
        ddfConditionsActions.then.set = (formState: FormState<any>) => {
          const dependsOn = action.value.slice(11, -2);
          console.log(`DEPENDS ON: ${dependsOn}`);
          return { [fieldId]: formState.values[dependsOn] };
        };
        ddfConditionsActions.else.set = {};
        ddfConditionsActions.else.visible = true;
      }
      // Should be last
      if (action.hasOwnProperty('visible')) {
        ddfConditionsActions.then.visible = action.visible;
        ddfConditionsActions.else.visible = !action.visible;
        if (action.visible) {
          ddfConditionsActions.then.set = {};
          ddfConditionsActions.else.set = { [fieldId]: '' };
        } else {
          ddfConditionsActions.then.set = { [fieldId]: '' };
          ddfConditionsActions.else.set = {};
        }
      }
    }
    return ddfConditionsActions;
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const ddfCondition = getBinaryTree(
    getBooleanExpressionString(conditionsActions),
    '$',
  ).toDDFConditionNode(internalToDDFOperatorMap);
  const ddfConditionsActions = getDDFConditionsActions(ddfCondition, conditionsActions.actions);
  // console.log('_________CONDITION________');
  // console.dir(ddfConditionsActions);
  return ddfConditionsActions;
};
