import { ISelectOption } from 'uikit';
import { FilterScheme } from 'types';

export const filterScheme: FilterScheme[] = [
  {
    field: 'text',
    name: 'accountNumber',
    label: 'Account Number',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'lowAmount',
    label: 'Amount From',
  },
  {
    field: 'text',
    name: 'highAmount',
    label: 'Amount To',
  },
  {
    field: 'text',
    name: 'bankName',
    label: 'Bank Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'bankCountry',
    label: 'Bank Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'bic',
    label: 'BIC',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'hashedCardNumber',
    label: 'Card Number Hashed',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'clientName',
    label: 'Client Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'clientRegistrationCountry',
    label: 'Client Registration Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'clientId',
    label: 'Client ID',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'clientEmail',
    label: 'Client Email',
  },
  {
    field: 'select',
    name: 'currency',
    label: 'Currency',
  },
  {
    field: 'select',
    name: 'country',
    label: 'Country',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'binCountry',
    label: 'Country BIN',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'datePicker',
    name: 'startCreationDate', // constant name
    label: 'Date Range',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'endCreationDate', // constant name
    label: 'hide', // constant label
    initialFilterValue: null,
  },
  {
    field: 'text',
    name: 'deviceId',
    label: 'Device ID',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'transactionId',
    label: 'External Transaction ID',
  },
  {
    field: 'selectMulti',
    name: 'excludedTriggeredRuleIds',
    label: 'Excluded Triggered Rule IDs',
    initialFilterValue: [],
  },
  {
    field: 'text',
    name: 'firstName',
    label: 'First Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'lastName',
    label: 'Last Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'iban',
    label: 'IBAN',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'ip',
    label: 'IP',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'merchantId',
    label: 'Merchant ID',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'merchantName',
    label: 'Merchant Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'pan',
    label: 'PAN',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'parentClientId', // Number without decimals
    label: 'Parent Client ID',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'parentName', // string
    label: 'Parent Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'pspId',
    label: 'PSP ID',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'referenceText',
    label: 'Reference Text',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'selectMulti',
    name: 'triggeredRuleId',
    label: 'Rule ID Triggered',
    initialFilterValue: [],
  },
  {
    field: 'text',
    name: 'name',
    label: 'Sender or Beneficiary Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderReference',
    label: 'Sender Reference Text',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'selectMulti',
    name: 'fraudResult',
    label: 'Status',
    initialFilterValue: [],
  },
  {
    field: 'text',
    name: 'sortCode',
    label: 'Sort code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'uuid',
    label: ' Transaction ID Haystack',
  },
  {
    field: 'selectMulti',
    name: 'transactionType',
    label: 'Transaction Type',
    initialFilterValue: [],
  },
];

export const transactionType: ISelectOption[] = [
  { value: 'PAYIN', label: 'Pay In' },
  { value: 'PAYOUT', label: 'Pay Out' },
  { value: 'TRANSFER', label: 'Transfer' },
  { value: 'DIRECT_DEBIT', label: 'Direct Debit' },
];

export const fraudResult: ISelectOption[] = [
  { value: 'PASS', label: 'Pass' },
  { value: 'LOWRISK', label: 'Low Risk' },
  { value: 'MEDIUMRISK', label: 'Medium Risk' },
  { value: 'HIGHRISK', label: 'High Risk' },
  { value: 'MODERATION', label: 'Moderation' },
  { value: 'REJECT', label: 'Reject' },
  { value: 'ERROR', label: 'Error' },
];
