/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  SUBMIT_SETTINGS_CHANGE,
  SUBMIT_SETTINGS_CHANGE_SUCCESS,
  SUBMIT_SETTINGS_CHANGE_FAIL,
} from '../constants/constants';

export const initialState = {
  success: false,
  loading: false,
  error: false,
};

const settingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUBMIT_SETTINGS_CHANGE:
        draft.loading = true;
        draft.success = false;
        draft.error = false;
        break;
      case SUBMIT_SETTINGS_CHANGE_SUCCESS:
        draft.loading = false;
        draft.success = action.message;
        draft.error = false;
        break;
      case SUBMIT_SETTINGS_CHANGE_FAIL:
        draft.loading = false;
        draft.success = false;
        draft.error = action.error;
        break;
    }
  });

export default settingsReducer;
