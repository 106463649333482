import { gatewayApi } from '../gatewayApi';

const acquirersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAcquirers: build.query({
      query: (params) => ({
        url: `acquirers`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Acquirers', id: 'LIST' }],
    }),
    getAcquirer: build.query({
      query: ({ id }) => `acquirers/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Acquirer', id }],
    }),
    createAcquirer: build.mutation({
      query: ({ body }) => ({
        url: 'acquirers',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Acquirers', id: 'LIST' }],
    }),
    updateAcquirer: build.mutation({
      query: ({ body, id }) => ({
        url: `acquirers/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Acquirers', id: 'LIST' },
        { type: 'Acquirer', id },
      ],
    }),
    deleteAcquirer: build.mutation({
      query: ({ id }) => ({
        url: `acquirers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Acquirers', id: 'LIST' },
        { type: 'Acquirer', id },
      ],
    }),
  }),
});

export const {
  useGetAcquirersQuery,
  useGetAcquirerQuery,
  useCreateAcquirerMutation,
  useUpdateAcquirerMutation,
  useDeleteAcquirerMutation,
} = acquirersApi;
