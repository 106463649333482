import React from 'react';
import { Tooltip } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

interface InformationProps {
  info?: React.ReactNode;
  disabled?: boolean;
}

export const Information: React.FC<InformationProps> = ({ info = undefined, disabled = false }) =>
  info ? (
    <Tooltip title={info}>
      <InfoIcon sx={{ fontSize: '18px', color: disabled ? 'grey.300' : 'grey.900' }} />
    </Tooltip>
  ) : null;
