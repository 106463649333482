import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Skeleton } from 'uikit';
import { Chat as ChatIcon, Send as SendIcon } from '@mui/icons-material';
import {
  useAddCaseCommentsMutation,
  useGetPredefinedCommentsQuery,
} from 'services/gatewayApi/casesApi';
import { toastCreateSuccess } from 'utils/toast';

import './AddCommentsForm.scss';

interface AddCommentsFormProps {
  caseIds?: any[];
  manualSubmit?: boolean;
  onSubmit?: any;
  showCompact?: boolean;
}

export const AddCommentsForm: React.FC<AddCommentsFormProps> = ({
  caseIds = [],
  manualSubmit = false,
  onSubmit,
  showCompact = false,
}) => {
  const { data: predefined = [], isLoading: isLoadingPredefined } =
    useGetPredefinedCommentsQuery(undefined);

  const inputRef = React.useRef<HTMLTextAreaElement>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  // #region Add comment to case(s) form
  const [addComments] = useAddCaseCommentsMutation();
  const { id: commenterId } = useSelector(makeUserData());

  const validationSchema = yup.object({
    comment: yup
      .string()
      .required('Required')
      .min(3, 'Minimum length is ${min}')
      .max(1000, 'Maximum length is ${max}'),
  });

  const plural = caseIds.length > 1 ? 's' : '';

  const formik = useFormik({
    initialValues: { comment: '' },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);

      addComments({ ...values, commenterId, caseIds })
        .unwrap()
        .then(() => {
          toastCreateSuccess(`Comment for selected case${plural}`);
          if (onSubmit) {
            onSubmit();
          }
          resetForm();
        })
        .catch((rejected) => {
          console.error(rejected);
        })
        .finally(() => {
          setIsSubmitting(false);
          if (inputRef.current) {
            inputRef.current.focus();
          }
        });
    },
  });

  // Manually trigger submit from modal
  useEffect(() => {
    if (manualSubmit) {
      formik.submitForm();
    }
  }, [manualSubmit]);
  // #endregion

  const checkIfContains = (field) =>
    field.toLowerCase().indexOf(formik.values.comment.toLowerCase()) > -1;

  const filterPredefined = (item) =>
    checkIfContains(item.description) ||
    checkIfContains(item.comment) ||
    checkIfContains(item.commentId.toString());

  const placeholderText = 'Write a comment or choose from the predefined below';

  return (
    <div className="AddCommentsForm">
      <Box my={2}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {!showCompact && (
                <Typography variant="subtitle1" color="grey.600">
                  {placeholderText}
                </Typography>
              )}

              <Stack
                direction="row"
                spacing={4}
                alignItems="flex-start"
                className={`textAreaAndorments
                  ${isSubmitting ? ' disabled' : ''}
                  ${inputFocus ? ' focus' : ''}`}
                onClick={() => inputRef.current.focus()}
              >
                {showCompact && (
                  <IconButton size="small" color="default" disabled>
                    <ChatIcon />
                  </IconButton>
                )}

                <TextField
                  inputRef={inputRef}
                  type="text"
                  variant="standard"
                  name="comment"
                  placeholder={showCompact ? placeholderText : ''}
                  multiline
                  fullWidth
                  autoFocus
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onFocus={() => setInputFocus(true)}
                  onBlur={() => setInputFocus(false)}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                  disabled={isSubmitting}
                  InputProps={{ disableUnderline: true }}
                />

                {showCompact && (
                  <IconButton type="submit" size="small" color="primary" disabled={isSubmitting}>
                    <SendIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>

      {isLoadingPredefined && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} sectionsCount={1} height={32} />
        </div>
      )}

      {!isLoadingPredefined && (
        <Box>
          <Stack gap={2}>
            {predefined.filter(filterPredefined).map((item) => (
              <div
                className="predefinedComment"
                key={item.commentId}
                onClick={() => formik.setFieldValue('comment', item.description)}
              >
                <Typography variant="body2">{item.comment}</Typography>
              </div>
            ))}
          </Stack>
        </Box>
      )}
    </div>
  );
};
