import React from 'react';
import { IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import { FiltersSelectable, Button } from 'uikit';
import { RiCloseCircleFill, RiSearch2Line } from 'react-icons/ri';
import { grey } from '../../theme/palette-blocks';
import { InputGreyStyled } from '../../muikit/Input/InputGreyStyled';
import { PageControlProps } from './PageControlProps';
import { PageControlFilter } from './PageControlFilter';
import './PageControl.scss';

const PageControl: React.FC<PageControlProps> = ({
  title,
  primaryAction,
  primaryActionTitle,
  secondaryAction,
  secondaryActionTitle,
  searchValue,
  searchHandler,
  filterScheme,
  filterHandler,
  filterValue,
  options,
  filtersSelectable,
  onFilterReset,
  hasError,
  actionMessage,
  children,
}) => (
  <div className="PageControl">
    <div className="PageControlContent">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography variant="h1">{title}</Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <div className="pageHeadingActions">
            {searchHandler && (
              <InputGreyStyled
                fullWidth
                color="secondary"
                placeholder="Search"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchHandler(e.target.value)}
                value={searchValue}
                startAdornment={
                  <InputAdornment position="start" sx={{ pl: 2 }}>
                    <RiSearch2Line style={{ color: grey[700] }} />
                  </InputAdornment>
                }
                endAdornment={
                  searchValue?.length > 0 && (
                    <InputAdornment position="end" sx={{ pl: 2 }}>
                      <IconButton
                        size="small"
                        onClick={() => searchHandler('')}
                        sx={{ '&:hover': { bgcolor: 'transparent' } }}
                      >
                        <RiCloseCircleFill color={grey[700]} fontSize="14" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            )}

            {filterScheme && filterHandler && !filtersSelectable && (
              <PageControlFilter
                filterScheme={filterScheme}
                filterValue={filterValue}
                filterHandler={filterHandler}
                options={options}
              />
            )}

            {filterScheme && filterHandler && filtersSelectable && (
              <FiltersSelectable
                filterScheme={filterScheme}
                filterValue={filterValue}
                filterHandler={filterHandler}
                options={options}
                onReset={onFilterReset}
              />
            )}

            {secondaryAction && (
              <Button onClick={secondaryAction} type="button">
                {secondaryActionTitle}
              </Button>
            )}

            {actionMessage && <Typography>{actionMessage}</Typography>}

            {primaryAction && (
              <Button variant="contained" theme="primary" onClick={primaryAction} type="button">
                {primaryActionTitle}
              </Button>
            )}
            {children && children}
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default PageControl;
