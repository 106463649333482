import React from 'react';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { figmaVariables } from '../../../theme';

export const NotAllShown = (): JSX.Element => (
  <Box
    sx={{
      my: 6,
      borderRadius: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 1,
      backgroundColor: alpha(figmaVariables.primitives.color.orange['orange-100'], 0.7),
      height: '48px',
    }}
  >
    Not all results are shown - the limit of 500 entries is exceeded, refine your search criteria.
  </Box>
);
