import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import RiskWordsSearchForm from './RiskWordsSearchForm';
import RiskWordsList from './RiskWordsList';

const RiskWords = () => {
  const [filterValues, setFilterValues] = useState({});

  return (
    <div className="page-content">
      <Helmet title="Risk Words" />
      <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
        <div className="page-title">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Typography variant="h1" py={4}>
                  Risk Words
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <RiskWordsSearchForm setFilterValues={setFilterValues} />
            </div>
            <div className="col-md-12">
              <RiskWordsList filterValues={filterValues} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskWords;
