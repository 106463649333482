/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */

export class Node {
  constructor() {
    Object.defineProperty(this, 'parent', {
      // enumerable: false
      writable: true,
      value: null,
    });
  }

  compute(ctx) {
    throw new Error('not implemented');
  }

  toString() {
    throw new Error('not implemented');
  }

  toDDFConditionNode() {
    throw new Error('not implemented');
  }
}
