import { SortOptions } from 'types';
import { Ubo } from './types';

export const initialUbo: Ubo = {
  id: 'new',
  number: '',
  status: 'ACTIVE',

  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  nationality: '',
  dateOfBirth: '',
  placeOfBirth: '',
  email: '',

  documentNum: '',
  documentType: '',
  documentCountryIssue: '',
  documentExpiry: '',

  address: '',
  city: '',
  state: '',
  country: '',
  postCode: '',

  screeningResult: '',
  screeningResultNote: '',
  nationalityAlpha2: '',
};

export const sortOptions: SortOptions[] = [
  { fieldName: 'firstName', label: 'First Name' },
  { fieldName: 'lastName', label: 'Last Name' },
  { fieldName: 'email', label: 'Email' },
  { fieldName: 'nationality', label: 'Nationality' },
  { fieldName: 'status', label: 'Status' },
  { fieldName: 'createdDate', label: 'Created on' },
];

export const detailsSections = [
  { id: 'basicInfo', label: 'Basic Information' },
  { id: 'idDocument', label: 'ID Document' },
  { id: 'address', label: 'Address' },
  { id: 'otherInfo', label: 'Other Information' },
];

export const detailsFields = [
  { sectionId: 'basicInfo', value: 'firstName', label: 'First Name' },
  { sectionId: 'basicInfo', value: 'middleName', label: 'Middle Name' },
  { sectionId: 'basicInfo', value: 'lastName', label: 'Last Name' },
  { sectionId: 'basicInfo', value: 'gender', label: 'Gender' },
  { sectionId: 'basicInfo', value: 'nationality', label: 'Nationality' },
  { sectionId: 'basicInfo', value: 'dateOfBirth', label: 'Date of Birth' },
  { sectionId: 'basicInfo', value: 'placeOfBirth', label: 'Place of Birth' },
  { sectionId: 'basicInfo', value: 'email', label: 'Email' },

  { sectionId: 'idDocument', value: 'documentNum', label: 'Document Number' },
  { sectionId: 'idDocument', value: 'documentType', label: 'Document Type' },
  { sectionId: 'idDocument', value: 'documentCountryIssue', label: 'Issuing Country' },
  { sectionId: 'idDocument', value: 'documentExpiry', label: 'Expiry' },

  { sectionId: 'address', value: 'address', label: 'Address' },
  { sectionId: 'address', value: 'city', label: 'City' },
  { sectionId: 'address', value: 'state', label: 'State/Region' },
  { sectionId: 'address', value: 'country', label: 'Country' },
  { sectionId: 'address', value: 'postCode', label: 'Post Code' },

  { sectionId: 'otherInfo', value: 'screeningResult', label: 'Screening Result' },
  { sectionId: 'otherInfo', value: 'screeningResultNote', label: 'Screening Result Note' },
  { sectionId: 'otherInfo', value: 'nationalityAlpha2', label: 'Nationality Alpha-2' },
];

export const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

export const documentTypes = [
  { value: 'birth-certificate', label: 'Birth Certificate' },
  { value: 'drivers-license', label: "Driver's License" },
  { value: 'government-issued-id', label: 'Government Issued ID' },
  { value: 'passport', label: 'Passport' },
];
