import { SortOptions } from 'types';

const defaultSortOptions: SortOptions[] = [
  { fieldName: 'createdDateTime', label: 'Date / time' },
  { fieldName: 'riskLevel', label: 'Risk Response' },
  { fieldName: 'clientName', label: 'Client Name' },
  { fieldName: 'description', label: 'Description' },
  { fieldName: 'numericAmount', label: 'Amount' },
  { fieldName: 'assigneeId', label: 'Assignee' },
  { fieldName: 'referenceId', label: 'Reference ID' },
  { fieldName: 'caseStatus', label: 'Status' },
];

const tableDefaultExcluded = ['clientName', 'referenceId'];

const applicationSortOptions: SortOptions[] = [
  { fieldName: 'createdDateTime', label: 'Date / time' },
  { fieldName: 'companyName', label: 'Application Name' },
  { fieldName: 'description', label: 'Case Subject' },
  { fieldName: 'assigneeId', label: 'Assignee' },
  { fieldName: 'referenceId', label: 'Application ID' },
  { fieldName: 'caseStatus', label: 'Status' },
];

const tableApplicationExcluded = ['referenceId'];

export const tableRightAligned = ['numericAmount'];

export const getSortOptions = (caseType): SortOptions[] =>
  caseType === 'APPLICATION' ? applicationSortOptions : defaultSortOptions;

export const getTableSortOptions = (caseType): SortOptions[] => {
  const excluded = caseType === 'APPLICATION' ? tableApplicationExcluded : tableDefaultExcluded;
  return getSortOptions(caseType)
    .filter((option) => !excluded.includes(option.fieldName));
}
