import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  Typography,
  IconButton,
  FormControlLabel,
} from '@mui/material';
import { createTheme, Theme, ThemeProvider, useTheme } from '@mui/material/styles';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Panel } from '../../../uikit';
import { theme as defaultTheme, palette } from '../../../theme';

const defaultColors = Object.keys(palette);

const openpaydColors = [
  'default',
  'base',
  'primary',
  'primaryLight',
  'secondary',
  'contrast',
  'warning',
  'successLight',
  'danger',
];

const typoVariantsOpenpayd = [
  'h1',
  'h2',
  'bodySmall',
  'body',
  'bodyBold',
  'bodyPlus',
  'bodyPlusBold',
  'label',
];
const typoVariantsDefault = [
  'h1',
  'h2',
  'h3',
  'h4',
  'body1',
  'body2',
  'button',
  'caption',
  'subtitle1',
  'subtitle2',
];

const colorShades = [
  'main',
  'light',
  'bg-100',
  'bg-200',
  'hover',
  'active',
  'dark',
  'bg-300',
  'outline-100',
  'contrastText',
];

export const Themes = () => {
  const currentTheme = useTheme();
  const ColorsExample: React.FC<{ colors: string[] }> = ({ colors }) => (
    <Stack className="ColorsExample" spacing={2} direction="column">
      <Typography>Grey - Box</Typography>
      <Stack direction="row" flexWrap="wrap">
        {[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((shade) => (
          <Box key={shade} sx={{ bgcolor: `grey.${shade}`, width: '30px', heigth: '30px' }}>
            {shade}
          </Box>
        ))}
      </Stack>

      <Typography>Grey - IconButton</Typography>
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((shade) => (
          <IconButton key={shade} size="small" sx={{ bgcolor: `grey.${shade}` }}>
            <CurrencyExchangeIcon />
          </IconButton>
        ))}
      </Stack>

      <Typography>Grey - Button</Typography>
      <Stack direction="row" flexWrap="wrap">
        {[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((shade) => (
          <Button key={shade} size="small" sx={{ bgcolor: `grey.${shade}` }}>
            {shade}
          </Button>
        ))}
      </Stack>

      <Typography>Palette</Typography>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {colors.map((color) => (
          <Stack direction="column" key={color}>
            <Typography>{color}</Typography>
            {colorShades.map((shade) => (
              <Box key={shade} sx={{ bgcolor: `${color}.${shade}` }}>
                {shade}
              </Box>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );

  const TypographyExample = ({ variants }: { variants: string[] }) => (
    <Stack>
      {variants.map((variant) => (
        <Typography key={variant} variant={variant}>
          {variant}
        </Typography>
      ))}
    </Stack>
  );

  const [checked, setChecked] = useState(false);

  const ComponentsExample: React.FC<{ title: 'default' | 'openpayd' }> = ({ title }) => (
    <Stack direction="column" gap={2}>
      <Typography variant="h2">{`Theme components ${title}`}</Typography>

      <Divider />
      <Typography>{'<FormControlLabel/> and <Checkbox/>'}</Typography>
      <Stack direction="row" spacing={4}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={<Checkbox checked={checked} onChange={() => setChecked((prev) => !prev)} />}
          label="Default"
        />
        <FormControlLabel
          control={<Checkbox checked={!checked} onChange={() => setChecked((prev) => !prev)} />}
          label="Default"
        />
        <FormControlLabel control={<Checkbox checked={checked} disabled />} label="Disabled" />
        <FormControlLabel control={<Checkbox checked={!checked} disabled />} label="Disabled" />
      </Stack>
      <Stack direction="row" spacing={4}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={<Checkbox checked={checked} onChange={() => setChecked((prev) => !prev)} />}
          label="Styled"
        />
        <FormControlLabel
          control={<Checkbox checked={!checked} onChange={() => setChecked((prev) => !prev)} />}
          label="Styled"
        />
        <FormControlLabel control={<Checkbox checked={checked} />} label="Disabled" disabled />
        <FormControlLabel control={<Checkbox checked={!checked} />} label="Disabled" disabled />
      </Stack>

      <Divider />
      <Typography>{'<Button/>'}</Typography>

      <Stack direction="column" gap={2}>
        <Typography>{`"shape" and "size" props: `}</Typography>
        {['rounded', 'circled', 'squared'].map((buttonShape: 'rounded' | 'circled' | 'squared') => (
          <Stack direction="row" spacing={2} alignItems="center" key={buttonShape}>
            <Typography sx={{ width: '80px' }}>{buttonShape}</Typography>
            {['small', 'medium', 'large'].map((buttonSize: 'small' | 'medium' | 'large') => (
              <Button
                size={buttonSize}
                color="primary"
                variant="contained"
                // TODO: shape prop works, but not typed, need to make StyledButton with shape prop
                shape={buttonShape}
                key={buttonSize}
              >
                {buttonSize}
              </Button>
            ))}
          </Stack>
        ))}
      </Stack>

      <Stack direction="column" gap={1}>
        <Typography sx={{ pt: 2 }}>{`"color" and "variant" props: `}</Typography>
        {defaultColors.map((buttonColor: any) => {
          if (['grey', 'text', 'action', 'background'].includes(buttonColor)) return null;

          return (
            <Stack
              key={buttonColor}
              direction="row"
              gap={1}
              p={1}
              alignItems="center"
              sx={{
                bgcolor:
                  buttonColor === 'contrast' ? defaultTheme.palette.contrast?.main : 'transparent',
              }}
            >
              <Typography sx={{ width: '100px', color: buttonColor === 'contrast' ? 'white' : '' }}>
                {buttonColor}
              </Typography>
              {['contained', 'text', 'outlined'].map(
                (buttonVariant: 'contained' | 'text' | 'outlined') => (
                  <React.Fragment key={buttonVariant}>
                    <Button color={buttonColor} variant={buttonVariant}>
                      {buttonVariant}
                    </Button>
                    <Button color={buttonColor} variant={buttonVariant} disabled>
                      dis
                    </Button>
                  </React.Fragment>
                ),
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );

  return (
    <div>
      <Typography variant="h2" mb={5}>
        Themes
      </Typography>

      <Panel>
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Stack direction="column" spacing={2} sx={{ width: '48%' }}>
            <Typography variant="h2">Default Theme</Typography>
            <ColorsExample colors={defaultColors} />
          </Stack>

          <Divider orientation="vertical" flexItem />

          <ThemeProvider
            theme={(theme: Theme) =>
              createTheme({
                ...defaultTheme,
                // palette: themeOpenpayd.palette,
                palette: defaultTheme.palette,
                // typography: themeOpenpayd.typography,
                typography: defaultTheme.typography,
                // components: { ...defaultTheme.components, ...themeOpenpayd.components },
                components: { ...defaultTheme.components, ...defaultTheme.components },
              })
            }
          >
            <Stack direction="column" spacing={2} sx={{ width: '48%' }}>
              <Typography variant="h2">Openpayd Theme</Typography>
              <ColorsExample colors={openpaydColors} />
            </Stack>
          </ThemeProvider>
        </Stack>
      </Panel>

      <Panel>
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Box sx={{ width: '48%' }}>
            <TypographyExample variants={typoVariantsDefault} />
          </Box>

          <Divider orientation="vertical" flexItem />

          <ThemeProvider
            theme={(theme: Theme) =>
              createTheme({
                ...defaultTheme,
                // palette: themeOpenpayd.palette,
                palette: defaultTheme.palette,
                // typography: themeOpenpayd.typography,
                typography: defaultTheme.typography,
                // components: { ...defaultTheme.components, ...themeOpenpayd.components },
                components: { ...defaultTheme.components, ...defaultTheme.components },
              })
            }
          >
            <Box sx={{ width: '48%' }}>
              <TypographyExample variants={typoVariantsOpenpayd} />
            </Box>
          </ThemeProvider>
        </Stack>
      </Panel>

      <Panel>
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Box sx={{ width: '48%' }}>
            <ComponentsExample title="default" />
          </Box>

          <Divider orientation="vertical" flexItem />

          <ThemeProvider
            theme={(theme: Theme) =>
              createTheme({
                ...defaultTheme,
                // palette: themeOpenpayd.palette,
                palette: defaultTheme.palette,
                // typography: themeOpenpayd.typography,
                typography: defaultTheme.typography,
                // components: { ...defaultTheme.components, ...themeOpenpayd.components },
                components: { ...defaultTheme.components, ...defaultTheme.components },
              })
            }
          >
            <Box sx={{ width: '48%', bgcolor: 'grey.50' }}>
              <ComponentsExample title="openpayd" />
            </Box>
          </ThemeProvider>
        </Stack>
      </Panel>
    </div>
  );
};
