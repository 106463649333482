export const currencies = [
  {
    code: 'EUR',
    name: 'Euro',
    fractionDigits: 2,
  },
  {
    code: 'USD',
    name: 'US Dollar',
    fractionDigits: 2,
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
    fractionDigits: 2,
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
    fractionDigits: 2,
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    fractionDigits: 2,
  },
  {
    code: 'AOA',
    name: 'Angolan Kwanza',
    fractionDigits: 2,
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    fractionDigits: 2,
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    fractionDigits: 2,
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
    fractionDigits: 2,
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    fractionDigits: 3,
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
    fractionDigits: 2,
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'BYR',
    name: 'Belarusian Ruble',
    fractionDigits: 0,
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    fractionDigits: 2,
  },
  {
    code: 'BMD',
    name: 'Bermudan Dollar',
    fractionDigits: 2,
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
    fractionDigits: 2,
  },
  {
    code: 'BAM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    fractionDigits: 2,
  },
  {
    code: 'BWP',
    name: 'Botswanan Pula',
    fractionDigits: 2,
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    fractionDigits: 2,
  },
  {
    code: 'GBP',
    name: 'British Pound Sterling',
    fractionDigits: 2,
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    fractionDigits: 2,
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    fractionDigits: 2,
  },
  {
    code: 'XOF',
    name: 'CFA Franc BCEAO',
    fractionDigits: 0,
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
    fractionDigits: 2,
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    fractionDigits: 2,
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    fractionDigits: 0,
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    fractionDigits: 2,
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    fractionDigits: 2,
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colón',
    fractionDigits: 2,
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    fractionDigits: 2,
  },
  {
    code: 'CYP',
    name: 'Cypriot Pound',
    fractionDigits: 2,
  },
  {
    code: 'CZK',
    name: 'Czech Republic Koruna',
    fractionDigits: 2,
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    fractionDigits: 2,
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    fractionDigits: 2,
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    fractionDigits: 2,
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    fractionDigits: 2,
  },
  {
    code: 'EEK',
    name: 'Estonian Kroon',
    fractionDigits: 2,
  },
  {
    code: 'FJD',
    name: 'Fijian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    fractionDigits: 2,
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    fractionDigits: 2,
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
    fractionDigits: 2,
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
    fractionDigits: 2,
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    fractionDigits: 2,
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    fractionDigits: 2,
  },
  {
    code: 'ISK',
    name: 'Icelandic Króna',
    fractionDigits: 0,
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    fractionDigits: 2,
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    fractionDigits: 2,
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    fractionDigits: 3,
  },
  {
    code: 'ILS',
    name: 'Israeli New Sheqel',
    fractionDigits: 2,
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    fractionDigits: 2,
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    fractionDigits: 0,
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    fractionDigits: 3,
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
    fractionDigits: 2,
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    fractionDigits: 2,
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    fractionDigits: 3,
  },
  {
    code: 'KGS',
    name: 'Kyrgystani Som',
    fractionDigits: 2,
  },
  {
    code: 'LAK',
    name: 'Laotian Kip',
    fractionDigits: 2,
  },
  {
    code: 'LVL',
    name: 'Latvian Lats',
    fractionDigits: 2,
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    fractionDigits: 2,
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    fractionDigits: 2,
  },
  {
    code: 'LTL',
    name: 'Lithuanian Litas',
    fractionDigits: 2,
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
    fractionDigits: 2,
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
    fractionDigits: 2,
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    fractionDigits: 2,
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    fractionDigits: 2,
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    fractionDigits: 2,
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    fractionDigits: 2,
  },
  {
    code: 'MNT',
    name: 'Mongolian Tugrik',
    fractionDigits: 2,
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    fractionDigits: 2,
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
    fractionDigits: 2,
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    fractionDigits: 2,
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    fractionDigits: 2,
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    fractionDigits: 2,
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    fractionDigits: 2,
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    fractionDigits: 2,
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    fractionDigits: 3,
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
    fractionDigits: 2,
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
    fractionDigits: 2,
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean Kina',
    fractionDigits: 2,
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guarani',
    fractionDigits: 0,
  },
  {
    code: 'PEN',
    name: 'Peruvian Nuevo Sol',
    fractionDigits: 2,
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    fractionDigits: 2,
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    fractionDigits: 2,
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
    fractionDigits: 2,
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    fractionDigits: 2,
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    fractionDigits: 2,
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    fractionDigits: 2,
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    fractionDigits: 2,
  },
  {
    code: 'SCR',
    name: 'Seychellois Rupee',
    fractionDigits: 2,
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    fractionDigits: 2,
  },
  {
    code: 'SIT',
    name: 'Slovenian Tolar',
    fractionDigits: 2,
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    fractionDigits: 2,
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    fractionDigits: 0,
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    fractionDigits: 2,
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    fractionDigits: 2,
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    fractionDigits: 2,
  },
  {
    code: 'TJS',
    name: 'Tajikistani Somoni',
    fractionDigits: 2,
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    fractionDigits: 2,
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    fractionDigits: 2,
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    fractionDigits: 2,
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    fractionDigits: 3,
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    fractionDigits: 2,
  },
  {
    code: 'TRL',
    name: 'Turkish Lira (1922-2005)',
    fractionDigits: 0,
  },
  {
    code: 'TMT',
    name: 'Turkmenistani Manat',
    fractionDigits: 2,
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    fractionDigits: 2,
  },
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    fractionDigits: 2,
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
    fractionDigits: 2,
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Som',
    fractionDigits: 2,
  },
  {
    code: 'VEF',
    name: 'Venezuelan Bolívar',
    fractionDigits: 2,
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
    fractionDigits: 0,
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    fractionDigits: 2,
  },
];
