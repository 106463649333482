/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  LOAD_TRANSACTIONS,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAIL,
  REMOVE_COMPLETED_TRANSACTION,
  CHANGE_ASSIGNEE_OF_TRANSACTION,
} from '../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  transactions: [],
};

const transactionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_TRANSACTIONS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_TRANSACTIONS_SUCCESS:
        draft.transactions = action.transactions;
        draft.loading = false;
        break;
      case LOAD_TRANSACTIONS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case REMOVE_COMPLETED_TRANSACTION: {
        const transactionsObj = state.transactions;
        const newTransactionsObj = { ...transactionsObj };
        const newTransactions = newTransactionsObj.responseList;
        const idx = newTransactions.findIndex((item) => item.transactionId === action.id);
        newTransactions.splice(idx, 1);
        newTransactionsObj.responseList = newTransactions;
        draft.transactions = newTransactionsObj;
        break;
      }
      case CHANGE_ASSIGNEE_OF_TRANSACTION: {
        const transactionsObj = state.transactions;
        const newTransactionsObj = { ...transactionsObj };
        const newTransactions = newTransactionsObj.responseList;
        let detailsObj = {
          ...JSON.parse(
            newTransactions.find((item) => item.transactionId === action.transactionId).details,
          ),
        };
        detailsObj.assignee = action.assignee;
        detailsObj = JSON.stringify(detailsObj);
        newTransactions.find((item) => item.transactionId === action.transactionId).details =
          detailsObj;
        newTransactionsObj.responseList = newTransactions;
        draft.transactions = newTransactionsObj;
        break;
      }
    }
  });

export default transactionsReducer;
