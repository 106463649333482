import { format, parse, isValid, isDate, parseISO, set } from 'date-fns';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_SERVER_FORMAT,
  DATE_TIME_SERVER_FORMAT,
  DATETIME_FORMAT,
  DATETIMESEC_FORMAT,
} from '../constants/constants';

/**
 * Format server date string using date-fns
 * @param {string} serverDate
 * @param {string} pattern - date-fns pattern
 * @returns {string}
 */
export const formatDate = (serverDate, pattern = DATETIME_FORMAT) =>
  format(new Date(serverDate), pattern);

/**
 * @returns DATETIMESEC_FORMAT {string} by default or in "format"
 * @param date in ISO format
 * @param formatString {string}
 */

export const formatFormDate = (input: Date | string | null | undefined): Date | null => {
  if (input === undefined || input === null) {
    return null;
  }
  if (isDate(input)) {
    return input as Date;
  }
  const parsedDate = parse(input as string, DATE_FORMAT, new Date());
  if (isValid(parsedDate)) {
    return parsedDate;
  }
  const parsedDateIso = parseISO(input as string);
  if (isValid(parsedDateIso)) {
    return parsedDateIso;
  }
  return null;
};

export const formatIsoDate = (date, formatString = DATETIMESEC_FORMAT) => {
  try {
    const outFormat = formatString || DATETIMESEC_FORMAT;
    if (date === null || date === undefined) return null;
    if (date instanceof Date) {
      return format(date, outFormat);
    }
    const parsedDate = parseISO(date);
    if (parsedDate instanceof Date) {
      return format(parsedDate, outFormat);
    }
  } catch {
    /* ignore errors */
  }

  return 'Date could not be formated.';
};

export const parseDateISO = (date) => {
  if (date === null || date === undefined) return null;
  return parseISO(date);
};

const isIsoDate = (str) => {
  const isoRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/;
  return isoRegExp.test(str);
};

export const formatDateScreen = (date, formatString = DATE_FORMAT) => {
  if (date === null || date === undefined) return null;
  if (date instanceof Date) {
    return format(date, formatString);
  }
  if (isIsoDate(date)) return format(parseDateISO(date), formatString);

  const parsedDate = parse(date, DATE_SERVER_FORMAT, new Date());
  return format(parsedDate, formatString);
};

export const parseDate = (date, formatString = DATE_SERVER_FORMAT) => {
  if (date === null || date === undefined || date === '-') return null;
  return parse(date, formatString, new Date());
};

export const formatDateServer = (date) => {
  if (date === null) return null;
  if (date instanceof Date) {
    return format(date, DATE_SERVER_FORMAT);
  }
  return date;
};

export const dateTime = (date, time) => {
  if (date === null) {
    return null;
  }
  const today = new Date();
  let d = date || today;
  if (typeof date === 'string') {
    d = parse(date, DATE_SERVER_FORMAT, today);
  }
  if (d > today) {
    d = today;
  }

  d.setHours(23, 59, 59, 0);
  if (time === 'start') {
    d.setHours(0, 0, 0, 0);
  }

  return d;
};

function formatByCurrency({
  amount,
  locale = 'en-GB',
  style = 'currency',
  currency = 'EUR',
  currencyDisplay = 'symbol',
}) {
  try {
    return amount.toLocaleString(locale, {
      style,
      currency,
      currencyDisplay,
    });
  } catch (e) {
    if (e instanceof RangeError) {
      return `(Unknown Currency) ${amount}`;
    }
    return null;
  }
}

export default function getLocalDateTime(utcDateTime) {
  const utc = moment.utc(utcDateTime);
  return moment(utc).local();
}

export { formatByCurrency };

export const formatServerDateTime = (inputDate: Date | string | null): string => {
  if (inputDate === '' || inputDate === null) {
    return '';
  }

  let dateObject: Date;

  if (typeof inputDate === 'string') {
    dateObject = parse(inputDate, DATE_TIME_SERVER_FORMAT, new Date());
  } else if (inputDate instanceof Date) {
    dateObject = inputDate;
  } else {
    throw new Error(
      'Invalid date. Please provide a valid Date object or a string in the specified format.',
    );
  }

  dateObject = set(dateObject, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

  return `${format(dateObject, DATE_SERVER_FORMAT)}T00:00:00`;
};

// reformat string "02/24/2024" to "24-02-2024"
export const reformatDateString = (inputDate: string): string => {
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dateFormatRegex.test(inputDate)) {
    return inputDate;
  }
  const dateParts = inputDate.split('/');
  return `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`;
};
