// npm
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';

import { cleanEmptyQueryParameters } from 'utils/cleanEmptyQueryParameters';
import { queryHasValue } from 'utils/queryHasValue';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';

import {
  PageContainer,
  PageControl,
  Skeleton,
  StickyHeader,
  SortByHeader,
  IFilterValue,
  EndListButton,
} from 'uikit';
import { DEFAULT_PAGE_SIZE } from 'constants/constants';
import { NoResultsBox, NoDataBox } from 'muikit';
import { useInfinityScroll } from 'hooks';
import { SortParam, ValueLabel } from 'types';
import { useGetListUsersQuery } from '../../services/gatewayApi/usersApi';
import {
  getApplicationStatusOptions,
  filterScheme,
  sortOptions,
  riskStatusOptions,
} from './constants';
import { RiskStatus } from './types';
import { ApplicationManagementList, ApplicationHead, ApplicationStatus, defaultSort } from '.';
import { useGetCaseAssignmentsQuery } from '../../services/gatewayApi/casesApi';
import { AssignmentsResponse } from '../cases/types';
import { removeDuplicatesByField } from '../../utils/removeDuplicatesByField';
import './ApplicationManagement.scss';

const ApplicationManagement = () => {
  const navigate = useNavigate();
  const applicationStatusOptions = getApplicationStatusOptions(true);
  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<IFilterValue>({});
  const [sortedBy, setSortedBy] = useState<SortParam>(defaultSort);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE * 2);
  const results = useRef<any>();
  const resultsTotalCount = useRef<number>(0);

  const loader = useRef<HTMLDivElement>();
  const isNoData = useRef<boolean>(false);
  const isNoResults = useRef<boolean>(false);

  // HELPERS
  // Skip multiple queries when typing search field
  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const clearQuery = () => {
    setSearchQuery('');
    setFilterQuery({ mainStatus: '', assignee: '', riskStatus: '' });
  };

  // return correct search object (empty if there is no search query)
  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  // HANDLERS
  // Search handler
  const searchHandler = (e) => {
    setSkipRequest(true);
    setSearchQuery(e);
    debounceQuery();
  };

  // QUERY
  const {
    data: applications,
    isLoading: isLoadingApplications,
    isFetching: isFetchingApplications,
  } = useGetApplicationsQuery(
    {
      page: 0,
      size: pageSize,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      ...cleanEmptyQueryParameters(filterQuery),
      ...searchObj(),
    },
    {
      skip: skipRequest,
    },
  );

  // received data
  results.current = applications?.content || [];
  resultsTotalCount.current = applications?.totalElements || 0;
  const resultsLength = results.current.length;

  const { data: usersList = [], isLoading: isUsersLoading } = useGetListUsersQuery(undefined);
  const { caseAssignments = [], isCaseAssignmentsLoading } = useGetCaseAssignmentsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      caseAssignments: (data as AssignmentsResponse[])?.filter(
        (el) =>
          el.caseCategory === 'ONBOARDING_COMPLIANCE' || el.caseCategory === 'COMPLIANCE_SENIOR',
      ),
      isCaseAssignmentsLoading: isLoading,
    }),
  });

  const getName = (userId) => usersList.find((el) => el.id === userId)?.name || userId;
  const [assigneeFilterOptions, setAssigneeFilterOptions] = useState<ValueLabel[]>([]);
  useEffect(() => {
    if (caseAssignments?.length > 0 && usersList?.length > 0) {
      const filteredAssignee = caseAssignments
        .map((el) => ({
          value: el.userId,
          label: getName(el.userId),
        }))
        .filter((el) => !!el.value);

      setAssigneeFilterOptions(removeDuplicatesByField(filteredAssignee, 'value'));
    }
  }, [isCaseAssignmentsLoading, isUsersLoading]);

  // check is there any application data
  isNoData.current = resultsLength === 0 && searchQuery.length === 0 && !queryHasValue(filterQuery);

  // check is there any application data match filter
  isNoResults.current =
    resultsLength === 0 && (searchQuery.length !== 0 || queryHasValue(filterQuery));

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    const searchString = new URLSearchParams(urlParams).get('search');
    setSearchQuery(searchString || '');

    const applicationStatusFilter = new URLSearchParams(urlParams).get('main_status_filter');
    const assigneeFilter = new URLSearchParams(urlParams).get('assignee_filter');
    const riskStatusFilter = new URLSearchParams(urlParams).get('risk_status_filter');
    setFilterQuery({
      mainStatus: (applicationStatusFilter as ApplicationStatus) || '',
      assignee: assigneeFilter || '',
      riskStatus: (riskStatusFilter as RiskStatus) || '',
    });
  }, []);

  // load next page method
  const nextPage = () => {
    if (
      !(isLoadingApplications || isFetchingApplications) &&
      resultsLength !== resultsTotalCount.current
    ) {
      setPageSize((prevPageSize) => {
        const nextPageSize = prevPageSize + DEFAULT_PAGE_SIZE;
        return nextPageSize >= resultsTotalCount.current ? resultsTotalCount.current : nextPageSize;
      });
    }
  };

  // infinity scroll hook
  useInfinityScroll({
    isFetching: isFetchingApplications,
    isLoading: isLoadingApplications,
    nextPage,
    loader,
  });

  // control search, sort, filter from inputs
  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }

    params.append('orderBy', sortedBy.property);
    params.append('direction', sortedBy.direction);

    if (filterQuery.mainStatus) {
      params.append('main_status_filter', filterQuery.mainStatus as string);
    }
    if (filterQuery.assignee) {
      params.append('assignee_filter', filterQuery.assignee as string);
    }
    if (filterQuery.riskStatus) {
      params.append('risk_status_filter', filterQuery.riskStatus as string);
    }
    navigate({ search: params.toString() }, { state: true });
  }, [searchQuery, sortedBy, navigate, filterQuery]);

  return (
    <PageContainer>
      <Helmet title="Application Management" />

      <div className="Application Management">
        <StickyHeader>
          <div className="applications-header-wrapper">
            <PageControl
              title="Application Management"
              primaryActionTitle="New Application"
              primaryAction={() => navigate('./new')}
              searchValue={searchQuery}
              searchHandler={searchHandler}
              filterScheme={filterScheme}
              filterValue={filterQuery}
              filterHandler={setFilterQuery}
              options={{
                mainStatus: applicationStatusOptions,
                assignee: assigneeFilterOptions,
                riskStatus: riskStatusOptions,
              }}
            />

            <SortByHeader
              count={resultsLength || 0}
              totalCount={resultsTotalCount.current}
              sortOptions={sortOptions}
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
            />
          </div>
        </StickyHeader>

        <div className="Applications-table-wrapper">
          {!isLoadingApplications && !isNoData?.current && !isNoResults.current && (
            <ApplicationHead sortedBy={sortedBy} setSortedBy={setSortedBy} />
          )}

          {isLoadingApplications && <Skeleton count={DEFAULT_PAGE_SIZE} height={48} />}

          {!isLoadingApplications && (
            <>
              <div
                className={`Applications applications-list-wrapper ${
                  isFetchingApplications ? 'applications-list-wrapper--fetching' : ''
                }`}
              >
                <ApplicationManagementList
                  list={results.current}
                  loading={isFetchingApplications || isLoadingApplications}
                />
              </div>

              <EndListButton
                isVisible={resultsLength > 0}
                nextPage={nextPage}
                isFetching={isFetchingApplications}
                isEndReached={resultsLength === resultsTotalCount.current}
              />
            </>
          )}
        </div>

        {!(isLoadingApplications || isFetchingApplications) && (
          <>
            <NoDataBox
              show={isNoData.current}
              entityTitle="Application"
              onCreate={() => navigate('./new')}
            />
            <NoResultsBox show={isNoResults.current} onResetFilters={clearQuery} />
          </>
        )}
      </div>

      <div ref={loader} />
    </PageContainer>
  );
};

export { ApplicationManagement };
