import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import {
  Grid,
  Typography,
  Button,
  Paper,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  CircularProgress,
  Switch,
  Tooltip,
  Stack,
  Slider,
  Input as MuiInput,
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Editor, { DiffEditor, useMonaco } from '@monaco-editor/react';
import PropTypes from 'prop-types';
import { updateRuleSuccess } from '../../actions/ruleBuilder';
import { selectCurrentRule, selectIsRuleLoading } from '../../selectors/ruleBuilder';
import { fillColorMap } from '../../constants/constants';
import { formatIsoDate } from '../../utils/formatter';
import RuleVetos from './ruleVetos';
import { vetoTypes } from './constants';
import {
  useAddNewRuleMutation,
  useGetRuleCategoriesPlainQuery,
  useGetRuleHistoryQuery,
  useUpdateRuleMutation,
} from '../../services/gatewayApi/rulesApi';
import { checkProductApiName, toastSuccess } from '../../utils';

const RuleForm = (props) => {
  const { urlId, handleCloseModal } = props;
  const dispatch = useDispatch();
  const monaco = useMonaco();

  const isLoading = useSelector(selectIsRuleLoading);
  const currentRule = useSelector(selectCurrentRule);
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const productId = parseInt(selectedProductId, 10);

  const [functionFilterCase, setFunctionFilterCase] = useState('');
  const [showHistory, setShowHistory] = useState(false);

  const [saveNewRule] = useAddNewRuleMutation();
  const [updateExistingRule] = useUpdateRuleMutation();
  const { data: ruleCategories = [], isLoading: ruleCategoriesLoading } =
    useGetRuleCategoriesPlainQuery({ productId });
  const { data: ruleHistory = [], isLoading: isHistoryLoaded = false } = useGetRuleHistoryQuery(
    { ruleId: currentRule.id },
    { skip: !showHistory },
  );

  const [selectedRevision, setSelectedRevision] = useState(ruleHistory[0]);

  useEffect(() => {
    if (ruleHistory[1]) setSelectedRevision(ruleHistory[1]);
  }, [ruleHistory]);

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('flippedDiffTheme', {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
          'diffEditor.insertedTextBackground': '#ff000033',
          'diffEditor.removedTextBackground': '#28d22833',
        },
      });
    }
  }, [monaco]);

  const vetoTypesForTenant = vetoTypes.filter((el) => checkProductApiName(el.apiName));

  const validationSchema = yup.object().shape({
    title: yup.string().required('Required'),
    description: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      title: currentRule.title,
      description: currentRule.description,
      categoryType: currentRule.categoryType,
      ruleCategoryId: currentRule.ruleCategoryId,
      answer: currentRule.answer,
      seonRule: currentRule.seonRule,
      script: currentRule.script,
      id: currentRule.id,
      score: currentRule.score || 0,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const model = {
        rule: {
          name: values.title,
          description: values.description,
          categoryType: values.categoryType,
          ruleCategoryId: values.ruleCategoryId,
          answer: values.answer,
          seonRule: values.seonRule,
          script: monaco.editor.getModels()[0].getValue(),
          id: currentRule.id,
          score: values.answer === 'MODERATION' ? values.score : 0,
        },
      };
      formik.values.script = model.rule.script;
      if (typeof currentRule.id !== 'number') {
        // if new rule
        const newModel = {
          ruleSetId: urlId,
          rule: { ...model.rule, id: null },
        };
        saveNewRule(newModel).then((res) => {
          dispatch(updateRuleSuccess(model.rule)); // TODO remove it after refactoring rulesets page
          handleCloseModal({ saveAttempt: true });
          toastSuccess(`${res.data.name} saved`);
        });
      } else {
        // if rule exist
        updateExistingRule(model).then((res) => {
          dispatch(updateRuleSuccess(model.rule)); // TODO remove it after refactoring rulesets page
          handleCloseModal({ saveAttempt: true });
          toastSuccess(`${res.data.name} updated`);
        });
      }
    },
  });

  const functionFilter = (item) => {
    const { script, description } = item;
    if (!script) return 'no script';
    return (
      script.toLowerCase().search(functionFilterCase.toLowerCase()) > -1 ||
      description.toLowerCase().search(functionFilterCase.toLowerCase()) > -1
    );
  };

  const handleFilterChange = (e) => {
    setFunctionFilterCase(e.target.value);
  };

  const RenderFunctions = () => {
    if (!props.functionList.length) return 'no functions';
    return props.functionList.filter(functionFilter).map((item) => (
      <Tooltip title={item.description} key={item.id}>
        <Typography variant="body2">
          {item.script === null ? 'no script' : item.script.slice(9, item.script.indexOf(')') + 1)}
        </Typography>
      </Tooltip>
    ));
  };

  const toggleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  const handleRevisionChange = (e) => {
    setSelectedRevision(ruleHistory[e.target.value]);
  };

  const handleBlur = () => {
    if (formik.values.score < 0) {
      formik.setFieldValue('score', 0);
    } else if (formik.values.score > 100) {
      formik.setFieldValue('score', 100);
    }
  };

  return (
    <Grid container direction="row" spacing={0} wrap="nowrap">
      <Grid item md>
        <Helmet title="Edit Rule" />

        <Paper sx={{ py: 6, px: 8, margin: 6 }}>
          <form onSubmit={formik.handleSubmit}>
            {/* Title Row */}
            <Grid
              container
              wrap="nowrap"
              alignItems="baseline"
              spacing={2}
              justifyContent="space-between"
              pb={3}
              mb={6}
            >
              {/* Title */}
              <Grid item xs={2} md={2}>
                <Typography variant="h1" mb={0}>
                  Rule Form
                </Typography>
              </Grid>

              {/* Secondary title */}
              <Grid item xs={3} md={4}>
                <Typography color="textSecondary">{formik.values.title}</Typography>
              </Grid>

              {/* Title Controls */}

              <Grid item xs={7} md={6}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                  <Stack direction="row" alignItems="center" spacing={2} mr={6}>
                    <Switch
                      disabled={isHistoryLoaded || typeof currentRule.id !== 'number'}
                      checked={showHistory}
                      onChange={toggleShowHistory}
                      name="ShowHistory"
                      color="primary"
                    />
                    <Typography pr={2}>Show history</Typography>
                  </Stack>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                      handleCloseModal({ saveAttempt: false });
                    }}
                  >
                    Close
                  </Button>
                  <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
                    Save Rule
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            {showHistory && (
              <Grid container sx={{ pb: 4 }} spacing={4}>
                <Grid item md={6}>
                  <Typography color="textPrimary">Last saved version:</Typography>
                  <Typography color="textSecondary">
                    Modified by: {currentRule.modifiedByName}
                  </Typography>
                  <Typography color="textSecondary">
                    Modified date:{' '}
                    {currentRule.modifiedDate === null
                      ? formatIsoDate(currentRule.createdDate)
                      : formatIsoDate(currentRule.modifiedDate)}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography color="textPrimary">Previous version:</Typography>

                  <Typography color="textSecondary">
                    Modified by:{' '}
                    {isHistoryLoaded ? (
                      <CircularProgress size={14} />
                    ) : (
                      <>
                        {selectedRevision
                          ? selectedRevision.revision.modifiedByName
                          : 'no history data'}
                      </>
                    )}
                  </Typography>
                  <Typography color="textSecondary">
                    Modified date:{' '}
                    {isHistoryLoaded ? (
                      <CircularProgress size={14} />
                    ) : (
                      <>
                        {selectedRevision
                          ? formatIsoDate(selectedRevision.revision.revisionInstant)
                          : 'no history data'}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <InputLabel id="simple-select-helper-label">Pagination revision</InputLabel>
                  {isHistoryLoaded ? (
                    <LinearProgress />
                  ) : (
                    <>
                      {selectedRevision ? (
                        <Select
                          labelId="Pagination revision"
                          id="simple-select-helper"
                          defaultValue={1}
                          onChange={(e) => {
                            handleRevisionChange(e);
                          }}
                          fullWidth
                        >
                          {ruleHistory.flatMap((item, index) =>
                            index === 0 ? (
                              []
                            ) : (
                              <MenuItem value={index} key={item.revision.revisionNumber}>
                                {`${index}) ${item.revision.revisionNumber}`}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      ) : (
                        <Typography color="textSecondary">no history data</Typography>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}

            {/* Edit Rule Form */}
            <Grid container spacing={2} wrap="nowrap" mb={2}>
              <Grid item md={showHistory ? 6 : 12}>
                <Helmet title="Edit Rule" />
                <Grid container spacing={2} direction="row" justifyContent="space-between">
                  {/* Text fields */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="title"
                      type="text"
                      fullWidth
                      multiline
                      label="Rule Name"
                      name="title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      value={formik.values.title}
                      variant="outlined"
                      error={formik.touched.title && Boolean(formik.errors.title)}
                      sx={{ mb: 8 }}
                    />

                    <TextField
                      id="Description"
                      label="Description"
                      name="description"
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      sx={{ mb: 8 }}
                    />
                  </Grid>

                  {/* Risk / Compliance */}
                  <Grid item xs={6} md={2}>
                    <FormControl component="fieldset" sx={{ mb: 8 }}>
                      <RadioGroup
                        column="true"
                        aria-label="category"
                        name="categoryType"
                        value={formik.values.categoryType}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="RISK"
                          control={<Radio color="primary" />}
                          label="Risk"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="COMPLIANCE"
                          control={<Radio color="primary" />}
                          label="Compliance"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>

                    {formik.values.categoryType === 'COMPLIANCE' && productId === 3 && (
                      <>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="ruleCategoryId-label">Rule Category</InputLabel>
                          <Select
                            labelId="ruleCategoryId-label"
                            disabled={ruleCategoriesLoading}
                            name="ruleCategoryId"
                            color="primary"
                            id="ruleCategoryId"
                            value={formik.values.ruleCategoryId}
                            fullWidth
                            onChange={formik.handleChange}
                            label="Rule Category"
                          >
                            {ruleCategories.map(({ id, name }) => (
                              <MenuItem value={id} key={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {productId === 1 && (
                      <FormControlLabel
                        sx={{ ml: 0, pl: 0 }}
                        control={
                          <Checkbox
                            checked={formik.values.seonRule}
                            onChange={formik.handleChange}
                            name="seonRule"
                            color="primary"
                            id="seonRule"
                          />
                        }
                        label="Seon - Check KYC Candidate"
                      />
                    )}
                    {formik.values.answer === 'MODERATION' && (
                      <Box width={1} mt={4}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Typography sx={{ mr: 2 }}>Score</Typography>
                          </Grid>
                          <Grid item xs>
                            <Slider
                              name="score"
                              value={formik.values.score}
                              onChange={formik.handleChange}
                              aria-labelledby="score-slider"
                            />
                          </Grid>
                          <Grid item>
                            <MuiInput
                              sx={{ width: '40px', ml: 2 }}
                              disableUnderline
                              name="score"
                              value={formik.values.score}
                              size="small"
                              onChange={formik.handleChange}
                              onBlur={handleBlur}
                              inputProps={{
                                min: 0,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>

                  {/* Risk score */}
                  <Grid item xs={6} md={2}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        column="true"
                        aria-label="answer"
                        name="answer"
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="PASS"
                          control={<Radio style={{ color: fillColorMap.PASS }} />}
                          label="Pass"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="LOWRISK"
                          control={<Radio style={{ color: fillColorMap.LOWRISK }} />}
                          label="Low risk"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="MEDIUMRISK"
                          control={<Radio style={{ color: fillColorMap.MEDIUMRISK }} />}
                          label="Medium risk"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="HIGHRISK"
                          control={<Radio style={{ color: fillColorMap.HIGHRISK }} />}
                          label="High risk"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="MODERATION"
                          control={<Radio style={{ color: fillColorMap.MODERATION }} />}
                          label="Moderation"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          sx={{ mb: 0 }}
                          value="REJECT"
                          control={<Radio style={{ color: fillColorMap.REJECT }} />}
                          label="Reject"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {showHistory && (
                <Grid item xs={2} md={6}>
                  <Helmet title="History Rule" />
                  {isHistoryLoaded ? (
                    <Grid container spacing={4} justifyContent="center" style={{ height: 263 }}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      {selectedRevision ? (
                        <Grid container spacing={4} direction="row" justifyContent="space-between">
                          <Grid item xs={12} md={6}>
                            <TextField
                              disabled
                              id="titleHistory"
                              type="text"
                              fullWidth
                              multiline
                              label="Rule Name"
                              name="titleHistory"
                              value={selectedRevision.name}
                              variant="outlined"
                              sx={{ mt: 2, ml: 2 }}
                            />
                            <TextField
                              disabled
                              id="DescriptionHistory"
                              label="DescriptionHistory"
                              name="descriptionHistory"
                              fullWidth
                              multiline
                              rows={4}
                              variant="outlined"
                              value={selectedRevision.description}
                              sx={{ mt: 7, ml: 2 }}
                            />
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FormControl component="fieldset" sx={{ mb: 8 }}>
                              <RadioGroup
                                column="true"
                                aria-label="category"
                                name="categoryTypeHistory"
                                value={selectedRevision.categoryType}
                              >
                                <FormControlLabel
                                  disabled
                                  value="RISK"
                                  control={<Radio color="primary" />}
                                  label="Risk"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="COMPLIANCE"
                                  control={<Radio color="primary" />}
                                  label="Compliance"
                                  labelPlacement="end"
                                />
                              </RadioGroup>
                            </FormControl>
                            {selectedRevision.categoryType === 'COMPLIANCE' && productId === 3 && (
                              <>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel id="ruleCategoryId-labelHistory">
                                    Rule Category
                                  </InputLabel>
                                  <Select
                                    labelId="ruleCategoryId-label"
                                    disabled
                                    name="ruleCategoryIdHistory"
                                    color="primary"
                                    id="ruleCategoryId"
                                    value={selectedRevision.ruleCategoryId}
                                    fullWidth
                                    label="Rule Category"
                                  >
                                    {ruleCategories.map(({ id, name }) => (
                                      <MenuItem value={id} key={id}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </>
                            )}
                            {productId === 1 && (
                              <FormControlLabel
                                sx={{ ml: 0, pl: 0 }}
                                control={
                                  <Checkbox
                                    disabled
                                    checked={selectedRevision.seonRule}
                                    name="seonRuleHistory"
                                    color="primary"
                                    id="seonRule"
                                  />
                                }
                                label="Seon - Check KYC Candidate"
                              />
                            )}
                            {selectedRevision.answer === 'MODERATION' && (
                              <Box width={1} mt={4}>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item>
                                    <Typography color="grey.300" sx={{ mr: 2 }}>
                                      Score
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Slider
                                      disabled
                                      name="score"
                                      value={selectedRevision.score}
                                      aria-labelledby="score-slider"
                                    />
                                  </Grid>
                                  <Grid item>
                                    <MuiInput
                                      disabled
                                      sx={{ width: '40px', ml: 2 }}
                                      disableUnderline
                                      name="score"
                                      value={selectedRevision.score}
                                      size="small"
                                      inputProps={{
                                        min: 0,
                                        max: 100,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                column="true"
                                aria-label="answer"
                                name="answerHistory"
                                value={selectedRevision.answer}
                              >
                                <FormControlLabel
                                  disabled
                                  value="PASS"
                                  control={<Radio />}
                                  label="Pass"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="LOWRISK"
                                  control={<Radio />}
                                  label="Low risk"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="MEDIUMRISK"
                                  control={<Radio />}
                                  label="Medium risk"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="HIGHRISK"
                                  control={<Radio />}
                                  label="High risk"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="MODERATION"
                                  control={<Radio />}
                                  label="Moderation"
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  disabled
                                  value="REJECT"
                                  control={<Radio />}
                                  label="Reject"
                                  labelPlacement="end"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={4} justifyContent="center" style={{ height: 263 }}>
                          <Typography>no history data</Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </Grid>

            {/* Script */}

            <Typography variant="caption1" color="textSecondary" mb={2}>
              Script:
            </Typography>

            {!monaco && isHistoryLoaded ? (
              <Paper variant="outlined" sx={{ padding: 4 }}>
                <Grid container justifyContent="center" alignItems="center" style={{ height: 100 }}>
                  <CircularProgress />
                </Grid>
              </Paper>
            ) : (
              <Paper variant="outlined" sx={{ padding: 4, mb: 8 }}>
                {showHistory ? (
                  <Grid>
                    {isHistoryLoaded ? (
                      <Paper>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          style={{ height: 156 }}
                        >
                          <CircularProgress />
                        </Grid>
                      </Paper>
                    ) : (
                      <DiffEditor
                        sx={{ margin: 2 }}
                        height="15vh"
                        name="script"
                        defaultLanguage="javascript"
                        original={formik.values.script}
                        modified={selectedRevision ? selectedRevision.script : 'no history data'}
                        theme="flippedDiffTheme"
                        options={{
                          originalEditable: true,
                          readOnly: true,
                          renderIndicators: false,
                        }}
                      />
                    )}
                  </Grid>
                ) : (
                  <Editor
                    name="script"
                    sx={{ margin: 2 }}
                    height="15vh"
                    defaultLanguage="javascript"
                    value={formik.values.script}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    error={formik.touched.script && Boolean(formik.errors.script)}
                  />
                )}
              </Paper>
            )}
          </form>

          {vetoTypesForTenant.length > 0 && (
            <>
              {/* Rule Vetos */}
              <Helmet title="Add Rule Vetos" />

              {currentRule && typeof currentRule.id === 'number' ? (
                <RuleVetos ruleId={currentRule.id} productId={productId} />
              ) : (
                <Typography sx={{ py: 8, color: 'grey.600', textAlign: 'center' }}>
                  To add Vetos, you need to save the Rule / Rule Set first
                </Typography>
              )}
            </>
          )}
        </Paper>
      </Grid>

      {!showHistory && (
        <Grid item md={3} className="sideBar">
          <Paper sx={{ padding: 6, margin: 6 }} elevation={3}>
            <div className="form-group">
              <label htmlFor="functionFilter" className="form-label">
                Functions
              </label>
              <div>
                <input
                  placeholder="Filter Functions"
                  id="functionFilter"
                  type="text"
                  className="form-control"
                  value={functionFilterCase}
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                />
              </div>
            </div>
            <div className="functions">
              <RenderFunctions />
              <ReactTooltip html place="bottom" />
            </div>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default RuleForm;

RuleForm.propTypes = {
  urlId: PropTypes.string,
  handleCloseModal: PropTypes.func,
  functionList: PropTypes.array,
};
