import React from 'react';
import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import { idenfyStatuses } from '../../constants/idenfyStatuses';
import { idenfyTags } from '../../constants/idenfyTags';

import './DescriptionTags.scss';

export const DescriptionTags = ({ description }) => {
  const result = [];

  try {
    const renderValueChip = (value, idx = 0) => {
      const statusInfo = idenfyStatuses.find((s) => s.key === value);

      return (
        <Tooltip title={statusInfo?.value ?? 'Undefined status.'} key={`statusChip_${idx}`}>
          <Chip
            size="small"
            color={statusInfo?.color ?? 'warning'}
            label={value.replaceAll(/_/gi, ' ')}
            sx={{ mr: 2, my: 1 }}
          />
        </Tooltip>
      );
    };

    const camelToSentenceCase = (text) => {
      const result = text.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    let extDescriptionTags = '';
    try {
      extDescriptionTags = JSON.parse(description);
    } catch {}

    for (var tag in extDescriptionTags) {
      const tagInfo = idenfyTags.find((t) => t.key === tag);
      const value = extDescriptionTags[tag];
      const isArray = Array.isArray(value);

      if (value && (!isArray || (isArray && value.length > 0))) {
        result.push(
          <Grid
            container
            key={tagInfo?.value}
            sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
          >
            <Grid item sx={{ width: '40%', py: 3, color: 'grey.900', typography: 'body1' }}>
              <Tooltip title={tagInfo?.value ?? 'Undefined tag info'}>
                <Typography variant="body2" sx={{ color: 'grey.600' }}>
                  {camelToSentenceCase(tag)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid sx={{ width: '60%', py: 2 }}>
              {isArray ? value.map((v, idx) => renderValueChip(v, idx)) : renderValueChip(value)}
            </Grid>
          </Grid>,
        );
      }
    }
  } catch (e) {
    console.error(e);
  }

  return (
    <div className="DescriptionTags">
      {result}

      {result.length === 0 && (
        <div className="no-data">
          <Typography variant="body1" component="p" color="grey.900">
            No current KYC results...
          </Typography>
        </div>
      )}
    </div>
  );
};
