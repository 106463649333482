import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { CPForms } from './CPForms';
import { DynamicForms } from './DynamicForms';

export const FormsFFWrapper = (): JSX.Element => {
  const { newApplicationForms } = useSelector((state: RootState) => state.featureFlags);

  return newApplicationForms ? <CPForms /> : <DynamicForms />;
};
