import React, { useState } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { FormDatePicker, Panel } from 'uikit';
import { ValueLabel } from 'types';
import { FormTextField } from 'uikit/FormFields/FormTextField';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { NumericFormat } from 'react-number-format';
import { FormRadio, FormSelect, FormMultiSelect } from '../../../uikit';
import { formatIsoDate } from '../../../utils/formatter';
import { countryOptions } from '../../ApplicationManagement/constants';

const mockOptions: ValueLabel[] = [
  { value: 10, label: 'Ten' },
  { value: 20, label: 'Twenty' },
  { value: 30, label: 'Thirty' },
];

export const FormFieldsSection = () => {
  const currentDate = new Date();
  const [isError, setIsError] = useState(false);
  const [clear, setClear] = useState(false);
  const [prefix, setPrefix] = useState(false);
  const [suffix, setSuffix] = useState(false);
  const [multiline, setMultiline] = useState(false);
  const [label, setLabel] = useState('Some label...');
  const [placeholder, setPlaceholder] = useState('Some placeholder...');
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [selectedMulti, setSelectedMulti] = useState<(string | number)[]>([]);
  const [date, setDate] = useState(currentDate);
  const [radioValue, setRadioValue] = useState(10);

  const Prefix = () => (
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <Checkbox
          disabled={disabled}
          sx={{ mr: 3 }}
          checked={suffix}
          onChange={() => setSuffix((prev) => !prev)}
        />
      }
      label="Show/Hide Suffix"
    />
  );
  const Suffix = () => <AgricultureIcon style={{ fontSize: '18px' }} />;

  const onChangeDateHandler = (newDate) => {
    setDate(newDate);
  };

  return (
    <div>
      <Typography variant="h2" mb={5}>
        Form Fields
      </Typography>

      <Panel>
        <Typography variant="h3">props playground:</Typography>
        <Box display="flex" flexDirection="row" gap={2} ml="11px" flexWrap="wrap">
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 3 }}
                checked={disabled}
                onChange={() => setDisabled((prev) => !prev)}
              />
            }
            label="disabled"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 3 }}
                checked={isError}
                onChange={() => setIsError((prev) => !prev)}
              />
            }
            label="is Error"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{ mr: 3 }} checked={clear} onChange={() => setClear((prev) => !prev)} />
            }
            label="clear"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 3 }}
                checked={prefix}
                onChange={() => setPrefix((prev) => !prev)}
              />
            }
            label="prefix"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 3 }}
                checked={suffix}
                onChange={() => setSuffix((prev) => !prev)}
              />
            }
            label="suffix"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{ mr: 3 }}
                checked={multiline}
                onChange={() => setMultiline((prev) => !prev)}
              />
            }
            label="multiline"
          />
          <FormControlLabel
            control={
              <input
                type="text"
                onChange={(e) => setLabel(e.target.value)}
                style={{ marginRight: '8px' }}
                defaultValue={label}
              />
            }
            label="input label"
          />
          <FormControlLabel
            control={
              <input
                type="text"
                onChange={(e) => setPlaceholder(e.target.value)}
                style={{ marginRight: '8px' }}
                defaultValue={placeholder}
              />
            }
            label="input placeholder"
          />
          <FormControlLabel
            control={
              <input
                type="text"
                onChange={(e) => setInfo(e.target.value)}
                style={{ marginRight: '8px' }}
                defaultValue={info}
              />
            }
            label="input info"
          />
        </Box>
        <Divider />

        <Typography variant="h3" sx={{ pt: 6 }}>
          component FormRadio:
        </Typography>
        <FormRadio
          options={mockOptions}
          value={radioValue}
          label={label}
          error={isError}
          disabled={disabled}
          onChange={(e) => setRadioValue(e.target.value)}
        />
        <Typography variant="body1">
          {radioValue || 'controlled radio choose result we will see here: ...'}
        </Typography>

        <Typography variant="h3" sx={{ pt: 6 }}>
          component FormTextField:
        </Typography>
        <NumericFormat
          thousandSeparator=","
          decimalScale={2}
          customInput={FormTextField}
          sx={{ py: 12 }}
          label="Financial format input"
          placeholder={placeholder}
          disabled={disabled}
          clear={clear ? () => setResult('') : undefined}
          error={isError}
          info={info}
          prefixForm={prefix ? <Prefix /> : undefined}
          onChange={(e) => setResult(e.target.value)}
        />
        <FormTextField
          sx={{ py: 12 }}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          clear={clear ? () => setResult('') : undefined}
          error={isError}
          multiline={multiline}
          info={info}
          prefixForm={prefix ? <Prefix /> : undefined}
          suffix={suffix ? <Suffix /> : undefined}
          onChange={(e) => setResult(e.target.value)}
        />
        <Typography variant="body1">
          {result || 'controlled input result we will see here: ...'}
        </Typography>

        <Typography variant="h3" sx={{ pt: 6 }}>
          component FormSelect:
        </Typography>
        <FormSelect
          sx={{ py: 12 }}
          label={label}
          disabled={disabled}
          error={isError}
          info={info}
          prefixForm={prefix ? <Prefix /> : undefined}
          suffix={suffix ? <Suffix /> : undefined}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          options={mockOptions}
        />
        <Typography variant="body1">
          {selected || 'Selected result value we will see here: ...'}
        </Typography>

        <Typography variant="h3" sx={{ pt: 6 }}>
          component FormMultiSelect:
        </Typography>
        <FormMultiSelect
          sx={{ py: 12 }}
          label={label}
          disabled={disabled}
          error={isError}
          options={countryOptions}
          placeholder={placeholder}
          values={countryOptions.filter((option: ValueLabel) =>
            selectedMulti.some((v) => option.value === v),
          )}
          onChange={(event, newValue: ValueLabel[]) => {
            setSelectedMulti(newValue.map((nextValue: ValueLabel) => nextValue.value));
          }}
        />
        <Typography variant="body1">
          {selectedMulti.length > 0 ? (
            <Typography component="span">{`[ ${selectedMulti?.join(', ')} ]`}</Typography>
          ) : (
            'Selected result value we will see here: ...'
          )}
        </Typography>

        <Typography variant="h3" sx={{ pt: 6 }}>
          component FormDatePicker:
        </Typography>
        <FormDatePicker
          sx={{ py: 12 }}
          label={label}
          disabled={disabled}
          error={isError}
          info={info}
          prefixForm={prefix ? <Prefix /> : undefined}
          suffix={suffix ? <Suffix /> : undefined}
          value={date}
          onChange={onChangeDateHandler}
        />
        <Typography variant="body1">
          {formatIsoDate(date) || 'Selected date value we will see here: ...'}
        </Typography>
      </Panel>
    </div>
  );
};
