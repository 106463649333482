import { gatewayApi } from '../gatewayApi';

const scoreTresholdsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAllByRuleSetId: build.query({
      query: ({ ruleSetId }) => ({
        url: `score-thresholds/all/${ruleSetId}`,
      }),
      providesTags: (result, error, { ruleSetId }) => [{ type: 'ScoreTresholds', id: ruleSetId }],
    }),

    addTreshold: build.mutation({
      query: (body) => ({
        url: `score-thresholds`,
        method: 'POST',
        body,
      }),
    }),

    updateTreshold: build.mutation({
      query: (model) => {
        return {
          url: `score-thresholds/${model.id}`,
          method: 'PUT',
          body: model,
        };
      },
      invalidatesTags: (result, error, { ruleSetId }) => [
        { type: 'ScoreTresholds', id: ruleSetId },
      ],
    }),

    deleteTreshold: build.mutation({
      query: ({ id }) => ({
        url: `score-thresholds/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { ruleSetId }) => [
        { type: 'ScoreTresholds', id: ruleSetId },
      ],
    }),
  }),
});

export const {
  useGetAllByRuleSetIdQuery,
  useAddTresholdMutation,
  useUpdateTresholdMutation,
  useDeleteTresholdMutation,
} = scoreTresholdsApi;
