import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
} from '@mui/material';
import { RiArrowRightFill, RiArrowRightLine, RiFileCopyLine, RiMailLine } from 'react-icons/ri';
import { checkProductApiName } from 'utils';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import { formatIsoDate } from 'utils/formatter';
import { DATE_MMM_FORMAT, TIME_FORMAT, TOOLTIP_DELAY } from 'constants/constants';
import { GetLamp } from '../Rules/RulesTree/GetLamp';
import { AssigneeChip, CaseStatusChip, TriggeredRulesChip } from './Chips';
import { Case } from './types';

import 'styles/tables.scss';

const SmallTableCell = styled(TableCell)(() => ({
  paddingTop: '14px',
  paddingBottom: '14px',
  verticalAlign: 'top',
  borderRadius: '0 !important',
})) as typeof TableCell;

const LargeTableCell = styled(TableCell)(() => ({
  paddingTop: '20px',
  paddingBottom: '20px',
  verticalAlign: 'top',
  borderRadius: '0 !important',
})) as typeof TableCell;

interface CaseRowProps {
  caseData: Case;
  users: any;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  isChecked: boolean;
  setCheckedItem: any;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
  prefixRow?: any;
  productId: number;
}

export const CaseRow: React.FC<CaseRowProps> = React.memo(
  ({
    caseData,
    users,
    setSelectedItemId,
    isChecked,
    setCheckedItem,
    setOpenDetailsDrawer,
    selected = false,
    productId,
  }) => {
    const theme = useTheme();

    const [isHover, setIsHover] = useState<boolean>(false);

    const {
      caseId,
      caseStatus,
      caseType,
      description,
      companyName,
      customerEmail,
      customerName,
      riskLevel,
      referenceId,
      haystackClientId,
      senderName,
      beneficiaryName,
      transactionAmount,
      createdDateTimeIso,
    } = caseData;

    const isGateway = checkProductApiName('GATEWAY');
    const isApplicationType = caseType === 'APPLICATION';

    const ellipsisStyle = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
    };

    const goToDetailsLink = isApplicationType
      ? `/application-management/${haystackClientId}/applications/${referenceId}`
      : `/cases/${caseId}`;

    return (
      <>
        <TableRow
          hover
          selected={selected}
          onClick={() => {
            setSelectedItemId(caseId);
            setOpenDetailsDrawer(true);
          }}
          className="table-row table-hover"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Checkbox */}
          <LargeTableCell sx={{ width: '1%' }}>
            <Checkbox
              checked={isChecked}
              onClick={(e) => e.stopPropagation()}
              onChange={(_, checked) => setCheckedItem(checked)}
            />
          </LargeTableCell>

          {/* Date-time */}
          <LargeTableCell sx={{ width: '1%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography
                variant="body"
                color={theme.palette.text.secondary}
                sx={{ whiteSpace: 'nowrap' }}
              >
                {formatIsoDate(createdDateTimeIso, DATE_MMM_FORMAT)}{' '}
              </Typography>
              <Typography variant="body" color={theme.palette.text.placeholder}>
                {formatIsoDate(createdDateTimeIso, TIME_FORMAT)}
              </Typography>
            </Stack>
          </LargeTableCell>

          {/* Risk status  */}
          {!isApplicationType && (
            <LargeTableCell sx={{ width: '1%', paddingTop: '25px' }}>
              <GetLamp answer={riskLevel} alwaysFilled width="8px" />
            </LargeTableCell>
          )}

          {/* Company name */}
          {isApplicationType && (
            <LargeTableCell sx={{ ...ellipsisStyle, width: '200px', maxWidth: '200px' }}>
              <Typography variant="body" color={theme.palette.text.secondary} sx={ellipsisStyle}>
                {companyName ?? 'Unknown'}
              </Typography>
            </LargeTableCell>
          )}

          {/* Case description */}
          <LargeTableCell sx={{ ...ellipsisStyle, maxWidth: '350px' }}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="start"
              spacing={2}
              sx={{ maxWidth: '350px' }}
            >
              {!isApplicationType && <TriggeredRulesChip description={description} />}
              <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                <Typography variant="body" sx={ellipsisStyle}>
                  {description}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ...ellipsisStyle,
                    maxWidth: '100%',
                  }}
                >
                  {productId == 3 && !isApplicationType && (
                    <>
                      <Tooltip title="Sender Name" enterDelay={TOOLTIP_DELAY}>
                        <Typography
                          variant="bodySmall"
                          color={theme.palette.text.danger}
                          component="span"
                          sx={ellipsisStyle}
                        >
                          {senderName || 'Unknown'}
                        </Typography>
                      </Tooltip>
                      <RiArrowRightLine
                        size="10px"
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                      />
                      <Tooltip title="Beneficiary Name" enterDelay={TOOLTIP_DELAY}>
                        <Typography
                          variant="bodySmall"
                          color={theme.palette.text.success}
                          component="span"
                          sx={ellipsisStyle}
                        >
                          {beneficiaryName || 'Unknown'}
                        </Typography>
                      </Tooltip>
                    </>
                  )}

                  {isGateway && (
                    <>
                      <Tooltip title="Customer Name" enterDelay={TOOLTIP_DELAY}>
                        <Typography variant="bodySmall" component="span">
                          {customerName || 'Unknown'}
                        </Typography>
                      </Tooltip>

                      {customerEmail && (
                        <>
                          <RiMailLine
                            size="14px"
                            style={{ marginLeft: '8px', marginRight: '8px' }}
                          />
                          <Tooltip title="Customer Email" enterDelay={TOOLTIP_DELAY}>
                            <Typography variant="bodySmall" component="span">
                              {customerEmail}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Stack>
          </LargeTableCell>

          {/* Transaction Amount */}
          {!isApplicationType && (
            <LargeTableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
              <Typography variant="body" color={theme.palette.text.secondary}>
                {transactionAmount}
              </Typography>
            </LargeTableCell>
          )}

          {/* Assignee chip */}
          <SmallTableCell sx={{ ...ellipsisStyle, maxWidth: '140px' }}>
            <AssigneeChip caseData={caseData} users={users} />
          </SmallTableCell>

          {/* Case status chip */}
          <SmallTableCell sx={{ ...ellipsisStyle, maxWidth: '200px' }}>
            <CaseStatusChip caseId={caseId} caseStatus={caseStatus} caseType={caseType} />
          </SmallTableCell>

          {/* Row action buttons */}
          <SmallTableCell sx={{ width: '1%' }}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              {!isApplicationType && (
                <Tooltip title="Copy External ID">
                  <IconButton
                    color={theme.palette.contrast.main}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(referenceId).then(
                        () => toastCopySuccess('External ID'),
                        () => toastCopyError('External ID'),
                      );
                    }}
                    sx={{ opacity: isHover ? 1 : 0 }}
                  >
                    <RiFileCopyLine size="14px" />
                  </IconButton>
                </Tooltip>
              )}
              <Link to={goToDetailsLink}>
                <IconButton
                  color={theme.palette.contrast.main}
                  sx={{ ml: 2, opacity: isHover ? 1 : 0 }}
                >
                  <RiArrowRightFill size="14px" />
                </IconButton>
              </Link>
            </Stack>
          </SmallTableCell>
        </TableRow>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isChecked === nextProps.isChecked &&
    prevProps.caseData === nextProps.caseData &&
    prevProps.selected === nextProps.selected,
);
