import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, ISelectOption, ModalDialog } from 'uikit';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { useCreateUboMutation, useUpdateUboMutation } from 'services/gatewayApi/ubosApi';
import { Ubo } from './types';
import { documentTypes, genders, initialUbo } from './constants';

interface UboDialogProps {
  open: boolean;
  onClose: any;
  ubo?: Ubo;
  countries: any[];
}

export const UboDialog: React.FC<UboDialogProps> = ({
  open,
  onClose,
  ubo = initialUbo,
  countries,
}) => {
  const isNew = ubo.id === 'new';
  const entityName = 'Associated Individual';
  const title = `${isNew ? 'New' : 'Edit'} ${entityName}`;
  const actionTitle = isNew ? 'Create' : 'Save';

  const [createUbo] = useCreateUboMutation();
  const [updateUbo] = useUpdateUboMutation();

  const validationSchema = yup.object({
    firstName: yup.string().required('Required'),
    middleName: yup.string(),
    lastName: yup.string().required('Required'),
    gender: yup.string().required('Required'),
    nationality: yup.string().required('Required'),
    dateOfBirth: yup
      .date()
      .required('Required')
      .transform((curr, orig) => (orig === '' ? null : curr)),
    placeOfBirth: yup.string().required('Required'),
    email: yup.string().email(),

    documentNum: yup.string().required('Required'),
    documentType: yup.string().required('Required'),
    documentCountryIssue: yup.string().required('Required'),
    documentExpiry: yup
      .date()
      .required('Required')
      .transform((curr, orig) => (orig === '' ? null : curr)),

    address: yup.string().required('Required'),
    city: yup.string().required('Required'),
    state: yup.string(),
    country: yup.string().required('Required'),
    postCode: yup.string(),

    screeningResult: yup.string().required('Required'),
    screeningResultNote: yup.string(),
    nationalityAlpha2: yup.string(),
  });

  const initialValues = { ...ubo };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const mutationPromise = isNew
        ? await createUbo({ body: values })
        : await updateUbo({ body: values, id: ubo.id });

      // @ts-ignore
      if (!mutationPromise.error) {
        isNew ? toastCreateSuccess(entityName) : toastUpdateSuccess(entityName);

        resetForm();
        onClose();
      }
    },
  });

  const countriesValueLabel = countries
    ? countries.map((c) => ({ value: c.countryCode, label: c.name }))
    : [];
  const countriesValueLabelAlpha = countries
    ? countries.map((c) => ({ value: c.countryCode, label: `${c.countryCode} - ${c.name}` }))
    : [];

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      handleAction={() => formik.handleSubmit()}
      actionTitle={actionTitle}
      isLoading={formik.isSubmitting}
    >
      <Stack spacing={4}>
        <Typography variant="subtitle1" component="span">
          Basic Information
        </Typography>
        <>
          <TextField
            type="text"
            fullWidth
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="standard"
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            disabled={formik.isSubmitting}
          />

          <TextField
            type="text"
            fullWidth
            label="Middle Name"
            name="middleName"
            onChange={formik.handleChange}
            value={formik.values.middleName}
            variant="standard"
            error={formik.touched.middleName && Boolean(formik.errors.middleName)}
            disabled={formik.isSubmitting}
          />

          <TextField
            type="text"
            fullWidth
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="standard"
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            disabled={formik.isSubmitting}
          />

          <TextField
            select
            fullWidth
            name="gender"
            label="Gender"
            value={formik.values.gender}
            error={!!formik.errors.gender}
            onChange={formik.handleChange}
            variant="standard"
            disabled={formik.isSubmitting}
          >
            {genders?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Autocomplete
            fullWidth
            options={countriesValueLabel}
            autoHighlight
            value={
              formik.values.nationality
                ? countriesValueLabel.find((c) => c.value === formik.values.nationality)
                : null
            }
            onChange={(event, option: ISelectOption) => {
              formik.setFieldValue('nationality', option ? option.value : '');
              formik.setFieldValue('nationalityAlpha2', option ? option.value : '');
            }}
            getOptionLabel={(option: ISelectOption) => option?.label}
            disabled={formik.isSubmitting}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nationality"
                variant="standard"
                error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />

          <DatePicker
            label="Date of Birth"
            name="dateOfBirth"
            value={formik.values.dateOfBirth}
            variant="standard"
            error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />

          <Autocomplete
            fullWidth
            options={countriesValueLabel}
            autoHighlight
            value={
              formik.values.placeOfBirth
                ? countriesValueLabel.find((c) => c.value === formik.values.placeOfBirth)
                : null
            }
            onChange={(event, option: ISelectOption) =>
              formik.setFieldValue('placeOfBirth', option ? option.value : '')
            }
            getOptionLabel={(option: ISelectOption) => option?.label}
            disabled={formik.isSubmitting}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Place of Birth"
                variant="standard"
                error={formik.touched.placeOfBirth && Boolean(formik.errors.placeOfBirth)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />

          <TextField
            type="email"
            fullWidth
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="standard"
            error={formik.touched.email && Boolean(formik.errors.email)}
            disabled={formik.isSubmitting}
          />
        </>

        <br />
        <Typography variant="subtitle1" component="span">
          ID Document
        </Typography>
        <>
          <TextField
            type="text"
            fullWidth
            label="Document Number"
            name="documentNum"
            onChange={formik.handleChange}
            value={formik.values.documentNum}
            variant="standard"
            error={formik.touched.documentNum && Boolean(formik.errors.documentNum)}
            disabled={formik.isSubmitting}
          />

          <TextField
            select
            fullWidth
            name="documentType"
            label="Document Type"
            value={formik.values.documentType}
            error={!!formik.errors.documentType}
            onChange={formik.handleChange}
            variant="standard"
            disabled={formik.isSubmitting}
          >
            {documentTypes?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Autocomplete
            fullWidth
            options={countriesValueLabel}
            autoHighlight
            value={
              formik.values.documentCountryIssue
                ? countriesValueLabel.find((c) => c.value === formik.values.documentCountryIssue)
                : null
            }
            onChange={(event, option: ISelectOption) =>
              formik.setFieldValue('documentCountryIssue', option ? option.value : '')
            }
            getOptionLabel={(option: ISelectOption) => option?.label}
            disabled={formik.isSubmitting}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Issuing Country"
                variant="standard"
                error={
                  formik.touched.documentCountryIssue && Boolean(formik.errors.documentCountryIssue)
                }
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />

          <DatePicker
            label="Expiry"
            name="documentExpiry"
            value={formik.values.documentExpiry}
            variant="standard"
            error={formik.touched.documentExpiry && Boolean(formik.errors.documentExpiry)}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />
        </>

        <br />
        <Typography variant="subtitle1" component="span">
          Address
        </Typography>
        <>
          <TextField
            type="text"
            fullWidth
            label="Address"
            name="address"
            onChange={formik.handleChange}
            value={formik.values.address}
            variant="standard"
            error={formik.touched.address && Boolean(formik.errors.address)}
            disabled={formik.isSubmitting}
          />

          <TextField
            type="text"
            fullWidth
            label="City"
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            variant="standard"
            error={formik.touched.city && Boolean(formik.errors.city)}
            disabled={formik.isSubmitting}
          />

          <TextField
            type="text"
            fullWidth
            label="State/Region"
            name="state"
            onChange={formik.handleChange}
            value={formik.values.state}
            variant="standard"
            error={formik.touched.state && Boolean(formik.errors.state)}
            disabled={formik.isSubmitting}
          />

          <Autocomplete
            fullWidth
            options={countriesValueLabel}
            autoHighlight
            value={
              formik.values.country
                ? countriesValueLabel.find((c) => c.value === formik.values.country)
                : null
            }
            onChange={(event, option: ISelectOption) =>
              formik.setFieldValue('country', option ? option.value : '')
            }
            getOptionLabel={(option: ISelectOption) => option?.label}
            disabled={formik.isSubmitting}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="standard"
                error={formik.touched.country && Boolean(formik.errors.country)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />

          <TextField
            type="text"
            fullWidth
            label="Post Code"
            name="postCode"
            onChange={formik.handleChange}
            value={formik.values.postCode}
            variant="standard"
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            disabled={formik.isSubmitting}
          />
        </>

        <br />
        <Typography variant="subtitle1" component="span">
          Other Information
        </Typography>
        <>
          <TextField
            type="text"
            fullWidth
            label="Screening Result"
            name="screeningResult"
            onChange={formik.handleChange}
            value={formik.values.screeningResult}
            variant="standard"
            error={formik.touched.screeningResult && Boolean(formik.errors.screeningResult)}
            disabled={formik.isSubmitting}
          />

          <TextField
            type="text"
            fullWidth
            multiline
            label="Screening Result Note"
            name="screeningResultNote"
            onChange={formik.handleChange}
            value={formik.values.screeningResultNote}
            variant="standard"
            error={formik.touched.screeningResultNote && Boolean(formik.errors.screeningResultNote)}
            disabled={formik.isSubmitting}
          />

          <Autocomplete
            fullWidth
            options={countriesValueLabelAlpha}
            autoHighlight
            value={
              formik.values.nationalityAlpha2
                ? countriesValueLabelAlpha.find((c) => c.value === formik.values.nationalityAlpha2)
                : null
            }
            getOptionLabel={(option: ISelectOption) => option?.label}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nationality Alpha-2"
                variant="standard"
                error={formik.touched.nationalityAlpha2 && Boolean(formik.errors.nationalityAlpha2)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </>
      </Stack>
    </ModalDialog>
  );
};
