import React from 'react';
import './Skeleton.scss';

interface SkeletonProps {
  count: number;
  height: number;
  sectionsCount?: number;
}

const Skeleton: React.FC<SkeletonProps> = ({ count, height, sectionsCount = 4 }) => {
  const itemsCount = Array(count).fill(0);
  const sections = Array(sectionsCount).fill(0);

  const skeletonBlockHeight = height ? { height: `${height}px` } : {};

  return (
    <div className="Skeleton">
      {itemsCount.map((_item, i) => (
        <div key={i} style={skeletonBlockHeight} className="SkeletonBlock">
          {sections.map((_item, j) => (
            <div key={j} className="SkeletonBlock--section" />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
