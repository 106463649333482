/*
// Wrap your header to this component to make it sticky
// TODO:
// 1. Update height of child component after screen size change.
*/

import React, { useEffect, useState, useRef } from 'react';
import './StickyHeader.scss';
import { useWindowSize } from 'hooks';

interface StickyHeaderProps {
  children?: React.ReactChildren | React.ReactNode | React.ReactElement;
  preventHiding?: boolean;
  bgColor?: string;
}

type ScrollDirection = 'up' | 'down';

const StickyHeader: React.FC<StickyHeaderProps> = ({ children, preventHiding = true, bgColor }) => {
  const [height, setHeight] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [lastScrollDirection, setLastScrollDirection] = useState<ScrollDirection>('up');
  const stickyHeaderContent = useRef<HTMLDivElement>();

  const windowSize = useWindowSize({ debounced: true });
  const preventHidingRef = useRef(preventHiding);

  const handleScroll = (e: Event) => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    scrollPosition - lastScrollTop > 0 && !preventHiding
      ? setLastScrollDirection('down')
      : setLastScrollDirection('up');

    setLastScrollTop(scrollPosition);
  }, [scrollPosition]);

  const updateWrapperSize = () => {
    setHeight(stickyHeaderContent.current.getBoundingClientRect().height - 32);
  };

  useEffect(() => {
    updateWrapperSize();
  }, [windowSize]);

  useEffect(() => {
    updateWrapperSize();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (preventHidingRef.current && !preventHiding) {
      setLastScrollDirection('up');
    }
    preventHidingRef.current = preventHiding;
  }, [preventHiding]);

  return (
    <div className="StickyHeader">
      <div
        id="StickyHeaderPosition"
        className={`StickyHeaderPosition ${scrollPosition > 0 ? 'StickyHeader--shadow' : ''}`}
        style={{
          top:
            scrollPosition > height - 16 && lastScrollDirection === 'down' && !preventHiding
              ? `-${height}px`
              : '0',
          backgroundColor: bgColor ?? '',
        }}
      >
        <div className="StickyHeaderContent container container-OpenPayd " ref={stickyHeaderContent}>
          {children}
        </div>
      </div>

      <div className="StickyHeaderStub" style={{ height: `${height}px` }} />
    </div>
  );
};

export default StickyHeader;
