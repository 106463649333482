/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_WORLD_TRANSACTIONS,
  LOAD_WORLD_TRANSACTIONS_SUCCESS,
  LOAD_WORLD_TRANSACTIONS_FAIL,
} from '../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  transactions: [],
};

const worldTransactionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_WORLD_TRANSACTIONS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_WORLD_TRANSACTIONS_SUCCESS:
        draft.transactions = action.data.transactions;
        draft.loading = false;
        break;
      case LOAD_WORLD_TRANSACTIONS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default worldTransactionsReducer;
