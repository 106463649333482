export const riskStatuses = {
  byId: {
    LOW_RISK: {
      id: 'LOW_RISK',
      label: 'LOW',
      color: 'success',
      priority: 1,
    },
    MEDIUM_RISK: {
      id: 'MEDIUM_RISK',
      label: 'MEDIUM',
      color: 'warning',
      priority: 2,
    },
    HIGH_RISK: {
      id: 'HIGH_RISK',
      label: 'HIGH',
      color: 'error',
      priority: 3,
    },
    RESTRICTED: {
      id: 'RESTRICTED',
      label: 'RESTRICTED',
      color: 'warning',
      priority: 4,
    },
    PROHIBITED: {
      id: 'PROHIBITED',
      label: 'PROHIBITED',
      color: 'error',
      priority: 5,
    },
    NOT_APPLICABLE: {
      id: 'NOT_APPLICABLE',
      label: 'NOT APPLICABLE',
      priority: 6,
    },
    NON_REPUTABLE: {
      id: 'NON_REPUTABLE',
      label: 'NON REPUTABLE',
      priority: 7,
    },
    DELETED: {
      id: 'DELETED',
      label: 'DELETED',
      priority: 8,
    },
  },
  allIds: [
    'LOW_RISK',
    'MEDIUM_RISK',
    'HIGH_RISK',
    'RESTRICTED',
    'PROHIBITED',
    'NOT_APPLICABLE',
    'NON_REPUTABLE',
    'DELETED',
  ],
};

export const eeaRiskStatuses = {
  byId: {
    LOW_RISK: {
      id: 'LOW_RISK',
      label: 'LOW',
      color: 'success',
      priority: 1,
    },
    NOT_APPLICABLE: {
      id: 'NOT_APPLICABLE',
      label: 'NOT APPLICABLE',
      priority: 2,
    },
  },
  allIds: [
    'LOW_RISK',
    'NOT_APPLICABLE',
  ],
};

export const riskStatusTypes = [
  {
    value: 'riskStatus',
    label: 'Risk',
  },
  {
    value: 'eeaRiskStatus',
    label: 'EEA/UK Risk',
  },
];
