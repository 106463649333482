import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Stack, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO, toDate } from 'date-fns';
import {
  RiUploadLine,
  RiDownloadLine,
  RiMore2Fill,
  RiFolder4Line,
  RiTimer2Line,
} from 'react-icons/ri';
import {
  IconButtonRiStyled,
  CancellableLinearProgress,
  DatePickerTitleToolbar,
  Document,
  DocumentActions,
  DocumentTitle,
  Menu,
  CancelConfirmButtonStack,
  QuarantinedChip,
  Dropzone,
  useDropzone,
} from 'muikit';
import { ModalDialog } from 'uikit/ModalDialog';
import { downloadFile } from 'utils/downloadFile';
import { reformatDateString, formatServerDateTime } from 'utils/formatter';
import { seIsUploading, setUploadProgress } from 'services/indicatorsSlice';
import {
  ApplicationDocument as ApplicationDocumentType,
  useDeleteApplicationDocumentMutation,
  usePatchApplicationDocumentMutation,
  useRejectApplicationDocumentMutation,
  useUploadWithProgressMutation,
  VirusStatus,
} from 'services/gatewayApi/applicationDocumentsApi';
import { DocumentMenuItems } from './DocumentMenuItems';
import { RejectedChip } from './RejectedChip';
import { RejectDialog } from './RejectDialog';
import { GroupDialog } from './GroupDialog';
import { StateType } from '../../../../../../Rules/types';
import { RightContentWrapper } from './RightContentWrapper';

interface ApplicationDocumentProps extends ApplicationDocumentType {
  checked?: boolean;
  handleCheck?: (id: string) => any;
  groups?: string[];
  description?: string;
}

export const ApplicationDocument = React.memo((doc: ApplicationDocumentProps) => {
  const {
    id,
    title: titleProp,
    fileSize,
    applicationId,
    fileName,
    isReadonly,
    canBeExpired,
    expiryDate,
    isRejected,
    rejectReason,
    virusStatus,
    checked,
    handleCheck,
    groups,
    groupName,
    description,
  } = doc;
  const [rejectedModalOpen, setRejectedModalOpen] = useState<boolean>(false);
  const [virusModalOpen, setVirusModalOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(titleProp);
  const dispatch = useDispatch();
  const idUploading = useSelector((state: StateType) => state.indicators.idUploading);

  const [uploadDocument, { isLoading: isUploading, status: uploadStatus }] =
    useUploadWithProgressMutation();
  const [updateDocument, { isLoading: isUpdating }] = usePatchApplicationDocumentMutation();
  const [rejectDocument, { isLoading: isRejecting }] = useRejectApplicationDocumentMutation();
  const [deleteDocument, { isLoading: isDeleting }] = useDeleteApplicationDocumentMutation();

  useEffect(() => {
    uploadStatus === 'rejected' && setDatepickerOpen(false);
  }, [uploadStatus]);

  useEffect(() => {
    if (isUploading) {
      dispatch(seIsUploading({ idUploading: id }));
    }
    if (id === idUploading && !isUploading) {
      dispatch(seIsUploading({ idUploading: undefined }));
    }
  }, [isUploading]);

  const isOtherUploading = useSelector((state: StateType) => state.indicators.idUploading);
  const isDisabled = (isOtherUploading && id !== isOtherUploading) || false;

  const today = new Date().setHours(0, 0, 0, 0);
  const isDateExpired = new Date(expiryDate).getTime() - today < 0;

  const handleUpdateTitle = async (docTitle: string) => {
    try {
      await updateDocument({ id, applicationId, title: docTitle }).unwrap();
      setEditTitle(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleReject = async (reason: string) => {
    try {
      await rejectDocument({
        id,
        applicationId,
        rejectReason: reason,
      }).unwrap();
      setRejectModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleDelete = async () => {
    try {
      await deleteDocument({ id, applicationId }).unwrap();
      setDeleteModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const cancelControllerRef = useRef(new AbortController());

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    open: openDropzoneFileDialog,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  } = useDropzone({
    setShowDropzone,
    upload: (formData: FormData) => {
      canBeExpired && setDatepickerOpen(true);
      uploadDocument({
        formData,
        applicationId,
        id,
        signal: cancelControllerRef.current.signal,
      });
    },
  });

  const cancelUpload = (cancelMessage: string) => {
    cancelControllerRef.current.abort('cancelMessage');
    console.warn(cancelMessage);
    setDatepickerOpen(false);
    cancelControllerRef.current = new AbortController();
    dispatch(setUploadProgress({ id, progressValue: undefined })); // Clear progress
  };

  useEffect(
    () => () => {
      cancelControllerRef.current.abort();
      cancelControllerRef.current = new AbortController(); // Create a new controller for the next render
    },
    [],
  );

  const isMenu = virusStatus !== VirusStatus.Pending && !isReadonly;
  const isProcesing = virusStatus === VirusStatus.Pending;
  const isVirusChecked = virusStatus === VirusStatus.Checked;
  const isVirusQuarantined = virusStatus === VirusStatus.Quarantined;
  const actionsDisabled = !isMenu || isDisabled;

  return (
    <Document
      fileSize={fileSize}
      isRejected={isRejected}
      virusStatus={virusStatus}
      description={description}
      showDropzone={showDropzone && !isReadonly}
      onDragEnter={() => (isDisabled || isUploading ? {} : setShowDropzone(true))}
    >
      {showDropzone && !isReadonly ? (
        <Dropzone
          getRootProps={getDropzoneRootProps}
          getInputProps={getDropzoneInputProps}
          textStyle={{ fontSize: '14px', color: 'inherit' }}
        />
      ) : (
        <>
          {!isUploading && (
            <>
              <DocumentTitle
                title={title}
                fileName={fileName}
                {...(editTitle
                  ? {
                      onChange: (e) => setTitle(e.currentTarget.value),
                      onKeyPress: (e) => {
                        if (e.key === 'Enter') {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                          e.preventDefault();
                          !!title.trim() && handleUpdateTitle(title);
                        }
                      },
                    }
                  : {})}
              >
                {fileSize && !isRejected && isVirusChecked && (
                  <Checkbox
                    checked={checked}
                    onChange={() => handleCheck(id)}
                    disabled={isDisabled}
                  />
                )}
              </DocumentTitle>
              <RightContentWrapper>
                <DocumentActions>
                  {editTitle ? (
                    <CancelConfirmButtonStack
                      onConfirm={() => handleUpdateTitle(title)}
                      onCancel={() => {
                        setTitle(titleProp);
                        setEditTitle(false);
                      }}
                      cancelProps={{ disabled: isUpdating }}
                      confirmProps={{
                        disabled: isUpdating || title.trim().length === 0,
                      }}
                    />
                  ) : (
                    <>
                      {fileSize && canBeExpired && !isRejected && !isVirusQuarantined && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            components={{ OpenPickerIcon: RiTimer2Line }}
                            disablePast
                            value={expiryDate && toDate(parseISO(expiryDate))}
                            label="XX-XX-XXXX"
                            showToolbar
                            ToolbarComponent={(props) => (
                              <DatePickerTitleToolbar {...props} toolbarTitle="Expiration Date" />
                            )}
                            open={datepickerOpen}
                            onClose={() => setDatepickerOpen(false)}
                            onChange={(newValue: Date | null) => {
                              newValue &&
                                updateDocument({
                                  id,
                                  applicationId,
                                  expiryDate: formatServerDateTime(newValue),
                                });
                            }}
                            renderInput={(props) => (
                              <Button
                                ref={props.inputRef}
                                color={isDateExpired ? 'error' : 'base'}
                                variant="text"
                                startIcon={
                                  <RiTimer2Line
                                    size={14}
                                    style={{
                                      boxSizing: 'unset',
                                      color: isDateExpired ? 'danger.main' : 'inherit',
                                    }}
                                  />
                                }
                                onClick={() => setDatepickerOpen(true)}
                              >
                                <Typography
                                  sx={{
                                    lineHeight: 'normal',
                                    color: isDateExpired ? 'danger.main' : 'inherit',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {reformatDateString(props.inputProps.value)}
                                </Typography>
                              </Button>
                            )}
                          />
                        </LocalizationProvider>
                      )}

                      {!fileSize || isRejected || isVirusQuarantined ? (
                        <IconButtonRiStyled
                          size="small"
                          onClick={() => {
                            setShowDropzone(true);
                            setTimeout(openDropzoneFileDialog, 100);
                          }}
                          disabled={isDisabled}
                          iconсolor={
                            !isMenu || isDisabled ? 'text.placeholder' : 'contrast.outline-100'
                          }
                        >
                          <RiUploadLine />
                        </IconButtonRiStyled>
                      ) : (
                        <IconButtonRiStyled
                          size="small"
                          disabled={isDownloading || isDisabled || isProcesing}
                          onClick={() => {
                            setIsDownloading(true);
                            downloadFile(
                              `/api/gateway/applications/${applicationId}/docs/${id}/download`,
                              { download: true },
                            ).finally(() => setIsDownloading(false));
                          }}
                          iconсolor={
                            isDownloading || isProcesing || isDisabled
                              ? 'text.placeholder'
                              : 'contrast.outline-100'
                          }
                        >
                          <RiDownloadLine />
                        </IconButtonRiStyled>
                      )}

                      <Menu
                        renderOpenButton={(props) => (
                          <IconButtonRiStyled
                            iconсolor={
                              actionsDisabled ? 'text.placeholder' : 'contrast.outline-100'
                            }
                            onClick={props.onClick}
                            size="small"
                            className="groupsMenuButton"
                            disabled={actionsDisabled}
                          >
                            <RiFolder4Line />
                          </IconButtonRiStyled>
                        )}
                        renderItems={(props) => (
                          <DocumentMenuItems
                            groupsMenu
                            uploaded={!!fileSize}
                            groups={groups}
                            groupName={groupName}
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            updateGroup={(groupName) =>
                              updateDocument({ id, applicationId, groupName })
                            }
                            showCreateModal={() => setCreateModalOpen(true)}
                            {...props}
                          />
                        )}
                      />

                      <Menu
                        renderOpenButton={(props) => (
                          <IconButtonRiStyled
                            onClick={props.onClick}
                            size="small"
                            disabled={actionsDisabled}
                            iconсolor={
                              actionsDisabled ? 'text.placeholder' : 'contrast.outline-100'
                            }
                          >
                            <RiMore2Fill />
                          </IconButtonRiStyled>
                        )}
                        renderItems={(props) => (
                          <DocumentMenuItems
                            uploaded={!!fileSize}
                            canBeExpired={canBeExpired}
                            edit={() => setEditTitle(true)}
                            upload={() => {
                              setShowDropzone(true);
                              setTimeout(openDropzoneFileDialog, 100);
                            }}
                            deleteDoc={() => setDeleteModalOpen(true)}
                            {...(expiryDate &&
                              canBeExpired &&
                              !isRejected &&
                              isVirusChecked && {
                                setExpiration: () => setDatepickerOpen(true),
                              })}
                            {...(!!fileSize &&
                              !isRejected &&
                              isVirusChecked && {
                                reject: () => setRejectModalOpen(true),
                              })}
                            {...props}
                            {...(!isRejected &&
                              isVirusChecked && {
                                addExpiration: () =>
                                  updateDocument({
                                    id,
                                    applicationId,
                                    canBeExpired: true,
                                  })
                                    .unwrap()
                                    .then(() => setDatepickerOpen(true)),
                              })}
                            {...(!isRejected &&
                              isVirusChecked && {
                                removeExpiration: () =>
                                  updateDocument({
                                    id,
                                    applicationId,
                                    canBeExpired: false,
                                    expiryDate: null,
                                  }),
                              })}
                          />
                        )}
                      />
                    </>
                  )}
                </DocumentActions>

                <Stack
                  className="actionsMessages"
                  sx={{
                    justifyContent: 'flex-end',
                    paddingRight: '7px',
                    gap: '0.5rem',
                  }}
                >
                  {isVirusQuarantined && (
                    <QuarantinedChip onClick={() => setVirusModalOpen(true)} />
                  )}

                  {isRejected && <RejectedChip onClick={() => setRejectedModalOpen(true)} />}

                  {isProcesing && (
                    <Typography variant="bodySmall" color="text.placeholder" textAlign="right">
                      Processing...
                    </Typography>
                  )}
                </Stack>
              </RightContentWrapper>
            </>
          )}
          {isUploading && <CancellableLinearProgress docId={id} cancelUpload={cancelUpload} />}
        </>
      )}

      <ModalDialog
        variant="error"
        open={rejectedModalOpen}
        onClose={() => setRejectedModalOpen(false)}
        title="Document rejected"
      >
        {rejectReason}
      </ModalDialog>
      <ModalDialog
        variant="error"
        open={virusModalOpen}
        onClose={() => setVirusModalOpen(false)}
        title="Virus detected"
      >
        Sorry, the uploaded file was found to be malicious. Please upload another one.
      </ModalDialog>
      <ModalDialog
        variant="error"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleAction={() => handleDelete()}
        actionTitle="Delete"
        isLoading={isDeleting}
        title="Delete document"
      >
        Are you sure you want to delete this document?
      </ModalDialog>
      <RejectDialog
        open={rejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
        isRejecting={isRejecting}
        handleAction={(reason: string) => handleReject(reason)}
      />
      <GroupDialog
        open={createModalOpen}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onClose={({ groupName }) => {
          if (groupName) {
            updateDocument({ id, applicationId, groupName });
          }
          setCreateModalOpen(false);
        }}
      />
    </Document>
  );
});
