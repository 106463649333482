import { ValueLabel } from 'types';

export type FormStatus = 'PENDING' | 'IN_PROGRESS' | 'ERRORS' | 'DONE';

export const formStatuses: ValueLabel[] = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'IN_PROGRESS', label: 'In Progress' },
  { value: 'ERRORS', label: 'Errors' },
  { value: 'DONE', label: 'Completed' },
];

export type SectionStatus = 'PENDING' | 'DONE' | 'VERIFIED' | 'PENDING_SIGNATURE' | 'SIGNED';

export const sectionStatuses: ValueLabel[] = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'DONE', label: 'Completed' },
  { value: 'VERIFIED', label: 'Verified' },
  { value: 'PENDING_SIGNATURE', label: 'Pending Signature' },
  { value: 'SIGNED', label: 'Signed' },
];

export interface FormSectionOfApplication {
  description: string;
  id: number;
  partitions?: string[];
  sectionOrder: number;
  title: string;
  isInternal?: boolean;
}

export interface FormSection {
  sectionId: number;
  partition: string;
  status: SectionStatus;
  sectionDetails: {
    id: number; // same value as parent object sectionId
    sectionOrder: number;
    title: string;
    description: string;
    isInternal?: boolean;
  };
}

export interface FormSectionHistory {
  sectionId: number;
  title: string;
  partition: string;
  history: FormSectionHistoryItem[];
}

interface FormSectionHistoryItem {
  sectionId: number; // same value as parent object
  partition: string;
  status: SectionStatus;
  revision: {
    revisionType: string;
    revisionNumber: number;
    revisionInstant: string; // 2023-07-19T12:26:56.756Z
    modifiedBy: string;
    modifiedByName: string;
  };
}

type RecordType = 'primitive' | 'list' | 'group' | string;

type FieldType =
  | null
  | 'datetime'
  | 'testing_type'
  | 'list'
  | 'text'
  | 'checkbox'
  | 'subtitle'
  | 'group'
  | 'key-value'
  | 'description'
  | 'textarea'
  | 'selector'
  | 'note';

interface Field {
  id: number;
  name: string;
  title: string;
  type: FieldType;
}

export interface Record {
  condition: FormCondition;
  col: number;
  createdBy: string;
  createdByName: string;
  createdDate: string;
  field: Field;
  formTemplateFieldId: string;
  id: string;
  isContainer: boolean;
  isMandatory?: boolean;
  modifiedBy: string;
  modifiedByName: string;
  modifiedDate: string; // 2022-11-23T13:15:04.546442Z
  row: number;
  type: RecordType;
  validations: any[];
  weight: number;
  parent?: string;
  defaultValue?: string;
  value?: string;
}

enum LogicalOperator {
  And = 'AND',
  Or = 'OR',
}
enum CompareOperator {
  Equal = 'equal',
  Not = 'not',
  Greater = 'gt',
  Less = 'ls',
  GreaterOrEqual = 'gte',
  LessOrEqual = 'lse',
  Contains = 'contains',
}

export interface Action {
  visible?: boolean;
  enabled?: boolean;
  value?: string; // need to parse | number | boolean | DSL like $F{getById(8e0ee73a-610e-4a4c-927f-acf55f90d48b)}
}

export interface Condition {
  compareOperator: LogicalOperator;
  dependsOn: string; // uuid
  dependsOnForm?: string; // uuid
  logicalOperator: CompareOperator;
  order: number;
  value: boolean;
}

export interface FormCondition {
  actions: Action[];
  conditions: Condition[];
}

export interface ApplicationForm {
  applicationId: string;
  condition?: FormCondition;
  createdBy: string;
  createdByName: string;
  createdDate: string; // '2022-11-23T13:15:04.398827Z';
  formOrder: number;
  formSection: FormSectionOfApplication;
  formTemplateId: number;
  id: string;
  isInternal: boolean;
  isPrequestionnaire: boolean;
  modifiedBy: string;
  modifiedByName: string;
  modifiedDate: string; // '2022-11-23T13:15:04.545143Z';
  needToSign: boolean;
  partitionName: string;
  records: Record[];
  title: string;
  status?: FormStatus;
}

export interface TemplateField {
  col: number;
  defaultValue: string;
  field: Field;
  fieldId: number;
  formTemplateId: number;
  id: string;
  isContainer: boolean;
  isMandatory?: boolean;
  parent: string;
  row: number;
  type: RecordType;
  validations: any[];
  weight: number;
}
