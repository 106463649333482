import { ChipColorTheme, RulesAnswer, RuleSortable, RuleVeto, Threshold } from './types';

export const isNewregex = /^new.*/;

export const ruleAnswer: { value: RulesAnswer; label: string }[] = [
  { value: 'PASS', label: 'Pass' },
  { value: 'LOWRISK', label: 'Low Risk' },
  { value: 'MEDIUMRISK', label: 'Medium Risk' },
  { value: 'HIGHRISK', label: 'High Risk' },
  { value: 'MODERATION', label: 'Moderation' },
  { value: 'REJECT', label: 'Reject' },
  { value: 'ERROR', label: 'Error' },
];

export const initialRule: RuleSortable = {
  id: '',
  title: 'Rule name',
  isActive: false,
  script: '1===1',
  description: 'rule description',
  children: [],
  answer: 'PASS',
};

export const initialRuleVeto: RuleVeto = {
  id: 'new',
  ruleId: undefined,
  ruleVetoType: undefined,
  referenceIdentifier: '',
  startDate: null,
  expiryDate: null,
  comments: '',
};

export const chipColorTheme: ChipColorTheme = {
  active: 'success',
  expired: 'error',
  expects: 'warning',
};

export const thresholdInitialModel: Threshold = {
  LOWRISK: 20,
  MEDIUMRISK: 40,
  HIGHRISK: 60,
  REJECT: 80,
};

export const hoverBgColor = { '&.MuiIconButton-root:hover': { background: 'rgba(0, 0, 0, 0.05)' } };
