/**
 * Page selectors
 */

import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { initialState } from '../reducers/ruleBuilder';
import { getRule } from '../utils/treeOperations';
import { prepareRuleSetForTree } from '../utils/prepareRuleSet';

const ruleBuilder = (state) => state.ruleBuilder || initialState;
export const selectCurrentRule = (state) => _.cloneDeep(state.ruleBuilder.currentRule);
export const selectIsRuleLoading = (state) => state.ruleBuilder.loading;
export const selectIsHistoryLoading = (state) => state.ruleBuilder.loadingHistory;
export const ruleHistorySelector = (state) => _.cloneDeep(state.ruleBuilder.ruleHistory);

const makeSelectedRuleData = () =>
  createSelector(ruleBuilder, (state) => {
    const { selectedRuleId } = state;
    const { ruleSet } = state;
    return getRule(ruleSet.rules, selectedRuleId);
  });

const makeRuleSetData = () =>
  createSelector(ruleBuilder, (state) => {
    if (state.ruleSet) {
      const { ruleSet } = _.cloneDeep(state);
      if (ruleSet.rules.length && !ruleSet.prepared) {
        ruleSet.rules = prepareRuleSetForTree(ruleSet.rules);
        ruleSet.prepared = true;
      }
      return ruleSet;
    }
    return {};
  });
const makeFunctionListData = () =>
  createSelector(ruleBuilder, (state) => {
    const { functions } = state;
    if (functions instanceof Array) {
      return functions;
    }
    return [];
  });
const makeSavingData = () => createSelector(ruleBuilder, (state) => state.saving || false);
const makeNewRuleFormValidData = () =>
  createSelector(ruleBuilder, (state) => state.newRuleFormValid || false);

const isVetosLoading = (state) => state.ruleBuilder.vetosLoading;
const makeRuleVetosData = (state) => state.ruleBuilder.vetosEmail;
const makeRuleVetosMerchant = (state) => state.ruleBuilder.vetosMerchant;
const makeRuleVetosIban = (state) => state.ruleBuilder.vetosIban;
const makeAllRuleVetosData = (state) => state.ruleBuilder.vetos;

const isDialogSelectRuleOpen = (state) => state.ruleBuilder.isDialogSelectRuleOpen;
const listRules = (state) => state.ruleBuilder.listRules;

export {
  ruleBuilder,
  makeSelectedRuleData,
  makeRuleSetData,
  makeSavingData,
  makeNewRuleFormValidData,
  makeFunctionListData,
  makeRuleVetosData,
  makeRuleVetosMerchant,
  makeRuleVetosIban,
  makeAllRuleVetosData,
  isVetosLoading,
  isDialogSelectRuleOpen,
  listRules,
};
