import * as yup from 'yup';
import { ValueLabel } from 'types';
import { titles } from '../../../constants';
import { FieldList } from '../../../FormRowInput/types';

interface Options {
  industries: ValueLabel[];
  solutions: ValueLabel[];
  categories: ValueLabel[];
  countries: ValueLabel[];
  users: ValueLabel[];
  legalEntityTypes: ValueLabel[];
}

export const sections = [
  { id: 1, title: 'Contact Information' },
  { id: 2, title: 'Company Information' },
  { id: 3, title: 'Relation Information' },
];

export const labelMap = {
  // Contact Information
  contactTitle: 'Title',
  contactFirstName: 'First Name',
  contactMiddleName: 'Middle Name',
  contactLastName: 'Last Name',
  contactPosition: 'Title/Position',
  contactEmail: 'Email',

  // Company Information
  companyName: 'Legal Entity Name',
  businessAddress: 'Business Address',
  companyDescription: 'Company Description',
  lastTransactionDate: 'Last Transaction Date',
  industryType: 'Industry Type',
  companyURLs: 'Company URL(s)',
  overallRisk: 'Overall Risk (As per last review)', // Missing and/or match from application?
  solutionsRequired: 'Solutions Required',
  contactPhone: 'Business Phone Number',
  categoryId: 'Application Category',
  dateOfIncorporation: 'Date of Incorporation',
  countryOfIncorporation: 'Country of Incorporation',
  legalEntityType: 'Legal Entity Type',
  isCommercialAgreementSigned: 'Pricing Agreement Signed',

  // Relation Information
  salesManager: 'Sales Manager',
  salesPersonEmail: 'Sales Manager Email',
  referralPartner: 'Referral Partner',
  relationshipManagerEmail: 'Relationship Manager Email',
  externalId: 'Salesforce ID {country}', // Get from application details
  openpaydClientId: 'OpenPayd {country} Client IDs',
  linkedIBAN: 'Linked IBANs',
  linkedIBAN3rd: 'Linked IBANs (3rd party)',
  companyRegistrationNo: 'Company Registration No',
  regulated: 'Regulated',
  regulatedByLink: 'Regulated by Link (for complaints)',
  regulatedForComplaints: 'Regulated for Complaints',
  complianceCallScheduledDate: 'Compliance Call Scheduled On',
  complianceCallDone: 'Compliance Call Done',
  compliancePerson: 'Compliance Person',
};

export const initialValues = {
  // Contact Information
  contactTitle: '',
  contactFirstName: '',
  contactMiddleName: '',
  contactLastName: '',
  contactPosition: '',
  contactEmail: '',

  // Company Information
  companyName: '',
  businessAddress: '',
  contactPhone: '',
  companyDescription: '',
  lastTransactionDate: '',
  industryType: '',
  companyURLs: '',
  overallRisk: '',
  solutionsRequired: '',
  categoryId: '',
  dateOfIncorporation: '',
  countryOfIncorporation: '',

  // Relation Information
  salesManager: '',
  salesPersonEmail: '',
  referralPartner: '',
  relationshipManagerEmail: '',
  externalId: '',
  openpaydClientId: '',
  linkedIBAN: false,
  linkedIBAN3rd: false,
  companyRegistrationNo: '',
  regulated: false,
  regulatedByLink: '',
  regulatedForComplaints: false,
  complianceCallScheduledDate: '',
  complianceCallDone: false,
  compliancePerson: '',
};

export const editableOnOpenStatus = [
  // Contact Information
  'contactFirstName',
  'contactMiddleName',
  'contactLastName',
  'contactEmail',

  // Company Information
  'businessAddress',
  'contactPhone',
];

export const editAppValidationSchema = () =>
  yup.object().shape({
    // Contact Information
    contactTitle: yup.string().required(`Please select a ${labelMap.contactTitle}`),
    contactFirstName: yup.string().required(`${labelMap.contactFirstName} is required`),
    contactLastName: yup.string().required(`${labelMap.contactLastName} is required`),
    contactPosition: yup.string().required(`${labelMap.contactPosition} is required`),
    contactEmail: yup
      .string()
      .required(`${labelMap.contactEmail} is required`)
      .email('Please enter a valid email address'),

    // Company Information
    companyName: yup.string().required(`${labelMap.companyName} is required`),
    businessAddress: yup.string().required(`${labelMap.businessAddress} is required`),
    contactPhone: yup.string().required(`${labelMap.contactPhone} is required`),
    categoryId: yup.string().required(`Please select a ${labelMap.categoryId}`),

    // Relation Information
    salesManager: yup.string().required(`${labelMap.salesManager} is required`),
    salesPersonEmail: yup
      .string()
      .email('Please enter a valid email address'),
    relationshipManagerEmail: yup
      .string()
      .email('Please enter a valid email address'),
  });

export const editAppFieldsSchema = ({ options }: { options: Options }): FieldList[] => {
  const initialFields: FieldList[] = [
    // #region Contact Information
    {
      sectionId: 1,
      field: 'radio',
      label: labelMap.contactTitle,
      name: 'contactTitle',
      options: titles,
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactFirstName,
      name: 'contactFirstName',
      placeholder: 'John',
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactMiddleName,
      name: 'contactMiddleName',
      placeholder: 'Lost',
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactLastName,
      name: 'contactLastName',
      placeholder: 'Doe',
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactPosition,
      name: 'contactPosition',
      placeholder: 'General Manager, CEO etc.',
      editable: true,
      defaultFocus: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'email',
      label: labelMap.contactEmail,
      name: 'contactEmail',
      placeholder: 'name@domain.com',
    },
    // #endregion

    // #region Company Information
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.companyName,
      name: 'companyName',
      placeholder: 'Company Name Ltd.',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.businessAddress,
      name: 'businessAddress',
      placeholder: '68-86, Clapham Rd, London SW9 OJJ',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.contactPhone,
      name: 'contactPhone',
      placeholder: '+1 123 123 12 12',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.companyDescription,
      name: 'companyDescription',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'date',
      label: labelMap.lastTransactionDate,
      name: 'lastTransactionDate',
      placeholder: 'No transaction yet',
    },
    {
      sectionId: 2,
      field: 'select',
      label: labelMap.industryType,
      name: 'industryType',
      options: options.industries,
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'array',
      label: labelMap.companyURLs,
      name: 'companyURLs',
      placeholder: 'Add URL',
      editable: true,
      keywordTest: (value) =>
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(
          value,
        ),
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.overallRisk,
      name: 'overallRisk',
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'array',
      label: labelMap.solutionsRequired,
      name: 'solutionsRequired',
      placeholder: 'Find',
      options: options.solutions,
      editable: true,
    },
    {
      sectionId: 2,
      field: 'select',
      label: labelMap.categoryId,
      name: 'categoryId',
      options: options.categories,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'date',
      label: labelMap.dateOfIncorporation,
      name: 'dateOfIncorporation',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'select',
      label: labelMap.countryOfIncorporation,
      name: 'countryOfIncorporation',
      options: options.countries,
      editable: true,
    },
    {
      sectionId: 2,
      field: 'select',
      label: labelMap.legalEntityType,
      name: 'legalEntityType',
      options: options.legalEntityTypes,
      editable: true,
    },
    {
      sectionId: 2,
      field: 'toggle',
      label: labelMap.isCommercialAgreementSigned,
      name: 'isCommercialAgreementSigned',
      editable: true,
    },
    // #endregion

    // #region Relation Information
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.salesManager,
      name: 'salesManager',
      placeholder: 'John Doe',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'email',
      label: labelMap.salesPersonEmail,
      name: 'salesPersonEmail',
      placeholder: 'name@domain.com',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.referralPartner,
      name: 'referralPartner',
      placeholder: 'John Doe',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'email',
      label: labelMap.relationshipManagerEmail,
      name: 'relationshipManagerEmail',
      placeholder: 'name@domain.com',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.externalId,
      name: 'externalId',
      editable: true,
      editLock: true,
      editLockWarning: `Manually editing this field can result in data loss or synchronization errors. Exercise caution when making changes and contact support for your questions.`,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'array',
      label: labelMap.openpaydClientId,
      name: 'openpaydClientId',
      placeholder: 'Add ID',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'toggle',
      label: labelMap.linkedIBAN,
      name: 'linkedIBAN',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'toggle',
      label: labelMap.linkedIBAN3rd,
      name: 'linkedIBAN3rd',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.companyRegistrationNo,
      name: 'companyRegistrationNo',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'toggle',
      label: labelMap.regulated,
      name: 'regulated',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.regulatedByLink,
      name: 'regulatedByLink',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'toggle',
      label: labelMap.regulatedForComplaints,
      name: 'regulatedForComplaints',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'date',
      label: labelMap.complianceCallScheduledDate,
      name: 'complianceCallScheduledDate',
      editable: true,
      placeholder: 'Not scheduled',
    },
    {
      sectionId: 3,
      field: 'toggle',
      label: labelMap.complianceCallDone,
      name: 'complianceCallDone',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'select',
      label: labelMap.compliancePerson,
      name: 'compliancePerson',
      options: options.users,
      editable: true,
    },

    // #endregion
  ];

  return initialFields;
};

// TODO: must be provided by BE
export const industries = [
  'Adult/Dating',
  'Financial Services',
  'Financial Services (CFD)',
  'Introducer',
  'Pharmaceuticals',
  'Professional Services',
  'Cryptocurrency',
  'Marketing Services',
  'Retail',
  'Retail - Luxury Goods',
  'Marketplace',
  'Gambling',
  'Skill Gaming',
  'Entertainment Services',
  'Restaurants/Food Industry',
  'IT Professional Services',
  'Nutraceuticals',
  'Logistics & Transport',
  'Hospitality',
  'Travel Services',
  'Manufacturing Services',
  'Telecommunication Services',
  'Bail Bondsman',
  'CBD/Marijuana Dispensary',
  'ICO',
  'Drug Paraphernalia',
  'Fortune Telling',
  'Councelling Centre',
  'MLM/Pyramid Scheme',
  'Pyrotechnic/Hazardous Material',
  'Weapons',
  'Timeshare',
  'Tobacco',
  'Legal Services',
  'Holding Company',
  'Real Estate',
  'Consultancy Services',
  'Gift Cards',
  'Charity',
  'Non-registered Charity',
].map((item) => ({ value: item, label: item }));
export const solutions = [
  {
    label: 'Local GBP Account',
    value: 'local_gbp_account',
  },
  {
    label: 'Local EUR Account',
    value: 'local_eur_account',
  },
  {
    label: 'Local USD Account',
    value: 'local_usd_account',
  },
  {
    label: 'Multi-currency Account',
    value: 'multi_currency_account',
  },
  {
    label: 'Foreign Exchange',
    value: 'foreign_exchange',
  },
  {
    label: 'Pooled Accounts',
    value: 'pooled_accounts',
  },
  {
    label: 'Linked vIBAN Account',
    value: 'linked_viban_account',
  },
  {
    label: 'API',
    value: 'api',
  },
];
export const legalEntityTypes = [
  "Publicly Listed Company ('PLC')",
  "Limited ('LTD')",
  "Limited Partnership ('LP')",
  'Partnership',
  'Trust',
  'Foundation',
  'Charity',
].map((item) => ({ value: item, label: item }));
