import React, { useEffect, useState } from 'react';
import { Box, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { RiArrowDownSLine, RiCheckLine } from 'react-icons/ri';
import { styled } from '@mui/material/styles';
import { ValueLabel } from '../../../../types';
import { OptionButton } from './OptionButton';

const StyledHeader = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    mt: 1,
    mb: 0,
    width: '100%',
    p: '2px 4px',
    color: 'grey.600',
    fontSize: '16px',
    fontWeight: 500,
  }),
);

interface FilterRowsSetProps {
  filterOptions: ValueLabel[];
  options: { label: string; value: string; count: number }[];
  filterQuery: IFilterValue;
  onChange?: (fieldName, selectedFilters: string[]) => void;
  hideDots?: boolean;
}

export const FilterRowsSet: React.FC<FilterRowsSetProps> = ({
  filterOptions,
  options,
  filterQuery,
  onChange,
  hideDots = false,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<ValueLabel>(filterOptions[0]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(
    () => setSelectedOptions(filterQuery[selectedFilter.value] ?? []),
    [filterQuery[selectedFilter.value]],
  );

  const handleSelect = (value: string, isSelected: boolean) => {
    const updatedSelection = isSelected
      ? [...selectedOptions, value]
      : selectedOptions.filter((f) => f !== value);
    setSelectedOptions(updatedSelection);
    if (onChange) {
      onChange(selectedFilter.value, updatedSelection);
    }
  };

  const hasManyFilters = filterOptions.length > 1;
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  return (
    <Box sx={{ my: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 3 }}>
        <StyledHeader
          variant="h4"
          sx={
            hasManyFilters
              ? {
                  borderRadius: 1,
                  transition: '.2s background-color',
                  cursor: 'pointer',

                  '&:hover': {
                    bgcolor: 'grey.100',
                  },
                }
              : {}
          }
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorElMenu(event.currentTarget)
          }
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>{selectedFilter.label}</div>
            {hasManyFilters && <RiArrowDownSLine />}
          </Stack>
        </StyledHeader>
      </Stack>

      {options &&
        options.map((option) => (
          <OptionButton
            key={option.label}
            label={option.label}
            value={option.value}
            count={option.count}
            selected={selectedOptions.includes(option.value)}
            onSelect={handleSelect}
            hideDots={hideDots}
          />
        ))}

      {hasManyFilters && (
        <Menu
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          onClose={() => setAnchorElMenu(null)}
          onClick={() => setAnchorElMenu(null)}
        >
          {filterOptions.map((filter) => (
            <MenuItem
              key={filter.value}
              onClick={() => {
                // Reset selected value for current filter
                if (onChange) {
                  onChange(selectedFilter.value, []);
                }
                // Clear selected items
                setSelectedOptions([]);
                setSelectedFilter(filter);
              }}
            >
              {selectedFilter.value === filter.value && (
                <ListItemIcon>
                  <RiCheckLine size="16px" />
                </ListItemIcon>
              )}
              {filter.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};
