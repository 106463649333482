import { gatewayApi } from '../gatewayApi';

export interface RuleDescription {
  id: string;
  description: string;
  comment?: string;
  tenantId?: number;
  lastUsedTime?: string;
}

type UpdateRuleDescriptionPayload = Partial<Pick<RuleDescription, 'description' | 'comment' | 'id'>>;

export const applicationDescriptionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getDescriptions: build.query<RuleDescription[], undefined>({
      query: () => ({
        url: 'exception-rule-descriptions',
        method: 'GET',
      }),
      providesTags: [{ type: 'RuleDescriptions', id: 'LIST' }],
    }),

    addDescription: build.mutation<RuleDescription, Omit<UpdateRuleDescriptionPayload, 'id'>>({
      query: (body) => ({
        url: 'exception-rule-descriptions',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'RuleDescriptions', id: 'LIST' }],
    }),

    updateDescription: build.mutation<RuleDescription, UpdateRuleDescriptionPayload>({
      query: ({ id, ...rest }) => ({
        url: `exception-rule-descriptions/${id}`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: [{ type: 'RuleDescriptions', id: 'LIST' }],
    }),

    deleteDescription: build.mutation({
      query: ({ id }) => ({
        url: `exception-rule-descriptions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'RuleDescriptions', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDescriptionsQuery,
  useAddDescriptionMutation,
  useUpdateDescriptionMutation,
  useDeleteDescriptionMutation,
} = applicationDescriptionsApi;
