const countriesConfig = {
  baseUrl: '',
  countries: 'countries',
};

const dictConfig = {
  baseUrl: '',
  dictionary: 'dict',
};

const kycConfig = {
  baseUrl: '',
  kyc_applications: 'customers/applications',
};

const onboardingConfig = {
  baseUrl: '',
  applications: 'applications',
  customers: '',
};

export const apiConfig = {
  countries: countriesConfig,
  dictionary: dictConfig,
  kyc: kycConfig,
  onboarding: onboardingConfig,
};

export const featureConfig = { formStatusesEnabled: false, sectionStatusesEnabled: false };
