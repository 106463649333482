/**
 *
 * Input
 *
 */

import PropTypes from 'prop-types';

import React from 'react';

const Input = (props) => {
  const { value, name, type, pattern, validationType, className, disabled } = props;
  let { handleInputChanged } = props;
  handleInputChanged = handleInputChanged || function hadle() {};

  return (
    <input
      type={type}
      value={value}
      pattern={pattern || type === 'text' ? '^[a-zA-Z0-9 "\'&._-]*$' : undefined}
      data-validationtype={validationType}
      className={className}
      onChange={(i) => {
        handleInputChanged(i);
      }}
      name={name}
      disabled={disabled}
    />
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  validationType: PropTypes.string,
  handleInputChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  value: '',
  className: '',
};

export default Input;
