import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { HeadLink } from 'muikit';
import { ConfirmDialog, ModalDialog } from 'uikit';
import { useGetRiskScoringSectionsQuery } from 'services/gatewayApi/riskScoringSectionsApi';
import { useGetApplicationQuery } from 'services/gatewayApi/applicationApi';
import {
  useGetRiskScoringAnswersByApplicationQuery,
  useSubmitRiskScoringMutation,
} from 'services/gatewayApi/riskScoringAnswersApi';
import { useGetRiskScoringScoresQuery } from 'services/gatewayApi/riskScoringScoresApi';
import { AccordionSection } from './AccordionSection';
import { ScoreDisplay } from './ScoreDisplay';

import './RiskScoringTab.scss';

export const RiskScoringTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { applicationId } = useParams<{ applicationId: string; }>();

  const { data: applicationDetails, isLoading: isLoadingApplication } =
    useGetApplicationQuery(applicationId);

  const { data: sections, isLoading: isLoadingSections } = useGetRiskScoringSectionsQuery(
    {
      version: applicationDetails?.riskScoringVersion,
    },
    { skip: !applicationDetails },
  );

  const sectionsSorted =
    sections &&
    sections.length > 0 &&
    sections.slice().sort((a, b) => (a.sectionOrder > b.sectionOrder ? 1 : -1));

  const { data: scores, isLoading: isLoadingScores } = useGetRiskScoringScoresQuery(
    { version: applicationDetails?.riskScoringVersion },
    { skip: !applicationDetails },
  );

  const { data: answers, isLoading: isLoadingAnswers } =
    useGetRiskScoringAnswersByApplicationQuery(applicationId);

  const [expandedId, setExpandedId] = useState<number | false>();
  const handleExpand = (panelId: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedId(newExpanded ? panelId : false);
  };

  // #region Submit Answers
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitAnswers] = useSubmitRiskScoringMutation();
  const handleSubmit = () => {
    setIsSubmitting(true);

    submitAnswers(applicationId)
      .unwrap()
      .then(() => setIsModalOpen(true))
      .catch((rejected) => {
        console.error(rejected);
        setIsSubmitting(false);
      });
  };

  const handleModalAction = () => {
    setIsModalOpen(false);
    const path = location.pathname.split('/');
    const prevPath = path.slice(0, -1).join('/');
    navigate(prevPath);
  };
  // #endregion

  const [isPepIdentified, setIsPepIdentified] = useState<boolean>(false);
  useEffect(() => {
    const answer = answers?.find((a) => a.questionId === 'd22d48d3-a052-4ab9-847e-cfd634c876ab'); // PEP Identified
    if (answer && answer.caption && isPepIdentified !== (answer.caption === 'YES')) {
      setIsPepIdentified(answer.caption === 'YES');
    }
  }, [answers]);

  const isReadOnly = applicationDetails?.isRiskScoringSubmitted || isSubmitting;

  const isLoading =
    isLoadingApplication || isLoadingSections || isLoadingScores || isLoadingAnswers;

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box className="RiskScoringTab">
      <HeadLink title="Risk Scoring" />

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box className="RiskScoringAccordion">
              {sectionsSorted.map((section, i) => (
                <AccordionSection
                  key={`AccordionSection_${section.id}`}
                  applicationId={applicationId}
                  riskScoringVersion={applicationDetails.riskScoringVersion}
                  isRiskScoringSubmitted={applicationDetails?.isRiskScoringSubmitted}
                  sectionId={section.id}
                  title={section.title}
                  scores={scores}
                  answers={answers}
                  readonly={isReadOnly}
                  expanded={expandedId === section.id}
                  onExpand={handleExpand}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ ml: 4 }}>
              <ScoreDisplay
                applicationId={applicationId}
                isModalOpen={isModalOpen}
                isPepIdentified={isPepIdentified}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsConfirmOpen(true)}
                disabled={isReadOnly}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>

        <ConfirmDialog
          showDialog={isConfirmOpen}
          setShowDialog={setIsConfirmOpen}
          onCancel={() => setIsConfirmOpen(false)}
          onConfirm={handleSubmit}
          title="Submit Risk Scoring"
          text="This page and risk score will become read-only after successful submit. Continue?"
        />

        <ModalDialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          actionTitle="Ok"
          handleAction={handleModalAction}
          title="Risk Score Result"
          noCancel
        >
          <ScoreDisplay
            applicationId={applicationId}
            isModalOpen={isModalOpen}
            isPepIdentified={isPepIdentified}
          />
        </ModalDialog>
      </Box>
    </Box>
  );
};
