import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import KycProvidersList from './KycProvidersList';

const KycProviders = () => (
  <div className="page-content">
    <Helmet title="KYC Providers" />
    <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
      <div className="page-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Typography variant="h1" py={4}>
                KYC Providers
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <KycProvidersList />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default KycProviders;
