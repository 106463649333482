import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography } from '@mui/material';
import { Button } from '../../../components';
import {
  makeCaseAssignmentsData,
  makeCaseAssignmentsLoadingData,
  makeCaseAssignmentsShowForm,
} from '../../../selectors/caseAssignments';
import {
  fetchCaseAssignments,
  showCreateAssignmentsForm,
  closeAssignmentsForm,
} from '../../../actions/caseAssignments';
import { fetchUsers } from '../../../actions/users';
import { fetchProducts } from '../../../actions/products';
import { makeUsersData, makeUsersLoadingData } from '../../../selectors/users';
import { makeProductsData, makeProductsLoadingData } from '../../../selectors/products';
import CaseAssignmentsList from './caseAssignmentsList';
import CaseAssignmentForm from './caseAssignmentForm';

export class CaseAssignments extends Component {
  componentWillMount() {
    this.props.fetchCaseAssignments();
    this.props.fetchUsers();
    this.props.fetchProducts();
  }

  handleNewAssignment() {
    this.props.showCreateAssignmentsForm();
  }

  render() {
    const { caseAssignments, users, products, usersLoading, productsLoading, assignmentsLoading } =
      this.props;
    return (
      <div className="page-content">
        <Helmet title="Case Assignments" />
        <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Typography variant="h1" py={4}>
                    Case Assignments
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="grid simple">
                  <div className="grid-title">
                    <h4>
                      <span>Case Assignments</span>
                    </h4>
                    <div className="new-button-container">
                      <Button
                        buttonText="New Case Assignment"
                        buttonClass="btn btn-primary btn-small"
                        iconClass="fa fa-plus"
                        disabled={this.props.showForm}
                        onClick={() => {
                          this.handleNewAssignment();
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid-body p-0">
                    {this.props.showForm && (
                      <div className="p-20">
                        <CaseAssignmentForm users={users} products={products} />
                      </div>
                    )}
                    <CaseAssignmentsList
                      caseAssignments={caseAssignments}
                      users={users}
                      products={products}
                      loading={usersLoading && productsLoading && assignmentsLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CaseAssignments.propTypes = {
  caseAssignments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  assignmentsLoading: PropTypes.bool,
  usersLoading: PropTypes.bool,
  productsLoading: PropTypes.bool,
  showForm: PropTypes.bool,
  fetchUsers: PropTypes.func,
  fetchProducts: PropTypes.func,
  fetchCaseAssignments: PropTypes.func,
  showCreateAssignmentsForm: PropTypes.func,
  closeAssignmentsForm: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  caseAssignments: makeCaseAssignmentsData(),
  users: makeUsersData(),
  products: makeProductsData(),
  assignmentsLoading: makeCaseAssignmentsLoadingData(),
  usersLoading: makeUsersLoadingData(),
  productsLoading: makeProductsLoadingData(),
  showForm: makeCaseAssignmentsShowForm(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCaseAssignments: () => dispatch(fetchCaseAssignments()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchProducts: () => dispatch(fetchProducts()),
  showCreateAssignmentsForm: () => dispatch(showCreateAssignmentsForm()),
  closeAssignmentsForm: () => dispatch(closeAssignmentsForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAssignments);
