import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatIsoDate } from 'utils/formatter';
import { BodyRow, BodyTableCell } from 'uikit';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/constants';
import { SectionStatus, sectionStatuses } from 'features/ApplicationManagement/types/forms';

interface HistoryRowProps {
  date: string; // "2022-07-04T10:51:09.920Z"
  name: string;
  title: string;
  status: SectionStatus;
  prevStatus?: SectionStatus;
}

export const HistoryRowOfForm: React.FC<HistoryRowProps> = ({
  date,
  name,
  title,
  status,
  prevStatus,
}) => {
  const getLabel = (value) => sectionStatuses.find((el) => el.value === value)?.label;

  return (
    <BodyRow disablehover>
      <BodyTableCell>
        <Typography color="grey.600" variant="body2" component="span">
          {formatIsoDate(date, DATE_FORMAT)}
        </Typography>
        <Typography color="grey.500" variant="body2" component="span" sx={{ pl: 4 }}>
          {formatIsoDate(date, TIME_FORMAT)}
        </Typography>
        <Typography variant="body1" color="grey.600" sx={{ pt: 2 }}>{`by ${name}`}</Typography>
      </BodyTableCell>
      <BodyTableCell>
        <Typography variant="body1" color="grey.900" component="span">
          {title}
        </Typography>
      </BodyTableCell>
      <BodyTableCell>
        <Stack direction="row" spacing={4} alignItems="center" sx={{ height: '32px' }}>
          {prevStatus && (
            <Typography variant="body1" color="grey.600" component="span">
              {getLabel(prevStatus)}
            </Typography>
          )}
          {prevStatus && <ArrowForwardIcon sx={{ color: 'grey.600', fontSize: '14px' }} />}
          <Typography variant="body1" color="grey.900" component="span">
            {getLabel(status)}
          </Typography>
        </Stack>
      </BodyTableCell>
    </BodyRow>
  );
};
