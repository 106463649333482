import {
  LOAD_ENVIRONMENT,
  LOAD_ENVIRONMENT_FAIL,
  LOAD_ENVIRONMENT_SUCCESS,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingListError } from '../utils/toast';

const entityTitle = 'Environment';

export function fetchEnvironment() {
  return (dispatch) => {
    dispatch({ type: LOAD_ENVIRONMENT });
    ApiService.get('/environment')
      .then((response) => dispatch(fetchEnvironmentSuccess(response)))
      .catch((error) => dispatch(fetchEnvironmentError(error)));
  };
}

const fetchEnvironmentSuccess = (payload) => ({
  type: LOAD_ENVIRONMENT_SUCCESS,
  payload,
});

const fetchEnvironmentError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_ENVIRONMENT_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};
