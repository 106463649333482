import React from 'react';
import { styled } from '@mui/material/styles';

export const RightContentWrapper = styled('div', {
  name: 'rightContentWrapper',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
);
