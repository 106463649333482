import * as _ from 'lodash';
import {
  GET_CARD_DATA,
  GET_CARD_DATA_FAIL,
  GET_CARD_DATA_SUCCESS,
  UPDATE_CARD_DATA,
  UPDATE_CARD_DATA_FAIL,
  UPDATE_CARD_DATA_SUCCESS,
} from '../../../constants/constants';
import ApiService from '../../../services/apiService';
import { toastLoadingListError, toastUpdateSuccess, toastUpdateError } from '../../../utils/toast';

const entityTitle = 'Card Data';

export function fetchCardData(queryModel, paging) {
  const model = {
    cardActions: queryModel.cardActions,
    cardNumber: queryModel.cardNumber === '' ? null : queryModel.cardNumber,
    maskedCardNumber: queryModel.maskedCardNumber === '' ? null : queryModel.maskedCardNumber,
  };
  const sorting = { sort: 'id,ASC' };
  const queryParams = _.extend(paging, sorting);
  return (dispatch) => {
    dispatch({ type: GET_CARD_DATA });
    ApiService.post('/api/card-data', model, queryParams)
      .then((response) => {
        const payload = response.content;
        const paginationInfo = {
          activePage: response.number,
          currentPageSize: response.size,
          totalItemsCount: response.totalElements,
        };
        dispatch(fetchCardDataSuccess({ payload, paginationInfo }));
      })
      .catch((error) => dispatch(fetchCardDataError(error)));
  };
}

export function updateCardData(model, id) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CARD_DATA });
    ApiService.update(`/api/card-data/${id}`, model)
      .then(() => dispatch(updateCardDataSuccess()))
      .catch((error) => dispatch(updateCardDataFail(error)));
  };
}

const updateCardDataSuccess = () => (dispatch) => {
  dispatch({ type: UPDATE_CARD_DATA_SUCCESS });
  dispatch(fetchCardData());
  toastUpdateSuccess(entityTitle);
};

const updateCardDataFail = (error) => (dispatch) => {
  dispatch({ type: UPDATE_CARD_DATA_FAIL });
  toastUpdateError(entityTitle, error);
};

const fetchCardDataSuccess = (cardData) => ({
  type: GET_CARD_DATA_SUCCESS,
  cardData,
});

const fetchCardDataError = (error) => (dispatch) => {
  dispatch({
    type: GET_CARD_DATA_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};
