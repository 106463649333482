import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { downloadFile } from 'utils/downloadFile';
import { removeEmptyProperties } from 'utils/modelHelper';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Pageview as PageviewIcon,
  SaveAlt as SaveAltIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { InputBox, ErrorMessage, FormRow, Select, ISelectOption, PageContainer } from 'uikit';

import { useGetCountriesQuery, useGetCompaniesQuery } from 'services/gatewayApi/credisafeApi';
import { DetailsPageControl } from './DetailsPageControl';

import './CreditsafeSearch.scss';

const CreditsafeSearch: React.FC = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState(undefined);
  const [companies, setCompanies] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 100;
  const totalPages = Math.ceil(total / pageSize);
  const isEmptyQuery = _.isEmpty(query);

  const { data: countries, isLoading: isCountriesLoading } = useGetCountriesQuery(undefined);
  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    isFetching: isCompaniesFetching,
  } = useGetCompaniesQuery(
    {
      ...query,
      page,
      pageSize,
    },
    {
      skip: isEmptyQuery,
    },
  );

  useEffect(() => {
    if (companiesData) {
      setCompanies(companiesData.companies);
      setTotal(companiesData.totalSize > 1000 ? 1000 : companiesData.totalSize); // Result list is limited to 1000 because Creditsafe test API. This might be fixed later.
    }
  }, [companiesData]);

  const onPageChange = (e, page) => {
    setPage(page);
  };

  const isLoading = isCountriesLoading || isCompaniesLoading || isCompaniesFetching;

  const formik = useFormik({
    initialValues: {
      countries: '',
      name: '',
      regNo: '',
      vatNo: '',
      safeNo: '',
      phoneNo: '',
      houseNo: '',
      street: '',
      city: '',
      postCode: '',
      status: '',
    },

    validationSchema: yup.object().shape({
      countries: yup
        .string()
        .required('Please select a country')
        .oneOf(
          countries ? countries.map((country) => country.value) : [],
          'Please select a country from the list',
        ),
    }),

    onSubmit: (values) => {
      const formValues = { ...values };
      removeEmptyProperties(formValues);

      setPage(1);
      setQuery(formValues);
    },
    onReset: () => {},
  });

  // #region Report preview/download
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [focusedConnectId, setFocusedConnectId] = useState(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuOpen = (event, connectId) => {
    setMenuAnchorEl(event.currentTarget);
    setFocusedConnectId(connectId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const previewReportPdf = (connectId, download = false) => {
    setIsDownloading(true);

    downloadFile(`/api/gateway/creditsafe/companies/${connectId}/pdf-report`, {
      fileName: connectId,
      download,
    })
      .catch((rejected) => {
        console.error(rejected);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };
  // #endregion

  return (
    <div className="CreditsafeSearch">
      <Helmet title="Creditsafe Company Search" />

      <PageContainer>
        <DetailsPageControl
          title="Creditsafe Company Search"
          previousAction={() => navigate('/application-management')}
        />
      </PageContainer>

      <PageContainer>
        {isCountriesLoading && <span>Loading...</span>}

        {!isCountriesLoading && (
          <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
              <Grid container spacing={4}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <FormRow>
                    <Select
                      name="countries"
                      selectBox
                      label="Country"
                      disabled={isLoading}
                      options={countries}
                      onChange={(option: ISelectOption) => {
                        formik.setFieldValue('countries', option ? option.value : '');
                      }}
                      value={
                        formik.values.countries
                          ? countries.find((c) => c.value === formik.values.countries)
                          : null
                      }
                      hasError={!!(formik.errors.countries && formik.touched.countries)}
                    />
                    {!!(formik.errors.countries && formik.touched.countries) && (
                      <ErrorMessage>{formik.errors.countries}</ErrorMessage>
                    )}
                  </FormRow>
                </Grid>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <FormRow>
                    <InputBox
                      label="Company Name"
                      type="text"
                      name="name"
                      onChange={formik.handleChange}
                      handleClear={() => formik.setFieldValue('name', '')}
                      value={formik.values.name}
                      disabled={isLoading}
                      hasError={!!(formik.errors.name && formik.touched.name)}
                    />
                    {!!(formik.errors.name && formik.touched.name) && (
                      <ErrorMessage>{formik.errors.name}</ErrorMessage>
                    )}
                  </FormRow>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormRow>
                    <InputBox
                      label="Registration Number"
                      type="text"
                      name="regNo"
                      onChange={formik.handleChange}
                      handleClear={() => formik.setFieldValue('regNo', '')}
                      value={formik.values.regNo}
                      disabled={isLoading}
                    />
                  </FormRow>
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormRow>
                    <InputBox
                      label="VAT Number"
                      type="text"
                      name="vatNo"
                      onChange={formik.handleChange}
                      handleClear={() => formik.setFieldValue('vatNo', '')}
                      value={formik.values.vatNo}
                      disabled={isLoading}
                    />
                  </FormRow>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormRow>
                    <InputBox
                      label="Safe Number"
                      type="text"
                      name="safeNo"
                      onChange={formik.handleChange}
                      handleClear={() => formik.setFieldValue('safeNo', '')}
                      value={formik.values.safeNo}
                      disabled={isLoading}
                    />
                  </FormRow>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <FormRow>
                    <InputBox
                      label="Phone Number"
                      type="text"
                      name="phoneNo"
                      onChange={formik.handleChange}
                      handleClear={() => formik.setFieldValue('phoneNo', '')}
                      value={formik.values.phoneNo}
                      disabled={isLoading}
                    />
                  </FormRow>
                </Grid>

                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <h5>Company Address</h5>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormRow>
                        <InputBox
                          label="House Name/Number"
                          type="text"
                          name="houseNo"
                          onChange={formik.handleChange}
                          handleClear={() => formik.setFieldValue('houseNo', '')}
                          value={formik.values.houseNo}
                          disabled={isLoading}
                        />
                      </FormRow>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormRow>
                        <InputBox
                          label="Street"
                          type="text"
                          name="street"
                          onChange={formik.handleChange}
                          handleClear={() => formik.setFieldValue('street', '')}
                          value={formik.values.street}
                          disabled={isLoading}
                        />
                      </FormRow>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormRow>
                        <InputBox
                          label="City/Town"
                          type="text"
                          name="city"
                          onChange={formik.handleChange}
                          handleClear={() => formik.setFieldValue('city', '')}
                          value={formik.values.city}
                          disabled={isLoading}
                        />
                      </FormRow>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormRow>
                        <InputBox
                          label="Post Code"
                          type="text"
                          name="postCode"
                          onChange={formik.handleChange}
                          handleClear={() => formik.setFieldValue('postCode', '')}
                          value={formik.values.postCode}
                          disabled={isLoading}
                        />
                      </FormRow>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <h5>Company Status</h5>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ padding: '.5rem 0' }}>
                    <RadioGroup
                      row
                      name="status"
                      defaultValue=""
                      onChange={(_, value) => formik.setFieldValue('status', value)}
                    >
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="All"
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        value="Active"
                        control={<Radio />}
                        label="Active"
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        value="NonActive"
                        control={<Radio />}
                        label="Non Active"
                        disabled={isLoading}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="formControlBlock">
                    <div className="summaryInfo">
                      {isCompaniesFetching && <span>Searching...</span>}

                      {!isLoading &&
                        !isEmptyQuery &&
                        companies &&
                        `${
                          total === 0
                            ? 'No companies'
                            : total === 1
                            ? '1 company'
                            : `${total.toLocaleString()} companies`
                        } found`}
                    </div>
                    <Button
                      disabled={isLoading}
                      type="reset"
                      variant="text"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        formik.resetForm();
                        setQuery(undefined);
                      }}
                    >
                      Reset
                    </Button>
                    <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                      <SearchIcon sx={{ mr: 1, fontSize: 20 }} /> Search
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </>
        )}

        {!isLoading && (
          <>
            {!isEmptyQuery && companies && companies.length > 0 && (
              <div className="dataWrapper">
                <table className={`uikit-table ${isDownloading ? 'uikit-table-inprogress' : ''}`}>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Country</th>
                      <th>Registration No.</th>
                      <th>Safe No.</th>
                      <th>Address</th>
                      <th>Company Status</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies.map((c) => (
                      <tr key={c.connectId}>
                        <td>{c.name}</td>
                        <td>{c.country ?? '-'}</td>
                        <td>{c.regNo ?? '-'}</td>
                        <td>{c.safeNo ?? '-'}</td>
                        <td>{c.address ?? '-'}</td>
                        <td>{c.status ?? '-'}</td>
                        <td>
                          {isDownloading && focusedConnectId === c.connectId && (
                            <CircularProgress />
                          )}
                          {(!isDownloading || focusedConnectId !== c.connectId) && (
                            <IconButton
                              onClick={(e) => {
                                handleMenuOpen(e, c.connectId);
                              }}
                              disabled={isDownloading}
                            >
                              <MoreVertIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {totalPages > 1 && (
                  <Box my={2} display="flex" justifyContent="center">
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={onPageChange}
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                )}

                {/* Popup menu used by every row */}
                <Menu
                  anchorEl={menuAnchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem
                    onClick={() => {
                      previewReportPdf(focusedConnectId);
                      handleMenuClose();
                    }}
                  >
                    <PageviewIcon fontSize="inherit" sx={{ mr: '.5rem' }} /> Preview report
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      previewReportPdf(focusedConnectId, true);
                      handleMenuClose();
                    }}
                  >
                    <SaveAltIcon fontSize="inherit" sx={{ mr: '.5rem' }} /> Save report
                  </MenuItem>
                </Menu>
              </div>
            )}
          </>
        )}
      </PageContainer>
    </div>
  );
};

export default CreditsafeSearch;
