import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useGetCaseCategoriesQuery } from '../../../services/gatewayApi/casesApi';

const CaseCategorySelector = (props) => {
  const { onChange } = props;

  const { data, isLoading } = useGetCaseCategoriesQuery(undefined);

  const caseCategories = data?.map((el) => ({
    value: el.category,
    label: el.description,
  }));

  return (
    <>
      {isLoading ? (
        'loading...'
      ) : (
        <Select
          name="caseCategory"
          options={caseCategories}
          isClearable
          onChange={(e) => onChange(e, 'caseCategory')}
        />
      )}
    </>
  );
};

export default CaseCategorySelector;

CaseCategorySelector.propTypes = {
  onChange: PropTypes.func,
};
