import React from 'react';
import { Box } from '@mui/material';
import { ArrowBack, Skeleton } from 'uikit';
import { useGetAcquirerQuery } from 'services/gatewayApi/acquirersApi';
import { DetailsTitle } from './DetailsTitle';
import { DetailsTab } from './DetailsTab';

export const AcquirerDetails = ({ id, onClose }) => {
  const { data: acquirer, isLoading } = useGetAcquirerQuery({ id }, { skip: !id });

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && acquirer && (
        <>
          <DetailsTitle acquirer={acquirer} onClose={onClose} />
          <DetailsTab acquirer={acquirer} />
        </>
      )}
    </Box>
  );
};
