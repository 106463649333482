/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  CANCEL_CREATING_COMMENT,
  CANCEL_UPDATING_COMMENT,
  CREATE_CASE_COMMENT,
  CREATE_CASE_COMMENT_FAIL,
  CREATE_CASE_COMMENT_SUCCESS,
  CREATING_COMMENT,
  DELETE_CASE_COMMENT_SUCCESS,
  LOAD_CASE_COMMENTS,
  LOAD_CASE_COMMENTS_FAIL,
  LOAD_CASE_COMMENTS_SUCCESS,
  UPDATE_CASE_COMMENT_FAIL,
  UPDATE_CASE_COMMENT_SUCCESS,
  UPDATING_COMMENT,
} from '../constants/constants';

export const initialState = {
  loading: false,
  creatingComment: false,
  updatingComment: false,
  error: false,
  caseComments: [],
};

const caseCommentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_CASE_COMMENTS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_CASE_COMMENTS_SUCCESS:
        draft.caseComments = action.comments;
        draft.loading = false;
        break;
      case LOAD_CASE_COMMENTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case CREATING_COMMENT:
        draft.creatingComment = true;
        break;
      case UPDATING_COMMENT:
        draft.updatingComment = action.id;
        break;
      case CANCEL_CREATING_COMMENT:
        draft.creatingComment = false;
        break;
      case CANCEL_UPDATING_COMMENT:
        draft.updatingComment = null;
        break;
      case CREATE_CASE_COMMENT:
        draft.creatingComment = true;
        break;
      case CREATE_CASE_COMMENT_SUCCESS:
        draft.creatingComment = false;
        break;
      case CREATE_CASE_COMMENT_FAIL:
        draft.creatingComment = true;
        draft.error = action.error;
        break;
      case UPDATE_CASE_COMMENT_SUCCESS:
        draft.updatingComment = null;
        break;
      case UPDATE_CASE_COMMENT_FAIL:
        draft.error = action.error;
        break;
      case DELETE_CASE_COMMENT_SUCCESS: {
        const idx = state.caseComments.findIndex((val) => val.commentId === action.id);
        if (idx !== -1) draft.caseComments.splice(idx, 1);
        break;
      }
    }
  });

export default caseCommentsReducer;
