import { SortOptions } from 'types';

export const sortOptions: SortOptions[] = [
  { fieldName: 'firstName', label: 'First Name' },
  { fieldName: 'lastName', label: 'Last Name' },
  { fieldName: 'email', label: 'Email' },
  { fieldName: 'extKycStatus', label: 'Final Status' },
  { fieldName: 'manualResult', label: 'Manual Status' },
  { fieldName: 'createdDate', label: 'Created on' },
  { fieldName: 'modifiedDate', label: 'Updated on' },
];
