import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalDialog, PageControl, SortByHeader, TabLink } from 'uikit';
import { Counter, SortParams, Tabs } from './types';
import { grey } from '../../theme/palette-blocks';

interface PepAndSanctionsHeaderProps {
  counter: Counter;
  tabs?: Tabs;
  sortBy?: SortParams;
  setSortBy?: React.Dispatch<React.SetStateAction<SortParams>>;
  searchValue?: string | undefined;
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
  subTitle?: string;
  isTransactions?: boolean;
}

export const PepAndSanctionsHeader: React.FC<PepAndSanctionsHeaderProps> = ({
  counter,
  tabs,
  sortBy,
  setSortBy,
  searchValue,
  setSearchValue,
  subTitle,
  isTransactions,
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleSearchFor = (searchType: 'business' | 'individual') => {
    setOpen(false);
    applicationId
      ? navigate(`/application-management/${applicationId}/pep-and-sanctions/new-search`, {
          state: { searchType },
        })
      : navigate(`/pep-and-sanctions/applications/new-search`, { state: { searchType } });
  };

  return (
    <>
      <PageControl
        title="PEP and Sanctions"
        primaryActionTitle="New search"
        primaryAction={isTransactions ? undefined : () => setOpen(true)}
        searchValue={searchValue || undefined}
        searchHandler={setSearchValue || undefined}
      />

      <SortByHeader
        count={counter.numberOfElements || 0}
        totalCount={counter.totalElements}
        // sortOptions={sortOptionsTransactionsPep} TODO make sort
        // sortedBy={sortBy} TODO make sort
        // setSortedBy={setSortBy} TODO make sort
        subTitle={subTitle}
      />

      <Stack direction="row" gap={8} style={{ borderBottom: `1px solid ${grey[100]}` }} mt={4}>
        {!applicationId && <TabLink link="/pep-and-sanctions/applications">Applications</TabLink>}
        {!applicationId && <TabLink link="/pep-and-sanctions/transactions">Transactions</TabLink>}
      </Stack>

      {open && (
        <ModalDialog
          open={open}
          onClose={() => setOpen(false)}
          title="Search for"
          handleAction={() => handleSearchFor('business')}
          actionTitle="Business"
          noCancel
          noCrossButton={false}
          handleActionSecondary={() => handleSearchFor('individual')}
          actionTitleSecondary="Individual"
        />
      )}
    </>
  );
};
