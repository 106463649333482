import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { Input } from '@mui/base';
import { InputTransparentInput } from '..';
import { grey } from '../../theme/palette-blocks';

interface NoteHeaderProps<
  TitleTypographyComponent extends React.ElementType = 'span',
  MetadataTypographyComponent extends React.ElementType = 'span',
> {
  metadata?: React.ReactNode;
  metadataTypographyProps?: TypographyProps<MetadataTypographyComponent>;
  title?: React.ReactNode;
  titleTypographyProps?: TypographyProps<TitleTypographyComponent>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  editable?: boolean;
  action?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const shouldWrapInTypography = (node: React.ReactNode): React.ReactNode =>
  typeof node === 'string' || (React.isValidElement(node) && node.type !== Typography);

const NoteHeaderRoot = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    mb: 0,
  }),
);

const NoteHeaderAction = styled('div')(({ theme }) =>
  theme.unstable_sx({
    flex: '0 0 auto',
    alignSelf: 'flex-start',
    mt: -1,
    mr: -2,
    mb: -1,
  }),
);

const NoteHeader = React.forwardRef(
  (props: NoteHeaderProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    const {
      action,
      metadata: metadataProp,
      metadataTypographyProps,
      title: titleProp,
      titleTypographyProps,
      editable: editableProp = false,
      onChange,
      ...other
    } = props;
    let editable = editableProp;
    if (editableProp && typeof titleProp !== 'string') {
      editable = false;
      if (process.env.NODE_ENV !== 'production')
        console.warn("Only children of type 'string' could be editable");
    }
    const metadata = shouldWrapInTypography(metadataProp) ? (
      <Typography
        sx={{ mb: 3, flex: 1 }}
        variant="body1"
        color="grey.600"
        component="span"
        display="block"
        {...metadataTypographyProps}
      >
        {metadataProp}
      </Typography>
    ) : (
      metadataProp
    );
    const title = shouldWrapInTypography(titleProp) ? (
      <Typography
        sx={{ width: '100%' }}
        display="block"
        component={editable ? Input : 'span'}
        {...(editable
          ? {
              slots: { input: InputTransparentInput },
              slotProps: {
                input: {
                  maxLength: 128,
                  style: {
                    border: `1px solid ${grey[100]}`,
                    borderRadius: '6px',
                    padding: '6px 10px',
                  },
                },
              },
              placeholder: 'Note title',
              value: titleProp,
              onChange,
              // Should be set by props, like ...titleProps or ...componentProps.title
              autoFocus: true,
            }
          : {})}
        {...titleTypographyProps}
      >
        {!editable && titleProp}
      </Typography>
    ) : (
      titleProp
    );

    return (
      <NoteHeaderRoot ref={ref} {...other}>
        {metadata}
        {action && <NoteHeaderAction>{action}</NoteHeaderAction>}
        {title}
      </NoteHeaderRoot>
    );
  },
);

export default NoteHeader;
