import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import './bodyTableCell.css';
import { grey } from '../../theme/palette-blocks';

export const BodyTableCell = styled(TableCell)(({ theme }) =>
  theme.unstable_sx({
    typography: 'body1',
    borderBottom: `1px dashed ${grey[200]}`,
    py: 3,
  }),
);
