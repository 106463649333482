export default function (productNamesToLook) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user || !user.selectedProductId) return false;

  const selectedProduct = user.tenants.find((p) => p.id === parseInt(user.selectedProductId));

  if (typeof selectedProduct !== 'undefined') {
    if (productNamesToLook.indexOf(selectedProduct.name.toUpperCase()) > -1) {
      return true; // exists
    }
  }

  return false;
}
