/* eslint-disable no-param-reassign */
import { getTokenParser } from './parser';
import { BinaryNode, DotNode, PropertyNode, UnaryNode, ValueNode } from './nodes/index.ts';

export const getBinaryTree = (str, propPrefix) => process(processOperands(str, propPrefix));
const isMatch = (token) => typeof token !== 'undefined';

function processOperands(str, propPrefix = '') {
  const tokens = [];
  const tokenParser = getTokenParser(propPrefix);
  str.replace(tokenParser, (token, number, string, bool, op, property) => {
    if (isMatch(number)) {
      token = new ValueNode(+number);
    } else if (isMatch(string)) {
      token = new ValueNode(JSON.parse(string));
    } else if (isMatch(bool)) {
      token = new ValueNode(bool === 'true');
    } else if (isMatch(property)) {
      token = new PropertyNode(property.substring(propPrefix.length));
    } else if (!op) {
      throw new Error(`unexpected token '${token}'`);
    }
    tokens.push(token);
  });
  return tokens;
}

function processDots(tokens) {
  for (let i; (i = tokens.indexOf('.')) > -1; ) {
    tokens.splice(i - 1, 3, new DotNode(tokens[i - 1], tokens[i + 1]));
  }
}

function processBrackets(tokens) {
  for (let i, j; (i = tokens.lastIndexOf('(')) > -1 && (j = tokens.indexOf(')', i)) > -1; ) {
    tokens.splice(i, j + 1 - i, process(tokens.slice(i + 1, j)));
  }
  if (tokens.indexOf('(') !== -1 || tokens.indexOf(')') !== -1) {
    throw new Error('mismatching brackets');
  }
}

function processUnaryNodes(tokens) {
  UnaryNode.operators.forEach((token) => {
    for (let i = 0; (i = tokens.indexOf(token, i)) > -1; ) {
      tokens.splice(i, 2, new UnaryNode(token, tokens[i + 1]));
    }
  });
}

function processBinaryNodes(tokens) {
  BinaryNode.operators.forEach((token) => {
    for (let i = 1; (i = tokens.indexOf(token, i - 1)) > -1; ) {
      tokens.splice(i - 1, 3, new BinaryNode(token, tokens[i - 1], tokens[i + 1]));
    }
  });
}

function process(tokens) {
  processDots(tokens);
  processBrackets(tokens);
  processUnaryNodes(tokens);
  processBinaryNodes(tokens);

  if (tokens.length !== 1) {
    // console.log('error: ', tokens.slice());
    throw new Error('something went wrong');
  }

  // console.log('Resulting tree: ', tokens[0])
  // console.log('Resulting expression: ', tokens[0].toString())
  return tokens[0];
}
