import * as React from 'react';
import { useState } from 'react';
import {
  TextField,
  Tooltip,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { DATE_FORMAT } from 'constants/constants';
import { formatDateScreen, formatIsoDate } from 'utils/formatter';
import { Badge } from 'uikit/Badge';
import {
  useAddRuleVetoCommentMutation,
  useDeleteRuleVetoMutation,
} from 'services/gatewayApi/ruleVetosApi';
import { badgeTheme, vetoTypes } from './constants';
import { RuleVeto } from '../Rules/types';

interface RowRuleVetoProps {
  veto: RuleVeto;
  isLoading: boolean;
}

export const RowRuleVeto: React.FC<RowRuleVetoProps> = ({ veto, isLoading }) => {
  const {
    id,
    referenceIdentifier,
    ruleId,
    ruleVetoType,
    status,
    comments,
    createdBy,
    createdDate,
    expiryDate,
    startDate,
    updatedDate,
    updatedBy,
  } = veto;

  const [addRuleVetoComment] = useAddRuleVetoCommentMutation();
  const [deleteRuleVeto] = useDeleteRuleVetoMutation();
  const [open, setOpen] = useState<boolean>(false);

  const handleUpdate = (body) => {
    if (!body || body === '') {
      return null;
    }
    return addRuleVetoComment({ ruleId, id, body });
  };

  return (
    <>
      <TableRow
        key={id}
        hover
        onClick={() => setOpen((prev) => !prev)}
        sx={{ backgroundColor: 'grey.100', cursor: 'pointer' }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {vetoTypes.find((el) => el.value === ruleVetoType)?.label || ''}
        </TableCell>
        <TableCell component="th" scope="row">
          {referenceIdentifier}
        </TableCell>
        <TableCell align="center">{formatDateScreen(startDate)}</TableCell>
        <TableCell align="center">{formatDateScreen(expiryDate)}</TableCell>
        <TableCell align="center">
          <Badge theme={badgeTheme[status]}>{status}</Badge>
        </TableCell>
        <TableCell padding="checkbox" align="center">
          <Tooltip title="Delete" disableFocusListener>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteRuleVeto(id);
              }}
              edge="end"
              disabled={isLoading}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0, borderBottom: '0px' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Created On</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Updated On</TableCell>
                    <TableCell>Updated By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ py: 2, borderBottom: 0, verticalAlign: 'top' }}>
                      {formatIsoDate(createdDate, DATE_FORMAT)}
                    </TableCell>
                    <TableCell sx={{ py: 2, borderBottom: 0, verticalAlign: 'top' }}>
                      {createdBy}
                    </TableCell>
                    <TableCell sx={{ py: 2, borderBottom: 0, verticalAlign: 'top' }}>
                      <TextField
                        id="comments"
                        multiline
                        type="text"
                        fullWidth
                        name="comments"
                        onBlur={(e) => handleUpdate(e.target.value)}
                        defaultValue={comments}
                        variant="standard"
                        InputProps={{ sx: { pt: 0, typography: 'body2' } }}
                      />
                    </TableCell>
                    <TableCell sx={{ py: 2, borderBottom: 0, verticalAlign: 'top' }}>
                      {formatIsoDate(updatedDate, DATE_FORMAT)}
                    </TableCell>
                    <TableCell sx={{ py: 2, borderBottom: 0, verticalAlign: 'top' }}>
                      {updatedBy}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
