import React, { useState } from 'react';
import { Chip, IconButton, TableCell, TableRow } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { DATETIME_FORMAT_MOMENT } from 'constants/constants';
import getLocalDateTime from 'utils/formatter';
import { kycStatuses, kycResults, extKycStatuses } from '../../../constants';
import { KycCheck } from '../../../types';
import { KycCheckDocuments } from './KycCheckDocuments';

const getStatusChip = (statuses, value) => {
  const result = statuses.byId[value];
  return value || result ? (
    <Chip label={result?.label ?? value} color={result?.color ?? 'primary'} />
  ) : null;
};

interface KycCheckRowProps {
  kycCheck: KycCheck;
}

export const KycCheckRow: React.FC<KycCheckRowProps> = ({ kycCheck }) => {
  const { createdDate, status, result, extStatus } = kycCheck;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{getLocalDateTime(createdDate).format(DATETIME_FORMAT_MOMENT)}</TableCell>
        <TableCell>{getStatusChip(kycStatuses, status)}</TableCell>
        <TableCell>{getStatusChip(kycResults, result)}</TableCell>
        <TableCell>{getStatusChip(extKycStatuses, extStatus)}</TableCell>
      </TableRow>
      <KycCheckDocuments kycCheck={kycCheck} isOpen={isOpen} />
    </>
  );
};
