import React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';

interface NoteProps {
  sx?: SxProps<Theme>;
  editable?: boolean;
  children: React.ReactNode;
}

const NoteRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'editable' && prop !== 'sx',
})<{ editable?: boolean }>(({ editable = false, theme }) =>
  theme.unstable_sx([
    {
      bgcolor: 'grey.100',
      borderRadius: '6px',
      p: 4,
    },
    editable && {
      bgcolor: 'white',
    },
  ]),
);

const Note = React.forwardRef(
  (
    { children, ...other }: React.PropsWithChildren<NoteProps>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => (
    <NoteRoot ref={ref} {...other}>
      {children}
    </NoteRoot>
  ),
);

export default Note;
