import React from 'react';
import { Chip } from '@mui/material';
import { RiCircleFill } from 'react-icons/ri';

import { riskLevels } from '../constants';

export const RiskLevelChip = ({ riskLevel }) => {
  const riskLevelItem = riskLevels.find((i) => i.value === riskLevel);

  return (
    <>
      {riskLevelItem && (
        <Chip
          icon={<RiCircleFill size="14px" />}
          size="medium"
          color={riskLevelItem.color as any}
          label={riskLevelItem.label}
          sx={{ borderRadius: '8px', pl: 1 }}
        />
      )}
    </>
  );
};
