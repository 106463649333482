import React from 'react';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import { IconButton } from 'uikit/IconButton';

import './DetailsPageControl.scss';

interface DetailsPageControlProps {
  title: string; // Title of component
  previousAction?: any;
}

const DetailsPageControl: React.FC<DetailsPageControlProps> = ({ title, previousAction }) => (
  <div className="DetailsPageControlWrapper">
    {previousAction && (
      <IconButton className="previousActionButton" theme="text" onClick={previousAction}>
        <ArrowBackIcon style={{ fontSize: '20px' }} />
      </IconButton>
    )}

    <div className="DetailsPageControl">
      <div className="pageHeadingTitle">
        <h1 className="pageTitle">{title}</h1>
      </div>
    </div>
  </div>
);

export default DetailsPageControl;
