import * as React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box, SxProps, Theme, Typography, useAutocomplete } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ValueLabel } from '../../../types';
import { TagsWrapper } from './TagsWrapper';
import { InputWrapper } from './InputWrapper';
import { Listbox } from '../../AutocompleteMulti/AutocompleteMulti';

interface FormMultiSelectProps {
  options: ValueLabel[];
  values: ValueLabel[];
  onChange: (event, newValue) => void;
  name?: string;
  label?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  sx?: SxProps<Theme>;
}

export const FormMultiSelect: React.FC<FormMultiSelectProps> = ({
  name = 'multiselect',
  options,
  values = [],
  onChange,
  placeholder = undefined,
  error = false,
  disabled = false,
  disableCloseOnSelect = true,
  label,
  multiple = true,
  sx,
}) => {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: name,
    value: values,
    multiple,
    disableCloseOnSelect: multiple,
    options,
    getOptionLabel: (option: ValueLabel) => option.label,
    onChange,
  });

  return (
    <Box {...sx}>
      <label>
        {label && (
          <Typography variant="body1" pb={2} color={disabled ? 'text.disabled' : 'text.primary'}>
            {label}
          </Typography>
        )}
        <InputWrapper {...getRootProps} error={error} disabled={disabled}>
          <TagsWrapper getTagProps={getTagProps} disabled={disabled} values={value} />
          <InputBase
            sx={{ flex: 1, '& .MuiInputBase-input': { lineHeight: '20px' } }}
            placeholder={placeholder}
            inputProps={{ ...getInputProps() }}
            disabled={disabled}
            value={value}
            startAdornment={<AddCircleIcon sx={{ pr: '8px' }} />}
          />
        </InputWrapper>
        {groupedOptions.length > 0 && (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={index} {...getOptionProps({ option, index })}>
                <span>{option.label}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        )}
      </label>
    </Box>
  );
};
