export const capitalize = (value: string): string =>
  value ? value[0].toUpperCase() + value.substring(1) : value;


const wordsToIgnoreUppercase = ['and', 'or', 'from', 'to'];

export const capitalizeExcept = (
  sentence: string,
  exceptions: string[] = wordsToIgnoreUppercase
) => {
  return sentence.toLowerCase()
    .split(' ')
    .map(word => exceptions.includes(word) ? word : word[0].toUpperCase() + word.slice(1))
    .join(' ');
}
