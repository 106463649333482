import React from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';

interface NoteActionsProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

const NoteActionsRoot = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row-reverse',
  }),
);

const NoteActions = React.forwardRef(
  (
    { children, ...other }: React.PropsWithChildren<NoteActionsProps>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => (
    <NoteActionsRoot ref={ref} {...other}>
      {children}
    </NoteActionsRoot>
  ),
);

export default NoteActions;
