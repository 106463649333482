import React, { useEffect, useState } from 'react';
import { Box, IconButton, Popover, Stack, Typography } from '@mui/material';
import { StaticDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RiCalendar2Fill } from 'react-icons/ri';
import { DATE_SERVER_FORMAT } from 'constants/constants';
import { formatDateScreen, parseDate } from 'utils';
import { styled } from '@mui/material/styles';
import { grey } from '../../../../theme/palette-blocks';

const StyledBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    py: 1,
    pl: 4,
    pr: 2,
    my: 3,
    width: '100%',
    borderRadius: 1,
    bgcolor: 'grey.100',
    cursor: 'pointer',
  }),
);

interface FilterRowDateRangeProps {
  dateFrom: string;
  dateTo: string;
  onChange?: (dateFrom: string, dateTo: string) => void;
}

export const FilterRowDateRange: React.FC<FilterRowDateRangeProps> = ({
  dateFrom: dateFromProp,
  dateTo: dateToProp,
  onChange,
}) => {
  const [dateFrom, setDateFrom] = useState(dateFromProp);
  const [dateTo, setDateTo] = useState(dateFromProp);

  useEffect(() => {
    setDateFrom(dateFromProp);
    setDateTo(dateToProp);
  }, [dateFromProp, dateToProp]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <StyledBox
      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
        setAnchorEl(anchorEl ? null : event.currentTarget)
      }
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1, width: '100%' }}
      >
        <Typography variant="body" component="span" color="grey.700">
          {dateFrom} - {dateTo}
        </Typography>

        <IconButton type="button">
          <RiCalendar2Fill fontSize="18" color={grey[700]} />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation()}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" justifyContent="space-between">
              <StaticDatePicker
                value={parseDate(dateFrom, DATE_SERVER_FORMAT)}
                maxDate={parseDate(dateTo, DATE_SERVER_FORMAT)}
                onChange={(newValue: Date | null) => {
                  const dateFrom = formatDateScreen(newValue, DATE_SERVER_FORMAT);
                  setDateFrom(dateFrom);
                  onChange && onChange(dateFrom, dateTo);
                }}
                showToolbar={false}
                componentsProps={{ actionBar: { actions: [] } }}
                renderInput={null}
              />
              <StaticDatePicker
                value={parseDate(dateTo, DATE_SERVER_FORMAT)}
                minDate={parseDate(dateFrom, DATE_SERVER_FORMAT)}
                onChange={(newValue: Date | null) => {
                  const dateTo = formatDateScreen(newValue, DATE_SERVER_FORMAT);
                  setDateTo(dateTo);
                  onChange && onChange(dateFrom, dateTo);
                }}
                showToolbar={false}
                componentsProps={{ actionBar: { actions: [] } }}
                renderInput={null}
              />
            </Stack>
          </LocalizationProvider>
        </Popover>
      </Stack>
    </StyledBox>
  );
};
