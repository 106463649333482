import React from 'react';
import './Badge.scss';

export type BadgeType = 'default' | 'primary' | 'success' | 'danger' | 'warning';
export type BadgeSize = 'sm' | 'md' | 'lg';
export type BadgeVariant = 'contained' | 'outlined' | 'text';
export type BadgeShape = 'squared' | 'rounded' | 'circled';

interface BadgeProps {
  theme?: BadgeType;
  size?: BadgeSize;
  children?: React.ReactChild | React.ReactChild[];
  bold?: boolean;
  variant?: BadgeVariant;
  shape?: BadgeShape;
  interactive?: boolean;
  prefix?: React.ReactChild;
  suffix?: React.ReactChild;
  active?: boolean;
  class?: string;
  style?: any;
  onClick?: (e?: React.MouseEvent) => any;
}

const Badge: React.FC<BadgeProps> = ({
  children,
  prefix,
  suffix,
  theme = 'default',
  size = 'sm',
  bold = false,
  variant = 'contained',
  shape = 'circled',
  interactive = false,
  active = false,
  ...other
}) => {
  const baseClassname = 'hayBadge';
  let className = `${baseClassname} ${baseClassname}--${theme} ${baseClassname}--${size} ${baseClassname}--${variant} ${baseClassname}--${shape}`;
  className += bold ? ` ${baseClassname}--bold` : '';
  className += interactive ? ` ${baseClassname}--interactive` : '';
  className += prefix ? ` ${baseClassname}--has-prefix` : '';
  className += suffix ? ` ${baseClassname}--has-suffix` : '';
  className += active ? ` ${baseClassname}--active` : '';
  className += children ? '' : ` ${baseClassname}--empty`;

  return (
    <span {...other} className={className}>
      <span className={`${baseClassname}--prefix`}>{prefix}</span>
      {children}
      <span className={`${baseClassname}--suffix`}>{suffix}</span>
    </span>
  );
};

export default Badge;
