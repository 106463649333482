import PropTypes from 'prop-types';
import React from 'react';

const DataTableRow = (props) => {
  const { children, onClick, className } = props;

  return (
    <tr onClick={onClick} className={className}>
      {children}
    </tr>
  );
};

DataTableRow.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default DataTableRow;
