import { ValueLabel } from 'types';
import { gatewayApi } from '../gatewayApi';

interface DictResponse {
  data: ValueLabel[];
  name: string;
}

const dictionaryApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getDictionariesList: build.query<string[], undefined>({
      query: () => ({
        url: `dict-list`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'DictionaryList', id: 'LIST' }],
    }),
    getDictionary: build.query<ValueLabel[], string>({
      query: (dictName) => ({
        url: `dict/${dictName}`,
        method: 'GET',
      }),
      providesTags: (result, error, dictName) => [{ type: 'Dictionary', id: dictName }],
      transformResponse: (response: DictResponse) =>
        response.data.sort((a, b) => (a.label > b.label ? 1 : -1)),
    }),
  }),
});

export const { useGetDictionariesListQuery, useGetDictionaryQuery } = dictionaryApi;
