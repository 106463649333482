import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import DOMPurify from 'isomorphic-dompurify';
import { ValueLabel } from '../../../types';
import { FormFieldWrapper } from '../FormFieldWrapper';

interface FormRadioProps {
  options?: ValueLabel[];
  value?: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (e: any) => void;
  sx?: any;
}

const getLabel = (label) => {
  const cleanHtml = DOMPurify.sanitize(label);
  return <span dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
};

export const FormRadio: React.FC<FormRadioProps> = ({
  onChange,
  label = undefined,
  value = undefined,
  options = [],
  error = false,
  disabled = false,
  sx = undefined,
}) => (
  <Box display="flex" flexDirection="column" {...sx}>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label>
      {label && (
        <Typography variant="body1" pb={2} color={disabled ? 'text.disabled' : 'text.primary'}>
          {getLabel(label)}
        </Typography>
      )}
      <RadioGroup
        value={value}
        onChange={onChange}
        sx={{
          display: 'flex',
          width: '100%',
          gap: 2,
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          flexDirection: 'row',
        }}
      >
        {options &&
          options.map((el) => (
            <FormFieldWrapper
              disabled={disabled}
              error={error}
              sx={{ width: '100%' }}
              key={el.label}
            >
              <FormControlLabel
                value={el.value}
                control={
                  <Radio
                    sx={{ p: 0.5, m: 0, transition: 'all 0.3s ease-in-out' }}
                    checkedIcon={<TripOriginIcon />}
                  />
                }
                label={el.label}
                disabled={disabled}
                sx={{ m: 0, pb: '1px', gap: 2 }}
              />
            </FormFieldWrapper>
          ))}
      </RadioGroup>
    </label>
  </Box>
);
