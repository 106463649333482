import { FilterScheme } from 'types';

export const filterScheme: FilterScheme[] = [
  {
    field: 'select',
    name: 'jurisdictionalCountry',
    label: 'Jurisdictional Country',
  },
  {
    field: 'select',
    name: 'applicationStatus',
    label: 'Application Status',
  },
  {
    field: 'text',
    name: 'description',
    label: 'Description',
  },
  {
    field: 'checkbox',
    name: 'isTrue',
    label: 'Is true?',
  },
];
