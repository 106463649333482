import React from 'react';
import { BodyRow, BodyTableCell } from 'uikit';
import { Chip, Typography } from '@mui/material';
import { Ubo } from './types';
import { documentTypes } from './constants';

interface UboRowProps {
  item: Ubo;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  countries: any[];
  selected: boolean;
}

export const UboRow: React.FC<UboRowProps> = ({
  item,
  setSelectedItemId,
  setOpenDetailsDrawer,
  countries,
  selected = false,
}) => (
  <BodyRow
    hover
    selected={selected}
    onClick={() => {
      setSelectedItemId(item.id);
      setOpenDetailsDrawer(true);
    }}
    sx={!selected ? { cursor: 'pointer' } : {}}
  >
    <BodyTableCell>
      <Typography variant="body1" component="div" sx={{ mb: 1 }}>
        {item.firstName} {item.lastName}
      </Typography>
      <Typography variant="body2" component="div" color="grey.600">
        {item.number}
      </Typography>
    </BodyTableCell>
    <BodyTableCell>{item.email}</BodyTableCell>
    <BodyTableCell>
      <Typography variant="body1" component="div" sx={{ mb: 1 }}>
        <Typography component="span" color="grey.600">
          Nationality:{' '}
        </Typography>
        {countries?.find((c) => c.countryCode === item.nationality)?.name ?? '-'}
      </Typography>
      <Typography variant="body1" component="div" sx={{ mb: 1 }}>
        <Typography component="span" color="grey.600">
          Document:{' '}
        </Typography>
        {documentTypes?.find((c) => c.value === item.documentType)?.label ?? '-'}
      </Typography>
    </BodyTableCell>
    <BodyTableCell sx={{ textAlign: 'right' }}>
      {item.status === 'ACTIVE' && <Chip size="small" color="secondary" label="Active" />}
      {item.status === 'INACTIVE' && <Chip size="small" color="error" label="Inactive" />}
    </BodyTableCell>
  </BodyRow>
);
