import React from 'react';
import { Panel } from 'uikit';
import { Typography, Box } from '@mui/material';
import { ButtonsUpdated } from './ButtonsUpdated';
import './ButtonsUpdatedSection.scss';

export const ButtonsUpdatedSection = () => (
  <Box mb={8}>
    <div className="ButtonsUpdatedSection">
      <Typography variant="h2" gutterBottom mb={4}>
        Buttons
      </Typography>

      {['primary', 'secondary', 'success', 'error', 'bold', 'base'].map((color, i) => (
        <Panel key={i}>
          <ButtonsUpdated color={color} />
        </Panel>
      ))}
    </div>
  </Box>
);
