import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dropzone, useDropzone } from 'muikit/Dropzone';
import { Menu } from 'muikit/Menu';
import { ModalDialog } from 'uikit/ModalDialog';
import { CancellableLinearProgress } from 'muikit/Progress';
import {
  RiDeleteBinFill,
  RiDownloadLine,
  RiUploadLine,
  RiErrorWarningFill,
  RiMore2Fill,
  RiHourglassLine,
} from 'react-icons/ri';
import {
  ApplicationNoteDocument,
  useAddApplicationNoteDocumentMutation,
  useDeleteApplicationNoteDocumentMutation,
  useUploadApplicationNoteDocumentMutation,
  VirusStatus,
} from 'services/gatewayApi/applicationNoteDocumentsApi';
import { seIsUploading, setUploadProgress } from 'services/indicatorsSlice';
import { downloadFile } from 'utils/downloadFile';
import { StateType } from '../../../../../Rules/types';
import { FigmaColor } from '../../../../../../theme';

interface ApplicationAttachmentProps {
  applicationId: string;
  doc: Partial<ApplicationNoteDocument>;
}

export const Attachment: React.FC<ApplicationAttachmentProps> = ({ applicationId, doc }) => {
  const { id, noteId, fileName, virusStatus } = doc;

  const isNew = id === 'new';

  const [docId, setDocId] = useState<string>(id);
  const [virusModalOpen, setVirusModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const idUploading = useSelector((state: StateType) => state.indicators.idUploading);

  const [addDocument, { isLoading: isAdding }] = useAddApplicationNoteDocumentMutation();
  const [uploadDocument, { isLoading: isUploading }] = useUploadApplicationNoteDocumentMutation();
  const [deleteDocument, { isLoading: isDeleting }] = useDeleteApplicationNoteDocumentMutation();

  useEffect(() => {
    if (isUploading) {
      dispatch(seIsUploading({ idUploading: docId }));
    }
    if (docId === idUploading && !isUploading) {
      dispatch(seIsUploading({ idUploading: undefined }));
    }
  }, [isUploading]);

  const isOtherUploading = useSelector((state: StateType) => state.indicators.idUploading);
  const isDisabled = (isOtherUploading && docId !== isOtherUploading) || false;

  const cancelControllerRef = useRef(new AbortController());

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    open: openDropzoneFileDialog,
  } = useDropzone({
    setShowDropzone,
    upload: (formData: FormData) => {
      if (isNew) {
        // Create document first then upload
        addDocument({ applicationId, noteId, title: docId })
          .unwrap()
          .then((newDocument) => {
            setDocId(newDocument.id);
            handleUploadDocument(newDocument.id, formData);
          });
      } else {
        // Upload file only
        handleUploadDocument(docId, formData);
      }
    },
  });

  const handleUploadDocument = (id, formData) => {
    uploadDocument({
      formData,
      applicationId,
      noteId,
      id,
      signal: cancelControllerRef.current.signal,
    })
      .unwrap()
      .catch((rejected) => {
        console.error(rejected);

        if (isNew) {
          deleteDocument({ id: docId, applicationId, noteId });
        }
      });
  };

  const cancelUpload = (cancelMessage: string) => {
    cancelControllerRef.current.abort('cancelMessage');
    console.warn(cancelMessage);
    cancelControllerRef.current = new AbortController();
    dispatch(setUploadProgress({ id: docId, progressValue: undefined })); // Clear progress

    if (isNew) {
      deleteDocument({ id: docId, applicationId, noteId });
    }
  };

  useEffect(
    () => () => {
      cancelControllerRef.current.abort();
      cancelControllerRef.current = new AbortController(); // Create a new controller for the next render
    },
    [],
  );

  const handleUpload = () => {
    setShowDropzone(true);
    setTimeout(openDropzoneFileDialog, 100);
  };

  const handleDelete = async () => {
    try {
      await deleteDocument({ id: docId, applicationId, noteId }).unwrap();
      setDeleteModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
        mr: 2,
        mb: 2,
        bgcolor: isNew ? '' : 'grey.100',
        borderRadius: '6px',
        overflow: 'hidden',
      }}
      onDragEnter={() => (isDisabled || isUploading ? {} : setShowDropzone(true))}
    >
      <>
        <Box sx={{ position: 'relative', p: '4px', minWidth: '130px' }}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            sx={showDropzone || isUploading ? { pointerEvents: 'none' } : {}}
          >
            {!isNew ? (
              <>
                <Stack direction="row" alignItems="center">
                  {virusStatus === VirusStatus.Checked && (
                    <IconButton
                      color="inherit"
                      size="small"
                      disabled={isDownloading}
                      onClick={() => {
                        setIsDownloading(true);
                        downloadFile(
                          `/api/gateway/applications/${applicationId}/notes/${noteId}/documents/${docId}/download`,
                          { download: true },
                        ).finally(() => setIsDownloading(false));
                      }}
                    >
                      <RiDownloadLine size="14px" />
                    </IconButton>
                  )}
                  {virusStatus === VirusStatus.Quarantined && (
                    <IconButton color="error" size="small" onClick={() => setVirusModalOpen(true)}>
                      <RiErrorWarningFill size="14px" />
                    </IconButton>
                  )}
                  {virusStatus === VirusStatus.Pending && (
                    <Tooltip title="Processing...">
                      <IconButton color="inherit" size="small" sx={{ cursor: 'default' }}>
                        <RiHourglassLine size="14px" />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '14px',
                      maxWidth: '182px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                      lineHeight: '28px',
                    }}
                  >
                    {fileName}
                  </Typography>
                </Stack>

                <Menu
                  renderOpenButton={(props) => (
                    <IconButton {...props} color="inherit" size="small">
                      <RiMore2Fill size="14px" />
                    </IconButton>
                  )}
                  renderItems={({ onClick }) => [
                    <MenuItem
                      key="Attachment_Menu_Reupload"
                      onClick={(e) => {
                        // eslint-disable-next-line react/prop-types
                        onClick && onClick(e);
                        handleUpload();
                      }}
                      disabled={isDisabled}
                    >
                      <ListItemIcon>
                        <RiUploadLine size="14px" />
                      </ListItemIcon>
                      Reupload
                    </MenuItem>,
                    <Divider key="Attachment_Menu_Divider" />,
                    <MenuItem
                      key="Attachment_Menu_Delete"
                      sx={{ color: 'error.main' }}
                      onClick={(e) => {
                        // eslint-disable-next-line react/prop-types
                        onClick && onClick(e);
                        setDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon sx={{ color: 'error.main' }}>
                        <RiDeleteBinFill size="14px" />
                      </ListItemIcon>
                      Delete
                    </MenuItem>,
                  ]}
                />
              </>
            ) : (
              <Typography
                variant="body2"
                fontSize="14px"
                sx={{ lineHeight: '28px' }}
                onClick={handleUpload}
              >
                Drag and drop or{' '}
                <span style={{ color: FigmaColor({ colorName: 'blue', shade: 900 }) }}>
                  choose file
                </span>{' '}
                to upload
              </Typography>
            )}
          </Stack>

          {(showDropzone || isAdding || isUploading) && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                p: '4px',
                bgcolor: 'grey.100',
              }}
            >
              {showDropzone && (
                <Dropzone
                  getRootProps={getDropzoneRootProps}
                  getInputProps={getDropzoneInputProps}
                  icon={
                    <IconButton color="inherit" size="small">
                      <RiUploadLine size="16px" />
                    </IconButton>
                  }
                  textStyle={{
                    fontSize: '14px',
                    lineHeight: '28px',
                    mr: 2,
                  }}
                />
              )}

              {(isAdding || isUploading) && (
                <Stack justifyContent="end" direction="row" alignItems="center">
                  <CancellableLinearProgress
                    docId={docId}
                    cancelUpload={cancelUpload}
                    minHeight="28px"
                  />
                </Stack>
              )}
            </Box>
          )}
        </Box>
      </>

      <ModalDialog
        variant="error"
        open={virusModalOpen}
        onClose={() => setVirusModalOpen(false)}
        title="Virus detected"
      >
        Sorry, the uploaded file was found to be malicious. Please upload another one.
      </ModalDialog>

      <ModalDialog
        variant="error"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleAction={() => handleDelete()}
        actionTitle="Delete"
        isLoading={isDeleting}
        title="Delete attachment"
      >
        Are you sure you want to delete this attachment?
      </ModalDialog>
    </Box>
  );
};
