import React from 'react';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Menu } from 'muikit/Menu';
import {
  useGetApplicationQuery,
  useUpdateApplicationStatusesMutation,
} from 'services/gatewayApi/applicationApi';
import {
  useGetRiskScoringAnswersByApplicationQuery,
  useGetRiskScoringAnswersTotalScoreByApplicationQuery,
} from 'services/gatewayApi/riskScoringAnswersApi';
import { riskMapForOpenPayd } from '../constants';
import { riskStatusOptions, statusesMap } from '../../../../../../../constants';

import './ScoreDisplay.scss';

interface ScoreDisplayProps {
  applicationId: string;
  isModalOpen: boolean;
  isPepIdentified: boolean;
}

export const ScoreDisplay: React.FC<ScoreDisplayProps> = ({
  applicationId,
  isModalOpen,
  isPepIdentified,
}) => {
  const {
    data: applicationDetails,
    isLoading: isLoadingApplication,
    isFetching: isFetchingApplication,
  } = useGetApplicationQuery(applicationId);

  const { data: answers, isLoading: isLoadingAnswers } =
    useGetRiskScoringAnswersByApplicationQuery(applicationId);

  const { data: answersScore, isLoading: isLoadingAnswersScore } =
    useGetRiskScoringAnswersTotalScoreByApplicationQuery(applicationId);

  const riskRating = riskMapForOpenPayd.find((s) => s.riskRating === answersScore?.riskRating);
  const riskStatus = riskStatusOptions.find((s) => s.value === applicationDetails?.riskStatus);
  const riskStatusColor = statusesMap.find(
    (s) => s.type === 'riskStatus' && s.value === riskStatus.value,
  )?.color;
  const boardingProhibited = answersScore?.isBoardingProhibited ? 'Boarding Prohibited' : false;
  const thresholdExceeded = answersScore?.totalRisk > 100 ? 'Threshold Exceeded' : false;

  // #region Override Risk Status
  const [updateApplicationStatus] = useUpdateApplicationStatusesMutation();

  // Comparing riskStatusOptions vs riskMapForOpenPayd is intended here, not a mistake
  let matchedCurrentStatus = false;
  const availableRiskStatusOptions = [
    ...riskStatusOptions.filter((riskStatus) => {
      if (riskStatus.value === riskRating?.riskStatus) {
        matchedCurrentStatus = true;
      }

      // Match only statuses followed by current status (higher statuses)
      if (matchedCurrentStatus) {
        return riskStatus;
      }
    }),
  ];

  const handleSaveOverride = (riskStatus) => {
    if (riskStatus !== applicationDetails?.riskStatus) {
      updateApplicationStatus({ id: applicationId, riskStatus })
        .unwrap()
        .catch((err) => console.error(err));
    }
  };

  const isOverrideDisabled =
    applicationDetails?.isRiskScoringSubmitted ||
    applicationDetails?.riskStatus === 'NOT_PERFORMED' ||
    isModalOpen ||
    isLoadingApplication ||
    isFetchingApplication;
  // #endregion

  const isEddRequired =
    isPepIdentified || (riskRating?.riskStatus === 'HIGH' && answersScore.totalRisk >= 50);

  const isLoading = isLoadingApplication || isLoadingAnswers || isLoadingAnswersScore;

  return (
    <Box className="ScoreDisplay">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4">Total Risk Score</Typography>
        <Typography variant="h2" fontWeight="700">
          {isLoading ? '...' : answersScore.totalRisk?.toFixed(2)}
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4">Risk Rating</Typography>
        <Typography
          variant="h3"
          fontWeight="700"
          className={`ResultLabel ResultLabel_${riskRating?.labelColor}`}
        >
          {isLoading ? '...' : answers.length === 0 ? 'N/A' : answersScore.riskRating}
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4">Overwrite Risk Score</Typography>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4 }}
        >
          <Typography
            variant="h3"
            fontWeight="700"
            className={`ResultLabel ResultLabel_${riskStatusColor}`}
            sx={{ m: 0 }}
          >
            {isLoading ? '...' : riskStatus.label}
          </Typography>

          {!isModalOpen && (
            <Menu
              renderOpenButton={(props) => (
                <Button
                  {...props}
                  variant="outlined"
                  color="primary"
                  disabled={isOverrideDisabled}
                  sx={{ mb: 2 }}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Override Risk Score
                </Button>
              )}
              renderItems={(props) =>
                riskStatusOptions
                  .filter((status) => status.value !== 'NOT_PERFORMED')
                  .map((status) => (
                    <MenuItem
                      key={status.value}
                      value={status.value}
                      disabled={
                        !availableRiskStatusOptions.find((item) => item.value === status.value)
                      }
                      selected={applicationDetails?.riskStatus === status.value}
                      onClick={(e) => {
                        handleSaveOverride(status.value);
                        props.onClick && props.onClick(e);
                      }}
                    >
                      {status.label}
                    </MenuItem>
                  ))
              }
            />
          )}
        </Stack>
        <Typography variant="body1" component="p" color="grey.600">
          * If you make any changes on the answers, score will be recalculated.
        </Typography>
      </Box>

      {(boardingProhibited || thresholdExceeded) && (
        <Typography
          variant="h1"
          fontWeight="700"
          className="ResultBlock ResultBlock_error"
          sx={{ mb: 4 }}
        >
          {!thresholdExceeded && boardingProhibited}
          {!boardingProhibited && thresholdExceeded}
          {boardingProhibited &&
            thresholdExceeded &&
            `${boardingProhibited} and ${thresholdExceeded}`}
        </Typography>
      )}

      <Typography
        variant="h4"
        className={`ResultBlock ResultBlock_${isEddRequired ? 'error' : 'warn'}`}
        sx={{ mb: 4 }}
      >
        {`${isEddRequired ? 'EDD' : 'CDD'} Required`}
      </Typography>
    </Box>
  );
};
