/*
  ## TODO: Input roadmap
  - [ ] TextArea
  - [x] Clearable (icon clear)
  - [ ] Pagination
*/

import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { Divider } from '@mui/material';
import {
  Cancel as CancelIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { IInputProps, InputSize } from './InputType';
import './Input.scss';

const InternalInput = (props, ref) => {
  const {
    value: propsValue,
    onChange,
    variant,
    onFocus,
    onBlur,
    onClick,
    type,
    touched: propsTouched,
    dirty: propsDirty,
    focused: propsFocused,
    valid: propsValid,
    size,
    autoFocus,
    readOnly,
    handleClear,
    hasError,
    startIcon,
    handlePrevNext,
    searchCounter,
    ...other
  } = props;

  const [focused, setFocused] = useState<boolean>(false);
  const [touched, setTouched] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current);

  const inputSize: InputSize = size || 'md';

  const inputClassNames = classNames({
    InputBase: true,
    InputSM: inputSize === 'sm',
    InputMD: inputSize === 'md',
    InputLG: inputSize === 'lg',
    SearchWithPrevNext: !!handlePrevNext,
    ReadOnly: readOnly,
    Focused: isNil(propsFocused) ? focused : propsFocused,
    Touched: isNil(propsTouched) ? touched : propsTouched,
    Untouched: isNil(propsTouched) ? !touched : !propsTouched,
    Dirty: isNil(propsDirty) ? dirty : propsDirty,
    Pristine: isNil(propsDirty) ? !dirty : !propsDirty,
    Valid: isNil(propsValid) ? true : propsValid,
    Invalid: isNil(propsValid) ? false : !propsValid,
    hasError,
    hasClear: !!handleClear,
    outlined: variant === 'outlined',
    startIcon: !!startIcon,
  });

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    isNil(propsDirty) && setDirty(true);
    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    isNil(propsFocused) && setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    isNil(propsFocused) && setFocused(false);
    isNil(propsTouched) && setTouched(true);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const clearHandler = () => {
    handleClear && handleClear();
    innerRef.current && innerRef.current.focus();
  };

  return (
    <div className="inputContainer">
      {!!startIcon && <div className="startIconContainer">{startIcon}</div>}
      <input
        {...other}
        value={propsValue}
        autoFocus={autoFocus}
        readOnly={readOnly}
        onChange={changeHandler}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        className={inputClassNames}
        type={type || 'text'}
        ref={innerRef}
      />
      {handleClear && propsValue && (
        <div className="iconContainer">
          {searchCounter && handlePrevNext && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 2 }} />
              <span className="searchCounter">{searchCounter}</span>
              <KeyboardArrowUpIcon className="iconButton" onClick={() => handlePrevNext('prev')} />
              <KeyboardArrowDownIcon
                className="iconButton"
                onClick={() => handlePrevNext('next')}
              />
            </>
          )}
          <CancelIcon onClick={clearHandler} className="iconButton" />
        </div>
      )}
    </div>
  );
};

const Input = forwardRef<HTMLInputElement, IInputProps>(InternalInput);
Input.displayName = 'Input';
export default Input;
