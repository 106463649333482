import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControlLabel, Stack, Typography, Checkbox } from '@mui/material';
import { ArrowBack } from 'uikit';
import { RiAddCircleFill } from 'react-icons/ri';
import { useGetNotesByFilterQuery } from 'services/gatewayApi/applicationNotesApi';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import { ApplicationNote, NoteDialog, NoteSkeleton } from '../ApplicationNote';
import { makeUserData } from '../../../../selectors/user';

interface NotesSidebarProps {
  onClose: any;
}

const NotesSidebar: React.FC<NotesSidebarProps> = ({ onClose }) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { id: userId } = useSelector(makeUserData());
  const [isHideInternalNotes, setIsHideInternalNotes] = useState<boolean>(false);

  const { onboardingApplicationId } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const [addNoteDialogOpen, setAddNoteDialogOpen] = useState<boolean>(false);

  const { notes = [], isNotesLoading } = useGetNotesByFilterQuery(
    { applicationId },
    {
      skip: !applicationId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        notes: data,
        isNotesLoading: isLoading,
        isNotesFetching: isFetching,
      }),
    },
  );

  const { data: usersList = [], isLoading: isUsersLoading } = useGetListUsersQuery(undefined);

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6, mt: 10 }}>
      <ArrowBack onClose={onClose} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="32px 0 8px"
      >
        <Typography sx={{ fontSize: '20px', lineHeight: '24px', color: 'grey.900' }}>
          Notes
        </Typography>
        <Button
          color="contrast"
          variant="contained"
          onClick={() => setAddNoteDialogOpen(true)}
          startIcon={<RiAddCircleFill size="14px" />}
        >
          Add note
        </Button>
      </Stack>

      <FormControlLabel
        sx={{ margin: '10px 0 22px' }}
        control={
          <Checkbox
            checked={isHideInternalNotes}
            onChange={(e, checked: boolean) => setIsHideInternalNotes(checked)}
          />
        }
        label="Hide internal notes"
      />

      <Box
        className="NotesListWrapper"
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        {isNotesLoading && (
          <>
            <NoteSkeleton />
            <NoteSkeleton />
          </>
        )}
        {notes
          .filter(
            (el) =>
              (isHideInternalNotes ? !el.isInternal : true) &&
              (el.isDraft
                ? el.createdBy === userId // show draft notes only to the creator (should be filtered on BE as well)
                : true),
          )
          .map((n) => (
            <ApplicationNote applicationId={applicationId} key={n.id} note={n} isSideBar />
          ))}
      </Box>

      {addNoteDialogOpen && !isUsersLoading && (
        <NoteDialog
          open={addNoteDialogOpen}
          onClose={() => setAddNoteDialogOpen(false)}
          note={{ id: 'new', applicationId, title: '' }}
          usersList={usersList}
        />
      )}
    </Box>
  );
};

export { NotesSidebar };
