export const shape = {
  props: {
    shape: 'circled',
  },
  style: {
    borderRadius: '16px',
  },
};

export const shapeSmall = {
  props: {
    shape: 'circled',
    size: 'small',
  },
  style: {
    borderRadius: '10px',
  },
};

export const shapeLarge = {
  props: {
    shape: 'circled',
    size: 'large',
  },
  style: {
    borderRadius: '20px',
  },
};

export const shapeSquared = {
  props: {
    shape: 'squared',
  },
  style: {
    borderRadius: 0,
  },
};
