import React, { PropsWithChildren } from 'react';
import {
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { Input } from '@mui/base';
import { InputTransparentInput } from '..';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlModules, htmlModulesLight, htmlFormats, htmlLightFormats } from 'constants/quillSettings';
import './NoteContent.scss';

interface NoteContentProps<
  ChildrenTypographyComponentType extends React.ElementType = 'span',
  ChildrenTypographyComponentInputProps extends React.ComponentPropsWithRef<
    'textarea' | 'input'
  > = TextareaAutosizeProps,
> {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  childrenTypographyProps?: TypographyProps<
    ChildrenTypographyComponentType,
    {
      slotProps?: {
        root?: React.ComponentPropsWithRef<'div'>;
        input?: ChildrenTypographyComponentInputProps & { as: React.ElementType };
      };
    }
  >;
  editable?: boolean;
  html?: boolean;
  htmlLight?: boolean;
  placeholder?: string;
  onChange?: (string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

const NoteContentRoot = styled('div')(({ theme }) => theme.unstable_sx({}));

const NoteContent = React.forwardRef(
  (
    {
      children,
      childrenTypographyProps,
      editable: editableProp = false,
      html = false,
      htmlLight = false,
      placeholder = '',
      onChange,
      onKeyDown,
      ...other
    }: PropsWithChildren<NoteContentProps>,
    ref: React.ForwardedRef<HTMLDivElement | HTMLInputElement>,
  ): JSX.Element => {
    let editable = editableProp;
    if (editableProp && typeof children !== 'string') {
      editable = false;
      if (process.env.NODE_ENV !== 'production')
        console.warn("Only children of type 'string' could be editable");
    }

    const text =
      typeof children === 'string' ||
        (React.isValidElement(children) && children.type !== Typography) ? (
        html || htmlLight ? (
          <ReactQuill
            className={`ReactQuill ReactQuill_${editable ? 'Editable' : 'ReadOnly'}`}
            theme="snow"
            readOnly={!editable}
            modules={html ? htmlModules : htmlModulesLight}
            formats={html ? htmlFormats : htmlLightFormats}
            value={children as string}
            placeholder={placeholder}
            onChange={(value) => onChange(value)}
            onKeyDown={onKeyDown}
          />
        ) : (
          <Typography
            sx={{ wordBreak: 'break-word' }}
            variant="body1"
            display="block"
            whiteSpace="pre-line"
            component={editable ? Input : 'span'}
            {...(editable
              ? {
                slots: { input: InputTransparentInput },
                slotProps: {
                  input: {
                    as: TextareaAutosize,
                    ref,
                    style: { resize: 'none' },
                  },
                },
                placeholder: 'Write a note...',
                value: children,
                onChange: (e) => onChange(e.currentTarget.value),
              }
              : {})}
            {...childrenTypographyProps}
          >
            {!editable && children}
          </Typography>
        )
      ) : (
        children
      );

    return (
      <NoteContentRoot ref={editable ? undefined : ref} {...other}>
        {text}
      </NoteContentRoot>
    );
  },
);

export default NoteContent;
