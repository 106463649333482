import React from 'react';
import './Spinner.scss';
import { FigmaColor } from '../../theme';

export interface SpinnerProps {}

export const Spinner: React.FC<SpinnerProps> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    width="24"
    height="24"
  >
    <circle
      opacity="0.3"
      fill="none"
      stroke={FigmaColor({ colorName: 'blue', shade: 900 })}
      strokeWidth="6"
      cx="12"
      cy="12"
      r="8"
    />

    <circle cx="6" cy="7" r="3" fill={FigmaColor({ colorName: 'blue', shade: 900 })}>
      <animateTransform
        type="rotate"
        accumulate="none"
        attributeName="transform"
        dur="1s"
        from="0 12 12"
        to="360 12 12"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);
