import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow, Typography } from '@mui/material';
import { SearchSessionsListRow, TransformedSession } from '../types';
import { ResultLabelRender } from './ResultLabelRender';
import { formatDateScreen, parseDateISO } from '../../../utils';
import 'styles/tables.scss';

interface SessionRowProps {
  session: SearchSessionsListRow | TransformedSession;
  isTransactions?: boolean;
}

export const SessionRow: React.FC<SessionRowProps> = ({ session, isTransactions = false }) => {
  const navigate = useNavigate();
  const handleGoToSession = () => {
    navigate(`${session.id}`);
  };

  const name = isTransactions
    ? (session as TransformedSession).fullName
    : (session as SearchSessionsListRow).name;
  const type = isTransactions
    ? (session as TransformedSession).transactionId
    : (session as SearchSessionsListRow).type;
  const decision = isTransactions
    ? (session as TransformedSession).decision
    : (session as SearchSessionsListRow).result.decision;
  const length = isTransactions
    ? (session as TransformedSession).matches
    : (session as SearchSessionsListRow).result.length;
  const lastUpdated = isTransactions
    ? formatDateScreen(parseDateISO((session as TransformedSession).performedOn)) ?? '-'
    : (session as SearchSessionsListRow).lastUpdated;

  return (
    <TableRow hover onClick={handleGoToSession} className="table-row table-hover">
      <TableCell>
        <Typography py={1.5}>{name}</Typography>
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>
        <ResultLabelRender decision={decision} length={length} />
      </TableCell>
      <TableCell>{lastUpdated}</TableCell>
    </TableRow>
  );
};
