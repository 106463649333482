import React from 'react';
import { Panel, DetailsField } from 'uikit';
import { Typography, Box } from '@mui/material';

export function DetailsFields() {
  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        Details field
      </Typography>
      <Panel>
        <>
          <DetailsField id="test1" label="testLabel" value="testValue" fieldType="text" />
          <DetailsField id="test2" label="testLabel" value="testValue" fieldType="text" />
          <DetailsField id="test3" label="testLabel" value="testValue" fieldType="text" />
        </>
      </Panel>
    </Box>
  );
}
