import React from 'react';
import { IconButton, IconButtonProps, Stack } from '@mui/material';
import { RiForbid2Line, RiCheckboxCircleFill } from 'react-icons/ri';
import { IconButtonRiStyled } from '../IconButtonRiStyled';

interface CancelConfirmButtonStackProps {
  onConfirm: () => any;
  onCancel: () => any;
  confirmProps?: IconButtonProps;
  cancelProps?: IconButtonProps;
}

export const CancelConfirmButtonStack = (props: CancelConfirmButtonStackProps) => {
  const { onConfirm, onCancel, cancelProps, confirmProps } = props;
  return (
    <Stack direction="row" gap={2} className="cancelConfirmButtonWrapper">
      <IconButtonRiStyled
        iconсolor="danger.main"
        size="small"
        onClick={() => {
          onCancel();
        }}
        {...cancelProps}
      >
        <RiForbid2Line />
      </IconButtonRiStyled>
      <IconButtonRiStyled
        size="small"
        iconсolor="success.main"
        onClick={() => {
          onConfirm();
        }}
        {...confirmProps}
      >
        <RiCheckboxCircleFill />
      </IconButtonRiStyled>
    </Stack>
  );
};
