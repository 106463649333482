/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_CUSTOMER_INFO,
  LOAD_CUSTOMER_INFO_SUCCESS,
  LOAD_CUSTOMER_INFO_FAIL,
  LOAD_CUSTOMER_TOTALS,
  LOAD_CUSTOMER_TOTALS_SUCCESS,
  LOAD_CUSTOMER_TOTALS_FAIL,
} from '../constants/constants';

export const initialState = {
  customerProfileLoading: false,
  customerProfileTotalsLoading: false,
  error: false,
  customerProfile: [],
  customerProfileAverages: [],
};

const customerProfileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_CUSTOMER_INFO:
        draft.customerProfileLoading = true;
        draft.error = false;
        break;
      case LOAD_CUSTOMER_INFO_SUCCESS:
        draft.customerProfile = action.payload;
        draft.customerProfileLoading = false;
        break;
      case LOAD_CUSTOMER_INFO_FAIL:
        draft.error = action.error;
        draft.customerProfileLoading = false;
        break;
      case LOAD_CUSTOMER_TOTALS:
        draft.customerProfileTotalsLoading = true;
        draft.error = false;
        break;
      case LOAD_CUSTOMER_TOTALS_SUCCESS:
        draft.customerProfileTotals = action.payload;
        draft.customerProfileTotalsLoading = false;
        break;
      case LOAD_CUSTOMER_TOTALS_FAIL:
        draft.error = action.error;
        draft.customerProfileTotalsLoading = false;
        break;
    }
  });

export default customerProfileReducer;
