import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { DetailsDrawer } from 'uikit';
import { toastSuccess } from 'utils/toast';
import { useGetRuleSetsQuery, useSaveRulesetMutation } from 'services/internalApi';
import { makeSelectedProductIdData } from 'selectors/user';
import { RulesHeader } from './RulesHeader';
import { RulesetsDrawerContent } from './RulesetsDrawerContent';
import { RuleSetProps } from './RulesTree/RulesTree';
import { StateType } from './types';
import { renameForBackend } from './rulesUtils';
import { updateSearchString } from './rulesSlice';

export const Rules: React.FC = () => {
  const location = useLocation();
  const { ruleSetId } = useParams<{ ruleSetId: string }>();
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const { ruleSetState, searchString: initialSearchString } = useSelector((state: StateType) => ({
    ruleSetState: state.ruleSetState.rulesTree,
    searchString: state.ruleSetState.searchString,
  }));
  const body = { productId: selectedProductId };

  const [isLocked, setIsLocked] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>(initialSearchString);
  const [listModifiedRules, setListModifiedRules] = useState<string[]>([]);
  const [isShowInactive, setIsShowInactive] = useState<boolean>(true);
  const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(false);
  const [openRuleSetsDrawer, setOpenRuleSetsDrawer] = useState<boolean>(false);
  const [selectedRulesetId, setSelectedRulesetId] = useState<string>(ruleSetId);
  const [allowAnimate, setAllowAnimate] = useState<boolean>(false);
  const [isSameListRules, setIsSameListRules] = useState<boolean>(true);
  const [skipUpdateSearch, setSkipUpdateSearch] = useState<boolean>(true);
  const [reset, setReset] = useState<boolean>(false);
  const [searchFocusIndex, setSearchFocusIndex] = useState<number>(0);
  const [searchFoundCount, setSearchFoundCount] = useState<number | null>(null);
  const [searchCounter, setSearchCounter] = useState<string>('0/0');
  const [preventHiding, setPreventHiding] = useState<boolean>(false);

  const dispatch = useDispatch();

  const debounceQuery = useCallback(
    debounce(() => setSkipUpdateSearch(false), 400),
    [],
  );

  useEffect(() => {
    if (!skipUpdateSearch) {
      dispatch(updateSearchString(searchString));
      setSkipUpdateSearch(true);
    }
    if (!searchString || searchString === '') {
      setPreventHiding(false);
    } else {
      setPreventHiding(true);
    }
  }, [searchString, skipUpdateSearch]);

  // for unlock tree when return from edited rule
  useEffect(() => {
    if (location?.state?.isLocked === false) {
      setIsLocked(false);
    }
  }, [location]);

  const navigate = useNavigate();

  const [saveRuleset, saveRulesetStatus] = useSaveRulesetMutation();

  // TODO api GET selected (default) ruleSet, & list of ruleSets
  const {
    data: ruleSets,
    isLoading: isLoadingRuleSets,
    isFetching: isFetchingRuleSets,
  } = useGetRuleSetsQuery(body, { skip: !selectedProductId });

  useEffect(() => {
    if (ruleSets?.length > 0 && !selectedRulesetId) {
      setSelectedRulesetId(ruleSets[0].id.toString());
    }
    setOpenRuleSetsDrawer(false);
  }, [ruleSets]);

  const getTitle = () =>
    ruleSets?.find((el) => el.id.toString() === selectedRulesetId?.toString())?.name || '';

  const handleLock = () => {
    setIsLocked((prev) => !prev);
    setAllowAnimate(true);
    setTimeout(() => {
      setAllowAnimate(false);
    }, 400);
  };
  const handleShowInactive = () => {
    // TODO api request for toggle show inactive ?
    setIsShowInactive((prev) => !prev);
  };

  const handleReset = () => {
    setIsLocked(true);
    setReset(true);
    handleSearch('');
  };

  const handlePublish = async () => {
    const currentRuleset = ruleSets.find((el) => el.id.toString() === selectedRulesetId);
    const preparedBackendRules = renameForBackend(ruleSetState);
    const bodyForSave = {
      body: {
        id: new Date().getTime(),
        ruleSet: { ...currentRuleset, rules: preparedBackendRules },
      },
    };
    try {
      await saveRuleset(bodyForSave).unwrap();
      handleReset();
      toastSuccess('Published');
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSearch = (value) => {
    setSearchString(value);
    debounceQuery();
  };

  const handlePrevNext = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setSearchFocusIndex(
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1,
      );
    } else {
      setSearchFocusIndex(
        searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0,
      );
    }
  };

  const headerProps = {
    isLocked,
    searchString,
    handleSearch,
    handleLock,
    isShowInactive,
    handleShowInactive,
    isPublishDisabled: isPublishDisabled || (listModifiedRules.length === 0 && isSameListRules),
    isLoading: saveRulesetStatus.isLoading,
    handlePublish,
    setOpenRuleSetsDrawer,
    titleRuleset: getTitle(),
    handleReset,
    handlePrevNext,
    searchCounter,
    preventHiding,
  };
  const ruleSetProps: RuleSetProps = {
    isLocked,
    setIsShowInactive,
    setListModifiedRules,
    setIsSameListRules,
    setIsPublishDisabled,
    isShowInactive,
    allowAnimate,
    reset,
    setReset,
    setSearchCounter,
    searchFocusIndex,
    setSearchFocusIndex,
    setSearchFoundCount,
  };

  useEffect(() => {
    selectedRulesetId && navigate(`${selectedRulesetId}`);
  }, [selectedRulesetId]);

  const handleSelectRuleset = (value) => {
    setSelectedRulesetId(value);
    setSearchString('');
  };

  return (
    <div className="rules-container">
      <RulesHeader {...headerProps} />
      <div className="container">
        {/* <RulesTree/> component in Outlet below */}
        <Outlet context={ruleSetProps} />
      </div>

      <DetailsDrawer width={428} open={openRuleSetsDrawer}>
        <RulesetsDrawerContent
          onClose={() => setOpenRuleSetsDrawer(false)}
          ruleSetsList={ruleSets}
          setSelectedRulesetId={handleSelectRuleset}
          selectedRulesetId={selectedRulesetId}
          isLoading={isLoadingRuleSets || isFetchingRuleSets}
        />
      </DetailsDrawer>
    </div>
  );
};
