import { base, bold } from 'theme/palette-blocks';
import { buttonFocus } from 'theme/theme-components/mixins';
import { palette } from 'theme/palette';

export const Bold = {
  props: {
    color: 'bold',
  },
  style: {
    '&:hover': {
      background: bold.hover,
      color: bold.contrastText,
    },
    '&:active': {
      background: bold.active,
      color: bold.contrastText,
    },
    '&:disabled': {
      opacity: palette.action?.disabledOpacity,
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: bold.main,
    },
    '&:focus&:not(:active)': buttonFocus(bold.dark),
  },
};

export const BoldContained = {
  props: {
    color: 'bold' as any,
    variant: 'contained' as any,
  },
  style: {
    color: bold.contrastText,
    background: bold.main,
    '&:disabled': {
      color: bold.contrastText,
      background: bold.main,
    },
    '.MuiLoadingButton-loadingIndicator': {
      color: bold.contrastText,
    },
  },
};

export const BoldText = {
  props: {
    color: 'bold',
    variant: 'text',
  },
  style: {
    '&:hover': {
      background: base.hover,
      color: base.contrastText,
    },
    '&:active': {
      background: base.active,
      color: base.contrastText,
    },
    '&:disabled': {
      color: bold.main,
    },
  },
};

export const BoldOutlined = {
  props: {
    color: 'bold',
    variant: 'outlined',
  },
  style: {
    '&:hover': {
      background: base.hover,
      color: base.contrastText,
    },
    '&:active': {
      background: base.active,
      color: base.contrastText,
    },
    '&:disabled': {
      borderColor: bold.main,
      background: bold.dark,
      color: bold.main,
    },
  },
};
