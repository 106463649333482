// TODO commented code needs to manage OP users,
//  must be restored after new KYC provider will be implemented

import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from 'theme/palette-blocks';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import {
  MoreVert as MoreVertIcon,
  Check as CheckIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { ModalDialog } from 'uikit';
import {
  useApproveUserMutation,
  useRejectUserMutation,
  usePepAndSanctionsMutation,
  useSyncUserMutation,
} from 'services/gatewayApi/usersApi';
import { useGetKycApplicantQuery } from 'services/gatewayApi/kycApplicantsApi';
import { OpenpaydUser } from '../types';
import { StatusChip } from './StatusChip';

const UserCardStyled = styled('div', {
  name: 'UserCard',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '16px 24px',
  }),
);

const declineMessage = 'Please provide short description why this client is going to be declined';

const accessRightMap = {
  BUSINESS_ADMINISTRATOR: 'Business administrator',
};

interface UserCardProps {
  user: OpenpaydUser;
  getCountryLabel: (arg0: string) => string;
}

export const UserCard: React.FC<UserCardProps> = ({ user, getCountryLabel }) => {
  const { applicationId, id, applicantId, userId } = user;
  const userStatusPending = user.approved === undefined;

  const [pepUser] = usePepAndSanctionsMutation();
  const [syncUser] = useSyncUserMutation();
  const [approveUser] = useApproveUserMutation();
  const [rejectUser] = useRejectUserMutation();
  const { data: kycResults, isLoading: isLoadingKycResults } = useGetKycApplicantQuery(
    { id: applicantId },
    { skip: userId === undefined },
  );

  const [declineUser, setDeclineUser] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [declineDescription, setDeclineDescription] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (declineDescription !== '') setIsError(false);
  }, [declineDescription]);

  const performPep = () => {
    pepUser({ applicationId, userId: id });
    handleMenuClose();
  };
  const handleAddCustomerUser = () => {
    syncUser({ applicationId, userId: id });
    handleMenuClose();
  };
  const handleApprove = () => {
    approveUser({ applicationId, userId: id });
    handleMenuClose();
  };
  const handleDecline = () => {
    handleMenuClose();
    setDeclineUser(user);
  };
  const handleDeclineUser = () => {
    if (declineDescription === '') return setIsError(true);
    rejectUser({ applicationId, userId: id, rejectReason: declineDescription });
    return handleModalDeclineClose();
  };
  const handleModalDeclineClose = () => {
    setDeclineUser(undefined);
    setIsError(false);
    setDeclineDescription('');
  };

  const getKyc = () => {
    const result = kycResults?.kycResult;
    if (userId === undefined) return 'KYC_NOT_PERFORMED';
    if (result === undefined || result === 'CONSIDER') return 'KYC_PENDING';
    return `KYC_${result}`;
  };

  return (
    <>
      <UserCardStyled>
        <Stack
          className="UserCardUpside"
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            paddingBottom: '8px',
            marginBottom: '8px',
          })}
        >
          <Box sx={{ width: '30%' }}>
            <Box mb={1}>
              <Typography variant="h3" component="span">
                {user.firstName}
              </Typography>
              <Typography variant="h3" component="span" ml={2}>
                {user.lastName}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2" color="grey.600" component="span">
                Position:
              </Typography>
              <Typography variant="body2" color="grey.900" ml={1} component="span">
                {user.position}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="grey.600" component="span">
                Access right:
              </Typography>
              <Typography variant="body2" ml={1} component="span">
                {accessRightMap[user.accessRights] || user.accessRights}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '30%' }}>
            <Typography variant="body2" mb={1}>
              {user.email}
            </Typography>
            <Typography variant="body2" mb={1}>
              {user.mobileNumber}
            </Typography>
            <Typography variant="body2" component="span">
              {user.addressLine1}
            </Typography>
            <Typography variant="body2" ml={1}>
              {user.addressLine2}
            </Typography>
            <Typography variant="body2" component="span">
              {getCountryLabel(user?.country || '')}
            </Typography>
            <Typography variant="body2" component="span" ml={1}>
              {user.city}
            </Typography>
            <Typography variant="body2" component="span" ml={1}>
              {user.postCode}
            </Typography>
          </Box>
          <Box sx={{ width: '30%', position: 'relative' }}>
            <Box mb={1}>
              <Typography variant="body2" color="grey.600" component="span">
                nationality:
              </Typography>
              <Typography variant="body2" color="grey.900" ml={1} component="span">
                {getCountryLabel(user.nationality)}
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2" color="grey.600" component="span">
                place of birth:
              </Typography>
              <Typography variant="body2" color="grey.900" ml={1} component="span">
                {user.placeOfBirth}
              </Typography>
            </Box>
            {user.mainUser && (
              <Box display="flex" justifyContent="flex-end">
                <Chip size="small" color="bold" label="Main user" />
              </Box>
            )}
            {/* {(!user.pepStatus || !userId || userStatusPending) && ( */}
            {/*  <IconButton onClick={handleOpenMenu} sx={{ position: 'absolute', top: 0, right: 0 }}> */}
            {/*    <MoreVertIcon fontSize="inherit" /> */}
            {/*  </IconButton> */}
            {/* )} */}
          </Box>
        </Stack>
        <Stack className="UserCardDownside" direction="row" justifyContent="space-between">
          <Box sx={{ width: '30%' }}>
            <Stack direction="row" alignItems="center" component="span">
              <Typography variant="body2" color="grey.600" component="span">
                Id:
              </Typography>
              <Typography variant="body2" color="grey.900" ml={1} component="span">
                {id}
              </Typography>
              {userId && (
                <>
                  <Typography variant="body2" color="grey.600" ml={2} component="span">
                    Customer user
                  </Typography>
                  <CheckIcon sx={{ color: 'success.main', fontSize: 'small', ml: 1 }} />
                </>
              )}
            </Stack>
          </Box>
          <Box sx={{ width: '30%' }}>
            <Typography variant="body2" color="grey.600" component="span">
              The decision is made:
            </Typography>
            <Typography variant="body2" color="grey.900" ml={1} component="span">
              {formatIsoDate(user.decisionTime, DATE_FORMAT_MESSAGES)}
            </Typography>
          </Box>
          {/* <Box sx={{ width: '30%' }}> */}
          {/*  {StatusChip(user.pepStatus ? 'PepPositive' : 'PepNegative')} */}
          {/*  {StatusChip(isLoadingKycResults ? 'KYC_LOADING' : getKyc())} */}
          {/*  {StatusChip(userStatusPending ? 'Pending' : user.approved)} */}
          {/* </Box> */}
        </Stack>
      </UserCardStyled>

      {/* <Menu */}
      {/*  id={`${id}-menu`} */}
      {/*  MenuListProps={{ 'aria-labelledby': `${id}-button` }} */}
      {/*  anchorEl={anchorEl} */}
      {/*  open={open} */}
      {/*  onClose={handleMenuClose} */}
      {/* > */}
      {/*  <MenuList sx={{ '&.MuiList-root': { '&:focus-visible': { outline: 'none' } } }}> */}
      {/*    {!user.pepStatus && ( */}
      {/*      <MenuItem onClick={performPep}> */}
      {/*        <ListItemText>Perform Pep and sanctions check</ListItemText> */}
      {/*      </MenuItem> */}
      {/*    )} */}

      {/*    {!userId && ( */}
      {/*      <MenuItem onClick={handleAddCustomerUser}> */}
      {/*        <ListItemText>Add to the Customer Portal users</ListItemText> */}
      {/*      </MenuItem> */}
      {/*    )} */}

      {/*    {(!user.pepStatus || !userId) && userStatusPending && <Divider sx={{ mx: 4 }} />} */}

      {/*    {userStatusPending && ( */}
      {/*      <MenuItem onClick={handleApprove} sx={{ color: 'success.main' }}> */}
      {/*        <ListItemIcon> */}
      {/*          <CheckIcon fontSize="small" sx={{ color: 'success.main' }} /> */}
      {/*        </ListItemIcon> */}
      {/*        <ListItemText>Approve</ListItemText> */}
      {/*      </MenuItem> */}
      {/*    )} */}

      {/*    {userStatusPending && ( */}
      {/*      <MenuItem onClick={handleDecline} sx={{ color: 'error.main' }}> */}
      {/*        <ListItemIcon> */}
      {/*          <CancelIcon fontSize="small" sx={{ color: 'error.main' }} /> */}
      {/*        </ListItemIcon> */}
      {/*        <ListItemText>Decline</ListItemText> */}
      {/*      </MenuItem> */}
      {/*    )} */}
      {/*  </MenuList> */}
      {/* </Menu> */}

      {/* {!!declineUser && ( */}
      {/*  <ModalDialog */}
      {/*    variant="error" */}
      {/*    open={!!declineUser} */}
      {/*    onClose={handleModalDeclineClose} */}
      {/*    title={`Decline user: ${user.firstName} ${user.lastName}`} */}
      {/*    handleAction={handleDeclineUser} */}
      {/*    actionTitle="Decline" */}
      {/*  > */}
      {/*    <Typography variant="body1" color="grey.600" pb={2}> */}
      {/*      {declineMessage} */}
      {/*    </Typography> */}
      {/*    <TextField */}
      {/*      label="Description" */}
      {/*      multiline */}
      {/*      fullWidth */}
      {/*      rows={3} */}
      {/*      error={isError} */}
      {/*      onChange={(e) => setDeclineDescription(e.target.value)} */}
      {/*      value={declineDescription} */}
      {/*    /> */}
      {/*  </ModalDialog> */}
      {/* )} */}
    </>
  );
};
