import React, { useState, useEffect } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parse } from 'date-fns';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, Divider, IconButton, Grid } from '@mui/material';
import { Delete as DeleteIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { checkProductApiName } from 'utils';
import { removeEmptyProperties } from 'utils/modelHelper';
import { DATE_FORMAT } from 'constants/constants';
import { dateTime, formatDateServer } from '../../utils/formatter';
import { AutocompleteMulti } from '../AutocompleteMulti';
import Select from '../Select/Select';
import Input from '../Input/Input';
import EmbedDropdown from '../EmbedDropdown/EmbedDropdown';
import { IPageControlFilterProps } from '../PageControl/PageControlFilter/PageControlFilterTypes';

export const FiltersSelectable: React.FC<IPageControlFilterProps> = ({
  filterScheme,
  filterValue,
  filterHandler,
  options,
  disabled = false,
  onReset,
}) => {
  const initialFilters = Object.keys(filterValue).map((key) => ({
    [key]: filterValue[key],
  }));

  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const filterCount = initialFilters.length || null;

  const dateRangeFilters = {
    'Date Range': ['startCreationDate', 'endCreationDate'], // from transactions and cases
    'Date Created': ['createdDateStart', 'createdDateEnd'], // from kyc applicants
    'Date Updated': ['modifiedDateStart', 'modifiedDateEnd'], // from kyc applicants
  };

  const getFiltersList = () =>
    selectedFilters
      .map((el) => {
        const fieldName = Object.keys(el)[0];
        let result = el;

        Object.keys(dateRangeFilters).forEach((filterGroup) => {
          if (dateRangeFilters[filterGroup].includes(fieldName)) {
            result = { [filterGroup]: el[fieldName] };
          }
        });

        return result;
      })
      .filter(
        (item, index, self) =>
          self.findIndex((el) => Object.keys(el)[0] === Object.keys(item)[0]) === index,
      );

  const findFilter = (fieldName) => {
    const filter = filterScheme.find((el) => el.name === fieldName);
    if (!filter) {
      console.warn(`Filter not defined for field: ${fieldName}`);
    }
    return filter;
  };

  const getInitialValues = () => {
    const obj = {};

    const initialFiltersList = filterScheme.map((el) => {
      const value = [el.name];
      const label = initialFilters.find((v) => el.name === Object.keys(v)[0])
        ? Object.values(initialFilters.find((v) => el.name === Object.keys(v)[0]))[0]
        : el.initialFilterValue ?? '';

      // @ts-ignore
      let result = { [value]: label };

      Object.keys(dateRangeFilters).forEach((filterGroup) => {
        if (dateRangeFilters[filterGroup].includes(value)) {
          const date = label instanceof Date ? label : parse(label, 'yyyy-MM-dd', new Date());

          // @ts-ignore
          result = { [value]: date };
        }
      });

      return result;
    });

    initialFiltersList.forEach((el) => Object.assign(obj, el));

    return obj;
  };

  // Close dropdown with 'esc' key
  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        setShowFilter(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const getValidation = () => {
    const validationObject = {};

    getFiltersList().forEach((field) => {
      const fieldType = Object.keys(field)[0];

      let isDateRangeFilter = false;
      Object.keys(dateRangeFilters).forEach((filterGroup) => {
        if (fieldType === filterGroup) {
          const [firstField, secondField] = dateRangeFilters[fieldType];

          // @ts-ignore
          validationObject[firstField] = yup
            .date()
            .nullable()
            .max(dateTime(), 'Future date not allowed');

          // @ts-ignore
          validationObject[secondField] = yup
            .date()
            .nullable()
            .max(dateTime(), 'Future date not allowed')
            .when(firstField, {
              is: (firstField) => !!firstField,
              then: yup
                .date()
                .nullable()
                .max(dateTime(), 'Future date not allowed')
                .min(yup.ref(firstField), 'Date needs to be later than the Start Date'),
            });

          isDateRangeFilter = true;
        }
      });

      if (isDateRangeFilter) {
        return validationObject;
      }

      if (Array.isArray(Object.values(field)[0])) {
        return (validationObject[fieldType] = yup
          .array()
          .nullable()
          // @ts-ignore
          .of(yup.lazy((value) => (typeof value === 'number' ? yup.number() : yup.string()))));
      }

      return (validationObject[fieldType] = yup.string());
    });

    return yup.object().shape(validationObject);
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validationSchema: getValidation(),
    onSubmit: (values, { setSubmitting }) => {
      const model = { ...values };

      Object.keys(dateRangeFilters).forEach((filterGroup) => {
        dateRangeFilters[filterGroup].forEach((fieldName) => {
          model[fieldName] = formatDateServer(values[fieldName]);
        });
      });

      removeEmptyProperties(model);
      setShowFilter(false);
      setSubmitting(false);
      filterHandler(model);
    },
  });

  useEffect(() => {
    undoFilterChanges();
  }, [filterValue]);

  const handleAddFilter = (option) => {
    // @ts-ignore
    setSelectedFilters((prev) => [
      ...prev,
      {
        [option]: findFilter(option).initialFilterValue ?? '',
      },
    ]);
  };

  const handleChangeFilter = (option, idx) => {
    setSelectedFilters((prev) =>
      prev.map((el, index) => (index === idx ? { [option.value]: '' } : el)),
    );
  };

  // Supports setting default values of multiple fields
  const setDefaultValue = (...args) => {
    for (let i = 0; i < args.length; i++) {
      const field = args[i];
      formik.setFieldValue(field, findFilter(field)?.initialFilterValue);
    }
  };

  const handleRemoveFilter = (index) => {
    const fieldToClear = Object.keys(getFiltersList().find((el, idx) => idx === index))[0];

    if (dateRangeFilters[fieldToClear]) {
      setDefaultValue(...dateRangeFilters[fieldToClear]);
    } else {
      setDefaultValue(fieldToClear);
    }

    setSelectedFilters(getFiltersList().filter((el) => Object.keys(el)[0] !== fieldToClear));
  };

  const undoFilterChanges = () => {
    formik.resetForm();
    setSelectedFilters(initialFilters);
  };

  const onResetFilters = () => {
    if (onReset) {
      onReset();
      setShowFilter(false);
    } else {
      undoFilterChanges();
    }
  };

  const filterSchemeByTenant = (el) =>
    (!el.product || checkProductApiName(el.product)) &&
    !selectedFilters.some((v) => el.name === Object.keys(v)[0]);

  const filterSchemeByApiName = (el) =>
    !el.forApiNames || (el.forApiNames && checkProductApiName(el.forApiNames));

  const getSortOptions = () => {
    const filtered = filterScheme
      .slice()
      .filter((el) => el.label !== 'hide')
      .filter(filterSchemeByTenant)
      .filter(filterSchemeByApiName);

    return filtered.map((el) => ({
      value: el.name,
      label: el.label,
    }));
  };

  const getOptions = (optionsName) => options[optionsName];

  const handleCloseFilters = () => {
    setShowFilter(false);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        disabled={disabled}
        color={filterCount ? 'secondary' : 'base'}
        variant="contained"
        onClick={() => setShowFilter(!showFilter)}
        startIcon={<FilterListIcon />}
      >
        Filter {!!filterCount && `(${filterCount})`}
      </Button>

      <form onSubmit={formik.handleSubmit} autoComplete="off">
        {showFilter && (
          <EmbedDropdown
            align="right"
            isShowing={showFilter}
            hide={() => setShowFilter(false)}
            style={{ width: '700px', borderRadius: '12px' }}
          >
            <Box sx={{ p: '20px 12px 0 12px' }}>
              {getFiltersList().map((el, idx) => {
                const fieldName = Object.keys(el)[0];
                const fieldType = Object.keys(dateRangeFilters).includes(fieldName)
                  ? fieldName
                  : findFilter(Object.keys(el)[0])?.field;

                return (
                  // <Box sx={{ pb: '4px', mb: '8px' }} key={fieldName} className="filterRow">
                  <Grid
                    className="filterRow"
                    key={fieldName}
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={2}
                    sx={{ pl: '8px', pb: '4px', mb: '8px', alignItems: 'center' }}
                  >
                    <Grid
                      item
                      md={4}
                      sm={4}
                      sx={{ width: '100%', pl: '16px' }}
                      className="LeftColumn"
                    >
                      <Select
                        value={{
                          value: fieldName,
                          label: findFilter(fieldName)?.label,
                        }}
                        options={getSortOptions()}
                        onChange={(option) => {
                          handleChangeFilter(option, idx);
                        }}
                        hasClear={false}
                      />
                    </Grid>

                    <Grid
                      className="RightColumn"
                      item
                      md={8}
                      sm={8}
                      container
                      wrap="nowrap"
                      spacing={1}
                      justifyContent="space-between"
                      sx={{ alignItems: 'center' }}
                    >
                      <Grid
                        item
                        md={11}
                        sm={11}
                        sx={{
                          width: '100%',
                          backgroundColor: '#fff !important',
                        }}
                      >
                        {(() => {
                          switch (fieldType) {
                            case 'text':
                              return (
                                <Input
                                  name={fieldName}
                                  type="text"
                                  value={formik.values[fieldName]}
                                  onChange={formik.handleChange}
                                  placeholder="input value"
                                />
                              );

                            case 'select':
                              return (
                                <Select
                                  name={fieldName}
                                  variant="outlined"
                                  value={getOptions(fieldName).find(
                                    (option) => option.value === formik.values[fieldName],
                                  )}
                                  placeholder="select value"
                                  options={getOptions(fieldName)}
                                  onChange={(option) => {
                                    formik.setFieldValue(fieldName, option.value);
                                  }}
                                  hasClear={false}
                                />
                              );

                            case 'selectMulti':
                              return (
                                <AutocompleteMulti
                                  name={fieldName}
                                  options={getOptions(fieldName)}
                                  placeholder="select values"
                                  values={getOptions(fieldName)?.filter((option) =>
                                    formik.values[fieldName]?.some((v) => option.value === v),
                                  )}
                                  onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                      fieldName,
                                      newValue.map((nextValue) => nextValue.value),
                                    );
                                  }}
                                  disabled={false}
                                />
                              );
                          }

                          if (Object.keys(dateRangeFilters).includes(fieldType)) {
                            const [firstField, secondField] = dateRangeFilters[fieldType];

                            return (
                              <Grid container spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <Grid item md={6} sm={6}>
                                    <DesktopDatePicker
                                      value={formik.values[firstField]}
                                      inputFormat={DATE_FORMAT}
                                      onChange={(newValue) => {
                                        formik.setFieldValue(
                                          firstField,
                                          dateTime(newValue, 'start'),
                                        );
                                      }}
                                      renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                          <Input
                                            ref={inputRef}
                                            {...inputProps}
                                            name={firstField}
                                            placeholder="start date"
                                            hasError={
                                              formik.touched[firstField] &&
                                              Boolean(formik.errors[firstField])
                                            }
                                          />
                                          {InputProps?.endAdornment}
                                        </Box>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={6}>
                                    <DesktopDatePicker
                                      value={formik.values[secondField]}
                                      inputFormat={DATE_FORMAT}
                                      onChange={(newValue) => {
                                        formik.setFieldValue(
                                          secondField,
                                          dateTime(newValue, undefined),
                                        );
                                      }}
                                      renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Input
                                            ref={inputRef}
                                            {...inputProps}
                                            name={secondField}
                                            placeholder="end date"
                                            hasError={
                                              formik.touched[secondField] &&
                                              Boolean(formik.errors[secondField])
                                            }
                                          />
                                          {InputProps?.endAdornment}
                                        </Box>
                                      )}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </Grid>
                            );
                          }

                          return <h5>Filter type mismatch for {fieldType}!</h5>;
                        })()}
                      </Grid>

                      <Grid item md={1} sm={1}>
                        <IconButton onClick={() => handleRemoveFilter(idx)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  // </Box>
                );
              })}

              {getSortOptions().length > 0 && (
                <Grid container spacing={2} sx={{ p: '0 0 16px 8px' }}>
                  <Grid item md={4} sm={4}>
                    <Select
                      name="addFilter"
                      placeholder="Add filter"
                      options={getSortOptions()}
                      onChange={(option) => {
                        handleAddFilter(option.value);
                      }}
                      hasClear={false}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>

            <Grid sx={{ p: '0 12px 8px 12px' }}>
              <Divider />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{ p: '0 12px 16px 20px' }}
            >
              <Grid item md={6} sm={6}>
                <Button
                  type="button"
                  onClick={() => handleCloseFilters()}
                  variant="text"
                  color="base"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item md={6} sm={6} container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button type="button" onClick={onResetFilters} color="error" variant="text">
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" color="primary" variant="contained">
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </EmbedDropdown>
        )}
      </form>
    </Box>
  );
};
