import { createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { gatewayApi, getKCHeaders } from '../gatewayApi';
import { setUploadProgress } from '../indicatorsSlice';
import { toastError } from '../../utils';

export enum VirusStatus {
  Pending = 'PENDING',
  Checked = 'CHECKED',
  Quarantined = 'QUARANTINED',
}

export interface ApplicationDocument {
  id: string;
  applicationId: string;
  canBeExpired: boolean;
  description: string;
  documentOrder: number;
  documentTemplateId: number;
  isReadonly: boolean;
  isGoingExpire: boolean;
  isEnabled: boolean;
  isEditableOnCustomer?: boolean;
  isInternal: boolean;
  isMandatory: boolean;
  isRejected: boolean;
  isRequestedManually: boolean;
  disableReason?: string;
  rejectReason?: string;
  title: string;
  expiryDate?: string;
  fileName?: string;
  fileSize?: number;
  fileType?: string;
  downloadHref?: string;
  virusStatus: VirusStatus;
  groupName?: string;

  createdBy: string;
  createdByName: string;
  createdDate: string;

  uploadedBy: string;
  uploadedByName: string;
  uploadedDate: string;
}

export interface ApplicationDocumentMetadata {
  fileName: string;
  size: string;
  type: string;
}

export const applicationDocumentsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationDocument: build.query<ApplicationDocument, Record<string, string>>({
      query: ({ applicationId, id }) => ({
        url: `applications/${applicationId}/docs/${id}`,
        method: 'GET',
      }),
    }),
    getAllApplicationDocuments: build.query<Array<ApplicationDocument>, string>({
      query: (applicationId) => ({
        url: `applications/${applicationId}/docs`,
        method: 'GET',
      }),
    }),
    updateApplicationDocument: build.mutation<ApplicationDocument, ApplicationDocument>({
      query({ id, applicationId, ...other }) {
        return {
          url: `applications/${applicationId}/docs/${id}`,
          method: 'PUT',
          body: { ...other },
        };
      },
      async onQueryStarted({ id, applicationId, ...other }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationDocumentsApi.util.updateQueryData(
            'getAllApplicationDocuments',
            applicationId,
            (draft) => {
              const document = draft.find((d) => d.id === id);
              document && Object.assign(document, { ...other });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    patchApplicationDocument: build.mutation({
      query({ id, applicationId, ...patch }) {
        return {
          url: `applications/${applicationId}/docs/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      async onQueryStarted({ id, applicationId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationDocumentsApi.util.updateQueryData(
            'getAllApplicationDocuments',
            applicationId,
            (draft) => {
              const document = draft.find((d) => d.id === id);
              document && Object.assign(document, { ...patch });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteApplicationDocument: build.mutation({
      query({ applicationId, id }) {
        return {
          url: `applications/${applicationId}/docs/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ id, applicationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationDocumentsApi.util.updateQueryData(
            'getAllApplicationDocuments',
            applicationId,
            (draft) => (draft = draft.filter((doc) => doc.id !== id)),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    rejectApplicationDocument: build.mutation<
      ApplicationDocument,
      Pick<ApplicationDocument, 'id' | 'applicationId' | 'rejectReason'>
    >({
      query({ id, applicationId, rejectReason }) {
        return {
          url: `applications/${applicationId}/docs/${id}/reject`,
          method: 'PUT',
          body: { reason: rejectReason },
        };
      },
      async onQueryStarted({ id, applicationId, rejectReason }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationDocumentsApi.util.updateQueryData(
            'getAllApplicationDocuments',
            applicationId,
            (draft) => {
              const document = draft.find((d) => d.id === id);
              document && Object.assign(document, { rejectReason, isRejected: true });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    uploadApplicationDocument: build.mutation<
      ApplicationDocumentMetadata,
      { formData: FormData; id: string; applicationId: string }
    >({
      query({ formData: body, id, applicationId }) {
        return {
          url: `applications/${applicationId}/docs/${id}/upload`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted({ formData, id, applicationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: documentMetadata } = await queryFulfilled;
          const { size: fileSize, fileName } = documentMetadata;
          dispatch(
            applicationDocumentsApi.util.updateQueryData(
              'getAllApplicationDocuments',
              applicationId,
              (draft) => {
                const document = draft.find((d) => d.id === id);
                document &&
                  Object.assign(document, {
                    fileSize,
                    fileName,
                    isRejected: false,
                    virusStatus: VirusStatus.Pending,
                  });
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    addApplicationDocument: build.mutation<
      ApplicationDocument,
      Pick<ApplicationDocument, 'title' | 'applicationId' | 'isInternal'>
    >({
      query({ applicationId, title, isInternal }) {
        return {
          url: `applications/${applicationId}/docs`,
          method: 'POST',
          body: {
            title,
            isInternal,
            isMandatory: false,
          },
        };
      },
      async onQueryStarted({ applicationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: document } = await queryFulfilled;
          dispatch(
            applicationDocumentsApi.util.updateQueryData(
              'getAllApplicationDocuments',
              applicationId,
              (draft) => {
                draft.push(document);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),

    sendDocumentsToKyc: build.mutation({
      query({ applicationId, documents }) {
        return {
          url: `applications/${applicationId}/docs/kyc`,
          method: 'POST',
          body: documents,
        };
      },
    }),

    uploadWithProgress: build.mutation<
      ApplicationDocumentMetadata,
      { formData: FormData; id: string; applicationId: string; signal: AbortSignal }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async ({ formData: data, id, applicationId, signal }, api) => {
        const url = `/api/gateway/applications/${applicationId}/docs/${id}/upload`;
        try {
          const result = await axios.post(url, data, {
            signal,
            headers: getKCHeaders(),
            method: 'POST',
            onUploadProgress: (upload) => {
              // Set the progress value to show the progress bar
              const uploadloadProgress = Math.round((100 * upload.loaded) / upload.total);
              api.dispatch(setUploadProgress({ id, progressValue: uploadloadProgress }));
            },
          });
          return { data: result.data };
        } catch (axiosError) {
          if (!axios.isCancel(axiosError)) {
            throw axiosError;
          }
          return {}; // cancellation, return an empty result
        }
      },
      async onQueryStarted({ formData, id, applicationId }, { dispatch, queryFulfilled }) {
        try {
          const queryResult = await queryFulfilled;
          if (queryResult && queryResult.data) {
            const { data: documentMetadata } = queryResult;
            const { size: fileSize, fileName } = documentMetadata;
            dispatch(
              applicationDocumentsApi.util.updateQueryData(
                'getAllApplicationDocuments',
                applicationId,
                (draft) => {
                  const document = draft.find((d) => d.id === id);
                  document &&
                    Object.assign(document, {
                      fileSize,
                      fileName,
                      isRejected: false,
                      virusStatus: VirusStatus.Pending,
                    });
                },
              ),
            );
          }
        } catch (e) {
          console.error(e);
          toastError(`Document upload failed. ${e.error.message}`);
        }
      },
    }),
  }),
});

export const selectDocument = (applicationId: string, id: string) =>
  createSelector(
    applicationDocumentsApi.endpoints.getAllApplicationDocuments.select(applicationId),
    (documents) => documents?.data?.find((d) => d.id === id) ?? {},
  );

export const {
  useGetApplicationDocumentQuery,
  useGetAllApplicationDocumentsQuery,
  useAddApplicationDocumentMutation,
  useUpdateApplicationDocumentMutation,
  usePatchApplicationDocumentMutation,
  useRejectApplicationDocumentMutation,
  useDeleteApplicationDocumentMutation,
  useUploadApplicationDocumentMutation,
  useSendDocumentsToKycMutation,
  useUploadWithProgressMutation,
} = applicationDocumentsApi;
