/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_SANCTION_TRANSACTION,
  LOAD_SANCTION_TRANSACTION_SUCCESS,
  LOAD_SANCTION_TRANSACTION_FAIL,
} from '../constants/constants';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  sanctionTransaction: null,
};

const sanctionReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_SANCTION_TRANSACTION:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_SANCTION_TRANSACTION_SUCCESS:
        draft.sanctionTransaction = action.sanctionTransaction;
        draft.loading = false;
        break;
      case LOAD_SANCTION_TRANSACTION_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default sanctionReducer;
