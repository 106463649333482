import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography, Button } from '@mui/material';
import { MdWarningAmber } from 'react-icons/md';

const ManualStatusWrapper = styled('div', {
  name: 'ManualStatusWrapper',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    padding: '12px 14px',
    margin: '12px 0',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.main',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  }),
);

interface ManualStatusDescriptionProps {
  description?: string;
  handleRemove: () => void;
}

export const ManualStatusDescription: React.FC<ManualStatusDescriptionProps> = ({
  description,
  handleRemove,
}) => (
  <ManualStatusWrapper>
    <Stack gap="6px" color="primary.main" flexDirection="row" alignItems="flex-start">
      <MdWarningAmber size={16} />
      <Typography color="primary.main" p={0}>
        Manual status applied
      </Typography>
    </Stack>
    <Typography color="grey.700">{description}</Typography>
    <Button
      variant="outlined"
      color="primary"
      onClick={handleRemove}
      sx={{ margin: '6px 0 0 auto' }}
    >
      Remove Manual Status
    </Button>
  </ManualStatusWrapper>
);
