import React from 'react';
import { Helmet } from 'react-helmet';
import MerchantCategoriesList from './MerchantCategoriesList';

const MerchantCategories = () => (
  <div className="page-content">
    <Helmet title="Merchant Categories" />
    <div className="content container">
      <div className="page-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3>Merchant Categories</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <MerchantCategoriesList />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MerchantCategories;
