import { PaletteOpenpaydColor } from './types';
import { FigmaColor, textColor } from '../figmaVariables';

export const contrast: PaletteOpenpaydColor = {
  main: FigmaColor({ colorName: 'grey-blue', shade: 700 }),
  light: FigmaColor({ colorName: 'grey-blue', shade: 600 }),
  dark: FigmaColor({ colorName: 'grey-blue', shade: 900 }),
  contrastText: textColor('text-inverted'),
  'bg-100': FigmaColor({ colorName: 'grey-blue', shade: 700 }),
  'bg-200': FigmaColor({ colorName: 'grey-blue', shade: 800 }),
  'bg-300': FigmaColor({ colorName: 'grey-blue', shade: 900 }),
  'bg-400': FigmaColor({ colorName: 'grey-blue', shade: 910 }),
  'outline-100': FigmaColor({ colorName: 'grey-blue', shade: 700 }),

  hover: FigmaColor({ colorName: 'grey-blue', shade: 800 }),
  active: FigmaColor({ colorName: 'grey-blue', shade: 900 }),
};
