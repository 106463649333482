import { gatewayApi } from '../gatewayApi';

const ubosApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getUbos: build.query({
      query: (params) => ({
        url: `ubos`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Ubos', id: 'LIST' }],
    }),
    getUbo: build.query({
      query: ({ id }) => `ubos/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Ubo', id }],
    }),
    createUbo: build.mutation({
      query: ({ body }) => ({
        url: 'ubos',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Ubos', id: 'LIST' }],
    }),
    updateUbo: build.mutation({
      query: ({ body, id }) => ({
        url: `ubos/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Ubos', id: 'LIST' },
        { type: 'Ubo', id },
        { type: 'UboHistory', id },
      ],
    }),
    deleteUbo: build.mutation({
      query: ({ id }) => ({
        url: `ubos/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Ubos', id: 'LIST' },
        { type: 'Ubo', id },
        { type: 'UboHistory', id },
      ],
    }),

    getUboHistory: build.query({
      query: ({ id, historySize }) => `ubos/${id}/history?historySize=${historySize}`,
      providesTags: (result, error, { id }) => [{ type: 'UboHistory', id }],
    }),

    getUboLinkedApplications: build.query({
      query: ({ id }) => `ubos/${id}/linked-applications`,
      providesTags: (result, error, { id }) => [{ type: 'UboLinkedApplications', id }],
    }),
  }),
});

export const {
  useGetUbosQuery,
  useGetUboQuery,
  useCreateUboMutation,
  useUpdateUboMutation,
  useDeleteUboMutation,

  useGetUboHistoryQuery,

  useGetUboLinkedApplicationsQuery,
} = ubosApi;
