import React from 'react';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Message as MessageIcon,
} from '@mui/icons-material';
import { DecisionHystory } from '../ClientDetails/Tabs/ApplicationsTab/HistoryTab/types';

export const decisions = [
  { value: 'APPROVE', label: 'Approved' },
  { value: 'PARTIAL_APPROVE', label: 'Partial approve' },
  { value: 'CONDITIONAL_APPROVE', label: 'Conditional approve' },
  { value: 'REJECT', label: 'Rejected' },
  { value: 'DECLINE', label: 'Declined' },
];

export const getDecisionIcon = (decision?: DecisionHystory): JSX.Element => {
  switch (decision) {
    case 'APPROVE':
      return <CheckCircleIcon color="success" fontSize="small" />;
    case 'PARTIAL_APPROVE':
    case 'CONDITIONAL_APPROVE':
      return <CheckCircleIcon color="primary" fontSize="small" />;
    case 'REJECT':
    case 'DECLINE':
      return <CancelIcon color="error" fontSize="small" />;
    default:
      return <MessageIcon sx={{ color: 'grey.600' }} fontSize="small" />;
  }
};
