/**
 * Page selectors
 */

import { createSelector } from 'reselect';
import { initialState } from '../reducers/users';

const usersState = (state) => state.users || initialState;
const makeUsersData = () => createSelector(usersState, (state) => state.users);
const makeUsersToSaveData = () =>
  createSelector(usersState, (state) => {
    const { usersToSave } = state;
    if (usersToSave instanceof Array) {
      return usersToSave;
    }
    return [];
  });
const makeUsersLoadingData = () =>
  createSelector(usersState, (state) => state.loadingUsers || false);
const makeSavingData = () => createSelector(usersState, (state) => state.saving || false);

const makeLoggedInUserData = () => createSelector(usersState, (state) => state.loggedInUser || {});

const makeProductSelectionModalData = () =>
  createSelector(usersState, (state) => state.productSelectionModalOpen);

export {
  makeUsersData,
  makeUsersToSaveData,
  makeUsersLoadingData,
  makeSavingData,
  makeLoggedInUserData,
  makeProductSelectionModalData,
};
