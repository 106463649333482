import { palette } from '../palette';

export const TableRow = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      '&.MuiTableRow-hover': {
        '&:hover': {
          backgroundColor: palette.grey[200],
        },
      },
      td: {
        '&:first-of-type': {
          borderRadius: 0,
          paddingLeft: '20px',
        },
        '&:last-of-type': {
          borderRadius: 0,
          paddingRight: '20px',
        },
      },
      '&:first-of-type': {
        td: {
          '&:first-of-type': {
            borderTopLeftRadius: '12px',
          },
          '&:last-of-type': {
            borderTopRightRadius: '12px',
          },
        },
      },
      '&:last-of-type': {
        td: {
          '&:first-of-type': {
            borderBottomLeftRadius: '12px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '12px',
          },
        },
      },
    },
  },
};
