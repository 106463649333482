import { combineDateTime, removeEmptyProperties } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { DATETIME_FORMAT } from '../../constants/constants';
import { gatewayApi } from '../gatewayApi';

const riskWordsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskWords: build.query({
      query: (params) => ({
        url: 'risk-word',
        params,
      }),
      providesTags: () => [{ type: 'RiskWords', id: 'LIST' }],
      transformResponse: (response) => ({
        results: response.content.map((item) => {
          let result = { ...item };
          result = combineDateTime(result, formatDate, DATETIME_FORMAT);
          return result;
        }),
        count: response.totalElements,
      }),
    }),
    addRiskWord: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: 'risk-word',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'RiskWords', id: 'LIST' }],
    }),
    updateRiskWord: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: 'risk-word',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'RiskWords', id: 'LIST' }],
    }),
    deleteRiskWord: build.mutation({
      query: (id) => ({
        url: `risk-word/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'RiskWords', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRiskWordsQuery,
  useAddRiskWordMutation,
  useUpdateRiskWordMutation,
  useDeleteRiskWordMutation,
} = riskWordsApi;
