const responseCodes = [
  {
    responseCode: '00',
    description: 'Approved',
  },
  {
    responseCode: '01',
    description: 'Refer to card issuer',
  },

  {
    responseCode: '03',
    description: 'Invalid merchant',
  },
  {
    responseCode: '04',
    description: 'Capture card',
  },
  {
    responseCode: '05',
    description: 'Do not honor',
  },
  {
    responseCode: '08',
    description: 'Honor with ID',
  },
  {
    responseCode: '10',
    description: 'Partial Approval',
  },
  {
    responseCode: '12',
    description: 'Invalid transaction',
  },
  {
    responseCode: '13',
    description: 'Invalid amount',
  },
  {
    responseCode: '14',
    description: 'Invalid card number',
  },
  {
    responseCode: '15',
    description: 'Invalid issuer',
  },
  {
    responseCode: '17',
    description: 'Customer Cancellation',
  },
  {
    responseCode: '30',
    description: 'Format error',
  },

  {
    responseCode: '33',
    description: 'Expired card (Capture)',
  },
  {
    responseCode: '41',
    description: 'Lost card',
  },
  {
    responseCode: '43',
    description: 'Stolen card',
  },

  {
    responseCode: '51',
    description: 'Insufficient funds',
  },

  {
    responseCode: '54',
    description: 'Expired card',
  },
  {
    responseCode: '55',
    description: 'Invalid PIN',
  },

  {
    responseCode: '57',
    description: 'Transaction not permitted to issuer/cardholder',
  },
  {
    responseCode: '58',
    description: 'Transaction not permitted to acquirer/terminal',
  },
  {
    responseCode: '61',
    description: 'Exceeds withdrawal amount limit',
  },
  {
    responseCode: '62',
    description: 'Restricted card',
  },
  {
    responseCode: '63',
    description: 'Security violation',
  },
  {
    responseCode: '65',
    description: 'Exceeds withdrawal count limit',
  },
  {
    responseCode: '70',
    description: 'Contact Card Issuer',
  },
  {
    responseCode: '71',
    description: 'PIN Not Changed',
  },
  {
    responseCode: '75',
    description: 'Allowable number of PIN tries exceeded',
  },
  {
    responseCode: '76',
    description: 'Invalid/nonexistent ‚Äö√Ñ√∫To Account‚Äö√Ñ√π specified',
  },
  {
    responseCode: '77',
    description: 'Invalid/nonexistent ‚Äö√Ñ√∫From Account‚Äö√Ñ√π specified',
  },
  {
    responseCode: '78',
    description: 'Invalid/nonexistent account specified (general)',
  },
  {
    responseCode: '81',
    description: 'Domestic Debit Transaction Not Allowed (Regional use only)',
  },
  {
    responseCode: '84',
    description: 'Invalid Authorization Life Cycle',
  },
  {
    responseCode: '85',
    description: 'Not declined,Valid for all zero amount transactions.',
  },
  {
    responseCode: '86',
    description: 'PIN Validation not possible',
  },
  {
    responseCode: '87',
    description: 'Purchase Amount Only, No Cash Back Allowed',
  },
  {
    responseCode: '88',
    description: 'Cryptographic failure',
  },
  {
    responseCode: '89',
    description: 'Unacceptable PIN‚Äö√Ñ√Æ Transaction Declined‚Äö√Ñ√ÆRetry',
  },
  {
    responseCode: '91',
    description: 'Authorization Platform or issuer system inoperative',
  },
  {
    responseCode: '92',
    description: 'Unable to route transaction',
  },
  {
    responseCode: '94',
    description: 'Duplicate transmission detected',
  },
  {
    responseCode: '96',
    description: 'System error',
  },
  {
    responseCode: 'B1',
    description: 'Surcharge amount not permitted on Visa Cards',
  },
  {
    responseCode: 'N0',
    description: 'Force STIP',
  },
  {
    responseCode: 'N3',
    description: 'Cash service not available',
  },
  {
    responseCode: 'N4',
    description: 'Cashback request exceeds Issuer limit',
  },
  {
    responseCode: 'N7',
    description: 'Decline for CVV2 failure',
  },
  {
    responseCode: 'P2',
    description: 'Invalid biller information',
  },
  {
    responseCode: 'P5',
    description: 'PIN change/unblock request declined',
  },
  {
    responseCode: 'P6',
    description: 'Unsafe PIN',
  },
  {
    responseCode: 'R0',
    description: 'Stop payment order',
  },
  {
    responseCode: 'R1',
    description: 'Revocation of authorization order',
  },
  {
    responseCode: 'R3',
    description: 'Revocation of all authorizations order',
  },
  {
    responseCode: 'Z3',
    description: 'Unable to go online, declined',
  },
];

export default responseCodes;
