import { useSelector } from 'react-redux';
import { makeUserPermissionsNamesData } from '../selectors/user';

// TODO: Enum with all possible permissions
const tenantAdmin = 'TENANT_ADMIN';
const riskCountryView = 'RISK_COUNTRY_VIEW';
const riskCountryEdit = 'RISK_COUNTRY_EDIT';
const eeaRiskCountryView = 'EEA_RISK_COUNTRY_VIEW';
const eeaRiskCountryEdit = 'EEA_RISK_COUNTRY_EDIT';

export const useHasPermissions = (permissionNames: string[]): boolean => {
  const userPermissions: string[] = useSelector(makeUserPermissionsNamesData());

  if (!userPermissions || !Array.isArray(permissionNames)) return false;
  if (userPermissions.includes(tenantAdmin)) return true;
  return userPermissions.some((permissionName) =>
    Boolean(permissionNames.includes(permissionName)),
  );
};

export const useCanViewRiskCountry = (): boolean =>
  useHasPermissions([riskCountryView, riskCountryEdit]);
export const useCanEditRiskCountry = (): boolean => useHasPermissions([riskCountryEdit]);
export const useCanViewEeaRiskCountry = (): boolean =>
  useHasPermissions([eeaRiskCountryView, eeaRiskCountryEdit]);
export const useCanEditEeaRiskCountry = (): boolean =>
  useHasPermissions([eeaRiskCountryEdit]);
