import React from 'react';
import { Button } from '../Button';
import { Modal } from '../Modal';

import './ConfirmDialog.scss';

interface ConfirmDialogProps {
  showDialog: boolean;
  setShowDialog: any;
  onConfirm: any;
  onCancel: any;
  title: string;
  text: string;
  confirmButtonText?: string;
  confirmButtonColor?: any;
  cancelButtonText?: string;
  cancelButtonColor?: any;
  showCancelButton?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  showDialog,
  setShowDialog,
  onConfirm,
  onCancel,
  title,
  text,
  confirmButtonText = 'Ok',
  confirmButtonColor = 'primary',
  cancelButtonText = 'Cancel',
  cancelButtonColor = 'text',
  showCancelButton = true,
}) => {
  const handleCancel = () => {
    setShowDialog(false);
    if (onCancel) {
      onCancel();
    }
  };
  const handleConfirm = () => {
    setShowDialog(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Modal showModal={showDialog} setModal={setShowDialog} modalHeader={<h3>{title}</h3>}>
      <p>{text}</p>
      <div className="formControlBlock">
        {showCancelButton && (
          <Button onClick={handleCancel} type="button" theme={cancelButtonColor}>
            {cancelButtonText}
          </Button>
        )}
        <Button type="submit" theme={confirmButtonColor} onClick={handleConfirm}>
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDialog;
