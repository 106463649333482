export const detailsFields = [
  { value: 'created', label: 'Created' },
  { value: 'modified', label: 'Modified' },

  { value: 'applicationId', label: 'Application ID' }, // Put a link to page

  { value: 'town', label: 'Town' },
  { value: 'manualAddress', label: 'Address' },
  { value: 'birthPlace', label: 'Place of Birth' },
  { value: 'residenceCountryCode', label: 'Country of Residence' },

  { value: 'documentType', label: 'Document Type' },
  { value: 'docIssuingCountryCode', label: 'Document Issuing Country' },
  { value: 'documentNumber', label: 'Document Number' },

  { value: 'extId', label: 'Client ID' },
  { value: 'nationality', label: 'Nationality' },
  { value: 'dob', label: 'Date of Birth' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'email', label: 'Email' },
];
