import toastr from 'toastr';
import Select from 'react-select';
import PropTypes from 'prop-types';
import React from 'react';
import { DataTableRow } from '../../../components';
import ApiService from '../../../services/apiService';

export class CardDataItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAction: '',
      isUpdating: false,
    };
  }

  getActionOptions() {
    return [
      { label: 'Block', value: 'BLOCK' },
      { label: 'Refresh', value: 'REFRESH' },
      { label: 'Always Allow', value: 'ALWAYS_ALLOW' },
    ];
  }

  cancelUpdateCardData() {
    this.setState({
      isUpdating: false,
    });
  }

  handleCardActionChange(e) {
    if (e) {
      this.setState({
        cardAction: e.value,
      });
    }
  }

  handleSubmit() {
    const { item } = this.props;
    if (this.state.cardAction === '') {
      return;
    }

    ApiService.update(`/api/card-data/${item.cardDataId}`, {
      cardAction: this.state.cardAction,
    })
      .then(() => {
        item.cardAction = this.state.cardAction;
        this.setState({
          isUpdating: false,
        });
        toastr.success('Card Action Updated Successfully');
      })
      .catch((error) => toastr.error(`Error updating card - ${error}`));
  }

  renderUpdateCardAction() {
    return (
      <span className="form-group">
        <Select
          className="select-width"
          name="cardAction"
          options={this.getActionOptions()}
          isClearable
          onChange={(e) => {
            this.handleCardActionChange(e);
          }}
        />
      </span>
    );
  }

  render() {
    const { item } = this.props;
    return (
      <tbody>
        <DataTableRow>
          <td>{item.maskedCardNumber}</td>
          <td>
            {!this.state.isUpdating && item.cardAction}
            {this.state.isUpdating && this.renderUpdateCardAction()}
          </td>
          <td className="text-nowrap">
            {!this.state.isUpdating && (
              <a
                className="text-success"
                onClick={() => {
                  this.setState({
                    isUpdating: true,
                  });
                }}
                title="Edit"
              >
                <i className="fa fa-edit" />
              </a>
            )}
            {this.state.isUpdating && (
              <span>
                <a
                  className="text-success"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  title="Save"
                >
                  <i className="fa fa-save" />
                </a>
                <a
                  className="text-danger"
                  onClick={() => {
                    this.setState({
                      isUpdating: false,
                    });
                  }}
                  title="Cancel"
                >
                  <i className="fa fa-times-circle" />
                </a>
              </span>
            )}
          </td>
        </DataTableRow>
      </tbody>
    );
  }
}

CardDataItem.propTypes = {
  item: PropTypes.object,
};

export default CardDataItem;
