import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { Client } from '../../types';
import { FinActivDialog } from './FinActivDialog';
import { FinActivForm } from './FinActivForm';
import { initialFinActivity } from '../../constants';

interface FinActivTabProps {
  clientDetails: Client;
  setEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FinActivTab: React.FC<FinActivTabProps> = ({ clientDetails, setEdited }) => {
  const [finActivity, setFinActivity] = useState(undefined);

  const { countries, isLoadingCountries } = useGetAllCountriesQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        countries: data?.map((el) => ({
          value: el.countryCode,
          label: `${el.countryCode}: ${el.name}`,
        })),
        isLoadingCountries: isLoading,
      }),
    },
  );

  const hasFinActivity =
    !!clientDetails.monthlyTransactions ||
    !!clientDetails.monthlyTurnover ||
    !!clientDetails.averageInbound ||
    !!clientDetails.averageOutbound ||
    !!clientDetails.maxInbound ||
    !!clientDetails.maxOutbound;

  return (
    <>
      {!hasFinActivity && (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => setFinActivity(initialFinActivity)}
          disabled={isLoadingCountries}
        >
          Provide financial activity info
        </Button>
      )}

      {hasFinActivity && (
        <FinActivForm
          client={clientDetails}
          countries={countries}
          isLoading={isLoadingCountries}
          setEdited={setEdited}
          setFinActivity={setFinActivity}
        />
      )}

      {!!finActivity && (
        <FinActivDialog
          finActivity={finActivity}
          setEdited={setEdited}
          clientId={clientDetails.id}
          onClose={() => setFinActivity(undefined)}
          countries={countries}
        />
      )}
    </>
  );
};
