import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { theme } from '../../theme';

// Compatibility: MUI-Datatables is still based on MUI 4
// TODO: Remove after MUI-Datatables update
const v4Theme = createTheme(theme, {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 600,
      },
      sortAction: {
        fontWeight: 600,
      },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        width: '360px',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
        },
      },
    },

    MuiGrid: {
      MuiGridContainer: {
        margin: '5px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '5px 10px',
      },
    },
  },
});

const ThemedMUIDatatable = (props) => (
  <ThemeProvider theme={v4Theme}>
    <MUIDataTable {...props} />
  </ThemeProvider>
);

export default ThemedMUIDatatable;
