import { removeEmptyProperties } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { gatewayApi } from '../gatewayApi';

const merchantsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getMerchants: build.query({
      query: (params) => ({
        url: `merchants`,
        params,
      }),
      providesTags: () => [{ type: 'Merchants', id: 'LIST' }],
      transformResponse: (response) => ({
        results: response.content.map((item) => {
          const result = { ...item };
          result.createdDateTime = formatDate(result.createdDateTime);
          return result;
        }),
        count: response.totalElements,
      }),
    }),
    getMerchant: build.query({
      query: (merchantId) => `merchants/${merchantId}`,
      providesTags: (result, error, merchantId) => [{ type: 'Merchant', merchantId }],
    }),
    addMerchant: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: 'merchants',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Merchants', id: 'LIST' }],
    }),
    updateMerchant: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: `merchants/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Merchants', id: 'LIST' }],
    }),
    deleteMerchant: build.mutation({
      query: (id) => ({
        url: `merchants/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Merchants', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetMerchantsQuery,
  useGetMerchantQuery,
  useAddMerchantMutation,
  useUpdateMerchantMutation,
  useDeleteMerchantMutation,
} = merchantsApi;
