import React from 'react';
import { styled } from '@mui/material/styles';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { ButtonProps, ButtonTypeMap, ExtendButtonBase } from '@mui/material';
import { Input } from '@mui/base';

const InputStyled = styled('input')(({ theme }) =>
  theme.unstable_sx({
    width: 100,
    pt: 1,
    border: 'none',
    backgroundColor: 'transparent',
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  }),
);

const InputRoot = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 134,
    height: 32,
    px: 1.5,
    py: 2,
    mx: 0,
    my: 0,
    background: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'grey.200',
    boxSizing: 'border-box',
    borderRadius: '6px',
    cursor: 'pointer',
    '& button': {
      p: 0,
      m: 0,
      '& > *:last-child': {
        fontSize: 16,
      },
    },
    '&:hover': {
      backgroundColor: 'grey.50',
    },
  }),
);

export const DatePickerInput = React.forwardRef(
  ({ InputProps, inputProps }: TextFieldProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const endAdornment = InputProps?.endAdornment as React.ReactElement;
    const openPickerButton = endAdornment?.props.children as React.ReactElement<
      ButtonProps,
      ExtendButtonBase<ButtonTypeMap>
    >;

    return (
      <Input
        ref={ref}
        components={{ Root: InputRoot, InputStyled }}
        onClick={openPickerButton?.props.onClick}
        endAdornment={openPickerButton}
        value={inputProps?.value}
        placeholder={inputProps?.placeholder}
        readOnly
      />
    );
  },
);
