import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
} from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import DatePicker from '../../components/DatePicker/datePicker';
import { checkProductApiName, formatDateServer, testIbanChecksum } from '../../utils';
import { IBAN_REGEX, IP_REGEX, NUM_LETTERS_REGEX } from '../../constants/constants';
import {
  useAddRuleVetoMutation,
  useGetRuleVetosQuery,
} from '../../services/gatewayApi/ruleVetosApi';
import { vetoTypes } from './constants';
import { RowRuleVeto } from './RowRuleVeto';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  color: theme.palette.common.white,
}));

interface RuleVetosFormProps {
  ruleId: number | string;
}

export const RuleVetosForm: React.FC<RuleVetosFormProps> = ({ ruleId }) => {
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const [addRuleVeto] = useAddRuleVetoMutation();
  const { data: ruleVetosList = [], isLoading, isFetching } = useGetRuleVetosQuery({ ruleId });

  const vetoTypesForTenant = vetoTypes.filter((el) => checkProductApiName(el.apiName));

  const validationSchema = yup.object({
    ruleVetoType: yup.string().required('Required'),
    referenceIdentifier: yup.string().when('ruleVetoType', (el, schema) => {
      switch (el) {
        case 'CUSTOMER_EMAIL':
          return schema
            .email('Please use a valid email address.')
            .required('Email address is required.');
        case 'MERCHANT_ID':
          return schema.required('Required').matches(/[0-9]/, 'Please input a number');
        case 'SENDER_IBAN':
        case 'BENEFICIARY_IBAN':
        case 'SENDER_BENEFICIARY_IBAN':
          return schema
            .required('Required')
            .min(5, 'Minimum length is ${min}')
            .max(35, 'Maximum length is ${max}')
            .matches(IBAN_REGEX, 'IBAN is not in correct format')
            .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value));
        case 'SENDER_BIC':
        case 'BENEFICIARY_BIC':
        case 'SENDER_BENEFICIARY_BIC':
        case 'CLIENT_ID':
          return schema
            .required('Required')
            .matches(NUM_LETTERS_REGEX, 'Only numbers and letters are allowed');
        case 'IP_ADDRESS':
          return schema.required('Required').matches(IP_REGEX, 'IP address format required');
        default:
          return schema.required('Required');
      }
    }),
    startDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    expiryDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(
        yup.ref('startDate'), // value needs to be startDate +1 day
        () => 'Date needs to be later than the Start Date',
      ),
    comments: yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      id: '',
      tenantId: '',
      ruleId,
      ruleVetoType: vetoTypesForTenant.length > 0 ? vetoTypesForTenant[0].value : '',
      referenceIdentifier: '',
      startDate: null,
      expiryDate: null,
      comments: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      const startDate = formatDateServer(values.startDate);
      const expiryDate = formatDateServer(values.expiryDate);
      const reference =
        selectedProductId === 3
          ? values.referenceIdentifier.replace(/[^a-zA-Z0-9]+/gi, '') // keep numbers and letters only & .toUpperCase();
          : values.referenceIdentifier;
      const model = {
        ruleId: values.ruleId,
        ruleVetoType: values.ruleVetoType,
        referenceIdentifier: reference,
        startDate,
        expiryDate,
        comments: values.comments,
      };
      addRuleVeto(model);
      resetForm();
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <TextField
              id="select-rule-veto-type"
              label="Veto Type"
              name="ruleVetoType"
              value={formik.values.ruleVetoType}
              select
              fullWidth
              size="small"
              autoFocus
              variant="outlined"
              onChange={formik.handleChange}
              error={formik.touched.ruleVetoType && Boolean(formik.errors.ruleVetoType)}
              helperText={formik.touched.ruleVetoType && formik.errors.ruleVetoType}
            >
              {vetoTypesForTenant &&
                vetoTypesForTenant.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="input-referenceIdentifier"
              type="text"
              fullWidth
              size="small"
              label={vetoTypes.find((el) => el.value === formik.values.ruleVetoType)?.label || ''}
              name="referenceIdentifier"
              onChange={formik.handleChange}
              value={formik.values.referenceIdentifier}
              variant="outlined"
              error={
                formik.touched.referenceIdentifier && Boolean(formik.errors.referenceIdentifier)
              }
              helperText={formik.touched.referenceIdentifier && formik.errors.referenceIdentifier}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              name="startDate"
              label="Start Date"
              size="small"
              error={formik.touched.startDate && Boolean(formik.errors.startDate)}
              value={formik.values.startDate}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <DatePicker
              name="expiryDate"
              label="Expiry Date"
              size="small"
              error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)}
              value={formik.values.expiryDate}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              id="comments"
              type="text"
              fullWidth
              size="small"
              label="Comment"
              name="comments"
              onChange={formik.handleChange}
              value={formik.values.comments}
              variant="outlined"
              error={formik.touched.comments && Boolean(formik.errors.comments)}
              helperText={formik.touched.comments && formik.errors.comments}
            />
          </Grid>
          <Grid item container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={formik.isSubmitting}
              >
                <AddCircleIcon sx={{ mr: 2 }} />
                Add Veto
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Typography component="div" variant="subtitle2" align="left" sx={{ mt: 4 }}>
        Vetos for Rule ID: {ruleId}
      </Typography>

      <Box visibility={isFetching || isLoading ? 'visible' : 'hidden'} sx={{ py: 2 }}>
        <LinearProgress />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Veto Type</StyledTableCell>
              <StyledTableCell>Veto Reference</StyledTableCell>
              <StyledTableCell align="center">Start Date</StyledTableCell>
              <StyledTableCell align="center">Expiry Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ruleVetosList.length > 0 &&
              ruleVetosList.map((veto) => (
                <RowRuleVeto veto={veto} key={veto.id} isLoading={isLoading || isFetching} />
              ))}

            {ruleVetosList.length === 0 && (
              <TableRow>
                <TableCell colSpan={12}>
                  {!isLoading && (
                    <Typography variant="body1" align="center" py={6} color="grey.600">
                      No Vetos added...
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
