/* eslint-disable no-template-curly-in-string */
import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdateKycProviderMutation } from '../../services/gatewayApi/kycProvidersApi';

const KycProviderFormDialog = ({ open, onClose, data }) => {
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('Required')
      .min(3, 'Minimum length is ${min}')
      .max(128, 'Maximum length is ${max}'),
    description: yup.string(),
  });

  const [updateKycProvider] = useUpdateKycProviderMutation();

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const model = {
        ...values,
      };
      const mutationPromise = await updateKycProvider(model);
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        onClose();
      }
    },
  });

  const handleExit = () => {
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle id="form-dialog-title">
        Edit KYC Provider
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box width={500} my={2}>
          <form id="kycProviderForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  size="small"
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="outlined"
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  size="small"
                  multiline
                  minRows={2}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={formik.values.isEnabled ? 'true' : 'false'}
                  onChange={(event, isEnabled) => {
                    if (isEnabled !== null) {
                      formik.setFieldValue('isEnabled', isEnabled === 'true');
                    }
                  }}
                >
                  <ToggleButton value="true">Enabled</ToggleButton>
                  <ToggleButton value="false">Disabled</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="kycProviderForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

KycProviderFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    isEnabled: PropTypes.bool,
  }),
};

export default KycProviderFormDialog;
