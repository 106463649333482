import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { setSelectedProduct } from 'actions/users';
import { BreadCrumbsAdapter, Modal } from 'uikit';
import { Button, Typography } from '@mui/material';
import { gatewayApi } from 'services/gatewayApi';
import { ProductMenu } from './ProductMenu/ProductMenu';
import { AppLogo } from './AppLogo';
import { AppUserMenu } from './AppUserMenu/AppUserMenu';
import { AppMenu } from './AppMenu/AppMenu';
import './AppHeader.scss';
import 'styles/scrollbar-replacement.css';

export const AppHeader = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.users.loggedInUser);
  const selectedProductId = useSelector((state: any) => state.users.selectedProductId);

  const [showTenantDialog, setShowTenantDialog] = useState<boolean>(false);
  const [showAppMenu, setShowAppMenu] = useState<boolean>(false);
  const [currentProductName, setCurrentProductName] = useState<string>('');

  const getCurrentProductName = () => {
    if (user?.tenants?.length) {
      const product = user.tenants.find((i) => i.id === parseInt(selectedProductId, 10));
      return product?.name || undefined;
    }
    return '';
  };

  // Set initial product
  useEffect(() => {
    setCurrentProductName(getCurrentProductName());
    if (!user.permissions) {
      setShowTenantDialog(false);
    }
  }, [user, selectedProductId]);

  // show/hide Tenant dialog
  useEffect(() => {
    if (selectedProductId) {
      setShowTenantDialog(false);
    } else {
      setShowTenantDialog(true);
    }
  }, [selectedProductId]);

  // Pin Tenant dialog if no product selected
  useEffect(() => {
    if (!selectedProductId && !showTenantDialog) {
      setShowTenantDialog(true);
    }

    if (!user.permissions) {
      setShowTenantDialog(false);
    }
  }, [selectedProductId, showTenantDialog]);

  const handleSelectProduct = (productId) => {
    dispatch(setSelectedProduct(productId));
    dispatch(gatewayApi.util.resetApiState());
  };

  const renderProducts = () => {
    const products = (user && user.tenants) || [];
    return (
      <ProductMenu
        products={products}
        handleSelectProduct={handleSelectProduct}
        selectedProductId={selectedProductId}
      />
    );
  };

  const getCurrentTenantLogoURL = () => {
    const products = (user && user.tenants) || [];

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === selectedProductId) {
        return products[i].imageUrl;
      }
    }
    return '';
  };
  const theme = useTheme();

  return (
    <>
      <div
        className="AppHeader"
        style={{
          backgroundColor: theme.palette.contrast['bg-100'],
          color: 'white',
          height: '44px',
        }}
      >
        <div className="container container-OpenPayd">
          <div className="AppHeaderContent">
            <div className="AppHeaderSection">
              <Button
                size="small"
                variant="contained"
                shape="circled"
                color="contrast"
                onClick={() => setShowAppMenu(!showAppMenu)}
              >
                <MenuIcon style={{ fontSize: '22px' }} />
              </Button>

              <span className="AppLogo">
                <AppLogo />
              </span>

              <div style={{ marginLeft: 40 }}>
                <BreadCrumbsAdapter />
              </div>
            </div>

            <div className="AppHeaderSection">
              {currentProductName && (
                <Button
                  size="medium"
                  variant="contained"
                  color="contrast"
                  onClick={() => setShowTenantDialog(true)}
                  disableElevation
                  sx={{
                    bgcolor: 'grey.700',
                    '&:hover': { bgcolor: 'grey.800' },
                  }}
                >
                  Product: {currentProductName}
                </Button>
              )}

              <AppUserMenu user={user} />
            </div>
          </div>
        </div>
      </div>

      {showTenantDialog && (
        <Modal
          showModal={showTenantDialog}
          setModal={setShowTenantDialog}
          modalHeader={<h3>Product selection</h3>}
          closable={!!selectedProductId}
        >
          <div className="ProductsList">{renderProducts()}</div>
        </Modal>
      )}

      {showAppMenu && (
        <AppMenu
          user={user}
          showAppMenu={showAppMenu}
          setShowAppMenu={setShowAppMenu}
          tenantLogoURL={getCurrentTenantLogoURL()}
        />
      )}
    </>
  );
};
