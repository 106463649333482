import { figmaVariables } from '../figmaVariables';

export const Menu = {
  defaultProps: {},
  styleOverrides: {
    paper: {
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '16px',
      },
      '::-webkit-scrollbar-thumb': {
        height: '56px',
        borderRadius: '8px',
        border: '4px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: 'transparent',
      },
      ':hover::-webkit-scrollbar-thumb': {
        backgroundColor: figmaVariables.primitives.color['grey-blue']['grey-blue-300'],
      },
    },
    list: {
      padding: '4px',
      '& .MuiMenuItem-root': {
        transition: 'none',
        borderRadius: figmaVariables.primitives.radius['md-6'],
        padding: '6px',
        color: figmaVariables.primitives.color['grey-blue']['grey-blue-900'],
        '&:hover': {
          backgroundColor: figmaVariables.primitives.color['grey-blue']['grey-blue-100'],
        },
        '& .MuiListItemIcon-root': {
          color: figmaVariables.primitives.color['grey-blue']['grey-blue-900'],
        },
        '&.redMenuItem': {
          color: figmaVariables.primitives.color['red']['red-700'],
          '& .MuiListItemIcon-root svg': {
            color: figmaVariables.primitives.color['red']['red-700'],
          },
        },
      },
      '& .MuiDivider-root': {
        // perhaps this should be a separate MuiDivider styleOverrides
        margin: '4px 0',
      },
    },
  },
};
