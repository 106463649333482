import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Chip } from '@mui/material';
import { Modal, InputBox, TextArea, FormRow, ErrorMessage } from 'uikit';
import {
  useGetApplicationEmailsQuery,
  useAddApplicationEmailsMutation,
} from 'services/gatewayApi/applicationApi';
import { validationSchema } from './sendEmailData';
import './SendEmailDialog.scss';
import { testEmail, toastSuccess } from 'utils';

export interface SendEmailProps {
  applicationId: string;
  emailType?: string;
  title?: string;
  subject?: string;
  onAfterSubmit?: any;
}

interface SendEmailDialogProps extends SendEmailProps {
  showDialog: boolean;
  setShowDialog: any;
}

export const SendEmailDialog: React.FC<SendEmailDialogProps> = ({
  showDialog,
  setShowDialog,
  emailType = 'welcome',
  applicationId,
  title = 'Notify Customer',
  subject,
  onAfterSubmit,
}) => {
  const isCommon = emailType === 'common';
  const isCustomerNotification = title === 'Notify Customer';

  const [addApplicationEmails] = useAddApplicationEmailsMutation();
  const { data: applicationEmail, isLoading } = useGetApplicationEmailsQuery(
    {
      applicationId,
      emailType: 'welcome',
    },
    {
      skip: !showDialog || (isCommon && !isCustomerNotification),
    },
  );

  const initialValues = isCommon
    ? { to: isCustomerNotification ? [applicationEmail?.to] : [], subject, body: '' }
    : { ...applicationEmail, to: [applicationEmail?.to] };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);

      const model = {
        emailType,
        id: applicationId,
        ...values,
        to: values.to[0], // Only single email is supported on /welcome endpoint
      };

      if (isCommon) {
        delete model.to;
        model.recipients = values.to;
      }

      addApplicationEmails(model)
        .unwrap()
        .then(() => {
          toastSuccess('Email sent successfully');
          if (onAfterSubmit) {
            onAfterSubmit();
          }
        })
        .catch((rejected) => {
          console.error(rejected);
        })
        .finally(() => {
          formik.resetForm();
          setShowDialog(false);
        });
    },
  });

  const cancelHandler = () => {
    formik.resetForm();
    setShowDialog(false);
  };

  // #region Email Recipients Input
  const emailInput = useRef<HTMLInputElement>();

  const focusEmailInput = () => {
    if (showDialog && !isCustomerNotification) {
      emailInput.current.focus();
    }
  };

  useEffect(() => {
    focusEmailInput();
  }, [showDialog, isCustomerNotification]);

  const isInToList = (email) => formik.values.to.includes(email);

  const handleAddEmail = () => {
    const email = formik.values?.email?.trim();

    if (email && testEmail(email) && !isInToList(email)) {
      formik.setFieldValue('to', [...formik.values.to, email]);
      formik.setFieldValue('email', '');
    } else {
      formik.setFieldValue('email', email);
    }
  };

  const handleKeyDownEmail = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();

      handleAddEmail();
    }
  };

  const handleDeleteEmail = (email) => {
    formik.setFieldValue(
      'to',
      formik.values.to.filter((i) => i !== email),
    );
    focusEmailInput();
  };

  const handlePasteEmail = (e) => {
    const paste = e.clipboardData.getData('text');
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      e.preventDefault();

      const toBeAdded = emails.filter((email) => !isInToList(email));

      formik.setFieldValue('to', [...formik.values.to, ...toBeAdded]);
    }
  };
  // #endregion

  return (
    <Modal
      showModal={!isLoading && showDialog}
      setModal={setShowDialog}
      modalHeader={<h3>{title}</h3>}
      doNotCloseOnBackdrop
      closable
    >
      <Box className="SendEmailDialog">
        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
          <FormRow>
            <Box
              className="ChipInput"
              onClick={(e) => {
                e.stopPropagation();
                focusEmailInput();
              }}
            >
              <span>Recipients</span>

              <Box className="ChipInputContent">
                {formik.values.to.length > 0 &&
                  formik.values.to.map((email) => (
                    <Chip
                      key={email}
                      label={email}
                      variant="outlined"
                      onDelete={isCustomerNotification ? undefined : () => handleDeleteEmail(email)}
                    />
                  ))}

                {!isCustomerNotification && (
                  <input
                    ref={emailInput}
                    name="email"
                    onBlur={handleAddEmail}
                    onChange={formik.handleChange}
                    onKeyDown={handleKeyDownEmail}
                    onPaste={handlePasteEmail}
                    value={formik.values.email}
                    style={{ width: 5 + formik.values?.email?.length + 'ch' }}
                    autoFocus
                    disabled={formik.isSubmitting}
                  />
                )}
              </Box>
            </Box>

            {formik.errors.email && formik.touched.email && (
              <ErrorMessage>{formik.errors.email}</ErrorMessage>
            )}

            {formik.errors.to && formik.touched.to && (
              <ErrorMessage>{formik.errors.to}</ErrorMessage>
            )}
          </FormRow>

          <FormRow>
            <InputBox
              label="Subject"
              name="subject"
              onChange={formik.handleChange}
              value={formik.values.subject}
              disabled={formik.isSubmitting}
              hasError={!!(formik.errors.subject && formik.touched.subject)}
            />

            {formik.errors.subject && formik.touched.subject && (
              <ErrorMessage>{formik.errors.subject}</ErrorMessage>
            )}
          </FormRow>
          <FormRow>
            <TextArea
              label="Message"
              name="body"
              onChange={formik.handleChange}
              value={formik.values.body}
              disabled={formik.isSubmitting}
              hasError={!!(formik.errors.body && formik.touched.body)}
            />

            {formik.errors.body && formik.touched.body && (
              <ErrorMessage>{formik.errors.body}</ErrorMessage>
            )}
          </FormRow>

          <div className="formControlBlock">
            <Button
              type="button"
              variant="text"
              color="base"
              onClick={cancelHandler}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
              sx={{ ml: 2 }}
            >
              Send
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};
