/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_ENVIRONMENT,
  LOAD_ENVIRONMENT_SUCCESS,
  LOAD_ENVIRONMENT_FAIL,
} from '../constants/constants';

export const initialState = {
  loading: false,
  hasLoaded: false,
  env: null,
  error: null,
};

const environmentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ENVIRONMENT:
        draft.loading = true;
        draft.hasLoaded = false;
        draft.error = false;
        break;
      case LOAD_ENVIRONMENT_SUCCESS:
        draft.env = action.payload;
        draft.hasLoaded = true;
        draft.loading = false;
        break;
      case LOAD_ENVIRONMENT_FAIL:
        draft.loading = false;
        draft.hasLoaded = false;
        draft.error = action.error;
        break;
    }
  });

export default environmentReducer;
