// should be refactored and became a part of hs-forms
type ComponentType = 'group' | 'subtitle' | 'key-value' | 'list' | 'row' | 'text' | 'selector';

interface Validate {
  groupIds?: string[];
  message: string;
  operator: string;
  threshold: number;
  type: string;
}

export interface FieldObject {
  component: ComponentType;
  fields: FieldObject[];
  label?: string;
  name?: string;
  parent?: string;
  keyValueType?: 'key' | 'value' | 'key-value';
  description?: string;
  fieldId?: number;
  fieldName?: string;
  id?: string;
  templateId?: string;
  validate?: Validate[];
  width?: number;
  initialValue?: string;
  isRequired?: boolean;
  selectorId?: string;
}

interface UpdateLabelsProps {
  keyLabel: string;
  valueLabel: string;
  obj: FieldObject;
}

interface AssignKeyValueLabelsDeepProps extends Omit<UpdateLabelsProps, 'obj'> {
  objects?: FieldObject[];
}

const LABEL_TRIM_END_CHARS = 2;

export const updateLabels = ({ keyLabel, valueLabel, obj }: UpdateLabelsProps): FieldObject => {
  const getLabel = (keyValueType: string) => {
    switch (keyValueType) {
      case 'key':
        return keyLabel;
      case 'value':
        return valueLabel;
      default:
        return obj.label;
    }
  };

  const updatedObj = {
    ...obj,
    label: getLabel(obj?.keyValueType || ''),
  };
  if (obj?.fields) {
    updatedObj.fields = obj.fields.map((field) =>
      updateLabels({ keyLabel, valueLabel, obj: field }),
    );
  }

  return updatedObj;
};

// make groups of key-value pairs with a subtitle and a list
export const processKeyValue = (schemaArray: FieldObject[]) => {
  const arr = schemaArray ? schemaArray.filter((item) => item !== null && item !== undefined) : [];
  const result = [];
  let i = 0;

  while (i < arr.length) {
    // Check if the next three objects meet the conditions (key-value group)
    if (
      i + 2 < arr.length &&
      arr[i].fields.length === 1 &&
      arr[i].fields[0].component === 'subtitle' &&
      arr[i + 1].fields.length === 1 &&
      arr[i + 1].fields[0].component === 'key-value' &&
      arr[i + 2].fields.length === 1 &&
      arr[i + 2].fields[0].component === 'list'
    ) {
      // make a new group row
      const newName = `${arr[i].name}-${arr[i + 1].name}-${arr[i + 2].name}`;
      const subTitleRow = arr[i];
      const keyValueLabelRow = arr[i + 1].fields[0];
      const keyLabel = keyValueLabelRow?.fields[0]?.fields[0]?.label || '';
      const valueLabel = keyValueLabelRow?.fields[0]?.fields[1]?.label || '';
      const listRow = updateLabels({ keyLabel, valueLabel, obj: arr[i + 2] });
      const newObject = {
        component: 'group',
        fields: [subTitleRow, keyValueLabelRow, listRow],
        name: newName,
        parent: 'root',
      };
      // add group row object and skip next two objects
      result.push(newObject);
      i += 3;
    } else {
      // add other row objects
      result.push(arr[i]);
      i += 1;
    }
  }

  return result;
};

// get the key and value labels from the FieldObject
export const findKeyAndValueLabels = (
  obj: FieldObject,
): { keyLabel: string; valueLabel: string } => {
  let keyLabel: string | undefined = '';
  let valueLabel: string | undefined = '';

  // recursive function to traverse the object
  function traverse(currentObj: FieldObject) {
    if (keyLabel && valueLabel) {
      return;
    }
    if (currentObj?.keyValueType === 'key') {
      keyLabel = currentObj.label;
    } else if (currentObj?.keyValueType === 'value') {
      valueLabel = currentObj.label;
    }
    if (currentObj?.fields && currentObj?.fields.length > 0) {
      for (const field of currentObj?.fields) {
        traverse(field);
      }
    }
  }

  traverse(obj);

  return { keyLabel, valueLabel };
};

// assign key and value labels to all FieldObject in the array
export const assignKeyValueLabelsDeep = ({
  keyLabel,
  valueLabel,
  objects,
}: AssignKeyValueLabelsDeepProps): FieldObject[] => {
  if (!objects) {
    return [];
  }

  // recursive function to traverse the object
  const updateObjectLabels = (obj: FieldObject): FieldObject => {
    const updatedObj = updateLabels({
      keyLabel: keyLabel.slice(0, -LABEL_TRIM_END_CHARS), // remove hardcoded on BE *
      valueLabel: valueLabel.slice(0, -LABEL_TRIM_END_CHARS), // remove hardcoded on BE *
      obj,
    });
    if (updatedObj?.fields && updatedObj.fields.length > 0) {
      updatedObj.fields = updatedObj.fields.map((field) => updateObjectLabels(field));
    }
    return updatedObj;
  };

  // use the recursive function to update all objects
  return objects.map((obj) => updateObjectLabels(obj));
};
