import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Skeleton,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import { ModalDialog } from 'uikit';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import {
  ApplicationNote as ApplicationNoteData,
  useAddNoteMutation,
  useUpdateNoteMutation,
} from 'services/gatewayApi/applicationNotesApi';
import {
  useGetApplicationNoteDocumentsQuery,
  VirusStatus,
} from 'services/gatewayApi/applicationNoteDocumentsApi';
import { User } from 'features/UsersManagement/types';
import { formatDate } from 'utils/formatter';
import { htmlModules, htmlFormats } from 'constants/quillSettings';
import { toastAddSuccess, toastUpdateSuccess } from 'utils/toast';
import { encodeToBase64, decodeFromBase64 } from 'utils/base64';
import ReactQuill from 'react-quill-hg-v2';
import { Attachments } from './Attachments';

// Temporary package from cloned branch which is not yet published
import 'react-quill/dist/quill.snow.css';

import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';

import './NoteDialog.scss';

interface NoteDialogProps {
  open: boolean;
  onClose: () => void;
  note?: Partial<ApplicationNoteData>;
  usersList?: User[]; // needs to be passed before render
}

export const NoteDialog: React.FC<NoteDialogProps> = ({ open, onClose, note = {}, usersList }) => {
  const {
    id,
    applicationId,
    title: titleProp = '',
    text: textProp = '',
    isSummary: isSummaryProp = false,
    isInternal: isInternalProp = false,
    isDraft: isDraftProp = false,
    createdByName,
    createdDate,
    modifiedByName,
    modifiedDate,
  } = note;
  const isNew = id === 'new';

  const entityName = 'Note';
  const modalTitle = `${isNew ? 'Add' : 'Edit'} ${entityName}`;
  const actionTitle = isNew ? 'Add' : 'Save';

  const [title, setTitle] = useState<string>(titleProp);
  const [text, setText] = useState<string>(decodeFromBase64(textProp || '<p><br></p>'));
  const [isSummary, setIsSummary] = useState<boolean>(isSummaryProp);
  const [isInternal, setIsInternal] = useState<boolean>(isInternalProp);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const quillRef = useRef<ReactQuill>(null);

  const [addNote] = useAddNoteMutation();
  const [updateNote] = useUpdateNoteMutation();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = ({ setIsDraft = false }: { setIsDraft?: boolean }): void => {
    setIsSaving(true);

    const model = {
      applicationId,
      title: title || 'Untitled',
      text: encodeToBase64(text) || '',
      isSummary,
      isDraft: setIsDraft,
      isInternal,
      taggedUsers: getSpanDataAttribute(text, 'id'),
    };

    (isNew ? addNote(model) : updateNote({ id, ...model }))
      .unwrap()
      .then(() => {
        setIsSaving(false);
        isNew ? toastAddSuccess(entityName) : toastUpdateSuccess(entityName);
        onClose();
      })
      .catch((rejected) => {
        console.error(rejected);
      });
  };

  // #region Note Documents
  const [hasPendingVirusStatus, setHasPendingVirusStatus] = useState<boolean>(true);
  const { data: attachments, isLoading: isLoadingAttachments } =
    useGetApplicationNoteDocumentsQuery(
      { applicationId, noteId: id },
      {
        pollingInterval: hasPendingVirusStatus ? 15 * 1000 : 0,
        skip: isNew,
      },
    );

  useEffect(() => {
    const hasPending = attachments?.some((doc) => doc.virusStatus === VirusStatus.Pending);
    setHasPendingVirusStatus(hasPending);
  }, [JSON.stringify(attachments)]);
  // #endregion

  const closeModalDialog = () => {
    const isContentModified =
      title !== titleProp ||
      text !== decodeFromBase64(textProp) ||
      isSummary !== isSummaryProp ||
      isInternal !== isInternalProp;
    if (isContentModified) {
      setConfirmDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleActionConfirm = () => {
    setConfirmDialogOpen(false);
    handleSave({ setIsDraft: isNew || isDraftProp });
  };
  const handleCloseConfirm = () => {
    setConfirmDialogOpen(false);
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const editor = quillRef.current?.getEditor().root;
      if (editor) {
        editor.focus();
      }
    }
  };

  // #region Mentions (tagging users/groups)
  const htmlModulesWithMention = {
    ...htmlModules,
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'], // ['@', '#'],
      source: useCallback((searchTerm: any, renderList: any, mentionChar: any) => {
        const values = usersList?.map(({ id, firstName, lastName }) => ({
          id,
          value: `${firstName} ${lastName}`,
        }));

        // Enable when using group mentions with #
        // if (mentionChar === '@') {
        //  values = getUsersList();
        // } else {
        //  values = hashValues;
        // }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              matches.push(values[i]);
            }
          }
          renderList(matches, searchTerm);
        }
      }, []),
    },
  };

  const htmlFormatsWithMention = [...htmlFormats, 'mention'];

  function getSpanDataAttribute(htmlString, dataAttributeName) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const spans = doc.querySelectorAll(`span[data-${dataAttributeName}]`);

    if (spans.length > 0) {
      // @ts-ignore
      const ids = Array.from(spans).map((span) => span.dataset[dataAttributeName]);
      return ids;
    }
    return [];
  }
  // #endregion

  return (
    <>
      <ModalDialog
        open={confirmDialogOpen}
        onClose={() => handleCloseConfirm()}
        title="Save current changes?"
        hasDivider
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Button variant="text" color="base" onClick={() => handleCloseConfirm()}>
            <Typography color="danger.main">Discard</Typography>
          </Button>
          <Button variant="contained" color="primary" onClick={() => handleActionConfirm()}>
            {isDraftProp || isNew ? 'Save draft' : 'Save'}
          </Button>
        </Box>
      </ModalDialog>
      <ModalDialog
        open={open}
        onClose={closeModalDialog}
        title={modalTitle}
        isLoading={isSaving}
        handleAction={() => handleSave({ setIsDraft: false })}
        disableAction={!title.trim() || !text.trim()}
        actionTitle={actionTitle}
        actionIcon={<RiCheckboxCircleFill size="14px" />}
        maxWidth="sm"
        allowOverflow
      >
        <Box className="NoteDialog">
          <TextField
            placeholder="Note title"
            fullWidth
            value={title}
            onKeyDown={handleKeyDown}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />

          <Box sx={{ mb: 3 }}>
            <ReactQuill
              ref={quillRef}
              className="ReactQuill ReactQuill_Editable"
              theme="snow"
              readOnly={isSaving}
              modules={htmlModulesWithMention}
              formats={htmlFormatsWithMention}
              value={text}
              placeholder="Write a note..."
              onChange={(value) => setText(value)}
            />
          </Box>

          {!isNew && (
            <Box>
              {attachments && !isLoadingAttachments ? (
                <Attachments applicationId={applicationId} attachments={attachments} noteId={id} />
              ) : (
                <>
                  <Skeleton sx={{ width: 100, height: 32 }} />
                  <Skeleton sx={{ width: 100, height: 32 }} />
                </>
              )}
            </Box>
          )}

          <Box sx={{ display: 'flex', gap: '24px', pt: '1rem' }} className="FlagsContainer">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSummary}
                  onChange={(e, checked: boolean) => setIsSummary(checked)}
                />
              }
              label="Summary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isInternal}
                  onChange={(e, checked: boolean) => setIsInternal(checked)}
                />
              }
              label="Internal"
            />
          </Box>

          {createdDate && (
            <Typography variant="body1" color="grey.400" mt={4}>
              Created by {createdByName} {formatDate(createdDate)}
            </Typography>
          )}
          {modifiedDate && (
            <Typography variant="body1" color="grey.400" mt={1}>
              Modified by {modifiedByName} {formatDate(modifiedDate)}
            </Typography>
          )}
        </Box>
      </ModalDialog>
    </>
  );
};
