/** npm */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';

/** global modules */
import { PageContainer, PageControl, StickyHeader } from 'uikit';
import {
  useGetGroupsMultiQuery,
  useGetGroupsQuery,
  useGetListUsersQuery,
} from 'services/gatewayApi/usersApi';
import '../ApplicationManagement/ApplicationManagement.scss';
import { RULE_NAME_REGEX, USERNAMEFULL_REGEX } from 'constants/constants';
import { SortParam } from 'types/types';
import 'uikit/SortBlock/SortBlock.scss';

/** this module */
import { initialEditRole } from './constants';
import './UsersManagement.scss';
import { UsersList } from './Users';
import { UsersManagementHead } from './Users/UsersManagementHead';
import { RolesList } from './Roles';
import { TabPanel } from './TabPanel';
import { RoleDialog } from './Roles/RoleDialog';

export const UsersManagement = () => {
  const navigate = useNavigate();
  const { selectedProductId, permissions } = useSelector(makeUserData());

  // TODO: check why these permissions can be undefined.
  const isSuperAdmin = permissions ? permissions.some((el) => el.name === 'SUPER_ADMIN') : false;

  const getInitialRole = () => {
    const { role } = initialEditRole;
    role.tenantId = selectedProductId;
    return { ...initialEditRole, role };
  };

  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'name',
    direction: 'asc',
  });
  const [valueTab, setValueTab] = useState(0);
  const [filterCase, setFilterCase] = useState('');
  const [errorInput, setErrorInput] = useState(false);
  const [editRole, setEditRole] = useState(getInitialRole());
  const [productList, setProductList] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
    setFilterCase('');
    setSortedBy({ property: 'name', direction: 'asc' });
  };
  const {
    data: usersList = [],
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetListUsersQuery(undefined);
  const {
    rolesMulti = [],
    isLoadingRolesMulti,
    isFetchingRolesMulti,
  } = useGetGroupsMultiQuery(
    { undefined },
    {
      skip: !isSuperAdmin,
      selectFromResult: ({ data = [], isLoading, isFetching }) => ({
        // @ts-ignore
        rolesMulti: Object.values(data).flat(),
        isLoadingRolesMulti: isLoading,
        isFetchingRolesMulti: isFetching,
      }),
    },
  );
  const {
    rolesTenant = [],
    isLoadingRolesTenant,
    isFetchingRolesTenant,
  } = useGetGroupsQuery(
    { undefined },
    {
      skip: isSuperAdmin,
      selectFromResult: ({ data = [], isLoading, isFetching }) => ({
        // @ts-ignore
        rolesTenant: Object.values(data).flat(),
        isLoadingRolesTenant: isLoading,
        isFetchingRolesTenant: isFetching,
      }),
    },
  );
  const rolesList = isSuperAdmin ? rolesMulti : rolesTenant;

  const isLoadingRoles = isLoadingRolesMulti || isLoadingRolesTenant;
  const isFetchingRoles = isFetchingRolesMulti || isFetchingRolesTenant;
  const isFetching = isFetchingRoles || isUsersFetching;
  const isLoading = isLoadingRoles || isUsersLoading;

  const searchHandlerUsers = (e) => {
    const inputValue = e;
    if (
      inputValue.match(valueTab === 0 ? USERNAMEFULL_REGEX : RULE_NAME_REGEX) ||
      inputValue === ''
    ) {
      setErrorInput(false);
      return setFilterCase(inputValue);
    }
    return setErrorInput(true);
  };

  const searchFilter = (item) => {
    if (valueTab === 0) {
      return item.name.toLowerCase().search(filterCase.toLowerCase()) > -1;
    } // find users by name
    return (
      item.name.toLowerCase().search(filterCase.toLowerCase()) > -1 || // find roles by name
      productList
        .find((el) => el.id === item.tenantId)
        .name.toLowerCase()
        .search(filterCase.toLowerCase()) > -1 // find roles by product name
    );
  };

  useEffect(() => {
    if (valueTab === 0) {
      const list = usersList.filter(searchFilter).sort((a, b) => {
        if (sortedBy.property === 'lastLoginDate' || sortedBy.property === 'createdDate') {
          // @ts-ignore
          return new Date(a[sortedBy.property] || 0) - new Date(b[sortedBy.property] || 0);
        }
        return a[sortedBy.property] - b[sortedBy.property];
      });
      if (sortedBy.direction === 'desc') {
        return setUsers(list.reverse());
      }
      return setUsers(list);
    }
  }, [isUsersLoading, isUsersFetching, filterCase, sortedBy]);

  useEffect(() => {
    if (valueTab === 1) {
      const list = rolesList.filter(searchFilter).sort((a, b) => {
        if (sortedBy.property === 'modifiedDate') {
          // @ts-ignore
          return new Date(a[sortedBy.property] || 0) - new Date(b[sortedBy.property] || 0);
        }
        return a[sortedBy.property]
          .toString()
          .toLowerCase()
          .localeCompare(b[sortedBy.property].toString().toLowerCase());
      });
      if (sortedBy.direction === 'desc') {
        return setRoles(list.reverse());
      }
      return setRoles(list);
    }
  }, [isLoadingRoles, isFetchingRoles, filterCase, sortedBy]);

  const handleOpenUser = (id) => {
    navigate(`/user-management/${id}`);
  };
  const handleOpenRole = () => {
    setEditRole({
      open: true,
      role: initialEditRole.role,
    });
  };

  const isNoData =
    valueTab === 0
      ? !!(users?.length === 0 && !isLoading && !isFetching)
      : !!(roles?.length === 0 && !isLoading && !isFetching);
  const isNoResults =
    valueTab === 0
      ? !!(users.length === 0 && !isNoData && filterCase !== '')
      : !!(roles.length === 0 && !isNoData && filterCase !== '');

  const handleRoleDialogClose = () => {
    setEditRole(initialEditRole);
  };

  return (
    <>
      <Helmet title="User Management" />
      <PageContainer>
        <StickyHeader>
          <PageControl
            title="User Management"
            primaryActionTitle={valueTab === 0 ? 'New User' : 'New Role'}
            primaryAction={() => (valueTab === 0 ? handleOpenUser('new') : handleOpenRole())}
            searchValue={filterCase}
            searchHandler={searchHandlerUsers}
            hasError={errorInput}
          />
          <UsersManagementHead
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
            valueTab={valueTab}
            handleChangeTab={handleChangeTab}
            TabPanel={TabPanel}
            isFetching={isFetching}
          />
          <Box visibility={isFetching ? 'visible' : 'hidden'}>
            <LinearProgress />
          </Box>
        </StickyHeader>
      </PageContainer>

      <PageContainer>
        <TabPanel value={valueTab} index={0}>
          {isNoResults && valueTab === 0 ? (
            <Tooltip title="Clean search filter">
              <Typography
                sx={{ fontSize: '24px', color: 'grey.600', cursor: 'pointer' }}
                onClick={() => setFilterCase('')}
              >
                Nothing was found for this search
              </Typography>
            </Tooltip>
          ) : (
            <UsersList users={users} isLoading={isLoading} />
          )}
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          {isNoResults && valueTab === 1 ? (
            <Tooltip title="Clean search filter">
              <Typography
                sx={{ fontSize: '24px', color: 'grey.600', cursor: 'pointer' }}
                onClick={() => setFilterCase('')}
              >
                No roles were found for this search
              </Typography>
            </Tooltip>
          ) : (
            <RolesList
              rolesList={roles}
              isLoading={isLoading}
              setIsRoleDialogOpen={setEditRole}
              setProductList={setProductList}
            />
          )}
        </TabPanel>
      </PageContainer>

      {editRole.open && (
        <RoleDialog
          role={editRole.role}
          open={editRole.open}
          onClose={() => handleRoleDialogClose()}
        />
      )}
    </>
  );
};
