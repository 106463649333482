import React, { useEffect, useRef, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { grey } from '../../theme/palette-blocks';

interface EditableTypographyProps {
  value: string | number;
  handleChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textStyle?: { [key: string]: any };
  onSave?: () => void;
  onEsc?: () => void;
  isLocked?: boolean;
  setIsEditCallback?: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
}

export const EditableTypography: React.FC<EditableTypographyProps> = ({
  value,
  textStyle = {},
  handleChangeValue,
  onSave = () => {},
  onEsc = () => {},
  isLocked = false,
  setIsEditCallback,
  placeholder = 'Enter value, please',
  multiline = false,
  rows = 2,
}) => {
  const [isEditTitle, setIsEditTitleLocal] = useState<boolean>(false);

  const setIsEditTitle = (flag: boolean): void => {
    setIsEditTitleLocal(flag);
    setIsEditCallback && setIsEditCallback(flag);
  };

  const titleRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditTitle) {
      titleRef.current?.focus();
    }
  }, [isEditTitle]);

  return (
    <>
      {isEditTitle ? (
        <TextField
          value={value}
          onChange={handleChangeValue}
          onBlur={() => {
            setIsEditTitle(false);
            onSave && onSave();
          }}
          variant="standard"
          fullWidth
          // multiline={multiline}
          // rows={rows}
          inputRef={titleRef}
          InputProps={{
            disableUnderline: true,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                setIsEditTitle(false);
                onSave && onSave();
              }
              if (e.key === 'Escape') {
                setIsEditTitle(false);
                onEsc && onEsc();
              }
            },
            slotProps: {
              input: {
                style: {
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  height: 'auto',
                  ...textStyle,
                },
              },
            },
          }}
        />
      ) : (
        <Typography
          sx={{
            width: '100%',
            transition: 'all 0.3s ease-in-out',
            '&:hover': { cursor: 'pointer', bgcolor: 'grey.50', borderRadius: '4px' },
            color: value ? 'inherit' : grey[300],
            ...textStyle,
          }}
          onClick={() => (!isLocked ? setIsEditTitle(true) : {})}
        >
          {value || placeholder}
        </Typography>
      )}
    </>
  );
};
