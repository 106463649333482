import React from 'react';
import { TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { MessageType } from './Message';
import MessagesPanel from './MessagesPanel';

const MessagesPanelContainerRoot = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    px: 5,
    overflow: 'hidden',
  }),
);

const TabPanelStyled = styled(TabPanel)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    p: 0,
    overflow: 'auto',
  }),
);

interface MessagesPanelContainerProps<T, U> {
  addMessage: (msg: MessageType) => any;
  messagesById: {
    [index: string]: Array<T>;
  };
  users: Array<U>;
  isSendToAll?: boolean;
  setDisabledSendToAll: (disabled: boolean) => void;
}

const MessagesPanelContainer = <T extends MessageType, U extends { id: string }>({
  addMessage,
  messagesById,
  users,
  isSendToAll = false,
  setDisabledSendToAll,
}: MessagesPanelContainerProps<T, U>): JSX.Element => (
  <MessagesPanelContainerRoot>
    {users.map((u) => (
      <TabPanelStyled key={u.id} value={u.id}>
        <MessagesPanel
          setDisabledSendToAll={setDisabledSendToAll}
          addMessage={addMessage}
          messages={messagesById[u.id]}
          isSendToAll={isSendToAll}
        />
      </TabPanelStyled>
    ))}
  </MessagesPanelContainerRoot>
);

export default MessagesPanelContainer;
