import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { OptionButton } from './OptionButton';

interface FilterColumnsSetProps {
  fieldName: string;
  options: { label: string; value: string; count: number }[];
  filterQuery: IFilterValue;
  onChange?: (fieldName: string, selectedFilters: string[]) => void;
}

export const FilterColumnsSet: React.FC<FilterColumnsSetProps> = ({
  fieldName,
  options,
  filterQuery,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => setSelectedOptions(filterQuery[fieldName] ?? []), [filterQuery[fieldName]]);

  const handleSelect = (value: string, isSelected: boolean) => {
    const updatedSelection = isSelected
      ? [...selectedOptions, value]
      : selectedOptions.filter((f) => f !== value);
    setSelectedOptions(updatedSelection);
    if (onChange) {
      onChange(fieldName, updatedSelection);
    }
  };

  return (
    <Stack
      className="FilterColumnsSet"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      sx={{ mt: 4 }}
    >
      {options &&
        options.map((option) => (
          <OptionButton
            key={option.label}
            label={option.label}
            value={option.value}
            count={option.count}
            selected={selectedOptions.includes(option.value)}
            onSelect={handleSelect}
          />
        ))}
    </Stack>
  );
};
