import { connect } from 'react-redux';
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React from 'react';
import { DataTable } from '../../../components';
import { CardDataItem } from './cardDataItem';
import { updateCardData } from '../../../actions/ValidatorTransactions/CardData/cardData';

export class CardDataList extends React.Component {
  renderChildren() {
    const { validatorTransactionReference, updateCard } = this.props;
    if (this.props.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="14">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }
    if (!this.props.cardData || this.props.cardData.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="13">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return this.props.cardData.map((item, index) => (
      <CardDataItem
        key={index}
        item={item}
        validatorTransactionReference={validatorTransactionReference}
        updateCard={updateCard}
      />
    ));
  }

  render() {
    return (
      <div>
        <DataTable>
          <thead>
            <tr>
              <th>Masked Card Number</th>
              <th>Card Action</th>
              <th className="td-small" />
            </tr>
          </thead>
          {this.renderChildren()}
        </DataTable>
      </div>
    );
  }
}

CardDataList.propTypes = {
  cardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  updateCard: PropTypes.func,
  validatorTransactionReference: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  updateCard: (model, id, ref) => dispatch(updateCardData(model, id, ref)),
});

export default connect(null, mapDispatchToProps)(CardDataList);
