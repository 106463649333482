import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import { HeadLink } from '../HeadLink';
import { HeadAccordionProps } from './HeadAccordionProps';

export const HeadAccordion: React.FC<HeadAccordionProps> = ({
  title,
  description,
  actions,
  isOpen = false,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const toggleControls = (
    <IconButton onClick={() => setOpen((prev) => !prev)}>
      {open ? <RiArrowUpLine size="16px" /> : <RiArrowDownLine size="16px" />}
    </IconButton>
  );

  return (
    <>
      <HeadLink
        title={title}
        description={description}
        actions={[...actions, toggleControls]}
        hasBorder={!open}
      />
      <Collapse in={open} timeout={100} sx={{ pb: 4 }}>
        {children}
      </Collapse>
    </>
  );
};
