import { SortOptions } from 'types';
import { Client, ClientAccount } from './types';

export const initialClient: Client = {
  name: '',
  clientId: '',
  clientCategories: [],
  id: 'new',
  incorporationDate: null,
};

export const sortOptions: SortOptions[] = [
  { fieldName: 'clientId', label: 'Client ID' },
  { fieldName: 'name', label: 'Client name' },
  { fieldName: 'createdBy', label: 'Created by' },
  { fieldName: 'createdDate', label: 'Created on' },
  { fieldName: 'incorporationDate', label: 'Date of incorporation' },
];

export const infoFields = [
  { value: 'clientId', label: 'Client ID' },
  { value: 'name', label: 'Client name' },
  { value: 'incorporationDate', label: 'Date of incorporation' },
  { value: 'createdBy', label: 'Created by' },
  { value: 'createdDate', label: 'Created on' },
  { value: 'clientCategories', label: 'Category' },
];

export const infoFieldsFinActivity = [
  { value: 'monthlyTurnover', label: 'Monthly turnover through OpenPayd (total value) (EUR)' },
  {
    value: 'monthlyTransactions',
    label: 'Number of transactions per month routed through OpenPayd (volume)',
  },
  { value: 'averageInbound', label: 'Average Inbound (EUR)' },
  { value: 'averageOutbound', label: 'Average Outbound (EUR)' },
  { value: 'maxInbound', label: 'Max Inbound (EUR)' },
  { value: 'maxOutbound', label: 'Max Outbound (EUR)' },
];

export const initialAccount: ClientAccount = {
  id: 'new',
  client: {
    id: '',
  },
  accountType: '',
  accountName: '',
  accountCategories: [],
  ibans: [],
};

export const textFieldProps = {
  type: 'text',
  fullWidth: true,
  variant: 'standard',
  placeholder: 'Not provided',
};

export const initialFinActivity = {
  averageInbound: '',
  averageOutbound: '',
  maxInbound: '',
  maxOutbound: '',
  monthlyTransactions: '',
  monthlyTurnover: '',

  flowFundsReceiptAllowed: [],
  flowFundsPaymentAllowed: [],
  flowFundsReceiptRestricted: [],
  flowFundsPaymentRestricted: [],
};
