import React from 'react';
import './FormRow.scss';

interface FormRowProps {
  children: React.ReactNode;
}

const FormRow: React.FC<FormRowProps> = ({ children }) => <div className="formRow">{children}</div>;

export default FormRow;
