import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { HeadLink } from 'muikit';
import { useGetNotesByFilterQuery } from 'services/gatewayApi/applicationNotesApi';

import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';
import { ApplicationNote, NoteSkeleton } from '../../ApplicationNote';

export const SummaryTab = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { onboardingApplicationId, isLoadingApplication } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const { notes, isLoadingNotes, isFetchingNotes } = useGetNotesByFilterQuery(
    { applicationId, isSummary: true },
    {
      skip: !applicationId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        notes: data,
        isLoadingNotes: isLoading,
        isFetchingNotes: isFetching,
      }),
    },
  );

  const isLoading = isLoadingNotes || isFetchingNotes || isLoadingApplication;

  return (
    <Box className="SummaryTab">
      <HeadLink title="Summary" />

      <Box
        className="NotesListWrapper"
        sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        {isLoading && (
          <>
            <NoteSkeleton />
            <NoteSkeleton />
          </>
        )}
        {notes && notes.map((n) => (
          <ApplicationNote applicationId={applicationId} key={n.id} note={n} />
        ))}

        {!isLoading && notes && notes.length === 0 && (
          <Box mt={4}>
            <Typography variant="h1" color="grey.400">
              Summay notes not found.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
