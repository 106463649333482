const conditionCodes = [
  {
    conditionCode: '00',
    description: 'Normal transaction of this type ',
  },
  {
    conditionCode: '01',
    description: 'Cardholder not present ',
  },

  {
    conditionCode: '02',
    description: 'Card and Cardholder present, PIN entered ',
  },
  {
    conditionCode: '03',
    description: 'Merchant suspicious of transaction or Card ',
  },
  {
    conditionCode: '05',
    description: 'Cardholder present, card- not-present ',
  },
  {
    conditionCode: '06',
    description: 'Completion advice (preauthorized request)',
  },
  {
    conditionCode: '08',
    description: 'Mail/phone order',
  },
  {
    conditionCode: '11',
    description: 'Suspected fraud',
  },
  {
    conditionCode: '12',
    description: 'Security',
  },
  {
    conditionCode: '13',
    description: 'Representment ',
  },
  {
    conditionCode: '51',
    description: 'Request for Account Number verification without authorization',
  },
  {
    conditionCode: '59',
    description: 'E-commerce',
  },
  {
    conditionCode: '71',
    description: 'Card present, magnetic stripe cannot be read (key-entered) ',
  },
];

export default conditionCodes;
