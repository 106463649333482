import { gatewayApi } from 'services/gatewayApi';
import { AddWhiteListBody, WhitelistRequest } from '../../features/PepAndSanctions/types';

const pepAndSanctionsWhitelistApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getWhitelist: build.query<WhitelistRequest, undefined>({
      query: () => ({
        url: `pepsanctions/whitelists`,
        method: 'GET',
        params: { size: 2000 }, // size=2000 backend limit
      }),
      providesTags: [{ type: 'PepsanctionsWhitelist', id: `LIST` }],
    }),
    addToWhitelist: build.mutation<any, { body: AddWhiteListBody; applicationId?: string }>({
      query: ({ body, applicationId }) => ({
        url: `pepsanctions/whitelists`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { applicationId, body }) => [
        { type: 'PepsanctionsWhitelist', id: `LIST` },
        {
          type: applicationId ? 'PepsanctionsSession' : 'SessionEntries',
          id: `LIST_${body.searchId}`,
        },
      ],
    }),
    removeFromWhitelist: build.mutation<any, { searchId: string; applicationId?: string }>({
      query: ({ searchId, applicationId }) => ({
        url: `pepsanctions/whitelists/${searchId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { applicationId, searchId }) => [
        { type: 'PepsanctionsWhitelist', id: `LIST` },
        { type: applicationId ? 'PepsanctionsSession' : 'SessionEntries', id: `LIST_${searchId}` },
      ],
    }),
  }),
});

export const { useGetWhitelistQuery, useAddToWhitelistMutation, useRemoveFromWhitelistMutation } =
  pepAndSanctionsWhitelistApi;
