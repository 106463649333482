import React from 'react';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { HeadTableCell, SortBlock } from 'uikit';
import { useGetClientsCategoriesQuery } from 'services/gatewayApi/clientsApi';
import { CategoryRow } from './CategoryRow';
import { Category } from './types';

interface ListProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: React.Dispatch<React.SetStateAction<Category>>;
}

export const ClientCategoriesList: React.FC<ListProps> = ({ setOpen, setCategory }) => {
  const {
    data: categoriesList,
    isLoading,
    isFetching,
  } = useGetClientsCategoriesQuery({ undefined });

  return (
    <>
      <Box visibility={isFetching || isLoading ? 'visible' : 'hidden'} sx={{ py: 1 }}>
        <LinearProgress />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell>
              <SortBlock>Name Category</SortBlock>
            </HeadTableCell>
            <HeadTableCell>
              <SortBlock>Created by</SortBlock>
            </HeadTableCell>
            <HeadTableCell>
              <SortBlock>Created Date</SortBlock>
            </HeadTableCell>
            <HeadTableCell>
              <SortBlock>Updated by</SortBlock>
            </HeadTableCell>
            <HeadTableCell>
              <SortBlock>Updated Date</SortBlock>
            </HeadTableCell>

            <HeadTableCell></HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && categoriesList.length > 0 ? (
            categoriesList?.map((el) => (
              <CategoryRow
                key={el.id}
                category={el}
                isLoading={isLoading || isFetching}
                setOpen={setOpen}
                setCategory={setCategory}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12}>
                {!isLoading && (
                  <Typography variant="body1" align="center" py={6}>
                    No categories
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};
