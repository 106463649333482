import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';
import { theme } from '../../../theme';

interface TagSelectedProps {
  onDelete: (e) => any;
  label: string;
  disabled: boolean;
}

export const TagSelected: React.FC<TagSelectedProps> = ({ label, onDelete, disabled }) => (
  <Box
    id="tag"
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 1,
      transition: theme.transitions.create(['border-color', 'background-color']),
      // cursor: 'pointer',
      borderRadius: 1,
      py: 1,
      px: 2,
    }}
    onClick={disabled ? () => {} : onDelete}
  >
    <Typography component="span" sx={{ color: disabled ? 'grey.300' : 'grey.900' }}>
      {label}
    </Typography>
    <CancelIcon sx={{ color: disabled ? 'grey.300' : 'grey.500', fontSize: 'small' }} />
  </Box>
);
