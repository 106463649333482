import { Pageable, Sort } from 'features/ApplicationManagement/types/Application';
import { gatewayApi } from '../gatewayApi';
import { IndividualKycStatusType } from '../../features/ApplicationManagement/ClientDetails/Tabs/IndividualsTab/constants/kycStatuses';

export interface Individual {
  id: string;
  applicationId: string;
  tenantId: number;
  syncReferenceId: string; // id for synchronization data between forms

  firstName: string;
  middleName?: string;
  lastName: string;

  dateOfBirth?: string;
  nationality?: string;
  placeOfBirth?: string;
  passport?: string;
  address?: string;
  city?: string;
  country?: string;
  postcode?: string;
  email?: string;
  kycStatus?: IndividualKycStatusType;
  kycStatusDescription?: string;
  kycManualStatus?: 'CLEAR' | 'CONSIDER';
  kycManualStatusReason?: string;
  kycApplicantId?: string;

  proofOfIdentityType?: string;
  proofOfIdentityCountry?: string;
  proofOfIdentityFileFront?: string;
  proofOfIdentityFileBack?: string;

  proofOfAddressType?: string;
  proofOfAddressCountry?: string;
  proofOfAddressFileFront?: string;

  affiliations: IndividualAffiliation[];
  properties: {
    SHAREHOLDER_SHARES_PERCENTAGE?: number;
    SHAREHOLDER_TYPE?: string;
    SHAREHOLDER_COMPANY_NAME?: string;
  };

  createdBy?: string;
  createdByName?: string;
  createdDate?: string;

  modifiedBy?: string;
  modifiedByName?: string;
  modifiedDate?: string;
}

interface IndividualAffiliation {
  type: string;
  status?: string;
  activationDate?: string;
}

interface IndividualResult {
  content: Individual[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort[];
  totalElements: number;
  totalPages: number;
}

const applicationIndividualsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getIndividuals: build.query<IndividualResult, any>({
      query: ({ applicationId, ...rest }) => ({
        url: `applications/${applicationId}/individuals`,
        params: { ...rest },
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        {
          type: 'Individuals',
          id: `LIST`,
        },
      ],
    }),
  }),
});

export const { useGetIndividualsQuery } = applicationIndividualsApi;
