import { gatewayApi } from '../gatewayApi';

const responseCodesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getResponseCodes: build.query({
      query: (params) => ({
        url: `response-codes`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'ResponseCodes', id: 'LIST' }],
    }),
    getResponseCode: build.query({
      query: ({ id }) => `response-codes/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'ResponseCode', id }],
    }),
    createResponseCode: build.mutation({
      query: ({ body }) => ({
        url: 'response-codes',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { acquirerId }) => [
        { type: 'ResponseCodes', id: 'LIST' },
        { type: 'Acquirers', id: 'LIST' },
        { type: 'Acquirer', id: acquirerId },
      ],
    }),
    updateResponseCode: build.mutation({
      query: ({ body, id }) => ({
        url: `response-codes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id, acquirerId }) => [
        { type: 'ResponseCodes', id: 'LIST' },
        { type: 'ResponseCode', id },
        { type: 'Acquirers', id: 'LIST' },
        { type: 'Acquirer', id: acquirerId },
      ],
    }),
    deleteResponseCode: build.mutation({
      query: ({ id }) => ({
        url: `response-codes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id, acquirerId }) => [
        { type: 'ResponseCodes', id: 'LIST' },
        { type: 'ResponseCode', id },
        { type: 'Acquirers', id: 'LIST' },
        { type: 'Acquirer', id: acquirerId },
      ],
    }),
  }),
});

export const {
  useGetResponseCodesQuery,
  useGetResponseCodeQuery,
  useCreateResponseCodeMutation,
  useUpdateResponseCodeMutation,
  useDeleteResponseCodeMutation,
} = responseCodesApi;
