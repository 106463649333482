// npm
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';

// global modules
import { SortParam } from 'types/types';
import { useGetAcquirersQuery } from 'services/gatewayApi/acquirersApi';
import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material';
import {
  DetailsDrawer,
  EndListButton,
  HeadTableCell,
  PageContainer,
  PageControl,
  Skeleton,
  StickyHeader,
  SortBlock,
  SortByHeader,
} from 'uikit';
import { NoResultsBox, NoDataBox } from 'muikit';
import { useInfinityScroll } from 'hooks/useInfinityScroll';

// this module
import { AcquirerRow } from './AcquirerRow';
import { AcquirerDialog } from './AcquirerDialog';
import { AcquirerDetails } from './AcquirerDetails';
import { sortOptions } from './constants';

import './Acquirers.scss';

const defaultPageSize = 20;

const Acquirers = () => {
  const navigate = useNavigate();

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string>('');

  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'createdDate',
    direction: 'desc',
  });

  const [pageSize, setPageSize] = useState<number>(defaultPageSize * 2);
  const results = useRef<any>();
  const resultsTotalCount = useRef<number>(0);

  const loader = useRef<HTMLDivElement>();

  const isNoData = useRef<boolean>(false);
  const isNoResults = useRef<boolean>(false);

  // HELPERS
  // Skip multiple queries when typing search field
  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const clearQuery = () => {
    setSearchQuery('');
  };

  // return correct search object (empty if there is no search query)
  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  // HANDLERS
  // Search handler
  const searchHandler = (e) => {
    setSkipRequest(true);
    setOpenDetailsDrawer(false);
    setSearchQuery(e);
    debounceQuery();
  };

  // QUERY
  const { data, isLoading, isFetching } = useGetAcquirersQuery(
    {
      page: 0,
      size: pageSize,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      ...searchObj(),
    },
    {
      skip: skipRequest,
    },
  );

  // received data
  results.current = data?.content || [];
  resultsTotalCount.current = data?.totalElements || 0;
  const resultsLength = results.current.length;

  // check is there any application data
  isNoData.current = resultsLength === 0 && searchQuery.length === 0;

  // check is there any application data match filter
  isNoResults.current = resultsLength === 0 && searchQuery.length !== 0;

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    const searchString = new URLSearchParams(urlParams).get('search');
    setSearchQuery(searchString || '');
  }, []);

  // load next page method
  const nextPage = () => {
    if (!(isLoading || isFetching) && resultsLength !== resultsTotalCount.current) {
      setPageSize((prevPageSize) => {
        const nextPageSize = prevPageSize + defaultPageSize;
        return nextPageSize >= resultsTotalCount.current ? resultsTotalCount.current : nextPageSize;
      });
    }
  };

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  // infinity scroll hook
  useInfinityScroll({ isFetching, isLoading, nextPage, loader });

  // control search, sort, filter from inputs
  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }

    params.append('orderBy', sortedBy.property);
    params.append('direction', sortedBy.direction);

    navigate({ search: params.toString() }, { state: true });
  }, [searchQuery, sortedBy, navigate]);

  return (
    <Box className="Acquirers">
      <Helmet title="Acquirers" />
      <PageContainer>
        <StickyHeader>
          <PageControl
            title="Acquirers"
            primaryActionTitle="New Acquirer"
            primaryAction={() => setShowCreateDialog(true)}
            searchValue={searchQuery}
            searchHandler={searchHandler}
          />

          <SortByHeader
            count={resultsLength || 0}
            totalCount={resultsTotalCount.current}
            sortOptions={sortOptions}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
        </StickyHeader>

        <DetailsDrawer open={openDetailsDrawer}>
          <AcquirerDetails id={selectedItemId} onClose={() => setOpenDetailsDrawer(false)} />
        </DetailsDrawer>

        {/* Loading animation */}
        {isLoading && <Skeleton count={20} height={56} />}

        {!isLoading && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  {sortOptions.map((option) => (
                    <HeadTableCell key={option.fieldName}>
                      <SortBlock
                        property={option.fieldName}
                        sortedBy={sortedBy}
                        handleSort={handleSort}
                      >
                        {option.label}
                      </SortBlock>
                    </HeadTableCell>
                  ))}
                  <HeadTableCell>Response codes</HeadTableCell>
                  <HeadTableCell sx={{ width: '1%' }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {results.current &&
                  results.current.map((item) => (
                    <AcquirerRow
                      key={item.id}
                      item={item}
                      selected={item.id === selectedItemId && openDetailsDrawer}
                      setSelectedItemId={setSelectedItemId}
                      setOpenDetailsDrawer={setOpenDetailsDrawer}
                    />
                  ))}
              </TableBody>
            </Table>

            <EndListButton
              isVisible={resultsLength > 0}
              nextPage={nextPage}
              isFetching={isFetching}
              isEndReached={resultsLength === resultsTotalCount.current}
            />
          </>
        )}

        {!(isLoading || isFetching) && (
          <>
            <NoDataBox
              show={isNoData.current}
              entityTitle="Acquirer"
              onCreate={() => setShowCreateDialog(true)}
            />
            <NoResultsBox show={isNoResults.current} onResetFilters={clearQuery} />
          </>
        )}
      </PageContainer>

      <div ref={loader} />

      {showCreateDialog && (
        <AcquirerDialog open={showCreateDialog} onClose={() => setShowCreateDialog(false)} />
      )}
    </Box>
  );
};

export { Acquirers };
