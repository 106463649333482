import { primary, primaryLight } from '../../palette-blocks';

export const WarningChip = {
  props: {
    color: 'warning',
  },
  style: {
    color: primaryLight.contrastText,
    background: primaryLight.light,
    '.MuiChip-deleteIcon': {
      color: primary.light,
      '&:hover, &:active': {
        color: primary.main,
      },
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: primaryLight.hover,
      },
      '&:active': {
        background: primaryLight.active,
      },
    },
    '.MuiChip-avatar': {
      background: primaryLight.dark,
      color: primaryLight.contrastText,
    },
  },
};

export const WarningChipOutlined = {
  props: {
    color: 'warning',
    variant: 'outlined',
  },
  style: {
    borderColor: primaryLight.dark,
    background: 'white',
    '&.MuiChip-clickable': {
      borderColor: primaryLight.dark,
      '&:hover': {
        background: primaryLight.hover,
        borderColor: primaryLight.dark,
      },
      '&:active': {
        background: primaryLight.active,
        borderColor: primaryLight.dark,
      },
    },
  },
};
