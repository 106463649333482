import { error } from '../../palette-blocks';

export const ErrorChip = {
  props: {
    color: 'error',
  },
  style: {
    color: error.main,
    background: error.light,
    '.MuiChip-deleteIcon': {
      color: error.main,
      '&:hover, &:active': {
        color: error.main,
      },
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: error.hover,
      },
      '&:active': {
        background: error.active,
      },
    },
    '.MuiChip-avatar': {
      background: error.dark,
      color: error.contrastText,
    },
  },
};

export const ErrorChipOutlined = {
  props: {
    color: 'error',
    variant: 'outlined',
  },
  style: {
    borderColor: error.main,
    background: 'white',
    '&.MuiChip-clickable': {
      '&:hover': {
        background: error.hover,
        borderColor: error.main,
      },
      '&:active': {
        background: error.active,
        borderColor: error.main,
      },
    },
  },
};
