import React from 'react';
import { Chip } from '@mui/material';
import { RiCircleFill } from 'react-icons/ri';

import { riskStatuses } from '../../constants';

export const RiskStatusChip = ({ riskStatus }) => {
  const riskStatusItem = riskStatuses.find((i) => i.value === riskStatus);

  return (
    <>
      {riskStatusItem && (
        <Chip
          icon={<RiCircleFill size="14px" />}
          size="small"
          color={riskStatusItem.color as any}
          label={riskStatusItem.label}
          sx={{ borderRadius: '6px', pl: 1 }}
        />
      )}
    </>
  );
};
