import { createSelector } from 'reselect';
import { initialState } from '../reducers/products';

const allProducts = (state) => state.products.products || initialState.products;
const makeProductsData = () => createSelector(allProducts, (state) => state);
const makeProductsLoadingData = () =>
  createSelector(
    (state) => state.products,
    (state) => state.loadingProducts || false,
  );

export { makeProductsData, makeProductsLoadingData };
