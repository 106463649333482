import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Paper,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import MUIDataTable from '../../components/MUIDataTable';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { formatDateScreen } from '../../utils/formatter';
import RuleSelectDialog from './ruleSelectDialog';
import {
  useDeleteRuleVetoMutation,
  useGetAllVetosQuery,
} from '../../services/gatewayApi/ruleVetosApi';
import { ConfirmationDialog, MUIDatatableSkeleton } from '../../components';
import { badgeTheme, vetoTypes } from '../ruleForm/constants';
import { Badge } from '../../uikit/Badge';
import { RuleVetosForm } from '../ruleForm/RuleVetosForm';

export const RuleVetosDataTable = () => {
  const [idToDelete, setIdToDelete] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [ruleId, setRuleId] = useState(null);
  const [selectedAnyRow, setSelectedAnyRow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const productId = parseInt(selectedProductId, 10);

  const {
    data: listVetos = [],
    isLoading,
    isFetching,
  } = useGetAllVetosQuery({ productId, size: 2000 }, { skip: !productId });

  const [deleteRuleVeto] = useDeleteRuleVetoMutation();

  const data = listVetos?.responseFormated;

  const [dialogRuleSelectOpen, setDialogRuleSelectOpen] = useState(false);

  const handleDelete = (arrIdToDelete) => {
    setIdToDelete(arrIdToDelete);
  };

  const confirmDelete = () => {
    idToDelete.forEach((itemId) => {
      deleteRuleVeto(itemId);
    });
    setIdToDelete([]);
  };

  const handleOpen = (ruleIdToEdit) => {
    setIsOpen(true);
    setRuleId(ruleIdToEdit);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const AddButton = () => (
    <Tooltip title="Edit Vetos in selected rule" disableFocusListener>
      <IconButton onClick={() => setDialogRuleSelectOpen(true)} size="large">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      name: 'ruleId',
      label: 'Rule ID',
      options: {
        filter: true,
        sortCompare: (order) => (obj1, obj2) => {
          const val1 = parseInt(obj1.data, 10);
          const val2 = parseInt(obj2.data, 10);
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        },
      },
    },
    {
      name: 'ruleVetoType',
      label: 'Veto Type',
      options: {
        filter: true,
        customBodyRender: (value) => vetoTypes.find((el) => el.value === value)?.label || '',
      },
    },
    {
      name: 'referenceIdentifier',
      label: 'Identifier',
      options: {
        filter: true,
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        filter: true,
        customBodyRender: (value) => formatDateScreen(value),
      },
    },
    {
      name: 'expiryDate',
      label: 'Expiry Date',
      options: {
        filter: true,
        customBodyRender: (value) => formatDateScreen(value),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterType: 'checkbox',
        customBodyRender: (value) => <Badge theme={badgeTheme[value]}>{value}</Badge>,
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRenderLite: (dataIndex) => (
          <Tooltip title="Edit" disableFocusListener>
            <IconButton
              onClick={() => handleOpen(data[dataIndex].ruleId)}
              disabled={selectedAnyRow}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  const CustomToolbarSelect = ({ selectedRows, setSelectedRows }) => {
    const arrIdToDelete = selectedRows.data.map((row) => data[row.dataIndex].id);

    return (
      <IconButton
        style={{
          marginRight: 30,
          padding: 16,
        }}
        onClick={() => {
          handleDelete(arrIdToDelete);
          setSelectedRows([]);
        }}
        size="large"
      >
        <DeleteIcon />
      </IconButton>
    );
  };

  CustomToolbarSelect.propTypes = {
    selectedRows: PropTypes.object,
    setSelectedRows: PropTypes.func,
  };

  const options = {
    elevation: 0,
    draggable: false,
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    selectableRows: 'multiple',
    textLabels: {
      body: {
        noMatch: isLoading ? <LinearProgress /> : 'No matching records found...',
      },
    },
    rowsPerPage,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    onTableChange: (action, state) => {
      setSelectedAnyRow(!!state.selectedRows.data.length);
      if (action === 'changeRowsPerPage') setRowsPerPage(state.rowsPerPage);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />
    ),
    customToolbar: AddButton,
  };

  return (
    <div className="grid simple">
      {dialogRuleSelectOpen && (
        <RuleSelectDialog
          handleOpen={handleOpen}
          open={dialogRuleSelectOpen}
          onClose={() => {
            setDialogRuleSelectOpen(false);
          }}
        />
      )}
      <div className="grid-body p-0">
        {isLoading ? (
          <MUIDatatableSkeleton />
        ) : (
          <Paper>
            <Box visibility={isFetching ? 'visible' : 'hidden'}>
              <LinearProgress />
            </Box>
            <MUIDataTable data={data} columns={columns} options={options} />
          </Paper>
        )}
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            Edit Rule Vetos
            <IconButton onClick={handleClose} size="large" variant="closeDialog">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box my={2}>
              <RuleVetosForm ruleId={ruleId} />
            </Box>
          </DialogContent>
        </Dialog>

        <ConfirmationDialog
          open={idToDelete.length !== 0}
          onClose={() => setIdToDelete('')}
          onCancel={() => setIdToDelete('')}
          onConfirm={confirmDelete}
          title="Delete Rule Veto"
          text="Are you sure you want to delete selected Rule Veto?"
          confirmButtonText="Delete"
          confirmButtonColor="danger"
          cancelButtonText="Cancel"
        />
      </div>
    </div>
  );
};
