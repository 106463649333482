import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import { Button } from '../../components';

export class RuleItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    Modal.setAppElement('#app');
  }

  openModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  renderChildren(rules) {
    if (!rules.length) {
      return null;
    }
    return rules.map((item, index) => <RuleItem key={index} item={item} />);
  }

  render() {
    const { item } = this.props;
    return (
      <div className="rule-item">
        <span className="title">└ Id:</span>
        <span> {item.id} | </span>
        <span className="title">Status:</span>
        <span> {item.status} | </span>
        <span className="title">Name:</span>
        <span> {item.name} | </span>
        <span className={`title ${item.answer}`}> {item.answer} </span>

        <div className="rule-description">
          <span className="title">Description: </span>
          <span>{item.description} |</span>
          <div className="inline-div left-spacing-sm">
            <a onClick={this.openModal} title="Script">
              <i className="fa fa-info-circle" />
            </a>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Script"
        >
          <div className="title">Script:</div>
          <div className="top-spacing">
            <span> {item.script}</span>
          </div>
          <div className="top-spacing">
            <Button
              onClick={this.closeModal}
              buttonText="Close"
              className="btn btn-primary btn-cons "
            />
          </div>
        </Modal>

        {this.renderChildren(item.childRules)}
      </div>
    );
  }
}

RuleItem.propTypes = {
  item: PropTypes.object,
};

export default RuleItem;
