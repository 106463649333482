import React, { Component } from 'react';
import Select from 'react-select';
import toastr from 'toastr';
import ApiService from '../../services/apiService';
import { Button, Input, DataTable, DataTableRow } from '../../components';
import { months } from './constants';

class SeonReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seonReportFilter: {},
      seonReport: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchSeonReport();
  }

  getSeonReportFilterValue = (key) => this.state.seonReportFilter[key] || '';

  getMonthsForSelector = () => months.map((month) => ({ value: month, label: month }));

  fetchSeonReport() {
    this.setState(() => ({ loading: true }));
    ApiService.get('/internal/admin-validator/reports/seon')
      .then((response) => {
        this.setState(() => ({
          seonReport: this.filterByYearMonth(this.sortByYearMonth(response.data)),
          loading: false,
        }));
      })
      .catch((error) => toastr.error(`Error getting Seon report data - ${error}`));
  }

  sortByYearMonth(data) {
    return data.sort(
      (a, b) => b.year - a.year || months.indexOf(b.month) - months.indexOf(a.month),
    );
  }

  filterByYearMonth(data) {
    return data.filter(
      (record) =>
        (!this.state.seonReportFilter.year || record.year === this.state.seonReportFilter.year) &&
        (!this.state.seonReportFilter.month ||
          record.month === this.state.seonReportFilter.month.value),
    );
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.fetchSeonReport();
  };

  handleFormClear = (e) => {
    e.preventDefault();
    this.setState({ seonReportFilter: {} });
    this.fetchSeonReport();
  };

  handleFilterChange = (e, key) => {
    const { seonReportFilter } = this.state;
    seonReportFilter[key] = e || '';
    this.setState(seonReportFilter);
  };

  renderChildren() {
    if (this.state.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="14">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }

    if (!this.state.seonReport || this.state.seonReport.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="13">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return this.state.seonReport.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <tbody key={index}>
        <DataTableRow>
          <td>{item.year}</td>
          <td>{item.month}</td>
          <td>{item.success || item.success === false ? item.success.toString() : '---'}</td>
          <td>{item.transactions}</td>
        </DataTableRow>
      </tbody>
    ));
  }

  render() {
    return (
      <div className="grid-body p-0">
        <div className="searchFilter p-20">
          <form>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="year">Year</label>
                  <Input
                    type="number"
                    name="year"
                    value={this.getSeonReportFilterValue('year')}
                    handleInputChanged={(e) => this.handleFilterChange(e.target.value, 'year')}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="month">Month</label>
                  <Select
                    options={this.getMonthsForSelector()}
                    value={this.getSeonReportFilterValue('month')}
                    isClearable
                    onChange={(e) => this.handleFilterChange(e, 'month')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Button
                  type="submit"
                  onClick={(e) => this.handleFormSubmit(e)}
                  buttonText="Search"
                />
                <Button
                  buttonText="Clear"
                  onClick={(e) => this.handleFormClear(e)}
                  buttonClass="btn btn-default btn-cons"
                />
              </div>
            </div>
          </form>
        </div>
        <DataTable>
          <thead>
            <tr>
              <th>YEAR</th>
              <th>MONTH</th>
              <th>SUCCESS</th>
              <th>TRANSACTIONS</th>
            </tr>
          </thead>
          {this.renderChildren()}
        </DataTable>
      </div>
    );
  }
}

export default SeonReport;
