import React, { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Individual } from 'services/gatewayApi/applicationIndividualsApi';
import { KycStatusChip } from './KycStatusChip';
import { affiliationTypes } from './constants';

import 'styles/tables.scss';

const SmallTableCell = styled(TableCell)(() => ({
  paddingTop: '14px',
  paddingBottom: '14px',
  verticalAlign: 'top',
  borderRadius: '0 !important',
})) as typeof TableCell;

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
};

interface IndividualRowProps {
  individual: Individual;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
}

export const IndividualRow: React.FC<IndividualRowProps> = ({
  individual,
  setSelectedItem,
  setOpenDetailsDrawer,
  selected = false,
}) => {
  const theme = useTheme();

  const [isHover, setIsHover] = useState<boolean>(false);

  const {
    firstName,
    lastName,
    email,
    affiliations,
    kycStatus,
    kycStatusDescription,
    kycManualStatus,
  } = individual;

  const companyName = individual?.properties?.SHAREHOLDER_COMPANY_NAME || undefined;
  const individualName = companyName ?? `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <TableRow
      selected={selected}
      className="table-row"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{ cursor: 'auto' }}
    >
      <SmallTableCell
        sx={{ ...ellipsisStyle, width: '200px', maxWidth: '200px', pl: '10px !important' }}
      >
        <Typography variant="body" color={theme.palette.text.primary} sx={ellipsisStyle}>
          {individualName}
        </Typography>
      </SmallTableCell>
      <SmallTableCell>
        <Typography variant="body" color={theme.palette.text.primary}>
          {email}
        </Typography>
      </SmallTableCell>
      <SmallTableCell>
        <Typography variant="body" color={theme.palette.text.primary}>
          {affiliations &&
            affiliations
              .map(
                (a) => affiliationTypes?.find((at) => at.value === a.type)?.label ?? 'Unknown Type',
              )
              .join(', ')}
        </Typography>
      </SmallTableCell>
      <SmallTableCell>
        <KycStatusChip status={kycManualStatus ?? kycStatus} description={kycStatusDescription} />
      </SmallTableCell>

      {/* Row action buttons */}
      <SmallTableCell sx={{ width: '1%', py: '10px' }}>
        <IconButton
          onClick={() => {
            setSelectedItem(individual.id);
            setOpenDetailsDrawer(true);
          }}
          sx={{ ml: 2, opacity: isHover ? 1 : 0, color: 'contrast.main' }}
        >
          <RiArrowRightSLine size="14px" />
        </IconButton>
      </SmallTableCell>
    </TableRow>
  );
};
