import PropTypes from 'prop-types';
import React from 'react';
import Pagination from 'react-js-pagination';

const Paging = (props) => {
  const { activePage, currentPageSize, totalItemsCount, pageRangeDisplayed, handlePageChange } =
    props;
  const to = activePage * currentPageSize;
  const from = to + 1 - currentPageSize;

  return (
    <div className="paginator-footer">
      <Pagination
        activePage={activePage}
        itemsCountPerPage={currentPageSize}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed || 5}
        onChange={handlePageChange}
      />
      <div className="items-info">
        {from} - {to > totalItemsCount ? totalItemsCount : to} of {totalItemsCount}
      </div>
    </div>
  );
};

Paging.propTypes = {
  activePage: PropTypes.number,
  currentPageSize: PropTypes.number,
  totalItemsCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  handlePageChange: PropTypes.func,
};

export default Paging;
