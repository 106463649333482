import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import { ModalDialog } from 'uikit';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import {
  useCreateResponseCodeMutation,
  useUpdateResponseCodeMutation,
} from 'services/gatewayApi/responseCodesApi';
import { ResponseCode } from '../../types';
import { initialResponseCode } from '../../constants';

interface ResponseCodeDialogProps {
  open: boolean;
  onClose: any;
  responseCode?: ResponseCode;
  acquirerId?: string;
}

export const ResponseCodeDialog: React.FC<ResponseCodeDialogProps> = ({
  open,
  onClose,
  responseCode = initialResponseCode,
  acquirerId,
}) => {
  const isNew = responseCode.id === 'new';
  const entityName = 'Response Code';
  const title = `${isNew ? 'New' : 'Edit'} ${entityName}`;
  const actionTitle = isNew ? 'Create' : 'Save';

  const [createResponseCode] = useCreateResponseCodeMutation();
  const [updateResponseCode] = useUpdateResponseCodeMutation();

  const validationSchema = yup.object({
    responseCode: yup.string().required('Required'),
    responseMessage: yup.string().required('Required'),
    score: yup
      .number()
      .required('Required')
      .min(0, 'Please enter a score higher than ${min}')
      .max(100, 'Please enter a score lower than ${max}'),
  });

  const initialValues = { ...responseCode };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const mutationPromise = isNew
        ? await createResponseCode({ body: { ...values, acquirerId } })
        : await updateResponseCode({ body: values, id: responseCode.id });

      // @ts-ignore
      if (!mutationPromise.error) {
        isNew ? toastCreateSuccess(entityName) : toastUpdateSuccess(entityName);

        resetForm();
        onClose();
      }
    },
  });

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      handleAction={() => formik.handleSubmit()}
      actionTitle={actionTitle}
      isLoading={formik.isSubmitting}
    >
      <Stack spacing={4}>
        <TextField
          type="text"
          fullWidth
          label="Code"
          name="responseCode"
          onChange={formik.handleChange}
          value={formik.values.responseCode}
          variant="standard"
          error={formik.touched.responseCode && Boolean(formik.errors.responseCode)}
          helperText={formik.touched.responseCode && formik.errors.responseCode}
          disabled={!isNew || formik.isSubmitting}
        />

        <TextField
          type="text"
          multiline
          fullWidth
          rows={4}
          label="Message"
          name="responseMessage"
          onChange={formik.handleChange}
          value={formik.values.responseMessage}
          variant="standard"
          error={formik.touched.responseMessage && Boolean(formik.errors.responseMessage)}
          helperText={formik.touched.responseMessage && formik.errors.responseMessage}
          disabled={formik.isSubmitting}
        />

        <TextField
          type="number"
          fullWidth
          label="Score"
          name="score"
          onChange={formik.handleChange}
          value={formik.values.score}
          variant="standard"
          error={formik.touched.score && Boolean(formik.errors.score)}
          helperText={formik.touched.score && formik.errors.score}
          disabled={formik.isSubmitting}
        />
      </Stack>
    </ModalDialog>
  );
};
