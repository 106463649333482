import {
  LOAD_RULESET_STATS,
  LOAD_RULESET_STATS_SUCCESS,
  LOAD_RULESET_STATS_FAIL,
  CHANGE_RULESET_STATS_DATE_RANGE,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingListError } from '../utils/toast';

const entityTitle = 'Ruleset Stat';

export const fetchRuleSetStats =
  ({ startDate, endDate }) =>
  (dispatch) => {
    const postData = {
      startDate,
      endDate,
    };
    dispatch({ type: LOAD_RULESET_STATS });
    ApiService.post('/internal/rules/ruleset-stats', postData)
      .then((response) => dispatch(fetchRuleSetStatsSuccess(response.data)))
      .catch((error) => dispatch(fetchRuleSetStatsError(error.message)));
  };

const fetchRuleSetStatsSuccess = (stats) => ({
  type: LOAD_RULESET_STATS_SUCCESS,
  stats,
});

const fetchRuleSetStatsError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_RULESET_STATS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

export const changeRuleSetStatsDateRange =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch({
      type: CHANGE_RULESET_STATS_DATE_RANGE,
      dateRange: {
        startDate,
        endDate,
      },
    });
  };
