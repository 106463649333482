import React, { useState } from 'react';
import { IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { Menu } from 'muikit/Menu';
import { ModalDialog } from 'uikit';
import { RiCheckboxCircleFill, RiDeleteBinFill, RiMore2Fill } from 'react-icons/ri';
import {
  useGetAppFormSectionsQuery,
  useDeleteAppFormSectionMutation,
  useUpdateAppFormSectionStatusMutation,
} from 'services/gatewayApi/applicationFormSectionsApi';
import {
  useGetApplicationQuery,
  useSendSectionToSignMutation,
} from 'services/gatewayApi/applicationApi';
import { toastError, toastSuccess } from 'utils';
import { adhocSections } from '../AddFormsMenu/AddFormsMenu';

interface SectionMenuProps {
  applicationId: string;
  sectionId: number;
  partition: string;
}

export const SectionMenu = ({
  applicationId,
  sectionId,
  partition,
}: SectionMenuProps): JSX.Element => {
  const { data: applicationDetails, isLoading } = useGetApplicationQuery(applicationId);
  const mainStatus = !isLoading && applicationDetails?.mainStatus;

  const [updateFormSectionStatus] = useUpdateAppFormSectionStatusMutation();
  const [deleteSection, { isLoading: isDeletingSection }] = useDeleteAppFormSectionMutation();

  const isAdhocSection = adhocSections.some((adhocSection) => adhocSection.id === sectionId);

  const { data: formSections, isLoading: isLoadingSections } = useGetAppFormSectionsQuery({
    applicationId,
  });
  const currentSection = formSections?.find(
    (s) => s.sectionId === sectionId && s.partition === partition,
  );

  const [openConfirmDeleteCI, setOpenConfirmDeleteCI] = useState<boolean>(false);
  const deleteCIAndAllRelatedSections = () =>
    Promise.all([
      deleteSection({ applicationId, sectionId: 30, partition: 'default' }).unwrap(),
      deleteSection({ applicationId, sectionId: 50, partition: 'default' }).unwrap(),
      deleteSection({ applicationId, sectionId: 350, partition: 'default' }).unwrap(),
      deleteSection({ applicationId, sectionId: 360, partition: 'default' }).unwrap(),
    ])
      .then(() =>
        deleteSection({ applicationId, sectionId: 1, partition: 'default' })
          .unwrap()
          .then(() => toastSuccess('All sections removed successfully'))
          .catch((e) => toastError('An error occurred while deleting CI section')),
      )
      .catch((e) => toastError('An error occurred while deleting dependant sections'));

  // #region Mark section as Verified
  const isSectionDone = !isLoadingSections && currentSection?.status === 'DONE';
  const isSectionInternal = !isLoadingSections && currentSection?.sectionDetails?.isInternal;
  const canMarkAsVerified = isSectionDone && !isSectionInternal; // + check: Only compliance team should be able to verify
  // #endregion

  // #region Send/Resend section for Signature
  const [sendSectionToSign] = useSendSectionToSignMutation();
  const isSectionPendingSignature =
    !isLoadingSections && currentSection?.status === 'PENDING_SIGNATURE';
  const isSectionVerified = !isLoadingSections && currentSection?.status === 'VERIFIED';
  const canSendToSignature =
    ['PENDING_FORMS_SIGNATURE', 'PENDING_ONBOARDING_COMPLIANCE_FIRST'].includes(mainStatus) &&
    (isSectionVerified || isSectionPendingSignature);
  // #endregion

  // #region Set section as Pending
  const canSetAsPending =
    currentSection?.status === 'PENDING_SIGNATURE' &&
    mainStatus === 'PENDING_ONBOARDING_COMPLIANCE_FIRST';
  // #endregion

  const isCustomerInformation = currentSection?.sectionId === 1;
  const isAppOpenOrPendingOnCustomer = [
    'PENDING_ON_CUSTOMER',
    'PENDING_ON_CUSTOMER_SECOND',
    'OPEN',
  ].includes(mainStatus);

  return isAdhocSection ||
    canMarkAsVerified ||
    canSendToSignature ||
    canSetAsPending ||
    /* temporary fix to be able to delete CI section */
    (isCustomerInformation && isAppOpenOrPendingOnCustomer) ? (
    <>
      <Menu
        renderOpenButton={(props) => (
          <IconButton
            {...props}
            color="inherit"
            disabled={isDeletingSection}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onClick(e);
            }}
            sx={{ width: '32px', height: '32px', lineHeight: '32px' }}
          >
            <RiMore2Fill size="0.875rem" />
          </IconButton>
        )}
        renderItems={(props) => [
          canMarkAsVerified && (
            <MenuItem
              key="menuVerify"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                updateFormSectionStatus({
                  applicationId,
                  sectionId,
                  partition,
                  status: 'VERIFIED',
                });

                // eslint-disable-next-line react/prop-types
                props.onClick && props.onClick(e);
              }}
            >
              <ListItemIcon>
                <RiCheckboxCircleFill fontSize="14" />
              </ListItemIcon>
              Mark as verified
            </MenuItem>
          ),

          canSendToSignature && (
            <MenuItem
              key="menuSendSectionToSign"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                sendSectionToSign({ applicationId, sectionId, partition });
                // eslint-disable-next-line react/prop-types
                props.onClick && props.onClick(e);
              }}
            >
              {isSectionPendingSignature ? 'Resend' : 'Send'} for Signature
            </MenuItem>
          ),

          canSetAsPending && (
            <MenuItem
              key="menuSetAsPending"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                updateFormSectionStatus({ applicationId, sectionId, partition, status: 'PENDING' });

                // eslint-disable-next-line react/prop-types
                props.onClick && props.onClick(e);
              }}
            >
              Mark as Pending
            </MenuItem>
          ),

          isAdhocSection && (
            <MenuItem
              key="menuHideFromCustomer"
              className="redMenuItem"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                deleteSection({ applicationId, sectionId, partition });
                // eslint-disable-next-line react/prop-types
                props.onClick && props.onClick(e);
              }}
            >
              <ListItemIcon>
                <RiDeleteBinFill fontSize="14" />
              </ListItemIcon>
              Delete form
            </MenuItem>
          ),

          /* temporary fix to be able to delete CI section */
          isCustomerInformation && isAppOpenOrPendingOnCustomer && (
            <MenuItem
              key="menuDeleteCustomerInfoForms"
              className="redMenuItem"
              onClick={(e) => {
                props.onClick && props.onClick(e);
                e.preventDefault();
                e.stopPropagation();
                setOpenConfirmDeleteCI(true);
              }}
            >
              <ListItemIcon>
                <RiDeleteBinFill fontSize="14" />
              </ListItemIcon>
              Delete from the application
            </MenuItem>
          ),
        ]}
      />
      {openConfirmDeleteCI && (
        <ModalDialog
          open={openConfirmDeleteCI}
          onClose={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenConfirmDeleteCI(false);
          }}
          title="Are you sure you want to delete Customer Information form?"
          handleAction={() => {
            deleteCIAndAllRelatedSections();
            setOpenConfirmDeleteCI(false);
          }}
          actionTitle="Delete"
          variant="error"
        >
          <Typography pb={2}>Crypto, RMSB, CFD forms will be deleted too</Typography>
        </ModalDialog>
      )}
    </>
  ) : (
    <></>
  );
};
