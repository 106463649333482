import { alpha } from '@mui/material/styles';
import { primary, contrast, primaryLight } from '../../palette-blocks';
import { buttonFocus } from '../mixins';

export const Primary = {
  props: {
    color: 'primary',
  },
  style: {
    '&:hover': {
      background: primary.hover,
      color: primary.contrastText,
    },
    '&:active': {
      background: primary.active,
      color: primary.contrastText,
    },
    '&:disabled': {
      background: alpha(primary.main, 0.5),
      color: primary.contrastText,
      '.MuiLoadingButton-loadingIndicator': {
        color: primary.contrastText,
      },
    },
    '&:focus&:not(:active)': buttonFocus(primary.main),
  },
};

export const PrimaryLarge = {
  props: {
    color: 'primary',
    size: 'large',
  },
  style: {
    '&:hover': {
      background: contrast.main,
      color: primary.contrastText,
    },
    '&:active': {
      background: contrast['bg-200'],
      color: primary.contrastText,
    },
    '&:disabled': {
      background: alpha(primary.main, 0.5),
      color: primary.contrastText,
      '.MuiLoadingButton-loadingIndicator': {
        color: primary.contrastText,
      },
    },
    '&:focus&:not(:active)': buttonFocus(contrast.main),
  },
};

export const PrimaryText = {
  props: {
    color: 'primary',
    variant: 'text',
  },
  style: {
    '&:hover': {
      background: primaryLight.main,
      color: primary.main,
    },
    '&:active': {
      background: primaryLight['bg-200'],
      color: primary.main,
    },
    '&:disabled': {
      background: 'transparent',
      color: alpha(primary.main, 0.5),
    },
  },
};

export const PrimaryOutline = {
  props: {
    color: 'primary',
    variant: 'outlined',
  },
  style: {
    borderColor: primaryLight['bg-300'],
    color: primary.main,
    '&:hover': {
      background: primaryLight.main,
      color: primary.main,
      borderColor: primaryLight['bg-300'],
    },
    '&:active': {
      background: primaryLight['bg-200'],
      color: primary.main,
      borderColor: primaryLight['bg-300'],
    },
    '&:disabled': {
      borderColor: alpha(primaryLight['bg-300'], 0.5),
      background: 'transparent',
      color: alpha(primary.main, 0.5),
    },
  },
};
