import { baseTheme } from '../baseTheme';

export const IconButton = {
  styleOverrides: {
    root: {
      borderRadius: baseTheme.shape.borderRadius,
      fontSize: baseTheme.typography.pxToRem(20),
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        padding: 4,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        padding: 6,
      },
    },
    {
      props: { size: 'large' },
      style: {
        padding: 10,
      },
    },
    {
      props: { variant: 'closeDialog' },
      style: {
        position: 'absolute',
        right: 8,
        top: 8,
      },
    },
  ],
};
