import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as _ from 'lodash';
import { Button } from '../../../components';
import { closeAssignmentsForm, createCaseAssignment } from '../../../actions/caseAssignments';
import CaseCategorySelector from './caseCategorySelector';

const Error = (props) => {
  const { field, show, validation } = props;
  if (show) {
    return <span className="error">{validation[field].message}</span>;
  }
  return null;
};

Error.propTypes = {
  field: PropTypes.string,
  show: PropTypes.bool,
  validation: PropTypes.object,
};

class CaseAssignmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  getValidation() {
    const validation = {
      caseCategory: {
        required: true,
        message: 'Case Category is required',
      },
      userId: {
        required: true,
        message: 'User is required',
      },
    };
    return validation;
  }

  getInitState() {
    return {
      caseCategory: null,
      userId: null,
      showErrors: false,
      errors: {
        caseCategory: true,
        userId: true,
      },
    };
  }

  getItems(items) {
    return items.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    if (_.isEmpty(_.pickBy(this.state.errors, (i) => i))) {
      const { users } = this.props;
      const user = users.find((item) => item.id === this.state.userId);
      const model = {
        caseCategory: this.state.caseCategory,
        userId: this.state.userId,
        username: user.email,
      };
      this.props.createAssignment(model);
    } else {
      this.setState({
        showErrors: true,
      });
    }
  }

  handleInputChange(e, field) {
    // const target = e.target;
    this.setState({ [field]: e.value }, () => this.isFieldValid(field));
  }

  isFieldValid(field) {
    let error = false;
    const validation = this.getValidation();

    if (validation[field].required && !this.state[field]) {
      error = true;
    }
    const { errors } = this.state;
    errors[field] = error;
    this.setState(() => ({ errors }));
  }

  render() {
    const { showErrors, errors } = this.state;
    return (
      <div className="row">
        <form>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="caseCategory">Case Category</label>
              <div className="controls">
                <CaseCategorySelector onChange={this.handleInputChange} />
              </div>
              <Error
                field="caseCategory"
                show={showErrors && errors.caseCategory}
                validation={this.getValidation()}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="userId">User</label>
              <div className="controls">
                <Select
                  name="userId"
                  options={this.getItems(this.props.users)}
                  isClearable
                  onChange={(e) => {
                    this.handleInputChange(e, 'userId');
                  }}
                />
              </div>
              <Error
                field="userId"
                show={showErrors && errors.userId}
                validation={this.getValidation()}
              />
            </div>
          </div>
          <div className="col-md-12">
            <Button
              buttonText="Submit"
              onClick={(e) => this.handleSubmit(e)}
              buttonClass="btn btn-primary btn-cons"
            />
            <Button
              buttonText="Cancel"
              onClick={() => this.props.closeAssignmentsForm()}
              buttonClass="btn btn-default btn-cons"
            />
          </div>
        </form>
      </div>
    );
  }
}

CaseAssignmentForm.propTypes = {
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  closeAssignmentsForm: PropTypes.func,
  createAssignment: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  createAssignment: (model) => dispatch(createCaseAssignment(model)),
  closeAssignmentsForm: () => dispatch(closeAssignmentsForm()),
});

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, mapDispatchToProps)(CaseAssignmentForm);
