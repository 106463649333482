/**
 *
 * Button
 *
 */

import PropTypes from 'prop-types';

import React from 'react';

import Spinner from 'components/Spinner';

const Button = (props) => {
  const { buttonType, buttonClass, buttonText, iconClass, onClick, disabled, loading } = props;

  return (
    <button
      type={buttonType || 'text'}
      onClick={onClick}
      className={buttonClass}
      disabled={disabled}
    >
      {iconClass.length > 0 ? <i className={iconClass} /> : ''}
      {loading ? <Spinner color="white" /> : buttonText}
    </button>
  );
};

Button.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  buttonClass: 'btn btn-primary btn-cons',
  buttonText: 'Button',
  iconClass: '',
  disabled: false,
};

export default Button;
