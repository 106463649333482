import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { TabTitle } from './TabTitle';
import { TabContainerProps } from './TabContainer.props';

import './TabContainer.scss';

const TabContainer: React.FC<TabContainerProps> = ({
  title,
  direction = 'horizontal',
  activeIndex = 0,
  hasPrevNext = false,
  hasSubmit = false,
  hasSubmitUnderTabTitles = false,
  isSubmitting = false,
  onSubmit,
  children,
  renderAllChildren = false,
  additionalInfo,
  hideAdditionalInfoOnLastTab = false,
}) => {
  const [index, setIndex] = useState(activeIndex);
  const childrenFiltered = React.Children.toArray(children).filter(Boolean);

  const handleMoveNextOrSubmit = () => {
    if (index < React.Children.count(childrenFiltered) - 1) {
      setIndex(index + 1);
    } else if (onSubmit) {
      onSubmit({}); // TODO: pass data here?
    }
  };

  const isFinalstep = index === React.Children.count(childrenFiltered) - 1;

  const renderSubmit = (fullWidth: boolean = false) => (
    <Box sx={{ mt: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleMoveNextOrSubmit}
        fullWidth={fullWidth}
        startIcon={
          isSubmitting ? <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} /> : null
        }
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </Box>
  );

  return (
    <div
      className={`
        TabContainer
        TabContainer--${direction}
      `}
    >
      <div className="TabTitles">
        {title && <h2>{title}</h2>}

        <div>
          {childrenFiltered &&
            React.Children.map(childrenFiltered, (c, i) => (
              <TabTitle
                key={`TabTitle_${i}`}
                className={i >= index ? 'TabTitle--notVisited' : ''}
                isActive={i === index}
                onClick={() => setIndex(i)}
                direction={direction}
                // @ts-ignore
                {...c.props}
              />
            ))}
        </div>

        {additionalInfo &&
          (!hideAdditionalInfoOnLastTab || (hideAdditionalInfoOnLastTab && !isFinalstep)) && (
            <div className="AdditionalInfo">{additionalInfo}</div>
          )}

        {hasSubmit && hasSubmitUnderTabTitles && renderSubmit(true)}
      </div>
      <div className="Tabs">
        {childrenFiltered && (
          <>
            {!renderAllChildren &&
              React.Children.map(childrenFiltered, (c, i) => (i === index ? c : <></>))}

            {renderAllChildren &&
              React.Children.map(childrenFiltered, (c, i) => (
                <div style={{ display: i === index ? 'block' : 'none' }}>{c}</div>
              ))}

            {((hasPrevNext && hasSubmit) || (hasSubmit && isFinalstep)) && (
              <div className="TabsNavigation">
                {!isFinalstep && (
                  <Button variant="contained" color="primary" onClick={handleMoveNextOrSubmit}>
                    Next
                  </Button>
                )}

                {isFinalstep && hasSubmit && !hasSubmitUnderTabTitles && renderSubmit()}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { TabContainer };
