import React from 'react';
import {
  ApplicationNoteDocument,
} from 'services/gatewayApi/applicationNoteDocumentsApi';
import { Attachment } from './Attachment';

interface AttachmentsListProps {
  applicationId: string;
  noteId: string;
  attachments: ApplicationNoteDocument[];
}

export const Attachments: React.FC<AttachmentsListProps> = ({
  applicationId,
  noteId,
  attachments,
}) => (
  <>
    {attachments && attachments
      .slice()
      .filter((a) => a.fileName)
      .sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1))
      .map((doc) => (
        <Attachment key={doc.id} applicationId={applicationId} doc={doc} />
      ))}

    { /* Placeholder to upload new attachment */}
    <Attachment key="new" applicationId={applicationId} doc={{ id: 'new', noteId }} />
  </>
);
