import { SortOptions } from 'types';

export const tableSortOptions: SortOptions[] = [
  { fieldName: 'haystackClient_companyName', label: 'Legal Name' },
  { fieldName: 'haystackClient_industryType', label: 'Business Type' },
  { fieldName: 'haystackClient_countryOfIncorporation', label: 'Country of Incorporation' },
  { fieldName: 'categoryId', label: 'Category' },
  { fieldName: 'riskStatus', label: 'Risk' },
];

export const sortOptions: SortOptions[] = [
  ...tableSortOptions,
  { fieldName: 'createdDate', label: 'Created Date' },
];
