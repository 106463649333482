import { ISelectOption } from 'uikit/Select';

export const sortOptions: ISelectOption[] = [
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'email', label: 'Email' },
  { value: 'residenceCountryCode', label: 'Residence Country' },
  { value: 'provider', label: 'Provider' },
  { value: 'kycStatus', label: 'KYC Status' },
  { value: 'kycResult', label: 'KYC Result' },
];
