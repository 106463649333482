import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { HeadTableCell, SortBlock } from 'uikit';
import { sortOptions, sortOptionsTransactions } from '../constants';
import { SessionRow } from './SessionRow';
import { SearchSessionsListRow, TransformedSession } from '../types';

interface SearchSessionsListProps {
  searchSessions: SearchSessionsListRow[] | TransformedSession[];
  isTransactions?: boolean;
}

export const SearchSessionsList: React.FC<SearchSessionsListProps> = ({
  searchSessions = [],
  isTransactions = false,
}) => {
  const options = isTransactions ? sortOptionsTransactions : sortOptions;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {options.map((option) => (
            <HeadTableCell key={option.fieldName} sx={{ pl: 1, borderBottom: 'none' }}>
              <SortBlock property={option.fieldName}>{option.label}</SortBlock>
            </HeadTableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!!searchSessions &&
          searchSessions.map((session) => (
            <SessionRow key={session.id} session={session} isTransactions={isTransactions} />
          ))}
      </TableBody>
    </Table>
  );
};
