import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Autocomplete } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { useFormik } from 'formik';
import { wordTypes } from './constants';

const RiskWordsSearchForm = (props) => {
  const { setFilterValues } = props;
  const initialValues = {
    word: '',
    type: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFilterValues(values);
      formik.setSubmitting(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialValues);
  };

  return (
    <div className="grid simple horizontal yellow">
      <div className="grid-title">
        <h4>
          <span>Filter Data</span>
        </h4>
      </div>
      <div className="grid-body">
        <form id="filterForm" onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id="word"
                name="word"
                label="Word"
                fullWidth
                size="small"
                value={formik.values.word}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                id="type"
                fullWidth
                size="small"
                options={wordTypes}
                value={
                  formik.values.type
                    ? wordTypes.find((item) => item?.value === formik.values.type)
                    : null
                }
                onChange={(event, option) => {
                  formik.setFieldValue('type', option ? option.value : '');
                }}
                autoHighlight
                getOptionLabel={(option) => option?.label || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="contained"
                color="primary"
                form="filterForm"
                type="submit"
                disabled={formik.isSubmitting}
                sx={{ mr: 2 }}
              >
                <SearchIcon sx={{ fontSize: 20, mr: 1 }} />
                Search
              </Button>
              <Button color="error" variant="text" onClick={handleReset}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

RiskWordsSearchForm.propTypes = {
  setFilterValues: PropTypes.func,
};

export default RiskWordsSearchForm;
