import { removeEmptyProperties } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { gatewayApi } from '../gatewayApi';

const exceptionRulesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getExceptionRules: build.query({
      query: (params) => ({
        url: `exception-rules`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'ExceptionRules', id: 'LIST' }],
      transformResponse: (response) => ({
        results: response.content.map((exceptionRule) => {
          const result = { ...exceptionRule };
          result.createdDateTime = formatDate(result.createdDateTime);
          return result;
        }),
        paginationInfo: {
          activePage: response.number,
          currentPageSize: response.size,
          totalItemsCount: response.totalElements,
        },
      }),
    }),
    addExceptionRule: build.mutation({
      query(model) {
        const body = { ...model };
        removeEmptyProperties(body);
        const { merchantId } = body;
        if (merchantId) {
          delete body.merchantId;
          delete body.merchantName;
          return {
            url: `exception-rules/merchant/${merchantId}`,
            method: 'POST',
            body,
          };
        }
        return {
          url: 'exception-rules',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'ExceptionRules', id: 'LIST' }],
    }),
    updateExceptionRule: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `exception-rules/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'ExceptionRules', id: 'LIST' }],
    }),
    deleteExceptionRule: build.mutation({
      query: (id) => ({
        url: `exception-rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'ExceptionRules', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetExceptionRulesQuery,
  useAddExceptionRuleMutation,
  useUpdateExceptionRuleMutation,
  useDeleteExceptionRuleMutation,
} = exceptionRulesApi;
