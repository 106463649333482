/* eslint-disable no-param-reassign */
import { Node } from './Node';

export class UnaryNode extends Node {
  constructor(op, node) {
    if (!(node instanceof Node)) {
      throw new Error('invalid node passed');
    }
    super();
    this.op = op;
    this.node = node;
    node.parent = this;
  }

  compute(ctx) {
    const v = this.node.compute(ctx);
    switch (this.op) {
      case 'NOT':
        return !v;
    }
    throw new Error(`operator not implemented '${this.op}'`);
  }

  toString() {
    return `( ${this.op} ${this.node.toString()} )`;
  }

  toDDFConditionNode() {
    return { [this.op]: [this.node.toDDFConditionNode()] };
  }
}

UnaryNode.operators = ['NOT'];
