import React from 'react';
import { Box } from '@mui/material';
import { ArrowBack, Skeleton, TabContainer, Tab } from 'uikit';
import { useGetUboQuery } from 'services/gatewayApi/ubosApi';
import { DetailsTitle } from './DetailsTitle';
import { DetailsTab } from './DetailsTab';
import { LinksTab } from './LinksTab';
import { HistoryTab } from './HistoryTab';

export const UboDetails = ({ id, onClose }) => {
  const { data: ubo, isLoading } = useGetUboQuery({ id }, { skip: !id });

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && ubo && (
        <>
          <DetailsTitle ubo={ubo} onClose={onClose} />

          <TabContainer>
            <Tab key="detailsTab" title="Details">
              <DetailsTab ubo={ubo} />
            </Tab>
            <Tab key="linksTab" title="Linked Applications">
              <LinksTab ubo={ubo} />
            </Tab>
            <Tab key="historyTab" title="History">
              <HistoryTab ubo={ubo} />
            </Tab>
          </TabContainer>
        </>
      )}
    </Box>
  );
};
