import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Typography,
  MenuItem,
  Select,
  Stack,
  Checkbox,
} from '@mui/material';
import { ModalDialog } from 'uikit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { grey } from 'theme/palette-blocks';
import { useGetCaseAssignmentsQuery, useUpdateCasesMutation } from 'services/gatewayApi/casesApi';
import { toastUpdateSuccess } from 'utils/toast';
import { getCaseStatuses } from '../constants';
import { AddCommentsForm } from '../AddCommentsForm';

import './BulkActionsBox.scss';
import { User } from '../../UsersManagement/types';
import { Case } from '../types';

export interface BulkActionsBoxProps {
  checkedItems: string[];
  users: User[];
  onComplete: () => void;
  allItems: Case[];
  selectedCaseType: string;
}

export const BulkActionsBox: React.FC<BulkActionsBoxProps> = ({
  checkedItems,
  users,
  onComplete,
  allItems,
  selectedCaseType,
}) => {
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [submitComment, setSubmitComment] = useState<boolean>(false);

  const selectedItems = allItems.filter((el) => checkedItems.some((item) => item === el.caseId));
  const plural = checkedItems.length > 1 ? 's' : '';

  // #region Form defaults and validation
  const [updateCases] = useUpdateCasesMutation();

  const validationSchema = yup.object({
    assigneeId: yup.string(),
    caseStatus: yup.string().required('Required'),
  });

  const initialValues = {
    assigneeId: '',
    caseStatus: '',
    addComments: true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ assigneeId, caseStatus, addComments }) => {
      updateCases({
        caseIds: checkedItems,
        assigneeId: assigneeId || undefined,
        caseStatus,
      })
        .unwrap()
        .then(() => {
          toastUpdateSuccess(
            `Status${assigneeId ? ' and assignee' : ''} for selected case${plural}`,
          );
          formik.resetForm();

          if (addComments) {
            setCommentModalOpen(true);
          } else {
            onComplete();
          }
        })
        .catch((rejected) => {
          console.error(rejected);
        });
    },
  });
  // #endregion

  const checkedCategories = [...new Set(selectedItems.map((c) => c.caseCategory))];
  const isAllSameCategory = checkedCategories.length === 1;
  const caseCategory = checkedCategories[0] ?? undefined;

  // #region Users list
  const { data: caseAssignments = [], isLoading } = useGetCaseAssignmentsQuery(undefined, {
    skip: !caseCategory,
  });
  const enabledUsers = users?.filter((u) => u.enabled);
  const [limitedUsers, setLimitedUsers] = useState([]);

  useEffect(() => {
    if (
      !isLoading &&
      caseAssignments.length > 0 &&
      enabledUsers.length > 0 &&
      limitedUsers.length === 0
    ) {
      const categoryUsers = caseAssignments.filter(
        (ca) => ca.caseCategory === caseCategory && enabledUsers.some((u) => u.id === ca.userId),
      );

      setLimitedUsers(enabledUsers.filter((u) => categoryUsers.some((cu) => cu.userId === u.id)));
    }
  }, [caseAssignments, isLoading, enabledUsers]);

  const usersKeyValue = limitedUsers.map((u) => ({
    value: u.id,
    label: u.name,
  }));
  // #endregion

  useEffect(() => {
    // Unselect assigneeId selection if multiple category cases are
    // selected because user list is limited based on case category
    if (!isAllSameCategory) {
      formik.setFieldValue('assigneeId', '');
    }
  }, [isAllSameCategory]);

  /* Complete bulk actions if modal is closed */
  useEffect(() => {
    if (!commentModalOpen) {
      onComplete();
    }
  }, [commentModalOpen]);

  const onModalClose = () => setCommentModalOpen(false);

  return (
    <>
      {checkedItems.length > 0 && (
        <form onSubmit={formik.handleSubmit}>
          <Stack direction="row" justifyContent="space-between" id="BulkActionsBox">
            <Stack direction="row" gap={5} alignItems="center">
              <Typography variant="bodyBold" pr={10}>
                Apply to {checkedItems.length} selected
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body" color="grey.600">
                  Assignee
                </Typography>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <Select
                    name="assigneeId"
                    value={formik.values.assigneeId}
                    error={!!formik.errors.assigneeId}
                    onChange={formik.handleChange}
                    displayEmpty
                    disabled={!isAllSameCategory}
                    startAdornment={
                      <InputAdornment position="start" sx={{ color: grey[900] }}>
                        <AccountCircleIcon />
                      </InputAdornment>
                    }
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem value="">
                      <em>Keep existing</em>
                    </MenuItem>
                    {usersKeyValue?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body" color="grey.600">
                  Status
                </Typography>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <Select
                    name="caseStatus"
                    value={formik.values.caseStatus}
                    error={!!formik.errors.caseStatus}
                    onChange={formik.handleChange}
                    displayEmpty
                    MenuProps={{ disableScrollLock: true }}
                  >
                    <MenuItem value="">
                      <em>Select a status</em>
                    </MenuItem>
                    {getCaseStatuses(selectedCaseType).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.addComments}
                    onChange={(e, checked: boolean) => formik.setFieldValue('addComments', checked)}
                  />
                }
                label="Add comment"
              />
            </Stack>

            <Stack direction="row" gap={2} alignItems="center">
              <Button
                color="base"
                variant="contained"
                disabled={formik.isSubmitting}
                onClick={onComplete}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
                  ) : null
                }
                disabled={formik.isSubmitting}
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
      <ModalDialog
        open={commentModalOpen}
        onClose={onModalClose}
        title={`Add Comment to Case${plural}`}
        handleAction={() => setSubmitComment(true)}
        actionTitle="Submit"
        isLoading={submitComment}
        disableEscapeKeyDown
      >
        <AddCommentsForm
          caseIds={checkedItems}
          manualSubmit={submitComment}
          onSubmit={() => {
            setSubmitComment(false);
            onModalClose();
          }}
        />
      </ModalDialog>
    </>
  );
};
