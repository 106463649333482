interface MakeTitleParams {
  firstName: string;
  lastName: string;
}

type MakeTitle = ({ firstName, lastName }: MakeTitleParams) => string;

export const makeTitle: MakeTitle = ({ firstName, lastName }) => {
  if (!firstName && !lastName) {
    return '-';
  }
  if (!firstName && lastName) {
    return lastName;
  }
  return `${firstName} ${lastName}`;
};
