import React from 'react';
import { Panel, Badge } from 'uikit';
import { Box, Divider, Stack, Typography } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

export function BadgesSection() {
  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom mb={4}>
        Badges
      </Typography>

      <Panel>
        <Typography variant="h4" gutterBottom>
          Badge sizes{' '}
        </Typography>
        <Divider sx={{ mb: 6 }} />

        <Box mb={3}>
          <Stack direction="row" gap={1} alignItems="flex-start">
            <Badge size="sm" bold>
              Small
            </Badge>
            <Badge bold size="md">
              Middle
            </Badge>
            <Badge bold size="lg">
              Large
            </Badge>
          </Stack>
        </Box>
      </Panel>
      <Panel>
        <Typography variant="h4" gutterBottom>
          Badge forms
        </Typography>
        <Divider sx={{ mb: 6 }} />
        <Box mb={3}>
          <Stack direction="row" gap={1} alignItems="flex-start">
            <Badge bold size="md">
              Contained
            </Badge>
            <Badge bold size="md" variant="outlined">
              Outlined
            </Badge>
            <Badge bold size="md" variant="text">
              Text
            </Badge>

            <Badge bold size="md" shape="rounded">
              Contained rounded
            </Badge>
            <Badge bold size="md" shape="squared">
              Contained squared
            </Badge>
          </Stack>
        </Box>
      </Panel>

      <Panel>
        <Typography variant="h4" gutterBottom>
          Badge interactive
        </Typography>
        <Divider sx={{ mb: 6 }} />
        <Box mb={3}>
          <Stack direction="row" gap={1} alignItems="flex-start">
            <Badge interactive bold size="sm" prefix={<AccessTimeFilledIcon />}>
              Small
            </Badge>
            <Badge interactive bold size="md" prefix={<AccessTimeFilledIcon />} variant="outlined">
              Middle
            </Badge>
            <Badge interactive bold size="lg" prefix={<AccessTimeFilledIcon />}>
              Large
            </Badge>
          </Stack>
        </Box>
      </Panel>
    </Box>
  );
}
