import React, { memo, forwardRef, ForwardedRef, ForwardRefRenderFunction } from 'react';

import './IconButton.scss';

import { IconButtonProps } from './IconButtonProps';

const InternalIconButton: ForwardRefRenderFunction<unknown, IconButtonProps> = (
  props: IconButtonProps,
  ref: ForwardedRef<any>,
) => {
  const { theme, shape, children, size, disabled, onlyText, className, ...rest } = props;
  const baseName = 'iconButton';
  let classNames = baseName;

  classNames += className ? ` ${className}` : '';

  classNames +=
    shape === 'circle' ? ` ${baseName}--circle` : shape === 'square' ? ` ${baseName}--square` : '';

  classNames +=
    size === 'md'
      ? ` ${baseName}--${size}`
      : size === 'lg'
      ? ` ${baseName}--${size}`
      : size === 'xs'
      ? ` ${baseName}--${size}`
      : '';

  classNames +=
    theme === 'primary'
      ? ` ${baseName}--${theme}`
      : theme === 'text'
      ? ` ${baseName}--${theme}`
      : theme === 'dark'
      ? ` ${baseName}--${theme}`
      : '';

  classNames += onlyText ? ' onlyText' : '';

  return (
    <button disabled={disabled} {...rest} ref={ref} className={classNames}>
      {children}
    </button>
  );
};

const IconButton = memo(forwardRef<unknown, IconButtonProps>(InternalIconButton));
IconButton.displayName = 'IconButton';
export default IconButton;
