import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, CircularProgress } from '@mui/material';
import { Modal, FormRow, Select, ISelectOption } from 'uikit';
import {
  useSetManualKycIndividualStatusMutation,
} from 'services/gatewayApi/kycApplicantsApi';
import { manualResults } from '../../../constants';


import './ManualDecisionDialog.scss';

interface ManualDecisionDialogProps {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
  data?: any;
}

export const ManualDecisionDialog: React.FC<ManualDecisionDialogProps> = ({
  showDialog,
  setShowDialog,
  data,
}) => {
  const { applicantId, decision, queryParams } = data;

  const [updateKycManualDecision] = useSetManualKycIndividualStatusMutation();

  const decisionOptions = manualResults?.allIds.map((id) => ({
    value: id,
    label: manualResults.byId[id].label,
  }));

  const formik = useFormik({
    initialValues: { decision },
    validationSchema: yup.object().shape({
      decision: yup
        .string()
        .required('Please select a decision')
        .oneOf(
          decisionOptions ? decisionOptions.map((el) => el.value) : [],
          'Please select a decision from the list',
        ),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const model = {
        kycApplicantId: applicantId as string,
        manualResult: values.decision as 'CLEAR' | 'CONSIDER',
      };

      updateKycManualDecision(model)
        .unwrap()
        .then(() => {
          setSubmitting(false);
          setShowDialog(false);
        });
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setShowDialog(false);
  };

  return (
    <Modal
      doNotCloseOnBackdrop
      showModal={showDialog}
      setModal={setShowDialog}
      modalHeader={<h3>{decision ? 'Edit' : 'Add'} Manual Status</h3>}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <FormRow>
          <Select
            name="decision"
            selectBox
            showOnFocus
            label="Manual Status"
            options={decisionOptions}
            onChange={(option: ISelectOption) => {
              formik.setFieldValue('decision', option ? option.value : '');
            }}
            value={
              formik.values.decision
                ? decisionOptions.find((c) => c.value === formik.values.decision)
                : null
            }
          />
        </FormRow>

        <div className="formControlBlock">
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={handleCancel}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={
              formik.isSubmitting ? (
                <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
              ) : null
            }
            disabled={formik.isSubmitting || decision === formik.values.decision}
            sx={{ ml: 2 }}
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};
