/** npm */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as _ from 'lodash';
/** global modules */
import { FiltersSelectable, PageContainer, StickyHeader } from 'uikit';
import { MUIDatatableSkeleton, Paging } from 'components';
import { checkProductApiName, formatDateScreen, parseDate, renameKeys } from 'utils';
import { useGetTransactionsQuery } from 'services/internalApi';
import { useGetRulesListQuery } from 'services/gatewayApi/rulesApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { currencies } from 'constants/currencies';
import { DATE_SERVER_FORMAT, TRANSACTIONS_PAGE_SIZE } from 'constants/constants';
/** this module */
import TransactionsList from 'containers/Transactions/transactionsList';
import { Box, Button, Tooltip, Typography, LinearProgress } from '@mui/material';
import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { filterScheme, fraudResult, transactionType } from './constants';
import { downloadFile } from '../../utils/downloadFile';

export const Transactions = () => {
  const navigate = useNavigate();

  const selectedProductId = useSelector(makeSelectedProductIdData());
  const previousProductIdRef = useRef<string | null>(null);
  const isBank = checkProductApiName('BANK');
  const isGateway = checkProductApiName('GATEWAY');

  const initialFilter = {};
  const [filterQuery, setFilterQuery] = useState<IFilterValue>(initialFilter);
  const [apiFilter, setApiFilter] = useState<IFilterValue>(initialFilter);
  const [isSkip, setIsSkip] = useState(false);
  const [pagination, setPagination] = useState({
    paginationItem: {
      from: 0,
      pageSize: TRANSACTIONS_PAGE_SIZE,
    },
  });

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    // Read each filter value for filters
    const urlFilters = {};
    filterScheme.map(({ field, name }) => {
      const filterValue = new URLSearchParams(urlParams).get(name);
      if (filterValue) {
        urlFilters[name] = field === 'selectMulti' ? filterValue.split(',') : filterValue;
      }
    });

    handleSetFilter(Object.keys(urlFilters).length > 0 ? urlFilters : initialFilter);
  }, [filterScheme]);

  // control filter from inputs
  useEffect(() => {
    const params = new URLSearchParams();
  
    Object.keys(filterQuery).map((key) => {
      params.append(key, filterQuery[key] as string);
    });
  
    navigate({ search: params.toString() }, { state: true });
  }, [navigate, filterQuery]);

  useEffect(() => {
    if (previousProductIdRef.current !== null) {
      handleSetFilter(initialFilter);
    }
    previousProductIdRef.current = selectedProductId;
  }, [selectedProductId]);

  const {
    data: transactions,
    isLoading: isTransactionsLoading,
    isFetching,
  } = useGetTransactionsQuery(
    { ...apiFilter, ...pagination, selectedProductId },
    { skip: !selectedProductId && isSkip },
  );

  const { rules, isRulesLoading } = useGetRulesListQuery(selectedProductId, {
    skip: !selectedProductId,
    selectFromResult: ({ data, isLoading }) => ({
      rules: data?.rulesList.map((el) => ({
        value: el.id,
        label: `${el.id}: ${el.name}`,
      })),
      isRulesLoading: isLoading,
    }),
  });

  const { countries, isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      countries: data?.map((el) => ({
        value: el.countryCode,
        label: `${el.countryCode}: ${el.name}`,
      })),
      isLoadingCountries: isLoading,
    }),
  });

  const isLoading = isTransactionsLoading || isRulesLoading || isLoadingCountries;

  const options = {
    fraudResult,
    transactionType,
    currency: currencies.map((el) => ({
      value: el.code,
      label: `${el.code}: ${el.name}`,
    })),
    country: countries,
    binCountry: countries,
    bankCountry: countries,
    clientRegistrationCountry: countries,
    excludedTriggeredRuleIds: rules,
    triggeredRuleId: rules,
  };

  const handlePageChange = (pageNumber) => {
    const paginationItem = {
      from: (pageNumber - 1) * TRANSACTIONS_PAGE_SIZE,
      pageSize: TRANSACTIONS_PAGE_SIZE,
    };
    setPagination({ paginationItem });
  };

  const todayDate = new Date();
  const handleSetFilter = (newFilter) => {
    const newKeys = { startCreationDate: 'startDate', endCreationDate: 'endDate' };
    const newApiFilter = renameKeys(newFilter, newKeys);
    if (!!newFilter.startCreationDate && !newFilter.endCreationDate) {
      newFilter.endCreationDate = formatDateScreen(todayDate, DATE_SERVER_FORMAT);
    }
    setIsSkip(true); // prevent 2 times fetching
    handlePageChange(1);
    setIsSkip(false);
    setFilterQuery(newFilter);
    setApiFilter(newApiFilter);
  };

  const isExportDisabled = () => {
    if (!transactions?.responseList || transactions.responseList?.length < 1) {
      return true;
    }
    const timeDiff = Math.floor(
      parseDate(filterQuery.endCreationDate)?.getTime() - parseDate(filterQuery.startCreationDate)?.getTime(),
    );
    const dateRange = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
    if (dateRange > 0 && dateRange < 94 && timeDiff > 0) {
      return false;
    }
    return true;
  };

  const messageExport = transactions?.responseList
    ? "Please filter with a 'Date Range' of less than 3 months"
    : 'List must not be empty';

  const handleExportExcel = () => {
    const data = _.clone(filterQuery);
    data.startDate = filterQuery.startCreationDate;
    data.endDate = filterQuery.endCreationDate;
    delete data.startCreationDate;
    delete data.endCreationDate;
    data.paginationItem = { from: 0, pageSize: 10000 };
    data.product = selectedProductId;

    const headers = {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    downloadFile('/api/gateway/transaction/extract-report', {
      download: true,
      method: 'POST',
      body: data,
      headers,
      fileName: `Transactions ${data.startDate} - ${data.endDate}`,
    }).catch((rejected) => {
      console.error(rejected);
    });
  };

  const RenderPaging = () => {
    const { paginationInfo } = transactions;

    if (!paginationInfo || (paginationInfo && paginationInfo.totalPageCount <= 1)) return null;

    return (
      <Paging
        activePage={paginationInfo.currentPage}
        currentPageSize={paginationInfo.currentPageSize}
        totalItemsCount={paginationInfo.totalEntryCount}
        handlePageChange={(activePage) => handlePageChange(activePage)}
      />
    );
  };

  return (
    <>
      <Helmet title="Transactions" />
      <PageContainer>
        <StickyHeader>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt="1.25rem"
            pb="1rem"
            className="transactionsHeader"
          >
            <Typography variant="h1">Transactions</Typography>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Tooltip title={isExportDisabled() ? messageExport : ''} disableFocusListener>
                <span>
                  <Button
                    sx={{ mr: 2 }}
                    color="base"
                    startIcon={<FileDownloadIcon style={{ fontSize: '16px' }} />}
                    disabled={isExportDisabled()}
                    onClick={handleExportExcel}
                  >
                    Export to Excel
                  </Button>
                </span>
              </Tooltip>
              <FiltersSelectable
                filterScheme={filterScheme}
                filterValue={filterQuery}
                filterHandler={handleSetFilter}
                options={options}
                onReset={() => handleSetFilter(initialFilter)}
              />
            </Box>
          </Box>
          <Box visibility={isFetching ? 'visible' : 'hidden'}>
            <LinearProgress />
          </Box>
        </StickyHeader>
      </PageContainer>

      <PageContainer>
        {isLoading ? (
          <MUIDatatableSkeleton />
        ) : (
          <>
            <TransactionsList
              transactions={transactions}
              loading={isLoading}
              isBank={isBank}
              isGateway={isGateway}
            />
            <RenderPaging />
          </>
        )}
      </PageContainer>
    </>
  );
};
