import {
  LOAD_PERMISSION_LIST,
  LOAD_PERMISSION_LIST_SUCCESS,
  LOAD_PERMISSION_LIST_FAIL,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingListError } from '../utils/toast';

const entityTitle = 'Permission';

export const fetchPermissions = () => (dispatch) => {
  const data = JSON.parse(localStorage.getItem('token_data'));
  dispatch({ type: LOAD_PERMISSION_LIST });
  ApiService.get(`/api/gateway/users/${data.userId}/permissions`)
    .then((response) => dispatch(fetchPermissionsSuccess(response)))
    .catch((error) => dispatch(fetchPermissionsError(error)));
};

const fetchPermissionsSuccess = (permissions) => ({
  type: LOAD_PERMISSION_LIST_SUCCESS,
  permissions,
});

const fetchPermissionsError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_PERMISSION_LIST_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};
