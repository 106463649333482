import { Buffer } from 'buffer';

// Checks if the given text is base64 encoded
export const isBase64 = (text: string) => Buffer.from(text, 'base64').toString('base64') === text;

// Encodes given text to base64
export const encodeToBase64 = (text: string) => Buffer.from(text).toString('base64');

// Decodes text if base64, otherwise returns the same text
export const decodeFromBase64 = (text: string) => isBase64(text)
  ? Buffer.from(text, 'base64').toString('utf-8')
  : text;
