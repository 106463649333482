import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  makeSelectedProductIdData,
  makeUserPermissionsData,
  makeUserProductsData,
} from 'selectors/user';
import { NOT_AUTHORIZED_URL } from 'constants/constants';
import NotFoundPage from 'containers/NotFoundPage';
import { routes } from './routes';
import { App } from '../containers/App';

export const AppRoutes = () => {
  const permissions = useSelector(makeUserPermissionsData());
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const userProducts = useSelector(makeUserProductsData());
  const selectedProduct =
    userProducts && userProducts.find((p) => p.id === parseInt(selectedProductId, 10));

  // Allow access to unprotected routes or to all routes if user's permissions are not loaded yet
  const isRouteGuarded = (allowedPermissions, userPermissions) =>
    !!allowedPermissions && !!userPermissions && userPermissions.length !== 0;

  const checkPermissions = (allowedPermissions, userPermissions) =>
    userPermissions.some((p) => allowedPermissions.includes(p.name));

  const permissionsMatched = (allowedPermissions, userPermissions) =>
    isRouteGuarded(allowedPermissions, userPermissions)
      ? checkPermissions(allowedPermissions, userPermissions)
      : true;

  const checkProductName = (authProductNames, selectedProductName) =>
    authProductNames.includes(selectedProductName);

  const productNamesMatched = (authProductNames, product) => {
    return isRouteGuarded(authProductNames, product)
      ? checkProductName(authProductNames, product.name.toUpperCase())
      : true;
  };

  const checkProductApiNames = (authProductApiNames, selectedProductApiName) =>
    authProductApiNames.includes(selectedProductApiName);

  const productApiNamesMatched = (authProductApiNames, product) =>
    isRouteGuarded(authProductApiNames, product)
      ? checkProductApiNames(authProductApiNames, product.productApi.apiName)
      : true;

  const getRoute = ({ path, element, subroutes }) => (
    <Route key={`${path}`} path={path} element={element}>
      {subroutes &&
        subroutes.map((subroute) =>
          getRoute({
            path: subroute.path,
            element: subroute.element,
            subroutes: subroute.subroutes,
          }),
        )}
    </Route>
  );

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path={'/'} element={<App />}>
        {routes.map((route) =>
          permissionsMatched(route.authorize, permissions) &&
          productNamesMatched(route.authProductNames, selectedProduct) &&
          productApiNamesMatched(route.authProductApiNames, selectedProduct) ? (
            getRoute({
              path: route.path,
              element: route.element,
              subroutes: route.subroutes,
            })
          ) : (
            <Route
              key={`${route.path}`}
              path={route.path}
              element={() => <Navigate replace to={NOT_AUTHORIZED_URL} />}
            />
          ),
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );
};
