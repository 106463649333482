/* eslint-disable class-methods-use-this */

import { BinaryNode } from './BinaryNode';
import { PropertyNode } from './PropertyNode';

export class DotNode extends BinaryNode {
  constructor(l, r) {
    /*
    if(!(l instanceof PropertyNode || l instanceof DotNode)){
        throw new Error("invalid left node")
    }
    */
    if (!(r instanceof PropertyNode)) {
      throw new Error('invalid right node');
    }
    super('.', l, r);
  }

  compute(ctx) {
    return this.right.compute(this.left.compute(ctx));
  }

  toString() {
    return `${this.left.toString()}.${this.right.toString()}`;
  }

  toDDFConditionNode() {
    return this.left.toDDFConditionNode();
    // throw new Error('not implemented');
  }
}
