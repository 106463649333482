export const kycStatuses = {
  byId: {
    UNDEFINED: {
      id: 'UNDEFINED',
      label: 'Undefined',
      color: 'primary',
    },
    NEW: {
      id: 'NEW',
      label: 'New',
      color: 'primary',
    },
    IN_PROGRESS: {
      id: 'IN_PROGRESS',
      label: 'In Progress',
      color: 'warning',
    },
    COMPLETE: {
      id: 'COMPLETE',
      label: 'Complete',
      color: 'success',
    },
    PAUSED: {
      id: 'PAUSED',
      label: 'Paused',
      color: 'warning',
    },
  },
  allIds: ['UNDEFINED', 'NEW', 'IN_PROGRESS', 'COMPLETE', 'PAUSED'],
};
