/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_TRANSACTION_COUNTRIES,
  LOAD_TRANSACTION_COUNTRIES_SUCCESS,
  LOAD_TRANSACTION_COUNTRIES_FAIL,
} from '../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  transactionCountries: [],
};

const transactionCountriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_TRANSACTION_COUNTRIES:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_TRANSACTION_COUNTRIES_SUCCESS:
        draft.transactionCountries = action.transactionCountries;
        draft.loading = false;
        break;
      case LOAD_TRANSACTION_COUNTRIES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default transactionCountriesReducer;
