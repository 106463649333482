import React, { useEffect, useState } from 'react';
import { DialogContentText } from '@mui/material';
import { Input, ModalDialog } from 'uikit';
import { CATEGORY_NAME_REGEX } from 'constants/constants';
import {
  useCreateClientCategoryMutation,
  useUpdateClientCategoryMutation,
} from 'services/gatewayApi/clientsApi';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { Category } from './types';

interface ClientCategoryDialogProps {
  open: boolean;
  category: Category;
  onClose: any;
}

export const ClientCategoryDialog: React.FC<ClientCategoryDialogProps> = ({
  open,
  category,
  onClose,
}) => {
  const { name, id: categoryId } = category;

  const [createCategory] = useCreateClientCategoryMutation();
  const [updateCategory] = useUpdateClientCategoryMutation();

  const [categoryName, setCategoryName] = useState(name);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (categoryName === '') {
      return setHasError(false);
    }
    categoryName.match(CATEGORY_NAME_REGEX) ? setHasError(false) : setHasError(true);
  }, [categoryName]);

  const handleSave = async () => {
    const isNew = categoryId === 'new';

    const mutationPromise = isNew
      ? await createCategory({ name: categoryName })
      : await updateCategory({ categoryId, name: categoryName });

    // @ts-ignore
    if (!mutationPromise.error) {
      isNew ? toastCreateSuccess('Category') : toastUpdateSuccess('Category');

      onClose();
    }
  };

  return (
    <ModalDialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      title={category.id === 'new' ? 'New Category' : 'Edit Category'}
      handleAction={handleSave}
      actionTitle={category.id === 'new' ? 'Create' : 'Save'}
      disableAction={categoryName === '' || categoryName === category.name || hasError}
    >
      <>
        <DialogContentText sx={{ pb: 2 }}>
          {category.id === 'new'
            ? 'Fill the name of new client category'
            : `Edit name of client category ID: ${category.id}`}
        </DialogContentText>
        <Input
          onChange={(e) => setCategoryName(e.target.value)}
          autoFocus
          value={categoryName}
          placeholder="Client category name"
          handleClear={() => setCategoryName('')}
          hasError={hasError}
        />
      </>
    </ModalDialog>
  );
};
