import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { NoteContent } from 'muikit/Note';
import { MessageType } from './Message';
import MessageQuoteChip from './MessageQuoteChip';
import 'react-quill/dist/quill.snow.css';
import { decodeFromBase64, encodeToBase64 } from 'utils/base64';

interface MessageInputProps {
  addMessage: (msg: MessageType) => any;
  replyMessage?: MessageType;
  onDeleteReply?: () => any;
}

const MessageInput = React.forwardRef(
  (
    { addMessage, replyMessage, onDeleteReply }: MessageInputProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ): JSX.Element => {
    const [content, setContent] = React.useState('');

    const canSend = content.replace(/<[^>]+>/g, '').trim() !== '';

    const handleSend = () => {
      if (canSend) {
        addMessage({
          content: encodeToBase64(content),
          replyTo: replyMessage?.id
        });
        setContent('');
        onDeleteReply && onDeleteReply();
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !e.shiftKey && canSend) {
        handleSend();
      }
    };

    return (
      <Box sx={{ position: 'relative' }}>
        {replyMessage && (
          <MessageQuoteChip
            sx={{ width: '100%', mb: 1 }}
            onDelete={onDeleteReply}
            label={decodeFromBase64(replyMessage.content).replace(/<[^>]+>/g, ' ').trim()}
          />
        )}
        <NoteContent
          htmlLight
          editable
          placeholder="Leave a message"
          onChange={(value) => setContent(value)}
          onKeyDown={handleKeyDown}
        >
          {content}
        </NoteContent>
        <IconButton
          onClick={() => handleSend()}
          disabled={!canSend}
          color="primary"
          sx={{ position: 'absolute', right: '4px', bottom: '4px' }}
        >
          <SendIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  },
);

export default MessageInput;
