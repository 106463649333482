import React from 'react';
import { useSelector } from 'react-redux';
import { Button, FormControlLabel, Stack, Tooltip, Checkbox } from '@mui/material';
import { RiFileExcelLine } from 'react-icons/ri';
import { IFilterValue, SortByHeader } from '../../uikit';
import { SortOptions, SortParam } from '../../types';
import { makeUserData } from '../../selectors/user';

interface ControlsPanelProps {
  handleCheckAll: () => void;
  isSelected: boolean;
  isLoading: boolean;
  setFilterQuery: React.Dispatch<React.SetStateAction<IFilterValue>>;
  isExportDisabled: boolean;
  messageExport: string;
  handleExportExcel: () => void;
  count: number;
  totalCount: number;
  sortOptions: SortOptions[];
  setSortedBy: React.Dispatch<React.SetStateAction<SortParam>>;
  sortedBy: SortParam;
  filterQuery: IFilterValue;
}

export const ControlsPanel: React.FC<ControlsPanelProps> = React.memo(
  (props) => {
    const {
      handleCheckAll,
      isSelected,
      isLoading,
      setFilterQuery,
      isExportDisabled,
      messageExport,
      handleExportExcel,
      count,
      totalCount,
      sortOptions,
      sortedBy,
      setSortedBy,
      filterQuery,
    } = props;

    const { id: userId } = useSelector(makeUserData());

    const getButtonLabel = () => (isSelected ? 'Deselect all' : 'Select all visible');

    const isChecked = (fieldName: string) => {
      switch (fieldName) {
        case 'caseStatus':
          return filterQuery[fieldName] === 'OPEN';

        case 'assigneeId':
          return filterQuery[fieldName] === userId;

        default:
          return false;
      }
    };

    const setChecked = (fieldName) => {
      setFilterQuery((prev) => {
        const next = { ...prev };

        if (isChecked(fieldName)) {
          delete next[fieldName];
        } else {
          let value = '';
          switch (fieldName) {
            case 'caseStatus':
              value = 'OPEN';
              break;

            case 'assigneeId':
              value = userId;
              break;

            default:
              console.warn(`No value is defined for checked field ${fieldName}`);
          }
          next[fieldName] = value;
        }

        return next;
      });
    };

    return (
      <Stack
        className="topControlsRow"
        direction="row"
        sx={{
          pt: 1,
          pb: 4,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          color="base"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            handleCheckAll();
          }}
        >
          {getButtonLabel()}
        </Button>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterQuery.caseStatus === 'OPEN'}
                onChange={() => setChecked('caseStatus')}
                disabled={isLoading}
              />
            }
            label="Show open"
            sx={{ mr: 6 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filterQuery.assigneeId === userId}
                onChange={() => setChecked('assigneeId')}
                disabled={isLoading}
              />
            }
            label="Show assigned to me"
          />
        </div>

        <SortByHeader
          count={count}
          totalCount={totalCount}
          sortOptions={sortOptions}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          disableCounter
        />

        <Tooltip title={isExportDisabled ? messageExport : ''}>
          <div>
            <Button
              color="base"
              variant="contained"
              startIcon={<RiFileExcelLine size="14px" />}
              disabled={isExportDisabled}
              onClick={handleExportExcel}
            >
              Export to Excel
            </Button>
          </div>
        </Tooltip>
      </Stack>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.messageExport === nextProps.messageExport &&
    prevProps.count === nextProps.count &&
    prevProps.sortOptions === nextProps.sortOptions &&
    prevProps.filterQuery === nextProps.filterQuery &&
    prevProps.sortedBy === nextProps.sortedBy,
);
