import { parseISO } from 'date-fns';
import { gatewayApi } from '../gatewayApi';
import {
  CreateSearchSessionBody,
  PepDecision,
  DecisionEntry,
  Result,
  ParamsGetPep,
  RequestGetPep,
  SearchSessionsResponseContent,
  ResponseTransactionsPep,
  TransformedResponseSearchSessions,
} from '../../features/PepAndSanctions/types';

const pepAndSanctionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getSearchSessions: build.query<RequestGetPep, { applicationId?: string; params: ParamsGetPep }>(
      {
        query: ({ applicationId, params }) => ({
          url: applicationId ? `applications/${applicationId}/pepsanctions` : `pepsanctions`,
          method: 'GET',
          params,
        }),
        providesTags: (result, error, { applicationId }) => [
          {
            type: applicationId ? 'PepsanctionsSessions' : 'Sessions',
            id: applicationId ? `LIST_${applicationId}` : `LIST`,
          },
        ],
      },
    ),
    getSearchSession: build.query<
      SearchSessionsResponseContent,
      { applicationId?: string; searchId: string }
    >({
      query: ({ applicationId, searchId }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}`
          : `pepsanctions/${searchId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { searchId, applicationId }) => [
        { type: applicationId ? 'PepsanctionsSession' : 'SessionEntries', id: `LIST_${searchId}` },
      ],
    }),

    createSearchSession: build.mutation<
      SearchSessionsResponseContent,
      { applicationId?: string; body: CreateSearchSessionBody }
    >({
      query: ({ applicationId, body }) => ({
        url: applicationId ? `applications/${applicationId}/pepsanctions` : `pepsanctions`,
        method: 'POST',
        body,
      }),
    }),

    performSearch: build.mutation<
      SearchSessionsResponseContent,
      { applicationId?: string; searchId: string }
    >({
      query: ({ applicationId, searchId }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}`
          : `pepsanctions/${searchId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { applicationId, searchId }) => [
        {
          type: applicationId ? 'PepsanctionsSessions' : 'Sessions',
          id: applicationId ? `LIST_${applicationId}` : 'LIST',
        },
        { type: applicationId ? 'PepsanctionsSession' : 'SessionEntries', id: `LIST_${searchId}` },
        { type: applicationId ? 'PepsanctionsResults' : 'SessionResults', id: `LIST_${searchId}` },
      ],
    }),
    patchSearch: build.mutation<
      SearchSessionsResponseContent,
      { applicationId?: string; searchId: string; body: CreateSearchSessionBody }
    >({
      query: ({ applicationId, searchId, body }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}`
          : `pepsanctions/${searchId}`,
        method: 'PATCH',
        body,
      }),
    }),

    makeDecision: build.mutation<
      SearchSessionsResponseContent,
      { applicationId?: string; searchId: string; decision: PepDecision }
    >({
      query: ({ applicationId, searchId, decision }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}/decision`
          : `pepsanctions/${searchId}/decision`,
        method: 'PUT',
        body: { decision },
      }),
      invalidatesTags: (result, error, { applicationId, searchId }) => [
        {
          type: applicationId ? 'PepsanctionsSessions' : 'Sessions',
          id: applicationId ? `LIST_${applicationId}` : 'LIST',
        },
        { type: applicationId ? 'PepsanctionsSession' : 'SessionEntries', id: `LIST_${searchId}` },
      ],
    }),

    makeDecisionEntry: build.mutation<
      SearchSessionsResponseContent,
      {
        applicationId?: string;
        searchId: string;
        entryId: string;
        decision: DecisionEntry;
      }
    >({
      query: ({ applicationId, searchId, entryId, decision }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}/entry/${entryId}/decision`
          : `pepsanctions/${searchId}/entry/${entryId}/decision`,
        method: 'PUT',
        body: { decision },
      }),
      invalidatesTags: (result, error, { searchId, applicationId }) => [
        { type: applicationId ? 'PepsanctionsSession' : 'SessionEntries', id: `LIST_${searchId}` },
      ],
    }),

    getSearchSessionResults: build.query<Result[], { applicationId?: string; searchId: string }>({
      query: ({ applicationId, searchId }) => ({
        url: applicationId
          ? `applications/${applicationId}/pepsanctions/${searchId}/results`
          : `pepsanctions/${searchId}/results`,
        method: 'GET',
      }),
      providesTags: (result, error, { searchId, applicationId }) => [
        { type: applicationId ? 'PepsanctionsResults' : 'SessionResults', id: `LIST_${searchId}` },
      ],
      transformResponse: (response: Result[]) =>
        response.sort(
          (a, b) => parseISO(b.performedOn).valueOf() - parseISO(a.performedOn).valueOf(),
        ),
    }),

    // pepSanctions Transactions
    getTransactionSearchSessions: build.query<TransformedResponseSearchSessions, any>({
      query: (params) => ({
        url: `transactions/pep-sanctions`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'PepsanctionsTransactionSessions', id: `LIST` }],
      transformResponse: (response: ResponseTransactionsPep) => {
        const { content, numberOfElements, totalElements, size: pageSize, sort } = response;
        const searchSessionsList = content.map((searchSession) => {
          const { id, transactionId, searchRequest, performedOn, searchResponse, decision } =
            searchSession;
          return {
            id,
            transactionId: transactionId || '-',
            performedOn,
            fullName: searchRequest?.fullName || '-',
            decision,
            matches: searchResponse?.length || 0,
          };
        });
        return {
          numberOfElements,
          totalElements,
          pageSize,
          sort,
          searchSessionsList,
        };
      },
    }),

    getTransactionSearchSession: build.query<SearchSessionsResponseContent, { searchId: string }>({
      query: ({ searchId }) => ({
        url: `transactions/pep-sanctions/${searchId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { searchId }) => [
        { type: 'PepsanctionsTransactionResults', id: `LIST_${searchId}` },
      ],
    }),

    TransactionMakeDecision: build.mutation<
      SearchSessionsResponseContent,
      { searchId: string; decision: PepDecision }
    >({
      query: ({ searchId, decision }) => ({
        url: `transactions/pep-sanctions/${searchId}/decision`,
        method: 'PUT',
        body: { decision },
      }),
      invalidatesTags: (result, error, { searchId }) => [
        { type: 'PepsanctionsTransactionSessions', id: `LIST` },
        { type: 'PepsanctionsTransactionResults', id: `LIST_${searchId}` },
      ],
    }),

    TransactionMakeDecisionEntry: build.mutation<
      SearchSessionsResponseContent,
      {
        searchId: string;
        entryId: string;
        decision: DecisionEntry;
      }
    >({
      query: ({ searchId: id, entryId: uid, decision }) => ({
        url: `transactions/pep-sanctions/${id}/uid/${uid}`,
        method: 'PUT',
        body: { decision },
      }),
      invalidatesTags: (result, error, { searchId }) => [
        { type: 'PepsanctionsTransactionResults', id: `LIST_${searchId}` },
      ],
    }),

    TransactionWhitelisting: build.mutation<
      SearchSessionsResponseContent,
      {
        searchId: string;
        body: {
          whitelisted: boolean;
          uid: number[];
        };
      }
    >({
      query: ({ body, searchId }) => ({
        url: `transactions/pep-sanctions/${searchId}/whitelist`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { searchId }) => [
        { type: 'PepsanctionsTransactionResults', id: `LIST_${searchId}` },
      ],
    }),
  }),
});

export const {
  useGetSearchSessionsQuery, // providesTags 'PepsanctionsSessions' | 'Sessions'
  useGetSearchSessionQuery, // providesTags 'PepsanctionsSession' | 'SessionEntries'
  useCreateSearchSessionMutation, // no invalidate
  usePatchSearchMutation, // no invalidate
  usePerformSearchMutation, // invalidate 'PepsanctionsSessions','PepsanctionsSession' | 'Sessions','SessionEntries'
  useGetSearchSessionResultsQuery, // history providesTags 'PepsanctionsSession' | 'SessionResults'
  useMakeDecisionMutation, // invalidate 'PepsanctionsSessions','PepsanctionsSession' | 'Sessions','SessionEntries'
  useMakeDecisionEntryMutation, // invalidate 'PepsanctionsSession' | 'SessionEntries'

  // pepSanctions Transactions
  useGetTransactionSearchSessionsQuery,
  useGetTransactionSearchSessionQuery,
  useTransactionMakeDecisionMutation,
  useTransactionMakeDecisionEntryMutation,
  useTransactionWhitelistingMutation,
} = pepAndSanctionsApi;
