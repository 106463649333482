import { formatDate } from 'utils/formatter';
import { gatewayApi } from '../gatewayApi';
import { Case, CaseDataRequest } from '../../features/cases/types';

function providesList(resultsWithIds, tagType, alterId = 'id') {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ [alterId]: id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

const transformCase = (caseItem) => {
  const { caseMetaData, ...rest } = caseItem;
  const result = { ...rest, ...caseMetaData };
  result.createdDateTime = formatDate(caseItem.createdDateTime);
  result.createdDateTimeIso = caseItem.createdDateTime;
  return result;
};

const casesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getCases: build.query({
      query: (params) => {
        const { ruleCategoryId, ...rest } = params;
        return {
          url: `cases`,
          params: {
            ruleCategories: ruleCategoryId,
            ...rest,
          },
        };
      },
      providesTags: (result) => providesList(result.content, 'Case', 'caseId'),
      transformResponse: (response) => ({
        content: response.content.map(transformCase),
        totalElements: response.totalElements,
      }),
    }),
    getCase: build.query<Case, string>({
      query: (caseId) => `cases/${caseId}`,
      providesTags: (result, error, caseId) => [{ type: 'Case', id: caseId }],
      transformResponse: (response) => transformCase(response),
    }),
    updateCase: build.mutation({
      query: (caseData) => ({
        url: `cases/${caseData.caseId}`,
        method: 'PUT',
        body: caseData.data,
      }),
      invalidatesTags: (result, error, { caseId }) => [
        { type: 'Case', id: caseId },
        { type: 'Applications', id: `LIST` },
      ],
    }),
    updateCases: build.mutation<{ caseData: CaseDataRequest }, any>({
      query: (caseData) => ({
        url: `cases`,
        method: 'PUT',
        body: caseData,
      }),
      invalidatesTags: [{ type: 'Case' }],
    }),

    getCaseComments: build.query({
      query: (caseId) => `cases/${caseId}/comments`,
      providesTags: (result, error, caseId) => [{ type: 'CaseComments', id: caseId }],
      transformResponse: (comments) =>
        comments.map((comment) => {
          const result = { ...comment };
          result.createdDate = formatDate(comment.createdDate);
          return result;
        }),
    }),
    addCaseComment: build.mutation({
      query: (comment) => ({
        url: `cases/${comment.caseId}/comments`,
        method: 'POST',
        body: comment,
      }),
      invalidatesTags: (result, error, { caseId }) => [{ type: 'CaseComments', id: caseId }],
    }),
    addCaseComments: build.mutation({
      query: (comments) => ({
        url: `cases/comments`,
        method: 'POST',
        body: comments,
      }),
      invalidatesTags: [{ type: 'CaseComments' }],
    }),
    updateCaseComment: build.mutation({
      query: (comment) => ({
        url: `cases/${comment.caseId}/comments/${comment.commentId}`,
        method: 'PUT',
        body: comment,
      }),
      invalidatesTags: (result, error, { caseId }) => [{ type: 'CaseComments', id: caseId }],
    }),
    deleteCaseComment: build.mutation({
      query: (comment) => ({
        url: `cases/${comment.caseId}/comments/${comment.commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { caseId }) => [{ type: 'CaseComments', id: caseId }],
    }),

    getPredefinedComments: build.query({
      query: () => `case-predefined-comments`,
      providesTags: ['CasePredefinedComments'],
    }),

    getCaseAssignments: build.query<any[], undefined>({
      query: () => `case-assignments`,
      providesTags: ['CaseAssignments'],
    }),

    getCaseCategories: build.query({
      query: () => `case-category`,
      providesTags: ['CaseCategory'],
    }),
  }),
});

export const {
  useGetCasesQuery,
  useGetCaseQuery,
  useUpdateCaseMutation,
  useUpdateCasesMutation,

  useGetCaseCommentsQuery,
  useAddCaseCommentMutation,
  useAddCaseCommentsMutation,
  useUpdateCaseCommentMutation,
  useDeleteCaseCommentMutation,

  useGetPredefinedCommentsQuery,

  useGetCaseAssignmentsQuery,

  useGetCaseCategoriesQuery,
} = casesApi;
