export const documentsTypeMap = {
  ID_CARD: 'ID card',
  PASSPORT: 'Passport',
  UTILITY_BILL: 'Utility bill',
  SELFIE: 'Selfie',
  DRIVING_LICENSE: 'Driving license',
};

export const documentsSideMap = {
  FRONT: 'front',
  BACK: 'back',
  FACE: 'face',
  UTILITY_BILL: 'utility bill',
};
