import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const riskScoringQuestionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskScoringQuestionsBySection: build.query({
      query: (sectionId) => ({
        url: `risk-scoring/questions/${sectionId}`,
        method: 'GET',
      }),
      providesTags: (result, error, sectionId) => [
        { type: 'RiskScoring_Questions', id: sectionId },
      ],
    }),

    addRiskScoringQuestion: build.mutation({
      query(model) {
        const body = { ...model };
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/questions/create`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { sectionId }) => [
        { type: 'RiskScoring_Questions', id: sectionId },
      ],
    }),

    updateRiskScoringQuestion: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/questions/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { sectionId }) => [
        { type: 'RiskScoring_Questions', id: sectionId },
      ],
    }),

    deleteRiskScoringQuestion: build.mutation({
      query: ({ id }) => ({
        url: `risk-scoring/questions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { sectionId }) => [
        { type: 'RiskScoring_Questions', id: sectionId },
      ],
    }),
  }),
});

export const {
  useGetRiskScoringQuestionsBySectionQuery,
  useAddRiskScoringQuestionMutation,
  useUpdateRiskScoringQuestionMutation,
  useDeleteRiskScoringQuestionMutation,
} = riskScoringQuestionsApi;
