import React from 'react';
import { NavLink } from 'react-router-dom';
import checkPermissions from 'utils/checkPermissions';
import checkProductApiName from 'utils/checkProductApiName';
import { appMenuContent } from './appMenuData';
import Grid from '@mui/material/Grid';

import './AppMenuContent.scss';

export const AppMenuContent = ({ user, closeAppMenu }) => {
  const renderBlock = (blockContent) => {
    return blockContent.map((menuItem, i) => {
      const permitted = menuItem.permissions ? checkPermissions(menuItem.permissions) : true;

      const productAPI = menuItem.productAPIName
        ? checkProductApiName(menuItem.productAPIName)
        : true;

      return (
        user &&
        permitted &&
        productAPI && (
          <NavLink
            key={i}
            to={menuItem.link}
            onClick={closeAppMenu}
            className={({ isActive }) => (isActive ? 'AppMenuActiveLink' : undefined)}
          >
            {menuItem.label}
          </NavLink>
        )
      );
    });
  };

  return (
    <div className="AppMenuItems">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className={'AppMenuBlock'}>{renderBlock(appMenuContent[0])}</div>
          <div className={'AppMenuBlock'}>{renderBlock(appMenuContent[1])}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={'AppMenuBlock'}>{renderBlock(appMenuContent[2])}</div>
          <div className={'AppMenuBlock'}>{renderBlock(appMenuContent[3])}</div>
        </Grid>
        <Grid item xs={4}>
          <div className={'AppMenuBlock'}>{renderBlock(appMenuContent[4])}</div>
        </Grid>
      </Grid>
    </div>
  );
};
