import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ArrowBack, Skeleton, ScrollContainer } from 'uikit';
import { useGetSearchSessionResultsQuery } from 'services/gatewayApi/pepAndSanctionsApi';
import { SanctionsRecord, SearchRequest } from '../types';
import { HistoryBlock } from './HistoryBlock';

interface HistoryDetailsProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  setHistorySearchResult: React.Dispatch<React.SetStateAction<SanctionsRecord[]>>;
  selectedResultId?: string;
  setSelectedResultId: React.Dispatch<React.SetStateAction<string>>;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchRequest>>;
  setPerformedOn: React.Dispatch<React.SetStateAction<string>>;
}

export const HistoryDetails: React.FC<HistoryDetailsProps> = ({
  onClose,
  setHistorySearchResult,
  setPerformedOn,
  selectedResultId = undefined,
  setSelectedResultId,
  setSearchParams,
}) => {
  const { applicationId, searchSessionId } = useParams<{
    applicationId: string;
    searchSessionId: string;
  }>();

  const {
    data: results,
    isLoading: isLoadingResults,
    isFetching: isFetchingResults,
  } = useGetSearchSessionResultsQuery({
    applicationId,
    searchId: searchSessionId,
  });

  const isLoading = isLoadingResults || isFetchingResults;

  useEffect(() => {
    !!results && !selectedResultId && setSelectedResultId(results[0]?.id);
  }, [results]);

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && (
        <ScrollContainer>
          <Box
            pt={8}
            pb={4}
            pr={2}
            sx={{
              width: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            {results?.map((result) => (
              <HistoryBlock
                key={result.id}
                result={result}
                selectedResultId={selectedResultId}
                setSelectedResultId={setSelectedResultId}
                setPerformedOn={setPerformedOn}
                setHistorySearchResult={setHistorySearchResult}
                setSearchParams={setSearchParams}
              />
            ))}
          </Box>
        </ScrollContainer>
      )}
    </Box>
  );
};
