import React from 'react';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import './SortBlock.scss';

interface SortBlockProps {
  property?: string;
  sortedBy?: any;
  handleSort?: any;
  children: any;
  style?: any;
}

const SortBlock: React.FC<SortBlockProps> = ({ property, sortedBy, handleSort, children, style }) => {
  const isCurrent = sortedBy?.property === property;

  return (
    <span
      className={
        `SortBlock
        ${handleSort ? 'SortBlock-interactive' : ''}
        ${isCurrent ? 'SortBlock-current' : ''}
      `}
      style={{ cursor: handleSort ? 'pointer' : 'unset', ...style }}
      onClick={() => (handleSort ? handleSort(property) : {})}
    >
      <span className="sort-feature">
        {children}
      </span>

      <span className="sort-direction">
        {isCurrent
          ? (
            <>
              {sortedBy?.direction === 'asc' && <RiArrowDownLine size="14px" />}
              {sortedBy?.direction === 'desc' && <RiArrowUpLine size="14px" />}
            </>
          )
          : (<RiArrowDownLine size="14px" />)
        }
      </span>
    </span>
  )
};

export { SortBlock };
