import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { kycStatuses } from '../constants';
import { IndividualKycStatusType } from '../constants/kycStatuses';

interface KycStatusChipProps {
  status?: IndividualKycStatusType;
  description?: string;
}

export const KycStatusChip: React.FC<KycStatusChipProps> = ({ status, description }) => {
  const kycStatus = kycStatuses.find((s) => s.value === status) ?? kycStatuses[0];

  return (
    <Tooltip title={description}>
      <Chip size="small" variant="outlined" color={kycStatus?.color} label={kycStatus?.label} />
    </Tooltip>
  );
};
