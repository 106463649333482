import React from 'react';
import { useDispatch } from 'react-redux';
import { BreadCrumbs } from 'uikit';
import { useGetRuleSetsQuery } from 'services/internalApi';
import { updateAllLinks } from '../../../uikit/BreadCrumbs/breadcrumbsSlice';

interface RuleHeaderProps {
  ruleSetId: string;
  ruleName?: string;
}

export const RuleHeader: React.FC<RuleHeaderProps> = ({ ruleSetId, ruleName = 'loading...' }) => {
  const dispatch = useDispatch();
  const { data: ruleSets, isLoading: isRulesetsLoading } = useGetRuleSetsQuery(undefined);
  const ruleSetName = ruleSets?.find((el) => el.id.toString() === ruleSetId)?.name || '';

  const getTitle = (): string =>
    isRulesetsLoading || ruleSetName === '' ? 'loading...' : ruleSetName;

  const navigateLinks = [
    { title: getTitle(), to: `/rulesNew/${ruleSetId}`, state: { state: { isLocked: false } } },
    { title: ruleName },
  ];
  dispatch(updateAllLinks(navigateLinks));

  return <BreadCrumbs />;
};
