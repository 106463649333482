/*
// This function transform data received from the backend
// PEP Sanctions
*/

import { Entities, SearchSessionsResponseContent, TransformResultFunction } from './types';
import { makeTitle } from './SearchSession/makeTitle';
import { personTypeLabel } from './constants';
import { formatDateScreen, parseDateISO } from '../../utils';

const isCompany = (ei: Entities[] | Entities) => ei?.includes('E') || ei?.includes('U');

export const transformResult: TransformResultFunction = (data, isLoading, isFetching) => {
  const searchSessionsList = data?.content
    ?.map((el: SearchSessionsResponseContent) => {
      const { searchRequest } = el;

      const name = `${makeTitle({
        firstName: searchRequest.firstName,
        lastName: searchRequest.lastName,
      })} ${isCompany(searchRequest.entities) ? '[Company]' : ''}`;

      return {
        name,
        type: personTypeLabel[el.type] ?? '-',
        result: {
          decision: el.decision,
          length: el.searchResponse.length,
        },
        lastUpdated: formatDateScreen(parseDateISO(el.performedOn)) ?? '-',
        id: el.id,
        reasonToPerform: el.reasonToPerform,
        status: el.status,
      };
    })
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  return {
    searchSessionsList,
    isLoading,
    isFetching,
    totalElements: data?.totalElements,
    numberOfElements: data?.numberOfElements,
  };
};
