import * as yup from 'yup';
import { ValueLabel } from 'types';
import { titles } from '../constants';
import { FieldList } from '../FormRowInput/types';

interface Options {
  categories: ValueLabel[];
}

export const sections = [
  { id: 1, title: 'Contact Information' },
  { id: 2, title: 'Company Information' },
  { id: 3, title: 'Relation Information' },
];

export const labelMap = {
  // Contact Information
  contactTitle: 'Title',
  contactFirstName: 'First Name',
  contactMiddleName: 'Middle Name',
  contactLastName: 'Last Name',
  contactPosition: 'Title/Position',
  contactEmail: 'Email',

  // Company Information
  companyName: 'Legal Entity Name',
  businessAddress: 'Business Address',
  contactPhone: 'Business Phone Number',
  categoryId: 'Application Category',

  // Relation Information
  salesManager: 'Sales Manager',
  salesPersonEmail: 'Sales Manager Email',
  referralPartner: 'Referral Partner',
  relationshipManagerEmail: 'Relationship Manager Email',
};

export const initialValues = {
  // Contact Information
  contactTitle: '',
  contactFirstName: '',
  contactMiddleName: '',
  contactLastName: '',
  contactPosition: '',
  contactEmail: '',

  // Company Information
  companyName: '',
  businessAddress: '',
  contactPhone: '',
  categoryId: '',

  // Relation Information
  salesManager: '',
  salesPersonEmail: '',
  referralPartner: '',
  relationshipManagerEmail: '',
};

export const createAppValidationSchema = () =>
  yup.object().shape({
    // Contact Information
    contactTitle: yup.string().required(`Please select a ${labelMap.contactTitle}`),
    contactFirstName: yup.string().required(`${labelMap.contactFirstName} is required`),
    contactLastName: yup.string().required(`${labelMap.contactLastName} is required`),
    contactPosition: yup.string().required(`${labelMap.contactPosition} is required`),
    contactEmail: yup
      .string()
      .required(`${labelMap.contactEmail} is required`)
      .email('Please enter a valid email address'),

    // Company Information
    companyName: yup.string().required(`${labelMap.companyName} is required`),
    businessAddress: yup.string().required(`${labelMap.businessAddress} is required`),
    contactPhone: yup.string().required(`${labelMap.contactPhone} is required`),
    categoryId: yup.string().required(`Please select a ${labelMap.categoryId}`),

    // Relation Information
    salesManager: yup.string().required(`${labelMap.salesManager} is required`),
    salesPersonEmail: yup
      .string()
      .email('Please enter a valid email address'),
    relationshipManagerEmail: yup
      .string()
      .email('Please enter a valid email address'),
  });

export const createAppFieldsSchema = ({ options }: { options: Options }): FieldList[] => {
  const initialFields: FieldList[] = [
    // #region Contact Information
    {
      sectionId: 1,
      field: 'radio',
      label: labelMap.contactTitle,
      name: 'contactTitle',
      options: titles,
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactFirstName,
      name: 'contactFirstName',
      placeholder: 'John',
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactMiddleName,
      name: 'contactMiddleName',
      placeholder: 'Lost',
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactLastName,
      name: 'contactLastName',
      placeholder: 'Doe',
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'text',
      label: labelMap.contactPosition,
      name: 'contactPosition',
      placeholder: 'General Manager, CEO etc.',
      editable: true,
    },
    {
      sectionId: 1,
      field: 'text',
      type: 'email',
      label: labelMap.contactEmail,
      name: 'contactEmail',
      placeholder: 'name@domain.com',
      editable: true,
    },
    // #endregion

    // #region Company Information
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.companyName,
      name: 'companyName',
      placeholder: 'Company Name Ltd.',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.businessAddress,
      name: 'businessAddress',
      placeholder: '68-86, Clapham Rd, London SW9 OJJ',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'text',
      type: 'text',
      label: labelMap.contactPhone,
      name: 'contactPhone',
      placeholder: '+1 123 123 12 12',
      editable: true,
    },
    {
      sectionId: 2,
      field: 'select',
      label: labelMap.categoryId,
      name: 'categoryId',
      options: options.categories,
      editable: true,
    },
    // #endregion

    // #region Relation Information
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.salesManager,
      name: 'salesManager',
      placeholder: 'John Doe',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'email',
      label: labelMap.salesPersonEmail,
      name: 'salesPersonEmail',
      placeholder: 'name@domain.com',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'text',
      label: labelMap.referralPartner,
      name: 'referralPartner',
      placeholder: 'John Doe',
      editable: true,
    },
    {
      sectionId: 3,
      field: 'text',
      type: 'email',
      label: labelMap.relationshipManagerEmail,
      name: 'relationshipManagerEmail',
      placeholder: 'name@domain.com',
      editable: true,
    },
    // #endregion
  ];

  return initialFields;
};
