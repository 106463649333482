import { Chip, ChipProps } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';
import React from 'react';

export const QuarantinedChip = (props: ChipProps): JSX.Element => (
  <Chip
    variant="filled"
    color="error"
    size="small"
    label="Virus detected"
    icon={<ErrorIcon />}
    onClick={() => {}}
    {...props}
  />
);
