import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button as MuiButton,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import {
  Button,
  ConfirmDialog,
  IconButton as IconButtonUiKit,
  Input,
  ISelectOption,
  SectionHeading,
  Select,
  SortBlock,
} from 'uikit';
import { ApplicationStatusBox } from 'features/ApplicationManagement/ApplicationRow/ApplicationStatusBox';

import { getCountryLabel } from 'constants/countries';
import { checkProductName } from 'utils';
import { pri } from 'uikit/options';

import {
  useGetKycApplicantsQuery,
  useAddKycApplicantsWithProviderMutation,
  useDeleteKycApplicantMutation,
} from 'services/gatewayApi/kycApplicantsApi';
import {
  useGetKycChecksByApplicationIdQuery,
  useAddKycExternalChecksMutation,
  useDeleteKycCheckMutation,
} from 'services/gatewayApi/kycChecksApi';
import {
  useGetApplicationQuery,
  useGetApplicationHistoryQuery,
  useUpdateApplicationMutation,
} from 'services/gatewayApi/applicationApi';
import { KycApplicantDialog } from 'features/kycApplicants/KycApplicantDialog';
import { KycResultSort, defaultSort } from './types';
import { columns, sortOptions } from './constants';
import { ChecksStatusBox } from './ChecksStatusBox';

import './IDVerifyTab.scss';

const IDVerifyTab = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { data: applicationDetails, isLoading } = useGetApplicationQuery(applicationId);

  // #region Link Generation & Delete
  const [addKycExternalChecks] = useAddKycExternalChecksMutation();
  const [addKycApplicantsWithProvider] = useAddKycApplicantsWithProviderMutation();
  const providerAuthLink = 'https://ivs.idenfy.com/api/v2/redirect?authToken=';
  const [linksNumberToGenerate, setLinksNumberToGenerate] = useState(0);
  const [isLinksGenerating, setIsLinksGenerating] = useState(false);

  const {
    data: links,
    isLoading: isLoadingLinks,
    isFetching: isFetchingLinks,
  } = useGetKycChecksByApplicationIdQuery({ applicationId, hasExtUrl: true });

  const handleGenerateLinks = () => {
    setIsLinksGenerating(true);

    const applicationIds = [];
    for (let i = 0; i < linksNumberToGenerate; i++) {
      applicationIds.push({ applicationId });
    }

    addKycApplicantsWithProvider({ provider: 'IDN', applicants: applicationIds })
      .unwrap()
      .then((result) => {
        const applicantIds = result.map((a) => ({ applicantId: a.id }));

        addKycExternalChecks({ applicationId, applicantIds })
          .unwrap()
          .then((result) => {
            setIsLinksGenerating(false);
          })
          .catch((rejected) => {
            setIsLinksGenerating(true);
            console.error(rejected);
          });
      })
      .catch((rejected) => {
        setIsLinksGenerating(true);
        console.error(rejected);
      });
  };

  const [deleteKycCheck] = useDeleteKycCheckMutation();
  const [deleteKycApplicant] = useDeleteKycApplicantMutation();
  const [isLinkDeleting, setIsLinkDeleting] = useState(false);
  const [linkIdToDelete, setLinkIdToDelete] = useState(null);
  const [applicantIdToDelete, setApplicantIdToDelete] = useState(null);

  const handleLinkDelete = (id, applicantId) => {
    setLinkIdToDelete(id);
    setApplicantIdToDelete(applicantId);
  };

  const confirmLinkAndApplicantDelete = () => {
    setIsLinkDeleting(true);

    deleteKycCheck({ id: linkIdToDelete, applicationId })
      .unwrap()
      .then(() => {
        deleteKycApplicant(applicantIdToDelete)
          .then(() => {
            setIsLinkDeleting(false);
          })
          .catch((rejected) => {
            setIsLinkDeleting(false);
            console.error(rejected);
          });
      })
      .catch((rejected) => {
        setIsLinkDeleting(false);
        console.error(rejected);
      });
  };

  const isLinksProcessing =
    isLoadingLinks || isFetchingLinks || isLinksGenerating || isLinkDeleting;
  // #endregion

  // #region Enable/Disable Customer Portal KYC Check
  const [updateApplication] = useUpdateApplicationMutation();
  const [allowCustomersKyc, setAllowCustomersKyc] = useState(false);
  useEffect(() => {
    setAllowCustomersKyc(applicationDetails ? applicationDetails.allowCustomersKyc : false);
  }, [applicationDetails]);
  const handleAllowCustomersKyc = (checked) => {
    setAllowCustomersKyc(checked);

    // TODO: really unecessary because no endpoint just for allowCustomersKyc update
    const model = {
      id: applicationDetails.id,
      contactTitle: applicationDetails.contactTitle,
      contactFirstName: applicationDetails.contactFirstName,
      contactLastName: applicationDetails.contactLastName,
      companyName: applicationDetails.companyName,
      contactPosition: applicationDetails.contactPosition,
      contactEmail: applicationDetails.contactEmail,
      businessAddress: applicationDetails.businessAddress,
      contactPhone: applicationDetails.contactPhone,
      categoryId: applicationDetails.category,
      salesManager: applicationDetails.salesManager,
      referralPartner: applicationDetails.referralPartner,
      relationshipManager: applicationDetails.relationshipManager,
      allowCustomersKyc: checked, // only field that needs update
    };

    updateApplication(model);
  };
  // #endregion

  // #region KYC Applicants
  const [sortedBy, setSortedBy] = useState<KycResultSort>(defaultSort);
  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };

    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };
  const handleSortProperty = (option: ISelectOption) => {
    setSortedBy((prevState) => ({ ...prevState, property: option.value }));
  };
  const handleSortDirection = () => {
    setSortedBy((prevState) => ({
      ...prevState,
      direction: sortedBy.direction === 'asc' ? 'desc' : 'asc',
    }));
  };
  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: applicantsData,
    isLoading: isLoadingApplicants,
    isFetching: isFetchingApplicants,
  } = useGetKycApplicantsQuery({
    applicationId,
    // kycStatus: kycStatuses.allIds.filter((status) => status != 'NEW'),
    page: 0,
    size: 2000,
    sort: `${sortedBy.property},${sortedBy.direction}`,
  });

  const [applicants, setApplicants] = useState([]);
  useEffect(() => {
    if (applicantsData) {
      setApplicants(applicantsData.content);
    }
  }, [applicantsData]);

  const isApplicantsProcessing = isLoadingApplicants || isFetchingApplicants;
  // #endregion

  const applicantSearchMatch = (item) => {
    if (searchQuery.length === 0) {
      return item;
    }

    const query = searchQuery.trim().toLowerCase();

    return (
      (item.firstName && item.firstName.toLowerCase().includes(query)) ||
      (item.lastName && item.lastName.toLowerCase().includes(query)) ||
      (item.email && item.email.toLowerCase().includes(query))
    );
  };

  const filteredApplicants = applicants && applicants.filter((item) => applicantSearchMatch(item));

  const [showApplicantDialog, setShowApplicantDialog] = useState(false);

  // #region Check MLRO status to disable page for EMB
  const isEmb = checkProductName(['EMBANK']);
  const {
    data: applicationHistory = [],
    isLoading: isLoadingApplicationHistory,
  } = useGetApplicationHistoryQuery(applicationId, {
    skip: !isEmb,
  });
  const latestMLRODecision = applicationHistory?.find((h) => h.applicationStatus === 'PENDING_MLRO_MANAGER_AFTER_AML');
  const isEditDisabled =
    isLoadingApplicationHistory ||
    (isEmb &&
      latestMLRODecision?.decision === 'APPROVE' &&
      applicants?.length ===
        applicants?.filter((applicant) => applicant.kycStatus === 'COMPLETE').length);
  // #endregion

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="IDVerifyTab">
      {/* Disabled temporarily
      <section style={{ marginBottom: '2rem' }}>
        <SectionHeading title="KYC Liveness Check" />

        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="linkGenerate gray-radius">
              <label>Generate KYC Links</label>
              <div>
                <input
                  type="number"
                  min={0}
                  value={linksNumberToGenerate}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > 99) {
                      value = 99;
                    } else if (value < 0) {
                      value = 0;
                    }
                    setLinksNumberToGenerate(value);
                  }}
                  disabled={isEditDisabled}
                />
                <Button
                  theme="primary"
                  disabled={linksNumberToGenerate === 0 || isEditDisabled}
                  onClick={handleGenerateLinks}
                >
                  Generate
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="checkEnable gray-radius">
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={allowCustomersKyc}
                    onChange={(e, checked) => handleAllowCustomersKyc(checked)}
                    disabled={isLoading || isEditDisabled}
                  />
                }
                label="Enable Customer Portal KYC Check"
              />
            </div>
          </Grid>
        </Grid>

        <section>
          {links && links.length > 0 && (
            <div className="dataWrapper">
              <table className={`uikit-table ${isLinksProcessing ? 'uikit-table-inprogress' : ''}`}>
                <tbody>
                  {links.map((link, i) => (
                    <tr key={link.id}>
                      <td className="td-small">{i + 1}</td>
                      <td>
                        {providerAuthLink}
                        {link.extUrl}
                      </td>
                      <td>
                        <ChecksStatusBox statusValue={link.status} />
                      </td>
                      <td>
                        {isLinkDeleting && linkIdToDelete === link.id && <CircularProgress />}
                        {(!isLinkDeleting || linkIdToDelete !== link.id) && (
                          <IconButton
                            onClick={() => handleLinkDelete(link.id, link.applicantId)}
                            disabled={isLinkDeleting || isEditDisabled}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>

        <ConfirmDialog
          showDialog={!!linkIdToDelete}
          setShowDialog={setLinkIdToDelete}
          onCancel={() => setLinkIdToDelete(false)}
          onConfirm={confirmLinkAndApplicantDelete}
          title="Delete Link and Applicant"
          text="Are you sure you want to delete Link and related Applicant?"
          confirmButtonText="Delete"
          confirmButtonColor="defaultDanger"
        />
      </section>
      */}

      <section style={{ marginBottom: '2em' }}>
        <SectionHeading
          title="KYC Applicants"
          actionButtons={
            <div>
              <MuiButton
                color="secondary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setShowApplicantDialog(true)}
                disabled={isEditDisabled}
              >
                Add new applicant
              </MuiButton>
            </div>
          }
        />
        {(filteredApplicants?.length > 0 || searchQuery.trim().length !== 0) && (
          <>
            <div className="KycResultHead">
              <div className="KycResultListToolbar">
                <div className="KycResultListSortBlock">
                  <div style={{ marginRight: '.5rem' }}>Sort by</div>

                  <div style={{ marginRight: '.25rem' }}>
                    <Select
                      value={sortOptions.filter((item) => item.value === sortedBy.property)[0]}
                      options={sortOptions}
                      onChange={(option) => {
                        handleSortProperty(option);
                      }}
                      hasClear={false}
                    />
                  </div>

                  <Tooltip title="Change sort order" disableFocusListener>
                    <IconButtonUiKit theme="text" onClick={() => handleSortDirection()}>
                      {sortedBy.direction === 'asc' ? (
                        <KeyboardArrowDownIcon style={{ color: pri, fontSize: '20px' }} />
                      ) : (
                        <KeyboardArrowUpIcon style={{ color: pri, fontSize: '20px' }} />
                      )}
                    </IconButtonUiKit>
                  </Tooltip>
                </div>

                <div className="text-muted">
                  <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchQuery(e.target.value)
                    }
                    value={searchQuery}
                    placeholder="Search"
                    handleClear={() => setSearchQuery('')}
                  />
                </div>
              </div>
            </div>

            {filteredApplicants.length > 0 && (
              <div className="dataWrapper">
                <table
                  className={`uikit-table ${
                    isApplicantsProcessing ? 'uikit-table-inprogress' : ''
                  }`}
                >
                  <thead>
                    <tr>
                      {columns.map((item, i) => (
                        <th key={i}>
                          <SortBlock
                            property={item.property}
                            sortedBy={sortedBy}
                            handleSort={handleSort}
                          >
                            {item.title}
                          </SortBlock>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredApplicants &&
                      filteredApplicants.map((item, i) => (
                        <tr key={item.id}>
                          <td>
                            <strong>{item.firstName ?? '-'}</strong>
                          </td>
                          <td>
                            <strong>{item.lastName ?? '-'}</strong>
                          </td>
                          {/* <td>{item.email ?? '-'}</td> */}
                          <td>{getCountryLabel(item.docIssuingCountryCode)}</td>
                          <td>{item.provider}</td>
                          <td>
                            <ApplicationStatusBox statusValue={item.kycStatus} />
                          </td>
                          <td>
                            <ApplicationStatusBox statusValue={item.kycResult} />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {filteredApplicants.length === 0 && searchQuery.trim().length > 0 && (
              <div className="dataWrapper no-results">No results...</div>
            )}
          </>
        )}

        {/* No data */}
        {filteredApplicants?.length === 0 && searchQuery.trim().length === 0 && (
          <div className="dataWrapper no-results">No applicants added yet...</div>
        )}
      </section>

      {showApplicantDialog && (
        <KycApplicantDialog
          setShowDialog={setShowApplicantDialog}
          showDialog={showApplicantDialog}
          applicationId={applicationId}
        />
      )}
    </div>
  );
};

export { IDVerifyTab };
