import React from 'react';
import { NavLink } from 'react-router-dom';
import { Chip, Table, TableBody, Typography } from '@mui/material';
import { BodyRow, BodyTableCell, Skeleton } from 'uikit';
import { useGetUboLinkedApplicationsQuery } from 'services/gatewayApi/ubosApi';
import { Ubo } from '../../types';

import './LinksTab.scss';

interface LinksTabProps {
  ubo: Ubo;
}

export const LinksTab: React.FC<LinksTabProps> = ({ ubo }) => {
  const { id } = ubo;
  const {
    data: linkedApplications,
    isLoading,
    isFetching,
  } = useGetUboLinkedApplicationsQuery({ id });

  return (
    <div className="LinksTab">
      {(isLoading || isFetching) && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!(isLoading || isFetching) && linkedApplications && (
        <Table>
          <TableBody>
            {linkedApplications.length > 0 &&
              linkedApplications.map((link) => (
                <BodyRow key={link.linkNumber} hover>
                  <BodyTableCell>
                    <div className="LinksTab_Info">
                      <NavLink to={`/application-management/${link.application.id}/ubos`}>
                        <Typography variant="h4" component="span">
                          {link.linkNumber}
                        </Typography>
                      </NavLink>
                      {link.status === 'ACTIVE' && (
                        <Chip size="small" color="secondary" label="Active" />
                      )}
                      {link.status === 'INACTIVE' && (
                        <Chip size="small" color="error" label="Inactive" />
                      )}
                    </div>
                    <Typography variant="body2" component="span" color="grey.600">
                      {link.positionInCompany}
                    </Typography>
                  </BodyTableCell>
                  <BodyTableCell sx={{ textAlign: 'right' }}>
                    <Typography variant="h3" component="span" color="grey.900">
                      {link.shareholder}%
                    </Typography>
                  </BodyTableCell>
                </BodyRow>
              ))}

            {linkedApplications.length === 0 && (
              <BodyRow className="no-data">
                <BodyTableCell>
                  <Typography variant="body1" component="p" color="grey.900">
                    No linked applications found...
                  </Typography>
                </BodyTableCell>
              </BodyRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
