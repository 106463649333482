export const tableSortOptions = [
  {
    property: 'firstName',
    title: 'Name',
    isSortable: true,
  },
  {
    property: 'email',
    title: 'Email',
    isSortable: true,
  },
  {
    property: 'role',
    title: 'Role',
    isSortable: false,
  },
  {
    property: 'kycStatus',
    title: 'KYC Status',
    isSortable: true,
  },
];
