import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { Acquirer } from '../../types';
import { detailsFields } from '../../constants';
import { ResponseCodeRow } from './ResponseCodeRow';
import { ResponseCodeDialog } from './ResponseCodeDialog';

interface DetailsTabProps {
  acquirer: Acquirer;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ acquirer }) => {
  const { id, responseCode: responseCodes } = acquirer;

  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

  const RenderValue = ({ property }) => {
    const value = acquirer[property];
    if (!value) {
      return <Typography>-</Typography>;
    }

    switch (property) {
      case 'createdDate':
        return <Typography>{formatIsoDate(value, DATE_FORMAT_MESSAGES)}</Typography>;

      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <>
      {detailsFields.map((field) => (
        <Box
          key={field.value}
          sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
        >
          <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
            {field.label}
          </Typography>
          <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
            <RenderValue property={field.value} />
          </Box>
        </Box>
      ))}

      <Typography variant="h4" sx={{ pt: 3, pb: 1, color: 'grey.900' }}>
        Response Codes
      </Typography>

      {responseCodes.length > 0 &&
        responseCodes
          .slice()
          .sort((a, b) => (a.responseCode > b.responseCode ? 1 : -1))
          .map((item) => <ResponseCodeRow item={item} />)}

      {responseCodes.length === 0 && (
        <div className="no-data">
          <Typography variant="body1" component="p" color="grey.900">
            No records found...
          </Typography>
        </div>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowCreateDialog(true)}
        type="button"
        startIcon={<AddCircleIcon />}
      >
        Add Response Code
      </Button>

      {showCreateDialog && (
        <ResponseCodeDialog
          open={showCreateDialog}
          onClose={() => setShowCreateDialog(false)}
          acquirerId={id}
        />
      )}
    </>
  );
};
