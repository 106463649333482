import React, { useEffect, useState } from 'react';
import { Box, Chip, Tooltip, LinearProgress, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import MUIDataTable from '../../components/MUIDataTable';
import { MUIDatatableSkeleton } from '../../components';
import CountryRiskHistory from './CountryRiskHistory';
import { CountryRiskFormDialog } from './CountryRiskFormDialog';
import {
  useGetAllRiskCountriesQuery,
  useGetAllEeaRiskCountriesQuery,
  useGetAllCountriesQuery,
} from '../../services/gatewayApi/countryRiskApi';

import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { riskStatuses } from './constants';
import { combineDateTime } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import {
  useCanViewRiskCountry,
  useCanViewEeaRiskCountry,
  useCanEditRiskCountry,
  useCanEditEeaRiskCountry,
} from '../../hooks/useHasPermissions';

export const CountryRiskList = () => {
  const canViewRiskCountry = useCanViewRiskCountry();
  const canViewEeaRiskCountry = useCanViewEeaRiskCountry();

  const canEditRiskCountry = useCanEditRiskCountry();
  const canEditEeaRiskCountry = useCanEditEeaRiskCountry();

  const classes = makeStyles(() => ({
    centeredHeader: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  }))();

  const {
    countries = [],
    isLoadingCountries,
    isFetchingCountries,
  } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      countries: data?.map((country) => combineDateTime(country, formatDate)),
      isLoadingCountries: isLoading,
      isFetchingCountries: isFetching,
    }),
  });
  const {
    data: riskCountries,
    isLoading: isLoadingRiskCountries,
    isFetching: isFetchingRiskCountries,
  } = useGetAllRiskCountriesQuery(undefined, { skip: !canViewRiskCountry });
  const {
    data: eeaRiskCountries,
    isLoading: isLoadingEeaRiskCountries,
    isFetching: isFetchingEeaRiskCountries,
  } = useGetAllEeaRiskCountriesQuery(undefined, {
    skip: !canViewEeaRiskCountry,
  });

  const isLoading =
    isLoadingCountries ||
    isLoadingRiskCountries ||
    isLoadingEeaRiskCountries;

  const isFetching =
    isFetchingCountries ||
    isFetchingRiskCountries ||
    isFetchingEeaRiskCountries;

  const [countriesMerged, setCountriesMerged] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [rowExpanded, setRowExpanded] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    name: 'name',
    direction: 'asc',
  });

  useEffect(() => {
    if (!isLoading && !isFetching && !!countries) {
      setCountriesMerged(
        countries.map((country) => {
          const countryMerged = { ...country };
          if (canViewRiskCountry)
            countryMerged.riskStatus =
              riskCountries?.entities[country.countryCode]?.riskStatus || 'DELETED';
          if (canViewEeaRiskCountry) {
            countryMerged.eeaRiskStatus =
              eeaRiskCountries?.entities[country.countryCode]?.riskStatus || 'DELETED';
          }
          return countryMerged;
        }),
      );
    }
  }, [isLoading, isFetching]);

  const handleEdit = (idx) => {
    setFormData(countriesMerged[idx]);
    setFormOpen(true);
  };

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filterType: 'multiselect',
    print: false,
    download: false,
    viewColumns: false,
    expandableRows: true,
    expandableRowsHeader: false,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    rowsExpanded: [rowExpanded.dataIndex],
    onRowExpansionChange: (curExpanded) =>
      rowExpanded.dataIndex !== curExpanded[0].dataIndex
        ? setRowExpanded(curExpanded[0])
        : setRowExpanded([]),
    jumpToPage: true,
    // Dirty hack to prevent resetting selected sort to defined in options sortOrder on every table rerender
    // Possibly, there is a bug in (MUIDataTable.js/setTableData())
    // State of the sortOrder moved outside of the mui-datatable and controlled by the component itself
    onTableChange: (action, state) => {
      if (action === 'sort') setSortOrder(state.sortOrder);
    },
    sortOrder,
    selectableRows: 'none',
    renderExpandableRow: (rowData, rowMeta) => {
      const idx = rowMeta.dataIndex;
      return <CountryRiskHistory countryCode={countriesMerged[idx].countryCode} />;
    },
  };

  const riskStatusOptions = {
    setCellHeaderProps: () => ({
      className: classes.centeredHeader,
      width: '100px',
    }),
    setCellProps: () => ({
      align: 'center',
    }),
    filterOptions: {
      renderValue: (value) => riskStatuses.byId[value]?.label,
      fullWidth: true,
    },
    customFilterListOptions: {
      render: (value) => riskStatuses.byId[value]?.label,
    },
    customBodyRender: (value) => (
      <Chip
        variant="contained"
        label={riskStatuses.byId[value]?.label}
        color={riskStatuses.byId[value]?.color}
      />
    ),
    sortCompare: (order) => (obj1, obj2) =>
      (riskStatuses.byId[obj1.data].priority - riskStatuses.byId[obj2.data].priority) *
      (order === 'asc' ? 1 : -1),
  };

  const columns = [
    {
      name: 'name',
      label: 'Country',
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: '160px' } }),
      },
    },
    {
      name: 'countryCode',
      label: 'Code',
      options: {
        filter: false,
      },
    },
    {
      name: 'riskStatus',
      label: 'Risk Status',
      options: {
        ...riskStatusOptions,
        display: canViewRiskCountry,
        filter: canViewRiskCountry,
      },
    },
    {
      name: 'eeaRiskStatus',
      label: 'EEA/UK Risk Status',
      options: {
        ...riskStatusOptions,
        display: canViewEeaRiskCountry,
        filter: canViewEeaRiskCountry,
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRenderLite: (dataIndex) => (
          <>
            {canEditRiskCountry || canEditEeaRiskCountry ? (
              <Tooltip title="Edit" disableFocusListener>
                <IconButton onClick={() => handleEdit(dataIndex)} size="large">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        {isLoading ? (
          <MUIDatatableSkeleton />
        ) : (
          <>
            <Box visibility={isFetching ? 'visible' : 'hidden'}>
              <LinearProgress />
            </Box>
            <MUIDataTable data={countriesMerged} columns={columns} options={options} />
          </>
        )}
        <CountryRiskFormDialog open={formOpen} onClose={() => setFormOpen(false)} data={formData} />
      </div>
    </div>
  );
};
