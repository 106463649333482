import React from 'react';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { BodyRow, BodyTableCell } from 'uikit';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { KycApplicant } from './types';
import { extKycStatuses } from './constants';

interface KycApplicantRowProps {
  item: KycApplicant;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
}

export const KycApplicantRow: React.FC<KycApplicantRowProps> = ({
  item,
  setSelectedItemId,
  setOpenDetailsDrawer,
  selected = false,
}) => {
  const { id, firstName, lastName, email, extKycStatus, createdDate, modifiedDate } = item;

  const renderextKycStatus = () => {
    const status = extKycStatuses.byId[extKycStatus];
    return extKycStatus || status ? (
      <Chip label={status?.label ?? extKycStatus} color={status?.color ?? 'primary'} size="small" />
    ) : null;
  };

  const renderManualResult = () => {
    const { manualResult, manualDecisionByName, manualDecisionDate } = item;

    const result = extKycStatuses.byId[manualResult == 'CLEAR' ? 'APPROVED' : 'DENIED']; // BE saves manualResult as CLEAR or CONSIDER
    return manualResult && result ? (
      <Tooltip title={`By ${manualDecisionByName} @ ${formatIsoDate(manualDecisionDate)}`}>
        <Chip
          label={result?.label ?? manualResult}
          color={result?.color ?? 'primary'}
          size="small"
        />
      </Tooltip>
    ) : null;
  };

  return (
    <BodyRow
      hover
      selected={selected}
      onClick={() => {
        setSelectedItemId(id);
        setOpenDetailsDrawer(true);
      }}
      sx={!selected ? { cursor: 'pointer' } : {}}
    >
      <BodyTableCell>{firstName}</BodyTableCell>
      <BodyTableCell>{lastName}</BodyTableCell>
      <BodyTableCell>{email}</BodyTableCell>
      <BodyTableCell>{renderextKycStatus()}</BodyTableCell>
      <BodyTableCell>{renderManualResult()}</BodyTableCell>
      <BodyTableCell>{formatIsoDate(createdDate, DATE_FORMAT_MESSAGES)}</BodyTableCell>
      <BodyTableCell>{formatIsoDate(modifiedDate, DATE_FORMAT_MESSAGES)}</BodyTableCell>
      <BodyTableCell className="displayOnHover">
        <IconButton color="inherit" size="small">
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </BodyTableCell>
    </BodyRow>
  );
};
