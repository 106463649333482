import { gatewayApi } from '../gatewayApi';

export interface ApplicationNote {
  id: string;
  applicationId: string;
  title: string;
  isDraft: boolean;
  isInternal: boolean;
  text?: string;
  isSummary?: boolean;
  tenantId?: number;

  createdBy?: string;
  createdByName?: string;
  createdDate?: string;

  modifiedBy?: string;
  modifiedByName?: string;
  modifiedDate?: string;
}

type AddApplicationNotePayload = Pick<
  ApplicationNote,
  'applicationId' | 'title' | 'text' | 'isSummary' | 'isDraft' | 'isInternal' | 'id'
>;
type UpdateApplicationNotePayload = Partial<Pick<ApplicationNote, 'title' | 'text' | 'isSummary'>> &
  Pick<ApplicationNote, 'id' | 'applicationId'>;
type ApplicationNotesFilter = Partial<ApplicationNote>;

export const applicationNotesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getNote: build.query<ApplicationNote, Pick<ApplicationNote, 'applicationId'>>({
      query: ({ applicationId }) => ({
        url: `applications/${applicationId}/notes`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ApplicationNote', id: 'LIST' }],
    }),

    getNotesByFilter: build.query<Array<ApplicationNote>, ApplicationNotesFilter>({
      query: ({ applicationId, ...rest }) => ({
        url: `applications/${applicationId}/notes`,
        params: { ...rest },
        method: 'GET',
      }),
      providesTags: [{ type: 'ApplicationNote', id: 'LIST' }],
    }),

    addNote: build.mutation<ApplicationNote, Omit<AddApplicationNotePayload, 'id'>>({
      query: ({ applicationId, ...rest }) => ({
        url: `applications/${applicationId}/notes`,
        method: 'POST',
        body: { ...rest },
      }),
      onQueryStarted({ applicationId, ...rest }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationNotesApi.util.updateQueryData(
            'getNotesByFilter',
            { applicationId },
            (draft) => {
              draft.unshift({ id: 'new', applicationId, ...rest });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: [{ type: 'ApplicationNote', id: 'LIST' }],
    }),

    updateNote: build.mutation<ApplicationNote, UpdateApplicationNotePayload>({
      query: ({ applicationId, id, ...rest }) => ({
        url: `applications/${applicationId}/notes/${id}`,
        method: 'PUT',
        body: { ...rest },
      }),
      onQueryStarted({ applicationId, id, ...rest }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationNotesApi.util.updateQueryData(
            'getNotesByFilter',
            { applicationId },
            (draft) => {
              const note = draft.find((n) => n.id === id);
              Object.assign(note, { ...rest });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: [{ type: 'ApplicationNote', id: 'LIST' }],
    }),

    deleteNote: build.mutation({
      query: ({ id, applicationId }) => ({
        url: `applications/${applicationId}/notes/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, applicationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationNotesApi.util.updateQueryData(
            'getNotesByFilter',
            { applicationId },
            (draft) => (draft = draft.filter((n) => n.id !== id)),
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: () => [{ type: 'ApplicationNote', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetNoteQuery,
  useGetNotesByFilterQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = applicationNotesApi;
