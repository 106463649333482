import React from 'react';

interface TabProps {
  title?: string;
  subTitle?: string;
  count?: number;
  disabled?: boolean;
  hidden?: boolean;
  hasActivity?: boolean;
  children: any;
  isLoading?: boolean;
}

export const Section: React.FC<TabProps> = ({
  // Most of the properties are used when rendering SectionTitle by parent (TabContainer)
  title = 'No title',
  subTitle = '',
  count,
  disabled = false,
  hidden = false,
  hasActivity = false,
  children,
  isLoading = false,
}) => <div className={`Tab${isLoading ? 'Section--IsLoading' : ''}`}>{children}</div>;
