import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Input } from '@mui/base';
import { InputTransparentInput } from '../index';
import { VirusStatus } from '../../services/gatewayApi/applicationDocumentsApi';

interface DocumentTitleProps {
  title: string;
  fileName?: string;
  isRejected?: boolean;
  openRejectedModal?: () => any;
  virusStatus?: VirusStatus;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const DocumentTitle = ({
  title,
  fileName,
  isRejected,
  openRejectedModal,
  virusStatus,
  onChange,
  onKeyPress,
  children,
}: React.PropsWithChildren<DocumentTitleProps>): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    onChange && ref.current?.focus();
  }, [onChange]);

  return (
    <Box
      className="documentTitleWrapper"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      height="100%"
    >
      {!onChange ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            py="6px"
            className="documentTitleContent"
            gap="4px"
          >
            {children}
            <Typography variant="body" color="grey.600">
              {title}
            </Typography>
          </Box>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isRejected ? (
            <Typography
              variant="bodySmall"
              color="error.main"
              sx={{ cursor: 'pointer' }}
              onClick={openRejectedModal}
            >
              Rejected
            </Typography>
          ) : // eslint-disable-next-line no-nested-ternary
            virusStatus === VirusStatus.Quarantined ? (
              <Typography variant="bodySmall" color="danger.main">
                Incompatible file. Try another file please.
              </Typography>
            ) : virusStatus === VirusStatus.Pending ? (
              <Typography variant="bodySmall" color="grey.600">
                Scanning...
              </Typography>
            ) : (
              fileName && (
                <Typography variant="bodySmall" color="text.link">
                  {fileName}
                </Typography>
              )
            )}
          {!fileName &&
            !isRejected &&
            virusStatus !== VirusStatus.Quarantined &&
            virusStatus !== VirusStatus.Pending && (
              <Typography variant="bodySmall" color="text.placeholder">
                Upload a file here
              </Typography>
            )}
        </>
      ) : (
        // Likely MUI/TS issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Typography
          className="documentTitleInput"
          variant="body"
          width="100%"
          component={Input}
          slots={{ input: InputTransparentInput }}
          slotProps={{
            input: {
              ref,
            },
          }}
          value={title}
          placeholder="Please enter a document name"
          onChange={onChange}
          onKeyDown={onKeyPress}
          sx={{ py: '6px' }}
        />
      )}
    </Box>
  );
};
