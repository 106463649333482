// https://documentation.idenfy.com/callbacks/ResultCallback
export const idenfyTags = [
  {
    key: 'overall',
    value:
      'An overall status of the verification. It is a combination of manual and automatic verification results.',
  },
  {
    key: 'fraudTags',
    value: 'A list of fraud tags (strings) indicating why verification was SUSPECTED.',
  },
  {
    key: 'mismatchTags',
    value:
      'A list of mismatch tags (strings) indicating where partner provided document information does not match with the read information. Verification overall status was SUSPECTED if any mismatchTags exist.',
  },
  {
    key: 'autoFace',
    value: 'An automatic face analysis result (decision made by an automated platform).',
  },
  {
    key: 'manualFace',
    value: 'A Final face analysis result (decision made by a automatic system and human).',
  },
  {
    key: 'autoDocument',
    value: 'An automatic document analysis result (decision made by an automated platform).',
  },
  {
    key: 'manualDocument',
    value: 'A Final document analysis result (decision made by a automatic system and human).',
  },
  {
    key: 'additionalSteps',
    value: 'An additional steps result (decision made by human).',
  },
];
