import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const riskScoringAnswersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskScoringAnswersByApplication: build.query({
      query: (applicationId) => ({
        url: `risk-scoring/${applicationId}/answers`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'RiskScoring_Answers', id: applicationId },
      ],
    }),

    getRiskScoringAnswersTotalScoreByApplication: build.query({
      query: (applicationId) => ({
        url: `risk-scoring/${applicationId}/answers/totalScore`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'RiskScoring_AnswersTotalScore', id: applicationId },
      ],
    }),

    addRiskScoringAnswer: build.mutation({
      query(model) {
        const body = { ...model };
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/${model.applicationId}/answers`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'RiskScoring_Answers', id: applicationId },
        { type: 'RiskScoring_AnswersTotalScore', id: applicationId },
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id: applicationId },
      ],
    }),

    updateRiskScoringAnswer: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/${model.applicationId}/answers/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'RiskScoring_Answers', id: applicationId },
        { type: 'RiskScoring_AnswersTotalScore', id: applicationId },
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id: applicationId },
      ],
    }),

    deleteRiskScoringAnswer: build.mutation({
      query: ({ applicationId, id }) => ({
        url: `risk-scoring/${applicationId}/answers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'RiskScoring_Answers', id: applicationId },
        { type: 'RiskScoring_AnswersTotalScore', id: applicationId },
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id: applicationId },
      ],
    }),

    submitRiskScoring: build.mutation({
      query: (applicationId) => ({
        url: `risk-scoring/${applicationId}/answers/submit`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, applicationId) => [
        { type: 'Application', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetRiskScoringAnswersByApplicationQuery,
  useGetRiskScoringAnswersTotalScoreByApplicationQuery,
  useAddRiskScoringAnswerMutation,
  useUpdateRiskScoringAnswerMutation,
  useDeleteRiskScoringAnswerMutation,
  useSubmitRiskScoringMutation,
} = riskScoringAnswersApi;
