import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import { cardActions } from './constants';

const CardDataSearchForm = (props) => {
  const { setFilterValues } = props;
  const initialValues = {
    cardNumber: '',
    maskedCardNumber: '',
    cardActions: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFilterValues(values);
      formik.setSubmitting(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialValues);
  };

  return (
    <div className="grid simple horizontal yellow">
      <div className="grid-title">
        <h4>
          <span>Filter Data</span>
        </h4>
      </div>
      <div className="grid-body">
        <form id="filterForm" onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container spacing={4}>
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id="cardNumber"
                name="cardNumber"
                label="Card Number"
                fullWidth
                size="small"
                value={formik.values.cardNumber}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField
                id="maskedCardNumber"
                name="maskedCardNumber"
                label="Masked Card Number"
                fullWidth
                size="small"
                value={formik.values.maskedCardNumber}
                onChange={formik.handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                id="cardActions"
                fullWidth
                size="small"
                multiple
                options={cardActions}
                value={
                  formik.values.cardActions
                    ? cardActions.find((item) => item.value === formik.values.cardActions)
                    : null
                }
                onChange={(event, options) => {
                  const selected = options.map((x) => x.value);
                  formik.setFieldValue(
                    'cardActions',
                    options.length ? selected : [], // Beware, usea multi selection
                  );
                }}
                autoHighlight
                getOptionLabel={(option) => option.label || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Card Action"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                variant="contained"
                color="primary"
                form="filterForm"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <SearchIcon style={{ marginRight: 5 }} />
                Search
              </Button>
              <Button onClick={handleReset} style={{ marginLeft: 10 }}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

CardDataSearchForm.propTypes = {
  setFilterValues: PropTypes.func,
};

export default CardDataSearchForm;
