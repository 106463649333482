import { FigmaColor } from '../theme';

export const TRANSACTIONS_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE = 20;
export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_SERVER_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_SERVER_FORMAT = 'yyyy-MM-ddTHH:mm:ss';
export const DATE_FORMAT_MESSAGES = 'dd MMM yyyy';
export const DATETIME_FORMAT = 'dd-MM-yyyy HH:mm';
export const DATETIMESEC_FORMAT = 'dd-MM-yyyy HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const DATE_MMM_FORMAT = 'dd MMM';
export const TOOLTIP_DELAY = 1000; // milliseconds delay before tooltip show

export const DATETIMESEC_FORMAT_MOMENT = 'DD-MM-YYYY HH:mm:ss';
export const DATETIME_FORMAT_MOMENT = 'DD/MM/YYYY h:mm A';
export const DATE_FORMAT_MOMENT = 'DD-MM-YYYY';

export const CHART_UPDATE_INTERVAL = 300000;

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

export const fillColorMap = {
  PASS: FigmaColor({ colorName: 'green', shade: 900 }),
  LOWRISK: FigmaColor({ colorName: 'green', shade: 700 }),
  MEDIUMRISK: FigmaColor({ colorName: 'orange', shade: 600 }),
  HIGHRISK: FigmaColor({ colorName: 'orange', shade: 800 }),
  MODERATION: FigmaColor({ colorName: 'red', shade: 700 }),
  REJECT: FigmaColor({ colorName: 'red', shade: 900 }),
  ERROR: FigmaColor({ colorName: 'grey-blue', shade: 910 }),
};

export const GET_CARD_DATA = 'GET_CARD_DATA';
export const GET_CARD_DATA_SUCCESS = 'GET_CARD_DATA_SUCCESS';
export const GET_CARD_DATA_FAIL = 'GET_CARD_DATA_FAIL';
export const UPDATE_CARD_DATA = 'UPDATE_CARD_DATA';
export const UPDATE_CARD_DATA_SUCCESS = 'UPDATE_CARD_DATA_SUCCESS';
export const UPDATE_CARD_DATA_FAIL = 'UPDATE_CARD_DATA_FAIL';

export const GET_VALIDATOR_TRANSACTIONS = 'GET_VALIDATOR_TRANSACTIONS';
export const GET_VALIDATOR_TRANSACTIONS_SUCCESS = 'GET_VALIDATOR_TRANSACTIONS_SUCCESS';
export const GET_VALIDATOR_TRANSACTIONS_FAIL = 'GET_VALIDATOR_TRANSACTIONS_FAIL';
export const GET_VALIDATOR_TRANSACTIONS_CARD_DATA = 'GET_VALIDATOR_TRANSACTIONS_CARD_DATA';
export const GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS =
  'GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS';
export const GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL =
  'GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL';

export const LOAD_RULES = 'LOAD_RULES';
export const LOAD_RULES_SUCCESS = 'LOAD_RULES_SUCCESS';
export const LOAD_RULES_FAIL = 'LOAD_RULES_FAIL';

export const LOAD_ENVIRONMENT = 'LOAD_ENVIRONMENT';
export const LOAD_ENVIRONMENT_SUCCESS = 'LOAD_ENVIRONMENT_SUCCESS';
export const LOAD_ENVIRONMENT_FAIL = 'LOAD_ENVIRONMENT_FAIL';

export const LOAD_CASES = 'LOAD_CASES';
export const LOAD_CASES_SUCCESS = 'LOAD_CASES_SUCCESS';
export const LOAD_CASES_FAIL = 'LOAD_CASES_FAIL';
export const SELECT_CASE = 'SELECT_CASE';
export const BULK_UPDATE_CASES = 'BULK_UPDATE_CASES';
export const BULK_UPDATE_CASES_COMPLETE = 'BULK_UPDATE_CASES_COMPLETE';
export const BULK_UPDATE_CASES_FAIL = 'BULK_UPDATE_CASES_FAIL';
export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAIL = 'UPDATE_CASE_FAIL';
export const SHOW_ASSIGN_CASE = 'SHOW_ASSIGN_CASE';
export const SHOW_UPDATE_STATUS = 'SHOW_UPDATE_STATUS';
export const CLOSE_ASSIGN_CASE = 'CLOSE_ASSIGN_CASE';
export const CLOSE_UPDATE_STATUS = 'CLOSE_UPDATE_STATUS';
export const CREATE_JIRA_CASE = 'CREATE_JIRA_CASE';
export const CREATE_JIRA_CASE_SUCCESS = 'CREATE_JIRA_CASE_SUCCESS';
export const CREATE_JIRA_CASE_FAIL = 'CREATE_JIRA_CASE_FAIL';

export const LOAD_CASE_COMMENTS = 'LOAD_CASE_COMMENTS';
export const LOAD_CASE_COMMENTS_SUCCESS = 'LOAD_CASE_COMMENTS_SUCCESS';
export const LOAD_CASE_COMMENTS_FAIL = 'LOAD_CASE_COMMENTS_FAIL';
export const CREATE_CASE_COMMENT = 'CREATE_CASE_COMMENT';
export const CREATE_CASE_COMMENT_SUCCESS = 'CREATE_CASE_COMMENT_SUCCESS';
export const CREATE_CASE_COMMENT_FAIL = 'CREATE_CASE_COMMENT_FAIL';
export const UPDATE_CASE_COMMENT = 'UPDATE_CASE_COMMENT';
export const UPDATE_CASE_COMMENT_SUCCESS = 'UPDATE_CASE_COMMENT_SUCCESS';
export const UPDATE_CASE_COMMENT_FAIL = 'UPDATE_CASE_COMMENT_FAIL';
export const DELETE_CASE_COMMENT = 'DELETE_CASE_COMMENT';
export const DELETE_CASE_COMMENT_SUCCESS = 'DELETE_CASE_COMMENT_SUCCESS';
export const DELETE_CASE_COMMENT_FAIL = 'DELETE_CASE_COMMENT_FAIL';
export const CREATING_COMMENT = 'CREATING_COMMENT';
export const UPDATING_COMMENT = 'UDPATING_COMMENT';
export const CANCEL_CREATING_COMMENT = 'CANCEL_CREATING_COMMENT';
export const CANCEL_UPDATING_COMMENT = 'CANCEL_UPDATING_COMMENT';

export const LOAD_EXCEPTION_RULES = 'LOAD_EXCEPTION_RULES';
export const LOAD_EXCEPTION_RULES_SUCCESS = 'LOAD_EXCEPTION_RULES_SUCCESS';
export const LOAD_EXCEPTION_RULES_FAIL = 'LOAD_EXCEPTION_RULES_FAIL';
export const CREATE_EXCEPTION_RULE = 'CREATE_EXCEPTION_RULE';
export const CREATE_EXCEPTION_RULE_SUCCESS = 'CREATE_EXCEPTION_RULE_SUCCESS';
export const CREATE_EXCEPTION_RULE_FAIL = 'CREATE_EXCEPTION_RULE_FAIL';
export const CREATE_TRANSACTION_EXCEPTION_RULE = 'CREATE_TRANSACTION_EXCEPTION_RULE';
export const CREATE_TRANSACTION_EXCEPTION_RULE_SUCCESS =
  'CREATE_TRANSACTION_EXCEPTION_RULE_SUCCESS';
export const CREATE_TRANSACTION_EXCEPTION_RULE_FAIL = 'CREATE_TRANSACTION_EXCEPTION_RULE_FAIL';
export const LOAD_SELECTED_EXCEPTION_RULE = 'LOAD_SELECTED_EXCEPTION_RULE';
export const LOAD_SELECTED_EXCEPTION_RULE_SUCCESS = 'LOAD_SELECTED_EXCEPTION_RULE_SUCCESS';
export const LOAD_SELECTED_EXCEPTION_RULE_FAIL = 'LOAD_SELECTED_EXCEPTION_RULE_FAIL';
export const UPDATE_EXCEPTION_RULE = 'UPDATE_EXCEPTION_RULE';
export const UPDATE_EXCEPTION_RULE_SUCCESS = 'UPDATE_EXCEPTION_RULE_SUCCESS';
export const UPDATE_EXCEPTION_RULE_FAIL = 'UPDATE_EXCEPTION_RULE_FAIL';
export const DELETE_EXCEPTION_RULE = 'DELETE_EXCEPTION_RULE';
export const DELETE_EXCEPTION_RULE_SUCCESS = 'DELETE_EXCEPTION_RULE_SUCCESS';
export const DELETE_EXCEPTION_RULE_FAIL = 'DELETE_EXCEPTION_RULE_FAIL';
export const OPEN_TRANSACTION_MODAL_FORM = 'OPEN_TRANSACTION_MODAL_FORM';
export const CLOSE_TRANSACTION_MODAL_FORM = 'CLOSE_TRANSACTION_MODAL_FORM';

export const LOAD_CASE_ASSIGNMENTS = 'LOAD_CASE_ASSIGNMENTS';
export const LOAD_CASE_ASSIGNMENTS_SUCCESS = 'LOAD_CASE_ASSIGNMENTS_SUCCESS';
export const LOAD_CASE_ASSIGNMENTS_FAIL = 'LOAD_CASE_ASSIGNMENTS_FAIL';
export const CREATE_CASE_ASSIGNMENT = 'CREATE_CASE_ASSIGNMENT';
export const CREATE_CASE_ASSIGNMENT_SUCCESS = 'CREATE_CASE_ASSIGNMENT_SUCCESS';
export const CREATE_CASE_ASSIGNMENT_FAIL = 'CREATE_CASE_ASSIGNMENT_FAIL';
export const DELETE_CASE_ASSIGNMENT = 'DELETE_CASE_ASSIGNMENT';
export const DELETE_CASE_ASSIGNMENT_SUCCESS = 'DELETE_CASE_ASSIGNMENT_SUCCESS';
export const DELETE_CASE_ASSIGNMENT_FAIL = 'DELETE_CASE_ASSIGNMENT_FAIL';
export const SHOW_ASSIGNMENT_FORM = 'SHOW_ASSIGNMENT_FORM';
export const CLOSE_ASSIGNMENT_FORM = 'CLOSE_ASSIGNMENTS_FORM';

export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAIL = 'LOAD_TRANSACTIONS_FAIL';
export const REMOVE_COMPLETED_TRANSACTION = 'REMOVE_COMPLETED_TRANSACTION';

export const LOAD_TRANSACTION_TYPES = 'LOAD_TRANSACTION_TYPES';
export const LOAD_TRANSACTION_TYPES_SUCCESS = 'LOAD_TRANSACTION_TYPES_SUCCESS';
export const LOAD_TRANSACTION_TYPES_FAIL = 'LOAD_TRANSACTION_TYPES_FAIL';

export const LOAD_TRANSACTION_CURRENCIES = 'LOAD_TRANSACTION_CURRENCIES';
export const LOAD_TRANSACTION_CURRENCIES_SUCCESS = 'LOAD_TRANSACTION_CURRENCIES_SUCCESS';
export const LOAD_TRANSACTION_CURRENCIES_FAIL = 'LOAD_TRANSACTION_CURRENCIES_FAIL';

export const LOAD_TRANSACTION_COUNTRIES = 'LOAD_TRANSACTION_COUNTRIES';
export const LOAD_TRANSACTION_COUNTRIES_SUCCESS = 'LOAD_TRANSACTION_COUNTRIES_SUCCESS';
export const LOAD_TRANSACTION_COUNTRIES_FAIL = 'LOAD_TRANSACTION_COUNTRIES_FAIL';

export const LOAD_RISK_COUNTRIES = 'LOAD_RISK_COUNTRIES';
export const LOAD_RISK_COUNTRIES_SUCCESS = 'LOAD_RISK_COUNTRIES_SUCCESS';
export const LOAD_RISK_COUNTRIES_FAIL = 'LOAD_RISK_COUNTRIES_FAIL';

export const LOAD_SANCTION_TRANSACTION = 'LOAD_SANCTION_TRANSACTION';
export const LOAD_SANCTION_TRANSACTION_SUCCESS = 'LOAD_SANCTION_TRANSACTION_SUCCESS';
export const LOAD_SANCTION_TRANSACTION_FAIL = 'LOAD_SANCTION_TRANSACTION_FAIL';
export const LOAD_SANCTION_DETAIL = 'LOAD_SANCTION_DETAIL';
export const LOAD_SANCTION_DETAIL_SUCCESS = 'LOAD_SANCTION_DETAIL_SUCCESS';
export const LOAD_SANCTION_DETAIL_FAIL = 'LOAD_SANCTION_DETAIL_FAIL';

export const LOAD_COLLECTIONS = 'LOAD_COLLECTIONS';
export const LOAD_COLLECTIONS_SUCCESS = 'LOAD_COLLECTIONS_SUCCESS';
export const LOAD_COLLECTIONS_FAIL = 'LOAD_COLLECTIONS_FAIL';

export const LOAD_COLLECTION_ITEMS = 'LOAD_COLLECTION_ITEMS';
export const LOAD_COLLECTION_ITEMS_SUCCESS = 'LOAD_COLLECTION_ITEMS_SUCCESS';
export const LOAD_COLLECTION_ITEMS_FAIL = 'LOAD_COLLECTION_ITEMS_FAIL';

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_FAIL = 'UPDATE_COLLECTION_FAIL';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_FAIL = 'DELETE_COLLECTION_FAIL';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';

export const EXPORT_REPORT = 'EXPORT_REPORT';
export const EXPORT_REPORT_FAIL = 'EXPORT_REPORT_FAIL';
export const EXPORT_REPORT_SUCCESS = 'EXPORT_REPORT_SUCCESS';

export const LOAD_CUSTOMER_INFO = 'LOAD_CUSTOMER_INFO';
export const LOAD_CUSTOMER_INFO_SUCCESS = 'LOAD_CUSTOMER_INFO_SUCCESS';
export const LOAD_CUSTOMER_INFO_FAIL = 'LOAD_CUSTOMER_INFO_FAIL';

export const LOAD_USERINFO = 'LOAD_USERINFO';
export const LOAD_USERINFO_SUCCESS = 'LOAD_USERINFO_SUCCESS';
export const LOAD_USERINFO_FAIL = 'LOAD_USERINFO_FAIL';

export const LOAD_CARD_WIDGET = 'LOAD_CARD_WIDGET';
export const LOAD_CARD_WIDGET_SUCCESS = 'LOAD_CARD_WIDGET_SUCCESS';
export const LOAD_CARD_WIDGET_FAIL = 'LOAD_CARD_WIDGET_FAIL';

export const LOAD_LOGS = 'LOAD_LOGS';
export const LOAD_LOGS_SUCCESS = 'LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAIL = 'LOAD_LOGS_FAIL';

export const LOAD_CUSTOMER_TOTALS = 'LOAD_CUSTOMER_TOTALS';
export const LOAD_CUSTOMER_TOTALS_SUCCESS = 'LOAD_CUSTOMER_TOTALS_SUCCESS';
export const LOAD_CUSTOMER_TOTALS_FAIL = 'LOAD_CUSTOMER_TOTALS_FAIL';

export const LOAD_MERCHANT_TRANSACTIONS_DATA = 'LOAD_MERCHANT_TRANSACTIONS_DATA';
export const LOAD_MERCHANT_TRANSACTIONS_DATA_SUCCESS = 'LOAD_MERCHANT_TRANSACTIONS_DATA_SUCCESS';
export const LOAD_MERCHANT_TRANSACTIONS_DATA_FAIL = 'LOAD_MERCHANT_TRANSACTIONS_DATA_FAIL';

export const LOAD_MERCHANT_FRAUD_NUMBERS = 'LOAD_MERCHANT_FRAUD_NUMBER';
export const LOAD_MERCHANT_FRAUD_NUMBERS_SUCCESS = 'LOAD_MERCHANT_FRAUD_NUMBERS_SUCCESS';
export const LOAD_MERCHANT_FRAUD_NUMBERS_FAIL = 'LOAD_MERCHANT_FRAUD_NUMBERS_FAIL';

export const LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS = 'LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS';
export const LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_SUCCESS =
  'LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_SUCCESS';
export const LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_FAIL =
  'LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_FAIL';

export const LOAD_WORLD_TRANSACTIONS = 'LOAD_WORLD_TRANSACTIONS';
export const LOAD_WORLD_TRANSACTIONS_SUCCESS = 'LOAD_WORLD_TRANSACTIONS_SUCCESS';
export const LOAD_WORLD_TRANSACTIONS_FAIL = 'LOAD_WORLD_TRANSACTIONS_FAIL';

export const LOAD_MERCHANT_CHARGE_BACK_NUMBERS = 'LOAD_MERCHANT_CHARGE_BACK_NUMBERS';
export const LOAD_MERCHANT_CHARGE_BACK_NUMBERS_SUCCESS =
  'LOAD_MERCHANT_CHARGE_BACK_NUMBERS_SUCCESS';
export const LOAD_MERCHANT_CHARGE_BACK_NUMBERS_FAIL = 'LOAD_MERCHANT_CHARGE_BACK_NUMBERS_FAIL';

export const LOAD_MERCHANT_INFO = 'LOAD_MERCHANT_INFO';
export const LOAD_MERCHANT_INFO_SUCCESS = 'LOAD_MERCHANT_INFO_SUCCESS';
export const LOAD_MERCHANT_INFO_FAIL = 'LOAD_MERCHANT_INFO_FAIL';

export const LOAD_MERCHANT_TOTALS = 'LOAD_MERCHANT_TOTALS';
export const LOAD_MERCHANT_TOTALS_SUCCESS = 'LOAD_MERCHANT_TOTALS_SUCCESS';
export const LOAD_MERCHANT_TOTALS_FAIL = 'LOAD_MERCHANT_TOTALS_FAIL';

export const CHANGE_RULESET_DATA = 'CHANGE_RULESET_DATA';
export const ADD_NEW_RULE_TO_RULESET = 'ADD_NEW_RULE_TO_RULESET';
export const DELETE_RULE_FROM_RULESET = 'DELETE_RULE_FROM_RULESET';
export const CLONE_RULE_FROM_RULESET = 'CLONE_RULE_FROM_RULESET';
export const SELECT_RULE = 'SELECT_RULE';
export const UNSELECT_RULE = 'UNSELECT_RULE';
export const SELECT_RULESET = 'SELECT_RULESET';
export const UPDATE_RULE = 'UPDATE_RULE';
export const UPDATE_RULESET_METADATA = 'UPDATE_RULESET_METADATA';
export const CHANGE_NEW_FORM_VALID_STATUS = 'CHANGE_NEW_FORM_VALID_STATUS';
export const CHANGE_NEW_FORM_CLOSE_ATTEMPT = 'CHANGE_NEW_FORM_CLOSE_ATTEMPT';
export const EMPTY_RULESET_AND_RULES = 'EMPTY_RULESET_AND_RULES';

export const IBAN_REGEX =
  /^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[a-zA-Z0-9]{4}\s?[a-zA-Z0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/;
export const RULE_NAME_REGEX = /^[A-Za-z0-9\-\s\,\.\_\(\)]*$/; /* eslint-disable-line */
export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3}) '\d{11})$/;
export const MASKED_NUMBER_REGEX = /^([0-9]{6}[X]{6}[0-9]{4})$/;
export const IP_REGEX =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const BIN_REGEX = /^\d{6}$/;
export const USERNAME_REGEX =
  /^[A-Za-z0-9]+((\s)?((\'|\-|\.)?([A-Za-z0-9])+))*$/; /* eslint-disable-line */
export const USERNAMEFULL_REGEX = /^[A-Za-z0-9\-\s,._@`"']*$/; /* eslint-disable-line */
export const CATEGORY_NAME_REGEX = /^[A-Za-z0-9]+((\s)?(([A-Za-z0-9])+))*$/;
export const DECIMAL_REGEX = /^(?=.?\d)\d*(\.\d{0,2})?$/;
export const NUM_REGEX = /^[0-9]*$/;
export const NUM_LETTERS_REGEX = /^[A-Za-z0-9]*$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const SAVE_RULSET_TO_API = 'SAVE_RULSET_TO_API';
export const SAVE_RULSET_TO_API_FAIL = 'SAVE_RULSET_TO_API_FAIL';
export const SAVE_RULSET_TO_API_SUCCESS = 'SAVE_RULSET_TO_API_SUCCESS';

export const LOAD_RULE_STATS = 'LOAD_RULE_STATS';
export const LOAD_RULE_STATS_SUCCESS = 'LOAD_RULE_STATS_SUCCESS';
export const LOAD_RULE_STATS_FAIL = 'LOAD_RULE_STATS_FAIL';

export const LOAD_RULESET_STATS = 'LOAD_RULESET_STATS';
export const LOAD_RULESET_STATS_SUCCESS = 'LOAD_RULESET_STATS_SUCCESS';
export const LOAD_RULESET_STATS_FAIL = 'LOAD_RULESET_STATS_FAIL';

export const DELETE_RULESET = 'DELETE_RULESET';
export const DELETE_RULESET_SUCCESS = 'DELETE_RULESET_SUCCESS';
export const DELETE_RULESET_FAIL = 'DELETE_RULESET_FAIL';
export const DELETE_RULESET_RESET = 'DELETE_RULESET_RESET';

export const CHANGE_RULESET_STATS_DATE_RANGE = 'CHANGE_RULESET_STATS_DATE_RANGE';

export const LOAD_FUNCTION_LIST = 'LOAD_FUNCTION_LIST';
export const LOAD_FUNCTION_LIST_SUCCESS = 'LOAD_FUNCTION_LIST_SUCCESS';
export const LOAD_FUNCTION_LIST_FAIL = 'LOAD_FUNCTION_LIST_FAIL';

export const LOAD_PERMISSION_LIST = 'LOAD_PERMISSION_LIST';
export const LOAD_PERMISSION_LIST_SUCCESS = 'LOAD_PERMISSION_LIST_SUCCESS';
export const LOAD_PERMISSION_LIST_FAIL = 'LOAD_PERMISSION_LIST_FAIL';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';
export const LOAD_TOKEN = 'LOAD_TOKEN';
export const LOAD_TOKEN_SUCCESS = 'LOAD_TOKEN_SUCCESS';
export const LOAD_TOKEN_FAIL = 'LOAD_TOKEN_FAIL';
export const SET_PRODUCT_USER = 'SET_PRODUCT_USER';
export const OPEN_PRODUCT_SELECTION_MODAL = 'OPEN_PRODUCT_SELECTION_MODAL';
export const CLOSE_PRODUCT_SELECTION_MODAL = 'CLOSE_PRODUCT_SELECTION_MODAL';

export const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';

export const SAVE_USERS = 'SAVE_USERS';
export const SAVE_USERS_SUCCESS = 'SAVE_USERS_SUCCESS';
export const SAVE_USERS_FAIL = 'SAVE_USERS_FAIL';
export const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL';
export const SEND_ACTIVATION_EMAIL_SUCCESS = 'SEND_ACTIVATION_EMAIL_SUCCESS';
export const SEND_ACTIVATION_EMAIL_FAIL = 'SEND_ACTIVATION_EMAIL_FAIL';
export const ENABLE_USER = 'ENABLE_USER';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAIL = 'ENABLE_USER_FAIL';
export const DISABLE_USER = 'DISABLE_USER';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAIL = 'DISABLE_USER_FAIL';

export const NOT_AUTHORIZED_URL = '/not-authorized';
export const SELECT_TRANSACTION_FOR_CASE_MANAGEMENT = 'SELECT_TRANSACTION_FOR_CASE_MANAGEMENT';
export const SUBMIT_ACTION_FOR_CASE_MANAGEMENT = 'SUBMIT_ACTION_FOR_CASE_MANAGEMENT';
export const SUBMIT_ACTION_FOR_CASE_MANAGEMENT_SUCCESS =
  'SUBMIT_ACTION_FOR_CASE_MANAGEMENT_SUCCESS';
export const SUBMIT_ACTION_FOR_CASE_MANAGEMENT_FAIL = 'SUBMIT_ACTION_FOR_CASE_MANAGEMENT_FAIL';
export const RESET_SUBMIT_ACTION_FOR_CASE_MANAGEMENT_SUCCESS =
  'RESET_SUBMIT_ACTION_FOR_CASE_MANAGEMENT_SUCCESS';
export const CHANGE_ASSIGNEE_OF_TRANSACTION = 'CHANGE_ASSIGNEE_OF_TRANSACTION';

export const LOAD_ASSIGNEE_CANDIDATES = 'LOAD_ASSIGNEE_CANDIDATES';
export const LOAD_ASSIGNEE_CANDIDATES_SUCCESS = 'LOAD_ASSIGNEE_CANDIDATES_SUCCESS';
export const LOAD_ASSIGNEE_CANDIDATES_FAIL = 'LOAD_ASSIGNEE_CANDIDATES_FAIL';

export const CLEAR_TRANSACTION_HISTORY = 'CLEAR_TRANSACTION_HISTORY';
export const LOAD_TRANSACTION_HISTORY = 'LOAD_TRANSACTION_HISTORY';
export const LOAD_TRANSACTION_HISTORY_SUCCESS = 'LOAD_TRANSACTION_HISTORY_SUCCESS';
export const LOAD_TRANSACTION_HISTORY_FAIL = 'LOAD_TRANSACTION_HISTORY_FAIL';

export const USERS_PERMISSIONS = ['MANAGE_USERS'];
export const USERS_MANAGEMENT = ['SUPER_ADMIN', 'TENANT_ADMIN', 'PROFILE_VIEW', 'PROFILE_EDIT'];
export const CASE_MANAGEMENT = ['TENANT_ADMIN', 'CASE_VIEW', 'CASE_EDIT'];
export const APPLICATION_MANAGEMENT = ['TENANT_ADMIN', 'APPLICATION_VIEW', 'APPLICATION_EDIT'];
export const KYC_MANAGEMENT = ['TENANT_ADMIN', 'KYC_VIEW', 'KYC_EDIT'];
export const CLIENTS_MANAGEMENT = ['TENANT_ADMIN', 'CLIENT_VIEW', 'CLIENT_EDIT'];
export const MERCHANTS_MANAGEMENT = ['TENANT_ADMIN', 'MERCHANT_VIEW', 'MERCHANT_EDIT'];
export const TRANSACTIONS_MANAGEMENT = ['TENANT_ADMIN', 'TRANSACTION_VIEW', 'TRANSACTION_EDIT'];
export const RISK_WORDS_MANAGEMENT = ['TENANT_ADMIN', 'RISK_WORDS_VIEW', 'RISK_WORDS_EDIT'];
export const RULES_MANAGEMENT = ['TENANT_ADMIN', 'RULES_VIEW', 'RULES_EDIT'];
export const PEPSANCTIONS_MANAGEMENT = ['TENANT_ADMIN', 'PEP_SANCTIONS_VIEW', 'PEP_SANCTIONS_EDIT'];
export const UBO_MANAGEMENT = ['TENANT_ADMIN', 'UBO_VIEW', 'UBO_EDIT'];
export const COUNTRY_RISK_MANAGEMENT = [
  'RISK_COUNTRY_EDIT',
  'RISK_COUNTRY_VIEW',
  'ONBOARDING_RISK_COUNTRY_VIEW',
  'ONBOARDING_RISK_COUNTRY_EDIT',
  'UBO_RISK_COUNTRY_VIEW',
  'UBO_RISK_COUNTRY_EDIT',
  'CLIENT_RISK_COUNTRY_VIEW',
  'CLIENT_RISK_COUNTRY_EDIT',
  'TENANT_ADMIN',
];
export const TRANSACTIONS_PERMISSIONS = [
  'TENANT_ADMIN',
  'TRANSACTIONS_MANAGEMENT',
  'TRANSACTIONS_VIEW',
  'CASE_MANAGEMENT',
];
export const CUSTOMER_PROFILE_PERMISSIONS = [
  'TENANT_ADMIN',
  'CASE_MANAGEMENT',
  'TRANSACTIONS_VIEW',
  'TRANSACTIONS_MANAGEMENT',
];
export const MERCHANT_PROFILE_PERMISSIONS = [
  'TENANT_ADMIN',
  'CASE_MANAGEMENT',
  'TRANSACTIONS_VIEW',
  'TRANSACTIONS_MANAGEMENT',
];
export const COLLECTIONS_PERMISSIONS = ['TENANT_ADMIN', 'COLLECTION_VIEW', 'COLLECTION_EDIT'];
export const REPORTS_PERMISSIONS = ['TENANT_ADMIN', 'REPORTS_VIEW'];
export const LOGS_PERMISSIONS = ['TENANT_ADMIN', 'ACCESS_LOGS'];
export const RULE_BUILDER_PERMISSIONS = ['TENANT_ADMIN', 'RULES_EDIT'];
export const PERMISSIONS_PERMISSIONS = ['TENANT_ADMIN', 'MANAGE_USERS'];
export const RISK_ASSESSMENT_BUILDER_PERMISSIONS = ['TENANT_ADMIN', 'RISK_ASSESSMENT_EDIT'];
export const RISK_ASSESSMENT_PERMISSIONS = [
  'TENANT_ADMIN',
  'RISK_ASSESSMENT_VIEW',
  'RISK_ASSESSMENT_EDIT',
];
export const RULE_FUNCTIONS_PERMISSIONS = [
  'TENANT_ADMIN',
  'RULE_FUNCTIONS_VIEW',
  'RULE_FUNCTIONS_EDIT',
];

export const LOAD_RULE_BUILDER_FIELDS = 'LOAD_RULE_BUILDER_FIELDS';
export const LOAD_RULE_BUILDER_FIELDS_SUCCESS = 'LOAD_RULE_BUILDER_FIELDS_SUCCESS';
export const LOAD_RULE_BUILDER_FIELDS_FAIL = 'LOAD_RULE_BUILDER_FIELDS_FAIL';

export const SUBMIT_SETTINGS_CHANGE = 'SUBMIT_SETTINGS_CHANGE';
export const SUBMIT_SETTINGS_CHANGE_SUCCESS = 'SUBMIT_SETTINGS_CHANGE_SUCCESS';
export const SUBMIT_SETTINGS_CHANGE_FAIL = 'SUBMIT_SETTINGS_CHANGE_FAIL';

export const LOAD_CASE_MANAGEMENT_STATS = 'LOAD_CASE_MANAGEMENT_STATS';
export const LOAD_CASE_MANAGEMENT_STATS_SUCCESS = 'LOAD_CASE_MANAGEMENT_STATS_SUCCESS';
export const LOAD_CASE_MANAGEMENT_STATS_FAIL = 'LOAD_CASE_MANAGEMENT_STATS_FAIL';

export const ADD_NEW_VALUATION_TO_SET = 'ADD_NEW_VALUATION_TO_SET';
export const DELETE_VALUATION_FROM_SET = 'DELETE_VALUATION_FROM_SET';
export const CHANGE_SET_DATA = 'CHANGE_SET_DATA';
export const SELECT_VALUATION = 'SELECT_VALUATION';
export const UNSELECT_VALUATION = 'UNSELECT_VALUATION';
export const SELECT_SET = 'SELECT_SET';
export const UPDATE_VALUATION = 'UPDATE_VALUATION';
export const UPDATE_SET_METADATA = 'UPDATE_SET_METADATA';
export const SAVE_SET_TO_API = 'SAVE_SET_TO_API';
export const SAVE_SET_TO_API_FAIL = 'SAVE_SET_TO_API_FAIL';
export const SAVE_SET_TO_API_SUCCESS = 'SAVE_SET_TO_API_SUCCESS';
export const CHANGE_NEW_VALUATION_FORM_VALID_STATUS = 'CHANGE_NEW_VALUATION_FORM_VALID_STATUS';
export const CHANGE_NEW_VALUATION_FORM_CLOSE_ATTEMPT = 'CHANGE_NEW_VALUATION_FORM_CLOSE_ATTEMPT';
export const EMPTY_SET_AND_VALUATIONS = 'EMPTY_SET_AND_VALUATIONS';

export const LOAD_ASSESSMENTS = 'LOAD_ASSESSMENTS';
export const LOAD_ASSESSMENTS_SUCCESS = 'LOAD_ASSESSMENTS_SUCCESS';
export const LOAD_ASSESSMENTS_FAIL = 'LOAD_ASSESSMENTS_FAIL';

export const DELETE_ASSESSMENT = 'DELETE_ASSESSMENT';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAIL = 'DELETE_ASSESSMENT_FAIL';
export const DELETE_ASSESSMENT_RESET = 'DELETE_ASSESSMENT_RESET';

export const SELECT_RULETEST_FIELDS = 'SELECT_RULETEST_FIELDS';
export const UPDATE_RULETEST_FIELD = 'UPDATE_RULETEST_FIELD';
export const GENERATE_FAKE_DATA = 'GENERATE_FAKE_DATA';
export const GENERATE_FAKE_DATA_RESET = 'GENERATE_FAKE_DATA_RESET';
export const SEND_RULETEST_FORM_DATA_FOR_FRAUD_CHECK = 'SEND_RULETEST_FORM_DATA_FOR_FRAUD_CHECK';
export const SEND_RULETEST_FORM_DATA_FOR_FEED = 'SEND_RULETEST_FORM_DATA_FOR_FEED';
export const UPDATE_FRAUDCHECK_RESULT = 'UPDATE_FRAUDCHECK_RESULT';
export const RESET_RULETEST_FRAUDCHECK_RESULTS = 'RESET_RULETEST_FRAUDCHECK_RESULTS';
export const TEST_TRANSACTION_FEED_COMPLETED = 'TEST_TRANSACTION_FEED_COMPLETED';

export const SELECT_FUNCTION = 'SELECT_FUNCTION';
export const UNSELECT_FUNCTION = 'UNSELECT_FUNCTION';
export const LOAD_FUNCTIONS = 'LOAD_FUNCTIONS';
export const LOAD_FUNCTIONS_SUCCESS = 'LOAD_FUNCTIONS_SUCCESS';
export const LOAD_FUNCTIONS_FAIL = 'LOAD_FUNCTIONS_FAIL';

export const CREATE_FUNCTION = 'CREATE_FUNCTION';
export const CREATE_FUNCTION_SUCCESS = 'CREATE_FUNCTION_SUCCESS';
export const CREATE_FUNCTION_FAIL = 'CREATE_FUNCTION_FAIL';
export const UPDATE_FUNCTION = 'UPDATE_FUNCTION';
export const UPDATE_FUNCTION_SUCCESS = 'UPDATE_FUNCTION_SUCCESS';
export const UPDATE_FUNCTION_FAIL = 'UPDATE_FUNCTION_FAIL';
export const LOAD_FUNCTION_HISTORY = 'LOAD_FUNCTION_HISTORY';
export const LOAD_FUNCTION_HISTORY_SUCCESS = 'LOAD_FUNCTION_HISTORY_SUCCESS';
export const LOAD_FUNCTION_HISTORY_FAIL = 'LOAD_FUNCTION_HISTORY_FAIL';

export const DELETE_FUNCTION = 'DELETE_FUNCTION';
export const DELETE_FUNCTION_SUCCESS = 'DELETE_FUNCTION_SUCCESS';
export const DELETE_FUNCTION_FAIL = 'DELETE_FUNCTION_FAIL';
export const DELETE_FUNCTION_RESET = 'DELETE_FUNCTION_RESET';
export const SHOW_NEW_FUNCTION_FORM = 'SHOW_NEW_FUNCTION_FORM';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAIL = 'LOAD_PRODUCTS_FAIL';
export const ASSOCIATE_PRODUCT = 'ASSOCIATE_PRODUCT';
export const ASSOCIATE_PRODUCT_SUCCESS = 'ASSOCIATE_PRODUCT_SUCCESS';
export const ASSOCIATE_PRODUCT_FAIL = 'ASSOCIATE_PRODUCT_FAIL';
export const REMOVE_ASSOCIATED_PRODUCT = 'ASSOCIATE_PRODUCT';
export const REMOVE_ASSOCIATED_PRODUCT_SUCCESS = 'ASSOCIATE_PRODUCT_SUCCESS';
export const REMOVE_ASSOCIATED_PRODUCT_FAIL = 'ASSOCIATE_PRODUCT_FAIL';
