import React from 'react';
import { Chip, Typography } from '@mui/material';
import { decisions } from '../constants';
import { PepDecision } from '../types';

interface ResultLabelRenderProps {
  decision: PepDecision;
  length: number;
}

export const ResultLabelRender: React.FC<ResultLabelRenderProps> = ({ decision, length }) => {
  const params = decisions.find((el) => el.value === decision);
  return decision ? (
    <Chip
      variant="outlined"
      color={(params?.color || 'base') as any}
      size="small"
      label={params?.label}
    />
  ) : (
    <Chip
      color="base"
      size="small"
      label={
        <Typography fontWeight={length > 0 ? 500 : 400}>
          {length > 0 ? `${length} matches` : 'No matches'}
        </Typography>
      }
    />
  );
};
