/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_FUNCTIONS,
  LOAD_FUNCTIONS_SUCCESS,
  LOAD_FUNCTIONS_FAIL,
  CREATE_FUNCTION,
  CREATE_FUNCTION_SUCCESS,
  CREATE_FUNCTION_FAIL,
  UPDATE_FUNCTION,
  UPDATE_FUNCTION_SUCCESS,
  UPDATE_FUNCTION_FAIL,
  DELETE_FUNCTION,
  DELETE_FUNCTION_SUCCESS,
  DELETE_FUNCTION_FAIL,
  DELETE_FUNCTION_RESET,
  SELECT_FUNCTION,
  UNSELECT_FUNCTION,
  SHOW_NEW_FUNCTION_FORM,
  LOAD_FUNCTION_HISTORY,
  LOAD_FUNCTION_HISTORY_SUCCESS,
  LOAD_FUNCTION_HISTORY_FAIL,
} from '../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  list: [],
  functionDeletingSuccess: false,
  functionDeletingError: false,
  selectedFunctionId: null,
  newForm: false,
  functionHistory: [],
};

const functionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_FUNCTIONS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_FUNCTIONS_SUCCESS:
        draft.list = action.functions;
        draft.loading = false;
        break;
      case LOAD_FUNCTIONS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case CREATE_FUNCTION:
        draft.loading = true;
        break;
      case CREATE_FUNCTION_SUCCESS:
        draft.loading = false;
        draft.newForm = false;
        break;
      case CREATE_FUNCTION_FAIL:
        draft.loading = false;
        break;
      case UPDATE_FUNCTION:
        draft.loading = true;
        break;
      case UPDATE_FUNCTION_SUCCESS:
        draft.loading = false;
        draft.selectedFunctionId = null;
        break;
      case UPDATE_FUNCTION_FAIL:
        draft.loading = false;
        break;
      case DELETE_FUNCTION:
        draft.loading = true;
        draft.functionDeletingSuccess = false;
        draft.functionDeletingError = false;
        break;
      case DELETE_FUNCTION_SUCCESS: {
        draft.loading = false;
        draft.functionDeletingSuccess = true;
        draft.functionDeletingError = false;
        break;
      }
      case DELETE_FUNCTION_FAIL:
        draft.loading = false;
        draft.functionDeletingSuccess = false;
        draft.functionDeletingError = action.error;
        break;
      case DELETE_FUNCTION_RESET:
        draft.loading = false;
        draft.functionDeletingError = false;
        draft.functionDeletingSuccess = false;
        break;
      case SHOW_NEW_FUNCTION_FORM:
        draft.newForm = true;
        break;
      case SELECT_FUNCTION: {
        draft.selectedFunctionId = action.functionId;
        break;
      }
      case UNSELECT_FUNCTION: {
        draft.newForm = false;
        draft.selectedFunctionId = null;
        break;
      }
      case LOAD_FUNCTION_HISTORY: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case LOAD_FUNCTION_HISTORY_SUCCESS: {
        draft.functionHistory = action.functionHistory;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case LOAD_FUNCTION_HISTORY_FAIL: {
        draft.loading = false;
        draft.error = true;
        break;
      }
    }
  });

export default functionsReducer;
