import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const NavButtonsWrapper = styled('div', {
  name: 'NavButtonsWrapper',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    gap: '1rem',
    padding: '20px 0 8px',
    borderTop: '1px solid',
    borderColor: 'grey.300',
    marginTop: '20px',
    flexDirection: 'row-reverse',
  }),
);

interface NavButtonsProps {
  formsTabValues?: { formId: string; tabValue: string }[];
  formId: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}
export const NavButtons = ({ formsTabValues, formId, setValue }: NavButtonsProps): JSX.Element => {
  const navigate = useNavigate();
  const sectionIndex = 0;
  const { sectionId, partitionName } = useParams<{
    sectionId: string;
    partitionName: string;
  }>();
  const formIndex = formsTabValues?.findIndex((el) => el.formId === formId) || 0;
  const lastFormIndex = formsTabValues ? formsTabValues.length - 1 : 0;
  const scrollToTop = () => window.scrollTo({ top: 0 });

  const handleClickNext = () => {
    if (Number(formIndex) === lastFormIndex) {
      navigate('..');
    } else {
      setValue(
        formIndex < lastFormIndex
          ? `${sectionIndex}${Number(formIndex) + 1}`
          : `${Number(sectionIndex + 1)}0`,
      );
      scrollToTop();
    }
  };

  const handleClickPrevious = () => {
    setValue(
      formIndex > 0 ? `${sectionIndex}${Number(formIndex) - 1}` : `${Number(sectionIndex - 1)}0`,
    );
    scrollToTop();
  };

  // const handleClickNext = () => {
  //   if (Number(formIndex) === lastFormIndex) {
  //     navigate('/');
  //   } else {
  //     const toFormId = formsTabValues[formIndex + 1].formId;
  //     navigate(`/section/${sectionId}/${partitionName}/${toFormId}`);
  //   }
  //   scrollToTop();
  // };
  //
  // const handleClickPrevious = () => {
  //   const toFormId = formsTabValues[formIndex - 1].formId;
  //   navigate(`/section/${sectionId}/${partitionName}/${toFormId}`);
  //   scrollToTop();
  // };

  return (
    <NavButtonsWrapper>
      <Button
        variant="contained"
        color="primary"
        sx={{ '& .MuiButton-endIcon': { ml: 1 } }}
        endIcon={<ArrowForwardIcon style={{ fontSize: '14px' }} />}
        onClick={() => handleClickNext()}
      >
        {Number(formIndex) === lastFormIndex ? 'Done' : 'Next'}
      </Button>
      <Button
        variant="contained"
        color="base"
        sx={{ bgcolor: 'base.main', '& .MuiButton-endIcon': { ml: 1 } }}
        startIcon={<ArrowBackIcon style={{ fontSize: '14px' }} />}
        onClick={() => handleClickPrevious()}
        disabled={Number(formIndex) === 0}
      >
        Previous
      </Button>
    </NavButtonsWrapper>
  );
};
