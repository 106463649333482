import React from 'react';

import { Product } from 'types';
// @ts-ignore
import ProductLogo from '../../../img/product-placeholder.png';
import './ProductMenu.scss';

interface ProductMenuProps {
  products: Product[];
  handleSelectProduct: (productId: number) => void;
  selectedProductId: number;
}

export const ProductMenu: React.FC<ProductMenuProps> = ({
  products,
  handleSelectProduct,
  selectedProductId,
}) => {
  return (
    <div className="ProductMenu">
      {products.map((product, i) => (
        <div key={i} className={'ProductImageButton-wrapper'}>
          <div
            onClick={() =>
              selectedProductId !== product.id ? handleSelectProduct(product.id) : null
            }
            className={`ProductImageButton ${
              selectedProductId === product.id ? 'ProductImageButtonSelected' : ''
            }`}
          >
            <img
              src={product.imageUrl ? product.imageUrl : ProductLogo}
              alt="product-logo"
              className={`ProductImage`}
            />
            <span className={'ProductName'}>{product.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
