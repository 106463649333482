import { gatewayApi } from '../gatewayApi';

const kycProvidersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getKycAllowedCountriesForProvider: build.query({
      query: ({ id }) => ({ url: `kyc/allowed-countries/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'KycAllowedCountries', id }],
    }),

    addKycAllowedCountry: build.mutation({
      query: ({ providerId, countryCode }) => ({
        url: `kyc/allowed-countries/${providerId}/${countryCode}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { providerId }) => [
        {
          type: 'KycAllowedCountries',
          id: providerId,
        },
      ],
    }),

    deleteKycAllowedCountry: build.mutation({
      query: ({ providerId, countryCode }) => ({
        url: `kyc/allowed-countries/${providerId}/${countryCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { providerId }) => [
        {
          type: 'KycAllowedCountries',
          id: providerId,
        },
      ],
    }),

    deleteKycAllowedCountriesForProvider: build.mutation({
      query: ({ providerId }) => ({
        url: `kyc/allowed-countries/${providerId}/allow-all`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { providerId }) => [
        {
          type: 'KycAllowedCountries',
          id: providerId,
        },
      ],
    }),
  }),
});

export const {
  useGetKycAllowedCountriesForProviderQuery,
  useAddKycAllowedCountryMutation,
  useDeleteKycAllowedCountryMutation,
  useDeleteKycAllowedCountriesForProviderMutation,
} = kycProvidersApi;
