import * as _ from 'lodash';
import {
  GET_VALIDATOR_TRANSACTIONS,
  GET_VALIDATOR_TRANSACTIONS_FAIL,
  GET_VALIDATOR_TRANSACTIONS_SUCCESS,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL,
} from '../../../constants/constants';
import { removeEmptyProperties } from '../../../utils/modelHelper';
import ApiService from '../../../services/apiService';
import { toastLoadingListError } from '../../../utils/toast';

const entityTitle = 'Validator Transaction';

export function fetchValidatorTransactions(params) {
  removeEmptyProperties(params);
  const sorting = { sort: 'actionDateTime,DESC' };
  const queryParams = _.extend(params, sorting);
  return (dispatch) => {
    dispatch({ type: GET_VALIDATOR_TRANSACTIONS });
    ApiService.get('/api/gateway/validator-transactions', queryParams)
      .then((response) => {
        const payload = response.content;
        const paginationInfo = {
          activePage: response.number,
          currentPageSize: response.size,
          totalItemsCount: response.totalElements,
        };
        dispatch(fetchValidatorTransactionsSuccess({ payload, paginationInfo }));
      })
      .catch((error) => dispatch(fetchValidatorTransactionsError(error)));
  };
}

export function fetchValidatorTransactionsForCard(cardId) {
  const queryParams = { sort: 'actionDateTime,DESC' };
  return (dispatch) => {
    dispatch({ type: GET_VALIDATOR_TRANSACTIONS });
    ApiService.get(`/api/gateway/card-data/${cardId}/validator-transactions`, queryParams)
      .then((response) => {
        const payload = response;
        dispatch(fetchValidatorTransactionsSuccess({ payload, paginationInfo: null }));
      })
      .catch((error) => dispatch(fetchValidatorTransactionsError(error)));
  };
}

const fetchValidatorTransactionsSuccess = (transactions) => ({
  type: GET_VALIDATOR_TRANSACTIONS_SUCCESS,
  transactions,
});

const fetchValidatorTransactionsError = (error) => (dispatch) => {
  dispatch({
    type: GET_VALIDATOR_TRANSACTIONS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

export function fetchValidatorTransactionsCardData(id) {
  return (dispatch) => {
    dispatch({ type: GET_VALIDATOR_TRANSACTIONS_CARD_DATA });
    const url = `/api/gateway/validator-transactions/${id}/card-data`;
    ApiService.get(url)
      .then((response) => dispatch(fetchValidatorTransactionsCardDataSuccess(response)))
      .catch((error) => dispatch(fetchValidatorTransactionsCardDataError(error)));
  };
}

const fetchValidatorTransactionsCardDataSuccess = (cardData) => ({
  type: GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS,
  cardData,
});

const fetchValidatorTransactionsCardDataError = (error) => (dispatch) => {
  dispatch({
    type: GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL,
    error,
  });
  toastLoadingListError(`${entityTitle} Card Data`, error);
};
