import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const cardDataApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getCardData: build.query({
      query: (params) => ({
        url: `/card-data?sort=${params.sort}`,
        method: 'POST',
        body: params,
      }),
      providesTags: () => [{ type: 'CardData', id: 'LIST' }],
      transformResponse: (response) => ({
        results: response.content,
        count: response.totalElements,
      }),
    }),
    updateCardData: build.mutation({
      query(cardData) {
        const body = { ...cardData };
        removeEmptyProperties(body);
        return {
          url: `/card-data/${body.cardDataId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'CardData', id: 'LIST' }],
    }),
  }),
});

export const { useGetCardDataQuery, useUpdateCardDataMutation } = cardDataApi;
