import React from 'react';
import { Panel } from 'uikit';
import { Button as MUIButton, IconButton as MUIIconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AppsIcon from '@mui/icons-material/Apps';

export function MUIButtonsSection() {
  return (
    <>
      <Panel>
        <MUIButton variant="text" color="primary">
          text primary
        </MUIButton>
      </Panel>

      <Panel>
        <MUIButton variant="contained" color="error">
          error
        </MUIButton>
        <MUIButton variant="contained" color="primary">
          primary
        </MUIButton>
        <MUIButton variant="contained" color="secondary">
          secondary
        </MUIButton>
        <MUIButton variant="contained" color="success">
          success
        </MUIButton>
      </Panel>

      <Panel>
        <MUIButton size="small" startIcon={<AddIcon />} variant="contained" color="primary">
          primary
        </MUIButton>
        <MUIButton size="medium" endIcon={<AddIcon />} variant="contained" color="primary">
          primary
        </MUIButton>
        <MUIButton
          size="large"
          startIcon={<AddIcon />}
          endIcon={<AddIcon />}
          variant="contained"
          color="primary"
        >
          primary
        </MUIButton>
      </Panel>

      <Panel>
        <MUIIconButton size="small" color="primary">
          <AppsIcon fontSize="small" />
        </MUIIconButton>
        <MUIIconButton size="medium" color="primary">
          <AppsIcon />
        </MUIIconButton>
        <MUIIconButton size="large" color="error">
          <AppsIcon />
        </MUIIconButton>
        <MUIIconButton size="large">
          <AppsIcon />
        </MUIIconButton>
      </Panel>
    </>
  );
}
