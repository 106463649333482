import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import {
  Close as CloseIcon,
  Circle as CircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { error, success, warning } from 'theme/palette-blocks';
import { TOOLTIP_DELAY } from 'constants/constants';
import { RulesAnswer } from '../types';
import { ruleAnswer } from '../constatns';

interface GetLampProps {
  answer?: RulesAnswer;
  score?: number;
  alwaysFilled?: boolean;
  width?: string;
}

const lampWrapperStyle = {
  cursor: 'default',
  display: 'flex',
  alignItems: 'center',
};

export const GetLamp: React.FC<GetLampProps> = ({ answer, score, alwaysFilled = false, width = '10px' }) => {
  const getLabel = () => ruleAnswer.find((el) => el.value === answer)?.label || 'no result';

  if (answer === 'MODERATION')
    return (
      <Tooltip title="Moderation score" enterDelay={TOOLTIP_DELAY}>
        <div style={lampWrapperStyle}>
          <Typography color="grey.900" fontSize={width} >
            {score || 0}
          </Typography>
        </div>
      </Tooltip>
    );

  if (answer === 'ERROR' || !answer)
    return (
      <Tooltip title="Error" enterDelay={TOOLTIP_DELAY}>
        <div style={lampWrapperStyle}>
          <CloseIcon style={{ fontSize: width, color: error.main }} />
        </div>
      </Tooltip>
    );

  let color: string;
  let filled: boolean;
  switch (answer) {
    case 'PASS':
      color = success.main;
      filled = true;
      break;
    case 'LOWRISK':
      color = success.main;
      filled = false;
      break;
    case 'MEDIUMRISK':
      color = warning.main;
      filled = false;
      break;
    case 'HIGHRISK':
      color = error.main;
      filled = false;
      break;
    case 'REJECT':
      color = error.main;
      filled = true;
      break;
    default:
      color = warning.main;
      filled = true;
  }

  return (
    <Tooltip title={getLabel()} enterDelay={TOOLTIP_DELAY}>
      <div style={{ ...lampWrapperStyle, width }}>
        {(alwaysFilled || filled) ? (
          <CircleIcon style={{ fontSize: width, color }} />
        ) : (
          <RadioButtonUncheckedIcon style={{ fontSize: width, color }} />
        )}
      </div>
    </Tooltip>
  );
};
