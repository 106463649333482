import { SortOptions } from 'types';
import { Acquirer, ResponseCode } from './types';

export const initialAcquirer: Acquirer = {
  id: 'new',
  acquirerCode: '',
  acquirerName: '',
};

export const initialResponseCode: ResponseCode = {
  id: 'new',
  acquirerId: '',
  responseCode: '',
  responseMessage: '',
  score: 10,
};

export const sortOptions: SortOptions[] = [
  { fieldName: 'acquirerCode', label: 'Code' },
  { fieldName: 'acquirerName', label: 'Name' },
  { fieldName: 'createdByName', label: 'Created by' },
  { fieldName: 'createdDate', label: 'Created on' },
];

export const detailsFields = [
  { value: 'acquirerCode', label: 'Code' },
  { value: 'createdByName', label: 'Created by' },
  { value: 'createdDate', label: 'Created on' },
];
