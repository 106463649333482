/* eslint-disable react/no-did-update-set-state */
import PropTypes from 'prop-types';

import React from 'react';
import toastr from 'toastr';
import Modal from 'react-modal';
import moment from 'moment';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import { TransactionItemDetails, DataTableRow } from '../../components';
import { history } from '../../store';
import { iterator, resetItems } from '../../utils/transactionIterator';
import { formatByCurrency } from '../../utils/formatter';
import { DATETIMESEC_FORMAT_MOMENT } from '../../constants/constants';
import responseCodes from '../../constants/responseCodes';
import ApiService from '../../services/apiService';
import { cleanExceptionRuleData } from '../../features/exceptionRules/constants';
import { ExceptionRuleDialog } from '../../features/exceptionRules/ExceptionRuleDialog';

export class TransactionsListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDetails: false,
      openRulesInfo: false,
      showModal: false,
    };
  }

  componentWillMount() {
    Modal.setAppElement('#app');
  }

  componentDidUpdate(prevProps) {
    if (this.props.isModalOpen !== prevProps.isModalOpen) {
      this.setState({ showModal: this.props.isModalOpen });
    }
  }

  handleOpenDetailsClick() {
    this.setState((prevState) => ({
      ...prevState,
      openDetails: !prevState.openDetails,
    }));
  }

  handleOpenCase(id) {
    const url = `/api/gateway/cases/transactions/${id}`;
    ApiService.get(url)
      .then((response) => {
        if (response.caseId) {
          history.push(`/cases/${response.caseId}`);
        }
      })
      .catch((error) => toastr.error(`Error retrieving case - ${error}`));
  }

  handleRulesInfo() {
    this.setState((prevState) => ({
      ...prevState,
      openRulesInfo: !prevState.openRulesInfo,
    }));
  }

  handleExceptionRuleModal() {
    this.setState({ showModal: true });
  }

  handleCloseExceptionRuleModal() {
    this.setState({ showModal: false });
  }

  renderModal(item) {
    const details = JSON.parse(item.details);
    const referenceData = [
      { value: 'PAN', label: 'PAN', reference: details.pan },
      {
        value: 'BIN',
        label: 'BIN',
        reference: details.enrichmentData?.binInfo?.binNumber,
      },
      { value: 'EMAIL', label: 'E-mail', reference: details.customer?.email },
      {
        value: 'FULL_NAME',
        label: 'Full Name',
        reference: `${details.customer?.firstName} ${details.customer?.lastName}`,
      },
      {
        value: 'IP_ADDRESS',
        label: 'IP Address',
        reference: details.customer?.ipAddress,
      },
      {
        value: 'COUNTRY',
        label: 'Country',
        reference: details.enrichmentData.ip2location?.country_iso_code,
      },
      {
        value: 'DEVICE_ID',
        label: 'Device ID',
        reference: details.customer?.deviceId,
      },
      {
        value: 'BIN_COUNTRY',
        label: 'BIN Country',
        reference: details.enrichmentData?.binInfo?.countryCode,
      },
      {
        value: 'HASHED_CARD_NUMBER',
        label: 'Hashed card number',
        reference: details.hashedCardNumber,
      },
    ];

    const referenceDataList = referenceData.filter((el) => el.reference && el.reference !== '');

    const dataExceptionRule = {
      ...cleanExceptionRuleData,
      referenceDataList,
      id: 'new',
    };

    return (
      <ExceptionRuleDialog
        open={this.state.showModal}
        onClose={() => {
          this.handleCloseExceptionRuleModal();
        }}
        productId={null}
        data={dataExceptionRule}
      />
    );
  }

  renderCollapseItem() {
    const { openDetails } = this.state;
    const wrapperClass = classNames({
      fa: true,
      'fa-caret-right': openDetails === false,
      'fa-caret-down': openDetails,
    });

    return (
      <div>
        <i className={wrapperClass} />
      </div>
    );
  }

  renderRuleInfo() {
    const { item } = this.props;
    if (item.reasons) {
      const rules = item.reasons;
      return (
        <tbody>
          {Object.keys(rules).map((key) => (
            <tr>
              <td>{key}</td>
              <td>{rules[key]}</td>
            </tr>
          ))}
        </tbody>
      );
    }
    return (
      <tbody>
        <tr>
          <td colSpan="2">No rule information available</td>
        </tr>
        )
      </tbody>
    );
  }

  renderItemDetails() {
    const { item } = this.props;
    if (!item.details) {
      return null;
    }
    resetItems();
    const printOutItems = iterator(JSON.parse(item.details));
    return printOutItems.map((i) => <TransactionItemDetails key={i.key.trim()} item={i} />);
  }

  renderResponseCode() {
    const { item } = this.props;
    if (!item.details) {
      return null;
    }
    const { responseCode } = JSON.parse(item.details);
    const responseCodeElement = responseCodes.find((i) => i.responseCode === responseCode);
    const responseCodeDescription = responseCodeElement ? responseCodeElement.description : '';

    return (
      <span data-tip={responseCodeDescription}>
        <ReactTooltip html place="top" />
        {responseCode}
      </span>
    );
  }

  renderPan() {
    const { item } = this.props;
    if (!item.details) {
      return null;
    }
    return JSON.parse(item.details).pan;
  }

  renderRiskStatus() {
    const { item } = this.props;
    if (!item.details) {
      return null;
    }
    const { response } = JSON.parse(item.details);
    const status = response ? response.status : '';
    if (status === 'PASS' || status === 'REJECT' || status === 'ERROR') {
      return status;
    }
    return (
      <button
        type="button"
        className="btn btn-primary btn-sm btn-small"
        title="View Case"
        onClick={() => this.handleOpenCase(item.transactionId)}
      >
        {status}
      </button>
    );
  }

  render() {
    const {
      item,
      item: { details },
      isBank,
      isGateway,
    } = this.props;
    const { openDetails } = this.state;
    const { openRulesInfo } = this.state;
    const fullName = `${item.firstName} ${item.lastName}`;
    const { actionDate, transactionType, client, beneficiary, sender, customer, hashedCardNumber } = JSON.parse(details);

    const amount = () =>
      item.amount < 0
        ? ''
        : formatByCurrency({
          amount: item.amount,
          currency: item.currency,
          currencyDisplay: 'code',
        });

    const amountOpenPayd = () => {
      const { totalAmount, totalAmountCurrencyCode } = JSON.parse(details);

      return totalAmount < 0
        ? ''
        : formatByCurrency({
          amount: totalAmount,
          currency: totalAmountCurrencyCode,
          currencyDisplay: 'code',
        });
    };

    const getSenderOrBeneficiaryName = () => {
      switch (transactionType) {
        case 'DIRECT_DEBIT':
        case 'PAYIN':
          return sender ? sender.name : '';
        case 'TRANSFER':
        case 'PAYOUT':
          return beneficiary ? beneficiary.name : '';
        default:
          return 'Undefined Transaction Type';
      }
    };

    return (
      <tbody>
        <DataTableRow>
          <td className="open-detail" onClick={() => this.handleOpenDetailsClick()}>
            {this.renderCollapseItem()}
          </td>
          <td>{moment(actionDate).format(DATETIMESEC_FORMAT_MOMENT)}</td>
          <td>{item.transactionId}</td>
          {isBank && (
            <>
              <td>{client && client.id}</td>
              <td>{getSenderOrBeneficiaryName()}</td>
              <td>{client && client.name}</td>
            </>
          )}
          {isGateway && (
            <>
              <td>{item.merchantName}</td>
              <td>{fullName}</td>
              <td>{customer?.email}</td>
              <td>{this.renderPan()}</td>
              <td>
                <div className="hashedNumber" style={{ width:'80px'}}>
                  <span className="ellipsis">{hashedCardNumber}</span>
                  <a
                    className="success"
                    onClick={() => navigator.clipboard.writeText(hashedCardNumber).then(
                      () => toastCopySuccess('Hashed Card Number'),
                      () => toastCopyError('Hashed Card Number'),
                    )}
                    title="Copy to clipboard"
                  >
                    <i className="far fa-clone icon-sm" />
                  </a>
                </div>
              </td>
            </>
          )}
          <td>{!isBank ? amount() : amountOpenPayd()}</td>
          <td>{!isBank ? item.type : transactionType}</td>
          {isBank && (
            <>
              <td>{sender?.bankName}</td>
              <td>{beneficiary?.bankName}</td>
            </>
          )}
          {!isBank && <td>{this.renderResponseCode()}</td>}
          <td>{this.renderRiskStatus()}</td>
          <td>
            <a
              className="text-primary"
              onClick={() => this.handleRulesInfo()}
              title="Rules Triggered Info"
            >
              <i className="fa fa-info-circle" />
            </a>
          </td>
          {!isBank && (
            <td>
              <a
                className="text-warning"
                onClick={() => this.handleExceptionRuleModal(item)}
                title="Create Exception Rule"
              >
                <i className="fa fa-exclamation-triangle" />
              </a>
              {this.state.showModal && this.renderModal(item)}
            </td>
          )}
        </DataTableRow>
        {openDetails && (
          <DataTableRow>
            <td className="details" colSpan="14">
              <table>
                <tbody>{this.renderItemDetails()}</tbody>
              </table>
            </td>
          </DataTableRow>
        )}
        {openRulesInfo && (
          <DataTableRow>
            <td colSpan="14">
              <table>
                <thead>
                  <tr>
                    <th>Triggered Rule</th>
                    <th>Description</th>
                  </tr>
                </thead>
                {this.renderRuleInfo()}
              </table>
            </td>
          </DataTableRow>
        )}
      </tbody>
    );
  }
}

TransactionsListItem.propTypes = {
  item: PropTypes.object,
  isModalOpen: PropTypes.bool,
  isBank: PropTypes.bool,
  isGateway: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps)(TransactionsListItem);
