import React from 'react';
import { Box } from '@mui/material';

export const LoadingBox = (): JSX.Element => (
  <Box
    sx={{
      width: 1,
      height: '122px',
      bgcolor: 'white',
      mb: 6,
      borderRadius: '1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    Loading...
  </Box>
);
