/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_MERCHANT_INFO,
  LOAD_MERCHANT_INFO_SUCCESS,
  LOAD_MERCHANT_INFO_FAIL,
  LOAD_MERCHANT_TOTALS,
  LOAD_MERCHANT_TOTALS_SUCCESS,
  LOAD_MERCHANT_TOTALS_FAIL,
} from '../constants/constants';

export const initialState = {
  merchantProfileLoading: false,
  merchantProfileTotalsLoading: false,
  error: false,
  merchantProfile: [],
  merchantProfileTotals: [],
};

const merchantProfileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MERCHANT_INFO:
        draft.merchantProfileLoading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANT_INFO_SUCCESS:
        draft.merchantProfile = action.payload;
        draft.merchantProfileLoading = false;
        break;
      case LOAD_MERCHANT_INFO_FAIL:
        draft.error = action.error;
        draft.merchantProfileLoading = false;
        break;
      case LOAD_MERCHANT_TOTALS:
        draft.merchantProfileTotalsLoading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANT_TOTALS_SUCCESS:
        draft.merchantProfileTotals = action.payload;
        draft.merchantProfileTotalsLoading = false;
        break;
      case LOAD_MERCHANT_TOTALS_FAIL:
        draft.error = action.error;
        draft.merchantProfileTotalsLoading = false;
        break;
    }
  });

export default merchantProfileReducer;
