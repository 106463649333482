import React, { useState } from 'react';
import { Button, IconButton, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import { RiArrowDownLine, RiArrowDownSLine, RiArrowUpLine } from 'react-icons/ri';

import { SortByBlockProps } from './SortByBlockProps';
import './SortByBlock.scss';

export const SortByBlock: React.FC<SortByBlockProps> = ({ sortOptions, sortedBy, setSortedBy }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleChangeSelected = (element, e) => {
    e.stopPropagation();
    setSortedBy({
      property: element,
      direction: 'asc',
    });
    setAnchorEl(null);
  };

  const toggleSortOrder = () =>
    setSortedBy((prev) => ({
      ...prev,
      direction: sortedBy?.direction === 'asc' ? 'desc' : 'asc',
    }));

  const label =
    sortOptions.find((item) => item.fieldName === sortedBy?.property)?.label || 'click for select';

  return (
    <>
      <div className="SortByBlock">
        <Typography variant="body" color="grey.600">
          {'Sort by '}
        </Typography>

        <Button
          color="base"
          variant="contained"
          onClick={handleClick}
          endIcon={<RiArrowDownSLine size="16px" />}
          sx={{ ml: 1, bgcolor: 'grey.100', '&:hover': { bgcolor: 'grey.300' } }}
        >
          {label}
        </Button>

        <IconButton
          onClick={toggleSortOrder}
          sx={{
            ml: 1,
            color: 'text.primary',
            bgcolor: 'grey.100',
            transition: '.3s background-color',
            '&:hover': { bgcolor: 'grey.300' },
          }}
        >
          {sortedBy?.direction === 'asc' ? (
            <RiArrowDownLine size="16px" />
          ) : (
            <RiArrowUpLine size="16px" />
          )}
        </IconButton>
      </div>

      <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)}>
        {sortOptions.map((el) => (
          <MenuItem onClick={(e) => handleChangeSelected(el.fieldName, e)} key={el.fieldName}>
            <ListItemText>{el.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
