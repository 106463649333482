import React, { useState } from 'react';
import { Panel } from 'uikit';
import { Button as Btn } from 'uikit/Btn/Button';
import { Typography } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './MilqButtonsSection.scss';

export function MilqButtonsSection() {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Typography variant="h2" gutterBottom>
        UIKit buttons
      </Typography>

      <Btn
        style={{ margin: '1rem 0' }}
        onClick={() => setLoading(!loading)}
        bold
        theme="primary"
        variant="light"
      >
        Load
      </Btn>

      <Panel>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="contained"
          theme="base"
          size="xs"
        >
          Default
        </Btn>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="light"
          theme="base"
          size="sm"
        >
          Default
        </Btn>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="contained"
          theme="primary"
          size="md"
        >
          Primary
        </Btn>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="outlined"
          theme="success"
          size="lg"
        >
          Success
        </Btn>

        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="text"
          theme="base"
          size="sm"
        >
          Default
        </Btn>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="text"
          theme="primary"
          size="md"
        >
          Primary
        </Btn>
        <Btn
          className="btn-offset"
          loading={loading}
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="text"
          theme="success"
          size="lg"
        >
          Success
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold theme="base">
          Base bold
        </Btn>
        <Btn className="btn-offset" bold disabled theme="base">
          Base disabled bold
        </Btn>
        <Btn className="btn-offset" bold variant="contained" theme="base">
          Base contained bold
        </Btn>
        <Btn className="btn-offset" bold variant="light" theme="base">
          Base light bold
        </Btn>
        <Btn className="btn-offset" bold variant="outlined" theme="base">
          Base outlined bold
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold theme="primary">
          Primary bold
        </Btn>
        <Btn className="btn-offset" bold variant="contained" theme="primary">
          Primary contained bold
        </Btn>
        <Btn className="btn-offset" bold variant="light" theme="primary">
          Primary light bold
        </Btn>
        <Btn className="btn-offset" bold variant="outlined" theme="primary">
          Primary outlined bold
        </Btn>
        <Btn
          className="btn-offset"
          bold
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          theme="primary"
          size="sm"
          variant="contained"
        >
          Primary contained bold prefix
        </Btn>
        <Btn
          className="btn-offset"
          bold
          suffix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          theme="primary"
          size="sm"
          variant="contained"
        >
          Primary contained bold suffix
        </Btn>
        <Btn
          className="btn-offset"
          bold
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          theme="primary"
          size="sm"
          variant="outlined"
        >
          Primary outlined bold prefix
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold theme="success">
          Success bold
        </Btn>
        <Btn className="btn-offset" bold variant="contained" theme="success">
          Success contained bold
        </Btn>
        <Btn className="btn-offset" bold variant="light" theme="success">
          Success light bold
        </Btn>
        <Btn className="btn-offset" bold variant="outlined" theme="success">
          Success outlined bold
        </Btn>
        <Btn className="btn-offset" disabled bold variant="outlined" theme="success">
          Success outlined disabled bold
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold theme="danger">
          Danger
        </Btn>
        <Btn className="btn-offset" bold variant="contained" theme="danger">
          Danger contained bold
        </Btn>
        <Btn className="btn-offset" disabled bold variant="contained" theme="danger">
          Danger contained disabled bold
        </Btn>
        <Btn className="btn-offset" bold variant="light" theme="danger">
          Danger light bold
        </Btn>
        <Btn className="btn-offset" bold variant="outlined" theme="danger">
          Danger outlined bold
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold theme="warning">
          Warning bold
        </Btn>
        <Btn className="btn-offset" bold variant="contained" theme="warning">
          Warning contained bold
        </Btn>
        <Btn className="btn-offset" bold variant="light" theme="warning">
          Warning light bold
        </Btn>
        <Btn className="btn-offset" disabled bold variant="light" theme="warning">
          Warning light disabled bold
        </Btn>
        <Btn className="btn-offset" bold variant="outlined" theme="warning">
          Warning outlined bold
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" bold variant="light" size="xs">
          XSmall
        </Btn>
        <Btn className="btn-offset" bold variant="light" size="sm">
          Small
        </Btn>
        <Btn className="btn-offset" bold variant="light" size="md">
          Medium
        </Btn>
        <Btn className="btn-offset" bold variant="light" size="lg">
          Large
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" variant="light" id="block-button" size="sm" block bold>
          Block button
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" variant="light">
          Regular text in the button
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" variant="contained">
          Circle
        </Btn>
        <Btn className="btn-offset" variant="contained" shape="circle">
          Circle
        </Btn>
        <Btn className="btn-offset" variant="contained" shape="square">
          Square
        </Btn>
      </Panel>

      <Panel>
        <Btn className="btn-offset" variant="light" wide>
          Light wide
        </Btn>
        <Btn className="btn-offset" variant="light" wide theme="success">
          Success light wide
        </Btn>
        <Btn className="btn-offset" variant="light" wide theme="danger">
          Danger light wide
        </Btn>
        <Btn className="btn-offset" variant="light" wide theme="primary">
          Primary light wide
        </Btn>
      </Panel>

      <Panel>
        <Btn
          className="btn-offset"
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          variant="contained"
          size="xs"
          bold
          theme="primary"
        >
          Add
        </Btn>
        <Btn
          className="btn-offset"
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          variant="outlined"
          size="sm"
          bold
          theme="primary"
        >
          Bookmark
        </Btn>
        <Btn
          className="btn-offset"
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          variant="light"
          size="md"
        >
          Information
        </Btn>
        <Btn
          className="btn-offset"
          prefix={<AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />}
          variant="contained"
          size="lg"
          bold
          theme="success"
        >
          Star
        </Btn>
        <Btn
          className="btn-offset"
          suffix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="outlined"
          size="sm"
        >
          Refresh
        </Btn>
      </Panel>

      <Panel>
        <Btn
          className="btn-offset"
          iconButton
          prefix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="contained"
          theme="base"
          size="xs"
        />
        <Btn
          className="btn-offset"
          iconButton
          prefix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="outlined"
          theme="danger"
          size="sm"
        />
        <Btn
          className="btn-offset"
          iconButton
          prefix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="contained"
          theme="warning"
          size="md"
        />
        <Btn
          className="btn-offset"
          iconButton
          prefix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="text"
          theme="base"
          size="md"
        />
        <Btn
          className="btn-offset"
          iconButton
          shape="circle"
          prefix={
            <>
              <AccessTimeFilledIcon sx={{ fontSize: 'inherit' }} />
            </>
          }
          variant="contained"
          theme="primary"
          size="lg"
        />

        <Btn
          iconButton
          shape="rounded"
          prefix={<MoreVertIcon sx={{ fontSize: '16px' }} />}
          variant="text"
          theme="base"
          size="xs"
        />
      </Panel>
    </>
  );
}
