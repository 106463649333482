import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { thresholdInitialModel } from './constatns';
import { updateThreshold } from './rulesSlice';
import { RulesAnswer, StateType, Threshold, ThresholdKeys } from './types';
import { GetLamp } from './RulesTree/GetLamp';
import { error, grey } from '../../theme/palette-blocks';
import { findMinValue, getPrevNext, thresholdModel } from './rulesUtils';

interface ThresholdDialogProps {
  onClose: () => void;
}

const initialErrors = [];
const menuButtonWidth = '170px';

export const ThresholdDialog: React.FC<ThresholdDialogProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { thresholds } = useSelector((state: StateType) => ({
    thresholds: state.ruleSetState.thresholds,
  }));

  const [formErrors, setFormErrors] = useState<string[]>(initialErrors);
  const [currentThresholds, setCurrentThresholds] = useState<Threshold>(
    thresholds || thresholdInitialModel,
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdate = () => {
    // TODO make POST API when BE will be ready;
    dispatch(updateThreshold(currentThresholds));
    onClose();
  };

  useEffect(() => {
    const newErrors = [];
    Object.keys(currentThresholds).forEach((name) => {
      if (
        currentThresholds[name] <= Number(getPrevNext(name as RulesAnswer, currentThresholds)[0]) ||
        currentThresholds[name] >= Number(getPrevNext(name as RulesAnswer, currentThresholds)[1])
      ) {
        newErrors.push(name);
      }
      setFormErrors(newErrors);
    });
  }, [currentThresholds]);

  const isThreshold = (name: ThresholdKeys): boolean => name in currentThresholds;

  const handleToggleThreshold = (name: ThresholdKeys) => {
    if (isThreshold(name)) {
      setCurrentThresholds((prev) => {
        const { [name]: deletedField, ...updatedThresholds } = prev;
        return updatedThresholds;
      });
      setAnchorEl(null);
    } else {
      const prevScore = Number(getPrevNext(name, currentThresholds)[0]);
      const nextScore = Number(getPrevNext(name, currentThresholds)[1]);
      const newValue = Math.floor((nextScore - prevScore) / 2) + prevScore;
      const newFields = thresholdModel
        .map((el) => el.value)
        .filter(
          (el) => el === name || Object.keys(currentThresholds).some((field) => field === el),
        );
      const newThresholds: Threshold = {};
      newFields.forEach((field) => {
        Object.assign(newThresholds, { [field]: currentThresholds[field] || newValue });
      });
      setCurrentThresholds(newThresholds);
      setAnchorEl(null);
    }
  };

  const isError = (name) => formErrors.some((el) => el === name);

  return (
    <>
      <Typography color="grey.500" pb={4}>
        {`"Pass" status will be from 0 to ${findMinValue(currentThresholds)} score`}
      </Typography>
      <Stack direction="column" spacing={4}>
        {Object.keys(currentThresholds).map((value) => (
          <Stack key={value} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <GetLamp answer={value as RulesAnswer} />
              <Typography color="grey.900">
                {thresholdModel.find((el) => el.value === value).label}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography
                style={{
                  visibility: isThreshold(value as ThresholdKeys) ? 'visible' : 'hidden',
                }}
              >
                ≥
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <input
                  type="number"
                  value={currentThresholds[value as ThresholdKeys]}
                  onChange={(e) =>
                    setCurrentThresholds((prev) => ({ ...prev, [value]: e.target.value }))
                  }
                  max={Number(getPrevNext(value as RulesAnswer, currentThresholds)[1]) - 1}
                  min={Number(getPrevNext(value as RulesAnswer, currentThresholds)[0]) + 1}
                  style={{
                    visibility: isThreshold(value as ThresholdKeys) ? 'visible' : 'hidden',
                    width: '96px',
                    height: '32px',
                    backgroundColor: isError(value) ? error.light : grey[100],
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 12px 0 12px',
                    minHeight: '32px',
                  }}
                />
              </div>
            </Stack>
          </Stack>
        ))}
        <Button
          style={{ width: menuButtonWidth }}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(e);
          }}
          startIcon={<SettingsIcon style={{ fontSize: '14px' }} />}
        >
          Manage thresholds
        </Button>
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end" pb={4} pt={7}>
        <Button color="base" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleUpdate}
          disabled={formErrors.length !== 0}
        >
          Update
        </Button>
      </Stack>

      {!!open && (
        <Menu
          className="MenuThresholds"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            '&.MuiPaper-root MuiMenu-paper MuiPopover paper': { maxWidth: menuButtonWidth },
          }}
        >
          {thresholdModel.map(({ value, label }) => (
            <MenuItem
              onClick={() => handleToggleThreshold(value as ThresholdKeys)}
              key={`MenuItem-${value}`}
              sx={{ width: menuButtonWidth }}
            >
              <ListItemText>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Checkbox sx={{ mr: 3 }} checked={isThreshold(value as ThresholdKeys)} />
                  }
                  label={label}
                />
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
