import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Box, ListItemText, Menu, MenuItem, Typography, Button } from '@mui/material';
import {
  Check as CheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { DatePicker, InputBox, ISelectOption, Select } from 'uikit';
import { removeEmptyProperties } from 'utils/modelHelper';
import {
  useCreateSearchSessionMutation,
  usePatchSearchMutation,
  usePerformSearchMutation,
} from 'services/gatewayApi/pepAndSanctionsApi';
import { formatDateServer } from 'utils';
import { USERNAME_REGEX } from 'constants/constants';
import { FilterElement, SanctionsRecord, SearchRequest, SearchType } from '../types';

interface SearchParamsBoxProps {
  searchType: SearchType;
  paramsScheme?: FilterElement[];
  searchParams?: SearchRequest;
  setSearchParams?: React.Dispatch<React.SetStateAction<SearchRequest>>;
  setSelectedResultId?: React.Dispatch<React.SetStateAction<string>>;
  setHistorySearchResult?: React.Dispatch<React.SetStateAction<SanctionsRecord[]>>;
  setPerformedOn?: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchParamsBox: React.FC<SearchParamsBoxProps> = ({
  paramsScheme = undefined,
  searchType,
  searchParams = undefined,
  setSearchParams = undefined,
  setSelectedResultId = undefined,
  setHistorySearchResult = undefined,
  setPerformedOn = undefined,
}) => {
  const navigate = useNavigate();
  const { applicationId, searchSessionId } = useParams<{
    applicationId: string;
    searchSessionId: string;
  }>();
  const [createSearchSession] = useCreateSearchSessionMutation();
  const [patchSearch] = usePatchSearchMutation();
  const [performSearch] = usePerformSearchMutation();
  const isNewSearch = searchSessionId === 'new-search';

  const getParams = () => {
    if (searchParams === undefined) return undefined;
    const params = [];
    paramsScheme.forEach((el) => {
      if (!searchParams[el.name] && !el.required) return;
      if (el.required && !searchParams[el.name]) {
        params.push(el);
      } else params.push({ ...el, initialFilterValue: searchParams[el.name] });
    });
    return params;
  };
  const [selectedParams, setSelectedParams] = useState(getParams());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (searchParams === undefined) {
      setSelectedParams(paramsScheme.filter((el) => el.required === true));
    } else
      setSelectedParams(
        paramsScheme
          .filter((el) => el.required === true || Object.keys(searchParams).includes(el.name))
          .map((el) => ({
            ...el,
            initialFilterValue: searchParams[el.name]
              ? searchParams[el.name]
              : el.initialFilterValue,
          })),
      );
  }, [searchParams]);

  // initialValues = params from searchParams props + required from paramsScheme
  const initialValues = paramsScheme
    .map((el) => ({
      [el.name]: selectedParams?.find((element) => element.name === el.name)
        ? selectedParams?.find((element) => element.name === el.name).initialFilterValue
        : el.initialFilterValue,
    }))
    .reduce((result, item) => {
      const key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});

  const required = yup.string().matches(USERNAME_REGEX, 'Only text, please').required('Required');
  const date = yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr));
  const validationSchema = yup.object({
    dob: date,
    firstName:
      searchType === 'business'
        ? yup.string().matches(USERNAME_REGEX, 'Only text, please')
        : required,
    lastName: required,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setSelectedResultId(undefined);
      setHistorySearchResult(undefined);
      setPerformedOn(undefined);
      const params = { ...values };
      removeEmptyProperties(params);
      if (params.dob) {
        params.dob = formatDateServer(values.dob);
      }

      const arrParams = Object.entries(params);
      const removedEntities = arrParams.filter(([key]) => key !== 'entities');
      const paramsWithoutEntities = Object.fromEntries(removedEntities);
      setSearchParams(params.entities?.length === 1 ? params : paramsWithoutEntities);

      const { entities } = params;
      if (!entities) {
        searchType === 'individual'
          ? (params.entities = ['M', 'F']) // for individuals search
          : (params.entities = ['E', 'U']); // for business search
      }

      if (isNewSearch) {
        await createSearchSession({
          applicationId,
          body: {
            reasonToPerform: 'MANUAL',
            searchRequest: { ...params },
          },
        })
          .unwrap()
          .then((payload) => {
            performSearch({ applicationId: payload.applicationId, searchId: payload.id })
              .unwrap()
              .then((payloadPerform) => {
                navigate(
                  applicationId
                    ? `/application-management/${applicationId}/pep-and-sanctions/${payloadPerform.id}`
                    : `/pep-and-sanctions/applications/${payloadPerform.id}`,
                );
              })
              .catch((error) => console.error('rejected: ', error));
          })
          .catch((error) => console.error('rejected: ', error));
      }
      if (!isNewSearch) {
        await patchSearch({
          applicationId,
          searchId: searchSessionId,
          body: {
            reasonToPerform: 'MANUAL',
            searchRequest: { ...params },
          },
        })
          .unwrap()
          .then((payload) => {
            performSearch({ applicationId, searchId: payload.id })
              .unwrap()
              .catch((error) => console.error('rejected: ', error));
          })
          .catch((error) => console.error('rejected: ', error));
      }
    },
  });

  const handleChangeSelected = (element, e) => {
    e.stopPropagation();
    const fieldClicked = selectedParams.find((el) => el.name === element);
    const isChecked = !!fieldClicked;
    const fieldRequired = fieldClicked?.required;
    isChecked && formik.setFieldValue(element, formik.initialValues[element]);
    !fieldRequired &&
      (isChecked
        ? setSelectedParams((prev) => prev.filter((el) => el.name !== element))
        : setSelectedParams((prev) => [...prev, paramsScheme.find((el) => el.name === element)]));
    setAnchorEl(null);
  };

  const getLabel = (label) => {
    const isChecked = !!selectedParams?.find((el) => el.label === label);
    const isDisabled = selectedParams?.find((el) => el.label === label)?.required;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography color={isDisabled ? 'grey.600' : 'grey.900'} pr={2}>
          {label}
        </Typography>
        {isChecked && <CheckIcon color={isDisabled ? 'disabled' : 'success'} fontSize="small" />}
      </Box>
    );
  };

  const isDisabled = (name) =>
    !!paramsScheme.find((el) => el.name === name).required && !isNewSearch;

  return (
    <Box sx={{ bgcolor: 'white', mb: 6, borderRadius: '1em' }} className="SearchParamsBox ">
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(4, 1fr)', p: 3 }}>
          {selectedParams?.map((field) => (
            <React.Fragment key={field.name}>
              {field.field === 'text' && (
                <InputBox
                  label={field.label}
                  name={field.name}
                  onChange={formik.handleChange}
                  handleClear={() => formik.setFieldValue(field.name, '')}
                  value={formik.values[field.name]}
                  disabled={field.disabled || formik.isSubmitting || isDisabled(field.name)}
                  hasError={!!(formik.errors[field.name] && formik.touched[field.name])}
                />
              )}
              {field.field === 'datePicker' && (
                <DatePicker
                  label={field.label}
                  name={field.name}
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  disabled={field.disabled || formik.isSubmitting}
                  error={!!(formik.errors[field.name] && formik.touched[field.name])}
                />
              )}
              {field.field === 'select' && (
                <Select
                  name={field.name}
                  selectBox
                  label={field.label}
                  disabled={field.disabled || formik.isSubmitting}
                  options={field.options}
                  onChange={(option: ISelectOption) => {
                    formik.setFieldValue(field.name, option ? option.value : '');
                  }}
                  value={
                    formik.values[field.name]
                      ? field.options.find((c) => c.value === formik.values[field.name])
                      : null
                  }
                  hasError={!!(formik.errors[field.name] && formik.touched[field.name])}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            px: 3,
            pt: 0,
            pb: 3,
          }}
        >
          <Button
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon sx={{ fontSize: 'inherit' }} />}
          >
            More parameters
          </Button>
          <Button variant="contained" fullWidth type="submit" sx={{ width: 'calc(25% - 8px)' }}>
            Find
          </Button>
        </Box>
      </form>

      {!!anchorEl && (
        <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)}>
          {paramsScheme.map((el) => (
            <MenuItem onClick={(e) => handleChangeSelected(el.name, e)} key={el.name}>
              <ListItemText>{getLabel(el.label)}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};
