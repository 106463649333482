export default function (e) {
  const val = e.target.value;
  const validationType = e.target.getAttribute('data-validationtype');
  let isValid = true;
  if (!validationType) return true;
  switch (validationType) {
    case 'alphabetic': {
      const pattern = /^([a-zA-ZsçÇöÖşŞıİğĞüÜ]*)$/;
      isValid = pattern.test(val);
      break;
    }
    case 'ipAddress': {
      const pattern = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
      isValid = pattern.test(val);
      break;
    }
    default:
      isValid = true;
  }

  return isValid;
}
