import { createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import { gatewayApi, getKCHeaders } from '../gatewayApi';
import { setUploadProgress } from '../indicatorsSlice';
import { toastError } from '../../utils';

export enum VirusStatus {
  Pending = 'PENDING',
  Checked = 'CHECKED',
  Quarantined = 'QUARANTINED',
}

export interface ApplicationNoteDocument {
  id: string;
  noteId: string;
  title: string;

  fileName?: string;
  fileSize?: number;
  fileType?: string;
  virusStatus: VirusStatus;

  createdBy: string;
  createdByName: string;
  createdDate: string;

  uploadedBy: string;
  uploadedByName: string;
  uploadedDate: string;
}

export interface ApplicationNoteDocumentMetadata {
  fileName: string;
  size: string;
  type: string;
}

export const applicationNoteDocumentsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationNoteDocuments: build.query<
      Array<ApplicationNoteDocument>,
      { applicationId: string; noteId: string }
    >({
      query: ({ applicationId, noteId }) => ({
        url: `applications/${applicationId}/notes/${noteId}/documents`,
        method: 'GET',
      }),
    }),
    patchApplicationNoteDocument: build.mutation({
      query({ id, applicationId, noteId, ...patch }) {
        return {
          url: `applications/${applicationId}/notes/${noteId}/documents/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      async onQueryStarted({ id, applicationId, noteId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationNoteDocumentsApi.util.updateQueryData(
            'getApplicationNoteDocuments',
            { applicationId, noteId },
            (draft) => {
              const document = draft.find((d) => d.id === id);
              document && Object.assign(document, { ...patch });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteApplicationNoteDocument: build.mutation({
      query({ applicationId, noteId, id }) {
        return {
          url: `applications/${applicationId}/notes/${noteId}/documents/${id}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ id, applicationId, noteId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          applicationNoteDocumentsApi.util.updateQueryData(
            'getApplicationNoteDocuments',
            { applicationId, noteId },
            (draft) => (draft = draft.filter((doc) => doc.id !== id)),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    addApplicationNoteDocument: build.mutation<
      ApplicationNoteDocument,
      { applicationId: string; noteId: string; title: string }
    >({
      query({ applicationId, noteId, title }) {
        return {
          url: `applications/${applicationId}/notes/${noteId}/documents`,
          method: 'POST',
          body: { title },
        };
      },
      async onQueryStarted({ applicationId, noteId }, { dispatch, queryFulfilled }) {
        try {
          const { data: document } = await queryFulfilled;
          dispatch(
            applicationNoteDocumentsApi.util.updateQueryData(
              'getApplicationNoteDocuments',
              { applicationId, noteId },
              (draft) => {
                draft.push(document);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    uploadApplicationNoteDocument: build.mutation<
      ApplicationNoteDocumentMetadata,
      { formData: FormData; id: string; applicationId: string; noteId: string; signal: AbortSignal }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async ({ formData: data, id, applicationId, noteId, signal }, api) => {
        const url = `/api/gateway/applications/${applicationId}/notes/${noteId}/documents/${id}/upload`;
        try {
          const result = await axios.post(url, data, {
            signal,
            headers: getKCHeaders(),
            method: 'POST',
            onUploadProgress: (upload) => {
              // Set the progress value to show the progress bar
              const uploadloadProgress = Math.round((100 * upload.loaded) / upload.total);
              api.dispatch(setUploadProgress({ id, progressValue: uploadloadProgress }));
            },
          });
          return { data: result.data };
        } catch (axiosError) {
          if (!axios.isCancel(axiosError)) {
            throw axiosError;
          }
          return {}; // cancellation, return an empty result
        }
      },
      async onQueryStarted({ id, applicationId, noteId }, { dispatch, queryFulfilled }) {
        try {
          const queryResult = await queryFulfilled;
          if (queryResult && queryResult.data) {
            const { data: documentMetadata } = queryResult;
            const { size: fileSize, fileName } = documentMetadata;
            dispatch(
              applicationNoteDocumentsApi.util.updateQueryData(
                'getApplicationNoteDocuments',
                { applicationId, noteId },
                (draft) => {
                  const document = draft.find((d) => d.id === id);
                  document &&
                    Object.assign(document, {
                      fileSize,
                      fileName,
                      isRejected: false,
                      virusStatus: VirusStatus.Pending,
                    });
                },
              ),
            );
          }
        } catch (e) {
          console.error(e);
          toastError(`Document upload failed. ${e.error.message}`);
        }
      },
    }),
  }),
});

export const selectDocument = (applicationId: string, noteId: string, id: string) =>
  createSelector(
    applicationNoteDocumentsApi.endpoints.getApplicationNoteDocuments.select({
      applicationId,
      noteId,
    }),
    (documents) => documents?.data?.find((d) => d.id === id) ?? {},
  );

export const {
  useGetApplicationNoteDocumentsQuery,
  useAddApplicationNoteDocumentMutation,
  usePatchApplicationNoteDocumentMutation,
  useDeleteApplicationNoteDocumentMutation,
  useUploadApplicationNoteDocumentMutation,
} = applicationNoteDocumentsApi;
