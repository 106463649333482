import { styled } from '@mui/material/styles';

export const DocumentActions = styled('div', {
  name: 'documentActions',
  slot: 'root',
  shouldForwardProp: (prop) => prop !== 'sx',
})(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  }),
);
