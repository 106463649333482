import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ color }) => <i className="fa fa-spinner fa-spin" style={{ color }} />;

Spinner.defaultProps = {
  color: '#73777c',
};

Spinner.propTypes = {
  color: PropTypes.string,
};

export default Spinner;
