import * as _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';

const resetIds = (array) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    obj.id = uuidV4();
    if (obj.children) {
      resetIds(obj.children);
    }
  }
};

const clone = (rules, id) => {
  rules.forEach((rule) => {
    if (rule.id === id) {
      const clonedRule = _.cloneDeep(rule);
      clonedRule.id = uuidV4();
      clonedRule.title = `CLONE - ${rule.title}`;
      if (clonedRule.children) {
        resetIds(clonedRule.children);
      }
      rules.push(clonedRule);
      return true;
    }
    if (rule.children) {
      return clone(rule.children, id);
    }
    return false;
  });
};
// this functions purpose is find the object with matching id and delete
// for doing this recurive function used
const prune = (array, id) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    if (obj.id === id) {
      // splice out 1 element starting at position i
      array.splice(i, 1);
      return true;
    }
    if (obj.children) {
      if (prune(obj.children, id)) {
        return true;
      }
    }
  }
  return false;
};
// this functions purpose is find the parent rule with matching id and add child rule
// for doing this recurive function used
const add = (array, id, rule) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    if (obj.id === id) {
      obj.children.push(rule);
      return true;
    }
    if (obj.children) {
      if (add(obj.children, id, rule)) {
        return true;
      }
    }
  }
  return false;
};

// this functions purpose is find the parent rule with matching id and update it
// for doing this recurive function used
const update = (array, rule) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    if (obj.id === rule.id) {
      obj.answer = rule.answer;
      obj.categoryType = rule.categoryType;
      obj.ruleCategoryId = rule.ruleCategoryId;
      obj.returnOnReject = rule.returnOnReject;
      obj.title = rule.title;
      obj.description = rule.description;
      obj.script = rule.script;
      obj.vetoTTL = rule.vetoTTL;
      obj.id = rule.id;
      obj.children = rule.children;
      obj.newRule = rule.newRule;
      obj.seonRule = rule.seonRule;
      obj.score = rule.score;

      return true;
    }
    if (obj.children) {
      if (update(obj.children, rule)) {
        return true;
      }
    }
  }
  return false;
};

// it simply brings tree node with the matching nodeId with recursive function
const getRule = (array, id) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    if (obj.id === id) {
      return obj;
    }
    if (obj.children) {
      const objToSend = getRule(obj.children, id);
      if (objToSend) {
        return objToSend;
      }
    }
  }
  return null;
};

export { prune, add, update, getRule, clone };
