import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { useGetRuleVetosQuery } from 'services/gatewayApi/ruleVetosApi';
import { ModalDialog } from 'uikit';
import { RuleVetoRow } from './RuleVetoRow';
import { RuleVetoDialog } from './RuleVetoDialog';

interface RuleVetosProps {
  ruleId: string;
}

export const RuleVetos: React.FC<RuleVetosProps> = ({ ruleId }) => {
  const navigate = useNavigate();
  const [openVetoDialog, setOpenVetoDialog] = useState<boolean>(false);

  const {
    data: ruleVetosList,
    isLoading: isLoadingVetos,
    isFetching: isFetchingVetos,
  } = useGetRuleVetosQuery({ ruleId }, { skip: !ruleId });

  return (
    <div className="RuleVetoes">
      <Stack
        className="ManageVetoes"
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>Rule vetos</Typography>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setOpenVetoDialog(true)}
            disabled={false}
          >
            <AddCircleOutlineIcon style={{ fontSize: '14px' }} />
          </IconButton>
        </Stack>
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={() => navigate('/rule-vetos-monitor')}
        >
          Manage vetos
        </Button>
      </Stack>
      {ruleVetosList && ruleVetosList.length > 0 ? (
        <Stack className="RuleVevosList" direction="column" spacing={2} mt={2}>
          {ruleVetosList.map((veto) => (
            <RuleVetoRow key={veto.id} veto={veto} />
          ))}
        </Stack>
      ) : (
        <Typography color="grey.500" pt={2}>
          {isLoadingVetos || isFetchingVetos ? 'Loading...' : 'No added vetoes'}
        </Typography>
      )}
      {openVetoDialog && (
        <ModalDialog
          open={!!openVetoDialog}
          onClose={() => setOpenVetoDialog(false)}
          title="Create rule veto"
        >
          <RuleVetoDialog ruleId={ruleId} onClose={() => setOpenVetoDialog(false)} />
        </ModalDialog>
      )}
    </div>
  );
};
