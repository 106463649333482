import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface EmptyPageProps {
  isLoading: boolean;
  title: string;
  subtitle?: string;
}

export const EmptyPage: React.FC<EmptyPageProps> = ({ isLoading, title, subtitle }) => (
  <>
    <Box
      width={1}
      height="300px"
      alignItems="start"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography component="div" variant="h1" color="grey.400" fontSize="44px">
            {title}
          </Typography>
          {!!subtitle && (
            <Typography component="div" variant="body" color="grey.600" mt={6}>
              {subtitle}
            </Typography>
          )}
        </>
      )}
    </Box>
  </>
);
