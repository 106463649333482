import React from 'react';
import { Typography } from '@mui/material';

import './SectionHeading.scss';

interface SectionHeadingProps {
  title: string;
  description?: string;
  actionButtons?: any;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ title, description, actionButtons }) => (
  <div className="SectionHeading">
    <div className="SectionHeadingTop">
      <h3 className="SectionHeadingTitle">{title}</h3>
      {actionButtons}
    </div>
    {/* {description && ( */}
    {/*  <Typography variant="body2" component="p" color="grey.600" mb={2}> */}
    {/*    {description} */}
    {/*  </Typography> */}
    {/* )} */}
  </div>
);

export default SectionHeading;
