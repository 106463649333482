import { createSlice } from '@reduxjs/toolkit';
import {
  addRuleAfter,
  copyRuleById,
  removeById,
  restoreRuleUtil,
  setFieldsById,
  setIsActiveDeepUtil,
  setMarkForDeleteUtil,
  updateExpandUtil,
  updateRuleUtil,
} from './rulesUtils';
import { RuleSetState } from './types';

const initialState: RuleSetState = {
  rulesTree: [],
  currentRuleSetId: null,
  searchString: '',
  expandedList: [],
  thresholds: undefined,
};

const rulesSlice = createSlice({
  name: 'rulesState',
  initialState,
  reducers: {
    addRuleOnTop(state, action) {
      state.rulesTree.unshift(action.payload);
    },
    addRuleAfterId(state, action) {
      addRuleAfter(state.rulesTree, action.payload);
    },
    removeRuleById(state, action) {
      removeById(state.rulesTree, action.payload as string);
    },
    setMarkForDelete(state, action) {
      setMarkForDeleteUtil(state.rulesTree, action.payload);
    },
    setFieldValue(state, action) {
      setFieldsById(state.rulesTree, action.payload);
    },
    setIsActiveDeep(state, action) {
      setIsActiveDeepUtil(state.rulesTree, action.payload);
    },
    addCopy(state, action) {
      copyRuleById(state.rulesTree, action.payload);
    },
    updateRuleset(state, action) {
      state.rulesTree = action.payload || [];
    },
    restoreRule(state, action) {
      restoreRuleUtil(state.rulesTree, action.payload);
    },
    updateRule(state, action) {
      updateRuleUtil(state.rulesTree, action.payload);
    },
    updateCurrentRuleSetId(state, action) {
      state.currentRuleSetId = action.payload;
    },
    updateSearchString(state, action) {
      state.searchString = action.payload;
    },
    updateExpanded(state, action) {
      const isUpdated = JSON.stringify(state.expandedList) !== JSON.stringify(action.payload);
      if (isUpdated) {
        state.rulesTree = updateExpandUtil(state.rulesTree, action.payload);
        state.expandedList = action.payload;
      }
    },
    updateThreshold(state, action) {
      state.thresholds = action.payload;
    },
  },
});

export const {
  addRuleOnTop,
  removeRuleById,
  updateRuleset,
  addCopy,
  setFieldValue,
  addRuleAfterId,
  setMarkForDelete,
  setIsActiveDeep,
  restoreRule,
  updateRule,
  updateCurrentRuleSetId,
  updateSearchString,
  updateExpanded,
  updateThreshold,
} = rulesSlice.actions;

export const ruleReducer = rulesSlice.reducer;
