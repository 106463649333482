import React from 'react';

export const AppLogo = () => (
  <svg width="104" height="24" viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.6004 17.2498H18.3484C18.1232 17.2498 18.0106 17.1478 18.0106 16.9438V5.98178C18.0106 5.77778 18.1232 5.67578 18.3484 5.67578H19.6004C19.8256 5.67578 19.9382 5.77778 19.9382 5.98178V10.4818C19.9382 10.6018 20.0177 10.6618 20.1767 10.6618H24.3697C24.5021 10.6618 24.5684 10.6018 24.5684 10.4818V5.98178C24.5684 5.77778 24.681 5.67578 24.9062 5.67578H26.178C26.4032 5.67578 26.5158 5.77778 26.5158 5.98178V16.9438C26.5158 17.1478 26.4032 17.2498 26.178 17.2498H24.9062C24.681 17.2498 24.5684 17.1478 24.5684 16.9438V12.3898C24.5684 12.2698 24.5021 12.2098 24.3697 12.2098H20.1767C20.0177 12.2098 19.9382 12.2698 19.9382 12.3898V16.9438C19.9382 17.1478 19.8256 17.2498 19.6004 17.2498Z"
      fill="white"
    />
    <path
      d="M30.531 17.2498H29.1002C28.9148 17.2498 28.8485 17.1478 28.9015 16.9438L32.3592 5.98178C32.4255 5.77778 32.5845 5.67578 32.8362 5.67578H34.8234C35.0883 5.67578 35.2473 5.77778 35.3003 5.98178L38.758 16.9438C38.811 17.1478 38.7381 17.2498 38.5394 17.2498H37.1285C37.0093 17.2498 36.9232 17.2318 36.8702 17.1958C36.8304 17.1478 36.7973 17.0638 36.7708 16.9438L36.0157 14.6038H31.6438L30.8887 16.9438C30.8357 17.1478 30.7165 17.2498 30.531 17.2498ZM33.79 7.40378L32.0413 13.1638H35.6381L33.8695 7.40378H33.79Z"
      fill="white"
    />
    <path
      d="M45.3691 17.2498H44.0973C43.8721 17.2498 43.7595 17.1478 43.7595 16.9438V12.9658L40.0434 5.99978C39.9904 5.90378 39.9904 5.82578 40.0434 5.76578C40.0964 5.70578 40.1693 5.67578 40.262 5.67578H41.8518C42.024 5.67578 42.1565 5.77778 42.2492 5.98178L44.6935 10.9318H44.8127L47.2172 5.98178C47.2967 5.77778 47.4292 5.67578 47.6147 5.67578H49.2044C49.3104 5.67578 49.3833 5.70578 49.423 5.76578C49.476 5.82578 49.476 5.90378 49.423 5.99978L45.707 12.9658V16.9438C45.707 17.1478 45.5944 17.2498 45.3691 17.2498Z"
      fill="white"
    />
    <path
      d="M51.4032 14.4418V13.9738C51.4032 13.7698 51.5158 13.6678 51.741 13.6678H53.0128C53.238 13.6678 53.3506 13.7698 53.3506 13.9738V14.2978C53.3506 14.8018 53.4699 15.1678 53.7083 15.3958C53.9601 15.6118 54.3707 15.7198 54.9404 15.7198H56.3116C56.8812 15.7198 57.2919 15.6058 57.5436 15.3778C57.7953 15.1378 57.9212 14.7538 57.9212 14.2258V13.8118C57.9212 13.4278 57.7556 13.1278 57.4244 12.9118C57.1065 12.6838 56.709 12.5398 56.2321 12.4798C55.7552 12.4078 55.2319 12.3118 54.6622 12.1918C54.0925 12.0718 53.5692 11.9338 53.0923 11.7778C52.6154 11.6218 52.2113 11.3278 51.8801 10.8958C51.5622 10.4638 51.4032 9.91178 51.4032 9.23978V8.48378C51.4032 7.60778 51.6748 6.92378 52.2179 6.43178C52.7744 5.92778 53.5361 5.67578 54.5032 5.67578H56.6097C57.59 5.67578 58.3518 5.92778 58.8949 6.43178C59.4514 6.92378 59.7296 7.60778 59.7296 8.48378V8.87978C59.7296 9.08378 59.6169 9.18578 59.3917 9.18578H58.1199C57.8947 9.18578 57.7821 9.08378 57.7821 8.87978V8.64578C57.7821 8.12978 57.6562 7.76378 57.4045 7.54778C57.1661 7.31978 56.762 7.20578 56.1923 7.20578H54.9404C54.3707 7.20578 53.9601 7.32578 53.7083 7.56578C53.4699 7.79378 53.3506 8.18978 53.3506 8.75378V9.31178C53.3506 9.85178 53.7812 10.2178 54.6423 10.4098C55.0265 10.4938 55.4438 10.5658 55.8943 10.6258C56.3579 10.6858 56.8216 10.7758 57.2853 10.8958C57.749 11.0158 58.1729 11.1778 58.5571 11.3818C58.9413 11.5738 59.2526 11.8738 59.4911 12.2818C59.7296 12.6778 59.8488 13.1638 59.8488 13.7398V14.4418C59.8488 15.3178 59.5706 16.0078 59.0142 16.5118C58.471 17.0038 57.7159 17.2498 56.7488 17.2498H54.5231C53.556 17.2498 52.7942 17.0038 52.2378 16.5118C51.6814 16.0078 51.4032 15.3178 51.4032 14.4418Z"
      fill="white"
    />
    <path
      d="M66.44 17.2498H65.1881C64.9496 17.2498 64.8304 17.1478 64.8304 16.9438V7.38578C64.8304 7.26578 64.7642 7.20578 64.6317 7.20578H62.1278C61.8893 7.20578 61.7701 7.10378 61.7701 6.89978V5.98178C61.7701 5.77778 61.8893 5.67578 62.1278 5.67578H69.5003C69.7388 5.67578 69.858 5.77778 69.858 5.98178V6.89978C69.858 7.10378 69.7388 7.20578 69.5003 7.20578H66.9964C66.8507 7.20578 66.7779 7.26578 66.7779 7.38578V16.9438C66.7779 17.1478 66.6652 17.2498 66.44 17.2498Z"
      fill="white"
    />
    <path
      d="M72.6685 17.2498H71.2377C71.0522 17.2498 70.986 17.1478 71.039 16.9438L74.4967 5.98178C74.5629 5.77778 74.7219 5.67578 74.9736 5.67578H76.9608C77.2258 5.67578 77.3847 5.77778 77.4377 5.98178L80.8955 16.9438C80.9485 17.1478 80.8756 17.2498 80.6769 17.2498H79.266C79.1467 17.2498 79.0606 17.2318 79.0076 17.1958C78.9679 17.1478 78.9348 17.0638 78.9083 16.9438L78.1531 14.6038H73.7813L73.0262 16.9438C72.9732 17.1478 72.8539 17.2498 72.6685 17.2498ZM75.9275 7.40378L74.1787 13.1638H77.7756L76.007 7.40378H75.9275Z"
      fill="white"
    />
    <path
      d="M88.4604 17.2498H86.1553C85.1749 17.2498 84.4132 17.0038 83.87 16.5118C83.3401 16.0198 83.0751 15.3358 83.0751 14.4598V8.46578C83.0751 7.57778 83.3401 6.89378 83.87 6.41378C84.4132 5.92178 85.1749 5.67578 86.1553 5.67578H88.4604C89.4275 5.67578 90.1827 5.92178 90.7258 6.41378C91.269 6.89378 91.5406 7.57778 91.5406 8.46578V9.32978C91.5406 9.54578 91.4214 9.65378 91.1829 9.65378H89.931C89.7057 9.65378 89.5931 9.54578 89.5931 9.32978V8.53778C89.5931 7.64978 89.1162 7.20578 88.1624 7.20578H86.4335C85.4796 7.20578 85.0027 7.64978 85.0027 8.53778V14.3878C85.0027 15.2758 85.4796 15.7198 86.4335 15.7198H88.1624C89.1162 15.7198 89.5931 15.2758 89.5931 14.3878V13.5958C89.5931 13.3798 89.7057 13.2718 89.931 13.2718H91.1829C91.4214 13.2718 91.5406 13.3798 91.5406 13.5958V14.4598C91.5406 15.3358 91.269 16.0198 90.7258 16.5118C90.1827 17.0038 89.4275 17.2498 88.4604 17.2498Z"
      fill="white"
    />
    <path
      d="M96.0093 17.2498H94.7573C94.5321 17.2498 94.4195 17.1478 94.4195 16.9438V5.98178C94.4195 5.77778 94.5321 5.67578 94.7573 5.67578H96.0093C96.2345 5.67578 96.3471 5.77778 96.3471 5.98178V11.6878H96.4067C96.5392 11.3638 96.7114 11.0638 96.9234 10.7878L100.719 5.98178C100.865 5.77778 101.077 5.67578 101.355 5.67578H102.825C103.104 5.67578 103.15 5.78978 102.964 6.01778L99.0894 10.7878L103.461 16.8898C103.62 17.1298 103.541 17.2498 103.223 17.2498H102.11C101.752 17.2498 101.501 17.1478 101.355 16.9438L97.917 12.0658L96.3471 13.7398V16.9438C96.3471 17.1478 96.2345 17.2498 96.0093 17.2498Z"
      fill="white"
    />
    <path
      d="M11.592 12L2.48404 3.75L2.48404 7.65789L7.27772 12L2.48404 16.3421L2.48404 20.25L11.592 12Z"
      fill="#F2A72F"
    />
    <path
      d="M3.31201 12L1.16825e-05 9L1.16139e-05 10.4211L1.15377e-05 12L1.14615e-05 13.5789L1.1393e-05 15L3.31201 12Z"
      fill="white"
    />
  </svg>
);
