import React from 'react';
import { Button, CircularProgress, MenuItem } from '@mui/material';
import { RiAddCircleLine } from 'react-icons/ri';
import { Menu } from 'muikit/Menu';
import { useAddAppFormSectionMutation } from 'services/gatewayApi/applicationFormSectionsApi';
import { FormSection } from '../../../../../../types/forms';

export const adhocSections = [
  {
    id: 390,
    title: 'Additional Account Request Form',
    description: 'Additional Account Request Form',
    sectionOrder: 3900,
  },
  {
    id: 180,
    title: 'Beneficial Owner Declaration Form',
    description: 'Beneficial Owner Declaration Form',
    sectionOrder: 900,
  },
  {
    id: 20,
    title: 'Board Resolution Form',
    description: 'Board Resolution Form',
    sectionOrder: 200,
  },
  {
    id: 410,
    title: 'CFD Questionnaire',
    description: 'CFD Questionnaire',
    sectionOrder: 150,
  },
  {
    id: 400,
    title: 'Digital Assets Additional Form',
    description: 'Digital Assets Additional Form',
    sectionOrder: 4000,
  },
  {
    id: 380,
    title: 'Financial Activity Routed Through OpenPayd',
    description: 'Financial Activity Routed Through OpenPayd',
    sectionOrder: 3800,
  },
  {
    id: 190,
    title: 'PEP Source of Wealth Declaration Form',
    description: 'PEP Source of Wealth Declaration Form',
    sectionOrder: 1000,
  },
  {
    id: 170,
    title: 'Politically Exposed Person (PEP) Self-Certification Form',
    description: 'Politically Exposed Person (PEP) Self-Certification Form',
    sectionOrder: 800,
  },
  {
    id: 370,
    title: 'Regulated Money Service Business Compliance Questionnaire',
    description: 'Regulated Money Service Business Compliance Questionnaire',
    sectionOrder: 1270,
  },
  {
    id: 160,
    title: 'Source of Wealth Declaration Form',
    description: 'Source of Wealth Declaration Form',
    sectionOrder: 700,
  },
];

const customerInformationSection = {
  id: 1,
  title: 'Customer Information',
  description: 'Please provide customer information',
  sectionOrder: 100,
};

interface AddFormsMenuProps {
  formSections: Array<FormSection>;
  applicationId: string;
}

export const AddFormsMenu = ({ applicationId, formSections }: AddFormsMenuProps): JSX.Element => {
  const [addSection, { isLoading: isAddingSection }] = useAddAppFormSectionMutation();

  const getMaxValue = (values) => {
    let max = 1;

    values.map((partitionName) => {
      const id = Number(partitionName.replace(/^\D+/g, '') || 0);
      if (max < id) {
        max = id;
      }
    });

    return max;
  };

  return (
    <Menu
      isLoading={!formSections}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      renderOpenButton={(props) => (
        <Button
          {...props}
          variant="contained"
          color="base"
          size="small"
          startIcon={
            isAddingSection ? (
              <CircularProgress size={14} sx={{ mr: 1 }} />
            ) : (
              <RiAddCircleLine size="14px" />
            )
          }
          disabled={isAddingSection}
          sx={{ bgcolor: 'grey.50' }}
        >
          Add form
        </Button>
      )}
      renderItems={(props) => [
        ...adhocSections.map(({ id, title }) => (
          <MenuItem
            key={id}
            onClick={(e) => {
              const existingSections = formSections.filter((fs) => fs.sectionId === id);
              const currentPartitionList = existingSections.map((section) => section.partition);
              const maxForPartitionName =
                existingSections.length > 0 ? getMaxValue(currentPartitionList) + 1 : 1;

              addSection({
                applicationId,
                sectionId: id,
                partition: `form-${maxForPartitionName}`,
              });
              // eslint-disable-next-line react/prop-types
              props.onClick && props.onClick(e);
            }}
          >
            {title}
          </MenuItem>
        )),
        /* temporary fix to add deleted CI section */
        !formSections.find((fs) => fs.sectionId === customerInformationSection.id) && (
          <MenuItem
            key={customerInformationSection.id}
            onClick={(e) => {
              addSection({
                applicationId,
                sectionId: customerInformationSection.id,
                partition: `default`,
              });
              props.onClick && props.onClick(e);
            }}
          >
            {customerInformationSection.title}
          </MenuItem>
        ),
      ]}
    />
  );
};
