/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  CLOSE_MERCHANT_FORM,
  CLOSE_UPDATE_MERCHANT_FORM,
  CREATE_MERCHANT,
  CREATE_MERCHANT_FAIL,
  CREATE_MERCHANT_SUCCESS,
  DELETE_MERCHANT_SUCCESS,
  LOAD_MERCHANTS,
  LOAD_MERCHANTS_FAIL,
  LOAD_MERCHANTS_SUCCESS,
  LOAD_SELECTED_MERCHANT,
  LOAD_SELECTED_MERCHANT_FAIL,
  LOAD_SELECTED_MERCHANT_SUCCESS,
  SHOW_MERCHANT_FORM,
  SHOW_UPDATE_MERCHANT_FORM,
  UPDATE_MERCHANT_SUCCESS,
  LOAD_MERCHANTS_WITHOUT_CATEGORIES,
  LOAD_MERCHANTS_WITHOUT_CATEGORIES_FAIL,
  LOAD_MERCHANTS_WITHOUT_CATEGORIES_SUCCESS,
} from '../containers/Merchants/constants';

export const initialState = {
  loading: false,
  error: false,
  merchants: [],
  selectedMerchant: null,
  showForm: false,
  showUpdateForm: false,
  paginationInfo: null,
  selectedRule: null,
  loadingSelected: false,
};

const merchantsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MERCHANTS:
        draft.selectedRule = null;
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANTS_SUCCESS:
        draft.merchants = action.merchants.payload;
        draft.paginationInfo = action.merchants.paginationInfo;
        draft.loading = false;
        break;
      case LOAD_MERCHANTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case LOAD_MERCHANTS_WITHOUT_CATEGORIES:
        draft.selectedRule = null;
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANTS_WITHOUT_CATEGORIES_SUCCESS:
        draft.merchants = action.merchants.payload;
        draft.paginationInfo = action.merchants.paginationInfo;
        draft.loading = false;
        break;
      case LOAD_MERCHANTS_WITHOUT_CATEGORIES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case SHOW_MERCHANT_FORM:
        draft.showForm = true;
        break;
      case CLOSE_MERCHANT_FORM:
        draft.showForm = false;
        break;

      case SHOW_UPDATE_MERCHANT_FORM:
        draft.selectedMerchant = action.item;
        draft.showUpdateForm = true;
        break;
      case CLOSE_UPDATE_MERCHANT_FORM:
        draft.selectedMerchant = null;
        draft.showUpdateForm = false;
        break;

      case LOAD_SELECTED_MERCHANT:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_SELECTED_MERCHANT_SUCCESS:
        draft.selectedMerchant = action.merchant;
        draft.loading = false;
        break;
      case LOAD_SELECTED_MERCHANT_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;

      case CREATE_MERCHANT:
        draft.isCreating = true;
        break;
      case CREATE_MERCHANT_SUCCESS:
        draft.isCreating = false;
        draft.showForm = false;
        break;
      case CREATE_MERCHANT_FAIL:
        draft.isCreating = false;
        draft.error = action.error;
        break;

      case UPDATE_MERCHANT_SUCCESS:
        draft.selectedMerchant = null;
        draft.showUpdateForm = false;
        break;
      case DELETE_MERCHANT_SUCCESS: {
        const idx = state.merchants.findIndex((val) => val.id === action.id);
        if (idx !== -1) draft.merchants.splice(idx, 1);
        break;
      }
    }
  });

export default merchantsReducer;
