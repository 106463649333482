/**
 * Rules Page selectors
 */

import { createSelector } from 'reselect';
import { initialState } from '../reducers/rules';

const ruleListSelector = (state) => state.rules.list || initialState.list;
const rulesLoadingSelector = (state) => state.rules.loading || initialState.loading;

const makeRulesData = () =>
  createSelector(ruleListSelector, (list) => {
    if (list instanceof Array) {
      return list;
    }
    return [];
  });

const makeSelectLoadingData = () =>
  createSelector(rulesLoadingSelector, (loading) => loading || false);

const ruleSetDeletingSelector = (state) => state.rules.rulesetDeleting;
const ruleSetDeletingSuccessSelector = (state) => state.rules.rulesetDeletingSuccess;
const ruleSetDeletingErrorSelector = (state) => state.rules.rulesetDeletingError;

const makeRuleSetDeletingData = () => createSelector(ruleSetDeletingSelector, (state) => state);

const makeRuleSetDeletingSuccessData = () =>
  createSelector(ruleSetDeletingSuccessSelector, (state) => state);

const makeRuleSetDeletingErrorData = () =>
  createSelector(ruleSetDeletingErrorSelector, (state) => state);

export {
  makeRulesData,
  makeSelectLoadingData,
  makeRuleSetDeletingData,
  makeRuleSetDeletingSuccessData,
  makeRuleSetDeletingErrorData,
};
