/** dialog for Create|Edit role (group of permissions) */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import * as _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { RULE_NAME_REGEX, USERNAMEFULL_REGEX } from 'constants/constants';
import { Button } from 'uikit/Button';
import {
  useCreateGroupMutation,
  useGetPermissionsQuery,
  useGetTenantsQuery,
  useUpdateGroupMutation,
  useAddGroupPermissionsMutation,
  useRemoveGroupPermissionsMutation,
  useCreateGroupMultiTenantMutation,
  useUpdateGroupMultiTenantMutation,
  useAddGroupPermissionsMultiTenantMutation,
  useRemoveGroupPermissionsMultiTenantMutation,
} from 'services/gatewayApi/usersApi';
import { toastCreateSuccess } from 'utils/toast';
import { Group, Permission, RolesDialogProps } from '../types';
import { RowLayout } from './RowLayout';
import { RoleDialogPermissions } from './RoleDialogPermissions';

interface UserData {
  selectedProductId: number;
  permissions: Permission[];
}

export const RoleDialog: React.FC<RolesDialogProps> = ({ role, open, onClose }) => {
  const { selectedProductId, permissions: currentUserPermissions } = useSelector(
    makeUserData(),
  ) as UserData;
  const isSuperAdmin = currentUserPermissions.some((el) => el.name === 'SUPER_ADMIN');

  const [updateRole] = useUpdateGroupMutation();
  const [updateRoleMultiTenant] = useUpdateGroupMultiTenantMutation();
  const [addPermissions] = useAddGroupPermissionsMutation();
  const [addPermissionsMultiTenant] = useAddGroupPermissionsMultiTenantMutation();
  const [removePermissions] = useRemoveGroupPermissionsMutation();
  const [removePermissionsMultiTenant] = useRemoveGroupPermissionsMultiTenantMutation();
  const [createGroup] = useCreateGroupMutation();
  const [createGroupMultiTenant] = useCreateGroupMultiTenantMutation();
  const { data: permissionsList, isLoading: isPermissionsLoading } =
    useGetPermissionsQuery(undefined);
  const { productsList = [], isTenantsLoading } = useGetTenantsQuery(
    { undefined },
    {
      selectFromResult: ({ data, isLoading }) => ({
        productsList: data?.map((el) => ({
          value: el.id,
          label: el.name,
        })),
        isTenantsLoading: isLoading,
      }),
    },
  );

  const isLoading = isPermissionsLoading || isTenantsLoading;

  const [selectedPermissions, setSelectedPermissions] = useState(
    role.permissions.filter((el) => el.resource) || [],
  );

  const validationSchema = yup.object({
    name: yup.string().required('Required').matches(RULE_NAME_REGEX, 'Only text, please'),
    description: yup.string().required('Required').matches(USERNAMEFULL_REGEX, 'Only text, please'),
  });

  const initialValues: Group = {
    id: role.id || 'new',
    name: role.name || '',
    description: role.description || '',
    permissions: role.permissions.filter((el) => el.resource) || [],
    tenantId: role.tenantId || selectedProductId,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (formik.values.id === 'new') {
        const body = {
          name: values.name,
          permissionIds: selectedPermissions.map((el) => el.id),
          description: values.description,
        };
        const mutationPromise =
          (await selectedProductId) === formik.values.tenantId
            ? createGroup(body)
            : createGroupMultiTenant({ tenantId: formik.values.tenantId, body });
        // @ts-ignore
        if (!mutationPromise.error) {
          toastCreateSuccess('Role');
        }
      } else {
        const modelGroup = { name: values.name, description: values.description };
        const modelAddPermissions = selectedPermissions
          .filter((item) => !role.permissions.some((el) => el.id === item.id))
          .map((element) => element.id);
        const modelRemovePermissions = role.permissions
          .filter((item) => !selectedPermissions.some((el) => el.id === item.id))
          .map((element) => element.id);

        !_.isEqual(modelGroup, {
          name: role.name,
          description: role.description,
        }) &&
          ((await (isSuperAdmin && selectedProductId !== role.tenantId))
            ? updateRoleMultiTenant({ tenantId: role.tenantId, id: role.id, body: modelGroup })
            : updateRole({ id: role.id, body: modelGroup }));

        modelAddPermissions.length > 0 &&
          ((await (isSuperAdmin && selectedProductId !== role.tenantId))
            ? addPermissionsMultiTenant({
                tenantId: role.tenantId,
                id: role.id,
                permissionsIds: modelAddPermissions,
              })
            : addPermissions({ groupId: role.id, permissionsIds: modelAddPermissions }));

        modelRemovePermissions.length > 0 &&
          ((await (isSuperAdmin && selectedProductId !== role.tenantId))
            ? removePermissionsMultiTenant({
                tenantId: role.tenantId,
                id: role.id,
                permissionsIds: modelRemovePermissions,
              })
            : removePermissions({ groupId: role.id, permissionsIds: modelRemovePermissions }));
      }
      resetForm();
      onClose();
    },
  });

  const handleReset = () => {
    formik.resetForm();
    setSelectedPermissions(role.permissions);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="Role-dialog" maxWidth="xs">
        <DialogTitle id="role-dialog" sx={{ pb: '8px' }}>
          <Box
            sx={{
              pb: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography component="span" variant="h1">
              {formik.values.id === 'new' ? 'Create Role' : 'Edit Role'}
            </Typography>
            <Typography component="span" sx={{ fontSize: '12px', color: 'grey.400' }}>
              {`   permissions: ${selectedPermissions.length}`}
            </Typography>
            <IconButton onClick={() => onClose()} size="large">
              <CloseIcon />
            </IconButton>
          </Box>

          <form id="RoleForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <TextField
              name="tenantId"
              fullWidth
              select
              label="Select"
              value={formik.values.tenantId}
              disabled={!(formik.values.id === 'new' && isSuperAdmin)}
              onChange={formik.handleChange}
              sx={{ pb: 4, borderWidth: '1px' }}
            >
              {productsList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              fullWidth
              label="Role`s name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
              sx={{ pb: 4, borderWidth: '1px', '&:hover': { borderWidth: '1px' } }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              disabled={formik.isSubmitting || isLoading}
            />
            <TextField
              type="text"
              fullWidth
              multiline
              rows={2}
              label="Role`s description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              variant="outlined"
              sx={{ pb: 4, borderWidth: '1px', '&:hover': { borderWidth: '1px' } }}
              error={formik.touched.description && Boolean(formik.errors.description)}
              disabled={formik.isSubmitting || isLoading}
            />
          </form>

          <Box sx={{ pr: '14px' }}>
            <RowLayout
              column1={<Typography variant="h3">Permissions</Typography>}
              column2={
                <Typography variant="body2" sx={{ fontSize: '11px', color: 'grey[600]' }}>
                  VIEW
                </Typography>
              }
              column3={
                <Typography variant="body2" sx={{ fontSize: '11px', color: 'grey[600]' }}>
                  EDIT
                </Typography>
              }
              column4={
                <Typography variant="body2" sx={{ fontSize: '11px', color: 'grey[600]' }}>
                  APPROVE
                </Typography>
              }
            />
          </Box>
        </DialogTitle>

        <DialogContent sx={{ pb: '0px' }}>
          {permissionsList && !isLoading ? (
            <RoleDialogPermissions
              // @ts-ignore
              permissionsList={permissionsList}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          ) : (
            <Box sx={{ height: '400px' }}>
              <LinearProgress />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ p: '16px' }}
          >
            <Grid item>
              <Button type="button" onClick={() => onClose()} theme="text">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="button" onClick={() => handleReset()} theme="text">
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" theme="primary" onClick={() => formik.handleSubmit()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
