import { connectRouter } from 'connected-react-router';

import languageProviderReducer from 'containers/LanguageProvider/reducer';
import environmentReducer from 'reducers/environment';
import rulesReducer from 'reducers/rules';
import transactionsReducer from 'reducers/transactions';
import customerProfileReducer from 'reducers/customerProfile';
import merchantProfileReducer from 'reducers/merchantProfile';
import cardWidgetReducer from 'reducers/dashboard/cardWidgets';
import merchantsReducer from 'reducers/merchants';
import merchantTransactionReducer from 'reducers/dashboard/merchantTransactions';
import merchantFraudNumbersReducer from 'reducers/dashboard/merchantFraudNumbers';
import merchantChargeBackNumbersReducer from 'reducers/dashboard/merchantChargeBackNumbers';
import transactionApproveRejectAmountsReducer from 'reducers/dashboard/transactionsApproveRejectAmounts';
import worldTransactionsReducer from 'reducers/dashboard/worldTransactions';
import transactionCurrenciesReducer from 'reducers/transactionCurrencies';
import transactionCountriesReducer from 'reducers/transactionCountries';
import ruleBuilderReducer from 'reducers/ruleBuilder';
import ruleStatsReducer from 'reducers/ruleStats';
import ruleSetStatsReducer from 'reducers/ruleSetStats';
import ruleCategoriesReducer from 'reducers/ruleCategories';
import permissionsReducer from 'reducers/permissions';
import usersReducer from 'reducers/users';
import casesReducer from 'reducers/cases';
import settingsReducer from 'reducers/settings';
import functionsReducer from 'reducers/functions';
import productsReducer from 'reducers/products';
import { formsApiReducers } from '@haystack-forms/api';
import { combineReducers } from 'redux';
import cardDataReducer from './reducers/ValidatorTransactions/CardData/cardData';
import validatorTransactionsReducer from './reducers/ValidatorTransactions/ValidatorTransactions/validatorTransactons';
import caseAssignmentsReducer from './reducers/caseAssignments';
import sanctionReducer from './reducers/sanction';
import sanctionDetailReducer from './reducers/sanctionDetail';
import caseCommentsReducer from './reducers/caseComments';
import { internalApi } from './services/internalApi';
import { authApi } from './services/authApi';
import { userSlice } from './services/gatewayApi/usersApi';
import { gatewayApi } from './services/gatewayApi';
import { ruleReducer } from './features/Rules/rulesSlice';
import { breadcrumbsReducer } from './uikit/BreadCrumbs/breadcrumbsSlice';
import { indicatorsReducer } from './services/indicatorsSlice';
import { featureFlagsReducer } from './services/featureFlagsSlice';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    language: languageProviderReducer,
    rules: rulesReducer,
    transactions: transactionsReducer,
    environment: environmentReducer,
    customerProfile: customerProfileReducer,
    merchantProfile: merchantProfileReducer,
    merchants: merchantsReducer,
    cardWidgets: cardWidgetReducer,
    merchantTransactions: merchantTransactionReducer,
    merchantFraudNumbers: merchantFraudNumbersReducer,
    merchantChargeBackNumbers: merchantChargeBackNumbersReducer,
    transactionApproveRejectAmounts: transactionApproveRejectAmountsReducer,
    worldTransactions: worldTransactionsReducer,
    transactionCurrencies: transactionCurrenciesReducer,
    transactionCountries: transactionCountriesReducer,
    validatorTransactions: validatorTransactionsReducer,
    ruleBuilder: ruleBuilderReducer,
    ruleStats: ruleStatsReducer,
    ruleSetStats: ruleSetStatsReducer,
    ruleCategories: ruleCategoriesReducer,
    permissions: permissionsReducer,
    users: usersReducer,
    cases: casesReducer,
    sanction: sanctionReducer,
    sanctionDetail: sanctionDetailReducer,
    caseAssignments: caseAssignmentsReducer,
    cardData: cardDataReducer,
    settings: settingsReducer,
    functions: functionsReducer,
    products: productsReducer,
    caseComments: caseCommentsReducer,
    ...formsApiReducers,
    [gatewayApi.reducerPath]: gatewayApi.reducer,
    user: userSlice.reducer,
    [internalApi.reducerPath]: internalApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    ruleSetState: ruleReducer,
    breadcrumbsState: breadcrumbsReducer,
    indicators: indicatorsReducer,
    featureFlags: featureFlagsReducer,
  });

export default createRootReducer;
