import React, { useState } from 'react';
import { Grid, Typography, Chip, Box } from '@mui/material';
import { ApplicationShareholder } from 'services/gatewayApi/shareholdersApiTyping';
import './Shareholder.scss';
import { ShareholderDialog } from '../ShareholderDialog';
import { countries } from 'constants/countries';
import { ConfirmDialog } from 'uikit';

interface IShareholderProps {
  shareholder: ApplicationShareholder;
  applicationId: string;
  remove?: (id: string) => void;
}

export const Shareholder: React.FC<IShareholderProps> = ({
  applicationId,
  shareholder,
  remove,
}) => {
  const [shareholderDialogOpen, setShareholderDialogOpen] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <div className="Shareholder">
      {shareholder && (
        <div className="SubItem" onClick={() => setShareholderDialogOpen(true)}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="body1">{shareholder.companyName}</Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="body1">{shareholder.tradeName}</Typography>
            </Grid>

            <Grid item xs={2} textAlign="right">
              <Typography variant="body1">{shareholder.percentage}%</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div className="statusWrapper">
                <Chip
                  size="small"
                  color={shareholder.status === 'ACTIVE' ? 'secondary' : 'base'}
                  label={shareholder.status}
                />
              </div>
            </Grid>

            <Grid item xs={5}>
              <Typography sx={{ paddingTop: '8px' }} color="text.secondary" variant="body2">
                {shareholder.address}
                {', '}
                {shareholder.postcode} {shareholder.city}
                {', '}
                {countries.find((item, i) => item.value === shareholder.country).label}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <div className="removeWrapper">
                <Box textAlign="right">
                  <Chip
                    size="small"
                    color="error"
                    label="DELETE"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteDialog(true);
                    }}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      {/* DELETE SHAREHOLDER */}
      <ConfirmDialog
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        onConfirm={() => remove(shareholder?.id)}
        title="Remove shareholder?"
        text={`Are you sure you want to remove ${shareholder?.companyName}?`}
        confirmButtonText="Remove"
        confirmButtonColor="defaultDanger"
      />

      {/* EDIT SHAREHOLDER */}
      {shareholderDialogOpen && (
        <ShareholderDialog
          open={shareholderDialogOpen}
          onClose={() => setShareholderDialogOpen(false)}
          shareholderData={shareholder}
          applicationId={applicationId}
        />
      )}
    </div>
  );
};
