import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { History as HistoryIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import Editor from '@monaco-editor/react';
import { EmptyPage, FormSelect, FormTextField, SwitchMacStyled, EditableTypography } from 'uikit';
import { grey } from 'theme/palette-blocks';
import { TOOLTIP_DELAY } from 'constants/constants';
import { isNewregex, ruleAnswer } from '../constatns';
import { RuleSortable } from '../types';
import { updateRule } from '../rulesSlice';
import { RenderIcon } from './RenderIcon';
import { RuleVetos } from '../RuleVetos';
import { setActiveDeep } from '../rulesUtils';

interface RuleContentProps {
  rule?: RuleSortable;
  ruleSetId: string;
  isLoading: boolean;
}

export const RuleContent: React.FC<RuleContentProps> = ({ rule, ruleSetId, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNoChanges, setIsNoChanges] = useState<boolean>(true);
  const [currentRule, setCurrentRule] = useState<RuleSortable>(rule);
  const isNew = isNewregex.test(rule?.id);

  useEffect(() => rule && setCurrentRule(rule), [rule]);
  useEffect(() => setIsNoChanges(_.isEqual(rule, currentRule)), [currentRule]);

  const ruleAnswerOptions = ruleAnswer.filter((el) => el.value !== 'ERROR');

  const getIconFunction = (value) => <RenderIcon iconName={value} fontSize={16} />;

  const getScore = (value: number): number => {
    if (value < 0) return 0;
    if (value > 100) return 100;
    return value;
  };

  const handleChangeActive = () => {
    if (currentRule.isActive) {
      const deactiveRuleDeep: RuleSortable = {
        ...currentRule,
        isActive: false,
        children: setActiveDeep(currentRule.children, false),
      };
      setCurrentRule(deactiveRuleDeep);
    } else {
      setCurrentRule((prev) => ({ ...prev, isActive: true }));
    }
  };

  const handleClose = () => {
    navigate(`/rulesNew/${ruleSetId}`, { state: { isLocked: false } });
  };

  const handleUpdate = () => {
    dispatch(updateRule({ ...currentRule, isEdited: true }));
    handleClose();
  };

  const handleEditField = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setCurrentRule((prev) => ({ ...prev, [fieldName]: e.target.value }));
  };

  const restoreField = (field: string) => {
    setCurrentRule((prev) => ({ ...prev, [field]: rule[field] }));
  };

  const isError = !currentRule?.script || !currentRule?.title || !currentRule?.description;

  const titleTextStyle = {
    fontSize: '24px',
    fontWeight: 500,
    '&:hover': { cursor: 'pointer', bgcolor: 'grey.50', py: '6px', borderRadius: '4px' },
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {currentRule ? (
            <>
              <Stack
                className="headerRuleDetails"
                direction="row"
                spacing={4}
                justifyContent="space-between"
                alignItems="center"
                position="relative"
              >
                <EditableTypography
                  value={currentRule.title}
                  handleChangeValue={(e) => handleEditField(e, 'title')}
                  textStyle={titleTextStyle}
                  onEsc={() => restoreField('title')}
                  placeholder="Rule name must not be empty"
                />

                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    color="base"
                    variant="contained"
                    startIcon={<HistoryIcon style={{ fontSize: '14px' }} />}
                    // onClick={() => setOpenHistoryDrawer((prev) => !prev)} TODO
                    disabled
                  >
                    History
                  </Button>
                  <Button
                    color="base"
                    variant="contained"
                    // onClick={() => setOpenHistoryDrawer((prev) => !prev)} TODO
                    disabled
                  >
                    Simulation
                  </Button>
                </Stack>
                <Tooltip title="Switch is rule active" enterDelay={TOOLTIP_DELAY}>
                  <div style={{ position: 'absolute', left: '-60px', top: '6px' }}>
                    <SwitchMacStyled
                      checked={currentRule.isActive}
                      onChange={handleChangeActive}
                      disabled={!currentRule.script}
                    />
                  </div>
                </Tooltip>
              </Stack>

              <Stack pt={2} color="grey.500" direction="row" spacing={3}>
                {!isNew && <span>{currentRule.id}</span>}
                <EditableTypography
                  value={currentRule.description}
                  handleChangeValue={(e) => handleEditField(e, 'description')}
                  onEsc={() => restoreField('description')}
                  placeholder="description must not be empty"
                />
              </Stack>

              <Stack
                direction="column"
                width={1}
                spacing={6}
                justifyContent="space-between"
                sx={(theme) => ({
                  borderBottom: `1px solid ${theme.palette.grey[200]}`,
                  py: 8,
                  mb: 4,
                })}
              >
                <Stack width={1} direction="column" spacing={2}>
                  <Typography color="grey.700">Rule function</Typography>
                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: '12px',
                      border: `1px solid ${!currentRule.script ? 'danger.main' : 'grey.200'}`,
                    }}
                  >
                    <Box sx={{ width: '100%', my: '12px' }}>
                      <Editor
                        height="15vh"
                        defaultLanguage="javascript"
                        value={currentRule.script}
                        onChange={(e) => setCurrentRule((prev) => ({ ...prev, script: e }))}
                        width="97%"
                      />
                    </Box>
                  </Box>
                </Stack>

                <Stack direction="row" spacing={6}>
                  <Stack width="40%" direction="column" spacing={2}>
                    <Typography color="grey.700">Result</Typography>
                    <FormSelect
                      value={currentRule.answer}
                      onChange={(e) =>
                        setCurrentRule((prev) => ({
                          ...prev,
                          answer: e.target.value,
                          score: e.target.value !== 'MODERATION' ? 0 : prev.score,
                        }))
                      }
                      options={ruleAnswerOptions}
                      getIconFunction={getIconFunction}
                    />
                  </Stack>
                  {currentRule.answer === 'MODERATION' && (
                    <Stack width="100px" direction="column" spacing={2}>
                      <Typography color="grey.700">Score</Typography>
                      <FormTextField
                        value={currentRule.score}
                        onChange={(e) =>
                          setCurrentRule((prev) => ({
                            ...prev,
                            score: getScore(parseInt(e.target.value, 10)),
                          }))
                        }
                        type="number"
                        error={currentRule.score > 100}
                      />
                    </Stack>
                  )}
                </Stack>

                {isNew ? (
                  <Typography color={grey[300]}>
                    Rule vetos can be edited after the rule is published
                  </Typography>
                ) : (
                  <RuleVetos ruleId={currentRule?.id} />
                )}
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Tooltip title="Close without changes" enterDelay={TOOLTIP_DELAY * 2}>
                  <Button color="base" variant="contained" onClick={() => handleClose()}>
                    Close
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    isNoChanges || !currentRule.script
                      ? 'Any changes are needed to update and the Rule function must not be empty'
                      : 'Close and update rules tree'
                  }
                  enterDelay={TOOLTIP_DELAY * 2}
                >
                  <div>
                    <Button
                      color={isError ? 'error' : 'primary'}
                      variant="contained"
                      onClick={handleUpdate}
                      disabled={isNoChanges || isError}
                    >
                      Update
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
            </>
          ) : (
            <EmptyPage
              isLoading={false}
              title="There is no such rule"
              subtitle="specify the correct rule ID"
            />
          )}
        </>
      )}
    </>
  );
};
