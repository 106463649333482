import { defineMessages } from 'react-intl';

export default defineMessages({
  selectPickerDateTitle: {
    id: 'app.containers.Transactions.SearchWidget.label.date',
    defaultMessage: 'From',
  },
  selectPickerToTitle: {
    id: 'app.containers.Transactions.SearchWidget.label.to',
    defaultMessage: 'To',
  },
});
