import { figmaVariables } from '../figmaVariables';
import { standard } from './textField-variants/standard';

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    xsmall: true;
  }
}

export const TextField = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        border: '1px solid',
        borderColor: figmaVariables.theme.line['line-base+'],
        '&.Mui-disabled': {
          backgroundColor: figmaVariables.theme.panel.default['panel-default-50'],
          opacity: 0.8,
        },
        '& .MuiFormLabel-root .MuiInputLabel-root.Mui-disabled': {
          opacity: 0.1,
          color: 'red',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          color: figmaVariables.theme.text['text-base'],
          '-webkit-text-fill-color': figmaVariables.theme.text['text-base'],
        },
      },
    },
  },

  variants: [standard],
};
