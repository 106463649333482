import { history } from '../store';
import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAIL,
  UPDATE_USER_PERMISSION,
  LOAD_USERINFO,
  LOAD_USERINFO_SUCCESS,
  LOAD_USERINFO_FAIL,
  SET_PRODUCT_USER,
  OPEN_PRODUCT_SELECTION_MODAL,
  CLOSE_PRODUCT_SELECTION_MODAL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_FAIL,
  ENABLE_USER,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_FAIL,
  DISABLE_USER,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_FAIL,
} from '../constants/constants';
import ApiService from '../services/apiService';
import {
  toastSuccess,
  toastError,
  toastLoadingListError,
  toastCreateSuccess,
  toastCreateError,
  toastUpdateSuccess,
  toastUpdateError,
  toastDeleteSuccess,
  toastDeleteError,
  toastEnableSuccess,
  toastEnableError,
  toastDisableSuccess,
  toastDisableError,
} from '../utils/toast';

const baseUrl = '/api/gateway';
const entityTitle = 'User';

export function enableUser(id) {
  return (dispatch) => {
    dispatch({ type: ENABLE_USER });
    ApiService.post(`${baseUrl}/users/${id}/enable`)
      .then(() => dispatch(enableUserSuccess()))
      .catch((error) => dispatch(enableUserFail(error)));
  };
}

export function disableUser(id) {
  return (dispatch) => {
    dispatch({ type: DISABLE_USER });
    ApiService.post(`${baseUrl}/users/${id}/disable`)
      .then(() => dispatch(disableUserSuccess()))
      .catch((error) => dispatch(disableUserFail(error)));
  };
}

export function sendActivationEmail(id) {
  return (dispatch) => {
    dispatch({ type: SEND_ACTIVATION_EMAIL });
    ApiService.post(`${baseUrl}/users/${id}/send-activation`)
      .then(() => dispatch(sendActivationEmailSuccess()))
      .catch((error) => dispatch(sendActivationEmailFail(error)));
  };
}

const disableUserSuccess = () => (dispatch) => {
  dispatch({ type: DISABLE_USER_SUCCESS });
  dispatch(fetchUsers());
  toastDisableSuccess(entityTitle);
};

const enableUserSuccess = () => (dispatch) => {
  dispatch({ type: ENABLE_USER_SUCCESS });
  dispatch(fetchUsers());
  toastEnableSuccess(entityTitle);
};

const sendActivationEmailSuccess = () => (dispatch) => {
  dispatch({ type: SEND_ACTIVATION_EMAIL_SUCCESS });
  toastSuccess(`Activation e-mail sent to ${entityTitle}.`);
};

export const openProductSelectionModal = () => (dispatch) => {
  dispatch({
    type: OPEN_PRODUCT_SELECTION_MODAL,
  });
};

export const closeProductSelectionModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_PRODUCT_SELECTION_MODAL,
  });
};

export const setSelectedProduct = (id) => (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'));
  user.selectedProductId = id;
  localStorage.setItem('user', JSON.stringify(user));
  dispatch({
    type: SET_PRODUCT_USER,
    productId: id,
  });
  dispatch(fetchUserInfo());
};

export const fetchUserInfo = () => (dispatch) => {
  const token = JSON.parse(localStorage.getItem('token_data'));
  const user = JSON.parse(localStorage.getItem('user'));
  if (!token.userId) {
    return;
  }
  const { userId } = token;
  dispatch({
    type: LOAD_USERINFO,
  });
  ApiService.get(`/api/gateway/users/${userId}`)
    .then((response) => {
      if (response) {
        const userinfo = response;
        localStorage.setItem('user', JSON.stringify({ ...user, ...userinfo }));
        if (user) userinfo.selectedProductId = user.selectedProductId;
        dispatch(fetchUserInfoSuccess(userinfo));
      }
    })
    .catch((error) => {
      dispatch(fetchUserInfoError(error));
    });
};

const fetchUserInfoSuccess = (user) => ({
  type: LOAD_USERINFO_SUCCESS,
  user,
});

const fetchUserInfoError = (error) => ({
  type: LOAD_USERINFO_FAIL,
  error,
});

export function fetchUsers() {
  return (dispatch) => {
    dispatch({ type: LOAD_USERS });
    ApiService.get('/api/gateway/users')
      .then((response) => dispatch(fetchUsersSuccess(response)))
      .catch((error) => dispatch(fetchUsersError(error)));
  };
}

export function fetchProductUsers(id) {
  return (dispatch) => {
    dispatch({ type: LOAD_USERS });
    ApiService.get(`/internal/users/product-users/${id}`)
      .then((response) => dispatch(fetchUsersSuccess(response.users)))
      .catch((error) => dispatch(fetchUsersError(error)));
  };
}

export function createUser(model) {
  return (dispatch) => {
    dispatch({ type: CREATE_USER });
    ApiService.post('/internal/users', model)
      .then(() => dispatch(createUserSuccess()))
      .catch((error) => dispatch(createUserFail(error)));
  };
}

const createUserSuccess = () => (dispatch) => {
  dispatch({ type: CREATE_USER_SUCCESS });
  history.push('/users');
  toastCreateSuccess(entityTitle);
};

export function updateUser(model) {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER });
    ApiService.update(`/api/gateway/users/${model.id}`, model)
      .then(() => dispatch(updateUserSuccess()))
      .catch((error) => dispatch(updateUserFail(error)));
  };
}

const updateUserSuccess = () => (dispatch) => {
  dispatch({ type: UPDATE_USER_SUCCESS });
  history.push('/users');
  toastUpdateSuccess(entityTitle);
};

export function deleteUser(id) {
  return (dispatch) => {
    dispatch({ type: DELETE_USER });
    ApiService.delete(`/internal/users/${id}`)
      .then(() => dispatch(deleteUserSuccess()))
      .catch((error) => dispatch(deleteUserFail(error)));
  };
}

const deleteUserSuccess = () => (dispatch) => {
  dispatch({ type: DELETE_USER_SUCCESS });
  dispatch(fetchUsers());
  toastDeleteSuccess(entityTitle);
};

export function updateUserPermission(userId, permission, gonnaAdd) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PERMISSION,
      userId,
      permission,
      gonnaAdd,
    });
  };
}

const fetchUsersSuccess = (users) => ({
  type: LOAD_USERS_SUCCESS,
  users,
});

const fetchUsersError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_USERS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

const createUserFail = (error) => (dispatch) => {
  dispatch({
    type: CREATE_USER_FAIL,
    error,
  });
  toastCreateError(entityTitle, error);
};

const updateUserFail = (error) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_FAIL,
    error,
  });
  toastUpdateError(entityTitle, error);
};

const deleteUserFail = (error) => (dispatch) => {
  dispatch({
    type: DELETE_USER_FAIL,
    error,
  });
  toastDeleteError(entityTitle, error);
};

const disableUserFail = (error) => (dispatch) => {
  dispatch({
    type: DISABLE_USER_FAIL,
    error,
  });
  toastDisableError(entityTitle);
};

const enableUserFail = (error) => (dispatch) => {
  dispatch({
    type: ENABLE_USER_FAIL,
    error,
  });
  toastEnableError(entityTitle);
};

const sendActivationEmailFail = (error) => (dispatch) => {
  dispatch({
    type: SEND_ACTIVATION_EMAIL_FAIL,
    error,
  });
  toastError(`Error sending activation e-mail to ${entityTitle}. ${error}`);
};
