import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody } from '@mui/material';
import { DetailsDrawer, EmptyPage } from 'uikit';
import { SearchSessionRow } from './SearchSessionRow';
import { SanctionsRecord } from '../types';
import { SanctionsRecordDetails } from './SanctionsRecordDetails';

interface SearchSessionListProps {
  searchResult: any[];
  disableAction?: boolean;
  isTransactions?: boolean;
  handleWhitelist?: Promise<void>;
  isLoading?: boolean;
}

export const SearchSessionList: React.FC<SearchSessionListProps> = ({
  searchResult,
  disableAction,
  isTransactions,
  handleWhitelist,
  isLoading,
}) => {
  const { applicationId, searchSessionId } = useParams<{
    applicationId: string;
    searchSessionId: string;
  }>();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [sanctionsRecordDetails, setSanctionsRecordDetails] = useState<SanctionsRecord>(undefined);

  return (
    <>
      {searchResult?.length > 0 ? (
        <Table>
          <TableBody>
            {searchResult.map((el) => (
              <SearchSessionRow
                applicationId={applicationId}
                searchId={searchSessionId}
                item={el}
                selected={el.uid === sanctionsRecordDetails?.uid && openDetailsDrawer}
                key={el.uid}
                setOpenDetailsDrawer={setOpenDetailsDrawer}
                setSanctionsRecordDetails={setSanctionsRecordDetails}
                disableAction={disableAction}
                isTransactions={isTransactions}
                handleWhitelist={handleWhitelist}
                isLoading={isLoading}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <div style={{ paddingLeft: '1rem' }}>
          <EmptyPage
            isLoading={isLoading}
            title="No results"
            subtitle="No matches for current search parameters"
          />
        </div>
      )}

      <DetailsDrawer open={openDetailsDrawer}>
        <SanctionsRecordDetails
          sanctionsRecordDetails={sanctionsRecordDetails}
          onClose={() => setOpenDetailsDrawer(false)}
        />
      </DetailsDrawer>
    </>
  );
};
