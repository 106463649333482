import React from 'react';
import {
  CheckCircle as CheckCircleIcon,
  PendingActions as PendingActionsIcon,
  ModeStandby as ModeStandbyIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { error, success, warning } from 'theme/palette-blocks';
import { RulesAnswer } from '../types';

interface RenderIconProps {
  iconName: RulesAnswer;
  fontSize?: number;
}

export const RenderIcon: React.FC<RenderIconProps> = ({ iconName, fontSize = 14 }) => {
  switch (iconName) {
    case 'PASS':
      return <CheckCircleIcon style={{ color: success.main, fontSize: `${fontSize}px` }} />;
    case 'LOWRISK':
      return <ModeStandbyIcon style={{ color: success.main, fontSize: `${fontSize}px` }} />;
    case 'MEDIUMRISK':
      return <ModeStandbyIcon style={{ color: warning.main, fontSize: `${fontSize}px` }} />;
    case 'MODERATION':
      return <PendingActionsIcon style={{ color: warning.main, fontSize: `${fontSize}px` }} />;
    case 'HIGHRISK':
      return <ModeStandbyIcon style={{ color: error.main, fontSize: `${fontSize}px` }} />;
    case 'REJECT':
      return <CancelIcon style={{ color: error.main, fontSize: `${fontSize}px` }} />;
    default:
      return null;
  }
};
