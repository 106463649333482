import { gatewayApi } from '../gatewayApi';

const clientsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getClientsCategories: build.query({
      query: () => `client-categories/all`,
      providesTags: () => [{ type: 'ClientsCategories', id: 'LIST' }],
    }),

    createClientCategory: build.mutation({
      query: (name) => ({
        url: 'client-categories',
        method: 'POST',
        body: name,
      }),
      invalidatesTags: [
        { type: 'ClientsCategories', id: 'LIST' },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    updateClientCategory: build.mutation({
      query: ({ categoryId, name }) => ({
        url: `client-categories/${categoryId}`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: [
        { type: 'ClientsCategories', id: 'LIST' },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    deleteClientCategory: build.mutation({
      query: ({ categoryId }) => ({
        url: `client-categories/${categoryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ClientsCategories', id: 'LIST' },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    getClients: build.query({
      query: (params) => ({
        url: `clients/all`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'Clients', id: 'LIST' }],
    }),
    getClient: build.query({
      query: ({ id }) => `clients/${id}`,
      providesTags: (result, error, { id }) => [{ type: 'Client', id }],
    }),
    createClient: build.mutation({
      query: ({ body }) => ({
        url: 'clients',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }],
    }),
    updateClient: build.mutation({
      query: ({ body, id }) => ({
        url: `clients/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Clients', id: 'LIST' },
        { type: 'Client', id },
      ],
    }),
    deleteClient: build.mutation({
      query: ({ id }) => ({
        url: `clients/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Clients', id: 'LIST' }],
    }),

    getClientAccountTypes: build.query({
      query: () => `client-accounts/types`,
      providesTags: () => [{ type: 'ClientAccountTypes', id: 'LIST' }],
    }),
    getClientAccount: build.query({
      query: ({ accountId }) => `client-accounts/${accountId}`,
      providesTags: () => [{ type: 'ClientAccounts', id: 'LIST' }],
    }),
    addClientAccount: build.mutation({
      query: ({ body }) => ({
        url: 'client-accounts/',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { body }) => [
        { type: 'Client', id: body.clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientAccount: build.mutation({
      query: ({ accountId, body }) => ({
        url: `client-accounts/${accountId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { body }) => [
        { type: 'Client', id: body.clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    deleteClientAccount: build.mutation({
      query: ({ accountId, clientId }) => ({
        url: `client-accounts/${accountId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    getClientIban: build.query({
      query: ({ ibanId }) => `client-ibans/${ibanId}`,
      providesTags: () => [{ type: 'ClientIbans', id: 'LIST' }],
    }),
    addClientIban: build.mutation({
      query: ({ body }) => ({
        url: 'client-ibans',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { body }) => [
        { type: 'Client', id: body.clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientIban: build.mutation({
      query: ({ body, ibanId }) => ({
        url: `client-ibans/${ibanId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { body }) => [
        { type: 'Client', id: body.clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    deleteClientIban: build.mutation({
      query: ({ ibanId, clientId }) => ({
        url: `client-ibans/${ibanId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientAccountFinActivity: build.mutation({
      query: ({ body, accountId }) => ({
        url: `client-accounts/${accountId}/financial-activity`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientAccountFinActivity: build.mutation({
      query: ({ body, accountId }) => ({
        url: `client-accounts/${accountId}/financial-activity`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientAccountFlowFund: build.mutation({
      query: ({ body, accountId }) => ({
        url: `client-accounts/${accountId}/flow-fund`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientAccountFlowFund: build.mutation({
      query: ({ body, accountId, type }) => ({
        url: `client-accounts/${accountId}/flow-fund/${type}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    deleteClientAccountFlowFund: build.mutation({
      query: ({ accountId }) => ({
        url: `client-accounts/${accountId}/flow-fund`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientIbanFinActivity: build.mutation({
      query: ({ body, ibanId }) => ({
        url: `client-ibans/${ibanId}/financial-activity`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientIbanFinActivity: build.mutation({
      query: ({ body, ibanId }) => ({
        url: `client-ibans/${ibanId}/financial-activity`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientIbanFlowFund: build.mutation({
      query: ({ body, ibanId }) => ({
        url: `client-ibans/${ibanId}/flow-fund`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientIbanFlowFund: build.mutation({
      query: ({ body, ibanId, type }) => ({
        url: `client-ibans/${ibanId}/flow-fund/${type}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    deleteClientIbanFlowFund: build.mutation({
      query: ({ ibanId }) => ({
        url: `client-ibans/${ibanId}/flow-fund`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientFinActivity: build.mutation({
      query: ({ id, body }) => ({
        url: `clients/${id}/financial-activity`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Client', id },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientFinActivity: build.mutation({
      query: ({ id, body }) => ({
        url: `clients/${id}/financial-activity`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Client', id },
        { type: 'Clients', id: 'LIST' },
      ],
    }),

    addClientFlowFund: build.mutation({
      query: ({ id, body }) => ({
        url: `clients/${id}/flow-fund`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Client', id },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    updateClientFlowFund: build.mutation({
      query: ({ id, type, body }) => ({
        url: `clients/${id}/flow-fund/${type}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Client', id },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
    deleteClientFlowFund: build.mutation({
      query: ({ id, clientId }) => ({
        url: `clients/${clientId}/flow-fund/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { clientId }) => [
        { type: 'Client', id: clientId },
        { type: 'Clients', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetClientsCategoriesQuery,
  useCreateClientCategoryMutation,
  useUpdateClientCategoryMutation,
  useDeleteClientCategoryMutation,

  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,

  useGetClientAccountTypesQuery,
  useGetClientAccountQuery,
  useAddClientAccountMutation,
  useUpdateClientAccountMutation,
  useDeleteClientAccountMutation,

  useGetClientIbanQuery,
  useAddClientIbanMutation,
  useUpdateClientIbanMutation,
  useDeleteClientIbanMutation,

  useAddClientAccountFinActivityMutation,
  useUpdateClientAccountFinActivityMutation,

  useAddClientAccountFlowFundMutation,
  useUpdateClientAccountFlowFundMutation,
  useDeleteClientAccountFlowFundMutation,

  useAddClientIbanFinActivityMutation,
  useUpdateClientIbanFinActivityMutation,

  useAddClientIbanFlowFundMutation,
  useUpdateClientIbanFlowFundMutation,
  useDeleteClientIbanFlowFundMutation,

  useAddClientFinActivityMutation,
  useUpdateClientFinActivityMutation,

  useAddClientFlowFundMutation,
  useUpdateClientFlowFundMutation,
  useDeleteClientFlowFundMutation,
} = clientsApi;
