import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import SweetAlert from 'sweetalert-react';
import { Line } from 'rc-progress';
import { Spinner, PermissionAware, DataTableRow } from '../../components';
import { history } from '../../store';
import { formatByCurrency } from '../../utils/formatter';
import { deleteRuleset, deleteRulesetReset } from '../../actions/rules';
import { fetchRuleStats } from '../../actions/ruleStats';
import { makeRuleStatsData } from '../../selectors/ruleStats';
import { makeRuleSetStatsDateRangeData } from '../../selectors/ruleSetStats';
import {
  makeRuleSetDeletingData,
  makeRuleSetDeletingSuccessData,
  makeRuleSetDeletingErrorData,
} from '../../selectors/rules';
import { fillColorMap } from '../../constants/constants';
import RuleItem from './RuleItem';

export class Ruleset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDetails: false,
      deleteBoxOpen: false,
      deleteBoxSuccess: props.ruleSetDeletingSuccess,
      deleteBoxError: !!props.ruleSetDeletingError,
    };
    this.handleEdit = this.handleEdit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ deleteBoxOpen: false });
    if (!!this.props.ruleSetDeletingError !== !!nextProps.ruleSetDeletingError) {
      this.setState({ deleteBoxError: !!nextProps.ruleSetDeletingError });
    }
    if (this.props.ruleSetDeletingSuccess !== nextProps.ruleSetDeletingSuccess) {
      this.setState({ deleteBoxSuccess: nextProps.ruleSetDeletingSuccess });
    }
  }

  handleEdit() {
    history.push(`/rules/${this.props.item.id}`);
  }

  handleEditNewBuilder() {
    history.push(`/new-rule-builder/${this.props.item.id}`);
  }

  handleDelete(e) {
    e.stopPropagation();
    this.setState({
      deleteBoxError: false,
      deleteBoxOpen: true,
    });
  }

  handleOpenDetailsClick() {
    const { item, ruleSetStatsDateRange } = this.props;
    let { startDate, endDate } = ruleSetStatsDateRange;
    startDate = moment(startDate).format('YYYY-MM-DD 00:00:00');
    endDate = moment(endDate).format('YYYY-MM-DD 23:59:59');

    this.setState(
      {
        openDetails: !this.state.openDetails,
      },
      () => {
        if (this.state.openDetails) {
          this.props.fetchRuleStats({ id: item.id, startDate, endDate });
        }
      },
    );
  }

  confirmDelete(id) {
    this.props.deleteRuleset(id);
  }

  resetError() {
    this.props.deleteRulesetReset();
  }

  renderCollapseItem() {
    const { openDetails } = this.state;
    const wrapperClass = classNames({
      fa: true,
      'fa-caret-right': openDetails === false,
      'fa-caret-down': openDetails,
    });

    return (
      <div>
        <i className={wrapperClass} />
      </div>
    );
  }

  renderRules(rules) {
    if (!rules.length) {
      return null;
    }
    return rules.map((item, index) => <RuleItem key={index} item={item} />);
  }

  renderRuleList(rules) {
    return (
      <DataTableRow>
        <td />
        <td colSpan="8">{this.renderRules(rules)}</td>
      </DataTableRow>
    );
  }

  renderStats() {
    const { stats, statsLoading } = this.props;

    const containerStyle = {
      minWidth: '200px',
      maxWidth: '300px',
      marginLeft: '100px',
      marginRight: '35px',
    };

    if (!stats.length) {
      return null;
    }

    if (statsLoading) {
      return <Spinner />;
    }

    return stats.map((item, idx) => (
      <div className={`rule-stats ${item.type.replace(' ', '')}`} key={idx}>
        <div className="rule-stats__label">{item.type}</div>
        <div className="rule-stats__count">{item.count}</div>
        <div
          data-tip={`${formatByCurrency({
            amount: item.amount,
          })} (%${parseFloat(item.ratio) * 100})`}
          style={containerStyle}
        >
          <Line
            percent={item.ratio * 100}
            strokeWidth="4"
            strokeColor={[fillColorMap[item.type]]}
          />
        </div>
        <ReactTooltip place="top" type="dark" effect="float" />
      </div>
    ));
  }

  renderAdditionalInfo(item) {
    return (
      <DataTableRow className="open additional-info">
        <td />
        <td colSpan="8">
          <div>
            <span className="title">CREATED ON:</span>
            <span>{item.createDate ? moment(item.createDate).format('DD-MM-YYYY') : '-'}</span>
            <span className="title">CREATED BY:</span>
            <span>{item.createUser ? item.createUser.name : '-'}</span>
            <span className="title">UPDATED ON:</span>
            <span>{item.updateDate ? moment(item.updateDate).format('DD-MM-YYYY') : '-'}</span>
            <span className="title">UPDATED BY:</span>
            <span>{item.updateUser ? item.updateUser.name : '-'}</span>
          </div>
        </td>
      </DataTableRow>
    );
  }

  render() {
    const { item, ruleSetDeletingError } = this.props;
    const { openDetails, deleteBoxOpen, deleteBoxError, deleteBoxSuccess } = this.state;
    const errorText = typeof ruleSetDeletingError === 'boolean' ? 'false' : ruleSetDeletingError;

    return (
      <tbody>
        <DataTableRow className={classNames({ open: openDetails })}>
          <td className="open-detail" onClick={() => this.handleOpenDetailsClick()}>
            {this.renderCollapseItem()}
          </td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>
            {item.active ? (
              <span className="label label-success">TRUE</span>
            ) : (
              <span className="label label-danger">FALSE</span>
            )}
          </td>
          <td>{item.status}</td>

          <td>{this.renderStats(item.id)}</td>
          <td>
            <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
              <Link to={`/rules/${this.props.item.id}`} className="text-success">
                <i className="fa fa-edit" />
              </Link>
            </PermissionAware>
          </td>
          <td>
            <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
              <a
                className="text-danger"
                onClick={(e) => {
                  this.handleDelete(e);
                }}
                title="Delete"
              >
                <i className="fa fa-trash-alt" />
              </a>
            </PermissionAware>
            <SweetAlert
              show={deleteBoxOpen}
              title="Are you sure?"
              text="You will not be able to recover this ruleset!"
              type="warning"
              showCancelButton
              confirmButtonColor="#DD6B55"
              confirmButtonText="Yes, delete it!"
              cancelButtonText="No, cancel!"
              showLoaderOnConfirm
              onConfirm={() => this.confirmDelete(item.id)}
              onCancel={() => this.setState({ deleteBoxOpen: false })}
            />
            <SweetAlert
              show={!!deleteBoxError}
              type="error"
              title="Error"
              text={errorText}
              onConfirm={() => this.resetError()}
            />
            <SweetAlert
              show={deleteBoxSuccess}
              type="success"
              title="Deleted"
              text="Ruleset Successfully Deleted"
              onConfirm={() => this.resetError()}
            />
          </td>
        </DataTableRow>
        {openDetails && this.renderAdditionalInfo(item)}
        {openDetails && this.renderRuleList(item.rules)}
      </tbody>
    );
  }
}

Ruleset.propTypes = {
  item: PropTypes.object,
  stats: PropTypes.array,
  statsLoading: PropTypes.bool,
  deleteRuleset: PropTypes.func,
  ruleSetDeletingError: PropTypes.any,
  ruleSetDeletingSuccess: PropTypes.bool,
  deleteRulesetReset: PropTypes.func,
  fetchRuleStats: PropTypes.func,
  ruleSetStatsDateRange: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  ruleSetDeleting: makeRuleSetDeletingData(),
  ruleSetDeletingSuccess: makeRuleSetDeletingSuccessData(),
  ruleSetDeletingError: makeRuleSetDeletingErrorData(),
  ruleStats: makeRuleStatsData(),
  ruleSetStatsDateRange: makeRuleSetStatsDateRangeData(),
});

const mapDispatchToProps = (dispatch) => ({
  deleteRuleset: (id) => dispatch(deleteRuleset(id)),
  deleteRulesetReset: () => dispatch(deleteRulesetReset()),
  fetchRuleStats: ({ id, startDate, endDate }) =>
    dispatch(fetchRuleStats({ id, startDate, endDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ruleset);
