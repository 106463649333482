import { base, grey, text } from 'theme/palette-blocks';

export const DefaultChip = {
  props: {
    color: 'default',
  },
  style: {
    color: grey[600],
    '.MuiChip-deleteIcon': {
      color: base.contrastText,
      '&:hover, &:active': {
        color: base.contrastText,
      },
    },
    '.MuiChip-avatar': {
      background: grey[300],
      color: base.contrastText,
    },
    '&.MuiChip-clickable': {
      color: grey[900],
      '&:hover': {
        background: grey[100],
        color: grey[900],
      },
      '&:active': {
        background: grey[200],
        color: grey[900],
      },
      '.MuiChip-icon': {
        color: base.contrastText,
      }
    },
    '.MuiChip-icon': {
      color: grey[600]
    }
  },
};

export const DefaultChipOutlined = {
  props: {
    color: 'default',
    variant: 'outlined',
  },
  style: {
    borderColor: grey[400],
    background: 'white',
    color: grey[600],
    '&.MuiChip-clickable': {
      '&:hover': {
        background: grey[100],
        borderColor: grey[400],
      },
      '&:active': {
        background: grey[200],
        borderColor: grey[400],
      },
    },
  },
};
