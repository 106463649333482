import React from 'react';
import { EmptyPage, EndListButton } from 'uikit';
import { SearchSessionsList } from '../SearchSessions/SearchSessionsList';
import { SearchSessionsListRow } from '../types';
import 'styles/tables.scss';

export interface PepSanctionsContentProps {
  isLoadingSearchSessions: boolean;
  numberOfElements: number;
  totalElements: number;
  searchSessionsList: SearchSessionsListRow[];
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  isFetchingSearchSessions: boolean;
}

export const PepSanctionsContent: React.FC<PepSanctionsContentProps> = ({
  isLoadingSearchSessions,
  numberOfElements,
  totalElements,
  searchSessionsList,
  setPageSize,
  isFetchingSearchSessions,
}) => (
  <div className="table-wrapper">
    {searchSessionsList?.length > 0 ? (
      <SearchSessionsList searchSessions={searchSessionsList} />
    ) : (
      <EmptyPage
        isLoading={isLoadingSearchSessions}
        title="You haven't done a search session yet."
        subtitle="The search data will appear when the search is applied."
      />
    )}
    {(!!searchSessionsList || searchSessionsList?.length < 1) && (
      <EndListButton
        isVisible={searchSessionsList?.length > 0}
        nextPage={() =>
          setPageSize((prev) => (prev + 100 > totalElements ? totalElements : prev + 100))
        }
        isFetching={isFetchingSearchSessions}
        isEndReached={totalElements === numberOfElements}
      />
    )}
  </div>
);
