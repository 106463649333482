import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  RiAlertLine,
  RiLockFill,
} from 'react-icons/ri';
import { TOOLTIP_DELAY } from 'constants/constants';

interface WarningAlertProps {
  message: string,
  setIsEditLocked: React.Dispatch<React.SetStateAction<boolean>>,
}

export const WarningAlert: React.FC<WarningAlertProps> = ({
  message,
  setIsEditLocked,
}) => {
  const theme = useTheme();

  return (
    <Box className="warning-container" sx={{ position: 'relative' }}>

      <Box className="warning-title">
        <RiAlertLine fontSize="18px" />
        <Typography>WARNING</Typography>

        <Tooltip
          title="Lock editing"
          enterDelay={TOOLTIP_DELAY}
          sx={{ position: 'absolute', right: 6, top: 6 }}
        >
          <IconButton onClick={() => setIsEditLocked((prev) => !prev)}>
            <RiLockFill color={theme.palette.text.placeholder} />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="body">{message}</Typography>
    </Box>
  )
};
