import React from 'react';
import { Box, Typography } from '@mui/material';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useGetDictionaryQuery } from 'services/gatewayApi/dictionaryApi';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { formatDateScreen } from 'utils';
import { Application, HaystackClient } from '../../../ApplicationManagement/types';
import { categories, overviewSections } from '../../constants';

interface OverviewTabProps {
  application: Application;
  haystackClient: HaystackClient;
}

export const OverviewTab: React.FC<OverviewTabProps> = ({ application, haystackClient }) => {
  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: (data as any[])?.map((c) => ({
        value: c.countryCode,
        label: c.name,
      })),
      isLoading: isLoadingCountries,
    }),
  });
  const { data: industryTypes, isLoading: isLoadingIndustryTypes } =
    useGetDictionaryQuery('industry_type');

  const RenderValue = ({ property }) => {
    let value = '';

    if (property.indexOf('haystackClient_') > -1) {
      property = property.replace('haystackClient_', '');
      value = haystackClient[property];
    } else {
      value = application[property];
    }

    if (!value) {
      return <Typography>-</Typography>;
    }

    switch (property) {
      case 'countryOfIncorporation':
        return (
          <Typography>{countries?.find((el) => el.value === value)?.label || value}</Typography>
        );

      case 'industryType':
        return (
          <Typography>{industryTypes?.find((el) => el.value === value)?.label || value}</Typography>
        );

      case 'categoryId':
        return (
          <Typography>{categories?.find((el) => el.value === value)?.label || value}</Typography>
        );

      case 'complianceCallScheduledDate':
        return (
          <Typography>{`Scheduled on ${formatDateScreen(value, DATE_FORMAT_MESSAGES)}`}</Typography>
        );

      case 'assigneeId':
        const assigneeId = value;
        // @ts-ignore
        const assigneeUser = users.find((u) => u.id === assigneeId);

        return (
          <Typography>{assigneeId && assigneeUser ? assigneeUser.name : 'no asignee'}</Typography>
        );

      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <>
      {application &&
        haystackClient &&
        overviewSections.map((section) => (
          <div key={section.id}>
            {section.label && (
              <Typography variant="subtitle1" component="span">
                {section.label}
              </Typography>
            )}

            {section.fields.map((field) => (
              <Box
                key={field.value}
                sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
              >
                <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
                  {field.label}
                </Typography>
                <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
                  <RenderValue property={field.value ?? '-'} />
                </Box>
              </Box>
            ))}
            <br />
          </div>
        ))}
    </>
  );
};
