import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import Badge from 'uikit/Badge/Badge';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  DoDisturb as DoDisturbIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { useDisableUserMutation, useEnableUserMutation } from 'services/gatewayApi/usersApi';
import { styled } from '@mui/material/styles';
import { CustomerUser, UserDialogProps } from './types';

interface UserRowProps {
  user: CustomerUser;
  setUserDialogProps: React.Dispatch<React.SetStateAction<UserDialogProps>>;
}

const StyledTableCell = styled(TableCell)(({ theme }) =>
  theme.unstable_sx({
    typography: 'body1',
    color: 'grey.900',
    py: 2,
    px: '0 !important',
  }),
);

export const UserRow: React.FC<UserRowProps> = ({ user, setUserDialogProps }) => {
  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getUserApplicationId = (customerUser?: CustomerUser) => {
    const applications = customerUser?.applications;
    return applications?.find((el) => el.kind === 'ONBOARDING')?.id;
  };

  const handleEditUser = () => {
    setAnchorEl(null);
    setUserDialogProps({
      open: true,
      customerId: user.customerId,
      applicationId: getUserApplicationId(user),
    });
  };
  const handleSwitchEnabledUser = () => {
    user.enabled ? disableUser(user.customerId) : enableUser(user.customerId);
    setAnchorEl(null);
  };

  const getBadge = () => {
    if (!user.enabled) {
      return <Badge theme="danger">Inactive</Badge>;
    }
    if (!user.emailVerified) {
      return <Badge theme="warning">Pending</Badge>;
    }
    if (user.enabled) {
      return <Badge theme="primary">Active</Badge>;
    }
  };

  return (
    <>
      <TableRow>
        <StyledTableCell>
          <Typography variant="subtitle1">{`${user.firstName} ${user.lastName}`}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography>{user.email}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography>{formatIsoDate(user.lastLoginDate, DATE_FORMAT_MESSAGES)}</Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography>{formatIsoDate(user.createdDate, DATE_FORMAT_MESSAGES)}</Typography>
        </StyledTableCell>
        <StyledTableCell>{getBadge()}</StyledTableCell>
        <StyledTableCell sx={{ textAlign: 'right' }}>
          <IconButton
            aria-label="actions"
            id={`${user.customerId}-button`}
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        </StyledTableCell>
      </TableRow>

      <Menu
        id={`${user.customerId}-menu`}
        MenuListProps={{ 'aria-labelledby': `${user.customerId}-button` }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleEditUser();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ ml: 2 }}>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSwitchEnabledUser}>
          <ListItemIcon>
            {user.enabled ? (
              <DoDisturbIcon fontSize="small" />
            ) : (
              <CheckCircleOutlineIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText sx={{ ml: 2 }}>{user.enabled ? 'Deactivate' : 'Activate'}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
