import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { ModalDialog } from 'uikit/ModalDialog';
import {
  useAddClientFinActivityMutation,
  useUpdateClientFinActivityMutation,
  useUpdateClientFlowFundMutation,
} from 'services/gatewayApi/clientsApi';
import { ValueLabel } from 'types/types';
import { initialFinActivity, textFieldProps } from '../../constants';

interface FinActivDialogProps {
  finActivity?: any;
  onClose: any;
  clientId: string;
  countries: ValueLabel[];
  setEdited: any;
}

export const FinActivDialog: React.FC<FinActivDialogProps> = ({
  finActivity,
  onClose,
  clientId,
  countries,
  setEdited,
}) => {
  const [addClientFinActivity] = useAddClientFinActivityMutation();
  const [updateClientFinActivity] = useUpdateClientFinActivityMutation();
  const [updateClientFlowFunds] = useUpdateClientFlowFundMutation();

  const isNew = finActivity === initialFinActivity;

  const validationSchema = yup.object({
    monthlyTurnover: yup.number().required('Required'),
    monthlyTransactions: yup.number().required('Required'),
    averageInbound: yup.number().required('Required'),
    averageOutbound: yup.number().required('Required'),
    maxInbound: yup.number().required('Required'),
    maxOutbound: yup.number().required('Required'),
  });

  const formik = useFormik({
    initialValues: finActivity,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const finActivityBody = {
        monthlyTurnover: values.monthlyTurnover,
        monthlyTransactions: values.monthlyTransactions,
        averageInbound: values.averageInbound,
        averageOutbound: values.averageOutbound,
        maxInbound: values.maxInbound,
        maxOutbound: values.maxOutbound,
      };

      const mutationPromise = isNew
        ? await addClientFinActivity({ id: clientId, body: finActivityBody })
        : await updateClientFinActivity({ id: clientId, body: finActivityBody });

      // @ts-ignore
      if (!mutationPromise.error) {
        isNew
          ? toastCreateSuccess('Client financial activity')
          : toastUpdateSuccess('Client financial activity');
      }

      if (values.flowFundsReceiptAllowed.length > 0) {
        await updateClientFlowFunds({
          id: clientId,
          type: 'RECEIPT',
          body: {
            countryCode: values.flowFundsReceiptAllowed,
            accessType: 'ALLOWED',
          },
        });
      }

      if (values.flowFundsPaymentAllowed.length > 0) {
        await updateClientFlowFunds({
          id: clientId,
          type: 'PAYMENT',
          body: {
            countryCode: values.flowFundsPaymentAllowed,
            accessType: 'ALLOWED',
          },
        });
      }

      if (values.flowFundsReceiptRestricted.length > 0) {
        await updateClientFlowFunds({
          id: clientId,
          type: 'RECEIPT',
          body: {
            countryCode: values.flowFundsReceiptRestricted,
            accessType: 'RESTRICTED',
          },
        });
      }

      if (values.flowFundsPaymentRestricted.length > 0) {
        await updateClientFlowFunds({
          id: clientId,
          type: 'PAYMENT',
          body: {
            countryCode: values.flowFundsPaymentRestricted,
            accessType: 'RESTRICTED',
          },
        });
      }

      setEdited(true);
      resetForm();
      onClose();
    },
  });

  return (
    <ModalDialog
      open={!!finActivity}
      onClose={onClose}
      title="Financial Activity"
      handleAction={formik.handleSubmit}
      actionTitle="Submit"
      isLoading={formik.isSubmitting}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            autoFocus
            label="Monthly turnover through OpenPayd (total value) (EUR)"
            name="monthlyTurnover"
            onChange={formik.handleChange}
            value={formik.values.monthlyTurnover}
            error={formik.touched.monthlyTurnover && Boolean(formik.errors.monthlyTurnover)}
            disabled={formik.isSubmitting}
          />
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            label="Transactions per month routed through OpenPayd (volume)"
            name="monthlyTransactions"
            onChange={formik.handleChange}
            value={formik.values.monthlyTransactions}
            error={formik.touched.monthlyTransactions && Boolean(formik.errors.monthlyTransactions)}
            disabled={formik.isSubmitting}
          />

          <Typography variant="subtitle1" sx={{ pt: 6 }}>
            Average Transaction Value Routed Through OpenPayd
          </Typography>
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            label="Inbound (EUR)"
            name="averageInbound"
            onChange={formik.handleChange}
            value={formik.values.averageInbound}
            error={formik.touched.averageInbound && Boolean(formik.errors.averageInbound)}
            disabled={formik.isSubmitting}
          />
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            label="Outbound (EUR)"
            name="averageOutbound"
            onChange={formik.handleChange}
            value={formik.values.averageOutbound}
            error={formik.touched.averageOutbound && Boolean(formik.errors.averageOutbound)}
            disabled={formik.isSubmitting}
          />

          <Typography variant="subtitle1" sx={{ pt: 6 }}>
            Maximum Transaction Value Routed Through OpenPayd
          </Typography>
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            label="Inbound (EUR)"
            name="maxInbound"
            onChange={formik.handleChange}
            value={formik.values.maxInbound}
            error={formik.touched.maxInbound && Boolean(formik.errors.maxInbound)}
            disabled={formik.isSubmitting}
          />
          {/* @ts-ignore */}
          <TextField
            {...textFieldProps}
            label="Outbound (EUR)"
            name="maxOutbound"
            onChange={formik.handleChange}
            value={formik.values.maxOutbound}
            error={formik.touched.maxOutbound && Boolean(formik.errors.maxOutbound)}
            disabled={formik.isSubmitting}
          />

          <Typography variant="subtitle1" sx={{ pt: 6 }}>
            Breakdown of Flow of Funds per Country to be routed through OpenPayd
          </Typography>
          <Autocomplete
            multiple
            id="flowFundsReceiptAllowed"
            options={countries}
            getOptionLabel={(option) => option.label}
            limitTags={2}
            disableCloseOnSelect
            fullWidth
            value={countries.filter((option) =>
              formik.values.flowFundsReceiptAllowed?.some((v) => option.value === v),
            )}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'flowFundsReceiptAllowed',
                newValue.map(
                  // @ts-ignore
                  (nextValue) => nextValue.value,
                ),
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Receipt countries"
                placeholder="Select receipt countries"
              />
            )}
          />
          <Autocomplete
            multiple
            id="flowFundsPaymentAllowed"
            options={countries}
            getOptionLabel={(option) => option.label}
            limitTags={2}
            disableCloseOnSelect
            fullWidth
            value={countries.filter((option) =>
              formik.values.flowFundsPaymentAllowed?.some((v) => option.value === v),
            )}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'flowFundsPaymentAllowed',
                // @ts-ignore
                newValue.map((nextValue) => nextValue.value),
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Payment countries"
                placeholder="Select payment countries"
              />
            )}
          />

          <Typography variant="subtitle1" sx={{ pt: 6 }}>
            Breakdown of Flow of Funds per Country
            <Typography variant="subtitle1" component="span" color="error" sx={{ fontWeight: 600 }}> NOT </Typography>
            to be routed through OpenPayd
          </Typography>
          <Autocomplete
            multiple
            id="flowFundsReceiptRestricted"
            options={countries}
            getOptionLabel={(option) => option.label}
            limitTags={2}
            disableCloseOnSelect
            fullWidth
            value={countries.filter((option) =>
              formik.values.flowFundsReceiptRestricted?.some((v) => option.value === v),
            )}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'flowFundsReceiptRestricted',
                newValue.map(
                  // @ts-ignore
                  (nextValue) => nextValue.value,
                ),
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Receipt countries"
                placeholder="Select receipt countries"
              />
            )}
          />
          <Autocomplete
            multiple
            id="flowFundsPaymentRestricted"
            options={countries}
            getOptionLabel={(option) => option.label}
            limitTags={2}
            disableCloseOnSelect
            fullWidth
            value={countries.filter((option) =>
              formik.values.flowFundsPaymentRestricted?.some((v) => option.value === v),
            )}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'flowFundsPaymentRestricted',
                // @ts-ignore
                newValue.map((nextValue) => nextValue.value),
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Payment countries"
                placeholder="Select payment countries"
              />
            )}
          />
        </Stack>
      </form>
    </ModalDialog>
  );
};
