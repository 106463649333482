import React, { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { ISelectOption, ModalDialog } from 'uikit';
import { useSendDocumentsToKycMutation } from 'services/gatewayApi/applicationDocumentsApi';
import {
  useGetApplicationQuery,
  useUpdateApplicationPartialMutation,
} from 'services/gatewayApi/applicationApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useAddKycApplicantWithProviderMutation } from 'services/gatewayApi/kycApplicantsApi';
import { toastSuccess } from 'utils';

interface KycDialogProps {
  open: boolean;
  onClose: () => any;
  applicationId: string;
  selectedDocuments: Array<{ id: string; title: string; documentOrder: number }>;
}

const KycDocumentsDialog = (props: KycDialogProps): JSX.Element => {
  const { open, onClose, applicationId, selectedDocuments } = props;

  const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery(undefined);
  const countriesValueLabel = countries
    ? countries.map((c) => ({ value: c.countryCode, label: c.name }))
    : [];

  const { data: application, isLoading: isLoadingApplication } =
    useGetApplicationQuery(applicationId);
  const [addKycApplicantWithProvider] = useAddKycApplicantWithProviderMutation();
  const [patchApplication] = useUpdateApplicationPartialMutation();
  const [sendDocsToKyc] = useSendDocumentsToKycMutation();
  const [isSavingKycDocs, setIsSavingKycDocs] = useState<boolean>(false);
  const documents = [
    ...selectedDocuments
      .sort((a, b) => (a.documentOrder > b.documentOrder ? 1 : -1))
      .map((doc) => ({
        id: doc.id,
        title: doc.title,
        countryCode: '',
      })),
  ];

  const onSendForVerification = (documents) => {
    setIsSavingKycDocs(true);

    // If application doesn't have an applicant, create applicant and update application's applicantId
    if (!application.applicantId) {
      // Create an applicant
      addKycApplicantWithProvider({
        provider: 'IDN',
        applicant: {
          title: application.contactTitle,
          firstName: application.contactFirstName,
          lastName: application.contactLastName,
          companyName: application.companyName,
          position: application.contactPosition,
          email: application.contactEmail,
          mobile: application.contactPhone,
          applicationId,
        },
      })
        .unwrap()
        .then((applicant) => {
          // Update applicantId
          patchApplication({
            id: application.id,
            applicantId: applicant.id,
          })
            .unwrap()
            .then(() => {
              sendSelectedDocsToKyc(documents);
            })
            .catch((rejected) => {
              setIsSavingKycDocs(false);
              console.error(rejected);
            });
        })
        .catch((rejected) => {
          setIsSavingKycDocs(false);
          console.error(rejected);
        });
    } else {
      sendSelectedDocsToKyc(documents);
    }
  };

  const sendSelectedDocsToKyc = (documents) => {
    sendDocsToKyc({
      applicationId,
      documents: documents.map((doc) => ({
        documentId: doc.id,
        countryCode: doc.countryCode,
      })),
    })
      .unwrap()
      .then(() => handleClose(null, 'onSubmit'))
      .catch((rejected) => {
        setIsSavingKycDocs(false);
        console.error(rejected);
      });
  };

  const validationSchema = yup.object().shape({
    documents: yup.array().of(
      yup.object().shape({
        countryCode: yup.string().required('Required field'),
      }),
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { documents },
    validationSchema,
    onSubmit: (values) => onSendForVerification(values.documents),
  });

  const isLoading = isLoadingApplication || isLoadingCountries || isSavingKycDocs;

  const handleClose = (e, reason) => {
    if (reason === 'onSubmit') {
      const count = selectedDocuments.length;
      toastSuccess(`${count} document${count > 1 ? 's' : ''} sent for verification.`);
    }
    !isSavingKycDocs && !(reason === 'backdropClick') && onClose();
  };

  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title="Send documents for verification"
      handleAction={formik.handleSubmit}
      actionTitle="Submit"
      isLoading={isLoading}
      disableEscapeKeyDown
    >
      <Box>
        <form>
          <Typography variant="body1" component="p" color="grey.600" mb={3}>
            Please specify issuing country for each document.
          </Typography>

          {documents.map((doc, idx) => (
            <Box key={doc.id} sx={{ bgcolor: 'grey.50', borderRadius: 1.5, p: 3, mb: 4 }}>
              <Typography variant="body1" component="p" mb={2}>
                {doc.title}
              </Typography>
              <Autocomplete
                fullWidth
                options={countriesValueLabel}
                autoHighlight
                disableClearable
                openOnFocus
                value={
                  formik.values.documents[idx].countryCode
                    ? countriesValueLabel.find(
                        (c) => c.value === formik.values.documents[idx].countryCode,
                      )
                    : null
                }
                onChange={(event, option: ISelectOption) =>
                  formik.setFieldValue(`documents.${idx}.countryCode`, option ? option.value : '')
                }
                getOptionLabel={(option: ISelectOption) => option?.label}
                disabled={isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a country"
                    variant="standard"
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    error={
                      formik.touched.documents &&
                      formik.touched.documents[idx]?.countryCode &&
                      // @ts-ignore
                      Boolean(formik.errors.documents && formik.errors.documents[idx]?.countryCode)
                    }
                    helperText={
                      formik.touched.documents &&
                      formik.touched.documents[idx]?.countryCode &&
                      formik.errors.documents &&
                      // @ts-ignore
                      formik.errors.documents[idx]?.countryCode
                    }
                  />
                )}
              />
            </Box>
          ))}
        </form>
      </Box>
    </ModalDialog>
  );
};

export default KycDocumentsDialog;
