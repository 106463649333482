import React, { forwardRef, useEffect, useRef, useImperativeHandle } from 'react';
import './TextArea.scss';
import CancelIcon from '@mui/icons-material/Cancel';

interface TextAreaProps {
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => any;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;

  disabled?: boolean;
  placeholder?: string;
  name?: string;
  readOnly?: boolean;
  hasError?: boolean;
  cols?: number;
  rows?: number;
  autoHeight?: boolean;
}

const InternalTextArea = (props, ref) => {
  const {
    readOnly,
    type,
    value,
    label,
    disabled,
    placeholder,
    name,
    cols,
    rows = 2,
    onChange,
    onFocus,
    onBlur,
    onClick,
    hasError,
    autoHeight = true,
    ...other
  } = props;

  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const handleKeyUp = (e: React.FocusEvent<HTMLInputElement>) => {
    if (autoHeight) {
      setAutoHeight();
    }
  };

  const setAutoHeight = () => {
    innerRef.current.style.height = 'auto';
    innerRef.current.style.height = `${innerRef.current.scrollHeight + 20}px`;
  };

  useEffect(() => {
    setAutoHeight();
  });

  return (
    <div
      className={`
      TextArea
      TextArea--${value ? 'touched' : 'untouched'}
      ${disabled ? 'TextArea--disabled' : ''}
      ${readOnly ? 'TextArea--readOnly' : ''}
      ${hasError ? 'TextArea--hasError' : ''}

    `}
    >
      <label className="TextAreaLabelEl">
        <span className="TextAreaLabel">{label}</span>
        <textarea
          ref={innerRef}
          readOnly={readOnly}
          cols={cols}
          rows={rows}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          className="TextAreaEl"
          value={value}
          onChange={changeHandler}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          onKeyUp={handleKeyUp}
          {...other}
        />
      </label>
    </div>
  );
};

const TextArea = forwardRef<any, any>(InternalTextArea);
TextArea.displayName = 'TextArea';
export default TextArea;
