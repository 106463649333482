import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { Ubo } from '../../types';
import { detailsSections, detailsFields, documentTypes, genders } from '../../constants';

interface DetailsTabProps {
  ubo: Ubo;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ ubo }) => {
  const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery(undefined);

  const RenderValue = ({ property }) => {
    const value = ubo[property];
    if (!value) {
      return <Typography>-</Typography>;
    }

    switch (property) {
      case 'dateOfBirth':
      case 'documentExpiry':
        return <Typography>{formatIsoDate(value, DATE_FORMAT_MESSAGES)}</Typography>;

      case 'nationality':
      case 'documentCountryIssue':
      case 'country':
        return (
          <Typography>{countries?.find((c) => c.countryCode === value)?.name ?? '-'}</Typography>
        );

      case 'gender':
        return <Typography>{genders?.find((c) => c.value === value)?.label ?? '-'}</Typography>;

      case 'documentType':
        return (
          <Typography>{documentTypes?.find((c) => c.value === value)?.label ?? '-'}</Typography>
        );

      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <>
      {!isLoadingCountries &&
        detailsSections.map((section) => (
          <div key={section.id}>
            {section.label && (
              <Typography variant="subtitle1" component="span">
                {section.label}
              </Typography>
            )}

            {detailsFields
              .filter((field) => field.sectionId === section.id)
              .map((field) => (
                <Box
                  key={field.value}
                  sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
                >
                  <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
                    {field.label}
                  </Typography>
                  <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
                    <RenderValue property={field.value} />
                  </Box>
                </Box>
              ))}

            <br />
          </div>
        ))}
    </>
  );
};
