import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisableUserMutation, useEnableUserMutation } from 'services/gatewayApi/usersApi';
import { Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  DoDisturb as DoDisturbIcon,
} from '@mui/icons-material';
import Badge from 'uikit/Badge/Badge';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { formatIsoDate } from 'utils/formatter';
import { Button as Btn } from 'uikit';

const UsersListRow = ({ user }) => {
  const { name, groups, enabled, id, createdDate, lastLoginDate } = user;
  const navigate = useNavigate();

  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditUser = () => {
    setAnchorEl(null);
    navigate(`/user-management/${id}`);
  };
  const handleSwitchEnabledUser = () => {
    enabled ? disableUser(id) : enableUser(id);
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{ width: '100%', height: '100%' }}
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Grid item md={4}>
          <Typography
            variant="body1"
            align="left"
            component="div"
            sx={{ fontSize: '14px', fontWeight: '400', mt: '6px' }}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item md={8} container alignItems="flex-start">
          <Grid item md={6}>
            {groups.length > 0 ? (
              groups.map((el) => (
                <Typography
                  variant="body1"
                  align="left"
                  component="div"
                  key={el.id}
                  sx={{ pl: '6px', my: '6px' }}
                >
                  {el.name}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body1"
                align="left"
                component="div"
                sx={{ pl: '6px', mt: '6px' }}
              >
                no role
              </Typography>
            )}
          </Grid>
          <Grid item md={2} sx={{ pl: '6px', mt: '6px' }}>
            <Typography variant="body1">
              {formatIsoDate(lastLoginDate, DATE_FORMAT_MESSAGES)}
            </Typography>
          </Grid>
          <Grid item md={2} sx={{ pl: '6px', mt: '6px' }}>
            <Typography variant="body1">
              {formatIsoDate(createdDate, DATE_FORMAT_MESSAGES)}
            </Typography>
          </Grid>
          <Grid item md={1} sx={{ pl: '6px', mt: '6px' }}>
            {enabled ? (
              <Badge theme="primary">Active</Badge>
            ) : (
              <Badge theme="danger">Inactive</Badge>
            )}
          </Grid>
          <Grid
            item
            md={1}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', mt: '6px' }}
          >
            <Btn
              onClick={handleClick}
              iconButton
              shape="rounded"
              prefix={<MoreVertIcon sx={{ fontSize: '16px' }} />}
              variant="text"
              theme="base"
              size="xs"
            />
          </Grid>
        </Grid>

        <Menu
          id={`${id}-menu`}
          MenuListProps={{ 'aria-labelledby': `${id}-button` }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditUser}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>Edit</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleSwitchEnabledUser}>
            <ListItemIcon>
              {enabled ? (
                <DoDisturbIcon fontSize="small" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>{enabled ? 'Deactivate' : 'Activate'}</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
};

export default UsersListRow;
