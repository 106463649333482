/*
// Single application in row appearance
*/

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  Circle as CircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { ApplicationStatus, RiskStatus } from '../types';
import { getApplicationStatusOptions, riskStatusOptions } from '../constants';
import { danger, error, grey, success } from '../../../theme/palette-blocks';
import './ApplicationRow.scss';
import 'styles/tables.scss';

interface ApplicationRowProps {
  title: string;
  id: string;
  status: ApplicationStatus;
  assignee: string;
  riskStatus: RiskStatus;
  salesManager: string;
  createdDate: string;
  clientId: string;
}

export const ApplicationRow: React.FC<ApplicationRowProps> = ({
  title,
  status,
  assignee,
  riskStatus,
  id,
  clientId,
  salesManager,
  createdDate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const applicationStatusOptions = getApplicationStatusOptions();

  const ellipsisStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
  };
  const lampWrapperStyle = {
    width: '12px',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
  };
  let color: string;
  let filled: boolean;
  switch (riskStatus) {
    case 'LOW':
      color = success.main;
      filled = true;
      break;
    case 'MEDIUM':
      color = success.main;
      filled = false;
      break;
    case 'HIGH':
      color = danger.main;
      filled = false;
      break;
    case 'VERY_HIGH':
      color = error.main;
      filled = false;
      break;
    case 'PROHIBITED':
      color = error.main;
      filled = true;
      break;
    case 'NOT_PERFORMED':
      // eslint-disable-next-line prefer-destructuring
      color = grey[600];
      filled = true;
      break;
    default:
      color = success.main;
      filled = true;
  }

  const handleOpenDetails = () => {
    navigate(`/application-management/${clientId}/applications/${id}/app`, {
      state: { backpath: location.search, applicationName: title, applicationId: id },
    });
  };

  return (
    <>
      <Box className="ApplicationRow table-row table-hover" onClick={handleOpenDetails}>
        <Box className="ApplicationRowCell column-name">
          <Typography sx={ellipsisStyle}>{title}</Typography>
        </Box>
        <Box className="ApplicationRowCell column-date">
          <Typography>{createdDate}</Typography>
        </Box>
        <Box
          className="ApplicationRowCell"
          sx={{
            ...ellipsisStyle,
            color: status === 'OPEN' ? 'primary.main' : 'grey.900',
          }}
        >
          {applicationStatusOptions.find((el) => el.value === status)?.label || status}
        </Box>
        <Box className="ApplicationRowCell">
          <Typography sx={ellipsisStyle}>{salesManager}</Typography>
        </Box>
        <Box
          className="ApplicationRowCell"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={ellipsisStyle}>{assignee || '-'}</Typography>
        </Box>
        <Box className="ApplicationRowCell colunm-risk-scoring">
          <div style={lampWrapperStyle}>
            {filled ? (
              <CircleIcon style={{ fontSize: '10px', color }} />
            ) : (
              <RadioButtonUncheckedIcon style={{ fontSize: '10px', color }} />
            )}
          </div>
          <Typography sx={{ color }}>
            {riskStatusOptions.find((el) => el.value === riskStatus)?.label || riskStatus}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
