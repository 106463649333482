import { alpha } from '@mui/material/styles';
import { primaryLight, text } from '../../palette-blocks';
import { buttonFocus } from '../mixins';

export const PrimaryLight = {
  props: {
    color: 'primaryLight',
  },
  style: {
    color: primaryLight.contrastText,
    background: primaryLight.main,
    '&:hover': {
      background: primaryLight.hover,
    },
    '&:active': {
      background: primaryLight.active,
    },
    '&:disabled': {
      background: alpha(primaryLight.main, 0.5),
      color: alpha(primaryLight.contrastText, 0.5),
      // opacity: palette.action?.disabledOpacity,
    },
    '&:focus&:not(:active)': buttonFocus(primaryLight.main),
  },
};

export const PrimaryLightText = {
  props: {
    color: 'primaryLight',
    variant: 'text',
  },
  style: {
    borderColor: 'transparent',
    background: 'transparent',
    '&:hover': {
      background: primaryLight.hover,
    },
    '&:active': {
      background: primaryLight.active,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};

export const PrimaryLightOutlined = {
  props: {
    color: 'primaryLight',
    variant: 'outlined',
  },
  style: {
    borderColor: primaryLight['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: primaryLight.hover,
      borderColor: primaryLight['outline-100'],
    },
    '&:active': {
      background: primaryLight.active,
      borderColor: primaryLight['outline-100'],
    },
    '&:disabled': {
      borderColor: alpha(primaryLight['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};
