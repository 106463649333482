import { createSelector } from 'reselect';
import { initialState } from '../reducers/caseAssignments';

const caseAssignmentsState = (state) => state.caseAssignments || initialState;
const makeCaseAssignmentsData = () =>
  createSelector(caseAssignmentsState, (state) => state.caseAssignments);

const makeCaseAssignmentsLoadingData = () =>
  createSelector(caseAssignmentsState, (state) => state.loading || false);
const makeCaseAssignmentsShowForm = () =>
  createSelector(caseAssignmentsState, (state) => state.showForm || false);

export { makeCaseAssignmentsData, makeCaseAssignmentsLoadingData, makeCaseAssignmentsShowForm };
