import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { RiArrowGoBackFill, RiArrowRightSLine } from 'react-icons/ri';
import { CopyAll as CopyAllIcon } from '@mui/icons-material';
import { HeadLinkProps } from './HeadLinkProps';
import gbFlag from '../../img/gb.svg';
import mtFlag from '../../img/mt.svg';
import { toastCopyError, toastCopySuccess } from '../../utils/toast';

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const HeadLink: React.FC<HeadLinkProps> = ({
  categoryId,
  applicationId,
  title,
  description,
  actions = [],
  link = '',
  linkIconBack = false,
  hasBorder = true,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      className="headLink"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={(theme) => ({
        py: 4,
        borderBottom: hasBorder ? `1px solid ${theme.palette.grey[300]}` : 'none',
      })}
    >
      <Typography
        className="headLinkTitle"
        variant="h2"
        sx={{ fontWeight: '700', display: 'flex', alignItems: 'baseline', gap: '0.5rem', lineHeight: '32px' }}
      >
        {categoryId && (
          <img src={categoryId === 5 ? mtFlag : gbFlag} alt="gp-flag" style={{ height: '16px' }} />
        )}
        {title}
        {applicationId && (
          <NoMaxWidthTooltip
            title={
              <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center', padding: '0.5rem' }}>
                <Typography color="white" sx={{ whiteSpace: 'nowrap' }}>
                  {applicationId}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(applicationId).then(
                      () => toastCopySuccess('Application ID'),
                      () => toastCopyError('Application ID'),
                    );
                  }}
                  sx={{ '&:hover': { backgroundColor: 'grey.500' } }}
                >
                  <CopyAllIcon sx={{ transform: 'scale(.7)', color: 'white' }} />
                </IconButton>
              </Box>
            }
          >
            <Typography sx={{ color: 'grey.600' }}>{`...${applicationId.slice(-4)}`}</Typography>
          </NoMaxWidthTooltip>
        )}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '50%' }}
        className="headLinkContent"
      >
        {description && <Box className="headLinkContentStatus">{description}</Box>}
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={4}
          className="headLinkContentActions"
          sx={{ marginLeft: 'auto' }}
        >
          {actions}
          {link && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(link);
              }}
            >
              {linkIconBack ? <RiArrowGoBackFill size="16px" /> : <RiArrowRightSLine size="16px" />}
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
