import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreVert as MoreVertIcon,
  SmsOutlined as SmsOutlinedIcon,
  Sms as SmsIcon,
} from '@mui/icons-material';
import { EditableTypography, ModalDialog } from 'uikit';
import { formatDateScreen } from 'utils';
import {
  useAddRuleVetoCommentMutation,
  useDeleteRuleVetoMutation,
} from 'services/gatewayApi/ruleVetosApi';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { grey } from 'theme/palette-blocks';
import { RuleVeto } from '../types';
import { ruleTypes } from '../../exceptionRules/constants';
import { chipColorTheme } from '../constatns';

interface RuleVetoRowProps {
  veto: RuleVeto;
}

export const RuleVetoRow: React.FC<RuleVetoRowProps> = ({ veto }) => {
  const {
    id: vetoId,
    referenceIdentifier,
    ruleId,
    ruleVetoType,
    expiryDate,
    status,
    comments,
    createdBy,
    createdDate,
    updatedDate,
    startDate,
    updatedBy,
  } = veto;

  const { label: ruleTypeLabel } = ruleTypes.find((el) => el?.value === ruleVetoType) || {};

  const [isRowOpened, setIsRowOpened] = useState<boolean>(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [comment, setComment] = useState<string>(comments);

  const [addComment, { isLoading }] = useAddRuleVetoCommentMutation();
  const [deleteRuleVeto] = useDeleteRuleVetoMutation();

  const infoFields = {
    createdDate: 'Created',
    updatedDate: 'Updated',
    startDate: 'Start date',
    expiryDate: 'Expiry date',
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteVeto = () => {
    setAnchorEl(null);
    setOpenConfirmDelete(true);
  };

  const handleSaveComment = () => {
    const isChanged = comment !== comments;
    isChanged && addComment({ ruleId, id: vetoId, body: comment });
  };

  const handleRestoreComment = () => {
    setComment(comments);
  };

  const getVetoDetails = (field): string => {
    const fieldMap = {
      createdDate: `${formatDateScreen(createdDate, DATE_FORMAT_MESSAGES)} by ${createdBy}`,
      updatedDate: `${formatDateScreen(updatedDate, DATE_FORMAT_MESSAGES)} by ${updatedBy}`,
      startDate: formatDateScreen(startDate, DATE_FORMAT_MESSAGES),
      expiryDate: formatDateScreen(expiryDate, DATE_FORMAT_MESSAGES),
    };
    return veto[field] ? (fieldMap[field] as string) || '-' : '-';
  };

  return (
    <>
      <Box className="RowVetoWrapper" sx={{ bgcolor: 'grey.50', borderRadius: '12px' }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          py="10px"
          px="16px"
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsRowOpened((prev) => !prev)}
            >
              {isRowOpened ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </IconButton>
            <Typography>{`${ruleTypeLabel}: ${referenceIdentifier}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center">
            {expiryDate && (
              <Typography color="grey.700">
                {`Expiry date: ${formatDateScreen(expiryDate, DATE_FORMAT_MESSAGES)}`}
              </Typography>
            )}
            <div style={{ width: '65px' }}>
              <Chip color={chipColorTheme[status]} variant="outlined" label={status} size="small" />
            </div>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
        <Collapse in={isRowOpened} timeout="auto" unmountOnExit>
          <Stack direction="row" spacing={1} p={1} height="100%">
            <Box
              className="fields-section"
              sx={{ bgcolor: 'white', borderRadius: '8px 0 0 8px', width: '40%', flexGrow: 1 }}
            >
              <Stack direction="column" spacing={2} p={4}>
                {Object.keys(infoFields).map((field, index, array) => (
                  <Stack
                    key={field}
                    direction="row"
                    spacing={2}
                    sx={{
                      borderBottom: index !== array.length - 1 ? `1px solid ${grey[200]}` : 'none',
                    }}
                    alignItems="center"
                  >
                    <Typography color="grey.500" width="140px" py={1}>
                      {infoFields[field]}
                    </Typography>
                    <Typography>{getVetoDetails(field)}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Box
              className="comment-section"
              sx={{ bgcolor: 'white', borderRadius: '0 8px 8px 0', width: '60%', flexGrow: 1 }}
            >
              <Stack direction="row" spacing={2} p={4} sx={{ height: '100%' }}>
                {isLoading ? (
                  <SmsOutlinedIcon fontSize="small" style={{ color: grey[300] }} />
                ) : (
                  <SmsIcon fontSize="small" style={{ color: grey[300] }} />
                )}
                <EditableTypography
                  value={comment}
                  handleChangeValue={(e) => setComment(e.target.value)}
                  onEsc={handleRestoreComment}
                  onSave={handleSaveComment}
                  placeholder="no comment yet"
                  isLocked={isLoading}
                />
              </Stack>
            </Box>
          </Stack>
        </Collapse>
      </Box>
      {!!open && (
        <Menu
          id={`${ruleId}-menu`}
          MenuListProps={{ 'aria-labelledby': `${ruleId}-button` }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDeleteVeto}>
            <ListItemText>
              <Typography color="error.main" fontWeight={600}>
                Delete veto
              </Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete rule veto"
          actionTitle="Delete"
          handleAction={() => deleteRuleVeto(vetoId)}
          variant="error"
        >
          <Typography pb={2}>Confirm the removal rule veto?</Typography>
          <Typography color="grey.600">{`${ruleTypeLabel}: ${referenceIdentifier}`}</Typography>
        </ModalDialog>
      )}
    </>
  );
};
