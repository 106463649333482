import { success, text } from '../../palette-blocks';
import { palette } from '../../palette';
import { buttonFocus } from '../mixins';

export const Success = {
  props: {
    color: 'success',
  },
  style: {
    color: success.contrastText,
    background: 'transparent',
    '&:hover': {
      background: success.hover,
      color: success.contrastText,
    },
    '&:active': {
      background: success.active,
    },
    '&:disabled': {
      background: success.light,
      color: success.contrastText,
      opacity: palette.action?.disabledOpacity,
    },
    '&:focus&:not(:active)': buttonFocus(success.main),
    '.MuiLoadingButton-loadingIndicator': {
      color: success.main,
    },
  },
};

export const SuccessText = {
  props: {
    color: 'success',
    variant: 'text',
  },
  style: {
    color: text.success,
    background: 'transparent',
    '&:hover': {
      background: success.hover,
    },
    '&:active': {
      background: success.active,
    },
  },
};

export const SuccessContained = {
  props: {
    color: 'success',
    variant: 'contained',
  },
  style: {
    background: success.light,
    '&:hover': {
      background: success.hover,
    },
    '&:active': {
      background: success.active,
    },
  },
};

export const SuccessOutlined = {
  props: {
    color: 'success',
    variant: 'outlined',
  },
  style: {
    color: text.success,
    '&:disabled': {
      borderColor: success['outline-100'],
      background: 'transparent',
      color: success.main,
    },
  },
};
