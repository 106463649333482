// npm
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import * as _ from 'lodash';
import { useTheme } from '@mui/material/styles';

// global modules
import { format } from 'date-fns';
import { DATE_FORMAT } from 'constants/constants';
import { cleanEmptyQueryParameters } from 'utils/cleanEmptyQueryParameters';
import { queryHasValue } from 'utils/queryHasValue';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import {
  useGetCasesQuery,
  useGetCaseAssignmentsQuery,
  useGetCaseCategoriesQuery,
} from 'services/gatewayApi/casesApi';
import { useGetRuleCategoriesQuery, useGetRulesListQuery } from 'services/gatewayApi/rulesApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { Box, Link, Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import {
  DetailsDrawer,
  EndListButton,
  HeadTableCell,
  PageContainer,
  PageControl,
  Skeleton,
  SortBlock,
  StickyHeader,
} from 'uikit';
import { NoResultsBox, NoDataBox } from 'muikit';

import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { SortParam } from 'types';
import { parseDate } from '../../utils';
import { downloadFile } from '../../utils/downloadFile';

// this module
import { BulkActionsBox } from './BulkActionsBox';
import { CaseRow } from './CaseRow';
import { CaseDetailsSidebar } from './CaseDetailsSidebar';
import {
  getCaseStatuses,
  caseTypes,
  getFilterScheme,
  riskStatuses,
  getSortOptions,
  getTableSortOptions,
  tableRightAligned,
  transactionTypes,
} from './constants';
import { ControlsPanel } from './ControlsPanel';
import { riskLevelsCases } from './constants/riskLevels';

import './Cases.scss';

const defaultPageSize = 20;

const Cases = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { selectedProductId: productId } = useSelector(makeUserData());

  // #region Load required data
  const { data: users = [], isLoading: isLoadingUsers } = useGetListUsersQuery(undefined);
  const { data: caseAssignmentsList = [], isLoading: isLoadingCaseAssignments } =
    useGetCaseAssignmentsQuery(undefined);
  const { data: caseCategories, isLoading: isLoadingCaseCategories } = useGetCaseCategoriesQuery({
    undefined,
  });
  const { data: rules, isLoading: isLoadingRules } = useGetRulesListQuery(productId, {
    skip: !productId,
  });
  const { data: ruleCategories, isLoading: isLoadingRuleCategories } = useGetRuleCategoriesQuery(
    {
      productId,
    },
    {
      skip: !productId,
    },
  );
  const { countries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: data?.map((el) => ({
        value: el.countryCode,
        label: `${el.countryCode}: ${el.name}`,
      })),
    }),
  });

  // #endregion

  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching
  const [searchQuery, setSearchQuery] = useState<string>('');

  const initialFilter = {
    caseStatus: 'OPEN',
    caseType: 'APPLICATION',
  };
  const [filterQuery, setFilterQuery] = useState<IFilterValue>(initialFilter);
  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'createdDateTime',
    direction: 'desc',
  });

  const [caseAssignments, setCaseAssignments] = useState([]);
  useEffect(() => {
    if (users.length > 0 && caseAssignmentsList.length > 0) {
      const filteredList = caseAssignmentsList
        .filter(
          (item, index, self) =>
            !!users?.some((element) => element.id === item.userId) && // remove not actual users Id
            !!users?.find((el) => el.id === item.userId)?.enabled && // remove users "not enabled"
            self.findIndex((el) => el.userId === item.userId) === index, // remove duplicates
        )
        .map((el) => ({
          ...el,
          currentUserName: users?.find((item) => item.id === el.userId).name,
        }));
      setCaseAssignments(filteredList);
    }
  }, [isLoadingUsers, isLoadingCaseAssignments]);

  const selectedCaseType = filterQuery.caseType;

  const filterOptions = {
    assigneeId: caseAssignments?.map((el) => ({
      value: el.userId,
      label: el.currentUserName,
    })),
    caseType: caseTypes,
    caseStatus: getCaseStatuses(selectedCaseType),
    riskLevel: riskLevelsCases,
    riskStatus: riskStatuses,
    caseCategories: caseCategories?.map((el) => ({
      value: el.category,
      label: el.description,
    })),
    transactionType: transactionTypes,
    ruleCategoryId: ruleCategories?.ids.map((el) => ({
      value: ruleCategories.entities[el].id,
      label: ruleCategories.entities[el].name,
    })),
    ruleId: rules?.rulesList.map((el) => ({
      value: el.id,
      label: `${el.id}: ${el.name}`,
    })),
    registrationCountry: countries,
    senderBankCountry: countries,
    beneficiaryBankCountry: countries,
  };

  const filterScheme = getFilterScheme(selectedCaseType);
  const sortOptions = getSortOptions(selectedCaseType);
  const tableSortOptions = getTableSortOptions(selectedCaseType);
  const replacedTableSortOptions = ['riskLevel']; // Show minimum width td instead of a sort option

  const [pageSize, setPageSize] = useState<number>(defaultPageSize * 2);
  const results = useRef<any>();
  const resultsTotalCount = useRef<number>(0);

  const loader = useRef<HTMLDivElement>();

  const isNoData = useRef<boolean>(false);
  const isNoResults = useRef<boolean>(false);

  // HELPERS
  // Skip multiple queries when typing search field
  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const clearQuery = () => {
    setSearchQuery('');
    setFilterQuery(initialFilter);
  };

  // return correct search object (empty if there is no search query)
  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  // HANDLERS
  // Search handler
  const searchHandler = (e) => {
    setSkipRequest(true);
    setSearchQuery(e);
    debounceQuery();
  };

  // QUERY
  const {
    data,
    isLoading: isLoadingCases,
    isFetching,
  } = useGetCasesQuery(
    {
      page: 0,
      tenantId: productId, // BE doesn't read x-tenant-id header, must be fixed
      size: pageSize,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      ...cleanEmptyQueryParameters(filterQuery),
      ...searchObj(),
    },
    {
      skip: skipRequest || !productId,
    },
  );

  // received data
  results.current = data?.content || [];
  resultsTotalCount.current = data?.totalElements || 0;
  const resultsLength = results.current.length;

  // check is there any application data
  isNoData.current = resultsLength === 0 && searchQuery.length === 0 && !queryHasValue(filterQuery);

  // check is there any application data match filter
  isNoResults.current =
    resultsLength === 0 && (searchQuery.length !== 0 || queryHasValue(filterQuery));

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    const searchString = new URLSearchParams(urlParams).get('search');
    setSearchQuery(searchString || '');

    // Read each filter value for filters
    const urlFilters = {};
    filterScheme.map(({ field, name }) => {
      const filterValue = new URLSearchParams(urlParams).get(name);
      if (filterValue) {
        urlFilters[name] = field === 'selectMulti' ? filterValue.split(',') : filterValue;
      }
    });

    setFilterQuery(Object.keys(urlFilters).length > 0 ? urlFilters : initialFilter);
  }, [getFilterScheme]);

  // load next page method
  const nextPage = () => {
    if (!isLoadingCases && !isFetching && resultsLength !== resultsTotalCount.current) {
      setPageSize((prevPageSize) => {
        const nextPageSize = prevPageSize + defaultPageSize;
        return nextPageSize >= resultsTotalCount.current ? resultsTotalCount.current : nextPageSize;
      });
    }
  };

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  // infinity scroll hook
  useInfinityScroll({ isFetching, isLoading: isLoadingCases, nextPage, loader });

  // control search, sort, filter from inputs
  useEffect(() => {
    setOpenDetailsDrawer(false); // Close details drawer
    setSelectedItemId(''); // Clear selected item
    setCheckedItems([]); // Clear checked items

    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }

    params.append('orderBy', sortedBy.property);
    params.append('direction', sortedBy.direction);

    Object.keys(filterQuery).map((key) => {
      params.append(key, filterQuery[key] as string);
    });

    navigate({ search: params.toString() }, { state: true });
  }, [searchQuery, sortedBy, navigate, filterQuery]);

  // #region Check-all functionality
  const handleCheckAll = () => {
    checkedItems.length === 0
      ? setCheckedItems([...results.current.map((el) => el.caseId)])
      : setCheckedItems([]);
  };
  // #endregion

  // #region Tab filters
  const getTabLink = (caseType = '') => {
    const { origin, href } = window.location;
    const url = new URL(href);
    url.searchParams.delete('caseType');

    if (caseType.length) {
      url.searchParams.append('caseType', caseType);
    }

    return url.toString().replace(origin, '');
  };

  const setCaseType = (e, caseType = '') => {
    e.preventDefault();

    setFilterQuery((prev) => {
      const next = { ...prev };

      if (caseType) {
        next.caseType = caseType;
      } else {
        delete next.caseType;
      }

      return next;
    });
  };
  // #endregion

  const isLoading =
    isLoadingUsers ||
    isLoadingCaseAssignments ||
    isLoadingCaseCategories ||
    isLoadingRuleCategories ||
    isLoadingRules;

  // #region Grouping helpers for createdDateTime
  let currentDate = '';
  const checkShowDate = (date) => {
    if (currentDate != format(date, DATE_FORMAT)) {
      currentDate = format(date, DATE_FORMAT);
      return true;
    }

    return false;
  };
  // #endregion

  // #region Export to Excel
  const messageExport =
    results.current.length > 0
      ? "Please filter with a 'Date Range' of less than 3 months"
      : 'List must not be empty';

  const isExportDisabled = (): boolean => {
    if (results.current.length < 1) {
      return true;
    }
    const timeDiff = Math.floor(
      parseDate(filterQuery.endCreationDate)?.getTime() -
        parseDate(filterQuery.startCreationDate)?.getTime(),
    );
    const dateRange = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
    if (dateRange > 0 && dateRange < 94 && timeDiff > 0) {
      return false;
    }
    return true;
  };

  const handleExportExcel = () => {
    const exportQuery = _.clone(filterQuery);
    const queryString = Object.keys(exportQuery)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(exportQuery[key])}`)
      .join('&');
    const headers = {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json',
    };

    downloadFile(`/api/gateway/cases/excel-reports?tenantId=${productId}&${queryString}`, {
      download: true,
      method: 'GET',
      headers,
      fileName: `Cases ${exportQuery.startCreationDate} - ${exportQuery.endCreationDate}`,
    }).catch((rejected) => {
      console.error(rejected);
    });
  };
  // #endregion

  const getIsChecked = (id) => checkedItems.some((el) => el === id);

  return (
    <>
      <PageContainer>
        <Helmet title="Cases" />

        <Box className="Cases">
          <StickyHeader bgColor={theme.palette.grey[50]}>
            <PageControl
              title="Cases"
              searchValue={searchQuery}
              searchHandler={searchHandler}
              filtersSelectable
              filterScheme={filterScheme}
              filterValue={filterQuery}
              filterHandler={setFilterQuery}
              options={filterOptions}
              onFilterReset={clearQuery}
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ borderBottom: 1, borderColor: theme.palette.divider }}
            >
              <Stack direction="row" gap={10}>
                {caseTypes.map(({ value, label }) => (
                  <div key={`Tab_${value}`} className="TabLink" style={{}}>
                    <Link
                      className={selectedCaseType === value ? 'active' : ''}
                      style={{ borderColor: theme.palette.primary.main }}
                      underline="none"
                      href={getTabLink(value)}
                      onClick={(e) => setCaseType(e, value)}
                    >
                      <Typography variant="bodyPlus">{label}</Typography>
                    </Link>
                  </div>
                ))}
              </Stack>
              <Typography variant="body" color={theme.palette.text.secondary}>
                {`Displaying ${resultsLength || 0} of ${resultsTotalCount.current}`}
              </Typography>
            </Stack>
          </StickyHeader>

          {/* Loading animation */}
          {(isLoading || isLoadingUsers) && <Skeleton count={15} height={58} />}

          {!(isLoading || isLoadingUsers) && (
            <>
              <ControlsPanel
                isSelected={checkedItems.length > 0}
                handleCheckAll={handleCheckAll}
                isLoading={isLoading}
                setFilterQuery={setFilterQuery}
                isExportDisabled={isExportDisabled()}
                messageExport={messageExport}
                handleExportExcel={handleExportExcel}
                count={resultsLength || 0}
                totalCount={resultsTotalCount.current}
                sortOptions={sortOptions}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
                filterQuery={filterQuery}
              />

              <Box
                sx={{
                  backgroundColor: 'white',
                  border: 1,
                  borderRadius: '12px',
                  borderColor: theme.palette.grey[200],
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <HeadTableCell sx={{ width: '1%' }} />
                      {tableSortOptions.map((option) =>
                        replacedTableSortOptions.includes(option.fieldName) ? (
                          <HeadTableCell key={option.fieldName} sx={{ width: '1%' }} />
                        ) : (
                          <HeadTableCell
                            key={option.fieldName}
                            sx={
                              tableRightAligned.includes(option.fieldName)
                                ? { textAlign: 'right' }
                                : {}
                            }
                          >
                            <SortBlock
                              property={option.fieldName}
                              sortedBy={sortedBy}
                              handleSort={handleSort}
                            >
                              {option.label === 'Description'
                                ? 'Triggered Rules, Sender, Beneficiary'
                                : option.label}
                            </SortBlock>
                          </HeadTableCell>
                        ),
                      )}
                      <HeadTableCell sx={{ width: '1%' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isLoadingUsers &&
                      results.current &&
                      results.current.map((item, idx) => {
                        const { caseId } = item;

                        return (
                          <CaseRow
                            key={caseId}
                            productId={productId}
                            caseData={item}
                            selected={caseId === selectedItemId && openDetailsDrawer}
                            users={users}
                            isChecked={getIsChecked(caseId)}
                            setCheckedItem={(checked) => {
                              if (checked) {
                                // Add doc to list
                                setCheckedItems((oldArray) => [...oldArray, caseId]);
                                return;
                              }
                              // Remove doc from list
                              setCheckedItems((oldArray) => [
                                ...oldArray.filter((c) => c !== caseId),
                              ]);
                            }}
                            setSelectedItemId={setSelectedItemId}
                            setOpenDetailsDrawer={setOpenDetailsDrawer}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>

              <EndListButton
                isVisible={resultsLength > 0}
                nextPage={nextPage}
                isFetching={isFetching}
                isEndReached={resultsLength === resultsTotalCount.current}
              />
            </>
          )}

          {!(isLoading || isFetching) && (
            <>
              <NoDataBox show={isNoData.current} entityTitle="Case" />
              <NoResultsBox show={isNoResults.current} onResetFilters={clearQuery} />
            </>
          )}
        </Box>
        <div ref={loader} />
      </PageContainer>

      {!!checkedItems && (
        <BulkActionsBox
          checkedItems={checkedItems}
          users={users}
          onComplete={() => setCheckedItems([])}
          allItems={results.current}
          selectedCaseType={selectedCaseType}
        />
      )}

      <DetailsDrawer open={openDetailsDrawer}>
        <CaseDetailsSidebar
          caseId={selectedItemId}
          users={users}
          onClose={() => {
            setOpenDetailsDrawer(false);
            setSelectedItemId('');
          }}
        />
      </DetailsDrawer>
    </>
  );
};

export { Cases };
