import { FilterScheme } from 'types';

export const filterScheme: FilterScheme[] = [
  {
    field: 'selectMulti',
    name: 'finalDecision',
    label: 'Final Decision',
    initialFilterValue: [],
  },
  {
    field: 'datePicker',
    name: 'dateFrom',
    label: 'Date From',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'dateTo',
    label: 'Date To',
    initialFilterValue: null,
  },
  {
    field: 'selectMulti',
    name: 'assigneeId',
    label: 'Assigned To',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'mainStatus',
    label: 'Status',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'riskStatus',
    label: 'Risk Status',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'categoryId',
    label: 'Category',
    initialFilterValue: [],
  },
];
