import * as yup from 'yup';
import { IBAN_REGEX, IP_REGEX, NUM_LETTERS_REGEX } from '../../constants/constants';
import { testIbanChecksum } from '../../utils';

export const validationSchemaRuleVeto = yup.object({
  ruleVetoType: yup.string().required('Required'),
  referenceIdentifier: yup.string().when('ruleVetoType', (el, schema) => {
    switch (el) {
      case 'CUSTOMER_EMAIL':
        return schema
          .email('Please use a valid email address.')
          .required('Email address is required.');
      case 'MERCHANT_ID':
        return schema.required('Required').matches(/[0-9]/, 'Please input a number');
      case 'SENDER_IBAN':
      case 'BENEFICIARY_IBAN':
      case 'SENDER_BENEFICIARY_IBAN':
        return schema
          .required('Required')
          .min(5, 'Minimum length is ${min}')
          .max(35, 'Maximum length is ${max}')
          .matches(IBAN_REGEX, 'IBAN is not in correct format')
          .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value));
      case 'SENDER_BIC':
      case 'BENEFICIARY_BIC':
      case 'SENDER_BENEFICIARY_BIC':
      case 'CLIENT_ID':
        return schema
          .required('Required')
          .matches(NUM_LETTERS_REGEX, 'Only numbers and letters are allowed');
      case 'IP_ADDRESS':
        return schema.required('Required').matches(IP_REGEX, 'IP address format required');
      default:
        return schema.required('Required');
    }
  }),
  startDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  expiryDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .min(
      yup.ref('startDate'), // value needs to be startDate +1 day
      () => 'Date needs to be later than the Start Date',
    ),
  comments: yup.string(),
});
