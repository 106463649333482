/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_CASES,
  LOAD_CASES_SUCCESS,
  LOAD_CASES_FAIL,
  SELECT_CASE,
  UPDATE_CASE_SUCCESS,
  SHOW_ASSIGN_CASE,
  SHOW_UPDATE_STATUS,
  CLOSE_ASSIGN_CASE,
  CLOSE_UPDATE_STATUS,
  UPDATE_CASE_FAIL,
  BULK_UPDATE_CASES_COMPLETE,
  CREATE_JIRA_CASE_SUCCESS,
} from '../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  cases: [],
  paginationInfo: null,
  selectedCase: null,
  isAssigningCase: false,
  isUpdatingStatus: false,
  onCasesUpdated: false,
};

const casesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_CASES:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_CASES_SUCCESS:
        draft.cases = action.cases.payload;
        draft.paginationInfo = action.cases.paginationInfo;
        draft.loading = false;
        break;
      case LOAD_CASES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case SELECT_CASE:
        draft.selectedCase = action.incident;
        break;
      case SHOW_ASSIGN_CASE:
        draft.isAssigningCase = true;
        break;
      case SHOW_UPDATE_STATUS:
        draft.isUpdatingStatus = true;
        break;
      case CLOSE_ASSIGN_CASE:
        draft.isAssigningCase = false;
        break;
      case CLOSE_UPDATE_STATUS:
        draft.isUpdatingStatus = false;
        break;
      case UPDATE_CASE_SUCCESS:
        draft.selectedCase = action.updatedCase;
        draft.isAssigningCase = false;
        draft.isUpdatingStatus = false;
        break;
      case BULK_UPDATE_CASES_COMPLETE: {
        const { onCasesUpdated } = state;
        draft.onCasesUpdated = !onCasesUpdated;
        break;
      }
      case UPDATE_CASE_FAIL:
        draft.isAssigningCase = false;
        draft.isUpdatingStatus = false;
        break;
      case CREATE_JIRA_CASE_SUCCESS:
        draft.jiraCaseCreated = true;
        draft.error = action;
        break;
    }
  });

export default casesReducer;
