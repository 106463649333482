import { darken } from '@mui/material/styles';
import { baseTheme } from '../baseTheme';
import {
  small,
  medium,
  large,
  Base,
  BaseContained,
  Primary,
  PrimaryText,
  PrimaryOutline,
  Secondary,
  SecondaryContained,
  SecondaryText,
  SecondaryOutlined,
  ContrastText,
  Success,
  SuccessContained,
  SuccessOutlined,
  Error,
  ErrorText,
  ErrorOutlined,
  Bold,
  BoldContained,
  BoldText,
  BoldOutlined,
  Contrast,
  ContrastOutlined,
  Danger,
  DangerText,
  DangerOutlined,
  SuccessLight,
  SuccessLightText,
  SuccessLightOutlined,
  Warning,
  WarningText,
  WarningOutlined,
  BaseText,
  BaseOutlined,
} from './button-variants';
import { SuccessText } from './button-variants/Success';
import {
  PrimaryLight,
  PrimaryLightOutlined,
  PrimaryLightText,
} from './button-variants/PrimaryLight';
import { shape, shapeLarge, shapeSmall, shapeSquared } from './button-variants/shape';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    base: true;
    bold: true;
  }
}

export const Button = {
  defaultProps: {
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      transition: 'background .3s ease-in-out',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },

  variants: [
    small,
    medium,
    large,
    Base,
    BaseContained,
    BaseOutlined,
    BaseText,
    Primary,
    PrimaryText,
    PrimaryLight,
    PrimaryOutline,
    PrimaryLightText,
    PrimaryLightOutlined,
    Secondary,
    SecondaryContained,
    SecondaryText,
    SecondaryOutlined,
    Contrast,
    ContrastText,
    ContrastOutlined,
    Danger,
    DangerText,
    DangerOutlined,
    Success,
    SuccessContained,
    SuccessOutlined,
    SuccessLight,
    SuccessLightText,
    SuccessLightOutlined,
    SuccessText,
    Error,
    ErrorText,
    ErrorOutlined,
    Warning,
    WarningText,
    WarningOutlined,
    Bold,
    BoldContained,
    BoldText,
    BoldOutlined,

    shape,
    shapeSmall,
    shapeLarge,
    shapeSquared,

    {
      props: { variant: 'contained', color: 'default' },
      style: {
        '&:not(:disabled):hover': {
          backgroundColor: darken(
            baseTheme.palette.default.main,
            baseTheme.palette.action.hoverOpacity,
          ),
        },

        '&:not(:disabled):active': {
          backgroundColor: darken(
            baseTheme.palette.default.main,
            baseTheme.palette.action.activatedOpacity,
          ),
        },
      },
    },

    {
      props: { variant: 'text', color: 'default' },
      style: {
        color: baseTheme.palette.common.black,
        '&:not(:disabled):hover': {
          backgroundColor: darken(
            baseTheme.palette.default.main,
            baseTheme.palette.action.hoverOpacity,
          ),
        },
        '&:not(:disabled):active': {
          backgroundColor: darken(
            baseTheme.palette.default.main,
            baseTheme.palette.action.activatedOpacity,
          ),
        },
      },
    },
  ],
};
