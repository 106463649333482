/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  LOAD_RULE_CATEGORIES,
  LOAD_RULE_CATEGORIES_SUCCESS,
  LOAD_RULE_CATEGORIES_FAIL,
} from '../containers/RuleCategories/constants';

export const initialState = {
  loading: false,
  error: false,
  ruleCategories: [],
};

const ruleCategoriesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_RULE_CATEGORIES:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_RULE_CATEGORIES_SUCCESS:
        draft.ruleCategories = action.ruleCategories.payload;
        draft.loading = false;
        break;
      case LOAD_RULE_CATEGORIES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default ruleCategoriesReducer;
