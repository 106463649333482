import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { Cancel as CancelIcon, ForwardToInbox as ForwardToInboxIcon } from '@mui/icons-material';
import { RiMore2Fill, RiCheckDoubleFill } from 'react-icons/ri';
import { ConfirmDialog, ModalDialog } from 'uikit';

import {
  allowSendAndDeleteActionsStatuses,
  allowResendActivationStatuses,
  allowApproveEvents,
  allowConditionallyApproveEvents,
  awaitEvents,
  disallowAwaitEvents,
  allowRejectStatuses,
  allowRevokeStatuses,
  statusToEventMap,
} from 'features/ApplicationManagement/constants/appEntitiesMap';

import {
  useDeleteApplicationMutation,
  useCustomerPasswordRecoverMutation,
  useUpdateApplicationPartialMutation,
  useUpdateApplicationStatusesMutation,
  useSendToSignMutation,
  useGetApplicationsQuery,
  useCopyApplicationMutation,
} from 'services/gatewayApi/applicationApi';
import {
  useGetNotifiableStatesQuery,
  useGetApplicationNextEventsQuery,
} from 'services/gatewayApi/stateMachineApi';

import './ApplicationMenu.scss';
import { toastDeleteSuccess, toastError, toastSuccess, checkProductName } from 'utils';
import { ConfirmDialogAppDetails } from '../../../ConfirmDialogAppDetails';
import { SendEmailDialog, SendEmailProps } from '../../../../SendEmailDialog';

import { ApplicationMenuProps } from './ApplicationMenuProps';

export const ApplicationMenu: React.FC<ApplicationMenuProps> = ({
  mainStatus,
  application,
  formsToSign,
}) => {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();

  const { id: applicationId, isRiskScoringSubmitted } = application;

  const isOpenPayd = checkProductName(['OPENPAYD']);

  const [isSaving, setIsSaving] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false);

  const { data: notifiableStates } = useGetNotifiableStatesQuery(undefined);
  const { data: nextEvents } = useGetApplicationNextEventsQuery(applicationId, {
    skip: !application,
  });
  const { applications, isLoadingApplications } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        applications: data?.content,
        isLoadingApplications: isLoading,
      }),
    },
  );

  const hasCopy = !isLoadingApplications && applications?.length > 1;
  const isMalta = application?.categoryId === 5;
  const isKind = application?.kind === 'ONBOARDING';
  const isCopyStatus = ['PENDING_BANKING'].includes(mainStatus);
  const isCanBeCopied = isMalta && isKind && !hasCopy && isCopyStatus;

  // STANDARD OPEN / CLOSE MECHANISM
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // #region Delete Application
  const [deleteApplication] = useDeleteApplicationMutation();
  const [idToDelete, setIdToDelete] = useState<string | boolean>(false);

  const confirmDelete = () => {
    setIsSaving(true);
    deleteApplication(idToDelete)
      .unwrap()
      .then(() => {
        toastDeleteSuccess('Application');
        navigate('/application-management');
      })
      .catch((rejected) => {
        setIsSaving(false);
        toastError(`Can't delete the application. Please try again later.`);
        console.error(rejected);
      });
  };
  // #endregion

  // #region Send Forms to Sign
  const hasFormsToSign = formsToSign.totalCount - formsToSign.signedCount > 0;
  const [sendToSign] = useSendToSignMutation();
  const handleSendToSign = () => {
    if (!formsToSign.canBeSigned) {
      toastError(
        `All of the ${formsToSign.totalCount} forms need to be verified in order to send for signature.`,
      );
      return;
    }
    setIsSaving(true);

    sendToSign({ applicationId })
      .then(() => {
        toastSuccess('Forms sent for signature.');
      })
      .catch((err) => {
        console.error(err);
        toastError('Error sending forms for signature.');
      })
      .finally(() => setIsSaving(false));
  };
  // #endregion

  // #region Resend Activation Email to Customer
  const [doCustomerPasswordReset] = useCustomerPasswordRecoverMutation();
  const handleResendActivation = () => {
    setIsSaving(true);

    doCustomerPasswordReset(applicationId)
      .unwrap()
      .then(() => {
        toastSuccess('Password reset email was resent to the customer.');
      })
      .catch((rejected) => {
        toastError(`Can't resend the application. Please try again later.`);
        console.error(rejected);
      })
      .finally(() => setIsSaving(false));
  };
  // #endregion

  // 3. Approve
  const handleApproveApplication = () => {
    const statusEvent = allowApproveEvents.find((event) => (nextEvents as any[]).includes(event));
    handleMainStatusUpdate(statusEvent);
  };

  // 4. Conditionally Approve
  const [patchApplication] = useUpdateApplicationPartialMutation();
  const [showConditionallyApproveDialog, setShowConditionallyApproveDialog] = useState(false);
  const handleConditionallyApprove = ({ reason, clientCanTransact }) => {
    patchApplication({ id: applicationId, clientCanTransact })
      .unwrap()
      .catch((rejected) => console.error(rejected));

    const statusEvent = allowConditionallyApproveEvents.find((event) =>
      (nextEvents as any[]).includes(event),
    );
    handleMainStatusUpdate(statusEvent, reason);
  };

  // 5. Back to the Customer
  const [showBackToCustomerDialog, setShowBackToCustomerDialog] = useState<boolean>(false);
  const handleBackToCustomerApplication = ({ reason }) =>
    handleMainStatusUpdate('REQUEST_FURTHER_DOCUMENTS_EVENT', reason);

  const [showSendEmailDialog, setShowSendEmailDialog] = useState<boolean>(false);
  const [emailProps, setEmailProps] = useState<SendEmailProps>({
    applicationId,
    emailType: 'common',
    title: 'Notify OpenPayd',
    subject: `Notification for application of "${application.companyName}"`,
  });

  const handleNotifyUsers = () => {
    setEmailProps((prevState) => ({
      ...prevState,
      onAfterSubmit: undefined,
    }));
    setShowSendEmailDialog(true);
  };

  const [updateApplicationStatus, statusUpdate] = useUpdateApplicationStatusesMutation();

  // show copy dialog after application get PENDING_BANKING status
  useEffect(() => {
    if (
      isCanBeCopied &&
      statusUpdate.status === 'fulfilled' &&
      statusUpdate.data?.mainStatus === 'PENDING_BANKING'
    ) {
      setShowCopyDialog(true);
    }
  }, [isCanBeCopied, statusUpdate]);

  const handleMainStatusUpdate = (newStatusEvent, reason = '') => {
    const shouldEmailNotify = notifiableStates[mainStatus];
    if (shouldEmailNotify && (shouldEmailNotify as any[]).includes(newStatusEvent)) {
      setEmailProps((prevState) => ({
        ...prevState,
        onAfterSubmit: () => doMainStatusUpdate(newStatusEvent, reason),
      }));
      setShowSendEmailDialog(true);
      return;
    }

    doMainStatusUpdate(newStatusEvent, reason);
  };
  const doMainStatusUpdate = (newStatusEvent, reason = '') => {
    setIsSaving(true);

    updateApplicationStatus({
      id: applicationId,
      mainStatusEvent: newStatusEvent,
      mainStatusReason: reason,
    })
      .unwrap()
      .then(() => {
        setIsSaving(false);
      })
      .catch((rejected) => {
        setIsSaving(false);
        console.error(rejected);
      });
  };

  // 12. Reject
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const handleRejectApplication = ({ reason }) => {
    switch (mainStatus) {
      // EMB
      case 'PENDING_AML_TEAM_PREQUESTIONNAIRE':
      case 'PENDING_AML_TEAM':
        return handleMainStatusUpdate('AML_DECLINE_EVENT', reason);
      case 'PENDING_MLRO_MANAGER_AFTER_AML':
      case 'PENDING_MLRO_MANAGER':
        return handleMainStatusUpdate('MLRO_REJECT_EVENT', reason);
      case 'PENDING_BOARD_MEMBER':
        return handleMainStatusUpdate('BOARD_DECLINE_EVENT', reason);

      // OpenPayd
      case 'PENDING_BANKING':
        return handleMainStatusUpdate('BANKING_DECLINE_EVENT', reason);
      case 'PENDING_ONBOARDING_COMPLIANCE_FIRST':
        return handleMainStatusUpdate('ONBOARDING_COMPLIANCE_FIRST_DECLINE_EVENT', reason);
      case 'PENDING_ONBOARDING_COMPLIANCE_SECOND':
        return handleMainStatusUpdate('ONBOARDING_COMPLIANCE_SECOND_DECLINE_EVENT', reason);
      case 'PENDING_ONBOARDING_COMPLIANCE_THIRD':
        return handleMainStatusUpdate('ONBOARDING_COMPLIANCE_THIRD_DECLINE_EVENT', reason);
      case 'PENDING_DIRECTOR_COMPLIANCE':
        return handleMainStatusUpdate('DIRECTOR_COMPLIANCE_DECLINE_EVENT', reason);
      case 'PENDING_UK_MLRO':
        return handleMainStatusUpdate('UK_MLRO_DECLINE_EVENT', reason);
      case 'PENDING_MALTA_MLRO':
        return handleMainStatusUpdate('MALTA_MLRO_DECLINE_EVENT', reason);
      case 'PENDING_HEAD_AML':
        return handleMainStatusUpdate('HEAD_AML_DECLINE_EVENT', reason);
      default:
        return '';
    }
  };

  const getDialogProps = () => {
    if (showRejectDialog) {
      return {
        title: 'Reject Application',
        actionTitle: 'Reject',
        setShowDialog: setShowRejectDialog,
        onConfirm: handleRejectApplication,
      };
    }

    if (showConditionallyApproveDialog) {
      return {
        title: 'Conditionally Approve',
        actionTitle: 'Conditionally Approve',
        setShowDialog: setShowConditionallyApproveDialog,
        onConfirm: handleConditionallyApprove,
        showCanTransact: true,
      };
    }

    if (showBackToCustomerDialog) {
      return {
        title: 'Back to Customer',
        actionTitle: 'Submit',
        setShowDialog: setShowBackToCustomerDialog,
        onConfirm: handleBackToCustomerApplication,
      };
    }
    return undefined;
  };

  const disabledReasonRiskScoring = 'Risk Scoring form needs to be submitted first';

  const renderAwaitMenuItems = () => {
    const result = [];

    Object.keys(awaitEvents).forEach((event) => {
      const title = awaitEvents[event];
      let isDisabled = false;
      let disabledReason = '';

      if ((nextEvents as any[]).includes(event) && !disallowAwaitEvents.includes(mainStatus)) {
        if (event === 'AWAIT_BANKING_EVENT' && !isRiskScoringSubmitted) {
          isDisabled = true;
          disabledReason = disabledReasonRiskScoring;
        }

        result.push(
          <MenuItem
            key={event}
            onClick={() =>
              !isDisabled ? handleMainStatusUpdate(event) : toastError(disabledReason)
            }
            data-event={event}
          >
            <Typography>{title}</Typography>
          </MenuItem>,
        );
      }
    });

    return result;
  };

  const [copyApplication, statusCopy] = useCopyApplicationMutation();

  const handleCopyApplication = ({ id }: { id: string }) => {
    setIsSaving(true);
    copyApplication({ applicationId: id })
      .unwrap()
      .then(() => {
        setIsSaving(false);
        toastSuccess('Application replicated');
      })
      .catch((rejected) => {
        setIsSaving(false);
        toastError(`Can't replicate the application.`);
        console.error(rejected);
      });
  };

  return (
    <>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        disabled={isSaving}
      >
        <RiMore2Fill size="16px" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {false && ( // temporarily hidden
          <>
            <MenuItem
              onClick={() =>
                navigate(`/application-management/${clientId}/applications/${applicationId}/id-verify`)
              }
            >
              <ListItemIcon>
                <RiCheckDoubleFill />
              </ListItemIcon>
              ID Verification
            </MenuItem>

            <Divider />
          </>
        )}

        {isOpenPayd && mainStatus !== 'PENDING_FORMS_SIGNATURE' && (
          <MenuItem onClick={handleNotifyUsers}>
            <ListItemIcon>
              <ForwardToInboxIcon fontSize="inherit" />
            </ListItemIcon>
            Notify to OpenPayd
          </MenuItem>
        )}
        {/* 1. Delete the application in the OPEN status */}
        {allowSendAndDeleteActionsStatuses.includes(mainStatus) && (
          <MenuItem onClick={() => setIdToDelete(applicationId)} className="text-danger">
            <ListItemIcon>
              <CancelIcon fontSize="inherit" />
            </ListItemIcon>
            Delete Application
          </MenuItem>
        )}
        {/* 2. Resend the application (reset password) */}
        {allowResendActivationStatuses.includes(mainStatus) && (
          <MenuItem onClick={() => handleResendActivation()} className="text-primary">
            Resend
          </MenuItem>
        )}
        {allowRevokeStatuses.includes(mainStatus) && (
          <MenuItem onClick={() => handleMainStatusUpdate(statusToEventMap[mainStatus])}>
            Revoke
          </MenuItem>
        )}
        {/* 3. Approve application if any of allowApproveEvents statuses */}
        {nextEvents && allowApproveEvents.some((event) => (nextEvents as any[]).includes(event)) && (
          <MenuItem onClick={() => handleApproveApplication()} className="text-success">
            Approve
          </MenuItem>
        )}
        {/* 4. Approve application if any of allowConditionallyApproveEvents statuses */}
        {nextEvents &&
          allowConditionallyApproveEvents.some((event) =>
            (nextEvents as any[]).includes(event),
          ) && (
            <MenuItem
              onClick={() => setShowConditionallyApproveDialog(true)}
              className="text-success"
            >
              Conditionally Approve
            </MenuItem>
          )}
        {/* 5. Send application back to the customer for further documents */}
        {nextEvents && (nextEvents as any[]).includes('REQUEST_FURTHER_DOCUMENTS_EVENT') && (
          <MenuItem onClick={() => setShowBackToCustomerDialog(true)}>
            Back to the Customer
          </MenuItem>
        )}
        {/* 10. render Await Menu Items */}
        {nextEvents && renderAwaitMenuItems()}
        {/* 11. Client informed */}
        {nextEvents && (nextEvents as any[]).includes('CLIENT_INFORMED_EVENT') && (
          <MenuItem
            onClick={() =>
              isRiskScoringSubmitted
                ? handleMainStatusUpdate('CLIENT_INFORMED_EVENT')
                : toastError(disabledReasonRiskScoring)
            }
          >
            <Typography sx={{ color: !isRiskScoringSubmitted ? 'grey.500' : 'grey.900' }}>
              Client Informed
            </Typography>
          </MenuItem>
        )}
        {/* 12. Reject */}
        {allowRejectStatuses.includes(mainStatus) && (
          <MenuItem onClick={() => setShowRejectDialog(true)} className="text-danger">
            Reject
          </MenuItem>
        )}
        {/* /!* 13. Change assignee - send to compliance *!/ */}
        {/* {['PENDING_SENIOR_ONBOARDING_OPERATIONS'].includes(mainStatus) && ( */}
        {/*  <div> */}
        {/*    <Divider /> */}
        {/*    <MenuAssigneeOptions applicationId={applicationId} mainStatus={mainStatus} /> */}
        {/*  </div> */}
        {/* )} */}
        {/* 14. Send for signature */}
        {hasFormsToSign && mainStatus === 'PENDING_ONBOARDING_COMPLIANCE_FIRST' && (
          <MenuItem
            onClick={handleSendToSign}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography>Send for Signature</Typography>
            {!formsToSign.canBeSigned && (
              <Typography sx={{ color: 'grey.500' }}>
                {`${formsToSign.verifiedCount + formsToSign.pendingCount}/${formsToSign.totalCount
                  }`}
              </Typography>
            )}
          </MenuItem>
        )}
      </Menu>

      {/* Delete dialog */}
      {!!idToDelete && (
        <ConfirmDialog
          showDialog={!!idToDelete}
          setShowDialog={setIdToDelete}
          onCancel={() => setIdToDelete(false)}
          onConfirm={confirmDelete}
          title="Delete Application"
          text={`Are you sure you want to delete Application? "${application.companyName}"`}
          confirmButtonText="Delete"
          confirmButtonColor="defaultDanger"
        />
      )}

      {/* Dialog for reason for reject, conditionally approve or back to customer */}
      {(showRejectDialog || showConditionallyApproveDialog || showBackToCustomerDialog) && (
        <ConfirmDialogAppDetails {...getDialogProps()} showDialog />
      )}

      {showSendEmailDialog && (
        <SendEmailDialog
          {...emailProps}
          applicationId={applicationId}
          setShowDialog={setShowSendEmailDialog}
          showDialog={showSendEmailDialog}
        />
      )}

      {showCopyDialog && (
        <ModalDialog
          open={showCopyDialog}
          onClose={() => setShowCopyDialog(false)}
          title="Replicate Application"
          actionTitle="Replicate"
          handleAction={() => {
            handleCopyApplication({ id: applicationId });
            setShowCopyDialog(false);
          }}
        >
          Would you like to replicate form in uk entity?
        </ModalDialog>
      )}
    </>
  );
};
