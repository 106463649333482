import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Skeleton } from 'uikit';
import { useGetKycChecksByApplicantIdQuery } from 'services/gatewayApi/kycChecksApi';
import { KycCheckRow } from './KycCheckRow';

import './KycCkecksTab.scss';

interface KycCkecksTabProps {
  applicantId: string;
}

export const KycCkecksTab: React.FC<KycCkecksTabProps> = ({ applicantId }) => {
  const { data: kycChecks, isLoading, isFetching } = useGetKycChecksByApplicantIdQuery(applicantId);

  return (
    <div className="KycCkecksTab">
      {(isLoading || isFetching) && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!(isLoading || isFetching) && kycChecks && (
        <>
          {kycChecks.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <strong>Created</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Result</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Final Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kycChecks
                  .slice()
                  .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1))
                  .map((kycCheck) => (
                    <KycCheckRow key={`check_${kycCheck.id}`} kycCheck={kycCheck} />
                  ))}
              </TableBody>
            </Table>
          )}

          {kycChecks.length === 0 && (
            <div className="no-data">
              <Typography variant="body1" component="p" color="grey.900">
                No KYC checks performed yet...
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};
