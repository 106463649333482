import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Backdrop,
  Chip,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import MUIDataTable from '../../components/MUIDataTable';
import { initialFormData, cardActions } from './constants';
import { useGetCardDataQuery } from '../../services/gatewayApi/cardDataApi';
import { makeSelectedProductIdData } from '../../selectors/user';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import CardDataFormDialog from './CardDataFormDialog';
import { removeEmptyProperties } from '../../utils/modelHelper';

const CardDataList = ({ filterValues }) => {
  const [page, setPage] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const [sortOrder, setSortOrder] = useState({
    name: 'id',
    direction: 'desc',
  });

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const filterValuesClean = _.clone(filterValues);
  removeEmptyProperties(filterValuesClean);

  const { data, isLoading, isFetching } = useGetCardDataQuery(
    {
      page,
      size: pageSize,
      sort: `${sortOrder.name},${sortOrder.direction}`,
      ...filterValuesClean,
    },
    {
      skip: !selectedProductId,
    },
  );

  useEffect(() => {
    if (data) {
      setCardData(data.results);
      setCount(data.count);

      if (Math.floor((data.count - 1) / pageSize) < page) {
        resetGridPage(); // in case page is not available (after delete)
      }
    }
  }, [data, page, pageSize]);

  // reset grid on search/reset
  useEffect(() => {
    resetGridPage();
  }, [filterValues]);

  const resetGridPage = (skipSetPage = false) => {
    if (!skipSetPage) {
      setPage(0); // move to first page
    }
  };

  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleEdit = (id) => {
    setFormData(cardData.find((item) => item.id === id));
    setFormOpen(true);
  };

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    expandableRows: false,
    expandableRowsHeader: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    onTableChange: (action, state) => {
      if (action === 'sort') {
        resetGridPage();
        setSortOrder(state.sortOrder);
      }

      if (action === 'changePage') {
        resetGridPage(true);
        setPage(state.page);
      }

      if (action === 'changeRowsPerPage') {
        resetGridPage();
        setPageSize(state.rowsPerPage);
      }
    },
    sortOrder,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isLoading || isFetching ? <LinearProgress /> : 'No matching records found...',
      },
    },

    serverSide: true,
    count,
    page,
  };

  const columns = [
    {
      name: 'maskedCardNumber',
      label: 'Masked Card Number',
    },
    {
      name: 'cardAction',
      label: 'Card Action',
      options: {
        customBodyRender: (value) => {
          const cardAction = cardActions.find((action) => action.value === value);
          return <Chip label={cardAction.label} color={cardAction.color} />;
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => (
          <Tooltip title="Edit" disableFocusListener>
            <IconButton onClick={() => handleEdit(value)} size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        <div style={{ position: 'relative' }}>
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MUIDataTable data={cardData} columns={columns} options={options} />
        </div>

        <CardDataFormDialog open={formOpen} onClose={() => setFormOpen(false)} data={formData} />
      </div>
    </div>
  );
};

CardDataList.propTypes = {
  filterValues: PropTypes.object,
};

export default CardDataList;
