import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormTemplate from '@data-driven-forms/mui-component-mapper/form-template';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  setFormsConfig,
  useGetApplicationFormByApplicationIdAndFormIdAndMapRecordsToDDFSchemaQuery,
  useGetSelectorsDataQuery,
} from '@haystack-forms/api';
import {
  actionMapper,
  conditionMapper,
  getComponentMapper,
  getStore,
  validatorMapper,
} from '@haystack-forms/ddforms';
import { useDispatch } from 'react-redux';
import { processKeyValue } from './processKeyValue';

const FormContainerStyled = styled(Grid, {
  name: 'FormContainer',
  slot: 'Root',
})(() => ({
  '& form': {
    display: 'inline-block',
    width: '100%',
  },
}));

interface CPFormProps {
  formId: string;
  isEditable: boolean;
  isInternal: boolean;
}

export const CPForm = React.memo(({ formId, isEditable, isInternal }: CPFormProps): JSX.Element => {
  const { applicationId } = useParams<{
    applicationId: string;
    sectionId: string;
    partition: string;
    clientId: string;
  }>();

  const dispatch = useDispatch();
  const { isFetching: isFetchingSelectors } = useGetSelectorsDataQuery(undefined);

  const [validateOnMount] = React.useState(!isInternal);

  React.useEffect(() => {
    if (isInternal) {
      dispatch(
        setFormsConfig({
          formStatusesEnabled: true,
          sectionStatusesEnabled: true,
        }),
      );
    } else {
      dispatch(
        setFormsConfig({
          formStatusesEnabled: false,
          sectionStatusesEnabled: false,
        }),
      );
    }
  }, []);

  const { data: schema, isFetching } =
    useGetApplicationFormByApplicationIdAndFormIdAndMapRecordsToDDFSchemaQuery({
      formId,
      applicationId,
    });

  const processedSchemaFields = schema && processKeyValue(schema.fields);
  const processedSchema = { ...schema, fields: processedSchemaFields };

  return !isFetching && !isFetchingSelectors && processedSchema && processedSchema.fields ? (
    <FormContainerStyled>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormRenderer
          actionMapper={actionMapper}
          conditionMapper={conditionMapper}
          componentMapper={getComponentMapper(
            applicationId,
            formId,
            validateOnMount,
            // TODO: refactor, shouldn't be here
            isEditable,
          )}
          validatorMapper={validatorMapper}
          FormTemplate={(props) => (
            <FormTemplate
              {...props}
              showFormControls={false}
              formWrapperProps={{ GridContainerProps: { sx: { gap: 0 } } }}
            />
          )}
          schema={processedSchema}
        />
      </LocalizationProvider>
    </FormContainerStyled>
  ) : (
    <>
      <Skeleton variant="text" sx={{ pb: 13, mb: 3 }} />
      <Skeleton variant="text" sx={{ pb: 13, mb: 3 }} />
      <Skeleton variant="text" sx={{ pb: 13, mb: 3 }} />
      <Skeleton variant="text" sx={{ pb: 13, mb: 3 }} />
      <Skeleton variant="text" sx={{ pb: 13, mb: 3 }} />
    </>
  );
});
