import React, { useEffect } from 'react';
import { Skeleton } from 'uikit';
import { Grid } from '@mui/material';
import { useGetTenantsQuery } from 'services/gatewayApi/usersApi';
import { RoleListRow } from './RoleListRow';

export const RolesList = ({
  rolesList,
  isLoading: isGroupsLoading,
  setIsRoleDialogOpen,
  setProductList,
}) => {
  const { data: productsList, isLoading: isTenantsLoading } = useGetTenantsQuery({ undefined });

  useEffect(() => {
    setProductList(productsList);
  }, [productsList]);

  const isLoading = isTenantsLoading || isGroupsLoading;

  return (
    <div>
      {isLoading ? (
        <Skeleton count={11} height={56} />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {rolesList.map((role) => (
              <Grid
                item
                sx={(theme) => ({
                  width: '100%',
                  minHeight: '56px',
                  borderBottom: `1px dashed ${theme.palette.grey[200]}`,
                })}
                key={role.id}
              >
                <RoleListRow
                  role={role}
                  productsList={productsList}
                  setIsRoleDialogOpen={setIsRoleDialogOpen}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};
