import { createSelector } from 'reselect';
import { initialState } from '../reducers/users';

const selectUser = (state) => state.users.loggedInUser || initialState.loggedInUser;
const makeUserData = () => createSelector(selectUser, (userState) => userState);

export const selectProductId = (state) => state.users.selectedProductId;
const makeSelectedProductIdData = () =>
  createSelector(selectProductId, (productIdState) => productIdState);
const makeUserProductsData = () => createSelector(selectUser, (state) => state.products || []);
const makeUserPermissionsData = () =>
  createSelector(selectUser, (state) => state.permissions || []);
const makeUserPermissionsNamesData = () =>
  createSelector(selectUser, (state) => state?.permissions?.map((p) => p.name) || []);
export {
  makeUserData,
  makeUserPermissionsData,
  makeUserPermissionsNamesData,
  makeSelectedProductIdData,
  makeUserProductsData,
};
