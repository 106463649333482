import { base, grey } from '../../palette-blocks';

export const BaseChip = {
  props: {
    color: 'base',
  },
  style: {
    color: base.contrastText,
    '.MuiChip-deleteIcon': {
      color: base.contrastText,
      '&:hover, &:active': {
        color: base.contrastText,
      },
    },
    '.MuiChip-avatar': {
      background: grey[200],
      color: base.contrastText,
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: base.hover,
      },
      '&:active': {
        background: base.active,
      },
    },
  },
};

export const BaseChipOutlined = {
  props: {
    color: 'base',
    variant: 'outlined',
  },
  style: {
    background: 'white',
    '&.MuiChip-clickable': {
      '&:hover': {
        background: base.hover,
        borderColor: base.hover,
      },
      '&:active': {
        background: base.active,
        borderColor: base.active,
      },
    },
  },
};
