import { gatewayApi } from '../gatewayApi';
import { FormSection, FormSectionHistory } from '../../features/ApplicationManagement/types/forms';

const applicationFormSectionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAppFormSections: build.query<FormSection[], { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: `applications/${applicationId}/forms/sections`,
        method: 'GET',
      }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'FormSections', id: applicationId },
      ],
    }),

    addAppFormSection: build.mutation<
      FormSection[],
      { applicationId: string; sectionId: number; partition: string }
    >({
      query: ({ applicationId, sectionId, partition }) => ({
        url: `applications/${applicationId}/forms/sections/${sectionId}`,
        method: 'POST',
        body: { partition },
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'FormSections', id: applicationId },
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),

    deleteAppFormSection: build.mutation<
      FormSection[],
      { applicationId: string; sectionId: number; partition: string }
    >({
      query: ({ applicationId, sectionId, partition }) => ({
        url: `applications/${applicationId}/forms/sections/${sectionId}`,
        method: 'DELETE',
        body: { partition },
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'FormSections', id: applicationId },
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),

    updateAppFormSectionStatus: build.mutation<
      FormSection[],
      { applicationId: string; sectionId: number; partition: string; status: string }
    >({
      query: ({ applicationId, sectionId, partition, status }) => ({
        url: `applications/${applicationId}/forms/sections/${sectionId}/statuses/${status}`,
        method: 'PUT',
        body: { partition },
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'FormSections', id: applicationId },
        { type: 'FormSectionsHistory', id: applicationId },
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),

    getAppFormSectionsHistory: build.query<FormSectionHistory[], { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: `applications/${applicationId}/forms/sections/history`,
        method: 'GET',
      }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'FormSectionsHistory', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetAppFormSectionsQuery,
  useAddAppFormSectionMutation,
  useDeleteAppFormSectionMutation,

  useUpdateAppFormSectionStatusMutation,

  useGetAppFormSectionsHistoryQuery,
} = applicationFormSectionsApi;
