export const riskLevels = [
  { value: 'PASS', label: 'Pass', color: 'success' },
  { value: 'LOWRISK', label: 'Low Risk', color: 'success' },
  { value: 'MEDIUMRISK', label: 'Medium Risk', color: 'warning' },
  { value: 'HIGHRISK', label: 'High Risk', color: 'error' },
  { value: 'MODERATION', label: 'Moderation', color: 'error' },
  { value: 'REJECT', label: 'Reject', color: 'error' },
];

export const riskLevelsCases = [
  { value: 'LOWRISK', label: 'Low Risk', color: 'success' },
  { value: 'MEDIUMRISK', label: 'Medium Risk', color: 'warning' },
  { value: 'HIGHRISK', label: 'High Risk', color: 'error' },
  { value: 'MODERATION', label: 'Moderation', color: 'error' },
];
