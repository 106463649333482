import React from 'react';
import './ErrorMessage.scss';

interface ErrorMessageProps {
  children: any;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => (
  <div className="ErrorMessage">{children}</div>
);

export default ErrorMessage;
