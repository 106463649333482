import { grey } from '../palette-blocks';

export const FormControlLabel = {
  defaultProps: {},
  styleOverrides: {
    root: {
      margin: 0,
      '&.Mui-focused': {
        color: grey[700],
      },
      '&.Mui-disabled': {
        '&:hover': 'none',
        // color: grey[300],
      },
    },
    label: {
      margin: 0,
      '&.MuiTypography-root': {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        '&.Mui-disabled': {
          color: grey[400],
        },
      },
    },
  },
};
