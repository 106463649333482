import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import {
  useUpdateRiskCountryMutation,
  useUpdateEeaRiskCountryMutation,
} from '../../services/gatewayApi/countryRiskApi';
import { riskStatusTypes } from './constants';
import { CountryRiskFormDialogStatus } from './CountryRiskFormDialogStatus';

export const CountryRiskFormDialog = ({
  open,
  onClose,
  data: {
    countryCode,
    riskStatus,
    eeaRiskStatus,
    isProhibited,
    isRestricted,
  },
}) => {
  const [updateRiskCountry] = useUpdateRiskCountryMutation();
  const [updateEeaRiskCountry] = useUpdateEeaRiskCountryMutation();

  const updateRisk = {
    riskStatus: updateRiskCountry,
    eeaRiskStatus: updateEeaRiskCountry,
  };

  const formik = useFormik({
    initialValues: {
      countryCode,
      riskStatus,
      eeaRiskStatus,
      isProhibited,
      isRestricted,
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const mutationPromise = await Promise.all(
        riskStatusTypes.map((riskType) =>
          formik.values[riskType.value] !== formik.initialValues[riskType.value]
            ? updateRisk[riskType.value]({
                countryCode: values.countryCode,
                riskStatus: values[riskType.value],
                ...{
                  isProhibited: values.isProhibited || false,
                  isRestricted: values.isRestricted || false,
                },
              })
            : undefined,
        ),
      );
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        onClose();
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Set Risk Status
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box my={2} minWidth={450}>
          <form id="countryForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4} wrap="nowrap">
              {riskStatusTypes.map((i) => (
                <CountryRiskFormDialogStatus
                  key={i.value}
                  riskStatusType={i}
                  values={formik.values}
                  onChange={formik.handleChange}
                />
              ))}
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="countryForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CountryRiskFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    countryCode: PropTypes.string,
    riskStatus: PropTypes.string,
  }),
};
