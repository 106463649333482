import React, { useState } from 'react';
import { ModalDialog, Panel } from 'uikit';
import { Button, Box, Typography } from '@mui/material';

export const ModalDialogsSection = () => {
  const [openModalDialog, setOpenModalDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggleOpen = () => setOpenModalDialog((prev) => !prev);
  const toggleLoading = () => setIsLoading((prev) => !prev);
  const handleReset = () => {
    toggleLoading();
    setTimeout(() => {
      toggleLoading();
    }, 1000);
  };

  const ContentForDialog = () => <>{isLoading ? 'Loading...' : 'Content of modal dialog'}</>;

  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        Modal dialogs
      </Typography>
      <Panel>
        <Button color="primary" variant="contained" onClick={handleToggleOpen}>
          Show modal dialog
        </Button>
      </Panel>
      <ModalDialog
        open={openModalDialog}
        onClose={handleToggleOpen}
        title="Modal Dialog"
        handleAction={handleToggleOpen}
        actionTitle="Approve"
        isLoading={isLoading}
      >
        <ContentForDialog />
      </ModalDialog>
    </Box>
  );
};
