import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import { ModalDialog } from 'uikit';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import {
  useCreateAcquirerMutation,
  useUpdateAcquirerMutation,
} from 'services/gatewayApi/acquirersApi';
import { Acquirer } from './types';
import { initialAcquirer } from './constants';

interface AcquirerDialogProps {
  open: boolean;
  onClose: any;
  acquirer?: Acquirer;
}

export const AcquirerDialog: React.FC<AcquirerDialogProps> = ({
  open,
  onClose,
  acquirer = initialAcquirer,
}) => {
  const isNew = acquirer.id === 'new';
  const entityName = 'Acquirer';
  const title = `${isNew ? 'New' : 'Edit'} ${entityName}`;
  const actionTitle = isNew ? 'Create' : 'Save';

  const [createAcquirer] = useCreateAcquirerMutation();
  const [updateAcquirer] = useUpdateAcquirerMutation();

  const validationSchema = yup.object({
    acquirerCode: yup.string().required('Required'),
    acquirerName: yup.string().required('Required'),
  });

  const initialValues = { ...acquirer };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const mutationPromise = isNew
        ? await createAcquirer({ body: values })
        : await updateAcquirer({ body: values, id: acquirer.id });

      // @ts-ignore
      if (!mutationPromise.error) {
        isNew ? toastCreateSuccess(entityName) : toastUpdateSuccess(entityName);

        resetForm();
        onClose();
      }
    },
  });

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      handleAction={() => formik.handleSubmit()}
      actionTitle={actionTitle}
      isLoading={formik.isSubmitting}
    >
      <Stack spacing={4}>
        <TextField
          type="text"
          fullWidth
          label="Code"
          name="acquirerCode"
          onChange={formik.handleChange}
          value={formik.values.acquirerCode}
          variant="standard"
          error={formik.touched.acquirerCode && Boolean(formik.errors.acquirerCode)}
          disabled={!isNew || formik.isSubmitting}
        />

        <TextField
          type="text"
          fullWidth
          label="Name"
          name="acquirerName"
          onChange={formik.handleChange}
          value={formik.values.acquirerName}
          variant="standard"
          error={formik.touched.acquirerName && Boolean(formik.errors.acquirerName)}
          disabled={formik.isSubmitting}
        />
      </Stack>
    </ModalDialog>
  );
};
