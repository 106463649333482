import { base, grey } from '../../palette-blocks';
import { baseTheme } from '../../baseTheme';

export const ChipSquared = {
  props: {
    variant: 'squared',
  },

  style: {
    transition: baseTheme.transitions.create(['border-color', 'background-color']),
    borderRadius: 6,
    paddingRight: 4,
    // padding: '4px 8px',
    height: 28,

    backgroundColor: baseTheme.palette.common.white,
    '.MuiChip-label': {
      fontSize: 14,
    },
    '.MuiChip-deleteIcon': {
      fontSize: 'small',
      color: base.contrastText,
      '&:hover, &:active': {
        color: base.contrastText,
      },
    },
    '.MuiChip-avatar': {
      background: grey[200],
      color: base.contrastText,
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: base.hover,
      },
      '&:active': {
        background: base.active,
      },
    },
  },
};
