import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { ModalDialog } from 'uikit';

interface GroupDialogProps {
  open: boolean;
  onClose: any;
}

export const GroupDialog: React.FC<GroupDialogProps> = ({ open, onClose }) => {
  const validationSchema = yup.object({
    groupName: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: { groupName: '' },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      resetForm();
      onClose(values);
    },
  });

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="New Group"
      handleAction={() => formik.handleSubmit()}
      actionTitle="Create"
      isLoading={formik.isSubmitting}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          autoFocus
          type="text"
          fullWidth
          label="Group Name"
          name="groupName"
          onChange={formik.handleChange}
          value={formik.values.groupName}
          variant="standard"
          error={formik.touched.groupName && Boolean(formik.errors.groupName)}
          helperText={formik.touched.groupName && formik.errors.groupName}
          disabled={formik.isSubmitting}
        />
      </form>
    </ModalDialog>
  );
};
