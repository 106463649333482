import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isDate, getYear } from 'date-fns';
import { debounce } from 'lodash';
import DOMPurify from 'isomorphic-dompurify';
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
  Checkbox,
} from '@mui/material';
import { RiAddCircleFill } from 'react-icons/ri';
import { NumericFormat } from 'react-number-format';

import { FormTextField, FormSelect, FormDatePicker, FormRadio, FormMultiSelect } from 'uikit';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import {
  useGetDictionariesListQuery,
  useGetDictionaryQuery,
} from 'services/gatewayApi/dictionaryApi';
import { useGetSelectorsQuery } from 'services/gatewayApi/selectorsApi';
import { useUpdateApplicationFormRecordMutation } from 'services/gatewayApi/applicationFormRecordsApi';
import './DetailsField.scss';
import { testEmail } from 'utils';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { ValueLabel } from '../../types';
import { grey } from '../../theme/palette-blocks';
import { formatFormDate, formatIsoDate } from '../../utils/formatter';
import { DATE_FORMAT } from '../../constants/constants';

interface DetailsFieldProps {
  id: string;
  applicationId?: string;
  label: string;
  value: string;
  fieldType?: string;
  record?: any;
  formId: string;
  disabled?: boolean;
  updateOnChange?: boolean;
  onBeforeSave?: () => void;
  onAfterSave?: () => void;
}

const DetailsField: React.FC<DetailsFieldProps> = ({
  id,
  applicationId,
  label,
  value,
  fieldType = 'default',
  record,
  formId,
  disabled = false,
  updateOnChange = false,
  onBeforeSave,
  onAfterSave,
}) => {
  // #region Options data for Select fields
  const emptyOption = { label: 'Not provided', value: '' };
  const [selectOptions, setSelectOptions] = useState(
    fieldType === 'multi-selector' ? [] : [emptyOption],
  );
  const isRadio = fieldType === 'radio';
  const isSelector = fieldType === 'selector' || fieldType === 'multi-selector' || isRadio;
  const selectorId = record?.field?.selectorId || undefined;
  const isFinancial = record?.field?.displayFormat === 'financial';
  const theme = useTheme();

  // Get available selectors dictionaries
  const { data: dictionaries, isLoading: isDictionariesLoading } = useGetDictionariesListQuery(
    undefined,
    { skip: !isSelector },
  );

  // Get all selectors data
  const { data: selectors, isLoading: isSelectorsLoading } = useGetSelectorsQuery(undefined, {
    skip: !isSelector,
  });

  // get countries
  const { data: countries, isLoading: isCountriesLoading } = useGetAllCountriesQuery(undefined, {
    skip: !isSelector || selectorId !== 'country' || selectorId === undefined,
  });

  // Load options from dictionary
  const { data: options } = useGetDictionaryQuery(selectorId, {
    skip:
      !isSelector ||
      selectorId === 'country' ||
      selectorId === undefined ||
      isDictionariesLoading ||
      (dictionaries && !dictionaries.includes(selectorId)),
  });

  useEffect(() => {
    if (
      isSelector &&
      selectorId === 'country' &&
      !isCountriesLoading &&
      countries &&
      countries.length
    ) {
      const list = [
        emptyOption,
        ...countries.map((c) => ({
          value: c.countryCode,
          label: c.name,
        })),
      ];
      setSelectOptions(list);
    }
  }, [countries, isCountriesLoading]);

  useEffect(() => {
    if (isSelector && selectorId !== undefined && selectorId !== 'country' && !!options) {
      const newOptions =
        isRadio || fieldType === 'multi-selector' ? options : [emptyOption, ...options];
      setSelectOptions(newOptions as any[]);
    }
  }, [options]);

  useEffect(() => {
    if (
      selectorId !== undefined &&
      selectors !== undefined &&
      selectorId !== 'country' &&
      isSelector &&
      !isSelectorsLoading &&
      selectors &&
      selectors.length
    ) {
      const selectorData = selectors.find((s) => s.selectorId === selectorId);

      if (selectorData && selectorData.data) {
        const selectOptions = JSON.parse(selectorData.data);
        const otherOptions = selectOptions.map((o) => ({
          value: o[selectorData.keyField],
          label: o[selectorData.valField],
        }));
        const list =
          isRadio || fieldType === 'multi-selector' ? otherOptions : [emptyOption, ...otherOptions];

        setSelectOptions(list);
      }
    }
  }, [selectors, isSelectorsLoading]);

  if (isSelector && selectorId === undefined) {
    console.warn('Undefined selectorId for ', record);
  }
  // #endregion

  const [data, setData] = useState(
    fieldType === 'datetime' ? formatIsoDate(formatFormDate(value), DATE_FORMAT) : value,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when updating with delay

  const handleChange = (valueToChange, delay = false) => {
    setSkipRequest(delay);

    if (delay) {
      debounceUpdateRecord();
    }

    if (fieldType === 'email') {
      setHasError(valueToChange.length > 0 && !testEmail(valueToChange));
    }

    let stringDate;

    if (fieldType === 'datetime') {
      const newDateValue = formatFormDate(valueToChange);

      let check = false;

      if (
        newDateValue !== null &&
        (!isDate(newDateValue) || // is not date
          newDateValue?.toString() === 'Invalid Date' || // is invalid date
          (isDate(newDateValue) && getYear(newDateValue) < 1900)) // is date and is bigger than 1900
      ) {
        check = true;
      }

      setHasError(check);

      stringDate = formatIsoDate(newDateValue, DATE_FORMAT);
    }

    setData(fieldType === 'datetime' ? stringDate : valueToChange);
  };
  const handleChangeWithDelay = (valueChangeWithDelay) => handleChange(valueChangeWithDelay, true);

  const [updateRecord] = useUpdateApplicationFormRecordMutation();
  const handleUpdateRecord = () => {
    if (!hasError && !isSaving && updateOnChange) {
      setIsSaving(true);
      onBeforeSave && onBeforeSave();
      let finData;
      if (isFinancial && data) {
        finData = data?.replace(/,/g, '');
      }

      updateRecord({ ...record, applicationId, formId, value: isFinancial ? finData : data })
        .unwrap()
        .catch((rejected) => {
          console.error(rejected);
        })
        .finally(() => {
          setIsSaving(false);
          onAfterSave && onAfterSave();
        });
    }
  };

  // Server update after a period of time
  const debounceUpdateRecord = useCallback(
    debounce(() => setSkipRequest(false), 1000),
    [],
  );

  useEffect(() => {
    let hasChanges = value != data && !(typeof value === 'undefined' && data === '');

    try {
      if (fieldType === 'datetime') {
        const oldValue = value;
        const newValue = data;
        if (isDate(formatFormDate(oldValue)) && isDate(formatFormDate(newValue))) {
          hasChanges = oldValue !== newValue;
        }
      }
    } catch {
      /* ignore errors */
    }

    if (!skipRequest && hasChanges) {
      handleUpdateRecord();
    }
  }, [skipRequest, value, data]);

  const csvSeparator = ';';

  // #region Array field helpers
  const inputRef = useRef<HTMLInputElement>(null);
  const focusInput = () => inputRef.current?.focus();

  const [keyword, setKeyword] = useState<string>('');
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const isInValueList = (kwrd) =>
    value && value.indexOf(csvSeparator) >= 0
      ? value.split(csvSeparator).includes(kwrd)
      : value === kwrd;

  const handleAddKeyword = (optionKeyword) => {
    const previousKeywords = `${value}`.split(csvSeparator);

    options && setIsListOpen(false);

    const kywrd = optionKeyword ?? keyword;

    if (kywrd.length > 0 && !isInValueList(kywrd)) {
      const newValue = value ? [...previousKeywords, kywrd].join(csvSeparator) : kywrd;
      handleChange(newValue);
      setKeyword('');
    }
  };

  const handleKeyDownKeyword = (e) => {
    if (['Enter', 'Tab', csvSeparator].includes(e.key)) {
      e.preventDefault();
      handleAddKeyword(keyword);
    }
  };

  const handleDeleteKeyword = (kywrd) => {
    const newValue = value
      ?.split(csvSeparator)
      .filter((item) => item !== kywrd)
      .join(csvSeparator);
    handleChange(newValue);
    focusInput();
  };

  const handleKeywordBlur = (e) => {
    if (!e.relatedTarget?.classList?.contains('MuiListItemButton-root')) {
      setIsListOpen(false);
    }
  };

  const optionsFiltered = () =>
    options.filter(
      (item) =>
        !value?.split(csvSeparator).includes(item.value.toString().toLowerCase()) &&
        item.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0,
    );
  // #endregion

  const baseClassNames =
    `DetailsFieldWrapper` +
    `${disabled ? ` DetailsFieldReadonly` : ''}` +
    `${isSaving ? ` DetailsFieldIsSaving` : ''}`;

  return (
    <div id={`DetailsField_${id}`} className={`${baseClassNames} ${fieldType}Field`}>
      {(() => {
        switch (fieldType) {
          case 'checkbox':
            return (
              <Box display="flex" alignItems="flex-start" py={2}>
                <Checkbox
                  name={`DetailsField_${id}`}
                  checked={data === 'true'}
                  onChange={(e, checked) => handleChange(checked.toString())}
                  disabled={disabled || isSaving}
                />
                <p
                  style={{
                    margin: 0,
                    lineHeight: '20px',
                    color: disabled ? theme.palette.text.disabled : 'inherit',
                  }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
                />
              </Box>
            );

          case 'radio':
            return (
              <FormRadio
                options={selectOptions}
                value={data || ''}
                label={label}
                error={hasError}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value)}
              />
            );

          case 'datetime':
            return (
              <FormDatePicker
                label={label}
                disabled={disabled}
                error={!disabled && hasError}
                value={data}
                onChange={(valueDateOnChange) => handleChangeWithDelay(valueDateOnChange)}
                onAccept={(valueDateAccept) => handleChange(valueDateAccept)}
              />
            );

          case 'description': {
            const cleanHtml = DOMPurify.sanitize(label);
            return (
              <p
                style={{ color: disabled ? theme.palette.text.disabled : 'inherit' }}
                dangerouslySetInnerHTML={{ __html: cleanHtml }}
              />
            );
          }

          case 'selector':
            return (
              <FormSelect
                value={selectOptions.find((c) => c.value === data)?.value || ''}
                onChange={(e) => handleChange(e.target.value)}
                options={selectOptions}
                label={label}
                name={`DetailsField_${id}`}
                disabled={disabled}
              />
            );

          case 'multi-selector': {
            return (
              <FormMultiSelect
                name={`DetailsField_${id}`}
                label={label}
                disabled={disabled}
                error={!disabled && hasError}
                options={selectOptions}
                placeholder="Select options"
                values={
                  selectOptions.filter((option: ValueLabel) =>
                    data?.split(csvSeparator).some((v) => option.value === v),
                  ) || []
                }
                onChange={(event, newValue: ValueLabel[]) => {
                  handleChange(
                    newValue.map((nextValue: ValueLabel) => nextValue.value).join(csvSeparator),
                  );
                }}
              />
            );
          }

          case 'subtitle':
            return (
              <h3 style={{ color: disabled ? theme.palette.text.disabled : 'inherit' }}>{label}</h3>
            );

          case 'note':
            return (
              <p
                style={{
                  margin: 0,
                  lineHeight: '18px',
                  paddingTop: '2px',
                  color: disabled ? theme.palette.text.disabled : grey[500],
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
              />
            );

          case 'multi-text':
            return (
              <>
                <Typography
                  variant="body1"
                  pb={2}
                  color={disabled ? 'text.disabled' : 'text.primary'}
                >
                  {label}
                </Typography>
                <Box
                  className="ChipInput"
                  onClick={focusInput}
                  sx={{
                    px: 3,
                    py: 1,
                    backgroundColor: disabled ? 'grey.50' : 'grey.100',
                    borderRadius: '12px',
                  }}
                >
                  <Box>
                    {value &&
                      value?.length > 0 &&
                      value
                        ?.split(csvSeparator)
                        .map((kwrd) => (
                          <Chip
                            key={kwrd}
                            label={
                              options ? options.find((item) => item.value === kwrd).label : kwrd
                            }
                            variant="outlined"
                            onDelete={!disabled ? () => handleDeleteKeyword(kwrd) : null}
                            sx={disabled ? { color: 'grey.500' } : {}}
                          />
                        ))}
                  </Box>
                  <Stack
                    className="ChipInputContent"
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={2}
                    sx={{ position: 'relative', color: disabled ? 'grey.500' : 'inherit' }}
                  >
                    <RiAddCircleFill fontSize="18px" />
                    <InputBase
                      inputRef={inputRef}
                      name="keyword"
                      sx={{ flex: 1, '& .MuiInputBase-input': { lineHeight: '20px' } }}
                      placeholder="Not provided"
                      inputProps={{ 'aria-label': 'form text field' }}
                      disabled={disabled}
                      onClick={options ? () => setIsListOpen(true) : null}
                      onBlur={options ? handleKeywordBlur : handleAddKeyword}
                      onChange={(e) => setKeyword(e.target.value.trim())}
                      onKeyDown={handleKeyDownKeyword}
                      value={keyword}
                    />
                    {isListOpen && options && optionsFiltered().length > 0 && (
                      <Box
                        sx={{
                          width: '100%',
                          maxWidth: 260,
                          position: 'absolute',
                          left: '14px',
                          top: '25px',
                          zIndex: 1,
                        }}
                      >
                        <Paper elevation={3}>
                          <List dense>
                            {optionsFiltered().map((o) => (
                              <ListItem key={`option_${o.value}`} sx={{ p: 0 }}>
                                <ListItemButton onClick={() => handleAddKeyword(o.value)}>
                                  <ListItemText
                                    primary={o.label}
                                    sx={{ '.MuiTypography-root': { fontSize: '13px' } }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </>
            );

          case 'text':
          case 'textarea':
          case 'email':
            return isFinancial ? (
              <NumericFormat
                thousandSeparator=","
                decimalScale={2}
                customInput={FormTextField}
                allowNegative={false}
                fullWidth
                name={`DetailsField_${id}`}
                label={label}
                value={data || ''}
                placeholder="Not provided"
                onChange={(e) => handleChangeWithDelay(e.target.value)}
                onBlur={(e) => handleChange(e.target.value)}
                disabled={disabled}
                inputProps={{ readOnly: isSaving }}
                error={!disabled && hasError}
              />
            ) : (
              <FormTextField
                fullWidth
                name={`DetailsField_${id}`}
                label={label}
                type={fieldType}
                multiline={fieldType === 'textarea'}
                value={data || ''}
                placeholder="Not provided"
                onChange={(e) => handleChangeWithDelay(e.target.value)}
                onBlur={(e) => handleChange(e.target.value)}
                disabled={disabled}
                inputProps={{ readOnly: isSaving }}
                error={!disabled && hasError}
              />
            );
        }
      })()}
    </div>
  );
};

export default DetailsField;
