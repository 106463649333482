/*
// TODO: Make the whole component generic.
*/

import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button } from '@mui/material';
import { useOnClickOutside } from 'hooks';
import { Checkbox } from 'uikit/Checkbox';
import { ISelectOption, Select } from '../../Select';
import { EmbedDropdown } from '../../EmbedDropdown';
import { FormRow, Input } from '../../Input';
import { IPageControlFilterProps, IFilterValue } from './PageControlFilterTypes';

import './PageControlFilter.scss';

const PageControlFilter: React.FC<IPageControlFilterProps> = ({
  filterScheme,
  filterValue,
  filterHandler,
  options,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const refFilter = useRef(null);

  const filterCount = Object.keys(filterValue).filter((key) => {
    if (filterValue[key] !== '' && filterValue[key] !== false) return true;
  }).length;

  const initialValues = filterValue;

  useEffect(() => {
    // TODO: better update formik state
    Object.keys(filterValue).forEach((filterName) =>
      formik.setFieldValue(filterName, filterValue[filterName]),
    );
  }, [filterValue]);

  useOnClickOutside([refFilter], () => setShowFilter(false), true);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setShowFilter(false);
        setSubmitting(false);
        filterHandler(formik.values);
      }, 1000);
    },
  });

  const resetFilter = () => {
    const clearedFilter = {};
    Object.keys(filterValue).forEach((key) => (clearedFilter[key] = ''));
    filterHandler(clearedFilter as IFilterValue);
    formik.resetForm();
    setShowFilter(false);
  };

  return (
    <div className="PageControlFilter" ref={refFilter}>
      <Button
        variant={filterCount ? 'contained' : 'text'}
        color="secondary"
        onClick={() => setShowFilter(!showFilter)}
      >
        Filter {!!filterCount && `(${filterCount})`}
      </Button>

      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <EmbedDropdown
          align="right"
          isShowing={showFilter}
          hide={() => setShowFilter(false)}
          style={{ width: '300px', borderRadius: '12px' }}
        >
          <div className="filterContent">
            {filterScheme.map(({ field, name, label }, i) => {
              switch (field) {
                case 'select':
                  return (
                    <FormRow key={i}>
                      <Select
                        placeholder={label}
                        name={name}
                        disabled={formik.isSubmitting}
                        onChange={(
                          option: ISelectOption,
                          _event:
                            | React.KeyboardEvent<HTMLInputElement>
                            | React.MouseEvent<HTMLDivElement>,
                        ) => {
                          formik.setFieldValue(name, option ? option.value : '');
                        }}
                        value={
                          formik.values[name]
                            ? options[name].find((c) => c.value === formik.values[name])
                            : null
                        }
                        options={options[name]}
                      />
                    </FormRow>
                  );

                // TODO: do not forget about number, email, etc...
                case 'text':
                  return (
                    <FormRow key={i}>
                      <Input
                        placeholder={label}
                        name={name}
                        disabled={formik.isSubmitting}
                        onChange={formik.handleChange}
                        value={formik.values[name] as string}
                        handleClear={() => formik.setFieldValue(name, '')}
                      />
                    </FormRow>
                  );

                case 'checkbox':
                  return (
                    <FormRow key={i}>
                      <Checkbox
                        onChange={(checked: boolean) => formik.setFieldValue(name, checked)}
                        disabled={formik.isSubmitting}
                        checked={formik.values[name] as boolean}
                      >
                        Is true?
                      </Checkbox>
                    </FormRow>
                  );

                default:
                  console.error(`Undefined filter field type "${field}"`);
                  return null;
              }
            })}

            <div className="filterButtons">
              <Button
                type="button"
                onClick={resetFilter}
                color="error"
                variant="text"
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Reset
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={formik.isSubmitting}
                sx={{ ml: 2 }}
              >
                Apply
              </Button>
            </div>
          </div>
        </EmbedDropdown>
      </form>
    </div>
  );
};

export default PageControlFilter;
