import React, { useState } from 'react';
import { unstable_usePrompt, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { HeadLink } from 'muikit';

import {
  useUpdateApplicationClientIdsMutation,
  useUpdateApplicationPartialMutation,
  useGetCategoriesQuery,
  useGetApplicationsQuery,
} from 'services/gatewayApi/applicationApi';
import {
  useUpdateHaystackClientPartialMutation,
  useGetHaystackClientQuery,
} from 'services/gatewayApi/haystackClientApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';

import { FormRowInput } from '../../../FormRowInput';
import {
  sections,
  initialValues,
  editableOnOpenStatus,
  editAppFieldsSchema,
  editAppValidationSchema,
  industries,
  solutions,
  legalEntityTypes,
} from './constants';

const getObjectKeys = (object, keys, excludeKeys = false) =>
  object
    ? Object.keys(object)
        .filter((key) => (excludeKeys ? !keys.includes(key) : keys.includes(key)))
        .reduce((obj, key) => {
          obj[key] = object[key];
          return obj;
        }, {})
    : {};

export const OverviewTab = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { data: client, isLoading: isLoadingClient } = useGetHaystackClientQuery(clientId, {
    skip: !clientId,
  });

  const { data: applications, isLoading: isLoadingApplications } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
    },
    {
      skip: !clientId,
    },
  );

  const applicationDetails = applications?.content?.find((el) => el.kind === 'ONBOARDING');

  const uniqueCategories = [
    ...new Set(applications?.content?.map((application) => application.categoryId)),
  ];
  const bothCategories = uniqueCategories.length > 1;

  const { id: applicationId = undefined } = applicationDetails || {};

  const { data: countries } = useGetAllCountriesQuery(undefined);
  const countriesValueLabel = countries
    ? countries.map((c) => ({ value: c.countryCode, label: c.name }))
    : [];

  const { data: usersList = [] } = useGetListUsersQuery(undefined);
  const usersValueLabel = usersList.map((u) => ({ value: u.id, label: u.name }));

  const { data: categories = [] } = useGetCategoriesQuery(undefined);

  const selectFieldOptions = {
    industries,
    solutions,
    categories: bothCategories ? [{ value: 'Both', label: 'Both' }] : categories,
    countries: countriesValueLabel,
    users: usersValueLabel,
    legalEntityTypes,
  };

  const fields = editAppFieldsSchema({ options: selectFieldOptions });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateApplicationClientIds] = useUpdateApplicationClientIdsMutation();
  const [patchApplication] = useUpdateApplicationPartialMutation();
  const [patchClient] = useUpdateHaystackClientPartialMutation();

  const validation = editAppValidationSchema();

  const csvSeparator = ';';

  const clientIdsToCsv = (arr) =>
    arr
      .filter((item) => !item.isReadOnly)
      .map((item) => item.clientId)
      .join(csvSeparator);

  // Keys to get/patch for onboarding application
  const keysOnboardingApplication = ['isCommercialAgreementSigned'];

  // Keys to patch for every applicaiton (for future uses)
  const keysAllApplications = [
    'externalId', // overwritten separately
  ];

  // Keys to exclude when updating haystack client
  const keysToExcludeForClient = [
    'applicationId', // id of the onboarding applicaion from url param
    'externalId',
    'openpaydClientId', // saved separately
    'isCommercialAgreementSigned',
  ];

  const valuesFromApplication = applicationDetails
    ? {
        applicationId,
        ...getObjectKeys(applicationDetails, keysOnboardingApplication),
        ...Object.fromEntries(
          applications?.content?.map((application) => [
            `openpaydClientId_${application.id}`,
            application?.openpaydClientId ? clientIdsToCsv(application?.openpaydClientId) : '',
          ]),
        ),
        ...Object.fromEntries(
          applications?.content?.map((application) => [
            `externalId_${application.id}`,
            application?.externalId,
          ]),
        ),
      }
    : {};

  const formik = useFormik({
    initialValues:
      isLoadingApplications || isLoadingClient
        ? initialValues
        : { ...initialValues, ...valuesFromApplication, ...client },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (values, { setSubmitting }) => {
      setIsSaving(true);

      applications?.content?.map((application) => {
        updateApplicationClientIds({
          body: { clientIds: values[`openpaydClientId_${application.id}`]?.split(csvSeparator) },
          applicationId: application.id,
        });

        const appData = getObjectKeys(values, keysAllApplications);
        patchApplication({
          ...appData,
          externalId: values[`externalId_${application.id}`],
          id: application.id,
        });
      });

      const onboardingAppData = getObjectKeys(values, keysOnboardingApplication);
      patchApplication({ ...onboardingAppData, id: applicationId })
        .unwrap()
        .then(() => {
          const clientData = getObjectKeys(values, keysToExcludeForClient, true);
          patchClient(clientData)
            .unwrap()
            .then(() => {
              setSubmitting(false);
              setIsSaving(false);
              setIsEditing(false);
            })
            .catch((rejected) => console.error(rejected));
        });
    },
  });

  const handleSubmitForm = () => {
    formik.handleSubmit();

    if (!formik.isValid) {
      const errorKeys = Object.keys(formik.errors);
      const input = document.querySelector(`input[name=${errorKeys[0]}]`);
      input.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const actions = [
    ...(isEditing
      ? [
          <Button
            key="Btn_Cancel"
            disabled={isSaving}
            onClick={(e) => {
              e.stopPropagation();
              formik.resetForm();
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>,
        ]
      : []),
    <Button
      key="Btn_EditSave"
      color="primary"
      variant="contained"
      disabled={isSaving}
      onClick={(e) => {
        e.stopPropagation();
        isEditing ? handleSubmitForm() : setIsEditing(true);
      }}
      startIcon={isSaving ? <CircularProgress color="inherit" size={14} /> : null}
    >
      {isEditing ? 'Save Changes' : 'Edit'}
    </Button>,
  ];

  unstable_usePrompt({
    message: 'You have unsaved changes on the page. Are you sure you want to navigate?',
    when: ({ currentLocation, nextLocation }) =>
      !!formik.dirty && currentLocation.pathname !== nextLocation.pathname,
  });

  return (
    <Box className="OverviewTab">
      <HeadLink title="Overview" actions={actions} />

      <Box sx={{ mt: 4, pt: 4 }}>
        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
          {sections.map((section) => (
            <Box key={`section_${section.id}`} sx={{ pb: 6 }}>
              <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[100]}` })}>
                <Typography variant="h2" color="text.secondary" sx={{ pb: 4, fontSize: '18px' }}>
                  {section.title}
                </Typography>
              </Box>

              {fields
                .filter((field) => field.sectionId === section.id)
                .map((field) => {
                  // Hide field regulatedByLink if not regulated
                  if (field.name === 'regulatedByLink' && !!formik.values.regulated !== true) {
                    return false;
                  }

                  const editable =
                    field.editable ||
                    (editableOnOpenStatus.includes(field.name) &&
                      applicationDetails?.mainStatus === 'OPEN');

                  // Show fields per application
                  if (['externalId', 'openpaydClientId'].includes(field.name)) {
                    return applications?.content?.map((el) => (
                      <FormRowInput
                        key={`row_${field.name}_${el.id}`}
                        fieldData={{
                          ...field,
                          name: `${field.name}_${el.id}`,
                          label: field.label.replace(
                            '{country}',
                            el.categoryId === 5 ? 'Malta' : 'UK',
                          ),
                          editable, // This might need to be assigned for each related application, not ONBOARDING one
                        }}
                        onChange={formik.handleChange}
                        value={formik.values[`${field.name}_${el.id}`]}
                        error={
                          formik.touched[`${field.name}_${el.id}`] &&
                          formik.errors[`${field.name}_${el.id}`]
                        }
                        disabled={!isEditing || isSaving}
                      />
                    ));
                  }

                  return (
                    <FormRowInput
                      key={`row_${field.name}`}
                      fieldData={{ ...field, editable }}
                      onChange={formik.handleChange}
                      value={
                        bothCategories && field.name === 'categoryId'
                          ? 'Both' // just inform user, not value
                          : formik.values[field.name]
                      }
                      error={formik.touched[field.name] && formik.errors[field.name]}
                      disabled={!isEditing || isSaving}
                    />
                  );
                })}
            </Box>
          ))}
        </form>
      </Box>
    </Box>
  );
};
