import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isDescendant } from './utils/tree-data-utils';
import {
  addNewRuleToRuleSet,
  cloneRuleFromRuleSet,
  deleteRuleFromRuleSet,
  selectRule,
  //fetchRuleVetos,
} from '../../actions/ruleBuilder';

const MyRenderer = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style = {},
  didDrop,
  addNewRuleToRuleSet,
  deleteRuleFromRuleSet,
  cloneRuleFromRuleSet,
  selectRule,
  // fetchRuleVetos,
  // isOver: _isOver,     // Not needed, but preserved for other renderers
  // parentNode: _parentNode, // Needed for drag-and-drop utils
  // endDrag: _endDrag,    // Needed for drag-and-drop utils
  // startDrag: _startDrag,  // Needed for drag-and-drop utils
  // addNewRuleToTree: addNewRuleToTree,
  // deleteRuleFromTree: deleteRuleFromTree,
  // ...otherProps,
}) => {
  function handleAddRuleClick() {
    addNewRuleToRuleSet(node.id);
  }

  function handleDeleteRuleClick() {
    deleteRuleFromRuleSet(node.id);
  }

  function handleSelectRuleClick() {
    selectRule(node.id);
  }

  function handleCloneRuleClick() {
    cloneRuleFromRuleSet(node.id);
  }

  // function handleExceptionRuleClick() {
  //   fetchRuleVetos(node.id, 'CUSTOMER_EMAIL');
  //   fetchRuleVetos(node.id, 'MERCHANT_ID');
  //   selectRule(node.id);
  // }

  let handle;
  if (canDrag) {
    if (typeof node.children === 'function' && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      // and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="loadingHandle">
          <div className="loadingCircle">
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
            <div className="loadingCirclePoint" />
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-drop
      handle = connectDragSource(<div className="moveHandle" />, {
        dropEffect: 'copy',
      });
    }
  }

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;

  return (
    <div style={{ height: '100%' }}>
      {toggleChildrenVisibility && node.children && node.children.length > 0 && (
        <div>
          <button
            type="button"
            aria-label={node.expanded ? 'Collapse' : 'Expand'}
            className={node.expanded ? 'collapseButton' : 'expandButton'}
            style={{ left: -0.5 * scaffoldBlockPxWidth }}
            onClick={() => toggleChildrenVisibility({ node, path, treeIndex })}
          />

          {node.expanded && !isDragging && (
            <div style={{ width: scaffoldBlockPxWidth }} className="lineChildren" />
          )}
        </div>
      )}

      <div className="rowWrapper">
        {/* Set the row preview to be used during drag and drop */}

        <a onClick={() => handleDeleteRuleClick()} className="deleteRule">
          <i className="fa fa-minus-circle" />
        </a>
        {/*  TODO temporaly removed adding child rules directly in tree, need to refactoring */}
        {/* <a onClick={() => handleAddRuleClick()} className="addChildRule"> */}
        {/*  <i className="fa fa-plus-circle" /> */}
        {/* </a> */}
        {connectDragPreview(
          <div
            className={`row2
                  ${isLandingPadActive ? ' rowLandingPad' : ''}
                  ${isLandingPadActive && !canDrop ? ' rowCancelPad' : ''}
                  ${isSearchMatch ? ' rowSearchMatch' : ''}
                  ${isSearchFocus ? ' rowSearchFocus' : ''}
                  ${className}`}
            style={{
              opacity: isDraggedDescendant ? 0.5 : 1,
              ...style,
            }}
          >
            {handle}
            <div
              // onClick={this.handleSelectRuleClick}
              className={!canDrag ? 'rowContents rowContentsDragDisabled' : 'rowContents'}
            >
              <div className="rowLabel">
                <span className={node.subtitle ? ' rowTitleWithSubtitle rowTitle' : 'rowTitle'}>
                  {typeof node.title === 'function'
                    ? node.title({ node, path, treeIndex })
                    : node.title}
                </span>

                {node.subtitle && (
                  <span className="rowSubtitle">
                    {typeof node.subtitle === 'function'
                      ? node.subtitle({ node, path, treeIndex })
                      : node.subtitle}
                  </span>
                )}
              </div>
              <div className="node-buttons">
                <a className="text-success" onClick={() => handleSelectRuleClick()} title="Edit">
                  <i className="fa fa-edit icon-sm" />
                </a>
                <a className="text-success" onClick={() => handleCloneRuleClick()} title="Clone">
                  <i className="far fa-clone icon-sm" />
                </a>
                {/* <a */}
                {/*  className="text-exception" */}
                {/*  onClick={() => handleExceptionRuleClick()} */}
                {/*  title="Exception" */}
                {/* > */}
                {/*  <i className="fas fa-times-circle icon-sm" /> */}
                {/* </a> */}
              </div>
              <div className="rowToolbar">
                {buttons &&
                  buttons.map((btn, index) => (
                    <div key={index} className="toolbarButton">
                      {btn}
                    </div>
                  ))}
              </div>
            </div>
          </div>,
        )}
      </div>
    </div>
  );
};

MyRenderer.propTypes = {
  node: PropTypes.object.isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  treeIndex: PropTypes.number.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,

  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.object,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  // parentNode: PropTypes.object,          // Needed for drag-and-drop utils
  // startDrag: PropTypes.func.isRequired, // Needed for drag-and-drop utils
  // endDrag: PropTypes.func.isRequired, // Needed for drag-and-drop utils
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.object,
  // Drop target
  // isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,
  addNewRuleToRuleSet: PropTypes.func,
  deleteRuleFromRuleSet: PropTypes.func,
  cloneRuleFromRuleSet: PropTypes.func,
  selectRule: PropTypes.func,
  // fetchRuleVetos: PropTypes.func,
};

export function mapDispatchToProps(dispatch) {
  return {
    addNewRuleToRuleSet: (eventNodeId) => dispatch(addNewRuleToRuleSet(eventNodeId)),
    deleteRuleFromRuleSet: (eventNodeId) => dispatch(deleteRuleFromRuleSet(eventNodeId)),
    cloneRuleFromRuleSet: (eventNodeId) => dispatch(cloneRuleFromRuleSet(eventNodeId)),
    selectRule: (eventNodeId) => dispatch(selectRule(eventNodeId)),
    // fetchRuleVetos: (ruleId, ruleVetoType) => dispatch(fetchRuleVetos(ruleId, ruleVetoType)),
  };
}

export default connect(null, mapDispatchToProps)(MyRenderer);
