import React from 'react';
import { BodyRow, BodyTableCell } from 'uikit';
import { IconButton, Typography } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { Acquirer } from './types';

interface AcquirerRowProps {
  item: Acquirer;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
}

export const AcquirerRow: React.FC<AcquirerRowProps> = ({
  item,
  setSelectedItemId,
  setOpenDetailsDrawer,
  selected = false,
}) => {
  const { acquirerCode, acquirerName, createdByName, createdDate, responseCode } = item;

  const renderResponseCodes = () => {
    const codes = responseCode
      ?.slice()
      .sort((a, b) => (a.responseCode > b.responseCode ? 1 : -1))
      .map((r) => r.responseCode);
    let result = codes.join(', ');
    let additional = '-';

    if (result.length) {
      additional = '';

      if (result.length > 20) {
        const itemsCount2Display = (result.substring(0, 20).match(/,/g) || []).length;
        const items2Display = codes.slice(0, itemsCount2Display);

        if (codes.length - itemsCount2Display > 0) {
          result = `${items2Display.join(', ')} ...`;
          additional = ` +${codes.length - itemsCount2Display}`;
        }
      }
    }

    return (
      <>
        {result}
        {additional && (
          <Typography variant="body1" component="span" color="grey.500">
            {additional}
          </Typography>
        )}
      </>
    );
  };

  return (
    <BodyRow
      hover
      selected={selected}
      onClick={() => {
        setSelectedItemId(item.id);
        setOpenDetailsDrawer(true);
      }}
      sx={!selected ? { cursor: 'pointer' } : {}}
    >
      <BodyTableCell>{acquirerCode}</BodyTableCell>
      <BodyTableCell>{acquirerName}</BodyTableCell>
      <BodyTableCell>{createdByName}</BodyTableCell>
      <BodyTableCell>{formatIsoDate(createdDate, DATE_FORMAT_MESSAGES)}</BodyTableCell>
      <BodyTableCell>{renderResponseCodes()}</BodyTableCell>
      <BodyTableCell className="displayOnHover">
        <IconButton color="inherit" size="small">
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </BodyTableCell>
    </BodyRow>
  );
};
