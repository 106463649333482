import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { history } from 'store';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { RiArrowRightUpFill } from 'react-icons/ri';
import {
  useGetCaseAssignmentsQuery,
  useGetCaseQuery,
  useUpdateCaseMutation,
} from 'services/gatewayApi/casesApi';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import { useGetApplicationQuery } from 'services/gatewayApi/applicationApi';
import { useGetTransactionsQuery } from 'services/internalApi';
import CaseComments from './CaseComments';
import TransactionsList from '../../containers/Transactions/transactionsList';
import { MUIDatatableSkeleton } from '../../components';
import { CaseType } from './types';
import { defaultCaseStatuses } from './constants';
import { checkProductName } from 'utils';

export const CaseDetails = () => {
  const { id: caseId } = useParams();
  const navigate = useNavigate();

  const { selectedProductId, permissions = [] } = useSelector(makeUserData());
  const [savedProductId] = useState(selectedProductId);
  useEffect(() => {
    if (savedProductId !== selectedProductId) {
      history.push('/cases');
    }
  }, [savedProductId, selectedProductId]);

  const isSupervisor =
    (permissions as any[])?.some((p) => ['CASE_EDIT', 'TENANT_ADMIN'].includes(p.name)) || false;
  const { data: caseData, isLoading: isCaseLoading } = useGetCaseQuery(caseId);
  const { usersList = [] } = useGetListUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      usersList: data?.filter((item) => item.enabled),
    }),
  });
  const { data: caseAssignments = [], isLoading: isCaseAssignmentsLoading } =
    useGetCaseAssignmentsQuery(undefined);

  const { data: applicationDetails, isLoading: isLoadingApplication } = useGetApplicationQuery(
    caseData?.referenceId,
    { skip: isCaseLoading || !caseData?.referenceId || caseData?.caseType !== 'APPLICATION' },
  );

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!!caseData && caseAssignments.length > 0 && usersList.length > 0 && users.length === 0) {
      setUsers(
        caseAssignments.filter(
          (el) =>
            el.caseCategory === caseData.caseCategory &&
            !!usersList.some((element) => element.id === el.userId),
        ),
      );
    }
  }, [caseAssignments, caseData, usersList]);

  const { data: transactions, isLoading: isTransactionsLoading } = useGetTransactionsQuery(
    caseData ? { transactionId: caseData.referenceId } : undefined,
    {
      skip: isCaseLoading,
    },
  );
  const [updateCase] = useUpdateCaseMutation();

  const firstCardColumn = {
    width: { md: 1 / 3, xl: 1 / 5 },
    minWidth: 90,
    typography: 'subtitle2',
    mb: 2,
  };
  const secondCardColumn = {
    width: { md: 2 / 3, xl: 4 / 5 },
    typography: 'body2',
    mb: 2,
  };

  const CaseReference = ({ caseType }: { caseType: CaseType }) => {
    switch (caseType) {
      case 'SANCTION':
      // return <Sanction sanctionTransactionId={caseData.referenceId} />;
      case 'TRANSACTION':
        return <TransactionsList transactions={transactions} isLoading={isTransactionsLoading} />;
      case 'APPLICATION':
        return (
          <Button
            onClick={() => {
              navigate(`/application-management/${applicationDetails.haystackClientId}`);
            }}
          >
            open Application details Case Reference
          </Button>
        );
      default:
        return null;
    }
  };

  const isTech2Pay = checkProductName(['TECHTOPAY']);

  return isCaseLoading || isCaseAssignmentsLoading || isLoadingApplication ? (
    <div>Loading...</div>
  ) : (
    <>
      <Helmet title="Case Details" />
      <div className="container" style={{ paddingTop: '2.5rem' }}>
        <div className="title-block" style={{ paddingBottom: '1rem' }}>
          <h3>Case Details</h3>
          <Button
            variant="outlined"
            size="small"
            onClick={() => (history.length > 1 ? history.goBack() : history.push('/cases'))}
          >
            Back
          </Button>
        </div>
        <Grid container spacing={8}>
          <Grid
            container
            item
            columnSpacing={{ xs: 5, xl: 30 }}
            rowSpacing={{ xs: 8, md: 0 }}
            style={{ marginRight: 0 }}
          >
            <Grid item xs={12} md>
              <Card sx={{ minHeight: 230 }}>
                <CardContent>
                  <Box mb={5}>
                    <Typography variant="h2">Case Overview</Typography>
                  </Box>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Case ID:</Box>
                    <Box sx={secondCardColumn}>{caseId}</Box>
                  </Grid>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Category:</Box>
                    <Box sx={secondCardColumn}>{caseData.caseCategory}</Box>
                  </Grid>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Type:</Box>
                    <Box sx={secondCardColumn}>{caseData.caseType}</Box>
                  </Grid>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Description:</Box>
                    <Box sx={secondCardColumn}>{caseData.description}</Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md>
              <Card sx={{ minHeight: 230 }}>
                <CardContent>
                  <Typography variant="h2">Customer Information</Typography>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Name:</Box>
                    <Box sx={secondCardColumn}>{caseData.customerName}</Box>
                  </Grid>
                  <Grid container item>
                    <Box sx={firstCardColumn}>Email:</Box>
                    <Box sx={secondCardColumn}>
                      {caseData.customerEmail ?? '-'}
                      {isTech2Pay && caseData.customerEmail && (
                        <Tooltip title="Show transactions">
                          <Link to={`/transactions?clientEmail=${encodeURI(caseData.customerEmail)}`}>
                            <IconButton sx={{ ml: 2 }}>
                              <RiArrowRightUpFill size="14px" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                  <Grid container item>
                    <Box sx={firstCardColumn}>PAN:</Box>
                    <Box sx={secondCardColumn}>{caseData.maskedPan}</Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* TODO: Maybe group 2 and 3 cards on large & extra small screens (1: 50%, (2,3): 50%) */}
            <Grid item xs={12} md>
              <Card sx={{ minHeight: 230 }}>
                <CardContent>
                  <Typography variant="h2">Assignment Information</Typography>
                  <Box sx={{ width: { xs: '50%', md: '100%' } }}>
                    {/* TODO: Restrict access for users without SUPERVISOR_CASE_MANAGEMENT_PERMISSION */}
                    {isSupervisor && users.length > 0 && caseData && usersList.length > 0 ? (
                      <Autocomplete
                        disablePortal
                        disableClearable
                        value={
                          usersList.find((c) => c.id === caseData.assigneeId)
                            ? usersList.find((c) => c.id === caseData.assigneeId).name
                            : 'User not found'
                        }
                        options={users}
                        getOptionLabel={(option) => {
                          if (option.username)
                            return usersList.find((el) => el.id === option.userId).name;
                          return usersList.find((el) => el.id === caseData.assigneeId)
                            ? usersList.find((el) => el.id === caseData.assigneeId).name
                            : 'User not found';
                        }}
                        onChange={(event, value, reason) => {
                          if (reason === 'selectOption')
                            updateCase({
                              caseId,
                              // For what sake I have to pass status?
                              data: {
                                assigneeId: value.userId,
                                caseStatus: caseData.caseStatus,
                              },
                            });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" label="Assignee" fullWidth />
                        )}
                      />
                    ) : (
                      <Typography variant="subtitle1" component="div" gutterBottom>
                        {`Assignee: `}
                        {usersList.length > 0 && users.length > 0 && !!caseData ? (
                          usersList.find((el) => caseData.assigneeId === el.id).name
                        ) : (
                          <LinearProgress />
                        )}
                      </Typography>
                    )}

                    <FormControl variant="standard" fullWidth style={{ marginTop: '16px' }}>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={caseData.caseStatus}
                        onChange={(event) => {
                          updateCase({
                            caseId,
                            // For what sake I have to pass assigneeId?
                            data: {
                              assigneeId: caseData.assigneeId,
                              caseStatus: event.target.value,
                            },
                          });
                        }}
                        label="Status"
                      >
                        {defaultCaseStatuses.map((status) => (
                          <MenuItem key={`Menu_${status.value}`} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {isTransactionsLoading ? (
                  <MUIDatatableSkeleton size={1} headerHeight={48} />
                ) : (
                  <Box>
                    <Typography variant="h2">Case Reference</Typography>
                    <CaseReference caseType={caseData.caseType} />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <CaseComments caseId={caseId} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
