import React, { ChangeEvent, useEffect } from 'react';
import { ModalDialog } from 'uikit';
import {
  AppShareholderBase,
  ApplicationShareholderDTO,
  useAddShareholderMutation,
  useUpdateShareholderMutation,
} from 'services/gatewayApi/shareholdersApi';
import {
  Stack,
  TextField,
  Switch,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  Chip,
} from '@mui/material';
import { useFormik } from 'formik';
import { ValueLabel } from 'types/types';
import { countries } from 'constants/countries';
import * as yup from 'yup';

interface IShareholderDialogProps {
  open: boolean;
  onClose: any;
  shareholderData: AppShareholderBase;
  applicationId: string;
  onChangeStatus?: (status: string, values: AppShareholderBase) => void;
}

export const ShareholderDialog: React.FC<IShareholderDialogProps> = ({
  open,
  onClose,
  shareholderData,
  applicationId,
  onChangeStatus,
}) => {
  const isNew = shareholderData.id === 'new';
  const title = `${isNew ? 'New' : 'Edit'} Shareholder`;

  const handleSubmit = () => formik.handleSubmit();
  const [addShareholder] = useAddShareholderMutation();
  const [updateShareholder] = useUpdateShareholderMutation();

  const update = (id: string, body: AppShareholderBase) => {
    updateShareholder({
      shareholderId: id,
      applicationId,
      body,
    });
  };

  const validationSchema = yup.object({
    companyName: yup.string().required('Please provide the company name'),
    tradeName: yup.string().required('Please provide the trade name'),
    address: yup.string().required('please provide the address'),
    postcode: yup.string().required('Please provide the postcode'),
    city: yup.string().required('Please provide the city'),
    country: yup.string().required('Please provide the country'),
    percentage: yup
      .number()
      .required('Please provide a number')
      .positive('It should be positive')
      .max(100, 'Can not be more than 100%'),
  });

  const formik = useFormik({
    initialValues: shareholderData,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (isNew) {
        addShareholder({ applicationId, body: values } as ApplicationShareholderDTO);
        onChangeStatus && onChangeStatus('ADD', values);
      } else {
        update(shareholderData.id, values);
        onChangeStatus && onChangeStatus('UPDATE', values);
      }
      onClose();
    },
  });

  return (
    <ModalDialog open={open} onClose={onClose} title={title} handleAction={handleSubmit}>
      <Stack direction="column" spacing={4}>
        {!isNew && (
          <Chip
            color={'base' as any}
            label={`id: ${formik.values.id}`}
            sx={{ marginBottom: '12px' }}
          />
        )}

        <TextField
          fullWidth
          label="Company name"
          name="companyName"
          onChange={formik.handleChange}
          value={formik.values.companyName}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.companyName && Boolean(formik.errors.companyName)}
          helperText={
            formik.touched.companyName && Boolean(formik.errors.companyName)
              ? formik.errors.companyName
              : null
          }
        />

        <TextField
          fullWidth
          label="Trade name"
          name="tradeName"
          onChange={formik.handleChange}
          value={formik.values.tradeName}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.tradeName && Boolean(formik.errors.tradeName)}
          helperText={
            formik.touched.tradeName && Boolean(formik.errors.tradeName)
              ? formik.errors.tradeName
              : null
          }
        />

        <TextField
          fullWidth
          label="Address"
          multiline
          rows={1}
          name="address"
          onChange={formik.handleChange}
          value={formik.values.address}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={
            formik.touched.address && Boolean(formik.errors.address) ? formik.errors.address : null
          }
        />

        <TextField
          fullWidth
          label="Postcode"
          name="postcode"
          onChange={formik.handleChange}
          value={formik.values.postcode}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.postcode && Boolean(formik.errors.postcode)}
          helperText={
            formik.touched.postcode && Boolean(formik.errors.postcode)
              ? formik.errors.postcode
              : null
          }
        />

        <TextField
          fullWidth
          label="City"
          name="city"
          onChange={formik.handleChange}
          value={formik.values.city}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={
            formik.touched.city && Boolean(formik.errors.city) ? formik.errors.city : null
          }
        />

        <Autocomplete
          fullWidth
          options={countries}
          autoHighlight
          value={
            formik.values.country
              ? countries.find((item) => item.value === formik.values.country)
              : null
          }
          onChange={(event, option: ValueLabel) =>
            formik.setFieldValue('country', option ? option.value : '')
          }
          getOptionLabel={(option: ValueLabel) => option.label || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="standard"
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={
                formik.touched.country && Boolean(formik.errors.country)
                  ? formik.errors.country
                  : null
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />

        <TextField
          fullWidth
          label="Percentage"
          type="number"
          name="percentage"
          onChange={formik.handleChange}
          value={formik.values.percentage}
          variant="standard"
          disabled={formik.isSubmitting}
          error={formik.touched.percentage && Boolean(formik.errors.percentage)}
          helperText={
            formik.touched.percentage && Boolean(formik.errors.percentage)
              ? formik.errors.percentage
              : null
          }
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="status"
                checked={formik.values.status === 'ACTIVE'}
                disabled={formik.isSubmitting}
                onChange={(e: ChangeEvent, checked: boolean) => {
                  formik.setFieldValue('status', checked ? 'ACTIVE' : 'INACTIVE');
                }}
              />
            }
            label="Activate the Shareholder"
          />
        </FormGroup>
      </Stack>
    </ModalDialog>
  );
};
