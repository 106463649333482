import React from 'react';

import { isValid } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { convertToDefEventParam } from 'utils/inputUtils';
import { DATE_FORMAT } from '../../constants/constants';

import './DatePicker.scss';
import { DatePickerProps } from './DatePickerTypes';
import { formatServerDateTime } from '../../utils/formatter';

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  helperText,
  error,
  value,
  onChange,
  disabled,
  variant,
}) => {
  const onChangeDate = (newDate) => {
    const formattedDateServer = isValid(newDate) ? formatServerDateTime(newDate) : newDate;
    onChange(convertToDefEventParam(name, formattedDateServer));
  };

  return (
    <div
      className={`
      ${variant === 'standard' ? 'ButtonShift' : 'DatePicker'}
      DatePicker--${value ? 'touched' : 'untouched'}
      ${disabled ? 'DatePicker--disabled' : ''}
      ${error ? 'DatePicker--hasError' : ''}
    `}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat={DATE_FORMAT}
          value={value}
          disabled={disabled}
          onChange={(date) => onChangeDate(date)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant={variant || 'outlined'}
              fullWidth
              name={name}
              label={label}
              error={error}
              helperText={helperText}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
