export const extKycStatuses = {
  byId: {
    APPROVED: {
      id: 'APPROVED',
      label: 'Approved',
      color: 'success',
    },
    SUSPECTED: {
      id: 'SUSPECTED',
      label: 'Suspected',
      color: 'warning',
    },
    EXPIRED: {
      id: 'EXPIRED',
      label: 'Expired',
      color: 'warning',
    },
    DENIED: {
      id: 'DENIED',
      label: 'Denied',
      color: 'error',
    },
  },
  allIds: ['APPROVED', 'SUSPECTED', 'EXPIRED', 'DENIED'],
};
