import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { RiCheckLine } from 'react-icons/ri';

const TabContainerStyled = styled('div', {
  name: 'TabContainerStyled',
  slot: 'root',
})(({ theme }) => theme.unstable_sx({
  position: 'relative',
  padding: '4px 8px 4px 22px',
}));

const CustomTabLabel = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSubTab',
  name: 'CustomTabLabel',
})<{ isSubTab?: boolean, isActive?: boolean }>(({
  theme,
  isSubTab = false,
}) => theme.unstable_sx({
    fontSize: '14px',
    padding: '4px',
    color: isSubTab ? 'grey.600' : 'grey.900',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    maxWidth: '100%',
    textAlign: 'left',
  }),
);

const CustomTabIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'indicatorColor' && prop !== 'isActive',
  name: 'CustomTabIndicator',
})<{ indicatorColor?: string, isActive?: boolean }>(({
  theme,
  indicatorColor,
  isActive = false,
}) => theme.unstable_sx({
  color: indicatorColor || 'grey.900',
  position: 'absolute',
  top: '50%',
  left: '6.5px',
  transform: 'translate(-50%, -50%)',
  width: '13px',
  height: '13px',
  borderRadius: '50%',
  border: `1px solid`,
  borderColor: indicatorColor || theme.palette.grey[900],
  backgroundColor: isActive ? 'grey.900' : 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  }),
);

export interface CustomTabProps {
  title: string;
  onClick: () => void;
  isActive?: boolean;
  isSubTab?: boolean;
  status?: string;
  id?: string;
  actualStatus?: string;
  indicatorColor?: string;
}

export const CustomTab: React.FC<CustomTabProps> = ({
  isActive = false,
  isSubTab = false,
  title,
  onClick,
  status = undefined,
                                                      indicatorColor,
}) => {
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      setIsOverflow(button.scrollWidth > button.clientWidth);
    }
  }, []);

  return (
    <TabContainerStyled className="CustomTab">
       <Tooltip title={isOverflow ? title : ''}>
        <CustomTabLabel className='TabLabel' onClick={onClick} variant='text' color='base' fullWidth isSubTab={isSubTab} ref={buttonRef}>
          {title}
        </CustomTabLabel>
       </Tooltip>
      <div className="leftLine" />
      { !isSubTab && (
        <CustomTabIndicator className='TabIndicator' indicatorColor={indicatorColor} isActive={isActive} >
          {status === 'DONE' && <RiCheckLine  size={13} style={{color: isActive ? 'white' : theme.palette.grey[900]}}/>}
        </CustomTabIndicator>
      )}
    </TabContainerStyled>
  );
};
