import { useEffect, useRef } from 'react';

export interface IUseInfinityScroll {
  isFetching: boolean;
  isLoading: boolean;
  nextPage: any;
  loader: HTMLDivElement;
}

export const useInfinityScroll = ({ isFetching, isLoading, nextPage, loader }) => {
  const lastScroll = useRef<number>(0);
  const delayRequest = useRef<boolean>(false); // avoid unwanted request

  // Scroll observer handler
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      if (!isFetching && !isLoading) {
        loadNext();
      }
    }
  };

  // Pagination function
  const loadNext = () => {
    if (!delayRequest.current) {
      delayRequest.current = true;
      lastScroll.current = window.scrollY;
      nextPage && nextPage();
    }
  };

  // init scroll observer
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    loader.current && observer.observe(loader.current);
  }, [handleObserver]);

  // scroll to the last position after request
  useEffect(() => {
    window.scrollTo(0, lastScroll.current);
    if (!isLoading && !isFetching) {
      delayRequest.current = false;
    }
  }, [isLoading, isFetching]);
};
