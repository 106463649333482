import { Skeleton } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const MUIDatatableSkeleton = ({
  size = 10,
  headerHeight = 140,
  rowHeight = 59,
  footerHeight = 52.5,
}) => {
  const rows = [];
  for (let i = 0; i < size; i += 1) {
    rows.push(<Skeleton key={i} variant="text" height={rowHeight} />);
  }
  return (
    <>
      <Skeleton variant="rectangular" height={headerHeight} />
      {rows}
      <Skeleton variant="rectangular" height={footerHeight} />
    </>
  );
};

MUIDatatableSkeleton.propTypes = {
  size: PropTypes.number,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  footerHeight: PropTypes.number,
};

export default MUIDatatableSkeleton;
