import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  Backdrop,
  CircularProgress,
  Icon,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { MemoizedChipSelector } from 'uikit';
import MUIDataTable from '../../components/MUIDataTable';
import { initialFormData } from './constants';
import {
  useDeleteMerchantMutation,
  useGetMerchantsQuery,
} from '../../services/gatewayApi/merchantsApi';
import {
  useGetMerchantCategoriesQuery,
  useLinkMerchantCategoryForMerchantMutation,
  useUnlinkMerchantCategoryForMerchantMutation,
} from '../../services/gatewayApi/merchantCategoriesApi';
import { makeSelectedProductIdData } from '../../selectors/user';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { ConfirmationDialog } from '../../components';
import MerchantFormDialog from './MerchantFormDialog';
import { removeEmptyProperties } from '../../utils/modelHelper';
import ApiService from '../../services/apiService';
import { cleanExceptionRuleData } from '../exceptionRules/constants';
import { ExceptionRuleDialog } from '../exceptionRules/ExceptionRuleDialog';

const MerchantsList = ({ filterValues }) => {
  const [page, setPage] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const [sortOrder, setSortOrder] = useState({
    name: 'createdDateTime',
    direction: 'desc',
  });
  const [exceptionRuleFormOpen, setExceptionRuleFormOpen] = useState(false);
  const [dataExceptionRule, setDataExceptionRule] = useState(cleanExceptionRuleData);

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const filterValuesClean = _.clone(filterValues);
  removeEmptyProperties(filterValuesClean);

  const { data, isLoading, isFetching } = useGetMerchantsQuery(
    {
      productId: selectedProductId,
      page,
      size: pageSize,
      sort: `${sortOrder.name},${sortOrder.direction}`,
      ...filterValuesClean,
    },
    {
      skip: !selectedProductId,
    },
  );

  const { categories, isLoadingCategories } = useGetMerchantCategoriesQuery({
    productId: selectedProductId,
    page: 0,
    size: 9999,
    sort: `categoryName,asc`,
  }, {
    skip: !selectedProductId,
    selectFromResult: ({ data, isLoading }) => ({
      categories: data?.results?.map((el) => ({ value: el.id, label: el.categoryName })),
      isLoadingCategories: isLoading,
    }),
  });

  useEffect(() => {
    if (data) {
      setMerchants(data.results);
      setCount(data.count);

      if (Math.floor((data.count - 1) / pageSize) < page) {
        resetGridPage(); // in case page is not available (after delete)
      }
    }
  }, [data, page, pageSize]);

  // reset grid on search/reset
  useEffect(() => {
    resetGridPage();
  }, [filterValues]);

  const resetGridPage = (skipSetPage = false) => {
    if (!skipSetPage) {
      setPage(0); // move to first page
    }
  };

  const [deleteMerchant] = useDeleteMerchantMutation();
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [idToDelete, setIdToDelete] = useState('');

  const handleExportCsv = () => {
    const params = {
      productId: selectedProductId,
      page,
      size: 2000,
      sort: `${sortOrder.name},${sortOrder.direction}`,
      ...filterValuesClean,
    };

    ApiService.getCSV('api/gateway/merchants', params);
  };

  const CustomButtons = () => (
    <>
      <Tooltip title="Export CSV" disableFocusListener>
        <IconButton onClick={handleExportCsv} size="large">
          <Icon className="fa fa-download" fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Merchant" disableFocusListener>
        <IconButton
          onClick={() => {
            setFormData(initialFormData);
            setFormOpen(true);
          }}
          size="large"
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  const handleCreateExceptionRule = (id) => {
    const merchant = merchants.find((item) => item.id === id);
    setDataExceptionRule((actual) => ({
      ...actual,
      merchantName: merchant.merchantName,
      merchantId: id,
      id: 'new',
    }));
    setExceptionRuleFormOpen(true);
  };

  const handleEdit = (id) => {
    setFormData(merchants.find((item) => item.id === id));
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    setIdToDelete(id);
  };

  const confirmDelete = () => {
    deleteMerchant(idToDelete);
    setIdToDelete('');
  };

  const [linkMerchant] = useLinkMerchantCategoryForMerchantMutation();
  const [unlinkMerchant] = useUnlinkMerchantCategoryForMerchantMutation();

  const handleUpdateCategories = (merchantId, newList, oldList) => {
    const toLink = newList.filter((item) => !oldList.map((i) => i.value).includes(item.value));
    const toUnlink = oldList.filter((item) => !newList.map((i) => i.value).includes(item.value));

    toLink.map((item) => linkMerchant({ merchantId, categoryId: item.value }));
    toUnlink.map((item) => unlinkMerchant({ merchantId, categoryId: item.value }));
  };

  const getMerchantCategories = (id) => {
    const merchant = data?.results?.find((item) => item.id === id);
    return merchant ? merchant.categoryList?.map((el) => ({ value: el.id, label: el.categoryName })) : [];
  }

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    expandableRows: false,
    expandableRowsHeader: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    onTableChange: (action, state) => {
      if (action === 'sort') {
        resetGridPage();
        setSortOrder(state.sortOrder);
      }

      if (action === 'changePage') {
        resetGridPage(true);
        setPage(state.page);
      }

      if (action === 'changeRowsPerPage') {
        resetGridPage();
        setPageSize(state.rowsPerPage);
      }
    },
    sortOrder,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isLoading || isFetching ? <LinearProgress /> : 'No matching records found...',
      },
    },

    serverSide: true,
    count,
    page,
    customToolbar: CustomButtons,
  };

  const columns = [
    {
      name: 'mid',
      label: 'MID',
    },
    {
      name: 'merchantName',
      label: 'Merchant Name',
    },
    {
      name: 'createdBy',
      label: 'Created By',
    },
    {
      name: 'createdDateTime',
      label: 'Created On',
    },
    {
      name: 'id',
      label: 'Categories',
      options: {
        sort: false,
        empty: true,
        customBodyRender: (value) => {
          const merchantCategories = getMerchantCategories(value);

          return isLoadingCategories
            ? 'Loading categories...'
            : (
              <MemoizedChipSelector
                list={categories}
                selectedList={merchantCategories}
                setSelectedList={(newList) => handleUpdateCategories(value, newList, merchantCategories)}
                limitTags={2}
              />
            )
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => (
          <>
            <Tooltip title="Create Exception Rule" disableFocusListener>
              <IconButton onClick={() => handleCreateExceptionRule(value)} size="large">
                <WarningIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" disableFocusListener>
              <IconButton onClick={() => handleEdit(value)} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" disableFocusListener>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDelete(value);
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        <div style={{ position: 'relative' }}>
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MUIDataTable data={merchants} columns={columns} options={options} />
        </div>

        <ConfirmationDialog
          open={!!idToDelete}
          onClose={() => setIdToDelete('')}
          onCancel={() => setIdToDelete('')}
          onConfirm={confirmDelete}
          title="Deleting Merchant"
          text="Are you sure you want to permanently delete selected Merchant?"
          confirmButtonText="Delete"
          confirmButtonColor="danger"
          cancelButtonText="Cancel"
        />

        <MerchantFormDialog
          open={formOpen}
          onClose={() => setFormOpen(false)}
          isNew={!formData?.id || false}
          data={formData}
        />
      </div>
      {exceptionRuleFormOpen && (
        <ExceptionRuleDialog
          open={exceptionRuleFormOpen}
          onClose={() => {
            setExceptionRuleFormOpen(false);
            setDataExceptionRule(cleanExceptionRuleData);
          }}
          productId={selectedProductId}
          data={dataExceptionRule}
        />
      )}
    </div>
  );
};

MerchantsList.propTypes = {
  filterValues: PropTypes.object,
};

export default MerchantsList;
