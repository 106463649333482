import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { setAutoFreeze } from 'immer';
import {
  formsApi,
  formStatusListenerMiddleware,
  validationListenerMiddleware,
} from '@haystack-forms/api';
import rootReducer from './reducers';
import { queryErrorHandler } from './middleware';
import { authApi } from './services/authApi';
import { internalApi } from './services/internalApi';
import { gatewayApi } from './services/gatewayApi';
import { sectionStatusListenerMiddleware } from './services/tempSlice';

export const history = createBrowserHistory();
const connectedRouterMiddleware = routerMiddleware(history);

// TODO: remove after Rules/RuleSet refactoring
setAutoFreeze(false);

let store;
export const configureAppStore = (preloadedState) => {
  store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        actionCreatorCheck: false,
      })
        .concat(connectedRouterMiddleware)
        .concat(queryErrorHandler)
        .prepend(formStatusListenerMiddleware.middleware)
        .prepend(validationListenerMiddleware.middleware)
        .prepend(sectionStatusListenerMiddleware.middleware)
        .concat(formsApi.middleware)
        .concat(gatewayApi.middleware)
        .concat(authApi.middleware)
        .concat(internalApi.middleware),
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }
  return store;
};
export const getStore = () => {
  if (!store) {
    throw new Error('Store has not been configured. Please call configureAppStore first.');
  }
  return store;
};

export type RootState = ReturnType<typeof store.getState>;
