import { gatewayApi } from '../gatewayApi';
import { ApplicationForm } from '../../features/ApplicationManagement/types/forms';

const applicationFormsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationForms: build.query<
      ApplicationForm[],
      { applicationId: string; sectionId?: number; partition?: string }
    >({
      query: ({ applicationId, sectionId, partition }) => ({
        url: `applications/${applicationId}/forms`,
        method: 'GET',
        params: { sectionId, partition },
      }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),
    getApplicationForm: build.query({
      query: ({ applicationId, id }) => ({
        url: `applications/${applicationId}forms/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'ApplicationFormTag', id }],
    }),
    deleteApplicationForm: build.mutation({
      query: ({ applicationId, id }) => ({
        url: `applications/${applicationId}/forms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { applicationId, id }) => [
        { type: 'ApplicationForms', id: applicationId },
        { type: 'ApplicationFormTag', id },
      ],
    }),
    updateApplicationFormStatus: build.mutation({
      query: ({ applicationId, id, status }) => ({
        url: `applications/${applicationId}/forms/${id}/statuses/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { applicationId, id }) => [
        { type: 'ApplicationForms', id: applicationId },
        { type: 'ApplicationFormTag', id },
        { type: 'FormSectionStatuses', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetApplicationFormsQuery,
  useGetApplicationFormQuery,
  useDeleteApplicationFormMutation,
  useUpdateApplicationFormStatusMutation,
} = applicationFormsApi;
