import { baseTheme } from '../baseTheme';

export const ButtonBase = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      transition: 'background .3s ease-in-out',
    },
  },
};
