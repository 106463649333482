import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { ArrowBack, ScrollContainer } from 'uikit';
import { primary, grey } from 'theme/palette-blocks';
import { useGetRulesQuery } from 'services/internalApi';
import { useParams } from 'react-router-dom';
import { updateCurrentRuleSetId, updateRuleset, updateSearchString } from './rulesSlice';

interface RulesetsDrawerContentProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  ruleSetsList?: any[];
  setSelectedRulesetId: React.Dispatch<React.SetStateAction<string>>;
  selectedRulesetId?: string;
  isLoading: boolean;
}

export const RulesetsDrawerContent: React.FC<RulesetsDrawerContentProps> = ({
  onClose,
  ruleSetsList,
  setSelectedRulesetId,
  selectedRulesetId = '',
  isLoading,
}) => {
  const getColor = (id: number | string) =>
    id.toString() === selectedRulesetId.toString()
      ? `1px solid ${primary.main}`
      : `1px solid ${grey[200]}`;

  const [newRulesetId, setNewRulesetId] = useState<undefined | string>(undefined);
  const { ruleSetId } = useParams<{ ruleSetId: string }>();
  const dispatch = useDispatch();

  const {
    data: rulesList,
    isLoading: isLoadingRulesList,
    isFetching: isFetchingRulesList,
  } = useGetRulesQuery(
    {
      // body: null, // TODO replace with params
      ruleSetId: newRulesetId,
    },
    {
      skip: !newRulesetId,
    },
  );

  useEffect(() => {
    if (rulesList && !isLoadingRulesList && !isFetchingRulesList) {
      dispatch(updateRuleset(rulesList));
    }
  }, [rulesList]);

  const handleChangeRuleset = (id: number | string): void => {
    setNewRulesetId(id.toString());
    dispatch(updateCurrentRuleSetId(id.toString()));
    dispatch(updateSearchString(''));
    setSelectedRulesetId(id.toString());
  };

  return (
    <Box pl={12}>
      <Box pb={8} pt={6}>
        <ArrowBack onClose={onClose} />
      </Box>
      <Typography sx={{ pb: 4, fontWeight: 500, fontSize: '24px' }}>Rule presets</Typography>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <ScrollContainer>
          <Box
            py={4}
            pr={2}
            sx={{
              width: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            {ruleSetsList &&
              ruleSetsList.map((preset) => (
                <Box
                  component="div"
                  sx={{
                    p: 2,
                    border: getColor(preset.id),
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'grey.100', cursor: 'pointer' },
                    transition: 'all 0.3s ease-in-out',
                  }}
                  onClick={() => {
                    handleChangeRuleset(preset.id);
                    onClose(false);
                  }}
                  key={preset.id}
                >
                  <Typography>{preset.id}</Typography>
                  <Typography>{preset.name}</Typography>
                  <Typography>{preset.description}</Typography>
                </Box>
              ))}
          </Box>
        </ScrollContainer>
      )}
    </Box>
  );
};
