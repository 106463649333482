import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

// removed because it clashes with new OP theme
// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     caption1: React.CSSProperties;
//   }
//
//   interface TypographyVariantsOptions {
//     caption1: React.CSSProperties;
//   }
// }
//
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     caption1: true;
//   }
// }

export const caption1: TypographyStyleOptions = {
  fontSize: '11px',
  lineHeight: '16px',
  marginBottom: '2px',
  fontWeight: 400,
  textTransform: 'uppercase',
  letterSpacing: '1px',
};
