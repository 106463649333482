import { PaletteColor } from '@mui/material/styles/createPalette';
import { FigmaColor, textColor } from '../figmaVariables';

export const info: PaletteColor = {
  main: FigmaColor({ colorName: 'grey', shade: 500 }),
  light: FigmaColor({ colorName: 'grey', shade: 200 }),
  dark: FigmaColor({ colorName: 'grey', shade: 500 }),
  contrastText: textColor('text-inverted-secondary'),

  hover: FigmaColor({ colorName: 'grey', shade: 600 }),
  active: FigmaColor({ colorName: 'grey', shade: 700 }),
};
