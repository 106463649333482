import { figmaVariables } from '../figmaVariables';

export const Paper = {
  styleOverrides: {
    root: {
      borderRadius: figmaVariables.primitives.radius['lg-8'],
    },
    elevation8: {
      boxShadow: '0px 4px 12px 0px #0000001A',
    },
  },
};
