import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { formatIsoDate } from '../../../utils/formatter';
import { DATETIME_FORMAT } from '../../../constants/constants';
import { entitiesLabel, paramsScheme } from '../constants';
import { Result, SanctionsRecord, SearchRequest } from '../types';

interface HistoryBlockProps {
  result: Result;
  selectedResultId: string;
  setSelectedResultId: React.Dispatch<React.SetStateAction<string>>;
  setPerformedOn: React.Dispatch<React.SetStateAction<string>>;
  setHistorySearchResult: React.Dispatch<React.SetStateAction<SanctionsRecord[]>>;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchRequest>>;
}

export const HistoryBlock: React.FC<HistoryBlockProps> = ({
  result,
  selectedResultId,
  setSelectedResultId,
  setPerformedOn,
  setHistorySearchResult,
  setSearchParams,
}) => {
  const getLabel = (el, value) => {
    if (el === 'entities') {
      return ['M', 'F'].includes(value[0]) ? 'Gender' : 'Corporate sign';
    }
    return paramsScheme.find((field) => field.name === el)?.label;
  };

  const makeParams = (params: SearchRequest) => {
    const resultParams = { ...params };
    if (params.entities.length > 1) {
      delete resultParams.entities;
    } else {
      resultParams.entities = params.entities[0];
    }
    return resultParams;
  };

  return (
    <Box
      onClick={() => {
        setSelectedResultId(result.id);
        setPerformedOn(result.performedOn);
        setHistorySearchResult(result.searchResponse);
        setSearchParams(makeParams(result.searchRequest));
      }}
      sx={{
        borderRadius: '12px',
        border: '1px solid',
        borderColor: selectedResultId === result.id ? 'primary.main' : 'grey.200',
        cursor: 'pointer',
        backgroundColor: selectedResultId === result.id ? 'white' : 'grey.50',
        '&:hover': {
          backgroundColor: selectedResultId === result.id ? 'white' : 'grey.100',
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{
          backgroundColor: selectedResultId === result.id ? 'grey.50' : 'grey.200',
          borderTopRightRadius: '12px',
          borderTopLeftRadius: '12px',
          p: '12px 12px 8px 12px',
          mb: '8px',
        }}
      >
        <Typography color="grey.700" variant="body2">
          {formatIsoDate(result.performedOn, DATETIME_FORMAT)}
        </Typography>
        <Typography
          color="grey.700"
          variant="body2"
        >{`${result.searchResponse?.length} results`}</Typography>
      </Stack>
      <Stack direction="column" spacing={1} px={3} pb={3} alignContent="flex-start">
        {Object.keys(result.searchRequest).map((el) => {
          if (el === 'entities' && result.searchRequest[el].length > 1) return null;
          return (
            <Box
              key={el}
              display="flex"
              justifyContent="space-between"
              sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
            >
              <Typography variant="body2" component="span" py={2} color="grey.600">
                {getLabel(el, result.searchRequest[el])}
              </Typography>
              <Typography variant="body2" component="span" fontWeight={500} py={2}>
                {el === 'entities'
                  ? entitiesLabel[result.searchRequest[el]]
                  : result.searchRequest[el]}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
