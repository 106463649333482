import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  RiAttachment2,
  RiDeleteBinFill,
  RiDownloadLine,
  RiMore2Fill,
  RiPencilLine,
  RiHourglassLine,
} from 'react-icons/ri';
import { ModalDialog } from 'uikit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  ApplicationNote as ApplicationNoteData,
  useDeleteNoteMutation,
} from 'services/gatewayApi/applicationNotesApi';
import {
  useGetApplicationNoteDocumentsQuery,
  VirusStatus,
} from 'services/gatewayApi/applicationNoteDocumentsApi';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import { toastDeleteSuccess } from 'utils';
import { decodeFromBase64 } from 'utils/base64';
import { downloadFile } from 'utils/downloadFile';
import { formatDate } from 'utils/formatter';
import { styled } from '@mui/material/styles';
import { NoteDialog } from './NoteDialog';
import { DATE_FORMAT } from '../../../../constants/constants';

const ApplicationNoteStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDraft' && prop !== 'isInternal',
  name: 'ApplicationNote',
  slot: 'Root',
})<{ isInternal: boolean; isDraft: boolean }>(({ theme, isDraft = false, isInternal = false }) =>
  theme.unstable_sx({
    bgcolor: isInternal ? 'grey.200' : 'white',
    borderRadius: '8px',
    padding: '12px',
    border: `1px ${isDraft ? 'dashed' : 'solid'}`,
    borderColor: 'grey.300',
    color: isDraft ? 'grey.600' : 'grey.900',
  }),
);

interface ApplicationNoteProps {
  applicationId: string;
  note: Partial<ApplicationNoteData>;
  isSideBar?: boolean;
}

export const ApplicationNote = ({
  applicationId,
  note,
  isSideBar = false,
}: ApplicationNoteProps): JSX.Element => {
  const { id: userId } = useSelector(makeUserData());
  const {
    id,
    title,
    text,
    createdBy,
    createdByName,
    createdDate,
    modifiedDate,
    isDraft = false,
    isInternal = false,
    isSummary = false,
  } = note;

  const [editNoteDialogOpen, setEditNoteDialogOpen] = useState<boolean>(false);

  const [anchorElAttachments, setAnchorElAttachments] = React.useState<null | HTMLElement>(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const isNoteOfCurrentUser = createdBy === userId;

  // #region Delete Note
  const [deleteNote] = useDeleteNoteMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteNote({ id, applicationId })
      .unwrap()
      .then(() => {
        toastDeleteSuccess('Note');
      });
    setOpenConfirmDelete(false);
  };
  // #endregion

  // #region Note Documents/Attachments
  const [hasPendingVirusStatus, setHasPendingVirusStatus] = useState<boolean>(true);
  const { data: attachments, isLoading: isLoadingAttachments } =
    useGetApplicationNoteDocumentsQuery(
      { applicationId, noteId: id },
      {
        pollingInterval: hasPendingVirusStatus ? 15 * 1000 : 0,
        skip: !applicationId || !id || id === 'new',
      },
    );

  useEffect(() => {
    const hasPending = attachments?.some((doc) => doc.virusStatus === VirusStatus.Pending);
    setHasPendingVirusStatus(hasPending);
  }, [JSON.stringify(attachments)]);
  // #endregion

  const { data: usersList = [], isLoading: isUsersLoading } = useGetListUsersQuery(undefined);

  return (
    <ApplicationNoteStyled isDraft={isDraft} isInternal={isInternal}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={2}
          className="NoteTitle"
        >
          <Typography
            sx={{
              mb: 0,
              fontSize: '18px',
              lineHeight: '20px',
              color: isDraft ? 'grey.600' : 'grey.900',
            }}
          >
            {title}
          </Typography>

          {isInternal && <Chip size="small" label="internal" />}
          {isDraft && <Chip size="small" label="draft" color="base" sx={{ color: 'grey.600' }} />}
          {isSummary && isSideBar && (
            <Chip size="small" label="summary" color="warning" sx={{ color: 'grey.900' }} />
          )}

          {!isLoadingAttachments &&
            attachments &&
            attachments.filter((a) => a.fileName).length > 0 && (
              <>
                <IconButton
                  size="small"
                  sx={{ p: 0 }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    setAnchorElAttachments(event.currentTarget)
                  }
                >
                  <RiAttachment2 size="14px" />
                </IconButton>
                <Menu
                  anchorEl={anchorElAttachments}
                  open={Boolean(anchorElAttachments)}
                  onClose={() => setAnchorElAttachments(null)}
                  onClick={() => setAnchorElAttachments(null)}
                  transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  sx={{ '.MuiPaper-root': { maxWidth: '250px' } }}
                >
                  {attachments
                    .slice()
                    .filter((a) => a.fileName && a.virusStatus !== VirusStatus.Quarantined)
                    .sort((a, b) => (a.createdDate > b.createdDate ? 1 : -1))
                    .map(({ id, noteId, fileName, virusStatus }) => (
                      <MenuItem
                        key={`attachment_${id}`}
                        disabled={isDownloading || virusStatus !== VirusStatus.Checked}
                        onClick={() => {
                          setIsDownloading(true);
                          downloadFile(
                            `/api/gateway/applications/${applicationId}/notes/${noteId}/documents/${id}/download`,
                            { download: true },
                          ).finally(() => setIsDownloading(false));
                        }}
                      >
                        <ListItemIcon>
                          {virusStatus === VirusStatus.Checked ? (
                            <RiDownloadLine />
                          ) : (
                            <RiHourglassLine />
                          )}
                        </ListItemIcon>
                        <Box
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fileName}
                        </Box>
                      </MenuItem>
                    ))}
                </Menu>
              </>
            )}
        </Stack>
        {isNoteOfCurrentUser && (
          <Box>
            <IconButton
              size="small"
              sx={{ p: 0 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorElMenu(event.currentTarget)
              }
            >
              <RiMore2Fill size="14px" />
            </IconButton>
            <Menu
              anchorEl={anchorElMenu}
              open={Boolean(anchorElMenu)}
              onClose={() => setAnchorElMenu(null)}
              onClick={() => setAnchorElMenu(null)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => setEditNoteDialogOpen(true)}>
                <ListItemIcon>
                  <RiPencilLine />
                </ListItemIcon>
                Edit
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleConfirmDelete} sx={{ color: 'error.main' }}>
                <ListItemIcon sx={{ color: 'error.main' }}>
                  <RiDeleteBinFill />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Stack>

      {createdDate && (
        <Typography variant="body2" sx={{ mt: 2, color: 'grey.600' }}>
          Created by {createdByName} {formatDate(createdDate)}
          {modifiedDate && (
            <Typography component="span" variant="body2" sx={{ mt: 1, color: 'grey.600' }}>
              {`, Modified: ${formatDate(modifiedDate, DATE_FORMAT)}`}
            </Typography>
          )}
        </Typography>
      )}

      <ReactQuill
        className="ReactQuill ReactQuill_ReadOnly"
        readOnly
        value={decodeFromBase64(text)}
      />

      {editNoteDialogOpen && !isUsersLoading && (
        <NoteDialog
          open={editNoteDialogOpen}
          onClose={() => setEditNoteDialogOpen(false)}
          note={note}
          usersList={usersList}
        />
      )}

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete Note"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Note '}
            <Typography variant="subtitle1" component="span">
              {title}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </ApplicationNoteStyled>
  );
};
