import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import 'date-fns';
import { DATE_FORMAT, TOOLTIP_DELAY } from 'constants/constants';
import {
  RiAddCircleFill,
  RiInformationLine,
  RiLockUnlockFill,
  RiFileCopyLine,
} from 'react-icons/ri';
import { MdDateRange } from 'react-icons/md';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import { convertToDefEventParam } from 'utils/inputUtils';
import { FieldList } from './types';
import { WarningAlert } from './WarningAlert';
import { danger } from '../../../theme/palette-blocks';
import { formatServerDateTime } from '../../../utils/formatter';
import './FormRowInput.scss';

interface FormRowInputProps {
  fieldData: FieldList;
  error?: string;
  value?: any;
  disabled?: boolean;
  onChange?: any;
}

export const FormRowInput: React.FC<FormRowInputProps> = ({
  fieldData: {
    field,
    type,
    name,
    label,
    placeholder,
    options,
    editable,
    editLock,
    editLockWarning,
    hasCopyValue,
    keywordTest,
    defaultFocus,
  },
  error,
  value,
  disabled = false,
  onChange,
}) => {
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => inputRef.current?.focus();

  const [isEditLocked, setIsEditLocked] = useState<boolean>(editLock);
  // Focus related field on unlock
  useEffect(() => {
    editLock && !isEditLocked && focusInput();
  }, [editLock, isEditLocked]);

  // Focus default field on form edit
  useEffect(() => {
    !disabled && editable && defaultFocus && focusInput();
  }, [disabled]);

  const csvSeparator = ';';

  // #region Array field helpers
  // Backward compatibility for comma separated values
  const arrayDefaultValue =
    field === 'text' && type === 'array' && value && value.indexOf(',') >= 0
      ? value.replace(/,/g, csvSeparator)
      : value;

  const [keyword, setKeyword] = useState<string>('');
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const isArrayField = field === 'array';
  const getDefaultValueAsArray = () =>
    isArrayField
      ? arrayDefaultValue
      : `${arrayDefaultValue}`.split(csvSeparator).filter((item) => item.length > 0);

  const isInValueList = (kywrd) => getDefaultValueAsArray().includes(kywrd);

  const triggerOnChange = (newArray) =>
    onChange(convertToDefEventParam(name, isArrayField ? newArray : newArray.join(csvSeparator)));

  const handleAddKeyword = (optionKeyword) => {
    options && setIsListOpen(false);

    const kywrd = optionKeyword ?? keyword;

    if (kywrd.length > 0 && (keywordTest ? keywordTest(kywrd) : true) && !isInValueList(kywrd)) {
      triggerOnChange([...getDefaultValueAsArray(), kywrd]);
      setKeyword('');
    }
  };

  const handleKeyDownKeyword = (e) => {
    if (options) {
      setIsListOpen(true);
      return false;
    }

    if (['Enter', 'Tab', csvSeparator].includes(e.key)) {
      e.preventDefault();
      handleAddKeyword(keyword);
    }
  };

  const handleDeleteKeyword = (kywrd) => {
    triggerOnChange(getDefaultValueAsArray().filter((item) => item !== kywrd));
    focusInput();
  };

  const handleKeywordBlur = (e) => {
    if (!e.relatedTarget?.classList?.contains('MuiListItemButton-root')) {
      setIsListOpen(false);
    }
  };

  const optionsFiltered = () =>
    options.filter(
      (item) =>
        !getDefaultValueAsArray().includes(item.value.toString().toLowerCase()) &&
        item.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0,
    );
  // #endregion

  const isDisabled = disabled || !editable || (editLock && isEditLocked);

  return (
    <Grid
      className="FormRowInput"
      container
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: error ? theme.palette.danger.main : theme.palette.grey[100],
      }}
    >
      <Grid item xs={5} md={3} sx={{ py: 4 }}>
        <Typography
          variant="body"
          component="label"
          htmlFor={name}
          sx={editable ? {} : { opacity: 0.6 }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7} md={9} sx={{ position: 'relative' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {['text', 'array'].includes(field) && (
            <>
              {['text', 'email'].includes(type) && (
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputRef={inputRef}
                  id={name}
                  name={name}
                  placeholder={!editable ? '' : placeholder}
                  fullWidth
                  value={value ?? ''}
                  disabled={isDisabled}
                  onChange={onChange}
                />
              )}

              {type === 'date' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputRef={inputRef}
                    inputFormat={DATE_FORMAT}
                    value={value ?? ''}
                    disabled={isDisabled}
                    onChange={(date) =>
                      onChange(convertToDefEventParam(name, formatServerDateTime(date)))
                    }
                    components={{
                      OpenPickerIcon: MdDateRange,
                    }}
                    disableOpenPicker={isDisabled}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        inputProps={{
                          ...props.inputProps,
                          placeholder: placeholder ?? DATE_FORMAT,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}

              {type === 'array' && (
                <Box className="ChipInput" onClick={focusInput}>
                  <Box>
                    {arrayDefaultValue &&
                      arrayDefaultValue?.length > 0 &&
                      getDefaultValueAsArray().map((kwrd) => (
                        <Chip
                          key={kwrd}
                          label={options ? options.find((item) => item.value === kwrd).label : kwrd}
                          variant="outlined"
                          onDelete={!isDisabled ? () => handleDeleteKeyword(kwrd) : null}
                          sx={isDisabled ? { color: 'grey.600' } : {}}
                        />
                      ))}
                  </Box>
                  {!isDisabled && (
                    <Stack
                      className="ChipInputContent"
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      spacing={2}
                      sx={{ position: 'relative' }}
                    >
                      <RiAddCircleFill fontSize="18px" />
                      <input
                        ref={inputRef}
                        name="keyword"
                        onClick={options ? () => setIsListOpen(true) : null}
                        onBlur={options ? handleKeywordBlur : handleAddKeyword}
                        onChange={(e) => setKeyword(e.target.value.trim())}
                        onKeyDown={handleKeyDownKeyword}
                        value={keyword}
                        style={{ width: `${5 + keyword.length}ch` }}
                        disabled={isDisabled}
                        placeholder={placeholder}
                      />
                      {isListOpen && options && optionsFiltered().length > 0 && (
                        <Box
                          sx={{
                            width: '100%',
                            maxWidth: 260,
                            position: 'absolute',
                            left: '14px',
                            top: '25px',
                            zIndex: 1,
                          }}
                        >
                          <Paper elevation={3}>
                            <List dense>
                              {optionsFiltered().map((o) => (
                                <ListItem key={`option_${o.value}`} sx={{ p: 0 }}>
                                  <ListItemButton onClick={() => handleAddKeyword(o.value)}>
                                    <ListItemText
                                      primary={o.label}
                                      sx={{ '.MuiTypography-root': { fontSize: '13px' } }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </Paper>
                        </Box>
                      )}
                    </Stack>
                  )}
                </Box>
              )}
            </>
          )}

          {field === 'radio' && (
            <RadioGroup
              id={name}
              name={name}
              row
              sx={{ height: '52px' }}
              value={value ?? ''}
              onChange={onChange}
            >
              {options.map((o) => (
                <FormControlLabel
                  key={`option_${o.value}`}
                  value={o.value}
                  control={<Radio />}
                  label={o.label}
                  disabled={isDisabled}
                />
              ))}
            </RadioGroup>
          )}

          {field === 'toggle' && (
            <Switch
              id={name}
              name={name}
              disabled={isDisabled}
              checked={value ?? false}
              onChange={(e: ChangeEvent, checked: boolean) =>
                onChange(convertToDefEventParam(name, checked))
              }
              sx={{ mt: 2 }}
            />
          )}

          {field === 'select' && type !== 'array' && (
            <Select
              inputRef={inputRef}
              id={name}
              name={name}
              fullWidth
              displayEmpty
              disabled={isDisabled}
              onChange={onChange}
              sx={{ '& .MuiInputBase-input': { pb: 4 } }}
              value={value ?? ''}
            >
              {options.map((o) => (
                <MenuItem key={`option_${o.value}`} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          )}

          {hasCopyValue && (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(value).then(
                  () => toastCopySuccess(label),
                  () => toastCopyError(label),
                );
              }}
            >
              <RiFileCopyLine />
            </IconButton>
          )}

          {error && (
            <Tooltip title={error}>
              <Box sx={{ lineHeight: 1, py: 4, pr: 3 }}>
                <RiInformationLine color={danger.main} fontSize="18" />
              </Box>
            </Tooltip>
          )}
        </Stack>

        {editLock && !disabled && (
          <>
            {isEditLocked && (
              <Tooltip
                title="Unlock editing"
                enterDelay={TOOLTIP_DELAY}
                sx={{ position: 'absolute', right: 0, top: 6 }}
              >
                <IconButton onClick={() => setIsEditLocked((prev) => !prev)}>
                  <RiLockUnlockFill color={theme.palette.text.placeholder} />
                </IconButton>
              </Tooltip>
            )}

            {!isEditLocked && (
              <WarningAlert message={editLockWarning} setIsEditLocked={setIsEditLocked} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
