import { parseISO } from 'date-fns';
import { gatewayApi } from '../gatewayApi';
import { NewRuleVeto, RuleVeto } from '../../features/Rules/types';

const ruleVetosApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAllVetos: build.query({
      query: (params) => ({
        url: `rules/vetos`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'RuleVetos', id: 'LIST' }],
      transformResponse: (response) => ({
        responseFormated: response.content.map((item) => {
          item.ruleId = parseInt(`${item.ruleId}`, 10);
          return item;
        }),
      }),
    }),
    getRuleVetos: build.query<RuleVeto[], { ruleId: string }>({
      query: ({ ruleId }) => ({
        url: `rules/veto/${ruleId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'RuleVetos', id: 'LIST' }],
      transformResponse: (response) =>
        response.sort((a, b) => parseISO(a.createdDate) - parseISO(b.createdDate)),
    }),
    addRuleVeto: build.mutation<any, NewRuleVeto | RuleVeto>({
      query: (params) => ({
        url: `rules/veto/${params.ruleId}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: 'RuleVetos', id: 'LIST' }],
    }),
    deleteRuleVeto: build.mutation<any, string>({
      query: (id) => ({
        url: `rules/veto/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'RuleVetos', id: 'LIST' }],
    }),

    addRuleVetoComment: build.mutation<any, { ruleId: string; id: string; body: string }>({
      query: ({ ruleId, id, body }) => ({
        url: `rules/veto/${ruleId}/${id}/comments`,
        method: 'PUT',
        body: { comment: body },
      }),
      invalidatesTags: [{ type: 'RuleVetos', id: 'LIST' }],
    }),
    deleteRuleVetoComment: build.mutation({
      query: ({ ruleId, id }) => ({
        url: `rules/veto/${ruleId}/${id}/comments`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'RuleVetos', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAllVetosQuery,
  useGetRuleVetosQuery,
  useAddRuleVetoMutation,
  useDeleteRuleVetoMutation,
  useAddRuleVetoCommentMutation,
  useDeleteRuleVetoCommentMutation,
} = ruleVetosApi;
