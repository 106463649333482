import { createSelector } from 'reselect';
import { initialState } from '../../../reducers/ValidatorTransactions/ValidatorTransactions/validatorTransactons';

const validatorTransactionState = (state) => state.validatorTransactions || initialState;
const makeValidatorTransactionsData = () =>
  createSelector(validatorTransactionState, (state) => state.validatorTransactions);

const makePaginationInfoData = () =>
  createSelector(validatorTransactionState, (state) => state.paginationInfo);
const makeValidatorTransactionsLoading = () =>
  createSelector(validatorTransactionState, (state) => state.loading || false);

const makeSelectedTransactionIndex = () =>
  createSelector(validatorTransactionState, (state) => state.selectedTransactionIndex || false);

const makeSelectedValidatorTransactionCardData = () =>
  createSelector(validatorTransactionState, (state) => state.selectedCardData);
const makeSelectedValidatorTransactionCardDataLoading = () =>
  createSelector(validatorTransactionState, (state) => state.selectedCardDataLoading || false);

export {
  makeValidatorTransactionsData,
  makeValidatorTransactionsLoading,
  makePaginationInfoData,
  makeSelectedValidatorTransactionCardDataLoading,
  makeSelectedValidatorTransactionCardData,
  makeSelectedTransactionIndex,
};
