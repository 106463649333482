// these fields are epoch date fields in transaction data
// that comes from server which need to be formatted
const dateTimeFields = [
  ' transmissionDateTime',
  ' localTransactionDateTime',
  ' merchant registrationDate',
  ' actionDate',
  ' customer registrationDate',
];

export default dateTimeFields;
