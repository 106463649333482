import { Node } from './Node';

export class PropertyNode extends Node {
  constructor(property) {
    super();
    this.property = property;
  }

  compute(ctx) {
    // console.log(ctx);
    return ctx[this.property];
  }

  toString() {
    return String(this.property);
  }

  toDDFConditionNode() {
    return { when: this.property };
  }
}
