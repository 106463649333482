import React from 'react';
import { Outlet } from 'react-router-dom';
import { Typography, Box, Divider, Stack } from '@mui/material';
import { Panel, Tab, TabContainer, TabLink } from 'uikit';

interface ITabsShowcaseProps {}

export const TabsShowcase: React.FC<ITabsShowcaseProps> = () => (
  <Box mb={8}>
    <Typography variant="h2" gutterBottom mb={4}>
      Tabs
    </Typography>

    <Panel>
      <Typography variant="h4" gutterBottom>
        Tabs without routing
      </Typography>
      <Divider sx={{ mb: 6 }} />

      <TabContainer>
        <Tab key="tabInfo" title="Info">
          Info
        </Tab>
        <Tab key="tabDocs" title="Docs">
          Docs
        </Tab>
      </TabContainer>
    </Panel>

    <div style={{ padding: '2rem 0' }}>
      <TabContainer direction="vertical">
        <Tab key="tabInfo" title="Info">
          <Box py={4}>Info</Box>
        </Tab>
        <Tab key="tabDocs" title="Docs">
          <Box py={4}>Documents</Box>
        </Tab>
        <Tab key="tabForms" title="Forms">
          <Box py={4}>Openpayd forms</Box>
        </Tab>
        <Tab key="tabUsers" title="Users">
          <Box py={4}>Openpayd users</Box>
        </Tab>
      </TabContainer>
    </div>

    <Panel>
      <Typography variant="h4" gutterBottom>
        Tabs with routing
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Stack
        direction="row"
        gap={6}
        sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[100]}` })}
      >
        <TabLink link="/sc/tab1">Link 1</TabLink>
        <TabLink link="/sc/tab2">Link 2</TabLink>
      </Stack>
      <Outlet />
    </Panel>
  </Box>
);
