import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import { createStructuredSelector } from 'reselect';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage } from 'react-intl';
import 'react-dates/lib/css/_datepicker.css';
import { Button } from '../../components';
import { fetchRuleSetStats, changeRuleSetStatsDateRange } from '../../actions/ruleSetStats';
import { makeSelectedProductIdData } from '../../selectors/user';
import { makeRuleSetStatsDateRangeData } from '../../selectors/ruleSetStats';
import messages from './messages';

class FilterWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: {
        endDate: false,
        startDate: false,
      },
      filter: props.ruleSetStatsDateRange,
    };
  }

  componentWillMount() {
    if (this.props.selectedProductId) {
      this.fetchRuleSetStats();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedProductId &&
      this.props.selectedProductId !== nextProps.selectedProductId
    ) {
      this.fetchRuleSetStats();
    }
  }

  searchForm(e) {
    e.preventDefault();
    this.fetchRuleSetStats();
  }

  fetchRuleSetStats() {
    let { startDate, endDate } = this.state.filter;
    startDate = moment(startDate).format('YYYY-MM-DD 00:00:00');
    endDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
    if (moment(startDate).isSameOrBefore(moment(endDate))) {
      this.props.fetchRuleSetStats({ startDate, endDate });
    } else {
      toastr.error('Start date can not be later than end date');
    }
  }

  handleDateChanged(date, key) {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        [key]: date,
      },
    }));

    this.props.changeRuleSetStatsDateRange(this.state.filter);
  }

  handleFocusChanged(e, selectDateFocused) {
    this.setState((prevState) => ({
      focused: {
        [selectDateFocused]: !prevState.focused[selectDateFocused],
      },
    }));
  }

  render() {
    return (
      <div className="grid simple horizontal yellow">
        <div className="grid-title">
          <h4>
            <span>Filter Ruleset Statistics</span>
          </h4>
        </div>
        <div className="grid-body">
          <div className="searchFilter">
            <div className="row">
              <form onSubmit={(e) => this.searchForm(e)}>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="date_input_from">
                      <FormattedMessage {...messages.selectPickerDateTitle} />
                    </label>
                    <SingleDatePicker
                      id="date_input_from"
                      displayFormat="DD-MM-YYYY"
                      date={this.state.filter.startDate}
                      isOutsideRange={(date) => !date.isSameOrBefore(this.state.filter.endDate)}
                      focused={this.state.focused.startDate}
                      onDateChange={(e) => {
                        this.handleDateChanged(e, 'startDate');
                      }}
                      onFocusChange={(e) => {
                        this.handleFocusChanged(e, 'startDate');
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="date_input_to">
                      <FormattedMessage {...messages.selectPickerToTitle} />
                    </label>
                    <SingleDatePicker
                      id="date_input_to"
                      displayFormat="DD-MM-YYYY"
                      isOutsideRange={(date) => !date.isSameOrAfter(this.state.filter.startDate)}
                      date={this.state.filter.endDate}
                      focused={this.state.focused.endDate}
                      onDateChange={(e) => {
                        this.handleDateChanged(e, 'endDate');
                      }}
                      onFocusChange={(e) => {
                        this.handleFocusChanged(e, 'endDate');
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="filter-button-container">
                    <Button type="submit" buttonText="Search" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterWidget.propTypes = {
  fetchRuleSetStats: PropTypes.func,
  ruleSetStatsDateRange: PropTypes.object,
  changeRuleSetStatsDateRange: PropTypes.func,
  selectedProductId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  ruleSetStatsDateRange: makeRuleSetStatsDateRangeData(),
  selectedProductId: makeSelectedProductIdData(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchRuleSetStats: ({ startDate, endDate }) =>
    dispatch(fetchRuleSetStats({ startDate, endDate })),
  changeRuleSetStatsDateRange: ({ startDate, endDate }) =>
    dispatch(changeRuleSetStatsDateRange({ startDate, endDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterWidget);
