import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import validateInput from '../../../utils/validate-input';
import {
  fetchValidatorTransactions,
  fetchValidatorTransactionsCardData,
  fetchValidatorTransactionsForCard,
} from '../../../actions/ValidatorTransactions/ValidatorTransactions/validatorTransactions';
import {
  makePaginationInfoData,
  makeSelectedTransactionIndex,
  makeSelectedValidatorTransactionCardData,
  makeSelectedValidatorTransactionCardDataLoading,
  makeValidatorTransactionsData,
  makeValidatorTransactionsLoading,
} from '../../../selectors/ValidatorTransactions/ValidatorTransactions/validatorTransactions';
import { ValidatorTransactionsList } from './validatorTransactionsList';
import { makeSelectedProductIdData } from '../../../selectors/user';
import { Button, Input, Paging } from '../../../components';

export class ValidatorTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.getFilterInitialState(),
      cardId: '',
    };

    const user = JSON.parse(localStorage.getItem('user')) || {};
    const productId = user.email ? user.selectedProductId : null;
    this.tenant = { tenantId: productId };
  }

  componentWillMount() {
    if (this.tenant.tenantId) {
      this.props.fetchValidatorTransactions(this.tenant);
    } else if (this.props.selectedProductId) {
      this.tenant.tenantId = this.props.selectedProductId;
      this.props.fetchValidatorTransactions(this.tenant);
    }
  }

  getFilterInitialState() {
    return {
      referenceId: '',
      resultActions: '',
    };
  }

  getResultActions() {
    return [
      { value: 'OPEN', label: 'Open' },
      { value: 'PASS', label: 'Pass' },
      { value: 'REJECT', label: 'Reject' },
    ];
  }

  handlePageChange(page) {
    const { filter } = this.state;
    this.props.fetchValidatorTransactions(_.extend(this.tenant, filter, { page }));
  }

  getFilterValue(key) {
    return this.state.filter[key];
  }

  getCardFilterValue() {
    return this.state.cardId;
  }

  handleInputChanged(e, key) {
    if (validateInput(e)) {
      const { filter } = this.state;
      filter[key] = e.target.value;
      this.setState(filter);
    }
  }

  handleCardIdChanged(e) {
    if (validateInput(e)) {
      this.setState({
        cardId: e.target.value,
      });
    }
  }

  handleSelectChange(e, key) {
    let event = e;
    if (!event) {
      event = {};
    }
    const { filter } = this.state;
    filter[key] = event.value;
    this.setState(filter);
  }

  handleFormClear() {
    const initialFilterState = this.getFilterInitialState();
    this.setState({
      filter: initialFilterState,
    });
  }

  handleCardFormClear() {
    this.setState({
      cardId: '',
    });
  }

  searchForm(e) {
    e.preventDefault();
    const { filter } = this.state;
    this.props.fetchValidatorTransactions(_.extend(this.tenant, filter, { page: 0 }));
  }

  searchByCardIdForm(e) {
    e.preventDefault();
    const { cardId } = this.state;
    if (cardId !== '') {
      this.props.fetchValidatorTransactionsForCard(cardId);
    }
  }

  renderSearch() {
    return (
      <div className="grid simple horizontal yellow">
        <div className="grid-title">
          <h4>
            <span>Filter Validator Transactions</span>
          </h4>
        </div>
        <div className="grid-body">
          <div className="searchFilter">
            <form onSubmit={(e) => this.searchForm(e)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="transactionId">Reference ID</label>
                    <Input
                      type="text"
                      name="referenceId"
                      value={this.getFilterValue('referenceId')}
                      handleInputChanged={(e) => this.handleInputChanged(e, 'referenceId')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="resultActions">Result Action</label>
                    <Select
                      options={this.getResultActions()}
                      isClearable
                      onChange={(e) => {
                        this.handleSelectChange(e, 'resultActions');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Button type="submit" buttonText="Search" />
                  <Button
                    buttonText="Clear"
                    onClick={() => this.handleFormClear()}
                    buttonClass="btn btn-default btn-cons"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderGetByCardDataId() {
    return (
      <div className="grid simple horizontal yellow">
        <div className="grid-title">
          <h4>
            <span>Filter by Card Data ID</span>
          </h4>
        </div>
        <div className="grid-body">
          <div className="searchFilter">
            <form onSubmit={(e) => this.searchByCardIdForm(e)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="cardDataId">Card Data ID</label>
                    <Input
                      type="number"
                      name="cardDataId"
                      value={this.getCardFilterValue()}
                      handleInputChanged={(e) => this.handleCardIdChanged(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Button type="submit" buttonText="Search" />
                  <Button
                    buttonText="Clear"
                    onClick={() => this.handleCardFormClear()}
                    buttonClass="btn btn-default btn-cons"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderPaging() {
    const { paginationInfo } = this.props;

    if (!paginationInfo || (paginationInfo && paginationInfo.totalPageCount <= 1)) return null;
    return (
      <Paging
        activePage={paginationInfo.activePage + 1}
        currentPageSize={paginationInfo.currentPageSize}
        totalItemsCount={paginationInfo.totalItemsCount}
        handlePageChange={(activePage) => this.handlePageChange(activePage - 1)}
      />
    );
  }

  render() {
    // eslint-disable-next-line no-shadow
    const {
      validatorTransactions,
      loading,
      selectedTransactionIndex,
      selectedCardData,
      selectedCardDataLoading,
      fetchValidatorTransactionsCardData,
    } = this.props;
    return (
      <div className="page-content">
        <Helmet title="Validator Transactions" />
        <div className="content container">
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h3>Validator Transactions</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-6">{this.renderSearch()}</div>
              <div className="col-sm-12 col-md-6">{this.renderGetByCardDataId()}</div>
              <div className="col-md-12">
                <div className="grid simple">
                  <div className="grid-title">
                    <h4>
                      <span>Validator Transactions</span>
                    </h4>
                  </div>
                  <div className="grid-body p-0">
                    <ValidatorTransactionsList
                      validatorTransactions={validatorTransactions}
                      loading={loading}
                      fetchValidatorTransactionsCardData={fetchValidatorTransactionsCardData}
                      selectedCardData={selectedCardData}
                      selectedCardDataLoading={selectedCardDataLoading}
                      selectedTransactionIndex={selectedTransactionIndex}
                    />
                    {this.renderPaging()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ValidatorTransactions.propTypes = {
  validatorTransactions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  paginationInfo: PropTypes.object,
  selectedCardData: PropTypes.object,
  selectedCardDataLoading: PropTypes.bool,
  loading: PropTypes.bool,
  fetchValidatorTransactions: PropTypes.func,
  fetchValidatorTransactionsForCard: PropTypes.func,
  fetchValidatorTransactionsCardData: PropTypes.func,
  selectedProductId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  validatorTransactions: makeValidatorTransactionsData(),
  paginationInfo: makePaginationInfoData(),
  loading: makeValidatorTransactionsLoading(),
  selectedTransactionIndex: makeSelectedTransactionIndex(),
  selectedCardData: makeSelectedValidatorTransactionCardData(),
  selectedCardDataLoading: makeSelectedValidatorTransactionCardDataLoading(),
  selectedProductId: makeSelectedProductIdData(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchValidatorTransactions: (filter) => dispatch(fetchValidatorTransactions(filter)),
  fetchValidatorTransactionsForCard: (id) => dispatch(fetchValidatorTransactionsForCard(id)),
  fetchValidatorTransactionsCardData: (id, index) =>
    dispatch(fetchValidatorTransactionsCardData(id, index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidatorTransactions);
