import figmaPrimitives from './figmaPrimitives.json';

const createFigmaVariables = () => {
  const { color } = figmaPrimitives;
  const { spacing } = figmaPrimitives;
  const { radius } = figmaPrimitives;
  const { breakpoints } = figmaPrimitives;

  return {
    primitives: {
      color,
      spacing,
      radius,
      breakpoints,
    },
    theme: {
      text: {
        'text-base': color['grey-blue']['grey-blue-900'],
        'text-base:light': color['grey-blue']['grey-blue-700'],
        'text-muted': color['grey-blue']['grey-blue-600'],
        'text-placeholder': color['grey-blue']['grey-blue-500'],
        'text-disabled': color['grey-blue']['grey-blue-500'],
        'text-inverted': color.base.white,
        'text-inverted-muted': color['grey-blue']['grey-blue-500'],
        'text-inverted-secondary': color['grey-blue']['grey-blue-400'],
        'text-link': color.blue['blue-900'],
        'text-error': color.red['red-700'],
        'text-success': color.green['green-900'],
        'text-primary': color.orange['orange-700'],
      },
      panel: {
        default: {
          'panel-transparent': color.base.transparent,
          'panel-default-50': color['grey-blue']['grey-blue-050'],
          'panel-default-100': color.base.white,
          'panel-default-200': color['grey-blue']['grey-blue-100'],
          'panel-default-300': color['grey-blue']['grey-blue-200'],
        },
        base: {
          'panel-base-100': color['grey-blue']['grey-blue-100'],
          'panel-base-200': color['grey-blue']['grey-blue-200'],
          'panel-base-300': color['grey-blue']['grey-blue-300'],
        },
        primary: {
          'panel-primary-100': color.orange['orange-700'],
          'panel-primary-200': color.orange['orange-800'],
          'panel-primary-300': color.orange['orange-900'],
        },
        'primary:light': {
          'panel-primary-light-100': color.orange['orange-100'],
          'panel-primary-light-200': color.orange['orange-200'],
          'panel-primary-light-300': color.orange['orange-300'],
        },
        secondary: {
          'panel-secondary-100': color.blue['blue-100'],
          'panel-secondary-200': color.blue['blue-200'],
          'panel-secondary-300': color.blue['blue-300'],
        },
        contrast: {
          'panel-contrast-100': color['grey-blue']['grey-blue-700'],
          'panel-contrast-200': color['grey-blue']['grey-blue-800'],
          'panel-contrast-300': color['grey-blue']['grey-blue-900'],
          'panel-contrast-400': color['grey-blue']['grey-blue-910'],
        },
        error: {
          'panel-error-100': color.red['red-700'],
          'panel-error-200': color.red['red-800'],
          'panel-error-300': color.red['red-900'],
        },
        warning: {
          'panel-warning-100': color.red['red-100'],
          'panel-warning-200': color.red['red-200'],
          'panel-warning-300': color.red['red-300'],
        },
        success: {
          'panel-success-100': color.green['green-100'],
          'panel-success-200': color.green['green-200'],
          'panel-success-300': color.green['green-300'],
        },
        'op-panel-primary-main': {
          'op-panel-primary-100': color.orange['orange-700'],
          'op-panel-primary-200': color['grey-blue']['grey-blue-700'],
          'op-panel-primary-300': color['grey-blue']['grey-blue-800'],
          'op-panel-primary-disabled': color['grey-blue']['grey-blue-400'],
        },
        'op-panel-primary-dark': {
          'op-panel-primary-100': color['grey-blue']['grey-blue-700'],
          'op-panel-primary-200': color['grey-blue']['grey-blue-600'],
          'op-panel-primary-300': color['grey-blue']['grey-blue-600'],
          'op-panel-primary-disabled': color['grey-blue']['grey-blue-400'],
        },
        'op-panel-success': {
          'op-panel-success-100': '#45CB29',
        },
      },
      line: {
        'line-base+': color['grey-blue']['grey-blue-300'],
        'line-base-outline+': color['grey-blue']['grey-blue-700'],
        'line-base-outline-disabled': color['grey-blue']['grey-blue-400'],
        'line-base:Light': color['grey-blue']['grey-blue-200'],
        'line-base:Dark+': color['grey-blue']['grey-blue-600'],
        'line-error': color.red['red-600'],
        'line-primary': color.orange['orange-600'],
        'line-inverted': color['grey-blue']['grey-blue-800'],
        'line-inverted:Dark': color['grey-blue']['grey-blue-910'],
        'line-inverted:Light': color['grey-blue']['grey-blue-600'],
        'line-success': color.green['green-500'],
        'line-secondary': color.blue['blue-300'],
        'line-warning': color.red['red-300'],
      },
      special: {
        variant: '#9747FF',
        'figma-bg-proto-sections': '#62c3a185',
        'figma-bg-dev': '#9BACB9',
        'figma-bg-components': '#ffffff33',
        'figma-bg-sandbox': '#82bbb14d',
      },
      spacing: {
        zero: spacing['space-0'],
        'spacing-xs': spacing['space-1-4'],
        'spacing-sm': spacing['space-2-8'],
        'spacing-md': spacing['space-3-12'],
        'spacing-lg': spacing['space-4-16'],
        'spacing-xl': spacing['space-5-20'],
        'spacing-2xl': spacing['space-8-32'],
        'spacing-3xl': spacing['space-9-40'],
        forms: {
          'X-pad': spacing['space-6-24'],
          'Y-pad': spacing['space-624'],
          GAP: spacing['space-5-20'],
          'sub-X-pad': spacing['space-5-20'],
          'sub-Y-pad': spacing['space-5-20'],
          'sub-GAP': spacing['space-5-20'],
        },
        fields: {
          'gap-Y': spacing['space-2-8'],
          'gap-X': spacing['space-3-12'],
        },
      },
    },
  };
};

export const figmaVariables = createFigmaVariables();

export type TextColorKey = keyof typeof figmaVariables.theme.text;
export const textColor = (color: TextColorKey): string => figmaVariables.theme.text[color];

export type LineColorKey = keyof typeof figmaVariables.theme.line;
export const lineColor = (color: LineColorKey): string => figmaVariables.theme.line[color];

export type GreyBlueColorKey = keyof typeof figmaVariables.primitives.color['grey-blue'];
export const greyBlueColor = (color: GreyBlueColorKey): string =>
  figmaVariables.primitives.color['grey-blue'][color];

export type FigmaPrimitivesColor = keyof typeof figmaVariables.primitives.color;
export const FigmaColor = ({
  colorName,
  shade,
}: {
  colorName: FigmaPrimitivesColor;
  shade: number | string;
}): string => {
  const colorShadeKey =
    `${colorName}-${shade}` as keyof typeof figmaVariables.primitives.color[FigmaPrimitivesColor];
  return figmaVariables.primitives.color[colorName][colorShadeKey];
};

export type BreakpointsKey = keyof typeof figmaVariables.primitives.breakpoints;
export const getBreakpoint = (key: BreakpointsKey): number =>
  figmaVariables.primitives.breakpoints[key];
