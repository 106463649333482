import React, { useState } from 'react';
import { Panel, Select, ISelectOption } from 'uikit';
import { Box, Typography } from '@mui/material';
import countries from '../constants/countries';

export function SelectorsSection() {
  const [selectedValue, setSelectedValue] = useState<any>(null);

  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        Custom selector
      </Typography>
      <Panel>
        <Box mb={4}>
          <Select
            placeholder="Country"
            name="country"
            onChange={(
              option: ISelectOption,
              // event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
            ) => {
              setSelectedValue(option.value);
            }}
            value={countries.find((c) => c.value === selectedValue)}
            options={countries}
          />
        </Box>

        <Select
          placeholder="Country"
          label="Country"
          selectBox
          name="country"
          onChange={(
            option: ISelectOption,
            // event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
          ) => {
            setSelectedValue(option.value);
          }}
          value={countries.find((c) => c.value === selectedValue)}
          options={countries}
        />
      </Panel>
    </Box>
  );
}
