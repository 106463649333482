/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_TRANSACTION_CURRENCIES,
  LOAD_TRANSACTION_CURRENCIES_SUCCESS,
  LOAD_TRANSACTION_CURRENCIES_FAIL,
} from '../constants/constants';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  transactionCurrencies: [],
};

const transactionCurrenciesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_TRANSACTION_CURRENCIES:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_TRANSACTION_CURRENCIES_SUCCESS:
        draft.transactionCurrencies = action.transactionCurrencies;
        draft.loading = false;
        break;
      case LOAD_TRANSACTION_CURRENCIES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default transactionCurrenciesReducer;
