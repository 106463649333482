import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RiArrowRightCircleLine, RiArrowRightFill, RiFileCopyLine } from 'react-icons/ri';
import { ArrowBack, Skeleton, TabContainer, Tab } from 'uikit';
import { useGetCaseQuery, useGetCaseCommentsQuery } from 'services/gatewayApi/casesApi';
import { User } from 'features/UsersManagement/types';
import { TOOLTIP_DELAY } from 'constants/constants';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import { OverviewTab } from './OverviewTab';
import { CommentsTab } from './CommentsTab';
import { AssigneeChip, CaseStatusChip, RiskLevelChip } from '../Chips';
import { Case } from '../types';
import { grey } from '../../../theme/palette-blocks';

const StyledTitle = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 700,
})) as typeof Typography;

interface CaseDetailsSidebarProps {
  caseId: string;
  users: User[];
  onClose: any;
}

export const CaseDetailsSidebar: React.FC<CaseDetailsSidebarProps> = ({
  caseId,
  users,
  onClose,
}) => {
  const {
    data: caseData = {} as Case,
    isLoading,
    isFetching,
  } = useGetCaseQuery(caseId, { skip: !caseId });

  const {
    caseStatus,
    caseType,
    companyName,
    description,
    referenceId,
    haystackClientId,
    senderName,
    beneficiaryName,
    riskLevel,
    transactionAmount,
  } = caseData;

  const isApplicationType = caseType === 'APPLICATION';

  const {
    data: comments,
    isLoading: isLoadingComments,
    isFetching: isFetchingComments,
  } = useGetCaseCommentsQuery(caseId, { skip: !caseId || isApplicationType });

  const goToDetailsLink = isApplicationType
    ? `/application-management/${haystackClientId}/applications/${referenceId}`
    : `/cases/${caseId}`;

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <ArrowBack onClose={onClose} />

        {!(isLoading || isFetching) && (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            {!isApplicationType && referenceId && (
              <Button
                variant="contained"
                color="base"
                onClick={() => {
                  navigator.clipboard.writeText(referenceId).then(
                    () => toastCopySuccess('External ID'),
                    () => toastCopyError('External ID'),
                  );
                }}
                startIcon={<RiFileCopyLine size="14px" />}
                size="small"
              >
                Copy External ID
              </Button>
            )}

            <Link to={goToDetailsLink}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<RiArrowRightFill size="14px" />}
                size="small"
              >
                Open {isApplicationType ? 'Application' : 'Reference'}
              </Button>
            </Link>
          </Stack>
        )}
      </Stack>

      {(isLoading || isFetching) && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!(isLoading || isFetching) && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ pt: 8, pb: 4 }}
          >
            {isApplicationType ? (
              <Typography variant="h2" sx={{ mb: 0 }}>
                {companyName ?? 'Unknown'}
              </Typography>
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ maxWidth: '75%' }}
                >
                  <Tooltip
                    title={`Sender Name: ${senderName || 'Unknown'}`}
                    enterDelay={TOOLTIP_DELAY}
                  >
                    <StyledTitle variant="h2" component="span">
                      {senderName || 'Unknown'}
                    </StyledTitle>
                  </Tooltip>
                  <RiArrowRightCircleLine size="14px" color={grey[500]} />
                  <Tooltip
                    title={`Beneficiary Name: ${beneficiaryName || 'Unknown'}`}
                    enterDelay={TOOLTIP_DELAY}
                  >
                    <StyledTitle variant="h2" component="span">
                      {beneficiaryName || 'Unknown'}
                    </StyledTitle>
                  </Tooltip>
                </Stack>
                <Typography
                  variant="h2"
                  component="span"
                  sx={{ width: '20%', mb: 0, whiteSpace: 'nowrap', fontWeight: 700 }}
                >
                  {transactionAmount}
                </Typography>
              </>
            )}
          </Stack>

          <Stack direction="row" alignItems="flex-start" spacing={2}>
            <AssigneeChip caseData={caseData} users={users} />
            <CaseStatusChip
              caseId={caseId}
              caseStatus={caseStatus}
              caseType={caseType}
              buttonVariant="contained"
            />
            {!isApplicationType && <RiskLevelChip riskLevel={riskLevel} />}
          </Stack>

          {description && (
            <Box sx={{ my: 6 }}>
              {description.split(';').map((el, idx) => (
                <Typography key={idx} component="div" variant="body">
                  {el}
                </Typography>
              ))}
            </Box>
          )}

          <TabContainer>
            <Tab key="overviewTab" title="Overview">
              <OverviewTab caseData={caseData} users={users} />
            </Tab>
            {!isApplicationType && (
              <Tab
                key="commentsTab"
                title="Comments"
                count={!(isLoadingComments || isFetchingComments) ? comments?.length : undefined}
              >
                <CommentsTab caseData={caseData} users={users} />
              </Tab>
            )}
          </TabContainer>
        </>
      )}
    </Box>
  );
};
