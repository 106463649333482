import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add as AddIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { testIbanChecksum } from 'utils';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { ModalDialog, ChipSelector } from 'uikit';
import { ValueLabel } from 'types';
import { IBAN_REGEX } from 'constants/constants';
import {
  useGetClientAccountTypesQuery,
  useAddClientAccountMutation,
  useUpdateClientAccountMutation,
  useUpdateClientAccountFlowFundMutation,
  useAddClientAccountFinActivityMutation,
  useUpdateClientAccountFinActivityMutation,
  useAddClientIbanMutation,
  useUpdateClientIbanMutation,
  useDeleteClientIbanMutation,
} from 'services/gatewayApi/clientsApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { initialAccount, textFieldProps } from '../../constants';
import { Client, ClientAccount, Iban } from '../../types';

interface AccountDialogProps {
  open: boolean;
  onClose: any;
  clientAccounts?: ClientAccount[];
  account: ClientAccount;
  categories: ValueLabel[];
  client: Client;
  setEdited: any;
}

export const AccountDialog: React.FC<AccountDialogProps> = ({
  open,
  onClose,
  clientAccounts,
  account,
  categories,
  client,
  setEdited,
}) => {
  const isNew: boolean = account.id === 'new';

  const hasFinActivity = !!account.monthlyTransactions;
  const [showFinActivity, setShowFinActivity] = useState(hasFinActivity);

  const [addClientAccount] = useAddClientAccountMutation();
  const [updateClientAccount] = useUpdateClientAccountMutation();
  const [addClientAccountIban] = useAddClientIbanMutation();
  const [updateClientAccountIban] = useUpdateClientIbanMutation();
  const [deleteClientAccountIban] = useDeleteClientIbanMutation();

  const [updateClientAccountFlowFunds] = useUpdateClientAccountFlowFundMutation();
  const [addClientAccountFinActivity] = useAddClientAccountFinActivityMutation();
  const [updateClientAccountFinActivity] = useUpdateClientAccountFinActivityMutation();

  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        countries: data?.map((el) => ({
          value: el.countryCode,
          label: `${el.countryCode}: ${el.name}`,
        })),
        isLoadingCountries: isLoading,
      }),
    },
  );

  const { clientAccountTypes, isLoadingTypes } = useGetClientAccountTypesQuery(
    { undefined },
    {
      selectFromResult: ({ data, isLoading }) => ({
        clientAccountTypes: data?.map((el) => ({
          value: el.key,
          label: el.value,
        })),
        isLoadingTypes: isLoading,
      }),
    },
  );
  const [accountTypes, setAccountTypes] = useState<ValueLabel[]>([]);
  useEffect(() => {
    clientAccountTypes &&
      setAccountTypes(
        clientAccountTypes.filter(
          (el) =>
            !clientAccounts?.some((account) => account?.accountType === el.value) ||
            el.value === account?.accountType,
        ),
      );
  }, [isLoadingTypes]);

  const validationSchema = yup.object({
    accountType: yup.string().required('Required'),
    accountCategories: yup.array().of(yup.string()),

    // Financial Activity
    averageInbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    averageOutbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    maxInbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    maxOutbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    monthlyTransactions: showFinActivity ? yup.number().required('Required') : yup.string(),
    monthlyTurnover: showFinActivity ? yup.number().required('Required') : yup.string(),

    ibans: yup.array().of(
      yup.object().shape({
        ibanCode: yup
          .string()
          .required('required')
          .min(5, 'Minimum length is ${min}')
          .max(35, 'Maximum length is ${max}')
          .matches(IBAN_REGEX, 'IBAN is not in correct format')
          .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value)),
        ibanCategories: yup.array().of(
          yup.object().shape({
            value: yup.number(),
            label: yup.string(),
          }),
        ),
      }),
    ),
  });

  const getCountryCodeFromArray = (array, fundType, accessType) =>
    Array.isArray(array) ? array?.filter((el) => el.fundType === fundType && el.accessType === accessType)
      .map((el) => el.countryCode) : [];

  const initialFlowFundsReceiptAllowed = getCountryCodeFromArray(account.flowFunds, 'RECEIPT', 'ALLOWED');
  const initialFlowFundsPaymentAllowed = getCountryCodeFromArray(account.flowFunds, 'PAYMENT', 'ALLOWED');
  const initialFlowFundsReceiptRestricted = getCountryCodeFromArray(account.flowFunds, 'RECEIPT', 'RESTRICTED');
  const initialFlowFundsPaymentRestricted = getCountryCodeFromArray(account.flowFunds, 'PAYMENT', 'RESTRICTED');

  const formatFlowFundsArray = (array, fundType, accessType) =>
    Array.isArray(array) ? array.map((el) => ({ fundType, countryCode: el, accessType })) : [];

  const initialValues = isNew
    ? {
      ...initialAccount,
      flowFundsReceiptAllowed: [],
      flowFundsPaymentAllowed: [],
      flowFundsReceiptRestricted: [],
      flowFundsPaymentRestricted: [],
    }
    : {
      ...account,
      accountCategories: account.accountCategories?.map((el) => el.id),

      ibans: account.ibans?.map((iban) => ({
        ...iban,
        ibanCategories: iban.ibanCategories?.map((category) => ({
          value: category.id,
          label: category.name,
        })),
      })),

      flowFundsReceiptAllowed: initialFlowFundsReceiptAllowed,
      flowFundsPaymentAllowed: initialFlowFundsPaymentAllowed,
      flowFundsReceiptRestricted: initialFlowFundsReceiptRestricted,
      flowFundsPaymentRestricted: initialFlowFundsPaymentRestricted,
    };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {

      const finActivityBody = {
        averageInbound: values.averageInbound,
        averageOutbound: values.averageOutbound,
        maxInbound: values.maxInbound,
        maxOutbound: values.maxOutbound,
        monthlyTransactions: values.monthlyTransactions,
        monthlyTurnover: values.monthlyTurnover,
      };

      const flowFunds = [
        ...formatFlowFundsArray(values.flowFundsReceiptAllowed, 'RECEIPT', 'ALLOWED'),
        ...formatFlowFundsArray(values.flowFundsPaymentAllowed, 'PAYMENT', 'ALLOWED'),
        ...formatFlowFundsArray(values.flowFundsReceiptRestricted, 'RECEIPT', 'RESTRICTED'),
        ...formatFlowFundsArray(values.flowFundsPaymentRestricted, 'PAYMENT', 'RESTRICTED'),
      ];

      if (isNew) {
        const body = {
          clientId: client.id,
          accountType: values.accountType,
          categoriesId: values.accountCategories,
          ibans: values.ibans.map((el) => ({
            ibanCode: el.ibanCode.toUpperCase(),
            categoriesId: el.ibanCategories.map((v) => v.value),
          })),
        };

        addClientAccount({ body })
          .unwrap()
          .then((response) => {
            const accountId = response.id;
            toastCreateSuccess('Client Account');

            // Add Fin Activity
            if (showFinActivity) {
              addClientAccountFinActivity({
                accountId,
                body: { ...finActivityBody, flowFunds },
              });
            }

            setEdited(true);
            resetForm();
            onClose();
          });

      } else {
        const accountId = account.id;

        const accountBody = {
          clientId: client.id,
          categoriesId: values.accountCategories,
          accountType: values.accountType,
        };

        const addIbansBody = (values.ibans as Iban[]).filter(
          (el) => !account.ibans?.some((element) => element.id === el.id),
        );

        const removeIbansBody = account.ibans.filter(
          (el) => !values.ibans?.some((element) => element.id === el.id),
        );

        const checkIsModified = (iban) => {
          const prevIban = account.ibans.find((el) => el.id === iban.id);
          const prevCategories = prevIban.ibanCategories.map((el) => ({
            value: el.id,
            label: el.name,
          }));
          const prev = { ...prevIban, ibanCategories: prevCategories };
          return !_.isEqual(iban, prev);
        };

        const updateIbanBody = (values.ibans as Iban[]).filter(
          (el) => account.ibans?.some((element) => element.id === el.id) && checkIsModified(el),
        );

        if (
          !_.isEqual(
            {
              clientId: account.client.id,
              categoriesId: account.accountCategories.map((el) => el.id),
              accountType: account.accountType,
            },
            accountBody,
          )
        ) {
          const mutationPromise = await updateClientAccount({
            accountId: account.id,
            body: accountBody,
          });
          // @ts-ignore
          if (!mutationPromise.error) {
            toastUpdateSuccess('Client Account');
          }
        }

        // Add Fin Activity
        if (!hasFinActivity && showFinActivity) {
          addClientAccountFinActivity({
            accountId,
            clientId: client.id,
            body: { ...finActivityBody, flowFunds },
          });
        }

        // Update existing Fin Activity
        if (hasFinActivity && showFinActivity) {
          updateClientAccountFinActivity({
            accountId,
            clientId: client.id,
            body: finActivityBody,
          });

          if (!_.isEqual(initialFlowFundsReceiptAllowed, values.flowFundsReceiptAllowed)) {
            updateClientAccountFlowFunds({
              accountId,
              clientId: client.id,
              type: 'RECEIPT',
              body: { countryCode: values.flowFundsReceiptAllowed, accessType: 'ALLOWED' }
            });
          }
          if (!_.isEqual(initialFlowFundsPaymentAllowed, values.flowFundsPaymentAllowed)) {
            updateClientAccountFlowFunds({
              accountId,
              clientId: client.id,
              type: 'PAYMENT',
              body: { countryCode: values.flowFundsPaymentAllowed, accessType: 'ALLOWED' }
            });
          }
          if (!_.isEqual(initialFlowFundsReceiptRestricted, values.flowFundsReceiptRestricted)) {
            updateClientAccountFlowFunds({
              accountId,
              clientId: client.id,
              type: 'RECEIPT',
              body: { countryCode: values.flowFundsReceiptRestricted, accessType: 'RESTRICTED' }
            });
          }
          if (!_.isEqual(initialFlowFundsPaymentRestricted, values.flowFundsPaymentRestricted)) {
            updateClientAccountFlowFunds({
              accountId,
              clientId: client.id,
              type: 'PAYMENT',
              body: { countryCode: values.flowFundsPaymentRestricted, accessType: 'RESTRICTED' }
            });
          }
        }

        if (addIbansBody.length > 0) {
          for (const iban of addIbansBody) {
            const body = {
              accountId: account.id,
              ibanCode: iban.ibanCode.toUpperCase(),
              // @ts-ignore
              categoriesId: iban.ibanCategories.map((el) => el.value),
            };
            addClientAccountIban({ body });
          }
        }

        if (updateIbanBody.length > 0) {
          for (const iban of updateIbanBody) {
            const body = {
              accountId: account.id,
              ibanCode: iban.ibanCode.toUpperCase(),
              // @ts-ignore
              categoriesId: iban.ibanCategories.map((el) => el.value),
            };
            updateClientAccountIban({ body, ibanId: iban.id });
          }
        }

        if (removeIbansBody.length > 0) {
          for (const iban of removeIbansBody) {
            deleteClientAccountIban({ ibanId: iban.id });
          }
        }
      }

      resetForm();
      setEdited(true);
      onClose();
    },
  });

  const handleAddIban = () => {
    formik.setFieldValue('ibans', [...formik.values.ibans, { ibanCode: '', ibanCategories: [] }]);
  };

  const hasIbans = formik.values.ibans?.length > 0;

  const handleDeleteIban = (idx) => {
    formik.setFieldValue(
      'ibans',
      (formik.values.ibans as Iban[]).filter((el, index) => index !== idx),
    );
  };

  const checkErrors = (idx) => {
    if (
      Object.keys(formik.touched).length !== 0 &&
      Object.keys(formik.errors).length !== 0 &&
      Boolean(formik.errors.ibans)
    ) {
      // @ts-ignore
      return formik.touched.ibans[idx]?.ibanCode && Boolean(formik.errors.ibans[idx]?.ibanCode);
    }
    return false;
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={isNew ? 'Add account' : 'Edit account'}
      handleAction={() => formik.handleSubmit()}
      actionTitle={isNew ? 'Create' : 'Save'}
      isLoading={formik.isSubmitting}
    >
      <Stack spacing={4}>
        {isNew ? (
          <TextField
            id="select-account-type"
            select
            fullWidth
            name="accountType"
            label="Account type"
            value={formik.values.accountType}
            error={!!formik.errors.accountType}
            onChange={formik.handleChange}
            variant="standard"
            disabled={isLoadingTypes}
          >
            {accountTypes?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <Typography variant="subtitle1">{account.accountName}</Typography>
        )}

        <Autocomplete
          multiple
          id="clientCategories"
          options={categories}
          getOptionLabel={(option) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={categories.filter((option) =>
            formik.values.accountCategories?.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            formik.setFieldValue(
              'accountCategories',
              newValue.map(
                // @ts-ignore
                (nextValue) => nextValue.value,
              ),
            );
          }}
          ChipProps={{ color: 'secondary', size: 'small' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Categories"
              placeholder="Select account categories"
            />
          )}
        />

        {!showFinActivity && (
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setShowFinActivity(true)}
          >
            Provide financial activity info
          </Button>
        )}

        {showFinActivity && (
          <Stack spacing={4}>
            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Monthly turnover through OpenPayd (total value) (EUR)"
              name="monthlyTurnover"
              onChange={formik.handleChange}
              value={formik.values.monthlyTurnover}
              error={formik.touched.monthlyTurnover && Boolean(formik.errors.monthlyTurnover)}
              disabled={formik.isSubmitting}
            />

            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Transactions per month routed through OpenPayd (volume)"
              name="monthlyTransactions"
              onChange={formik.handleChange}
              value={formik.values.monthlyTransactions}
              error={formik.touched.monthlyTransactions && Boolean(formik.errors.monthlyTransactions)}
              disabled={formik.isSubmitting}
            />

            <Typography variant="subtitle1" sx={{ pt: 6 }}>
              Average Transaction Value Routed Through OpenPayd
            </Typography>

            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Inbound (EUR)"
              name="averageInbound"
              onChange={formik.handleChange}
              value={formik.values.averageInbound}
              error={formik.touched.averageInbound && Boolean(formik.errors.averageInbound)}
              disabled={formik.isSubmitting}
            />

            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Outbound (EUR)"
              name="averageOutbound"
              onChange={formik.handleChange}
              value={formik.values.averageOutbound}
              error={formik.touched.averageOutbound && Boolean(formik.errors.averageOutbound)}
              disabled={formik.isSubmitting}
            />

            <Typography variant="subtitle1" sx={{ pt: 6 }}>
              Maximum Transaction Value Routed Through OpenPayd
            </Typography>

            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Inbound (EUR)"
              name="maxInbound"
              onChange={formik.handleChange}
              value={formik.values.maxInbound}
              error={formik.touched.maxInbound && Boolean(formik.errors.maxInbound)}
              disabled={formik.isSubmitting}
            />

            {/* @ts-ignore */}
            <TextField
              {...textFieldProps}
              label="Outbound (EUR)"
              name="maxOutbound"
              onChange={formik.handleChange}
              value={formik.values.maxOutbound}
              error={formik.touched.maxOutbound && Boolean(formik.errors.maxOutbound)}
              disabled={formik.isSubmitting}
            />

            <Typography variant="subtitle1" sx={{ pt: 6 }}>
              Breakdown of Flow of Funds per Country to be routed through OpenPayd
            </Typography>
            <Autocomplete
              multiple
              id="flowFundsReceiptAllowed"
              options={countries}
              getOptionLabel={(option) => option.label}
              limitTags={2}
              disableCloseOnSelect
              fullWidth
              value={countries.filter((option) =>
                // @ts-ignore
                formik.values.flowFundsReceiptAllowed?.some((v) => option.value === v),
              )}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'flowFundsReceiptAllowed',
                  newValue.map(
                    // @ts-ignore
                    (nextValue) => nextValue.value,
                  ),
                );
              }}
              ChipProps={{ color: 'secondary', size: 'small' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Receipt countries"
                  placeholder="Select receipt countries"
                />
              )}
            />
            <Autocomplete
              multiple
              id="flowFundsPaymentAllowed"
              options={countries}
              getOptionLabel={(option) => option.label}
              limitTags={2}
              disableCloseOnSelect
              fullWidth
              value={countries.filter((option) =>
                // @ts-ignore
                formik.values.flowFundsPaymentAllowed?.some((v) => option.value === v),
              )}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'flowFundsPaymentAllowed',
                  // @ts-ignore
                  newValue.map((nextValue) => nextValue.value),
                );
              }}
              ChipProps={{ color: 'secondary', size: 'small' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Payment countries"
                  placeholder="Select payment countries"
                />
              )}
            />

            <Typography variant="subtitle1" sx={{ pt: 6 }}>
              Breakdown of Flow of Funds per Country
              <Typography variant="subtitle1" component="span" color="error" sx={{ fontWeight: 600 }}> NOT </Typography>
              to be routed through OpenPayd
            </Typography>
            <Autocomplete
              multiple
              id="flowFundsReceiptRestricted"
              options={countries}
              getOptionLabel={(option) => option.label}
              limitTags={2}
              disableCloseOnSelect
              fullWidth
              value={countries.filter((option) =>
                // @ts-ignore
                formik.values.flowFundsReceiptRestricted?.some((v) => option.value === v),
              )}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'flowFundsReceiptRestricted',
                  newValue.map(
                    // @ts-ignore
                    (nextValue) => nextValue.value,
                  ),
                );
              }}
              ChipProps={{ color: 'secondary', size: 'small' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Receipt countries"
                  placeholder="Select receipt countries"
                />
              )}
            />
            <Autocomplete
              multiple
              id="flowFundsPaymentRestricted"
              options={countries}
              getOptionLabel={(option) => option.label}
              limitTags={2}
              disableCloseOnSelect
              fullWidth
              value={countries.filter((option) =>
                // @ts-ignore
                formik.values.flowFundsPaymentRestricted?.some((v) => option.value === v),
              )}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  'flowFundsPaymentRestricted',
                  // @ts-ignore
                  newValue.map((nextValue) => nextValue.value),
                );
              }}
              ChipProps={{ color: 'secondary', size: 'small' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Payment countries"
                  placeholder="Select payment countries"
                />
              )}
            />
          </Stack>
        )}

        {hasIbans && (<>
          <Typography variant="subtitle1">Account IBANs</Typography>
          {formik.values.ibans?.map((iban, idx) => (
            <Box key={idx}>
              <TextField
                type="text"
                fullWidth
                label="IBAN"
                name={`ibans.${[idx]}.ibanCode`}
                onChange={formik.handleChange}
                value={formik.values.ibans[idx].ibanCode}
                variant="standard"
                error={checkErrors(idx)}
                disabled={formik.isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => handleDeleteIban(idx)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ChipSelector
                list={categories}
                selectedList={iban.ibanCategories}
                setSelectedList={(arr) =>
                  formik.setFieldValue(`ibans.${[idx]}.ibanCategories`, arr)
                }
                limitTags={2}
              />
            </Box>
          ))}
        </>)}

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleAddIban}
        >
          Add IBAN
        </Button>
      </Stack>
    </ModalDialog>
  );
};
