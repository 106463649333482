import React, { DragEventHandler } from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

enum VirusStatus {
  Pending = 'PENDING',
  Checked = 'CHECKED',
  Quarantined = 'QUARANTINED',
}

interface DocumentProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  showDropzone?: boolean;
  onDragEnter?: DragEventHandler;

  fileSize?: number;
  isRejected?: boolean;
  virusStatus?: VirusStatus;
  description?: string;
}

interface DocumentRootProps {
  showDropzone?: boolean;
  isRejected?: boolean;
  isUploaded: boolean;
  virusStatus?: VirusStatus;
  isUploading?: boolean;
  isEditing?: boolean;
}

const getBorderColor = ({
  showDropzone,
  isUploaded,
  isUploading,
  isEditing,
  isRejected,
  virusStatus,
}: {
  showDropzone?: boolean;
  isUploaded?: boolean;
  isUploading?: boolean;
  isEditing?: boolean;
  isRejected?: boolean;
  virusStatus?: string;
}): string => {
  if (isRejected || virusStatus === VirusStatus.Quarantined) {
    return 'danger.light';
  }
  if (showDropzone) {
    return 'primary.main';
  }
  if (isUploaded || isUploading) {
    return 'grey.200';
  }
  if (isEditing) {
    return 'grey.700';
  }
  return 'transparent';
};

const DocumentRoot = styled('div', {
  name: 'documentRoot',
  shouldForwardProp: (prop) =>
    prop !== 'sx' &&
    prop !== 'isUploaded' &&
    prop !== 'isUploading' &&
    prop !== 'isEditing' &&
    prop !== 'isRejected' &&
    prop !== 'virusStatus' &&
    // prop !== 'wrapperHeight' &&
    prop !== 'showDropzone',
})<DocumentRootProps>(
  ({
    showDropzone = false,
    isUploaded,
    isUploading = false,
    isEditing = false,
    isRejected,
    virusStatus,
    theme,
  }) =>
    theme.unstable_sx({
      width: '100%',
      height: 80,
      padding: showDropzone ? 0 : '10px 10px 10px 16px',
      borderWidth: showDropzone || isUploaded || isUploading || isEditing ? '1px' : 'none',
      // eslint-disable-next-line no-nested-ternary
      borderStyle: showDropzone
        ? 'dashed'
        : isUploaded || isUploading || isEditing
        ? 'solid'
        : 'none',
      borderColor: getBorderColor({
        showDropzone,
        isUploaded,
        isUploading,
        isEditing,
        isRejected,
        virusStatus,
      }),
      borderRadius: '0.5rem',
      backgroundColor:
        ((isRejected || virusStatus === VirusStatus.Quarantined) && 'grey.50') ||
        (showDropzone && 'white') ||
        (isUploaded || isEditing ? 'white' : 'grey.50'),
      ...(!isUploaded && { color: 'grey.600' }),
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
);

export const Document = ({
  fileSize = undefined,
  isRejected = false,
  virusStatus = VirusStatus.Checked,
  showDropzone = false,
  description = undefined,
  children,
  ...other
}) => (
  <Box display="flex" flexDirection="column" width="100%" className="documentWrapper">
    <DocumentRoot
      showDropzone={showDropzone}
      isUploaded={!!fileSize}
      isRejected={isRejected}
      virusStatus={virusStatus}
      {...other}
    >
      {children}
    </DocumentRoot>
    {description && (
      <Box
        className="documentDescription"
        sx={{ display: 'flex', mt: 2, mb: 4, color: 'text.secondary' }}
      >
        <InfoOutlinedIcon sx={{ fontSize: '18px' }} />
        <Typography variant="bodySmall" sx={{ ml: 2 }}>
          {description}
        </Typography>
      </Box>
    )}
  </Box>
);
