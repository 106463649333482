/*
// Form element wrapper
 */

import * as React from 'react';
import './FormBlock.scss';

export interface FormRowProps {
  children: React.ReactChildren | React.ReactChild | React.ReactChildren[] | React.ReactChild[];
}

export const FormBlock: React.FC<FormRowProps> = ({ children }) => (
  <div className="FormBlock">{children}</div>
);
