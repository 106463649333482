import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { gatewayApi } from '../gatewayApi';
import { authApi } from '../authApi';
import { Group, Permission, User } from '../../features/UsersManagement/types';
import { CustomerUser } from '../../features/ApplicationManagement/AppDetails/Tabs/ClientTab/UsersTab/types';

const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState();
const usersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query({
      query: () => 'users',
      providesTags: ['Users'],
      transformResponse: (users) => usersAdapter.setAll(usersAdapter.getInitialState(), users),
    }),

    getListUsers: build.query<User[], undefined>({
      query: () => 'users',
      providesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    getUser: build.query<User, { id: string }>({
      query: ({ id }) => `users/${id}?forceNoCache=true`,
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    addUser: build.mutation({
      query: (user) => ({
        url: 'users',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    updateUser: build.mutation({
      query({ id, body }) {
        return {
          url: `users/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    enableUser: build.mutation({
      query: (id) => ({
        url: `users/${id}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }, 'CustomerUsers'],
    }),
    disableUser: build.mutation({
      query: (id) => ({
        url: `users/${id}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }, 'CustomerUsers'],
    }),
    deleteUser: build.mutation({
      query(id) {
        return {
          url: `users/${id}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    addDepartments: build.mutation({
      query({ id, list }) {
        return {
          url: `users/${id}/departments/${list}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    addDepartmentsMulti: build.mutation({
      query({ tenantId, userId, departmentIds }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/departments/${departmentIds}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    removeDepartments: build.mutation({
      query({ id, list }) {
        return {
          url: `users/${id}/departments/${list}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    removeDepartmentsMulti: build.mutation({
      query({ tenantId, userId, departmentIds }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/departments/${departmentIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
      ],
    }),

    addTenants: build.mutation({
      query({ id, list, tenantId }) {
        return {
          url: `multi-tenants/${tenantId}/users/${id}/tenants/${list}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    removeTenants: build.mutation({
      query({ id, list, tenantId }) {
        return {
          url: `multi-tenants/${tenantId}/users/${id}/tenants/${list}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'User', id },
        { type: 'Users', id: 'LIST' },
      ],
    }),

    getPermissions: build.query<Permission[], any>({
      query: () => 'permissions',
      providesTags: ['Permissions'],
      transformResponse: (permissions) =>
        permissions?.map((permission) => ({ ...permission, value: false })),
    }),
    addPermissions: build.mutation({
      query({ id, list }) {
        return {
          url: `users/${id}/permissions/${list}`,
          method: 'PUT',
        };
      },
      invalidatesTags: [
        { type: 'Groups', id: 'LIST' },
        { type: 'Permissions', id: 'LIST' },
      ],
    }),
    removePermissions: build.mutation({
      query({ id, list }) {
        return {
          url: `users/${id}/permissions/${list}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        { type: 'Groups', id: 'LIST' },
        { type: 'Permissions', id: 'LIST' },
      ],
    }),

    sendActivationEmail: build.mutation({
      query: (id) => ({
        url: `users/${id}/reset-password`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    getDepartments: build.query({
      query: () => 'departments',
      providesTags: ['Departments'],
      transformResponse: (departments) =>
        departments.map((department) => ({ ...department, value: false })),
    }),
    getDepartmentsMulti: build.query({
      query: () => 'multi-tenants/departments',
      providesTags: ['Departments'],
    }),
    getTenants: build.query({
      query: () => 'tenants',
      providesTags: ['Tenants'],
      transformResponse: (tenants) => tenants.map((tenant) => ({ ...tenant, value: false })),
    }),
    /** groups (roles) actions */
    getGroupsMulti: build.query({
      query: () => 'multi-tenants/groups',
      providesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    getGroups: build.query<Group[], undefined>({
      query: () => 'groups',
      providesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    addGroups: build.mutation({
      query({ userId, groupsIds }) {
        return {
          url: `users/${userId}/groups/${groupsIds}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
        { type: 'CustomerUser' },
      ],
    }),
    addGroupsMulti: build.mutation({
      query({ tenantId, userId, groupIds }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/groups/${groupIds}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    removeGroups: build.mutation({
      query({ userId, groupsIds }) {
        return {
          url: `users/${userId}/groups/${groupsIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
        { type: 'CustomerUser' },
      ],
    }),
    removeGroupsMulti: build.mutation({
      query({ tenantId, userId, groupIds }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/groups/${groupIds}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
        { type: 'Users', id: 'LIST' },
      ],
    }),
    createGroupMultiTenant: build.mutation({
      query: ({ tenantId, body }) => ({
        url: `multi-tenants/${tenantId}/groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    createGroup: build.mutation({
      query: (body) => ({
        url: `groups`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    updateGroup: build.mutation({
      query: ({ id, body }) => ({
        url: `groups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    updateGroupMultiTenant: build.mutation({
      query: ({ tenantId, id, body }) => ({
        url: `multi-tenants/${tenantId}/groups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    deleteGroup: build.mutation({
      query: (id) => ({
        url: `groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    deleteGroupMultiTenant: build.mutation({
      query: ({ tenantId, id }) => ({
        url: `multi-tenants/${tenantId}/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),

    addGroupPermissions: build.mutation({
      query: ({ groupId, permissionsIds }) => ({
        url: `groups/${groupId}/permissions/${permissionsIds}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    addGroupPermissionsMultiTenant: build.mutation({
      query: ({ tenantId, id, permissionsIds }) => ({
        url: `multi-tenants/${tenantId}/groups/${id}/permissions/${permissionsIds}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    removeGroupPermissions: build.mutation({
      query: ({ groupId, permissionsIds }) => ({
        url: `groups/${groupId}/permissions/${permissionsIds}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),
    removeGroupPermissionsMultiTenant: build.mutation({
      query: ({ tenantId, id, permissionsIds }) => ({
        url: `multi-tenants/${tenantId}/groups/${id}/permissions/${permissionsIds}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }],
    }),

    addSuperAdminPermission: build.mutation({
      query({ tenantId, userId }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/superadmin`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Permissions'],
    }),
    removeSuperAdminPermission: build.mutation({
      query({ tenantId, userId }) {
        return {
          url: `multi-tenants/${tenantId}/users/${userId}/superadmin`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Permissions'],
    }),

    // Customer users
    getCustomerUsers: build.query<CustomerUser[], { applicationId: string }>({
      query: ({ applicationId }) => `applications/${applicationId}/customer-users`,
      providesTags: ['CustomerUsers'],
    }),
    getCustomerUser: build.query<CustomerUser, { applicationId: string; customerId: string }>({
      query: ({ applicationId, customerId }) =>
        `applications/${applicationId}/customer-users/${customerId}`,
      providesTags: ['CustomerUser'],
    }),
    createCustomerUser: build.mutation({
      query: ({ applicationId, body }) => ({
        url: `applications/${applicationId}/customer-users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CustomerUsers'],
    }),
    updateCustomerUser: build.mutation({
      query: ({ applicationId, customerId, body }) => ({
        url: `applications/${applicationId}/customer-users/${customerId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CustomerUsers', 'CustomerUser'],
    }),
    resetPasswordCustomerUser: build.mutation<any, { email: string }>({
      query: ({ email }) => ({
        url: `customer-users/reset-password/email/${email}`,
        method: 'PUT',
      }),
    }),

    // Openpayd users
    getOpenpaydUsers: build.query<any[], string>({
      query: (applicationId) => `applications/${applicationId}/openpayd-users`,
      providesTags: [{ type: 'OpenpaydUsers', id: 'LIST' }],
    }),
    approveUser: build.mutation({
      query({ applicationId, userId }) {
        return {
          url: `applications/${applicationId}/openpayd-users/${userId}/approve`,
          method: 'PUT',
        };
      },
      invalidatesTags: [{ type: 'OpenpaydUsers', id: 'LIST' }],
    }),
    rejectUser: build.mutation({
      query({ applicationId, userId, rejectReason }) {
        return {
          url: `applications/${applicationId}/openpayd-users/${userId}/reject`,
          method: 'PUT',
          body: { rejectReason },
        };
      },
      invalidatesTags: [{ type: 'OpenpaydUsers', id: 'LIST' }],
    }),
    pepAndSanctions: build.mutation({
      query: ({ applicationId, userId }) => ({
        url: `applications/${applicationId}/openpayd-users/${userId}/pep-and-sanctions`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'OpenpaydUsers', id: 'LIST' }],
    }),
    syncUser: build.mutation({
      query: ({ applicationId, userId }) => ({
        url: `applications/${applicationId}/openpayd-users/${userId}/customer-portal-sync`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'OpenpaydUsers', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetListUsersQuery,
  useGetUserQuery,
  useDisableUserMutation,
  useEnableUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,

  useGetDepartmentsQuery,
  useGetDepartmentsMultiQuery,
  useAddDepartmentsMutation,
  useAddDepartmentsMultiMutation,
  useRemoveDepartmentsMutation,
  useRemoveDepartmentsMultiMutation,

  useGetTenantsQuery,
  useAddTenantsMutation,
  useRemoveTenantsMutation,
  useGetPermissionsQuery,
  useAddPermissionsMutation,
  useRemovePermissionsMutation,
  useSendActivationEmailMutation,

  useGetGroupsQuery,
  useGetGroupsMultiQuery,
  useAddGroupsMutation,
  useAddGroupsMultiMutation,
  useRemoveGroupsMutation,
  useRemoveGroupsMultiMutation,

  useCreateGroupMutation,
  useCreateGroupMultiTenantMutation,
  useUpdateGroupMutation,
  useUpdateGroupMultiTenantMutation,
  useDeleteGroupMutation,
  useDeleteGroupMultiTenantMutation,
  useAddGroupPermissionsMutation,
  useAddGroupPermissionsMultiTenantMutation,
  useRemoveGroupPermissionsMutation,
  useRemoveGroupPermissionsMultiTenantMutation,
  useAddSuperAdminPermissionMutation,
  useRemoveSuperAdminPermissionMutation,

  useGetCustomerUsersQuery,
  useGetCustomerUserQuery,
  useCreateCustomerUserMutation,
  useUpdateCustomerUserMutation,
  useResetPasswordCustomerUserMutation,

  useGetOpenpaydUsersQuery,
  useApproveUserMutation,
  useRejectUserMutation,
  usePepAndSanctionsMutation,
  useSyncUserMutation,
} = usersApi;

export const fetchUser = createAsyncThunk('users/fetchUserAndToken', async (_, thunkAPI) => {
  const { data: userData } = await thunkAPI.dispatch(gatewayApi.endpoints.getUser.initiate());
  const { data: tokenData } = await thunkAPI.dispatch(authApi.endpoints.getToken.initiate());

  const user = { ...userData.user };
  user.auth = localStorage.getItem('token');
  localStorage.setItem('user', JSON.stringify(user));

  return { ...userData, tokenData };
});

export const userSlice = createSlice({
  name: 'user',
  initialState: [],
  reducers: {
    setProductId: (state, action) => {
      const productId = action.payload;
      localStorage.setItem('user', JSON.stringify({ ...state.user, productId }));
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.fulfilled, (state, action) => action.payload.user);
  },
});

export const selectLoggedInUserData = (state) => state.user || initialState;

export const selectUsersResult = usersApi.endpoints.getAllUsers.select();
const selectUsersData = createSelector(selectUsersResult, (usersResult) => usersResult.data);

export const { selectAll: selectAllUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => selectUsersData(state) ?? initialState,
);

export const { setProductId } = userSlice.actions;
