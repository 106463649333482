import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import {
  PageContainer,
  StickyHeader,
  PageControl,
  SortBlock,
  DetailsDrawer,
  HeadTableCell,
  SortByHeader,
} from 'uikit';
import {
  useGetClientsCategoriesQuery,
  useGetClientsQuery,
  useUpdateClientMutation,
} from 'services/gatewayApi/clientsApi';
import { SortParam } from 'types/types';
import { toastUpdateSuccess } from 'utils/toast';
import { USERNAMEFULL_REGEX } from 'constants/constants';
import { Client } from './types';
import { ClientDialog } from './ClientDialog';
import { ClientRow } from './ClientRow';
import { ClientDetails } from './ClientDetails';
import { initialClient, sortOptions } from './constants';

export const Clients = () => {
  const {
    data: clientsList = [],
    isLoading: isLoadingClients,
    isFetching,
  } = useGetClientsQuery({ size: 20000 });
  const { categories, isLoadingCategories } = useGetClientsCategoriesQuery(
    { undefined },
    {
      selectFromResult: ({ data, isLoading }) => ({
        categories: data?.map((el) => ({
          value: el.id,
          label: el.name,
        })),
        isLoadingCategories: isLoading,
      }),
    },
  );
  const [updateClient] = useUpdateClientMutation();

  const isLoading = isLoadingCategories || isLoadingClients;

  // Shows preloader before the requests
  const [isPreloading, setIsPreloading] = useState<boolean>(true);
  const [clientDialogOpen, setClientDialogOpen] = useState<boolean>(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [clientDetails, setClientDetails] = useState<Client>();
  const [filterCase, setFilterCase] = useState('');
  const [clients, setClients] = useState([]);
  const [errorInput, setErrorInput] = useState(false);
  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'createdDate',
    direction: 'desc',
  });

  // Switch preloaders control to the Query
  useEffect(() => setIsPreloading(false), [isLoadingClients]);

  const searchHandlerClients = (inputValue) => {
    if (inputValue.match(USERNAMEFULL_REGEX) || inputValue === '') {
      setErrorInput(false);
      return setFilterCase(inputValue);
    }
    return setErrorInput(true);
  };

  const searchFilter = (item) => {
    const categoryNames = item.clientCategories.map((el) => el.name).join();
    return (
      item.name?.toLowerCase().search(filterCase.toLowerCase()) > -1 || // find clients by name
      item.clientId?.search(filterCase) > -1 || // find clients by client ID
      item.createdBy?.toLowerCase().search(filterCase.toLowerCase()) > -1 || // find clients by createdBy name
      categoryNames?.toLowerCase().search(filterCase.toLowerCase()) > -1 // find clients by categoryName
    );
  };

  const handleUpdateClient = async (id, model) => {
    const mutationPromise = await updateClient({ body: model, id });
    // @ts-ignore
    if (!mutationPromise.error) {
      toastUpdateSuccess('Client');
    }
  };

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  useEffect(() => {
    if (clientsList?.content?.length > 0) {
      const list = clientsList.content.filter(searchFilter).sort((a, b) => {
        if (sortedBy.property === 'createdDate') {
          // @ts-ignore
          return new Date(a[sortedBy.property] || 0) - new Date(b[sortedBy.property] || 0);
        }
        if (a[sortedBy.property] > b[sortedBy.property]) {
          return 1;
        }
        if (a[sortedBy.property] < b[sortedBy.property]) {
          return -1;
        }
        return 0;
      });
      if (sortedBy.direction === 'desc') {
        return setClients(list.reverse());
      }
      return setClients(list);
    }
    return undefined;
  }, [isLoading, isFetching, filterCase, sortedBy]);

  return (
    <>
      <Helmet title="Clients" />
      <PageContainer>
        <StickyHeader>
          <PageControl
            title="Clients"
            primaryActionTitle="New Client"
            primaryAction={() => setClientDialogOpen(true)}
            searchValue={filterCase}
            searchHandler={searchHandlerClients}
            hasError={errorInput}
          />
          <SortByHeader
            count={clients.length}
            totalCount={clientsList.totalElements}
            sortOptions={sortOptions}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
          />
        </StickyHeader>

        <DetailsDrawer open={openDetailsDrawer}>
          <ClientDetails
            updateClient={handleUpdateClient}
            clientDetails={clientDetails}
            categories={categories}
            onClose={() => setOpenDetailsDrawer(false)}
          />
        </DetailsDrawer>

        <Table>
          <TableHead>
            <TableRow>
              {sortOptions
                .filter((option) => option.fieldName !== 'incorporationDate')
                .map((option) => (
                  <HeadTableCell key={option.fieldName}>
                    <SortBlock
                      property={option.fieldName}
                      sortedBy={sortedBy}
                      handleSort={handleSort}
                    >
                      {option.label}
                    </SortBlock>
                  </HeadTableCell>
                ))}
              <HeadTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {/* INITIAL PRELOADER */}
              {isPreloading ||
                (isLoadingClients && (
                  <TableRow>
                    <TableCell sx={{ borderBottom: 'none', mt: 3 }}>
                      <Typography>Loading...</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {/* CLIENTS ROWS */}
              {!isPreloading &&
                !isLoadingClients &&
                clients &&
                clients.map((client) => (
                  <ClientRow
                    key={client.id}
                    client={client}
                    selected={client.id === clientDetails?.id && openDetailsDrawer}
                    setClientDetails={setClientDetails}
                    updateClient={handleUpdateClient}
                    setOpenDetailsDrawer={setOpenDetailsDrawer}
                    categories={categories}
                    isLoadingCategories={isLoadingCategories}
                  />
                ))}
            </>
          </TableBody>
        </Table>
      </PageContainer>

      {clientDialogOpen && !isLoading && (
        <ClientDialog
          open={clientDialogOpen}
          onClose={() => setClientDialogOpen(false)}
          client={initialClient}
          categories={categories}
        />
      )}
    </>
  );
};
