/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';
import { prune, add, update, getRule, clone } from '../utils/treeOperations';
import { prepareRuleSetForTree } from '../utils/prepareRuleSet';
import {
  ADD_NEW_RULE_TO_RULESET,
  DELETE_RULE_FROM_RULESET,
  CLONE_RULE_FROM_RULESET,
  CHANGE_RULESET_DATA,
  SELECT_RULE,
  UNSELECT_RULE,
  SELECT_RULESET,
  UPDATE_RULE,
  UPDATE_RULESET_METADATA,
  SAVE_RULSET_TO_API,
  SAVE_RULSET_TO_API_FAIL,
  SAVE_RULSET_TO_API_SUCCESS,
  CHANGE_NEW_FORM_VALID_STATUS,
  CHANGE_NEW_FORM_CLOSE_ATTEMPT,
  EMPTY_RULESET_AND_RULES,
  LOAD_FUNCTION_LIST,
  LOAD_FUNCTION_LIST_SUCCESS,
  LOAD_FUNCTION_LIST_FAIL,
} from '../constants/constants';
import { UPDATE_RULE_SUCCESS_RULE_FORM } from '../containers/RuleBuilder/constants';

export const initialState = {
  currentRule: {},
  ruleSet: {
    prepared: true,
    fromApi: false,
    name: 'Name of Ruleset',
    description: 'This is description',
    active: false,
    returnOnReject: false,
    status: 'TEST',
    rules: [
      {
        answer: 'LOWRISK',
        description: 'This is description',
        script: '1==1',
        title: 'This is name',
        id: uuidV4(),
        children: [],
        expanded: true,
        categoryType: 'RISK',
        ruleCategoryId: 1,
        newRule: false,
        root: true,
        deleteId: true,
        seonRule: false,
        score: 0,
      },
    ],
  },
  rules: [],
  saving: false,
  newRuleFormValid: true,
  newRuleFormCloseAttempt: false,
  isRulesStateShouldUpdate: false,
  functions: [],
  ruleToReturn: {},
  loading: false,
  isRuleFormOpen: false,
  initialRuleFormValues: {
    answer: 'LOWRISK',
    description: 'This is description',
    script: '1==1',
    title: 'This is name',
    id: uuidV4(),
    children: [],
    expanded: true,
    categoryType: 'RISK',
    ruleCategoryId: '',
    newRule: false,
    root: true,
    deleteId: true,
    seonRule: false,
    score: 0,
  },
  ruleHistory: [],
  loadingHistory: true,
  vetosMerchant: [],
  vetosEmail: [],
  vetosIban: [],
  vetos: [],
  vetosLoading: false,
  vetosMerchantLoading: false,
  vetosEmailLoading: false,
  isDialogSelectRuleOpen: false,
  listRules: [],
};

function deleteRuleWithTempId(rules) {
  const clearRules = rules.filter((n) => typeof n.id === 'number');
  clearRules.forEach((item) => {
    if (item.children.length) {
      item.children = item.children.filter((n) => typeof n.id === 'number');
      deleteRuleWithTempId(item.children);
    }
  });
  return clearRules;
}

const ruleBuilderReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_RULESET_DATA: {
        draft.ruleSet.rules = action.data;
        break;
      }
      case ADD_NEW_RULE_TO_RULESET: {
        const id = uuidV4();
        const newRule = initialState.ruleSet.rules[0];
        const { rules } = draft.ruleSet;
        if (action.ruleId) {
          add(rules, action.ruleId, newRule);
        } else {
          rules.push(newRule);
        }
        draft.selectedRuleId = id;
        draft.ruleSet.rules = rules;
        draft.currentRule = newRule;
        break;
      }
      case DELETE_RULE_FROM_RULESET: {
        const ruleSet = _.cloneDeep(state.ruleSet.rules);
        prune(ruleSet, action.ruleId);
        draft.ruleSet.rules = ruleSet;
        break;
      }

      case CLONE_RULE_FROM_RULESET: {
        const ruleSet = [...state.ruleSet.rules];
        clone(ruleSet, action.ruleId);
        draft.ruleSet.rules = ruleSet;
        break;
      }

      case SELECT_RULE: {
        const { ruleSet } = state;
        const selectedRule = getRule(ruleSet.rules, action.ruleId);
        draft.selectedRuleId = action.ruleId;
        draft.ruleToReturn = selectedRule;
        draft.currentRule = selectedRule;
        break;
      }

      case UNSELECT_RULE: {
        if (action.recoverRule) {
          const { ruleToReturn } = state;
          const { rules } = state.ruleSet;
          update(rules, ruleToReturn);
          draft.selectedRuleId = null;
          draft.ruleSet.rules = rules;
          break;
        }
        draft.selectedRuleId = null;
        break;
      }
      case SELECT_RULESET: {
        const ruleSetToGo = action.ruleSet;
        if (action.ruleSet) {
          if (!ruleSetToGo.prepared) {
            ruleSetToGo.rules = prepareRuleSetForTree(ruleSetToGo.rules);
            ruleSetToGo.prepared = true;
            ruleSetToGo.fromApi = true;
          }
          draft.ruleSet = ruleSetToGo;
          break;
        }
        break;
      }
      case UPDATE_RULE: {
        const ruleSet = [...state.ruleSet.rules];
        update(ruleSet, action.rule);
        draft.ruleSet.rules = ruleSet;
        break;
      }
      case UPDATE_RULESET_METADATA: {
        draft.ruleSet[action.field] = action.value;
        break;
      }
      case SAVE_RULSET_TO_API: {
        draft.saving = true;
        draft.error = false;
        break;
      }
      case SAVE_RULSET_TO_API_SUCCESS: {
        const ruleSetToGo = _.cloneDeep(action.ruleSet);
        ruleSetToGo.rules = prepareRuleSetForTree(ruleSetToGo.rules);
        ruleSetToGo.prepared = true;
        ruleSetToGo.fromApi = true;
        draft.error = false;
        draft.saving = false;
        draft.ruleSet = ruleSetToGo;
        break;
      }
      case SAVE_RULSET_TO_API_FAIL: {
        draft.error = action.error;
        draft.saving = false;
        break;
      }
      case CHANGE_NEW_FORM_VALID_STATUS: {
        draft.newRuleFormValid = action.bool;
        break;
      }

      case CHANGE_NEW_FORM_CLOSE_ATTEMPT: {
        draft.newRuleFormCloseAttempt = action.bool;
        break;
      }
      case EMPTY_RULESET_AND_RULES: {
        draft.ruleSet = {
          rules: [],
        };
        draft.rules = [];
        break;
      }

      /** begin Rule cases for Rule Form  */
      case UPDATE_RULE_SUCCESS_RULE_FORM: {
        const ruleToUpdate = _.cloneDeep(state.currentRule);
        ruleToUpdate.title = action.rule.name;
        ruleToUpdate.script = action.rule.script;
        ruleToUpdate.categoryType = action.rule.categoryType;
        ruleToUpdate.description = action.rule.description;
        ruleToUpdate.ruleCategoryId = action.rule.ruleCategoryId;
        ruleToUpdate.seonRule = action.rule.seonRule;
        ruleToUpdate.answer = action.rule.answer;
        ruleToUpdate.score = action.rule.score;
        const ruleSet = _.cloneDeep(state.ruleSet.rules);
        update(ruleSet, ruleToUpdate);
        draft.ruleSet.rules = ruleSet;
        draft.currentRule = ruleToUpdate;
        draft.loading = false;
        break;
      }
      /** end Rule cases for Rule Form */

      case LOAD_FUNCTION_LIST:
        draft.functionsLoading = true;
        draft.error = false;
        break;
      case LOAD_FUNCTION_LIST_SUCCESS:
        draft.functions = action.functions;
        draft.functionsLoading = false;
        break;
      case LOAD_FUNCTION_LIST_FAIL:
        draft.functionsLoading = false;
        draft.error = action.error;
        break;
    }
  });

export default ruleBuilderReducer;
