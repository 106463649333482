import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SortByBlock } from './SortByBlock';
import { SortByHeaderProps } from './SortByHeaderProps';

export { SortByBlock };

export const SortByHeader: React.FC<SortByHeaderProps> = ({
  count = 0,
  totalCount = 0,
  sortOptions,
  sortedBy,
  setSortedBy,
  subTitle,
  disableCounter = false,
}) => (
  <Stack className="SortByHeader" direction="row" alignItems="center">
    {sortedBy && (
      <SortByBlock sortOptions={sortOptions} sortedBy={sortedBy} setSortedBy={setSortedBy} />
    )}
    {subTitle && (
      <Typography variant="body" color="grey.600">
        {subTitle}
      </Typography>
    )}
    {!disableCounter && (
      <Typography variant="body" color="grey.600" sx={{ marginLeft: 'auto' }}>
        {`Displaying ${(count ?? 0).toLocaleString()} of ${(totalCount ?? 0).toLocaleString()}`}
      </Typography>
    )}
  </Stack>
);
