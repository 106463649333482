import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

interface IconButtonRiStyledProps {
  iconсolor: string;
}

export const IconButtonRiStyled = styled(IconButton, {
  name: 'iconButtonRiStyled',
  slot: 'root',
})<IconButtonRiStyledProps>(({ theme, iconсolor }) =>
  theme.unstable_sx({
    '&.MuiIconButton-root': {
      // color: TODO,
      svg: {
        color: iconсolor,
        fontSize: '14px',
        padding: '3px',
        boxSizing: 'unset',
      },
    },
    '&.MuiIconButton-sizeSmall': {
      svg: {
        fontSize: '14px',
        padding: '3px',
        boxSizing: 'unset',
      },
    },
    '&.MuiIconButton-sizeMedium': {
      // TODO
      svg: {
        // TODO
        // fontSize: '14px',
        // padding: '3px',
        // boxSizing: 'unset',
      },
    },
  }),
);
