import React from 'react';
import { Box } from '@mui/material';
import { ArrowBack, Skeleton, TabContainer, Tab } from 'uikit';
import { useGetKycApplicantQuery } from 'services/gatewayApi/kycApplicantsApi';
import { DetailsTitle } from './DetailsTitle';
import { DetailsTab } from './DetailsTab';
import { KycCkecksTab } from './KycCkecksTab';

export const KycApplicantDetails = ({ id, onClose }) => {
  const { data: kycApplicant, isLoading } = useGetKycApplicantQuery({ id }, { skip: !id });

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && kycApplicant && (
        <>
          <DetailsTitle kycApplicant={kycApplicant} onClose={onClose} />

          <TabContainer>
            <Tab key="detailsTab" title="Details">
              <DetailsTab kycApplicant={kycApplicant} />
            </Tab>
            <Tab key="kycChecksTab" title="KYC Checks">
              <KycCkecksTab applicantId={kycApplicant.id} />
            </Tab>
          </TabContainer>
        </>
      )}
    </Box>
  );
};
