import React, { createContext, useContext } from 'react';
import { EmbedDropdownProps, DropdownContext } from './EmbedDropdownTypes';
import './EmbedDropdown.scss';

const dropdownContext = createContext<DropdownContext | null>(null);

// Wrap this component in a Context.
// so its child components can interact with it.
const EmbedDropdown: React.FC<EmbedDropdownProps> = ({
  align,
  isShowing,
  hide,
  priority,
  style,
  children,
}) => (
  <dropdownContext.Provider value={{ isShowing, hide }}>
    <div
      className={`embedDropdown embedDropdown--${isShowing ? 'show' : 'hide'} ${
        align === 'right' ? 'right' : ''
      } ${priority ? 'priority' : ''}`}
      style={style && style}
    >
      {children}
    </div>
  </dropdownContext.Provider>
);

export default EmbedDropdown;

export function useDropdownContext() {
  return useContext(dropdownContext);
}
