import { Chip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconChip = styled(Chip)<ChipProps>(({ theme }) => ({
  padding: 0,
  width: 20,
  borderRadius: 6,
  '.MuiSvgIcon-root.MuiChip-iconSmall': {
    marginRight: '-4px',
  },
}));
