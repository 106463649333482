import { PaletteColor } from '@mui/material/styles/createPalette';
import { FigmaColor, textColor } from '../figmaVariables';

export const error: PaletteColor = {
  main: FigmaColor({ colorName: 'red', shade: 700 }),
  light: FigmaColor({ colorName: 'red', shade: 100 }),
  dark: FigmaColor({ colorName: 'red', shade: 800 }),
  contrastText: textColor('text-inverted'),

  hover: FigmaColor({ colorName: 'red', shade: 200 }),
  active: FigmaColor({ colorName: 'red', shade: 300 }),
};
