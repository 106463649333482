import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const websiteChecklistsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getWebsiteChecklistSections: build.query({
      query: () => ({
        url: `website-checklist-sections`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'WebsiteChecklistSections', id: 'LIST' }],
    }),
    getWebsiteChecklistsForApplication: build.query({
      query: (applicationId) => ({
        url: `applications/${applicationId}/website-checklists`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'WebsiteChecklistsForApplication', id: applicationId },
      ],
    }),
    updateWebsiteChecklistsForApplication: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        delete body.applicationId;
        removeEmptyProperties(body);
        return {
          url: `applications/${model.applicationId}/website-checklists/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'WebsiteChecklistsForApplication', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetWebsiteChecklistSectionsQuery,
  useGetWebsiteChecklistsForApplicationQuery,
  useUpdateWebsiteChecklistsForApplicationMutation,
} = websiteChecklistsApi;
