export const htmlModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline'],
    ['link', 'image'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['blockquote', { indent: '-1' }, { indent: '+1' }],
  ],
};

export const htmlFormats = [
  'background',
  'bold',
  'color',
  'font',
  'italic',
  'link',
  'size',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'image',
];

export const htmlModulesLight = {
  toolbar: [
    [{ font: [] }],
    ['bold', 'italic', 'underline'],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
  ],
};

export const htmlLightFormats = [
  'background',
  'bold',
  'color',
  'font',
  'italic',
  'underline',
  'blockquote',
  'list',
];
