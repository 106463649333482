import React from 'react';
import { PepStatus, RiskStatus, KYCStatus, KYCResult } from 'features/ApplicationManagement/types';
import { statusesMap } from '../../constants';
import './ApplicationStatusBox.scss';

interface ApplicationStatusBoxProps {
  statusValue: PepStatus | RiskStatus | KYCStatus | KYCResult;
}

const ApplicationStatusBox: React.FC<ApplicationStatusBoxProps> = ({
  statusValue = 'NOT_PERFORMED',
}) => {
  const status = statusesMap.find((el) => el.value === statusValue);
  const textLabel = status?.label || 'status not found';

  return (
    <div className={`ApplicationStatusBox ApplicationStatusBox--${status?.color}`}>
      <p className="status-label">{textLabel}</p>
    </div>
  );
};

export { ApplicationStatusBox };
