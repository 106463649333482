import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

export const InputGreyStyled = styled(InputBase, {
  name: 'InputGreyStyled',
})(({ theme }) =>
  theme.unstable_sx({
    padding: '4px 0',
    bgcolor: 'grey.100',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '6px',
    height: 32,
    maxWidth: '273px',
    transition: '.3s all',
    '&.Mui-focused': {
      bgcolor: 'white',
      border: `1px solid ${theme.palette.grey[700]}`,
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  }),
);
