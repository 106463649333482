import { SortParam } from '../../../types';

export interface ApplicationSort {
  property: string;
  direction: string;
}

export const defaultSort: SortParam = {
  property: 'modifiedDate',
  direction: 'desc',
};

export interface ApplicationCount {
  countApplications: number;
  totalCountApplications: number;
}

export const defaultCount: ApplicationCount = {
  countApplications: 0,
  totalCountApplications: 0,
};
