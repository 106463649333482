import React, { useState } from 'react';
import { parseISO } from 'date-fns';
import { Button, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { ApplicationDocument as ApplicationDocumentType } from 'services/gatewayApi/applicationDocumentsApi';
import { Dictionary } from 'types/types';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT } from 'constants/constants';
import { styled } from '@mui/material/styles';
import { ApplicationDocument } from './Document';

export const AccordionSummaryStyled = styled(AccordionSummary, {
  name: 'accordionSummaryStyled',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    '&.MuiAccordionSummary-root': {
      padding: 0,
      minHeight: '32px',
      '.Mui-expanded': {
        margin: '6px 0',
      },
      '.MuiAccordionSummary-content': {
        margin: '6px 0',
      },
    },
  }),
);

interface DocumentsListProps {
  documents: ApplicationDocumentType[];
  checkedIds?: Dictionary<boolean>;
  handleCheck?: (id: string) => any;
  filter?: (doc: ApplicationDocumentType) => boolean;
}

const renderDocuments = (
  documents: ApplicationDocumentType[],
  checkedIds?: Dictionary<boolean>,
  handleCheck?: (id: string) => any,
  groups?: string[],
) =>
  documents
    .sort((a, b) => (a.documentOrder > b.documentOrder ? 1 : -1))
    .map((doc) => (
      <ApplicationDocument
        key={doc.id}
        {...(checkedIds && handleCheck
          ? {
              checked: !!checkedIds[doc.id],
              handleCheck,
            }
          : {})}
        {...doc}
        groups={groups}
      />
    ));

export const DocumentsList = React.memo(
  ({
    documents,
    checkedIds,
    handleCheck,
    filter = (doc: ApplicationDocumentType) => true,
  }: DocumentsListProps): JSX.Element => {
    const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]);

    const groups = Array.from(
      new Set(
        documents
          .filter((doc) => doc.groupName && doc.groupName !== '')
          .map((doc) => doc.groupName),
      ),
    ).sort((a, b) => (a.toString().toLowerCase() > b.toString().toLowerCase() ? 1 : -1));

    const groupFilter = ({ groupName }: ApplicationDocumentType, matchGroup = '') =>
      matchGroup
        ? groupName === matchGroup
        : typeof groupName === 'undefined' || groupName?.length === 0;

    const { datedDocuments, undatedDocuments } = (documents || [])
      .filter((doc) => filter(doc) && groupFilter(doc))
      .reduce(
        (result, doc) => {
          if (doc.uploadedDate) {
            result.datedDocuments.push(doc);
          } else {
            result.undatedDocuments.push(doc);
          }
          return result;
        },
        { datedDocuments: [], undatedDocuments: [] },
      );

    const dateGroups = Array.from(
      new Set(
        datedDocuments
          .map((doc) => parseISO(doc.uploadedDate))
          .sort((a: Date, b: Date) => b.getTime() - a.getTime())
          .map((date) => formatIsoDate(date, DATE_FORMAT)),
      ),
    );

    const getDateString = (date) => formatIsoDate(parseISO(date), DATE_FORMAT);

    const handleToggleGroupOpen = (groupName) => {
      const isGroupCollapsed = collapsedGroups.includes(groupName);
      setCollapsedGroups((oldArray) =>
        isGroupCollapsed
          ? oldArray.filter((group) => group !== groupName)
          : [...oldArray, groupName],
      );
    };

    return (
      <>
        {dateGroups?.length > 0 && (
          <Box sx={{ width: '100%' }} className="groupsWrapper">
            {dateGroups.map((groupName) => {
              const groupDocuments = datedDocuments?.filter(
                (doc) => getDateString(doc.uploadedDate) === groupName,
              );

              const isGroupCollapsed = collapsedGroups.includes(groupName);

              return groupDocuments.length > 0 ? (
                <Accordion
                  key={`GroupBox_${groupName}`}
                  className="groupAccordion"
                  elevation={0}
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  expanded={!isGroupCollapsed}
                  onChange={() => handleToggleGroupOpen(groupName)}
                >
                  <AccordionSummaryStyled className="groupAccordionSummary">
                    <Button
                      className="groupButton"
                      color="base"
                      variant="text"
                      endIcon={
                        isGroupCollapsed ? (
                          <RiArrowUpSLine size={14} />
                        ) : (
                          <RiArrowDownSLine size={14} />
                        )
                      }
                      sx={{ width: 'fit-content' }}
                    >
                      {groupName}
                    </Button>
                  </AccordionSummaryStyled>
                  <AccordionDetails
                    sx={{ display: 'flex', flexDirection: 'column', gap: '12px', p: '0 0 2rem 0' }}
                    className="groupAccordionDetails"
                  >
                    {renderDocuments(groupDocuments, checkedIds, handleCheck, groups)}
                  </AccordionDetails>
                </Accordion>
              ) : null;
            })}
          </Box>
        )}

        {groups?.length > 0 && (
          <Box sx={{ width: '100%' }} className="groupsWrapper">
            {groups.map((groupName) => {
              const groupDocuments = documents?.filter(
                (doc) => filter(doc) && groupFilter(doc, groupName),
              );
              const isGroupCollapsed = collapsedGroups.includes(groupName);

              return groupDocuments.length > 0 ? (
                <Accordion
                  key={`GroupBox_${groupName}`}
                  className="groupAccordion"
                  elevation={0}
                  sx={{
                    backgroundColor: 'transparent',
                  }}
                  expanded={!isGroupCollapsed}
                  onChange={() => handleToggleGroupOpen(groupName)}
                >
                  <AccordionSummaryStyled className="groupAccordionSummary">
                    <Button
                      className="groupButton"
                      color="base"
                      variant="text"
                      endIcon={
                        isGroupCollapsed ? (
                          <RiArrowUpSLine size={14} />
                        ) : (
                          <RiArrowDownSLine size={14} />
                        )
                      }
                      sx={{ width: 'fit-content' }}
                    >
                      {groupName}
                    </Button>
                  </AccordionSummaryStyled>
                  <AccordionDetails
                    sx={{ display: 'flex', flexDirection: 'column', gap: '12px', p: '0 0 2rem 0' }}
                    className="groupAccordionDetails"
                  >
                    {renderDocuments(groupDocuments, checkedIds, handleCheck, groups)}
                  </AccordionDetails>
                </Accordion>
              ) : null;
            })}
          </Box>
        )}

        {undatedDocuments.length > 0 &&
          renderDocuments(undatedDocuments, checkedIds, handleCheck, groups)}
      </>
    );
  },
);
