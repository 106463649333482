import React from 'react';
import { tuple } from 'uikit/utils/type';

export const InputTypes = tuple(
  'button',
  'checkbox',
  'color',
  'date',
  'datetime-local',
  'email',
  'file',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'radio',
  'range',
  'reset',
  'search',
  'submit',
  'tel',
  'text',
  'time',
  'url',
  'week',
);

export type InputType = (typeof InputTypes)[number];

export type InputSize = 'sm' | 'md' | 'lg';
type PrevNext = 'prev' | 'next';

export interface IInputProps {
  name?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  id?: string;
  variant?: string;
  autoFocus?: boolean;

  handlePrevNext?: (direction: PrevNext) => void;
  searchCounter?: string;

  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;

  touched?: boolean;
  dirty?: boolean;
  focused?: boolean;
  valid?: boolean;

  size?: InputSize;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => any;
  handleClear?: () => any;

  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  hasError?: boolean;
  startIcon?: React.ReactNode;
}
