import * as React from 'react';
import {
  Menu as MuiMenu,
  ButtonProps,
  IconButtonProps,
  MenuItemProps,
  Skeleton,
  MenuProps,
} from '@mui/material';

type OpenButtonPropsType = ButtonProps | IconButtonProps;

export interface Item {
  label: string;
  action: (...params: any[]) => any;
}

interface FilterMenuProps extends Omit<MenuProps, 'open'> {
  renderOpenButton: (props: OpenButtonPropsType) => React.ReactElement;
  renderItems: (props: MenuItemProps) => React.ReactElement | React.ReactNode[];
  isLoading?: boolean;
}

export const Menu = (props: FilterMenuProps): JSX.Element => {
  const { isLoading = false, renderOpenButton, renderItems, ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return !isLoading ? (
    <>
      {renderOpenButton({
        onClick: handleOpen,
      })}
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        {...other}
      >
        {renderItems({
          onClick: handleClose,
        })}
      </MuiMenu>
    </>
  ) : (
    <Skeleton variant="text" width={150} />
  );
};
