import React from 'react';
import './Filtering.scss';

interface FilteringProps {}

const Filtering: React.FC<FilteringProps> = () => (
  <div className="filtering">Content for filtering</div>
);

export default Filtering;
