/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdateCardDataMutation } from '../../services/gatewayApi/cardDataApi';
import { cardActions } from './constants';
import { makeSelectedProductIdData } from '../../selectors/user';

const CardDataFormDialog = ({ open, onClose, data }) => {
  const validationSchema = yup.object({
    cardAction: yup.string().required('Required'),
  });

  const [updateCardData] = useUpdateCardDataMutation();

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const model = {
        ...values,
        productId: selectedProductId,
      };
      const mutationPromise = await updateCardData(model);
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        onClose();
      }
    },
  });

  const handleExit = () => {
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle id="form-dialog-title">
        Edit Card Action
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box width={500} my={2}>
          <form id="cardDataForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  id="maskedCardNumber"
                  name="maskedCardNumber"
                  label="Masked Card Number"
                  fullWidth
                  size="small"
                  disabled
                  value={formik.values.maskedCardNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Card Action</FormLabel>
                  <RadioGroup
                    id="cardAction"
                    name="cardAction"
                    row
                    value={formik.values.cardAction}
                    onChange={formik.handleChange}
                  >
                    {cardActions.map((action) => (
                      <FormControlLabel
                        key={`type_${action.value}`}
                        control={<Radio />}
                        label={action.label}
                        value={action.value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="cardDataForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CardDataFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    maskedCardNumber: PropTypes.string,
    cardAction: PropTypes.string,
  }),
};

export default CardDataFormDialog;
