import React from 'react';
import { FormRow, Input, InputBox, Panel } from 'uikit';
import { Box, Typography } from '@mui/material';

export function InputBoxes({ inputBoxVal, setInputBoxVal }) {
  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        InputBox
      </Typography>
      <Panel>
        <label>User input</label>
        <FormRow>
          <Input
            placeholder="Placeholder value"
            value={inputBoxVal}
            onChange={(e: any) => {
              setInputBoxVal(e.target.value);
            }}
            handleClear={() => {
              setInputBoxVal('');
            }}
          />
        </FormRow>
        <FormRow>
          <InputBox
            label="Input value"
            placeholder="Placeholder value"
            value={inputBoxVal}
            disabled
            onChange={(e: any) => {
              setInputBoxVal(e.target.value);
            }}
            handleClear={() => setInputBoxVal('')}
          />
        </FormRow>
      </Panel>
    </Box>
  );
}
