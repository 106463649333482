import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
// Sentry.io FE error logging
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { configureAppStore as store } from './store';
import { oidcConfig, userManager } from './keycloak';
import FontFaceObserver from 'fontfaceobserver';

import { AppRouterProvider } from './AppRouterProvider';
import { AuthProvider } from 'react-oidc-context';
import LanguageProvider from 'containers/LanguageProvider';

// Import i18n messages
import { translationMessages } from './i18n';

import './style.scss';

// (Legacy) Use CSS instead of react-with-styles
// https://github.com/airbnb/react-dates#initialize
import 'react-dates/initialize';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@material-ui/core/styles';
import { theme } from './theme';

if (window && window.location.hostname !== 'localhost') { // do not log if request is local
  Sentry.init({
    dsn: 'https://6129eba638d9498f923ae4d9a44890ad@o448830.ingest.sentry.io/5785615',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', `${window.location.origin}/api`, `${window.location.origin}/internal`],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const fontObserverRoboto = new FontFaceObserver('Roboto', {});
const fontObserverRubik = new FontFaceObserver('Rubik', {});

// When external fonts are loaded, add a font-family using this font to the body
Promise.all([
  fontObserverRoboto.load(),
  fontObserverRubik.load()
]).then(() => {
  document.body.classList.add('fontLoaded');
});

const container = document.getElementById('app');
const root = createRoot(container);

const render = messages => {
  // Determine if current window is iframe and if it is, don't render anything, just call callback to sign in
  if (window.self === window.top) {
    // TODO: <StylesProvider injectFirst> - less priority, remove after v5 migration
    root.render(
      <Provider store={store()}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LanguageProvider messages={messages}>
              <AuthProvider {...oidcConfig}>
                <AppRouterProvider />
              </AuthProvider>
            </LanguageProvider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>,
    );
  } else {
    userManager.signinSilentCallback();
  }
};

// Hot reloadable translation json files
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(container);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'));
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en.js'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
