import {
  DefaultChip,
  DefaultChipOutlined,
  SecondaryChip,
  SecondaryChipOutlined,
  SuccessChip,
  SuccessChipOutlined,
  PrimaryChip,
  PrimaryChipOutlined,
  BaseChip,
  BaseChipOutlined,
  ErrorChip,
  ErrorChipOutlined,
  BoldChip,
  BoldChipOutlined,
  WarningChip,
  WarningChipOutlined,
  ChipSquared,
} from './chip-variants';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    squared: true;
  }
}

export const Chip = {
  styleOverrides: {
    root: {
      '&:active': {
        boxShadow: 'none',
      },

      '.MuiChip-deleteIcon': {
        fontSize: '12px',
        opacity: '1',
        marginLeft: '2px',
        transition: 'opacity .3s ease-in-out',
        '&:hover': {
          opacity: '0.9',
        },
      },

      '.MuiChip-labelSmall': {
        padding: '0 2px',
      },

      '.MuiChip-iconSmall': {
        fontSize: '16px',
        margin: '0 2px 0 0',
      },

      '.MuiChip-avatarSmall': {
        margin: '0 4px 0 -6px',
      },
    },
  },
  variants: [
    DefaultChip,
    DefaultChipOutlined,
    SecondaryChip,
    SecondaryChipOutlined,
    SuccessChip,
    SuccessChipOutlined,
    PrimaryChip,
    PrimaryChipOutlined,
    BaseChip,
    BaseChipOutlined,
    ErrorChip,
    ErrorChipOutlined,
    BoldChip,
    BoldChipOutlined,
    WarningChip,
    WarningChipOutlined,
    ChipSquared,
    {
      props: { size: 'small' },
      style: {
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '18px',
        padding: '1px 5px',
        height: '20px',
        borderRadius: '4px',
        textTransform: 'uppercase',
      },
    },
  ],
};
