import { base } from 'theme/palette-blocks';
import { buttonFocus } from 'theme/theme-components/mixins';
import { alpha } from '@mui/material/styles';

export const Base = {
  props: {
    color: 'base',
  },
  style: {
    color: base.contrastText,
    '&:hover': {
      background: base.hover,
    },
    '&:active': {
      background: base.active,
    },
    '&:disabled': {
      background: alpha(base.main, 0.5),
      color: alpha(base.contrastText, 0.5),
      // opacity: palette.action?.disabledOpacity,
    },
    '&:focus&:not(:active)': buttonFocus(base.main),
  },
};

export const BaseContained = {
  props: {
    color: 'base',
    variant: 'contained',
  },
  style: {
    background: base.light,
    '&:hover': {
      background: base.hover,
    },
    '&:active': {
      background: base.active,
    },
  },
};

export const BaseOutlined = {
  props: {
    color: 'base',
    variant: 'outlined',
  },
  style: {
    borderColor: base['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: base.hover,
      borderColor: base['outline-100'],
    },
    '&:active': {
      background: base.active,
      borderColor: base['outline-100'],
    },
    '&:disabled': {
      borderColor: alpha(base['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};

export const BaseText = {
  props: {
    color: 'base',
    variant: 'text',
  },
  style: {
    borderColor: 'transparent',
    background: 'transparent',
    '&:hover': {
      background: base.hover,
    },
    '&:active': {
      background: base.active,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};
