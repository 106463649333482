import { TypeText } from '@mui/material/styles/createPalette';
import { textColor } from '../figmaVariables';

export interface TypeOpenpaydText extends TypeText {
  base: string;
  placeholder: string;
  inverted: string;
  link: string;
  danger: string;
  success: string;
}

export const text: TypeOpenpaydText = {
  base: textColor('text-base'),
  primary: textColor('text-base:light'),
  secondary: textColor('text-muted'),
  placeholder: textColor('text-placeholder'),
  inverted: textColor('text-inverted'),
  link: textColor('text-link'),
  danger: textColor('text-error'),
  success: textColor('text-success'),
  disabled: textColor('text-disabled'),
};
