import { baseTheme } from '../baseTheme';

export const Backdrop = {
  styleOverrides: {
    root: {
      zIndex: baseTheme.zIndex.drawer + 1,
      color: baseTheme.palette.common.white,
    },
  },
};
