import * as React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useAutocomplete } from '@mui/material';
import { FigmaColor } from '../../theme';

const Root = styled('div')(
  ({ theme }) => `
  color: ${'rgba(0,0,0,.85)'};
  font-size: 14px;
`,
);

const InputWrapper = styled('div', {
  name: 'InputWrapper',
  slot: 'Root',
})(({ theme }) =>
  theme.unstable_sx({
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'white',
    borderRadius: '6px',
    padding: '1px 6px',
    display: 'flex',
    flexWrap: 'wrap',
    transition: 'all 0.3s ease-in-out',

    '&.focused': {
      borderColor: FigmaColor({ colorName: 'blue', shade: 900 }),
    },

    '& input': {
      backgroundColor: 'white',
      color: 'rgba(0,0,0,.85)',
      height: '28px',
      fontSize: '14px',
      boxSizing: 'border-box',
      padding: '1px 0px',
      width: '0',
      minWidth: '30px',
      flexGrow: 1,
      border: '0',
      margin: '0',
      outline: '0',
      transition: 'all 0.3s ease-in-out',
      lineHeight: '22px',

      '::placeholder': {
        fontSize: '14px',
        color: 'grey.500',
      },
    },
  }),
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag, {
  name: 'StyledTag',
  slot: 'Root',
})(({ theme }) =>
  theme.unstable_sx({
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    margin: '3px 6px 3px 2px',
    lineHeight: '18px',
    backgroundColor: 'grey.50',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '2px',
    boxSizing: 'content-box',
    padding: '0 4px 0 10px',
    outline: '0',
    overflow: 'hidden',

    '&:focus': {
      borderColor: FigmaColor({ colorName: 'blue', shade: 700 }),
      backgroundColor: FigmaColor({ colorName: 'blue', shade: 100 }),
    },

    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& svg': {
      fontSize: '20px',
      cursor: 'pointer',
      padding: '4px',
      '&:hover': {
        color: 'danger.main',
      },
    },
  }),
);

export const Listbox = styled('ul', {
  name: 'Listbox',
  slot: 'Root',
})(({ theme }) =>
  theme.unstable_sx({
    width: '378px',
    margin: '2px 0 0',
    padding: 0,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: '#fff',
    overflow: 'auto',
    maxHeight: '250px',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    zIndex: 1,

    '& li': {
      padding: '5px 12px',
      display: 'flex',

      '& span': {
        flexGrow: 1,
      },

      '& svg': {
        color: 'transparent',
      },
    },

    "& li[aria-selected='true']": {
      backgroundColor: 'grey.50',
      fontWeight: 600,

      '& svg': {
        color: FigmaColor({ colorName: 'blue', shade: 800 }),
      },
    },

    "& li[data-focus='true']": {
      backgroundColor: FigmaColor({ colorName: 'blue', shade: 100 }),
      cursor: 'pointer',

      '& svg': {
        color: 'currentColor',
      },
    },
  }),
);

export const AutocompleteMulti = ({ name, options, placeholder, values, onChange, disabled }) => {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: name,
    name,
    value: values,
    multiple: true,
    options,
    getOptionLabel: (option) => option.label,
    onChange,
  });

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => (
            <StyledTag key={index} label={option.label} {...getTagProps({ index })} />
          ))}

          <input {...getInputProps()} placeholder={placeholder} disabled={disabled} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 && (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li key={index} {...getOptionProps({ option, index })}>
              <span>{option.label}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      )}
    </Root>
  );
};

AutocompleteMulti.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};
