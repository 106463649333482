import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Box,
  InputAdornment,
  OutlinedInput,
  Dialog,
  LinearProgress,
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { selectProductId } from '../../selectors/user';
import { RULE_NAME_REGEX } from '../../constants/constants';
import { useGetRulesListQuery } from '../../services/gatewayApi/rulesApi';

const RuleSelectDialog = (props) => {
  const { open, onClose, handleOpen } = props;

  const productId = useSelector(selectProductId);

  const [ruleFilterCase, setRuleFilterCase] = useState('');
  const [errorInput, setErrorInput] = useState(false);

  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetRulesListQuery(productId, { skip: !productId });

  const handleFilterChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.match(RULE_NAME_REGEX)) {
      setErrorInput(false);
      return setRuleFilterCase(inputValue);
    }
    return setErrorInput(true);
  };

  const ruleFilter = (item) =>
    item.name.toLowerCase().search(ruleFilterCase.toLowerCase()) > -1 ||
    item.description.toLowerCase().search(ruleFilterCase.toLowerCase()) > -1 ||
    item.id.toString().toLowerCase().search(ruleFilterCase.toLowerCase()) > -1;

  const RenderRules = () => {
    if (!data.rulesList.length) return 'no rules loaded';
    const list = data.rulesList.filter(ruleFilter).map((item) => (
      <Grid item key={item.id} md={12} style={{ marginRight: 8 }}>
        <Paper
          sx={{
            padding: 2,
            margin: 2,
            width: '100%',
            '&:hover': {
              backgroundColor: 'lightgreen',
              cursor: 'pointer',
            },
          }}
          onClick={() => handleSelectRule(item.id)}
        >
          <Typography variant="body2" component="span" style={{ fontWeight: 600 }}>
            {' '}
            ID: {item.id}{' '}
          </Typography>
          <Typography variant="body2"> Name: {item.name} </Typography>
          <Typography variant="caption"> Description: {item.description} </Typography>
        </Paper>
      </Grid>
    ));
    return <Box> {list} </Box>;
  };

  const handleSelectRule = (ruleId) => {
    onClose();
    handleOpen(ruleId);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="dialog-rule-select">
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          <Grid item>Choose Rule to add / edit Vetos</Grid>
          <Grid item>
            <IconButton onClick={() => onClose()} size="large" variant="closeDialog">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item md={12}>
            <OutlinedInput
              id="searchInput"
              type="text"
              name="searchInput"
              placeholder="Find Rule by name, description or rule Id"
              fullWidth
              autoFocus
              value={ruleFilterCase}
              onChange={(e) => {
                handleFilterChange(e);
              }}
              error={errorInput}
              variant="outlined"
              inputProps={{ maxLength: 128 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setRuleFilterCase('')}
                    size="small"
                  >
                    {ruleFilterCase === '' ? <DeleteOutlineIcon /> : <DeleteIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent style={{ padding: 24, margin: 8 }}>
        <Grid container spacing={4} direction="column" style={{ height: 800 }}>
          {isLoading ? (
            <Grid container justifyContent="center" alignItems="center" style={{ height: 800 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Box visibility={isFetching ? 'visible' : 'hidden'}>
                <LinearProgress />
              </Box>
              <RenderRules />
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RuleSelectDialog;

RuleSelectDialog.propTypes = {
  handleOpen: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
