import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Skeleton, Typography } from '@mui/material';
import {
  RiArrowDownSLine,
  RiFilter3Fill,
  RiAddCircleLine,
  RiDownloadLine,
  RiSendPlane2Line,
} from 'react-icons/ri';
import {
  ApplicationDocument,
  useGetAllApplicationDocumentsQuery,
  VirusStatus,
} from 'services/gatewayApi/applicationDocumentsApi';
import { Item, Menu } from 'muikit/Menu/Menu';
import { BulkActionPanel } from 'muikit/BulkActionPanel';
import { Dictionary } from 'types/types';
import { downloadFile } from 'utils/downloadFile';
import { ApplicationDocumentDraft } from './Document';
import { FilterMenuItems, initialFilterItem } from './Document/FilterMenuItems';
import { DocumentsList } from './DocumentsList';
import KycDocumentsDialog from './Document/KycDocumentsDialog';

export const DocumentsTab = ({ isInternal = false }: { isInternal?: boolean }): JSX.Element => {
  const { applicationId } = useParams<{ applicationId: string; }>();

  const [hasPendingVirusStatus, setHasPendingVirusStatus] = useState<boolean>(true);

  const { data: documents, isLoading } = useGetAllApplicationDocumentsQuery(applicationId || '', {
    pollingInterval: hasPendingVirusStatus ? 15 * 1000 : 0,
    skip: !applicationId,
  });

  const checkedListInitialValue = documents
    ? documents.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: false,
      }),
      {},
    )
    : {};

  const [kycDialogOpen, setKycDialogOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<Item>(initialFilterItem);
  const [additionMode, setAdditionMode] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<Dictionary<boolean>>(checkedListInitialValue);
  const isAnySelected = () => Object.values(checkedList).includes(true);

  const handleCheck = useCallback(
    (id: string) => {
      const updatedCheckedList = { ...checkedList };
      updatedCheckedList[id] = !updatedCheckedList[id];
      setCheckedList(updatedCheckedList);
    },
    [checkedList],
  );

  const getCheckedDocuments = (): Array<ApplicationDocument> =>
    documents.filter((doc) => Object.keys(checkedList).includes(doc.id) && checkedList[doc.id]);

  useEffect(() => {
    documents?.filter((doc) => doc.virusStatus === VirusStatus.Pending).length === 0
      ? setHasPendingVirusStatus(false)
      : setHasPendingVirusStatus(true);
  }, [JSON.stringify(documents)]);

  return (
    <>
      <Grid
        className="documentsWrapper"
        container
        sx={{
          minHeight: '70vh',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          py: '1rem',
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Grid
          container
          item
          xs={12}
          flexDirection="column"
          gap="12px"
          alignItems="center"
          className="documentsListWrapper"
        >
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="baseline"
            className="documentsHeaderRow"
            mb="0.5rem"
          >
            <Grid item className="documentsTitleItem">
              <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
                {isInternal ? 'Internal documents' : 'Documents'}
              </Typography>
            </Grid>
            <Grid item className="documentsFilterItem">
              <Menu
                isLoading={isLoading}
                renderOpenButton={(props) => (
                  <Button
                    {...props}
                    variant="text"
                    color="bold"
                    endIcon={<RiArrowDownSLine size={14} />}
                    startIcon={<RiFilter3Fill size={14} />}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <Typography variant="body1" sx={{ color: 'grey.600' }}>
                        Show
                      </Typography>
                      <Typography variant="body1">{filter.label}</Typography>
                    </div>
                  </Button>
                )}
                renderItems={(props) => (
                  <FilterMenuItems
                    handleSelect={(item) => {
                      setFilter(item);
                      setCheckedList(checkedListInitialValue);
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>
          {documents && !isLoading ? (
            <DocumentsList
              documents={filter.action(documents)}
              checkedIds={checkedList}
              handleCheck={handleCheck}
              filter={(doc: ApplicationDocument) =>
                doc.isEnabled && (isInternal ? doc.isInternal : !doc.isInternal)
              }
            />
          ) : (
            <>
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
              <Skeleton sx={{ width: '100%', height: 72 }} />
            </>
          )}
          {additionMode ? (
            <ApplicationDocumentDraft
              applicationId={applicationId}
              isInternal={isInternal}
              onCancel={() => setAdditionMode(false)}
            />
          ) : (
            <Button
              id="block-button"
              variant="contained"
              color="base"
              startIcon={<RiAddCircleLine size={14} />}
              onClick={() => setAdditionMode(true)}
              sx={{ mt: '1rem' }}
            >
              Add Document
            </Button>
          )}
        </Grid>
        {isAnySelected() && (
          <BulkActionPanel>
            <Button
              variant="text"
              color="contrast"
              startIcon={<RiSendPlane2Line size={14} style={{ boxSizing: 'unset' }} />}
              onClick={() => setKycDialogOpen(true)}
            >
              Send to KYC
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RiDownloadLine size={14} />}
              onClick={() => {
                const documentIds = getCheckedDocuments()
                  .map((doc) => doc.id)
                  .join();
                downloadFile(
                  `/api/gateway/applications/${applicationId}/docs/download-bulk/${documentIds}`,
                  { download: true },
                );
              }}
            >
              Download selected documents
            </Button>
          </BulkActionPanel>
        )}
      </Grid>

      {kycDialogOpen && (
        <KycDocumentsDialog
          open={kycDialogOpen}
          onClose={() => setKycDialogOpen(false)}
          applicationId={applicationId}
          selectedDocuments={documents && kycDialogOpen && getCheckedDocuments()}
        />
      )}
    </>
  );
};
