import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { ModalDialog, Spinner } from 'uikit';
import {
  Direction,
  useAddMessageBulkMutation,
  useAddMessageMutation,
  useGetMessagesByFilterQuery,
  useReadMessagesMutation,
} from 'services/gatewayApi/applicationMessagesApi';
import { useGetCustomersByFilterQuery } from 'services/gatewayApi/applicationCustomersApi';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';
import { TabContext } from '@mui/lab';
import { makeUserData } from 'selectors/user';
import TabList from './TabList';
import MessagesPanelContainer from './MessagesPanelContainer';

const initialMessageDialogState = {
  open: false,
  content: undefined,
};

const MailTab = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isSendToAll, setIsSendToAll] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(initialMessageDialogState);
  const [disabledSendToAll, setDisabledSendToAll] = React.useState(false);

  const { onboardingApplicationId } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const { data: customers = [], isLoading: isCustomersLoading } = useGetCustomersByFilterQuery(
    {
      applicationId,
    },
    { skip: !applicationId },
  );
  const { data: messagesByCustomerIdMap = {}, isLoading: isMessagesLoading } =
    useGetMessagesByFilterQuery(
      { applicationId },
      {
        skip: !applicationId,
        pollingInterval: 30 * 1000,
        selectFromResult: ({ data, isLoading, isFetching }) => ({
          data: _.groupBy(data, 'customerId'),
          isLoading,
          isFetching,
        }),
      },
    );
  const [addMessage] = useAddMessageMutation();
  const [addMessages] = useAddMessageBulkMutation();
  const [readMessages] = useReadMessagesMutation();

  const { name: userName } = useSelector(makeUserData());
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<string>(undefined);

  React.useEffect(() => {
    customers[0] && setSelectedCustomerId(customers[0].id);
  }, [customers]);
  React.useEffect(() => {
    if (
      messagesByCustomerIdMap &&
      selectedCustomerId &&
      messagesByCustomerIdMap[selectedCustomerId]
    ) {
      const messagesToRead = messagesByCustomerIdMap[selectedCustomerId].filter(
        (m) => m.isRead === false && m.direction === Direction.In,
      );
      if (messagesToRead.length !== 0 && applicationId)
        readMessages({
          applicationId,
          messages: messagesToRead,
        });
    }
  }, [
    selectedCustomerId,
    messagesByCustomerIdMap[selectedCustomerId] &&
    messagesByCustomerIdMap[selectedCustomerId]?.length,
  ]);

  const addMessageBulk = () => {
    const messagesToAll = customers.map(({ id: customerId }) => ({
      applicationId,
      createdByName: userName,
      customerId,
      threadId: customerId,
      direction: Direction.Out,
      content: confirmDialogOpen.content,
    }));
    setConfirmDialogOpen(initialMessageDialogState);
    addMessages({ messages: messagesToAll });
  };

  return (
    <Box className="MailTab">
      <Box sx={{ display: 'flex', height: 'calc(100vh - 320px)' }}>
        {isCustomersLoading && isMessagesLoading && (
          <Box className="app-details-preloader app-details-preloader--small">
            <Spinner />
          </Box>
        )}

        {selectedCustomerId && messagesByCustomerIdMap && (
          <TabContext value={selectedCustomerId}>
            <TabList
              isSendToAll={isSendToAll}
              items={customers}
              setSelectedId={setSelectedCustomerId}
              getLabel={(customer) => `${customer.firstName} ${customer.lastName}`}
              getValue={(customer) => customer.id}
              getHasActivity={(customer) =>
                messagesByCustomerIdMap[customer.id] &&
                messagesByCustomerIdMap[customer.id].some(
                  (m) => m.direction === Direction.In && m.isRead === false,
                )
              }
            />
            <Divider orientation="vertical" flexItem />
            {!isCustomersLoading && !isMessagesLoading ? (
              <MessagesPanelContainer
                setDisabledSendToAll={setDisabledSendToAll}
                isSendToAll={isSendToAll}
                messagesById={messagesByCustomerIdMap}
                users={customers}
                addMessage={({ content, replyTo }) =>
                  isSendToAll
                    ? setConfirmDialogOpen({ open: true, content })
                    : addMessage({
                      applicationId,
                      createdByName: userName,
                      customerId: selectedCustomerId,
                      threadId: selectedCustomerId,
                      direction: Direction.Out,
                      content,
                      replyTo,
                    })
                }
              />
            ) : (
              <>Loading...</>
            )}
          </TabContext>
        )}
      </Box>
      {selectedCustomerId &&
        customers?.length > 1 &&
        messagesByCustomerIdMap &&
        !isCustomersLoading &&
        !isMessagesLoading && (
          <FormControlLabel
            sx={{ position: 'absolute', right: '50px', bottom: '88px' }}
            disabled={disabledSendToAll}
            control={
              <Checkbox
                checked={isSendToAll}
                onChange={(e, checked: boolean) => setIsSendToAll(checked)}
              />
            }
            label="Send to all users"
          />
        )}

      <ModalDialog
        open={confirmDialogOpen.open}
        onClose={() => setConfirmDialogOpen(initialMessageDialogState)}
        title="Send message to all customer users?"
        hasDivider
        actionTitle="Send"
        handleAction={addMessageBulk}
      />
    </Box>
  );
};

export { MailTab };
