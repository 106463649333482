import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Menu as MenuIcon, Edit as EditIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Input, StickyHeader } from 'uikit';
import SearchIcon from '@mui/icons-material/Search';
import { ThresholdPanel } from './ThresholdPanel';
import { TOOLTIP_DELAY } from '../../constants/constants';

interface RulesHeaderProps {
  isLocked: boolean;
  handleLock: () => void;
  titleRuleset?: string;
  isShowInactive: boolean;
  handleShowInactive: () => void;
  isPublishDisabled: boolean;
  handlePublish: () => void;
  isLoading?: boolean;
  setOpenRuleSetsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  handleReset: () => void;
  searchString?: string;
  handleSearch: (string) => void;
  handlePrevNext: (direction: 'prev' | 'next') => void;
  searchCounter: string;
  preventHiding?: boolean;
}

export const RulesHeader: React.FC<RulesHeaderProps> = ({
  titleRuleset = 'Default',
  isLocked,
  handleLock,
  isShowInactive,
  handleShowInactive,
  isPublishDisabled,
  handlePublish,
  isLoading = false,
  setOpenRuleSetsDrawer,
  handleReset,
  searchString,
  handleSearch,
  handlePrevNext,
  searchCounter,
  preventHiding,
}) => (
  <div className="rules-header">
    <StickyHeader preventHiding={preventHiding}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" pt={6} pb={4}>
        <Typography variant="h1">Rules</Typography>
        <Stack direction="row" gap={2}>
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
            value={searchString}
            placeholder="Search"
            handleClear={() => handleSearch('')}
            onKeyDown={(e) => {
              if (e.key === 'ArrowUp') {
                e.preventDefault();
                handlePrevNext('prev');
              } else if (e.key === 'ArrowDown' || e.key === 'Enter') {
                e.preventDefault();
                handlePrevNext('next');
              }
            }}
            startIcon={<SearchIcon style={{ fontSize: '20px' }} />}
            handlePrevNext={handlePrevNext}
            searchCounter={searchCounter}
          />
          <Button
            color="base"
            variant="contained"
            startIcon={<MenuIcon style={{ fontSize: '16px', paddingRight: '4px' }} />}
            onClick={() => setOpenRuleSetsDrawer((prev) => !prev)}
            disabled={!isLocked}
            sx={{ maxWidth: '300px' }}
          >
            <Typography
              sx={{
                maxWidth: '260px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {titleRuleset}
            </Typography>
          </Button>
          <Tooltip
            title={isLocked ? 'Unlock rules tree for edit' : 'Reset rules tree to previous saved'}
            enterDelay={TOOLTIP_DELAY}
          >
            <Button
              color={isLocked ? 'base' : 'error'}
              variant={isLocked ? 'contained' : 'text'}
              startIcon={
                isLocked ? <EditIcon style={{ fontSize: '16px', paddingRight: '4px' }} /> : null
              }
              onClick={(e) => {
                isLocked ? handleLock() : handleReset();
              }}
            >
              {isLocked ? 'Edit ruleset' : 'Reset'}
            </Button>
          </Tooltip>
          {!isLocked && (
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={isPublishDisabled}
              loading={isLoading}
              onClick={handlePublish}
            >
              Publish
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={4}>
        <ThresholdPanel isLocked={isLocked} />
        <FormControlLabel
          sx={{ m: 0 }}
          control={<Checkbox checked={isShowInactive} onChange={handleShowInactive} />}
          label="Show inactive rules"
        />
      </Stack>
      <Divider light />
    </StickyHeader>
  </div>
);
