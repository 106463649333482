/** func for rename list of keys in obj
 *  newKeys = { oldKeyName1: "newKeyName1", oldKeyName2: "newKeyName2", .. }
 *  if oldKeyName not exist, it will be skiped
 * */

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
