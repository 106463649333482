import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiDeleteBinFill } from 'react-icons/ri';
import { ModalDialog } from 'uikit';
import { useDeleteKycApplicantMutation } from 'services/gatewayApi/kycApplicantsApi';
import { positions } from '../constants';

export const DetailsTitle = ({ kycApplicant, onClose }: {kycApplicant: any; onClose: () => void}) => {
  const [deleteKycApplicant] = useDeleteKycApplicantMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteKycApplicant(kycApplicant.id);
    setOpenConfirmDelete(false);
    onClose();
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pt: 5, pb: 2 }}
      >
        <Typography variant="h3" sx={{ mb: 0 }}>
          {kycApplicant.firstName} {kycApplicant.lastName}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button
            color="error"
            onClick={handleConfirmDelete}
            variant="contained"
            startIcon={<RiDeleteBinFill size="14px" />}
            disabled={kycApplicant.accounts?.length > 0}
          >
            Delete
          </Button>
        </Stack>
      </Stack>

      <Typography variant="body1" sx={{ color: 'grey.700' }}>
        {positions.find((position) => position.value === kycApplicant.position)?.label}
      </Typography>

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete KYC Applicant"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete KYC Applicant '}
            <Typography variant="subtitle1" component="span">
              {kycApplicant.firstName} {kycApplicant.lastName}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </Box>
  );
};
