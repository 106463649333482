/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useGetKycAllowedCountriesForProviderQuery,
  useAddKycAllowedCountryMutation,
  useDeleteKycAllowedCountryMutation,
  useDeleteKycAllowedCountriesForProviderMutation,
} from '../../services/gatewayApi/kycAllowedCountriesApi';
import { useGetAllCountriesQuery } from '../../services/gatewayApi/countryRiskApi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  color: theme.palette.common.white,
}));

const KycAllowedCountriesFormDialog = ({ open, onClose, kycProvider }) => {
  // #region Load base data
  const { data: countriesList } = useGetAllCountriesQuery();
  const [kycAllowedCountries, setKycAllowedCountries] = useState([]);
  const { data, isLoading, isFetching } = useGetKycAllowedCountriesForProviderQuery(kycProvider, {
    skip: !kycProvider,
  });

  useEffect(() => {
    if (data) {
      setKycAllowedCountries(data);
    }
  }, [data]);
  // #endregion

  // #region Add functionality
  const [addKycAllowedCountry, { isLoading: isLoadingAddCountry }] =
    useAddKycAllowedCountryMutation();

  const validationSchema = yup.object({
    countryCode: yup.string().nullable().required('Required'),
  });

  const formik = useFormik({
    initialValues: { countryCode: null },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const model = {
        providerId: kycProvider.id,
        ...values,
      };
      const mutationPromise = await addKycAllowedCountry(model);
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        // onClose();
      }
    },
  });
  // #endregion

  // #region Delete functionality
  const [deleteKycAllowedCountry, { isLoading: isLoadingDeleteCountry }] =
    useDeleteKycAllowedCountryMutation();

  const handleDelete = (countryCode) => {
    deleteKycAllowedCountry({ providerId: kycProvider.id, countryCode });
  };
  // #endregion

  // #region Delete-all functionality
  const [deleteKycAllowedCountriesForProvider, { isLoading: isLoadingDeleteAllCountries }] =
    useDeleteKycAllowedCountriesForProviderMutation();

  const handleDeleteAll = () => {
    deleteKycAllowedCountriesForProvider({ providerId: kycProvider.id });
  };
  // #endregion

  const handleExit = () => {
    formik.resetForm();
  };

  const RenderList = ({ list }) => {
    if (!list || list.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Typography variant="body1" align="center" component="div">
              No countries added. All countries are allowed by default...
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return list.map((item) => (
      <TableRow hover tabIndex={-1}>
        <TableCell component="th" scope="row">
          {item.countryCode}
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell padding="checkbox">
          <IconButton
            onClick={() => {
              handleDelete(item.countryCode);
            }}
          >
            {isLoadingDeleteCountry ? <CircularProgress size={14} /> : <DeleteIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle id="form-dialog-title">
        {`Edit Allowed Countries for ${kycProvider?.name}`}
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box width={500} my={2}>
          <form id="kycProviderForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4} style={{ marginBottom: 15 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="countryCode"
                    fullWidth
                    size="small"
                    options={countriesList || []}
                    value={
                      formik.values.countryCode && countriesList
                        ? countriesList.find((c) => c.countryCode === formik.values.countryCode)
                        : null
                    }
                    onChange={(event, option) => {
                      formik.setFieldValue('countryCode', option ? option.countryCode : '');
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.name || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <FormHelperText
                    error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                  >
                    {formik.touched.countryCode && formik.errors.countryCode}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginBottom: 15 }}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={formik.isSubmitting}
                >
                  <AddCircleIcon style={{ marginRight: 5 }} />
                  Add Country
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid container style={{ marginBottom: 15 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Code</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell padding="checkbox" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading || isFetching || isLoadingAddCountry ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Grid align="center">
                          <CircularProgress />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <RenderList list={kycAllowedCountries} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="error"
                type="submit"
                fullWidth
                disabled={isLoadingDeleteAllCountries}
                onClick={() => handleDeleteAll()}
              >
                <DeleteIcon style={{ marginRight: 5 }} />
                Delete All
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

KycAllowedCountriesFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  kycProvider: PropTypes.object,
};

export default KycAllowedCountriesFormDialog;
