import React from 'react';

interface TabProps {
  title?: string;
  count?: number;
  disabled?: boolean;
  hidden?: boolean;
  hasActivity?: boolean;
  children: any;
  isLoading?: boolean;
}

const Tab: React.FC<TabProps> = ({
  // Most of the properties are used when rendering TabTitle by parent (TabContainer)
  title = 'No title',
  count,
  disabled = false,
  hidden = false,
  hasActivity = false,
  children,
  isLoading = false,
}) => <div className={`Tab${isLoading ? 'Tab--IsLoading' : ''}`}>{children}</div>;

export default Tab;
