import { gatewayApi } from '../gatewayApi';
import { getRandomInt } from '../../utils/random';

export enum Direction {
  In = 'IN',
  Out = 'OUT',
  Fanout = 'FANOUT',
}

export interface ApplicationMessage {
  id: string;
  tenantId: number;
  applicationId: string;
  threadId: string;
  replyTo?: string;
  senderType?: string;
  userId: string;
  customerId: string;
  direction: Direction;
  content: string;
  isRead: boolean;
  createdDate: string;
  modifiedDate: string;
  createdBy: string;
  modifiedBy: string;
  createdByName: string;
  modifiedByName: string;
}

export type AddApplicationMessagePayload = Pick<
  ApplicationMessage,
  | 'applicationId'
  | 'threadId'
  | 'replyTo'
  | 'senderType'
  | 'customerId'
  | 'content'
  | 'createdByName'
  | 'direction'
>;
type ReadApplicationMessagesPayload = {
  applicationId: ApplicationMessage['applicationId'];
  messages: Array<Pick<ApplicationMessage, 'id'>>;
};
type ApplicationMessagesFilter = Partial<ApplicationMessage>;

export const applicationMessagesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getMessage: build.query<ApplicationMessage, Pick<ApplicationMessage, 'applicationId' | 'id'>>({
      query: ({ applicationId, id }) => ({
        url: `applications/${applicationId}/messages/${id}`,
        method: 'GET',
      }),
      providesTags: ({ id }) => [{ type: 'ApplicationMessage', id }],
    }),
    getMessagesByFilter: build.query<
      Array<ApplicationMessage & { draft?: boolean }>,
      ApplicationMessagesFilter
    >({
      query: ({ applicationId, ...params }) => ({
        url: `applications/${applicationId}/messages`,
        params,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'ApplicationMessage', id: 'LIST' }],
    }),
    addMessage: build.mutation<
      ApplicationMessage,
      AddApplicationMessagePayload & { createdByName?: string }
    >({
      query: ({ applicationId, threadId, customerId, content, replyTo, direction }) => ({
        url: `applications/${applicationId}/messages`,
        method: 'POST',
        body: { threadId, customerId, direction, content, replyTo },
      }),
      async onQueryStarted(
        { applicationId, threadId, customerId, createdByName, content, replyTo },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          applicationMessagesApi.util.updateQueryData(
            'getMessagesByFilter',
            { applicationId },
            (draft) => {
              draft.push({
                id: `draft_${getRandomInt()}`,
                draft: true,
                createdDate: new Date().toString(),
                modifiedDate: new Date().toString(),
                applicationId,
                tenantId: 0,
                userId: 'draft',
                createdBy: 'draft',
                modifiedBy: 'draft',
                createdByName,
                modifiedByName: createdByName,
                isRead: false,
                threadId,
                customerId,
                direction: Direction.Out,
                content,
                replyTo,
              });
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: () => [{ type: 'ApplicationMessage', id: 'LIST' }],
    }),
    addMessageBulk: build.mutation<
      Array<ApplicationMessage>,
      { messages: Array<AddApplicationMessagePayload> }
    >({
      query: ({ messages }) => ({
        url: `/applications/${messages[0].applicationId}/messages/bulk`,
        method: 'POST',
        body: [...messages],
        // messages,
      }),
      onQueryStarted({ messages }, { dispatch, queryFulfilled }) {
        const patchResults = messages.map(
          ({ applicationId, threadId, customerId, createdByName, content, replyTo }) =>
            dispatch(
              applicationMessagesApi.util.updateQueryData(
                'getMessagesByFilter',
                { applicationId },
                (draft) => {
                  draft.push({
                    id: `draft_${getRandomInt()}`,
                    draft: true,
                    createdDate: new Date().toString(),
                    modifiedDate: new Date().toString(),
                    applicationId,
                    tenantId: 0,
                    userId: 'draft',
                    createdBy: 'draft',
                    modifiedBy: 'draft',
                    createdByName,
                    modifiedByName: createdByName,
                    isRead: false,
                    threadId,
                    customerId,
                    direction: Direction.Out,
                    content,
                    replyTo,
                  });
                },
              ),
            ),
        );
        queryFulfilled.catch(() => {
          patchResults.forEach((patchResult) => patchResult.undo());
        });
      },
      invalidatesTags: () => [{ type: 'ApplicationMessage', id: 'LIST' }],
    }),

    readMessages: build.mutation<Array<ApplicationMessage>, ReadApplicationMessagesPayload>({
      query: ({ applicationId, messages }) => {
        const messageIds = messages.map((m) => m.id).join(',');
        return {
          url: `applications/${applicationId}/messages/read/${messageIds}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'ApplicationMessage', id: 'LIST' },
        { type: 'ApplicationsUnreadMessages', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetMessageQuery,
  useGetMessagesByFilterQuery,
  useAddMessageMutation,
  useAddMessageBulkMutation,
  useReadMessagesMutation,
} = applicationMessagesApi;
