import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Backdrop, CircularProgress, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import MUIDataTable from '../../components/MUIDataTable';
import { initialFormData, wordTypes } from './constants';
import { makeSelectedProductIdData } from '../../selectors/user';
import {
  useDeleteRiskWordMutation,
  useGetRiskWordsQuery,
} from '../../services/gatewayApi/riskWordsApi';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../constants/constants';
import { ConfirmationDialog } from '../../components';
import RiskWordFormDialog from './RiskWordFormDialog';
import { removeEmptyProperties } from '../../utils/modelHelper';

const RiskWordsList = ({ filterValues }) => {
  const [page, setPage] = useState(0);
  const [riskWords, setRiskWords] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
  const [sortOrder, setSortOrder] = useState({
    name: 'word',
    direction: 'asc',
  });

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const filterValuesClean = _.clone(filterValues);
  removeEmptyProperties(filterValuesClean);

  const { data, isLoading, isFetching } = useGetRiskWordsQuery(
    {
      productId: selectedProductId,
      page,
      size: pageSize,
      sort: `${sortOrder.name},${sortOrder.direction}`,
      ...filterValuesClean,
    },
    {
      skip: !selectedProductId,
    },
  );

  useEffect(() => {
    if (data) {
      setRiskWords(data.results);
      setCount(data.count);

      if (Math.floor((data.count - 1) / pageSize) < page) {
        resetGridPage(); // in case page is not available (after delete)
      }
    }
  }, [data, page, pageSize]);

  const resetGridPage = (skipSetPage = false) => {
    if (!skipSetPage) {
      setPage(0); // move to first page
    }
  };

  const [deleteRiskWord] = useDeleteRiskWordMutation();
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [idToDelete, setIdToDelete] = useState('');

  const AddButton = () => (
    <Tooltip title="Add Risk Word" disableFocusListener>
      <IconButton
        onClick={() => {
          setFormData(initialFormData);
          setFormOpen(true);
        }}
        size="large"
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const handleEdit = (id) => {
    setFormData(riskWords.find((item) => item.id === id));
    setFormOpen(true);
  };

  const handleDelete = (id) => {
    setIdToDelete(id);
  };

  const confirmDelete = () => {
    deleteRiskWord(idToDelete);
    setIdToDelete('');
  };

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    rowsPerPage: pageSize,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    jumpToPage: true,
    onTableChange: (action, state) => {
      if (action === 'sort') {
        resetGridPage();
        setSortOrder(state.sortOrder);
      }

      if (action === 'changePage') {
        resetGridPage(true);
        setPage(state.page);
      }

      if (action === 'changeRowsPerPage') {
        resetGridPage();
        setPageSize(state.rowsPerPage);
      }
    },
    sortOrder,
    selectableRows: 'none',
    textLabels: {
      body: {
        noMatch: isFetching || isLoading ? <LinearProgress /> : 'No matching records found...',
      },
    },
    serverSide: true,
    count,
    page,
    customToolbar: AddButton,
  };

  const columns = [
    {
      name: 'word',
      label: 'Word',
      options: {
        filter: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: (type) => wordTypes.find((wordType) => wordType.value === type)?.label,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => (
          <>
            <Tooltip title="Edit" disableFocusListener>
              <IconButton aria-label="edit" onClick={() => handleEdit(value)} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" disableFocusListener>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDelete(value);
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        <div style={{ position: 'relative' }}>
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MUIDataTable data={riskWords} columns={columns} options={options} />
        </div>

        <ConfirmationDialog
          open={!!idToDelete}
          onClose={() => setIdToDelete('')}
          onCancel={() => setIdToDelete('')}
          onConfirm={confirmDelete}
          title="Deleting Risk Word"
          text="Are you sure you want to permanently delete selected Risk Word?"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          confirmButtonColor="danger"
        />

        <RiskWordFormDialog
          open={formOpen}
          onClose={() => setFormOpen(false)}
          isNew={!formData?.id || false}
          data={formData}
        />
      </div>
    </div>
  );
};

RiskWordsList.propTypes = {
  filterValues: PropTypes.object,
};

export default RiskWordsList;
