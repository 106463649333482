import { gatewayApi } from '../gatewayApi';

export interface ApplicationCustomer {
  id: string;
  applicationId: string;
  keycloakId: string;
  firstName: string;
  lastName: string;
  email: string;
  tenantId: number;
  tenantName: string;
}
type ApplicationCustomersFilter = Pick<ApplicationCustomer, 'applicationId'> &
  Partial<Pick<ApplicationCustomer, 'firstName' | 'lastName' | 'email'>>;

export const applicationCustomersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomersByFilter: build.query<Array<ApplicationCustomer>, ApplicationCustomersFilter>({
      query: ({ applicationId, ...params }) => ({
        url: `applications/${applicationId}/customer-users`,
        params,
        method: 'GET',
      }),
      // replace customerId with id
      transformResponse: (response: Array<ApplicationCustomer & { customerId: string }>) =>
        response.map((customer) => {
          const c = { ...customer };
          c.id = customer.customerId;
          return c;
        }),
      providesTags: () => [{ type: 'ApplicationCustomer', id: 'LIST' }],
    }),
  }),
});

export const { useGetCustomersByFilterQuery } = applicationCustomersApi;
