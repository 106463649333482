import React from 'react';
import { Divider, MenuItem, MenuItemProps, Typography } from '@mui/material';
import {
  RiPencilLine,
  RiTimer2Line,
  RiUploadLine,
  RiForbid2Line,
  RiDeleteBin5Fill,
} from 'react-icons/ri';
import { danger } from 'theme/palette-blocks';

interface DocumentMenuItemsProps extends MenuItemProps {
  uploaded?: boolean;
  reject?: () => any;
  deleteDoc?: () => any;
  edit?: () => any;
  addExpiration?: () => any;
  removeExpiration?: () => any;
  setExpiration?: () => any;
  canBeExpired?: boolean;
  upload?: () => any;
  groups?: string[];
  groupName?: string;
  updateGroup?: (groupName?: string) => any;
  showCreateModal?: () => any;
  groupsMenu?: boolean;
}

export const DocumentMenuItems = (props: DocumentMenuItemsProps): JSX.Element => {
  const {
    onClick,
    canBeExpired = false,
    uploaded = false,
    reject,
    deleteDoc,
    edit,
    addExpiration,
    removeExpiration,
    setExpiration,
    upload,
    groups,
    groupName,
    updateGroup,
    showCreateModal,
    groupsMenu = false,
    ...other
  } = props;

  return (
    <>
      {edit && (
        <MenuItem
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            edit();
          }}
          {...other}
        >
          <RiPencilLine />
          <Typography variant="body1" sx={{ color: 'grey.900', ml: 2 }}>
            Edit document name
          </Typography>
        </MenuItem>
      )}
      {addExpiration && (
        <MenuItem
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            !canBeExpired ? addExpiration() : removeExpiration();
          }}
          {...other}
        >
          <RiTimer2Line />
          <Typography variant="body1" sx={{ color: 'grey.900', ml: 2 }}>
            {!canBeExpired ? 'Add expiration date' : 'Remove expiration date'}
          </Typography>
        </MenuItem>
      )}
      {setExpiration && (
        <MenuItem
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            setExpiration();
          }}
          {...other}
        >
          <RiTimer2Line />
          <Typography variant="body1" sx={{ color: 'grey.900', ml: 2 }}>
            Set expiration date
          </Typography>
        </MenuItem>
      )}
      {upload && (
        <MenuItem
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            upload();
          }}
          {...other}
        >
          <RiUploadLine />
          <Typography variant="body1" sx={{ ml: 2 }}>
            {uploaded ? 'Reupload' : 'Upload'}
          </Typography>
        </MenuItem>
      )}

      {groupsMenu && (
        <MenuItem
          sx={{ color: 'primary.main' }}
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            showCreateModal();
          }}
          {...other}
        >
          <Typography variant="body1" sx={{ color: 'text.link', ml: 1 }}>
            Add to a new group
          </Typography>
        </MenuItem>
      )}

      {groupsMenu &&
        groups &&
        groups.map((group) => {
          if (group !== groupName) {
            return (
              <MenuItem
                key={`Group_Menu_${group}`}
                onClick={(e) => {
                  // eslint-disable-next-line react/prop-types
                  onClick && onClick(e);
                  updateGroup(group);
                }}
                {...other}
              >
                <Typography variant="body1" sx={{ color: 'grey.900', ml: 1 }}>
                  {`Add to the ${group}`}
                </Typography>
              </MenuItem>
            );
          }
          return null;
        })}
      {groupsMenu && groupName && (
        <>
          <Divider />
          <MenuItem
            onClick={(e) => {
              // eslint-disable-next-line react/prop-types
              onClick && onClick(e);
              updateGroup('');
            }}
            {...other}
          >
            <Typography variant="body1" sx={{ color: 'danger.main', ml: 1 }}>
              Remove from the group
            </Typography>
          </MenuItem>
        </>
      )}
      {!groupsMenu && <Divider />}

      {reject && (
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            reject();
          }}
          {...other}
        >
          <RiForbid2Line color={danger.main} />
          <Typography variant="body1" sx={{ color: 'danger.main', ml: 2 }}>
            Reject
          </Typography>
        </MenuItem>
      )}
      {deleteDoc && (
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            deleteDoc();
          }}
          {...other}
        >
          <RiDeleteBin5Fill color={danger.main} />
          <Typography variant="body1" sx={{ color: 'danger.main', ml: 2 }}>
            Delete from application
          </Typography>
        </MenuItem>
      )}
    </>
  );
};
