import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useGetRiskCountryHistoryQuery,
  useGetEeaRiskCountryHistoryQuery,
} from '../../services/gatewayApi/countryRiskApi';
import {
  useCanViewRiskCountry,
  useCanViewEeaRiskCountry,
} from '../../hooks/useHasPermissions';

const CountryRiskHistory = memo(({ countryCode }) => {
  const canViewRiskCountry = useCanViewRiskCountry();

  const { data: riskCountryHistory, isFetching: isRiskCountryFetching } =
    useGetRiskCountryHistoryQuery(countryCode, { skip: !canViewRiskCountry });
  const { data: eeaRiskCountryHistory, isFetching: isEeaRiskCountryFetching } =
    useGetEeaRiskCountryHistoryQuery(countryCode, {
      skip: !useCanViewEeaRiskCountry,
    });

  const isFetching =
    isRiskCountryFetching ||
    isEeaRiskCountryFetching;

  const [history, setHistory] = useState([]);
  useEffect(() => {
    if (!isFetching) {
      const historyMerged = []
        .concat(riskCountryHistory)
        .concat(eeaRiskCountryHistory);
      setHistory(
        historyMerged.sort(
          (a, b) => new Date(b.revision.revisionInstant) - new Date(a.revision.revisionInstant),
        ),
      );
    }
  }, [isFetching]);

  return (
    <>
      <TableRow>
        <TableCell sx={{ paddingBottom: 8, paddingTop: 0 }} colSpan={9}>
          <Box margin={1}>
            {isFetching ? (
              <LinearProgress />
            ) : (
              <Table size="small" aria-label="history">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Modified</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Action</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Risk Status</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {history && history.length && history.some((h) => h !== undefined) ? (
                  <TableBody>
                    {history.map(
                      (h) =>
                        h !== undefined && (
                          <TableRow key={h.revision.revisionNumber}>
                            <TableCell>{h.modified}</TableCell>
                            <TableCell>{h.type}</TableCell>
                            <TableCell>{h.revision.revisionType}</TableCell>
                            <TableCell>{h.riskStatus}</TableCell>
                          </TableRow>
                        ),
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Typography variant="body1" align="center" component="div">
                          No data
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
});

CountryRiskHistory.propTypes = {
  countryCode: PropTypes.string,
};

export default CountryRiskHistory;
