import React from 'react';
import { Chip } from '@mui/material';
import { RiUserLine } from 'react-icons/ri';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import { Application } from '../../../ApplicationManagement/types';

interface AssigneeChipProps {
  application: Application;
}

export const AssigneeChip: React.FC<AssigneeChipProps> = ({ application }) => {
  const { data: usersList = [], isLoading: isUsersLoading } = useGetListUsersQuery(undefined);

  const getName = (userId) => usersList.find((el) => el.id === userId)?.name || userId;

  const { assignee } = application || {};

  return (
    <>
      {!isUsersLoading && (
        <Chip
          icon={<RiUserLine size="14px" />}
          size="small"
          label={getName(assignee)}
          sx={{ borderRadius: '6px', pl: 1 }}
        />
      )}
    </>
  );
};
