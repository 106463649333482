import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

import './RiskStatusBox.scss';

type RiskStatus =
  | 'LOW_RISK'
  | 'MEDIUM_RISK'
  | 'HIGH_RISK'
  | 'RESTRICTED'
  | 'PROHIBITED'
  | 'NOT_APPLICABLE'
  | 'NON_REPUTABLE'
  | 'DELETED';

interface RiskStatusMap {
  status?: RiskStatus;
  tooltip?: string;
  color: string;
  icon?: any;
}

const riskStatusMap: RiskStatusMap[] = [
  {
    status: 'LOW_RISK',
    color: 'successLight.bg-100', //success-light/bg-100
  },
  {
    status: 'MEDIUM_RISK',
    color: 'primaryLight.bg-100', //primary-light/bg-100
  },
  {
    status: 'HIGH_RISK',
    tooltip: 'Escalate to MLRO',
    color: 'warning.bg-300', //warning/bg-300
  },
  {
    status: 'RESTRICTED',
    tooltip: 'Restricted Country Warning. Escalate to MLRO',
    color: 'warning.bg-300', //warning/bg-300
  },
  {
    status: 'PROHIBITED',
    tooltip: 'Escalate to MLRO',
    color: 'text.danger', //text/danger-100
    icon: <ErrorOutlineIcon sx={{ color: '#fff' }} />,
  },
  {
    status: 'NOT_APPLICABLE',
    color: 'primaryLight.bg-100', //primary-light/bg-100
  },
  {
    status: 'NON_REPUTABLE',
    tooltip: 'Non-Reputable Country Warning - Escalate',
    color: 'warning.bg-300', //warning/bg-300
    icon: <ErrorOutlineIcon sx={{ color: 'text.danger' }} />,
  },
  {
    status: 'DELETED',
    color: 'primaryLight.bg-100', //primary-light/bg-100
  },
];

interface RiskStatusBoxProps {
  status?: RiskStatus;
  tooltip?: string;
}

const RiskStatusBox: React.FC<RiskStatusBoxProps> = ({ status, tooltip }) => {
  const riskStatus = riskStatusMap.find((s) => s.status === status);

  return (
    <Tooltip title={tooltip || riskStatus?.tooltip}>
      <Box
        className="RiskStatusBox"
        sx={{ bgcolor: riskStatus?.color, borderColor: riskStatus?.color }}
      >
        {riskStatus?.icon}
      </Box>
    </Tooltip>
  );
};

export { RiskStatusBox };
