/** Dialog for checkboxes select/reselect some elements from list
 *  returns a new list of selected items
 * */

import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlineIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { RULE_NAME_REGEX } from 'constants/constants';
import { Button } from 'uikit/Button';
import { FigmaColor } from '../../theme';

interface SelectRolesDialogProps {
  title: string;
  placeholder?: string;
  list: any[];
  defaultList: any[];
  selectedList: any[];
  setSelectedList: any;
  open: boolean;
  onClose: any;
  haveSearch?: boolean;
}

const DialogSelectFromList: React.FC<SelectRolesDialogProps> = ({
  title,
  placeholder,
  list,
  defaultList,
  selectedList,
  setSelectedList,
  open,
  onClose,
  haveSearch,
}) => {
  const [listFilterCase, setListFilterCase] = useState('');
  const [currentListSelected, setCurrentListSelected] = useState(selectedList);
  const [errorInput, setErrorInput] = useState(false);

  const listFilter = (item) =>
    item.name?.toLowerCase().search(listFilterCase.toLowerCase()) > -1 ||
    item.description?.toLowerCase().search(listFilterCase.toLowerCase()) > -1 ||
    item.id.toString().toLowerCase().search(listFilterCase.toLowerCase()) > -1;

  const handleFilterChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.match(RULE_NAME_REGEX)) {
      setErrorInput(false);
      return setListFilterCase(inputValue);
    }
    return setErrorInput(true);
  };

  const getIsRoleChecked = (elementId) => currentListSelected.some((el) => el.id === elementId);

  const getIsDefault = (elementId) => defaultList.some((el) => el === elementId);

  const switchRoleChecked = (elementId) => {
    const [newElement] = list.filter((el) => el.id === elementId);
    getIsRoleChecked(elementId)
      ? setCurrentListSelected((prev) => prev.filter((el) => el.id !== elementId))
      : setCurrentListSelected((prev) => [...prev, newElement]);
  };

  const RenderItem = ({ item }) => {
    const disabled = getIsDefault(item.id);
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          py: 1,
          '&:hover': { color: FigmaColor({ colorName: 'blue', shade: 900 }), cursor: 'pointer' },
        }}
        alignItems="center"
        onClick={() => (!disabled ? switchRoleChecked(item.id) : '')}
      >
        <Grid item md={1}>
          <Checkbox disabled={disabled} checked={getIsRoleChecked(item.id)} />
        </Grid>
        <Grid item md={11}>
          {/* @ts-ignore */}
          <Typography variant="body-1">{item.name}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">{item.description && item.description}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby={`${title}-dialog`} maxWidth="md">
        <DialogTitle id={`${title}-dialog`}>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: '8px', width: '515px' }}
          >
            <Grid item>
              <span>{title}</span>
            </Grid>
            <Grid item>
              <Typography component="span" sx={{ fontSize: '12px', color: 'grey.600' }}>
                {`   selected: ${currentListSelected.length}`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => onClose()} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
            {haveSearch && (
              <Grid item md={12}>
                <OutlinedInput
                  id="searchInput"
                  type="text"
                  name="searchInput"
                  placeholder={placeholder}
                  fullWidth
                  autoFocus
                  value={listFilterCase}
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  error={errorInput}
                  inputProps={{ maxLength: 128 }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setListFilterCase('')}
                        size="small"
                      >
                        {listFilterCase === '' ? <DeleteOutlineIcon /> : <DeleteIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            )}
          </Grid>
        </DialogTitle>

        <Divider variant="middle" />

        <DialogContent>
          <Box sx={{ width: '450px', height: '500px' }}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
              {list.filter(listFilter).map((el) => (
                <RenderItem item={el} key={el.id} />
              ))}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ p: '16px' }}
          >
            <Grid item>
              <Button type="button" onClick={() => onClose()} theme="text">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={() => setSelectedList(currentListSelected)}
                theme="primary"
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { DialogSelectFromList };
