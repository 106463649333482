import React, { useEffect, useState } from 'react';
import './PopupAlert.scss';
import ReactDOM from 'react-dom';

interface PopupAlertProps {
  show: boolean;
  textContent: string;
}

const PopupAlert: React.FC<PopupAlertProps> = ({ textContent, show }) => {
  const checkAside = () => {
    const asideContent = document.querySelector('.asideContent--showed');
    return !!asideContent;
  };

  return ReactDOM.createPortal(
    show && (
      <div className={`PopupAlert ${checkAside() ? 'PopupAlert--withAsideOffset' : ''}`}>
        {textContent}
      </div>
    ),
    document.getElementById('popup-alert-root') as HTMLDivElement,
  );
};

export { PopupAlert };
