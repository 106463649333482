import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import SortableTree from 'react-sortable-tree';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetRulesQuery } from 'services/internalApi';
import { updateCurrentRuleSetId, updateExpanded, updateRuleset } from '../rulesSlice';
import { isNewregex } from '../constatns';
import { RuleSortable, StateType } from '../types';
import { NodeRenderer } from './NodeRender';
import { findDeepById, getExpandedLength, makeFlatIds } from '../rulesUtils';

export type RuleSetProps = {
  isLocked: boolean;
  isShowInactive: boolean;
  allowAnimate: boolean;
  reset?: boolean;
  setListModifiedRules: React.Dispatch<React.SetStateAction<string[]>>;
  setIsShowInactive?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPublishDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSameListRules?: React.Dispatch<React.SetStateAction<boolean>>;
  setReset?: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchCounter?: React.Dispatch<React.SetStateAction<string>>;
  searchFocusIndex?: number;
  setSearchFocusIndex?: React.Dispatch<React.SetStateAction<number>>;
  setSearchFoundCount?: React.Dispatch<React.SetStateAction<number | null>>;
};

export const RulesTree: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleSetId } = useParams<{ ruleSetId: string }>();

  const {
    isLocked,
    setIsShowInactive,
    setListModifiedRules,
    setIsSameListRules,
    setIsPublishDisabled,
    isShowInactive,
    allowAnimate,
    reset,
    setReset,
    setSearchCounter,
    searchFocusIndex,
    setSearchFocusIndex,
    setSearchFoundCount,
  } = useOutletContext<RuleSetProps>();

  const { ruleSetState, ruleSetStateId, searchString } = useSelector((state: StateType) => ({
    ruleSetState: state.ruleSetState.rulesTree,
    ruleSetStateId: state.ruleSetState.currentRuleSetId,
    searchString: state.ruleSetState.searchString,
  }));

  const [filteredRuleset, setFilteredRuleset] = useState(ruleSetState);

  const {
    data: rulesList,
    isLoading: isLoadingRulesList,
    isFetching: isFetchingRulesList,
  } = useGetRulesQuery(
    {
      // body, // TODO make with params
      ruleSetId,
    },
    {
      skip: !ruleSetId,
    },
  );

  const listIdState = makeFlatIds(ruleSetState);
  const listIdSaved = makeFlatIds(rulesList);

  useEffect(() => {
    setIsSameListRules(
      listIdState.every((el, idx) => el === listIdSaved[idx]) &&
        listIdState.length === listIdSaved.length,
    );
  }, [listIdState, listIdSaved]);

  useEffect(() => {
    const loading = isFetchingRulesList || isLoadingRulesList;
    if (
      (rulesList && !loading && ruleSetState?.length === 0) ||
      (rulesList && !loading && reset) ||
      ruleSetStateId !== ruleSetId
    ) {
      dispatch(updateRuleset(rulesList));
      dispatch(updateCurrentRuleSetId(ruleSetId));
      setReset(false);
      setListModifiedRules([]);
    }
  }, [rulesList, reset, isFetchingRulesList, isLoadingRulesList]);

  useEffect(() => {
    setFilteredRuleset(isShowInactive ? ruleSetState : deepFilter(ruleSetState));
  }, [isShowInactive, ruleSetState]);

  // check current ruleSet state for publish
  useEffect(() => {
    let isError = false;
    const findChanges = (arr: RuleSortable[]) => {
      arr.forEach((rule) => {
        if (isNewregex.test(rule.id) || rule.isEdited || rule.isForDelete || rule.isMoved) {
          setListModifiedRules((prev) => [...prev, rule.id]);
        }
        if (!rule.script) {
          isError = true;
        } // prevent publish rules without script
        rule.children && findChanges(rule.children);
      });
    };
    findChanges(ruleSetState);
    setIsPublishDisabled(isError);
  }, [ruleSetState]);

  const deepFilter = (arr: RuleSortable[]): RuleSortable[] => {
    const filtered = arr.filter((rule) => rule.isActive);
    return filtered.map((rule) => ({
      ...rule,
      children: deepFilter(rule.children),
    }));
  };

  const handleCanDrop = ({ nextParent }) =>
    !(nextParent?.isParentForDelete || nextParent?.isForDelete);

  const getRule = (rules, id) => findDeepById(rules, id);

  const setExpandDeep = (arr: RuleSortable[], expandRules: string[]): RuleSortable[] =>
    arr.map((rule) => ({
      ...rule,
      expanded: expandRules.includes(rule.id),
      children: setExpandDeep(rule.children, expandRules),
    }));

  const searchFinishCallback = (searchMatches) => {
    setSearchFoundCount(searchMatches?.length || 0);
    setSearchFocusIndex(searchMatches?.length > 0 ? searchFocusIndex % searchMatches.length : 0);
    setSearchCounter(
      `${searchMatches?.length > 0 ? (searchFocusIndex % searchMatches.length) + 1 : 0}/${
        searchMatches?.length || 0
      }`,
    );
    const makeExpandList = searchMatches.flatMap((item) => item.path.slice(0, -1));
    const withoutDublicates = [...new Set(makeExpandList)];
    dispatch(updateExpanded(withoutDublicates));
    const focusedRuleId =
      searchMatches?.length > 0 ? searchMatches[searchFocusIndex].node?.id : undefined;
    const element = document.getElementById(`ruleRow-${focusedRuleId}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const searchMethod = ({
    node,
    searchQuery,
  }: {
    node: { id: string | number; title: string; description: string };
    searchQuery: string;
  }) => {
    const { id, title, description } = node;
    const isNew = isNewregex.test(id.toString());
    return !!(
      searchQuery !== '' &&
      ((!isNew && id.toString().toLowerCase().includes(searchQuery.toString().toLowerCase())) ||
        title.toString().toLowerCase().includes(searchQuery.toString().toLowerCase()) ||
        description.toString().toLowerCase().includes(searchQuery.toString().toLowerCase()))
    );
  };

  return (
    <div
      className="rules-content"
      style={{ marginLeft: '-8px', display: 'flex', flexDirection: 'column' }}
    >
      <Helmet title="Rule Sets" />

      {isLoadingRulesList || isFetchingRulesList ? (
        <div style={{ paddingTop: '8px' }}>Loading ...</div>
      ) : (
        <div
          className="rules-tree"
          style={{
            height: `${getExpandedLength(filteredRuleset) * 62 + 62}px`,
            paddingTop: '8px',
            paddingBottom: '32px',
            marginLeft: '-44px',
            marginRight: '-60px',
          }}
        >
          <SortableTree
            treeData={filteredRuleset}
            onChange={(treeData: any[]) => dispatch(updateRuleset(treeData))}
            getNodeKey={({ node }) => node.id}
            generateNodeProps={(nodeProps) => ({
              ...nodeProps,
              isLocked,
              allowAnimate,
              setIsShowInactive,
              currentStateRule: getRule(ruleSetState, nodeProps.node.id),
              savedRule: getRule(rulesList, nodeProps.node.id),
            })}
            canDrag={!isLocked}
            canDrop={({ nextParent }) => handleCanDrop({ nextParent })}
            searchQuery={searchString}
            searchFocusOffset={searchFocusIndex}
            searchMethod={searchMethod}
            searchFinishCallback={(searchMatches) => searchFinishCallback(searchMatches)}
            nodeContentRenderer={NodeRenderer}
          />
        </div>
      )}
    </div>
  );
};
