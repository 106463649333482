export const statusesMap = [
  {
    type: 'kycStatus',
    value: 'NOT_PERFORMED',
    label: 'Not performed',
    icon: 'checked',
    color: 'default',
  },
  {
    type: 'kycStatus',
    value: 'COMPLETE',
    label: 'Complete',
    icon: 'checked',
    color: 'default',
  },
  {
    type: 'kycStatus',
    value: 'NEW',
    label: 'New',
    icon: 'checked',
    color: 'default',
  },
  {
    type: 'kycStatus',
    value: 'VERIFIED',
    label: 'Verified',
    icon: 'checked',
    color: 'success',
  },
  {
    type: 'kycStatus',
    value: 'NOT_VERIFIED',
    label: 'Not Verified',
    icon: 'warning',
    color: 'error',
  },

  {
    type: 'kycResult',
    value: 'UNIDENTIFIED',
    label: 'Unidentified',
    icon: 'cancel',
    color: 'error',
  },
  {
    type: 'kycResult',
    value: 'CONSIDER',
    label: 'Consider',
    icon: 'warning',
    color: 'warning',
  },
  {
    type: 'kycResult',
    value: 'CLEAR',
    label: 'Clear',
    icon: 'checked',
    color: 'success',
  },

  {
    type: 'pepStatus',
    value: 'NOT_PERFORMED',
    label: 'Not performed',
    icon: 'checked',
    color: 'default',
  },
  {
    type: 'pepStatus',
    value: 'NO_MATCHES',
    label: 'No matches',
    icon: 'checked',
    color: 'success',
  },
  {
    type: 'pepStatus',
    value: 'MATCHES',
    label: 'Match',
    icon: 'warning',
    color: 'error',
  },

  {
    type: 'riskStatus',
    value: 'NOT_PERFORMED',
    label: 'Not performed',
    icon: 'checked',
    color: 'default',
  },
  {
    type: 'riskStatus',
    value: 'LOW',
    label: 'Low risk',
    icon: 'circle',
    color: 'success',
  },
  {
    type: 'riskStatus',
    value: 'MEDIUM',
    label: 'Medium risk',
    icon: 'circle',
    color: 'warning',
  },
  {
    type: 'riskStatus',
    value: 'HIGH',
    label: 'High risk',
    icon: 'circle',
    color: 'error',
  },
  {
    type: 'riskStatus',
    value: 'VERY_HIGH',
    label: 'Very High risk',
    icon: 'circle',
    color: 'error',
  },
  {
    type: 'riskStatus',
    value: 'PROHIBITED',
    label: 'Prohibited',
    icon: 'circle',
    color: 'error',
  },
];
