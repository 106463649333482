import { primary, primaryLight } from '../../palette-blocks';

export const PrimaryChip = {
  props: {
    color: 'primary',
  },
  style: {
    '&.MuiChip-clickable': {
      '&:hover': {
        background: primary.hover,
      },
      '&:active': {
        background: primary.active,
      },
    },
  },
};

export const PrimaryChipOutlined = {
  props: {
    color: 'primary',
    variant: 'outlined',
  },
  style: {
    background: 'white',
    '&.MuiChip-clickable': {
      '&:hover': {
        background: primaryLight.hover,
        borderColor: primary.hover,
      },
      '&:active': {
        background: primaryLight.active,
        borderColor: primary.active,
      },
    },
  },
};
