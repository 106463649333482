import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Circle as CircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { RowLayout } from './RowLayout';
import { Permission, Resource } from '../types';

const CheckboxStyled = styled(Checkbox, {
  name: 'CheckboxStyled',
})(({ theme }) =>
  theme.unstable_sx({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    '&:active, &:focus, &:focusWithin': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiSvgIcon-root': {
      color: 'grey.700',
    },
    '&.Mui-checked': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: 'grey.700',
      },
    },
    '&.Mui-disabled, &.MuiCheckbox-root.Mui-disabled': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      '& .MuiSvgIcon-root': {
        color: 'grey.500',
      },
    },
  }),
);

export interface RoleDialogPermissionsProps {
  permissionsList: Permission[];
  selectedPermissions: Permission[];
  setSelectedPermissions: React.Dispatch<React.SetStateAction<Permission[]>>;
}

export const RoleDialogPermissions: React.FC<RoleDialogPermissionsProps> = React.memo((props) => {
  // @ts-ignore
  const { permissionsList, selectedPermissions, setSelectedPermissions } = props;

  const isSelectedPermissionsHasAdmin = selectedPermissions.some(
    (el) => el.name === 'TENANT_ADMIN' || el.name === 'SUPER_ADMIN',
  );
  const isSelectedPermissionsHasCustomer = selectedPermissions.some(
    (el) => el.resource.scope === 'CUSTOMER-PORTAL',
  );
  const isSelectedPermissionsHasOther = selectedPermissions.some(
    (el) =>
      el.name !== 'TENANT_ADMIN' &&
      el.name !== 'SUPER_ADMIN' &&
      el.resource.scope !== 'CUSTOMER-PORTAL',
  );

  const isDisabledCheck = (resource) => {
    if (
      !isSelectedPermissionsHasCustomer &&
      resource.scope === 'CUSTOMER-PORTAL' &&
      (isSelectedPermissionsHasOther || isSelectedPermissionsHasAdmin)
    ) {
      return true;
    }
    if (
      !isSelectedPermissionsHasAdmin &&
      resource.scope === 'ADMIN' &&
      (isSelectedPermissionsHasOther || isSelectedPermissionsHasCustomer)
    ) {
      return true;
    }
    if (
      !isSelectedPermissionsHasOther &&
      resource.scope !== 'ADMIN' &&
      resource.scope !== 'CUSTOMER-PORTAL' &&
      (isSelectedPermissionsHasAdmin || isSelectedPermissionsHasCustomer)
    ) {
      return true;
    }
    return false;
  };

  const [listResources, setListResources] = useState<Resource[]>([]);

  useEffect(() => {
    if (permissionsList?.length > 0) {
      const filteredList = permissionsList
        .filter((el) => !!el.resource && !!el.type)
        .map((el) => ({ ...el.resource }));
      const withoutDuplicates = filteredList
        .filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
        .sort(
          (a, b) => a.scope.localeCompare(b.scope) || a.description.localeCompare(b.description),
        );
      setListResources(withoutDuplicates);
    }
  }, [permissionsList]);

  const getIsPermissionChecked = (resourceId, permissionType) => {
    if (permissionType === 'EDIT') {
      return selectedPermissions.some(
        (el) => el.resource.id === resourceId && (el.type === 'EDIT' || el.type === 'AMC_EDIT'),
      );
    }
    return selectedPermissions.some(
      (el) => el.resource.id === resourceId && el.type === permissionType,
    );
  };

  const switchChecked = (resourceId, permissionType, isChecked, hasAmcSupport) => {
    switch (permissionType) {
      case 'VIEW':
        isChecked
          ? setSelectedPermissions((prev) => prev.filter((el) => el.resource.id !== resourceId)) // remove all types
          : setSelectedPermissions((prev) => [
              ...prev,
              ...permissionsList.filter(
                (el) => el.resource?.id === resourceId && el.type === permissionType,
              ), // add only VIEW type
            ]);
        break;
      case 'EDIT':
        const hasAMC_APPROVE = selectedPermissions.some(
          (el) => el.resource.id === resourceId && el.type === 'AMC_APPROVE',
        );
        let editPermissions = [];
        (function () {
          if (hasAmcSupport) {
            return (editPermissions = hasAMC_APPROVE
              ? permissionsList.filter(
                  (el) =>
                    el.resource?.id === resourceId &&
                    (el.type === 'VIEW' || el.type === 'EDIT' || el.type === 'AMC_APPROVE'),
                ) // add all types (without AML_EDIT)
              : permissionsList.filter(
                  (el) =>
                    el.resource?.id === resourceId && (el.type === 'VIEW' || el.type === 'EDIT'),
                )); // add VIEW , EDIT , AMC_EDIT type
          }
          return (editPermissions = permissionsList.filter((el) => el.resource?.id === resourceId)); // add all types
        })();
        isChecked
          ? setSelectedPermissions((prev) =>
              prev.filter(
                (el) =>
                  (el.resource.id === resourceId && el.type !== 'EDIT' && el.type !== 'AMC_EDIT') ||
                  el.resource.id !== resourceId, // remove EDIT and AMC_EDIT
              ),
            )
          : setSelectedPermissions((prev) => [
              ...prev.filter((el) => el.resource.id !== resourceId),
              ...editPermissions,
            ]);
        break;
      case 'AMC_APPROVE':
        const hasAMC_EDIT = selectedPermissions.some(
          (el) => el.resource.id === resourceId && (el.type === 'AMC_EDIT' || el.type === 'EDIT'),
        );
        let approvePermissions = [];
        (function () {
          return (approvePermissions = hasAMC_EDIT
            ? permissionsList.filter(
                (el) =>
                  el.resource?.id === resourceId &&
                  (el.type === 'VIEW' || el.type === 'AMC_APPROVE' || el.type === 'EDIT'),
              ) // add all types (without AML_EDIT)
            : permissionsList.filter(
                (el) =>
                  el.resource?.id === resourceId &&
                  (el.type === 'VIEW' || el.type === 'AMC_APPROVE'),
              )); // add VIEW , EDIT , AMC_APPROVE type
        })();
        isChecked
          ? setSelectedPermissions((prev) =>
              prev.filter(
                (el) =>
                  (el.resource.id === resourceId && el.type !== 'AMC_APPROVE') ||
                  el.resource.id !== resourceId, // remove EDIT and AMC_EDIT
              ),
            )
          : setSelectedPermissions((prev) => [
              ...prev.filter((el) => el.resource.id !== resourceId),
              ...approvePermissions,
            ]);
        break;
    }
  };

  const RenderPermissionRow = ({ resource }) => {
    const isView = permissionsList.some(
      (el) => el.resource?.id === resource.id && el.type === 'VIEW',
    );
    const isEdit = permissionsList.some(
      (el) => el.resource?.id === resource.id && (el.type === 'EDIT' || el.type === 'AMC_EDIT'),
    );
    return (
      <RowLayout
        column1={
          <Typography component="span" sx={{ pl: 2 }}>
            {resource.description}
          </Typography>
        }
        column2={
          isView ? (
            <CheckboxStyled
              // sx={{ p: 1 }}
              disabled={isDisabledCheck(resource)}
              inputProps={{ 'aria-label': 'view' }}
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<CheckCircleIcon fontSize="small" />}
              checked={getIsPermissionChecked(resource.id, 'VIEW')}
              onChange={(e, checkedState) =>
                switchChecked(resource.id, 'VIEW', !checkedState, resource.hasAmcSupport)
              }
            />
          ) : (
            <CheckboxStyled
              disabled
              icon={<CircleIcon fontSize="small" sx={{ color: 'grey.100' }} />}
            />
          )
        }
        column3={
          isEdit ? (
            <CheckboxStyled
              disabled={isDisabledCheck(resource)}
              inputProps={{ 'aria-label': 'edit' }}
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<CheckCircleIcon fontSize="small" />}
              checked={getIsPermissionChecked(resource.id, 'EDIT')}
              onChange={(e, checkedState) =>
                switchChecked(resource.id, 'EDIT', !checkedState, resource.hasAmcSupport)
              }
            />
          ) : (
            <CheckboxStyled
              disabled
              icon={<CircleIcon fontSize="small" sx={{ color: 'grey.100' }} />}
            />
          )
        }
        column4={
          resource.hasAmcSupport ? (
            <CheckboxStyled
              disabled={isDisabledCheck(resource)}
              inputProps={{ 'aria-label': 'approve' }}
              icon={<RadioButtonUncheckedIcon fontSize="small" />}
              checkedIcon={<CheckCircleIcon fontSize="small" />}
              checked={getIsPermissionChecked(resource.id, 'AMC_APPROVE')}
              onChange={(e, checkedState) =>
                switchChecked(resource.id, 'AMC_APPROVE', !checkedState, resource.hasAmcSupport)
              }
            />
          ) : (
            <CheckboxStyled
              disabled
              icon={<CircleIcon fontSize="small" sx={{ color: 'grey.100' }} />}
            />
          )
        }
      />
    );
  };

  const ScopeTitle = ({ name }) => (
    <Box className="ScopeTitle" sx={{ width: '100%', pt: 2 }}>
      <Divider variant="fullWidth" light />
      <Typography variant="subtitle1" pt={2} pl={2}>
        {name}
      </Typography>
    </Box>
  );

  const groupedResources = listResources?.reduce((acc, resource) => {
    const { scope } = resource;
    if (!acc[scope]) {
      acc[scope] = [];
    }
    acc[scope].push(resource);
    return acc;
  }, {});

  return (
    <Grid
      container
      spacing={2}
      className="PermissionsWrapper"
      sx={{ height: '400px', overflow: 'auto' }}
    >
      {Object.entries(groupedResources).map(([scope, resources], index) => (
        <React.Fragment key={scope}>
          <ScopeTitle name={scope} />
          {resources.map((resource) => (
            <Grid item md={12} xs={12} key={resource.id}>
              <RenderPermissionRow resource={resource} />
            </Grid>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
});
