import React from 'react';
import './FormLabel.scss';

export interface FormLabelProps {
  children: React.ReactChildren | React.ReactChild | React.ReactChildren[] | React.ReactChild[];
}

export const FormLabel: React.FC<FormLabelProps> = ({ children }) => (
  <label className="FormLabel">{children}</label>
);
