import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { DetailsDrawer, PageContainer, Spinner } from 'uikit';
import { Sidebar, SidebarItem, SidebarMenu } from 'uikit/Sidebar';
import { SidebarItemType, SidebarMenuGroup } from 'uikit/Sidebar/SidebarTypes';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';
import { useGetHaystackClientQuery } from 'services/gatewayApi/haystackClientApi';
import { testUuid, toastError } from 'utils';
import { NotesSidebar } from './NotesSidebar';
import { ClientDetailsProps, ClientDetailsOutletProps } from './ClientDetails.props';
import { applicationKinds } from 'features/ApplicationManagement/ClientDetails/Tabs/ApplicationsTab/constants';

import './ClientDetails.scss';

export const ClientDetails: React.FC<ClientDetailsProps> = () => {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const isValidUuid = testUuid(clientId);

  const {
    data: client,
    isLoading: isLoadingClient,
    isError,
  } = useGetHaystackClientQuery(clientId, {
    skip: !clientId,
  });

  const { applications, isLoadingApplications } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
    },
    {
      skip: !isValidUuid || !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        applications: data?.content,
        isLoadingApplications: isLoading,
      }),
    },
  );

  const pageUrl = `/application-management/${clientId}`;
  isError && navigate('/application-management');

  if (location.pathname === pageUrl) {
    navigate(`${pageUrl}/overview`);
  }

  if (!isValidUuid) {
    toastError('Error: Application does not exist.');
    navigate('/application-management');
    // return null;
  }

  // #region Sidebar menu items
  const sidebarMenu: SidebarMenuGroup[] = [
    {
      title: 'Client',
      links: [
        { title: 'Overview', link: 'overview' },
        { title: 'Summary', link: 'summary' },
        { title: 'Customer Portal Users', link: 'customer-users' },
        { title: 'OpenPayd Users', link: 'openpayd-users' },
        { title: 'Associated Individuals', link: 'associated-individuals' },
        { title: 'Shareholders', link: 'shareholders' },
      ],
    },
    {
      title: 'Applications',
      links: !isLoadingApplications && applications && applications?.map((app) => ({
        title: `${applicationKinds[app.kind].replace(' Application', '')} (${app?.categoryId === 5 ? 'Malta' : 'UK'})`,
        link: `applications/${app.id}`
      })) || [],
    },
  ];
  // #endregion

  // #region Title overflow tooltip
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const titleRef = useRef();
  const isOverflowActive = (event) =>
    event && (event.scrollWidth > event.offsetWidth);

  useEffect(() => {
    if (isOverflowActive(titleRef.current)) {
      setShowTooltip(true);
      return;
    }

    setShowTooltip(false);
  }, [isOverflowActive]);
  // #endregion

  const [openNotesDrawer, setOpenNotesDrawer] = useState<boolean>(false);
  const outletProps: ClientDetailsOutletProps = [setOpenNotesDrawer];

  const isLoading = isLoadingApplications || isLoadingClient || !client?.companyName;

  return (
    <Box className="ClientDetails">
      <Helmet title="Application Details" />

      {isLoading ? (
        <Box className="app-details-preloader">
          <Spinner />
        </Box>
      ) : (
        <>
          <DetailsDrawer open={openNotesDrawer}>
            <NotesSidebar onClose={() => setOpenNotesDrawer(false)} />
          </DetailsDrawer>

          {/* Different half bgcolor trick */}
          <Box sx={{ position: 'absolute', zIndex: 1, width: '50%', right: 0, height: '100%', minHeight: '100vh', bgcolor: 'white' }}></Box>

          <Box sx={{ zIndex: 10, position: 'relative' }}>
            <PageContainer>
              <Grid container sx={{ bgcolor: 'white' }}>
                <Grid item xs={2}>
                  <Box sx={{ pt: 10, pr: 3, height: '100%', minHeight: '100vh', bgcolor: 'grey.50' }}>
                    <Tooltip title={showTooltip ? client?.companyName : ''}>
                      <Typography ref={titleRef} variant="h1" className="application-dashboard-title" px={2}>
                        {client?.companyName}
                      </Typography>
                    </Tooltip>

                    <Sidebar>
                      {sidebarMenu.map(({ title: groupTitle, links }) => (
                        <SidebarMenu key={`menu_${groupTitle}`}>
                          <SidebarItem title={groupTitle} />
                          {links
                            .filter(({ hidden }) => !hidden)
                            .map(({ title: linkTitle, link, action }) => (
                              <SidebarItem
                                key={`link_${link}`}
                                type={SidebarItemType.NavLink}
                                title={linkTitle}
                                link={link}
                                action={action}
                                pageUrl={pageUrl}
                              />
                            ))}
                        </SidebarMenu>
                      ))}
                    </Sidebar>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box sx={{ pt: 4, pb: 8, pl: 8, minHeight: '100vh' }}>
                    {isLoading ? (
                      <Box className="app-details-preloader">
                        <Spinner />
                      </Box>
                    ) : (
                      <Outlet context={outletProps} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </PageContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
