export default function (apiNamesToLook) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user || !user.tenants) return false;

  const selectedProduct = user.tenants.find((p) => p.id === parseInt(user.selectedProductId));

  if (typeof selectedProduct !== 'undefined') {
    if (apiNamesToLook.indexOf(selectedProduct.productApi.apiName) > -1) {
      return true; // exists
    }
  }

  return false;
}
