import React from 'react';
import { Box } from '@mui/material';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { isSameDay } from 'date-fns';
import { styled } from '@mui/material/styles';
import Message, { MessageType } from './Message';
import MessageInput from './MessageInput';
import MessageDate from './MessageDate';

const MessagesContainer = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    py: 2,
    mb: 4,
    overflow: 'auto',

    '::-webkit-scrollbar': {
      width: '16px',
    },
    '::-webkit-scrollbar-thumb': {
      height: '56px',
      borderRadius: '8px',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
      bgcolor: 'transparent',
    },
    ':hover::-webkit-scrollbar-thumb': {
      bgcolor: 'grey.300',
    },
  }),
);

interface MessagesPanelProps<T> {
  messages: Array<T>;
  addMessage: any;
  isSendToAll?: boolean;
  setDisabledSendToAll: (disabled: boolean) => void;
}

const MessagesPanel = <T extends MessageType & { draft?: boolean }>({
  messages,
  addMessage,
  isSendToAll = false,
  setDisabledSendToAll,
}: MessagesPanelProps<T>): JSX.Element => {
  const inputRef = React.useRef<HTMLTextAreaElement>();
  const messagesHtmlRef = React.useRef<HTMLDivElement>();
  const [messagesCount, setMessagesCount] = React.useState<number>(0);

  const focusInput = () => {
    // WYSIWYG editor input ref is not implemented
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (messagesCount != messages?.length) {
      setMessagesCount(messages?.length);
    }
  }, [messages]);

  React.useLayoutEffect(() => {
    focusInput();
    if (messagesHtmlRef.current) {
      messagesHtmlRef.current.scrollIntoView(false);
    }
  }, [messagesCount]);

  const [replyMessage, setReplyMessage] = React.useState<MessageType>();
  const shouldDisplayDate = (prev: T, curr: T) =>
    !prev || (prev && !isSameDay(new Date(prev.createdDate), new Date(curr.createdDate)));
  const getQuote = (replyTo, messages) =>
    replyTo ? messages.find((m) => m.id === replyTo)?.content : undefined;

  React.useLayoutEffect(() => {
    focusInput();
  }, []);

  return (
    <>
      <MessagesContainer>
        <Box>This is the very beginning of your conversation</Box>
        {messages &&
          messages.map((m, i, arr) => (
            <Box key={m.id}>
              {shouldDisplayDate(arr[i - 1], arr[i]) && (
                <MessageDate date={m.createdDate} format={DATE_FORMAT_MESSAGES} />
              )}
              <Message
                isSendToAll={isSendToAll}
                message={m}
                quote={getQuote(m.replyTo, messages)}
                onReplyClick={
                  !m.draft
                    ? (message) => {
                      setReplyMessage(message);
                      setDisabledSendToAll(true);
                      focusInput();
                    }
                    : undefined
                }
              />
            </Box>
          ))}
        <div ref={messagesHtmlRef} />
      </MessagesContainer>
      <MessageInput
        ref={inputRef}
        addMessage={addMessage}
        replyMessage={replyMessage}
        onDeleteReply={() => {
          setReplyMessage(undefined);
          setDisabledSendToAll(false);
        }}
      />
    </>
  );
};

export default MessagesPanel;
