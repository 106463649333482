/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useAddMerchantCategoryMutation,
  useUpdateMerchantCategoryMutation,
} from '../../services/gatewayApi/merchantCategoriesApi';
import { makeSelectedProductIdData } from '../../selectors/user';

const MerchantCategoryFormDialog = ({ open, onClose, isNew, data }) => {
  const validationSchema = yup.object({
    categoryName: yup
      .string()
      .required('Required')
      .matches(
        /^[^- ][A-Za-z0-9- ]*[^- ]$/,
        'Only letters, dashes and spaces in the middle are allowed',
      )
      .min(3, 'Minimum length is ${min}')
      .max(128, 'Maximum length is ${max}'),
  });

  const [createMerchantCategory] = useAddMerchantCategoryMutation();
  const [updateMerchantCategory] = useUpdateMerchantCategoryMutation();

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const model = {
        ...values,
        productId: selectedProductId,
      };
      const mutationPromise = isNew
        ? await createMerchantCategory(model)
        : await updateMerchantCategory(model);
      formik.setSubmitting(false);
      if (!mutationPromise.error) {
        resetForm();
        onClose();
      }
    },
  });

  const handleExit = () => {
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle id="form-dialog-title">
        {isNew ? 'Add' : 'Edit'} Merchant Category
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box width={500} my={2}>
          <form id="merchantCategoryForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  id="categoryName"
                  name="categoryName"
                  label="Category Name"
                  fullWidth
                  size="small"
                  autoFocus
                  value={formik.values.categoryName}
                  onChange={formik.handleChange}
                  error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
                  helperText={formik.touched.categoryName && formik.errors.categoryName}
                  variant="outlined"
                  inputProps={{ maxLength: 128 }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="merchantCategoryForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

MerchantCategoryFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isNew: PropTypes.bool,
  data: PropTypes.shape({
    categoryName: PropTypes.string,
  }),
};

export default MerchantCategoryFormDialog;
