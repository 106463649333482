import { InputBaseSize } from '../inputBase-type';

export const xsmall = {
  props: { size: 'xsmall' as InputBaseSize },
  style: {
    input: {
      padding: '6px 8px',
    },
  },
};
