import { AppShareholderBase } from 'services/gatewayApi/shareholdersApi';
import { SortOptions } from 'types';

export const mockShareholder: AppShareholderBase = {
  id: 'mock',
  companyName: 'Company name',
  tradeName: 'Trade name',
  address: 'Some address',
  city: 'Sydney',
  postcode: '10022',
  country: 'AU',
  percentage: '34',
  status: 'ACTIVE',
};

export const emptyShareholder: AppShareholderBase = {
  id: 'new',
  companyName: '',
  tradeName: '',
  address: '',
  city: '',
  postcode: '',
  country: '',
  percentage: '',
  status: 'ACTIVE',
};

export const sortOptions: SortOptions[] = [
  { fieldName: 'id', label: 'Shareholder ID' },
  { fieldName: 'companyName', label: 'Company name' },
  { fieldName: 'tradeName', label: 'Trade name' },
  { fieldName: 'city', label: 'City' },
  { fieldName: 'postcode', label: 'Postcode' },
  { fieldName: 'country', label: 'Country' },
  { fieldName: 'percentage', label: 'Percentage' },
  { fieldName: 'status', label: 'Status' },

  { fieldName: 'createdDate', label: 'Created date' },
  { fieldName: 'modifiedDate', label: 'Modified date' },
  { fieldName: 'createdBy', label: 'Created by' },
  { fieldName: 'modifiedBy', label: 'Modified by' },
];
