import { ValueLabel } from '../../../types';

export const positions: ValueLabel[] = [
  {
    value: 'UBO',
    label: 'UBO',
  },
  {
    value: 'SHAREHOLDER',
    label: 'Shareholder',
  },
  {
    value: 'REP',
    label: 'Representative / Board of Directors',
  },
  {
    value: 'CEO',
    label: 'CEO',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];
