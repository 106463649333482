import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetAllByRuleSetIdQuery,
  useAddTresholdMutation,
  useUpdateTresholdMutation,
  useDeleteTresholdMutation,
} from 'services/gatewayApi/scoreTresholdsApi';

import './TresholdForm.scss';

interface TresholdFormProps {
  saveAttempt: boolean;
  onAfterSave: any;
}

export const TresholdForm: React.FC<TresholdFormProps> = ({ saveAttempt, onAfterSave }) => {
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [moderationMin, setModerationMin] = useState('');
  const [moderationMax, setModerationMax] = useState('');

  const { id: ruleSetId } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAllByRuleSetIdQuery({ ruleSetId });

  useEffect(() => {
    const moderation = data?.find((treshold) => treshold.status === 'MODERATION');
    if (moderation) {
      setModerationMin(moderation.minThreshold);
      setModerationMax(moderation.maxThreshold);
    }
  }, [data]);

  useEffect(() => {
    saveAttempt && handleSave();
  }, [saveAttempt]);

  useEffect(() => {
    isSaved && onAfterSave();
  }, [isSaved]);

  const [addTreshold] = useAddTresholdMutation();
  const [updateTreshold] = useUpdateTresholdMutation();
  const [deleteTreshold] = useDeleteTresholdMutation();

  const handleSave = () => {
    const statuses = ['PASS', 'MODERATION', 'REJECT'];

    //Prepare & save treshold values
    let min = parseInt(moderationMin),
      max = parseInt(moderationMax),
      tresholds = [];

    if (min > 0) {
      tresholds.push({ status: 'PASS', minThreshold: 0, maxThreshold: min });
    }

    tresholds.push({
      status: 'MODERATION',
      minThreshold: isNaN(min) ? 0 : min,
      maxThreshold: isNaN(max) ? 100 : max,
    });

    if (max < 100) {
      tresholds.push({
        status: 'REJECT',
        minThreshold: max,
        maxThreshold: 100,
      });
    }

    let counter = 0;

    statuses.forEach((status) => {
      const existingRecord = data?.find((treshold) => treshold.status === status);
      const newRecord = tresholds?.find((treshold) => treshold.status === status);

      if (newRecord && !existingRecord) {
        counter++;
        addTreshold({ ...newRecord, ruleSetId })
          .unwrap()
          .finally(() => {
            counter--;
            checkIfAllSaved(counter);
          });
      } else if (newRecord && existingRecord) {
        // Update only if min or max values are different
        if (
          newRecord.minThreshold !== existingRecord.minThreshold ||
          newRecord.maxThreshold !== existingRecord.maxThreshold
        ) {
          counter++;
          updateTreshold({ ...newRecord, id: existingRecord.id, ruleSetId })
            .unwrap()
            .finally(() => {
              counter--;
              checkIfAllSaved(counter);
            });
        }
      } else if (!newRecord && existingRecord) {
        deleteTreshold({ id: existingRecord.id, ruleSetId })
          .unwrap()
          .finally(() => {
            counter--;
            checkIfAllSaved(counter);
          });
      }
    });

    checkIfAllSaved(counter);
  };

  // Ensures all saving operations are done
  const checkIfAllSaved = (counter) => {
    if (counter === 0) {
      setIsSaved(true);
    }
  };

  return (
    <div className="TresholdForm">
      <h5>Threshold</h5>
      <div className="form-group">
        <label htmlFor="moderationMin" className="form-label">
          Pass transaction if score is less than
        </label>
        <div className="controls">
          <input
            id="moderationMin"
            type="number"
            value={moderationMin}
            onChange={(e) => setModerationMin(e.target.value)}
            onBlur={(e) => setModerationMin(e.target.value)}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="moderationMax" className="form-label">
          Reject transaction if score is greater than
        </label>
        <div className="controls">
          <input
            id="moderationMax"
            type="number"
            value={moderationMax}
            onChange={(e) => setModerationMax(e.target.value)}
            onBlur={(e) => setModerationMax(e.target.value)}
          />
        </div>
      </div>
      <small>* In other case transaction will go to moderation</small>
    </div>
  );
};
