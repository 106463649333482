import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer">
        <span>© 2018 AKCE Group. All rights reserved.</span>
        <span>{process.env.APP_VERSION}</span>
      </div>
    );
  }
}

export default Footer;
