import React from 'react';
import './styles.scss';

interface PageContainerProps {
  children?: React.ReactNode;
  bgColor?: any;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, bgColor }) => (
  <div
    className="container container-OpenPayd pageContainer"
    style={{
      backgroundColor: bgColor,
    }}
  >
    {children}
  </div>
);

export default PageContainer;
