import React from 'react';
import { formShouldBeVisible } from 'onboarding/api/onboarding/utils';
import { ApplicationSeparator } from '../ApplicationSeparator';
import { ServiceButton } from '../ServiceContainer';

interface SectionProps {
  forms: any,
  section: any,
  applicationDetails: any,
}

export const Section = ({ forms, section, applicationDetails }: SectionProps): JSX.Element => (
  <ApplicationSeparator title={section?.title} titleActions={section?.titleActions}>
    {section && section.data && section.data
      .slice()
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map((service, j) => {
        let isVisible = true;

        if (service.formId) {
          const form = forms?.find((f) => f.id === service.formId);
          const allFormRecords = Object.fromEntries(
            forms
              .map((f) => f.records)
              .flat()
              .map((r) => [r.id, r]),
          );

          // Param types are same but has separate declarations. Should be merged after OB merge.
          // @ts-ignore
          isVisible = formShouldBeVisible(form, allFormRecords, false);
        }

        return !isVisible ? null : (
          <ServiceButton key={j} application={applicationDetails} service={service} />
        );
      })}
  </ApplicationSeparator>
);
