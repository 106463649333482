// npm
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import * as _ from 'lodash';
import { useTheme } from '@mui/material/styles';

// global modules
import { cleanEmptyQueryParameters } from 'utils/cleanEmptyQueryParameters';
import { queryHasValue } from 'utils/queryHasValue';
import { useGetCaseAssignmentsQuery } from 'services/gatewayApi/casesApi';
import { useGetDashboardsQuery } from 'services/gatewayApi/dashboardsApi';
import { useGetListUsersQuery } from 'services/gatewayApi/usersApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useGetDictionaryQuery } from 'services/gatewayApi/dictionaryApi';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DetailsDrawer,
  EndListButton,
  HeadTableCell,
  PageContainer,
  Skeleton,
  SortByBlock,
  SortBlock,
} from 'uikit';
import { NoResultsBox, NoDataBox } from 'muikit';
import {
  RiAddCircleLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiCloseCircleFill,
  RiRestartLine,
  RiSearch2Line,
} from 'react-icons/ri';

import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { AssignmentsResponse } from 'features/cases/types';
import { SortParam } from 'types';
import { formatDateScreen } from 'utils';
import { DATE_SERVER_FORMAT } from 'constants/constants';
import {
  allowedCaseCategories,
  categories,
  finalDecisions,
  filterScheme,
  optionalFilters,
  riskStatuses,
  sortOptions,
  tableSortOptions,
} from './constants';

import { getApplicationStatusOptions } from '../ApplicationManagement/constants';
import { downloadFile } from '../../utils/downloadFile';

import { DashboardRow } from './DashboardRow';
import { ApplicationDetailsSidebar } from './ApplicationDetailsSidebar';

import {
  FilterRowBoolean,
  FilterRowsSet,
  FilterColumnsSet,
  FilterRowDateRange,
  FilterRowMultiSelect,
} from './Filters';
import { User } from '../UsersManagement/types';
import { InputGreyStyled } from '../../muikit/Input/InputGreyStyled';

const defaultPageSize = 20;

export const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { selectedProductId: productId } = useSelector(makeUserData());

  // #region Load required data
  const [assigneeOptions, setAssigneeOptions] = useState<User[]>(undefined);

  const { data: usersList = [], isLoading: isLoadingUsers } = useGetListUsersQuery(undefined);
  const { caseAssignments = [], isLoadingCaseAssignments } = useGetCaseAssignmentsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      caseAssignments: (data as AssignmentsResponse[])?.filter((el) =>
        allowedCaseCategories.includes(el.caseCategory),
      ),
      isLoadingCaseAssignments: isLoading,
    }),
  });
  useEffect(() => {
    if (usersList && !isLoadingUsers && caseAssignments && !isLoadingCaseAssignments) {
      const allCaseAssignmentUsersIds = caseAssignments?.map(
        (el) => usersList.find((user) => user.id === el.userId).id,
      );
      const distinctCaseAssignmentUsers = usersList.filter((u) =>
        allCaseAssignmentUsersIds.includes(u.id),
      );
      setAssigneeOptions(distinctCaseAssignmentUsers);
    }
  }, [isLoadingUsers, isLoadingCaseAssignments]);

  const { countries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: data?.map((el) => ({
        value: el.countryCode,
        label: el.name,
      })),
    }),
  });

  const { data: industryTypes, isLoading: isLoadingIndustryTypes } =
    useGetDictionaryQuery('industry_type');
  // #endregion

  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<string>('');

  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when searching
  const [searchQuery, setSearchQuery] = useState<string>('');

  const initialFilter = {
    dateFrom: formatDateScreen(new Date(Date.now() - 14 * 24 * 60 * 60 * 1000), DATE_SERVER_FORMAT), // 14 days ago
    dateTo: formatDateScreen(new Date(), DATE_SERVER_FORMAT), // today
  };
  const [filterQuery, setFilterQuery] = useState<IFilterValue>(initialFilter);
  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'createdDate',
    direction: 'desc',
  });

  const [pageSize, setPageSize] = useState<number>(defaultPageSize * 2);
  const applications = useRef<any>();
  const applicationsTotalCount = useRef<number>(0);
  const haystackClients = useRef<any>();
  const statistics = useRef<any>();

  const loader = useRef<HTMLDivElement>();

  const isNoData = useRef<boolean>(false);
  const isNoResults = useRef<boolean>(false);

  // HELPERS
  // Skip multiple queries when typing search field
  const debounceQuery = useCallback(
    debounce(() => setSkipRequest(false), 700),
    [],
  );

  const clearQuery = () => {
    setSearchQuery('');
    setFilterQuery(initialFilter);
    setVisibleFilters([]);
  };

  const showResetFilters = !_.isEqual(initialFilter, filterQuery);

  // return correct search object (empty if there is no search query)
  const searchObj = () => (searchQuery ? { search: searchQuery } : {});

  // HANDLERS
  // Search handler
  const searchHandler = (e) => {
    setSkipRequest(true);
    setSearchQuery(e);
    debounceQuery();
  };

  // QUERY
  const {
    data,
    isLoading: isLoadingDashboards,
    isFetching,
  } = useGetDashboardsQuery(
    {
      page: 0,
      size: pageSize,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      ...cleanEmptyQueryParameters(filterQuery),
      ...searchObj(),
    },
    {
      skip: skipRequest || !productId,
    },
  );

  // received data
  applications.current = data?.applications.content || [];
  applicationsTotalCount.current = data?.applications.totalElements || 0;
  const applicationsLength = applications.current.length;
  haystackClients.current = data?.haystackClients || [];
  statistics.current = data?.statistics;

  // check is there any application data
  isNoData.current =
    applicationsLength === 0 && searchQuery.length === 0 && !queryHasValue(filterQuery);

  // check is there any application data match filter
  isNoResults.current =
    applicationsLength === 0 && (searchQuery.length !== 0 || queryHasValue(filterQuery));

  // INITIALISATION
  // restore search and filter value from query
  const { search: urlParams } = useLocation();
  useEffect(() => {
    const searchString = new URLSearchParams(urlParams).get('search');
    setSearchQuery(searchString || '');

    // Read each filter value for filters
    const urlFilters = {};
    filterScheme.map(({ field, name }) => {
      const filterValue = new URLSearchParams(urlParams).get(name);
      if (filterValue) {
        urlFilters[name] = field === 'selectMulti' ? filterValue.split(',') : filterValue;
      }
    });

    setFilterQuery(Object.keys(urlFilters).length > 0 ? urlFilters : initialFilter);
  }, [filterScheme]);

  // load next page method
  const nextPage = () => {
    if (
      !isLoadingDashboards &&
      !isFetching &&
      applicationsLength !== applicationsTotalCount.current
    ) {
      setPageSize((prevPageSize) => {
        const nextPageSize = prevPageSize + defaultPageSize;
        return nextPageSize >= applicationsTotalCount.current
          ? applicationsTotalCount.current
          : nextPageSize;
      });
    }
  };

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  // infinity scroll hook
  useInfinityScroll({ isFetching, isLoading: isLoadingDashboards, nextPage, loader });

  // control search, sort, filter from inputs
  useEffect(() => {
    setOpenDetailsDrawer(false); // Close details drawer
    setSelectedItemId(''); // Clear selected item

    const params = new URLSearchParams();
    if (searchQuery) {
      params.append('search', searchQuery);
    }

    params.append('orderBy', sortedBy.property);
    params.append('direction', sortedBy.direction);

    Object.keys(filterQuery)?.map((key) => {
      params.append(key, filterQuery[key] as string);
    });

    navigate({ search: params.toString() }, { state: true });
  }, [searchQuery, sortedBy, navigate, filterQuery]);

  const isLoading =
    isLoadingDashboards || isLoadingUsers || isLoadingCaseAssignments || isLoadingIndustryTypes;

  // #region Export to Excel
  const handleExportExcel = () => {
    const exportQuery = _.clone(filterQuery);
    const queryString = Object.keys(exportQuery)?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(exportQuery[key])}`)
      .join('&');
    const headers = {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    };

    downloadFile(`/api/gateway/dashboards/exports?tenantId=${productId}&${queryString}`, {
      download: true,
      method: 'GET',
      headers,
      fileName: `Dashboards ${exportQuery.dateFrom} - ${exportQuery.dateTo}`,
    }).catch((rejected) => {
      console.error(rejected);
    });
  };
  // #endregion

  const handleFilterChange = (fieldName: string, selectedValues: string | string[]) =>
    setFilterQuery((prev) => {
      const next = { ...prev };

      if (selectedValues) {
        next[fieldName] = selectedValues;
      } else {
        delete next[fieldName];
      }

      return next;
    });

  const getCountFromStats = (field, value = '', defaultValue = 0) => {
    if (statistics.current) {
      const stats = statistics.current[field];

      if (!stats) {
        return defaultValue;
      }

      if (value) {
        if (!stats.hasOwnProperty(value)) {
          return defaultValue;
        }

        return stats[value] ?? defaultValue;
      }

      return stats; // direct count types
    }

    return defaultValue;
  };

  // #region Load filter options
  const finalDecisionFilters =
    !isLoading &&
    finalDecisions.map(({ label, value }) => ({
      label,
      value: value.toString(),
      count: getCountFromStats('finalDecision', value.toString()),
    }));

  const assigneeIdFilters =
    !isLoading &&
    assigneeOptions?.map((user) => ({
      label: user.name,
      value: user.id,
      count: getCountFromStats('assigneeId', user.id),
    }));

  const mainStatusStats = statistics.current?.mainStatus;
  const mainStatusFilters =
    !isLoading &&
    mainStatusStats &&
    Object.keys(mainStatusStats)?.map((value) => ({
      label: getApplicationStatusOptions(true).find((s) => s.value === value)?.label,
      value,
      count: getCountFromStats('mainStatus', value),
    }));

  const riskStatusFilters =
    !isLoading &&
    riskStatuses.map(({ label, value }) => ({
      label,
      value: value.toString(),
      count: getCountFromStats('riskStatus', value.toString()),
    }));

  const categoryIdFilters =
    !isLoading &&
    categories.map(({ label, value }) => ({
      label,
      value: value.toString(),
      count: getCountFromStats('categoryId', value.toString()),
    }));
  // #endregion

  const getHaystackClientById = (haystackClientId) =>
    haystackClients?.current.find((c) => c.id === haystackClientId);

  // #region Optional filters
  const [anchorElFilters, setAnchorElFilters] = React.useState<null | HTMLElement>(null);
  const [visibleFilters, setVisibleFilters] = useState<string[]>([]);
  const handleRemoveFilter = (fieldName) => {
    handleFilterChange(fieldName, '');
    setVisibleFilters(visibleFilters.filter((f) => f != fieldName));
  };
  // #endregion

  return (
    <>
      <PageContainer>
        <Helmet title="Dashboard" />

        <Box className="Dashboard" sx={{ mt: 6 }}>
          <Stack
            className="DashboardHeader"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: 1, borderColor: theme.palette.divider }}
          >
            <Stack direction="row" gap={6}>
              <div key="Tab_Onboarding" className="TabLink">
                <Link
                  className="active"
                  style={{ borderColor: theme.palette.primary.main }}
                  underline="none"
                >
                  <Typography variant="body">Onboarding</Typography>
                </Link>
              </div>
              <div key="Tab_CorporateReview" className="TabLink">
                <Link style={{ borderColor: theme.palette.primary.main }} underline="none">
                  <Typography variant="body">Corporate Review</Typography>
                </Link>
              </div>
            </Stack>
            <Typography variant="body" color={theme.palette.text.secondary}>
              {`${applicationsLength || 0} of ${applicationsTotalCount.current} applications`}
            </Typography>
          </Stack>

          <FilterColumnsSet
            fieldName="finalDecision"
            options={finalDecisionFilters}
            filterQuery={filterQuery}
            onChange={handleFilterChange}
          />

          <Grid container sx={{ pt: 6 }} spacing={8}>
            <Grid item xs={4} sx={{ pb: 10 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 4 }}
              >
                <Typography variant="h3" color="grey.900" sx={{ mb: 0 }}>
                  Filters
                </Typography>
                <Box>
                  {showResetFilters && (
                    <Button
                      variant="contained"
                      color="warning"
                      startIcon={<RiRestartLine size="16" />}
                      onClick={clearQuery}
                    >
                      Reset
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      setAnchorElFilters(event.currentTarget)
                    }
                    sx={{ ml: 2 }}
                  >
                    Filters
                  </Button>
                  <Menu
                    anchorEl={anchorElFilters}
                    open={Boolean(anchorElFilters)}
                    onClose={() => setAnchorElFilters(null)}
                    onClick={() => setAnchorElFilters(null)}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  >
                    {optionalFilters.map(({ value, label }) => {
                      const isVisible = visibleFilters.includes(value.toString());

                      return (
                        <MenuItem
                          key={`filter_${value}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisibleFilters(
                              isVisible
                                ? visibleFilters.filter((f) => f != value.toString())
                                : [...visibleFilters, value.toString()],
                            );
                          }}
                        >
                          <ListItemIcon>
                            {isVisible ? <RiCheckboxFill /> : <RiCheckboxBlankLine />}
                          </ListItemIcon>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              </Stack>
              <>
                <Divider />
                <FilterRowDateRange
                  dateFrom={filterQuery.dateFrom}
                  dateTo={filterQuery.dateTo}
                  onChange={(dateFrom, dateTo) => {
                    handleFilterChange('dateFrom', dateFrom);
                    handleFilterChange('dateTo', dateTo);
                  }}
                />

                {visibleFilters.includes('complianceCall') && (
                  <>
                    <Divider />
                    <FilterRowBoolean
                      label="Compliance Call"
                      fieldName="complianceCall"
                      selected={filterQuery.complianceCall === 'true'}
                      onSelect={(value, isSelected) =>
                        handleFilterChange(value, isSelected ? 'true' : '')
                      }
                      count={statistics.current?.complianceCall}
                      hideDots
                    />
                  </>
                )}

                {visibleFilters.includes('countryOfIncorporation') && (
                  <>
                    <Divider />
                    <FilterRowMultiSelect
                      label="Country of Incorporation"
                      fieldName="countryOfIncorporation"
                      options={countries}
                      filterQuery={filterQuery}
                      onChange={handleFilterChange}
                      onRemove={handleRemoveFilter}
                    />
                  </>
                )}

                {visibleFilters.includes('industryType') && (
                  <>
                    <Divider />
                    <FilterRowMultiSelect
                      label="Business Type"
                      fieldName="industryType"
                      options={industryTypes}
                      filterQuery={filterQuery}
                      onChange={handleFilterChange}
                      onRemove={handleRemoveFilter}
                    />
                  </>
                )}

                <Divider />
                <FilterRowsSet
                  filterOptions={[
                    { label: 'Assigned To', value: 'assigneeId' },
                    /* { label: 'Managed By', value: 'managedById' }, */
                  ]}
                  options={assigneeIdFilters}
                  filterQuery={filterQuery}
                  onChange={handleFilterChange}
                />

                <Divider />
                <FilterRowsSet
                  filterOptions={[{ label: 'Status', value: 'mainStatus' }]}
                  options={mainStatusFilters}
                  filterQuery={filterQuery}
                  onChange={handleFilterChange}
                />

                <Divider />
                <FilterRowsSet
                  filterOptions={[{ label: 'Risk Status', value: 'riskStatus' }]}
                  options={riskStatusFilters}
                  filterQuery={filterQuery}
                  onChange={handleFilterChange}
                />

                <Divider />
                <FilterRowsSet
                  filterOptions={[{ label: 'Category', value: 'categoryId' }]}
                  hideDots
                  options={categoryIdFilters}
                  filterQuery={filterQuery}
                  onChange={handleFilterChange}
                />

                {/*
                <Divider />
                <FilterRowsSet
                  filterOptions={[
                    { label: 'Industry Type', value: 'industryType' },
                  ]}
                  hideDots
                  options={industryTypeFilters}
                  filterQuery={filterQuery}
                  onChange={handleFilterChange}
                />
                */}

                <Divider />
                <FilterRowBoolean
                  label="Show Idle Applications"
                  fieldName="isIdle"
                  selected={filterQuery.isIdle === 'true'}
                  onSelect={(value, isSelected) =>
                    handleFilterChange(value, isSelected ? 'true' : '')
                  }
                  count={statistics.current?.isIdle}
                  hideDots
                />

                {visibleFilters.includes('complianceCall') && (
                  <>
                    <Divider />
                    <FilterRowBoolean
                      label="Compliance Call"
                      fieldName="complianceCall"
                      selected={filterQuery.complianceCall === 'true'}
                      onSelect={(value, isSelected) =>
                        handleFilterChange(value, isSelected ? 'true' : '')
                      }
                      count={statistics.current?.complianceCall}
                      hideDots
                    />
                  </>
                )}
              </>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                <SortByBlock
                  sortOptions={sortOptions}
                  sortedBy={sortedBy}
                  setSortedBy={setSortedBy}
                />
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<RiAddCircleLine size="16" />}
                  sx={{ bgcolor: 'grey.100' }}
                  onClick={handleExportExcel}
                >
                  Export as CSV
                </Button>
                <InputGreyStyled
                  fullWidth
                  color="secondary"
                  placeholder="Search"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    searchHandler(e.target.value)
                  }
                  value={searchQuery}
                  startAdornment={
                    <InputAdornment position="start" sx={{ pl: 2 }}>
                      <RiSearch2Line color={theme.palette.grey[700]} />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchQuery?.length > 0 && (
                      <InputAdornment position="end" sx={{ pl: 2 }}>
                        <IconButton
                          size="small"
                          onClick={() => searchHandler('')}
                          sx={{ '&:hover': { bgcolor: 'transparent' } }}
                        >
                          <RiCloseCircleFill color={theme.palette.grey[700]} fontSize="14" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </Stack>

              {/* Loading animation */}
              {isLoading && <Skeleton count={6} height={58} />}

              {!isLoading && (
                <>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      border: 1,
                      borderRadius: '12px',
                      borderColor: 'grey.200',
                      mt: 4,
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableSortOptions.map((option) => (
                            <HeadTableCell key={option.fieldName}>
                              <SortBlock
                                property={option.fieldName}
                                sortedBy={sortedBy}
                                handleSort={handleSort}
                                style={{ whiteSpace: 'normal' }}
                              >
                                {option?.label}
                              </SortBlock>
                            </HeadTableCell>
                          ))}
                          <HeadTableCell sx={{ width: '1%' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applications.current &&
                          applications?.current?.map((item) => {
                            const { id, haystackClientId } = item;

                            return (
                              <DashboardRow
                                key={id}
                                application={item}
                                haystackClient={getHaystackClientById(haystackClientId)}
                                selected={id === selectedItemId && openDetailsDrawer}
                                setSelectedItemId={setSelectedItemId}
                                setOpenDetailsDrawer={setOpenDetailsDrawer}
                              />
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>

                  <EndListButton
                    isVisible={applicationsLength > 0}
                    nextPage={nextPage}
                    isFetching={isFetching}
                    isEndReached={applicationsLength === applicationsTotalCount.current}
                  />
                </>
              )}

              {!(isLoading || isFetching) && (
                <>
                  <NoDataBox show={isNoData.current} entityTitle="Case" />
                  <NoResultsBox show={isNoResults.current} onResetFilters={clearQuery} />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </PageContainer>

      <DetailsDrawer open={openDetailsDrawer}>
        <ApplicationDetailsSidebar
          applicationId={selectedItemId}
          onClose={() => {
            setOpenDetailsDrawer(false);
            setSelectedItemId('');
          }}
        />
      </DetailsDrawer>
    </>
  );
};
