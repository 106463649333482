export const approvers = {
  COMPLIANCE_JUNIOR: 'Compliance junior',
  RISK_TEAM: 'Risk team',
  COMPLIANCE_SENIOR: 'Compliance senior',
};

export const allowSendAndDeleteActionsStatuses = ['OPEN'];

export const allowResendActivationStatuses = [
  'PENDING_ON_CUSTOMER',
  'PENDING_ON_CUSTOMER_SECOND',
  'PENDING_ON_CUSTOMER_PREQUESTIONNAIRE',
];

export const allowRevokeStatuses = ['PENDING_ON_CUSTOMER', 'PENDING_ON_CUSTOMER_SECOND'];

export const statusToEventMap = {
  PENDING_ON_CUSTOMER: 'AWAIT_ONBOARDING_OPERATIONS_EVENT',
  PENDING_ON_CUSTOMER_SECOND: 'AWAIT_ONBOARDING_COMPLIANCE_THIRD_EVENT',
};

// Approve application
export const allowApproveEvents = [
  'AML_APPROVE_EVENT',
  'MLRO_APPROVE_EVENT',
  'BOARD_APPROVE_EVENT',
  'MLRO_APPROVE_EVENT',
  'AML_PROCEED_EVENT',

  // OpenPayd
  'BANKING_APPROVE_EVENT',
  'ONBOARDING_COMPLIANCE_FIRST_APPROVE_EVENT',
  'ONBOARDING_COMPLIANCE_SECOND_APPROVE_EVENT',
  'ONBOARDING_COMPLIANCE_THIRD_APPROVE_EVENT',
  'DIRECTOR_COMPLIANCE_APPROVE_EVENT',
  'UK_MLRO_APPROVE_EVENT',
  'HEAD_AML_APPROVE_EVENT',
  'MALTA_MLRO_APPROVE_EVENT',
];

// Conditionally Approve application
export const allowConditionallyApproveEvents = [
  // OpenPayd
  'BANKING_CONDITIONAL_APPROVE_EVENT',
  'ONBOARDING_COMPLIANCE_SECOND_CONDITIONAL_APPROVE_EVENT',
  'DIRECTOR_COMPLIANCE_CONDITIONAL_APPROVE_EVENT',
  'UK_MLRO_CONDITIONAL_APPROVE_EVENT',
  'HEAD_AML_CONDITIONAL_APPROVE_EVENT',
  'MALTA_MLRO_CONDITIONAL_APPROVE_EVENT',
];

export const disallowAwaitEvents = [
  'OPEN',
  'PENDING_ON_CUSTOMER',
  'PENDING_ON_CUSTOMER_SECOND',
  'PENDING_SENIOR_COMPLIANCE', // obsolete status
];

export const allowRejectStatuses = [
  // EMB
  'PENDING_AML_TEAM_PREQUESTIONNAIRE',
  'PENDING_AML_TEAM',
  'PENDING_MLRO_MANAGER_AFTER_AML',
  'PENDING_MLRO_MANAGER',
  'PENDING_BOARD_MEMBER',

  // OpenPayd
  'PENDING_BANKING',
  'PENDING_ONBOARDING_COMPLIANCE_FIRST',
  'PENDING_ONBOARDING_COMPLIANCE_SECOND',
  'PENDING_ONBOARDING_COMPLIANCE_THIRD',
  'PENDING_DIRECTOR_COMPLIANCE',
  'PENDING_UK_MLRO',
  'PENDING_MALTA_MLRO',
  'PENDING_HEAD_AML',
];

export const customServicesMap = {
  'customer-users': 'Customers',
  'openpayd-users': 'OpenPayd users',
};

export const awaitEvents = {
  // common
  AWAIT_CUSTOMER_EVENT: 'Back to the Customer',
  AWAIT_SALES_TEAM_EVENT: 'Send to Sales',

  // EMB
  AWAIT_AML_TEAM_EVENT: 'Send to AML Team',
  AWAIT_SALES_TEAM_FINAL_EVENT: 'Send to Sales',
  AWAIT_SALES_TEAM_HIGH_RISK_EVENT: 'Send to Sales',
  AWAIT_MLRO_MANAGER_EVENT: 'Send to MLRO Manager',
  AWAIT_BOARD_MEMBER_EVENT: 'Send to Board Member',

  // OpenPayd
  AWAIT_ONBOARDING_COMPLIANCE_FIRST_EVENT: 'Send to Onboarding Compliance',
  AWAIT_ONBOARDING_COMPLIANCE_SECOND_EVENT: 'Send to Onboarding Compliance',
  AWAIT_ONBOARDING_COMPLIANCE_THIRD_EVENT: 'Send to Onboarding Compliance',
  AWAIT_ONBOARDING_OPERATIONS_EVENT: 'Send to Onboarding Operations',
  AWAIT_ONBOARDING_OPERATIONS_SECOND_EVENT: 'Send to Onboarding Operations: Second',
  AWAIT_BANKING_EVENT: 'Send to Banking',
  AWAIT_DIRECTOR_COMPLIANCE_EVENT: 'Send to Compliance Director',
  AWAIT_HEAD_AML_EVENT: 'Send to Head of AML',
};
