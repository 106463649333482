import React from 'react';
import { Grid } from '@mui/material';

export const RowLayout = ({ column1, column2, column3, column4 }) => (
  <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="flex-end">
    <Grid item md={6}>
      {column1}
    </Grid>
    <Grid item container md={6}>
      <Grid item md={4} display="flex" justifyContent="center">
        {column2}
      </Grid>
      <Grid item md={4} display="flex" justifyContent="center">
        {column3}
      </Grid>
      <Grid item md={4} display="flex" justifyContent="center">
        {column4}
      </Grid>
    </Grid>
  </Grid>
);
