import React from 'react';
import './TabTitle.scss';
import { Chip, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { TabTitleProps } from './TabTitle.props';

const TabTitle: React.FC<TabTitleProps> = ({
  title = 'No title',
  count,
  isActive = false,
  disabled = false,
  hidden = false,
  hasActivity = false,
  className = '',
  onClick,
  direction,
}) => {
  const handleOnClick = () => {
    if (!disabled && !isActive && onClick) {
      onClick();
    }
  };

  if (hidden) {
    return <></>;
  }

  return (
    <div
      className={`
        TabTitle
        TabTitle--${direction}
        ${className}
        TabTitle--${isActive ? 'active' : 'inactive'}
        ${disabled ? 'TabTitle--disabled' : ''}
      `}
      onClick={handleOnClick}
    >
      <h4 className="tab-title-el">
        {title}
        {!isNaN(count) && (
          <Chip
            label={<Typography variant="body2">{count}</Typography>}
            variant="outlined"
            size="small"
            color="primary"
            sx={{ ml: 2 }}
          />
        )}
      </h4>
      {hasActivity ? <CircleIcon className="hasActivityIcon" /> : <></>}
    </div>
  );
};

export default TabTitle;
