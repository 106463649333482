import React, { Component } from 'react';
import toastr from 'toastr';
import ApiService from '../../services/apiService';

class Integration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seonIntegration: {
        integration: false,
        crypto: false,
        gambling: false,
      },
      // citigateIntegration: false,
      nerodataIntegration: false,
    };
  }

  componentDidMount() {
    this.fetchSeonIntegration();
    // this.fetchCitigateIntegration();
    this.fetchNerodataIntegration();
  }

  fetchSeonIntegration() {
    ApiService.get('/internal/admin-validator/status/seon')
      .then((response) => this.setState(() => ({ seonIntegration: response })))
      .catch((error) => toastr.error(`Error getting Seon integration status - ${error}`));
  }

  fetchNerodataIntegration() {
    ApiService.get('/internal/admin-validator/status/nerodata')
      .then((response) => this.setState(() => ({ nerodataIntegration: response.integration })))
      .catch((error) => toastr.error(`Error getting Nerodata integration status - ${error}`));
  }

  // fetchCitigateIntegration() {
  //   ApiService.get('/internal/admin-validator/status/citigate')
  //     .then(response =>
  //       this.setState(() => ({ citigateIntegration: response.integration })),
  //     )
  //     .catch(error =>
  //       toastr.error(`Error getting Citigate integration status - ${error}`),
  //     );
  // }

  handleSeonIntegrationChange = (e) => {
    ApiService.post('internal/admin-validator/status/seon', {
      ...this.state.seonIntegration,
      [e.value]: !this.state.seonIntegration[e.value],
    })
      .then((response) => this.setState(() => ({ seonIntegration: response.data })))
      .catch((error) => {
        toastr.error(`Error updating Seon integration status - ${error}`);
      });
  };

  // handleCitigateIntegrationChange = () => {
  //   ApiService.post('internal/admin-validator/status/citigate', {
  //     integration: !this.state.citigateIntegration,
  //   })
  //     .then(response =>
  //       this.setState(() => ({
  //         citigateIntegration: response.data.integration,
  //       })),
  //     )
  //     .catch(error => {
  //       toastr.error(`Error updating Seon integration status - ${error}`);
  //     });
  // };

  handleNerodataIntegrationChange = () => {
    ApiService.post('internal/admin-validator/status/nerodata', {
      integration: !this.state.nerodataIntegration,
    })
      .then((response) =>
        this.setState(() => ({
          nerodataIntegration: response.data.integration,
        })),
      )
      .catch((error) => {
        toastr.error(`Error updating Nerodata integration status - ${error}`);
      });
  };

  render() {
    const {
      seonIntegration,
      // citigateIntegration,
      nerodataIntegration,
    } = this.state;
    return (
      <div className="grid-body">
        <div className="row">
          <div className="col-md-6">
            <div className="tiles white">
              <div className="tiles-title">
                <h4>
                  <span>Seon</span>
                </h4>
              </div>
              <div className="tiles-body">
                <div className="form-group">
                  <input
                    type="checkbox"
                    value="integration"
                    checked={seonIntegration.integration}
                    onChange={(e) => this.handleSeonIntegrationChange(e.target)}
                  />
                  <span className="checkbox-label">Integration</span>
                </div>
                <div className="form-group">
                  <input
                    type="checkbox"
                    value="crypto"
                    checked={seonIntegration.crypto}
                    onChange={(e) => this.handleSeonIntegrationChange(e.target)}
                  />
                  <span className="checkbox-label">Crypto</span>
                  <input
                    type="checkbox"
                    value="gambling"
                    checked={seonIntegration.gambling}
                    onChange={(e) => this.handleSeonIntegrationChange(e.target)}
                  />
                  <span className="checkbox-label">Gambling</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4"> */}
          {/*  <div className="tiles white"> */}
          {/*    <div className="tiles-title"> */}
          {/*      <h4> */}
          {/*        <span>Citigate</span> */}
          {/*      </h4> */}
          {/*    </div> */}
          {/*    <div className="tiles-body"> */}
          {/*      <div className="form-group"> */}
          {/*        <input */}
          {/*          type="checkbox" */}
          {/*          value="citigateIntegration" */}
          {/*          checked={citigateIntegration} */}
          {/*          onChange={() => this.handleCitigateIntegrationChange()} */}
          {/*        /> */}
          {/*        <span className="checkbox-label">Integration</span> */}
          {/*      </div> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* </div> */}
          <div className="col-md-6">
            <div className="tiles white">
              <div className="tiles-title">
                <h4>
                  <span>Nerodata</span>
                </h4>
              </div>
              <div className="tiles-body">
                <div className="form-group">
                  <input
                    type="checkbox"
                    value="nerodataIntegration"
                    checked={nerodataIntegration}
                    onChange={() => this.handleNerodataIntegrationChange()}
                  />
                  <span className="checkbox-label">Integration</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Integration;
