export const overviewSections = [
  {
    id: 'general',
    // label: 'General', //section title is hidden
    fields: [
      {
        value: 'caseId',
        label: 'Case ID',
        forCaseTypes: ['TRANSACTION', 'SANCTION']
      },
      {
        value: 'referenceId',
        label: 'Transaction ID',
        forCaseTypes: ['TRANSACTION', 'SANCTION']
      },
      { value: 'assigneeId', label: 'Assignee' },
      { value: 'createdDateTime', label: 'Created On' },
      { value: 'caseCategory', label: 'Category' },
      { value: 'caseType', label: 'Type' },
      { value: 'registrationCountry', label: 'Registration country' },
      { value: 'parentName', label: 'Parent Name', forApiNames: ['BANK'] },
    ],
  },
  {
    id: 'clientInfoBank',
    label: 'Client Information',
    forCaseTypes: ['TRANSACTION', 'SANCTION'],
    forApiNames: ['BANK'],
    fields: [
      { value: 'clientName', label: 'Client Name' },
      { value: 'clientEmail', label: 'Client Email' },
      { value: 'riskStatus', label: 'Risk Status' },
    ],
  },
  {
    id: 'transactionInfoBank',
    label: 'Transaction Information',
    forCaseTypes: ['TRANSACTION', 'SANCTION'],
    forApiNames: ['BANK'],
    fields: [
      { value: 'transactionType', label: 'Transaction Type' },
      { value: 'transactionAmount', label: 'Transaction Amount' },
      { value: 'senderName', label: 'Sender Name' },
      { value: 'senderIban', label: 'Sender IBAN' },
      { value: 'senderBankCountry', label: 'Sender bank country' },
      { value: 'beneficiaryName', label: 'Beneficiary Name' },
      { value: 'beneficiaryIban', label: 'Beneficiary IBAN' },
      { value: 'beneficiaryBankCountry', label: 'Beneficiary bank country' },
    ],
  },
  {
    id: 'customerInfoGateway',
    label: 'Customer Information',
    forCaseTypes: ['TRANSACTION', 'SANCTION'],
    forApiNames: ['GATEWAY'],
    fields: [
      { value: 'customerName', label: 'Customer Name' },
      { value: 'customerEmail', label: 'Customer Email' },
      { value: 'maskedPan', label: 'PAN' },
    ],
  },
  {
    id: 'merchantInfoGateway',
    label: 'Merchant Information',
    forCaseTypes: ['TRANSACTION', 'SANCTION'],
    forApiNames: ['GATEWAY'],
    fields: [
      { value: 'merchantId', label: 'Merchant ID' },
      { value: 'merchantName', label: 'Merchant Name' },
    ],
  },
];
