import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '../../../../theme/palette-blocks';

const renderDot = (active, idx) => (
  <span
    key={`dot_${idx}`}
    style={{
      width: 8,
      height: 8,
      marginRight: 4,
      borderRadius: '50%',
      backgroundColor: active ? grey[900] : grey[200],
    }}
  />
);

const StyledBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    py: 0,
    px: 2,
    mb: 2,
    width: '100%',
    borderRadius: 1,
    transition: '.2s background-color',
    cursor: 'pointer',

    '&:hover': {
      bgcolor: 'grey.100',
    },
  }),
);

interface OptionButtonProps {
  label: string;
  value: string;
  count: number;
  selected?: boolean;
  onSelect?: (value: string, isSelected: boolean) => void;
  hideDots?: boolean;
}

export const OptionButton: React.FC<OptionButtonProps> = ({
  label,
  value,
  count,
  selected = false,
  onSelect,
  hideDots = false,
}) => {
  const [isChecked, setIsChecked] = useState(selected);

  useEffect(() => {
    setIsChecked(selected);
  }, [selected]);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (onSelect) {
      onSelect(value, !isChecked);
    }
  };

  const isDisabled = count === 0;

  return (
    <StyledBox
      onClick={handleClick}
      sx={
        isChecked
          ? { bgcolor: 'primaryLight.light', '&:hover': { bgcolor: 'primaryLight.main' } }
          : {}
      }
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1, width: '100%' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '53%' }}>
          <Checkbox checked={isChecked} sx={{ ml: 0, mr: 1 }} />
          <Typography
            variant="body"
            component="span"
            sx={{
              color: 'grey.600',
              lineHeight: '13px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {label}
          </Typography>
        </div>
        <Stack
          direction="row"
          justifyContent={hideDots ? 'end' : 'space-between'}
          alignItems="center"
          sx={{ width: '45%' }}
        >
          {!hideDots && (
            <Stack direction="row" alignItems="center">
              {Array.apply(null, Array(10)).map((item, idx) => renderDot(idx < count, idx))}
              {count > 10 && (
                <Typography variant="body" fontSize={16} fontWeight={900}>
                  +
                </Typography>
              )}
            </Stack>
          )}

          <Typography variant="body">{count}</Typography>
        </Stack>
      </Stack>
    </StyledBox>
  );
};
