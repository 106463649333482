import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import MerchantsSearchForm from './MerchantsSearchForm';
import MerchantsList from './MerchantsList';

const Merchants = () => {
  const [filterValues, setFilterValues] = useState({});

  return (
    <div className="page-content">
      <Helmet title="Merchants" />
      <div className="content container">
        <div className="page-title">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h3>Merchants</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MerchantsSearchForm setFilterValues={setFilterValues} />
            </div>
            <div className="col-md-12">
              <MerchantsList filterValues={filterValues} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchants;
