import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { history } from '../../../../store';
import { fetchSanctionDetail } from '../../../../actions/sanctionDetail';
import { makeSanctionDetailData, makeLoadingData } from '../../../../selectors/sanctionDetail';
import { iterator, resetItems } from '../../../../utils/transactionIterator';
import { TransactionItemDetails, Button } from '../../../../components';

export class SanctionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (this.props.params) {
      const { id } = this.props.match.params;
      this.props.fetchSanctionDetail(id);
    }
  }

  renderItemDetails() {
    const { loading, sanctionDetail } = this.props;
    if (loading) {
      return (
        <div className="grid-body-spinner centered">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    }
    if (!sanctionDetail) {
      return <span> No Sanction Detail Found</span>;
    }
    const sd = JSON.parse(sanctionDetail.details);
    resetItems();
    const printOutItems = iterator(sd);
    return printOutItems.map((i, idx) => <TransactionItemDetails key={idx} item={i} />);
  }

  render() {
    return (
      <div className="page-content">
        <Helmet title="Case Management" />
        <div className="content">
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 title-block">
                  <h3>Case Management</h3>
                  <Button
                    buttonText="Back to Case"
                    buttonClass="btn btn-primary btn-small"
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="grid simple">
                <div className="grid-title">
                  <h4>
                    <span>Sanction Detail</span>
                  </h4>
                </div>
                <div className="grid-body">{this.renderItemDetails()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SanctionDetail.propTypes = {
  sanctionDetail: PropTypes.object,
  params: PropTypes.object,
  loading: PropTypes.bool,
  fetchSanctionDetail: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  sanctionDetail: makeSanctionDetailData(),
  loading: makeLoadingData(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSanctionDetail: (id) => dispatch(fetchSanctionDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SanctionDetail);
