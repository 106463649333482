import { styled } from '@mui/material/styles';

const InputTransparentInput = styled('input')(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    p: 0,
    backgroundColor: 'transparent',
    border: 'none',
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  }),
);

export default InputTransparentInput;
