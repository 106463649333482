import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { AddCircle as AddCircleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DetailsField } from 'uikit';
import { useTheme } from '@mui/material/styles';
import { formatFieldTitle } from '../constants';
import { RenderKeyValue } from './RenderKeyValue';
import { parseRecordType } from './parseRecordType';
import { Record } from '../../../types/forms';
import './DynamicForms.scss';

interface RenderListProps {
  childRecords: Record[];
  isSavingForms: boolean;
  formRecords: Record[];
  record: Record;
  handleDeleteFormRecord: (id: string, formId: string) => void;
  formId: string;
  savingComplexFieldId: string;
  handleAddComplexType: (
    fieldName: string,
    listId: string,
    formId: string,
    formTemplateFieldId: string,
    maxRowNumber: number,
  ) => void;
  disabled?: boolean;
}

export const RenderList: React.FC<RenderListProps> = ({
  childRecords,
  isSavingForms,
  formRecords,
  record,
  handleDeleteFormRecord,
  formId,
  savingComplexFieldId,
  handleAddComplexType,
  disabled,
}) => {
  const theme = useTheme();
  const { id, formTemplateFieldId, field } = record;
  const { name: fieldName } = field;

  const { applicationId } = useParams<{ applicationId: string }>();
  let childPrimitiveTypes: Record[] = [];
  let maxRowNumber = 0;

  // Find highest row number for complex type
  childRecords.forEach(({ row: rowRecord }) => {
    if (maxRowNumber < rowRecord) {
      maxRowNumber = rowRecord;
    }
  });

  const formRecordsSort = (a: Record, b: Record) =>
    a.row * 1000 + a.col > b.row * 1000 + b.col ? 1 : -1;

  return (
    <div id={`complexTypeList_${id}`} className={`complexTypeList ${fieldName}`}>
      {childRecords.map((ct, idx) => {
        childPrimitiveTypes = formRecords
          .slice()
          .sort(formRecordsSort)
          .filter((pt) => pt.parent === ct.id);

        const ctType = parseRecordType(ct.type);
        if (ctType === 'key-value') {
          return (
            <RenderKeyValue
              key={`complexType_${ct.id}`}
              childRecords={childPrimitiveTypes}
              ctId={ct.id}
              ctFormId={formId}
              applicationId={applicationId}
              handleDeleteFormRecord={handleDeleteFormRecord}
              disabled={isSavingForms || disabled}
            />
          );
        }

        return (
          <div
            id={`complexType_${ct.id}`}
            key={`complexType_${ct.id}`}
            className={`complexType ${ct.type}`}
          >
            <div className="primitiveTypeContainer">
              {/* Display other standart fields */}
              {childPrimitiveTypes.map((pt) => (
                <DetailsField
                  id={pt.id}
                  key={`${pt.id}_${pt.row}_${pt.col}`}
                  applicationId={applicationId}
                  fieldType={pt.field.type}
                  label={pt.field.title.replace('#idx#', `${idx + 1}`)}
                  value={pt.value}
                  record={pt}
                  formId={formId}
                  updateOnChange
                  disabled={isSavingForms || disabled}
                />
              ))}
            </div>

            {/* Delete complex type button */}
            <IconButton
              size="small"
              className="complexTypeActionIcon"
              onClick={() => handleDeleteFormRecord(ct.id, formId)}
              disabled={isSavingForms || disabled}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      })}

      {/* Add complex type button */}
      <div style={{ margin: '.5em 0' }}>
        <Button
          size="medium"
          startIcon={
            id === savingComplexFieldId && isSavingForms ? (
              <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
            ) : (
              <AddCircleIcon />
            )
          }
          variant="contained"
          color="secondary"
          onClick={() =>
            handleAddComplexType(fieldName, id, formId, formTemplateFieldId, maxRowNumber)
          }
          disabled={isSavingForms || disabled}
        >
          Add new {formatFieldTitle[fieldName] || ''}
        </Button>
      </div>

      {/* Show total for shareholder list */}
      {fieldName === 'shareholder_list' && (
        <div
          className="shareholder_list_total"
          style={{ color: disabled ? theme.palette.text.disabled : 'inherit' }}
        >
          Total{' '}
          {Number(
            formRecords
              .filter((pt) => childRecords.map((ct) => ct.id).includes(pt.parent))
              .filter((r) => r.field.name === 'shareholder_shareholding')
              .reduce((acc, o) => acc + (parseFloat(o.value) || 0), 0),
          ).toFixed(2)}
          %
        </div>
      )}
    </div>
  );
};
