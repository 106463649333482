import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, CircularProgress } from '@mui/material';

import { SectionTitle } from './SectionTitle';
import { SectionContainerProps } from './SectionContainer.props';

import './SectionContainer.scss';

const SectionContainer: React.FC<SectionContainerProps> = ({
  activeIndex = 0,
  hasPrevNext = false,
  hasSubmit = false,
  isSubmitting = false,
  isSectionProcessing = false,
  onIndexChange,
  onSubmit,
  children,
  renderAllChildren = false,
}) => {
  const [prevIndex, setPrevIndex] = useState(activeIndex);
  const [index, setIndex] = useState(activeIndex);
  const childrenFiltered = React.Children.toArray(children).filter(Boolean);
  const navigate = useNavigate();
  const { clientId, applicationId } = useParams<{
    applicationId: string;
    clientId: string;
  }>();

  const handleMoveNextOrSubmit = () => {
    if (!isSectionProcessing) {
      if (index < React.Children.count(childrenFiltered) - 1) {
        setIndex(index + 1);
      } else if (onSubmit) {
        onSubmit({}); // TODO: pass data here?
      }
    }
  };

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(prevIndex, index);
      setPrevIndex(index);
    }
  }, [index]);

  const isFinalstep = index === React.Children.count(childrenFiltered) - 1;

  return (
    <div
      className={`
        SectionContainer
        SectionContainer--vertical
      `}
    >
      <div className="SectionTitles">
        {childrenFiltered &&
          React.Children.map(childrenFiltered, (c, i) => (
            <SectionTitle
              key={`SectionTitle_${i}`}
              isActive={i === index}
              onClick={() => !isSectionProcessing && setIndex(i)}
              // @ts-ignore
              {...c.props}
            />
          ))}
      </div>
      <div className="Sections">
        {childrenFiltered && (
          <>
            {!renderAllChildren &&
              React.Children.map(childrenFiltered, (c, i) => (i === index ? c : <></>))}

            {renderAllChildren &&
              React.Children.map(childrenFiltered, (c, i) => (
                <div style={{ display: i === index ? 'block' : 'none' }}>{c}</div>
              ))}

            {(hasPrevNext || (hasSubmit && isFinalstep)) && (
              <div className="SectionNavigation">
                {!isFinalstep && (
                  <Button variant="contained" color="primary" onClick={handleMoveNextOrSubmit}>
                    Next
                  </Button>
                )}

                {isFinalstep && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      hasSubmit
                        ? handleMoveNextOrSubmit()
                        : navigate(
                            `/application-management/${clientId}/applications/${applicationId}/app`,
                          );
                    }}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
                      ) : null
                    }
                    disabled={isSubmitting}
                  >
                    {hasSubmit ? 'Submit' : 'Save and return to application'}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { SectionContainer };
