import PropTypes from 'prop-types';
import React from 'react';
import checkPermissions from '../../utils/checkPermissions';

const PermissionAware = (props) => {
  const { requiredPers, children } = props;
  const isUserAuthorized = checkPermissions(requiredPers);
  if (isUserAuthorized) {
    return <span>{children}</span>;
  }
  return null;
};

PermissionAware.propTypes = {
  requiredPers: PropTypes.array,
  children: PropTypes.element,
};

export default PermissionAware;
