import { grey } from '../palette-blocks';

export const CssBaseline = {
  styleOverrides: {
    body: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: grey[50],
      '::-webkit-scrollbar': {
        width: '.5rem',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: grey[300],
      },
      '::-webkit-scrollbar-thumb': {
        background: grey[600],
      },
      '::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
  },
};
