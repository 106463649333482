import React from 'react';
import { Box, Typography } from '@mui/material';
import { RiUploadLine } from 'react-icons/ri';
import { styled } from '@mui/material/styles';

interface DropzoneProps {
  acceptedFiles?: Array<File>;
  getRootProps: () => any;
  getInputProps: () => any;
  disabled?: boolean;
  icon?: any;
  textStyle?: any;
}
const DropzoneRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'sx',
})(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    height: 'inherit',
  }),
);

export const Dropzone = (props: DropzoneProps) => {
  const { acceptedFiles, getRootProps, getInputProps, disabled, icon, textStyle } = props;
  const { multiple } = getInputProps();

  const color = acceptedFiles?.length == 0 || disabled ? 'grey.400' : 'grey.600';

  const sx = {
    pointerEvents: disabled ? 'none' : '',
  };

  return (
    <DropzoneRoot {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        color={color}
        sx={sx}
        display="flex"
        className="dropzoneMessageWrapper"
        alignItems="center"
        gap="0.5rem"
      >
        {icon || (
          <RiUploadLine
            style={{ fontSize: '14px', padding: '3px', boxSizing: 'unset', color: 'inherit' }}
          />
        )}

        {(!acceptedFiles || acceptedFiles?.length == 0) && (
          <Typography variant="body2" sx={textStyle}>
            {`Drop your ${multiple ? 'files' : 'a file'} here`}
          </Typography>
        )}

        {/* Show file list in file(s) are selected */}
        {acceptedFiles?.length > 0 &&
          acceptedFiles.map((file, idx) => <Typography variant="body2">{file.name}</Typography>)}
      </Box>
    </DropzoneRoot>
  );
};
