import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { DoNotDisturb as DoNotDisturbIcon } from '@mui/icons-material';

export const RejectedChip = (props: ChipProps): JSX.Element => (
  <Chip
    icon={<DoNotDisturbIcon />}
    size="small"
    color="error"
    label="Rejected"
    variant="outlined"
    sx={{ bgcolor: 'white' }}
    onClick={() => {}}
    {...props}
  />
);
