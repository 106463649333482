import toastr from 'toastr';
import * as _ from 'lodash';
import {
  ADD_NEW_RULE_TO_RULESET,
  DELETE_RULE_FROM_RULESET,
  CLONE_RULE_FROM_RULESET,
  CHANGE_RULESET_DATA,
  SELECT_RULE,
  UNSELECT_RULE,
  SELECT_RULESET,
  UPDATE_RULE,
  UPDATE_RULESET_METADATA,
  SAVE_RULSET_TO_API,
  SAVE_RULSET_TO_API_FAIL,
  SAVE_RULSET_TO_API_SUCCESS,
  CHANGE_NEW_FORM_VALID_STATUS,
  CHANGE_NEW_FORM_CLOSE_ATTEMPT,
  EMPTY_RULESET_AND_RULES,
  LOAD_FUNCTION_LIST,
  LOAD_FUNCTION_LIST_SUCCESS,
  LOAD_FUNCTION_LIST_FAIL,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { UPDATE_RULE_SUCCESS_RULE_FORM } from '../containers/RuleBuilder/constants';
import { toastLoadingError } from '../utils/toast';

export function addNewRuleToRuleSet(ruleId) {
  return (dispatch) => {
    dispatch({
      type: ADD_NEW_RULE_TO_RULESET,
      ruleId,
    });
  };
}

export function deleteRuleFromRuleSet(ruleId) {
  return (dispatch) => {
    dispatch({
      type: DELETE_RULE_FROM_RULESET,
      ruleId,
    });
  };
}

export function cloneRuleFromRuleSet(ruleId) {
  return (dispatch) => {
    dispatch({
      type: CLONE_RULE_FROM_RULESET,
      ruleId,
    });
  };
}

export function selectRule(ruleId) {
  return (dispatch) => {
    dispatch({
      type: SELECT_RULE,
      ruleId,
    });
  };
}

export function unselectRule(recoverRule) {
  return (dispatch) => {
    dispatch({
      type: UNSELECT_RULE,
      recoverRule,
    });
  };
}

export function changeRuleSetData(data) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_RULESET_DATA,
      data,
    });
  };
}

export function selectRuleSet(ruleSet) {
  return (dispatch) => {
    dispatch({
      type: SELECT_RULESET,
      ruleSet,
    });
  };
}

export function updateRule(rule) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_RULE,
      rule,
    });
  };
}

export function updateRuleSet(field, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_RULESET_METADATA,
      field,
      value,
    });
  };
}

export function saveRuleSet(postData) {
  const payload = _.cloneDeep(postData);
  if (typeof payload.ruleSet.id !== 'number') {
    payload.ruleSet.id = null;
  }
  if (payload.ruleSet.rules) {
    clearTempIds(payload.ruleSet.rules);
  }
  return (dispatch) => {
    dispatch({ type: SAVE_RULSET_TO_API });
    ApiService.post('/internal/ruleset/save', payload)
      .then((res) => {
        const msg =
          res.method === 'POST' ? 'Rule Set added successfully' : 'Rule Set updated successfully';
        toastr.success(msg);
        dispatch(saveRuleSetSuccess(res.response));
      })
      .catch((error) => {
        dispatch(saveRuleSetError());
        toastr.error(`Save ruleSet - ${error}`);
      });
  };
}

/** begin of Rules for RuleForm */
export const updateRuleSuccess = (rule) => (dispatch) => {
  dispatch({
    type: UPDATE_RULE_SUCCESS_RULE_FORM,
    rule,
  });
};

export function fetchFunctions() {
  return (dispatch) => {
    dispatch({ type: LOAD_FUNCTION_LIST });
    ApiService.get('/internal/ruleset/function-list')
      .then((response) => {
        dispatch(fetchFunctionListSuccess(response.functionList));
      })
      .catch((error) => {
        dispatch(fetchFunctionListError());
        toastLoadingError(`fetchFunctions:`, error);
      });
  };
}

export function changeNewRuleFormValidStatus(bool) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_NEW_FORM_VALID_STATUS,
      bool,
    });
  };
}

export function changeNewRuleFormCloseAttempt(bool) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_NEW_FORM_CLOSE_ATTEMPT,
      bool,
    });
  };
}

export function emptyRuleSetAndRules() {
  return (dispatch) => {
    dispatch({
      type: EMPTY_RULESET_AND_RULES,
    });
  };
}

export function saveRuleSetSuccess(ruleSet) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RULSET_TO_API_SUCCESS,
      ruleSet,
    });
  };
}

const saveRuleSetError = () => ({
  type: SAVE_RULSET_TO_API_FAIL,
  error: true,
});

const fetchFunctionListSuccess = (functions) => ({
  type: LOAD_FUNCTION_LIST_SUCCESS,
  functions,
});

const fetchFunctionListError = () => ({
  type: LOAD_FUNCTION_LIST_FAIL,
  error: true,
});

const clearTempIds = (array) => {
  for (let i = 0; i < array.length; i += 1) {
    const obj = array[i];
    if (typeof obj.id !== 'number') {
      obj.id = null;
    }
    if (obj.childRules) {
      clearTempIds(obj.childRules);
    }
  }
};
