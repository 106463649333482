import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const NEW_FORMS_ENABLED_KEY = 'ff_newFormsEnabled';
export interface FeatureFlagsProps {
  newApplicationForms: boolean;
}

const initialState: FeatureFlagsProps = {
  newApplicationForms: true,
};

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setNewApplicationForms: (state, action: PayloadAction<{ enable: boolean }>) => {
      const { enable } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.newApplicationForms = enable;
      if (enable) {
        localStorage.setItem(NEW_FORMS_ENABLED_KEY, 'true');
      } else {
        localStorage.removeItem(NEW_FORMS_ENABLED_KEY);
      }
    },
  },
});

export const { setNewApplicationForms } = featureFlagsSlice.actions;

export const featureFlagsReducer = featureFlagsSlice.reducer;
