import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import {
  Circle as CircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { RiArrowRightUpFill, RiArrowRightSLine } from 'react-icons/ri';
import { danger, error, grey, success } from 'theme/palette-blocks';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import gbFlag from '../../img/gb.svg';
import mtFlag from '../../img/mt.svg';
import { Application, HaystackClient } from '../ApplicationManagement/types';

import 'styles/tables.scss';

const SmallTableCell = styled(TableCell)(() => ({
  paddingTop: '14px',
  paddingBottom: '14px',
  verticalAlign: 'top',
  borderRadius: '0 !important',
})) as typeof TableCell;

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordWrap: 'break-word',
};

const lampWrapperStyle = {
  width: '12px',
  cursor: 'default',
  display: 'flex',
  alignItems: 'center',
  marginTop: '5px',
};

interface DashboardRowProps {
  application: Application;
  haystackClient: HaystackClient;
  setSelectedItemId: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selected: boolean;
}

export const DashboardRow: React.FC<DashboardRowProps> = React.memo(
  ({ application, haystackClient, setSelectedItemId, setOpenDetailsDrawer, selected = false }) => {
    const theme = useTheme();

    const [isHover, setIsHover] = useState<boolean>(false);

    const { id, companyName, haystackClientId, riskStatus } = application;

    const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery(undefined);

    // #region Risk Status Dot
    let color: string;
    let filled: boolean;
    switch (riskStatus) {
      case 'LOW':
        color = success.main;
        filled = true;
        break;
      case 'MEDIUM':
        color = success.main;
        filled = false;
        break;
      case 'HIGH':
        color = danger.main;
        filled = false;
        break;
      case 'VERY_HIGH':
        color = error.main;
        filled = false;
        break;
      case 'PROHIBITED':
        color = error.main;
        filled = true;
        break;
      case 'NOT_PERFORMED':
        // eslint-disable-next-line prefer-destructuring
        color = grey[600];
        filled = true;
        break;
      default:
        color = success.main;
        filled = true;
    }
    // #endregion

    return (
      <>
        <TableRow
          selected={selected}
          className="table-row"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          sx={{ cursor: 'auto' }}
        >
          <SmallTableCell
            sx={{ ...ellipsisStyle, width: '200px', maxWidth: '200px', pl: '10px !important' }}
          >
            <Typography variant="body" color={theme.palette.text.primary} sx={ellipsisStyle}>
              {companyName}
            </Typography>
          </SmallTableCell>

          <SmallTableCell sx={{ ...ellipsisStyle, maxWidth: '150px' }}>
            <Typography variant="body" color={theme.palette.text.primary}>
              {haystackClient?.industryType}
            </Typography>
          </SmallTableCell>

          <SmallTableCell>
            <Typography variant="body" color={theme.palette.text.primary}>
              {haystackClient?.countryOfIncorporation &&
                !isLoadingCountries &&
                countries.find(
                  ({ countryCode }) => countryCode === haystackClient?.countryOfIncorporation,
                ).name}
            </Typography>
          </SmallTableCell>

          <SmallTableCell>
            {application?.categoryId && (
              <img
                src={application?.categoryId === 5 ? mtFlag : gbFlag}
                alt="Country flag"
                style={{
                  height: '14px',
                  border: '1px solid',
                  borderColor: grey[200],
                  marginTop: '3px',
                }}
              />
            )}
          </SmallTableCell>

          <SmallTableCell>
            <div style={lampWrapperStyle}>
              {filled ? (
                <CircleIcon style={{ fontSize: '10px', color }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ fontSize: '10px', color }} />
              )}
            </div>
          </SmallTableCell>

          {/* Row action buttons */}
          <SmallTableCell sx={{ width: '1%', py: '10px' }}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Link to={`/application-management/${haystackClientId}/applications/${id}/app`}>
                <IconButton sx={{ opacity: isHover ? 1 : 0, color: 'contrast.main' }}>
                  <RiArrowRightUpFill size="14px" />
                </IconButton>
              </Link>
              <IconButton
                onClick={() => {
                  setSelectedItemId(id);
                  setOpenDetailsDrawer(true);
                }}
                sx={{ ml: 2, opacity: isHover ? 1 : 0, color: 'contrast.main' }}
              >
                <RiArrowRightSLine size="14px" />
              </IconButton>
            </Stack>
          </SmallTableCell>
        </TableRow>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.application === nextProps.application && prevProps.selected === nextProps.selected,
);
