import { getBreakpoint } from './figmaVariables';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

export const breakpoints = {
  values: {
    xs: getBreakpoint('xs'), // 390
    sm: getBreakpoint('sm'), // 620
    md: getBreakpoint('md'), // 1024
    lg: getBreakpoint('lg'), // 1200
    xl: getBreakpoint('xl'), // 1600
    mobile: getBreakpoint('mobile'), // 768
    tablet: getBreakpoint('tablet'), // 1024
    laptop: getBreakpoint('laptop'), // 1200
    desktop: getBreakpoint('desktop'), // 1600
  },
};
