import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getKCHeaders } from './gatewayApi';
import { RuleSetBackendFormat, RulesetWithoutRules, RuleSortable } from '../features/Rules/types';
import { findDeepById, renameForTreeRules } from '../features/Rules/rulesUtils';

const baseQuery = fetchBaseQuery({
  baseUrl: '/internal',
  prepareHeaders: (headers) => {
    Object.entries(getKCHeaders()).forEach((header) => {
      headers.set(header[0], header[1]);
    });
    if (headers.get('content-type') === null) {
      // For some reason backend expects content-type header for every request, including GET
      headers.set('content-type', 'application/json');
    }
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => baseQuery(args, api, extraOptions);

export const internalApi = createApi({
  reducerPath: 'internalApi',
  tagTypes: ['RuleSets', 'RuleSet'],
  baseQuery: customFetchBase,
  endpoints: (build) => ({
    getLoggedInUser: build.query({
      query: () => 'reference/user-info',
    }),
    // TODO: Remove and replace calls in Cases after Transactions refactoring
    getTransactions: build.query({
      query: (body) => ({
        url: 'transaction/list',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response.transactions,
    }),

    getRuleSets: build.query<RulesetWithoutRules[], any>({
      query: (body) => ({
        url: 'rules/list', // TODO will be another endpoint for GET ruleSets
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: RuleSetBackendFormat[] }) =>
        response?.data.map((ruleset) => {
          const { rules, ...other } = ruleset;
          return { ...other };
        }),
      providesTags: () => [{ type: 'RuleSets', id: 'LIST' }],
    }),
    getRules: build.query({
      query: ({ body, ruleSetId }) => ({
        url: 'rules/list', // TODO will be another endpoint for GET rules
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: RuleSetBackendFormat[] }, error, { ruleSetId }) => {
        const { data } = response;
        return renameForTreeRules(data?.find((el) => el.id.toString() === ruleSetId)?.rules);
      },
      providesTags: (response: { data: RuleSetBackendFormat[] }, error, { ruleSetId }) => [
        { type: 'RuleSet', id: ruleSetId },
      ],
    }),
    getRule: build.query<any, { ruleId: string }>({
      query: ({ ruleId }) => ({
        url: 'rules/list', // TODO will be another endpoint for GET rule
        method: 'POST',
      }),
      transformResponse: (response: { data: RuleSetBackendFormat[] }, error, { ruleId }) => {
        const { data } = response;
        let rule: undefined | RuleSortable;
        data.forEach((ruleSet) => {
          const renamedRules = renameForTreeRules(ruleSet.rules);
          const foundRule = findDeepById(renamedRules, ruleId);
          if (foundRule) {
            rule = foundRule;
          }
        });
        return rule;
      },
    }),
    saveRuleset: build.mutation<any, { body: { id: number; ruleSet: RuleSetBackendFormat } }>({
      query: ({ body }) => ({
        url: `ruleset/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { body }) => [
        // { type: 'RuleSets', id: 'LIST' },
        { type: 'RuleSet', id: body.ruleSet.id },
      ],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetRulesQuery,
  useGetRuleSetsQuery,
  useGetRuleQuery,
  useSaveRulesetMutation,
} = internalApi;
