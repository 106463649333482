import { ColorPartial } from '@mui/material/styles/createPalette';
import { FigmaColor } from '../figmaVariables';

export interface ExtendedColorPartial extends ColorPartial {
  910?: string;
}

export const grey: ExtendedColorPartial = {
  50: FigmaColor({ colorName: 'grey-blue', shade: '050' }),
  100: FigmaColor({ colorName: 'grey-blue', shade: 100 }),
  200: FigmaColor({ colorName: 'grey-blue', shade: 200 }),
  300: FigmaColor({ colorName: 'grey-blue', shade: 300 }),
  400: FigmaColor({ colorName: 'grey-blue', shade: 400 }),
  500: FigmaColor({ colorName: 'grey-blue', shade: 500 }),
  600: FigmaColor({ colorName: 'grey-blue', shade: 600 }),
  700: FigmaColor({ colorName: 'grey-blue', shade: 700 }),
  800: FigmaColor({ colorName: 'grey-blue', shade: 800 }),
  900: FigmaColor({ colorName: 'grey-blue', shade: 900 }),
  910: FigmaColor({ colorName: 'grey-blue', shade: 910 }),
};
