import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { RiCheckFill } from "react-icons/ri";
import { alpha } from '@mui/system';
import { grey, primary, secondary } from '../palette-blocks';

interface CustomUnCheckedIconProps extends SvgIconProps {
  size?: 'small' | 'medium' | 'large';
}

const CustomUnCheckedIcon = (props: CustomUnCheckedIconProps) => (
  <SvgIcon {...props}>
  {}
  </SvgIcon>
);

const CustomCheckedIcon = (props: CustomUnCheckedIconProps) => (
  <SvgIcon {...props}>
  <RiCheckFill size={16} />
</SvgIcon>
);

export const Checkbox = {
  styleOverrides: {
    root: {
      width: '20px',
      height: '20px',
      padding: 0,
      margin: '0 8px 0 0',
      borderRadius: '4px',
      border: '2px solid',
      borderColor: grey[600],
      background: 'white',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      transition: 'none',
      '&:focus-within': {
        background: 'white',
        boxShadow: `0 0 0 1px ${alpha(secondary.active!, 0.3)}`,
      },
      '&.Mui-checked': {
        background: primary.main,
        borderColor: primary.main,
        color: 'white',
        '&:hover': {
          background: primary.dark,
          borderColor: primary.dark,
        },
      },
      '&.Mui-disabled': {
        background: grey[50],
        borderColor: alpha(grey[600]!, 0.7),
        color: 'white',
        '&.Mui-checked': {
          background: alpha(grey[50]!, 0.7),
          borderColor: alpha(grey[600]!, 0.7),
          color: alpha(grey[600]!, 0.7),
        },
      },
    },
  },
  defaultProps: {
    icon:  <CustomUnCheckedIcon />,
    checkedIcon: <CustomCheckedIcon />,
  },
};
