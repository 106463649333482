import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

interface SidebarMenuProps {
  // No additional props are needed
}

export const SidebarMenu: React.FC<PropsWithChildren<SidebarMenuProps>> = ({ children }) => (
  <Box sx={{ my: 4 }}>
    {children}
  </Box>
);
