/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  LOAD_RULES,
  LOAD_RULES_SUCCESS,
  LOAD_RULES_FAIL,
  DELETE_RULESET,
  DELETE_RULESET_SUCCESS,
  DELETE_RULESET_FAIL,
  DELETE_RULESET_RESET,
} from '../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  list: [],
  rulesetDeleting: false,
  rulesetDeletingError: false,
  rulesetDeletingSuccess: false,
};

const rulesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_RULES:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_RULES_SUCCESS:
        draft.list = action.rules;
        draft.loading = false;
        break;
      case LOAD_RULES_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case DELETE_RULESET:
        draft.rulesetDeleting = true;
        draft.rulesetDeletingSuccess = false;
        draft.rulesetDeletingError = false;
        break;
      case DELETE_RULESET_SUCCESS: {
        const idx = state.list.findIndex((val) => val.id === action.id);
        if (idx !== -1) draft.list.splice(idx, 1);
        draft.rulesetDeleting = false;
        draft.rulesetDeletingSuccess = true;
        draft.rulesetDeletingError = false;
        break;
      }
      case DELETE_RULESET_FAIL:
        draft.rulesetDeleting = false;
        draft.rulesetDeletingSuccess = false;
        draft.rulesetDeletingError = action.error;
        break;
      case DELETE_RULESET_RESET:
        draft.rulesetDeleting = false;
        draft.rulesetDeletingError = false;
        draft.rulesetDeletingSuccess = false;
        break;
    }
  });

export default rulesReducer;
