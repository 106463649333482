export const applicationsHeadData = [
  {
    property: 'companyName',
    title: 'Name',
    className: 'ApplicationRowCell column-name',
    isSortable: false,
  },
  {
    property: 'createdDate',
    title: 'Created',
    className: 'ApplicationRowCell column-date',
    isSortable: true,
  },
  {
    property: 'mainStatus',
    title: 'Status',
    className: 'ApplicationRowCell',
    isSortable: true,
  },
  {
    property: 'salesManager',
    title: 'Sales manager',
    className: 'ApplicationRowCell',
    isSortable: false,
  },
  {
    property: 'assignee',
    title: 'Assigned to',
    className: 'ApplicationRowCell',
    isSortable: false,
  },
  {
    property: 'riskStatus',
    title: 'Risk Scoring',
    className: 'ApplicationRowCell colunm-risk-scoring',
    isSortable: true,
  },
];
