import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { ModalDialog, DatePicker } from 'uikit';
import { RULE_NAME_REGEX, USERNAMEFULL_REGEX } from 'constants/constants';
import { formatDateServer, parseDateISO } from 'utils';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { useCreateClientMutation, useUpdateClientMutation } from 'services/gatewayApi/clientsApi';
import { Client } from './types';

interface ClientDialogProps {
  open: boolean;
  onClose: any;
  client: Client;
  categories: any[];
}

interface Item {
  value: number;
  label: string;
}

const messageAddAccounts =
  'Once you add a new client you can also add their accounts, IBAN and financial activity information.';
const messageEditAccounts =
  'To edit accounts, IBANs and financial activity information, go to the appropriate tab in the client details.';

export const ClientDialog: React.FC<ClientDialogProps> = ({
  open,
  onClose,
  client,
  categories,
}) => {
  const isNew = client.id === 'new';
  const title = isNew ? 'New Client' : 'Edit Client';
  const actionTitle = isNew ? 'Create' : 'Save';

  const [createClient] = useCreateClientMutation();
  const [updateClient] = useUpdateClientMutation();

  const validationSchema = yup.object({
    clientId: yup
      .string()
      .required('Required')
      .matches(RULE_NAME_REGEX, 'Only text and numbers, please'),
    name: yup
      .string()
      .required('Required')
      .matches(USERNAMEFULL_REGEX, 'Only text and numbers, please'),
    incorporationDate: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
  });

  const initialValues = {
    ...client,
    clientCategories: client.clientCategories.map((el) => el.id),
    incorporationDate: parseDateISO(client.incorporationDate),
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        name: values.name,
        clientId: values.clientId,
        categoriesId: values.clientCategories,
        incorporationDate: formatDateServer(values.incorporationDate),
      };

      const mutationPromise = isNew
        ? await createClient({ body })
        : await updateClient({ body, id: client.id });

      // @ts-ignore
      if (!mutationPromise.error) {
        isNew ? toastCreateSuccess('Client') : toastUpdateSuccess('Client');

        resetForm();
        onClose();
      }
    },
  });

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      handleAction={() => formik.handleSubmit()}
      actionTitle={actionTitle}
      isLoading={formik.isSubmitting}
    >
      <Stack spacing={4}>
        <TextField
          type="text"
          autoFocus
          fullWidth
          label="Client ID"
          name="clientId"
          onChange={formik.handleChange}
          value={formik.values.clientId}
          variant="standard"
          error={formik.touched.clientId && Boolean(formik.errors.clientId)}
          disabled={formik.isSubmitting}
        />
        <TextField
          type="text"
          fullWidth
          label="Name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          variant="standard"
          error={formik.touched.name && Boolean(formik.errors.name)}
          disabled={formik.isSubmitting}
        />
        <DatePicker
          label="Date of incorporation (if exists)"
          name="incorporationDate"
          value={formik.values.incorporationDate}
          variant="standard"
          error={formik.touched.incorporationDate && Boolean(formik.errors.incorporationDate)}
          onChange={formik.handleChange}
          disabled={!isNew && !!initialValues.incorporationDate}
        />

        <Autocomplete
          multiple
          id="clientCategories"
          options={categories}
          getOptionLabel={(option: Item) => option.label}
          limitTags={2}
          disableCloseOnSelect
          fullWidth
          value={categories.filter((option) =>
            formik.values.clientCategories.some((v) => option.value === v),
          )}
          onChange={(event, newValue) => {
            formik.setFieldValue(
              'clientCategories',
              newValue.map((nextValue) => nextValue.value),
            );
          }}
          ChipProps={{ color: 'secondary', size: 'small' }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Categories"
              placeholder="Select client categories"
            />
          )}
        />

        <Typography sx={{ color: 'grey.600' }}>
          {isNew ? messageAddAccounts : messageEditAccounts}
        </Typography>
      </Stack>
    </ModalDialog>
  );
};
