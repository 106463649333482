import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import {
  Checkbox,
  Chip,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { ValueLabel } from '../../types';

interface ChipSelectorProps {
  list: ValueLabel[];
  selectedList: ValueLabel[];
  setSelectedList: React.Dispatch<React.SetStateAction<ValueLabel[]>>;
  color?: string; // recommended: 'default' | 'base' | 'primary' | 'secondary' | 'success' | 'error' | 'bold' | 'warning';
  limitTags?: number;
  messageEmpty?: string;
  listDisabled?: string[]; // list of values
}

const ChipSelector: React.FC<ChipSelectorProps> = ({
  list,
  selectedList,
  setSelectedList,
  color = 'secondary',
  limitTags,
  messageEmpty = 'no categories',
  listDisabled = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<ValueLabel[]>(selectedList);
  const overLimitTags =
    selectedList.length > limitTags ? selectedList.length - limitTags : undefined;

  useEffect(() => {
    setSelected(selectedList);
  }, [selectedList]);

  const open = Boolean(anchorEl);

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    !_.isEqual(selected, selectedList) && setSelectedList(selected);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleChangeSelected = (element, e) => {
    e.stopPropagation();
    if (getIsChecked(element)) {
      setSelected((prev) => prev.filter((el) => el.value !== element));
    } else {
      const elementForAdd = list.find((el) => el.value === element);
      setSelected((prev) => [...prev, elementForAdd]);
    }
  };

  const getIsChecked = (element) => !!selected.some((el) => el.value === element);
  const getIsDisabled = (element) => listDisabled?.some((el) => el === element);

  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        {selectedList.length > 0 ? (
          selectedList.map((el, idx) => {
            if (limitTags !== undefined && idx > limitTags - 1) {
              return null;
            }
            // @ts-ignore
            return <Chip key={el.value} size="small" color={color} label={el.label} />;
          })
        ) : (
          <Typography>{messageEmpty}</Typography>
        )}
        {overLimitTags > 0 && <Typography>{`+${overLimitTags}`}</Typography>}
        <IconButton size="small" onClick={handleClick}>
          <MoreHorizIcon color="primary" />
        </IconButton>
      </Stack>

      <Menu anchorEl={anchorEl} open={open} onClose={(e) => handleClose(e)}>
        {list.length === 0 || !list ? (
          <MenuItem>no values</MenuItem>
        ) : (
          list.map((el) => (
            <MenuItem
              onClick={(e) => handleChangeSelected(el.value, e)}
              key={el.value}
              disabled={getIsDisabled(el.value)}
            >
              <Checkbox checked={getIsChecked(el.value)} />
              <ListItemText>{el.label}</ListItemText>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export { ChipSelector };
export const MemoizedChipSelector = React.memo(ChipSelector);
