/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  LOAD_PERMISSION_LIST,
  LOAD_PERMISSION_LIST_SUCCESS,
  LOAD_PERMISSION_LIST_FAIL,
} from '../constants/constants';

export const initialState = {
  error: false,
  permissions: [],
  permissionsLoading: false,
};

const permissionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_PERMISSION_LIST:
        draft.permissionsLoading = true;
        draft.error = false;
        break;
      case LOAD_PERMISSION_LIST_SUCCESS:
        draft.permissions = action.permissions;
        draft.permissionsLoading = false;
        break;
      case LOAD_PERMISSION_LIST_FAIL:
        draft.permissionsLoading = false;
        draft.error = action.error;
        break;
    }
  });

export default permissionsReducer;
