import { ValueLabel } from 'types';

export const categories: ValueLabel[] = [
  { label: 'Malta', value: 5 },
  { label: 'UK', value: 6 },
]

export const allowedCaseCategories: string[] = [
  'ONBOARDING_COMPLIANCE',
  'ONBOARDING_OPERATIONS',
  'BANKING',
  'SALES',
  'UK_MLRO',
  'MALTA_MLRO',
  'DIRECTOR_COMPLIANCE',
  'HEAD_AML',
  'ONBOARDING_OPERATIONS_SENIOR',
];
