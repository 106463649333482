import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';

export const HeadTableCell = styled(TableCell)(({ theme }) =>
  theme.unstable_sx({
    color: 'grey.600',
    typography: 'body',
    fontWeight: '400',
    py: 4,
  }),
);
