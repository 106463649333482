import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ModalDialog } from 'uikit';
import { RiDeleteBinFill, RiPencilLine } from 'react-icons/ri';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { useDeleteResponseCodeMutation } from 'services/gatewayApi/responseCodesApi';
import { toastDeleteSuccess } from 'utils';
import { ResponseCode } from '../../types';
import { ResponseCodeDialog } from './ResponseCodeDialog';

interface ResponseCodeRowProps {
  item: ResponseCode;
}

export const ResponseCodeRow: React.FC<ResponseCodeRowProps> = ({ item }) => {
  const { id, responseCode, responseMessage, score, createdByName, createdDate } = item;

  const [deleteResponseCode] = useDeleteResponseCodeMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const handleDelete = () => {
    deleteResponseCode({ id })
      .unwrap()
      .then(() => {
        toastDeleteSuccess('Response Code');
      });
    setOpenConfirmDelete(false);
  };

  return (
    <>
      <Box
        sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[300]}`, mb: 3, pb: 3 })}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="body1" component="span">
            {responseCode}
          </Typography>
          <Typography variant="body1" component="span">
            score: {score}
          </Typography>
        </Stack>
        <Typography className="wrap" variant="body1" component="p" color="grey.600" sx={{ mb: 2 }}>
          {responseMessage}
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="body2" component="span" color="grey.600">
            Created by {createdByName} on {formatIsoDate(createdDate, DATE_FORMAT_MESSAGES)}
          </Typography>
          <Box>
            <Button
              color="base"
              onClick={handleEdit}
              variant="contained"
              startIcon={<RiPencilLine size="14px" />}
            >
              Edit
            </Button>
            <Button
              color="error"
              onClick={handleConfirmDelete}
              variant="contained"
              startIcon={<RiDeleteBinFill size="14px" />}
              sx={{ ml: 2 }}
            >
              Delete
            </Button>
          </Box>
        </Stack>
        <div />
      </Box>

      {dialogOpen && (
        <ResponseCodeDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          responseCode={item}
        />
      )}

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete Response Code"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Response Code '}
            <Typography variant="subtitle1" component="span">
              {responseCode}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </>
  );
};
