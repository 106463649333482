import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiArrowRightFill, RiFileCopyLine } from 'react-icons/ri';
import { ArrowBack, Skeleton } from 'uikit';
import { useGetApplicationQuery } from 'services/gatewayApi/applicationApi';
import { useGetHaystackClientQuery } from 'services/gatewayApi/haystackClientApi';
import { toastCopySuccess, toastCopyError } from 'utils/toast';
import { OverviewTab } from './OverviewTab';
import { AssigneeChip, RiskStatusChip } from './Chips';

interface ApplicationDetailsSidebarProps {
  applicationId: string;
  onClose: any;
}

export const ApplicationDetailsSidebar: React.FC<ApplicationDetailsSidebarProps> = ({
  applicationId,
  onClose,
}) => {
  const { data: applicationDetails, isLoading: isLoadingApplication } = useGetApplicationQuery(
    applicationId,
    {
      skip: !applicationId,
    },
  );

  const {
    data: client,
    isLoading: isLoadingClient,
  } = useGetHaystackClientQuery(applicationDetails?.haystackClientId, {
    skip: !applicationDetails,
  });

  const isLoading = isLoadingApplication || isLoadingClient;

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <ArrowBack onClose={onClose} />

        {!isLoading && (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              color="base"
              onClick={() => {
                navigator.clipboard.writeText(applicationId).then(
                  () => toastCopySuccess('ID'),
                  () => toastCopyError('ID'),
                );
              }}
              startIcon={<RiFileCopyLine size="14px" />}
              size="small"
            >
              Copy ID
            </Button>

            <Link to={`/application-management/${applicationDetails?.haystackClientId}/applications/${applicationId}`}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<RiArrowRightFill size="14px" />}
                size="small"
              >
                Open Application
              </Button>
            </Link>
          </Stack>
        )}
      </Stack>

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && (
        <>
          <Typography variant="h2" sx={{ mt: 4, mb: 2 }}>
            {applicationDetails?.companyName}
          </Typography>

          <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ mb: 4 }}>
            <AssigneeChip application={applicationDetails} />
            <RiskStatusChip riskStatus={applicationDetails?.riskStatus} />
          </Stack>

          <OverviewTab application={applicationDetails} haystackClient={client} />
        </>
      )}
    </Box>
  );
};
