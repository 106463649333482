import React from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { ValueLabel } from 'types';
import { AdornmentWrapper, FormFieldWrapper, Information } from '../index';

interface FormSelectProps extends InputBaseProps {
  label?: string;
  // size?: 'sm' | 'md' | 'lg'; //TODO
  // variant?: 'plain' | 'outlined' | 'soft' | 'solid'; // TODO
  // color?: 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info'; // TODO
  disabled?: boolean;
  info?: string;
  prefixForm?: React.FC | JSX.Element;
  suffix?: React.FC | JSX.Element;
  error?: boolean;
  value?: string;
  options: ValueLabel[];
  onChange?: (any) => void;
  getIconFunction?: (string) => React.FC | JSX.Element;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  label = undefined,
  // size = 'sm', // TODO
  // variant = 'soft', // TODO
  // color = 'info', // TODO
  disabled = false,
  info = undefined,
  prefixForm = undefined,
  suffix = undefined,
  error = false,
  value = '',
  options,
  onChange,
  getIconFunction,
  sx,
  ...otherProps
}) => (
  <FormFieldWrapper label={label} disabled={disabled} error={error} sx={sx}>
    <AdornmentWrapper disabled={disabled}>{prefixForm}</AdornmentWrapper>
    <Select
      onChange={onChange}
      input={
        <InputBase
          sx={{ flex: 1, '& .MuiInputBase-input': { lineHeight: '20px' } }}
          inputProps={{ 'aria-label': 'form text field' }}
          disabled={disabled}
          value={value}
          {...otherProps}
        />
      }
    >
      {options.map((el, idx) => (
        <MenuItem key={`list_${el.value}_${el.label}`} value={el.value}>
          <Stack direction="row" spacing={2} alignItems="center">
            {getIconFunction && getIconFunction(el.value)}
            <Typography>{el.label}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
    <Information info={info} disabled={disabled} />
    <AdornmentWrapper disabled={disabled}>{suffix}</AdornmentWrapper>
  </FormFieldWrapper>
);
