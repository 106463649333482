import {
  Backdrop,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  TextField,
  Skeleton,
  Paper,
  Menu,
  // Switch,
  Checkbox,
  DialogActions,
  DialogTitle,
  DialogContent,
  Chip,
  FormLabel,
  FormControlLabel,
  InputBase,
  FilledInput,
  FormHelperText,
  InputLabel,
  InputAdornment,
  CssBaseline,
  Autocomplete,
} from './theme-components';
import { grey } from './palette-blocks';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
    dark: true;
  }
}

export const components = {
  // Data display
  MuiChip: Chip,

  // Inputs
  MuiAutocomplete: Autocomplete,
  MuiButtonBase: ButtonBase,
  MuiButton: Button,
  MuiIconButton: IconButton,
  MuiSvgIcon: SvgIcon,
  MuiCheckbox: Checkbox,
  MuiTextField: TextField,
  MuiInputBase: InputBase,
  MuiFilledInput: FilledInput,
  // MuiSwitch: Switch,

  // Feedback
  MuiSkeleton: Skeleton,
  MuiDialogActions: DialogActions,
  MuiDialogTitle: DialogTitle,
  MuiDialogContent: DialogContent,

  // Surface
  MuiPaper: Paper,
  MuiMenu: Menu,
  // Layout
  MuiBackdrop: Backdrop,
  MuiFormLabel: FormLabel,
  MuiFormControlLabel: FormControlLabel,
  MuiFormHelperText: FormHelperText,
  MuiInputLabel: InputLabel,
  MuiInputAdornment: InputAdornment,
  // MuiX
  MuiGrid: Grid,
  MuiTableCell: TableCell,
  MuiTableRow: TableRow,
  MuiTypography: {
    styleOverrides: {
      root: {
        color: grey[900],
      },
    },
  },
  MuiCssBaseline: CssBaseline,
};
