import { createEntityAdapter } from '@reduxjs/toolkit';
import { gatewayApi } from '../gatewayApi';

const ruleCategoriesAdapter = createEntityAdapter();
const rulesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getRuleCategories: build.query({
      query: (params) => ({
        url: 'rules/categories',
        params,
      }),
      transformResponse: (categories) =>
        ruleCategoriesAdapter.setAll(ruleCategoriesAdapter.getInitialState(), categories),
    }),
    getRuleCategoriesPlain: build.query({
      query: (params) => ({
        url: 'rules/categories',
        params,
      }),
    }),
    getRuleHistory: build.query({
      query: (params) => ({
        url: `rules/history/${params.ruleId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Rules', id: 'LIST' }],
    }),
    getRulesList: build.query({
      query: (productId) => ({
        url: `ruleset/${productId}/rules-list`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Rules', id: 'LIST' }],
      transformResponse: (response) => ({
        rulesList: response.map((item) => ({
          id: item.id,
          name: item.name,
          description: item.description,
        })),
      }),
    }),
    addNewRule: build.mutation({
      query: (newModel) => ({
        url: 'rules',
        method: 'POST',
        body: newModel,
      }),
      invalidatesTags: [{ type: 'Rules', id: 'LIST' }],
    }),
    updateRule: build.mutation({
      query: (model) => {
        if (typeof model.rule.id !== 'number') return {};
        return {
          url: `rules/${model.rule.id}`,
          method: 'PUT',
          body: model,
        };
      },
      invalidatesTags: [{ type: 'Rules', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRuleCategoriesQuery,
  useGetRuleCategoriesPlainQuery,
  useGetRuleHistoryQuery,
  useGetRulesListQuery,
  useAddNewRuleMutation,
  useUpdateRuleMutation,
} = rulesApi;
