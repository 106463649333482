import { gatewayApi } from '../gatewayApi';
import type { Application, HaystackClient } from '../../features/ApplicationManagement/types';
import { Pageable, Sort } from '../../features/ApplicationManagement/types/Application';

export interface GetDashboardsRequest {
  applications: {
    content: Application[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort[];
    totalElements: number;
    totalPages: number;
  },
  haystackClients: HaystackClient[],
  statistics: object,
}

const dashboardsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboards: build.query<GetDashboardsRequest, any>({
      query: (params) => ({
        url: `dashboards`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'Dashboards', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDashboardsQuery,
} = dashboardsApi;
