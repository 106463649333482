import React from 'react';
import { ApplicationDTO } from 'features/ApplicationManagement/types/ApplicationInList';
import { formatDateScreen, parseDateISO } from '../../../utils';
import { useGetListUsersQuery } from '../../../services/gatewayApi/usersApi';
import { ApplicationRow } from '../ApplicationRow';

interface ApplicationManagementListProps {
  list: ApplicationDTO[];
  loading: boolean;
}

const ApplicationManagementList: React.FC<ApplicationManagementListProps> = ({ list, loading }) => {
  const { data: usersList = [], isLoading: isUsersLoading } = useGetListUsersQuery(undefined);

  const getName = (userId) => usersList.find((el) => el.id === userId)?.name || userId;

  return (
    <>
      {list.map((item, idx) => (
        <ApplicationRow
          key={item.id}
          id={item.id}
          clientId={item.haystackClientId}
          title={item.companyName}
          status={item.mainStatus}
          riskStatus={item.riskStatus}
          assignee={isUsersLoading ? '...' : getName(item.assignee)}
          salesManager={item.salesManager ? item.salesManager : ''}
          createdDate={formatDateScreen(parseDateISO(item.createdDate)) || '-'}
        />
      ))}
    </>
  );
};

export default ApplicationManagementList;
