import { xsmall } from './inputBase-variants';
import { grey } from '../palette-blocks';

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    xsmall: true;
  }
}

export const InputBase = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.Mui-disabled.MuiInputBase-root': {
        backgroundColor: grey[50],
      },
    },
  },

  variants: [xsmall],
};
