export const colors = [
  'default',
  'base',
  'primary',
  'secondary',
  'success',
  'error',
  'bold',
  'warning',
];
