import React, { useState } from 'react';
import { checkProductApiName, formatDateServer } from 'utils';
import { Button, Stack } from '@mui/material';
import { FormDatePicker, FormSelect, FormTextField } from 'uikit';
import { useAddRuleVetoMutation } from 'services/gatewayApi/ruleVetosApi';
import { ErrorsType, NewRuleVeto, RuleVeto } from '../types';
import { vetoTypes } from '../../ruleForm/constants';
import { validationSchemaRuleVeto } from '../../ruleForm/validationShemaRuleVeto';
import { initialRuleVeto } from '../constatns';

interface RuleVetoDialogProps {
  onClose: () => void;
  ruleId: string;
}

export const RuleVetoDialog: React.FC<RuleVetoDialogProps> = ({ onClose, ruleId }) => {
  const [addRuleVeto] = useAddRuleVetoMutation();

  const vetoTypesForTenant = vetoTypes.filter((el) => checkProductApiName(el.apiName));

  const [formErrors, setFormErrors] = useState<ErrorsType>({});
  const [currentVeto, setCurrentVeto] = useState<RuleVeto>({
    ...initialRuleVeto,
    ruleVetoType: vetoTypesForTenant[0]?.value,
  });

  const handleChangeValue = ({ value, field }) =>
    setCurrentVeto((prev) => ({ ...prev, [field]: value }));

  const handleAction = async () => {
    const model: NewRuleVeto = {
      ruleId,
      ruleVetoType: currentVeto.ruleVetoType,
      referenceIdentifier: currentVeto.referenceIdentifier,
      startDate: currentVeto.startDate,
      expiryDate: currentVeto.expiryDate,
      comments: currentVeto.comments,
    };
    try {
      await validationSchemaRuleVeto.validate(model, { abortEarly: false });
      addRuleVeto({
        ...model,
        startDate: formatDateServer(model.startDate),
        expiryDate: formatDateServer(model.expiryDate),
      });
      onClose();
    } catch (error) {
      const errors = {};
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      setFormErrors(errors);
    }
  };

  const validateVeto = async (model) => {
    try {
      await validationSchemaRuleVeto.validate(model, { abortEarly: false });
      setFormErrors({});
    } catch (error) {
      const errors = {};
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      setFormErrors(errors);
    }
  };

  const handleChangeVetotype = (e) => {
    setCurrentVeto((prev) => ({ ...prev, ruleVetoType: e.target.value, referenceIdentifier: '' }));
    setFormErrors((prev) => {
      const { referenceIdentifier, ...rest } = prev;
      return rest as ErrorsType;
    });
  };

  return (
    <>
      <Stack direction="column" spacing={4}>
        <FormSelect
          name="ruleVetoType"
          label="Veto type"
          value={currentVeto.ruleVetoType}
          onChange={handleChangeVetotype}
          options={vetoTypesForTenant}
        />
        <FormTextField
          name="referenceIdentifier"
          label="Identifier"
          value={currentVeto.referenceIdentifier}
          error={!!formErrors?.referenceIdentifier}
          onChange={(e) =>
            handleChangeValue({ value: e.target.value, field: 'referenceIdentifier' })
          }
          onBlur={() => validateVeto(currentVeto)}
        />
        <FormDatePicker
          label="Start date"
          error={!!formErrors?.startDate}
          value={currentVeto.startDate as Date}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(e: Date) => handleChangeValue({ value: e, field: 'startDate' })}
          onBlur={() => validateVeto(currentVeto)}
        />
        <FormDatePicker
          label="Expiry date"
          error={!!formErrors?.expiryDate}
          value={currentVeto.expiryDate as Date}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(e: Date) => handleChangeValue({ value: e, field: 'expiryDate' })}
          onBlur={() => validateVeto(currentVeto)}
        />
        <FormTextField
          name="comments"
          label="Comment"
          multiline
          rows={3}
          value={currentVeto.comments}
          onChange={(e) => handleChangeValue({ value: e.target.value, field: 'comments' })}
          onBlur={() => validateVeto(currentVeto)}
        />
      </Stack>
      <Stack direction="row" spacing={2} justifyContent="flex-end" pb={4} pt={7}>
        <Button color="base" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleAction}
          disabled={Object.keys(formErrors).length !== 0}
        >
          Create
        </Button>
      </Stack>
    </>
  );
};
