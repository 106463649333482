import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button as MUIButton,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  AddCircle as AddCircleIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { useGetRiskScoringQuestionsBySectionQuery } from 'services/gatewayApi/riskScoringQuestionsApi';
import {
  useAddRiskScoringAnswerMutation,
  useDeleteRiskScoringAnswerMutation,
} from 'services/gatewayApi/riskScoringAnswersApi';
import { RiskScoringField } from '../RiskScoringField';

import './AccordionSection.scss';

interface AccordionSectionProps {
  applicationId: string;
  riskScoringVersion: number;
  isRiskScoringSubmitted: boolean;
  sectionId: number;
  title: string;
  scores: any;
  answers: any[];
  expanded: boolean;
  onExpand: any;
  readonly?: boolean;
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  applicationId,
  riskScoringVersion,
  isRiskScoringSubmitted = false,
  sectionId,
  title,
  scores,
  answers,
  expanded = false,
  onExpand,
  readonly,
}) => {
  const { data: questions, isLoading } = useGetRiskScoringQuestionsBySectionQuery(sectionId);

  useEffect(() => {
    if (!isLoading) {
      const data = {
        sectionId,
        questionsCount: questions.length,
        answersCount: questions.filter((question) =>
          answers.find((answer) => answer.questionId === question.id),
        ).length,
      };
    }
  }, [isLoading, questions, answers]);

  const qnaSort = (a, b) => (a.row * 1000 + a.col > b.row * 1000 + b.col ? 1 : -1);

  const getAnswerForQuestion = (questionId, index = 0) =>
    answers
      .slice()
      .sort(qnaSort)
      .filter((answer) => answer.questionId === questionId)[index];

  const getAnswerScoreIdForQuestion = (questionId, answer) =>
    scores.find(
      (score) =>
        score.questionId === questionId && (score.answer === answer || score.answer === undefined),
    )?.id;

  // #region Adding new row
  const [addAnswer] = useAddRiskScoringAnswerMutation();
  const onAddAnswersRow = (childQuestions, maxRowNumber) => {
    childQuestions
      .slice()
      .sort(qnaSort)
      .forEach(({ id: questionId }, i) => {
        const scoreId = getAnswerScoreIdForQuestion(questionId, undefined);

        const model = {
          questionId,
          scoreId,
          row: maxRowNumber + 1,
          col: 1,
          applicationId,
        };

        addAnswer(model)
          .unwrap()
          .catch((rejected) => console.error(rejected));
      });
  };
  // #endregion

  // #region Removing row
  const [deleteAnswer] = useDeleteRiskScoringAnswerMutation();
  const onDeleteAnswersRow = (relatedAnswers) => {
    relatedAnswers.forEach(({ id }, i) => {
      deleteAnswer({ applicationId, id })
        .unwrap()
        .catch((rejected) => console.error(rejected));
    });
  };
  // #endregion

  let hideExchangeName = true,
    hideIsCustomerLicensed = true;

  return (
    <Accordion
      id={`accordion${sectionId}`}
      key={`accordion${sectionId}`}
      className="AccordionSection"
      expanded={expanded}
      onChange={onExpand(sectionId)}
      disableGutters
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: 22 }} />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="body1"
            color="contrastText"
            component="span"
            sx={{ fontSize: '16px' }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="grey.500"
            component="span"
            sx={{ mr: 2, fontSize: '16px' }}
          >
            {isRiskScoringSubmitted ? 'Submitted' : 'Pending'}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {questions &&
          questions.length > 0 &&
          questions
            .slice()
            .sort(qnaSort)
            .filter((q) => typeof q.parentId === 'undefined')
            .map(({ id, title, description, kind, option, isContainer }) => {
              if (isContainer) {
                // Container for questions
                const repeatable = questions
                  .slice()
                  .sort(qnaSort)
                  .find((q) => q.parentId === id);

                if (!repeatable) {
                  return null;
                }

                // Questions
                const childQuestions = questions
                  .slice()
                  .sort(qnaSort)
                  .filter((q) => q.parentId === repeatable.id);

                if (!childQuestions) {
                  return null;
                }

                // Rows count which needs to be generated
                let firstQuestionAnswersCount = 0;
                let maxRowNumber = 0;

                // if no answers, show single block with disabled Add button
                // if answer count >= 1, show single block with enabled Add button and add answer records for second row
                let firstRowHasAllAnswers = true;
                childQuestions.forEach((q, i) => {
                  const answersForQuestion = answers.filter((a) => a.questionId === q.id);
                  firstRowHasAllAnswers &&= !!answersForQuestion.length;

                  // Find highest row number for answers
                  answersForQuestion.forEach(({ row }) => {
                    if (maxRowNumber < row) {
                      maxRowNumber = row;
                    }
                  });

                  if (i === 0) {
                    firstQuestionAnswersCount = answersForQuestion.length;
                  }
                });

                let index = 0;
                const rows = [];

                rows.push(
                  <Box key={`RiskScoringField_${id}_title`} sx={{ my: 2 }}>
                    <Typography
                      variant="body1"
                      component="span"
                      color={readonly ? 'grey.400' : 'grey.600'}
                    >
                      {repeatable.title}
                    </Typography>
                  </Box>,
                );

                do {
                  // Answers for deletion
                  const relatedAnswers = [];
                  childQuestions.forEach((q) => {
                    const answer = getAnswerForQuestion(q.id, index);
                    if (answer) {
                      relatedAnswers.push(answer);
                    }
                  });

                  rows.push(
                    <Box
                      id={`RiskScoringField_Repeatable_${id}_${index}`}
                      key={`RiskScoringField_Repeatable_${id}_${index}`}
                      className="RiskScoringField_Repeatable"
                      sx={{ mb: 2 }}
                    >
                      <Stack
                        id={`RiskScoringField_RepeatableWrapper_${repeatable.id}`}
                        key={`RiskScoringField_RepeatableWrapper_${repeatable.id}`}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        {childQuestions &&
                          childQuestions.length > 0 &&
                          childQuestions
                            .slice()
                            .sort(qnaSort)
                            .map((c) => {
                              const answer = getAnswerForQuestion(c.id, index);

                              return (
                                <RiskScoringField
                                  key={`RiskScoringField_${c.id}_${answer?.id}_${index}`}
                                  id={c.id}
                                  applicationId={applicationId}
                                  title={c.title + (c.description ? ` (${c.description})` : '')}
                                  kind={c.kind}
                                  option={c.option}
                                  answers={answers}
                                  questions={questions}
                                  answer={answer}
                                  scores={scores}
                                  riskScoringVersion={riskScoringVersion}
                                  hideScore
                                  readonly={readonly}
                                />
                              );
                            })}
                        <IconButton
                          color="error"
                          onClick={() => onDeleteAnswersRow(relatedAnswers)}
                          disabled={relatedAnswers.length !== childQuestions.length || readonly}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </Box>,
                  );

                  index++;
                } while (index < firstQuestionAnswersCount);

                if (!isRiskScoringSubmitted) {
                  rows.push(
                    <Box key={`RiskScoringField_${id}_addButton`} sx={{ mb: 2 }}>
                      <MUIButton
                        size="medium"
                        startIcon={<AddCircleIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={() => onAddAnswersRow(childQuestions, maxRowNumber)}
                        disabled={!firstRowHasAllAnswers || readonly}
                      >
                        Add {repeatable.title}
                      </MUIButton>
                    </Box>,
                  );
                }

                return (
                  <Box
                    key={`RiskScoringField_RepeatableGroup_${id}`}
                    className="RiskScoringField_RepeatableGroup"
                  >
                    {rows}
                  </Box>
                );
              }

              // Hide "Exchange" field if "Publicly traded entity" is NOT answered YES
              if (title === 'Publicly traded entity' && kind.toUpperCase() === 'RADIO') {
                hideExchangeName = getAnswerForQuestion(id)?.caption !== 'YES';
              }
              if (title === 'If Publicly Traded, please list down exchange' && hideExchangeName) {
                return null;
              }

              // Hide "Is Customer licensed" field if "Does customer require a license to operate" is NOT answered YES
              if (
                title === 'Does customer require a license to operate?' &&
                kind.toUpperCase() === 'RADIO'
              ) {
                hideIsCustomerLicensed = getAnswerForQuestion(id)?.caption !== 'YES';
              }
              if (title === 'Is Customer licensed?' && hideIsCustomerLicensed) {
                return null;
              }

              return (
                <RiskScoringField
                  key={`RiskScoringField_${id}`}
                  id={id}
                  applicationId={applicationId}
                  riskScoringVersion={riskScoringVersion}
                  title={title + (description ? ` (${description})` : '')}
                  kind={kind}
                  option={option}
                  answers={answers}
                  questions={questions}
                  answer={getAnswerForQuestion(id)}
                  scores={scores}
                  readonly={readonly}
                />
              );
            })}
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionSection };
