import { PaletteOpenpaydColor } from './types';
import { FigmaColor, textColor } from '../figmaVariables';

export const base: PaletteOpenpaydColor = {
  main: FigmaColor({ colorName: 'grey-blue', shade: 100 }),
  light: FigmaColor({ colorName: 'grey-blue', shade: '050' }),
  dark: FigmaColor({ colorName: 'grey-blue', shade: 300 }),
  contrastText: textColor('text-base'),
  'bg-100': FigmaColor({ colorName: 'grey-blue', shade: 100 }),
  'bg-200': FigmaColor({ colorName: 'grey-blue', shade: 200 }),
  'bg-300': FigmaColor({ colorName: 'grey-blue', shade: 300 }),
  'outline-100': FigmaColor({ colorName: 'grey-blue', shade: 700 }),

  hover: FigmaColor({ colorName: 'grey-blue', shade: 200 }),
  active: FigmaColor({ colorName: 'grey-blue', shade: 300 }),
};
