/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_USERS,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAIL,
  SET_PRODUCT_USER,
  UPDATE_USER_PERMISSION,
  SAVE_USERS,
  SAVE_USERS_SUCCESS,
  SAVE_USERS_FAIL,
  LOAD_USERINFO,
  LOAD_USERINFO_SUCCESS,
  LOAD_USERINFO_FAIL,
  OPEN_PRODUCT_SELECTION_MODAL,
  CLOSE_PRODUCT_SELECTION_MODAL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from '../constants/constants';

export const initialState = {
  users: [],
  loadingUsers: false,
  error: false,
  usersToSave: [],
  loggedInUser: {},
  selectedProductId: null,
  productSelectionModalOpen: false,
};

const usersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_USERS:
        draft.loadingUsers = true;
        draft.error = false;
        break;
      case LOAD_USERS_SUCCESS:
        draft.users = action.users;
        draft.loadingUsers = false;
        break;
      case LOAD_USERS_FAIL:
        draft.loadingUsers = false;
        draft.error = action.error;
        break;
      case UPDATE_USER_PERMISSION: {
        const { userId, permission } = action;
        const users = state.users.slice(0);
        const user = users.find((u) => u.id === userId);

        const hasPermission = user.permissions.find((p) => p.id === permission.id);

        if (hasPermission === undefined) {
          user.permissions.push(permission);
        } else {
          user.permissions = user.permissions.filter((p) => p.id !== permission.id);
        }
        let { usersToSave } = state;
        // if (!(usersToSave instanceof Array)) usersToSave = usersToSave.toJS();

        usersToSave = usersToSave.filter((u) => u.id !== user.id);
        usersToSave.push(user);

        const foundIndex = users.findIndex((u) => u.id === user.id);
        users[foundIndex] = user;

        draft.users = users;
        draft.users.usersToSave = usersToSave;
        break;
        // return state.set(users, users).set('usersToSave', usersToSave);
      }
      case SAVE_USERS:
        draft.saving = true;
        draft.error = false;
        break;
      case CREATE_USER:
        draft.saving = true;
        draft.error = false;
        break;
      case CREATE_USER_SUCCESS:
        break;
      case CREATE_USER_FAIL:
        break;
      case SAVE_USERS_SUCCESS:
        draft.saving = false;
        draft.usersToSave = [];
        break;
      case SAVE_USERS_FAIL:
        draft.saving = false;
        draft.error = action.error;
        break;
      case LOAD_USERINFO:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_USERINFO_SUCCESS:
        draft.loggedInUser = action.user;
        draft.selectedProductId = action.user.selectedProductId;
        draft.loading = false;
        break;
      case LOAD_USERINFO_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case SET_PRODUCT_USER:
        draft.selectedProductId = action.productId;
        break;
      case OPEN_PRODUCT_SELECTION_MODAL:
        draft.productSelectionModalOpen = true;
        break;
      case CLOSE_PRODUCT_SELECTION_MODAL:
        draft.productSelectionModalOpen = false;
        break;
    }
  });

export default usersReducer;
