import { grey } from '../palette-blocks';

export const FormLabel = {
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        color: grey[700],
      },
    },
  },
};
