import React, { useState } from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { RiCheckLine, RiArrowDownSLine } from 'react-icons/ri';
import { toastUpdateSuccess } from 'utils/toast';
import { useUpdateCaseMutation } from 'services/gatewayApi/casesApi';
import { getCaseStatuses } from '../constants';

interface CaseStatusChipProps {
  caseId: string;
  caseStatus: any;
  buttonVariant?: string;
  caseType: string;
}

export const CaseStatusChip: React.FC<CaseStatusChipProps> = ({
  caseId,
  caseStatus,
  buttonVariant = 'text',
  caseType,
}) => {
  const caseStatusItem = getCaseStatuses(caseType).find((i) => i.value === caseStatus);

  const [menuAnhcorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnhcorEl);
  const handleMenuClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleChipClick = (e) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };

  const [updateCase] = useUpdateCaseMutation();
  const [isSaving, setIsSaving] = useState(false);
  const handleChange = (e, status) => {
    e.stopPropagation();
    handleMenuClose(e);

    // Do not update if value is same as current
    if (status === caseStatus) {
      return;
    }

    setIsSaving(true);

    updateCase({
      caseId,
      data: {
        caseStatus: status,
      },
    })
      .unwrap()
      .then(() => {
        toastUpdateSuccess('Status');
      })
      .catch((rejected) => console.error(rejected))
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {caseStatusItem && (
        <Button
          size="medium"
          variant={buttonVariant}
          color="base"
          disabled={isSaving}
          onClick={handleChipClick}
          endIcon={<RiArrowDownSLine size="14px" />}
        >
          {caseStatusItem.label}
        </Button>
      )}
      <Menu id="CaseStatusMenu" anchorEl={menuAnhcorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {getCaseStatuses(caseType).map((item, idx) => (
          <MenuItem key={idx} onClick={(e) => handleChange(e, item.value)}>
            <ListItemIcon>{item.value === caseStatus && <RiCheckLine size="16px" />}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
