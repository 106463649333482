import { alpha } from '@mui/material/styles';
import { contrast, text } from '../../palette-blocks';
import { buttonFocus } from '../mixins';

export const Contrast = {
  props: {
    color: 'contrast',
  },
  style: {
    color: contrast.contrastText,
    background: contrast['bg-200'],
    '&:hover': {
      background: contrast['bg-300'],
    },
    '&:active': {
      background: contrast['bg-400'],
    },
    '&:disabled': {
      background: alpha(contrast['bg-200'], 0.5),
      color: alpha(contrast.contrastText, 0.5),
      '.MuiLoadingButton-loadingIndicator': {
        color: contrast.contrastText,
      },
    },
    '&:focus&:not(:active)': buttonFocus(contrast['bg-400']),
  },
};

export const ContrastText = {
  props: {
    color: 'contrast',
    variant: 'text',
  },
  style: {
    borderColor: 'transparent',
    background: 'transparent',
    color: text.inverted,
    '&:hover': {
      background: contrast.hover,
    },
    '&:active': {
      background: contrast.active,
    },
    '&:disabled': {
      background: 'transparent',
      color: alpha(text.inverted, 0.5),
    },
  },
};

export const ContrastOutlined = {
  props: {
    color: 'contrast',
    variant: 'outlined',
  },
  style: {
    borderColor: contrast['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: contrast.hover,
      borderColor: contrast['outline-100'],
    },
    '&:active': {
      background: contrast.active,
      borderColor: contrast.active,
    },
    '&:disabled': {
      borderColor: alpha(contrast['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};
