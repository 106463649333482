/**
 * Functions Page selectors
 */

import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { initialState } from '../reducers/functions';

const functionsSelector = (state) => state.functions || initialState;
const functionListSelector = (state) => state.functions.list || initialState.list;
const functionsLoadingSelector = (state) => state.functions.loading || initialState.loading;
const functionIsNewFormSelector = (state) => state.functions.newForm || initialState.newForm;

const makeFunctionsData = () =>
  createSelector(functionListSelector, (list) => {
    if (list instanceof Array) {
      return list;
    }
    return [];
  });

const makeIsNewFormData = () =>
  createSelector(functionIsNewFormSelector, (newForm) => newForm || false);

const makeSelectLoadingData = () =>
  createSelector(functionsLoadingSelector, (loading) => loading || false);

const makeSelectedFunctionData = () =>
  createSelector(functionsSelector, (state) => {
    const functionList = state.list;
    const { selectedFunctionId } = state;
    const selectedFunction = _.first(_.filter(functionList, { id: +selectedFunctionId }));
    return selectedFunction;
  });

const functionDeletingSelector = (state) => state.functions.functionDeleting;
const functionDeletingSuccessSelector = (state) => state.functions.functionDeletingSuccess;
const functionDeletingErrorSelector = (state) => state.functions.functionDeletingError;
const functionHistorySelector = (state) => state.functions.functionHistory;

const makeFunctionDeletingData = () => createSelector(functionDeletingSelector, (state) => state);

const makeFunctionDeletingSuccessData = () =>
  createSelector(functionDeletingSuccessSelector, (state) => state);

const makeFunctionDeletingErrorData = () =>
  createSelector(functionDeletingErrorSelector, (state) => state);

export {
  makeFunctionsData,
  makeSelectLoadingData,
  makeSelectedFunctionData,
  makeFunctionDeletingData,
  makeFunctionDeletingSuccessData,
  makeFunctionDeletingErrorData,
  makeIsNewFormData,
  functionHistorySelector,
};
