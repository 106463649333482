export const InputAdornment = {
  defaultProps: {},
  styleOverrides: {
    root: ({ ownerState }: { ownerState: any }) => ({
      ...(ownerState.variant === 'filled' && {
        '&.MuiInputAdornment-positionStart&:not(.MuiInputAdornment-hiddenLabel)': {
          marginTop: 0,
        },
      }),
    }),
  },
};
