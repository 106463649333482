import { createSlice } from '@reduxjs/toolkit';
import { BreadCrumbsProps } from './BreadCrumbs.props';
import { updateApplications, updateLinkSiblingsUtil, updateMainLinks } from './breadcrumbsUtils';

const initialState: BreadCrumbsProps = {
  links: [],
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbsState',
  initialState,
  reducers: {
    updateLinks(state, action) {
      updateMainLinks({ state, newList: action.payload });
    },
    updateApplicationsSiblings(state, action) {
      updateApplications({
        state,
        siblings: action.payload.appSiblings,
        pagination: action.payload.pagination,
        hasSearch: action.payload.hasSearch,
      });
    },
    updateAllLinks(state, action) {
      state.links = action.payload;
    },
    updateLinkSiblings(state, action) {
      updateLinkSiblingsUtil({
        state,
        linkIndex: action.payload.linkIndex,
        siblings: action.payload.siblings,
        hasSearch: action.payload.hasSearch,
      });
    },
    cleanState(state, action) {
      const elementsToKeep = action.payload || 1;
      if (elementsToKeep < state.links.length) {
        state.links = state.links.slice(0, elementsToKeep);
      }
    },
  },
});

export const {
  updateLinks,
  updateApplicationsSiblings,
  updateAllLinks,
  updateLinkSiblings,
  cleanState,
} = breadcrumbsSlice.actions;

export const breadcrumbsReducer = breadcrumbsSlice.reducer;
