import { gatewayApi } from '../gatewayApi';

const selectorsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getSelectors: build.query({
      query: () => ({
        url: `selectors`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'Selectors', id: 'LIST' }],
    }),
    getSelector: build.query({
      query: (id) => ({
        url: `selectors/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Selector', id }],
    }),
  }),
});

export const { useGetSelectorsQuery, useGetSelectorQuery } = selectorsApi;
