// Exported for old files
export const defaultCaseStatuses = [
  { value: 'OPEN', label: 'Open', color: 'contrast' },
  { value: 'IN_REVIEW', label: 'Ongoing Investigation', color: 'secondary' },
  { value: 'APPROVED', label: 'Approved', color: 'successLight' },
  { value: 'REJECTED', label: 'Rejected', color: 'warning' },
  { value: 'KYC_REVIEWED', label: 'Reviewed – Expected Activity', color: 'successLight' },
  { value: 'DUPLICATE', label: 'Duplicate', color: 'secondary' },
  { value: 'STR_SAR', label: 'STR/SAR Filed', color: 'secondary' },
  { value: 'FALSE_POSITIVE', label: 'False Positive', color: 'warning' },
];

const applicationCaseStatuses = [
  { value: 'OPEN', label: 'Open', color: 'contrast' },
  { value: 'IN_PROGRESS', label: 'In Progress', color: 'secondary' },
  { value: 'CLOSED', label: 'Closed', color: 'successLight' },
];

export const getCaseStatuses = (caseType) =>
  caseType === 'APPLICATION' ? applicationCaseStatuses : defaultCaseStatuses;
