import React from 'react';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
  Typography,
  TextField,
} from '@mui/material';
import { Close as CloseIcon, Replay as ReplayIcon } from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import {
  useAddCaseCommentsMutation,
  useGetPredefinedCommentsQuery,
  useUpdateCaseCommentMutation,
} from '../../services/gatewayApi/casesApi';
import { toastCreateSuccess } from '../../utils/toast';

/* eslint-disable no-template-curly-in-string */
const CaseCommentsFormDialog = ({ open, onClose, isNew, data }) => {
  const { caseId, commentId, comment = '', bulkList = [] } = data;
  const validationSchema = yup.object({
    comment: yup
      .string()
      .required('Required')
      .min(3, 'Minimum length is ${min}')
      .max(1000, 'Maximum length is ${max}'),
  });

  const { id: commenterId } = useSelector(makeUserData());
  const [addComments] = useAddCaseCommentsMutation();
  const [updateComment] = useUpdateCaseCommentMutation();
  const { data: predefinedComments, isLoading } = useGetPredefinedCommentsQuery();

  const formik = useFormik({
    initialValues: { comment },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const caseIds = bulkList.length ? [...bulkList] : [caseId];
      const mutationPromise = isNew
        ? await addComments({ ...values, commenterId, caseIds })
        : await updateComment({ ...values, caseId, commentId, commenterId });
      if (!mutationPromise.error) {
        toastCreateSuccess('Comments for selected cases');
        formik.setSubmitting(false);
        resetForm();
        onClose();
      }
    },
  });

  const commentsFilter = (item) =>
    item.description.toLowerCase().indexOf(formik.values.comment.toLowerCase()) > -1 ||
    item.comment.toLowerCase().indexOf(formik.values.comment.toLowerCase()) > -1 ||
    item.commentId.toString().toLowerCase().indexOf(formik.values.comment.toLowerCase()) > -1;

  const RenderPredefinedComments = () => {
    if (isLoading) return <LinearProgress />;
    if (!predefinedComments.length && !isLoading) return 'no predefined comments found';
    const list = predefinedComments?.filter(commentsFilter).map((item) => (
      <Grid item key={item.commentId} md={12}>
        <Paper
          sx={{
            padding: 2,
            margin: 2,
            marginLeft: '0px',
            width: '100%',
            '&:hover': {
              backgroundColor: 'lightgreen',
              cursor: 'pointer',
            },
          }}
          onClick={() => formik.setFieldValue('comment', item.description)}
        >
          <Typography variant="body2"> {item.comment} </Typography>
        </Paper>
      </Grid>
    ));
    return <Box> {list} </Box>;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {isNew ? 'Add' : 'Edit'} Comment
        <IconButton onClick={onClose} size="large" variant="closeDialog">
          <CloseIcon />
        </IconButton>
        <Box my={2}>
          <form id="caseCommentForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  id="comment"
                  name="comment"
                  placeholder="Write a comment or choose from the predefined ones below"
                  multiline
                  rows="6"
                  fullWidth
                  autoFocus
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helpertext={formik.touched.comment && formik.errors.comment}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Reset changes" disableFocusListener>
                          <IconButton
                            onClick={() => formik.handleReset({ name: comment })}
                            size="small"
                            disabled={formik.values.comment === comment}
                          >
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear field" disableFocusListener>
                          <IconButton
                            onClick={() => formik.setFieldValue('comment', '')}
                            size="small"
                            disabled={formik.values.comment === ''}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <RenderPredefinedComments />
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="caseCommentForm"
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CaseCommentsFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isNew: PropTypes.bool,
  data: PropTypes.shape({
    caseId: PropTypes.string,
    commentId: PropTypes.string,
    comment: PropTypes.string,
  }),
};

export default CaseCommentsFormDialog;
