import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { useFormik } from 'formik';
import { makeSelectedProductIdData } from '../../selectors/user';
import { useGetMerchantCategoriesQuery } from '../../services/gatewayApi/merchantCategoriesApi';

const MerchantsSearchForm = (props) => {
  const [merchantCategories, setMerchantCategories] = useState([]);
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const { data, isLoading, isFetching } = useGetMerchantCategoriesQuery(
    {
      productId: selectedProductId,
      page: 0,
      size: 9999,
      sort: `categoryName,asc`,
    },
    {
      skip: !selectedProductId,
    },
  );

  useEffect(() => {
    if (data) {
      setMerchantCategories(
        data.results.map((item) => ({
          value: item.categoryName,
          label: item.categoryName,
        })),
      );
    }
  }, [data]);

  const { setFilterValues } = props;
  const initialValues = {
    merchantName: '',
    mid: '',
    categories: '',
    noCategories: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFilterValues(values);
      formik.setSubmitting(false);
    },
  });

  const handleReset = () => {
    formik.resetForm();
    setFilterValues(initialValues);
  };

  return (
    <div className="grid simple horizontal yellow">
      <div className="grid-title">
        <h4>
          <span>Filter Data</span>
        </h4>
      </div>
      <div style={{ position: 'relative' }}>
        <div className="grid-body">
          <Backdrop open={isLoading || isFetching}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form id="filterForm" onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id="merchantName"
                  name="merchantName"
                  label="Merchant Name"
                  fullWidth
                  size="small"
                  value={formik.values.merchantName}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  id="mid"
                  name="mid"
                  label="MID"
                  fullWidth
                  size="small"
                  value={formik.values.mid}
                  onChange={formik.handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Autocomplete
                  id="categories"
                  fullWidth
                  size="small"
                  options={merchantCategories}
                  disabled={formik.values.noCategories}
                  value={
                    formik.values.categories
                      ? merchantCategories.find((c) => c.value === formik.values.categories)
                      : null
                  }
                  onChange={(event, option) => {
                    formik.setFieldValue('categories', option ? option.value : '');
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Merchant Category"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="noCategories"
                      checked={formik.values.noCategories}
                      onChange={(event, value) => {
                        formik.setFieldValue('noCategories', value);
                        formik.setFieldValue('categories', '');
                      }}
                    />
                  }
                  label="Merchants Without Category"
                />
              </Grid>
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  form="filterForm"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <SearchIcon style={{ marginRight: 5 }} />
                  Search
                </Button>
                <Button onClick={handleReset} style={{ marginLeft: 10 }}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

MerchantsSearchForm.propTypes = {
  setFilterValues: PropTypes.func,
};

export default MerchantsSearchForm;
