import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { PageContainer } from 'uikit';
import {
  useAddApplicationMutation,
  useGetCategoriesQuery,
} from 'services/gatewayApi/applicationApi';
import { FormRowInput } from '../FormRowInput';
import {
  sections,
  initialValues,
  createAppFieldsSchema,
  createAppValidationSchema,
} from './constants';

export const AppCreate: React.FC = () => {
  const navigate = useNavigate();
  const [addApplication] = useAddApplicationMutation();
  const { data: categories = [] } = useGetCategoriesQuery(undefined);

  const selectFieldOptions = { categories };

  const validation = createAppValidationSchema();

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values, { setSubmitting }) => {
      const model = {
        // Contact Information
        contactTitle: values.contactTitle,
        contactFirstName: values.contactFirstName,
        contactMiddleName: values.contactMiddleName,
        contactLastName: values.contactLastName,
        contactPosition: values.contactPosition,
        contactEmail: values.contactEmail,

        // Company Information
        companyName: values.companyName,
        businessAddress: values.businessAddress,
        contactPhone: values.contactPhone,
        categoryId: values.categoryId,

        // Relation Information
        salesManager: values.salesManager,
        salesPersonEmail: values.salesPersonEmail,
        referralPartner: values.referralPartner,
        relationshipManagerEmail: values.relationshipManagerEmail,
      };

      addApplication(model)
        .unwrap()
        .then(({ haystackClientId, id: applicationId }) => {
          setSubmitting(false);
          navigate(`/application-management/${haystackClientId}/applications/${applicationId}`);
        })
        .catch((rejected) => console.error(rejected));
    },
  });

  const fields = createAppFieldsSchema({ options: selectFieldOptions });

  return (
    <Box className="AppCreate">
      <Helmet title="Create Application" />

      <PageContainer>
        <Box sx={{ mt: '2rem' }}>
          <Typography variant="h1" py={4}>
            Create Application
          </Typography>
        </Box>

        <Box sx={{ bgcolor: 'white', p: 6, mb: 12, borderRadius: '12px' }}>
          <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
            {sections.map((section) => (
              <Box key={`section_${section.id}`} sx={{ pb: 6 }}>
                <Box sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[100]}` })}>
                  <Typography variant="h2" color="text.secondary" sx={{ pb: 4, fontSize: '18px' }}>
                    {section.title}
                  </Typography>
                </Box>

                {fields
                  .filter((field) => field.sectionId === section.id)
                  .map((field) => (
                    <FormRowInput
                      key={`row_${field.name}`}
                      fieldData={field}
                      onChange={formik.handleChange}
                      value={formik.values[field.name]}
                      error={formik.touched[field.name] && formik.errors[field.name]}
                    />
                  ))}
              </Box>
            ))}

            <Stack direction="row" justifyContent="end" spacing={2} sx={{ mt: 4 }}>
              <Link to="../application-management">
                <Button>Cancel</Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
                  ) : null
                }
                disabled={formik.isSubmitting}
              >
                Create Application
              </Button>
            </Stack>
          </form>
        </Box>
      </PageContainer>
    </Box>
  );
};
