import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Note, NoteHeader } from 'muikit/Note';
import { formatDate } from 'utils/formatter';
import { TIME_FORMAT } from 'constants/constants';
import { decodeFromBase64 } from 'utils/base64';
import MessageQuoteChip from './MessageQuoteChip';
import 'react-quill/dist/quill.snow.css';

const StyledNote = styled(Note)(({ theme }) =>
  theme.unstable_sx({
    px: 0,
    py: 3,
    bgcolor: 'transparent',
    '&:hover': {
      bgcolor: 'grey.50',
      '& .MuiBox-root': {
        '& p': {
          color: 'grey.500',
        },
      },
    },
  }),
);

const Dot = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isRead',
})<{ isRead?: boolean }>(({ isRead, theme }) =>
  theme.unstable_sx({
    position: 'relative',
    left: '10px',
    top: '21px',
    height: 12,
    width: 12,
    borderRadius: '50%',
    display: 'inline-block',
    bgcolor: isRead ? 'grey.300' : 'grey.800',
  }),
);

export type MessageType = {
  id?: string;
  createdDate?: string;
  createdByName?: string;
  content: string;
  isRead?: boolean;
  replyTo?: string;
};

interface MessageProps {
  message: MessageType;
  quote?: string;
  onReplyClick?: (message: MessageType) => void;
  isSendToAll?: boolean;
}

const Message = ({
  message,
  onReplyClick,
  quote,
  isSendToAll = false,
}: MessageProps): JSX.Element => {
  const { createdDate, createdByName, content, isRead } = message;

  return (
    <StyledNote>
      <Dot isRead={isRead} />
      <NoteHeader
        sx={{ mb: 0, pl: 6, alignItems: 'baseline' }}
        metadata={formatDate(createdDate, TIME_FORMAT)}
        metadataTypographyProps={{ sx: { mb: 1, mx: 1.5 } }}
        title={createdByName}
        titleTypographyProps={{ variant: 'subtitle1', sx: { width: 'auto' } }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            pr: 13,
            pl: 19,
            width: '100%', // prevents text cropping
          }}
        >
          {quote && (
            <MessageQuoteChip
              label={decodeFromBase64(quote)
                .replace(/<[^>]+>/g, ' ')
                .trim()}
            />
          )}
          <Box
            className="quill ql-snow ql-snow ql-disabled ql-container"
            sx={{ border: 'none !important' }}
          >
            <Box
              className="ql-editor"
              sx={{ p: 0 }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(decodeFromBase64(content)),
              }}
            />
          </Box>
        </Box>
        {onReplyClick && !isSendToAll && (
          <Typography
            sx={{
              mr: 3,
              alignSelf: 'flex-end',
              color: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => onReplyClick(message)}
          >
            Reply
          </Typography>
        )}
      </Box>
    </StyledNote>
  );
};

export default Message;
