import {
  LOAD_RULES,
  LOAD_RULES_SUCCESS,
  LOAD_RULES_FAIL,
  DELETE_RULESET,
  DELETE_RULESET_SUCCESS,
  DELETE_RULESET_FAIL,
  DELETE_RULESET_RESET,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingListError, toastDeleteError } from '../utils/toast';

const entityTitle = 'Rule';

export const fetchRules = () => (dispatch) => {
  dispatch({
    type: LOAD_RULES,
  });
  ApiService.post('/internal/rules/list')
    .then((response) => {
      dispatch(fetchRulesSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchRulesError(error));
    });
};

const fetchRulesSuccess = (rules) => ({
  type: LOAD_RULES_SUCCESS,
  rules,
});

const fetchRulesError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_RULES_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

export const deleteRulesetReset = () => (dispatch) => {
  dispatch({
    type: DELETE_RULESET_RESET,
  });
};

export const deleteRuleset = (id) => (dispatch) => {
  const postData = { id };
  dispatch({
    type: DELETE_RULESET,
    id,
  });

  ApiService.post('/internal/rules/delete-ruleset', postData)
    .then(() => dispatch(deleteRulesetSuccess(id)))
    .catch((error) => dispatch(deleteRulesetError(error)));
};

const deleteRulesetSuccess = (id) => ({
  type: DELETE_RULESET_SUCCESS,
  id,
});

const deleteRulesetError = (error) => (dispatch) => {
  dispatch({
    type: DELETE_RULESET_FAIL,
    error,
  });
  toastDeleteError(entityTitle, error);
};
