import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { StickyHeader, PageControl, PageContainer } from 'uikit';
import { ClientCategoriesList } from './ClientCategoriesList';
import { ClientCategoryDialog } from './ClientCategoryDialog';
import { Category } from './types';

const initialCategory = {
  createdBy: '',
  createdDate: '',
  id: 'new',
  name: '',
  updatedBy: '',
  updatedDate: '',
};

export const ClientCategories = () => {
  const [category, setCategory] = useState<Category>(initialCategory);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setCategory(initialCategory);
  };

  return (
    <>
      <Helmet title="Client Categories" />
      <PageContainer>
        <StickyHeader>
          <PageControl
            title="Client Categories"
            primaryActionTitle="New Category"
            primaryAction={() => setOpen(true)}
          />
        </StickyHeader>
        <ClientCategoriesList setOpen={setOpen} setCategory={setCategory} />
      </PageContainer>
      {open && <ClientCategoryDialog open={open} category={category} onClose={handleClose} />}
    </>
  );
};
