// Starts request of a file from given requestUrl and downloads or opens in new tab/window
import { getKCHeaders } from '../services/gatewayApi';
import { toastError } from './toast';

export const downloadFile = (url, cfg = {}) => {
  const { method } = cfg || 'GET';
  const { headers } = cfg;
  const { body } = cfg;

  const { fileName } = cfg;
  const { download } = cfg || true;

  const isHaystackUrl = url.includes('/api/');
  return new Promise((resolve, reject) => {
    const request = {
      method,
      ...(body && { body: JSON.stringify(body) }),
      ...(headers ? { headers } : { headers: {} }),
    };
    if (isHaystackUrl) {
      request.headers = getKCHeaders(headers);
    }

    if (request.body && !request.headers['Content-Type'])
      request.headers['Content-Type'] = 'application/json';

    try {
      let responseFileName;
      fetch(url, request)
        .then((response) => {
          if (response.ok) {
            if (!fileName) {
              // Extract filename from header
              const { headers: responseHeaders } = response;
              const contentDisposition = responseHeaders.get('content-disposition');
              if (responseHeaders && contentDisposition) {
                responseFileName = contentDisposition
                  .split(';')
                  .find((n) => n.includes('filename='));
              }
              if (responseFileName) {
                // get fileName as last part of path if name as path
                const parts = responseFileName.split('/');
                const shortFileName = parts[parts.length - 1];
                responseFileName = shortFileName
                  .replace('filename=', '')
                  .replaceAll('"', '')
                  .trim();
              }
            }
            return response.blob();
          }

          toastError('Could not download file, please try again later...');
          throw new Error(`Could not download file from url: ${url}`);
        })
        .then((blob) => {
          const urlBlob = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = urlBlob;

          if (download) {
            a.download = `${fileName || responseFileName || 'temp'}`; // set download file name
          } else {
            a.target = '_blank'; // open in new tab
          }

          document.body.appendChild(a); // append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // remove the temporary element

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const openFileInNewTab = (requestUrl) => downloadFile(requestUrl, { download: false });
