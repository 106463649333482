import {
  SELECT_FUNCTION,
  UNSELECT_FUNCTION,
  SHOW_NEW_FUNCTION_FORM,
  CREATE_FUNCTION,
  CREATE_FUNCTION_SUCCESS,
  CREATE_FUNCTION_FAIL,
  UPDATE_FUNCTION,
  UPDATE_FUNCTION_SUCCESS,
  UPDATE_FUNCTION_FAIL,
  LOAD_FUNCTIONS,
  LOAD_FUNCTIONS_SUCCESS,
  LOAD_FUNCTIONS_FAIL,
  DELETE_FUNCTION,
  DELETE_FUNCTION_SUCCESS,
  DELETE_FUNCTION_FAIL,
  DELETE_FUNCTION_RESET,
  LOAD_FUNCTION_HISTORY,
  LOAD_FUNCTION_HISTORY_SUCCESS,
  LOAD_FUNCTION_HISTORY_FAIL,
} from '../constants/constants';
import ApiService from '../services/apiService';
import {
  toastLoadingError,
  toastLoadingListError,
  toastCreateSuccess,
  toastCreateError,
  toastUpdateSuccess,
  toastUpdateError,
} from '../utils/toast';

const entityTitle = 'Function';

export const fetchRuleFunctions = (filter) => (dispatch) => {
  dispatch({
    type: LOAD_FUNCTIONS,
  });
  ApiService.get('/api/gateway/function', filter)
    .then((response) => dispatch(fetchFunctionsSuccess(response)))
    .catch((error) => dispatch(fetchFunctionsError(error)));
};

const fetchFunctionsSuccess = (functions) => ({
  type: LOAD_FUNCTIONS_SUCCESS,
  functions,
});

const fetchFunctionsError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_FUNCTIONS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

export function selectFunction(functionId) {
  return (dispatch) => {
    dispatch({
      type: SELECT_FUNCTION,
      functionId,
    });
  };
}

export function newFunctionForm() {
  return (dispatch) => {
    dispatch({
      type: SHOW_NEW_FUNCTION_FORM,
    });
  };
}
export function unselectFunction() {
  return (dispatch) => {
    dispatch({
      type: UNSELECT_FUNCTION,
    });
  };
}

export const deleteFunctionReset = () => (dispatch) => {
  dispatch({
    type: DELETE_FUNCTION_RESET,
  });
};

export const deleteFunction = (id) => (dispatch) => {
  dispatch({
    type: DELETE_FUNCTION,
    id,
  });
  ApiService.delete(`/internal/functions/${id}`)
    .then(() => dispatch(deleteFunctionSuccess(id)))
    .catch((error) => dispatch(deleteFunctionError(error)));
};

export function createFunction(model) {
  return (dispatch) => {
    dispatch({ type: CREATE_FUNCTION });
    ApiService.post('/internal/functions', model)
      .then(() => dispatch(createFunctionSuccess()))
      .catch((error) => {
        toastCreateError(entityTitle, error);
        dispatch(createFunctionFail(error));
      });
  };
}

export function createFunctionSuccess() {
  return (dispatch) => {
    dispatch({ type: CREATE_FUNCTION_SUCCESS });
    toastCreateSuccess(entityTitle);
    dispatch(fetchRuleFunctions());
  };
}

const createFunctionFail = (error) => ({
  type: CREATE_FUNCTION_FAIL,
  error,
});

export function updateFunction(model) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FUNCTION });
    const { id } = model.function;
    ApiService.update(`/internal/functions/${id}`, model)
      .then(() => dispatch(updateFunctionSuccess()))
      .catch((error) => {
        toastUpdateError(entityTitle, error);
        dispatch(updateFunctionFail(error));
      });
  };
}

export function updateFunctionSuccess() {
  return (dispatch) => {
    dispatch({ type: UPDATE_FUNCTION_SUCCESS });
    toastUpdateSuccess(entityTitle);
    dispatch(fetchRuleFunctions());
  };
}

const updateFunctionFail = (error) => ({
  type: UPDATE_FUNCTION_FAIL,
  error,
});

const deleteFunctionSuccess = () => (dispatch) => {
  dispatch({ type: DELETE_FUNCTION_SUCCESS });
  dispatch(fetchRuleFunctions());
};

const deleteFunctionError = (error) => ({
  type: DELETE_FUNCTION_FAIL,
  error,
});

export const fetchFunctionHistory = (id) => (dispatch) => {
  dispatch({
    type: LOAD_FUNCTION_HISTORY,
  });
  ApiService.get(`/internal/function/history/${id}`)
    .then((response) => dispatch(fetchFunctionHistorySuccess(response)))
    .catch((error) => dispatch(fetchFunctionHistoryFail(error)));
};

const fetchFunctionHistorySuccess = (functionHistory) => ({
  type: LOAD_FUNCTION_HISTORY_SUCCESS,
  functionHistory,
});

const fetchFunctionHistoryFail = (error) => (dispatch) => {
  dispatch({
    type: LOAD_FUNCTION_HISTORY_FAIL,
    error,
  });
  toastLoadingError(`${entityTitle} History`, error);
};
