import { inputBaseClasses } from '@mui/material';
import { baseTheme } from '../baseTheme';
import { grey } from '../palette-blocks';

export const FilledInput = {
  defaultProps: {
    disableUnderline: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }: { theme: any; ownerState: any }) => ({
      ...(ownerState.multiline && {
        padding: 0,
        ...(ownerState.size === 'small' && {
          padding: 0,
        }),
      }),
      backgroundColor: grey[100],
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',

      '&:hover': {
        backgroundColor: grey[100],
      },
      '&.Mui-focused': {
        borderColor: grey[900],
        backgroundColor: baseTheme.palette.common.white,
      },
      '&.Mui-error': {
        backgroundColor: baseTheme.palette.error.light,
        borderColor: baseTheme.palette.error.main,
      },
    }),

    input: ({ theme, ownerState }: { theme: any; ownerState: any }) => {
      const light = theme.palette.mode === 'light';
      const placeholderVisible = {
        opacity: light ? '0.42 !important' : '0.5 !important',
      };
      return {
        padding: '14px 12px',
        [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
          '&::-webkit-input-placeholder': placeholderVisible,
          '&::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:-ms-input-placeholder': placeholderVisible, // IE11
          '&::-ms-input-placeholder': placeholderVisible, // Edge
        },
      };
    },
  },
};
