import { formsApi } from '@haystack-forms/api';
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { gatewayApi } from './gatewayApi';

export const sectionStatusListenerMiddleware = createListenerMiddleware();
// Update data on screen with sections on section status update
// basically links updateApplicationSectionStatus from hs-forms api and getApplicationForms
// TODO: remove after switching to 'new' forms version and refactoring (probably never as always)
sectionStatusListenerMiddleware.startListening({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  matcher: isAnyOf(formsApi.endpoints.updateApplicationSectionStatus.matchFulfilled),
  effect: (action, { dispatch, getState }) => {
    dispatch(gatewayApi.util.invalidateTags(['ApplicationForms']));
  },
});
