import React, { useCallback, useEffect, useState, useRef, createRef } from 'react';
import { debounce } from 'lodash';
import { Cancel as CancelIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useOnClickOutside } from 'hooks';
import { EmbedDropdown, EmbedDropdownNode } from '../EmbedDropdown';
import { Input } from '../Input';
import { InputBox } from '../InputBox';

import { ISelectOption } from './SelectTypes';
import './Select.scss';

interface SelectProps {
  name?: string;
  variant?: string;
  value?: ISelectOption;
  options: ISelectOption[];
  placeholder?: string;
  onChange?: (option: ISelectOption, e?: any) => void;
  onBlur?: any;
  disabled?: boolean;
  selectBox?: boolean;
  showOnFocus?: boolean;
  label?: string;
  hasError?: boolean;
  hasClear?: boolean;
  borderless?: boolean;
}

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      name,
      variant,
      options,
      placeholder,
      label,
      disabled,
      selectBox = false,
      showOnFocus = false,
      onBlur,
      hasError,
      hasClear = true,
      value,
      onChange,
      borderless = false,
    },
    ref,
  ) => {
    const [isShowing, setIsShowing] = useState(false); // show or hide dropdown
    const [focusedIndex, setFocusedIndex] = useState(-1); // cursor for focused option
    const [searchValue, setSearchValue] = useState(''); // searched option
    const refSelect = useRef(); // reference to element
    const [refItems, setRefItems] = useState([]); // reference to items

    const resetSelect = () => {
      onChange(null);
    };

    useOnClickOutside(
      [refSelect],
      () => {
        setIsShowing(false);
      },
      true,
    );

    const toggleShowing = () => {
      setIsShowing((prev) => !prev);
    };

    const keyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
      // TODO: Should scroll for active option.
      const { key } = e;

      if (key === 'Tab') {
        setIsShowing(false);
        onBlur && onBlur();
      }

      if (key !== 'Tab') {
        e.preventDefault();
        e.stopPropagation();
      }

      if (key === 'Escape') {
        toggleShowing();
      }

      if (key === 'ArrowDown') {
        if (!isShowing) {
          setIsShowing(true);
        }

        if (focusedIndex === -1 || focusedIndex === options?.length - 1) {
          setFocusedIndex(0);
        } else {
          setFocusedIndex(focusedIndex + 1);
        }
      }

      if (key === 'ArrowUp') {
        if (focusedIndex === -1 || focusedIndex === 0) {
          setFocusedIndex(options?.length - 1);
        } else {
          setFocusedIndex(focusedIndex - 1);
        }
      }

      if (key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();

        if (isShowing) {
          optionKeyEnterHandler(options[focusedIndex], e);
          setIsShowing(false);
        } else {
          setIsShowing(true);
        }
      }

      // Search items functionality
      setSearchValue(searchValue + key.toLowerCase());
      delayedClearSearchValue();
    };

    // clear searched value after 1 sec
    const delayedClearSearchValue = useCallback(
      debounce(() => setSearchValue(''), 1000),
      [],
    );

    // clear searched value after 1 sec
    useEffect(() => {
      if (options && searchValue?.length) {
        // search by startsWith first and if nothing is found search by includes
        const item =
          options.find((c) => c.label.toLowerCase().startsWith(searchValue)) ||
          options.find((c) => c.label.toLowerCase().includes(searchValue));
        const index = options.indexOf(item);
        if (index !== -1) {
          setFocusedIndex(index);
        }
      }
    }, [options, searchValue]);

    // prepare refs for items
    useEffect(() => {
      setRefItems((item) =>
        Array(options?.length)
          .fill(null)
          .map((_, i) => item[i] || createRef()),
      );
    }, [options?.length]);

    // scroll to item on index change
    useEffect(() => {
      let item = refItems[0]?.current;

      if (focusedIndex !== -1) {
        item = refItems[focusedIndex].current;
      }

      if (item) {
        item.scrollIntoView();
      }
    }, [focusedIndex, refItems]);

    // reset index on close
    useEffect(() => {
      if (!isShowing) {
        setFocusedIndex(-1);
      }
    }, [isShowing]);

    const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      // TODO: find better solution.
      // setTimeout(() => {setIsShowing(false)}, 400);
      // onBlur && onBlur();
    };

    const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      // TODO: Find better solution. Shouldn't conflict with click handler
      if (showOnFocus) {
        setTimeout(() => {
          setIsShowing(true);
        }, 100);
      }
    };

    const optionKeyEnterHandler = (
      option: ISelectOption,
      e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      onChange(option, e);
    };

    const optionClickHandler = (e: React.MouseEvent<HTMLDivElement>, option: ISelectOption) => {
      const currentOption = options.indexOf(option);
      setFocusedIndex(currentOption);
      onChange(option, e);
    };

    const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
      toggleShowing();
    };

    return (
      <div
        ref={refSelect}
        className={`baseSelect ${isShowing ? 'baseSelect--show' : 'baseSelect--hide'}`}
      >
        {!selectBox && (
          <Input
            name={name}
            variant={variant}
            disabled={disabled}
            placeholder={placeholder}
            readOnly
            onFocus={focusHandler}
            onBlur={blurHandler}
            onClick={handleInputClick}
            onKeyDown={keyHandler}
            value={value?.label || value?.value || ''}
            hasError={hasError}
          />
        )}

        {selectBox && (
          <InputBox
            label={label}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            readOnly
            onFocus={focusHandler}
            onBlur={blurHandler}
            onClick={handleInputClick}
            onKeyDown={keyHandler}
            value={value?.label || value?.value || ''}
            hasError={hasError}
            borderless={borderless}
            ref={ref}
          />
        )}

        {value && !disabled && hasClear && (
          <div className="clearContainer" onClick={resetSelect}>
            <CancelIcon style={{ fontSize: '20px' }} />
          </div>
        )}

        {!(value && hasClear) && !disabled && (
          <div className="iconContainer" onClick={toggleShowing}>
            <ArrowDropDownIcon style={{ fontSize: '20px' }} />
          </div>
        )}

        <EmbedDropdown priority isShowing={isShowing} hide={() => setIsShowing(false)}>
          {options &&
            options.map((option: ISelectOption, i: number) => (
              <div
                ref={refItems[i]}
                key={i}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => optionClickHandler(e, option)}
              >
                <EmbedDropdownNode active={i === focusedIndex}>
                  {option.label || option.value}
                </EmbedDropdownNode>
              </div>
            ))}
        </EmbedDropdown>
      </div>
    );
  },
);

export default Select;
