import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { DataTableRow } from '../../../components';
import { DATETIMESEC_FORMAT_MOMENT } from '../../../constants/constants';

export class ValidatorTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAction: '',
      isUpdating: false,
    };
  }

  render() {
    const { item, index, handleOpenDetails } = this.props;
    return (
      <DataTableRow>
        <td>{moment(item.actionDateTime).format(DATETIMESEC_FORMAT_MOMENT)}</td>
        <td>{item.externalId}</td>
        <td>{item.referenceId}</td>
        <td>
          <span className={`label label-${item.resultAction == 'PASS' ? 'success' : 'danger'}`}>
            {item.resultAction}
          </span>
        </td>
        <td>{item.transactionUuid}</td>
        <td>
          <a
            className="primary"
            onClick={() => {
              handleOpenDetails(index, item.validatorTransactionId);
            }}
            title="Card Info"
          >
            <i className="fa fa-info-circle" />
          </a>
        </td>
      </DataTableRow>
    );
  }
}

ValidatorTransaction.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  handleOpenDetails: PropTypes.func,
};
