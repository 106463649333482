import React from 'react';
import 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { convertToDefEventParam } from 'utils/inputUtils';
import { DATE_FORMAT } from '../../constants/constants';

export default function DatePicker({ name, label, size, helperText, error, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        autoOk
        size={size}
        inputFormat={DATE_FORMAT}
        value={value}
        onChange={(date) => onChange(convertToDefEventParam(name, date))}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            fullWidth
            name={name}
            label={label}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func,
};
