import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { HeadLink } from 'muikit';
import { Spinner } from 'uikit';

import { useGetOpenpaydUsersQuery } from 'services/gatewayApi/usersApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';

import { UserCard } from './UserCard';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';

export const OpenpaydUsers = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { onboardingApplicationId, isLoadingApplication } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const { data: users = [], isLoading } = useGetOpenpaydUsersQuery(applicationId, {
    skip: !applicationId,
  });
  const { countries = [] } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: data?.map((el) => ({
        value: el.countryCode,
        label: el.name,
      })),
    }),
  });
  const getCountryLabel = (countryCode) => {
    const country = countries?.find((c) => c.value === countryCode);
    return country ? country.label : countryCode;
  };

  return (
    <Box className="OpenpaydUsersTab">
      <HeadLink title="OpenPayd Users" />

      <Box sx={{ mt: 4 }}>
        {isLoading || users.length < 1 ? (
          <>
            {isLoading ? (
              <div className="app-details-preloader app-details-preloader--small">
                <Spinner />
              </div>
            ) : (
              <Box mt={8}>
                <Typography variant="h1" color="grey.400">
                  Users not found.
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box sx={{ my: 2, width: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {users.map((user) => (
              <UserCard key={user.id} user={user} getCountryLabel={getCountryLabel} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
