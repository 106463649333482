import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Box, Grid, Typography } from '@mui/material';
import { HeadLink } from 'muikit';
import { SortByBlock, Input, Spinner } from 'uikit';
import { RiAddCircleLine } from 'react-icons/ri';
import {
  useGetShareholdersQuery,
  useRemoveShareholderMutation,
  ApplicationShareholder,
} from 'services/gatewayApi/shareholdersApi';
import { SortParam } from 'types/types';
import { emptyShareholder, sortOptions } from './types';
import { Shareholder } from './Shareholder';
import { ShareholderDialog } from './ShareholderDialog';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';

const defaultSorting: SortParam = {
  property: 'createdDate',
  direction: 'desc',
};

export const ShareholdersTab = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { onboardingApplicationId, isLoadingApplication } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  // Shows preloader before the requests
  const [isPreloading, setIsPreloading] = useState<boolean>(true);
  // These two are used for add shareholders in optimistic way
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [shareholderToAdd, setShareholderToAdd] = useState<ApplicationShareholder>(null);

  const [searchString, setSearchString] = useState<string>('');
  const [sortedBy, setSortedBy] = useState<SortParam>(defaultSorting);
  const [shareholderDialogOpen, setShareholderDialogOpen] = useState<boolean>(false);

  const {
    shareholders = null,
    isShareholdersLoading,
    isShareholdersFetching,
  } = useGetShareholdersQuery(
    {
      applicationId,
      sort: sortedBy,
    },
    {
      skip: !applicationId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        shareholders: data,
        isShareholdersLoading: isLoading,
        isShareholdersFetching: isFetching,
      }),
    },
  );

  const [removeShareholder] = useRemoveShareholderMutation();

  const remove = (id: string) => removeShareholder({ applicationId, shareholderId: id });

  // Switch preloaders control to the Query
  useEffect(() => setIsPreloading(false), [isShareholdersLoading]);

  // Hide shareholder template when adding shareholder in optimistic way
  useEffect(() => {
    isAdding && !isShareholdersFetching && setIsAdding(false);
  }, [isShareholdersFetching]);

  // Listen status changing in the ShareholderDialog
  const changeStatus = (status: string, data) => {
    setIsAdding(true);
    setShareholderToAdd(data);
    setSortedBy(defaultSorting);
  };

  return (
    <Box className="ShareholdersTab">
      <HeadLink
        title="Shareholders"
        actions={[
          <Button
            key="Btn_AddLink"
            color="primary"
            variant="contained"
            startIcon={<RiAddCircleLine size="14px" />}
            onClick={(e) => {
              e.stopPropagation();
              setShareholderDialogOpen(true);
            }}
          >
            Add Shareholder
          </Button>,
        ]}
      />

      <Box sx={{ mt: 4 }}>
        {/* SORT, ADD AND SEARCH */}
        <Grid container spacing={2}>
          <Grid item xs={4} md={4} lg={4}>
            <Box style={{ display: 'inline-flex' }}>
              <SortByBlock
                sortOptions={sortOptions}
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
              />
            </Box>
          </Grid>

          <Grid item xs={8} md={8} lg={8}>
            <Box style={{ width: '100%' }}>
              <Input
                placeholder="Find shareholder by company name, trade name or id"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>

        {/* ADD LOADER */}
        {isAdding && applicationId && (
          <Box sx={{ marginTop: '12px', marginBottom: '-12px' }}>
            <Box className="optimistic-loader-block">
              <Shareholder
                applicationId={applicationId}
                shareholder={shareholderToAdd}
                remove={remove}
              />
            </Box>
          </Box>
        )}

        {/* INITIAL PRELOADER */}
        {isPreloading ||
          (isShareholdersLoading && (
            <Box className="app-details-preloader app-details-preloader--small">
              <Spinner />
            </Box>
          ))}

        {shareholders?.content?.length === 0 && (
          <Typography variant="h1" color="grey.400" sx={{ mt: 8 }}>
            Shareholders not found.
          </Typography>
        )}

        {/* SHAREHOLDER LIST */}
        {applicationId && !isPreloading && !isShareholdersLoading && (
          <Box my={3}>
            {!isPreloading &&
              !isShareholdersLoading &&
              shareholders?.content?.map((shareholder, i) => {
                const normalizedSearchString = searchString.toLowerCase();
                if (
                  shareholder.companyName.toLowerCase().includes(normalizedSearchString) ||
                  shareholder.tradeName.toLowerCase().includes(normalizedSearchString) ||
                  shareholder.id.toLowerCase().includes(normalizedSearchString)
                ) {
                  return (
                    <Box key={i} className="shareholder">
                      <Shareholder
                        applicationId={applicationId}
                        shareholder={shareholder}
                        remove={remove}
                      />
                    </Box>
                  );
                }
              })}
          </Box>
        )}

        {/* ADD/EDIT SHAREHOLDER DIALOG */}
        {shareholderDialogOpen && applicationId && (
          <ShareholderDialog
            open={shareholderDialogOpen}
            onClose={() => setShareholderDialogOpen(false)}
            shareholderData={emptyShareholder}
            applicationId={applicationId}
            onChangeStatus={changeStatus}
          />
        )}
      </Box>
    </Box>
  );
};
