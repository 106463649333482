import React from 'react';
import { Box, Grid } from '@mui/material';

import './ServiceContainer.scss';

interface ServiceContainerProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  children: React.ReactNode,
}

export const ServiceContainer = ({
  onClick,
  children,
}: ServiceContainerProps): JSX.Element => (
  <Grid
    className={`ServiceContainer
        ${onClick ? 'ServiceContainer-Hover' : ''}`}
    item
    lg={3}
    md={6}
    sm={6}
    xs={12}
  >
    <Box
      className="application-link"
      style={onClick ? { cursor: 'pointer' } : {}}
      onClick={onClick}
    >
      {children}
    </Box>
  </Grid>
);
