import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { add, parseISO } from 'date-fns';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import {
  useAddExceptionRuleMutation,
  useUpdateExceptionRuleMutation,
} from '../../services/gatewayApi/exceptionRulesApi';
import {
  useGetDescriptionsQuery,
  useAddDescriptionMutation,
  useUpdateDescriptionMutation,
} from 'services/gatewayApi/exceptionRuleDescriptionsApi';
import { checkProductApiName, testIbanChecksum } from '../../utils';
import { ModalDialog, DatePicker } from '../../uikit';
import { resultActions, ruleTypes } from './constants';
import {
  BIN_REGEX,
  CARD_REGEX,
  IBAN_REGEX,
  IP_REGEX,
  MASKED_NUMBER_REGEX,
  RULE_NAME_REGEX,
} from '../../constants/constants';
import { countries } from '../../constants/countries';

interface ExceptionRuleData {
  id: string; // "ab82e83e-4aeb-470a-bdd8-e8e6829476bf"
  exceptionRuleType: string; // "DOUBLE_IBAN"
  referenceData: string; // "EG800002000156789012345180002"
  additionalReferenceData?: string; // "BG18RZBB91550123456789"
  resultAction: 'PASS' | 'REJECT';
  description: string;
  productId?: number; // 3
  comment?: string;
  expiryDateTime?: string; // "2022-07-29T21:00:00"
  createdBy?: string;
  createdDateTime?: string; // "22-07-2022 14:31"
  merchantName?: string;
  merchantId?: string;
  referenceDataList?: any;
}

interface ExceptionRuleDialogProps {
  open: boolean;
  onClose: any;
  data: ExceptionRuleData;
}

export const ExceptionRuleDialog: React.FC<ExceptionRuleDialogProps> = ({
  open,
  onClose,
  data,
}) => {
  const {
    id,
    merchantName,
    merchantId,
    comment,
    description,
    exceptionRuleType,
    resultAction,
    additionalReferenceData,
    referenceDataList,
  } = data;

  const referenceData = exceptionRuleType === 'PAN' ? '' : data.referenceData;
  const isNew = id === 'new';
  const expiryDateTime = () => {
    // @ts-ignore
    if (data.expiryDateTime === undefined || data.expiryDateTime === null) {
      return null;
    }
    return parseISO(data.expiryDateTime);
  };

  const tomorrow = add(new Date(), { days: 1 });

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const [selectedReferenceData, setSelectedReferenceData] = useState('');

  const [addExceptionRule] = useAddExceptionRuleMutation();
  const [updateExceptionRule] = useUpdateExceptionRuleMutation();

  const [addRuleDescription] = useAddDescriptionMutation();
  const [updateRuleDescription] = useUpdateDescriptionMutation();
  const { data: descriptions, isLoading: isLoadingDescriptions } = useGetDescriptionsQuery(undefined);

  const currentRuleTypes =
    referenceDataList || ruleTypes.filter((el) => checkProductApiName(el.product)).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

  const initialValuesEditForm = {
    comment: comment || '',
    description,
    exceptionRuleType,
    expiryDateTime: expiryDateTime(),
    referenceData,
    additionalReferenceData,
    resultAction,
  };

  const validationSchema = yup.object({
    exceptionRuleType: yup.string().required('Required'),
    referenceData: yup
      .string()
      .required('Required')
      .when('exceptionRuleType', (el, schema) => {
        switch (el) {
          case 'EMAIL':
            return schema
              .email('Please use a valid email address.')
              .required('Email address is required.');
          case 'IP_ADDRESS':
            return schema
              .matches(IP_REGEX, 'The correct IP address is required')
              .required('IP address is required.');
          case 'PAN':
            return schema
              .test(
                'PAN',
                'The correct card number is required',
                (value) => !!value?.match(MASKED_NUMBER_REGEX) || !!value?.match(CARD_REGEX),
              )
              .required('PAN is required.');
          case 'BIN':
            return schema
              .matches(BIN_REGEX, 'The correct BIN is required')
              .required('BIN is required.');
          case 'COUNTRY':
          case 'BIN_COUNTRY':
          case 'SENDER_COUNTRY':
          case 'BENEFICIARY_COUNTRY':
          case 'BILLING_COUNTRY':
            return schema.required('Select the country');
          case 'FULL_NAME':
            return schema
              .matches(RULE_NAME_REGEX, 'Text format is required')
              .required('Full Name is required');
          case 'DEVICE_ID':
            return schema
              .matches(RULE_NAME_REGEX, 'ID format is required')
              .required('Device ID is required');
          case 'SENDER_IBAN':
          case 'BENEFICIARY_IBAN':
          case 'SENDER_BENEFICIARY_IBAN':
          case 'DOUBLE_IBAN':
            return schema
              .required('Required')
              .min(5, 'Minimum length is ${min}')
              .max(35, 'Maximum length is ${max}')
              .matches(IBAN_REGEX, 'IBAN is not in correct format')
              .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value));
          case 'ACCOUNT_BENEFICIARY':
          case 'ACCOUNT_SENDER':
          case 'BIC_CODE_BENEFICIARY':
          case 'BIC_CODE_SENDER':
          case 'SORT_CODE_BENEFICIARY':
          case 'SORT_CODE_SENDER':
          case 'CLIENT_ID':
            return schema.required('Required');
          default:
            return schema;
        }
      }),
    additionalReferenceData: yup.string().when('exceptionRuleType', {
      is: 'DOUBLE_IBAN',
      then: (schema) =>
        schema
          .required('Required')
          .min(5, 'Minimum length is ${min}')
          .max(35, 'Maximum length is ${max}')
          .matches(IBAN_REGEX, 'IBAN is not in correct format')
          .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value)),
      otherwise: (schema) => schema.notRequired(),
    }),
    expiryDateTime: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(tomorrow, 'Date must be later than today'),
    resultAction: yup.string().required('Required'),
    description: yup.string().required('Required').matches(RULE_NAME_REGEX),
    comment: yup.string().matches(RULE_NAME_REGEX, 'Only text and numbers, please'),
  });

  const formikEditForm = useFormik({
    initialValues: initialValuesEditForm,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Save description
      const { description, comment } = values;
      const match = descriptions?.find((d) => d.description === description);
      if (!match) {
        await addRuleDescription({ description, comment });
      } else if (comment !== match.comment) {
        await updateRuleDescription({ id: match.id, description, comment });
      }

      // Save rule exception
      if (isNew) {
        const model = {
          ...values,
          productId: selectedProductId,
          merchantId,
        };
        await addExceptionRule(model);
      } else {
        const model = {
          ...values,
          productId: selectedProductId,
          merchantName,
          id,
        };
        await updateExceptionRule(model);
      }
      resetForm();
      onClose();
    },
  });

  const handleSelectType = (type) => {
    if (data.referenceDataList) {
      let { reference } = data.referenceDataList.find((el) => el.value === type) || '';
      if (reference === null) reference = '';
      setSelectedReferenceData(reference);
      formikEditForm.setFieldValue('referenceData', reference);
    }
  };

  const getRuleType = (value) => {
    switch (value) {
      case 'DOUBLE_IBAN':
        return 'IBAN 1';

      case '':
        return 'Please select a rule type';

      default:
        const ruleType = currentRuleTypes.find((el) => el.value === value);
        return ruleType?.label ?? `Undefined rule type: ${value}`;
    }
  };

  const isCountryType = [
    'COUNTRY',
    'BIN_COUNTRY',
    'SENDER_COUNTRY',
    'BENEFICIARY_COUNTRY',
  ].includes(formikEditForm.values.exceptionRuleType);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={isNew ? 'Create Exception Rule' : 'Edit Exception Rule'}
      handleAction={formikEditForm.handleSubmit}
      actionTitle={isNew ? 'Create' : 'Save'}
      isLoading={formikEditForm.isSubmitting}
    >
      <Stack direction="column" spacing={4} sx={{ pt: 2 }}>
        <Autocomplete
          id="exceptionRuleType"
          fullWidth
          size="small"
          options={currentRuleTypes}
          getOptionLabel={(option) => option.label}
          autoHighlight
          clearOnEscape
          disabled={!isNew}
          value={
            formikEditForm.values.exceptionRuleType
              ? currentRuleTypes.find((c) => c.value === formikEditForm.values.exceptionRuleType)
              : null
          }
          onChange={(event, option) => {
            formikEditForm.setFieldValue('exceptionRuleType', option ? option.value : '');
            handleSelectType(option.value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rule Type"
              variant="outlined"
              error={
                formikEditForm.touched.exceptionRuleType &&
                Boolean(formikEditForm.errors.exceptionRuleType)
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        {data.referenceDataList ? (
          <TextField
            disabled
            label={getRuleType(formikEditForm.values.exceptionRuleType)}
            name="referenceData"
            variant="outlined"
            size="small"
            fullWidth
            error={
              formikEditForm.touched.referenceData && Boolean(formikEditForm.errors.referenceData)
            }
            helperText={formikEditForm.touched.referenceData && formikEditForm.errors.referenceData}
            value={
              formikEditForm.values.exceptionRuleType === 'PAN'
                ? formikEditForm.values.referenceData
                : selectedReferenceData
            }
            onChange={formikEditForm.handleChange}
          />
        ) : (
          <>
            {!isCountryType ? (
              <>
                <TextField
                  disabled={formikEditForm.values.exceptionRuleType === ''}
                  label={getRuleType(formikEditForm.values.exceptionRuleType)}
                  name="referenceData"
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={
                    formikEditForm.touched.referenceData &&
                    Boolean(formikEditForm.errors.referenceData)
                  }
                  helperText={
                    formikEditForm.touched.referenceData && formikEditForm.errors.referenceData
                  }
                  value={formikEditForm.values.referenceData}
                  onChange={formikEditForm.handleChange}
                />
                {formikEditForm.values.exceptionRuleType === 'DOUBLE_IBAN' && (
                  <TextField
                    sx={{ mt: 4 }}
                    label="IBAN 2"
                    name="additionalReferenceData"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={
                      formikEditForm.touched.additionalReferenceData &&
                      Boolean(formikEditForm.errors.additionalReferenceData)
                    }
                    helperText={
                      formikEditForm.touched.additionalReferenceData &&
                      formikEditForm.errors.additionalReferenceData
                    }
                    value={formikEditForm.values.additionalReferenceData}
                    onChange={formikEditForm.handleChange}
                  />
                )}
              </>
            ) : (
              <Autocomplete
                id="referenceData"
                fullWidth
                size="small"
                options={countries}
                getOptionLabel={(option) => `${option.label} (${option.value})`}
                autoHighlight
                clearOnEscape
                value={
                  formikEditForm.values.referenceData
                    ? countries.find((c) => c.value === formikEditForm.values.referenceData)
                    : null
                }
                onChange={(event, option) => {
                  formikEditForm.setFieldValue(
                    'referenceData',
                    // @ts-ignore
                    option ? option.value : '',
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    variant="outlined"
                    error={
                      formikEditForm.touched.referenceData &&
                      Boolean(formikEditForm.errors.referenceData)
                    }
                    helperText={
                      formikEditForm.touched.referenceData && formikEditForm.errors.referenceData
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
          </>
        )}
        <Autocomplete
          id="resultAction"
          fullWidth
          size="small"
          options={resultActions}
          getOptionLabel={(option) => option.label}
          autoHighlight
          clearOnEscape
          value={
            formikEditForm.values.resultAction
              ? resultActions.find((c) => c.value === formikEditForm.values.resultAction)
              : null
          }
          onChange={(event, option) => {
            formikEditForm.setFieldValue(
              'resultAction',
              // @ts-ignore
              option ? option.value : '',
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Result Action"
              variant="outlined"
              error={
                formikEditForm.touched.resultAction && Boolean(formikEditForm.errors.resultAction)
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <DatePicker
          name="expiryDateTime"
          label="Rule Expiry"
          error={
            formikEditForm.touched.expiryDateTime && Boolean(formikEditForm.errors.expiryDateTime)
          }
          // @ts-ignore
          helperText={formikEditForm.touched.expiryDateTime && formikEditForm.errors.expiryDateTime}
          value={formikEditForm.values.expiryDateTime}
          onChange={formikEditForm.handleChange}
        />
        <Autocomplete
          freeSolo
          value={formikEditForm.values.description}
          options={descriptions?.map((d) => d.description)}
          filterOptions={(options, { inputValue }) =>
            options.filter((o) => o.includes(inputValue)).slice(0, 5) // limit options to 5
          }
          onChange={(event, option) => {
            const match = descriptions?.find((d) => d.description === option);
            if (match) {
              formikEditForm.setFieldValue('description', match.description);
              formikEditForm.setFieldValue('comment', match.comment);
            }
          }}
          disabled={isLoadingDescriptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label="Description"
              multiline
              onBlur={(event) => {
                formikEditForm.setFieldValue('description', event.target.value);
              }}
              error={formikEditForm.touched.description && Boolean(formikEditForm.errors.description)}
              helperText={formikEditForm.touched.description && formikEditForm.errors.description}
            />
          )}
        />
        <TextField
          label="Comment"
          name="comment"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          value={formikEditForm.values.comment}
          onChange={formikEditForm.handleChange}
          error={formikEditForm.touched.comment && Boolean(formikEditForm.errors.comment)}
          helperText={formikEditForm.touched.comment && formikEditForm.errors.comment}
        />
        <Box>
          <Typography component="span" variant="body2" color="grey.600">
            Merchant:
          </Typography>
          <Typography component="span" variant="body2" sx={{ pl: 4 }}>
            {merchantName || 'not linked'}
          </Typography>
        </Box>
        <Box>
          <Typography component="span" variant="body2" color="grey.600">
            Rule ID:
          </Typography>
          <Typography component="span" variant="body2" sx={{ pl: 4 }}>
            {id}
          </Typography>
        </Box>
        {formikEditForm.values.exceptionRuleType === 'PAN' &&
          (additionalReferenceData || data.referenceDataList) && (
            <Box>
              <Typography component="span" variant="body2" color="grey.600">
                Masked Card Number:
              </Typography>
              <Typography component="span" variant="body2" sx={{ pl: 4 }}>
                {data.referenceDataList
                  ? data.referenceDataList[0].reference
                  : additionalReferenceData}
              </Typography>
            </Box>
          )}
      </Stack>
    </ModalDialog>
  );
};
