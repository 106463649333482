import React from 'react';
import { parseISO, toDate } from 'date-fns';
import { MenuItem, MenuItemProps } from '@mui/material';
import { ApplicationDocument } from 'services/gatewayApi/applicationDocumentsApi';
import { Item } from 'muikit/Menu/Menu';

const filterItems = [
  { label: 'all', action: (docs: Array<ApplicationDocument>) => docs },
  {
    label: 'not rejected',
    action: (docs: Array<ApplicationDocument>) =>
      docs.filter((doc) => doc.fileSize && !doc.isRejected),
  },
  {
    label: 'not expired',
    action: (docs: Array<ApplicationDocument>) =>
      docs.filter((doc) =>
        doc.expiryDate ? toDate(parseISO(doc.expiryDate)) > new Date() : doc.fileSize && true,
      ),
  },
  {
    label: 'expired only',
    action: (docs: Array<ApplicationDocument>) =>
      docs.filter((doc) =>
        doc.fileSize && doc.expiryDate ? toDate(parseISO(doc.expiryDate)) < new Date() : false,
      ),
  },
];

export const initialFilterItem = filterItems[0];

interface FilterMenuItemsProps extends MenuItemProps {
  handleSelect: (item: Item) => any;
}

export const FilterMenuItems = (props: FilterMenuItemsProps): JSX.Element => {
  const { handleSelect, onClick, ...other } = props;
  return (
    <>
      {filterItems.map((item, i) => (
        <MenuItem
          key={item.label}
          onClick={(e) => {
            // eslint-disable-next-line react/prop-types
            onClick && onClick(e);
            handleSelect(filterItems[i]);
          }}
          {...other}
        >
          {filterItems[i].label}
        </MenuItem>
      ))}
    </>
  );
};
