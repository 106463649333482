import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import {
  DragHandle as DragHandleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ContentCopy as ContentCopyIcon,
  AddCircle as AddCircleIcon,
  Restore as RestoreIcon,
} from '@mui/icons-material';
import { EditableTypography, ModalDialog, SwitchMacStyled } from 'uikit';
import { grey, error, primary, warning } from 'theme/palette-blocks';
import { TOOLTIP_DELAY } from 'constants/constants';
import {
  removeRuleById,
  addCopy,
  setFieldValue,
  addRuleAfterId,
  setMarkForDelete,
  setIsActiveDeep,
  restoreRule,
} from '../rulesSlice';
import './ruleRow.scss';
import { GetLamp } from './GetLamp';
import { RuleSortable } from '../types';
import { hoverBgColor, isNewregex } from '../constatns';

interface RuleRowProps {
  rule: RuleSortable;
  savedRule: RuleSortable;
  isLocked: boolean;
  connectDragSource: any;
  canDrag: any;
  nestedLevel: number;
  isDragging?: boolean;
  didDrop?: boolean;
  allowAnimate?: boolean;
  setIsShowInactive?: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchMatch?: boolean;
  isSearchFocus?: boolean;
}

export const RuleRow: React.FC<RuleRowProps> = ({
  rule,
  savedRule,
  isLocked,
  connectDragSource,
  canDrag,
  nestedLevel,
  isDragging,
  didDrop,
  allowAnimate = false,
  setIsShowInactive,
  isSearchMatch,
  isSearchFocus,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ruleId = rule.id.toString();
  const {
    isEdited = false,
    isForDelete = false,
    isParentForDelete = false,
    isActive = true,
    title = '',
    answer,
    score,
    isMoved = false,
  } = rule;
  const isGroup = rule.children?.length > 0 || false;
  const isNew = isNewregex.test(ruleId);

  const [currentTitle, setCurrentTitle] = useState(title);
  const [showButtons, setShowButtons] = useState(false);
  const [openConfirmRestoreEdited, setOpenConfirmRestoreEdited] = useState(false);
  const [isRowNameEdit, setIsRowNameEdit] = useState(false);

  const isError = !rule.script || !currentTitle;

  const fullWidth = 1252; // TODO calc from document
  const rowWidth = `${fullWidth - nestedLevel * 44 - 20}px`;
  const ruleIdWidth = 40;
  const titleWidth = `${
    fullWidth -
    30 -
    (!isNew && ruleIdWidth) - // rule ID width
    36 - // drug button width
    nestedLevel * 44 -
    (showButtons && !isLocked ? 320 : 180) -
    (isEdited ? 52 : 0) -
    ((isNew && !isEdited && !showButtons) || (isNew && isLocked) ? 90 : 0) +
    (isLocked && !isForDelete && 44)
  }px`;

  let handle;
  if (canDrag) {
    // Show the handle used to initiate a drag-and-drop
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    handle = connectDragSource(
      <div style={{ zIndex: 1000 }}>
        <IconButton sx={{ cursor: 'move', color: grey[500], ...hoverBgColor }}>
          <DragHandleIcon style={{ fontSize: '20px' }} />
        </IconButton>
      </div>,
      {
        dropEffect: 'copy',
      },
    );
  }

  const titleTextStyle = {
    width: titleWidth,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    transition: 'none',
    '&:hover': {
      cursor: isLocked ? 'default' : 'pointer',
      color: isLocked ? 'grey.900' : 'black',
      borderRadius: '4px',
    },
  };

  if (isDragging && didDrop) {
    dispatch(
      setFieldValue({
        ruleId,
        setFields: {
          isMoved: true,
        },
      }),
    );
  }

  const getBgColor = () => {
    if (isRowNameEdit) return 'white';
    if (isForDelete || isParentForDelete) return 'error.light';
    if (isEdited || isMoved) return warning.light;
    if (isNew) return warning.light;
    return isGroup ? 'grey.200' : 'grey.50';
  };

  const getBorderColor = () => {
    if (isError) {
      return error.main;
    }
    if (isRowNameEdit) {
      return grey[400];
    }
    return isNew ? warning.light : getBgColor();
  };

  const handleMarkDelete = (value: boolean) => {
    dispatch(
      setMarkForDelete({
        ruleId,
        isForDelete: value,
      }),
    );
    setShowButtons(!value);
  };
  const handleRemoveRuleById = () => {
    dispatch(removeRuleById(ruleId));
  };

  const handleRestoreEdited = () => {
    dispatch(restoreRule(savedRule));
  };

  const handleAddBelow = () => {
    dispatch(addRuleAfterId(ruleId));
    setIsShowInactive(true);
  };

  const handleAddCopy = () => {
    dispatch(addCopy(ruleId));
    setIsShowInactive(true);
  };

  const handleChangeActive = () => {
    if (isActive) {
      dispatch(
        setIsActiveDeep({
          ruleId,
          isActive: false,
        }),
      );
    } else {
      dispatch(
        setFieldValue({
          ruleId,
          setFields: {
            isActive: true,
            isMoved: true,
          },
        }),
      );
    }
  };

  const handleEditTitle = (e) => {
    setCurrentTitle(e.target.value);
  };

  const handleSaveTitle = () => {
    const isChanged = currentTitle !== title;
    isChanged &&
      dispatch(
        setFieldValue({
          ruleId,
          setFields: {
            title: currentTitle,
            isEdited: true,
          },
        }),
      );
  };

  return (
    <>
      <div
        onMouseEnter={() => !(isForDelete || isParentForDelete) && setShowButtons(true)}
        onMouseLeave={() => !(isForDelete || isParentForDelete) && setShowButtons(false)}
        className="rowWrapperShowButtons"
        style={{ width: rowWidth }}
        id={`ruleRow-${ruleId}`}
      >
        <div
          className="addRowButton"
          style={{ display: showButtons && !isLocked ? 'block' : 'none' }}
        >
          <Tooltip title="Add rule below" enterDelay={TOOLTIP_DELAY}>
            <IconButton onClick={handleAddBelow} sx={{ mt: '4px', ...hoverBgColor }}>
              <AddCircleIcon style={{ fontSize: '20px', color: primary.main }} />
            </IconButton>
          </Tooltip>
        </div>

        <Tooltip
          title={isError ? 'Please edit or delete any empty rule.' : ''}
          enterDelay={TOOLTIP_DELAY}
          enterNextDelay={TOOLTIP_DELAY * 5}
        >
          <Box
            className="rowWrapperBody"
            sx={{
              bgcolor: getBgColor(),
              border: isDragging ? `2px dashed ${primary.main}` : `1px solid ${getBorderColor()}`,
              boxShadow: isSearchMatch
                ? `0 0 0 2px ${primary.main}${isSearchFocus ? 'BB' : '40'}`
                : 'none',
            }}
          >
            <Stack p={2} direction="row" justifyContent="space-between" width="100%">
              <Box className="leftSideContentRow">
                <div className="container-row">
                  <div
                    className={`
                    dragElement
                    ${!(isForDelete || isParentForDelete) && isLocked ? 'IsLocked' : 'IsUnLocked'}
                    ${allowAnimate ? 'Animate' : ''}
                  `}
                  >
                    {isForDelete || isParentForDelete ? (
                      <Tooltip
                        title={isParentForDelete ? 'Unmark parent deletion' : 'Unmark deletion'}
                        enterDelay={TOOLTIP_DELAY}
                      >
                        <div>
                          <IconButton
                            onClick={() => handleMarkDelete(false)}
                            disabled={isParentForDelete || isLocked}
                            sx={{
                              color: 'error.main',
                              ...hoverBgColor,
                            }}
                          >
                            <RestoreIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    ) : (
                      handle
                    )}
                  </div>

                  <div className="slideElement">
                    <Tooltip title="Switch is rule active" enterDelay={TOOLTIP_DELAY}>
                      <div>
                        <SwitchMacStyled
                          checked={isActive}
                          onChange={handleChangeActive}
                          disabled={isLocked || isForDelete || isParentForDelete || isError}
                        />
                      </div>
                    </Tooltip>
                    {isEdited && !isForDelete && !isParentForDelete && !isNew && (
                      <Tooltip title="Undo edit" enterDelay={TOOLTIP_DELAY}>
                        <div>
                          <IconButton
                            onClick={() => setOpenConfirmRestoreEdited(true)}
                            sx={{ color: warning.main, ...hoverBgColor }}
                            disabled={isLocked}
                          >
                            <RestoreIcon style={{ fontSize: '20px' }} />
                          </IconButton>
                        </div>
                      </Tooltip>
                    )}
                    {!isNew && (
                      <Typography sx={{ width: `${ruleIdWidth}px`, color: 'grey.600' }}>
                        {ruleId}
                      </Typography>
                    )}
                    <EditableTypography
                      value={currentTitle}
                      handleChangeValue={handleEditTitle}
                      textStyle={titleTextStyle}
                      onSave={handleSaveTitle}
                      onEsc={() => setCurrentTitle(title)}
                      isLocked={isLocked}
                      setIsEditCallback={setIsRowNameEdit}
                      placeholder="Rule name must not be empty"
                    />
                  </div>
                </div>
              </Box>

              <Box className="rightSideContentRow">
                {!isLocked && (
                  <Box sx={{ display: showButtons ? 'block' : 'none' }}>
                    <Stack pr={4} direction="row" justifyContent="space-between" gap={2}>
                      <Tooltip title="Copy" enterDelay={TOOLTIP_DELAY}>
                        <IconButton
                          sx={{ transition: 'all 0.3s ease-in-out', ...hoverBgColor }}
                          onClick={handleAddCopy}
                        >
                          <ContentCopyIcon style={{ fontSize: '20px', color: grey[900] }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit" enterDelay={TOOLTIP_DELAY}>
                        <IconButton
                          sx={{ transition: 'all 0.3s ease-in-out', ...hoverBgColor }}
                          onClick={() => navigate(`${ruleId}`)}
                        >
                          <EditIcon style={{ fontSize: '20px', color: grey[900] }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title={isNew && !isEdited ? 'Delete' : 'Mark for deletion'}
                        enterDelay={TOOLTIP_DELAY}
                      >
                        <IconButton
                          sx={{ transition: 'all 0.3s ease-in-out', ...hoverBgColor }}
                          onClick={() =>
                            isNew && !isEdited ? handleRemoveRuleById() : handleMarkDelete(true)
                          }
                        >
                          <DeleteIcon
                            style={{
                              fontSize: '20px',
                              color: isNew && !isEdited ? grey[700] : error.main,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Box>
                )}
                {isNew && !isEdited && (!showButtons || isLocked) && (
                  <IconButton
                    onClick={() => handleRemoveRuleById()}
                    sx={{ mr: 4, color: grey[500], ...hoverBgColor }}
                    disabled={isLocked || isParentForDelete}
                  >
                    <DeleteIcon style={{ fontSize: '20px' }} />
                  </IconButton>
                )}
                <GetLamp answer={answer} score={score} />
              </Box>
            </Stack>
          </Box>
        </Tooltip>
      </div>

      {openConfirmRestoreEdited && (
        <ModalDialog
          open={openConfirmRestoreEdited}
          maxWidth="xs"
          variant="error"
          onClose={() => setOpenConfirmRestoreEdited(false)}
          title="Undo changes"
          handleAction={() => {
            setOpenConfirmRestoreEdited(false);
            handleRestoreEdited();
          }}
          actionTitle="Remove"
        >
          Are you sure you want remove changes in this rule?
        </ModalDialog>
      )}
    </>
  );
};
