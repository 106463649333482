import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { RiArrowRightUpFill } from 'react-icons/ri';
import { useGetCaseCategoriesQuery } from 'services/gatewayApi/casesApi';
import { checkProductApiName, checkProductName } from 'utils';
import { Case } from '../../types';
import { caseTypes, overviewSections } from '../../constants';
import { useGetAllCountriesQuery } from '../../../../services/gatewayApi/countryRiskApi';

interface OverviewTabProps {
  caseData: Case;
  users: any;
}

export const OverviewTab: React.FC<OverviewTabProps> = ({ caseData, users }) => {
  const { data: categories, isLoading: isLoadingCategories } = useGetCaseCategoriesQuery(undefined);
  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: (data as any[])?.map((c) => ({
        value: c.countryCode,
        label: c.name,
      })),
      isLoading: isLoadingCountries,
    }),
  });

  const isTech2Pay = checkProductName(['TECHTOPAY']);

  const RenderValue = ({ property }) => {
    const value = caseData[property];

    if (!value) {
      return <Typography>-</Typography>;
    }

    switch (property) {
      case 'registrationCountry':
      case 'senderBankCountry':
      case 'beneficiaryBankCountry':
        return (
          <Typography>{countries?.find((el) => el.value === value)?.label || value}</Typography>
        );

      case 'assigneeId':
        const assigneeId = value;
        // @ts-ignore
        const assigneeUser = users.find((u) => u.id === assigneeId);

        return (
          <Typography>{assigneeId && assigneeUser ? assigneeUser.name : 'no asignee'}</Typography>
        );

      case 'caseCategory':
        return (
          <Typography>
            {categories && value ? categories.find((c) => c.category === value)?.description : '-'}
          </Typography>
        );

      case 'caseType':
        return (
          <Typography>{value ? caseTypes.find((c) => c.value === value)?.label : '-'}</Typography>
        );

      case 'customerEmail':
        return (
          <Typography>
            {value ?? '-'}
            {isTech2Pay && value && (
              <Tooltip title="Show transactions">
                <Link to={`/transactions?clientEmail=${encodeURI(value)}`}>
                  <IconButton sx={{ ml: 2 }}>
                    <RiArrowRightUpFill size="14px" />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </Typography>
        );

      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <>
      {!isLoadingCategories &&
        overviewSections
          .filter(
            (section) =>
              (!section.forApiNames || (section.forApiNames && checkProductApiName(section.forApiNames)))
              && (!section.forCaseTypes || (section.forCaseTypes.includes(caseData?.caseType))),
          )
          .map((section) => (
            <div key={section.id}>
              {section.label && (
                <Typography variant="subtitle1" component="span">
                  {section.label}
                </Typography>
              )}

              {section.fields
                .filter(
                  (field) =>
                    (!field.forApiNames || (field.forApiNames && checkProductApiName(field.forApiNames)))
                    && (!field.forCaseTypes || (field.forCaseTypes.includes(caseData?.caseType))),
                )
                .map((field) => (
                  <Box
                    key={field.value}
                    sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
                  >
                    <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
                      {field.label}
                    </Typography>
                    <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
                      <RenderValue property={field.value ?? '-'} />
                    </Box>
                  </Box>
                ))}
              <br />
            </div>
          ))}
    </>
  );
};
