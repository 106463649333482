/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import moment from 'moment';

import {
  LOAD_RULESET_STATS,
  LOAD_RULESET_STATS_SUCCESS,
  LOAD_RULESET_STATS_FAIL,
  CHANGE_RULESET_STATS_DATE_RANGE,
} from '../constants/constants';

const today = moment();
const thirtyDaysAgo = moment().subtract(1, 'months');
const initialDateState = {
  startDate: thirtyDaysAgo,
  endDate: today,
};

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  data: [],
  dateRange: initialDateState,
};

const ruleSetStatsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_RULESET_STATS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_RULESET_STATS_SUCCESS:
        draft.loading = false;
        draft.data = action.stats;
        break;
      case LOAD_RULESET_STATS_FAIL: {
        draft.loading = false;
        draft.error = action.error;
        break;
      }
      case CHANGE_RULESET_STATS_DATE_RANGE:
        draft.dateRange = action.dateRange;
        break;
    }
  });

export default ruleSetStatsReducer;
