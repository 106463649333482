import React from 'react';
import { Box } from '@mui/material';
import CaseAssignments from 'containers/CaseManagement/CaseAssignments';
import { Dashboard } from 'features/Dashboard';
import { Transactions } from 'features/Transactions';
import Rules from 'containers/Rules';
import RuleBuilder from 'containers/RuleBuilder';
import RuleFunctions from 'containers/RuleFunctions';
import ValidatorTransactions from 'containers/ValidatorTransactions/ValidatorTransactions';
import Showcase from 'features/Showcase';
import Administration from 'containers/Administration';
import SanctionDetail from 'containers/CaseManagement/Sanction/SanctionDetail';
import { ApplicationManagement } from 'features/ApplicationManagement';
import { AppCreate } from 'features/ApplicationManagement/AppCreate';
import { ClientDetails } from 'features/ApplicationManagement/ClientDetails';
import { CreditsafeSearch } from 'features/CreditsafeSearch';
import CardData from 'features/cardData';
import { CaseDetails } from 'features/cases/CaseDetails';
import { CountryRisk } from 'features/countryRisk';
import { KycApplicants } from 'features/kycApplicants';
import KycProviders from 'features/kycProviders';
import Merchants from 'features/merchants';
import MerchantCategories from 'features/merchantCategories';
import RiskWords from 'features/riskWords';
import RuleVetosMonitor from 'features/ruleVetosMonitor';
import { UsersManagement } from 'features/UsersManagement';

import {
  CASE_MANAGEMENT,
  RULES_MANAGEMENT,
  APPLICATION_MANAGEMENT,
  KYC_MANAGEMENT,
  CLIENTS_MANAGEMENT,
  MERCHANTS_MANAGEMENT,
  USERS_MANAGEMENT,
  TRANSACTIONS_MANAGEMENT,
  RISK_WORDS_MANAGEMENT,
  COUNTRY_RISK_MANAGEMENT,
  PEPSANCTIONS_MANAGEMENT,
  UBO_MANAGEMENT,
} from 'constants/constants';
import { Cases } from 'features/cases';
import { UserPage } from 'features/UsersManagement/Users/UserPage';
import { ClientCategories } from 'features/clientCategories';
import { Clients } from 'features/clients';
import { Acquirers } from 'features/acquirers';
import { UboManagement } from 'features/uboManagement';
import {
  ApplicationsTab,
  OverviewTab,
  SummaryTab,
  ShareholdersTab,
  IndividualsTab,
  IDVerifyTab,
  CustomerUsers,
  OpenpaydUsers,
} from 'features/ApplicationManagement/ClientDetails/Tabs';
import {
  AppTab,
  RiskScoringTab,
  WebsiteChecklistTab,

  DocsTab,
  DocumentsTab,

  MailTab,
  HistoryTab,
} from 'features/ApplicationManagement/ClientDetails/Tabs/ApplicationsTab/Tabs';
import Filtering from 'features/Showcase/Filtering';
import {
  PepAndSanctions,
  PepAndSanctionsApplications,
  PepAndSanctionsTransactions,
} from 'features/PepAndSanctions';
import { SearchSessionContainer } from 'features/PepAndSanctions/SearchSession/SearchSessionContainer';
import { ExceptionRules } from 'features/exceptionRules';
import { Rules as RulesNew } from '../features/Rules';
import { RulesTree } from '../features/Rules/RulesTree';
import { RuleDetails } from '../features/Rules/RuleDetails';
import { FormsFFWrapper } from '../features/ApplicationManagement/ClientDetails/Forms/FormsFFWrapper';

export const routes = [
  {
    path: '/sc',
    element: <Showcase />,
    authorize: ['SUPER_ADMIN'],
    subroutes: [
      {
        path: 'filtering',
        element: <Filtering />,
      },
      {
        path: 'tab1',
        element: (
          <Box pt={5} pb={2}>
            Route 1
          </Box>
        ),
      },
      {
        path: 'tab2',
        element: (
          <Box pt={5} pb={2}>
            Route 2
          </Box>
        ),
      },
    ],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/transactions',
    element: <Transactions />,
    authorize: TRANSACTIONS_MANAGEMENT,
  },
  {
    path: '/user-management',
    element: <UsersManagement />,
    authorize: USERS_MANAGEMENT,
  },
  {
    path: '/user-management/:id',
    element: <UserPage />,
    authorize: USERS_MANAGEMENT,
  },
  {
    path: '/merchants',
    element: <Merchants />,
    authorize: MERCHANTS_MANAGEMENT,
    authProductApiNames: ['GATEWAY'],
  },
  {
    path: '/merchant-categories',
    element: <MerchantCategories />,
    authorize: MERCHANTS_MANAGEMENT,
    authProductApiNames: ['GATEWAY'],
  },
  {
    path: '/clients',
    element: <Clients />,
    authorize: CLIENTS_MANAGEMENT,
    authProductApiNames: ['BANK'],
  },
  {
    path: '/client-categories',
    element: <ClientCategories />,
    authorize: CLIENTS_MANAGEMENT,
    authProductApiNames: ['BANK'],
  },
  {
    path: '/exception-rules',
    element: <ExceptionRules />,
    authorize: TRANSACTIONS_MANAGEMENT,
  },
  {
    path: '/card-data',
    element: <CardData />,
    authorize: TRANSACTIONS_MANAGEMENT,
    authProductNames: ['GATEWAY'],
  },
  {
    path: '/acquirers',
    element: <Acquirers />,
  },
  {
    path: '/validator-transactions',
    element: <ValidatorTransactions />,
    authorize: TRANSACTIONS_MANAGEMENT,
    authProductNames: ['GATEWAY'],
  },
  {
    path: '/cases/:id',
    element: <CaseDetails />,
    authorize: CASE_MANAGEMENT,
  },
  {
    path: '/cases',
    element: <Cases />,
    authorize: CASE_MANAGEMENT,
  },
  {
    path: '/sanction-detail/:id',
    element: <SanctionDetail />,
    authorize: CASE_MANAGEMENT,
  },
  {
    path: '/case-assignments',
    element: <CaseAssignments />,
    authorize: CASE_MANAGEMENT,
  },
  {
    path: '/rulesNew',
    element: <RulesNew />,
    authorize: RULES_MANAGEMENT,
    subroutes: [
      {
        path: ':ruleSetId',
        element: <RulesTree />,
        authorize: RULES_MANAGEMENT,
      },
    ],
  },
  {
    path: '/rulesNew/:ruleSetId/:ruleId',
    element: <RuleDetails />,
    authorize: RULES_MANAGEMENT,
  },
  {
    path: '/rules',
    element: <Rules />,
    authorize: RULES_MANAGEMENT,
  },
  {
    path: '/rules/:id',
    element: <RuleBuilder />,
    authorize: RULES_MANAGEMENT,
  },
  {
    path: '/rule-functions',
    element: <RuleFunctions />,
    authorize: RULES_MANAGEMENT,
  },
  {
    path: '/rule-vetos-monitor',
    element: <RuleVetosMonitor />,
    authorize: RULES_MANAGEMENT,
  },
  {
    path: '/administration',
    element: <Administration />,
    authorize: ['SUPER_ADMIN'],
  },
  {
    path: '/country-risk',
    element: <CountryRisk />,
    authorize: COUNTRY_RISK_MANAGEMENT,
    authProductApiNames: ['BANK'],
  },
  {
    path: '/applicants-kyc',
    element: <KycApplicants />,
    authorize: KYC_MANAGEMENT,
  },
  {
    path: '/providers-kyc',
    element: <KycProviders />,
    authorize: KYC_MANAGEMENT,
  },
  {
    path: 'application-management',
    element: <ApplicationManagement />,
    authorize: APPLICATION_MANAGEMENT,
  },
  {
    path: 'application-management/new',
    element: <AppCreate />,
    authorize: APPLICATION_MANAGEMENT,
  },
  {
    path: '/application-management/:clientId',
    element: <ClientDetails />,
    authorize: APPLICATION_MANAGEMENT,
    subroutes: [
      {
        path: 'applications/*',
        element: <ApplicationsTab />,
        authorize: APPLICATION_MANAGEMENT,
      },
      {
        path: 'applications/:applicationId/*',
        element: <ApplicationsTab />,
        authorize: APPLICATION_MANAGEMENT,
        subroutes: [
          {
            path: 'docs/*',
            element: <DocsTab />,
            authorize: APPLICATION_MANAGEMENT,
            subroutes: [
              {
                path: 'documents',
                element: <DocumentsTab />,
                authorize: APPLICATION_MANAGEMENT,
              },
              {
                path: 'internal-documents',
                element: <DocumentsTab isInternal />,
                authorize: APPLICATION_MANAGEMENT,
              },
            ],
          },
          {
            path: 'app/*',
            element: <AppTab />,
            authorize: APPLICATION_MANAGEMENT,
            subroutes: [
              {
                path: 'forms/:sectionId/:partition/:sectionUrl',
                authorize: APPLICATION_MANAGEMENT,
                element: <FormsFFWrapper />,
              },
              {
                path: 'website-checklist',
                element: <WebsiteChecklistTab />,
                authorize: APPLICATION_MANAGEMENT,
              },
              {
                path: 'risk-scoring',
                element: <RiskScoringTab />,
                authorize: APPLICATION_MANAGEMENT,
              },
              {
                path: 'id-verify',
                element: <IDVerifyTab />,
                authorize: APPLICATION_MANAGEMENT,
              },
            ],
          },
          {
            path: 'history',
            element: <HistoryTab />,
            authorize: APPLICATION_MANAGEMENT,
          },
          {
            path: 'mail',
            element: <MailTab />,
            authorize: APPLICATION_MANAGEMENT,
          },
        ],
      },
      {
        path: 'overview',
        element: <OverviewTab />,
        authorize: APPLICATION_MANAGEMENT,
      },
      {
        path: 'summary',
        element: <SummaryTab />,
        authorize: APPLICATION_MANAGEMENT,
      },
      {
        path: 'associated-individuals',
        element: <IndividualsTab />,
        authorize: UBO_MANAGEMENT,
      },
      {
        path: 'shareholders',
        element: <ShareholdersTab />,
        authorize: APPLICATION_MANAGEMENT,
      },
      {
        path: 'customer-users',
        element: <CustomerUsers />,
        authorize: APPLICATION_MANAGEMENT,
      },
      {
        path: 'openpayd-users',
        element: <OpenpaydUsers />,
        authorize: APPLICATION_MANAGEMENT,
      },
    ],
  },
  {
    path: 'pep-and-sanctions/*', // main menu
    element: <PepAndSanctions />,
    authorize: PEPSANCTIONS_MANAGEMENT,
    subroutes: [
      {
        path: 'applications',
        element: <PepAndSanctionsApplications />,
        authorize: PEPSANCTIONS_MANAGEMENT,
      },
      {
        path: 'transactions',
        element: <PepAndSanctionsTransactions />,
        authorize: PEPSANCTIONS_MANAGEMENT,
      },
    ],
  },
  {
    path: 'pep-and-sanctions/transactions/:searchSessionId',
    element: <SearchSessionContainer />,
    authorize: PEPSANCTIONS_MANAGEMENT,
  },
  {
    path: 'pep-and-sanctions/applications/:searchSessionId',
    element: <SearchSessionContainer />,
    authorize: PEPSANCTIONS_MANAGEMENT,
  },

  {
    path: '/ubo-management',
    element: <UboManagement />,
    authorize: UBO_MANAGEMENT,
  },
  {
    path: '/creditsafe-search',
    element: <CreditsafeSearch />,
    authorize: APPLICATION_MANAGEMENT,
  },
  {
    path: '/risk-words',
    element: <RiskWords />,
    authorize: RISK_WORDS_MANAGEMENT,
    authProductApiNames: ['BANK'],
  },
];
