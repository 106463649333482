// https://documentation.idenfy.com/extra/Vocabulary#verification-status-values-vocabulary
export const idenfyStatuses = [
  {
    key: 'APPROVED',
    value:
      'Verification was successful and approved by an automated platform or a manual reviewer. An overall status of the verification. It is a combination of manual and automatic verification results. Please refer to autoFace, manualFace, autoDocument and manualDocument statuses.',
    color: 'success',
  },
  {
    key: 'DENIED',
    value:
      'Verification was not successful and was denied by an automated platform or a manual reviewer. An overall status of the verification. It is a combination of manual and automatic verification results. Please refer to autoFace, manualFace, autoDocument and manualDocument statuses.',
    color: 'error',
  },
  {
    key: 'SUSPECTED',
    value:
      'Overall status of the verification. It depends on auto or manual webhook, but if face status:autoFace, manualFace is FACE_MATCH and document status autoDocument, manualDocument is DOC_VALIDATED, then the verification is approved. If other statuses exist, for example: DOC_NOT_FOUND, FACE_MISMATCH, then verification is declined. If any fraudTags or mismatchTags exist for the verification, then status can be set as SUSPECTED, because the identification caught some discrepancies. If verification is approved(FACE_MATCH and DOC_VALIDATED) but overall status SUSPECTED it should be decided on your end to allow or decline this client.',
    color: 'warning',
  },
  {
    key: 'REVIEWING',
    value:
      'Verification is being reviewed by a human. Contact iDenfy support to turn this feature on or off.',
    color: 'info',
  },
  {
    key: 'EXPIRED',
    value:
      'A token associated with this verification got expired and a verification process never took place (token was not used).',
  },
  {
    key: 'ACTIVE',
    value:
      'A verifications has no decisive status. A token representing this verification was not used and is still active and can be used by a client any moment.',
  },
  {
    key: 'EXPIRED-DELETED',
    value:
      'A token associated with this identification got expired and a verifications process never took place (token was not used and got deleted).',
  },
  {
    key: 'DELETED',
    value: 'For performance reasons we might decide to delete certain verifications.',
  },
  {
    key: 'ARCHIVED',
    value: 'For performance reasons we might decide to archive certain verifications.',
  },
  {
    key: 'FACE_MATCH',
    value:
      'Face from document was successfully matched with the selfie face. This status indicates that the person and the owner of the document are the same.',
    color: 'success',
  },
  {
    key: 'FACE_MISMATCH',
    value:
      "The face of a verifying person could not be determined as facial features are not fully visible or there a few similar faces in the photo. It could be of low-quality pictures and/or changes in a person's facial features during a lifetime and/or blur, shadow, glare, fingers or other objects in the photo.",
  },
  {
    key: 'NO_FACE_FOUND',
    value:
      'The face cannot be accurately determined or facial features are not clear in the photo. The selfie might be effected by blur, shadow, glare, fingers or other objects in the photo.',
  },
  {
    key: 'TOO_MANY_FACES',
    value:
      'In the given selfie photo more than one face is visible. We can not accurately determine which face to match and/or the law prohibits more than one face in the verification selfie.',
  },
  {
    key: 'FACE_TOO_BLURRY',
    value: 'Selfie face was too blurry and face matching could not be performed.',
  },
  {
    key: 'FACE_ERROR',
    value: 'Unclassified error happened when locating/matching faces.',
  },
  {
    key: 'FACE_NOT_ANALYSED',
    value:
      'Verification was denied for some other reason e.g. it was suspected therefore face analysis was not performed.',
  },
  {
    key: 'FAKE_FACE',
    value:
      'The photo were taken not in a real time, virtual camera were detected or face is fake. It also could occured of blur, shadow, glare, fingers, or other objects in the photo.',
  },
  {
    key: 'FACE_GLARED',
    value: 'Detected glare in selfie photo.',
  },
  {
    key: 'FACE_UNCERTAIN',
    value:
      'Selfie face liveness can not be determined. This means that it might be because of not well-lit environment, fake face provided or blur, shadow, glare, fingers or other objects in the photo.',
  },
  {
    key: 'DOC_VALIDATED',
    value:
      'Document is valid. This means that all data is visible and read, document face is foundand document seems to be genuine.',
    color: 'success',
  },
  {
    key: 'DOC_NOT_FOUND',
    value: 'Document could not be found in the photo.',
  },
  {
    key: 'DOC_NOT_FULLY_VISIBLE',
    value:
      'This status indicates that the document or some information in the document are not fully visible. It occurred of not showing the document, blur, shadow, glare, fingers, damaged document, or other objects in the photo.',
  },
  {
    key: 'DOC_NOT_SUPPORTED',
    value:
      'The client has shown a document type that is not supported for a selected country, not allowed for currect session, or type of document does not match with selected.',
  },
  {
    key: 'DOC_FACE_NOT_FOUND',
    value: 'A face in the document could not be located.',
  },
  {
    key: 'DOC_NAME_ERROR',
    value: 'In the front side of the document a name field could not be found or parsed.',
  },
  {
    key: 'DOC_SURNAME_ERROR',
    value: 'In the front side of the document a surname field could not be found or parsed.',
  },
  {
    key: 'DOC_EXPIRY_ERROR',
    value:
      'In the front side of the document a document expiry date field could not be found or parsed.',
  },
  {
    key: 'DOC_DOB_ERROR',
    value: 'In the front side of the document a date of birth field could not be found or parsed.',
  },
  {
    key: 'DOC_PERSONAL_NUMBER_ERROR',
    value: 'In the front side of the document a personal code field could not be found or parsed.',
  },
  {
    key: 'DOC_NUMBER_ERROR',
    value:
      'In the front side of the document a document number field could not be found or parsed.',
  },
  {
    key: 'DOC_DATE_OF_ISSUE_ERROR',
    value: 'In the front side of the document a date of issue field could not be found or parsed.',
  },
  {
    key: 'DOC_SEX_ERROR',
    value: 'In the front side of the document a sex field could not be found or parsed.',
  },
  {
    key: 'DOC_NATIONALITY_ERROR',
    value: 'In the front side of the document a nationality field could not be found or parsed.',
  },
  {
    key: 'DOC_GLARED',
    value: 'Document looks glared in the photo and data parsing can not be performed.',
  },
  {
    key: 'DOC_FACE_GLARED',
    value: 'Document face looks glared in the photo and data parsing can not be performed.',
  },
  {
    key: 'DOC_TOO_BLURRY ',
    value: 'Document looks too blurry in the photo and data parsing can not be performed.',
  },
  {
    key: 'DOC_NOT_ALLOWED',
    value:
      "Document could be read but the specific document type is not allowed in the partner's settings.",
  },
  {
    key: 'MRZ_NOT_FOUND',
    value: 'Machine Readable Zone (MRZ) could not be located.',
  },
  {
    key: 'MRZ_OCR_READING_ERROR',
    value:
      'Failed to read and parse Machine Readable Zone (MRZ). Possibly check-digit discrepancy.',
  },
  {
    key: 'BARCODE_NOT_FOUND',
    value: 'The barcode of the document could not be located.',
  },
  {
    key: 'DOC_EXPIRED',
    value:
      'The document shown in the photo indicates that it is expired and therefore no longer valid. It might also be effected of blur, shadow, glare, fingers, or other objects in the photo.',
  },
  {
    key: 'COUNTRY_MISMATCH',
    value: 'Selected country and identity document issuing country do not match.',
  },
  {
    key: 'DOC_SIDE_MISMATCH',
    value: 'Document side is different than expected.',
  },
  {
    key: 'DOC_TYPE_MISMATCH',
    value: 'Selected document type mismatches with shown document or allowed document.',
  },
  {
    key: 'DOC_ERROR',
    value: 'Unclassified error happened while analysing identity document.',
  },
  {
    key: 'DOC_NOT_ANALYSED',
    value:
      'Verification was denied for some other reason e.g. it was suspected therefore document analysis was not performed.',
  },
  {
    key: 'DOC_DAMAGED',
    value: 'Document in the photo is damaged: it might be cracked or broken in some way.',
  },
  {
    key: 'DOC_FAKE',
    value:
      'The photo were taken not on a real time, virtual camera were detected or document is fake. It also could occured of blur, shadow, glare, fingers, or other objects in the photo.',
  },
  {
    key: 'AUTO_UNVERIFIABLE',
    value: 'This verification can not be automatically verified and needs to be verified by human.',
  },
  {
    key: 'COUNTRY_NOT_SUPPORTED',
    value: 'Document country is not supported.',
  },
  {
    key: 'FACE_SUSPECTED',
    value: 'Automatic algorithms triggered a suspicion that selfie face is not genuine.',
  },
  {
    key: 'FACE_BLACKLISTED',
    value: 'A selfie is blacklisted.',
  },
  {
    key: 'DOC_FACE_BLACKLISTED',
    value: 'A photo of a document is blacklisted.',
  },
  {
    key: 'DOC_MOBILE_PHOTO',
    value: 'A photo of a document is shown from a mobile screen.',
  },
  {
    key: 'DEV_TOOLS_OPENED',
    value: 'A client performing verification has opened dev-tools in the browser.',
  },
  {
    key: 'DOC_PRINT_SPOOFED',
    value: 'Automatic algorithms triggered a suspicion that document is printed on paper.',
  },
  {
    key: 'FAKE_PHOTO',
    value: 'General identifier for a fake photo.',
  },
  {
    key: 'AML_SUSPECTION',
    value:
      'If AML functionality is turned on for you - this reason specifies that a client is in PEPs or Sanctions list.',
  },
  {
    key: 'AML_FAILED',
    value:
      'If AML functionality is turned on for you - this reason specifies that the check has failed.',
  },
  {
    key: 'LID_SUSPECTION',
    value:
      "If LID functionality is turned on for you - this reason specifies that client's document is found in lost or stolen documents database.",
  },
  {
    key: 'LID_FAILED',
    value:
      'If LID functionality is turned on for you - this reason specifies that the check has failed.',
  },
  {
    key: 'UNDER_AGE',
    value: 'An age read from document is lower than partner age limit.',
  },
  {
    key: 'UNKNOWN_AGE',
    value: 'An age was not read from document and partner has set age limit.',
  },
  {
    key: 'NAME',
    value: 'Partner given client name does not match one read form document.',
  },
  {
    key: 'SURNAME',
    value: 'Partner given client surname does not match the one read form document.',
  },
  {
    key: 'DOCUMENT_NUMBER',
    value: 'Partner given client document number does not match the one read form document.',
  },
  {
    key: 'PERSONAL_CODE',
    value: 'Partner given client personal code does not match the one read form document.',
  },
  {
    key: 'EXPIRY_DATE',
    value: 'Partner given client expiry date does not match the one read form document.',
  },
  {
    key: 'DATE_OF_BIRTH',
    value: 'Partner given client date of birth does not match the one read form document.',
  },
  {
    key: 'DATE_OF_ISSUE',
    value: 'Partner given client date of issue does not match the one read form document.',
  },
  {
    key: 'FULL_NAME',
    value: 'Partner given client full name does not match the one read form document.',
  },
  {
    key: 'DOC_PERSONAL_CODE_INVALID',
    value:
      'The personal code could not be verified. This means that the personal code is incorrect or not fully visible because of blur, shadow, glare, fingers, damaged document, or other objects in the photo.',
  },
  {
    key: 'DOC_SPOOF_DETECTED',
    value: 'Document detected as not real.',
  },
  {
    key: 'MRZ_INVALID',
    value:
      'Machine readable zone (MRZ) is invalid and cannot be verified. It occured ofblur, shadow, glare, fingers, damaged document, other objects in the photo or it may be eddited by the client.',
  },
  {
    key: 'INVALID_ADDITIONAL_STEP',
    value: 'Additional step document was invalid.',
  },
  {
    key: 'ADDITIONAL_STEP_NOT_FOUND',
    value: 'Additional step was not found.',
  },
  {
    key: 'FACE_IN_BLACKLIST',
    value: 'A selfie is a part of blacklist.',
  },
  {
    key: 'DOC_FACE_IN_BLACKLIST',
    value: 'A photo of a document is a part of blacklist.',
  },
  {
    key: 'DUPLICATE_FACE',
    value: 'A face matched with earlier done verification selfie.',
  },
  {
    key: 'DUPLICATE_DOC_FACE',
    value: 'A document face matched with earlier done verification document face.',
  },
  {
    key: 'VIRTUAL_CAMERA',
    value: 'Likely virtual camera detected.',
  },
  {
    key: 'DOC_INFO_MISMATCH',
    value: 'The information provided does not match between the system and the document.',
  },
  {
    key: 'UNDER_AGE',
    value: 'The client is under certain age limit.',
  },
  {
    key: 'ADDITIONAL_STEP_INFORMATION_MISMATCH',
    value: 'The additional step information does not match with the document provided.',
  },
  {
    key: 'EXPIRED_ADDITIONAL_STEP_INFORMATION',
    value: 'The provided document is expired',
  },
  {
    key: 'INVALID_ADDITIONAL_STEP',
    value: 'Additional step is invalid',
  },

  // Additional which are not on the linked page
  {
    key: 'VALID',
    value: 'Additional step is valid.',
    color: 'success',
  },
  {
    key: 'INVALID',
    value: 'Additional step is invalid.',
    color: 'error',
  },
  {
    key: 'NOT_FOUND',
    value: 'Additional step was not found.',
  },
];
