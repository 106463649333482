import { ISelectOption } from 'uikit';

export const businessTypeOptions: ISelectOption[] = [
  {
    value: 'adult',
    label: 'Adult',
  },
  {
    value: 'affilatives',
    label: 'Affilatives',
  },
  {
    value: 'broker | introducer',
    label: 'Broker / Introducer',
  },
  {
    value: 'chemical',
    label: 'Chemical',
  },
  {
    value: 'construction',
    label: 'Construction',
  },
  {
    value: 'crypto',
    label: 'Crypto',
  },
  {
    value: 'dating',
    label: 'Dating',
  },
  {
    value: 'digitalMedia | advertising',
    label: 'DigitalMedia / Advertising',
  },
  {
    value: 'eCommerce',
    label: 'eCommerce',
  },
  {
    value: 'education',
    label: 'Education',
  },
  {
    value: 'electronics',
    label: 'Electronics',
  },
  {
    value: 'eMoney',
    label: 'eMoney',
  },
  {
    value: 'energy',
    label: 'Energy',
  },
  {
    value: 'entertainment',
    label: 'Entertainment',
  },
  {
    value: 'financialServices',
    label: 'Financial Services',
  },
  {
    value: 'fishing',
    label: 'Fishing',
  },
  {
    value: 'food',
    label: 'Food',
  },
  {
    value: 'forex',
    label: 'Forex',
  },
  {
    value: 'fxBroker',
    label: 'FX Broker',
  },
  {
    value: 'gambling',
    label: 'Gambling',
  },
  {
    value: 'gaming',
    label: 'Gaming',
  },
  {
    value: 'healthCare',
    label: 'Health Care',
  },
  {
    value: 'holdingCompany',
    label: 'Holding Company',
  },
  {
    value: 'hospitality',
    label: 'Hospitality',
  },
  {
    value: 'hosting',
    label: 'Hosting',
  },
  {
    value: 'humanResources',
    label: 'Human Resources',
  },
  {
    value: 'individuals',
    label: 'Individuals',
  },
  {
    value: 'informationTechnology',
    label: 'Information Technology',
  },
  {
    value: 'insurance',
    label: 'Insurance',
  },
  {
    value: 'internalAccount',
    label: 'Internal Account',
  },
  {
    value: 'internet',
    label: 'Internet',
  },
  {
    value: 'lender',
    label: 'Lender',
  },
  {
    value: 'logistics',
    label: 'Logistics',
  },
  {
    value: 'lottoMessenger',
    label: 'Lotto Messenger',
  },
  {
    value: 'luxuryGoods',
    label: 'Luxury Goods',
  },
  {
    value: 'manufacturing',
    label: 'Manufacturing',
  },
  {
    value: 'marine',
    label: 'Marine',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'marketplace',
    label: 'Marketplace',
  },
  {
    value: 'mining',
    label: 'Mining',
  },
  {
    value: 'nutra',
    label: 'Nutra',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'payroll',
    label: 'Payroll',
  },
  {
    value: 'pharma',
    label: 'Pharma',
  },
  {
    value: 'prePaidCards',
    label: 'Pre-Paid Cards',
  },
  {
    value: 'propertyInvestment',
    label: 'Property Investment',
  },
  {
    value: 'psp',
    label: 'PSP',
  },
  {
    value: 'publishing',
    label: 'Publishing',
  },
  {
    value: 'pulpAndPaper',
    label: 'Pulp and Paper',
  },
  {
    value: 'remittance',
    label: 'Remittance',
  },
  {
    value: 'retail',
    label: 'Retail',
  },
  {
    value: 'SaaS',
    label: 'Saas',
  },
  {
    value: 'skillGames',
    label: 'Skill Games',
  },
  {
    value: 'software',
    label: 'Software',
  },
  {
    value: 'steel',
    label: 'Steel',
  },
  {
    value: 'streaming',
    label: 'Streaming',
  },
  {
    value: 'telecommunications',
    label: 'Telecommunications',
  },
  {
    value: 'textiles',
    label: 'Textiles',
  },
  {
    value: 'timber',
    label: 'Timber',
  },
  {
    value: 'transport',
    label: 'Transport',
  },
  {
    value: 'travel',
    label: 'Travel',
  },
  {
    value: 'water',
    label: 'Water',
  },
  {
    value: 'webhosting',
    label: 'Webhosting',
  },
];
