export const manualResults = {
  byId: {
    CLEAR: {
      id: 'CLEAR',
      label: 'Approved',
      color: 'success',
    },
    CONSIDER: {
      id: 'CONSIDER',
      label: 'Denied',
      color: 'warning',
    },
  },
  allIds: ['CLEAR', 'CONSIDER'],
};
