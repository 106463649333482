import { FilterScheme } from 'types';

export const filterScheme: FilterScheme[] = [
  {
    field: 'select',
    name: 'mainStatus',
    label: 'Application Status',
  },
  {
    field: 'select',
    name: 'assignee',
    label: 'Assigned to',
  },
  {
    field: 'select',
    name: 'riskStatus',
    label: 'Risk Status',
  },
  {
    field: 'text',
    name: 'salesManager',
    label: 'Sales Manager',
  },
];
