// Please use ConfirmDialog from uikit

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from 'uikit';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  title,
  text,
  confirmButtonText,
  confirmButtonColor = 'primary',
  cancelButtonText = 'Cancel',
  showCancelButton = true,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} type="button">
        {cancelButtonText}
      </Button>
      {showCancelButton ? (
        <Button
          onClick={onConfirm}
          type="submit"
          theme={confirmButtonColor}
          variant="light"
          autoFocus
        >
          {confirmButtonText}
        </Button>
      ) : null}
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
};

export default ConfirmationDialog;
