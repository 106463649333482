/*
// ButtonComponent
 */
import React, { memo, forwardRef, ForwardedRef, ForwardRefRenderFunction } from 'react';
import './Button.scss';
import classNames from 'classnames';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { ButtonProps } from './Button.types';

const InternalButton: ForwardRefRenderFunction<unknown, ButtonProps> = (
  props: ButtonProps,
  ref: ForwardedRef<any>,
) => {
  const {
    theme = 'base',
    variant = 'text',
    shape,
    children,
    size,
    bold,
    disabled,
    type,
    prefix,
    suffix,
    className,
    block,
    wide,
    loading,
    iconButton,
    ...other
  } = props;

  let buttonClassNames = classNames({
    ButtonBase: true,

    Base: theme === 'base',
    Primary: theme === 'primary',
    Success: theme === 'success',
    Danger: theme === 'danger',
    Warning: theme === 'warning',

    Text: variant === 'text',
    Contained: variant === 'contained',
    Light: variant === 'light',
    Outlined: variant === 'outlined',

    XS: size === 'xs',
    SM: size === 'sm',
    MD: size === 'md',
    LG: size === 'lg',

    Circle: shape === 'circle',
    Square: shape === 'square',
    Rounded: shape === 'rounded',

    IconButton: iconButton,

    Bold: bold,
    Loading: loading,
    Wide: wide,
    Block: block,
  });

  buttonClassNames += className ? ` ${className}` : '';

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onClick } = props;

    if (onClick) {
      (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)(e);
    }
  };

  return (
    <button
      {...other}
      disabled={disabled || loading}
      ref={ref}
      onClick={handleClick}
      type={type || 'button'}
      className={buttonClassNames}
    >
      {prefix && <span className="buttonPrefix">{prefix}</span>}
      {!iconButton && children}
      {!iconButton && suffix && <span className="buttonSuffix">{suffix}</span>}

      {loading && (
        <span className="preLoader">
          <span className="preloaderAnimator">
            <RotateRightIcon sx={{ fontSize: 'inherit' }} />
          </span>
        </span>
      )}
    </button>
  );
};

const Button = memo(forwardRef<unknown, ButtonProps>(InternalButton));
Button.displayName = 'Button';
export { Button };
