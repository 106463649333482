import { FilterScheme } from 'types';

const defaultFilterScheme: FilterScheme[] = [
  {
    field: 'text',
    name: 'amountFrom',
    label: 'Amount From',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'amountTo',
    label: 'Amount To',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'assigneeId',
    label: 'Assignee',
  },
  {
    field: 'text',
    name: 'beneficiaryAccountNumber',
    label: 'Beneficiary Account Number',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryActivityType',
    label: 'Beneficiary Activity Type',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'beneficiaryBankCountry',
    label: 'Beneficiary Bank Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryBic',
    label: 'Beneficiary BIC Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryIban',
    label: 'Benficiary IBAN',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiaryName',
    label: 'Beneficiary Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'beneficiarySortCode',
    label: 'Beneficiary Sort Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'selectMulti',
    name: 'caseCategories',
    label: 'Case Categories',
    initialFilterValue: [],
  },
  {
    field: 'select',
    name: 'caseType',
    label: 'Case Type',
    initialFilterValue: [],
  },
  {
    field: 'select',
    name: 'caseStatus',
    label: 'Case Status',
  },
  {
    field: 'text',
    name: 'clientId',
    label: 'Client ID',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'clientName',
    label: 'Client Name',
    forApiNames: ['BANK'],
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'customerName',
    label: 'Customer Name',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'text',
    name: 'customerEmail',
    label: 'Customer Email',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'riskStatus',
    label: 'Client Risk Status',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'datePicker',
    name: 'startCreationDate', // constant name
    label: 'Date Range',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'endCreationDate', // constant name
    label: 'hide', // constant label
    initialFilterValue: null,
  },
  {
    field: 'text',
    name: 'parentName',
    label: 'Parent Name',
    forApiNames: ['BANK'],
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'maskedPan',
    label: 'Masked Pan',
    tenant: 1,
    product: 'GATEWAY',
  },
  {
    field: 'select',
    name: 'registrationCountry',
    label: 'Registration country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'referenceId',
    label: 'Reference ID',
  },
  {
    field: 'selectMulti',
    name: 'riskLevel',
    label: 'Risk Response',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'ruleCategoryId',
    label: 'Rule Category',
    initialFilterValue: [],
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'selectMulti',
    name: 'ruleId',
    label: 'Rule ID',
    initialFilterValue: [],
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderActivityType',
    label: 'Sender Activity Type',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderIban',
    label: 'Sender IBAN',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderAccountNumber',
    label: 'Sender Account Number',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'select',
    name: 'senderBankCountry',
    label: 'Sender Bank Country',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderBic',
    label: 'Sender BIC Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderName',
    label: 'Sender Name',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'text',
    name: 'senderSortCode',
    label: 'Sender Sort Code',
    tenant: 3,
    product: 'BANK',
  },
  {
    field: 'selectMulti',
    name: 'transactionType',
    label: 'Transaction Type', // PAYIN, PAYOUT, TRANSFER, DIRECT_DEBIT
    initialFilterValue: [],
    tenant: 3,
    product: 'BANK',
  },
];

const applicationsFilterScheme: FilterScheme[] = [
  {
    field: 'datePicker',
    name: 'startCreationDate', // constant name
    label: 'Date Range',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'endCreationDate', // constant name
    label: 'hide', // constant label
    initialFilterValue: null,
  },
  {
    field: 'text',
    name: 'description',
    label: 'Case Subject',
  },
  {
    field: 'text',
    name: 'companyName',
    label: 'Application Name',
  },
  {
    field: 'text',
    name: 'referenceId',
    label: 'Application ID',
  },
  {
    field: 'select',
    name: 'assigneeId',
    label: 'Assignee',
  },
  {
    field: 'selectMulti',
    name: 'caseCategories',
    label: 'Case Categories',
    initialFilterValue: [],
  },
  {
    field: 'select',
    name: 'caseType',
    label: 'Case Type',
    initialFilterValue: ['APPLICATION'],
  },
  {
    field: 'select',
    name: 'caseStatus',
    label: 'Case Status',
  },
];

export const getFilterScheme = (caseType): FilterScheme[] =>
  caseType === 'APPLICATION' ? applicationsFilterScheme : defaultFilterScheme;
