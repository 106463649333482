import { gatewayApi } from '../gatewayApi';

const creditsafeApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query({
      query: () => ({
        url: 'creditsafe/countries',
        method: 'GET',
      }),
      providesTags: () => [{ type: 'CreditsafeCountries', id: 'LIST' }],
      transformResponse: (response) =>
        response.map((country) => {
          const result = {
            label: country.countryName,
            value: country.countryIso2,
          };
          return result;
        }),
    }),
    getCompanies: build.query({
      query: (params) => ({
        url: 'creditsafe/companies/search',
        method: 'GET',
        params,
      }),
      providesTags: (result, error, params) => [
        { type: 'CreditsafeCompanies', id: JSON.stringify(params) },
      ],
    }),
  }),
});

export const { useGetCountriesQuery, useGetCompaniesQuery } = creditsafeApi;
