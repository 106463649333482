export const columns = [
  {
    property: 'firstName',
    title: 'First Name',
  },
  {
    property: 'lastName',
    title: 'Last Name',
  },
  // {
  //   property: 'email',
  //   title: 'Email',
  // },
  {
    property: 'docIssuingCountryCode',
    title: 'Doc Issuing Country',
  },
  {
    property: 'provider',
    title: 'Provider',
  },
  {
    property: 'kycStatus',
    title: 'KYC Status',
  },
  {
    property: 'kycResult',
    title: 'KYC Result',
  },
];
