import {
  TRANSACTIONS_MANAGEMENT,
  USERS_MANAGEMENT,
  MERCHANTS_MANAGEMENT,
  CLIENTS_MANAGEMENT,
  KYC_MANAGEMENT,
  APPLICATION_MANAGEMENT,
  CASE_MANAGEMENT,
  RULES_MANAGEMENT,
  COUNTRY_RISK_MANAGEMENT,
  RISK_WORDS_MANAGEMENT,
  UBO_MANAGEMENT,
  PEPSANCTIONS_MANAGEMENT,
} from 'constants/constants';

import { Permissions, AppMenuLink } from './AppMenu.types';
import checkPermissions from '../../../utils/checkPermissions';
import checkProductApiName from '../../../utils/checkProductApiName';

export const appMenuContent: AppMenuLink[][] = [
  // Section 1
  [
    {
      label: 'Dashboard',
      link: '/dashboard',
    },
    {
      label: 'Application Management',
      link: '/application-management',
      permissions: APPLICATION_MANAGEMENT as Permissions[],
    },
    {
      label: 'PEP and Sanctions',
      link: '/pep-and-sanctions',
      permissions: PEPSANCTIONS_MANAGEMENT as Permissions[],
    },
  ],

  // Section 2
  [
    {
      label: 'User Management',
      link: '/user-management',
      permissions: USERS_MANAGEMENT as Permissions[],
    },
    {
      label: 'KYC Applicants',
      link: '/applicants-kyc',
      permissions: KYC_MANAGEMENT as Permissions[],
    },
    {
      label: 'KYC Providers',
      link: '/providers-kyc',
      permissions: KYC_MANAGEMENT as Permissions[],
    },
    {
      label: 'Associated Individuals',
      link: '/ubo-management',
      permissions: UBO_MANAGEMENT as Permissions[],
    },
    {
      label: 'Merchants',
      link: '/merchants',
      permissions: MERCHANTS_MANAGEMENT as Permissions[],
      productAPIName: ['GATEWAY'],
    },
    {
      label: 'Merchant Categories',
      link: '/merchant-categories',
      permissions: MERCHANTS_MANAGEMENT as Permissions[],
      productAPIName: ['GATEWAY'],
    },
    {
      label: 'Clients',
      link: '/clients',
      permissions: CLIENTS_MANAGEMENT as Permissions[],
      productAPIName: ['BANK'],
    },
    {
      label: 'Client Categories',
      link: '/client-categories',
      permissions: CLIENTS_MANAGEMENT as Permissions[],
      productAPIName: ['BANK'],
    },
    {
      label: 'Acquirers',
      link: '/acquirers',
    },
  ],

  // Section 3
  [
    {
      label: 'Transactions',
      link: '/transactions',
      permissions: TRANSACTIONS_MANAGEMENT as Permissions[],
    },
    {
      label: 'Rules',
      link: '/rules',
      permissions: RULES_MANAGEMENT as Permissions[],
    },
    {
      label: 'Rules (New design)',
      link: '/rulesNew',
      permissions: RULES_MANAGEMENT as Permissions[],
    },
    {
      label: 'Rule Functions',
      link: '/rule-functions',
      permissions: RULES_MANAGEMENT as Permissions[],
    },
    {
      label: 'Rule Vetos Monitor',
      link: '/rule-vetos-monitor',
      permissions: RULES_MANAGEMENT as Permissions[],
    },
    {
      label: 'Exception Rules',
      link: '/exception-rules',
      permissions: TRANSACTIONS_MANAGEMENT as Permissions[],
    },
  ],

  // Section 4
  [
    {
      label: 'Country Risk',
      link: '/country-risk',
      permissions: COUNTRY_RISK_MANAGEMENT as Permissions[],
      productAPIName: ['BANK'],
    },
    {
      label: 'Risk Words',
      link: '/risk-words',
      permissions: RISK_WORDS_MANAGEMENT as Permissions[],
      productAPIName: ['BANK'],
    },
    {
      label: 'Card Data',
      link: '/card-data',
      permissions: TRANSACTIONS_MANAGEMENT as Permissions[],
      productAPIName: ['GATEWAY'],
    },
    {
      label: 'Validator Transactions',
      link: '/validator-transactions',
      permissions: TRANSACTIONS_MANAGEMENT as Permissions[],
      productAPIName: ['GATEWAY'],
    },
  ],

  // Section 5
  [
    {
      label: 'Cases',
      link: '/cases',
      permissions: CASE_MANAGEMENT as Permissions[],
    },
    {
      label: 'Case Assignments',
      link: '/case-assignments',
      permissions: CASE_MANAGEMENT as Permissions[],
    },
    {
      label: 'Administration',
      link: '/administration',
      permissions: ['SUPER_ADMIN'],
    },
  ],
];

const flatMenu = appMenuContent.reduce((flat, subArray) => flat.concat(subArray), []);
const linksMenu = flatMenu
  .map((el) => ({
    to: el.link,
    title: el.label,
    icon: el.icon,
    permissions: el.permissions,
    productAPIName: el.productAPIName,
  }))
  .filter(
    (element) =>
      (element.permissions ? checkPermissions(element.permissions) : true) &&
      (element.productAPIName ? checkProductApiName(element.productAPIName) : true),
  );
export const siblingsMainMenu =
  linksMenu?.map((el) => ({
    title: el.title,
    to: el.to,
    icon: el.icon,
  })) || [];
