import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

interface DrawerProps {
  children?: React.ReactChildren | React.ReactNode | React.ReactElement;
  width?: number;
  open: boolean;
  onTop?: boolean;
}

export const DetailsDrawer: React.FC<DrawerProps> = ({
  children,
  width = 636,
  open,
  onTop = true,
}) => {
  const [hidden, setHidden] = useState(!open);

  let slideOpen = false;
  setTimeout(() => {
    slideOpen = true;
  }, 30);

  useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        setHidden(true);
      }, 300); // 300ms должно совпадать с временем transition
      return () => clearTimeout(timer);
    }
    return setHidden(false);
  }, [open]);

  return (
    <Box
      className="detailsDrawerWrapper"
      id="detailsDrawerWrapper"
      sx={{
        visibility: hidden ? 'hidden' : 'visible',
        width: `${width}px`,
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: open ? 0 : `-${width}px`,
        pt: 2,
        pr: 8,
        zIndex: `${onTop ? 1050 : 950}`,
        bgcolor: 'white',
        boxShadow: '0 0 20px 0 #0000001A',
        transition: 'all 0.3s ease-in-out',
        overflowY: 'auto',
        marginRight: 'calc(0px - (100vw - 100%))',
      }}
    >
      {children || null}
    </Box>
  );
};
