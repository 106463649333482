import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const riskScoringSectionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getRiskScoringSections: build.query<any, { version: number }>({
      query: ({ version }) => ({
        url: `risk-scoring/sections`,
        params: { version },
        method: 'GET',
      }),
      providesTags: () => [{ type: 'RiskScoring_Sections', id: 'LIST' }],
    }),

    addRiskScoringSection: build.mutation({
      query(model) {
        const body = { ...model };
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/sections`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'RiskScoring_Sections', id: 'LIST' }],
    }),

    updateRiskScoringSection: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/sections/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'RiskScoring_Sections', id: 'LIST' }],
    }),

    deleteRiskScoringSection: build.mutation({
      query: (id) => ({
        url: `risk-scoring/sections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'RiskScoring_Sections', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetRiskScoringSectionsQuery,
  useAddRiskScoringSectionMutation,
  useUpdateRiskScoringSectionMutation,
  useDeleteRiskScoringSectionMutation,
} = riskScoringSectionsApi;
