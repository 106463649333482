/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_RULE_STATS,
  LOAD_RULE_STATS_SUCCESS,
  LOAD_RULE_STATS_FAIL,
} from '../constants/constants';

export const initialState = [];

const ruleStatsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_RULE_STATS: {
        const idx = state.findIndex((val) => val.id === action.id);
        if (idx === -1) {
          draft.push({
            id: action.id,
            loading: true,
            error: false,
          });
          break;
        }

        draft[idx].loading = false;
        draft[idx].error = false;

        break;
      }

      case LOAD_RULE_STATS_SUCCESS: {
        const idx = state.findIndex((val) => val.id === action.id);
        if (idx === -1) break;

        draft[idx].loading = false;
        draft[idx].stats = action.stats;

        break;
      }

      case LOAD_RULE_STATS_FAIL: {
        const idx = state.findIndex((val) => val.id === action.id);
        if (idx === -1) break;

        draft[idx].loading = false;
        draft[idx].error = action.error;

        break;
      }
    }
  });

export default ruleStatsReducer;
