import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  to: yup
    .array()
    .min(1, 'Please add a recipient')
    .of(yup.string().required('Required').email('Invalid email')),
  email: yup.string().email('Invalid email'),
  subject: yup.string().required('Required'),
  body: yup.string().required('Required'),
});
