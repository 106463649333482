import React, { memo, forwardRef, ForwardedRef, ForwardRefRenderFunction } from 'react';
import { ButtonProps } from './ButtonTypes';
import './Button.scss';

const InternalButton: ForwardRefRenderFunction<unknown, ButtonProps> = (
  props: ButtonProps,
  ref: ForwardedRef<any>,
) => {
  const {
    theme,
    shape,
    children,
    size,
    disabled,
    type,
    childrenLeft,
    childrenRight,
    className,
    block,
    ...rest
  } = props;
  const baseName = 'baseButton';
  let classNames = baseName;

  classNames += className ? ` ${className}` : '';

  classNames += block ? ` ${baseName}--block` : ``;

  classNames += shape === 'square' ? ` ${baseName}--square` : '';

  classNames +=
    size === 'md'
      ? ` ${baseName}--${size}`
      : size === 'lg'
      ? ` ${baseName}--${size}`
      : size === 'xs'
      ? ` ${baseName}--${size}`
      : '';

  classNames +=
    theme === 'primary'
      ? ` ${baseName}--${theme}`
      : theme === 'text'
      ? ` ${baseName}--${theme}`
      : theme === 'dark'
      ? ` ${baseName}--${theme}`
      : theme === 'defaultDanger'
      ? ` ${baseName}--${theme}`
      : theme === 'defaultSuccess'
      ? ` ${baseName}--${theme}`
      : theme === 'defaultAccent'
      ? ` ${baseName}--${theme}`
      : '';

  return (
    <button type={type} disabled={disabled} ref={ref} className={classNames} {...rest}>
      {childrenLeft && <span className="childrenLeft">{childrenLeft}</span>}

      {children}

      {childrenRight && <span className="childrenRight">{childrenRight}</span>}
    </button>
  );
};

const Button = memo(forwardRef<unknown, ButtonProps>(InternalButton));
Button.displayName = 'IconButton';
export { Button };
