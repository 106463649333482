import React, { ChangeEvent, useState } from 'react';
import { Panel, FormRow, Checkbox, FormBlock, FormLabel } from 'uikit';
import { Checkbox as MUICheckbox, Typography, Box, Grid } from '@mui/material';

import { palette } from 'theme/palette';

export function CheckboxesSection() {
  const [checked, setChecked] = useState<boolean>(false);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked);

  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        Checkboxes
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6} sm={8} xs={12}>
          <Panel>
            <FormRow>
              <Checkbox
                disabled
                checked={checked}
                onChange={(checkedState) => setChecked(checkedState)}
              >
                This Checkbox is no longer supported
              </Checkbox>
            </FormRow>
            <FormRow>
              <Checkbox checked={checked} onChange={(checkedState) => setChecked(checkedState)}>
                This Checkbox is no longer supported
              </Checkbox>
            </FormRow>
          </Panel>

          <Panel>
            <FormBlock>
              <FormLabel>
                <MUICheckbox checked={checked} onChange={changeHandler} />
                Some label.
              </FormLabel>
            </FormBlock>
          </Panel>

          <Panel>
            <FormBlock>
              <FormLabel>
                <MUICheckbox checked={checked} onChange={changeHandler} />
                Some label
              </FormLabel>
              <Typography color={palette.grey[600]} variant="body2">
                Some description for the checkbox
              </Typography>
            </FormBlock>
          </Panel>
        </Grid>
      </Grid>
    </Box>
  );
}
