import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import './InputBox.scss';
import CancelIcon from '@mui/icons-material/Cancel';

interface InputBoxProps {
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => any;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => any;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;

  disabled?: boolean;
  placeholder?: string;
  name?: string;
  readOnly?: boolean;
  borderless?: boolean;
  handleClear?: () => any;
  hasError?: boolean;
}

const InternalInputBox = (props, ref) => {
  const {
    handleClear,
    readOnly,
    borderless = false,
    type,
    value,
    label,
    disabled,
    placeholder,
    name,
    onChange,
    onFocus,
    onBlur,
    onClick,
    hasError,
    ...other
  } = props;

  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const clearHandler = () => {
    handleClear && handleClear();
    innerRef?.current?.focus();
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`
      InputBox
      InputBox--${value ? 'touched' : 'untouched'}
      ${disabled ? 'InputBox--disabled' : ''}
      ${readOnly ? 'InputBox--readOnly' : ''}
      ${borderless ? 'InputBox--borderless' : ''}
      ${hasError ? 'InputBox--hasError' : ''}

    `}
    >
      <label className="InputBoxLabelEl">
        <span className="InputBoxLabel">{label}</span>
        <input
          ref={innerRef}
          readOnly={readOnly}
          type={type || 'text'}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          className="InputBoxEl"
          value={value}
          onChange={changeHandler}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          {...other}
        />
      </label>

      {handleClear && !disabled && !readOnly && value && (
        <div className="iconContainer" onClick={clearHandler}>
          <CancelIcon style={{ fontSize: '20px' }} />
        </div>
      )}
    </div>
  );
};

const InputBox = forwardRef<any, any>(InternalInputBox);
InputBox.displayName = 'Input';
export default InputBox;
