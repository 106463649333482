import React, { Dispatch, SetStateAction } from 'react';
import { Box, Tabs, Tab, Grid } from '@mui/material';
import { UsersHeadData, RolesHeadData } from '../constants';
import { SortBlock } from 'uikit';

interface UsersManagementHeadProps {
  sortedBy: any;
  setSortedBy: Dispatch<SetStateAction<any>>;
  handleChangeTab: any;
  valueTab: number;
  TabPanel: React.FC<any>; // react component
  isFetching: boolean;
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  sx: {
    ':not(:disabled):focus': {
      boxShadow: '0px 0px black',
    },
  },
});

export const UsersManagementHead: React.FC<UsersManagementHeadProps> = ({
  sortedBy,
  setSortedBy,
  valueTab,
  handleChangeTab,
  TabPanel,
}) => {
  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 6 }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Users and Roles tabs">
          <Tab label="Users" value={0} {...a11yProps(0)} />
          <Tab label="Roles" value={1} {...a11yProps(1)} />
        </Tabs>
      </Box>

      {/* Users */}
      <TabPanel value={valueTab} index={0}>
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: '8px', width: '100%', height: '100%', color: 'grey.600' }}
        >
          <Grid item md={4}>
            <SortBlock
              property={UsersHeadData[0].property}
              sortedBy={sortedBy}
              handleSort={handleSort}
            >
              {UsersHeadData[0].title}
            </SortBlock>
          </Grid>
          <Grid
            item
            md={8}
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            {UsersHeadData.slice(1, UsersHeadData.length).map((item, i) => (
              <Grid item md={item.width} key={i}>
                <SortBlock
                  property={item.property}
                  sortedBy={sortedBy}
                  handleSort={item.property !== '' ? handleSort : null}
                >
                  {item.title}
                </SortBlock>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Roles */}
      <TabPanel value={valueTab} index={1}>
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: '12px', width: '100%', height: '100%', color: 'grey.600' }}
        >
          <Grid item md={5}>
            <SortBlock
              property={RolesHeadData[0].property}
              sortedBy={sortedBy}
              handleSort={handleSort}
            >
              {RolesHeadData[0].title}
            </SortBlock>
          </Grid>
          <Grid
            item
            md={7}
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            {RolesHeadData.slice(1, RolesHeadData.length).map((item, i) => (
              <Grid item md={item.width} key={i}>
                <SortBlock
                  property={item.property}
                  sortedBy={sortedBy}
                  handleSort={item.property !== '' ? handleSort : null}
                >
                  {item.title}
                </SortBlock>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
};
