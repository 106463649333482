import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

export const label: TypographyStyleOptions = {
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
};
