import React from 'react';
import { Box } from '@mui/material';

export const AdornmentWrapper = ({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled: boolean;
}) => {
  if (!children) {
    return null;
  }
  return (
    <Box
      sx={{
        color: disabled ? 'grey.300' : 'grey.900',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};
