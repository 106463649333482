import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { RiDownloadLine, RiCheckboxCircleFill, RiForbidFill } from 'react-icons/ri';
import { Individual } from 'services/gatewayApi/applicationIndividualsApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import {
  useDeleteManualKycIndividualStatusMutation,
  useSetManualKycIndividualStatusMutation,
} from 'services/gatewayApi/kycApplicantsApi';
import { formatDateScreen, parseDate } from 'utils';
import { FormTextField, ModalDialog } from 'uikit';
import { DATE_FORMAT } from 'constants/constants';
import { capitalizeExcept } from 'utils/capitalize';
import { downloadFile } from 'utils/downloadFile';
import { KycStatusChip } from '../../KycStatusChip';
import { overviewSections, overviewGroups, affiliationTypes, documentTypes } from '../../constants';
import { ManualStatusDescription } from './ManualStatusDescription';

const GroupBox = styled(Box, {
  name: 'GroupBox',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    border: '1px solid',
    borderColor: 'grey.300',
    borderRadius: '12px',
    mt: 1,
    px: 3,
    py: 2,
  }),
);

interface OverviewTabProps {
  applicationId: string;
  individual: Individual;
}

export const OverviewTab: React.FC<OverviewTabProps> = ({ applicationId, individual }) => {
  const {
    firstName,
    middleName,
    lastName,
    affiliations,
    properties,
    kycStatus,
    kycManualStatusReason,
    kycApplicantId,
    kycManualStatus,
  } = individual || {};

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string | undefined>(undefined);
  const [openDialogManualKYC, setOpenDialogManualKYC] = useState<
    undefined | 'approved' | 'rejected'
  >(undefined);
  const [openConfirmRemoveManualKYC, setOpenConfirmRemoveManualKYC] = useState<boolean>(false);
  const [openConfirmSetAutoKYC, setOpenConfirmSetAutoKYC] = useState<boolean>(false);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countries: (data as any[])?.map((c) => ({
        value: c.countryCode,
        label: c.name,
      })),
      isLoading: isLoadingCountries,
    }),
  });

  const [setManualKycStatus, { isLoading: isSettingManualStatus }] =
    useSetManualKycIndividualStatusMutation();
  const [deleteManualKycStatus] = useDeleteManualKycIndividualStatusMutation();

  const RenderValue = ({ property }) => {
    const value = individual[property];

    switch (property) {
      case 'fullName':
        return [firstName, middleName, lastName].join(' ');

      case 'country':
      case 'nationality':
      case 'proofOfIdentityCountry':
      case 'proofOfAddressCountry':
        return countries?.find((el) => el.value === value)?.label || value || '-';

      case 'dateOfBirth':
        return value
          ? value.length === 10
            ? formatDateScreen(parseDate(value, DATE_FORMAT))
            : formatDateScreen(value)
          : '-';

      case 'proofOfIdentityType':
      case 'proofOfAddressType':
        return documentTypes?.find((el) => el.value === value)?.label || value;

      case 'proofOfIdentityFileFront':
      case 'proofOfIdentityFileBack':
      case 'proofOfAddressFileFront': {
        const documentId =
          value && value.indexOf(':') > 0 && value.substring(0, value.indexOf(':'));

        return (
          <IconButton
            size="small"
            disabled={isDownloading || !documentId}
            onClick={() => {
              setIsDownloading(true);
              downloadFile(
                `/api/gateway/applications/${applicationId}/docs/${documentId}/download`,
                { download: true },
              ).finally(() => setIsDownloading(false));
            }}
            sx={{ color: 'text.link' }}
          >
            <RiDownloadLine size="14px" />
          </IconButton>
        );
      }

      case 'kycStatus':
        return (
          <KycStatusChip
            status={kycManualStatus ?? kycStatus}
            description={kycManualStatusReason}
          />
        );

      default:
        return value ?? '-';
    }
  };

  const handleRemoveManualStatus = () => {
    setOpenConfirmRemoveManualKYC(false);
    deleteManualKycStatus({ kycApplicantId });
  };

  const handleSetAutoKYC = () => {
    setOpenConfirmSetAutoKYC(false);
  };

  const handleOpenDialogManualKYC = (status: 'approved' | 'rejected') => {
    setOpenDialogManualKYC(status);
    handleCloseMenu();
  };

  const handleSetManualKYC = () => {
    setManualKycStatus({
      kycApplicantId,
      manualResult: openDialogManualKYC === 'approved' ? 'CLEAR' : 'CONSIDER',
      manualDecisionReason: warningText,
    })
      .unwrap()
      .then(() => {
        setWarningText(undefined);
      });
    setOpenDialogManualKYC(undefined);
  };

  return (
    <>
      {individual && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
            <Typography variant="body" sx={{ py: 1, color: 'grey.600' }}>
              {properties?.SHAREHOLDER_TYPE
                ? `${capitalizeExcept(properties.SHAREHOLDER_TYPE)}, `
                : ''}
              {affiliations &&
                affiliations
                  .map(
                    (a) =>
                      affiliationTypes.find((at) => at.value === a.type)?.label ?? 'Unknown Type',
                  )
                  .join(', ')}
            </Typography>

            <Typography variant="body" sx={{ py: 1, color: 'grey.900' }}>
              {`${properties?.SHAREHOLDER_SHARES_PERCENTAGE ?? '0'}%`}
            </Typography>
          </Stack>

          {overviewSections.map((section) => (
            <div key={section.id}>
              <Divider sx={{ my: 2 }} />
              {section.fields.map((field) => (
                <Stack
                  key={field.value}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Typography variant="body" sx={{ py: 1, color: 'grey.600' }}>
                    {field.label}
                  </Typography>
                  <Typography variant="body" sx={{ py: 1, color: 'grey.900' }}>
                    <RenderValue property={field.value ?? '-'} />
                  </Typography>
                </Stack>
              ))}
            </div>
          ))}

          {!kycManualStatus && kycApplicantId && (
            <>
              <Button
                variant="text"
                color="secondary"
                type="button"
                sx={{ margin: '16px 0 8px', display: 'block' }}
                onClick={(e) => setAnchorMenu(e.currentTarget)}
              >
                Set KYC status manually
              </Button>
              <Menu
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={() => setAnchorMenu(null)}
                onClick={() => setAnchorMenu(null)}
              >
                <MenuItem onClick={() => handleOpenDialogManualKYC('approved')}>
                  <Stack direction="row" alignItems="center" spacing={2} color="success.main">
                    <RiCheckboxCircleFill />
                    <Typography>Approved</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => handleOpenDialogManualKYC('rejected')}>
                  <Stack direction="row" alignItems="center" spacing={2} color="danger.main">
                    <RiForbidFill />
                    <Typography>Rejected</Typography>
                  </Stack>
                </MenuItem>
              </Menu>
            </>
          )}

          {kycManualStatus && (
            <ManualStatusDescription
              description={kycManualStatusReason}
              handleRemove={() => setOpenConfirmRemoveManualKYC(true)}
            />
          )}

          {/* TODO: return button when BE will be ready */}
          {/* {!kycManualStatus && ( */}
          {/*  <Button */}
          {/*    variant="outlined" */}
          {/*    color="primary" */}
          {/*    onClick={() => setOpenConfirmSetAutoKYC(true)} */}
          {/*    // sx={{ margin: '6px 0 0 auto' }} */}
          {/*  > */}
          {/*    Resend to automatic KYC */}
          {/*  </Button> */}
          {/* )} */}

          {overviewGroups.map((section) => {
            const isGroupVisible = !!individual[section.fields[0].value];

            return (
              isGroupVisible && (
                <Box key={section.id} sx={{ mt: 4 }}>
                  {section.label && (
                    <Typography variant="bodyBold" component="span">
                      {section.label}
                    </Typography>
                  )}

                  <GroupBox>
                    {section.fields.map((field) => (
                      <Stack
                        key={field.value}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={4}
                      >
                        <Typography variant="body" sx={{ py: 1, color: 'grey.600' }}>
                          {field.label}
                        </Typography>
                        <Typography variant="body" sx={{ py: 1, color: 'grey.900' }}>
                          <RenderValue property={field.value ?? '-'} />
                        </Typography>
                      </Stack>
                    ))}
                  </GroupBox>
                </Box>
              )
            );
          })}
        </>
      )}
      <ModalDialog
        open={Boolean(openDialogManualKYC)}
        onClose={() => setOpenDialogManualKYC(undefined)}
        title={`Confirm KYC Manual ${
          openDialogManualKYC === 'approved' ? 'Approvement' : 'Rejection'
        }`}
        hasDivider
        disableAction
        maxWidth="sm"
      >
        <Typography pb="8px">Warning text</Typography>
        <FormTextField
          autoFocus
          value={warningText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setWarningText(event.target.value);
          }}
          multiline
          rows={3}
          placeholder="Please explain why you decided to do it manually"
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end" pt="16px">
          <Button color="base" variant="text" onClick={() => setOpenDialogManualKYC(undefined)}>
            Cancel
          </Button>
          <Button
            color={openDialogManualKYC === 'approved' ? 'successLight' : 'danger'}
            variant="contained"
            disabled={!warningText}
            onClick={handleSetManualKYC}
            startIcon={
              openDialogManualKYC === 'approved' ? (
                <RiCheckboxCircleFill size={16} />
              ) : (
                <RiForbidFill size={16} />
              )
            }
          >
            {openDialogManualKYC === 'approved' ? 'Approved' : 'Rejected'}
          </Button>
        </Stack>
      </ModalDialog>
      <ModalDialog
        open={openConfirmRemoveManualKYC}
        onClose={() => setOpenConfirmRemoveManualKYC(undefined)}
        title="Remove manual KYC status?"
        hasDivider
        maxWidth="sm"
        handleAction={handleRemoveManualStatus}
        actionTitle="Remove"
        variant="error"
      />
      <ModalDialog
        open={openConfirmSetAutoKYC}
        onClose={() => setOpenConfirmSetAutoKYC(false)}
        title="Restart KYC Process for this Individual?"
        hasDivider
        maxWidth="sm"
        handleAction={handleSetAutoKYC}
        actionTitle="Resend"
        variant="error"
      >
        <Typography>
          This will remove current KYC status and starts KYC automatic process from the begining
        </Typography>
      </ModalDialog>
    </>
  );
};
