/*
// Application List. Grid head.
*/

import React, { Dispatch, SetStateAction } from 'react';
import { SortBlock } from 'uikit';
import { applicationsHeadData } from './applications-head-data';
import { ApplicationSort } from '../../types';
import '../ApplicationRow.scss';

interface ApplicationHeadProps {
  sortedBy: ApplicationSort;
  setSortedBy: Dispatch<SetStateAction<ApplicationSort>>;
}

const ApplicationHead: React.FC<ApplicationHeadProps> = ({ sortedBy, setSortedBy }) => {
  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };

    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  return (
    <div className="ApplicationRow ApplicationsHead">
      {applicationsHeadData.map((item) => (
        <div className={item.className} key={item.property}>
          <SortBlock
            property={item.property}
            sortedBy={sortedBy}
            handleSort={item.isSortable ? handleSort : undefined}
          >
            {item.title}
          </SortBlock>
        </div>
      ))}
    </div>
  );
};

export { ApplicationHead };
