import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import {
  fontFamilyBase as fontFamily,
  h1,
  h2,
  h3,
  h4,
  body1,
  subtitle1,
  body2,
  subtitle2,
  caption1,
  button,
  label,
  body,
  bodySmall,
  bodyBold,
  bodyPlus,
  bodyPlusBold,
} from './typography-blocks';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: TypographyStyleOptions;
    bodySmall: TypographyStyleOptions;
    body: TypographyStyleOptions;
    bodyBold: TypographyStyleOptions;
    bodyPlus: TypographyStyleOptions;
    bodyPlusBold: TypographyStyleOptions;
    caption1?: TypographyStyleOptions;
    subtitle1?: TypographyStyleOptions;
    subtitle2?: TypographyStyleOptions;
    body1?: TypographyStyleOptions;
    body2?: TypographyStyleOptions;
  }
  interface TypographyVariantsOptions {
    label?: TypographyStyleOptions;
    bodySmall?: TypographyStyleOptions;
    body?: TypographyStyleOptions;
    bodyBold?: TypographyStyleOptions;
    bodyPlus?: TypographyStyleOptions;
    bodyPlusBold?: TypographyStyleOptions;
    caption1?: TypographyStyleOptions;
    subtitle1?: TypographyStyleOptions;
    subtitle2?: TypographyStyleOptions;
    body1?: TypographyStyleOptions;
    body2?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    bodySmall: true;
    body: true;
    bodyBold: true;
    bodyPlus: true;
    bodyPlusBold: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: false;
    h6: false;
    body1: true;
    body2: true;
    subtitle1: true;
    subtitle2: true;
    caption: false;
    caption1: true;
    overline: false;
    button: false;
  }
}

export const typography: TypographyOptions = {
  fontFamily,
  h1, // 26px
  h2, // 20px
  h3, // 17px weight 500
  h4, // 17px
  body1, // 14px
  subtitle1, // 14px weight 600
  body2, // 12px
  subtitle2, // 12px weight 600
  caption1, // 11px uppercase
  button, // 14px
  label, // 13px uppercase
  bodySmall, // 12px
  body, // 14px
  bodyBold, // 14px weight 700
  bodyPlus, // 16px
  bodyPlusBold, // 16px weight 700
};
