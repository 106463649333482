export const overviewGroups = [
  {
    id: 'proofOfIdentity',
    label: 'Proof of Identity',
    fields: [
      { value: 'proofOfIdentityType', label: 'Document Type'},
      { value: 'proofOfIdentityCountry', label: 'Country of Issue' },
      { value: 'proofOfIdentityFileFront', label: 'Document Front Side' },
      { value: 'proofOfIdentityFileBack', label: 'Document Back Side' },
    ],
  },
  {
    id: 'proofOfAddress',
    label: 'Proof of Address',
    fields: [
      { value: 'proofOfAddressType', label: 'Document Type'},
      { value: 'proofOfAddressCountry', label: 'Country of Issue' },
      { value: 'proofOfAddressFileFront', label: 'Document Front Side' },
    ],
  },
];

export const overviewSections = [
  {
    id: 'general',
    fields: [
      { value: 'id', label: 'ID' },
      { value: 'fullName', label: 'Full Name' },
      { value: 'dateOfBirth', label: 'Date of Birth' },
      { value: 'placeOfBirth', label: 'Place of Birth' },
      { value: 'nationality', label: 'Nationality' },
      { value: 'passport', label: 'Passport/ID Number' },
    ],
  },
  {
    id: 'adress',
    fields: [
      { value: 'address', label: 'Address' },
      { value: 'city', label: 'City' },
      { value: 'country', label: 'Country' },
      { value: 'postcode', label: 'Postcode' },
    ],
  },
  {
    id: 'email',
    fields: [
      { value: 'email', label: 'Email' },
    ],
  },
  {
    id: 'kyc',
    fields: [
      { value: 'kycStatus', label: 'KYC Status' },
    ],
  },
];
