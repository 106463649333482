import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as PropTypes from 'prop-types';
import { riskStatuses, eeaRiskStatuses } from './constants';
import {
  useCanEditRiskCountry,
  useCanEditEeaRiskCountry,
} from '../../hooks/useHasPermissions';

export const CountryRiskFormDialogStatus = ({ riskStatusType, values, onChange }) => {
  const canEditRiskCountry = useCanEditRiskCountry();
  const canEditEeaRiskCountry = useCanEditEeaRiskCountry();

  const canEdit = (type) => {
    switch (type) {
      case 'riskStatus':
        return canEditRiskCountry;
      case 'eeaRiskStatus':
        return canEditEeaRiskCountry;
      default:
        return false;
    }
  };

  let statuses = [];
  switch (riskStatusType.value) {
    case 'eeaRiskStatus':
      statuses = eeaRiskStatuses;
      break;

    default:
      statuses = riskStatuses;
  }

  return canEdit(riskStatusType.value) ? (
    <Grid key={riskStatusType.value} item xs={4}>
      <FormControl component="fieldset" disabled={values[riskStatusType.value] === 'DELETED'}>
        <FormLabel component="legend">{riskStatusType.label}</FormLabel>
        <RadioGroup
          id={riskStatusType.value}
          name={riskStatusType.value}
          value={values[riskStatusType.value]}
          onChange={onChange}
        >
          {statuses.allIds.map((id) =>
            id === 'DELETED' ? null : (
              <FormControlLabel
                key={id}
                control={<Radio />}
                value={id}
                label={statuses.byId[id].label}
              />
            ),
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
  ) : (
    <></>
  );
};

CountryRiskFormDialogStatus.propTypes = {
  values: PropTypes.shape({
    countryCode: PropTypes.string,
    onboardingRiskStatus: PropTypes.string,
    riskStatus: PropTypes.string,
    uboRiskStatus: PropTypes.string,
    clientRiskStatus: PropTypes.string,
    eeaRiskStatus: PropTypes.string,
  }),
  onChange: PropTypes.func,
  riskStatusType: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};
