import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import * as _ from 'lodash';
import { useFormik } from 'formik';
import { Box, Checkbox, TextField, Typography } from '@mui/material';
import { RULE_NAME_REGEX, USERNAMEFULL_REGEX } from 'constants/constants';
import { toastCreateSuccess } from 'utils/toast';
import {
  useAddGroupsMutation,
  useCreateCustomerUserMutation,
  useGetCustomerUserQuery,
  useGetGroupsQuery,
  useRemoveGroupsMutation,
  useResetPasswordCustomerUserMutation,
  useUpdateCustomerUserMutation,
} from 'services/gatewayApi/usersApi';
import { FormBlock, FormLabel, ModalDialog } from 'uikit';
import { UserDialogProps } from '../types';

interface UserDialogPropsModal {
  userDialogProps: UserDialogProps;
  setUserDialogProps: React.Dispatch<React.SetStateAction<UserDialogProps>>;
}

export const UserDialog: React.FC<UserDialogPropsModal> = ({
  userDialogProps,
  setUserDialogProps,
}) => {
  const { open, customerId, applicationId } = userDialogProps;
  const isNew = customerId === 'new';

  const onClose = () => setUserDialogProps({ open: false, customerId: '', applicationId });

  const {
    data: customerUser,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetCustomerUserQuery(
    {
      applicationId,
      customerId,
    },
    { skip: isNew || !applicationId },
  );

  const { groupsList = [], isLoadingRoles } = useGetGroupsQuery(undefined, {
    selectFromResult: ({ data = [], isLoading }) => ({
      groupsList: Object.values(data)
        .flat()
        .filter((el) => el.permissions[0]?.scope === 'CUSTOMER'),
      isLoadingRoles: isLoading,
    }),
  });

  const [createCustomerUser, statusesUserCreated] = useCreateCustomerUserMutation();
  const [updateCustomerUser] = useUpdateCustomerUserMutation();
  const [sendActivationEmail, statusesSendLink] = useResetPasswordCustomerUserMutation();
  const [addGroups] = useAddGroupsMutation();
  const [removeGroups] = useRemoveGroupsMutation();

  const [emailSent, setEmailSent] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const userRoles = !isNew ? customerUser?.groups?.map((el) => el.id) : [];

  useEffect(() => {
    if (statusesUserCreated.status === 'fulfilled') {
      setUserDialogProps({
        open: true,
        customerId: statusesUserCreated.data.customerId,
        applicationId,
      });
    }
  }, [statusesUserCreated]);

  useEffect(() => {
    isLoadingUser === false && !isNew && customerUser && setSelectedRoles(userRoles);
  }, [isLoadingUser, isFetchingUser]);

  useEffect(() => {
    if (statusesSendLink.isSuccess === true) {
      toastCreateSuccess(`Activation link for ${customerUser.email}`);
    }
  }, [statusesSendLink]);

  const validationSchema = yup.object({
    firstName: yup.string().required('Required').matches(RULE_NAME_REGEX, 'Only text, please'),
    lastName: yup.string().required('Required').matches(USERNAMEFULL_REGEX, 'Only text, please'),
    email: yup.string().required('Required').email('Please use a valid email address.'),
  });

  const enableReinitialize = !isLoadingUser || !isFetchingUser;

  const initialValues = {
    firstName: customerUser?.firstName || '',
    lastName: customerUser?.lastName || '',
    email: customerUser?.email || '',
  };

  const formik = useFormik({
    enableReinitialize,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (isNew) {
        const body = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          groups: selectedRoles,
        };
        const mutationPromise = await createCustomerUser({ applicationId, body });
        // @ts-ignore
        if (!mutationPromise.error) {
          toastCreateSuccess('User');
          resetForm();
          onClose();
        }
      } else {
        const body = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        };
        !_.isEqual(body, {
          firstName: customerUser.firstName,
          lastName: customerUser.lastName,
          email: customerUser.email,
        }) && (await updateCustomerUser({ applicationId, customerId, body }));
      }
      const modelAddGroups = selectedRoles?.filter((x) => !userRoles.includes(x));
      const modelRemoveGroups = userRoles?.filter((x) => !selectedRoles.includes(x));
      modelAddGroups.length > 0 &&
        (await addGroups({ userId: customerUser.customerId, groupsIds: modelAddGroups }));
      modelRemoveGroups.length > 0 &&
        (await removeGroups({
          userId: customerUser.customerId,
          groupsIds: modelRemoveGroups,
        }));
      resetForm();
      onClose();
    },
  });

  const getIsRoleChecked = (elementId) => selectedRoles?.some((el) => el === elementId);
  const switchRoleChecked = (elementId) => {
    getIsRoleChecked(elementId)
      ? setSelectedRoles((prev) => prev.filter((el) => el !== elementId))
      : setSelectedRoles((prev) => [...prev, elementId]);
  };

  const RenderGroups = () => (
    <>
      {groupsList.map((group) => (
        <Box sx={{ width: '100%' }} key={group.id}>
          <FormBlock>
            <FormLabel>
              <Checkbox
                checked={getIsRoleChecked(group.id)}
                onClick={() => switchRoleChecked(group.id)}
              />
              {group.name}
            </FormLabel>
            <Typography color="grey.600" variant="body2">
              {group.description && group.description}
            </Typography>
          </FormBlock>
        </Box>
      ))}
    </>
  );

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={`${isNew ? 'New' : 'Edit'} User`}
      handleAction={customerUser || isNew ? formik.handleSubmit : undefined}
      actionTitle="Save"
    >
      {customerUser || isNew ? (
        <form>
          <TextField
            type="text"
            autoFocus
            fullWidth
            label="First name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            variant="standard"
            sx={{ pb: 4, mt: 2, borderWidth: '1px', '&:hover': { borderWidth: '1px' } }}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            disabled={formik.isSubmitting || isLoadingUser || isLoadingRoles}
          />
          <TextField
            type="text"
            fullWidth
            label="Last name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            variant="standard"
            sx={{ pb: 4, borderWidth: '1px', '&:hover': { borderWidth: '1px' } }}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            disabled={formik.isSubmitting || isLoadingUser || isLoadingRoles}
          />
          <TextField
            type="text"
            fullWidth
            label="E-mail"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            variant="standard"
            sx={{ pb: 4, borderWidth: '1px', '&:hover': { borderWidth: '1px' } }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            disabled={
              formik.isSubmitting ||
              isLoadingUser ||
              isLoadingRoles ||
              (!isNew && customerUser?.emailVerified === true)
            }
          />

          {!isNew &&
            customerUser?.emailVerified === false &&
            (emailSent ? (
              <Typography variant="body2" component="span" color="grey.600" sx={{ pb: 2 }}>
                Email sent.
              </Typography>
            ) : (
              <Box
                sx={{ cursor: 'pointer', pb: 2 }}
                onClick={() => {
                  sendActivationEmail({ email: customerUser.email });
                }}
              >
                <Typography variant="body2" component="span" color="error">
                  Email not verified.{' '}
                </Typography>
                <Typography variant="body2" component="span" color="primary">
                  Resend the activation link for Customer.
                </Typography>
              </Box>
            ))}

          <Typography variant="h3" color="grey.900" sx={{ pt: 4, mb: 1 }}>
            Selected users roles:
          </Typography>
          {groupsList?.length > 0 && !isLoadingRoles ? (
            <RenderGroups />
          ) : (
            <Typography variant="body1">Customer portal roles not found</Typography>
          )}
        </form>
      ) : (
        <>
          {isLoadingUser ? (
            <Typography variant="body1">Loading...</Typography>
          ) : (
            <Typography variant="body1">Customer user not found</Typography>
          )}
        </>
      )}
    </ModalDialog>
  );
};
