import {
  LOAD_CASE_ASSIGNMENTS,
  LOAD_CASE_ASSIGNMENTS_SUCCESS,
  LOAD_CASE_ASSIGNMENTS_FAIL,
  CREATE_CASE_ASSIGNMENT,
  CREATE_CASE_ASSIGNMENT_SUCCESS,
  CREATE_CASE_ASSIGNMENT_FAIL,
  DELETE_CASE_ASSIGNMENT,
  DELETE_CASE_ASSIGNMENT_SUCCESS,
  DELETE_CASE_ASSIGNMENT_FAIL,
  SHOW_ASSIGNMENT_FORM,
  CLOSE_ASSIGNMENT_FORM,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { gatewayApi } from '../services/gatewayApi';
import {
  toastLoadingListError,
  toastCreateSuccess,
  toastCreateError,
  toastDeleteSuccess,
  toastDeleteError,
} from '../utils/toast';

const entityTitle = 'Case Assignment';

export const fetchCaseAssignments = () => (dispatch) => {
  dispatch({ type: LOAD_CASE_ASSIGNMENTS });
  ApiService.get('/api/gateway/case-assignments')
    .then((response) => dispatch(fetchCaseAssignmentsSuccess(response)))
    .catch((error) => dispatch(fetchCaseAssignmentsError(error)));
};

export const createCaseAssignment = (model) => (dispatch) => {
  dispatch({ type: CREATE_CASE_ASSIGNMENT });
  ApiService.post('/api/gateway/case-assignments', model)
    .then(() => {
      dispatch(createCaseAssignmentSuccess());
      dispatch(gatewayApi.util.invalidateTags(['CaseAssignments']));
    })
    .catch((error) => dispatch(createCaseAssignmentFail(error)));
};

export const deleteCaseAssignment = (id) => (dispatch) => {
  dispatch({ type: DELETE_CASE_ASSIGNMENT });
  ApiService.delete(`/api/gateway/case-assignments/${id}`)
    .then(() => {
      dispatch(deleteCaseAssignmentSuccess());
      dispatch(gatewayApi.util.invalidateTags(['CaseAssignments']));
    })
    .catch((error) => dispatch(deleteCaseAssignmentFail(error)));
};

const createCaseAssignmentSuccess = () => (dispatch) => {
  dispatch({ type: CREATE_CASE_ASSIGNMENT_SUCCESS });
  dispatch(closeAssignmentsForm());
  dispatch(fetchCaseAssignments());
  toastCreateSuccess(entityTitle);
};

const createCaseAssignmentFail = (error) => (dispatch) => {
  dispatch({ type: CREATE_CASE_ASSIGNMENT_FAIL });
  toastCreateError(entityTitle, error);
};

const fetchCaseAssignmentsSuccess = (caseAssignments) => ({
  type: LOAD_CASE_ASSIGNMENTS_SUCCESS,
  caseAssignments,
});

const fetchCaseAssignmentsError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_CASE_ASSIGNMENTS_FAIL,
    error,
  });
  toastLoadingListError(entityTitle, error);
};

const deleteCaseAssignmentSuccess = () => (dispatch) => {
  dispatch({ type: DELETE_CASE_ASSIGNMENT_SUCCESS });
  dispatch(fetchCaseAssignments());
  toastDeleteSuccess(entityTitle);
};

const deleteCaseAssignmentFail = (error) => (dispatch) => {
  dispatch({ type: DELETE_CASE_ASSIGNMENT_FAIL });
  toastDeleteError(entityTitle, error);
};

export const showCreateAssignmentsForm = () => (dispatch) => {
  dispatch({ type: SHOW_ASSIGNMENT_FORM });
};

export const closeAssignmentsForm = () => (dispatch) => {
  dispatch({ type: CLOSE_ASSIGNMENT_FORM });
};
