import React, { PropsWithChildren } from 'react';

interface SidebarProps {
  // No additional props are needed
}

export const Sidebar: React.FC<PropsWithChildren<SidebarProps>> = ({ children }) => (
  <>
    {children}
  </>
);
