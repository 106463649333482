import { gatewayApi } from '../gatewayApi';
import {
  ApplicationShareholderDTO,
  ApplicationShareholderFilter,
  ApplicationShareholdersDTO,
  ApplicationShareholder,
  AppShareholderBase,
} from './shareholdersApiTyping';

export type {
  ApplicationShareholderDTO,
  ApplicationShareholderFilter,
  ApplicationShareholdersDTO,
  ApplicationShareholder,
  AppShareholderBase,
};

const defaultPageSize = 1000;

const shareholdersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getShareholders: build.query<ApplicationShareholdersDTO, ApplicationShareholderFilter>({
      query: ({ applicationId, size = defaultPageSize, sort, page }) => ({
        url: `applications/${applicationId}/shareholders`,
        params: { size, sort: `${sort.property},${sort.direction}`, page },
        method: 'GET',
      }),
      providesTags: () => [{ type: 'ApplicationShareholder', id: 'LIST' }],
    }),

    addShareholder: build.mutation<ApplicationShareholder, ApplicationShareholderDTO>({
      query: ({ applicationId, body }) => ({
        url: `applications/${applicationId}/shareholders`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ applicationId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          shareholdersApi.util.updateQueryData(
            'getShareholders',
            { applicationId },
            (draft: ApplicationShareholdersDTO) => {
              const shareholder = [{ id: 'new', ...body }].concat(draft.content);
              Object.assign(draft, shareholder);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: () => [{ type: 'ApplicationShareholder', id: 'LIST' }],
    }),

    removeShareholder: build.mutation<ApplicationShareholder, any>({
      query: ({ applicationId, shareholderId }) => ({
        url: `applications/${applicationId}/shareholders/${shareholderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'ApplicationShareholder', id: 'LIST' }],
    }),

    updateShareholder: build.mutation<ApplicationShareholder, ApplicationShareholderDTO | any>({
      query({ applicationId, shareholderId, body }) {
        return {
          url: `applications/${applicationId}/shareholders/${shareholderId}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted({ applicationId, shareholderId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          shareholdersApi.util.updateQueryData(
            'getShareholders',
            { applicationId },
            (draft: ApplicationShareholdersDTO) => {
              const shareholder = draft.content.find((s) => s.id === shareholderId);
              Object.assign(draft, shareholder);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: () => [{ type: 'ApplicationShareholder', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetShareholdersQuery,
  useAddShareholderMutation,
  useRemoveShareholderMutation,
  useUpdateShareholderMutation,
} = shareholdersApi;
