/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_MERCHANT_TRANSACTIONS_DATA,
  LOAD_MERCHANT_TRANSACTIONS_DATA_SUCCESS,
  LOAD_MERCHANT_TRANSACTIONS_DATA_FAIL,
} from '../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  data: [],
};

const merchantTransactionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MERCHANT_TRANSACTIONS_DATA:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANT_TRANSACTIONS_DATA_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case LOAD_MERCHANT_TRANSACTIONS_DATA_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default merchantTransactionsReducer;
