import { PaletteOptions } from '@mui/material';
import {
  grey,
  base,
  primary,
  primaryLight,
  secondary,
  successLight,
  danger,
  warning,
  text,
  contrast,
  bold,
  success,
  error,
  info,
} from './palette-blocks';
import { FigmaColor, textColor, figmaVariables } from './figmaVariables';

declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary'];
    base: Palette['primary'];
    bold: Palette['primary'];
    danger: Palette['primary'];
    primaryLight: Palette['primary'];
    contrast: Palette['primary'];
    successLight: Palette['primary'];
  }

  interface PaletteOptions {
    default: PaletteOptions['primary'];
    base: PaletteOptions['primary'];
    bold: PaletteOptions['primary'];
    danger: PaletteOptions['primary'];
    primaryLight: PaletteOptions['primary'];
    contrast: PaletteOptions['primary'];
    successLight: PaletteOptions['primary'];
  }

  interface PaletteColor {
    hover?: string;
    active?: string;
  }

  interface SimplePaletteColorOptions {
    hover?: string;
    active?: string;
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    successLight?: string;
    primaryLight?: string;
    contrast?: string;
    danger?: string;
    helper?: string;
  }

  interface SimplePaletteColorOptions {
    successLight?: string;
    primaryLight?: string;
    contrast?: string;
    danger?: string;
    helper?: string;
  }
}

export const palette: PaletteOptions = {
  grey,
  primary,
  primaryLight,
  secondary,
  danger,
  warning,
  contrast,
  base,
  success,
  successLight,
  error,
  bold,
  text,
  info,

  default: {
    main: FigmaColor({ colorName: 'grey-blue', shade: '050' }),
    light: FigmaColor({ colorName: 'grey-blue', shade: '050' }),
    dark: FigmaColor({ colorName: 'grey-blue', shade: 100 }),
    contrastText: textColor('text-base'),

    hover: FigmaColor({ colorName: 'grey-blue', shade: 100 }),
    active: FigmaColor({ colorName: 'grey-blue', shade: 200 }),
  },

  action: {
    hover: 'rgba(0, 0, 0, 0.1)',
    hoverOpacity: 0.1,
    active: 'rgba(0, 0, 0, 0.54)',
    activatedOpacity: 0.2,
    disabledOpacity: 0.6,
  },

  // there defined body background style
  background: {
    default: figmaVariables.primitives.color['grey-blue']['grey-blue-050'],
    paper: figmaVariables.primitives.color.base.white,
  },
};
