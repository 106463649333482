import React, { useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { toastDeleteSuccess, toastUpdateSuccess } from 'utils/toast';
import { ChipSelector, ModalDialog } from 'uikit';
import { ValueLabel } from 'types';
import {
  useUpdateClientAccountMutation,
  useDeleteClientAccountMutation,
  useUpdateClientIbanMutation,
  useDeleteClientIbanMutation,
} from 'services/gatewayApi/clientsApi';
import { Client, ClientAccount } from '../../types';
import { IbanDialog } from './IbanDialog';

interface AccountProps {
  account: ClientAccount;
  categories: ValueLabel[];
  setEdited: any;
  client: Client;
  setEditAccount: any;
  filterCase: string;
}

export const Account: React.FC<AccountProps> = ({
  account,
  categories,
  setEdited,
  client,
  setEditAccount,
  filterCase,
}) => {
  const initialIban = {
    id: 'new',
    ibanCode: '',
    account: { id: account.id },
    ibanCategories: [],
  };
  const [isIbanDialogOpen, setIsIbanDialogOpen] = useState<boolean>(false);
  const [ibanToOpen, setIbanToOpen] = useState(initialIban);
  const [accountIdToDelete, setAccountIdToDelete] = useState(undefined);
  const [ibanIdToDelete, setIbanIdToDelete] = useState(undefined);

  const [updateClientAccount] = useUpdateClientAccountMutation();
  const [deleteClientAccount] = useDeleteClientAccountMutation();
  const [updateClientAccountIban] = useUpdateClientIbanMutation();
  const [deleteClientAccountIban] = useDeleteClientIbanMutation();

  const handleDeleteAccount = async (accountId) => {
    const mutationPromise = await deleteClientAccount({ accountId, clientId: client.id });
    // @ts-ignore
    if (!mutationPromise.error) {
      toastDeleteSuccess('Account');
      setAccountIdToDelete(undefined);
      setEdited(true);
    }
  };

  const handleUpdateAccountCategories = async (newCategories) => {
    const body = {
      clientId: client.id,
      accountType: account.accountType,
      accountName: account.accountName,
      categoriesId: newCategories.map((el) => el.value),
    };
    const mutationPromise = await updateClientAccount({ accountId: account.id, body });
    // @ts-ignore
    if (!mutationPromise.error) {
      toastUpdateSuccess('Account');
      setEdited(true);
    }
  };

  const handleEditAccount = () => {
    setEditAccount(account);
  };

  const handleAddAccountIban = () => {
    setIsIbanDialogOpen(true);
    setIbanToOpen((prev) => ({ ...prev, id: 'new' }));
    setEdited(true);
  };

  const handleUpdateIban = async (iban, newCategories) => {
    const body = {
      accountId: iban.account.id,
      ibanCode: iban.ibanCode,
      categoriesId: newCategories.map((el) => el.value),
    };
    const mutationPromise = await updateClientAccountIban({ body, ibanId: iban.id });
    // @ts-ignore
    if (!mutationPromise.error) {
      toastUpdateSuccess('IBAN');
    }
    setIbanToOpen(initialIban);
    setEdited(true);
  };

  const handleEditIban = (iban) => {
    setIsIbanDialogOpen(true);
    setIbanToOpen({ ...iban });
  };

  const handleDeleteIban = async () => {
    const mutationPromise = await deleteClientAccountIban({
      ibanId: ibanIdToDelete.id,
      clientId: client.id,
    });
    // @ts-ignore
    if (!mutationPromise.error) {
      toastDeleteSuccess('Account');
      setIbanIdToDelete(undefined);
    }
    setEdited(true);
  };

  const searchFilter = (item) => item.ibanCode.search(filterCase.toUpperCase()) > -1;

  return (
    <Box
      sx={(theme) => ({
        mb: 2,
        p: '2px',
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 1,
      })}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          p: 2,
          borderRadius: 1,
          '&:hover': { backgroundColor: 'grey.100' },
          '&:hover .buttons': { opacity: 1 },
        }}
      >
        <Box>
          <Typography variant="h3">{account.accountName}</Typography>
          <ChipSelector
            list={categories}
            selectedList={account.accountCategories.map((el) => ({
              value: el.id,
              label: el.name,
            }))}
            setSelectedList={handleUpdateAccountCategories}
            limitTags={3}
          />
        </Box>
        <Stack
          className="buttons"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ pr: 2, opacity: 0 }}
        >
          <IconButton
            size="small"
            onClick={() => {
              setAccountIdToDelete(account.id);
            }}
            disabled={account.ibans.length > 0}
            color="error"
          >
            <DeleteOutlineIcon />
          </IconButton>
          <IconButton size="small" onClick={handleEditAccount}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton size="small" onClick={handleAddAccountIban}>
            <AddIcon color="primary" />
          </IconButton>
        </Stack>
      </Stack>

      {account.ibans.length > 0 ? (
        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.grey[100]}`,
            mt: '2px',
            pt: '2px',
          })}
        >
          {account.ibans.filter(searchFilter).map((iban) => (
            <Box
              key={iban.id}
              sx={{
                p: 2,
                borderRadius: 1,
                '&:hover': { backgroundColor: 'grey.100' },
                '&:hover .buttons': { opacity: 1 },
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <Box>
                  <Typography sx={{ pt: '6px' }}>{iban.ibanCode}</Typography>
                  <ChipSelector
                    list={categories}
                    selectedList={iban.ibanCategories.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    setSelectedList={(newCategories) => handleUpdateIban(iban, newCategories)}
                    limitTags={3}
                    color="default"
                  />
                </Box>
                <Stack
                  className="buttons"
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  sx={{ pr: 2, opacity: 0 }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIbanIdToDelete({ id: iban.id, ibanCode: iban.ibanCode });
                    }}
                    color="error"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleEditIban({
                        ...iban,
                        ibanCategories: iban.ibanCategories.map((el) => el.id),
                      })
                    }
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ py: 4, textAlign: 'center' }}>
          <Typography color="grey.400">No IBANs...</Typography>
        </Box>
      )}

      {isIbanDialogOpen && (
        <IbanDialog
          open={isIbanDialogOpen}
          onClose={() => {
            setIsIbanDialogOpen(false);
            setIbanToOpen(initialIban);
          }}
          ibanToOpen={ibanToOpen}
          client={client}
          categories={categories}
          setEdited={setEdited}
        />
      )}

      {!!accountIdToDelete && (
        <ModalDialog
          open={!!accountIdToDelete}
          onClose={() => setAccountIdToDelete(undefined)}
          title="Delete Client Account"
          handleAction={() => handleDeleteAccount(accountIdToDelete)}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Client Account '}
            <Typography variant="subtitle1" component="span">
              {account.accountName}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}

      {!!ibanIdToDelete && (
        <ModalDialog
          open={!!ibanIdToDelete}
          onClose={() => setIbanIdToDelete(undefined)}
          title="Delete Client Account IBAN"
          handleAction={() => handleDeleteIban()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Client Account IBAN '}
            <Typography variant="subtitle1" component="span">
              {ibanIdToDelete.ibanCode}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </Box>
  );
};
