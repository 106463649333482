import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { RuleVetosForm } from './RuleVetosForm';

const RuleVetos = ({ ruleId }) => (
  <Grid>
    <Grid
      container
      spacing={4}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      mb={4}
    >
      <Grid item md={8} xs={12} sx={{ py: 4 }}>
        <Typography variant="h2" my={0}>
          Rule Vetos
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography variant="body2" align="right">
          Current date: {format(new Date(), 'dd-MM-yyyy')}
        </Typography>
      </Grid>
    </Grid>

    <Box my={2}>
      <RuleVetosForm ruleId={ruleId} />
    </Box>
  </Grid>
);

export default RuleVetos;

RuleVetos.propTypes = {
  ruleId: PropTypes.number,
};
