import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { DataTable } from '../../components';
import TransactionsListItem from './transactionsListItem';

class TransactionsList extends React.Component {
  getCustomerProfileProp() {
    const prop = { hideFieldsForCustomerProfile: '' };
    if (this.props.customerProfile)
      prop.hideFieldsForCustomerProfile = 'hideFieldsForCustomerProfile';
    return prop;
  }

  hideFieldsForCustomerProfile() {
    return classNames({
      hideFieldsForCustomerProfile: this.props.customerProfile,
    });
  }

  renderChildren(colSpan, isBank, isGateway) {
    if (this.props.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={colSpan}>
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }

    if (
      !this.props.transactions.responseList ||
      this.props.transactions.responseList.length === 0
    ) {
      return (
        <tbody>
          <tr>
            <td colSpan={colSpan}>
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return this.props.transactions.responseList.map((item) => (
      <TransactionsListItem
        item={item}
        key={`${item.transactionId}_${item.requestDate}`}
        isBank={isBank}
        isGateway={isGateway}
        {...this.getCustomerProfileProp()}
      />
    ));
  }

  render() {
    const { isBank, isGateway } = this.props;
    const className = this.hideFieldsForCustomerProfile();

    return (
      <div>
        <DataTable>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Date</th>
              <th>External Transaction ID</th>
              {isBank && (
                <>
                  <th className={className}>Client ID</th>
                  <th className={className}>Sender/Beneficiary</th>
                  <th className={className}>Client Name</th>
                </>
              )}
              {isGateway && (
                <>
                  <th className={className}>Merchant Name</th>
                  <th className={className}>Customer Name</th>
                  <th className={className}>Email</th>
                  <th className={className}>PAN</th>
                  <th className={className}>Hashed Card</th>
                </>
              )}              
              <th>Amount</th>
              <th>{!isBank ? 'Type' : 'Txn Type'}</th>
              {isBank && (
                <>
                  <th>Sender Bank</th>
                  <th>Beneficiary Bank</th>
                </>
              )}
              {isGateway && <th>Response Code</th>}
              <th>Risk Status</th>
              <th className="td-small">&nbsp;</th>
              <th className="td-small">&nbsp;</th>
            </tr>
          </thead>
          {this.renderChildren(isBank ? 13 : 14, isBank, isGateway)}
        </DataTable>
      </div>
    );
  }
}

TransactionsList.propTypes = {
  transactions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  customerProfile: PropTypes.bool,
  isBank: PropTypes.bool,
  isGateway: PropTypes.bool,
};

export default TransactionsList;
