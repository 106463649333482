import React from 'react';
import { Panel, Button, IconButton } from 'uikit';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AppsIcon from '@mui/icons-material/Apps';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { pri } from 'uikit/options';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Typography } from '@mui/material';

export function SimpleButtonsSection() {
  return (
    <>
      <Typography variant="h2" gutterBottom>
        UIKit buttons
      </Typography>
      <Panel>
        <Button theme="primary">Primary</Button>
        <Button theme="default">Default</Button>
        <Button theme="defaultDanger">Default danger</Button>
        <Button theme="defaultAccent">Default accent</Button>
        <Button theme="defaultSuccess">Default success</Button>
        <Button theme="dark">Dark</Button>
        <Button theme="text">Text</Button>
      </Panel>

      <Panel>
        <Button theme="dark" childrenRight={<CompareArrowsIcon style={{ fontSize: '20px' }} />}>
          Button primary
        </Button>
        <Button theme="text" childrenLeft={<AppsIcon style={{ fontSize: '20px' }} />}>
          Button text
        </Button>
        <Button theme="defaultAccent" childrenLeft={<AppsIcon style={{ fontSize: '20px' }} />}>
          Default accent button
        </Button>
        <Button theme="default" childrenLeft={<AppsIcon style={{ fontSize: '20px' }} />}>
          Default accent button
        </Button>
      </Panel>

      <Panel>
        <Button theme="primary">Button primary</Button>
        <Button theme="dark" childrenLeft={<CompareArrowsIcon style={{ fontSize: '20px' }} />}>
          Button primary
        </Button>
      </Panel>

      <Panel>
        <Button theme="text">Button text</Button>
        <Button theme="text" childrenLeft={<AppsIcon style={{ fontSize: '20px' }} />}>
          Button text
        </Button>
      </Panel>

      <Panel>
        <IconButton theme="text">
          <AppsIcon style={{ fontSize: '20px' }} />
        </IconButton>
        <IconButton theme="default">
          <CloudDownloadIcon style={{ color: pri, fontSize: '20px' }} />
        </IconButton>
        <IconButton theme="text">
          <ChatBubbleIcon style={{ fontSize: '20px' }} />
        </IconButton>
      </Panel>
    </>
  );
}
