import { FilterScheme } from '../../../types';

export const filterScheme: FilterScheme[] = [
  {
    field: 'text',
    name: 'firstName',
    label: 'First Name',
  },
  {
    field: 'text',
    name: 'lastName',
    label: 'Last Name',
  },
  {
    field: 'text',
    name: 'email',
    label: 'Email',
  },
  {
    field: 'selectMulti',
    name: 'kycStatus',
    label: 'KYC Status',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'extKycStatus',
    label: 'Final Status',
    initialFilterValue: [],
  },
  {
    field: 'selectMulti',
    name: 'manualResult',
    label: 'Manual Status',
    initialFilterValue: [],
  },
  {
    field: 'datePicker',
    name: 'createdDateStart',
    label: 'Date Created',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'createdDateEnd',
    label: 'hide',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'modifiedDateStart',
    label: 'Date Updated',
    initialFilterValue: null,
  },
  {
    field: 'datePicker',
    name: 'modifiedDateEnd',
    label: 'hide',
    initialFilterValue: null,
  },
];
