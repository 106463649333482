/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_CASE_ASSIGNMENTS,
  LOAD_CASE_ASSIGNMENTS_SUCCESS,
  LOAD_CASE_ASSIGNMENTS_FAIL,
  SHOW_ASSIGNMENT_FORM,
  CLOSE_ASSIGNMENT_FORM,
} from '../constants/constants';

export const initialState = {
  loading: false,
  showForm: false,
  error: false,
  caseAssignments: [],
};

const caseAssignmentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_CASE_ASSIGNMENTS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_CASE_ASSIGNMENTS_SUCCESS:
        draft.caseAssignments = action.caseAssignments;
        draft.loading = false;
        break;
      case LOAD_CASE_ASSIGNMENTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case SHOW_ASSIGNMENT_FORM:
        draft.showForm = true;
        break;
      case CLOSE_ASSIGNMENT_FORM:
        draft.showForm = false;
        break;
    }
  });

export default caseAssignmentsReducer;
