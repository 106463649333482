import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { format as formatDateFns, isToday, isYesterday } from 'date-fns';

interface MessageDateProps {
  format?: string;
  date?: string;
  children?: React.ReactNode;
}

const MessageDate = ({ date: dateString, format, children }: MessageDateProps): JSX.Element => {
  const date = new Date(dateString);
  const getTodayOrYesterdayString = (d) => {
    if (isToday(d)) return 'Today, ';
    if (isYesterday(d)) return 'Yesterday, ';
    return '';
  };
  const formatDate = (d, formatString) =>
    `${getTodayOrYesterdayString(d)}${formatDateFns(d, formatString)}`;

  return (
    <Box sx={{ padding: '12px 0px 24px' }}>
      <Divider sx={{ '::before': { width: '0%' } }} flexItem textAlign="left">
        <Typography
          variant="body1"
          sx={{
            border: '1px solid',
            borderColor: 'grey.100',
            borderRadius: 1.5,
            color: 'grey.500',
            px: 2,
            py: 0.5,
          }}
        >
          {children || (date && format && formatDate(date, format))}
        </Typography>
      </Divider>
    </Box>
  );
};

export default MessageDate;
