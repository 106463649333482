import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiArrowRightUpLine } from 'react-icons/ri';
import { ArrowBack } from 'uikit';
import { Individual } from 'services/gatewayApi/applicationIndividualsApi';
import { OverviewTab } from './OverviewTab';

interface DetailsSidebarProps {
  applicationId: string;
  individual: Individual;
  onClose: any;
}

export const DetailsSidebar: React.FC<DetailsSidebarProps> = ({
  applicationId,
  individual,
  onClose,
}) => (
  <Box sx={{ pr: 0, pl: 8, py: 6, mt: 10 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <ArrowBack onClose={onClose} />
      {/* Button hidden while HAYS-4056 blocked */}
      {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> */}
      {/*  <Link to="#"> */}
      {/*    {' '} */}
      {/*    // TODO: Add link to the form (what is the form?) */}
      {/*    <Button */}
      {/*      variant="contained" */}
      {/*      color="secondary" */}
      {/*      endIcon={<RiArrowRightUpLine size="14px" />} */}
      {/*      size="small" */}
      {/*    > */}
      {/*      Go to the form */}
      {/*    </Button> */}
      {/*  </Link> */}
      {/* </Stack> */}
    </Stack>

    <>
      <Typography variant="h2" sx={{ mt: 8, fontSize: '18px', fontWeight: 700 }}>
        {individual?.firstName} {individual?.lastName}
      </Typography>

      <OverviewTab applicationId={applicationId} individual={individual} />
    </>
  </Box>
);
