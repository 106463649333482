import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Box, Typography } from '@mui/material';

interface FormFieldWrapperProps {
  label?: string;
  disabled?: boolean;
  error?: boolean;
  sx?: any;
  children?: React.ReactNode;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  disabled = false,
  error = false,
  sx = undefined,
  label = undefined,
  children = null,
}) => {
  const getColor = () => {
    if (error) {
      return '#FEEFF0';
    }
    if (disabled) {
      return 'grey.50';
    }
    return 'grey.100';
  };

  return (
    <Box className="FormFieldWrapper" display="flex" flexDirection="column" {...sx}>
      <label htmlFor={`${label || 'id'}-labelId`}>
        {label && (
          <Typography
            variant="body1"
            pb={2}
            color={disabled ? 'text.disabled' : 'text.primary'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
          />
        )}
        <Box
          className="FormFieldContentWrapper"
          sx={(theme) => ({
            bgcolor: getColor(),
            borderRadius: '6px',
            border: 1,
            borderColor: getColor(),
            transition: theme.transitions.create(['border-color', 'background-color']),
            ': focus-within': {
              borderColor: error ? 'danger.main' : 'grey.900',
              bgcolor: error ? '#FEEFF0' : 'white',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            px: 3,
            pt: '9px',
            pb: '8px',
          })}
        >
          {children}
        </Box>
      </label>
    </Box>
  );
};
