import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button as Btn } from 'uikit';
import AddIcon from '@mui/icons-material/Add';

import './NoDataBox.scss';

interface NoDataBoxProps {
  show: boolean;
  entityTitle: string;
  onCreate?: any;
}

export const NoDataBox: React.FC<NoDataBoxProps> = ({ show, entityTitle, onCreate }) => (
  <>
    {show && (
      <Box className="NoDataBox">
        <Typography variant="h2">Nothing found</Typography>
        <Typography>It looks like you don’t have any {entityTitle} yet</Typography>
        {onCreate && (
          <Btn bold variant="light" theme="primary" onClick={onCreate} prefix={<AddIcon />}>
            Add {entityTitle}
          </Btn>
        )}
      </Box>
    )}
  </>
);
