import React, { useRef } from 'react';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { Callback } from 'types';
import { FormFieldWrapper } from '../FormFieldWrapper';
import { AdornmentWrapper } from '../AdornmentWrapper';
import { Information } from '../Information';
import { Clear } from '../Clear';

interface FormTextFieldProps extends InputBaseProps {
  label?: string;
  placeholder?: string;
  // size?: 'sm' | 'md' | 'lg'; //TODO
  // variant?: 'plain' | 'outlined' | 'soft' | 'solid'; // TODO
  // color?: 'secondary' | 'success' | 'warning' | 'error' | 'primary' | 'info'; // TODO
  disabled?: boolean;
  info?: string;
  prefixForm?: React.FC | JSX.Element;
  suffix?: React.FC | JSX.Element;
  clear?: boolean | Callback; // boolean for uncontrolled input
  error?: boolean;
  value?: string | number;
}

export const FormTextField: React.FC<FormTextFieldProps> = ({
  label = undefined,
  placeholder = undefined,
  // size = 'sm', // TODO
  // variant = 'soft', // TODO
  // color = 'info', // TODO
  disabled = false,
  info = undefined,
  prefixForm = undefined,
  suffix = undefined,
  clear = false,
  error = false,
  value = undefined,
  sx,
  ...otherProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <FormFieldWrapper label={label} disabled={disabled} error={error} sx={sx}>
      <AdornmentWrapper disabled={disabled}>{prefixForm}</AdornmentWrapper>
      <InputBase
        inputRef={inputRef}
        sx={{ flex: 1, '& .MuiInputBase-input': { lineHeight: '20px' } }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'form text field' }}
        disabled={disabled}
        value={value}
        {...otherProps}
      />
      <Clear clear={clear} disabled={disabled} inputRef={inputRef} />
      <Information info={info} disabled={disabled} />
      <AdornmentWrapper disabled={disabled}>{suffix}</AdornmentWrapper>
    </FormFieldWrapper>
  );
};
