import { PaletteOpenpaydColor } from './types';
import { FigmaColor } from '../figmaVariables';

export const secondary: PaletteOpenpaydColor = {
  main: FigmaColor({ colorName: 'blue', shade: 200 }),
  light: FigmaColor({ colorName: 'blue', shade: 100 }),
  dark: FigmaColor({ colorName: 'blue', shade: 500 }),
  contrastText: FigmaColor({ colorName: 'blue', shade: 900 }),
  'bg-100': FigmaColor({ colorName: 'blue', shade: 200 }),
  'bg-200': FigmaColor({ colorName: 'blue', shade: 300 }),
  'bg-300': FigmaColor({ colorName: 'blue', shade: 400 }),
  'outline-100': FigmaColor({ colorName: 'blue', shade: 200 }),

  hover: FigmaColor({ colorName: 'blue', shade: 300 }),
  active: FigmaColor({ colorName: 'blue', shade: 400 }),
};
