import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';
import { mixins } from './mixins';
import { breakpoints } from './breakpoints';
import { figmaVariables } from './figmaVariables';

export const baseTheme = createTheme({
  palette,
  shape: {
    borderRadius: figmaVariables.primitives.radius['md-6'],
  },
  typography,
  mixins,
  breakpoints,
  spacing: figmaVariables.primitives.spacing['space-1-4'],
});
