import PropTypes from 'prop-types';
import React from 'react';
import { DataTable } from '../../../components';
import CaseAssignmentsListItem from './caseAssignmentsListItem';

class CaseAssignmentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
    };
  }

  renderChildren() {
    const { users, products } = this.props;
    if (this.props.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="14">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }
    if (!this.props.caseAssignments || this.props.caseAssignments.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="13">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return this.props.caseAssignments.map((item, index) => (
      <CaseAssignmentsListItem key={index} item={item} users={users} products={products} />
    ));
  }

  render() {
    return (
      <div>
        <DataTable>
          <thead>
            <tr>
              <th>Tenant</th>
              <th>User</th>
              <th>Case Category</th>
              <th className="td-small" />
            </tr>
          </thead>
          {this.renderChildren()}
        </DataTable>
      </div>
    );
  }
}

CaseAssignmentsList.propTypes = {
  caseAssignments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
};

export default CaseAssignmentsList;
