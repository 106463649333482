import React, { useRef, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import './ModalDialog.scss';

const DialogStyled = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'allowOverflow',
})<{ allowOverflow: boolean }>(({ theme, allowOverflow }) =>
  theme.unstable_sx(
    allowOverflow
      ? {
          '.MuiDialog-container > .MuiPaper-root': { overflow: 'visible' },
          '.MuiDialogContent-root': { overflow: 'visible' },
        }
      : {},
  ),
);

interface ModalDialogProps {
  open: boolean;
  onClose: any;
  ariaLabel?: string;
  title: string;
  isLoading?: boolean;
  disableAction?: boolean;
  children?: React.ReactNode;
  handleReset?: any;
  handleAction?: any;
  handleActionSecondary?: any;
  actionTitle?: string;
  actionIcon?: any;
  actionTitleSecondary?: string;
  allowOverflow?: boolean;
  noCancel?: boolean;
  noCrossButton?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | string;
  variant?: 'error';
  disableEscapeKeyDown?: boolean;
  hasDivider?: boolean;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  open,
  onClose,
  ariaLabel,
  title,
  children,
  isLoading,
  disableAction,
  handleReset,
  handleAction,
  actionTitle = 'Submit',
  actionIcon,
  allowOverflow = false,
  maxWidth,
  variant,
  disableEscapeKeyDown,
  handleActionSecondary,
  actionTitleSecondary,
  noCancel = false,
  noCrossButton = true,
  hasDivider = false,
}) => {
  const contentWrapperRef = useRef(null);
  const contentInnerRef = useRef(null);

  const [contentScroll, seContentScroll] = useState(0);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);
  const [bottomOffset, setBottomOffset] = useState(0);

  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      const wrapperHeight = contentWrapperRef?.current?.offsetHeight;
      const innerHeight = contentInnerRef?.current?.offsetHeight;

      setShowTopShadow(contentScroll > 0);
      setBottomOffset(
        wrapperHeight + contentScroll > innerHeight ? innerHeight : wrapperHeight + contentScroll,
      );
      setShowBottomShadow(
        wrapperHeight < innerHeight && innerHeight - contentScroll > wrapperHeight - 8,
      );
    });
  });

  // Clear timeout if have been modal closed
  useEffect(() => () => clearTimeout(timeout.current));

  const handleContentScroll = () => seContentScroll(contentWrapperRef.current.scrollTop);

  return (
    <DialogStyled
      className="modalDialog"
      open={open}
      onClose={onClose}
      aria-labelledby={ariaLabel}
      maxWidth={maxWidth || 'xs'}
      fullWidth
      disableEscapeKeyDown={disableEscapeKeyDown}
      allowOverflow={allowOverflow}
    >
      {/* onClose callback provides event and reason by design (escapeKeyDown, backdropClick) */}
      {!noCrossButton && (
        <IconButton
          size="small"
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={(e) => {
            e.stopPropagation();
            onClose(e, 'cancelClick');
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogTitle className="modalDialogTitleWrapper" onClick={(e) => e.stopPropagation()}>
        <Typography
          className="modalDialogTitle"
          id={ariaLabel}
          color={variant === 'error' ? 'danger.main' : 'grey.900'}
        >
          {title}
        </Typography>
      </DialogTitle>

      {hasDivider && <div className="divider" />}

      {!!children && (
        <DialogContent
          className="modalDialogContentWrapper"
          ref={contentWrapperRef}
          onScroll={handleContentScroll}
          sx={{ position: 'relative' }}
          style={{ paddingBottom: handleAction ? '1.25rem' : '2rem' }}
          onClick={(e) => e.stopPropagation()}
        >
          <div ref={contentInnerRef}>{children}</div>

          {/* Do not show shadows on small sizes. Causes unecessary vertical scrollbar. */}
          {contentScroll > 50 && (
            <>
              <div
                className="modal-dialog-top-shadow"
                style={{ top: `${contentScroll - 12}px`, opacity: `${showTopShadow ? '1' : '0'}` }}
              />
              <div
                className="modal-dialog-bottom-shadow"
                style={{ top: `${bottomOffset}px`, opacity: `${showBottomShadow ? '1' : '0'}` }}
              />
            </>
          )}
        </DialogContent>
      )}

      {handleAction && (
        <DialogActions className="modalDialogActionsWrapper" onClick={(e) => e.stopPropagation()}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {!noCancel && (
              <Button color="base" variant="text" onClick={(e) => onClose(e, 'cancelClick')}>
                Close
              </Button>
            )}

            {handleReset && (
              <Button color="error" variant="text" onClick={() => handleReset()}>
                Reset
              </Button>
            )}

            {handleActionSecondary && (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleActionSecondary()}
                disabled={isLoading || disableAction}
              >
                {actionTitleSecondary}
              </Button>
            )}

            {handleAction && actionTitle && (
              <Button
                variant="contained"
                color={variant === 'error' ? 'error' : 'primary'}
                startIcon={isLoading ? <CircularProgress size={16} sx={{ mr: 1 }} /> : actionIcon}
                disabled={isLoading || disableAction}
                onClick={() => handleAction()}
              >
                {actionTitle}
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}
    </DialogStyled>
  );
};
