import { ValueLabel } from 'types';
import { checkProductName } from 'utils';

interface ValueLabelExtended extends ValueLabel {
  labelExtended?: string;
}

const applicationStatusOptions_Common: ValueLabelExtended[] = [
  { value: 'PENDING_ON_CUSTOMER', label: 'Pending on Customer' },
  { value: 'PENDING_ON_CUSTOMER_SECOND', label: 'Pending on Customer: Second' },
  { value: 'PENDING_SALES_TEAM', label: 'Pending Sales Team' },
  { value: 'CLIENT_INFORMED', label: 'Client Informed' },
  { value: 'OPEN', label: 'Open' },
];

const applicationStatusOptions_EMB: ValueLabelExtended[] = [
  { value: 'PENDING_BOARD_MEMBER', label: 'Pending Board Member' },
  {
    value: 'PENDING_AML_TEAM_PREQUESTIONNAIRE',
    label: 'Pending AML Team',
    labelExtended: 'Pending AML Team: Prequestionnaire',
  },
  { value: 'PENDING_AML_TEAM', label: 'Pending AML Team' },
  { value: 'PENDING_MLRO_MANAGER', label: 'Pending MLRO Manager' },
  { value: 'PENDING_MLRO_MANAGER_AFTER_AML', label: 'Pending MLRO Manager' },
  { value: 'PENDING_RISK_SCORE_CHECK', label: 'Pending Risk Team' },
  { value: 'PENDING_PEP_CHECK', label: 'Pending PEP Check' },
  {
    value: 'PENDING_ON_SALES_TEAM_FINAL_STAGE',
    label: 'Pending Sales Team',
    labelExtended: 'Pending Sales Team: Final',
  },
  {
    value: 'PENDING_ON_SALES_TEAM_AFTER_MLRO',
    label: 'Pending Sales Team',
    labelExtended: 'Pending Sales Team: After MLRO',
  },
  {
    value: 'PENDING_ON_SALES_TEAM_HIGH_RISK',
    label: 'Pending Sales Team',
    labelExtended: 'Pending Sales Team: High Risk',
  },
  {
    value: 'PENDING_ON_SALES_TEAM_PREQUESTIONNAIRE',
    label: 'Pending Sales Team',
    labelExtended: 'Pending Sales Team: Prequestionnaire',
  },
  { value: 'PENDING_PREQUESTIONNAIRE_CHECK', label: 'Pending Prequestionnaire Check' },
  {
    value: 'PENDING_ON_CUSTOMER_PREQUESTIONNAIRE',
    label: 'Pending on Customer',
    labelExtended: 'Pending on Customer: Prequestionnaire',
  },
  {
    value: 'PENDING_ON_CUSTOMER_FURTHER_INFO',
    label: 'Pending on Customer',
    labelExtended: 'Pending on Customer: Further Info',
  },
  {
    value: 'PENDING_ON_CUSTOMER_FURTHER_FORMS',
    label: 'Pending on Customer',
    labelExtended: 'Pending on Customer: Further Forms',
  },
];

const applicationStatusOptions_OpenPayd: ValueLabelExtended[] = [
  {
    value: 'PENDING_ONBOARDING_COMPLIANCE_FIRST',
    label: 'Pending Onboarding Compliance',
    labelExtended: 'Pending Onboarding Compliance: First',
  },
  {
    value: 'PENDING_ONBOARDING_COMPLIANCE_SECOND',
    label: 'Pending Onboarding Compliance',
    labelExtended: 'Pending Onboarding Compliance: Second',
  },
  {
    value: 'PENDING_ONBOARDING_COMPLIANCE_THIRD',
    label: 'Pending Onboarding Compliance',
    labelExtended: 'Pending Onboarding Compliance: Third',
  },
  { value: 'PENDING_SENIOR_COMPLIANCE', label: 'Pending Senior Compliance' }, // obsolete status
  { value: 'PENDING_ONBOARDING_OPERATIONS', label: 'Pending Onboarding Operations' },
  { value: 'PENDING_ONBOARDING_OPERATIONS_SECOND', label: 'Pending Onboarding Operations: Second' },
  { value: 'PENDING_FORMS_SIGNATURE', label: 'Pending Forms Signature' },
  { value: 'PENDING_BANKING', label: 'Pending Banking' },
  { value: 'PENDING_INTERNAL_CHECKING', label: 'Pending Internal Checking' },
  { value: 'PENDING_INTEGRATION_CHECKING', label: 'Pending Integration Checking' },
  { value: 'PENDING_DIRECTOR_COMPLIANCE', label: 'Pending Director Compliance' },
  { value: 'PENDING_UK_MLRO', label: 'Pending UK MLRO' },
  { value: 'PENDING_MALTA_MLRO', label: 'Pending Malta MLRO' },
  { value: 'PENDING_HEAD_AML', label: 'Pending Head AML' },
  {
    value: 'PENDING_SALES_TEAM_FINAL',
    label: 'Pending Sales Team',
    labelExtended: 'Pending Sales Team: Final',
  },
];

export const getApplicationStatusOptions = (useExtendedLabels = false): ValueLabel[] => {
  const isEmb = checkProductName(['EMBANK']);
  const isOpenPayd = checkProductName(['OPENPAYD']);

  return [
    ...applicationStatusOptions_Common,
    ...(isEmb ? applicationStatusOptions_EMB : []),
    ...(isOpenPayd ? applicationStatusOptions_OpenPayd : []),
  ]
    .sort((a, b) => (a.label > b.label ? 1 : -1))
    .map((item) => ({
      value: item.value,
      label: useExtendedLabels ? item.labelExtended ?? item.label : item.label,
    }));
};

// For dynamic forms other than internal
export const notEditableStatusesForForms = [
  'PENDING_ON_CUSTOMER',
  'PENDING_ON_CUSTOMER_SECOND',
  'PENDING_ON_CUSTOMER_PREQUESTIONNAIRE',
  'PENDING_ON_CUSTOMER_FURTHER_INFO',
  'PENDING_ON_CUSTOMER_FURTHER_FORMS',
  'CLIENT_INFORMED',
  'SIGNED',
  'PENDING_SIGNATURE',
];

export const notEditableStatuses = ['SIGNED', 'PENDING_SIGNATURE'];

export const notEditableSectionStatuses = ['SIGNED', 'PENDING_SIGNATURE', 'VERIFIED'];
