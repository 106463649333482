import React from 'react';
import { Panel, IconChip } from 'uikit';
import { Typography, Box, Stack, Chip, Divider, Avatar } from '@mui/material';
import { colors } from 'theme/constants';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DateRangeIcon from '@mui/icons-material/DateRange';

export const Chips = () => (
  <Box mb={8}>
    <Typography variant="h2" gutterBottom mb={4}>
      Chips
    </Typography>

    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Base
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip size="small" color={color as any} label={`${color}`} />
            <Chip
              variant="outlined"
              color={color as any}
              size="small"
              label={`${color} outlined`}
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* CLICKABLE */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Clickable{' '}
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip
              onClick={() => console.log('click')}
              size="small"
              color={color as any}
              label={`${color} clickable`}
            />
            <Chip
              onClick={() => console.log('click')}
              variant="outlined"
              size="small"
              color={color as any}
              label={`${color} outlined clickable`}
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* CLICKABLE WITH ICON BEFORE */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Clickable with icon{' '}
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip
              icon={<DateRangeIcon />}
              onClick={() => console.log('click')}
              size="small"
              color={color as any}
              label={`${color} clickable`}
            />
            <Chip
              icon={<MoreHorizIcon />}
              onClick={() => console.log('click')}
              variant="outlined"
              size="small"
              color={color as any}
              label={`${color} clickable`}
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* DELETABLE */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Deletable
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box mt={4}>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip
              onDelete={() => console.log('delete')}
              size="small"
              color={color as any}
              label={`${color} deletable`}
            />
            <Chip
              onDelete={() => console.log('delete')}
              variant="outlined"
              size="small"
              color={color as any}
              label={`${color} deletable`}
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* CLICKABLE AND DELETABLE */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Clickable deletable
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip
              onClick={() => console.log('click')}
              onDelete={() => console.log('delete')}
              size="small"
              color={color as any}
              label={`${color} clickable deletable`}
            />
            <Chip
              onClick={() => console.log('click')}
              onDelete={() => console.log('delete')}
              variant="outlined"
              size="small"
              color={color as any}
              label={`${color} clickable deletable`}
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* AVATAR */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Avatar
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        {colors.map((color, i) => (
          <Stack key={i} direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
            <Chip
              onClick={() => console.log('avatar click')}
              avatar={<Avatar>M</Avatar>}
              size="small"
              color={color as any}
              label="With Avatar"
            />
            <Chip
              onClick={() => console.log('avatar click')}
              avatar={<Avatar>M</Avatar>}
              variant="outlined"
              size="small"
              color={color as any}
              label="Outlined with Avatar"
            />
          </Stack>
        ))}
      </Box>
    </Panel>

    {/* CLICKABLE WITHOUT TEXT */}
    <Panel>
      <Typography variant="h4" gutterBottom>
        Chip: Clickable without text{' '}
      </Typography>
      <Divider sx={{ mb: 6 }} />
      <Box>
        <Stack direction="row" rowGap={1} gap={1} flexWrap="wrap" mb={4}>
          {colors.map((color, i) => (
            <IconChip
              key={i}
              icon={<MoreHorizIcon />}
              onClick={() => console.log('click')}
              size="small"
              color={color as any}
            />
          ))}
        </Stack>
      </Box>
    </Panel>
  </Box>
);
