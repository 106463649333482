import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { HeadLink } from 'muikit';
import {
  useGetApplicationQuery,
  useGetApplicationHistoryQuery,
} from 'services/gatewayApi/applicationApi';
import { useGetApplicationFormsQuery } from 'services/gatewayApi/applicationFormsApi';
import { useGetAppFormSectionsQuery } from 'services/gatewayApi/applicationFormSectionsApi';
import {
  getApplicationStatusOptions,
  decisions,
  getDecisionIcon,
} from 'features/ApplicationManagement/constants';
import { allowSendAndDeleteActionsStatuses } from 'features/ApplicationManagement/constants/appEntitiesMap';
import { formShouldBeVisible } from 'onboarding/api/onboarding/utils';
import { ApplicationMenu } from './ApplicationMenu';
import { FormsToSign } from './ApplicationMenu/ApplicationMenuProps';
import { SendEmailDialog } from '../../../SendEmailDialog';
import { applicationKinds } from './constants';

export const TabHeadLink = ({ linkIconBack = false, applicationId }) => {
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);

  const { data: applicationDetails, isLoading: isLoadingApplication } =
    useGetApplicationQuery(applicationId);
  const { data: forms, isLoading: isLoadingForms } = useGetApplicationFormsQuery({ applicationId });
  const { data: allFormSections, isLoading: isLoadingSections } = useGetAppFormSectionsQuery({
    applicationId,
  });

  const {
    data: applicationHistory = [],
    isLoading: isLoadingApplicationHistory,
  } = useGetApplicationHistoryQuery(applicationId);

  const categoryId = applicationDetails?.categoryId || undefined;

  const mainStatus = applicationDetails?.mainStatus;
  const lastHistoryForStatus =
    !isLoadingApplicationHistory &&
    applicationHistory.find((h) => h.applicationStatus === mainStatus);
  const { decision, reason } = lastHistoryForStatus || {};

  const applicationStatusOptions = getApplicationStatusOptions();

  const getMainStatusLabel = (value) =>
    applicationStatusOptions.find((el) => el.value === value)?.label;

  const getFormsToSign = (): FormsToSign => {
    const allFormRecords = Object.fromEntries(
      forms
        ?.map((f) => f.records)
        .flat()
        .map((r) => [r.id, r]) || [],
    );

    const shouldBeVerifiedForms =
      forms
        ?.filter((form) => form.status === 'DONE')
        ?.map((form) =>
          formShouldBeVisible(form, allFormRecords, false) && !form.isInternal ? form : undefined,
        )
        .filter((el) => el) || [];

    const shouldBeVerifiedSections =
      allFormSections?.filter(
        (section) =>
          shouldBeVerifiedForms.some(
            (form) =>
              form.formSection.id === section.sectionId && form.partitionName === section.partition,
          ) && section.status !== 'SIGNED',
      ) || [];
    const totalCount = shouldBeVerifiedSections.length;

    const signedSections = shouldBeVerifiedSections.filter(
      (section) => section.status === 'SIGNED',
    );
    const signedCount = signedSections.length;

    const pendingSections = shouldBeVerifiedSections.filter(
      (section) => section.status === 'PENDING_SIGNATURE',
    );
    const pendingCount = pendingSections.length;

    const verifiedSections = shouldBeVerifiedSections.filter(
      (section) => section.status === 'VERIFIED',
    );
    const verifiedCount = verifiedSections.length;

    return {
      totalCount,
      signedCount,
      verifiedCount,
      pendingSections,
      pendingCount,
      canBeSigned:
        totalCount - signedCount === verifiedCount + pendingCount &&
        (verifiedCount > 0 || pendingCount > 0),
    };
  };

  const hideMenuButtonStatuses = [
    'PENDING_ON_CUSTOMER_FURTHER_FORMS',
    'PENDING_ON_CUSTOMER_FURTHER_INFO',
  ];
  const showMenuButton = !hideMenuButtonStatuses.includes(mainStatus);

  const [showSendEmailDialog, setShowSendEmailDialog] = useState<boolean>(false);

  const isLoading = isLoadingApplication || isLoadingForms || isLoadingSections;

  const location = useLocation();
  const backPath = location.pathname.split('/').slice(0, -1).join('/');

  const link = linkIconBack ? backPath : `${location.pathname}/${applicationId}`;

  return (
    <>
      {!isLoading ? (
        <HeadLink
          categoryId={categoryId}
          applicationId={applicationId}
          title={applicationKinds[applicationDetails?.kind] || 'Loading...'}
          description={
            <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-start">
              <Typography variant="body1" sx={{ minWidth: '250px' }}>
                {getMainStatusLabel(applicationDetails?.mainStatus)}
              </Typography>

              {(!!decision || !!reason) && (
                <Tooltip title={reason}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      borderRadius: '6px',
                      backgroundColor: 'white',
                      p: 1,
                      pr: 2,
                    }}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      {getDecisionIcon(decision)}
                      <Typography
                        variant="body2"
                        color="grey.900"
                        component="span"
                        sx={{
                          m: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '100px',
                        }}
                      >
                        {decision
                          ? decisions.find((d) => d.value === decision)?.label +
                            (reason ? `: ${reason}` : '')
                          : reason}
                      </Typography>
                    </Stack>
                  </Box>
                </Tooltip>
              )}
            </Stack>
          }
          actions={
            showMenuButton &&
            applicationDetails &&
            !isLoading && (
              <>
                {allowSendAndDeleteActionsStatuses.includes(mainStatus) && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSendEmailDialog(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Send to Customer
                  </Button>
                )}
                <ApplicationMenu
                  mainStatus={mainStatus}
                  application={applicationDetails}
                  formsToSign={getFormsToSign()}
                  handleOpenHistory={(e) => {
                    e.stopPropagation();
                    setOpenHistoryModal(true);
                  }}
                />
              </>
            )
          }
        />
      ) : (
        <div style={{ padding: '1rem 0' }}>Loading...</div>
      )}

      {showSendEmailDialog && (
        <SendEmailDialog
          applicationId={applicationId}
          setShowDialog={setShowSendEmailDialog}
          showDialog={showSendEmailDialog}
        />
      )}
    </>
  );
};
