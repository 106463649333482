import React, { useState } from 'react';
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Document, DocumentTitle, DocumentActions } from 'muikit/Document';
import { CancelConfirmButtonStack } from 'muikit/Button';
import { useAddApplicationDocumentMutation } from 'services/gatewayApi/applicationDocumentsApi';
import { RightContentWrapper } from './RightContentWrapper';

interface ApplicationDocumentDraftProps {
  applicationId: string;
  isInternal?: boolean;
  onCancel: () => any;
}

export const ApplicationDocumentDraft = (props: ApplicationDocumentDraftProps) => {
  const { onCancel, applicationId, isInternal = false } = props;
  const [titleValue, setTitleValue] = useState<string>('');
  const [addDocument, { isLoading: isAdding }] = useAddApplicationDocumentMutation();

  const documentAdditionHandler = async (title: string) => {
    try {
      await addDocument({ title, applicationId, isInternal }).unwrap();
      onCancel();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Document>
      <DocumentTitle
        title={titleValue}
        onChange={(e) => setTitleValue(e.currentTarget.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            !!titleValue.trim() && documentAdditionHandler(titleValue);
          }
        }}
      />
      <RightContentWrapper>
        <DocumentActions>
          <CancelConfirmButtonStack
            onConfirm={() => documentAdditionHandler(titleValue)}
            onCancel={() => onCancel()}
            cancelProps={{ disabled: isAdding }}
            confirmProps={{
              disabled: isAdding || !titleValue.trim(),
            }}
          />
        </DocumentActions>
      </RightContentWrapper>
    </Document>
  );
};
