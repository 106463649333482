import React from 'react';
import { IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT } from 'constants/constants';
import { useDeleteClientCategoryMutation } from 'services/gatewayApi/clientsApi';
import { BodyRow, BodyTableCell } from 'uikit';
import { Category } from './types';

interface CategoryRowProps {
  category: Category;
  isLoading: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCategory: React.Dispatch<React.SetStateAction<Category>>;
}

export const CategoryRow: React.FC<CategoryRowProps> = ({
  category,
  isLoading,
  setOpen,
  setCategory,
}) => {
  const [deleteClientCategory] = useDeleteClientCategoryMutation();

  return (
    <BodyRow
      hover
      onClick={() => {
        setOpen(true);
        setCategory(category);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <BodyTableCell>{category.name}</BodyTableCell>
      <BodyTableCell>{category.createdBy}</BodyTableCell>
      <BodyTableCell>{formatIsoDate(category.createdDate, DATE_FORMAT)}</BodyTableCell>
      <BodyTableCell>{category.updatedBy}</BodyTableCell>
      <BodyTableCell>{formatIsoDate(category.updatedDate, DATE_FORMAT)}</BodyTableCell>
      <BodyTableCell align="center">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
            setCategory(category);
          }}
          disabled={isLoading}
          sx={{ mr: 4 }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            deleteClientCategory({ categoryId: category.id });
          }}
          edge="end"
          disabled={isLoading}
        >
          <DeleteIcon />
        </IconButton>
      </BodyTableCell>
    </BodyRow>
  );
};
