import React from 'react';
import { BodyRow, BodyTableCell, MemoizedChipSelector } from 'uikit';
import { Client } from './types';
import { formatIsoDate } from '../../utils/formatter';
import { DATE_FORMAT_MESSAGES } from '../../constants/constants';
import { ValueLabel } from '../../types/types';

interface ClientRowProps {
  client: Client;
  setClientDetails: React.Dispatch<React.SetStateAction<Client>>;
  updateClient: any;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  categories: ValueLabel[];
  isLoadingCategories: boolean;
  selected: boolean;
}

export const ClientRow: React.FC<ClientRowProps> = ({
  client,
  setClientDetails,
  updateClient,
  setOpenDetailsDrawer,
  categories,
  isLoadingCategories,
  selected = false,
}) => {
  const clientCategories: ValueLabel[] = client.clientCategories.map((el) => ({
    value: el.id,
    label: el.name,
  }));

  const handleUpdateClient = (newList) => {
    const model = {
      name: client.name,
      clientId: client.clientId,
      categoriesId: newList.map((el) => el.value),
      incorporationDate: client.incorporationDate,
    };
    updateClient(client.id, model);
  };

  return (
    <>
      <BodyRow
        hover
        selected={selected}
        onClick={() => {
          setClientDetails(client);
          setOpenDetailsDrawer(true);
        }}
        sx={!selected ? { cursor: 'pointer' } : {}}
      >
        <BodyTableCell>{client.clientId}</BodyTableCell>
        <BodyTableCell>{client.name}</BodyTableCell>
        <BodyTableCell>{client.createdBy}</BodyTableCell>
        <BodyTableCell>{formatIsoDate(client.createdDate, DATE_FORMAT_MESSAGES)}</BodyTableCell>
        <BodyTableCell>
          {isLoadingCategories ? (
            'Loading categories...'
          ) : (
            <MemoizedChipSelector
              list={categories}
              selectedList={clientCategories}
              setSelectedList={handleUpdateClient}
              limitTags={2}
            />
          )}
        </BodyTableCell>
      </BodyRow>
    </>
  );
};
