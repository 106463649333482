export default function (persToLook) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) return false;
  const { permissions, email } = user;

  const userPers = email ? permissions.map((p) => p.name) : [];

  for (const per of persToLook) {
    if (userPers.indexOf(per) > -1) {
      return true; // exists
    }
  }

  return false;
}
