import { inputBaseClasses } from '@mui/material';
import { baseTheme } from '../baseTheme';
import { grey } from '../palette-blocks';

export const FormHelperText = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme, ownerState }: { theme: any; ownerState: any }) => ({
      marginLeft: 0,
    }),
  },
};
