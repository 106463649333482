import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './AppMenu.scss';
import CloseIcon from '@mui/icons-material/Close';
import { AppMenuContent } from '../AppMenuContent/AppMenuContent';
import { Badge } from '../../Badge';
import { useModalDialogEffect } from 'hooks';

export const AppMenu = ({ showAppMenu, setShowAppMenu, tenantLogoURL, user }) => {
  const [rootClassName, setRootClassName] = useState<string>('AppMenuContent');
  const closeAppMenu = () => setShowAppMenu(false);

  useModalDialogEffect({
    hideModal: () => setShowAppMenu(false),
    isShowed: showAppMenu,
  });

  useEffect(() => {
    setRootClassName('AppMenuContent AppMenuContentShow');
  }, []);

  return ReactDOM.createPortal(
    showAppMenu && (
      <div className={'AppMenu'}>
        <div className="AppMenuBackdrop" onClick={closeAppMenu} />

        <div className={rootClassName}>
          <div className="container container-OpenPayd">
            <div className={'CloseButtonWrapper'}>
              <Badge interactive bold size="sm" prefix={<CloseIcon />} onClick={closeAppMenu} />
            </div>

            <div className="AppMenuHeader">
              <img src={tenantLogoURL} alt="product-logo" style={{ width: '120px' }} />
            </div>

            <AppMenuContent user={user} closeAppMenu={closeAppMenu} />
          </div>
        </div>
      </div>
    ),
    document.getElementById('app-menu') as HTMLDivElement,
  );
};
