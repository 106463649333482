export const LOAD_MERCHANTS = 'LOAD_MERCHANTS';
export const LOAD_MERCHANTS_FAIL = 'LOAD_MERCHANTS_FAIL';
export const LOAD_MERCHANTS_SUCCESS = 'LOAD_MERCHANTS_SUCCESS';

export const LOAD_MERCHANTS_WITHOUT_CATEGORIES = 'MERCHANTS_WITHOUT_CATEGORIES';
export const LOAD_MERCHANTS_WITHOUT_CATEGORIES_FAIL = 'LOAD_MERCHANTS_WITHOUT_FAIL';
export const LOAD_MERCHANTS_WITHOUT_CATEGORIES_SUCCESS = 'LOAD_MERCHANTS_WITHOUT_SUCCESS';

export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const CREATE_MERCHANT_SUCCESS = 'CREATE_MERCHANT_SUCCESS';
export const CREATE_MERCHANT_FAIL = 'CREATE_MERCHANT_FAIL';

export const DELETE_MERCHANT = 'DELETE_MERCHANT';
export const DELETE_MERCHANT_SUCCESS = 'DELETE_MERCHANT_SUCCESS';
export const DELETE_MERCHANT_FAIL = 'DELETE_MERCHANT_FAIL';

export const LOAD_SELECTED_MERCHANT = 'LOAD_SELECTED_MERCHANT';
export const LOAD_SELECTED_MERCHANT_SUCCESS = 'LOAD_SELECTED_MERCHANT_SUCCESS';
export const LOAD_SELECTED_MERCHANT_FAIL = 'LOAD_SELECTED_MERCHANT_FAIL';

export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS';
export const UPDATE_MERCHANT_FAIL = 'UPDATE_MERCHANT_FAIL';

export const LOAD_MERCHANT_CATEGORIES = 'LOAD_MERCHANT_CATEGORIES';
export const LOAD_MERCHANT_CATEGORIES_FAIL = 'LOAD_MERCHANT_CATEGORIES_FAIL';
export const LOAD_MERCHANT_CATEGORIES_SUCCESS = 'LOAD_MERCHANT_CATEGORIES_SUCCESS';

export const CREATE_MERCHANT_CATEGORY = 'CREATE_MERCHANT_CATEGORIES';
export const CREATE_MERCHANT_CATEGORY_FAIL = 'CREATE_MERCHANT_CATEGORIES_FAIL';
export const CREATE_MERCHANT_CATEGORY_SUCCESS = 'CREATE_MERCHANT_CATEGORIES_SUCCESS';

export const UPDATE_MERCHANT_CATEGORY = 'UPDATE_MERCHANT_CATEGORY';
export const UPDATE_MERCHANT_CATEGORY_SUCCESS = 'UPDATE_MERCHANT_CATEGORY_SUCCESS';
export const UPDATE_MERCHANT_CATEGORY_FAIL = 'UPDATE_MERCHANT_CATEGORY_FAIL';

export const DELETE_MERCHANT_CATEGORY = 'DELETE_MERCHANT_CATEGORY';
export const DELETE_MERCHANT_CATEGORY_SUCCESS = 'DELETE_MERCHANT_CATEGORY_SUCCESS';
export const DELETE_MERCHANT_CATEGORY_FAIL = 'DELETE_MERCHANT_CATEGORY_FAIL';

export const SHOW_MERCHANT_CATEGORY_FORM = 'SHOW_MERCHANT_CATEGORY_FORM';
export const CLOSE_MERCHANT_CATEGORY_FORM = 'CLOSE_MERCHANT_CATEGORY_FORM';

export const SHOW_UPDATE_MERCHANT_CATEGORY_FORM = 'SHOW_UPDATE_MERCHANT_CATEGORY_FORM';
export const CLOSE_UPDATE_MERCHANT_CATEGORY_FORM = 'CLOSE_UPDATE_MERCHANT_CATEGORY_FORM';

export const SHOW_MERCHANT_FORM = 'SHOW_MERCHANT_FORM';
export const CLOSE_MERCHANT_FORM = 'CLOSE_MERCHANT_FORM';
export const SHOW_UPDATE_MERCHANT_FORM = 'SHOW_UPDATE_MERCHANT_FORM';
export const CLOSE_UPDATE_MERCHANT_FORM = 'CLOSE_UPDATE_MERCHANT_FORM';

export const LOAD_MERCHANT_MERCHANT_CATEGORIES = 'LOAD_MERCHANT_MERCHANT_CATEGORIES';
export const LOAD_MERCHANT_MERCHANT_CATEGORIES_SUCCESS =
  'LOAD_MERCHANT_MERCHANT_CATEGORIES_SUCCESS';
export const LOAD_MERCHANT_MERCHANT_CATEGORIES_FAIL = 'LOAD_MERCHANT_MERCHANT_CATEGORIES_FAIL';

export const DELETE_MERCHANT_MERCHANT_CATEGORY = 'DELETE_MERCHANT_MERCHANT_CATEGORY';
export const DELETE_MERCHANT_MERCHANT_CATEGORY_SUCCESS =
  'DELETE_MERCHANT_MERCHANT_CATEGORY_SUCCESS';
export const DELETE_MERCHANT_MERCHANT_CATEGORY_FAIL = 'DELETE_MERCHANT_MERCHANT_CATEGORY_FAIL';

export const LINK_MERCHANT_CATEGORY = 'LINK_MERCHANT_CATEGORY';
export const LINK_MERCHANT_CATEGORY_SUCCESS = 'LINK_MERCHANT_CATEGORY_SUCCESS';
export const LINK_MERCHANT_CATEGORY_FAIL = 'LINK_MERCHANT_CATEGORY_FAIL';
