import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  IconButton,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import { Download as DownloadIcon } from '@mui/icons-material';

export function ButtonsUpdated({ color }) {
  const [priLoading, setPriLoading] = useState(true);

  const title = color.charAt(0).toUpperCase() + color.slice(1);

  return (
    <>
      <Box>
        <Typography variant="h4" gutterBottom mb={4}>
          {title}
        </Typography>

        <Divider />

        <Box mb={6} mt={4}>
          <FormControlLabel
            control={
              <Switch
                checked={priLoading}
                onChange={() => setPriLoading(!priLoading)}
                name="loading"
              />
            }
            label="Loading…"
          />
        </Box>

        <Box mb={6}>
          <Button color={color} variant="contained">
            {title} contained
          </Button>
          <Button color={color} variant="contained" disabled>
            {title} contained disabled
          </Button>
          <Button color={color} variant="contained" startIcon={<AddIcon />}>
            {title} contained with icon before
          </Button>
          <Button
            color={color}
            variant="contained"
            startIcon={<CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />}
          >
            {title} contained with CircularProgress
          </Button>
          <Button color={color} variant="contained" endIcon={<AddIcon />}>
            {title} with icon after
          </Button>
          <LoadingButton
            color={color}
            variant="contained"
            loading={priLoading}
            onClick={() => setPriLoading(!priLoading)}
          >
            Loading
          </LoadingButton>
        </Box>

        <Box mb={6}>
          <Button color={color} variant="text">
            {title} text
          </Button>
          <Button color={color} variant="text" disabled>
            {title} text disabled
          </Button>
          <Button color={color} variant="text" startIcon={<AddIcon />}>
            {title} text with icon before
          </Button>
          <Button
            color={color}
            variant="text"
            startIcon={<CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />}
          >
            {title} text with CircularProgress
          </Button>
          <Button color={color} variant="text" endIcon={<AddIcon />}>
            {title} with icon after
          </Button>
          <LoadingButton
            color={color}
            variant="text"
            loading={priLoading}
            onClick={() => setPriLoading(!priLoading)}
          >
            Loading
          </LoadingButton>
        </Box>

        <Box>
          <Button color={color} variant="outlined">
            {title} outlined
          </Button>
          <Button color={color} variant="outlined" disabled>
            {title} outlined disabled
          </Button>
          <Button color={color} variant="outlined" startIcon={<AddIcon />}>
            {title} outlined with icon before
          </Button>
          <Button
            color={color}
            variant="outlined"
            startIcon={<CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />}
          >
            {title} outlined with CircularProgress
          </Button>
          <Button color={color} variant="outlined" endIcon={<AddIcon />}>
            {title} outlined with icon after
          </Button>
          <LoadingButton
            color={color}
            variant="outlined"
            loading={priLoading}
            onClick={() => setPriLoading(!priLoading)}
          >
            Loading
          </LoadingButton>
        </Box>

        <Box>
          <IconButton size="small">
            <DownloadIcon fontSize="small" color={color} />
          </IconButton>

          <IconButton size="medium">
            <DownloadIcon fontSize="medium" color={color} />
          </IconButton>

          <IconButton size="large">
            <DownloadIcon fontSize="large" color={color} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
