import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectedProductIdData } from 'selectors/user';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { PageContainer, StickyHeader, FiltersSelectable } from '../../uikit';
import { ExceptionRulesList } from './exceptionRulesList';
import { PageControl } from './styles';
import { filterScheme, resultActions, ruleTypes } from './constants';
import { useGetAllCountriesQuery } from '../../services/gatewayApi/countryRiskApi';
import { useGetMerchantsQuery } from '../../services/gatewayApi/merchantsApi';
import { checkProductApiName } from '../../utils';

export const ExceptionRules = () => {
  window.scrollTo(0, 0);

  const selectedProductId = useSelector(makeSelectedProductIdData());

  const { countries, isLoadingCountries } = useGetAllCountriesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      countries: data?.map((el) => ({
        value: el.countryCode,
        label: `${el.countryCode}: ${el.name}`,
      })),
      isLoadingCountries: isLoading,
    }),
  });
  const { merchants = [], isLoading: isLoadingMerchants } = useGetMerchantsQuery(
    { productId: selectedProductId, size: 2000 },
    {
      skip: !selectedProductId,
      selectFromResult: ({ data }) => ({
        merchants: data?.results.map((item) => ({
          label: `${item.merchantName} (${item.mid})`,
          value: item.id,
        })),
        isLoading,
      }),
    },
  );

  const isLoading = isLoadingCountries || isLoadingMerchants;

  const initialFilter = {};
  const [filter, setFilter] = useState<IFilterValue>(initialFilter);

  const options = {
    merchantId: merchants,
    country: countries,
    beneficiaryCountry: countries,
    binCountry: countries,
    billingCountry: countries,
    senderCountry: countries,
    resultAction: resultActions,
    exceptionRuleTypes: ruleTypes.filter((el) => checkProductApiName(el.product)),
  };

  return (
    <>
      <Helmet title="Exception rules" />
      <PageContainer>
        <StickyHeader>
          <PageControl>
            <Typography variant="h1">Exception rules</Typography>
            <FiltersSelectable
              filterScheme={filterScheme.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
              filterValue={filter}
              filterHandler={setFilter}
              onReset={() => setFilter(initialFilter)}
              options={options}
              disabled={isLoading}
            />
          </PageControl>
        </StickyHeader>
      </PageContainer>

      <PageContainer>
        <Box>
          <ExceptionRulesList filterValues={filter} productId={selectedProductId} />
        </Box>
      </PageContainer>
    </>
  );
};
