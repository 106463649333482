import { ModalDialog } from 'uikit';
import React from 'react';
import { TextField, Typography } from '@mui/material';

interface RejectDialogProps {
  open: boolean;
  onClose: () => any;
  handleAction: (reason: string) => any;
  isRejecting: boolean;
}

export const RejectDialog = (props: RejectDialogProps) => {
  const { open, onClose, handleAction, isRejecting } = props;
  const [rejectReason, setRejectReason] = React.useState<string>('');

  return (
    <ModalDialog
      variant="error"
      open={open}
      onClose={onClose}
      handleAction={() => handleAction(rejectReason)}
      actionTitle="Reject"
      disableAction={!rejectReason.trim()}
      isLoading={isRejecting}
      title="Reject uploaded document"
    >
      <Typography variant="body1" color="grey.600">
        Please provide short description why you want to decline this document
      </Typography>
      <TextField
        sx={{ mt: 4 }}
        label="Description"
        onChange={(e) => setRejectReason(e.target.value)}
        multiline
        fullWidth
        rows={4}
      />
    </ModalDialog>
  );
};
