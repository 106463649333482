// eslint-disable-next-line import/no-cycle
import {
  ApplicationField,
  ApplicationForm,
  ApplicationRecord,
  ConditionsActions,
  FieldValidation,
  Validation,
} from './applicationsApi.types';
import { getRandomInt } from '../../common/utils/random';
// eslint-disable-next-line import/no-cycle
import { computeConditions, getDDFCondition } from '../../ddforms/parser/adapters/formConditions';
import { Dictionary } from '../../common/types';

export type DDFField = {
  component: Pick<ApplicationField, 'type'>;
  name: string;
  width: number;
  id: string;
  label: string;
  templateId: string;
};

export const buildForms = (forms: Array<ApplicationForm>) =>
  forms
    .sort((a, b) => (a.formOrder > b.formOrder ? 1 : -1))
    .map((form) => {
      const { records, ...rest } = form;
      const fields = buildFields(form.records, undefined, true);
      return { ...rest, ...fields };
    });

/** If rootId is defined, it returns only a part of the result tree
 It is supposed to return subtree to render a list of complex fields
 'autosave' component should be added only once to the root of the form
 */

export const buildFields = (
  records: Array<ApplicationRecord>,
  rootId?: string,
  skipAutosave = false,
): DDFField | { fields: Array<DDFField> } => {
  const mapRecordToDDFField = (record: ApplicationRecord) => ({
    id: record.id,
    fieldId: record.field.id,
    name: record.id,
    templateId: record.formTemplateFieldId,
    ...(record.field.displayFormat && { displayFormat: record.field.displayFormat }),
    label: record.field.title,
    description: record.field.description,
    component: record.field.type || (record.type === 'list' && 'list') || 'complex',
    ...((record.type === 'key' || record.type === 'value') && { keyValueType: record.type }),
    ...(record.field.selectorId && { selectorId: record.field.selectorId }),

    // placeholder: 'testplaceholder',
    width: record.weight,

    ...(record.value && { initialValue: getFieldValue(record.value, record.field.type) }),
    ...(record.parent && { parent: record.parent }),

    ...(record.validations?.find((v) => v.fieldValidation.name === 'required') && {
      isRequired: true,
    }),
    ...(record.validations && { validate: getDDFValidation(record.validations) }),
    ...(record?.condition && { condition: getDDFCondition(record?.condition, record.id) }),
  });

  const result: Record<string, any> = {
    root: {},
  };

  records.forEach((record) => {
    // that's how it intended
    // eslint-disable-next-line no-param-reassign
    record.parent ??= 'root';
    (((result[record.parent] ??= {}).fields ??= [])[record.row - 1] ??= {
      name: `row-${getRandomInt()}`,
      component: 'row',
      parent: record.parent,
      fields: [],
    }).fields[record.col - 1] = Object.assign(
      (result[record.id] ??= {}),
      mockData(mapRecordToDDFField(record), records),
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  !skipAutosave && result.root.fields.push({ component: 'autosave', name: 'autosave' });

  return rootId ? result[rootId] : result.root;
};

// I'm really sorry, but I have to do this (temporarily)
let appId = '';
let formId = '';
export const setupContext = (a: string, f: string) => {
  appId = a;
  formId = f;
};

//                                          update type later
const mockData = (ddfField: any, ddfFields: Array<ApplicationRecord>) => {
  const res = { ...ddfField };
  // Mock action for OP->CI->PEP form checkbox to delete all complex fields from the list
  // Will be provided by backend later
  // Not required for now but I leave it as the example
  // if (ddfField.fieldId === 251) {
  //   res.actions = {};
  //   res.actions.onClick = ['removeRecordOnChangeIfValueIsEqualTo'];
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  //   res.actions.onClick.push('no', appId, formId, records.find((r) => r.field.id === 253)?.id);
  // }
  // Mock resolveProps action for OP->CI->II form to uncheck all others checkboxes on checkbox check
  // Will be provided by backend later
  const individualToMainUserMap = {
    individual_last_name: 'main_user_last_name',
    individual_first_name: 'main_user_first_name',
    individual_nationality: 'main_user_nationality',
    individual_date_of_birth: 'main_user_dob',
    individual_place_of_birth: 'main_user_place_of_birth',
    individual_passport_id_number: 'main_user_passport',

    individual_address_country: 'main_user_country',
    individual_address_postcode: 'main_user_postcode',
    individual_address_address: 'main_user_address',

    // excluded because it is a selector in the Individual Information form
    // and a regular text field in the Main User form
    // so to update the value, I have to figure out type of the field in one form,
    // another form, compare them, find value in the dictionary
    // (if it is there, but maybe it is in field properties? so I also need to check there too)
    // TOO MUCH, ISN'T IT?
    // I really hope we will change the type or say fuck it
    // individual_position_in_company: 'main_user_position_in_company',
  };

  const inheritableFieldIdsToDisable = [211, 213, 214, 215, 216, 217, 220, 221, 222];
  const mainUserCheckboxName = 'individual_is_main_user';

  if (ddfField.fieldId === 223) {
    res.actions = {};
    res.actions.resolveProps = ['selectOneCheckboxOfGroupAndUpdateDependantFields'];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    res.actions.resolveProps.push({
      formData: { applicationId: appId, formId, fields: ddfFields },
      extra: { individualToMainUserMap, templateId: 34 },
    });
  }
  if (inheritableFieldIdsToDisable.includes(ddfField.fieldId)) {
    res.actions = {};
    res.actions.resolveProps = ['disableOnCheck'];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    res.actions.resolveProps.push({
      extra: { checkboxName: mainUserCheckboxName },
    });
  }

  return res;
};

const getFieldValue = (val: string, type?: string) =>
  type === 'multi-selector' ? getMultiSelectValue(val) : getTypedValue(val);

// As backend returns value only as a string we have to parse it
export const getTypedValue = (val?: string) => {
  if (typeof val !== undefined) {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }
  throw new Error('value is undefined');
};

const getMultiSelectValue = (val: string) => val.split(';');

// Validation
const thresholdValidationTypes = [
  'min-length',
  'max-length',
  'exact-length',
  'min-number-value',
  'max-number-value',

  'min-items',
  'max-items',
  'exact-items',
];
const nameToTypeMap: Record<string, string> = {
  required: 'required',
  minLength: 'min-length',
  maxLength: 'max-length',
  length: 'exact-length',
  gtq: 'min-number-value',
  ltq: 'max-number-value',
  regexp: 'pattern',
  numbers: 'pattern',
  email: 'pattern',
  url: 'url',
  disableFuture: 'disableFuture',

  minListLength: 'min-items',
};

const mapValidationToDDFValidation = (validation: FieldValidation) => {
  const validationType = nameToTypeMap[validation.name];
  const DDFValidation = {
    type: validationType,
    message: validation.hintMessage,
    ...(validationType === 'pattern' && { pattern: validation.defaultArgs }),
    ...(thresholdValidationTypes.includes(validationType) && { threshold: validation.defaultArgs }),
  };
  return DDFValidation;
};

const getDDFValidation = (validation: Array<Validation>): Array<any> => {
  if (validation.length === 0) return [];
  // Lists with minListValidation should be required by default
  const validationCopy = [...validation];
  if (validationCopy.find((v) => v.fieldValidation.name === 'minListLength')) {
    validationCopy.push({
      fieldValidation: {
        name: 'required',
        defaultArgs: 'true',
        hintMessage: 'Please add at least one item',
      },
    });
  }
  return validationCopy.map((v) => mapValidationToDDFValidation(v.fieldValidation));
};

// Conditions
const hasConditionAndVisibilityAction = (condition?: ConditionsActions) => {
  if (condition === undefined || condition.actions[0].visible === undefined) {
    // console.log('is visible because it has neither conditions nor visibility actions');
    return false;
  }
  return true;
};
const computedVisibility = (
  condition: ConditionsActions,
  action: { visible: boolean },
  records: any,
): boolean => {
  const isVisible = computeConditions(condition, records);
  // console.log(
  //   `is ${(isVisible ? action.visible : true) ? 'visible' : 'hidden'} because it has condition: `,
  //   condition,
  //   `\nand depends on record value: `,
  //   records[condition.conditions[0].dependsOn],
  // );
  return isVisible ? action.visible : true;
};

export const formShouldBeVisible = (
  form: Pick<ApplicationForm, 'title' | 'condition' | 'isInternal'>,
  records: Dictionary<Pick<ApplicationRecord, 'id' | 'value'> & { name: string }>,
  hideBackofficeForm: boolean,
): boolean => {
  const { condition, isInternal } = form || {};

  if (hideBackofficeForm && isInternal) {
    return false;
  }
  if (hasConditionAndVisibilityAction(condition)) {
    for (const action of condition.actions) {
      if (action.visible !== undefined) {
        return computedVisibility(condition, action as { visible: boolean }, records);
      }
    }
  }
  return true;
};
export const arrayValueToString = (value: string | Array<string>) =>
  Array.isArray(value) ? value.join(';') : value;

export function providesList<R extends { [key: string]: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  alterId = 'id',
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ [alterId]: id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}
