import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { InputBox, Select, ISelectOption } from 'uikit';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useGetOptionsQuery } from 'services/gatewayApi/riskScoringOptionsApi';
import {
  useGetAllRiskCountriesQuery,
} from 'services/gatewayApi/countryRiskApi';

import {
  useAddRiskScoringAnswerMutation,
  useUpdateRiskScoringAnswerMutation,
  useDeleteRiskScoringAnswerMutation,
} from 'services/gatewayApi/riskScoringAnswersApi';
import { RiskStatusBox } from '../RiskStatusBox';

import './RiskScoringField.scss';

const FieldStack = ({ id, type, isSaving, isDisabled, children }) => (
  <Stack
    id={`RiskScoringField_${id}`}
    className={`
      RiskScoringFieldWrapper
      RiskScoring${type}Field
      ${isSaving ? 'RiskScoringField-Saving' : ''}
      ${isDisabled ? 'RiskScoringField-Disabled' : ''}
    `}
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={4}
  >
    {children}
  </Stack>
);

const countryRiskQuestions = [
  'Customer SoF Country', // sof-sow-country
  'UBO SoW Country', // sof-sow-country
  'Authorised Signatory (please select Country of the signatory with the highest rank)', // countries
  'Non-signatory Directors (please select Country of the director with the highest risk)', // countries
  'UBO Country (please select Country of the UBO with the highest risk)', // countries
  'Countries of Inbound Payments', // payments-county-type
  'Countries of Outbound Payments', // payments-county-type
];

const listToRiskMap = {
  'sof-sow-country': {
    HIGHRISKCOUNTRIES: 'HIGH_RISK',
    MEDIUMRISKCOUNTRIES: 'MEDIUM_RISK',
    LOWRISKCOUNTRIES: 'LOW_RISK',
    PROHIBITEDCOUNTRIES: 'PROHIBITED',
    RESTRICTEDCOUNTRIES: 'RESTRICTED',
    NONREPUTABLECOUNTRIES: 'NON_REPUTABLE',
  },
  'payments-county-type': {
    PROHIBITEDCOUNTRIES: 'PROHIBITED',
    RESTRICTEDCOUNTRIES: 'RESTRICTED',
  },
  'screening-results': {
    ADVERSEMEDIAIDENTIFIED: 'HIGH_RISK',
    RELEVANTLAWSUITIDENTIFIED: 'HIGH_RISK',
    ENFORCEMENTACTION: 'HIGH_RISK',
  },
};

interface RiskScoringFieldProps {
  id: string;
  applicationId: string;
  riskScoringVersion: number;
  title?: string;
  kind: string;
  option?: string;
  answers?: any;
  questions?: any;
  answer?: any;
  scores?: any;
  hideScore?: boolean;
  readonly?: boolean;
}

const renderRiskStatusBox = (kind, title, status, value) => {
  if (kind === 'RADIO' && title === 'PEP Identified' && value === 'YES') {
    return (
      <RiskStatusBox
        status="HIGH_RISK"
        tooltip="PEP identified, EDD must be applied, PEP specific EDD measures must be applied"
      />
    );
  }

  if (kind === 'RS_OPTION' && title === 'Screening Results') {
    return <RiskStatusBox status={status} tooltip="Discount/Escalate Adverse Information" />;
  }

  if ((kind === 'COUNTRY' || countryRiskQuestions.includes(title)) && status) {
    return <RiskStatusBox status={status} />;
  }

  return <RiskStatusBox />;
};

const RiskScoringField: React.FC<RiskScoringFieldProps> = ({
  id,
  applicationId,
  riskScoringVersion,
  title,
  kind,
  option,
  answers,
  questions,
  answer,
  scores,
  hideScore = false,
  readonly,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // #region Options data for Select fields
  const [selectOptions, setSelectOptions] = useState([]);

  if (kind === 'RS_OPTION') {
    const { data: options, isLoading: isOptionsLoading } = useGetOptionsQuery({
      name: option,
      version: riskScoringVersion,
    });

    useEffect(() => {
      if (!isOptionsLoading && options && options.length) {
        setSelectOptions(
          options.map((o) => ({
            value: o.key,
            label: o.value,
          })),
        );
      }
    }, [options, isOptionsLoading]);
  }

  if (kind === 'COUNTRY') {
    const { data: countries, isLoading: isCountriesLoading } = useGetAllRiskCountriesQuery(undefined);

    useEffect(() => {
      if (!isCountriesLoading && countries && countries.ids && countries.ids.length) {
        const list = countries.ids.map((id) => ({
          value: countries.entities[id].countryCode,
          label: countries.entities[id].name,
          riskStatus: countries.entities[id].riskStatus, // for risk label of the selected country
        }));

        setSelectOptions(list);
      }
    }, [countries, isCountriesLoading]);
  }
  // #endregion

  const qnaSort = (a, b) => (a.row * 1000 + a.col > b.row * 1000 + b.col ? 1 : -1);

  const getAnswerForQuestion = (questionId, index = 0) =>
    answers
      .slice()
      .sort(qnaSort)
      .filter((answer) => answer.questionId === questionId)[index];

  const getAnswerScoreIdForQuestion = (questionId, answer) =>
    scores.find(
      (score) =>
        score.questionId === questionId && (score.answer === answer || score.answer === undefined),
    )?.id;

  const getAnswerScoreByScoreId = (scoreId) => scores.find((score) => score.id === scoreId);

  // #region Add/Update answer
  const [addAnswer] = useAddRiskScoringAnswerMutation();
  const [updateAnswer] = useUpdateRiskScoringAnswerMutation();

  const onSubmitAnswer = (questionId, scoreId, value) => {
    setIsSaving(true);

    const currentAnswerId = answer?.id;

    const currentQuestion = questions.find((q) => q.id === questionId);

    //#region Custom updates for other questions

    //Set answer to "NOT_APPLICABLE" for quesion "Is Customer licensed?"
    if (currentQuestion.title === 'Does customer require a license to operate?' && value === 'NO') {
      const targetQuestion = questions.find((q) => q.title === 'Is Customer licensed?');

      if (targetQuestion) {
        const targetQuestionCurrentAnswer = getAnswerForQuestion(targetQuestion.id);
        const answerValue = 'NOT_APPLICABLE';
        const targetScoreId = getAnswerScoreIdForQuestion(targetQuestion.id, answerValue);

        const model = {
          scoreId: targetScoreId,
          row: targetQuestionCurrentAnswer?.row ?? 1,
          col: targetQuestionCurrentAnswer?.col ?? 1,
          caption: answerValue,
          applicationId,
        };

        if (targetQuestionCurrentAnswer) {
          // Skip update if answer/scoreId is same
          if (targetQuestionCurrentAnswer.scoreId !== targetScoreId) {
            updateAnswer({ ...model, id: targetQuestionCurrentAnswer?.id })
              .unwrap()
              .catch((rejected) => console.error(rejected));
          }
        } else {
          addAnswer({ ...model, questionId: targetQuestion.id })
            .unwrap()
            .catch((rejected) => console.error(rejected));
        }
      }
    }
    //#endregion

    const model = {
      scoreId,
      row: answer?.row ?? 1,
      col: answer?.col ?? 1,
      caption: value,
      applicationId,
    };

    if (currentAnswerId) {
      updateAnswer({ ...model, id: currentAnswerId })
        .unwrap()
        .catch((rejected) => console.error(rejected))
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      addAnswer({ ...model, questionId: id })
        .unwrap()
        .catch((rejected) => console.error(rejected))
        .finally(() => {
          setIsSaving(false);
        });
    }
  };
  // #endregion

  // #region Delete answer
  const [deleteAnswer] = useDeleteRiskScoringAnswerMutation();
  const onClearAnswer = () => {
    setIsSaving(true);
    const currentAnswerId = answer?.id;
    if (currentAnswerId) {
      deleteAnswer({ applicationId, id: currentAnswerId })
        .unwrap()
        .catch((rejected) => console.error(rejected))
        .finally(() => {
          setIsSaving(false);
        });
    }
  };
  // #endregion

  const defaultValue = answer?.caption;
  const getRiskStatus = () => {
    if (kind === 'RS_OPTION') {
      return listToRiskMap[option] ? listToRiskMap[option][defaultValue] : '';
    }

    if (kind === 'COUNTRY') {
      return selectOptions.find((c) => c.value === defaultValue)?.riskStatus;
    }
  };

  // #region Text field debounce update
  const [textValue, setTextValue] = useState(defaultValue);
  const [questionId, setQuestionId] = useState<string>();
  const [textScoreId, setTextScoreId] = useState();
  const [skipRequest, setSkipRequest] = useState<boolean>(false); // debounce when updating
  // Server update after a period of time
  const debounceUpdateRecord = useCallback(
    debounce(() => setSkipRequest(false), 500),
    [],
  );

  useEffect(() => {
    if (!skipRequest) {
      if (defaultValue != textValue) {
        onSubmitAnswer(questionId, textScoreId, textValue);
      }
    }
  }, [skipRequest, textValue]);
  // #endregion

  // #region Render helpers
  const renderFieldScore = () => {
    if (!hideScore) {
      const score = getAnswerScoreByScoreId(answer?.scoreId);
      const scoreValue = score?.score ?? 0;

      return (
        <Box className={`FieldScore ${scoreValue > 0 ? 'FieldScore-HasScore' : ''}`}>
          {scoreValue}
        </Box>
      );
    }

    return null;
  };
  // #endregion

  const isDisabled = isSaving || readonly;

  switch (kind.toUpperCase()) {
    case 'RADIO':
      return (
        <FieldStack id={id} type="Radio" isSaving={isSaving} isDisabled={isDisabled}>
          <Typography variant="body1" component="span" color="grey.600">
            {title}
          </Typography>
          <RadioGroup
            row
            name={`Question_${id}`}
            value={defaultValue}
            onChange={(_, value) => {
              const scoreId = getAnswerScoreIdForQuestion(id, value);
              onSubmitAnswer(id, scoreId, value);
            }}
          >
            {/* YES/NO options */}
            {(option === 'YES/NO' || option === 'YES/NO/NOT_APPLICABLE') && (
              <>
                <FormControlLabel
                  value="YES"
                  control={<Radio />}
                  label="Yes"
                  labelPlacement="end"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio />}
                  label="No"
                  labelPlacement="end"
                  disabled={isDisabled}
                />
              </>
            )}

            {/* Append NOT_APPLICABLE option */}
            {option === 'YES/NO/NOT_APPLICABLE' && (
              <FormControlLabel
                value="NOT_APPLICABLE"
                control={<Radio />}
                label="Not Applicable"
                labelPlacement="end"
                disabled={isDisabled}
              />
            )}

            {option === 'FACE_TO_FACE/NON_FACE_TO_FACE' && (
              <>
                <FormControlLabel
                  value="FACE_TO_FACE"
                  control={<Radio />}
                  label="Face to Face"
                  labelPlacement="end"
                  disabled={isDisabled}
                />
                <FormControlLabel
                  value="NON_FACE_TO_FACE"
                  control={<Radio />}
                  label="Non Face to Face"
                  labelPlacement="end"
                  disabled={isDisabled}
                />
              </>
            )}
          </RadioGroup>
          {renderRiskStatusBox(kind, title, '', defaultValue)}
          {renderFieldScore()}
        </FieldStack>
      );

    case 'RS_OPTION':
    case 'COUNTRY':
      return (
        <FieldStack
          id={`${kind}_${option}_${id}`}
          type="Select"
          isSaving={isSaving}
          isDisabled={isDisabled}
        >
          <Select
            name={`Question_${id}`}
            selectBox
            label={title}
            disabled={isDisabled}
            options={selectOptions}
            onChange={(
              option: ISelectOption /* , event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement> */,
            ) => {
              if (option === null) {
                return onClearAnswer();
              }

              const valueForScore = selectOptions.find((o) => o.value === option.value);
              const scoreId = getAnswerScoreIdForQuestion(
                id,
                valueForScore.riskStatus || option.value,
              );
              onSubmitAnswer(id, scoreId, option.value);
            }}
            value={selectOptions.find((c) => c.value === defaultValue)}
            hasClear
            borderless
          />
          {renderRiskStatusBox(kind, title, getRiskStatus())}
          {renderFieldScore()}
        </FieldStack>
      );

    case 'TEXT':
      return (
        <FieldStack id={id} type="Text" isSaving={isSaving} isDisabled={isDisabled}>
          <InputBox
            name={`Question_${id}`}
            label={title}
            type="text"
            onChange={(e) => {
              setSkipRequest(true);
              const scoreId = getAnswerScoreIdForQuestion(id, undefined);
              setQuestionId(id);
              setTextScoreId(scoreId);
              setTextValue(e.target.value);
              debounceUpdateRecord();
            }}
            value={textValue}
            disabled={readonly}
            borderless
          />
          {/* Field disabled only when readonly intentionally because it loses focus */}
        </FieldStack>
      );

    default:
      return null;
  }
};

export { RiskScoringField };
