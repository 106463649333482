import { Group } from './types';

export const UsersHeadData = [
  {
    property: 'name',
    title: 'User`s name',
    width: 4,
    value: 'name',
  },
  {
    property: '',
    title: 'Role',
    width: 6,
    value: 'groups',
  },
  {
    property: 'lastLoginDate',
    title: 'Last logged in',
    width: 2,
    value: 'lastLoginDate',
  },
  {
    property: 'createdDate',
    title: 'Date created',
    width: 2,
    value: 'createdDate',
  },
  {
    property: 'enabled',
    title: 'Status',
    width: 1,
    value: 'enabled',
  },
  {
    property: '',
    title: 'Actions',
    width: 1,
    value: '',
  },
];

export const RolesHeadData = [
  {
    property: 'name',
    title: 'Role`s name',
    width: 4,
    value: '',
  },
  {
    property: 'tenantId',
    title: 'Product',
    width: 5,
    value: '',
  },
  {
    property: 'modifiedDate',
    title: 'Last modified',
    width: 5,
    value: '',
  },
  {
    property: '',
    title: 'Actions',
    width: 2,
    value: '',
  },
];

export const initialUser = {
  departments: [],
  email: '',
  emailVerified: false,
  enabled: true,
  groups: [],
  id: 'new',
  keycloakId: '',
  name: '',
  firstName: '',
  lastName: '',
  permissions: [],
  tenantId: '',
  tenants: [],
  createdBy: '',
  createdByName: '',
  createdDate: '',
  modifiedBy: '',
  modifiedByName: '',
  modifiedDate: '',
  tenantDepartments: [],
  tenantGroups: [],
  tenantPermissions: [],
};

interface EditGroup {
  role: Group;
  open: boolean;
}

export const initialEditRole: EditGroup = {
  role: {
    id: 'new',
    name: '',
    permissions: [],
    tenantId: null,
    description: '',
  },
  open: false,
};
