import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import SweetAlert from 'sweetalert-react';
import { createStructuredSelector } from 'reselect';
import { Button, DataTable, DataTableRow, PermissionAware } from '../../components';
import { makeSelectedFunctionData } from '../../selectors/functions';
import {
  selectFunction,
  newFunctionForm,
  deleteFunction,
  unselectFunction,
} from '../../actions/functions';
import FunctionForm from './functionForm';
import getLocalDateTime from '../../utils/formatter';
import { DATETIME_FORMAT_MOMENT } from '../../constants/constants';

const newFunction = {
  name: 'Name of Function',
  script: '1==1',
  description: 'This is description',
  category: 1,
  status: 'TEST',
};

const FunctionList = (props) => {
  Modal.setAppElement('#app');

  const { selectedFunction } = useSelector(
    createStructuredSelector({
      selectedFunction: makeSelectedFunctionData(),
    }),
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setShowDeleteDialog(true);
    setItemIdToDelete(id);
  };

  const confirmDelete = () => {
    dispatch(deleteFunction(itemIdToDelete));
    setShowDeleteDialog(false);
  };

  const handleEdit = (id) => {
    dispatch(selectFunction(id));
    setIsOpen(true);
  };

  const handleNewFunction = () => {
    dispatch(newFunctionForm());
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(unselectFunction());
    setIsOpen(false);
  };

  const renderModal = () => (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Rule Definition"
      portalClassName="ruleBuilder"
    >
      <FunctionForm
        ruleFunction={selectedFunction || newFunction}
        handleCloseModal={handleCloseModal}
        urlId="1"
      />
    </Modal>
  );
  const renderList = () => {
    const { loading, functions } = props;

    if (loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="10">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }
    if (!functions || functions.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="10">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }

    return functions.map((item, i) => (
      <tbody key={i}>
        <DataTableRow>
          <td className="text-nowrap">
            {item.modifiedDate
              ? getLocalDateTime(item.modifiedDate).format(DATETIME_FORMAT_MOMENT)
              : getLocalDateTime(item.createdDate).format(DATETIME_FORMAT_MOMENT)}
          </td>
          <td>{item.modifiedByName || item.createdByname || ''}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>{item.status}</td>
          <td>
            <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
              <a
                className="text-success"
                onClick={() => {
                  handleEdit(item.id);
                }}
                title="Edit"
              >
                <i className="fa fa-edit" />
              </a>
            </PermissionAware>
          </td>
          <td>
            <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
              <a
                className="text-danger"
                onClick={(e) => {
                  handleDelete(e, item.id);
                }}
                title="Delete"
              >
                <i className="fa fa-trash-alt" />
              </a>
            </PermissionAware>
          </td>
        </DataTableRow>
      </tbody>
    ));
  };

  return (
    <div className="grid simple">
      <div className="grid-title">
        <h4>
          <span>Rule Functions</span>
        </h4>
        <div className="new-button-container">
          <PermissionAware requiredPers={['TENANT_ADMIN', 'RULES_EDIT']}>
            <Button
              buttonText="New Function"
              buttonClass="btn btn-primary btn-small"
              iconClass="fa fa-plus"
              onClick={() => {
                handleNewFunction();
              }}
            />
          </PermissionAware>
        </div>
      </div>
      <div className="grid-body p-0">
        <DataTable>
          <thead>
            <tr>
              <th>Modified On</th>
              <th>Modified By</th>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th />
              <th />
            </tr>
          </thead>
          {renderList()}
        </DataTable>
        {renderModal()}
        <SweetAlert
          show={showDeleteDialog}
          title="Deleting Rule Function"
          text="Are you sure you want to permanently delete the selected Rule Function?"
          type="warning"
          showCancelButton
          confirmButtonColor="#DD6B55"
          confirmButtonText="Yes"
          cancelButtonText="No"
          onConfirm={() => confirmDelete()}
          onCancel={() => setShowDeleteDialog(false)}
        />
      </div>
    </div>
  );
};

FunctionList.propTypes = {
  functions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
};

export default FunctionList;
