import React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { ScrollContainer, ArrowBack } from 'uikit';
import { sanctionRecordFields } from '../constants';
import { SanctionsRecord } from '../types';

interface SanctionsRecordDetailsProps {
  sanctionsRecordDetails: SanctionsRecord;
  onClose: () => void;
}

export const SanctionsRecordDetails: React.FC<SanctionsRecordDetailsProps> = ({
  sanctionsRecordDetails,
  onClose,
}) => {
  const getLabel = (item) => {
    if (item === 'ei') {
      return sanctionsRecordDetails[item] === 'F' || sanctionsRecordDetails[item] === 'M'
        ? 'Gender'
        : 'Corporate sign';
    }
    return sanctionRecordFields.find((el) => el.value === item)?.label || '';
  };

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {sanctionsRecordDetails && (
        <>
          <Stack pt={5} direction="row" justifyContent="space-between">
            <Typography variant="bodyPlusBold" className="detailsTille">
              {sanctionsRecordDetails?.lastName}
            </Typography>
            <Button variant="contained" color="base" disabled>
              Export to PDF
            </Button>
          </Stack>
          <ScrollContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {Object.keys(sanctionsRecordDetails).map((item) => (
                <TextField
                  key={item}
                  label={getLabel(item)}
                  value={sanctionsRecordDetails[item].toString()}
                  variant="standard"
                  multiline
                  sx={{ my: 2, pointerEvents: 'none' }}
                />
              ))}
            </div>
          </ScrollContainer>
        </>
      )}
    </Box>
  );
};
