import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { ModalDialog } from '../../../uikit/ModalDialog';

interface ConfirmDialogProps {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: any;
  title: string;
  actionTitle: string;
  showCanTransact?: boolean;
}

export const ConfirmDialogAppDetails: React.FC<ConfirmDialogProps> = ({
  showDialog,
  setShowDialog,
  onConfirm,
  title,
  actionTitle,
  showCanTransact = false,
}) => {
  const initialValues = {
    reason: '',
    clientCanTransact: true,
  };
  const validationSchema = yup.object().shape({
    reason: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        formik.resetForm();
        setShowDialog(false);

        if (onConfirm) {
          onConfirm(values);
        }
      }, 500);
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setShowDialog(false);
  };

  return (
    <ModalDialog
      open={showDialog}
      onClose={handleCancel}
      title={title}
      actionTitle={actionTitle}
      handleAction={formik.handleSubmit}
      variant={actionTitle === 'Reject' ? 'error' : undefined}
      isLoading={formik.isSubmitting}
      disableAction={formik.values.reason === ''}
    >
      <TextField
        autoFocus
        sx={{ mt: 2 }}
        name="reason"
        label="Reason"
        fullWidth
        multiline
        rows={2}
        onChange={formik.handleChange}
        value={formik.values.reason}
        error={!!(formik.errors.reason && formik.touched.reason)}
        disabled={formik.isSubmitting}
      />
      {showCanTransact && (
        <FormControlLabel
          label="Can transact"
          control={
            <Checkbox
              checked={formik.values.clientCanTransact}
              onChange={(e, checked: boolean) => formik.setFieldValue('clientCanTransact', checked)}
              disabled={formik.isSubmitting}
            />
          }
          sx={{ mt: 4 }}
        />
      )}
    </ModalDialog>
  );
};
