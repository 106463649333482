import { success, successLight } from '../../palette-blocks';

export const SuccessChip = {
  props: {
    color: 'success',
  },
  style: {
    color: successLight.contrastText,
    background: successLight.main,
    '.MuiChip-deleteIcon': {
      color: successLight.contrastText,
      '&:hover, &:active': {
        color: success.dark,
      },
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        background: successLight.hover,
      },
      '&:active': {
        background: successLight.active,
      },
    },
    '.MuiChip-avatar': {
      background: successLight.dark,
      color: successLight.contrastText,
    },
  },
};

export const SuccessChipOutlined = {
  props: {
    color: 'success',
    variant: 'outlined',
  },
  style: {
    borderColor: success.main,
    background: 'white',
    '&.MuiChip-clickable': {
      '&:hover': {
        background: successLight.hover,
        // borderColor: successLight.main,
      },
      '&:active': {
        background: successLight.active,
        // borderColor: successLight.main,
      },
    },
  },
};
