/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';

import React from 'react';
import { DataTable, DataTableRow } from 'components';
import { ValidatorTransaction } from './validatorTransaction';
import { CardDataList } from '../CardData/cardDataList';

export class ValidatorTransactionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedIndex: -1,
    };
  }

  handleOpenDetails(index, id) {
    if (this.state.openedIndex === index) {
      this.setState({
        openedIndex: -1,
      });
    } else {
      this.props.fetchValidatorTransactionsCardData(id, index);
      this.setState({
        openedIndex: index,
      });
    }
  }

  renderCardData() {
    const { selectedCardData, selectedCardDataLoading } = this.props;

    return (
      <DataTableRow>
        <td colSpan="6">
          <CardDataList cardData={[selectedCardData]} loading={selectedCardDataLoading} />
        </td>
      </DataTableRow>
    );
  }

  renderChildren() {
    const { openedIndex } = this.state;

    if (this.props.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan="14">
              <span className="listNotFound">
                <i className="fa fa-spinner fa-spin" />
              </span>
            </td>
          </tr>
        </tbody>
      );
    }
    if (!this.props.validatorTransactions || this.props.validatorTransactions.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan="13">
              <span className="listNotFound">Not Found</span>
            </td>
          </tr>
        </tbody>
      );
    }
    return this.props.validatorTransactions.map((item, index) => (
      <tbody key={index}>
        <ValidatorTransaction
          key={index}
          index={index}
          item={item}
          handleOpenDetails={this.handleOpenDetails.bind(this)}
        />
        {openedIndex === index && this.renderCardData(item.validatorTransactionId)}
      </tbody>
    ));
  }

  render() {
    return (
      <div>
        <DataTable>
          <thead>
            <tr>
              <th>Action Time</th>
              <th>External Id</th>
              <th>Reference Id</th>
              <th>Result Action</th>
              <th>Transaction Id</th>
              <th className="td-small" />
            </tr>
          </thead>
          {this.renderChildren()}
        </DataTable>
      </div>
    );
  }
}

ValidatorTransactionsList.propTypes = {
  validatorTransactions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  selectedCardDataLoading: PropTypes.bool,
  selectedCardData: PropTypes.object,
  fetchValidatorTransactionsCardData: PropTypes.func,
};
