import React, { ReactChild, ReactChildren } from 'react';
import { NavLink } from 'react-router-dom';
import './TabLink.scss';

interface ITabLinkProps {
  link: string;
  children: ReactChild | ReactChild[] | ReactChildren;
}

export const TabLink: React.FC<ITabLinkProps> = ({ link, children }) => (
  <div className="TabLink">
    <NavLink to={link}>{children}</NavLink>
  </div>
);
