import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import {
  Edit as EditIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Public as PublicIcon,
} from '@mui/icons-material';
import MUIDataTable from '../../components/MUIDataTable';
import { initialFormData } from './constants';
import {
  useGetKycProvidersQuery,
  useEnableKycProviderMutation,
  useDisableKycProviderMutation,
  useShiftKycProviderMutation,
} from '../../services/gatewayApi/kycProvidersApi';
import KycAllowedCountriesFormDialog from './KycAllowedCountriesFormDialog';
import KycProviderFormDialog from './KycProviderFormDialog';

const KycProvidersList = () => {
  // #region Load base data
  const [kycProviders, setKycProviders] = useState([]);
  const { data, isLoading, isFetching } = useGetKycProvidersQuery();

  useEffect(() => {
    if (data) {
      setKycProviders(data);
    }
  }, [data]);

  const getFirstProvider = () => kycProviders[0];
  const getLastProvider = () => kycProviders[kycProviders.length - 1];
  // #endregion

  // #region Shift Up/Down functionality
  const [shiftKycProvider, { isLoading: isLoadingShiftKyc }] = useShiftKycProviderMutation();

  const shiftProvider = (id, direction) => {
    shiftKycProvider({ id, direction, levels: 1 });
  };
  // #endregion

  // #region Enable/Disable functionality
  const [enableKycProvider, { isLoading: isLoadingEnableKyc }] = useEnableKycProviderMutation();
  const [disableKycProvider, { isLoading: isLoadingDisableKyc }] = useDisableKycProviderMutation();
  // #endregion

  // #region Edit functionality
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleEdit = (id) => {
    setFormData(kycProviders.find((item) => item.id === id));
    setFormOpen(true);
  };
  // #endregion

  // #region Edit Allowed Countries functionality
  const [countriesFormOpen, setCountriesFormOpen] = useState(false);
  const [kycProviderForAllowedCountries, setKycProviderForAllowedCountries] = useState();

  const handleEditKycAllowedCountries = (id) => {
    setKycProviderForAllowedCountries(kycProviders.find((item) => item.id === id));
    setCountriesFormOpen(true);
  };
  // #endregion

  const options = {
    elevation: 0,
    draggable: false,
    responsive: 'standard',
    filter: false,
    sort: false,
    search: false,
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRows: 'none',
    sortOrder: {
      name: 'globalOrder',
      direction: 'asc',
    },
    textLabels: {
      body: {
        noMatch: isLoading || isFetching ? <LinearProgress /> : 'No matching records found...',
      },
    },
  };

  const columns = [
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          className: 'td-small',
        }),
        setCellProps: () => ({
          className: 'td-small',
        }),
        customBodyRender: (value) => (
          <>
            <IconButton
              size="small"
              onClick={() => shiftProvider(value, 'UP')}
              disabled={!getFirstProvider() || value === getFirstProvider().id}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => shiftProvider(value, 'DOWN')}
              disabled={!getLastProvider() || value === getLastProvider().id}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </>
        ),
      },
    },
    {
      name: 'providerCode',
      label: 'Code',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'isEnabled',
      label: 'Status',
      options: {
        setCellProps: () => ({
          className: 'td-small',
        }),
        customBodyRender: (value, row) => {
          const kycProvider = kycProviders[row.rowIndex];

          return (
            <Button
              variant="contained"
              size="small"
              color={value ? 'error' : 'success'}
              disableElevation
              onClick={() => {
                if (value) {
                  disableKycProvider(kycProvider);
                } else {
                  enableKycProvider(kycProvider);
                }
              }}
            >
              {value ? 'Disable' : 'Enable'}
            </Button>
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        empty: true,
        setCellProps: () => ({
          align: 'right',
        }),
        customBodyRender: (value) => (
          <>
            <Tooltip title="Edit Allowed Countries" disableFocusListener>
              <IconButton onClick={() => handleEditKycAllowedCountries(value)} size="large">
                <PublicIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" disableFocusListener>
              <IconButton onClick={() => handleEdit(value)} size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  return (
    <div className="grid simple">
      <div className="grid-body p-0">
        <div style={{ position: 'relative' }}>
          <Backdrop
            open={
              isLoading ||
              isFetching ||
              isLoadingEnableKyc ||
              isLoadingDisableKyc ||
              isLoadingShiftKyc
            }
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <MUIDataTable data={kycProviders} columns={columns} options={options} />
        </div>

        <KycAllowedCountriesFormDialog
          open={countriesFormOpen}
          onClose={() => setCountriesFormOpen(false)}
          kycProvider={kycProviderForAllowedCountries}
        />

        <KycProviderFormDialog open={formOpen} onClose={() => setFormOpen(false)} data={formData} />
      </div>
    </div>
  );
};

export default KycProvidersList;
