export const kycResults = {
  byId: {
    UNIDENTIFIED: {
      id: 'UNIDENTIFIED',
      label: 'Unidentified',
      color: 'primary',
    },
    CONSIDER: {
      id: 'CONSIDER',
      label: 'Consider',
      color: 'warning',
    },
    CLEAR: {
      id: 'CLEAR',
      label: 'Clear',
      color: 'success',
    },
  },
  allIds: ['UNIDENTIFIED', 'CONSIDER', 'CLEAR'],
};
