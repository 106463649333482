import { error, success, warning } from 'theme/palette-blocks';

export const riskMapForOpenPayd = [
  {
    riskRating: 'N/A',
    labelColor: 'inherit',
  },
  {
    riskRating: 'Low Risk',
    riskStatus: 'LOW',
    labelColor: success.main,
  },
  {
    riskRating: 'Medium Risk',
    riskStatus: 'MEDIUM',
    labelColor: warning.main,
  },
  {
    riskRating: 'High Risk',
    riskStatus: 'HIGH',
    labelColor: error.main,
  },
];
