import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { HeadLink } from 'muikit';
import { ConfirmDialog, Spinner, Tab, TabContainer, TextArea } from 'uikit';
import {
  useGetWebsiteChecklistSectionsQuery,
  useGetWebsiteChecklistsForApplicationQuery,
  useUpdateWebsiteChecklistsForApplicationMutation,
} from 'services/gatewayApi/websiteChecklistsApi';

import { useFormik } from 'formik';
import { toastUpdateSuccess } from 'utils/toast';

import './WebsiteChecklistTab.scss';

const WebsiteChecklistTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { clientId, applicationId } = useParams<{
    applicationId: string;
    clientId: string;
  }>();

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: websiteChecklistSections = [], isLoading: isLoadingSections } =
    useGetWebsiteChecklistSectionsQuery(undefined);

  const { data: websiteChecklist, isLoading: isLoadingAnswers } =
    useGetWebsiteChecklistsForApplicationQuery(applicationId);

  const [updateWebsiteChecklists] = useUpdateWebsiteChecklistsForApplicationMutation();

  const initialValues = websiteChecklist
    ? Object.assign(
      { comment: websiteChecklist?.comment },
      ...websiteChecklist?.answers.map((a) => ({ [a.questionId]: a.answer })),
    )
    : {};

  const formikWebsiteChecklist = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      /* Form submit handled by TabContainer onSubmit  */
    },
  });

  const handleConfirmSubmit = () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const data = _.cloneDeep(websiteChecklist);

    data.applicationId = applicationId;
    data.status = 'PERFORMED';
    data.comment = formikWebsiteChecklist.values.comment;

    data.answers.forEach((answer) => {
      answer.answer = formikWebsiteChecklist.values[answer.questionId];
    });

    updateWebsiteChecklists(data)
      .unwrap()
      .then(() => {
        toastUpdateSuccess('Website Checklist');
      })
      .catch((rejected) => {
        console.error(rejected);
      })
      .finally(() => {
        setIsSaving(false);
      });

    const path = location.pathname.split('/');
    const prevPath = path.slice(0, -1).join('/');
    navigate(prevPath);
  };

  const isLoading = isLoadingSections || isLoadingAnswers;
  const hasSections = websiteChecklistSections.length > 0;
  const isEditDisabled = websiteChecklist?.status === 'PERFORMED';

  if (isLoading) {
    return (
      <div className="app-details-preloader app-details-preloader--small">
        <Spinner />
      </div>
    );
  }

  if (!hasSections && !isLoading) {
    return <div>Website Compliance Checklist needs to be prepared...</div>;
  }

  return (
    <div className="WebsiteChecklistTab">
      <HeadLink title="Website Checklist" />

      <div style={{ marginTop: '1em' }}>
        <TabContainer
          title="Website Compliance Checklist"
          hasPrevNext={websiteChecklist?.answers.length !== 0}
          direction="vertical"
          hasSubmit={websiteChecklist?.status !== 'PERFORMED'}
          isSubmitting={isSaving}
          onSubmit={() => setIsConfirmOpen(true)}
          additionalInfo={
            <div className="statusDisplay">
              <div>
                <h4>Status</h4>
                <span className={websiteChecklist?.status === 'PERFORMED' ? 'performed' : ''}>
                  {websiteChecklist?.status === 'PERFORMED' ? 'PERFORMED' : 'NOT PERFORMED'}
                </span>
              </div>
            </div>
          }
        >
          {hasSections &&
            websiteChecklistSections.map((section, i) => (
              <Tab key={`WebsiteChecklist_Tab_${section.id}`} title={section.title}>
                <form onSubmit={formikWebsiteChecklist.handleSubmit} autoComplete="off" noValidate>
                  {section.questions.map((question) => {
                    if (!question.isDeleted) {
                      return (
                        <div key={question.id} className="TabRadioField">
                          <div>
                            <h5>{question.question}</h5>
                          </div>
                          <RadioGroup
                            row
                            name={`RadioGroup_${question.id}`}
                            defaultValue={formikWebsiteChecklist.values[question.id]}
                            onChange={(_, value) =>
                              formikWebsiteChecklist.setFieldValue(question.id, value)
                            }
                          >
                            <FormControlLabel
                              value="YES"
                              control={<Radio />}
                              label="Yes"
                              labelPlacement="start"
                              disabled={isEditDisabled}
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="No"
                              labelPlacement="start"
                              disabled={isEditDisabled}
                            />
                            {!question.isSatisfactoryQuestion && (
                              <FormControlLabel
                                value="NOT_APPLICABLE"
                                control={<Radio />}
                                label="Not Applicable"
                                labelPlacement="start"
                                disabled={isEditDisabled}
                              />
                            )}
                          </RadioGroup>
                        </div>
                      );
                    }

                    return '';
                  })}

                  {i === websiteChecklistSections.length - 1 && (
                    <div className="TabTextAreaField">
                      <TextArea
                        label="Underwriting Officer Notes"
                        name="comment"
                        defaultValue={formikWebsiteChecklist.values.comment}
                        onChange={(e) =>
                          formikWebsiteChecklist.setFieldValue('comment', e.target.value)
                        }
                        disabled={isEditDisabled}
                      />
                    </div>
                  )}
                </form>
              </Tab>
            ))}
        </TabContainer>
      </div>

      <ConfirmDialog
        showDialog={isConfirmOpen}
        setShowDialog={setIsConfirmOpen}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={handleConfirmSubmit}
        title="Submit Website Checklist"
        text="Are you sure to mark website checklist as performed? Further edits will not be possible."
        confirmButtonText="Ok"
      />
    </div>
  );
};

export { WebsiteChecklistTab };
