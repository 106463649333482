/* utils for operate rules tree */
import { nanoid } from '@reduxjs/toolkit';
import { RuleBackendFormat, RulesAnswer, RuleSortable, Threshold } from './types';
import { initialRule, isNewregex, ruleAnswer } from './constatns';

// rename BE name => title and childRules => children for sortable-tree standard
export const renameForTreeRules = (ruleSet: RuleBackendFormat[]): RuleSortable[] =>
  ruleSet?.map((item) => {
    const { name, childRules = [], id, ...rest } = item;
    const renamedChildren = renameForTreeRules(childRules);
    return {
      title: name,
      children: renamedChildren,
      id: id.toString(),
      isActive: true, // TODO temp while no on BE
      ...rest,
    };
  });

// rename sortable-tree title => name and children => childRules for BE model
export const renameForBackend = (ruleSet: RuleSortable[]): RuleBackendFormat[] => {
  const removeMarkedForDelete = ruleSet.filter((rule) => !rule.isForDelete);
  return removeMarkedForDelete.map((item, idx) => {
    const {
      id,
      title,
      children = [],
      isEdited,
      isForDelete,
      isParentForDelete,
      isActive, // temporally remove from BE model, while BE not ready
      isMoved,
      expanded,
      ruleOrder,
      ...rest
    } = item;
    const isNew = isNewregex.test(id);
    const renamedChildren = renameForBackend(children);
    return {
      id: isNew ? null : id,
      name: title,
      childRules: renamedChildren,
      ruleOrder: idx,
      ...rest,
    };
  });
};

// remove rule by ID from ruleset
export const removeById = (ruleSet: RuleSortable[], id: string): RuleSortable[] => {
  const result = [...ruleSet].forEach((rule, index) => {
    if (rule.id === id) {
      ruleSet.splice(index, 1); // remove the object with the matching ID
    } else if (rule.children) {
      removeById(rule.children, id); // recursively call this function on children array
    }
  });
  return result as unknown as RuleSortable[];
};

// copy rule with children
export const copyRuleById = (ruleSet: RuleSortable[], id: string): RuleSortable[] => {
  const updateNewChildren = (children: RuleSortable[]): RuleSortable[] =>
    children.map((obj) => ({
      ...obj,
      id: `new-${obj.id}-${nanoid()}`,
      title: `${obj.title} (copy)`,
      isEdited: false,
      isForDelete: false,
      isActive: false,
      children: obj.children.length > 0 ? updateNewChildren(obj.children) : [],
    }));

  const result = [...ruleSet].forEach((rule, index) => {
    if (rule.id === id) {
      ruleSet.splice(index + 1, 0, {
        ...rule,
        id: `new-${rule.id}-${nanoid()}`,
        title: `${rule.title} (copy)`,
        isEdited: false,
        isForDelete: false,
        isActive: false,
        children: updateNewChildren(rule.children), // same change id, title for children
      }); // copy the object with the matching ID
    } else if (rule.children) {
      copyRuleById(rule.children, id); // recursively call this function on children array
    }
  });
  return result as unknown as RuleSortable[];
};

// add new rule after rule ID
export const addRuleAfter = (ruleSet: RuleSortable[], ruleId: string): RuleSortable[] => {
  const newRule = {
    ...initialRule,
    id: `new-${nanoid()}`,
  };
  const result = [...ruleSet].forEach((rule, index) => {
    if (rule.id === ruleId) {
      if (rule.children?.length > 0) {
        const updatedRule = { ...rule, expanded: true }; // expand group
        updatedRule.children.unshift(newRule); // add newRule as first child in group
        ruleSet.splice(index, 1, updatedRule); // replace the original rule with the updated rule in the ruleSet array
      } else ruleSet.splice(index + 1, 0, newRule); // add the newRule next from current
    } else if (rule.children) {
      addRuleAfter(rule.children, ruleId); // recursively call this function on children array
    }
  });
  return result as unknown as RuleSortable[];
};

export const setFieldsById = (
  ruleSet: RuleSortable[],
  { ruleId, setFields }: { ruleId: string; setFields: any[] },
): RuleSortable[] =>
  ruleSet.map((rule) => {
    if (rule.id === ruleId) {
      const fieldsNames = Object.keys(setFields);
      fieldsNames.forEach((el) => (rule[el] = setFields[el]));
    }
    return {
      ...rule,
      children: rule.children ? setFieldsById(rule.children, { ruleId, setFields }) : rule.children,
    };
  });

const setDeep = (arr: RuleSortable[], isParentForDelete: boolean): RuleSortable[] =>
  arr.map((rule) => ({
    ...rule,
    isForDelete: isParentForDelete,
    isParentForDelete,
    children: setDeep(rule.children, isParentForDelete),
  }));

export const setMarkForDeleteUtil = (
  ruleSet: RuleSortable[],
  { ruleId, isForDelete }: { ruleId: string; isForDelete: boolean },
): RuleSortable[] => {
  const result = [...ruleSet].forEach((rule, index) => {
    if (rule.id === ruleId) {
      rule.isForDelete = isForDelete;
      rule.children = setDeep(rule.children, isForDelete);
    }
    setMarkForDeleteUtil(rule.children, { ruleId, isForDelete });
  });
  return result as unknown as RuleSortable[];
};

export const setActiveDeep = (arr: RuleSortable[], isActive: boolean): RuleSortable[] =>
  arr.map((rule) => ({
    ...rule,
    isActive,
    isMoved: true, // for make like edited
    children: setActiveDeep(rule.children, isActive),
  }));

export const setIsActiveDeepUtil = (
  ruleSet: RuleSortable[],
  { ruleId, isActive }: { ruleId: string; isActive: boolean },
): RuleSortable[] => {
  const result = [...ruleSet].forEach((rule) => {
    if (rule.id === ruleId) {
      rule.isActive = isActive;
      rule.isMoved = true; // for make like edited
      rule.children = setActiveDeep(rule.children, isActive);
    }
    setIsActiveDeepUtil(rule.children, { ruleId, isActive });
  });
  return result as unknown as RuleSortable[];
};

export const findDeepById = (arr?: RuleSortable[], id?: string): RuleSortable | undefined => {
  if (!arr || !id) {
    return undefined;
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id.toString() === id.toString()) {
      return arr[i];
    }
    const found = findDeepById(arr[i].children, id);
    if (found) {
      return found;
    }
  }
  return undefined;
};

export const restoreRuleUtil = (ruleSet: RuleSortable[], restoreRule: RuleSortable) => {
  ruleSet.map((rule) => {
    if (rule.id === restoreRule.id) {
      const fieldsNames = Object.keys(restoreRule);
      fieldsNames.forEach((el) => el !== 'children' && (rule[el] = restoreRule[el]));
      rule.isEdited = false;
    }
    return {
      ...rule,
      children: rule.children ? restoreRuleUtil(rule.children, restoreRule) : rule.children,
    };
  });
};

export const updateRuleUtil = (ruleSet: RuleSortable[], updateRule: RuleSortable) => {
  ruleSet.map((rule) => {
    if (rule.id === updateRule.id) {
      const fieldsNames = Object.keys(updateRule);
      fieldsNames.forEach((el) => (rule[el] = updateRule[el]));
      rule.isEdited = true;
    }
    return {
      ...rule,
      children: rule.children ? updateRuleUtil(rule.children, updateRule) : rule.children,
    };
  });
};

export const updateExpandUtil = (ruleSet: RuleSortable[], searchString: string[]) =>
  ruleSet.map((rule) => ({
    ...rule,
    expanded: searchString.includes(rule.id),
    children: updateExpandUtil(rule.children, searchString),
  }));

export const findMinValue = (thresholds: Threshold) => {
  if (Number(Object.values(thresholds)[0]) === 0) return 0;
  return Object.values(thresholds)[0] ? Number(Object.values(thresholds)[0]) - 1 : 100;
};

export const thresholdModel = ruleAnswer.filter(
  (el) => el.value !== 'ERROR' && el.value !== 'PASS' && el.value !== 'MODERATION',
);

export const getPrevNext = (name: RulesAnswer, thresholds: Threshold): number[] => {
  const listOfFields = thresholdModel.map((el) => el.value);
  const index = listOfFields.indexOf(name);
  if (index === -1) {
    return null;
  }
  let prev = 0;
  let next = 100;
  for (let i = index - 1; i >= 0; i--) {
    if (thresholds.hasOwnProperty(listOfFields[i])) {
      prev = thresholds[listOfFields[i]];
      break;
    }
  }
  for (let i = index + 1; i < listOfFields.length; i++) {
    if (thresholds.hasOwnProperty(listOfFields[i])) {
      next = thresholds[listOfFields[i]];
      break;
    }
  }
  return [prev, next];
};

export const makeFlatIds = (arr?: RuleSortable[]): string[] => {
  let result = [];
  arr?.forEach((el) => {
    result.push(el.id);
    if (el.children?.length > 0) {
      result = result.concat(makeFlatIds(el.children));
    }
  });
  return result;
};

export const getExpandedLength = (arr?: RuleSortable[]): number => {
  let count = 0;
  if (arr) {
    count += arr.length;
    arr.forEach((item) => {
      if (item.expanded) {
        if (item.children) {
          count += getExpandedLength(item.children);
        }
      }
    });
  }
  return count;
};
