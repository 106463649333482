import React from 'react';
import { useDropdownContext } from '../EmbedDropdown';
import { DropdownContext } from '../EmbedDropdownTypes';
import { EmbedDropdownNodeProps } from './EmbedDropdownNodeTypes';
import './EmbedDropdownNode.scss';

const EmbedDropdownNode: React.FC<EmbedDropdownNodeProps> = ({ children, active, ...rest }) => {
  const context: DropdownContext = useDropdownContext();
  const { hide } = context;

  return (
    <div
      {...rest}
      className={`embedDropdownNode ${active ? 'embedDropdownNode--active' : ''}`}
      onClick={() => hide && hide()}
    >
      {children}
    </div>
  );
};

export default EmbedDropdownNode;
