import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as _ from 'lodash';
import { Typography } from '@mui/material';
import { makeSelectedProductIdData } from '../../selectors/user';
import { fetchFunctions } from '../../actions/ruleBuilder';
import { fetchRuleFunctions } from '../../actions/functions';
import { makeFunctionsData, makeSelectLoadingData } from '../../selectors/functions';
import FunctionList from './FunctionList';
import { Button, Input, PermissionAware } from '../../components';

export class Functions extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      filter: this.getFilterInitialState(),
      apiFilter: this.getFilterInitialState(),
    };
  }

  componentWillMount() {
    this.props.fetchRuleFunctions();
    this.props.fetchFunctions();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedProductId &&
      this.props.selectedProductId !== nextProps.selectedProductId
    ) {
      this.props.fetchFunctions();
    }
  }

  getFilterInitialState() {
    return {
      name: '',
    };
  }

  getFilterValue(key) {
    return this.state.filter[key];
  }

  handleInputChanged(e, key) {
    const { filter } = this.state;
    filter[key] = e.target.value;
    this.setState({ filter });
  }

  handleFormClear() {
    const initialFilterState = this.getFilterInitialState();
    this.setState({
      filter: initialFilterState,
      apiFilter: initialFilterState,
    });
  }

  searchForm(e) {
    e.preventDefault();
    const { filter } = this.state;
    const apiFilter = _.clone(filter);
    this.setState({ apiFilter });
    this.props.fetchRuleFunctions(apiFilter);
    this.props.fetchFunctions(apiFilter);
  }

  renderSearch() {
    return (
      <div className="grid simple horizontal yellow">
        <div className="grid-title">
          <h4>
            <span>Filter Rule Functions</span>
          </h4>
        </div>
        <div className="grid-body">
          <div className="searchFilter">
            <form onSubmit={(e) => this.searchForm(e)}>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Input
                      type="text"
                      name="name"
                      value={this.getFilterValue('name')}
                      handleInputChanged={(e) => this.handleInputChanged(e, 'name')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div style={{ marginTop: '1.5rem' }}>
                    <Button type="submit" buttonText="Search" />
                    <Button
                      buttonText="Clear"
                      onClick={() => this.handleFormClear()}
                      buttonClass="btn btn-default btn-cons"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { functions, loading } = this.props;

    return (
      <div className="page-content rules-page functions">
        <Helmet title="Rule Functions" />
        <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Typography variant="h1" py={4}>
                    Rule Functions
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">{this.renderSearch()}</div>
              <div className="col-md-12">
                <FunctionList functions={functions} loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Functions.propTypes = {
  functions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  fetchFunctions: PropTypes.func,
  selectedProductId: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  fetchRuleFunctions: (filter) => dispatch(fetchRuleFunctions(filter)),
  fetchFunctions: () => dispatch(fetchFunctions()),
});

const mapStateToProps = createStructuredSelector({
  functions: makeFunctionsData(),
  loading: makeSelectLoadingData(),
  selectedProductId: makeSelectedProductIdData(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Functions);
