import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PageContainer, PageControl, StickyHeader, IFilterValue, Badge } from 'uikit';
import {
  getApplicationStatusOptions,
  businessTypeOptions,
  countryOptions,
} from 'features/ApplicationManagement/constants';
import { Box, Chip, Stack } from '@mui/material';
import { filterScheme } from './constants/filterScheme';
import * as Sections from './Sections';
import { ShowcaseProps } from '.';
import './Showcase.scss';

const Showcase: React.FC<ShowcaseProps> = () => {
  const applicationStatusOptions = getApplicationStatusOptions();
  const [inputBoxVal, setInputBoxVal] = useState<string>('');

  const [filterQuery, setFilterQuery] = useState<IFilterValue>({
    applicationStatus: '',
    jurisdictionCountry: '',
    description: '',
    isTrue: false,
  });

  const [searchValue, setSearchValue] = useState<string>();

  return (
    <PageContainer>
      <StickyHeader>
        <PageControl
          title="Showcase"
          primaryActionTitle="Create"
          primaryAction={() => console.log('Create')}
          secondaryActionTitle="Download"
          secondaryAction={() => console.log('Download')}
          searchValue={searchValue}
          searchHandler={(value) => setSearchValue(value)}
          filterScheme={filterScheme}
          filterValue={filterQuery}
          filterHandler={setFilterQuery}
          options={{
            applicationStatus: applicationStatusOptions,
            jurisdictionalCountry: countryOptions,
            businessType: businessTypeOptions,
          }}
        />

        <Stack direction="row" rowGap={1} gap={1} flexWrap="wrap" pt={2} pb={6}>
          <Chip
            size="medium"
            label="Themes"
            component="a"
            href="#themes"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="FormFields"
            component="a"
            href="#form-fields"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="UIKit buttons"
            component="a"
            href="#ui-kit-buttons"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Badges"
            component="a"
            href="#badges"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Cards"
            component="a"
            href="#cards"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Tabs"
            component="a"
            href="#tabs"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Chips"
            component="a"
            href="#chips"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Buttons"
            component="a"
            href="#buttons"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Modal"
            component="a"
            href="#modal"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Checkboxes sections"
            component="a"
            href="#checkboxes-sections"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Typography"
            component="a"
            href="#typography"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="InputBox"
            component="a"
            href="#input-box"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="DetailsField"
            component="a"
            href="#details-field"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Date picker"
            component="a"
            href="#datepicker"
            clickable
            variant="outlined"
          />
          <Chip
            size="medium"
            label="Selector"
            component="a"
            href="#selector"
            clickable
            variant="outlined"
          />
        </Stack>
      </StickyHeader>

      <Box pt={4}>
        <div id="themes" className="showcase-section">
          <Sections.Themes />
        </div>
        <div id="form-fields" className="showcase-section">
          <Sections.FormFieldsSection />
        </div>
        <div id="ui-kit-buttons" className="showcase-section">
          <Sections.MilqButtonsSection />
        </div>
        <div id="badges" className="showcase-section">
          <Sections.BadgesSection />
        </div>
        <div id="cards" className="showcase-section">
          <Sections.CardSection />
        </div>
        <div id="tabs" className="showcase-section">
          <Sections.TabsShowcase />
        </div>
        <div id="chips" className="showcase-section">
          <Sections.Chips />
        </div>
        <div id="buttons" className="showcase-section">
          <Sections.ButtonsUpdatedSection />
        </div>
        <div id="modal" className="showcase-section">
          <Sections.ModalDialogsSection />
        </div>
        <div id="checkboxes-sections" className="showcase-section">
          <Sections.CheckboxesSection />
        </div>
        <div id="typography" className="showcase-section">
          <Sections.TypographySection />
        </div>
        <div id="input-box" className="showcase-section">
          <Sections.InputBoxes inputBoxVal={inputBoxVal} setInputBoxVal={setInputBoxVal} />
        </div>
        <div id="details-field" className="showcase-section">
          <Sections.DetailsFields />
        </div>
        <div id="datepicker" className="showcase-section">
          <Sections.DatePickers />
        </div>
        <div id="selector" className="showcase-section">
          <Sections.SelectorsSection />
        </div>

        {/* <Sections.SimpleButtonsSection /> */}

        {/*
          <div className="showcase-routes"><Outlet /></div>
          <Box py={8}><DatePickerSection /></Box>
          <ApplicationRowsSection />

          <Modal showModal={showModal} setModal={setShowModal} modalHeader={<h3>Title</h3>}>
            <p>This is the test message for modal window</p>
            <InputBox label="Input value" placeholder="Placeholder value" value={inputBoxVal}
              onChange={(e: any) => setInputBoxVal(e.target.value)}
              handleClear={() => setInputBoxVal('')}
            />
          </Modal>
        */}
      </Box>
    </PageContainer>
  );
};

export default Showcase;
