export default [
  {
    label: 'United Kingdom',
    value: 'uk',
  },
  {
    label: 'France',
    value: 'fr',
  },
  {
    label: 'Sweden',
    value: 'sw',
  },
  {
    label: 'Poland',
    value: 'pl',
  },
];
