import React, { useState } from 'react';
import { Button, ListItemText, Menu, MenuItem } from '@mui/material';

interface TriggeredRulesChipProps {
  description: string;
}

export const TriggeredRulesChip: React.FC<TriggeredRulesChipProps> = ({ description }) => {
  const [menuAnhcorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnhcorEl);
  const handleMenuClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="base"
        onClick={(e) => {
          e.stopPropagation();
          description.split(';').length > 1 && setMenuAnchorEl(e.currentTarget);
        }}
        size="small"
        sx={{
          minWidth: 'auto',
          bgcolor: 'white',
          borderColor: 'grey.300',
          lineHeight: '21px',
        }}
      >
        {description.split(';').length}
      </Button>

      <Menu id="AssigneeMenu" anchorEl={menuAnhcorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {description.split(';').map((el) => (
          <MenuItem key={el} onClick={(e) => e.stopPropagation()}>
            <ListItemText>{el}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
