import toastr from 'toastr';

export function toastSuccess(message) {
  toastr.success(message);
}

export function toastError(message) {
  toastr.error(message);
}

export const toastLoadingError = (entityTitle, error) =>
  toastError(`Error loading ${entityTitle}. ${error}`);

export const toastLoadingListError = (entityTitle, error) =>
  toastError(`Error loading ${entityTitle} list. ${error}`);

export const toastCreateSuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} created successfully.`);
export const toastCreateError = (entityTitle, error) =>
  toastError(`Error creating ${entityTitle}. ${error}`);

export const toastAddSuccess = (entityTitle) => toastSuccess(`${entityTitle} added successfully.`);

export const toastUpdateSuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} updated successfully.`);
export const toastUpdateError = (entityTitle, error) =>
  toastError(`Error updating ${entityTitle}. ${error}`);

export const toastDeleteSuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} deleted successfully.`);
export const toastDeleteError = (entityTitle, error) =>
  toastError(`Error deleting ${entityTitle}. ${error}`);

export const toastEnableSuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} enabled successfully.`);
export const toastEnableError = (entityTitle, error) =>
  toastError(`Error enabling ${entityTitle}. ${error}`);

export const toastDisableSuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} disabled successfully.`);
export const toastDisableError = (entityTitle, error) =>
  toastError(`Error disabling ${entityTitle}. ${error}`);

export const toastCopySuccess = (entityTitle) =>
  toastSuccess(`${entityTitle} copied to the clipboard.`);
export const toastCopyError = (entityTitle, error) =>
  toastError(`Can't copy ${entityTitle}. ${error}`);
