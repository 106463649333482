import React from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from 'uikit';
import UsersListRow from './UsersListRow';

export const UsersList = ({ users, isLoading }) => (
  <div>
    {isLoading ? (
      <Skeleton count={11} height={56} />
    ) : (
      <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {users.map((user) => (
            <Grid
              item
              sx={(theme) => ({
                width: '100%',
                minHeight: '56px',
                borderBottom: `1px dashed ${theme.palette.grey[200]}`,
              })}
              key={user.id}
            >
              <UsersListRow user={user} />
            </Grid>
          ))}
        </Grid>
      </>
    )}
  </div>
);
