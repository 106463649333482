/*
check whether the currently selected product matches the parameter by ID or by name product(tenant).
check whether the current user has such a product in the permissions of the parameter by ID or by name product(tenant).
*/
import { useSelector } from 'react-redux';
import { makeUserData } from '../selectors/user';

type ProductNameOrId = string | number; // ID or name of Tenant

export const checkSelectedProduct = (product: ProductNameOrId) => {
  const { selectedProductId, tenants } = useSelector(makeUserData());

  const checkId = Number(product) === selectedProductId;

  const checkName =
    tenants.find((tenant) => tenant.id === selectedProductId).name.toLowerCase() ===
    String(product).toLowerCase();

  return checkId || checkName;
};
