import { removeEmptyProperties } from '../../utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const kycProvidersApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getKycProviders: build.query({
      query: (params) => ({
        url: 'kyc/providers/',
        params,
      }),
      providesTags: () => [{ type: 'KycProviders', id: 'LIST' }],
    }),

    updateKycProvider: build.mutation({
      query(kycProvider) {
        const body = { ...kycProvider };
        removeEmptyProperties(body);
        return {
          url: `kyc/providers/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'KycProviders', id: 'LIST' }],
    }),

    enableKycProvider: build.mutation({
      query(kycProvider) {
        const body = { ...kycProvider };
        return {
          url: `kyc/providers/${body.id}/enable`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'KycProviders', id: 'LIST' }],
    }),

    disableKycProvider: build.mutation({
      query(kycProvider) {
        const body = { ...kycProvider };
        return {
          url: `kyc/providers/${body.id}/disable`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'KycProviders', id: 'LIST' }],
    }),

    shiftKycProvider: build.mutation({
      query(kycProvider) {
        const body = { ...kycProvider };
        return {
          url: `kyc/providers/${body.id}/shift`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'KycProviders', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetKycProvidersQuery,
  useUpdateKycProviderMutation,
  useEnableKycProviderMutation,
  useDisableKycProviderMutation,
  useShiftKycProviderMutation,
} = kycProvidersApi;
