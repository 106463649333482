import { figmaVariables } from '../../figmaVariables';

export const standard = {
  props: {
    variant: 'standard',
  },
  style: {
    '& .MuiInputBase-root': {
      border: 'none',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
  },
};
