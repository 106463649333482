import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Edit as EditIcon, AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ModalDialog } from 'uikit';
import { ThresholdDialog } from './ThresholdDialog';
import { StateType } from './types';
import { GetLamp } from './RulesTree/GetLamp';
import { ruleAnswer } from './constatns';
import { findMinValue, getPrevNext } from './rulesUtils';

interface ThresholdPanelProps {
  isLocked?: boolean;
}

export const ThresholdPanel: React.FC<ThresholdPanelProps> = ({ isLocked = false }) => {
  const { thresholds } = useSelector((state: StateType) => ({
    thresholds: state.ruleSetState.thresholds,
  }));

  const [openThesholdDialog, setOpenThesholdDialog] = useState<boolean>(false);

  // TODO api for threshold;

  const getRange = (name) => {
    if (name === 'PASS') return `0 - ${findMinValue(thresholds)}`;
    const nextValue = getPrevNext(name, thresholds)[1];
    return `${thresholds[name]} - ${nextValue === 100 ? nextValue : nextValue - 1}`;
  };
  const thresholdModel = ruleAnswer.filter(
    (el) => el.value !== 'ERROR' && el.value !== 'MODERATION',
  );

  const ThresholdBlock = ({ label }) => (
    <Stack direction="row" alignItems="center" gap={1}>
      <GetLamp answer={label} />
      <Typography color="grey.500">
        {thresholdModel.find((el) => el.value === label).label}
      </Typography>
      <Typography color="primary">{getRange(label)}</Typography>
    </Stack>
  );

  return (
    <>
      {thresholds ? (
        <Stack direction="row" alignItems="center" gap={4}>
          <Typography>Threshold:</Typography>
          <ThresholdBlock label="PASS" />
          {Object.keys(thresholds).map((el) => (
            <ThresholdBlock label={el} key={el} />
          ))}
          <Button
            color="base"
            variant="contained"
            startIcon={<EditIcon />}
            disabled={isLocked}
            size="small"
            onClick={() => setOpenThesholdDialog(true)}
          >
            Edit
          </Button>
        </Stack>
      ) : (
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          disabled={isLocked}
          size="small"
          onClick={() => setOpenThesholdDialog(true)}
        >
          Add threshold
        </Button>
      )}
      {openThesholdDialog && (
        <ModalDialog
          open={openThesholdDialog}
          maxWidth="xs"
          onClose={() => setOpenThesholdDialog(false)}
          title="Ruleset threshold"
          actionTitle="Update"
        >
          <ThresholdDialog onClose={() => setOpenThesholdDialog(false)} />
        </ModalDialog>
      )}
    </>
  );
};
