/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */

import { Node } from './Node';

export class BinaryNode extends Node {
  constructor(op, l, r) {
    if (!(l instanceof Node && r instanceof Node)) {
      throw new Error('invalid node passed');
    }
    super();
    this.op = op;
    this.left = l;
    this.right = r;
    l.parent = this;
    r.parent = this;
  }

  compute(ctx) {
    const l = this.left.compute(ctx);
    const r = this.right.compute(ctx);
    switch (this.op) {
      // logic operators
      case 'AND':
        return l && r;
      case 'OR':
        return l || r;

      // comparison-operators
      case '=':
        return l === r;
      case '<=':
        return l <= r;
      case '>=':
        return l >= r;
      case '!=':
        return l !== r;
      case '>':
        return l > r;
      case '<':
        return l < r;

      // computational operators
      case '+':
        return l + r;
      case '-':
        return l - r;
      case '*':
        return l * r;
      case '/':
        return l / r;
    }
    throw new Error(`operator not implemented '${this.op}'`);
  }

  toString() {
    return `( ${this.left.toString()} ${this.op} ${this.right.toString()} )`;
  }

  toDDFConditionNode(operatorMap) {
    if (Object.keys(operatorMap).includes(this.op)) {
      return {
        ...this.left.toDDFConditionNode(operatorMap[this.op]),
        ...this.right.toDDFConditionNode(operatorMap[this.op]),
      };
    }
    return {
      [this.op.toLowerCase()]: [
        this.left.toDDFConditionNode(operatorMap),
        this.right.toDDFConditionNode(operatorMap),
      ],
    };
  }
}

BinaryNode.operators = [
  // order defines precedence
  '*',
  '/',
  '+',
  '-',
  '>',
  '<',
  '<=',
  '>=',
  '!=',
  '=',
  'AND',
  'OR',
];
