import { removeEmptyProperties } from 'utils/modelHelper';
import { gatewayApi } from '../gatewayApi';
import type {
  Application,
  OpenpaydClientId,
} from '../../features/ApplicationManagement/types';
import { GetApplicationsRequest } from '../../features/ApplicationManagement/types';
import {
  AppHistory,
  AppHistoryTransformed,
} from '../../features/ApplicationManagement/types/Application';

const applicationApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getApplications: build.query<GetApplicationsRequest, any>({
      query: (params) => ({
        url: `applications`,
        method: 'GET',
        params,
      }),
      providesTags: [{ type: 'Applications', id: 'LIST' }],
    }),
    getApplication: build.query<Application, string>({
      query: (applicationId) => ({
        url: `applications/${applicationId}`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [{ type: 'Application', id: applicationId }],
    }),
    getCategories: build.query({
      query: () => ({
        url: `categories`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'AppCategories', id: 'LIST' }],
      transformResponse: (response: any[]) =>
        response.map((category) => {
          const result = {
            label: category.title,
            value: category.id,
          };
          return result;
        }),
    }),
    addApplication: build.mutation({
      query(model) {
        const body = { ...model };
        return {
          url: 'applications?createWhitelabelUser=false', // TODO remove ?createWhitelabelUser=false
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        { type: 'Applications', id: 'LIST' },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),

    updateApplication: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `applications/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id },
        { type: 'ApplicationEmails', id },
        { type: 'ApplicationHistory', id },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),

    updateApplicationPartial: build.mutation({
      query({ id, ...patch }) {
        return {
          url: `applications/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id },
        { type: 'ApplicationEmails', id },
        { type: 'ApplicationHistory', id },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),

    deleteApplication: build.mutation({
      query: (applicationId) => ({
        url: `applications/${applicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),

    updateApplicationStatuses: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `applications/${model.id}/statuses`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id },
        { type: 'ApplicationStates', id },
        { type: 'ApplicationStatesNext', id },
        { type: 'ApplicationEvents', id },
        { type: 'ApplicationHistory', id },
      ],
    }),

    updateApplicationClientIds: build.mutation<
      OpenpaydClientId[],
      { body: { clientIds: string[] }; applicationId: string }
    >({
      query({ body, applicationId }) {
        return {
          url: `applications/${applicationId}/openpayd-clients`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'Application', id: applicationId },
      ],
    }),

    customerPasswordRecover: build.mutation({
      query(applicationId) {
        return {
          url: `applications/${applicationId}/customer-password-recover`,
          method: 'POST',
        };
      },
    }),

    getApplicationEmails: build.query<any, { applicationId: string; emailType: string }>({
      query: ({ applicationId, emailType }) => ({
        url: `applications/${applicationId}/emails/${emailType}`,
        method: 'GET',
      }),
      providesTags: (result, error, { applicationId }) => [
        { type: 'ApplicationEmails', id: applicationId },
      ],
    }),
    addApplicationEmails: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `applications/${model.id}/emails/${model.emailType}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id },
        { type: 'ApplicationEmails', id },
        { type: 'ApplicationHistory', id },
      ],
    }),

    getApplicationHistory: build.query<AppHistoryTransformed[], string>({
      query: (applicationId) => ({
        url: `applications/${applicationId}/history/?historySize=100`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'ApplicationHistory', id: applicationId },
      ],
      transformResponse: (data: AppHistory[]) =>
        data
          ?.map((el) => ({
            type: 'app-status',
            date: el.revision.revisionInstant,
            modifiedByName: el.revision.modifiedByName,
            modifiedBy: el.revision.modifiedBy,
            revisionNumber: el.revision.revisionNumber,
            applicationStatus: el.state,
            decision: el.decision,
            reason: el.reason,
          }))
          .sort((a, b) => (b.revisionNumber > a.revisionNumber ? 1 : -1)),
    }),

    getUnreadMessages: build.query({
      query: (applicationId) => ({
        url: `applications/${applicationId}/messages/unread-count`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'ApplicationsUnreadMessages', id: applicationId },
      ],
    }),

    copyApplication: build.mutation<any, { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: `applications/${applicationId}/copies`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [
        { type: 'Applications', id: 'LIST' },
        { type: 'Dashboards', id: 'LIST' },
      ],
    }),

    sendToSign: build.mutation<any, { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: `applications/${applicationId}/esignatures`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id: applicationId },
        { type: 'FormSections', id: applicationId },
        { type: 'FormSectionsHistory', id: applicationId },
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),

    sendSectionToSign: build.mutation<
      any,
      { applicationId: string; sectionId: number; partition: string }
    >({
      query: ({ applicationId, sectionId, partition }) => ({
        url: `applications/${applicationId}/forms/sections/${sectionId}/esignatures`,
        method: 'POST',
        body: { partition },
      }),
      invalidatesTags: (result, error, { applicationId }) => [
        { type: 'Applications', id: 'LIST' },
        { type: 'Application', id: applicationId },
        { type: 'FormSections', id: applicationId },
        { type: 'FormSectionsHistory', id: applicationId },
        { type: 'ApplicationForms', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationQuery,
  useGetCategoriesQuery,
  useAddApplicationMutation,
  useUpdateApplicationMutation,
  useUpdateApplicationClientIdsMutation,
  useUpdateApplicationPartialMutation,
  useDeleteApplicationMutation,

  useUpdateApplicationStatusesMutation,

  useCustomerPasswordRecoverMutation,

  useGetApplicationEmailsQuery,
  useAddApplicationEmailsMutation,

  useGetApplicationHistoryQuery,

  useGetUnreadMessagesQuery,

  useSendToSignMutation,
  useSendSectionToSignMutation,

  useCopyApplicationMutation,
} = applicationApi;
