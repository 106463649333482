import React, { useState } from 'react';
import { Panel, DatePicker } from 'uikit';
import { DatePicker as MUIDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import { DatePickerInput } from 'muikit';
import { Box, Typography } from '@mui/material';

export function DatePickers() {
  const [testDate, setTestDate] = useState(new Date(1988, 0, 17));
  return (
    <Box mb={8}>
      <Typography variant="h2" gutterBottom>
        Date picker
      </Typography>
      <Panel>
        <DatePicker
          label="UIKit DatePicker"
          name="testDate"
          value={testDate}
          onChange={(e) => {
            setTestDate(e.target.value);
          }}
        />
      </Panel>

      {/* <Panel> */}
      {/*  <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      {/*    <MUIDatePicker */}
      {/*      value={testDate} */}
      {/*      onChange={(value) => { */}
      {/*        setTestDate(value); */}
      {/*      }} */}
      {/*      components={{ OpenPickerIcon: CalendarTodayIcon }} */}
      {/*      inputFormat="yyyy/MM/dd" */}
      {/*      renderInput={(props) => <DatePickerInput {...props} ref={props.inputRef} />} */}
      {/*    /> */}
      {/*  </LocalizationProvider> */}
      {/* </Panel> */}
    </Box>
  );
}
