import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';

interface ScrollContainerProps {
  children: React.ReactNode;
  containerHeight?: string;
}

export const ScrollContainer: React.FC<ScrollContainerProps> = ({ children, containerHeight }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const wrapper = wrapperRef.current;
      if (wrapper) {
        const { top } = wrapper.getBoundingClientRect();
        wrapper.style.height = `calc(100vh - ${top}px)`;
      }
    };

    !containerHeight && handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      sx={{
        width: '100%',
        overflowY: 'auto',
        height: containerHeight || '100%',
        '::-webkit-scrollbar': {
          width: '16px',
        },
        '::-webkit-scrollbar-thumb': {
          height: '56px',
          borderRadius: '8px',
          border: '4px solid transparent',
          backgroundClip: 'content-box',
          bgcolor: 'transparent',
        },
        ':hover::-webkit-scrollbar-thumb': {
          bgcolor: 'grey.300',
        },
      }}
    >
      {children}
    </Box>
  );
};
