import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useFormik, FormikProps } from 'formik';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ErrorMessage, FormRow, InputBox, Modal, Select, ISelectOption } from 'uikit';
import { Dropzone, useDropzone } from 'muikit/Dropzone';

import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useAddKycApplicantWithProviderMutation } from 'services/gatewayApi/kycApplicantsApi';
import { useUploadKycDocumentMutation } from 'services/gatewayApi/kycDocumentsApi';
import { useAddKycCheckMutation } from 'services/gatewayApi/kycChecksApi';

import { documentTypes, positions } from '../constants';

import './KycApplicantDialog.scss';
import { ValueLabel } from '../../../types';

// Limit to single side file upload
const singleSidedDocs = ['PASSPORT'];

const allowedDocumentTypes = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf'];

interface KycApplicantDialogProps {
  showDialog: boolean;
  setShowDialog: (value: boolean) => void;
  applicationId?: string;
}

interface FormValues {
  firstName: string;
  lastName: string;
  position: string;
  faceSide: any;
  docIssuingCountryCode: string;
  documentType: string;
  frontSide: any;
  backSide: any;
  addressProof: any;
}

export const KycApplicantDialog: React.FC<KycApplicantDialogProps> = ({
  showDialog,
  setShowDialog,
  applicationId,
}) => {
  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        countries: (data as any[])?.map((c) => ({
          value: c.countryCode,
          label: c.name,
        })),
        isLoading: isLoadingCountries,
      }),
    },
  );

  const refInputAutofocus = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refInputAutofocus?.current?.focus();
  }, [showDialog]);

  const [addKycApplicantWithProvider] = useAddKycApplicantWithProviderMutation();

  const formik: FormikProps<FormValues> = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      position: '',
      faceSide: '',
      docIssuingCountryCode: '',
      documentType: 'ID_CARD',
      frontSide: '',
      backSide: '',
      addressProof: '',
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      position: yup
        .string()
        .required('Please select a position')
        .oneOf(
          positions.map((position) => position.value as string),
          'Please select a position from the list',
        ),
      faceSide: yup
        .string()
        .required('Face photo is required')
        .test(
          'CHECK_DOCUMENT_EXTENSION',
          'Supported document extension [png, jpeg, gif, pdf]',
          (type) => allowedDocumentTypes.includes(type),
        ),
      docIssuingCountryCode: yup
        .string()
        .required('Please select document issuing country')
        .oneOf(
          (countries as ValueLabel[]).map((country) => country.value as string),
          'Please select a country from the list',
        ),
      documentType: yup
        .string()
        .required('Please select document type')
        .oneOf(
          (documentTypes as ValueLabel[]).map((type) => type.value as string),
          'Please select a document type from the list',
        ),
      frontSide: yup
        .string()
        .required('Front side is required')
        .test(
          'CHECK_DOCUMENT_EXTENSION',
          'Supported document extension [png, jpeg, gif, pdf]',
          (type) => allowedDocumentTypes.includes(type),
        ),
      backSide: yup
        .string()
        .test(
          'CHECK_DOCUMENT_EXTENSION',
          'Supported document extension [png, jpeg, gif, pdf]',
          (type) =>
            singleSidedDocs.includes(formik.values.documentType)
              ? true
              : type === undefined || allowedDocumentTypes.includes(type),
        ),
      addressProof: yup
        .string()
        .required('Address proof document is required')
        .test(
          'CHECK_DOCUMENT_EXTENSION',
          'Supported document extension [png, jpeg, gif, pdf]',
          (type) => allowedDocumentTypes.includes(type),
        ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const { documentType } = formik.values;

      // 1. Create applicant
      let applicantId;

      // Create an applicant
      addKycApplicantWithProvider({
        provider: 'IDN',
        applicant: {
          applicationId,
          firstName: values.firstName,
          lastName: values.lastName,
          position: values.position,
          docIssuingCountryCode: values.docIssuingCountryCode,
        },
      })
        .unwrap()
        .then((applicant) => {
          applicantId = applicant.id;

          // #region 2. Upload documents

          // Upload face photo
          if (documentFace) {
            uploadPromiseFace.current = uploadDocument({
              formData: documentFace,
              applicantId,
              side: 'FACE',
              type: documentType,
              issuingCountry: values.docIssuingCountryCode,
            }).then(() => {
              // upload address proof
              if (documentAddress) {
                uploadPromiseAddress.current = uploadDocument({
                  formData: documentAddress,
                  applicantId,
                  side: 'UTILITY_BILL',
                  type: documentType,
                  issuingCountry: values.docIssuingCountryCode,
                }).then(() => {
                  // Upload front side
                  if (documentFront) {
                    uploadPromiseFront.current = uploadDocument({
                      formData: documentFront,
                      applicantId,
                      side: 'FRONT',
                      type: documentType,
                      issuingCountry: values.docIssuingCountryCode,
                    }).then(() => {
                      if (!singleSidedDocs.includes(documentType) && documentBack) {
                        // Upload back side
                        uploadPromiseBack.current = uploadDocument({
                          formData: documentBack,
                          applicantId,
                          side: 'BACK',
                          type: documentType,
                          issuingCountry: values.docIssuingCountryCode,
                        }).then(() => {
                          submitDocsToKyc(applicantId);
                        });
                      } else {
                        submitDocsToKyc(applicantId);
                      }
                    });
                  } else {
                    submitDocsToKyc(applicantId);
                  }
                });
                // })
              } else {
                submitDocsToKyc(applicantId);
              }
            });

            // #endregion
          }
        })
        .catch((rejected) => {
          console.error(rejected);
        });
    },
  });

  const [addKycCheck] = useAddKycCheckMutation();
  const submitDocsToKyc = (applicantId) => {
    if (applicantId) {
      setDocumentBack(null);
      setDocumentFront(null);

      addKycCheck({ applicantId })
        .unwrap()
        .then(() => {
          formik.resetForm();
          formik.setSubmitting(false);
        })
        .catch((rejected) => {
          console.error(rejected);
        });
    }
    setShowDialog(false);
  };

  const handleCancel = () => {
    formik.resetForm();
    setShowDialog(false);
  };

  // #region Document selection to upload
  const [uploadDocument] = useUploadKycDocumentMutation();

  // Face photo
  const uploadPromiseFace = useRef(null);
  const [documentFace, setDocumentFace] = useState(null);
  const {
    acceptedFiles: acceptedFilesFace,
    getRootProps: getFaceDzProps,
    getInputProps: getFaceDzInputProps,
  } = useDropzone({
    upload: (formData: FormData) => {
      setDocumentFace(formData);
      // @ts-ignore
      formik.setFieldValue('faceSide', formData.get('file').type);
    },
  });

  // Address proof
  const uploadPromiseAddress = useRef(null);
  const [documentAddress, setDocumentAddress] = useState(null);
  const {
    acceptedFiles: acceptedFilesAddress,
    getRootProps: getAddressDzProps,
    getInputProps: getAddressDzInputProps,
  } = useDropzone({
    upload: (formData: FormData) => {
      setDocumentAddress(formData);
      // @ts-ignore
      formik.setFieldValue('addressProof', formData.get('file').type as string);
    },
  });

  // Front side
  const uploadPromiseFront = useRef(null);
  const [documentFront, setDocumentFront] = useState(null);
  const {
    acceptedFiles: acceptedFilesFront,
    getRootProps: getFrontDzProps,
    getInputProps: getFrontDzInputProps,
  } = useDropzone({
    upload: (formData: FormData) => {
      setDocumentFront(formData);
      // @ts-ignore
      formik.setFieldValue('frontSide', formData.get('file').type);
    },
  });

  // Back side
  const uploadPromiseBack = useRef(null);
  const [documentBack, setDocumentBack] = useState(null);
  const {
    acceptedFiles: acceptedFilesBack,
    getRootProps: getBackDzProps,
    getInputProps: getBackDzInputProps,
  } = useDropzone({
    upload: (formData: FormData) => {
      setDocumentBack(formData);
      // @ts-ignore
      formik.setFieldValue('backSide', formData.get('file').type);
    },
  });
  // #endregion

  return (
    <Modal
      doNotCloseOnBackdrop
      showModal={showDialog}
      setModal={setShowDialog}
      modalHeader={<h3>New KYC Applicant</h3>}
    >
      <p>KYC Applicant information</p>

      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <FormRow>
          <InputBox
            label="First Name"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            handleClear={() => formik.setFieldValue('firstName', '')}
            value={formik.values.firstName}
            disabled={formik.isSubmitting}
            ref={refInputAutofocus}
            hasError={!!(formik.errors.firstName && formik.touched.firstName)}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <ErrorMessage>{formik.errors.firstName}</ErrorMessage>
          )}
        </FormRow>

        <FormRow>
          <InputBox
            label="Last Name"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            handleClear={() => formik.setFieldValue('lastName', '')}
            value={formik.values.lastName}
            disabled={formik.isSubmitting}
            hasError={!!(formik.errors.lastName && formik.touched.lastName)}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <ErrorMessage>{formik.errors.lastName}</ErrorMessage>
          )}
        </FormRow>

        <FormRow>
          <Select
            name="position"
            selectBox
            showOnFocus
            label="Position"
            options={positions}
            onChange={(option: ISelectOption) => {
              formik.setFieldValue('position', option ? option.value : '');
            }}
            value={
              formik.values.position
                ? positions.find((c) => c.value === formik.values.position)
                : null
            }
            disabled={formik.isSubmitting}
          />
          {formik.errors.position && formik.touched.position && (
            <ErrorMessage>{formik.errors.position}</ErrorMessage>
          )}
        </FormRow>

        <FormRow>
          <Select
            name="docIssuingCountryCode"
            selectBox
            showOnFocus
            label="Document Issuing Country"
            options={countries}
            onChange={(option: ISelectOption) => {
              formik.setFieldValue('docIssuingCountryCode', option ? option.value : '');
            }}
            value={
              formik.values.docIssuingCountryCode
                ? countries.find((c) => c.value === formik.values.docIssuingCountryCode)
                : null
            }
            disabled={formik.isSubmitting}
          />
          {formik.errors.docIssuingCountryCode && formik.touched.docIssuingCountryCode && (
            <ErrorMessage>{formik.errors.docIssuingCountryCode}</ErrorMessage>
          )}
        </FormRow>

        <FormRow>
          <Select
            name="documentType"
            selectBox
            showOnFocus
            label="Document Type"
            options={documentTypes}
            onChange={(option: ISelectOption) => {
              formik.setFieldValue('documentType', option ? option.value : '');
            }}
            value={
              formik.values.documentType
                ? documentTypes.find((c) => c.value === formik.values.documentType)
                : null
            }
            disabled={formik.isSubmitting}
          />
          {formik.errors.documentType && formik.touched.documentType && (
            <ErrorMessage>{formik.errors.documentType}</ErrorMessage>
          )}
        </FormRow>

        <FormRow>
          <Box className="dropzoneUpload">
            <Typography variant="body2">Front Side</Typography>
            <Dropzone
              acceptedFiles={acceptedFilesFront}
              getRootProps={getFrontDzProps}
              getInputProps={getFrontDzInputProps}
              disabled={formik.isSubmitting}
            />
            {formik.errors.frontSide && formik.touched.frontSide && (
              <ErrorMessage>{formik.errors.frontSide}</ErrorMessage>
            )}
          </Box>
        </FormRow>

        {!singleSidedDocs.includes(formik.values.documentType) && (
          <FormRow>
            <Box className="dropzoneUpload">
              <Typography variant="body2">Back Side</Typography>
              <Dropzone
                acceptedFiles={acceptedFilesBack}
                getRootProps={getBackDzProps}
                getInputProps={getBackDzInputProps}
                disabled={formik.isSubmitting}
              />
              {formik.errors.backSide && formik.touched.backSide && (
                <ErrorMessage>{formik.errors.backSide}</ErrorMessage>
              )}
            </Box>
          </FormRow>
        )}

        <FormRow>
          <Box className="dropzoneUpload">
            <Typography variant="body2">Face Photo</Typography>
            <Dropzone
              acceptedFiles={acceptedFilesFace}
              getRootProps={getFaceDzProps}
              getInputProps={getFaceDzInputProps}
              disabled={formik.isSubmitting}
            />
            {formik.errors.faceSide && formik.touched.faceSide && (
              <ErrorMessage>{formik.errors.faceSide}</ErrorMessage>
            )}
          </Box>
        </FormRow>

        <FormRow>
          <Box className="dropzoneUpload">
            <Typography variant="body2">Address proof</Typography>
            <Dropzone
              acceptedFiles={acceptedFilesAddress}
              getRootProps={getAddressDzProps}
              getInputProps={getAddressDzInputProps}
              disabled={formik.isSubmitting}
            />
            {formik.errors.addressProof && formik.touched.addressProof && (
              <ErrorMessage>{formik.errors.addressProof}</ErrorMessage>
            )}
          </Box>
        </FormRow>

        <div className="formControlBlock">
          <Button
            type="button"
            variant="text"
            color="primary"
            onClick={handleCancel}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={
              formik.isSubmitting ? (
                <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
              ) : null
            }
            disabled={formik.isSubmitting}
            sx={{ ml: 2 }}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
