import React from 'react';
import { Box, Typography } from '@mui/material';
import { Note, NoteContent, NoteHeader } from 'muikit/Note';

interface CommentData {
  comment: string;
  commenterId: string;
  createdDate: string;
}

interface CommentBox {
  data: Partial<CommentData>;
  users: any;
}

const CommentBox = ({ data, users }: CommentBox): JSX.Element => {
  const { comment = '', commenterId, createdDate } = data;
  const commenter = users.find((u) => u.id === commenterId);

  return (
    <Note sx={[{ my: 4, borderRadius: '6px' }]}>
      <NoteHeader
        metadata={
          <Box sx={{ display: 'inline-flex' }}>
            <Typography sx={{ mr: 2 }} variant="subtitle1" color="grey.900">
              by {commenter.name}
            </Typography>
            <Typography variant="body1" color="grey.600">
              {createdDate}
            </Typography>
          </Box>
        }
      />
      <NoteContent>{comment}</NoteContent>
    </Note>
  );
};

export default CommentBox;
