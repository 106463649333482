import React from 'react';
import { Badge, BadgeProps, Tab, TabProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabList as MuiTabList, TabListProps as MuiTabListProps } from '@mui/lab';

const TabListStyled = styled(MuiTabList)<MuiTabListProps>(({ theme }) =>
  theme.unstable_sx({
    flex: '0 0 240px',
    '& .MuiTabs-indicator': {},
  }),
);

const TabStyled = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'isSendToAll',
})<TabProps & { isSendToAll?: boolean }>(({ isSendToAll = false, theme }) =>
  theme.unstable_sx({
    alignItems: 'flex-start',
    margin: '4px 15px 4px 0',
    borderRadius: '8px',
    px: 2,
    py: 2.5,
    minHeight: '20px',
    color: 'grey.600',
    bgcolor: isSendToAll ? '#FDF3E2' : 'white',
    '&:not(:disabled):focus': {
      boxShadow: 'none',
    },
    '&.Mui-selected': {
      color: 'grey.900',
    },
  }),
);

const BadgeStyled = styled(Badge)<BadgeProps>(({ theme }) =>
  theme.unstable_sx({
    '& .MuiBadge-badge': {
      width: 4,
      height: 4,
      minWidth: 4,
      top: 2,
      right: -2,
    },
  }),
);

interface TabListProps<T> {
  items: Array<T>;
  getLabel: (item: T) => string;
  getValue: (item: T) => any;
  getHasActivity?: (item: T) => boolean;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  isSendToAll?: boolean;
}

const TabList = <T,>({
  items,
  setSelectedId,
  getLabel,
  getValue,
  getHasActivity,
  isSendToAll = false,
}: TabListProps<T>): JSX.Element => (
  <TabListStyled orientation="vertical" onChange={(e, value) => setSelectedId(value)}>
    {items.map((i) => (
      <TabStyled
        isSendToAll={isSendToAll}
        key={getValue(i)}
        label={
          <BadgeStyled
            color="primary"
            badgeContent=" "
            variant="dot"
            invisible={!getHasActivity(i)}
          >
            <Typography variant="subtitle1" sx={{ textAlign: 'left', textTransform: 'uppercase' }}>
              {getLabel(i)}
            </Typography>
          </BadgeStyled>
        }
        value={getValue(i)}
      />
    ))}
  </TabListStyled>
);

export default TabList;
