import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography } from '@mui/material';
import { makeUsersData } from '../../selectors/users';
import { makeProductsData } from '../../selectors/products';
import { fetchUsers } from '../../actions/users';
import { fetchProducts } from '../../actions/products';
import Integration from './integration';
import KYCThreshold from './kycThreshold';
import SeonReport from './seonReport';

export class Administration extends Component {
  componentWillMount() {
    this.props.fetchUsers();
    this.props.fetchProducts();
  }

  render() {
    return (
      <div className="page-content">
        <Helmet title="Administration" />
        <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Typography variant="h1" py={4}>
                    Administration
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="grid simple horizontal red">
                  <div className="grid-title">
                    <h4>
                      <span>Integration Status</span>
                    </h4>
                  </div>
                  <Integration />
                </div>
              </div>
              <div className="col-md-12">
                <div className="grid simple horizontal red">
                  <div className="grid-title">
                    <h4>
                      <span>KYC Threshold</span>
                    </h4>
                  </div>
                  <KYCThreshold />
                </div>
              </div>
              <div className="col-md-12">
                <div className="grid simple">
                  <div className="grid-title">
                    <h4>
                      <span>Seon Report</span>
                    </h4>
                  </div>
                  <SeonReport />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: makeUsersData(),
  products: makeProductsData(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchProducts: () => dispatch(fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
