import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress, Divider } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  ConfirmDialog,
  DetailsField,
  Section,
  SectionContainer,
  SectionHeading,
  Spinner,
} from 'uikit';
import { useGetFormTemplateFieldsQuery } from 'services/gatewayApi/formTemplateFieldsApi';
import {
  useGetApplicationFormsQuery,
  useUpdateApplicationFormStatusMutation,
} from 'services/gatewayApi/applicationFormsApi';
import {
  useAddApplicationFormRecordsMutation,
  useDeleteApplicationFormRecordMutation,
} from 'services/gatewayApi/applicationFormRecordsApi';
import {
  useGetAppFormSectionsQuery,
  useUpdateAppFormSectionStatusMutation,
} from 'services/gatewayApi/applicationFormSectionsApi';
import { useGetApplicationStatesQuery } from 'services/gatewayApi/stateMachineApi';
import { openFileInNewTab } from 'utils/downloadFile';
import { toastSuccess, toastDeleteSuccess, checkProductName } from 'utils';

import { RenderKeyValue } from './RenderKeyValue';
import { RenderGroup } from './RenderGroup';
import { RenderList } from './RenderList';
import { parseRecordType } from './parseRecordType';
import { Record } from '../../../types/forms';
import {
  notEditableSectionStatuses,
  notEditableStatuses,
  notEditableStatusesForForms,
} from '../../../constants/applicationStatusOptions';

import './DynamicForms.scss';

const DynamicForms = () => {
  const navigate = useNavigate();

  const { clientId, applicationId, sectionId, partition } = useParams<{
    applicationId: string;
    sectionId: string;
    partition: string;
    clientId: string;
  }>();

  const applicationUrl = `/application-management/${clientId}/applications/${applicationId}/app`;

  const [isSavingForms, setIsSavingForms] = useState<boolean>(false);
  const [savingComplexFieldId, setSavingComplexFieldId] = useState<string>('');

  const [updateFormStatus] = useUpdateApplicationFormStatusMutation();

  const { mainStatus, isLoadingMainStatus } = useGetApplicationStatesQuery(applicationId, {
    selectFromResult: ({ data, isLoading }) => ({
      mainStatus: data?.currentState,
      isLoadingMainStatus: isLoading,
    }),
  });
  const isOpenPayd = checkProductName(['OPENPAYD']);
  const disabledByMainStatus =
    isOpenPayd && (notEditableStatuses.some((el) => el === mainStatus) || isLoadingMainStatus);
  const disabledByMainStatusForForms =
    isOpenPayd &&
    (notEditableStatusesForForms.some((el) => el === mainStatus) || isLoadingMainStatus);

  const { data: formSections, isLoading: isLoadingSections } = useGetAppFormSectionsQuery({
    applicationId,
  });
  const currentSection = formSections?.find(
    (s) => s.sectionId === Number(sectionId) && s.partition === partition,
  );
  const isDisabledBySectionStatus = currentSection
    ? notEditableSectionStatuses.includes(currentSection?.status)
    : true;

  const { data: applicationForms, isLoading: isLoadingForms, isFetching: isFetchingForms } = useGetApplicationFormsQuery(
    { applicationId, sectionId: currentSection?.sectionId, partition },
    {
      skip: isSavingForms || isLoadingSections,
    },
  );

  // #region Add complex type
  const { data: templateFields, isLoading: isLoadingTemplateFields } =
    useGetFormTemplateFieldsQuery(undefined);
  const [addFormRecords] = useAddApplicationFormRecordsMutation();

  const handleAddComplexType = (fieldName, listId, formId) => {
    // Auto add complex type
    if (!isLoadingTemplateFields && templateFields) {
      setIsSavingForms(true);
      setSavingComplexFieldId(listId);

      // Add complex type to list
      addFormRecords({ applicationId, formId, listId })
        .unwrap()
        .catch((rejected) => console.error(rejected))
        .finally(() => {
          setIsSavingForms(false);
          setSavingComplexFieldId('');
        });
    }
  };
  // #endregion

  // #region Delete complex type
  const [formId, setFormId] = useState<null | string>(null);
  const [deleteFormRecord] = useDeleteApplicationFormRecordMutation();
  const [formRecordIdToDelete, setFormRecordIdToDelete] = useState<string>();

  const handleDeleteFormRecord = (id: string, formIdToSet: string) => {
    setFormRecordIdToDelete(id);
    setFormId(formIdToSet);
  };

  const confirmDeleteFormRecord = () => {
    setIsSavingForms(true);

    deleteFormRecord({ applicationId, formId, id: formRecordIdToDelete })
      .unwrap()
      .then(() => {
        setFormRecordIdToDelete(undefined);
        setFormId(null);
        toastDeleteSuccess('Field(s)');
      })
      .catch((rejected) => console.error(rejected))
      .finally(() => {
        setIsSavingForms(false);
      });
  };
  // #endregion

  const formRecordsSort = (a: Record, b: Record) =>
    a.row * 1000 + a.col > b.row * 1000 + b.col ? 1 : -1;

  const sections =
    !isLoadingForms && applicationForms
      ? applicationForms.slice().sort((a, b) => (a.formOrder > b.formOrder ? 1 : -1))
      : [];

  const skipUpdateStatuses = ['NOT_REQUIRED', 'VERIFIED', 'SIGNED', 'PENDING_SIGNATURE'];
  const skipFieldTypes = ['description', 'subtitle', 'note']; // BE has validation definitions on some fields probably by mistake

  const isEditDisabled =
    disabledByMainStatusForForms || disabledByMainStatus || isDisabledBySectionStatus;
  const isInternalForm =
    !isLoadingForms && Object.values(sections).every((section) => section.isInternal);

  const handleIndexChange = (prevIndex) => updateSectionStatus(prevIndex);

  const updateSectionStatus = (sectionIndex, onComplete = () => { }) => {
    const section = sections[sectionIndex];

    if (section && (section.isInternal || !isEditDisabled)) {
      // Update only for internal forms
      if (!isLoadingForms && section && section.isInternal) {
        const { id, status: oldStatus, records } = section;

        let status;
        const hasAnyField = records.filter(({ isContainer }) => !isContainer).length > 0;
        let hasAnyValue = false;
        let isValid = true;
        const hasError = false;

        records.map((record) => {
          const { field, value, validations } = record;

          if (skipFieldTypes.includes(field?.type)) {
            return;
          }

          const trimmedValue = value?.trim();

          if (trimmedValue) {
            hasAnyValue = true;
          }

          // Check if required field has value
          const isRequired = validations?.find(
            (validation) => validation.fieldValidation.name === 'required',
          );
          if (isRequired && !trimmedValue) {
            isValid = false;
          }
        });

        if (!hasAnyValue && hasAnyField) {
          status = 'PENDING';
        } else if (isValid) {
          status = 'DONE';
        } else if (hasError) {
          status = 'ERRORS';
        } else {
          status = 'IN_PROGRESS';
        }

        if (status && oldStatus != status && !skipUpdateStatuses.includes(status)) {
          updateFormStatus({ applicationId, id, status })
            .unwrap()
            .then((result) => {
              // Update sections manually because fetching forms might be delayed
              sections.splice(sectionIndex, 1, result);
              onComplete();
            });
        } else {
          onComplete();
        }
      }
    }
  };

  const [updateFormSectionStatus] = useUpdateAppFormSectionStatusMutation();
  const handleSubmit = () => {
    updateSectionStatus(sections.length - 1, () => {
      // Mark internal form as DONE if all sub-sections are DONE
      const incompleteSections = sections.filter((s) => s.status !== 'DONE');
      if (
        incompleteSections.length === 0 &&
        currentSection.sectionDetails.isInternal &&
        currentSection.status == 'PENDING'
      ) {
        updateFormSectionStatus({
          applicationId,
          sectionId: Number(sectionId),
          partition,
          status: 'DONE',
        });
      }

      toastSuccess(`${currentSection?.sectionDetails?.title} saved`);
      navigate(applicationUrl);
    });
  };

  // #region Download Forms as PDF
  const [isDownloadingForms, setIsDownloadingForms] = useState<boolean>(false);
  const downloadFormsAsPdf = () => {
    setIsDownloadingForms(true);

    openFileInNewTab(
      `/api/gateway/applications/${applicationId}/forms/reports?sectionId=${sectionId}&partition=${partition}`,
    )
      .catch((reject) => {
        console.error(reject);
      })
      .finally(() => {
        setIsDownloadingForms(false);
      });
  };
  // #endregion

  return (
    <div className="DynamicForms">
      <section style={{ marginBottom: '2em' }}>
        <SectionHeading
          title={currentSection?.sectionDetails?.title}
          description={currentSection?.sectionDetails?.description}
          actionButtons={
            <div>
              <Button
                color="primary"
                variant="text"
                startIcon={
                  isDownloadingForms ? (
                    <CircularProgress color="inherit" size={16} sx={{ mr: 1 }} />
                  ) : (
                    <DownloadIcon />
                  )
                }
                onClick={downloadFormsAsPdf}
                disabled={isDownloadingForms}
              >
                Download PDF
              </Button>
            </div>
          }
        />
        <Divider />

        {isLoadingForms && (
          <div className="app-details-preloader app-details-preloader--small">
            <Spinner />
          </div>
        )}

        {!isLoadingForms && (
          <SectionContainer
            hasPrevNext={!isLoadingForms}
            onIndexChange={handleIndexChange}
            hasSubmit={isInternalForm && !isEditDisabled}
            onSubmit={handleSubmit}
            isSectionProcessing={isLoadingForms || isFetchingForms || isSavingForms}
          >
            {sections.map((form) => (
              <Section key={`Section_${form.id}`} title={form.title}>
                {form.records.length > 0 &&
                  form.records
                    .slice()
                    .sort(formRecordsSort)
                    .map((record) => {
                      const { id, value, type: recordType, parent, row, col } = record;

                      const { title: fieldTitle, type: fieldType } = record.field;

                      const hasParent = !!parent;
                      if (hasParent) {
                        return null;
                      }
                      const parsedRecordType = parseRecordType(recordType);

                      const childRecords = form.records
                        .slice()
                        .sort(formRecordsSort)
                        .filter((ct) => ct.parent === id);

                      switch (parsedRecordType) {
                        case 'primitive':
                          return (
                            <DetailsField
                              id={id}
                              applicationId={applicationId}
                              key={`${id}_${row}_${col}`}
                              fieldType={fieldType}
                              label={fieldTitle}
                              value={value}
                              formId={form.id}
                              record={record}
                              updateOnChange
                              disabled={!form.isInternal && isEditDisabled}
                              onBeforeSave={() => setIsSavingForms(true)}
                              onAfterSave={() => setIsSavingForms(false)}
                            />
                          );

                        case 'key-value':
                          return (
                            <RenderKeyValue
                              key={`${id}_${col}`}
                              childRecords={childRecords}
                              applicationId={applicationId}
                              formId={form.id}
                              handleDeleteFormRecord={handleDeleteFormRecord}
                              disabled={isSavingForms || (!form.isInternal && isEditDisabled)}
                            />
                          );

                        case 'group':
                          return (
                            <RenderGroup
                              key={`${id}_${col}`}
                              childRecords={childRecords}
                              formId={form.id}
                              disabled={isSavingForms || (!form.isInternal && isEditDisabled)}
                            />
                          );

                        case 'list':
                          return (
                            <RenderList
                              key={`${id}_${row}_${col}`}
                              childRecords={childRecords}
                              isSavingForms={isSavingForms}
                              formRecords={form.records}
                              record={record}
                              handleDeleteFormRecord={handleDeleteFormRecord}
                              formId={form.id}
                              savingComplexFieldId={savingComplexFieldId}
                              handleAddComplexType={handleAddComplexType}
                              disabled={!form.isInternal && isEditDisabled}
                            />
                          );

                        default:
                          console.error(`Undefined record type for form "${form.title}": `, record);
                          return null;
                      }
                    })}
              </Section>
            ))}
          </SectionContainer>
        )}

        <ConfirmDialog
          showDialog={!!formRecordIdToDelete}
          setShowDialog={setFormRecordIdToDelete}
          onCancel={() => setFormRecordIdToDelete(undefined)}
          onConfirm={confirmDeleteFormRecord}
          title="Deleting Field Group"
          text="Are you sure you want to permanently remove this field group?"
          confirmButtonText="Delete"
          confirmButtonColor="defaultDanger"
        />
      </section>
    </div>
  );
};

export { DynamicForms };
