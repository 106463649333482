import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setNewApplicationForms } from 'services/featureFlagsSlice';
import { RootState } from 'store';

export const EnableNewFormsSwitch = (): JSX.Element => {
  const dispatch = useDispatch();
  const { newApplicationForms } = useSelector((state: RootState) => state.featureFlags);

  const handleChange = (e) => {
    dispatch(setNewApplicationForms({ enable: e.currentTarget.checked }));
  };
  return (
    <FormControlLabel
      control={<Switch disabled checked={newApplicationForms} onChange={handleChange} />}
      label="Try out new forms"
    />
  );
};
