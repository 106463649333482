import moment from 'moment';
import dateTimeFields from '../constants/dateTimeLookUpTable';
import { DATETIMESEC_FORMAT_MOMENT } from '../constants/constants';

let items = [];
const parentNodeFunc = (parentNode, i) => `${parentNode} ${i}`;

export const resetItems = () => {
  items = [];
};

export const iterator = (obj, parentNode = '') => {
  let obj1 = {};
  if (obj != null) {
    obj1 = obj;
  }

  Object.keys(obj1).map((i) => {
    if (Object.prototype.hasOwnProperty.call(obj, i) && isNaN(i)) {
      if (typeof obj[i] === 'object') {
        iterator(obj[i], parentNodeFunc(parentNode, i));
        if (Array.isArray(obj[i])) {
          items.push({ key: parentNodeFunc(parentNode, i), value: obj[i] });
        }
      } else {
        const key = parentNodeFunc(parentNode, i);
        if (key !== ' enrichmentData binInfo source') {
          if (dateTimeFields.indexOf(key) > -1) {
            items.push({
              key,
              value: moment(obj[i]).format(DATETIMESEC_FORMAT_MOMENT),
            });
          } else {
            items.push({ key, value: obj[i] });
          }
        }
      }
    }
    return false;
  });
  return items;
};
