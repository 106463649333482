import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

const getColor = (error, disabled) => {
  if (error) {
    return 'error.light';
  }
  if (disabled) {
    return 'grey.50';
  }
  return 'grey.100';
};

interface InputWrapperStyledProps extends BoxProps {
  error?: boolean;
  disabled?: boolean;
}

export const InputWrapper = styled(Box)<InputWrapperStyledProps>(
  ({ error = false, disabled = false, theme }) =>
    theme.unstable_sx({
      bgcolor: getColor(error, disabled),
      borderRadius: 2,
      border: 1,
      borderColor: getColor(error, disabled),
      transition: theme.transitions.create(['border-color', 'background-color']),
      '& #tag': {
        bgcolor: 'white',
        cursor: disabled ? 'inherit' : 'pointer',
        '&:hover': {
          bgcolor: disabled ? 'white' : 'grey.300',
          '& svg': {
            transition: theme.transitions.create(['border-color', 'background-color', 'color']),
            color: disabled ? 'grey.300' : 'grey.700',
          },
        },
      },
      ': focus-within': {
        borderColor: error ? 'error.main' : 'grey.900',
        bgcolor: error ? 'error' : 'white',
        '& #tag': {
          bgcolor: 'grey.50',
          '&:hover': {
            bgcolor: 'grey.200',
          },
        },
      },
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      px: 3,
      pt: '9px',
      pb: '8px',
    }),
);
