import React from 'react';
import { Box, Button } from '@mui/material';

interface EndListButtonProps {
  isVisible: boolean;
  isFetching: boolean;
  isEndReached: boolean; // loaded elements count === total elements count
  nextPage?: () => void; // handler for case 'load more..'
}

export const EndListButton: React.FC<EndListButtonProps> = ({
  isVisible,
  isFetching,
  isEndReached,
  nextPage,
}) => (
  <>
    {isVisible && (
      <Box sx={(theme) => ({ p: '1rem 0', borderTop: `1px solid ${theme.palette.grey[100]}` })}>
        <Button
          variant="text"
          color="base"
          sx={{ width: 1 }}
          disabled={isFetching || isEndReached}
          onClick={nextPage}
        >
          {isFetching
            ? 'Loading...'
            : isEndReached
            ? 'You have reached the end of the list'
            : 'Load more'}
        </Button>
      </Box>
    )}
  </>
);
