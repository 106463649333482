import { removeEmptyProperties } from 'utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const applicationFormRecordsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getApplicationFormRecords: build.query({
      query: ({ applicationId, formId }) => ({
        url: `applications/${applicationId}/forms/${formId}/records`,
        method: 'GET',
      }),
      providesTags: (result, error, { applicationId, formId }) => [
        { type: 'ApplicationFormRecords', id: `${applicationId}_${formId}` },
      ],
    }),
    getApplicationFormRecord: build.query({
      query: ({ applicationId, formId, id }) => ({
        url: `applications/${applicationId}/forms/${formId}/records/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'ApplicationFormRecord', id }],
    }),
    addApplicationFormRecords: build.mutation({
      query({ applicationId, formId, listId }) {
        return {
          url: `applications/${applicationId}/forms/${formId}/records/lists/${listId}/items`,
          method: 'POST',
          body: [],
        };
      },
      invalidatesTags: (result, error, { applicationId, formId }) => [
        { type: 'ApplicationForms', id: applicationId },
        { type: 'ApplicationForm', id: formId },
        { type: 'ApplicationFormRecords', id: `${applicationId}_${formId}` },
      ],
    }),
    updateApplicationFormRecord: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        delete body.applicationId;
        removeEmptyProperties(body);
        return {
          url: `applications/${model.applicationId}/forms/${model.formId}/records/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { applicationId, applicationFormId, id }) => [
        { type: 'ApplicationForms', id: applicationId },
        { type: 'ApplicationForm', id: applicationFormId },
        {
          type: 'ApplicationFormRecords',
          id: `${applicationId}_${applicationFormId}`,
        },
        { type: 'ApplicationFormRecord', id },
      ],
    }),
    deleteApplicationFormRecord: build.mutation({
      query: ({ applicationId, formId, id }) => ({
        url: `applications/${applicationId}/forms/${formId}/records/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { applicationId, formId, id }) => [
        { type: 'ApplicationForms', id: applicationId },
        { type: 'ApplicationForm', id: formId },
        { type: 'ApplicationFormRecords', id: `${applicationId}_${formId}` },
        { type: 'ApplicationFormRecord', id },
      ],
    }),
  }),
});

export const {
  useGetApplicationFormRecordsQuery,
  useGetApplicationFormRecordQuery,
  useAddApplicationFormRecordsMutation,
  useUpdateApplicationFormRecordMutation,
  useDeleteApplicationFormRecordMutation,
} = applicationFormRecordsApi;
