import React from 'react';
import { Box } from '@mui/material';
import { TagSelected } from './TagSelected';
import { ValueLabel } from '../../../types';

interface TagsWrapperProps {
  values: ValueLabel[];
  disabled: boolean;
  getTagProps: ({ index }: { index: number }) => {
    key: number;
    'data-tag-index': number;
    tabIndex: -1;
    onDelete: (event: any) => void;
  };
}

export const TagsWrapper: React.FC<TagsWrapperProps> = ({ values, getTagProps, disabled }) => (
  <>
    {values?.length > 0 && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {values.map((option, index) => (
          <TagSelected
            key={index}
            onDelete={getTagProps({ index }).onDelete}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </Box>
    )}
  </>
);
