import { createEntityAdapter } from '@reduxjs/toolkit';
import { gatewayApi } from '../gatewayApi';
import { combineDateTime, removeEmptyProperties, transformDelete } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { riskStatuses } from '../../features/countryRisk/constants';

const countriesAdapter = createEntityAdapter({
  selectId: (country) => country.countryCode,
});

const transformCountryHistory = (history, type) =>
  history.map((h) => {
    const copy = { ...h };
    const result =
      copy.revision?.revisionType === 'DELETE'
        ? transformDelete(copy, formatDate)
        : combineDateTime(copy, formatDate);
    result.riskStatus =
      copy.revision?.revisionType !== 'DELETE'
        ? riskStatuses.byId[copy.riskStatus].label
        : undefined;
    result.type = type;
    return result;
  });

interface CountryType {
  country3Code: string;
  countryCode: string;
  name: string;
  numericCode: string;
}

const countryRiskApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getAllCountries: build.query<CountryType[], undefined>({
      query: () => `countries`,
      providesTags: () => [{ type: 'Countries', id: 'LIST' }],
    }),

    getAllRiskCountries: build.query({
      query: () => `risk-countries`,
      providesTags: (result, error, countryCode) => [{ type: 'RiskCountries', countryCode }],
      transformResponse: (countries) =>
        countriesAdapter.setAll(countriesAdapter.getInitialState(), countries),
    }),
    updateRiskCountry: build.mutation({
      query(country) {
        const body = { ...country };
        removeEmptyProperties(body);
        return {
          url: `risk-countries/${body.countryCode}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { countryCode }) => [
        { type: 'RiskCountries', countryCode },
        { type: 'RiskCountryHistory', countryCode },
      ],
    }),
    getRiskCountryHistory: build.query({
      query: (countryCode) => `risk-countries/history/${countryCode}`,
      providesTags: (result, error, countryCode) => [{ type: 'RiskCountryHistory', countryCode }],
      transformResponse: (history) => transformCountryHistory(history, 'Country Risk'),
    }),

    getAllEeaRiskCountries: build.query({
      query: () => `eea-risk-countries`,
      providesTags: () => [{ type: 'EeaRiskCountries', id: 'LIST' }],
      transformResponse: (countries) =>
        countriesAdapter.setAll(countriesAdapter.getInitialState(), countries),
    }),
    updateEeaRiskCountry: build.mutation({
      query(country) {
        const body = { ...country };
        removeEmptyProperties(body);
        return {
          url: `eea-risk-countries/${body.countryCode}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { countryCode }) => [
        { type: 'EeaRiskCountries', countryCode },
        { type: 'EeaRiskCountryHistory', countryCode },
      ],
    }),
    getEeaRiskCountryHistory: build.query({
      query: (countryCode) => `eea-risk-countries/history/${countryCode}`,
      providesTags: (result, error, countryCode) => [
        { type: 'EeaRiskCountryHistory', countryCode },
      ],
      transformResponse: (history) => transformCountryHistory(history, 'EEA/UK Risk'),
    }),
  }),
});

export const {
  useGetAllCountriesQuery,

  useGetAllRiskCountriesQuery,
  useUpdateRiskCountryMutation,
  useGetRiskCountryHistoryQuery,

  useGetAllEeaRiskCountriesQuery,
  useUpdateEeaRiskCountryMutation,
  useGetEeaRiskCountryHistoryQuery,
} = countryRiskApi;
