import { alpha } from '@mui/material/styles';
import { successLight } from '../../palette-blocks';
import { buttonFocus } from '../mixins';

export const SuccessLight = {
  props: {
    color: 'successLight',
  },
  style: {
    color: successLight.contrastText,
    '&:hover': {
      background: successLight.hover,
    },
    '&:active': {
      background: successLight.active,
    },
    '&:disabled': {
      background: alpha(successLight.main, 0.5),
      color: alpha(successLight.contrastText, 0.5),
      // opacity: palette.action?.disabledOpacity,
    },
    '&:focus&:not(:active)': buttonFocus(successLight.main),
  },
};

export const SuccessLightText = {
  props: {
    color: 'successLight',
    variant: 'text',
  },
  style: {
    borderColor: 'transparent',
    background: 'transparent',
    '&:hover': {
      background: successLight.hover,
    },
    '&:active': {
      background: successLight.active,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};

export const SuccessLightOutlined = {
  props: {
    color: 'successLight',
    variant: 'outlined',
  },
  style: {
    borderColor: successLight['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: successLight.hover,
      borderColor: successLight['outline-100'],
    },
    '&:active': {
      background: successLight.active,
      borderColor: successLight['outline-100'],
    },
    '&:disabled': {
      borderColor: alpha(successLight['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};
