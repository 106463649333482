export const initialFormData = {
  cardAction: 'BLOCK',
};

export const cardActions = [
  {
    value: 'BLOCK',
    label: 'Block',
    color: 'error',
  },
  {
    value: 'REFRESH',
    label: 'Refresh',
    color: 'warning',
  },
  {
    value: 'ALWAYS_ALLOW',
    label: 'Always Allow',
    color: 'success',
  },
];
