import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { EndListButton, EmptyPage } from 'uikit';
import {
  useGetSearchSessionQuery,
  useGetTransactionSearchSessionQuery,
  useMakeDecisionMutation,
  useTransactionMakeDecisionMutation,
  useTransactionWhitelistingMutation,
} from 'services/gatewayApi/pepAndSanctionsApi';
import {
  useAddToWhitelistMutation,
  useGetWhitelistQuery,
  useRemoveFromWhitelistMutation,
} from 'services/gatewayApi/pepAndSanctionsWhitelistApi';
import { paramsScheme } from '../constants';
import { SearchParamsBox } from './SearchParamsBox';
import { SearchSessionList } from './SearchSessionList';
import { SearchHeader } from './SearchHeader';
import { makeTitle } from './makeTitle';
import { NotAllShown } from './NotAllShown';
import { LoadingBox } from './LoadingBox';
import { Entities, PepDecision, SanctionsRecord, SearchRequest, SearchType } from '../types';
import 'styles/tables.scss';

export const getType = (entities: Entities | Entities[]): SearchType => {
  if (!entities) return undefined;
  const isTypeIndividual = ['M', 'F'].some((el: Entities) => entities?.includes(el));
  const isTypeBusiness = ['E', 'U'].some((el: Entities) => entities?.includes(el));
  if (!isTypeIndividual && !isTypeBusiness) return 'unknown';
  return isTypeIndividual ? 'individual' : 'business';
};

export const SearchSession: React.FC = () => {
  const navigate = useNavigate();
  const { applicationId, searchSessionId } = useParams<{
    applicationId: string;
    searchSessionId: string;
  }>();
  const location = useLocation();
  const isTransactions = location.pathname.split('/').some((el) => el === 'transactions');
  const isNewSearch = searchSessionId === 'new-search';

  const [searchType, setSearchType] = useState<SearchType>(
    useLocation().state?.searchType || 'noType',
  );
  const [searchParams, setSearchParams] = useState<SearchRequest | undefined>(undefined);
  const [historySearchResult, setHistorySearchResult] = useState<SanctionsRecord[] | undefined>(
    undefined,
  );
  const [selectedResultId, setSelectedResultId] = useState<string | undefined>(undefined);
  const [resultPerformedOn, setPerformedOn] = useState<string | undefined>(undefined);
  const [searchName, setSearchName] = useSearchParams();

  const [makeDecision] = useMakeDecisionMutation();
  const [addToWhitelist] = useAddToWhitelistMutation();
  const [removeFromWhitelist] = useRemoveFromWhitelistMutation();
  const [makeDecisionTransaction] = useTransactionMakeDecisionMutation();
  const [transactionWhitelisting, { isLoading: isLoadingTWL }] =
    useTransactionWhitelistingMutation();

  const {
    searchResponse,
    searchRequest,
    decision,
    performedOn,
    searchRequestType,
    isLoadingSearchResult,
    isFetchingSearchResult,
    isWhitelisted,
  } = useGetSearchSessionQuery(
    { applicationId, searchId: searchSessionId },
    {
      skip: isNewSearch || isTransactions,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        searchResponse: data?.searchResponse,
        searchRequest: data?.searchRequest,
        searchRequestType: getType(data?.searchRequest?.entities),
        searchStatus: data?.status,
        decision: data?.decision,
        performedOn: data?.performedOn,
        type: data?.type,
        isWhitelisted: data?.whitelisted,
        searchReasonToPerform: data?.reasonToPerform,
        isLoadingSearchResult: isLoading,
        isFetchingSearchResult: isFetching,
      }),
    },
  );

  const {
    data: transactionsSession,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useGetTransactionSearchSessionQuery({ searchId: searchSessionId }, { skip: !isTransactions });

  const { searchRequest: searchRequestTransactions, searchResponse: searchResponseTransactions } =
    transactionsSession || {};
  const { fullName } = searchRequestTransactions || {};

  const { whitelist, isLoadingWhitelist, isFetchingWhitelist } = useGetWhitelistQuery(undefined, {
    skip: isNewSearch || !searchResponse || searchResponse.length === 0 || isTransactions,
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      whitelist: searchResponse
        ?.filter((pep) => data?.content.some((el) => pep.uid === el.uid))
        ?.map((el) => el.uid),
      isLoadingWhitelist: isLoading,
      isFetchingWhitelist: isFetching,
    }),
  });
  const isLoadingWL = isLoadingWhitelist || isFetchingWhitelist;

  const searchResult = searchResponse?.map((record) => {
    const whitelistedRecord = whitelist?.some((element) => element === record.uid);
    return { ...record, whitelistedRecord };
  });

  const isShowSearchBox =
    !['noType', 'unknown', undefined].includes(searchType) &&
    !isLoadingSearchResult &&
    !isFetchingSearchResult;

  // go back in case when searchType not selected for new search
  useEffect(() => {
    if (searchType === 'noType' && isNewSearch) {
      applicationId
        ? navigate(`/application-management/${applicationId}/pep-and-sanctions`)
        : navigate(`/pep-and-sanctions`);
    }
  }, [searchType]);

  useEffect(() => {
    if (isTransactions && transactionsSession) {
      setSearchName({
        applicationName: searchName.get('applicationName'),
        searchSessionName: transactionsSession.searchRequest.fullName,
      });
    }
    if (!isNewSearch && searchResult) {
      const searchRequestCopy = { ...searchRequest };
      const { entities } = searchRequest;
      delete searchRequestCopy.entities;
      setSearchType(searchRequestType);
      setSearchParams(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof entities === 'string' || entities?.length === 1
          ? { ...searchRequest, entities: searchRequest.entities[0] }
          : searchRequestCopy,
      );
      setSearchName({
        applicationName: searchName.get('applicationName'),
        searchSessionName: makeTitle({
          firstName: searchRequest?.firstName,
          lastName: searchRequest?.lastName,
        }),
      });
    }
  }, [searchResponse, isLoadingSearchResult, isFetchingSearchResult, transactionsSession]);

  const handleBatchWhitelistening = () => {
    if (!isWhitelisted) {
      const entries = searchResponse?.map((el) => ({
        uid: el.uid,
        lastName: el.lastName,
        firstName: el.firstName,
      }));
      addToWhitelist({ body: { searchId: searchSessionId, entries }, applicationId });
    }
    if (isWhitelisted) {
      removeFromWhitelist({ searchId: searchSessionId, applicationId });
    }
  };

  const handleMakeDecision = (handledDecision: PepDecision) => {
    isTransactions
      ? makeDecisionTransaction({ searchId: searchSessionId, decision: handledDecision })
      : makeDecision({ applicationId, searchId: searchSessionId, decision: handledDecision });
  };

  const handleTransactionWhitelistening = async () => {
    const allChecked = searchResponseTransactions?.every((el) => el.whitelisted === true);
    const allUids = searchResponseTransactions?.map((el) => el.uid);
    const body = {
      whitelisted: !allChecked,
      uid: allUids,
    };
    await transactionWhitelisting({ searchId: searchSessionId, body });
  };

  const handleWhitelist = async ({ uid, whitelisted }) => {
    await transactionWhitelisting({
      searchId: searchSessionId,
      body: { whitelisted, uid: [uid] },
    });
  };

  const getTitle = () => {
    if (isTransactions && transactionsSession) return fullName;
    return makeTitle({
      firstName: searchRequest?.firstName,
      lastName: searchRequest?.lastName,
    });
  };

  const getTransactionsWhitelisted = () => {
    const allChecked = searchResponseTransactions?.every((el) => el.whitelisted === true);
    const allUnchecked = searchResponseTransactions?.every((el) => el.whitelisted === false);
    if (allChecked === false && allUnchecked === false) return [false, true];
    return allChecked ? [true, true] : [false, false];
  };

  return (
    <>
      <Helmet title="PEP and sanctions - search session" />
      <div style={{ paddingTop: 20 }}>
        {!isNewSearch && (
          <SearchHeader
            isTransactions={isTransactions}
            isLoadingWL={isLoadingWL || isLoadingTWL}
            isWhitelisted={isTransactions ? getTransactionsWhitelisted() : isWhitelisted}
            handleWhitelist={
              isTransactions ? handleTransactionWhitelistening : handleBatchWhitelistening
            }
            title={getTitle()}
            dateTime={
              isTransactions ? transactionsSession?.performedOn : resultPerformedOn ?? performedOn
            }
            handleAction={handleMakeDecision}
            currentDecision={isTransactions ? transactionsSession?.decision : decision}
            setHistorySearchResult={setHistorySearchResult}
            selectedResultId={selectedResultId}
            setSelectedResultId={setSelectedResultId}
            setSearchParams={setSearchParams}
            setPerformedOn={setPerformedOn}
          />
        )}

        {!isTransactions &&
          (isShowSearchBox ? (
            <SearchParamsBox
              setSelectedResultId={setSelectedResultId}
              setHistorySearchResult={setHistorySearchResult}
              searchType={searchType}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setPerformedOn={setPerformedOn}
              paramsScheme={paramsScheme.filter(
                (el) => el.searchType === searchType || el.searchType === 'any',
              )}
            />
          ) : (
            <LoadingBox />
          ))}

        {searchResult?.length === 500 && <NotAllShown />}

        {!isTransactions &&
          (historySearchResult?.length > 0 || searchResult?.length > 0 ? (
            <div className="table-wrapper">
              <SearchSessionList
                searchResult={historySearchResult || searchResult}
                disableAction={!!historySearchResult}
              />
              <EndListButton
                isVisible={
                  isTransactions ? searchResponseTransactions?.length > 0 : searchResult?.length > 0
                }
                isFetching={isFetchingSearchResult || isFetchingTransactions || isLoadingTWL}
                isEndReached
              />
            </div>
          ) : (
            <EmptyPage
              isLoading={isLoadingSearchResult || isFetchingSearchResult}
              title={searchResult?.length === 0 ? 'No results' : "You haven't done a search yet."}
              subtitle={
                searchResult?.length === 0
                  ? 'Try another search'
                  : 'The search data will appear when the search is applied.'
              }
            />
          ))}

        {isTransactions && (
          <div className="table-wrapper">
            <SearchSessionList
              searchResult={searchResponseTransactions}
              isTransactions={isTransactions}
              handleWhitelist={handleWhitelist as any}
              isLoading={isLoadingTWL || isLoadingTransactions}
            />
            <EndListButton
              isVisible={
                isTransactions ? searchResponseTransactions?.length > 0 : searchResult?.length > 0
              }
              isFetching={isFetchingSearchResult || isFetchingTransactions || isLoadingTWL}
              isEndReached
            />
          </div>
        )}
      </div>
    </>
  );
};
