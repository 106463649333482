import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import SortableTree from 'react-sortable-tree';
import Select from 'react-select';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { v4 as uuidV4 } from 'uuid';
import { makeSelectedProductIdData, makeUserData } from '../../selectors/user';
import {
  addNewRuleToRuleSet,
  changeNewRuleFormCloseAttempt,
  changeRuleSetData,
  deleteRuleFromRuleSet,
  emptyRuleSetAndRules,
  fetchFunctions,
  saveRuleSet,
  selectRuleSet,
  unselectRule,
  updateRuleSet,
} from '../../actions/ruleBuilder';
import { fetchRules } from '../../actions/rules';
import { makeRulesData, makeSelectLoadingData } from '../../selectors/rules';
import isFormValid from '../../utils/isFormValid';
import { prepareRuleSetForApi } from '../../utils/prepareRuleSet';
import { Spinner } from '../../components';
import MyRenderer from './myRenderer';
import {
  makeFunctionListData,
  makeNewRuleFormValidData,
  makeRuleSetData,
  makeSavingData,
  makeSelectedRuleData,
} from '../../selectors/ruleBuilder';
import RuleForm from '../../features/ruleForm/ruleForm';
import { TresholdForm } from './TresholdForm';
import { WithPathIdHOC } from './WithPathIdHOC';
import { WithNavigateHOC } from '../../utils/WithNavigateHOC';

const newRuleSet = {
  prepared: true,
  fromApi: false,
  name: 'Name of Ruleset',
  description: 'This is description',
  active: false,
  returnOnReject: false,
  status: 'TEST',
  rules: [
    {
      answer: 'LOWRISK',
      description: 'This is description',
      script: '1==1',
      title: 'This is name',
      id: uuidV4(),
      children: [],
      expanded: true,
      categoryType: 'RISK',
      newRule: false,
      root: true,
      deleteId: true,
      seonRule: false,
    },
  ],
};

export class RuleBuilder extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.handleAddNodeToRoot = this.handleAddNodeToRoot.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.state = {
      errors: {},
      saveAttempt: false,
    };
  }

  componentWillMount() {
    this.props.emptyRuleSetAndRules();
    if (this.props.pathId !== 'new') {
      this.props.fetchRules();
    } else {
      this.props.selectRuleSet(newRuleSet);
    }
    this.props.fetchFunctions();
    Modal.setAppElement('#app');
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pathId !== 'new' && nextProps.rules.length && !this.props.ruleSet.fromApi) {
      const ruleSet = this.getRuleSetFromList(nextProps.rules, this.props.pathId);
      this.props.selectRuleSet(ruleSet);
    }
    if (this.props.pathId !== nextProps.pathId) {
      let ruleSet = {};
      if (nextProps.pathId === 'new') {
        ruleSet = newRuleSet;
      } else {
        this.props.fetchRules();
        this.props.emptyRuleSetAndRules();
        return;
      }
      this.props.selectRuleSet(ruleSet);
    }
  }

  getRuleSetFromList(list, id) {
    return list.find((i) => i.id === Number(id));
  }

  getStatuses() {
    return [
      { value: 'PROD', label: 'PROD' },
      { value: 'TEST', label: 'TEST' },
    ];
  }

  getStatus = () => this.getStatuses().find((status) => status.value === this.props.ruleSet.status);

  getValidClassname(field) {
    return classNames({
      error: this.state.errors[field] && this.state.saveAttempt,
      'form-control': field !== 'status',
    });
  }

  handleSave() {
    const { ruleSet, selectedProductId, user } = this.props;

    this.setState({ saveAttempt: true });
    if (!isFormValid(this.state.errors)) return;

    const selectedProduct = user.tenants.find((i) => i.id === parseInt(selectedProductId, 10));
    const postObject = prepareRuleSetForApi(ruleSet, selectedProduct);
    this.props.saveRuleSet(postObject);
  }

  handleInputChange(e, field) {
    const value = e.target ? e.target.value : e.value;

    if (field !== 'status') {
      const { errors } = this.state;
      // eslint-disable-next-line no-unused-expressions
      value.trim() && value.match(e.target.pattern) !== null
        ? delete errors[field]
        : (errors[field] = true);
      this.setState({
        errors,
      });
    }
    this.props.updateRuleSet(field, value);
  }

  handleCheckChange(e, field) {
    this.props.updateRuleSet(field, e.target.checked);
  }

  handleCloseModal() {
    this.props.changeNewRuleFormCloseAttempt(true);
    const { newRuleFormValid, selectedRule } = this.props;
    const { pathId } = this.props;
    if (!newRuleFormValid && !selectedRule.newRule) return;
    this.props.unselectRule();
    if (
      (pathId === 'new' && !selectedRule.root && selectedRule.newRule) ||
      (pathId !== 'new' && selectedRule.newRule)
    ) {
      this.props.deleteRuleFromRuleSet(selectedRule.id);
    }
  }

  handleAddNodeToRoot() {
    this.props.addNewRuleToRuleSet();
  }

  renderRuleSet() {
    if ((this.props.pathId !== 'new' && !this.props.rules.length) || this.props.loading) {
      return (
        <div className="spinner">
          <Spinner />
        </div>
      );
    }
    if (!this.props.ruleSet.rules) {
      return null;
    }
    const className = classNames('spinner inline', {
      hide: !this.props.saving,
    });
    return (
      <div>
        <div className="grid simple horizontal red">
          <div className="grid-title">
            <h4>
              <span>Rule Set</span>
            </h4>
          </div>
          <div className="grid-body">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="form-group">
                  <div className="checkbox check-warning">
                    <input
                      onChange={(e) => {
                        this.handleCheckChange(e, 'active');
                      }}
                      id="active"
                      type="checkbox"
                      checked={this.props.ruleSet.active ? this.props.ruleSet.active : false}
                    />
                    <label htmlFor="active">Active</label>
                    <input
                      onChange={(e) => {
                        this.handleCheckChange(e, 'returnOnReject');
                      }}
                      id="returnOnReject"
                      type="checkbox"
                      checked={
                        this.props.ruleSet.returnOnReject
                          ? this.props.ruleSet.returnOnReject
                          : false
                      }
                    />
                    <label htmlFor="returnOnReject">Return On Reject</label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="setName" className="form-label">
                    Name
                  </label>
                  <div className="controls">
                    <input
                      id="setName"
                      type="text"
                      pattern="^[a-zA-Z0-9 .-]+$"
                      className={this.getValidClassname('name')}
                      value={this.props.ruleSet.name}
                      onChange={(e) => {
                        this.handleInputChange(e, 'name');
                      }}
                      onBlur={(e) => {
                        this.handleInputChange(e, 'name');
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="setDescription" className="form-label">
                    Description
                  </label>
                  <div className="controls">
                    <input
                      id="setDescription"
                      type="text"
                      pattern="^[a-zA-Z0-9 .-]+$"
                      className={this.getValidClassname('description')}
                      value={this.props.ruleSet.description}
                      onChange={(e) => {
                        this.handleInputChange(e, 'description');
                      }}
                      onBlur={(e) => {
                        this.handleInputChange(e, 'description');
                      }}
                    />
                  </div>
                </div>
                {this.renderStatus()}
              </div>
              {this.props.pathId !== 'new' && (
                <div className="col-md-4 col-sm-12">
                  <TresholdForm
                    saveAttempt={this.state.saveAttempt}
                    onAfterSave={() => {
                      this.props.navigate('/rules');
                    }}
                  />
                </div>
              )}
              <hr className="bottom-spacing full-width" />
              <div className="col-md-8 col-sm-8 col-xs-8">
                <button
                  disabled={this.props.pathId === 'new'}
                  type="button"
                  onClick={this.handleAddNodeToRoot}
                  className="btn btn-success btn-cons addNodeToRoot"
                >
                  <i className="fa fa-plus-circle" />
                  Add rule to root
                </button>
                <div className="bottom-spacing">
                  {this.props.pathId === 'new' ? (
                    'Save rule set to start adding rules...'
                  ) : (
                    <SortableTree
                      treeData={this.props.ruleSet.rules}
                      onChange={(rules) => this.props.changeRuleSetData(rules)}
                      nodeContentRenderer={MyRenderer}
                      isVirtualized={false}
                    />
                  )}
                </div>
                <button
                  type="button"
                  disabled={this.props.saving}
                  onClick={() => {
                    this.handleSave();
                  }}
                  className="btn btn-primary btn-cons verticalAlignTop"
                >
                  Save
                </button>
                <div className={className}>
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModal() {
    if (this.props.selectedRule === null) {
      return null;
    }
    return (
      <Modal
        isOpen
        onRequestClose={this.handleCloseModal}
        contentLabel="Rule Definition"
        portalClassName="ruleBuilder"
      >
        <RuleForm
          rule={this.props.selectedRule}
          functionList={this.props.functionList}
          urlId={this.props.pathId}
          handleCloseModal={this.handleCloseModal}
        />
      </Modal>
    );
  }

  renderStatus() {
    if (!this.props.ruleSet.fromApi) {
      return null;
    }
    return (
      <div className="form-group">
        <label htmlFor="status" className="form-label">
          Environment
        </label>
        <div className="controls">
          <Select
            id="status"
            options={this.getStatuses()}
            value={this.getStatus()}
            onChange={(e) => {
              this.handleInputChange(e, 'status');
            }}
            className={this.getValidClassname('status')}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page-content rules-page">
        <Helmet title="Rule Definition" />
        <div className="content container container-OpenPayd" style={{ paddingTop: '1rem' }}>
          <div className="page-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h3>Rules</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">{this.renderRuleSet()}</div>
              <div>{this.renderModal()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RuleBuilder.propTypes = {
  ruleSet: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  changeRuleSetData: PropTypes.func,
  addNewRuleToRuleSet: PropTypes.func,
  selectedRule: PropTypes.object,
  unselectRule: PropTypes.func,
  fetchRules: PropTypes.func,
  rules: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectRuleSet: PropTypes.func,
  updateRuleSet: PropTypes.func,
  saveRuleSet: PropTypes.func,
  navigate: PropTypes.func,
  saving: PropTypes.bool,
  deleteRuleFromRuleSet: PropTypes.func,
  newRuleFormValid: PropTypes.bool,
  changeNewRuleFormCloseAttempt: PropTypes.func,
  loading: PropTypes.bool,
  emptyRuleSetAndRules: PropTypes.func,
  fetchFunctions: PropTypes.func,
  functionList: PropTypes.array,
  pathId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  ruleSet: makeRuleSetData(),
  selectedRule: makeSelectedRuleData(),
  rules: makeRulesData(),
  loading: makeSelectLoadingData(),
  saving: makeSavingData(),
  newRuleFormValid: makeNewRuleFormValidData(),
  functionList: makeFunctionListData(),
  selectedProductId: makeSelectedProductIdData(),
  user: makeUserData(),
});

export function mapDispatchToProps(dispatch) {
  return {
    changeRuleSetData: (data) => dispatch(changeRuleSetData(data)),
    addNewRuleToRuleSet: () => dispatch(addNewRuleToRuleSet()),
    unselectRule: () => dispatch(unselectRule()),
    fetchRules: () => dispatch(fetchRules()),
    selectRuleSet: (ruleSetId) => dispatch(selectRuleSet(ruleSetId)),
    updateRuleSet: (field, value) => dispatch(updateRuleSet(field, value)),
    saveRuleSet: (ruleSet) => dispatch(saveRuleSet(ruleSet)),
    deleteRuleFromRuleSet: (ruleId) => dispatch(deleteRuleFromRuleSet(ruleId)),
    changeNewRuleFormCloseAttempt: (bool) => dispatch(changeNewRuleFormCloseAttempt(bool)),
    emptyRuleSetAndRules: () => dispatch(emptyRuleSetAndRules()),
    fetchFunctions: () => dispatch(fetchFunctions()),
  };
}

// Wrap the component to inject dispatch and state into it
export default WithNavigateHOC(
  WithPathIdHOC(connect(mapStateToProps, mapDispatchToProps)(RuleBuilder)),
);
