import { Chip, ChipProps } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledChip = styled(Chip)(({ theme }) =>
  theme.unstable_sx({
    height: 28,
    p: 1,
    borderRadius: 1,
    justifyContent: 'space-between',
    '&:not(:disabled):focus': {
      boxShadow: 'none',
    },
    '& .MuiChip-label': {
      p: 0,
    },
    '& .MuiChip-deleteIcon': {
      fontSize: 20,
      m: 0,
    },
  }),
);

const MessageQuoteChip = ({
  deleteIcon = <CloseIcon />,
  color = 'secondary',
  ...props
}: ChipProps): JSX.Element => <StyledChip color={color} deleteIcon={deleteIcon} {...props} />;

export default MessageQuoteChip;
