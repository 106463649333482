export const initialFormData = {
  word: '',
  type: 'TERRORIST_FINANCING',
};

export const wordTypes = [
  {
    value: 'TERRORIST_FINANCING',
    label: 'Terrorist Financing',
  },
  {
    value: 'CHILD_EXPLOITATION',
    label: 'Child Exploitation',
  },
  {
    value: 'CRYPTO',
    label: 'Crypto',
  },
  {
    value: 'AML',
    label: 'AML',
  },
  {
    value: 'BINANCE',
    label: 'Binance',
  },
];
