import React from 'react';
import { parse, isBefore } from 'date-fns';
import { DATETIME_FORMAT } from 'constants/constants';
import { Table, TableBody, Typography } from '@mui/material';
import { BodyRow, BodyTableCell, Skeleton } from 'uikit';
import { useGetCaseCommentsQuery } from 'services/gatewayApi/casesApi';
import { Case } from '../../types';
import { AddCommentsForm } from '../../AddCommentsForm';
import CommentBox from './CommentBox';

import './CommentsTab.scss';

interface CommentsTabProps {
  caseData: Case;
  users: any;
}

export const CommentsTab: React.FC<CommentsTabProps> = ({ caseData, users }) => {
  const { caseId } = caseData;
  const { data: comments = [], isLoading, isFetching } = useGetCaseCommentsQuery(caseId);

  const sortMomentDateTime = (a, b) =>
    isBefore(
      parse(a.createdDate, DATETIME_FORMAT, new Date()),
      parse(b.createdDate, DATETIME_FORMAT, new Date()),
    )
      ? 1
      : -1;

  return (
    <div className="CommentsTab">
      <AddCommentsForm caseIds={[caseId]} showCompact />

      {(isLoading || isFetching) && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} sectionsCount={1} height={50} />
        </div>
      )}

      {!(isLoading || isFetching) && (
        <>
          {comments.length === 0 && (
            <Table>
              <TableBody>
                <BodyRow className="no-data">
                  <BodyTableCell>
                    <Typography variant="body1" component="p" color="grey.900">
                      No comments found...
                    </Typography>
                  </BodyTableCell>
                </BodyRow>
              </TableBody>
            </Table>
          )}

          {comments.length > 0 &&
            comments
              .slice()
              .sort(sortMomentDateTime)
              .map((item) => <CommentBox key={item.commentId} data={item} users={users} />)}
        </>
      )}
    </div>
  );
};
