import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface OptionButtonProps {
  label: string;
  value: string;
  count: number;
  selected?: boolean;
  onSelect?: (value: string, isSelected: boolean) => void;
}

const StyledBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 4,
    pt: 3,
    width: '100%',
    borderRadius: 1,
    transition: '.2s background-color',

    '&:hover': {
      bgcolor: 'grey.100',
      cursor: 'pointer',
    },
  }),
);

export const OptionButton: React.FC<OptionButtonProps> = ({
  label,
  value,
  count,
  selected = false,
  onSelect,
}) => {
  const [isChecked, setIsChecked] = useState(selected);

  useEffect(() => {
    setIsChecked(selected);
  }, [selected]);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (onSelect) {
      onSelect(value, !isChecked);
    }
  };

  return (
    <StyledBox onClick={handleClick} sx={{ bgcolor: isChecked ? '#FDF3E2 !important' : 'white' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="body" color="grey.600">
          {label}
        </Typography>
        <Checkbox checked={isChecked} sx={{ mr: 0 }} />
      </Stack>
      <Typography variant="h2" fontSize={32} fontWeight={500}>
        {count}
      </Typography>
    </StyledBox>
  );
};
