import React, { Component } from 'react';
import Select from 'react-select';
import toastr from 'toastr';
import ApiService from '../../services/apiService';
import { Button, Input } from '../../components';

class KYCThreshold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lowerThreshold: 0,
      higherThreshold: 0,
      ruleset: this.getRuleSets()[0],
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchKYCThreshold(this.state.ruleset.value);
  }

  getRuleSets = () => [{ value: 3, label: 'TechToPay' }];

  getKYCThresholdValue = (key) => this.state[key];

  fetchKYCThreshold(ruleSetId) {
    this.setState({ loading: true });
    ApiService.get(`/internal/admin-validator/threshold/${ruleSetId}`)
      .then((response) => {
        this.setState(() => ({
          ...response,
          ruleset: this.getRuleSets().find((rule) => rule.value === ruleSetId),
          loading: false,
        }));
      })
      .catch((error) => toastr.error(`Error getting KYC threshold data - ${error}`));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { lowerThreshold, higherThreshold } = this.state;
    const ruleSetId = this.state.ruleset.value;
    ApiService.update('/internal/admin-validator/threshold', {
      lowerThreshold,
      higherThreshold,
      ruleSetId,
    })
      .then(() => {
        toastr.success('KYC Threshold updated');
      })
      .catch((error) => {
        toastr.error(`Error updating KYC Threshold - ${error}`);
      });
  };

  handleInputChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleSelectChange = (e) => {
    if (e) {
      this.setState({
        ruleset: e,
      });
    }
  };

  handleFormReset(e) {
    e.preventDefault();
    this.fetchKYCThreshold(this.state.ruleset.value);
  }

  renderForm() {
    if (this.state.loading) {
      return (
        <div className="centered width:100%">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    }

    return (
      <form>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="lowerThreshold">Lower Threshold</label>
              <Input
                type="number"
                name="lowerThreshold"
                value={this.getKYCThresholdValue('lowerThreshold')}
                handleInputChanged={(e) => this.handleInputChange(e, 'lowerThreshold')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="higherThreshold">Higher Threshold</label>
              <Input
                type="number"
                name="higherThreshold"
                value={this.getKYCThresholdValue('higherThreshold')}
                handleInputChanged={(e) => this.handleInputChange(e, 'higherThreshold')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="ruleset">Ruleset</label>
              <Select
                value={this.getKYCThresholdValue('ruleset')}
                options={this.getRuleSets()}
                onChange={(e) => {
                  this.handleSelectChange(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Button type="submit" onClick={(e) => this.handleSubmit(e)} buttonText="Save" />
            <Button
              buttonText="Reset"
              onClick={(e) => this.handleFormReset(e)}
              buttonClass="btn btn-default btn-cons"
            />
          </div>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="grid-body">
        <div className="searchFilter">{this.renderForm()}</div>
      </div>
    );
  }
}

export default KYCThreshold;
