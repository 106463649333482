import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeSelectedProductIdData } from 'selectors/user';
import { useGetRulesQuery } from 'services/internalApi';
import { StateType } from '../types';
import { findDeepById } from '../rulesUtils';
import { updateCurrentRuleSetId, updateRuleset } from '../rulesSlice';
import { RuleHeader } from './RuleHeader';
import { RuleContent } from './RuleContent';

export const RuleDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { ruleSetId, ruleId } = useParams<{ ruleSetId: string; ruleId: string }>();
  const ruleSetState = useSelector((state: StateType) => state.ruleSetState.rulesTree);
  const rule = findDeepById(ruleSetState, ruleId);

  // refresh state data if tenant changed
  const selectedProductId = useSelector(makeSelectedProductIdData());
  const previousProductIdRef = useRef<string | null>(null);
  const navigate = useNavigate();

  const {
    data: rulesList,
    isLoading: isLoadingRulesList,
    isFetching: isFetchingRulesList,
  } = useGetRulesQuery(
    {
      ruleSetId,
    },
    {
      skip: !ruleSetId || ruleSetState.length > 0,
    },
  );

  useEffect(() => {
    if (previousProductIdRef.current !== null) {
      dispatch(updateRuleset([]));
      dispatch(updateCurrentRuleSetId(null));
      navigate('/rulesNew');
    }
    previousProductIdRef.current = selectedProductId;
  }, [selectedProductId]);

  useEffect(() => {
    if (rulesList) {
      dispatch(updateRuleset(rulesList));
      dispatch(updateCurrentRuleSetId(ruleSetId));
    }
  }, [rulesList]);

  return (
    <div className="container">
      <Helmet title="Rule details" />
      <RuleHeader ruleSetId={ruleSetId} ruleName={rule?.title} />
      <RuleContent
        ruleSetId={ruleSetId}
        rule={rule}
        isLoading={isLoadingRulesList || isFetchingRulesList}
      />
    </div>
  );
};
