import { BinaryNode, UnaryNode } from './nodes/index.ts';

const escapeForRegex = (str) => String(str).replace(/[.*+?^=!:${}()|[\]\\/\\]/g, '\\$&');
let tokenParser;
export const getTokenParser = (propertyPrefix = '') => {
  const propertyPrefixEscaped = escapeForRegex(propertyPrefix);
  if (!tokenParser)
    tokenParser = new RegExp(
      [
        // order is important
        // numbers
        /\d+(?:\.\d*)?|\.\d+/.source,

        // string-literal
        /["](?:\\[\s\S]|[^"])+["]|['](?:\\[\s\S]|[^'])+[']/.source,

        // booleans
        'true|false',

        // operators
        ['.', '(', ')']
          .concat(UnaryNode.operators, BinaryNode.operators)
          .sort((a, b) => b.length - a.length)
          .map(escapeForRegex)
          .join('|'),

        // properties
        `${propertyPrefixEscaped}[a-zA-Z0-9$_-]+`,

        // remaining chars
        /\S/.source,
      ]
        .map((s) => `(${s})`)
        .join('|'),
      'g',
    );
  return tokenParser;
};
