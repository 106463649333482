import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, className } = this.props;
    return (
      <div className={classNames({ [className]: className }, 'dataTableWidget')}>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped">{children}</table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataTable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

export default DataTable;
