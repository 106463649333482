export const removeDuplicatesByField = <T extends Record<string, any>>(
  array: T[],
  field: keyof T,
): T[] => {
  const result: T[] = [];

  array.forEach((item) => {
    const value = item[field];

    if (!result.some((existingItem) => existingItem[field] === value)) {
      result.push(item);
    }
  });

  return result;
};
