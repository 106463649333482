import { BreadCrumbsLink, BreadCrumbsPagination, BreadCrumbsProps } from './BreadCrumbs.props';

export const updateApplications = ({
  state,
  siblings,
  pagination,
  hasSearch = true,
}: {
  state: BreadCrumbsProps;
  siblings?: BreadCrumbsLink[];
  pagination?: BreadCrumbsPagination;
  hasSearch?: boolean;
}) => {
  const sortedSiblings = siblings?.sort((a, b) => a?.title.localeCompare(b?.title));
  const updatedItem = { ...state.links[1], siblings: sortedSiblings, hasSearch, pagination };
  state.links[1] = updatedItem;
};

export const updateLinkSiblingsUtil = ({
  state,
  siblings,
  linkIndex,
  hasSearch = undefined,
}: {
  state: BreadCrumbsProps;
  siblings: BreadCrumbsLink[];
  linkIndex: number;
  hasSearch?: boolean;
}) => {
  const sortedSiblings = siblings
    ? siblings?.sort((a, b) => a?.title?.localeCompare(b?.title))
    : [];
  const updatedItem = { ...state.links[linkIndex], siblings: sortedSiblings, hasSearch };
  state.links[linkIndex] = updatedItem;
};

export const updateMainLinks = ({
  state,
  newList,
}: {
  state: BreadCrumbsProps;
  newList: BreadCrumbsLink[];
}) => {
  const mainMenu = newList[0];
  const appMenu = { ...state.links[1], to: newList[1]?.to, title: newList[1]?.title };
  state.links = [mainMenu, appMenu, ...newList.slice(2)];
};
