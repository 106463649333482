/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_MERCHANT_FRAUD_NUMBERS,
  LOAD_MERCHANT_FRAUD_NUMBERS_SUCCESS,
  LOAD_MERCHANT_FRAUD_NUMBERS_FAIL,
} from '../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  data: [],
};

export const merchantFraudNumbersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_MERCHANT_FRAUD_NUMBERS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_MERCHANT_FRAUD_NUMBERS_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case LOAD_MERCHANT_FRAUD_NUMBERS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default merchantFraudNumbersReducer;
