import { ISelectOption } from 'uikit';

export const riskStatusOptions: ISelectOption[] = [
  {
    value: 'NOT_PERFORMED',
    label: 'Not Performed',
  },
  {
    value: 'LOW',
    label: 'Low',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'HIGH',
    label: 'High',
  },
  {
    value: 'VERY_HIGH',
    label: 'Very High',
  },
  {
    value: 'PROHIBITED',
    label: 'Prohibited',
  },
];
