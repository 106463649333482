export const formatFieldTitle = {
  // OpenPayd - Beneficial Owner Declaration
  business_interests_entity_list: 'Entity',

  // OpenPayd - Customer Information
  shareholder_list: 'Shareholder',
  individual_list: 'Individual',
  fin_activity_source_list: 'Source',
  pep_list: 'PEP',
  additional_users_list: 'Additional User',

  // OpenPayd - Board Resolution Template
  board_resolution_template_person_list: 'Person',
  board_resolution_template_directors_list: 'Director',

  // OpenPayd - Crypto Questionnaire
  crypto_questionnaire_office_list: 'Office',

  // OpenPayd - Business Questionnaire
  business_questionnaire_office_list: 'Office',
  outbound_currencies_list: 'Outbound Currency',

  // EMB - Customer Information
  shareholders_list: 'Shareholder',
  statistics_most_operates_country_list: 'Country',
  statistics_main_correspondent_list: 'Account',

  // EMB - Prequestionnaire
  prequestionnaire_shareholder_list: 'Shareholder',
  prequestionnaire_ubo_list: 'UBO',
  prequestionnaire_board_member_list: 'Board Member / Representative',
  prequestionnaire_client_list: 'Client / Partner',

  // EMB - Memo
  memo_shareholder_list: 'Shareholder',
  memo_ubo_list: 'UBO',
  memo_memo_financial_results_list: 'financial results',
  memo_representatives_list: 'representatives',

  // EMB - Financial Institutions
  representative_information_list: 'Representative',
  most_activity_operates_list: 'Country',
  reason_for_account_opening_list: 'Bank',
  owners_list: 'owner, shareholder, founder',
  ubo_list: 'ultimate beneficiary owner',
  members_list: 'Member',
  industries_list: 'Industry',

  legal_entity_representative_list: 'Representative',
  legal_entity_owners_list: 'Owners',
  legal_entity_shareholders_list: 'Shareholder',
  legal_entity_partners_list: 'Partner',
  legal_entity_banks_list: 'Bank',
  legal_entity_political_persons_list: 'political person',
  sow_f1_r1_list: 'Individual',

  fin_activity_r12_list: 'source',
  fin_activity_r20_list: 'source',
  fin_activity_r29_list: 'source',
  fin_activity_r37_list: 'source',
};
