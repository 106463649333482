import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { RiFileCopyLine, RiStickyNoteLine } from 'react-icons/ri';
import { TabLink } from 'uikit';
import { toastCopyError, toastCopySuccess } from 'utils/toast';
import { TabHeadLink } from './TabHeadLink';
import { ClientDetailsOutletProps } from '../../ClientDetails.props';

import './ApplicationsTab.scss';

export const ApplicationsTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { applicationId, clientId } = useParams<{ applicationId: string, clientId: string }>();

  const [setOpenNotesDrawer] = useOutletContext<ClientDetailsOutletProps>();

  React.useEffect(() => {
    if (!sessionStorage.getItem('currentUserEmail')) {
      sessionStorage.setItem('currentUserEmail', 'applicationId');
    }
    sessionStorage.setItem('applicationId', applicationId);
  }, [applicationId]);

  const applicationUrl = `/application-management/${clientId}/applications/${applicationId}`;
  const currentPathDepth = location.pathname.split('/').length;

  // Navigate to Application sub-tab if no tab is specified
  useEffect(() => {
    if (currentPathDepth === 5) {
      navigate('app');
    }
  }, [currentPathDepth]);

  return (
    <>
      {currentPathDepth === 6 ? (
        <Box className="ApplicationsTab">
          <TabHeadLink applicationId={applicationId} />

          <Box sx={{ mt: 4 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[100]}` })}
            >
              <Stack direction="row" gap={6}>
                <TabLink link={`${applicationUrl}/app`}>Application</TabLink>
                <TabLink link={`${applicationUrl}/docs`}>Documents</TabLink>
                <TabLink link={`${applicationUrl}/history`}>History</TabLink>
                <TabLink link={`${applicationUrl}/mail`}>Mail</TabLink>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="base"
                  onClick={() => setOpenNotesDrawer(true)}
                  type="button"
                  startIcon={<RiStickyNoteLine size="16px" />}
                  sx={{ bgcolor: 'grey.50' }}
                >
                  Notes
                </Button>
                <Button
                  variant="contained"
                  color="base"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(applicationId).then(
                      () => toastCopySuccess('Application ID'),
                      () => toastCopyError('Application ID'),
                    );
                  }}
                  type="button"
                  startIcon={<RiFileCopyLine size="16px" />}
                  sx={{ bgcolor: 'grey.50' }}
                >
                  Copy ID
                </Button>
              </Stack>
            </Stack>

            <Outlet />
          </Box>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};
