import React from 'react';
import { CheckboxProps } from './CheckboxTypes';
import './Checkbox.scss';

const Checkbox: React.FC<CheckboxProps> = ({ children, checked, onChange, disabled }) => {
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <label className={`baseCheckbox ${disabled ? 'baseCheckbox--disabled' : ''}`}>
      <span
        className={`checkboxElementWrapper ${checked ? 'checkboxElementWrapper--checked' : ''}`}
      >
        <input
          disabled={disabled}
          checked={checked}
          onChange={changeHandler}
          type="checkbox"
          className="checkboxElement"
        />
        <span className="checkboxInner" />
      </span>
      {children && <span className="textContent">{children}</span>}
    </label>
  );
};

export default Checkbox;
