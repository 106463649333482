import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ArrowBack, Skeleton, TabContainer, Tab } from 'uikit';
import { useGetClientQuery } from 'services/gatewayApi/clientsApi';
import { Client } from '../types';
import { DetailsTitle } from './DetailsTitle';
import { IbansTab } from './IbansTab';
import { FinActivTab } from './FinActivTab';
import { InfoTab } from './InfoTab';

export const ClientDetails = ({ clientDetails, onClose, updateClient, categories }) => {
  const [client, setClient] = useState<Client>(clientDetails);
  const [edited, setEdited] = useState<boolean>(false);

  const {
    data: clientLoaded,
    isLoading,
    isFetching,
  } = useGetClientQuery({ id: clientDetails?.id }, { skip: !edited || !clientDetails });

  useEffect(() => {
    if (edited && clientLoaded && !isLoading && !isFetching) {
      setClient(clientLoaded);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    setEdited(false);
    setClient(clientDetails);
  }, [clientDetails]);

  const clientData = edited ? client : clientDetails;

  return (
    <Box sx={{ pr: 0, pl: 8, py: 6 }}>
      <ArrowBack onClose={onClose} />

      {isLoading && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!isLoading && clientData && (
        <>
          <DetailsTitle
            client={clientData}
            onClose={onClose}
            setEdited={setEdited}
            updateClient={updateClient}
            categories={categories}
          />
          <TabContainer>
            <Tab key="ibansTab" title="IBANs">
              <IbansTab client={clientData} categories={categories} setEdited={setEdited} />
            </Tab>
            <Tab key="finActivTab" title="Financial Activity">
              <FinActivTab clientDetails={clientData} setEdited={setEdited} />
            </Tab>
            <Tab key="infoTab" title="Info">
              <InfoTab clientDetails={clientData} />
            </Tab>
          </TabContainer>
        </>
      )}
    </Box>
  );
};
