import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { Callback } from '../../types';

interface ClearProps {
  clear?: boolean | Callback;
  disabled?: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement>;
}

export const Clear: React.FC<ClearProps> = ({ clear = undefined, disabled = false, inputRef }) => {
  const [clearIconVisibility, setClearIconVisibility] = useState('hidden');

  const handleClear = () => {
    if (typeof clear !== 'boolean') {
      clear();
    }
    inputRef.current.value = '';
    setClearIconVisibility('hidden');
    inputRef.current.focus();
  };

  useEffect(() => {
    setClearIconVisibility(inputRef?.current?.value?.length > 0 ? 'visible' : 'hidden');
  }, [inputRef?.current?.value]);

  return clear ? (
    <IconButton
      type="button"
      sx={{ p: '5px', visibility: clearIconVisibility }}
      aria-label="clear"
      onClick={() => handleClear()}
      disabled={disabled}
    >
      <CancelIcon sx={{ fontSize: '12px', color: disabled ? 'grey.300' : 'grey.500' }} />
    </IconButton>
  ) : null;
};
