import React, { useRef, useState } from 'react';
import './AppUserMenu.scss';

import { useOnClickOutside } from 'hooks';
import { EmbedDropdown, EmbedDropdownNode } from 'uikit';
import { ExitToApp, Person as PersonIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useAuth } from 'react-oidc-context';

export interface AppUserMenuProps {
  user: any;
}

export const AppUserMenu: React.FC<AppUserMenuProps> = ({ user }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const refUserMenu = useRef();
  const auth = useAuth();

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await auth.removeUser();
    sessionStorage.clear();
    localStorage.setItem('logoutInitiated', 'true');
    localStorage.removeItem('logoutInitiated');
    await auth.signoutRedirect();
  };

  useOnClickOutside([refUserMenu], () => setShowUserMenu(false), true);

  return (
    <div className="AppUserMenu" ref={refUserMenu}>
      <Button
        size="medium"
        variant="contained"
        color="contrast"
        onClick={() => setShowUserMenu(!showUserMenu)}
        startIcon={<PersonIcon style={{ fontSize: '22px' }} />}
        disableElevation
        sx={{
          bgcolor: 'grey.700',
          '&:hover': { bgcolor: 'grey.800' },
        }}
      >
        {user.name}
      </Button>

      <EmbedDropdown isShowing={showUserMenu}>
        <a href="/logout" onClick={handleLogout}>
          <EmbedDropdownNode>
            <>
              <ExitToApp style={{ margin: '0 4px 0 -4px', fontSize: '16px' }} />
              Logout
            </>
          </EmbedDropdownNode>
        </a>
      </EmbedDropdown>
    </div>
  );
};
