import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Button as Btn } from 'uikit';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import {
  useMakeDecisionEntryMutation,
  useTransactionMakeDecisionEntryMutation,
} from 'services/gatewayApi/pepAndSanctionsApi';
import { DecisionEntry, SanctionsRecord } from '../types';
import { FALSE_POSITIVE, HIT, PENDING, statusesPep } from '../constants';
import { makeTitle } from './makeTitle';
import 'styles/tables.scss';

interface SanctionsRecordWhitelisted extends SanctionsRecord {
  whitelistedRecord?: boolean;
  whitelisted?: boolean;
}

interface SearchSessionRowProps {
  applicationId: string;
  item: SanctionsRecordWhitelisted;
  selected?: boolean;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSanctionsRecordDetails: React.Dispatch<React.SetStateAction<SanctionsRecordWhitelisted>>;
  disableAction: boolean;
  isTransactions?: boolean;
  searchId?: string;
  whitelisted?: boolean;
  handleWhitelist: any;
  isLoading: boolean;
}

export const SearchSessionRow: React.FC<SearchSessionRowProps> = ({
  applicationId,
  item,
  selected,
  setOpenDetailsDrawer,
  setSanctionsRecordDetails,
  isTransactions,
  searchId,
  handleWhitelist,
  isLoading,
}) => {
  const {
    firstName,
    lastName,
    position,
    uid,
    dob,
    countries,
    decision: decisionEntry,
    whitelistedRecord,
    whitelisted,
  } = item;

  const [makeDecisionEntry, makeDecisionEntryStatus] = useMakeDecisionEntryMutation();
  const [makeDecisionEntryTransaction, makeDecisionEntryTransactionStatus] =
    useTransactionMakeDecisionEntryMutation();

  const handleMakeDecisionEntry = (status: DecisionEntry, entryId: string) => {
    isTransactions
      ? makeDecisionEntryTransaction({ searchId, entryId, decision: status })
      : makeDecisionEntry({ applicationId, searchId, entryId, decision: status });
  };

  const [currentDecision, setCurrentDecision] = useState(decisionEntry);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateStatus = (status: DecisionEntry) => {
    handleMakeDecisionEntry(status, uid.toString());
    setAnchorEl(null);
  };

  useEffect(() => {
    if (makeDecisionEntryStatus?.isSuccess || makeDecisionEntryTransactionStatus?.isSuccess) {
      isTransactions
        ? setCurrentDecision(makeDecisionEntryTransactionStatus.originalArgs.decision)
        : setCurrentDecision(makeDecisionEntryStatus.originalArgs.decision);
    }
  }, [makeDecisionEntryStatus, makeDecisionEntryTransactionStatus]);

  const opacity = { opacity: whitelistedRecord || whitelisted ? 0.7 : 1 };

  const getLabel = (decision) => {
    if (decision === 'HIT') {
      return <Chip variant="outlined" color="error" size="small" label="Hit" />;
    }
    return (
      <Typography variant="body" sx={opacity}>
        {decision ? statusesPep.find((el) => el.value === decision).label : 'Pending'}
      </Typography>
    );
  };

  return (
    <>
      <TableRow
        hover
        selected={selected}
        onClick={() => {
          setSanctionsRecordDetails(item);
          setOpenDetailsDrawer(true);
        }}
        sx={!selected ? { cursor: 'pointer' } : {}}
        className="table-row table-hover"
      >
        {isTransactions && (
          <TableCell>
            <Button
              color="base"
              startIcon={<Checkbox checked={whitelisted} />}
              onClick={(e) => {
                e.stopPropagation();
                handleWhitelist({ uid, whitelisted: !whitelisted });
              }}
              disabled={isLoading}
              sx={opacity}
            >
              Whitelisted
            </Button>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body" sx={opacity}>
            {makeTitle({ firstName, lastName })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body" sx={opacity}>
            {position}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body" sx={opacity}>
            {formatIsoDate(dob, DATE_FORMAT_MESSAGES)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body" sx={opacity}>
            {countries}
          </Typography>
        </TableCell>

        <TableCell width="15%">
          <Btn
            className="btn-offset"
            suffix={<KeyboardArrowDownIcon sx={{ fontSize: 'inherit', ...opacity }} />}
            variant="text"
            theme="base"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            {getLabel(currentDecision)}
          </Btn>
        </TableCell>
      </TableRow>

      {!!open && (
        <Menu
          id={`${uid}-menu`}
          MenuListProps={{ 'aria-labelledby': `${uid}-button` }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleUpdateStatus(PENDING)}>
            <ListItemText>
              <Typography color="grey.600" fontWeight={600}>
                Pending
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={() => handleUpdateStatus(HIT)}>
            <ListItemText>
              <Typography color="error.main" fontWeight={600}>
                Hit
              </Typography>
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={() => handleUpdateStatus(FALSE_POSITIVE)}>
            <ListItemText>
              <Typography fontWeight={600}>False positive</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
