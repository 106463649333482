import React, { useState } from 'react';
import {
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  History as HistoryIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { DetailsDrawer, Badge } from 'uikit';
import { DATETIME_FORMAT } from 'constants/constants';
import { formatIsoDate } from 'utils/formatter';
import { decisions } from '../constants';
import { PepDecision } from '../types';
import { HistoryDetails } from './HistoryDetails';

interface SearchHeaderProp {
  isTransactions?: boolean;
  isLoadingWL: boolean;
  title: string;
  dateTime?: any;
  handleAction: (PepDecision) => void;
  currentDecision?: PepDecision;
  setHistorySearchResult?: any;
  selectedResultId: string;
  setSelectedResultId: React.Dispatch<React.SetStateAction<string>>;
  setSearchParams?: any;
  setPerformedOn?: React.Dispatch<React.SetStateAction<string>>;
  handleWhitelist: () => void;
  isWhitelisted?: boolean | boolean[];
}

export const SearchHeader: React.FC<SearchHeaderProp> = ({
  isTransactions = false,
  isLoadingWL,
  title,
  dateTime,
  handleAction,
  currentDecision,
  setHistorySearchResult,
  selectedResultId,
  setSelectedResultId,
  setPerformedOn,
  setSearchParams,
  handleWhitelist,
  isWhitelisted = false,
}) => {
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDesision = (decision: PepDecision) => {
    handleAction(decision);
    handleClose();
  };

  const getLabel = () => {
    if (!currentDecision) {
      return 'Make decision';
    }
    return decisions.find((el) => el.value === currentDecision).label;
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
        <Stack direction="row" alignItems="center" gap={4}>
          <Typography
            color="grey.900"
            fontWeight={500}
            fontSize="24px"
            lineHeight="32px"
            letterSpacing="-0,25px"
          >
            {title}
          </Typography>
          {dateTime && (
            <Badge style={{ display: 'inline-block' }}>
              <Typography color="grey.700" variant="body2">
                {formatIsoDate(dateTime, DATETIME_FORMAT)}
              </Typography>
            </Badge>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            color="base"
            startIcon={
              isLoadingWL ? (
                <CircularProgress color="inherit" size={14} sx={{ mr: 2 }} />
              ) : (
                <Checkbox
                  checked={isTransactions ? isWhitelisted[0] && isWhitelisted[1] : isWhitelisted}
                  indeterminate={isTransactions ? isWhitelisted[0] !== isWhitelisted[1] : false}
                />
              )
            }
            onClick={handleWhitelist}
          >
            {isTransactions ? 'Whitelisted all' : 'Whitelisted'}
          </Button>
          {!isTransactions && (
            <Button
              color="base"
              startIcon={<HistoryIcon style={{ fontSize: '14px' }} />}
              onClick={() => setOpenHistoryDrawer((prev) => !prev)}
            >
              History
            </Button>
          )}
          <Button
            color="base"
            variant="contained"
            endIcon={<KeyboardArrowDownIcon style={{ fontSize: '14px' }} />}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            {getLabel()}
          </Button>
        </Stack>
      </Stack>

      <DetailsDrawer width={428} open={openHistoryDrawer}>
        <HistoryDetails
          selectedResultId={selectedResultId}
          setSelectedResultId={setSelectedResultId}
          setHistorySearchResult={setHistorySearchResult}
          onClose={() => setOpenHistoryDrawer(false)}
          setSearchParams={setSearchParams}
          setPerformedOn={setPerformedOn}
        />
      </DetailsDrawer>

      {!!open && (
        <Menu
          id="reject-menu"
          MenuListProps={{ 'aria-labelledby': `reject-button` }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {!!anchorEl &&
            decisions.map((el) => (
              <MenuItem onClick={() => handleDesision(el.value)} key={el.value}>
                <ListItemText>
                  <Typography color={el.color}>{el.label}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
        </Menu>
      )}
    </>
  );
};
