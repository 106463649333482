export const overviewSections = [
  {
    id: 'general',
    // label: 'General', //section title is hidden
    fields: [
      { value: 'haystackClient_industryType', label: 'Business Type' },
      { value: 'haystackClient_countryOfIncorporation', label: 'Country of Incorporation' },
      { value: 'categoryId', label: 'Category' },
      { value: 'haystackClient_complianceCallScheduledDate', label: 'Compliance Call' },
      { value: 'id', label: 'Haystack ID' },
    ],
  },
];
