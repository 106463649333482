import React from 'react';
import { PaperProps, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const BulkActionPanelRoot = styled('div', {
  name: 'bulkActionPanelWrapper',
  slot: 'root',
})(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    position: 'sticky',
    bottom: 32,
    margin: '0 auto',
    backgroundColor: 'grey.700',
    borderRadius: '12px',
    width: 'fit-content',
    boxShadow: '0px 0px 30px 0px #0000001A',
  }),
);

export const BulkActionPanel = (props: PaperProps): JSX.Element => {
  const { children, ...other } = props;
  return (
    <BulkActionPanelRoot>
      <Stack direction="row" sx={{ gap: '0.5rem', padding: '12px', alignItems: 'center' }}>
        {children}
      </Stack>
    </BulkActionPanelRoot>
  );
};
