import React from 'react';
import { Panel, Card } from 'uikit';
import { Box, Stack, Typography } from '@mui/material';

export const CardSection = () => (
  <Box mb={8}>
    <Typography variant="h2" gutterBottom mb={4}>
      Cards
    </Typography>
    <Panel>
      <Card>Test</Card>
    </Panel>
  </Box>
);
