import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { RiCheckboxCircleFill, RiCheckFill, RiMailSendLine } from 'react-icons/ri';
import { useGetCustomerUsersQuery, useGetOpenpaydUsersQuery } from 'services/gatewayApi/usersApi';
import { useGetShareholdersQuery } from 'services/gatewayApi/shareholdersApi';
import { useGetKycApplicantsQuery } from 'services/gatewayApi/kycApplicantsApi';
import { useGetRiskScoringAnswersTotalScoreByApplicationQuery } from 'services/gatewayApi/riskScoringAnswersApi';
import { useGetApplicationFormsQuery } from 'services/gatewayApi/applicationFormsApi';
import { useGetAllApplicationDocumentsQuery } from 'services/gatewayApi/applicationDocumentsApi';
import { useGetWebsiteChecklistsForApplicationQuery } from 'services/gatewayApi/websiteChecklistsApi';
import {
  useGetAppFormSectionsQuery,
  useUpdateAppFormSectionStatusMutation,
} from 'services/gatewayApi/applicationFormSectionsApi';
import { useSendSectionToSignMutation } from 'services/gatewayApi/applicationApi';
import { checkProductName, checkPermissions } from 'utils';
import { kycStatuses } from 'features/kycApplicants/constants';
import { Application } from '../../../../../types';
import { sectionStatuses } from '../../../../../types/forms';
import { riskMapForOpenPayd } from '../../Tabs/AppTab/RiskScoringTab/constants';
import { ServiceContainer } from '../ServiceContainer';
import {
  IdVerificationStatus,
  IdVerificationStatusMap,
  RiskScoringStatus,
  RiskScoringStatusMap,
  SectionStatus,
  WebsiteChecklistStatus,
  WebsiteChecklistStatusMap,
} from './types';

interface ServiceButtonProps {
  application: Application;
  service: any;
}

export const ServiceButton = ({
  application,
  service,
}: ServiceButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const baseUrl = location.pathname;

  const { id: applicationId, isRiskScoringSubmitted } = application;
  const { sectionId, partition } = service;

  let serviceTitle = service.title;
  let additionalTitle = '';
  let status:
    | SectionStatus
    | WebsiteChecklistStatus
    | IdVerificationStatus
    | RiskScoringStatus
    | 'HAS_DATA'
    | 'PENDING';
  let statusLabel = 'Pending';
  let statusIcon;
  let boldStatusLabel = false;
  let hasError = false;
  let doneCount = 0;
  let totalCount = 0;

  const getStatusLabelColor = () => {
    switch (status) {
      case 'SIGNED':
      case 'VERIFIED':
        return 'success.main';

      default:
        return 'grey.500';
    }
  };

  const doneStatuses = ['DONE', 'PERFORMED', 'VERIFIED', 'SIGNED'];
  const showCountStatuses = ['PENDING', 'IN_PROGRESS'];

  const isOpenPayd = checkProductName(['OPENPAYD']);

  // #region Section: Verification and Checks

  // #region ID Verification
  const isIdVerificationButton = service.link === 'id-verify';
  const idVerificationStatusMap: IdVerificationStatusMap[] = [
    {
      status: 'NOT_PERFORMED',
      title: 'Not performed',
    },
    {
      status: 'IN_PROGRESS',
      title: 'In Progress',
    },
    {
      status: 'FAILED',
      title: 'Failed',
      hasError: true,
    },
    {
      status: 'VERIFIED',
      title: 'Verified',
    },
  ];

  const { data: applicantsData, isLoading: isLoadingApplicants } = useGetKycApplicantsQuery(
    {
      applicationId,
      kycStatus: kycStatuses.allIds.filter((statusKyc) => statusKyc !== 'NEW'),
      page: 0,
      size: 2000,
    },
    { skip: !isIdVerificationButton },
  );

  if (isIdVerificationButton) {
    let idVerificationStatus = idVerificationStatusMap.find(
      (item) => item.status === 'NOT_PERFORMED',
    ); // Default status

    const applicants: any[] = applicantsData?.content ?? [];

    const hasApplicantInKycStatus = (...status) =>
      !isLoadingApplicants &&
      applicants?.filter((item) => status.includes(item.kycStatus)).length > 0;

    const hasApplicantInKycResult = (...status) =>
      !isLoadingApplicants &&
      applicants?.filter((item) => status.includes(item.kycResult)).length > 0;

    if (applicants?.length > 0) {
      if (hasApplicantInKycStatus('IN_PROGRESS')) {
        idVerificationStatus = idVerificationStatusMap.find(
          (item) => item.status === 'IN_PROGRESS',
        );
      } else if (hasApplicantInKycResult('CONSIDER', 'UNDEFINED')) {
        idVerificationStatus = idVerificationStatusMap.find((item) => item.status === 'FAILED');
      }
    }

    status = idVerificationStatus.status;
    statusLabel = idVerificationStatus.title;
    hasError = idVerificationStatus.hasError;
  }
  // #endregion

  // #region PEP and Sanctions
  const isPepButton = service.link === 'pep-and-sanctions';

  if (isPepButton) {
    statusLabel = 'Not performed';
  }
  // #endregion

  // #endregion

  // #region Section: Individuals

  // #region Users
  const isUsersButton = service.link === 'users';

  const { data: customerUsers = [], isLoading: isLoadingCustomerUsers } = useGetCustomerUsersQuery(
    { applicationId },
    { skip: !isUsersButton },
  );
  const { data: openPaydUsers = [], isLoading: isLoadingOpenPaydUsers } = useGetOpenpaydUsersQuery(
    applicationId,
    { skip: !(isUsersButton || isOpenPayd) },
  );
  if (isUsersButton) {
    statusLabel = '0';

    const totalUsers = customerUsers.length + openPaydUsers.length;

    if (isLoadingCustomerUsers || isLoadingOpenPaydUsers) {
      statusLabel = '...';
    } else if (totalUsers > 0) {
      status = 'HAS_DATA';
      statusLabel = totalUsers.toString();
      boldStatusLabel = true;
    }
  }
  // #endregion

  // #region Shareholders
  const isShareholdersButton = service.link === 'shareholders';

  const { data: shareholders = null, isLoading: isLoadingShareholders } = useGetShareholdersQuery(
    {
      applicationId,
      sort: { property: 'createdDate', direction: 'desc' },
    },
    { skip: !isShareholdersButton },
  );

  if (isShareholdersButton) {
    statusLabel = '0';

    if (isLoadingShareholders) {
      statusLabel = '...';
    } else if (shareholders?.content.length > 0) {
      status = 'HAS_DATA';
      statusLabel = shareholders?.content.length.toString();
      boldStatusLabel = true;
    }
  }
  // #endregion

  // #endregion

  // #region Section: Forms

  // #region Documents & Internal Documents
  const isDocumentsButton = service.link === 'documents';
  const isInternalDocumentsButton = service.link === 'internal-documents';

  const { data: allDocuments, isLoading: isLoadingAllDocuments } =
    useGetAllApplicationDocumentsQuery(applicationId, {
      skip: !(isDocumentsButton || isInternalDocumentsButton),
    });

  if (isDocumentsButton && !isLoadingAllDocuments) {
    const documents = allDocuments?.filter((item) => item.isInternal === false);
    doneCount = documents?.filter((item) => !!item.uploadedDate).length;
    totalCount = documents?.length;
  }

  if (isInternalDocumentsButton && !isLoadingAllDocuments) {
    const internalDocuments = allDocuments?.filter((item) => item.isInternal === true);
    doneCount = internalDocuments?.filter((item) => !!item.uploadedDate).length;
    totalCount = internalDocuments?.length;
  }

  // #endregion

  // #endregion

  // #region Section: Internal Forms

  // #region Risk Scoring (OpenPayd)
  const isRiskScoringButton = isOpenPayd && service.link === 'risk-scoring';
  const riskScoringStatusMap: RiskScoringStatusMap[] = [
    {
      status: 'COMPLETED',
      title: 'Completed',
    },
    {
      status: 'IN_PROGRESS',
      title: 'In Progress',
    },
    {
      status: 'NOT_PERFORMED',
      title: 'Pending',
    },
  ];
  const { data: answersScore, isLoading: isLoadingAnswersScore } =
    useGetRiskScoringAnswersTotalScoreByApplicationQuery(applicationId, {
      skip: !isRiskScoringButton,
    });

  if (isRiskScoringButton && !isLoadingAnswersScore) {
    let riskScoringStatus = 'NOT_PERFORMED';
    if (!isRiskScoringSubmitted && answersScore?.riskStatus !== 'NOT_PERFORMED') {
      riskScoringStatus = 'IN_PROGRESS';
    }

    const currentStatus = riskScoringStatusMap.find((item) => item.status === riskScoringStatus);
    serviceTitle = `Risk Score: ${answersScore?.totalRisk ?? 0}`;
    status = currentStatus?.status;
    statusLabel = currentStatus?.title;

    if (isRiskScoringSubmitted) {
      const riskStatus = riskMapForOpenPayd.find(
        (item) => item.riskStatus === answersScore?.riskStatus,
      );
      status = 'COMPLETED';
      statusLabel = riskStatus?.riskRating;
      hasError = riskStatus?.riskStatus === 'HIGH';
    }
  }
  // #endregion

  // #region Website Checklist
  const isWebsiteChecklistButton = service.link === 'website-checklist';
  const websiteChecklistStatusMap: WebsiteChecklistStatusMap[] = [
    {
      status: 'NOT_PERFORMED',
      title: 'Not performed',
    },
    {
      status: 'PERFORMED',
      title: 'Performed',
    },
  ];

  const { data: websiteChecklist } = useGetWebsiteChecklistsForApplicationQuery(applicationId);

  if (isWebsiteChecklistButton) {
    const currentStatus = websiteChecklistStatusMap.find(
      (item) => item.status === websiteChecklist?.status,
    );
    status = currentStatus?.status;
    statusLabel = currentStatus?.title;
  }
  // #endregion

  // #endregion

  // #region All dynamic forms (Forms + Internal Forms)
  const statusIconMap = {
    DONE: {
      icon: <RiCheckboxCircleFill size="14px" style={{ marginRight: '4px' }} />,
    },
    PENDING_SIGNATURE: {
      // icon: <AutorenewIcon sx={{ fontSize: '14px', mr: 2, color: 'grey.600' }} />,
    },
    SIGNED: {
      // icon: <CheckCircleIcon sx={{ fontSize: '14px', mr: 2, color: 'success.main' }} />,
    },
    VERIFIED: {
      // icon: <CheckCircleIcon sx={{ fontSize: '14px', mr: 2, color: 'grey.600' }} />,
      // buttonIcon: <CheckCircleIcon sx={{ fontSize: 'inherit', mr: '6px', color: 'grey.600' }} />,
    },
  };

  const formStatusMap = sectionStatuses.map((item) => {
    const icons = statusIconMap[item.value];
    return {
      status: item.value,
      title: item.label,
      icon: icons?.icon,
      buttonIcon: icons?.buttonIcon,
    };
  });

  const formsWithAdditionalTitle = [
    {
      formTitle: 'Source of Wealth Declaration Form',
      formTemplateFieldId: '305c3c29-a529-40fe-8a71-cc1336780c84', // Official full name
    },
    {
      formTitle: 'Politically Exposed Person (PEP) Self-certification form',
      formTemplateFieldId: 'bc082e3c-c102-4458-b6cb-dca430b046b7', // Name of PEP
    },
    {
      formTitle: 'Beneficial Owner Declaration Form',
      formTemplateFieldId: 'a9d1c459-2f3b-4169-84f3-3bdb96fbabd5', // Official full name
    },
    {
      formTitle: 'PEP Source of Wealth Declaration Form',
      formTemplateFieldId: 'a59c9298-f03d-4849-9c0c-b228f714d533', // Name of PEP
    },
  ];

  const additionalTitleField = formsWithAdditionalTitle.find(
    (form) => form.formTitle === service.title,
  );

  // Fetch all application forms data, NOT specific section and partition
  const { data: applicationForms, isLoading: isLoadingForms } = useGetApplicationFormsQuery({
    applicationId,
  });

  if (!isLoadingForms) {
    const formSections = applicationForms?.filter(
      (form) =>
        form.formSection.id === service.sectionId && form.partitionName === service.partition,
    );

    if (!(isDocumentsButton || isInternalDocumentsButton)) {
      doneCount = formSections?.filter((item) => doneStatuses.includes(item.status)).length ?? 0;
      totalCount = formSections?.length ?? 0;
    }

    if (additionalTitleField) {
      const fieldData = formSections
        ?.flatMap((formSectionData) => formSectionData.records)
        .find((field) => field.formTemplateFieldId === additionalTitleField.formTemplateFieldId);
      additionalTitle = fieldData?.value;
    }
  }

  if (service.sectionId) {
    const currentStatus = formStatusMap.find((item) => item.status === service.status);
    if (currentStatus) {
      status = currentStatus?.status;
      statusLabel = currentStatus?.title;
      statusIcon = currentStatus?.icon;
    }
  }
  // #endregion

  // #region Product and User Permission checks
  if (service.forProducts && !checkProductName(service.forProducts)) {
    return null;
  }

  if (service.permissions && !checkPermissions(service.permissions)) {
    return null;
  }
  // #endregion

  const getLabelColor = () => {
    if (hasError) {
      return 'error.main';
    }
    if (status !== 'NOT_PERFORMED') {
      return 'grey.700';
    }
    return 'grey.400';
  };

  const title = serviceTitle + (additionalTitle ? `: ${additionalTitle}` : '');

  const handleOnClick = (e, ignoreButtonCheck: boolean = false) => {
    if (
      ignoreButtonCheck || (
        !(e.target as Element).classList.contains('MuiBackdrop-root') &&
        !(e.target as Element).classList.contains('MuiButton-root')
      )
    ) {
      navigate(`${baseUrl}/${service.link}`);
    }
  };

  const { data: formSections, isLoading: isLoadingSections } = useGetAppFormSectionsQuery({
    applicationId,
  });
  const currentSection = formSections?.find(
    (s) => s.sectionId === sectionId && s.partition === partition,
  );

  const [updateFormSectionStatus] = useUpdateAppFormSectionStatusMutation();

  // #region Mark section as Verified
  const isSectionDone = !isLoadingSections && currentSection?.status === 'DONE';
  const isSectionInternal = !isLoadingSections && currentSection?.sectionDetails?.isInternal;
  const canMarkAsVerified = isSectionDone && !isSectionInternal; // + check: Only compliance team should be able to verify
  // #endregion

  // #region Send/Resend section for Signature
  const [sendSectionToSign] = useSendSectionToSignMutation();
  const isSectionPendingSignature =
    !isLoadingSections && currentSection?.status === 'PENDING_SIGNATURE';
  const isSectionVerified = !isLoadingSections && currentSection?.status === 'VERIFIED';
  const canSendToSignature =
    ['PENDING_FORMS_SIGNATURE', 'PENDING_ONBOARDING_COMPLIANCE_FIRST'].includes(application?.mainStatus) &&
    (isSectionVerified || isSectionPendingSignature);
  // #endregion

  return (
    <ServiceContainer onClick={handleOnClick}>
      <Box display="flex" alignItems="start" justifyContent="space-between" m={0}>
        <Typography
          className="link-title"
          variant="body"
          sx={{ mb: 0, pt: 0, py: service.detailedButton ? '6px' : 0 }}
        >
          {title}
        </Typography>

        {service.titleAction}

        {!service.detailedButton && (
          <Typography
            className="TitleCounter"
            variant="body"
            color={getLabelColor()}
            sx={{ fontSize: '12px', fontWeight: boldStatusLabel ? '500' : '' }}
            p={0}
          >
            {statusLabel}
          </Typography>
        )}
      </Box>

      {service.detailedButton && (
        <Box
          className="DetailedButton"
          pt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: getStatusLabelColor(),
              height: '20px',
              fontSize: '12px',
            }}
          >
            {isInternalDocumentsButton
              ? ' '
              : (
                <>
                  {statusIcon}
                  {statusLabel} {showCountStatuses.includes(status) && ` ${doneCount} of ${totalCount}`}
                </>
              )
            }
          </Typography>

          {!canMarkAsVerified && !canSendToSignature && (
            <Button
              variant="contained"
              color="base"
              type="button"
              size="small"
              onClick={(e) => handleOnClick(e, true)}
              sx={{ bgcolor: 'grey.50' }}
            >
              Open
            </Button>
          )}

          {canMarkAsVerified && (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={() => updateFormSectionStatus({
                applicationId,
                sectionId,
                partition,
                status: 'VERIFIED',
              })}
              startIcon={<RiCheckFill size="14px" />}
            >
              Verify
            </Button>
          )}

          {canSendToSignature && (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={() => sendSectionToSign({ applicationId, sectionId, partition })}
              startIcon={<RiMailSendLine size="14px" />}
            >
              Send for Sign
            </Button>
          )}
        </Box>
      )}
    </ServiceContainer>
  );
};
