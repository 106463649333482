import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DetailsField } from 'uikit';
import { Record } from '../../../types/forms';

interface RenderGroupProps {
  childRecords: Record[];
  formId: string;
  disabled: boolean;
}

export const RenderGroup: React.FC<RenderGroupProps> = ({
  childRecords,
  formId,
  disabled = false,
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();

  return (
    <Box
      width={1}
      border={1}
      borderColor="grey.300"
      borderRadius="20px"
      p={3}
      display="flex"
      flexDirection="column"
      gap={2}
      mb={2}
    >
      {childRecords.map((el) => (
        <DetailsField
          id={el.id}
          applicationId={applicationId}
          key={`${el.id}_${el.row}_${el.col}`}
          fieldType={el.field.type}
          label={el.field.title}
          value={el.value}
          formId={formId}
          record={el}
          updateOnChange
          disabled={disabled}
        />
      ))}
    </Box>
  );
};
