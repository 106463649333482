import { alpha } from '@mui/material/styles';
import { primary, secondary } from '../../palette-blocks';
import { palette } from '../../palette';
import { buttonFocus } from '../mixins';

export const Secondary = {
  props: {
    color: 'secondary',
  },
  style: {
    color: secondary.contrastText,
    background: secondary.main,
    '&:hover': {
      background: secondary.hover,
    },
    '&:active': {
      background: secondary.active,
    },
    '&:disabled': {
      background: alpha(secondary.main, 0.5),
      color: alpha(secondary.contrastText, 0.5),
      // opacity: palette.action?.disabledOpacity,

      '.MuiLoadingButton-loadingIndicator': {
        color: secondary.contrastText,
      },
    },
    '&:focus&:not(:active)': buttonFocus(secondary['bg-300']),
  },
};

export const SecondaryContained = {
  props: {
    color: 'secondary',
    variant: 'contained',
  },
  style: {},
};

export const SecondaryText = {
  props: {
    color: 'secondary',
    variant: 'text',
  },
  style: {
    background: 'transparent',
    '&:hover': {
      background: secondary.light,
    },
    '&:active': {
      background: secondary.hover,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};

export const SecondaryOutlined = {
  props: {
    color: 'secondary',
    variant: 'outlined',
  },
  style: {
    borderColor: secondary['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: secondary.light,
      borderColor: secondary.hover,
    },
    '&:active': {
      background: secondary.hover,
      borderColor: secondary.active,
    },
    '&:disabled': {
      borderColor: alpha(secondary['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};
