interface SidebarMenuGroupLink {
  title: string;
  link?: string;
  action?: () => void;
  hidden?: boolean;
}

export interface SidebarMenuGroup {
  title: string;
  links: SidebarMenuGroupLink[];
}

export enum SidebarItemType {
  SubTitle,
  NavLink,
}
