import { alpha } from '@mui/material/styles';
import { warning } from '../../palette-blocks';
import { buttonFocus } from '../mixins';

export const Warning = {
  props: {
    color: 'warning',
  },
  style: {
    color: warning.contrastText,
    '&:hover': {
      background: warning.hover,
    },
    '&:active': {
      background: warning.active,
    },
    '&:disabled': {
      background: alpha(warning.main, 0.5),
      color: alpha(warning.contrastText, 0.5),
      // opacity: palette.action?.disabledOpacity,
    },
    '&:focus&:not(:active)': buttonFocus(warning.main),
  },
};

export const WarningText = {
  props: {
    color: 'warning',
    variant: 'text',
  },
  style: {
    borderColor: 'transparent',
    background: 'transparent',
    '&:hover': {
      background: warning.hover,
    },
    '&:active': {
      background: warning.active,
    },
    '&:disabled': {
      background: 'transparent',
    },
  },
};

export const WarningOutlined = {
  props: {
    color: 'warning',
    variant: 'outlined',
  },
  style: {
    borderColor: warning['outline-100'],
    background: 'transparent',

    '&:hover': {
      background: warning.hover,
      borderColor: warning['outline-100'],
    },
    '&:active': {
      background: warning.active,
      borderColor: warning['outline-100'],
    },
    '&:disabled': {
      borderColor: alpha(warning['outline-100'], 0.5),
      background: 'transparent',
    },
  },
};
