/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS,
  LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_SUCCESS,
  LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_FAIL,
} from '../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  data: [],
};

const transactionApproveRejectAmountsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_SUCCESS:
        draft.data = action.data;
        draft.loading = false;
        break;
      case LOAD_TRANSACTIONS_APPROVE_REJECT_AMOUNTS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
    }
  });

export default transactionApproveRejectAmountsReducer;
