import { combineDateTime, removeEmptyProperties } from '../../utils/modelHelper';
import { formatDate } from '../../utils/formatter';
import { DATETIME_FORMAT } from '../../constants/constants';
import { gatewayApi } from '../gatewayApi';

const merchantCategoriesApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getMerchantCategories: build.query({
      query: (params) => ({
        url: 'merchant-categories',
        params,
      }),
      providesTags: () => [{ type: 'MerchantCategories', id: 'LIST' }],
      transformResponse: (response) => ({
        results: response.content.map((r) => {
          let result = { ...r };
          result = combineDateTime(result, formatDate, DATETIME_FORMAT);
          return result;
        }),
        count: response.totalElements,
      }),
    }),
    addMerchantCategory: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: 'merchant-categories',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'MerchantCategories', id: 'LIST' }],
    }),
    updateMerchantCategory: build.mutation({
      query(merchantCategory) {
        const body = { ...merchantCategory };
        removeEmptyProperties(body);
        return {
          url: `merchant-categories/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'MerchantCategories', id: 'LIST' }],
    }),
    deleteMerchantCategory: build.mutation({
      query: (id) => ({
        url: `merchant-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'MerchantCategories', id: 'LIST' }],
    }),

    // Merchant Category for Merchant actions
    getMerchantCategoriesForMerchant: build.query({
      query: (merchantId) => `merchants/${merchantId}/merchant-categories`,
      providesTags: (result, error, merchantId) => [
        { type: 'MerchantCategoriesForMerchant', merchantId },
      ],
    }),
    linkMerchantCategoryForMerchant: build.mutation({
      query({ merchantId, categoryId }) {
        return {
          url: `merchants/${merchantId}/merchant-categories/${categoryId}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { merchantId }) => [
        { type: 'MerchantCategoriesForMerchant', merchantId },
        { type: 'Merchants', id: 'LIST' },
      ],
    }),
    unlinkMerchantCategoryForMerchant: build.mutation({
      query({ merchantId, categoryId }) {
        return {
          url: `merchants/${merchantId}/merchant-categories/${categoryId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { merchantId }) => [
        { type: 'MerchantCategoriesForMerchant', merchantId },
        { type: 'Merchants', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetMerchantCategoriesQuery,
  useAddMerchantCategoryMutation,
  useUpdateMerchantCategoryMutation,
  useDeleteMerchantCategoryMutation,

  useGetMerchantCategoriesForMerchantQuery,
  useLinkMerchantCategoryForMerchantMutation,
  useUnlinkMerchantCategoryForMerchantMutation,
} = merchantCategoriesApi;

export const useGetMerchantCategoriesForMerchantQueryState =
  merchantCategoriesApi.endpoints.getMerchantCategoriesForMerchant.useQueryState;
