import { removeEmptyProperties } from 'utils/modelHelper';
import { gatewayApi } from '../gatewayApi';
import type { HaystackClient } from '../../features/ApplicationManagement/types';

const haystackClientApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getHaystackClient: build.query<HaystackClient, string>({
      query: (id) => ({
        url: `haystack-client/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'HaystackClient', id: id }],
    }),

    updateHaystackClient: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `haystack-client/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'HaystackClient', id }],
    }),
    updateHaystackClientPartial: build.mutation({
      query({ id, ...patch }) {
        return {
          url: `haystack-client/${id}`,
          method: 'PATCH',
          body: patch,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'HaystackClient', id }],
    }),
  }),
});

export const {
  useGetHaystackClientQuery,
  useUpdateHaystackClientMutation,
  useUpdateHaystackClientPartialMutation,
} = haystackClientApi;
