import React from 'react';
import { Box, Grid } from '@mui/material';

interface ApplicationSeparatorProps {
  title: string;
  titleActions?: React.ReactNode;
  children: React.ReactNode;
}

export const ApplicationSeparator = ({
  title,
  titleActions,
  children,
}: ApplicationSeparatorProps): JSX.Element => (
  <Box>
    <Box
      className="application-separator"
      display="flex"
      alignItems="center"
      justifyContent="start"
    >
      <Box sx={{ mr: 2 }}>{title}</Box>
      {titleActions}
    </Box>
    <Grid container spacing={4}>
      {children}
    </Grid>
  </Box>
);
