import React, { useState } from 'react';
import * as _ from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { testIbanChecksum } from 'utils';
import { toastCreateSuccess, toastUpdateSuccess } from 'utils/toast';
import { ModalDialog } from 'uikit/ModalDialog';
import { ValueLabel } from 'types/types';
import {
  useAddClientIbanMutation,
  useUpdateClientIbanMutation,
  useUpdateClientIbanFlowFundMutation,
  useAddClientIbanFinActivityMutation,
  useUpdateClientIbanFinActivityMutation,
} from 'services/gatewayApi/clientsApi';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { IBAN_REGEX } from 'constants/constants';
import { textFieldProps } from '../../constants';
import { Client, Iban } from '../../types';

interface IbanDialogProps {
  open: boolean;
  onClose: any;
  ibanToOpen: Iban;
  categories: ValueLabel[];
  client: Client;
  setEdited: any;
}

export const IbanDialog: React.FC<IbanDialogProps> = ({
  open,
  onClose,
  ibanToOpen,
  categories,
  client,
  setEdited,
}) => {
  const isNew = ibanToOpen.id === 'new';

  const hasFinActivity = !!ibanToOpen.monthlyTransactions;
  const [showFinActivity, setShowFinActivity] = useState(hasFinActivity);

  const [addClientAccountIban] = useAddClientIbanMutation();
  const [updateClientAccountIban] = useUpdateClientIbanMutation();

  const [updateClientIbanFlowFunds] = useUpdateClientIbanFlowFundMutation();
  const [addClientIbanFinActivity] = useAddClientIbanFinActivityMutation();
  const [updateClientIbanFinActivity] = useUpdateClientIbanFinActivityMutation();

  const { countries = [], isLoadingCountries } = useGetAllCountriesQuery(
    undefined,
    {
      selectFromResult: ({ data, isLoading }) => ({
        countries: data?.map((el) => ({
          value: el.countryCode,
          label: `${el.countryCode}: ${el.name}`,
        })),
        isLoadingCountries: isLoading,
      }),
    },
  );

  const validationSchema = yup.object({
    ibanCode: yup
      .string()
      .required('required')
      .min(5, 'Minimum length is ${min}')
      .max(35, 'Maximum length is ${max}')
      .matches(IBAN_REGEX, 'IBAN is not in correct format')
      .test('is-valid-iban', 'Invalid IBAN', (value) => testIbanChecksum(value)),
    ibanCategories: yup.array().of(yup.number()),

    // Financial Activity
    averageInbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    averageOutbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    maxInbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    maxOutbound: showFinActivity ? yup.number().required('Required') : yup.string(),
    monthlyTransactions: showFinActivity ? yup.number().required('Required') : yup.string(),
    monthlyTurnover: showFinActivity ? yup.number().required('Required') : yup.string(),
  });

  const getCountryCodeFromArray = (array, fundType, accessType) =>
    Array.isArray(array) ? array?.filter((el) => el.fundType === fundType && el.accessType === accessType)
      .map((el) => el.countryCode) : [];

  const initialFlowFundsReceiptAllowed = getCountryCodeFromArray(ibanToOpen.flowFunds, 'RECEIPT', 'ALLOWED');
  const initialFlowFundsPaymentAllowed = getCountryCodeFromArray(ibanToOpen.flowFunds, 'PAYMENT', 'ALLOWED');
  const initialFlowFundsReceiptRestricted = getCountryCodeFromArray(ibanToOpen.flowFunds, 'RECEIPT', 'RESTRICTED');
  const initialFlowFundsPaymentRestricted = getCountryCodeFromArray(ibanToOpen.flowFunds, 'PAYMENT', 'RESTRICTED');

  const formatFlowFundsArray = (array, fundType, accessType) =>
    Array.isArray(array) ? array.map((el) => ({ fundType, countryCode: el, accessType })) : [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...ibanToOpen,
      flowFundsReceiptAllowed: initialFlowFundsReceiptAllowed,
      flowFundsPaymentAllowed: initialFlowFundsPaymentAllowed,
      flowFundsReceiptRestricted: initialFlowFundsReceiptRestricted,
      flowFundsPaymentRestricted: initialFlowFundsPaymentRestricted,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        accountId: ibanToOpen.account?.id,
        ibanCode: values.ibanCode.toUpperCase(),
        categoriesId: values.ibanCategories,
      };

      const finActivityBody = {
        averageInbound: values.averageInbound,
        averageOutbound: values.averageOutbound,
        maxInbound: values.maxInbound,
        maxOutbound: values.maxOutbound,
        monthlyTransactions: values.monthlyTransactions,
        monthlyTurnover: values.monthlyTurnover,
      };

      const flowFunds = [
        ...formatFlowFundsArray(values.flowFundsReceiptAllowed, 'RECEIPT', 'ALLOWED'),
        ...formatFlowFundsArray(values.flowFundsPaymentAllowed, 'PAYMENT', 'ALLOWED'),
        ...formatFlowFundsArray(values.flowFundsReceiptRestricted, 'RECEIPT', 'RESTRICTED'),
        ...formatFlowFundsArray(values.flowFundsPaymentRestricted, 'PAYMENT', 'RESTRICTED'),
      ];

      if (isNew) {
        addClientAccountIban({ body })
          .unwrap()
          .then((response) => {
            const ibanId = response.id;
            toastCreateSuccess('IBAN');

            // Add Fin Activity
            if (showFinActivity) {
              addClientIbanFinActivity({
                ibanId,
                body: { ...finActivityBody, flowFunds },
              });
            }
          });
      } else {
        const ibanId = ibanToOpen.id;

        updateClientAccountIban({ body, ibanId })
          .unwrap()
          .then(() => {
            toastUpdateSuccess('IBAN');

            // Add Fin Activity
            if (!hasFinActivity && showFinActivity) {
              addClientIbanFinActivity({
                ibanId,
                clientId: client.id,
                body: { ...finActivityBody, flowFunds },
              });
            }

            // Update existing Fin Activity
            if (hasFinActivity && showFinActivity) {
              updateClientIbanFinActivity({
                ibanId,
                clientId: client.id,
                body: finActivityBody,
              });

              if (!_.isEqual(initialFlowFundsReceiptAllowed, values.flowFundsReceiptAllowed)) {
                updateClientIbanFlowFunds({
                  ibanId,
                  clientId: client.id,
                  type: 'RECEIPT',
                  body: { countryCode: values.flowFundsReceiptAllowed, accessType: 'ALLOWED' }
                });
              }
              if (!_.isEqual(initialFlowFundsPaymentAllowed, values.flowFundsPaymentAllowed)) {
                updateClientIbanFlowFunds({
                  ibanId,
                  clientId: client.id,
                  type: 'PAYMENT',
                  body: { countryCode: values.flowFundsPaymentAllowed, accessType: 'ALLOWED' }
                });
              }
              if (!_.isEqual(initialFlowFundsReceiptRestricted, values.flowFundsReceiptRestricted)) {
                updateClientIbanFlowFunds({
                  ibanId,
                  clientId: client.id,
                  type: 'RECEIPT',
                  body: { countryCode: values.flowFundsReceiptRestricted, accessType: 'RESTRICTED' }
                });
              }
              if (!_.isEqual(initialFlowFundsPaymentRestricted, values.flowFundsPaymentRestricted)) {
                updateClientIbanFlowFunds({
                  ibanId,
                  clientId: client.id,
                  type: 'PAYMENT',
                  body: { countryCode: values.flowFundsPaymentRestricted, accessType: 'RESTRICTED' }
                });
              }
            }
          });
      }

      resetForm();
      setEdited(true);
      onClose();
    },
  });

  return (
    <>
      <ModalDialog
        open={open}
        onClose={onClose}
        title={isNew ? 'Add IBAN' : 'Edit IBAN'}
        handleAction={formik.handleSubmit}
        actionTitle={isNew ? 'Create' : 'Save'}
        isLoading={formik.isSubmitting}
      >
        <Stack spacing={2}>
          <TextField
            autoFocus
            type="text"
            fullWidth
            label="IBAN"
            name="ibanCode"
            onChange={formik.handleChange}
            value={formik.values.ibanCode}
            variant="standard"
            error={formik.touched.ibanCode && Boolean(formik.errors.ibanCode)}
            disabled={formik.isSubmitting}
          />

          <Autocomplete
            multiple
            id="ibanCategories"
            options={categories}
            getOptionLabel={(option) => option.label}
            limitTags={2}
            disableCloseOnSelect
            fullWidth
            value={categories.filter((option) =>
              formik.values.ibanCategories?.some(
                // @ts-ignore
                (v) => option.value === v,
              ),
            )}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'ibanCategories',
                newValue.map(
                  // @ts-ignore
                  (nextValue) => nextValue.value,
                ),
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Categories"
                placeholder="Select IBAN categories"
              />
            )}
          />

          {!showFinActivity && (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => setShowFinActivity(true)}
            >
              Provide financial activity info
            </Button>
          )}

          {showFinActivity && (
            <Stack spacing={4}>
              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Monthly turnover through OpenPayd (total value) (EUR)"
                name="monthlyTurnover"
                onChange={formik.handleChange}
                value={formik.values.monthlyTurnover}
                error={formik.touched.monthlyTurnover && Boolean(formik.errors.monthlyTurnover)}
                disabled={formik.isSubmitting}
              />

              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Transactions per month routed through OpenPayd (volume)"
                name="monthlyTransactions"
                onChange={formik.handleChange}
                value={formik.values.monthlyTransactions}
                error={formik.touched.monthlyTransactions && Boolean(formik.errors.monthlyTransactions)}
                disabled={formik.isSubmitting}
              />

              <Typography variant="subtitle1" sx={{ pt: 6 }}>
                Average Transaction Value Routed Through OpenPayd
              </Typography>

              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Inbound (EUR)"
                name="averageInbound"
                onChange={formik.handleChange}
                value={formik.values.averageInbound}
                error={formik.touched.averageInbound && Boolean(formik.errors.averageInbound)}
                disabled={formik.isSubmitting}
              />

              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Outbound (EUR)"
                name="averageOutbound"
                onChange={formik.handleChange}
                value={formik.values.averageOutbound}
                error={formik.touched.averageOutbound && Boolean(formik.errors.averageOutbound)}
                disabled={formik.isSubmitting}
              />

              <Typography variant="subtitle1" sx={{ pt: 6 }}>
                Maximum Transaction Value Routed Through OpenPayd
              </Typography>

              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Inbound (EUR)"
                name="maxInbound"
                onChange={formik.handleChange}
                value={formik.values.maxInbound}
                error={formik.touched.maxInbound && Boolean(formik.errors.maxInbound)}
                disabled={formik.isSubmitting}
              />

              {/* @ts-ignore */}
              <TextField
                {...textFieldProps}
                label="Outbound (EUR)"
                name="maxOutbound"
                onChange={formik.handleChange}
                value={formik.values.maxOutbound}
                error={formik.touched.maxOutbound && Boolean(formik.errors.maxOutbound)}
                disabled={formik.isSubmitting}
              />

              <Typography variant="subtitle1" sx={{ pt: 6 }}>
                Breakdown of Flow of Funds per Country to be routed through OpenPayd
              </Typography>
              <Autocomplete
                multiple
                id="flowFundsReceiptAllowed"
                options={countries}
                getOptionLabel={(option) => option.label}
                limitTags={2}
                disableCloseOnSelect
                fullWidth
                value={countries.filter((option) =>
                  // @ts-ignore
                  formik.values.flowFundsReceiptAllowed?.some((v) => option.value === v),
                )}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'flowFundsReceiptAllowed',
                    newValue.map(
                      // @ts-ignore
                      (nextValue) => nextValue.value,
                    ),
                  );
                }}
                ChipProps={{ color: 'secondary', size: 'small' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Receipt countries"
                    placeholder="Select receipt countries"
                  />
                )}
              />
              <Autocomplete
                multiple
                id="flowFundsPaymentAllowed"
                options={countries}
                getOptionLabel={(option) => option.label}
                limitTags={2}
                disableCloseOnSelect
                fullWidth
                value={countries.filter((option) =>
                  // @ts-ignore
                  formik.values.flowFundsPaymentAllowed?.some((v) => option.value === v),
                )}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'flowFundsPaymentAllowed',
                    // @ts-ignore
                    newValue.map((nextValue) => nextValue.value),
                  );
                }}
                ChipProps={{ color: 'secondary', size: 'small' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Payment countries"
                    placeholder="Select payment countries"
                  />
                )}
              />

              <Typography variant="subtitle1" sx={{ pt: 6 }}>
                Breakdown of Flow of Funds per Country
                <Typography variant="subtitle1" component="span" color="error" sx={{ fontWeight: 600 }}> NOT </Typography>
                to be routed through OpenPayd
              </Typography>
              <Autocomplete
                multiple
                id="flowFundsReceiptRestricted"
                options={countries}
                getOptionLabel={(option) => option.label}
                limitTags={2}
                disableCloseOnSelect
                fullWidth
                value={countries.filter((option) =>
                  // @ts-ignore
                  formik.values.flowFundsReceiptRestricted?.some((v) => option.value === v),
                )}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'flowFundsReceiptRestricted',
                    newValue.map(
                      // @ts-ignore
                      (nextValue) => nextValue.value,
                    ),
                  );
                }}
                ChipProps={{ color: 'secondary', size: 'small' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Receipt countries"
                    placeholder="Select receipt countries"
                  />
                )}
              />
              <Autocomplete
                multiple
                id="flowFundsPaymentRestricted"
                options={countries}
                getOptionLabel={(option) => option.label}
                limitTags={2}
                disableCloseOnSelect
                fullWidth
                value={countries.filter((option) =>
                  // @ts-ignore
                  formik.values.flowFundsPaymentRestricted?.some((v) => option.value === v),
                )}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'flowFundsPaymentRestricted',
                    // @ts-ignore
                    newValue.map((nextValue) => nextValue.value),
                  );
                }}
                ChipProps={{ color: 'secondary', size: 'small' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Payment countries"
                    placeholder="Select payment countries"
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
      </ModalDialog>
    </>
  );
};
