import { removeEmptyProperties } from 'utils/modelHelper';
import { gatewayApi } from '../gatewayApi';

const riskScoringOptionsApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getOptions: build.query<any, { name: string; version: number }>({
      query: ({ name, version }) => ({
        url: `risk-scoring/options/${name}`,
        params: { version },
        method: 'GET',
      }),
      providesTags: (result, error, { name }) => [{ type: 'RiskScoring_Options', id: name }],
    }),

    addOption: build.mutation({
      query(model) {
        const body = { ...model };
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/options`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { name }) => [{ type: 'RiskScoring_Options', id: name }],
    }),

    updateOption: build.mutation({
      query(model) {
        const body = { ...model };
        delete body.id;
        removeEmptyProperties(body);
        return {
          url: `risk-scoring/options/${model.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { name }) => [{ type: 'RiskScoring_Options', id: name }],
    }),

    deleteOption: build.mutation({
      query: (id) => ({
        url: `risk-scoring/options/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { name }) => [{ type: 'RiskScoring_Options', id: name }],
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useAddOptionMutation,
  useUpdateOptionMutation,
  useDeleteOptionMutation,
} = riskScoringOptionsApi;
