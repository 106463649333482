import { SortOptions } from 'types';

export const sortOptions: SortOptions[] = [
  // { fieldName: 'companyName', label: 'Company Name' },
  { fieldName: 'mainStatus', label: 'Application Status' },
  // { fieldName: 'assignee', label: 'Assigned to' },
  { fieldName: 'riskStatus', label: 'Risk Scoring' },
  { fieldName: 'createdDate', label: 'Created Date' },
  { fieldName: 'modifiedDate', label: 'Modified Date' },
  // { fieldName: 'pepStatus', label: 'PEP & Sanctions' },
  // { fieldName: 'salesManager', label: 'Sales Manager' },
  // { fieldName: 'kycStatus', label: 'KYC' },
];
