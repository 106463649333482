import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { IFilterValue } from 'uikit/PageControl/PageControlFilter/PageControlFilterTypes';
import { RiCloseCircleLine } from 'react-icons/ri';
import { styled } from '@mui/material/styles';
import { ValueLabel } from '../../../../types';

const StyledHeader = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    mt: 1,
    mb: 0,
    width: '100%',
    p: '2px 4px',
    color: 'grey.600',
    fontSize: '16px',
    fontWeight: 500,
  }),
);

const allignChipsStyle = {
  '.MuiTextField-root': {
    '.MuiOutlinedInput-root': { pt: '14px' },
    '.MuiChip-label': { pr: 2 },
    '.MuiChip-deleteIcon': { mr: 1 },
  },
};

interface FilterRowMultiSelectProps {
  label: string;
  fieldName: string;
  options: ValueLabel[];
  filterQuery: IFilterValue;
  onChange?: (fieldName: string, selectedOptions: string[]) => void;
  onRemove?: (fieldName: string) => void;
}

export const FilterRowMultiSelect: React.FC<FilterRowMultiSelectProps> = ({
  label,
  fieldName,
  options,
  filterQuery,
  onChange,
  onRemove,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => setSelectedOptions(filterQuery[fieldName] ?? []), [filterQuery[fieldName]]);

  return (
    <Box sx={{ my: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 3 }}>
        <StyledHeader variant="h4">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <div>{label}</div>
            {onRemove && (
              <IconButton size="small" onClick={() => onRemove(fieldName)}>
                <RiCloseCircleLine />
              </IconButton>
            )}
          </Stack>
        </StyledHeader>
      </Stack>

      <Autocomplete
        multiple
        options={options}
        value={selectedOptions.map((value) => options.find((option) => option.value === value))}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              sx={{ mr: 2 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => <TextField {...params} fullWidth />}
        onChange={(e, newValue) => {
          const updatedSelection = newValue
            ? newValue.map((option) => option.value.toString())
            : [];
          setSelectedOptions(updatedSelection);
          if (onChange) {
            onChange(fieldName, updatedSelection);
          }
        }}
        sx={allignChipsStyle}
      />
    </Box>
  );
};
