import { v4 as uuidV4 } from 'uuid';
// this functions purpose is prepare Rule Set data coming from API for SortableTree react module
// for doing this recurive function used
const prepareRuleSetForTree = (rules) =>
  rules.map((item) => {
    const obj = item;
    obj.title = obj.name;
    delete obj.name;
    obj.children = obj.childRules;
    delete obj.childRules;
    obj.expanded = true;
    if (obj.children.length) {
      prepareRuleSetForTree(obj.children);
    }
    if (!obj.id) {
      obj.id = uuidV4();
    }
    return obj;
  });

// this functions purpose is prepare Rule Set data coming from SortableTree react module for API
// for doing this recurive function used
const prepareRules = (rules) => {
  let order = 0;
  return rules.map((item) => {
    const obj = item;
    obj.name = obj.title;
    obj.childRules = obj.children;
    if (obj.deleteId) {
      delete obj.id;
    }
    delete obj.deleteId;
    delete obj.newRule;
    delete obj.children;
    delete obj.expanded;
    delete obj.title;
    delete obj.ruleCategory;
    obj.ruleOrder = order;
    order += 1;
    if (obj.childRules.length) {
      prepareRules(obj.childRules);
    }
    return obj;
  });
};
// this functions purpose is prepare Rule Set data coming from SortableTree react module for API
// for doing this recursive function used
const prepareRuleSetForApi = (ruleSet, selectedProduct) => {
  const date = new Date();
  const postObject = { id: date.getTime(), ruleSet };
  delete postObject.ruleSet.prepared;

  postObject.ruleSet.rules = prepareRules(postObject.ruleSet.rules);
  postObject.ruleSet.ownedProduct = selectedProduct;
  return postObject;
};
const scriptify = (rules, isInner, isMerchant) => {
  let script = '';
  let varBlock =
    'var customerId = message.payload.customer.id;var rating = 0;var p = message.payload;';
  let returnBlock = 'return new com.eft.model.RiskRating(p.customer.id, rating);';
  if (isMerchant) {
    varBlock =
      'var merchantId = message.payload.merchant.id; var rating = 0; var p = message.payload;';
    returnBlock = 'return new com.eft.model.RiskRating(merchantId, rating);';
  }
  if (!isInner) script += varBlock;
  for (let i = 0; i < rules.length; i += 1) {
    const obj = rules[i];
    const aritmetic = obj.answer > 0 ? '+' : '';
    const answerBlock = ` rating = rating${aritmetic}${obj.answer};`;
    // make your scriptify
    const condition = obj.script.replace(/"/g, "'");
    script += `if(${condition})`;
    if (obj.childRules.length) {
      script += '{';
      script += answerBlock;
      const isInnerCall = true;
      script += scriptify(obj.childRules, isInnerCall);
      script += '}';
    } else {
      script += answerBlock;
    }
  }
  if (!isInner) script += returnBlock;
  return script;
};
// this functions purpose is prepare Valuation Set data coming from SortableTree react module for API
// for doing this recursive function used
const prepareValuationSetForApi = (ruleSet) => {
  const meta = ruleSet;
  meta.rules = prepareRules(ruleSet.rules);
  delete meta.prepared;
  let isMerchant = false;
  const isInner = false;
  if (ruleSet.status === 'MerchantRiskRating') isMerchant = true;
  const sourceCode = scriptify(meta.rules, isInner, isMerchant);
  const postObject = {
    sourceCode,
    description: ruleSet.description,
    meta: JSON.stringify(meta),
    category: ruleSet.status,
  };
  if (ruleSet.id) postObject.id = ruleSet.id;
  delete meta.prepared;
  return postObject;
};

export { prepareRuleSetForTree, prepareRuleSetForApi, prepareValuationSetForApi };
