import React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import {
  useGetApplicationRecordsByApplicationIdQuery,
  useGetApplicationSectionsByApplicationIdQuery,
  useGetApplicationSectionStatusesQuery,
  useGetFieldValidationsQuery,
} from '@haystack-forms/api';
import { NavButtons } from './NavButtons';
import { FormTabsList } from './FormTabsList';
import { CPForm } from './CPForm';
import {
  notEditableSectionStatuses,
  notEditableStatuses,
  notEditableStatusesForForms,
} from '../../../constants/applicationStatusOptions';
import { useGetApplicationStatesQuery } from 'services/gatewayApi/stateMachineApi';

const SectionFormsContainer = styled('div', {
  name: 'SectionFormsContainer',
  slot: 'root',
})({
  display: 'flex',
  maxWidth: '100%',
  minHeight: '40vh',
  gap: '1rem',
});

const LeftColumn = styled('div', {
  name: 'LeftColumn',
  slot: 'root',
})({
  flex: 1,
  maxWidth: '25%',
});

const StickyMenu = styled('div', {
  name: 'StickyMenu',
  slot: 'root',
})({
  position: 'sticky',
  top: 0,
  padding: '2rem 0 0',
});

const RightColumn = styled('div', {
  name: 'RightColumn',
  slot: 'root',
})({
  flex: 3,
  padding: '2rem 0 0',
});

export const CPForms = ({}): JSX.Element => {
  const { applicationId, sectionId, partition } = useParams<{
    applicationId: string;
    sectionId: string;
    partition: string;
  }>();

  const { data: fieldValidations, isLoading: isLoadingValidations } =
    useGetFieldValidationsQuery(undefined);

  const { mainStatus, isLoadingMainStatus } = useGetApplicationStatesQuery(applicationId, {
    selectFromResult: ({ data, isLoading }) => ({
      mainStatus: data?.currentState,
      isLoadingMainStatus: isLoading,
    }),
  });

  const { data: allRecords, isLoadingRecords } = useGetApplicationRecordsByApplicationIdQuery(
    applicationId,
    {
      skip: !applicationId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        data: data?.entities,
        isLoadingRecords: isLoading || isFetching,
      }),
    },
  );

  const { data: sectionStatus } = useGetApplicationSectionStatusesQuery(applicationId, {
    selectFromResult: ({ data, isLoading }) => ({
      data: data?.[`${sectionId}-${partition}`],
      isLoadingStatus: isLoading,
    }),
    refetchOnMountOrArgChange: true,
  });

  const { data: section } = useGetApplicationSectionsByApplicationIdQuery(
    { applicationId, records: allRecords },
    {
      selectFromResult: ({ data: data, isLoading, isFetching }) => ({
        data: data?.find((el) => el.id === Number(sectionId) && el.partitionName === partition),
        isLoadingSections: isLoading || isFetching,
      }),
      skip: !applicationId || !allRecords || isLoadingRecords,
      refetchOnMountOrArgChange: true,
    },
  );

  const isDisabledBySectionStatus = section
    ? notEditableSectionStatuses.includes(sectionStatus)
    : true;
  const disabledByMainStatus =
    notEditableStatuses.some((el) => el === mainStatus) || isLoadingMainStatus;
  const disabledByMainStatusForForms =
    notEditableStatusesForForms.some((el) => el === mainStatus) || isLoadingMainStatus;

  const isEditDisabled =
    disabledByMainStatusForForms || disabledByMainStatus || isDisabledBySectionStatus;

  const formsTabValues =
    allRecords &&
    section?.forms.map(({ id }, idx) => ({
      formId: id,
      tabValue: `${0}${idx}`,
    }));

  const [tabValue, setTabValue] = React.useState('00');
  const handleTabChange = (value: string) => {
    setTabValue(value);
  };

  return applicationId && section && sectionStatus && !isLoadingValidations ? (
    <div className="DynamicForms">
      <SectionFormsContainer>
        <TabContext value={tabValue}>
          <LeftColumn>
            <StickyMenu>
              <FormTabsList
                section={section}
                tabValue={tabValue}
                handleChange={handleTabChange}
                applicationId={applicationId}
              />
            </StickyMenu>
          </LeftColumn>

          <RightColumn>
            {section.forms.map((form, formIdx) => (
              <TabPanel value={`0${formIdx}`} key={form.id} sx={{ width: '100%', padding: 0 }}>
                <CPForm
                  formId={form.id}
                  isEditable={!isEditDisabled || form.isInternal}
                  isInternal={form.isInternal}
                />
                <NavButtons
                  formId={form.id}
                  formsTabValues={formsTabValues}
                  setValue={handleTabChange}
                />
              </TabPanel>
            ))}
          </RightColumn>
        </TabContext>
      </SectionFormsContainer>
    </div>
  ) : (
    <div />
  );
};
