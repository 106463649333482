import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DetailsField } from 'uikit';
import { Record } from '../../../types/forms';

interface RenderKeyValueProps {
  childRecords: Record[];
  applicationId: string;
  ctId?: string;
  ctFormId?: string;
  formId: string;
  handleDeleteFormRecord: (ctId, ctFormId) => void;
  disabled?: boolean;
}

export const RenderKeyValue: React.FC<RenderKeyValueProps> = ({
  childRecords,
  ctId = undefined,
  ctFormId = undefined,
  formId,
  applicationId,
  handleDeleteFormRecord,
  disabled = false,
}) => (
  <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
    {childRecords.map((el) => (
      <Box width={`${el.weight}%`} key={`${el.id}_${el.row}_${el.col}`}>
        <DetailsField
          id={el.id}
          applicationId={applicationId}
          fieldType={el.field.type}
          label={!!ctId && !!ctFormId ? '' : el.field.title}
          value={el.value}
          formId={formId}
          record={el}
          updateOnChange
          disabled={disabled}
        />
      </Box>
    ))}
    {!!ctId && !!ctFormId ? (
      <IconButton
        size="small"
        className="complexTypeActionIcon"
        onClick={() => handleDeleteFormRecord(ctId, ctFormId)}
        disabled={disabled}
      >
        <DeleteIcon />
      </IconButton>
    ) : (
      <Box ml={2} p="6px">
        <Box width="24px" />
      </Box>
    )}
  </Stack>
);
