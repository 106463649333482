/** page for Create new user or Edit one */

/** npm */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeUserData } from 'selectors/user';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as _ from 'lodash';
import { Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
/** global modules */
import {
  Skeleton,
  PageControl,
  StickyHeader,
  PageContainer,
  Button,
  DialogSelectFromList,
  ChipSelector,
} from 'uikit';
import {
  toastAddSuccess,
  toastCreateSuccess,
  toastDeleteSuccess,
  toastUpdateSuccess,
} from 'utils/toast';
/** this module */
import {
  useAddTenantsMutation,
  useAddUserMutation,
  useGetGroupsMultiQuery,
  useGetGroupsQuery,
  useGetTenantsQuery,
  useGetUserQuery,
  useRemoveTenantsMutation,
  useUpdateUserMutation,
  useAddGroupsMutation,
  useRemoveGroupsMutation,
  useSendActivationEmailMutation,
  useAddGroupsMultiMutation,
  useRemoveGroupsMultiMutation,
  useGetDepartmentsQuery,
  useGetDepartmentsMultiQuery,
  useAddDepartmentsMultiMutation,
  useAddDepartmentsMutation,
  useRemoveDepartmentsMutation,
  useRemoveDepartmentsMultiMutation,
} from 'services/gatewayApi/usersApi';
import { initialUser } from '../constants';
import { Group, GroupsMutation, GroupsMutationMulti, Tenant } from '../types';

const UserPage = () => {
  const params = useParams();
  const [id, setId] = useState(params.id);
  const isNew = id === 'new';
  const navigate = useNavigate();

  const { selectedProductId, permissions } = useSelector(makeUserData());
  const isSuperAdmin = permissions?.some((el) => el?.name === 'SUPER_ADMIN');

  const { rolesMulti = [], isLoadingRolesMulti } = useGetGroupsMultiQuery(
    { undefined },
    {
      skip: !isSuperAdmin,
      selectFromResult: ({ data = [], isLoading }) => ({
        // @ts-ignore
        rolesMulti: Object.values(data).flat(),
        isLoadingRolesMulti: isLoading,
      }),
    },
  );
  const { rolesTenant = [], isLoadingRolesTenant } = useGetGroupsQuery(
    { undefined },
    {
      skip: isSuperAdmin,
      selectFromResult: ({ data = [], isLoading }) => ({
        // @ts-ignore
        rolesTenant: Object.values(data)
          .flat()
          .filter((el: any) => el?.permissions?.some((element) => element.name !== 'SUPER_ADMIN')),
        isLoadingRolesTenant: isLoading,
      }),
    },
  );
  const { departments, isLoadingDepartments } = useGetDepartmentsQuery(undefined, {
    skip: isSuperAdmin,
    selectFromResult: ({ data = [], isLoading, isFetching }) => ({
      departments: data.map((el) => ({
        value: el.id,
        label: el.category,
        tenantId: selectedProductId,
      })),
      isLoadingDepartments: isLoading,
      isFetchingDepartments: isFetching,
    }),
  });
  const { departmentsMulti = [], isLoadingDepartmentsMulti } = useGetDepartmentsMultiQuery(
    undefined,
    {
      skip: !isSuperAdmin,
      selectFromResult: ({ data = [], isLoading, isFetching }) => ({
        // @ts-ignore
        departmentsMulti: Object.keys(data)
          .map((tenantId) =>
            data[tenantId].map((el) => ({
              value: el.id,
              label: el.category,
              tenantId: Number(tenantId),
              // @ts-ignore
            })),
          )
          .flat(),
        isLoadingDepartmentsMulti: isLoading,
        isFetchingDepartmentsMulti: isFetching,
      }),
    },
  );

  const rolesList = isSuperAdmin ? (rolesMulti as Group[]) : (rolesTenant as Group[]);

  const { data: productsList, isLoading: isTenantsLoading } = useGetTenantsQuery(undefined);
  const {
    data: user,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetUserQuery({ id }, { skip: isNew });

  const [addUser, statusesAddUser] = useAddUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [addTenants] = useAddTenantsMutation();
  const [removeTenants] = useRemoveTenantsMutation();
  const [addGroups] = useAddGroupsMutation<GroupsMutation>();
  const [addGroupsMulti] = useAddGroupsMultiMutation<GroupsMutationMulti>();
  const [removeGroups] = useRemoveGroupsMutation<GroupsMutation>();
  const [removeGroupsMulti] = useRemoveGroupsMultiMutation<GroupsMutationMulti>();
  const [sendActivationEmail, statusesSendLink] = useSendActivationEmailMutation();
  const [addDepartments] = useAddDepartmentsMutation();
  const [addDepartmentsMulti] = useAddDepartmentsMultiMutation();
  const [removeDepartments] = useRemoveDepartmentsMutation();
  const [removeDepartmentsMulti] = useRemoveDepartmentsMultiMutation();

  const [assignedProducts, setAssignedProducts] = useState<Tenant[]>([]); // user Tenants
  const [selectedRoles, setSelectedRoles] = useState<Group[]>([]); // user Roles(Groups)
  const [tenantForEditRoles, setTenantForEditRoles] = useState<number | null>(null);
  const [isOpenRoles, setIsOpenRoles] = useState<boolean>(false);
  const [isOpenTenants, setIsOpenTenants] = useState<boolean>(false);
  const [isNoChanged, setIsNoChanged] = useState<boolean>(true);
  const [selectedDepartments, setSelectedDepartments] = useState<
    { value: number; label: string; tenantId?: number }[]
  >([]);

  useEffect(() => {
    if (!!user && !!user?.tenantDepartments) {
      const userDepartments = user?.tenantDepartments?.map((el) => ({
        value: el.id,
        label: el.category,
        tenantId: el?.tenantId,
      }));
      setSelectedDepartments(userDepartments);
    }
  }, [isLoadingUser, isFetchingUser]);

  const defaultUserTenant = user?.tenantId;
  const isLoading =
    isLoadingUser ||
    isLoadingRolesMulti ||
    isLoadingRolesTenant ||
    isTenantsLoading ||
    isLoadingDepartments ||
    isLoadingDepartmentsMulti;

  const modelSelectedUser = {
    name: user?.name,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    enabled: user?.enabled,
    emailVerified: user?.emailVerified,
  };

  const validationSchema = yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().email('Please use a valid email address.').required('Required'),
  });
  const enableReinitialize = !isLoadingUser || !isFetchingUser;

  const initialValues = isNew ? { ...initialUser } : { ...user };

  const formik = useFormik({
    enableReinitialize,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      if (id === 'new') {
        const model = {
          name: `${values.firstName} ${values.lastName}`, // TODO remove field when backend be ready
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          enabled: true,
          emailVerified: false,
        };
        const mutationPromise = await addUser(model);
        if (!mutationPromise.error) {
          toastCreateSuccess('User');
        }
      } else {
        const modelUser = {
          name: `${values.firstName} ${values.lastName}`, // TODO remove field when backend be ready
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          enabled: values.enabled,
          emailVerified: values.emailVerified,
        };

        const modelAddTenants = assignedProducts
          .filter((item) => item.id && user.tenants.filter((el) => el.id === item.id).length === 0)
          .map((element) => element.id);
        const modelRemoveTenants = user.tenants
          .filter(
            (item) => item.id && assignedProducts.filter((el) => el.id === item.id).length === 0,
          )
          .map((element) => element.id);

        const modelAddGroups = assignedProducts.map((userTenant) => {
          const tenantId = userTenant.id;
          const groupsForAdd = selectedRoles
            .filter(
              (group) =>
                tenantId === group?.tenantId &&
                user.tenantGroups.filter((el) => el.id === group.id).length === 0,
            )
            .map((element) => element.id);
          if (groupsForAdd.length === 0) return null;
          return { tenantId, groupsForAdd };
        });

        const modelRemoveGroups = assignedProducts.map((userTenant) => {
          const groupsForRemove = user.tenantGroups
            .filter(
              (group) =>
                userTenant.id === group?.tenantId &&
                selectedRoles.filter((el) => el.id === group.id).length === 0,
            )
            .map((element) => element.id);
          if (groupsForRemove.length === 0) return null;
          return { tenantId: userTenant.id, groupsForRemove };
        });

        const userDepartments = user?.tenantDepartments?.map((el) => el.id);
        const formDepartments = selectedDepartments.map((el) => el.value);
        const modelRemoveDepartments = _.difference(userDepartments, formDepartments);
        const modelAddDepartments = _.difference(formDepartments, userDepartments);

        isSuperAdmin &&
          assignedProducts.forEach((tenant) => {
            const tenantId = tenant.id;
            const tenantAddDepartments = selectedDepartments
              .filter(
                (el) =>
                  el?.tenantId === tenantId && modelAddDepartments.some((v) => v === el.value),
              )
              .map((el) => el.value);
            const tenantRemoveDepartments = user?.tenantDepartments
              .filter(
                (el) =>
                  el?.tenantId === tenantId && modelRemoveDepartments.some((v) => v === el.id),
              )
              .map((el) => el.id);

            if (tenantAddDepartments.length > 0) {
              addDepartmentsMulti({
                tenantId,
                userId: user.id,
                departmentIds: tenantAddDepartments,
              });
            }
            if (tenantRemoveDepartments.length > 0) {
              removeDepartmentsMulti({
                tenantId,
                userId: user.id,
                departmentIds: tenantRemoveDepartments,
              });
            }
          });

        if (!isSuperAdmin && modelAddDepartments.length > 0) {
          const mutationPromise = await addDepartments({ id: user.id, list: modelAddDepartments });
          if (!mutationPromise.error) {
            toastAddSuccess('User departments');
          }
        }

        if (!isSuperAdmin && modelRemoveDepartments.length > 0) {
          const mutationPromise = await removeDepartments({
            id: user.id,
            list: modelRemoveDepartments,
          });
          if (!mutationPromise.error) {
            toastDeleteSuccess('User departments');
          }
        }

        if (!_.isEqual(modelUser, modelSelectedUser)) {
          const mutationPromise = await updateUser({ id, body: modelUser });
          if (!mutationPromise.error) {
            toastUpdateSuccess('User');
          }
        }

        if (modelAddTenants.length > 0) {
          const mutationPromise = await addTenants({
            id: user.id,
            list: modelAddTenants,
            tenantId: selectedProductId, // irrelevant for API
          });
          if (!mutationPromise.error) {
            toastCreateSuccess('User tenant');
          }
        }

        if (modelRemoveTenants.length > 0) {
          const mutationPromise = await removeTenants({
            id: user.id,
            list: modelRemoveTenants,
            tenantId: selectedProductId, // irrelevant for API
          });
          if (!mutationPromise.error) {
            toastDeleteSuccess('User tenant');
          }
        }

        if (modelAddGroups.length > 0) {
          modelAddGroups.map((el) => {
            if (el === null) return null;
            if (isSuperAdmin) {
              const mutationPromise = addGroupsMulti({
                tenantId: el?.tenantId,
                userId: user.id,
                groupIds: el.groupsForAdd,
              });
              if (!mutationPromise.error) {
                toastAddSuccess('User roles');
              }
            } else {
              const mutationPromise = addGroups({
                userId: user.id,
                groupsIds: el.groupsForAdd,
              });
              if (!mutationPromise.error) {
                toastAddSuccess('User role');
              }
            }
            return null;
          });
        }

        if (modelRemoveGroups.length > 0 && isSuperAdmin) {
          modelRemoveGroups.map((el) => {
            if (el === null) return null;
            const mutationPromise = removeGroupsMulti({
              tenantId: el?.tenantId,
              userId: user.id,
              groupIds: el.groupsForRemove,
            });
            if (!mutationPromise.error) {
              toastDeleteSuccess('User roles');
            }
            return null;
          });
        }

        if (modelRemoveGroups.length > 0 && !isSuperAdmin) {
          modelRemoveGroups.map((el) => {
            if (el === null) return null;
            const mutationPromise = removeGroups({
              userId: user.id,
              groupsIds: el.groupsForRemove,
            });
            if (!mutationPromise.error) {
              toastDeleteSuccess('User roles');
            }
            return null;
          });
        }

        resetForm();
        setSubmitting(false);
      }
    },
  });

  const handlePrimaryAction = () => {
    sendActivationEmail(user.id);
  };

  const handleManageRoles = (tenantId) => {
    setIsOpenRoles(true);
    setTenantForEditRoles(tenantId);
  };

  const isHaveSuperAdmin = (group: undefined | Group) =>
    group?.permissions.some((el) => el.name === 'SUPER_ADMIN') || false;

  const handleUpdateTenants = (tenants: Tenant[]) => {
    setAssignedProducts(tenants);

    if (selectedRoles.some((el) => isHaveSuperAdmin(el))) {
      // add superAdminRole for new tenants
      tenants.map((userProduct) => {
        if (selectedRoles.some((item) => item.tenantId === userProduct.id)) {
          return null;
        }
        const superRoleToAdd = rolesList.find(
          (el) => el.tenantId === userProduct.id && isHaveSuperAdmin(el),
        );
        return superRoleToAdd ? setSelectedRoles((prev) => [...prev, superRoleToAdd]) : null;
      });
    }
    setIsOpenTenants(false);
  };

  const handleRemoveTenant = ([tenantIdToRemove]) => {
    setAssignedProducts((prev) => prev.filter((el) => el.id !== tenantIdToRemove));
    setSelectedRoles((prev) => prev.filter((el) => el?.tenantId !== tenantIdToRemove));
  };

  const handleUpdateRoles = (roles: Group[]) => {
    const getOtherTenantsRoles = () => {
      // add superAdminRole for all user tenants
      if (
        !selectedRoles.some((el) => isHaveSuperAdmin(el)) &&
        roles.some((el) => isHaveSuperAdmin(el))
      ) {
        const superAdmins = assignedProducts
          .filter((tenant) => tenant.id !== tenantForEditRoles)
          .map(
            (userProduct) =>
              rolesList.filter((el) => isHaveSuperAdmin(el) && el.tenantId === userProduct.id)[0],
          );
        return [
          ...superAdmins,
          ...selectedRoles.filter((element) => element?.tenantId !== tenantForEditRoles),
        ].filter((el) => !!el);
      }
      // remove superAdminRole from all user tenants
      if (
        selectedRoles.some((el) => isHaveSuperAdmin(el)) &&
        !roles.some((el) => isHaveSuperAdmin(el))
      ) {
        return selectedRoles.filter(
          (el) => el.tenantId !== tenantForEditRoles && !isHaveSuperAdmin(el),
        );
      }
      return selectedRoles.filter((el) => el?.tenantId !== tenantForEditRoles);
    };
    setSelectedRoles([...roles, ...getOtherTenantsRoles()]);
    setTenantForEditRoles(null);
    setIsOpenRoles(false);
  };

  const handleRemoveRole = (role) => {
    setSelectedRoles((prev) =>
      prev.filter((el) =>
        isHaveSuperAdmin(role)
          ? !el.permissions.some((permission) => permission.name === 'SUPER_ADMIN')
          : el.id !== role.id,
      ),
    );
  };

  const handleResetUser = () => {
    setAssignedProducts(user.tenants);
    setSelectedRoles(user.tenantGroups);
    setSelectedDepartments(
      user.tenantDepartments?.map((el) => ({
        value: el.id,
        label: el.category,
        tenantId: el?.tenantId,
      })),
    );
    formik.resetForm();
  };

  const getSuperAdminRole = () => {
    if (isSuperAdmin) {
      return [];
    }
    const superAdminRoleId = rolesList.filter(
      (el) => el.tenantId == selectedProductId && isHaveSuperAdmin(el),
    )[0].id;
    return [superAdminRoleId];
  };
  const checkIsUserChanged = () => {
    const formProducts = assignedProducts?.map((el) => el.id);
    const userProducts = user?.tenants?.map((el) => el.id);
    const formRoles = selectedRoles?.map((el) => el.id);
    const userRoles = user?.tenantGroups?.map((el) => el.id);
    const userDepartments = user?.tenantDepartments?.map((el) => el.id);
    const formDepartments = selectedDepartments.map((el) => el.value);
    const status =
      formik.values.firstName !== user?.firstName ||
      formik.values.lastName !== user?.lastName ||
      !_.isEqual(userProducts, formProducts) ||
      !_.isEqual(userRoles, formRoles) ||
      !_.isEqual(userDepartments, formDepartments);
    return setIsNoChanged(!status);
  };

  const getMessage = () => {
    if (isNew) {
      return '';
    }
    return user?.emailVerified ? 'email verified' : 'email not verified';
  };

  useEffect(() => {
    if (user?.tenants) {
      setAssignedProducts(user.tenants);
      setSelectedRoles(user.tenantGroups);
    }
  }, [isLoadingUser]);

  useEffect(() => {
    checkIsUserChanged();
  }, [
    isLoadingUser,
    isFetchingUser,
    formik.values,
    assignedProducts,
    selectedRoles,
    selectedDepartments,
  ]);

  useEffect(() => {
    if (statusesAddUser.status === 'fulfilled') {
      const newUserId = statusesAddUser.data?.id;
      setId(newUserId);
      toastCreateSuccess(`User ${statusesAddUser.data?.name}`);
    }
  }, [statusesAddUser]);

  useEffect(() => {
    if (statusesSendLink.isSuccess === true) {
      toastCreateSuccess(`Activation link for ${user.email}`);
    }
  }, [statusesSendLink]);

  const departmentsList = isSuperAdmin ? departmentsMulti : departments;

  const handleChangeDepartments = (tenantId, newValues) => {
    setSelectedDepartments((prev) => [
      ...prev.filter((el) => el.tenantId !== tenantId),
      ...newValues,
    ]);
  };

  const UserRoles = () => (
    <>
      {!isLoading &&
        assignedProducts?.map((tenant) => {
          if (!isSuperAdmin && tenant.id !== selectedProductId) {
            return null;
          }
          const listTenantRoles = selectedRoles?.filter((el) => el.tenantId === tenant.id) || [];
          return (
            <Grid
              item
              container
              sx={{ width: '100%' }}
              key={tenant.id}
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item md={6}>
                <Typography sx={{ fontWeight: 500 }}>{tenant.name}</Typography>
              </Grid>
              <Grid item md={6} container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <ChipSelector
                    list={departmentsList.filter((el) => el.tenantId === tenant.id)}
                    selectedList={selectedDepartments.filter((el) => el.tenantId === tenant.id)}
                    setSelectedList={(newValues) => handleChangeDepartments(tenant.id, newValues)}
                    limitTags={3}
                    messageEmpty="no departments"
                    color="default"
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    theme="primary"
                    variant="light"
                    onClick={() => handleManageRoles(tenant.id)}
                  >
                    Manage roles
                  </Button>
                </Grid>
                {isSuperAdmin && (
                  <Grid item>
                    <Button
                      theme="danger"
                      variant="light"
                      disabled={defaultUserTenant === tenant.id}
                      onClick={() => handleRemoveTenant([tenant.id])}
                    >
                      Remove
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12} sx={{ pt: '8px' }}>
                <Divider variant="fullWidth" />
              </Grid>
              {listTenantRoles.length > 0 ? (
                listTenantRoles.map((role) => (
                  <Grid item md={12} key={role.id}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>{role.name}</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton size="small" onClick={() => handleRemoveRole(role)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid item md={12}>
                  <Typography sx={{ textSize: '10px', color: 'grey[300]' }}>
                    no roles assigned for this product
                  </Typography>
                </Grid>
              )}
            </Grid>
          );
        })}
    </>
  );

  return (
    <>
      {isOpenRoles && (
        <DialogSelectFromList
          title="Select roles"
          placeholder="Find Role by name, description or role Id"
          list={rolesList.filter((el) => el.tenantId === tenantForEditRoles)}
          defaultList={getSuperAdminRole()}
          selectedList={selectedRoles.filter((el) => el.tenantId === tenantForEditRoles)}
          setSelectedList={handleUpdateRoles}
          open={isOpenRoles}
          onClose={() => {
            setIsOpenRoles(false);
          }}
          haveSearch
        />
      )}

      {isOpenTenants && (
        <DialogSelectFromList
          title="Select products"
          list={productsList}
          defaultList={[defaultUserTenant]}
          selectedList={assignedProducts}
          setSelectedList={handleUpdateTenants}
          open={isOpenTenants}
          onClose={() => {
            setIsOpenTenants(false);
          }}
          haveSearch={false}
        />
      )}
      <PageContainer>
        <form id="userForm" onSubmit={formik.handleSubmit} autoComplete="off">
          <Helmet title={isNew ? 'Create User' : 'Edit User'} />
          <StickyHeader>
            <PageControl
              title={isNew ? 'Create User' : 'Edit User'}
              primaryAction={isNew ? undefined : !user?.emailVerified && handlePrimaryAction}
              primaryActionTitle={isNew ? undefined : 'Resend activation link'}
              actionMessage={getMessage()}
            />
            <Typography sx={{ fontSize: '0.7rem', pb: '12px' }}>{`User ID: ${id}`}</Typography>
          </StickyHeader>

          <Grid container direction="row" spacing={10}>
            {/* left column - User details */}
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              md={4}
              spacing={4}
            >
              <Grid item sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>User details</Typography>
                <Divider variant="fullWidth" light sx={{ pt: '8px' }} />
              </Grid>
              {isLoadingUser ? (
                <Grid item sx={{ width: '100%' }}>
                  <Skeleton count={3} height={40} />
                </Grid>
              ) : (
                <>
                  <Grid item sx={{ width: '100%' }}>
                    <TextField
                      label="First name"
                      name="firstName"
                      variant="outlined"
                      error={formik.touched.name && Boolean(formik.errors.firstName)}
                      size="small"
                      fullWidth
                      value={formik.values.firstName || ''}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <TextField
                      label="Last name"
                      name="lastName"
                      variant="outlined"
                      error={formik.touched.name && Boolean(formik.errors.lastName)}
                      size="small"
                      fullWidth
                      value={formik.values.lastName || ''}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <TextField
                      disabled={id !== 'new'}
                      label="E-mail"
                      name="email"
                      variant="outlined"
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      size="small"
                      fullWidth
                      value={formik.values.email || ''}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {/* right column - User Products & Roles */}
            {!isNew && (
              <Grid
                item
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                md={8}
                spacing={4}
              >
                <Grid item sx={{ width: '100%' }}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                    User Products & Roles
                  </Typography>
                  <Divider variant="fullWidth" light sx={{ pt: '8px' }} />
                </Grid>
                {isLoadingUser ? (
                  <Grid item sx={{ width: '100%' }}>
                    <Skeleton count={3} height={40} />
                  </Grid>
                ) : (
                  <UserRoles />
                )}
                {!isNew && isSuperAdmin && (
                  <Grid
                    item
                    sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', pb: 4 }}
                  >
                    <Button
                      type="button"
                      block
                      theme="base"
                      variant="text"
                      onClick={() => setIsOpenTenants(true)}
                    >
                      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <AddIcon /> Add Product
                      </Grid>
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          <Divider variant="fullWidth" light sx={{ pt: '12px' }} />

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ pt: 4, pb: 10 }}
          >
            <Grid item>
              <Button type="button" onClick={() => navigate(`/user-management`)} theme="base">
                Cancel
              </Button>
            </Grid>
            {!isNew && (
              <Grid item>
                <Button
                  type="button"
                  onClick={() => handleResetUser()}
                  theme="danger"
                  variant="light"
                  disabled={isNoChanged}
                >
                  Reset
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button type="submit" theme="primary" disabled={isNoChanged}>
                {isNew ? 'Create user' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </PageContainer>
    </>
  );
};

export { UserPage };
