import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import './NoResultsBox.scss';

interface NoResultsBoxProps {
  show: boolean;
  onResetFilters?: any;
}

export const NoResultsBox: React.FC<NoResultsBoxProps> = ({ show, onResetFilters }) => (
  <>
    {show && (
      <Box className="NoResultsBox">
        <Typography variant="h2">Nothing found</Typography>
        <Typography>Nothing was found for this query</Typography>
        {onResetFilters && (
          <Button variant="contained" color="error" onClick={onResetFilters}>
            Reset filters
          </Button>
        )}
      </Box>
    )}
  </>
);
