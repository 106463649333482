import { ValueLabel } from 'types';
import { FilterElement, PepDecision, PersonTypeValue } from './types';
import { SortOptions } from 'types';

export const sanctionRecordFields: ValueLabel[] = [
  { value: 'lastName', label: 'Last name' },
  { value: 'uid', label: 'UID' },
  { value: 'firstName', label: 'First name' },
  { value: 'dob', label: 'Date of birth' },
  { value: 'dobs', label: 'Date of birth alias' },
  { value: 'age', label: 'Age' },
  { value: 'yob', label: 'Year of birth' },
  { value: 'ageDate', label: 'Age Date' },
  { value: 'aliases', label: 'Aliases' },
  { value: 'ei', label: 'Gender/Business' },
  { value: 'alternativeSpelling', label: 'Alternative spelling' },
  { value: 'category', label: 'Category' },
  { value: 'updateCategory', label: 'Update Category' },
  { value: 'citizenship', label: 'Citizenship' },
  { value: 'companies', label: 'Companies' },
  { value: 'countries', label: 'Countries' },
  { value: 'entered', label: 'Entered' },
  { value: 'externalSources', label: 'External sources' },
  { value: 'furtherInformation', label: 'Further information' },
  { value: 'identificationNumbers', label: 'Identification numbers' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'linkedTo', label: 'Linked to' },
  { value: 'locations', label: 'Locations' },
  { value: 'lowQualityAliases', label: 'Low quality aliases' },
  { value: 'passports', label: 'Passports' },
  { value: 'pepRoles', label: 'PEP roles' },
  { value: 'pepStatus', label: 'PEP status' },
  { value: 'placeOfBirth', label: 'Place of birth' },
  { value: 'position', label: 'Position' },
  { value: 'subCategory', label: 'Sub category' },
  { value: 'title', label: 'Title' },
  { value: 'updated', label: 'Updated date' },
  { value: 'whitelisted', label: 'Whitelisted' },
];

export const CLEAN = 'CLEAN';
export const SELECT_ALL = 'SELECT_ALL';
export const PENDING = 'PENDING';
export const HIT = 'HIT';
export const FALSE_POSITIVE = 'FALSE_POSITIVE';

export const statusesPep: ValueLabel[] = [
  { value: PENDING, label: 'Pending' },
  { value: HIT, label: 'Hit' },
  { value: FALSE_POSITIVE, label: 'False positive' },
];

export const statusSearchSession = {
  NOT_DECIDED: 'Not decided',
  DRAFT: 'Draft',
  CLARIFICATION_NEEDED: 'Clarification needed',
  DECISION_MADE: 'Decision made',
  UNKNOWN: 'Not performed',
};

export const typeSearchSession = {
  SHAREHOLDER: 'Shareholder',
  UBO: 'UBO',
  UNKNOWN: 'Unknown',
};

export const sortOptions: SortOptions[] = [
  { fieldName: 'name', label: 'Name' },
  { fieldName: 'type', label: 'Type' },
  { fieldName: 'result', label: 'Result' },
  { fieldName: 'lastUpdated', label: 'Last updated' },
];
export const sortOptionsTransactions: SortOptions[] = [
  { fieldName: 'fullName', label: 'Name' },
  { fieldName: 'transactionId', label: 'Transaction ID' },
  { fieldName: 'result', label: 'Result' },
  { fieldName: 'performedOn', label: 'Last updated' },
];

export const paramsScheme: FilterElement[] = [
  {
    field: 'text',
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    searchType: 'individual',
    required: true,
  },
  {
    field: 'text',
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    searchType: 'individual',
    required: true,
  },
  {
    field: 'text',
    type: 'number',
    name: 'yob',
    label: 'Year of birth',
    searchType: 'individual',
  },
  {
    field: 'datePicker',
    name: 'dob',
    label: 'Date of birth',
    searchType: 'individual',
  },
  {
    field: 'text',
    type: 'text',
    name: 'passportNumber',
    label: 'Passport number',
    searchType: 'individual',
  },
  {
    field: 'text',
    type: 'text',
    name: 'idCardNumber',
    label: 'ID card number',
    searchType: 'individual',
  },
  {
    field: 'select',
    type: 'text',
    name: 'entities',
    label: 'Gender',
    searchType: 'individual',
    options: [
      { value: 'M', label: 'Male' },
      { value: 'F', label: 'Female' },
    ],
  },
  {
    field: 'text',
    type: 'text',
    name: 'lastName',
    label: 'Company name',
    searchType: 'business',
    required: true,
  },
  {
    field: 'text',
    type: 'number',
    name: 'yob',
    label: 'Registration year',
    searchType: 'business',
  },
  {
    field: 'datePicker',
    name: 'dob',
    label: 'Registration date',
    searchType: 'business',
  },
  {
    field: 'select',
    type: 'text',
    name: 'entities',
    label: 'Entity type',
    searchType: 'business',
    options: [
      { value: 'E', label: 'E' },
      { value: 'U', label: 'U' },
    ],
  },
  {
    field: 'text',
    type: 'text',
    name: 'address',
    label: 'Address',
    searchType: 'any',
  },
];

export const decisions: { value: PepDecision; label: string; color: string }[] = [
  { value: 'SANCTIONS_REJECTED', label: 'Sanctions rejected', color: 'error' },
  { value: 'PEP_REJECTED', label: 'PEP rejected', color: 'error' },
  {
    value: 'ADVERSE_MEDIA_REJECTED',
    label: 'Adverse media rejected',
    color: 'error',
  },
  {
    value: 'ENFORCEMENT_REJECTED',
    label: 'Enforcement rejected',
    color: 'error',
  },
  { value: 'PEP_ACCEPTED', label: 'PEP accepted', color: 'success' },
  {
    value: 'ADVERSE_MEDIA_ACCEPTED',
    label: 'Adverse media accepted',
    color: 'success',
  },
  {
    value: 'ENFORCEMENT_ACCEPTED',
    label: 'Enforcement accepted',
    color: 'success',
  },
];

export const entitiesLabel = {
  M: 'Male',
  F: 'Female',
  E: 'E',
  U: 'U',
};

export interface IPersonTypeLabel {
  UNKNOWN: PersonTypeValue;
  SHAREHOLDER: PersonTypeValue;
  UBO: PersonTypeValue;
  BOARD_MEMBER: PersonTypeValue;
  PARTNER: PersonTypeValue;
  OPENPAYD_USER: PersonTypeValue;
}

export const personTypeLabel: IPersonTypeLabel = {
  UNKNOWN: 'Unknown',
  SHAREHOLDER: 'Shareholder',
  UBO: 'UBO',
  BOARD_MEMBER: 'Board member',
  PARTNER: 'Partner',
  OPENPAYD_USER: 'OpenPayd user',
};
