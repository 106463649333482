import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDateScreen, formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES } from 'constants/constants';
import { Client } from '../../types';
import { infoFields } from '../../constants';

interface InfoTabProps {
  clientDetails: Client;
}

export const InfoTab: React.FC<InfoTabProps> = ({ clientDetails }) => {
  const RenderValue = ({ property }) => {
    const value = clientDetails[property];

    switch (property) {
      case 'incorporationDate':
        return (
          <Typography>
            {value === undefined ? '---' : formatDateScreen(value, DATE_FORMAT_MESSAGES)}
          </Typography>
        );
      case 'createdDate':
        return <Typography>{formatIsoDate(value, DATE_FORMAT_MESSAGES)}</Typography>;
      case 'clientCategories':
        return (
          <>
            {value.length > 0 ? (
              <Typography>{value.map((el) => el.name).join([', '])}</Typography>
            ) : (
              <Typography>no categories assigned</Typography>
            )}
          </>
        );
      default:
        return <Typography>{value}</Typography>;
    }
  };

  return (
    <>
      {infoFields.map((field) => (
        <Box
          key={field.value}
          sx={(theme) => ({ borderBottom: `1px dashed ${theme.palette.grey[200]}` })}
        >
          <Typography variant="body2" sx={{ pt: 2, pb: 1, color: 'grey.600' }}>
            {field.label}
          </Typography>
          <Box sx={{ pt: 0, pb: 2, color: 'grey.900', typography: 'body1' }}>
            <RenderValue property={field.value} />
          </Box>
        </Box>
      ))}
    </>
  );
};
