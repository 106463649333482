import { gatewayApi } from '../gatewayApi';

const stateMachineApi = gatewayApi.injectEndpoints({
  endpoints: (build) => ({
    getNotifiableStates: build.query({
      query: () => ({
        url: `/sm/states/events/notifiable`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'NotifiableStates', id: 'LIST' }],
    }),
    getApplicationNextStates: build.query({
      query: (applicationId) => ({
        url: `sm/states/${applicationId}/next`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'ApplicationStatesNext', id: applicationId },
      ],
    }),
    getApplicationStates: build.query({
      query: (applicationId) => ({
        url: `sm/states/${applicationId}`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'ApplicationStates', id: applicationId },
      ],
    }),
    getApplicationNextEvents: build.query({
      query: (applicationId) => ({
        url: `sm/events/${applicationId}/next`,
        method: 'GET',
      }),
      providesTags: (result, error, applicationId) => [
        { type: 'ApplicationEvents', id: applicationId },
      ],
    }),
  }),
});

export const {
  useGetNotifiableStatesQuery,
  useGetApplicationNextStatesQuery,
  useGetApplicationNextEventsQuery,
  useGetApplicationStatesQuery,
} = stateMachineApi;
