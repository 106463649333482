import { Button } from '@mui/material';
import { RiCloseFill } from 'react-icons/ri';
import React from 'react';

interface ArrowBackProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  fullWidth?: boolean;
  title?: string;
}

export const ArrowBack: React.FC<ArrowBackProps> = ({ onClose, title = 'Close' }) => (
  <Button
    color="base"
    onClick={() => onClose(false)}
    variant="contained"
    startIcon={<RiCloseFill size="14px" />}
    size="small"
  >
    {title}
  </Button>
);
