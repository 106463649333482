/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';
import {
  GET_VALIDATOR_TRANSACTIONS,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL,
  GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS,
  GET_VALIDATOR_TRANSACTIONS_FAIL,
  GET_VALIDATOR_TRANSACTIONS_SUCCESS,
} from '../../../constants/constants';

export const initialState = {
  loading: false,
  error: false,
  validatorTransactions: [],
  paginationInfo: null,
  selectedCardDataLoading: false,
  selectedCardDataError: false,
  selectedCardData: null,
  selectedTransactionIndex: null,
};

const validatorTransactionsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_VALIDATOR_TRANSACTIONS:
        draft.loading = true;
        draft.error = false;
        break;
      case GET_VALIDATOR_TRANSACTIONS_SUCCESS:
        draft.validatorTransactions = action.transactions.payload;
        draft.paginationInfo = action.transactions.paginationInfo;
        draft.loading = false;
        break;
      case GET_VALIDATOR_TRANSACTIONS_FAIL:
        draft.loading = false;
        draft.error = action.error;
        break;
      case GET_VALIDATOR_TRANSACTIONS_CARD_DATA:
        draft.selectedCardDataLoading = true;
        break;
      case GET_VALIDATOR_TRANSACTIONS_CARD_DATA_SUCCESS:
        draft.selectedCardData = action.cardData;
        draft.selectedCardDataLoading = false;
        break;
      case GET_VALIDATOR_TRANSACTIONS_CARD_DATA_FAIL:
        draft.selectedCardDataError = action.error;
        draft.selectedCardDataLoading = false;
        break;
    }
  });

export default validatorTransactionsReducer;
