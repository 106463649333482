import React from 'react';
import * as _ from 'lodash';
import { Table, TableBody, Typography } from '@mui/material';
import { BodyRow, BodyTableCell, Skeleton } from 'uikit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatIsoDate } from 'utils/formatter';
import { DATE_FORMAT_MESSAGES, DATETIME_FORMAT } from 'constants/constants';
import { useGetAllCountriesQuery } from 'services/gatewayApi/countryRiskApi';
import { useGetUboHistoryQuery } from 'services/gatewayApi/ubosApi';
import { Ubo } from '../../types';
import { detailsFields, documentTypes, genders } from '../../constants';

import './HistoryTab.scss';

interface HistoryTabProps {
  ubo: Ubo;
}

export const HistoryTab: React.FC<HistoryTabProps> = ({ ubo }) => {
  const { id } = ubo;
  const {
    data: history,
    isLoading,
    isFetching,
  } = useGetUboHistoryQuery({ id, historySize: 100 /* Max rows */ });

  const { data: countries, isLoading: isLoadingCountries } = useGetAllCountriesQuery(undefined);

  const formatValue = (field, value) => {
    if (!value) {
      return '-';
    }

    switch (field) {
      case 'dateOfBirth':
      case 'documentExpiry':
        return formatIsoDate(value, DATE_FORMAT_MESSAGES);

      case 'nationality':
      case 'documentCountryIssue':
      case 'country':
        return countries?.find((c) => c.countryCode === value)?.name ?? '-';

      case 'gender':
        return genders?.find((c) => c.value === value)?.label ?? '-';

      case 'documentType':
        return documentTypes?.find((c) => c.value === value)?.label ?? '-';

      default:
        return value;
    }
  };

  const changes = [];

  if (!(isLoading || isFetching || isLoadingCountries)) {
    const historySorted = history
      .slice()
      .sort((a, b) => (a.revision.revisionNumber > b.revision.revisionNumber ? 1 : -1));

    let previousRecord = { ...historySorted[0] };

    for (var i = 1; i < historySorted.length; i++) {
      // Find changes for fields
      let differences = _.reduce(
        historySorted[i],
        (result, value, key) =>
          _.isEqual(value, previousRecord[key]) ? result : result.concat(key),
        [],
      );

      // Ignore changes for following fields
      differences = differences.filter(
        (item) => !['modifiedDate', 'modifiedBy', 'modifiedByName', 'revision'].includes(item),
      );

      differences.forEach((d) => {
        changes.push({
          id: `${historySorted[i].revision.revisionNumber}_${d}`,
          modifiedDate: historySorted[i].modifiedDate,
          modifiedBy: historySorted[i].modifiedBy,
          modifiedByName: historySorted[i].modifiedByName,
          field: d,
          oldValue: previousRecord[d],
          newValue: historySorted[i][d],
        });
      });

      previousRecord = { ...historySorted[i] };
    }
  }

  return (
    <div className="HistoryTab">
      {(isLoading || isFetching) && (
        <div style={{ marginTop: '1.5em' }}>
          <Skeleton count={3} height={50} />
        </div>
      )}

      {!(isLoading || isFetching) && (
        <>
          {changes.length > 0 &&
            changes?.reverse().map((c) => (
              <div className="item clearfix" key={c.id}>
                <div className="head clearfix">
                  <div className="left">
                    <Typography variant="subtitle1" component="span" color="grey.900">
                      {detailsFields.find((f) => f.value === c.field)?.label}
                    </Typography>
                  </div>
                  <div className="center"> </div>
                  <div className="right">
                    <Typography variant="body2" component="span" color="grey.600">
                      {formatIsoDate(c.modifiedDate, DATETIME_FORMAT)} by {c.modifiedByName}
                    </Typography>
                  </div>
                </div>
                <div className="details clearfix">
                  <div className="left">{formatValue(c.field, c.oldValue)}</div>
                  <div className="center">
                    <ArrowForwardIcon />
                  </div>
                  <div className="right">{formatValue(c.field, c.newValue)}</div>
                </div>
              </div>
            ))}

          {changes.length === 0 && (
            <Table>
              <TableBody>
                <BodyRow className="no-data">
                  <BodyTableCell>
                    <Typography variant="body1" component="p" color="grey.900">
                      No changes found...
                    </Typography>
                  </BodyTableCell>
                </BodyRow>
              </TableBody>
            </Table>
          )}
        </>
      )}
    </div>
  );
};
