import { BaseToolbarProps } from '@mui/x-date-pickers/internals/models/props/baseToolbarProps';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const DatePickerToolbarRoot = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    px: 6,
    py: 4,
  }),
);

const DatePickerToolbarTitle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    alignSelf: 'center',
  }),
);

export const DatePickerTitleToolbar = <TDate,>(
  props: BaseToolbarProps<TDate, TDate | null>,
): JSX.Element => {
  const {
    parsedValue,
    isLandscape,
    isMobileKeyboardViewOpen,
    toggleMobileKeyboardView,
    toolbarFormat,
    toolbarPlaceholder = '––',
    toolbarTitle = 'Select date',
    views,
    ...other
  } = props;
  return (
    <DatePickerToolbarRoot>
      <DatePickerToolbarTitle variant="h4">{toolbarTitle}</DatePickerToolbarTitle>
    </DatePickerToolbarRoot>
  );
};
