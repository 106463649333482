import { isRejectedWithValue } from '@reduxjs/toolkit';
import toastr from 'toastr';
import * as Sentry from '@sentry/browser';
import { toastError } from './utils/toast';

/**
 * Log a warning and show a toast!
 */
export const queryErrorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.error) {
      toastError(action.payload.error);
    }
    if (action.payload?.data?.status) {
      // Failed with response from API
      const { status, errors, message } = action.payload.data;
      console.warn(`Action rejected: Status: ${status}, Error: ${errors}, Message: ${message}`);
      toastr.error(message);
      if (status === 500) Sentry.captureException(action.payload.data);
    } else if (action.meta.baseQueryMeta.response) {
      // Failed, no data in response available
      const { status, statusText } = action.meta.baseQueryMeta.response;
      console.warn(`Action rejected: Status: ${status}: ${statusText}`);
      toastr.error(`${status}: ${statusText}`);
    } else {
      // No response
      console.warn(`Action rejected: ${action.payload.error}`);
      toastr.error(`Unable to get a response`);
    }
  }
  return next(action);
};
