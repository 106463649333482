import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useGetTransactionSearchSessionsQuery } from 'services/gatewayApi/pepAndSanctionsApi';
import { TabProps } from './PepAndSanctions';
import { EmptyPage, EndListButton } from '../../uikit';
import { SearchSessionsList } from './SearchSessions/SearchSessionsList';
import 'styles/tables.scss';

export const PepAndSanctionsTransactions = () => {
  const [counter, setCounter, sortBy, setSortBy, pageSize, setPageSize] =
    useOutletContext<TabProps>();

  const {
    data,
    isLoading: isLoadingSearchSessions,
    isFetching: isFetchingSearchSessions,
  } = useGetTransactionSearchSessionsQuery({
    page: 0,
    size: pageSize,
  });

  const { searchSessionsList, totalElements, numberOfElements } = data || {};

  useEffect(() => {
    if (data) {
      setCounter({ numberOfElements, totalElements });
    }
  }, [data]);

  return (
    <>
      {searchSessionsList?.length > 0 ? (
        <div className="table-wrapper">
          <SearchSessionsList searchSessions={searchSessionsList} isTransactions />
          {(!!searchSessionsList || searchSessionsList?.length < 1) && (
            <EndListButton
              isVisible={searchSessionsList?.length > 0}
              nextPage={() =>
                setPageSize((prev) => (prev + 100 > totalElements ? totalElements : prev + 100))
              }
              isFetching={isFetchingSearchSessions}
              isEndReached={totalElements === numberOfElements}
            />
          )}
        </div>
      ) : (
        <EmptyPage
          isLoading={isLoadingSearchSessions}
          title="You haven't done a search session yet."
          subtitle="The search data will appear when the search is applied."
        />
      )}
    </>
  );
};
