import {
  LOAD_SANCTION_DETAIL,
  LOAD_SANCTION_DETAIL_FAIL,
  LOAD_SANCTION_DETAIL_SUCCESS,
} from '../constants/constants';
import ApiService from '../services/apiService';
import { toastLoadingError } from '../utils/toast';

const entityTitle = 'Sanction';

export const fetchSanctionDetail = (id) => (dispatch) => {
  dispatch({ type: LOAD_SANCTION_DETAIL });
  const url = `/api/sanction-details/${id}/external-references`;
  ApiService.get(url)
    .then((response) => dispatch(fetchSanctionDetailSuccess(response)))
    .catch((error) => dispatch(fetchSanctionDetailError(error)));
};

const fetchSanctionDetailSuccess = (sanctionDetail) => ({
  type: LOAD_SANCTION_DETAIL_SUCCESS,
  sanctionDetail,
});

const fetchSanctionDetailError = (error) => (dispatch) => {
  dispatch({
    type: LOAD_SANCTION_DETAIL_FAIL,
    error,
  });
  toastLoadingError(entityTitle, error);
};
