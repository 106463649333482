// Query has at least one value

export interface IQuery {
  [key: string]: string | boolean;
}

export const queryHasValue = (query: IQuery) => {
  let result = false;
  Object.keys(query).forEach((key) => {
    if (query[key] !== '') {
      result = true;
      return false; // exit forEach
    }
  });
  return result;
};
