import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import classNames from 'classnames';
import * as _ from 'lodash';
import Editor, { DiffEditor, useMonaco } from '@monaco-editor/react';
import { createFunction, fetchFunctionHistory, updateFunction } from '../../actions/functions';
import { Button, Spinner } from '../../components';
import { makeSavingData } from '../../selectors/ruleBuilder';
import { removeEmptyProperties } from '../../utils/modelHelper';
import { functionHistorySelector } from '../../selectors/functions';
import getLocalDateTime from '../../utils/formatter';
import { DATETIME_FORMAT_MOMENT } from '../../constants/constants';

const FunctionForm = (props) => {
  const makeFunctionFormStructuredSelector = createStructuredSelector({
    saving: makeSavingData(),
  });

  const { saving } = useSelector(makeFunctionFormStructuredSelector);
  const functionHistory = useSelector(functionHistorySelector);

  const [values, setValues] = useState({
    name: props.ruleFunction.name,
    script: props.ruleFunction.script,
    id: props.ruleFunction.id,
    category: props.ruleFunction.category,
    status: props.ruleFunction.status,
    description: props.ruleFunction.description,
    modifiedByName: props.ruleFunction.modifiedByName,
    modifiedDate: props.ruleFunction.modifiedDate,
  });
  const [errors, setErrors] = useState({});
  const [saveAttempt, setSaveAttempt] = useState(false);
  const [isShowHistory, setIsShowHistory] = useState(false);
  const [selectedRevision, setSelectedRevision] = useState({
    name: '',
    script: '',
    status: '',
    description: '',
    modifiedByName: '',
    modifiedDate: '',
    createdDate: '',
    revision: { revisionNumber: '' },
  });

  // Backward compatibility
  const isHistoryAvailable = values.modifiedDate && values.modifiedByName !== null;
  const monaco = useMonaco();
  const dispatch = useDispatch();

  useEffect(() => {
    if (functionHistory[1]) setSelectedRevision(functionHistory[1]);
  }, [functionHistory]);

  useEffect(() => {
    if (monaco) {
      monaco.editor.defineTheme('flippedDiffTheme', {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
          'diffEditor.insertedTextBackground': '#ff000033',
          'diffEditor.removedTextBackground': '#28d22833',
        },
      });
    }
  }, [monaco]);

  const getRevisions = () =>
    functionHistory.flatMap((item, index) =>
      index === 0 ? [] : { value: index, label: item.revision.revisionNumber },
    );

  const getStatuses = () => [
    { value: 'TEST', label: 'TEST' },
    { value: 'PROD', label: 'PROD' },
    { value: 'ERROR', label: 'ERROR' },
  ];

  const getValidClassname = (field) =>
    classNames({
      error: errors[field] && saveAttempt,
      'form-control': field !== 'category' && field !== 'status',
    });

  const handleRevisionChange = (e) => {
    setSelectedRevision(functionHistory[e.value]);
  };

  const handleInputChange = (e, field) => {
    if (field === 'category' || field === 'status') {
      setValues({ ...values, [field]: e.value });
    } else {
      setValues({ ...values, [field]: e.target.value });
      // eslint-disable-next-line no-unused-expressions
      e.target.value.trim() && e.target.value.match(e.target.pattern) !== null
        ? delete errors[field]
        : (errors[field] = true);
      setErrors(errors);
    }
  };

  const handleClickShowHistory = () => {
    dispatch(fetchFunctionHistory(values.id));
    setIsShowHistory(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveAttempt(true);
    // Workaround to get DiffEditor values because onChange doesn't work
    if (_.isEmpty(_.pickBy(errors, (i) => i))) {
      const model = {
        function: {
          id: values.id,
          name: values.name,
          category: values.category,
          description: values.description,
          script: monaco.editor.getModels()[0].getValue(),
          status: values.status,
        },
      };
      removeEmptyProperties(model);
      if (model.function.id) {
        dispatch(updateFunction(model));
      } else {
        dispatch(createFunction(model));
      }
    }
    props.handleCloseModal(saveAttempt);
  };

  const handleClickOverlay = (e) => {
    if (e.target.className === 'ruleFormWrapper') {
      props.handleCloseModal({ saveAttempt: false });
    }
  };

  const className = classNames('spinner inline', {
    hide: !saving,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={handleClickOverlay} className="ruleFormWrapper">
      <div className="row form function-form">
        <button
          type="button"
          className="closeModal"
          onClick={() => {
            props.handleCloseModal({ saveAttempt: false });
          }}
        >
          <i className="fa fa-times" />
        </button>
        <Helmet title="Edit Rule" />
        <div className="col-md-12 height100">
          <div className="grid simple">
            <div className="grid-title no-border">
              <h4>
                Rule Function <span className="semi-bold">Form</span>
              </h4>
            </div>
            <div className="grid-body no-border">
              <div className="row">
                {isHistoryAvailable && (
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div>
                      <p className="form-label">Modified By: {values.modifiedByName}</p>
                      <p className="form-label">
                        Modified Date:{' '}
                        {getLocalDateTime(values.modifiedDate).format(DATETIME_FORMAT_MOMENT)}
                      </p>
                    </div>
                  </div>
                )}
                {isShowHistory && isHistoryAvailable ? (
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <p className="form-label">Modified By: {selectedRevision.modifiedByName}</p>
                      <p className="form-label">
                        Modified Date:{' '}
                        {getLocalDateTime(
                          selectedRevision.modifiedDate || selectedRevision.createdDate,
                        ).format(DATETIME_FORMAT_MOMENT)}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="revision" className="form-label">
                        Revision
                      </label>
                      <div className="controls">
                        <Select
                          name="revision"
                          options={getRevisions()}
                          value={{
                            value: 1,
                            label: selectedRevision.revision.revisionNumber,
                          }}
                          onChange={(e) => {
                            handleRevisionChange(e);
                          }}
                          className={getValidClassname('status')}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  !isShowHistory &&
                  isHistoryAvailable && (
                    <div className="new-button-container">
                      <Button
                        buttonText="Show History"
                        onClick={handleClickShowHistory}
                        buttonClass="btn btn-primary"
                      />
                    </div>
                  )
                )}
                <br />
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <div className="controls">
                        <input
                          id="name"
                          pattern="^[a-zA-Z0-9 .-]+$"
                          type="text"
                          className={getValidClassname('name')}
                          value={values.name}
                          onChange={(e) => {
                            handleInputChange(e, 'name');
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <div className="controls">
                        <input
                          id="description"
                          type="text"
                          pattern="^[a-zA-Z0-9 .-]+$"
                          className={getValidClassname('description')}
                          value={values.description}
                          onChange={(e) => {
                            handleInputChange(e, 'description');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {isShowHistory && (
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="prevName" className="form-label">
                          Name
                        </label>
                        <div className="controls">
                          <input
                            id="prevName"
                            type="text"
                            className="form-control"
                            value={selectedRevision.name}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="prevDescription" className="form-label">
                          Description
                        </label>
                        <div className="controls">
                          <input
                            id="prevDescription"
                            type="text"
                            className="form-control"
                            value={selectedRevision.description}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="script" className="form-label">
                    Script
                  </label>
                  <div>
                    {isShowHistory ? (
                      <DiffEditor
                        height="35vh"
                        defaultLanguage="javascript"
                        original={values.script}
                        modified={selectedRevision.script}
                        theme="flippedDiffTheme"
                        options={{
                          originalEditable: true,
                          readOnly: true,
                          renderIndicators: false,
                        }}
                      />
                    ) : (
                      <Editor height="35vh" defaultLanguage="javascript" value={values.script} />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <div className="controls">
                        <Select
                          name="status"
                          isDisabled={!values.id}
                          options={getStatuses()}
                          value={getStatuses().find((status) => status.value === values.status)}
                          onChange={(e) => {
                            handleInputChange(e, 'status');
                          }}
                          className={getValidClassname('status')}
                        />
                      </div>
                    </div>
                    {isShowHistory && (
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <div className="controls">
                          <Select
                            name="status"
                            isDisabled
                            value={{
                              value: 0,
                              label: selectedRevision.status,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="new-button-container">
                  <Button
                    buttonType="submit"
                    buttonClass="btn btn-primary btn-cons"
                    buttonText="Save"
                  />
                </div>
                <div className={className}>
                  <Spinner />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FunctionForm.propTypes = {
  ruleFunction: PropTypes.object,
  handleCloseModal: PropTypes.func,
};

export default FunctionForm;
