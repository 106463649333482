import { useEffect } from 'react';

// - Lock body scroll
// - Close dialog on Escape
export const useModalDialogEffect = ({ hideModal, isShowed }) => {
  useEffect(() => {
    const keyPressHandler = (e) => {
      if (e.key === 'Escape') {
        hideModal();
      }
    };

    if (isShowed) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', keyPressHandler, { capture: false }); // false — is important, because it is set bubbling
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', keyPressHandler, { capture: false });
      document.body.style.overflow = 'auto';
    };
  }, [isShowed]);
};
