import React, { useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { TabProps } from './PepAndSanctions';
import { useGetSearchSessionsQuery } from 'services/gatewayApi/pepAndSanctionsApi';
import { transformResult } from './transformResult';
import { PepSanctionsContent } from './PepSanctionsContent/PepSanctionsContent';

export const PepAndSanctionsApplications = () => {
  const [counter, setCounter, sortBy, setSortBy, pageSize, setPageSize] =
    useOutletContext<TabProps>();

  const { applicationId } = useParams<{ applicationId: string }>();

  const { data, isLoading, isFetching } = useGetSearchSessionsQuery({
    applicationId,
    params: { page: 0, size: pageSize },
    // sort: ['performedOn', 'ASC'], //TODO when BE will be ready
  });

  const {
    searchSessionsList,
    isLoading: isLoadingSearchSessions,
    isFetching: isFetchingSearchSessions,
    totalElements,
    numberOfElements,
  } = transformResult(data, isLoading, isFetching);

  useEffect(() => {
    if (data) {
      setCounter({ numberOfElements, totalElements });
    }
  }, [data]);

  return (
    <PepSanctionsContent
      searchSessionsList={searchSessionsList}
      isLoadingSearchSessions={isLoadingSearchSessions}
      isFetchingSearchSessions={isFetchingSearchSessions}
      totalElements={totalElements}
      numberOfElements={numberOfElements}
      setPageSize={setPageSize}
    />
  );
};
