import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { DetailsDrawer, HeadTableCell, SortBlock, Spinner } from 'uikit';
import { SortParam } from 'types';
import { useGetApplicationsQuery } from 'services/gatewayApi/applicationApi';
import { Individual, useGetIndividualsQuery } from 'services/gatewayApi/applicationIndividualsApi';
import { IndividualRow } from './IndividualRow';
import { DetailsSidebar } from './DetailsSidebar';
import { tableSortOptions } from './constants';

export const IndividualsTab = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const { onboardingApplicationId, isLoadingApplication } = useGetApplicationsQuery(
    {
      haystackClientId: clientId,
      kind: 'ONBOARDING',
    },
    {
      skip: !clientId,
      selectFromResult: ({ data, isLoading }) => ({
        onboardingApplicationId: data?.content[0].id,
        isLoadingApplication: isLoading,
      }),
    },
  );

  const applicationId = onboardingApplicationId;

  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<null | string>(null);

  const [sortedBy, setSortedBy] = useState<SortParam>({
    property: 'firstName',
    direction: 'asc',
  });

  const handleSort = (property) => {
    const getDirection = () => {
      if (sortedBy.property === property) {
        return sortedBy.direction === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    };
    return setSortedBy({
      property,
      direction: getDirection(),
    });
  };

  const { data: individuals, isLoading } = useGetIndividualsQuery(
    {
      page: 0,
      size: 9999,
      sort: `${sortedBy.property},${sortedBy.direction}`,
      applicationId,
    },
    {
      skip: !applicationId,
    },
  );

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        {(isLoading || isLoadingApplication) && (
          <Box className="app-details-preloader app-details-preloader--small">
            <Spinner />
          </Box>
        )}

        {!isLoading && !isLoadingApplication && (
          <Box sx={{ width: '100%' }}>
            {individuals && individuals.content.length > 0 && (
              <>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    border: 1,
                    borderRadius: '12px',
                    borderColor: 'grey.200',
                    mt: 4,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        {tableSortOptions.map((item) => (
                          <HeadTableCell key={item.property}>
                            <SortBlock
                              property={item.property}
                              sortedBy={sortedBy}
                              handleSort={item.isSortable ? handleSort : undefined}
                              style={{ whiteSpace: 'normal' }}
                            >
                              {item.title}
                            </SortBlock>
                          </HeadTableCell>
                        ))}
                        <HeadTableCell sx={{ width: '1%' }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {individuals.content.map((individual) => (
                        <IndividualRow
                          key={individual.id}
                          individual={individual}
                          selected={individual.id === selectedItem?.id && openDetailsDrawer}
                          setSelectedItem={setSelectedItem}
                          setOpenDetailsDrawer={setOpenDetailsDrawer}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </>
            )}

            {individuals && individuals.content.length === 0 && (
              <Box mt={4}>
                <Typography variant="h1" color="grey.400">
                  Associated individuals not found.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Grid>

      <DetailsDrawer open={openDetailsDrawer} width={450}>
        <DetailsSidebar
          applicationId={onboardingApplicationId}
          individual={
            individuals?.content.find((el) => el.id === selectedItem) || ({} as Individual)
          }
          onClose={() => {
            setOpenDetailsDrawer(false);
            setSelectedItem(null);
          }}
        />
      </DetailsDrawer>
    </>
  );
};
