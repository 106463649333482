import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RiDeleteBinFill, RiPencilLine } from 'react-icons/ri';
import { useDeleteClientMutation } from 'services/gatewayApi/clientsApi';
import { ModalDialog, MemoizedChipSelector } from 'uikit';
import { ClientDialog } from '../ClientDialog';
import { Client } from '../types';

interface DetailsTitleProps {
  client: Client;
  onClose: any;
  setEdited: any;
  updateClient: any;
  categories: any;
}

export const DetailsTitle: React.FC<DetailsTitleProps> = ({
  client,
  onClose,
  setEdited,
  updateClient,
  categories,
}) => {
  const [deleteClient] = useDeleteClientMutation();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [clientDialogOpen, setClientDialogOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setClientDialogOpen(true);
    setEdited(true);
  };
  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };
  const handleDelete = () => {
    deleteClient({ id: client.id });
    setOpenConfirmDelete(false);
    onClose();
  };

  const handleUpdateClient = (newList) => {
    const model = {
      name: client.name,
      clientId: client.clientId,
      categoriesId: newList.map((el) => el.value),
      incorporationDate: client.incorporationDate,
    };
    updateClient(client.id, model);
    setEdited(true);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ pt: 5, pb: 2 }}
      >
        <Typography variant="h3" sx={{ mb: 0 }}>
          {client.name}
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Button
            color="base"
            onClick={handleEdit}
            variant="contained"
            startIcon={<RiPencilLine size="14px" />}
          >
            Edit
          </Button>
          <Button
            color="error"
            onClick={handleConfirmDelete}
            variant="contained"
            startIcon={<RiDeleteBinFill size="14px" />}
            disabled={client.accounts?.length > 0}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
      <MemoizedChipSelector
        list={categories}
        selectedList={client?.clientCategories.map((el) => ({ value: el.id, label: el.name }))}
        setSelectedList={handleUpdateClient}
        limitTags={5}
      />

      {clientDialogOpen && (
        <ClientDialog
          open={clientDialogOpen}
          onClose={() => setClientDialogOpen(false)}
          client={client}
          categories={categories}
        />
      )}

      {openConfirmDelete && (
        <ModalDialog
          open={openConfirmDelete}
          onClose={() => setOpenConfirmDelete(false)}
          title="Delete Client"
          handleAction={() => handleDelete()}
          actionTitle="Delete"
          variant="error"
        >
          <Typography variant="body1" component="span">
            {'Are you sure you want to delete Client '}
            <Typography variant="subtitle1" component="span">
              {client.name}
            </Typography>
            {' ?'}
          </Typography>
        </ModalDialog>
      )}
    </Box>
  );
};
