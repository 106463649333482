/* eslint-disable no-param-reassign,default-case */
import produce from 'immer';

import {
  LOAD_CARD_WIDGET,
  LOAD_CARD_WIDGET_SUCCESS,
  LOAD_CARD_WIDGET_FAIL,
} from '../../constants/constants';

export const initialState = [
  {
    category: 'fraudAmount',
    loading: false,
    error: false,
    data: [],
  },
  {
    category: 'fraudNumbers',
    loading: false,
    error: false,
    data: [],
  },
  {
    category: 'cbAmount',
    loading: false,
    error: false,
    data: [],
  },
  {
    category: 'cbNumbers',
    loading: false,
    error: false,
    data: [],
  },
];
const cardWidgetReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const idx = state.findIndex((val) => val.category === action.category);
    if (idx !== -1) {
      switch (action.type) {
        case LOAD_CARD_WIDGET: {
          draft[idx].loading = true;
          draft[idx].error = false;
          break;
        }
        case LOAD_CARD_WIDGET_SUCCESS: {
          draft[idx].loading = false;
          draft[idx].error = action.data;
          break;
        }
        case LOAD_CARD_WIDGET_FAIL: {
          draft[idx].loading = false;
          draft[idx].error = action.error;
          break;
        }
      }
    }
  });

export default cardWidgetReducer;
