export const medium = {
  props: { size: 'medium' },
  style: {
    padding: '6px 12px',
    height: 'fit-content',

    '.MuiButton-startIcon': {
      marginRight: '8px',
    },
  },
};
