import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { figmaVariables } from 'theme/figmaVariables';
import { SidebarItemType } from './SidebarTypes';

const ButtonStyled = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    justifyContent: 'start',
    mb: 1,
    px: 2,
    color: figmaVariables.theme.text['text-base'],

    '&:focus': { boxShadow: 'none !important' }
  }),
);

interface SitebarItemProps {
  type?: SidebarItemType;
  title?: string;
  link?: string;
  action?: () => void;
  pageUrl?: string;
}

export const SidebarItem: React.FC<SitebarItemProps> = ({
  type = SidebarItemType.SubTitle,
  title,
  link,
  action,
  pageUrl,
}) => {
  const isActive = location.pathname.includes(link);

  if (type === SidebarItemType.SubTitle) {
    return (
      <ButtonStyled
        color="base"
        variant="text"
        disabled
        fullWidth
        sx={{
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 500,
          letterSpacing: '1px',
        }}
      >
        {title}
      </ButtonStyled>
    )
  }

  const button = (
    <ButtonStyled
      color={isActive ? 'secondary' : 'base'}
      variant={isActive ? 'contained' : 'text'}
      onClick={action}
      fullWidth
    >
      {title}
    </ButtonStyled>
  );

  if (action) {
    return button;
  }

  return (
    <Link to={`${pageUrl}/${link}`}>
      {button}
    </Link >
  );
};
