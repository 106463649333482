import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IndicatorsStateProps {
  progressBars: Record<string, number>;
  idUploading?: string;
}

const initialState: IndicatorsStateProps = {
  progressBars: {},
  idUploading: undefined,
};

export const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  reducers: {
    setUploadProgress: (state, action: PayloadAction<{ id: string; progressValue: number }>) => {
      const { id, progressValue } = action.payload;
      state.progressBars[id] = progressValue;
    },
    seIsUploading: (state, action: PayloadAction<{ idUploading: string }>) => {
      const { idUploading } = action.payload;
      state.idUploading = idUploading;
    },
  },
});

export const { setUploadProgress, seIsUploading } = indicatorsSlice.actions;

export const indicatorsReducer = indicatorsSlice.reducer;
